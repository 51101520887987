import React from 'react';
import { Box, BoxProps, Grid, Hidden, Paper, PaperProps } from '@mui/material';
import { AssistantSvg } from '../assistant-svg/component';
import { useStyles } from './styles';
import { HintContent, SharedHintContentProps } from '../hint-content/component';
import { AssistantHintData } from '../store/model';
import { AssistantControls, SharedAssistantControlsProps } from '../assistant-controls/components';

export interface SharedInlineAssistantProps extends BoxProps {
  showAssistantSvg?: boolean;
  paperProps?: PaperProps;
}

export interface InlineAssistantProps extends SharedInlineAssistantProps {
  open?: boolean;
  hintData?: AssistantHintData;
  loading?: boolean;
  contentProps?: SharedHintContentProps;
  onSelectHintOptions?: (options: string[]) => void;
  controlsProps?: SharedAssistantControlsProps;
}

export const InlineAssistant: React.FC<InlineAssistantProps> = ({
  open,
  hintData,
  loading,
  contentProps,
  onSelectHintOptions,
  controlsProps,
  paperProps,
  showAssistantSvg = true,
  ...rest
}) => {
  const styles = useStyles();

  if (!open) return null;

  return (
    <Box width={'100%'} {...rest}>
      <Paper className={styles.paper} {...paperProps}>
        {showAssistantSvg && (
          <Hidden smDown>
            <AssistantSvg px={2} pt={2} className={styles.assistantSvg} loading={loading} hint={!!hintData?.text} />
          </Hidden>
        )}

        <Box className={styles.content} p={2}>
          <HintContent hintData={hintData} loading={loading} onSelect={onSelectHintOptions} {...contentProps} />

          <Box width={'100%'} pt={2} marginTop={'auto'}>
            <Grid container spacing={1} alignItems={'flex-end'}>
              {showAssistantSvg && (
                <Hidden smUp>
                  <Grid item xs={'auto'}>
                    <AssistantSvg
                      px={2}
                      pt={2}
                      className={styles.assistantSvg}
                      loading={loading}
                      hint={!!hintData?.text}
                    />
                  </Grid>
                </Hidden>
              )}

              <Grid item xs={true}>
                <AssistantControls
                  hintData={hintData}
                  hintRowsType={contentProps?.hintRowsType}
                  loading={loading}
                  containerProps={{ justifyContent: 'flex-end' }}
                  {...controlsProps}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
