import { EbsIdeaCategory, EbsIdeaDifficultyVariant } from '@priz/shared/src/models/tools/ebs';
import { resolveNodeType } from '@priz/shared/src/utils/ebs';

export const resolveNodePropsByCoords = (x: number, y: number) => {
  const props: {
    implementation?: EbsIdeaDifficultyVariant;
    validation?: EbsIdeaDifficultyVariant;
    type?: EbsIdeaCategory;
  } = {};

  const implementation = y < 0 ? EbsIdeaDifficultyVariant.Easy : EbsIdeaDifficultyVariant.NotEasy;
  const validation = x > 0 ? EbsIdeaDifficultyVariant.Easy : EbsIdeaDifficultyVariant.NotEasy;

  props.implementation = implementation;
  props.validation = validation;
  props.type = resolveNodeType(implementation, validation);

  return props;
};
