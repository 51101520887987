import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { DomainActions, DomainPrincipleActions, PrincipleActions } from '../store/actions';
import { InventivePrinciplesSelectors } from '../store/selectors/InventivePrinciplesSelectors';
import { useForm } from 'react-hook-form';
import { ReactHookFormSelect } from '../../react/form-elements';
import { Principle } from '@priz/shared/src/models/tools/forty-principles/principle';
import { DomainPrinciple } from '@priz/shared/src/models/tools/forty-principles/domain-principle';
import { EditableTable } from '../../react/editable-table/component';
import { useStyles } from './styles';
import { UpdateDomainPrincipleCommand, UpdatePrincipleCommand } from '../services/principle.service';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { useTranslation } from 'react-i18next';
import { resolveTranslation } from '../../react/translation-resolver/component';

const resolveDomainPrinciple = (
  principle: Principle,
  selectedDomainId: number,
  domainPrinciples: DomainPrinciple[],
) => {
  if (selectedDomainId) {
    return domainPrinciples?.find(dp => dp.domain.id === selectedDomainId && dp.principle.id === principle.id);
  } else {
    return principle;
  }
};

export const PrinciplesSettings: React.FC = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getValues, control, reset } = useForm({
    defaultValues: {
      selectedDomainId: null,
    },
  });

  const [selectedDomainId, setSelectedDomainId] = useState(0);

  const principles = useSelector(InventivePrinciplesSelectors.getPrinciples);
  const domainPrinciples = useSelector(InventivePrinciplesSelectors.getDomainPrinciples);
  const domains = useSelector(InventivePrinciplesSelectors.getDomains);

  const isPrinciplesLoaded = useSelector(InventivePrinciplesSelectors.isPrinciplesLoaded);
  const isDomainPrinciplesLoaded = useSelector(InventivePrinciplesSelectors.isDomainPrinciplesLoaded);
  const isDomainsLoaded = useSelector(InventivePrinciplesSelectors.isDomainsLoaded);

  const principlesUpdatingIds = useSelector(InventivePrinciplesSelectors.getPrinciplesUpdatingIds);
  const domainPrinciplesUpdatingIds = useSelector(InventivePrinciplesSelectors.getDomainPrinciplesUpdatingIds);
  const domainsUpdatingIds = useSelector(InventivePrinciplesSelectors.getDomainsUpdatingIds);

  const isLoaded = isPrinciplesLoaded && isDomainPrinciplesLoaded && isDomainsLoaded;
  const updatingIds = [...principlesUpdatingIds, ...domainPrinciplesUpdatingIds, ...domainsUpdatingIds];

  const principlesForDomain: Partial<Principle>[] = principles.map(item => {
    const domainPrinciple = resolveDomainPrinciple(item, selectedDomainId, domainPrinciples);

    return {
      id: item.id,
      domainId: selectedDomainId,
      domainPrincipleId: domainPrinciple.id,
      title: item.title,
      titleZh: item.titleZh,
      titleEs: item.titleEs,
      description: domainPrinciple?.description,
      descriptionZh: domainPrinciple?.descriptionZh,
      descriptionEs: domainPrinciple?.descriptionEs,
    };
  });

  useEffect(() => {
    dispatch(PrincipleActions.loadPrinciples());
    dispatch(DomainPrincipleActions.loadDomainPrinciples());
    dispatch(DomainActions.loadDomains());
  }, []);

  useEffect(() => {
    if (!isSelectedDomainExist()) {
      reset({ selectedDomainId: null });
      setSelectedDomainId(0);
    }
  }, [domains, principles, domainPrinciples, selectedDomainId]);

  const isSelectedDomainExist = () => {
    return selectedDomainId === 0 || domains.map(item => item.id).includes(selectedDomainId);
  };

  const selectDomainHandler = () => {
    setSelectedDomainId(getValues().selectedDomainId || 0);
  };

  const onChangeHandler = (row, col, val) => {
    if (['description', 'descriptionZh', 'descriptionEs'].includes(col) && selectedDomainId) {
      const principle = resolveDomainPrinciple(row, selectedDomainId, domainPrinciples);
      const data = { ...principle, [col]: val };

      const command: UpdateDomainPrincipleCommand = {
        description: data.description,
        descriptionZh: data.descriptionZh,
        descriptionEs: data.descriptionEs,
      };

      dispatch(DomainPrincipleActions.updateDomainPrinciple(principle.id, command));
    } else {
      const domainPrinciple = { ...row, [col]: val };
      const principle = selectedDomainId ? principles.find(p => p.id === row.id) : domainPrinciple;

      const command: UpdatePrincipleCommand = {
        title: domainPrinciple.title,
        titleZh: domainPrinciple.titleZh,
        titleEs: domainPrinciple.titleEs,
        description: principle.description,
        descriptionZh: principle.descriptionZh,
        descriptionEs: principle.descriptionEs,
      };

      dispatch(PrincipleActions.updatePrinciple(domainPrinciple.id, command));
    }
  };

  return (
    <Box>
      <Box mb={3} className={styles.domainSelectContainer}>
        {isSelectedDomainExist() && (
          <ReactHookFormSelect
            displayEmpty={true}
            variant={'standard'}
            name={'selectedDomainId'}
            control={control}
            options={[
              { value: '', text: t('Generic definitions') },
              ...domains.map(d => {
                const title = resolveTranslation({
                  en: d.title,
                  zh: d.titleZh,
                  es: d.titleEs,
                });

                return { value: d.id, text: title };
              }),
            ]}
            onChange={selectDomainHandler}
            wrapperProps={{
              width: { xs: 500 },
              maxWidth: '100%',
              mb: 0,
            }}
          />
        )}
      </Box>

      {!isLoaded && (
        <Box py={10}>
          <LoadingOverlay loading={true} backdropStyles={{ backgroundColor: 'transparent' }} />
        </Box>
      )}

      {isLoaded && (
        <EditableTable
          rowsArray={principlesForDomain}
          columnsToDisplayMap={{
            id: [{ propName: 'id' }],
            title: [
              { propName: 'title', label: 'en', editable: true },
              { propName: 'titleZh', label: 'cn', editable: true },
              { propName: 'titleEs', label: 'es', editable: true },
            ],
            description: [
              { propName: 'description', label: 'en', editable: true },
              { propName: 'descriptionZh', label: 'cn', editable: true },
              { propName: 'descriptionEs', label: 'es', editable: true },
            ],
          }}
          useQuillForProps={['description', 'descriptionZh', 'descriptionEs']}
          titlesArray={[
            { label: 'No.', sortParameter: 'id' },
            { label: 'Title', sortParameter: 'title' },
            { label: 'Description', sortParameter: 'description' },
          ]}
          columnsWidth={{
            id: '5%',
            title: '20%',
          }}
          onChange={onChangeHandler}
          rowHash={selectedDomainId}
          loadingIds={updatingIds}
        />
      )}
    </Box>
  );
};
