import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { LocalStorageKey, LocalStorageService } from '../local-storage';
import { Locales } from '../../models/locales';
import { environment } from '../../environments/environment';

const localeGetter = (): string => {
  try {
    return LocalStorageService.getItem(LocalStorageKey.Locale) || Locales.EN;
  } catch (e) {
    return Locales.EN;
  }
};

const init = () => {
  i18n
    .use(initReactI18next)
    .use(HttpApi)
    .init({
      supportedLngs: ['en', 'zh', 'es'],
      fallbackLng: false,
      lng: localeGetter().substr(0, 2),
      debug: false,
      returnEmptyString: false,
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: `${environment.apiDomain}/v1/locales/{{lng}}/{{ns}}.json`,
      },
    });
};

export const LocalizationService = {
  init,
};
