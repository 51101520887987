import React, { useEffect } from 'react';
import { AuthContainer } from '../auth-container/component';
import { AuthService } from '@priz/shared/src/services/auth';
import { useNavigate } from 'react-router-dom';
import { AuthTabsType } from '@priz/shared/src/components/security/auth-tabs/component';
import { AuthForm } from '../auth-form/component';

export const RequestPasswordReset: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (AuthService.isAuthenticated()) navigate('/');
  }, []);

  return (
    <AuthContainer contentBoxProps={{ justifyContent: 'center' }} title={'Recover your password'}>
      <AuthForm initialTab={AuthTabsType.PasswordReset} useNavigationTabs />
    </AuthContainer>
  );
};
