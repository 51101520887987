import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    'position': 'relative',

    '&._stretch': {
      'width': 'calc(100% + 10px)',
      'left': -5,

      '& $buttonWrap': {
        'flex': '1 0 auto',

        '& > *': {
          margin: '0 5px',
          width: 'calc(100% - 10px)',
        },
      },
    },
  },
  buttonWrap: {
    position: 'relative',
  },
});
