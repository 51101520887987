import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Idea } from '@priz/shared/src/models/idea/idea';
import { ProjectIdeaActions } from '../store';
import { ProjectAssigneeWithMember } from '@priz/shared/src/models/assignment';
import { UserAvatar } from '@priz/shared/src/components/user-avatar/component';
import { IdeaCard } from '@priz/shared/src/components/idea-card/component';
import { WinningButton } from '@priz/shared/src/components/winning-button/component';
import { IdeaStatusSelector } from '../idea-status-selector/component';
import { IdeaStatus } from '@priz/shared/src/models/idea';
import { UserContextSelectors } from '../../security/store/selectors';
import { Status } from '@priz/shared/src/components/status/component';
import { IdeaControls } from '../idea-controls/comonent';
import { ToolLinkResolver } from '../../tools/tool-link-resolver/component';
import { Grid } from '@mui/material';

export interface EditableIdeaCardProps {
  idea: Idea;
  projectId: number;
  disabled: boolean;
  assignee?: ProjectAssigneeWithMember;
}

export const EditableIdeaCard: React.FC<EditableIdeaCardProps> = ({ idea, assignee, projectId, disabled }) => {
  const dispatch = useDispatch();

  const { content, latestRankingScore, lastUpdated, dateCreated } = idea;

  const currentUser = useSelector(UserContextSelectors.getCurrentUser);

  const isAuthor = currentUser.id === idea.createdBy.id;

  const winningToggleHandle = () => {
    dispatch(ProjectIdeaActions.setAsWinning(idea, !idea.selectedAsWinning));
  };

  const statusSelectHandler = (status: IdeaStatus) => {
    dispatch(ProjectIdeaActions.update(projectId, idea.id, { status }));
  };

  const renderButtons = () => {
    return <IdeaControls idea={idea} disabled={disabled} />;
  };

  return (
    <IdeaCard
      content={content}
      rank={latestRankingScore}
      date={lastUpdated || dateCreated}
      dateFormat={'MMM d yyyy'}
      user={assignee?.user && <UserAvatar size={26} user={assignee.user} showName={true} />}
      controls={renderButtons()}
      footerContent={
        <Grid container alignItems={'center'} spacing={2}>
          <Grid item>
            {isAuthor ? (
              <IdeaStatusSelector status={idea.status} onSelect={statusSelectHandler} />
            ) : idea.status ? (
              <Status status={idea.status} />
            ) : undefined}
          </Grid>

          {idea.sourceToolUtilization && (
            <Grid item>
              <ToolLinkResolver utilizationId={idea.sourceToolUtilization.id} sourceId={idea.sourceId} />
            </Grid>
          )}
        </Grid>
      }
      icons={
        <WinningButton isActive={idea.selectedAsWinning} onClick={winningToggleHandle} disabled={disabled} mr={1} />
      }
    />
  );
};
