import React from 'react';
import { FortyPrinciplesUtilization } from '../../../models/tools/forty-principles/forty-principles-utilization';
import { Box, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { ContradictionTable } from '../../../components/contradiction-table/component';
import { ArrayUtils, SortDirection } from '../../../utils/common';
import { Principle } from '../../../models/tools/forty-principles/principle';
import { useStyles } from './styles';

interface FortyPrinciplesViewerProps {
  utilization: FortyPrinciplesUtilization;
  matchingPrinciples?: Principle[];
  showGeneratedIdeas?: boolean;
}

export const FortyPrinciplesViewer: React.FC<FortyPrinciplesViewerProps> = ({ utilization, matchingPrinciples }) => {
  const styles = useStyles();

  return (
    <Box>
      <Box mb={2}>
        <Typography variant={'body1'} component={'span'} className={styles.bold}>
          <Trans>Contradiction</Trans>:
        </Typography>
      </Box>

      <ContradictionTable utilization={utilization} />

      {utilization.contradictionNotes && (
        <>
          <Box mt={4} mb={2}>
            <Typography variant={'body1'} component={'span'} className={styles.bold}>
              <Trans>Contradiction notes</Trans>:
            </Typography>
          </Box>

          <Box>
            <Typography variant={'body1'} component={'span'}>
              {utilization.contradictionNotes}
            </Typography>
          </Box>
        </>
      )}

      {utilization.improvingParameterDescription && utilization.improvingParameter && (
        <>
          <Box mt={4} mb={2}>
            <Typography variant={'body1'} component={'span'} className={styles.bold}>
              <Trans>Improving parameter</Trans>
            </Typography>
          </Box>

          <div>
            <Typography variant={'body2'} component={'span'} className={styles.label}>
              <Trans>Description of what is improving</Trans>:
            </Typography>
          </div>

          <div>
            <Typography variant={'body1'} component={'span'}>
              {utilization.improvingParameterDescription}
            </Typography>
          </div>

          <Box mt={2}>
            <Typography variant={'body2'} component={'span'} className={styles.label}>
              <Trans>Selected improving parameter</Trans>:
            </Typography>
          </Box>

          <div>
            <Typography variant={'body1'} component={'span'}>
              {utilization.improvingParameter.title}
            </Typography>
          </div>
        </>
      )}

      {utilization.worseningParameterDescription && utilization.worseningParameter && (
        <>
          <Box mt={4} mb={2}>
            <Typography variant={'body1'} component={'span'} className={styles.bold}>
              <Trans>Worsening parameter</Trans>
            </Typography>
          </Box>

          <Box>
            <Typography variant={'body2'} component={'span'} className={styles.label}>
              <Trans>Description of what is worsening</Trans>:
            </Typography>
          </Box>

          <div>
            <Typography variant={'body1'} component={'span'}>
              {utilization.worseningParameterDescription}
            </Typography>
          </div>

          <Box mt={2}>
            <Typography variant={'body2'} component={'span'} className={styles.label}>
              <Trans>Selected worsening parameter</Trans>:
            </Typography>
          </Box>

          <div>
            <Typography variant={'body1'} component={'span'}>
              {utilization.worseningParameter.title}
            </Typography>
          </div>
        </>
      )}

      {!!matchingPrinciples?.length && (
        <>
          <Box mt={4} mb={2}>
            <Typography variant={'body1'} component={'span'} className={styles.bold}>
              <Trans>Matching principles</Trans>:
            </Typography>
          </Box>

          {matchingPrinciples.sort(ArrayUtils.sorterByParam('id', SortDirection.ASC)).map((item, key) => (
            <ListItem key={key} className={styles.listItem} disableGutters={true}>
              <ListItemIcon>
                <Typography variant={'body2'} component={'div'} className={styles.bold}>
                  {item.id}
                </Typography>
              </ListItemIcon>
              <ListItemText>
                <Typography variant={'body1'} component={'span'}>
                  {item.title}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </>
      )}
    </Box>
  );
};
