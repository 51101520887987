import axios, { AxiosResponse } from 'axios';
import { IChat } from '../store/model';

export interface ConversationCommand {
  chatId?: number;
  message: string;
}

export interface ChatUpdateCommand {
  name?: string;
  shared?: boolean;
}

const getChats = (projectId: number): Promise<IChat[]> =>
  axios.get(`/v1/api/<ws-id>/project/${projectId}/chats`).then((response: AxiosResponse<IChat[]>) => response.data);

const sendChatMessage = (projectId: number, command: ConversationCommand): Promise<IChat> =>
  axios
    .post(`/v1/api/<ws-id>/project/${projectId}/chat/message`, command)
    .then((response: AxiosResponse<IChat>) => response.data);

const getAnswer = (projectId: number, chatId: number): Promise<IChat> =>
  axios
    .get(`/v1/api/<ws-id>/project/${projectId}/chat/${chatId}/answer`)
    .then((response: AxiosResponse<IChat>) => response.data);

const createChat = (projectId: number): Promise<IChat> =>
  axios.post(`/v1/api/<ws-id>/project/${projectId}/chat`).then((response: AxiosResponse<IChat>) => response.data);

const updateChat = (projectId: number, chatId: number, command: ChatUpdateCommand): Promise<IChat> =>
  axios
    .put(`/v1/api/<ws-id>/project/${projectId}/chat/${chatId}`, command)
    .then((response: AxiosResponse<IChat>) => response.data);

const deleteChat = (projectId: number, chatId: number): Promise<IChat> =>
  axios
    .delete(`/v1/api/<ws-id>/project/${projectId}/chat/${chatId}`)
    .then((response: AxiosResponse<IChat>) => response.data);

export const ChatApi = {
  getChats,
  sendChatMessage,
  getAnswer,
  createChat,
  updateChat,
  deleteChat,
};
