import React, { CSSProperties } from 'react';
import { useStyles } from './styles';

interface ExcessiveIconProps {
  styleProps?: CSSProperties;
  onClick?: () => void;
}

export const ExcessiveIcon: React.FC<ExcessiveIconProps> = ({ styleProps, onClick, ...rest }) => {
  const styles = useStyles();

  return (
    <div
      className={`${styles.arrow} ${styles.excessive}`}
      onClick={() => {
        if (onClick) onClick();
      }}
      style={{
        ...styleProps,
      }}
      {...rest}
    />
  );
};
