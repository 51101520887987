import { User } from '@priz/shared/src/models/security/user';
import { UserAction, UserActionType } from '../actions/user.actions';
import { CurrentUser, DefaultCurrentUser } from '@priz/shared/src/models/user';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { handleActions } from 'redux-actions';
import { AuthService } from '@priz/shared/src/services/auth';

export const currentUserReducers = handleActions(
  {
    // Fetch

    [toStarted(UserActionType.Fetch)]: (state: CurrentUser): CurrentUser => ({
      ...state,
      loading: true,
    }),
    [toFailed(UserActionType.Fetch)]: (state: CurrentUser): CurrentUser => ({
      ...state,
      error: true,
      loading: false,
      loaded: false,
    }),
    [toSuccess(UserActionType.Fetch)]: (state: CurrentUser, action: UserAction): CurrentUser => ({
      ...state,
      loading: false,
      loaded: true,
      user: new User({
        ...state.user,
        ...action.payload,
        policiesAcceptedAt: action.payload.policiesAcceptedAt,
      }),
    }),

    // UpdateProfile

    [toStarted(UserActionType.UpdateProfile)]: (state: CurrentUser): CurrentUser => ({
      ...state,
      loading: true,
      profileUpdating: true,
    }),
    [toFailed(UserActionType.UpdateProfile)]: (state: CurrentUser): CurrentUser => ({
      ...state,
      error: false,
      loading: false,
      profileUpdating: false,
    }),
    [toSuccess(UserActionType.UpdateProfile)]: (state: CurrentUser, action: UserAction): CurrentUser => {
      const user = new User({ ...state.user, ...action.payload });

      AuthService.setUserInfo(user);

      return {
        ...state,
        loading: false,
        profileUpdating: false,
        user,
      };
    },

    // UpdatePassword

    [toStarted(UserActionType.UpdatePassword)]: (state: CurrentUser): CurrentUser => ({
      ...state,
      loading: true,
      passwordUpdating: true,
    }),
    [toFailed(UserActionType.UpdatePassword)]: (state: CurrentUser): CurrentUser => ({
      ...state,
      error: true,
      loading: false,
      passwordUpdating: false,
    }),
    [toSuccess(UserActionType.UpdatePassword)]: (state: CurrentUser): CurrentUser => ({
      ...state,
      loading: false,
      passwordUpdating: false,
    }),

    // UpdateSecuritySettings

    [toStarted(UserActionType.UpdateSecuritySettings)]: (state: CurrentUser): CurrentUser => ({
      ...state,
      securitySettingsUpdating: true,
      securitySettingsUpdated: false,
      error: false,
    }),
    [toFailed(UserActionType.UpdateSecuritySettings)]: (state: CurrentUser): CurrentUser => ({
      ...state,
      securitySettingsUpdating: false,
      securitySettingsUpdated: false,
      error: true,
    }),
    [toSuccess(UserActionType.UpdateSecuritySettings)]: (state: CurrentUser, action: UserAction): CurrentUser => ({
      ...state,
      loading: false,
      securitySettingsUpdating: false,
      securitySettingsUpdated: true,
      error: false,
      user: new User({
        ...state.user,
        ...action.payload,
      }),
    }),

    // DeleteConnection

    [toStarted(UserActionType.DeleteConnection)]: (state: CurrentUser, action: UserAction): CurrentUser => ({
      ...state,
      connectionsUpdating: true,
      connectionsUpdated: false,
      loadingConnection: action.meta.connectionType,
      error: false,
    }),
    [toFailed(UserActionType.DeleteConnection)]: (state: CurrentUser): CurrentUser => ({
      ...state,
      connectionsUpdating: false,
      connectionsUpdated: false,
      loadingConnection: undefined,
      error: true,
    }),
    [toSuccess(UserActionType.DeleteConnection)]: (state: CurrentUser, action: UserAction): CurrentUser => {
      const user = new User({
        ...state.user,
        ...action.payload,
        auth0Id: action.payload.auth0Id,
        googleId: action.payload.googleId,
        linkedinId: action.payload.linkedinId,
      });

      AuthService.setUserInfo(user);

      return {
        ...state,
        loading: false,
        connectionsUpdating: false,
        connectionsUpdated: true,
        loadingConnection: undefined,
        error: false,
        user,
      };
    },

    // UpdateAvatar

    [toStarted(UserActionType.UpdateAvatar)]: (state: CurrentUser): CurrentUser => ({
      ...state,
      loading: true,
      avatarUpdating: true,
    }),
    [toFailed(UserActionType.UpdateAvatar)]: (state: CurrentUser): CurrentUser => ({
      ...state,
      error: true,
      loading: false,
      avatarUpdating: false,
    }),
    [toSuccess(UserActionType.UpdateAvatar)]: (state: CurrentUser, action: UserAction): CurrentUser => ({
      ...state,
      loading: false,
      error: false,
      avatarUpdating: false,
      user: new User({
        ...state.user,
        ...action.payload,
      }),
    }),

    // RequestVerificationEmail

    [toStarted(UserActionType.RequestVerificationEmail)]: (state: CurrentUser): CurrentUser => ({
      ...state,
      loading: true,
      verificationEmailSending: true,
    }),
    [toFailed(UserActionType.RequestVerificationEmail)]: (state: CurrentUser): CurrentUser => ({
      ...state,
      error: true,
      loading: false,
      verificationEmailSending: false,
    }),
    [toSuccess(UserActionType.RequestVerificationEmail)]: (state: CurrentUser, action: UserAction): CurrentUser => ({
      ...state,
      loading: false,
      error: false,
      verificationEmailSending: false,
      user: new User({
        ...state.user,
        ...action.payload,
      }),
    }),

    // UpdatePrivacySettings

    [toStarted(UserActionType.UpdatePrivacySettings)]: (state: CurrentUser): CurrentUser => ({
      ...state,
      privacySettingsUpdating: true,
    }),
    [toFailed(UserActionType.UpdatePrivacySettings)]: (state: CurrentUser): CurrentUser => ({
      ...state,
      privacySettingsUpdating: false,
      error: true,
    }),
    [toSuccess(UserActionType.UpdatePrivacySettings)]: (state: CurrentUser, action: UserAction): CurrentUser => ({
      ...state,
      loading: false,
      error: false,
      privacySettingsUpdating: false,
      user: new User({
        ...state.user,
        ...action.payload,
      }),
    }),

    // UpdatePrivacySettings

    [toStarted(UserActionType.AcceptPolicies)]: (state: CurrentUser): CurrentUser => ({
      ...state,
      policiesUpdating: true,
    }),
    [toFailed(UserActionType.AcceptPolicies)]: (state: CurrentUser): CurrentUser => ({
      ...state,
      policiesUpdating: false,
      error: true,
    }),
    [toSuccess(UserActionType.AcceptPolicies)]: (state: CurrentUser, action: UserAction): CurrentUser => ({
      ...state,
      error: false,
      policiesUpdating: false,
      user: new User({
        ...state.user,
        ...action.payload,
      }),
    }),
  },
  DefaultCurrentUser,
);
