import React, { HTMLAttributes } from 'react';
import SimpleBar from 'simplebar-react';
import { Box, BoxProps } from '@mui/material';
import { useStyles } from './styles';

interface ScrollContainerProps {
  containerProps?: BoxProps;
  scrollbarProps?: SimpleBar.Props & HTMLAttributes<HTMLDivElement>;
}

export const ScrollContainer: React.FC<ScrollContainerProps> = ({ containerProps, scrollbarProps, children }) => {
  const styles = useStyles();
  const { className: containerClassName, ...restContainerProps } = containerProps || {};
  const { className: scrollbarClassName, ...restScrollbarProps } = scrollbarProps || {};

  const containerClassNames = [styles.container];
  const scrollbarClassNames = [styles.scrollbar];

  if (containerClassName) containerClassNames.push(containerClassName);
  if (scrollbarClassName) scrollbarClassNames.push(scrollbarClassName);

  return (
    <Box className={containerClassNames.join(' ')} {...restContainerProps}>
      <SimpleBar forceVisible="y" autoHide={false} className={scrollbarClassNames.join(' ')} {...restScrollbarProps}>
        {children}
      </SimpleBar>
    </Box>
  );
};
