import { put, takeLatest } from 'redux-saga/effects';
import { ActionSuffix } from '../../../../shared/store/action-suffix.enum';
import { PreloaderActions, PreloaderActionType } from '../actions';

const actions = [];

function* setActions({ type }) {
  const nameArray = type.split('/');
  const status = '/' + nameArray.pop();
  const actionName = nameArray.join('/');

  if (actionName !== PreloaderActionType.Show && actionName !== PreloaderActionType.Hide) {
    if (status === ActionSuffix.Started) {
      actions.push(actionName);
    }

    if (status === ActionSuffix.Success || status === ActionSuffix.Failed) {
      const idx = actions.findIndex(item => item === actionName);
      if (idx !== -1) actions.splice(idx, 1);
    }

    if (!actions.length) {
      yield put(
        // @ts-ignore
        PreloaderActions.hide(),
      );
    }
  }
}

export function* allActionsDoneSagas() {
  yield takeLatest('*', setActions);
}
