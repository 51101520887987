import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';

export const useStyles = makeStyles({
  container: {
    height: 'auto',
    maxHeight: '100%',
    width: '100%',
    position: 'relative',
  },
  title: {
    fontWeight: 600,
  },
  letter: {
    fontWeight: 600,
  },

  header: {
    backgroundColor: pgColorScheme.gray50,
  },
});
