import React, { useState } from 'react';
import { Box, BoxProps, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { Meeting } from '../store/model';
import { MeetingStatus } from '@priz/shared/src/models/meetings';
import { KeyboardDoubleArrowRightRounded, RemoveRedEyeOutlined, PeopleAltOutlined } from '@mui/icons-material';
import { MeetingViewer } from '../meeting-viewer/component';
import { useSelector } from 'react-redux';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Status } from '@priz/shared/src/components/status/component';
import { AttendeeSelectors } from '../store/selectors';
import { AttendingButton } from '../attending-button/component';

export interface MeetingCardCompactProps extends BoxProps {
  meeting: Meeting;
}

export const MeetingCardCompact: React.FC<MeetingCardCompactProps> = ({ meeting, ...rest }) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const attendees = useSelector(AttendeeSelectors.getAllByProjectIdAndMeetingId(meeting.project.id, meeting.id));

  const isLead = currentUser?.id === meeting.createdBy.id;

  const openViewer = () => {
    setIsViewerOpen(true);
  };

  const closeViewer = () => {
    setIsViewerOpen(false);
  };

  if (meeting.status === MeetingStatus.Draft && !isLead) return null;

  return (
    <Paper variant={'outlined'} component={Box} pl={1} pr={0.5} py={0.5} {...rest}>
      <Grid container spacing={1} wrap={'nowrap'} alignItems={'center'}>
        <Grid item>
          <PeopleAltOutlined fontSize={'medium'} sx={{ display: 'block', color: pgColorScheme.purple }} />
        </Grid>

        <Grid item xs={true} overflow={'hidden'}>
          <Typography variant={'subtitle2'} noWrap>
            {meeting.title}
          </Typography>
        </Grid>

        <Grid item>
          <Status status={meeting.status} variant={'chip'} containerChipPros={{ size: 'extra_small' }} showTooltip />
        </Grid>

        <Grid item display={'flex'} alignItems={'center'}>
          <AttendingButton meeting={meeting} attendees={attendees} />

          <Tooltip title={<Trans>View</Trans>} placement={'top'} arrow disableInteractive>
            <IconButton onClick={openViewer} size={'small'} color={'primary'}>
              <RemoveRedEyeOutlined fontSize={'small'} />
            </IconButton>
          </Tooltip>

          {isLead && (
            <Tooltip title={<Trans>Open in new tab</Trans>} placement={'top'} arrow disableInteractive>
              <IconButton
                component={Link}
                size={'small'}
                color={'primary'}
                to={['/project', meeting.project.id, 'meeting', meeting.id].join('/')}
                target={'_blank'}
              >
                <KeyboardDoubleArrowRightRounded fontSize={'small'} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      <MeetingViewer open={isViewerOpen} meeting={meeting} onClose={closeViewer} />
    </Paper>
  );
};
