import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  summaryCard: {
    height: '100%',
  },
  cardContent: {
    paddingTop: 0,
  },
});
