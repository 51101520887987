import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgendaActions, AttendeeActions, MeetingActions } from '../store/actions';
import { AgendaSelectors, AttendeeSelectors, MeetingSelectors } from '../store/selectors';
import { WorkspaceMembersActions } from '../../workspace/store/actions';
import { MeetingEditor } from '../meeting-editor/component';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { useNavigate } from 'react-router-dom';

export interface MeetingPageProps {
  projectId: number;
  meetingId: number;
}

export const MeetingPage: React.FC<MeetingPageProps> = ({ projectId, meetingId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const meeting = useSelector(MeetingSelectors.getById(meetingId));
  const attendees = useSelector(AttendeeSelectors.getAllByProjectIdAndMeetingId(projectId, meetingId));
  const agenda = useSelector(AgendaSelectors.getAllByProjectIdAndMeetingId(projectId, meetingId));

  useEffect(() => {
    dispatch(AgendaActions.getAll(projectId));
    dispatch(AttendeeActions.getAll(projectId));
    dispatch(MeetingActions.getAll(projectId));
    dispatch(WorkspaceMembersActions.loadAll());
  }, []);

  if (!meeting || !currentUser) return null;

  if (meeting && currentUser && currentUser.id !== meeting.createdBy.id) {
    navigate(['/project', projectId, 'meetings'].join('/'));
  }

  return <MeetingEditor meeting={meeting} attendeesList={attendees} agendaList={agenda} />;
};
