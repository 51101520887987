import React, { useState } from 'react';
import { MenuItem, MenuList, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';

interface SeatsQuantityPopperProps {
  value: number;
  onChange?: (value: number) => void;
}

export const SeatsQuantityPopper: React.FC<SeatsQuantityPopperProps> = ({ value, onChange, children }) => {
  const [open, setOpen] = useState(false);

  const changeHandler = (value: number) => {
    if (onChange) onChange(value);
    openStateChangeHandler(false);
  };

  const openStateChangeHandler = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  return (
    <PopperWrap
      open={open}
      containerProps={{ component: 'span' }}
      dropdownProps={{ p: 0, height: 250 }}
      onOpenToggle={openStateChangeHandler}
    >
      {children}

      <MenuList>
        {[...Array(100).keys()].map(key => {
          const number = key + 1;

          return (
            <MenuItem key={number} value={number} selected={value === number} onClick={() => changeHandler(number)}>
              <Typography noWrap>
                {number}&nbsp;<Trans>{number > 1 ? 'seats' : 'seat'}</Trans>
              </Typography>
            </MenuItem>
          );
        })}
      </MenuList>
    </PopperWrap>
  );
};
