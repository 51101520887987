import * as React from "react";

export const ReactComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="54" height="60" viewBox="0 0 54 60" fill="#08445E" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M41.9578 37.0586H38.4282C38.306 37.0586 38.1852 37.0319 38.0743 36.9803C37.9635 36.9287 37.8653 36.8535 37.7866 36.76C36.4015 35.0559 34.7262 33.61 32.8378 32.489L32.4058 32.2483C28.7899 30.2403 24.6892 27.9638 24.2572 22.6645C24.2338 22.2486 24.0861 21.8492 23.8333 21.5181C23.5804 21.187 23.234 20.9395 22.8388 20.8075L22.6317 20.7446C22.3542 20.6548 22.0605 20.6264 21.771 20.6613C21.4815 20.6962 21.203 20.7937 20.9548 20.9468C20.7067 21.1 20.4947 21.3052 20.3337 21.5484C20.1727 21.7915 20.0664 22.0667 20.0222 22.355C19.4074 26.2268 19.6549 31.2745 24.3193 33.3261C24.4951 33.4036 24.639 33.5392 24.7269 33.7101C24.8147 33.881 24.8412 34.077 24.8019 34.2651C24.7626 34.4532 24.6599 34.6221 24.511 34.7436C24.3621 34.865 24.1759 34.9317 23.9838 34.9323H19.9845C19.762 34.9323 19.5487 34.844 19.3914 34.6867C19.2341 34.5294 19.1457 34.316 19.1457 34.0936C19.1457 33.8711 19.2341 33.6578 19.3914 33.5005C19.5487 33.3432 19.762 33.2548 19.9845 33.2548H21.1588C18.5695 30.8878 17.5848 27.0362 18.369 22.0874C18.4511 21.5616 18.6459 21.0597 18.9401 20.6163C19.2343 20.1728 19.6209 19.7982 20.0734 19.5181C20.526 19.2381 21.0337 19.0592 21.5619 18.9938C22.09 18.9284 22.6261 18.978 23.1332 19.1392L23.3379 19.2021C24.0534 19.4322 24.6833 19.872 25.1459 20.4644C25.6085 21.0568 25.8824 21.7745 25.9322 22.5244C26.2937 26.9288 29.6597 28.7993 33.2245 30.7788L33.6598 31.0212C35.6162 32.1657 37.3602 33.6395 38.8149 35.3777H41.9578C42.1802 35.3777 42.3936 35.4661 42.5509 35.6234C42.7082 35.7807 42.7965 35.994 42.7965 36.2165C42.7965 36.4389 42.7082 36.6523 42.5509 36.8096C42.3936 36.9669 42.1802 37.0553 41.9578 37.0553V37.0586Z" />
    <path d="M36.9481 58.115H17.1942C16.9718 58.115 16.7584 58.0266 16.6011 57.8693C16.4438 57.712 16.3555 57.4987 16.3555 57.2762C16.3555 57.0537 16.4438 56.8404 16.6011 56.6831C16.7584 56.5258 16.9718 56.4374 17.1942 56.4374H36.9481C38.0206 56.4376 39.0868 56.2733 40.1094 55.9501L41.7031 55.4468C41.8082 55.4138 41.9188 55.4018 42.0285 55.4115C42.1382 55.4211 42.245 55.4523 42.3426 55.5033C42.4403 55.5542 42.527 55.6239 42.5977 55.7083C42.6685 55.7928 42.7219 55.8903 42.7549 55.9954C42.788 56.1005 42.8 56.211 42.7903 56.3208C42.7806 56.4305 42.7494 56.5372 42.6985 56.6349C42.6475 56.7326 42.5779 56.8193 42.4934 56.89C42.409 56.9608 42.3115 57.0142 42.2064 57.0472L40.6127 57.5505C39.4272 57.9248 38.1913 58.1152 36.9481 58.115Z" />
    <path d="M50.197 58.9664H43.6546C42.982 58.9655 42.3373 58.6979 41.8618 58.2222C41.3862 57.7465 41.1188 57.1017 41.1182 56.4291V35.8029C41.1188 35.1303 41.3862 34.4855 41.8618 34.0098C42.3373 33.5341 42.982 33.2665 43.6546 33.2656H50.197C50.8697 33.2663 51.5147 33.5338 51.9904 34.0095C52.4661 34.4852 52.7336 35.1302 52.7343 35.8029V56.4291C52.7336 57.1018 52.4661 57.7468 51.9904 58.2225C51.5147 58.6982 50.8697 58.9657 50.197 58.9664ZM43.6546 34.9432C43.4268 34.9436 43.2085 35.0344 43.0475 35.1955C42.8865 35.3567 42.7959 35.5751 42.7957 35.8029V56.4291C42.7959 56.6569 42.8865 56.8753 43.0475 57.0365C43.2085 57.1976 43.4268 57.2884 43.6546 57.2888H50.197C50.425 57.2886 50.6435 57.198 50.8047 57.0368C50.9659 56.8756 51.0565 56.657 51.0568 56.4291V35.8029C51.0565 35.575 50.9659 35.3564 50.8047 35.1952C50.6435 35.034 50.425 34.9434 50.197 34.9432H43.6546Z" />
    <path d="M19.5505 40.7365H10.2326C9.25163 40.7218 8.31577 40.3218 7.62723 39.6229C6.93869 38.9239 6.55273 37.9822 6.55273 37.0011C6.55273 36.02 6.93869 35.0782 7.62723 34.3793C8.31577 33.6804 9.25163 33.2803 10.2326 33.2656H19.5514C20.5324 33.2803 21.4682 33.6804 22.1568 34.3793C22.8453 35.0782 23.2313 36.02 23.2313 37.0011C23.2313 37.9822 22.8453 38.9239 22.1568 39.6229C21.4682 40.3218 20.5324 40.7218 19.5514 40.7365H19.5505ZM10.2326 34.9432C9.69404 34.954 9.18117 35.1756 8.8041 35.5604C8.42704 35.9451 8.21585 36.4624 8.21585 37.0011C8.21585 37.5398 8.42704 38.057 8.8041 38.4418C9.18117 38.8265 9.69404 39.0481 10.2326 39.059H19.5514C20.09 39.0481 20.6028 38.8265 20.9799 38.4418C21.357 38.057 21.5682 37.5398 21.5682 37.0011C21.5682 36.4624 21.357 35.9451 20.9799 35.5604C20.6028 35.1756 20.09 34.954 19.5514 34.9432H10.2326Z" />
    <path d="M20.5666 46.5299H11.5515C10.5705 46.5152 9.63462 46.1152 8.94608 45.4163C8.25754 44.7173 7.87158 43.7756 7.87158 42.7945C7.87158 41.8134 8.25754 40.8716 8.94608 40.1727C9.63462 39.4737 10.5705 39.0737 11.5515 39.059H20.5666C21.0618 39.0516 21.5536 39.1427 22.0133 39.3271C22.4731 39.5115 22.8915 39.7855 23.2444 40.1331C23.5972 40.4807 23.8774 40.895 24.0687 41.3519C24.2599 41.8088 24.3584 42.2992 24.3584 42.7945C24.3584 43.2898 24.2599 43.7802 24.0687 44.2371C23.8774 44.694 23.5972 45.1083 23.2444 45.4559C22.8915 45.8035 22.4731 46.0774 22.0133 46.2618C21.5536 46.4462 21.0618 46.5374 20.5666 46.5299ZM11.5515 40.7366C11.0129 40.7474 10.5 40.969 10.123 41.3538C9.74589 41.7385 9.53469 42.2558 9.53469 42.7945C9.53469 43.3332 9.74589 43.8504 10.123 44.2352C10.5 44.6199 11.0129 44.8415 11.5515 44.8524H20.5666C20.8403 44.8579 21.1124 44.8088 21.3669 44.7078C21.6215 44.6069 21.8533 44.4562 22.0488 44.2646C22.2444 44.0729 22.3997 43.8442 22.5058 43.5918C22.6118 43.3393 22.6665 43.0683 22.6665 42.7945C22.6665 42.5207 22.6118 42.2496 22.5058 41.9972C22.3997 41.7448 22.2444 41.516 22.0488 41.3244C21.8533 41.1328 21.6215 40.982 21.3669 40.8811C21.1124 40.7802 20.8403 40.731 20.5666 40.7366H11.5515Z" />
    <path d="M21.1578 52.3239H12.7801C12.2849 52.3313 11.7931 52.2401 11.3334 52.0558C10.8737 51.8714 10.4552 51.5974 10.1024 51.2498C9.74951 50.9022 9.4693 50.4879 9.27804 50.031C9.08678 49.5741 8.98828 49.0837 8.98828 48.5884C8.98828 48.0931 9.08678 47.6027 9.27804 47.1458C9.4693 46.6889 9.74951 46.2746 10.1024 45.927C10.4552 45.5794 10.8737 45.3055 11.3334 45.1211C11.7931 44.9367 12.2849 44.8455 12.7801 44.853H21.1578C21.653 44.8455 22.1448 44.9367 22.6045 45.1211C23.0642 45.3055 23.4827 45.5794 23.8356 45.927C24.1884 46.2746 24.4686 46.6889 24.6599 47.1458C24.8511 47.6027 24.9496 48.0931 24.9496 48.5884C24.9496 49.0837 24.8511 49.5741 24.6599 50.031C24.4686 50.4879 24.1884 50.9022 23.8356 51.2498C23.4827 51.5974 23.0642 51.8714 22.6045 52.0558C22.1448 52.2401 21.653 52.3313 21.1578 52.3239ZM12.7801 46.5305C12.5064 46.525 12.2343 46.5741 11.9798 46.675C11.7253 46.776 11.4934 46.9267 11.2979 47.1183C11.1023 47.31 10.947 47.5387 10.8409 47.7911C10.7349 48.0436 10.6802 48.3146 10.6802 48.5884C10.6802 48.8622 10.7349 49.1333 10.8409 49.3857C10.947 49.6381 11.1023 49.8668 11.2979 50.0585C11.4934 50.2501 11.7253 50.4008 11.9798 50.5018C12.2343 50.6027 12.5064 50.6519 12.7801 50.6463H21.1578C21.4315 50.6519 21.7036 50.6027 21.9581 50.5018C22.2126 50.4008 22.4445 50.2501 22.64 50.0585C22.8356 49.8668 22.9909 49.6381 23.097 49.3857C23.203 49.1333 23.2577 48.8622 23.2577 48.5884C23.2577 48.3146 23.203 48.0436 23.097 47.7911C22.9909 47.5387 22.8356 47.31 22.64 47.1183C22.4445 46.9267 22.2126 46.776 21.9581 46.675C21.7036 46.5741 21.4315 46.525 21.1578 46.5305H12.7801Z" />
    <path d="M21.568 58.1168H14.3856C13.8903 58.1243 13.3986 58.0331 12.9388 57.8487C12.4791 57.6643 12.0607 57.3904 11.7078 57.0428C11.355 56.6952 11.0748 56.2809 10.8835 55.824C10.6922 55.3671 10.5938 54.8767 10.5938 54.3814C10.5938 53.8861 10.6922 53.3957 10.8835 52.9388C11.0748 52.4819 11.355 52.0676 11.7078 51.72C12.0607 51.3724 12.4791 51.0984 12.9388 50.914C13.3986 50.7296 13.8903 50.6385 14.3856 50.6459H21.568C22.549 50.6606 23.4849 51.0606 24.1734 51.7596C24.8619 52.4585 25.2479 53.4003 25.2479 54.3814C25.2479 55.3625 24.8619 56.3042 24.1734 57.0032C23.4849 57.7021 22.549 58.1021 21.568 58.1168ZM14.3856 52.3235C14.1119 52.3179 13.8398 52.3671 13.5852 52.468C13.3307 52.569 13.0989 52.7197 12.9034 52.9113C12.7078 53.1029 12.5525 53.3317 12.4464 53.5841C12.3403 53.8365 12.2857 54.1076 12.2857 54.3814C12.2857 54.6552 12.3403 54.9262 12.4464 55.1787C12.5525 55.4311 12.7078 55.6598 12.9034 55.8515C13.0989 56.0431 13.3307 56.1938 13.5852 56.2947C13.8398 56.3957 14.1119 56.4448 14.3856 56.4393H21.568C22.1066 56.4284 22.6195 56.2068 22.9965 55.8221C23.3736 55.4373 23.5848 54.9201 23.5848 54.3814C23.5848 53.8427 23.3736 53.3254 22.9965 52.9407C22.6195 52.5559 22.1066 52.3343 21.568 52.3235H14.3856Z" />
    <path d="M4.34058 17.7713C4.21848 17.7712 4.09787 17.7445 3.98717 17.6929C3.87647 17.6414 3.77835 17.5663 3.69967 17.473C3.62099 17.3796 3.56365 17.2702 3.53165 17.1523C3.49965 17.0345 3.49376 16.9111 3.51439 16.7908L4.22399 12.6531L1.21784 9.72243C1.10716 9.61302 1.02911 9.47497 0.992406 9.32373C0.955705 9.17249 0.961806 9.01401 1.01003 8.86604C1.05825 8.71807 1.14669 8.58643 1.26546 8.48585C1.38422 8.38527 1.52863 8.31972 1.68252 8.29653L5.83695 7.69261L7.69482 3.92821C7.77184 3.79723 7.88174 3.68864 8.01363 3.61319C8.14552 3.53774 8.29483 3.49805 8.44678 3.49805C8.59873 3.49805 8.74804 3.53774 8.87993 3.61319C9.01182 3.68864 9.12172 3.79723 9.19873 3.92821L11.0574 7.69261L15.2119 8.29653C15.3667 8.31901 15.5121 8.38435 15.6318 8.48515C15.7514 8.58595 15.8405 8.7182 15.8889 8.86696C15.9373 9.01572 15.9432 9.17506 15.9058 9.32697C15.8684 9.47889 15.7893 9.61732 15.6774 9.72663L12.6712 12.6573L13.3808 16.7949C13.4067 16.9492 13.389 17.1076 13.3297 17.2522C13.2703 17.3969 13.1717 17.5221 13.0449 17.6137C12.9182 17.7053 12.7684 17.7597 12.6124 17.7706C12.4564 17.7815 12.3004 17.7487 12.1621 17.6757L8.44552 15.7213L4.72977 17.6757C4.60953 17.7381 4.47608 17.7709 4.34058 17.7713ZM8.45055 13.9356C8.58639 13.9354 8.72023 13.9682 8.84058 14.0312L11.4408 15.3992L10.9434 12.5021C10.9204 12.3681 10.9305 12.2305 10.9725 12.1011C11.0146 11.9718 11.0875 11.8547 11.1849 11.7598L13.2894 9.70818L10.3806 9.28879C10.246 9.26903 10.1183 9.21687 10.0085 9.1368C9.89859 9.05673 9.80982 8.95114 9.74981 8.82914L8.4472 6.18953L7.14627 8.82579C7.08618 8.9479 6.99728 9.05354 6.88724 9.13362C6.77721 9.2137 6.64934 9.26581 6.51467 9.28544L3.60666 9.70818L5.71197 11.7598C5.80925 11.8548 5.88198 11.972 5.92392 12.1013C5.96585 12.2306 5.97573 12.3681 5.9527 12.5021L5.45195 15.3992L8.05214 14.0312C8.17399 13.9674 8.30966 13.9346 8.4472 13.9356H8.45055Z" />
    <path d="M46.8036 17.7715C46.6675 17.7714 46.5334 17.7386 46.4127 17.6758L42.6936 15.7215L38.9778 17.6758C38.8393 17.7487 38.683 17.7814 38.5268 17.7701C38.3707 17.7588 38.2208 17.704 38.0941 17.6119C37.9674 17.5198 37.8691 17.3941 37.8102 17.249C37.7513 17.1039 37.7342 16.9452 37.7608 16.7909L38.4704 12.6533L35.4634 9.72346C35.3512 9.61418 35.2719 9.47568 35.2344 9.32365C35.1969 9.17161 35.2027 9.01211 35.2511 8.8632C35.2996 8.71428 35.3887 8.58191 35.5085 8.48105C35.6283 8.38019 35.7739 8.31488 35.9289 8.29251L40.0833 7.6886L41.9412 3.9242C42.0183 3.79323 42.1283 3.68467 42.2603 3.60924C42.3922 3.53382 42.5416 3.49414 42.6936 3.49414C42.8456 3.49414 42.9949 3.53382 43.1269 3.60924C43.2589 3.68467 43.3688 3.79323 43.446 3.9242L45.3038 7.6886L49.4583 8.29251C49.6133 8.31488 49.7589 8.38019 49.8787 8.48105C49.9985 8.58191 50.0876 8.71428 50.1361 8.8632C50.1845 9.01211 50.1903 9.17161 50.1528 9.32365C50.1153 9.47568 50.0359 9.61418 49.9238 9.72346L46.9168 12.6533L47.6264 16.7909C47.647 16.9113 47.6411 17.0347 47.6091 17.1525C47.5771 17.2703 47.5198 17.3798 47.4411 17.4731C47.3624 17.5665 47.2643 17.6416 47.1536 17.6931C47.0429 17.7446 46.9223 17.7714 46.8002 17.7715H46.8036ZM42.6936 13.9358C42.8294 13.9356 42.9633 13.9684 43.0836 14.0314L45.6838 15.3994L45.1873 12.5023C45.1642 12.3683 45.1741 12.2308 45.216 12.1014C45.258 11.9721 45.3307 11.8549 45.428 11.76L47.5341 9.70836L44.6244 9.28897C44.4898 9.26934 44.3619 9.21724 44.2519 9.13716C44.1418 9.05708 44.0529 8.95143 43.9928 8.82933L42.6936 6.19056L41.3927 8.82597C41.3326 8.94808 41.2437 9.05373 41.1336 9.13381C41.0236 9.21389 40.8957 9.26599 40.7611 9.28562L37.8522 9.705L39.9575 11.7566C40.0548 11.8516 40.1275 11.9688 40.1695 12.0981C40.2114 12.2274 40.2213 12.365 40.1983 12.4989L39.7017 15.3961L42.3019 14.028C42.423 13.9659 42.5575 13.9343 42.6936 13.9358Z" />
    <path d="M29.6774 14.5676C29.5416 14.5675 29.4078 14.5347 29.2874 14.472L25.5716 12.5185L21.8551 14.472C21.7165 14.5448 21.5604 14.5774 21.4043 14.5662C21.2482 14.5549 21.0984 14.5002 20.9718 14.4082C20.8451 14.3162 20.7468 14.1907 20.6878 14.0457C20.6288 13.9008 20.6116 13.7422 20.638 13.5879L21.3476 9.44944L18.3414 6.51962C18.2293 6.4104 18.15 6.27197 18.1124 6.11999C18.0748 5.96802 18.0805 5.80857 18.1289 5.65968C18.1773 5.51079 18.2663 5.3784 18.386 5.27749C18.5057 5.17658 18.6512 5.11118 18.8061 5.08868L22.9614 4.48476L24.8193 0.720364C24.8965 0.589591 25.0064 0.481207 25.1383 0.405912C25.2701 0.330618 25.4194 0.291016 25.5712 0.291016C25.7231 0.291016 25.8723 0.330618 26.0042 0.405912C26.136 0.481207 26.246 0.589591 26.3232 0.720364L28.1827 4.48476L32.338 5.08868C32.4928 5.11129 32.6381 5.17672 32.7576 5.27758C32.8772 5.37843 32.9661 5.51069 33.0144 5.65943C33.0628 5.80817 33.0685 5.96746 33.0311 6.11931C32.9937 6.27116 32.9146 6.40952 32.8027 6.51878L29.7965 9.44944L30.5061 13.5879C30.5266 13.7083 30.5206 13.8317 30.4885 13.9495C30.4564 14.0672 30.399 14.1766 30.3202 14.2699C30.2415 14.3632 30.1433 14.4382 30.0326 14.4896C29.9218 14.541 29.8012 14.5676 29.6791 14.5676H29.6774ZM25.5675 10.7319C25.7033 10.732 25.837 10.7648 25.9575 10.8275L28.5577 12.1956L28.0611 9.29846C28.0381 9.16449 28.048 9.02692 28.0899 8.89761C28.1318 8.76829 28.2046 8.65111 28.3018 8.55615L30.4072 6.50452L27.4975 6.08514C27.3628 6.06551 27.2349 6.0134 27.1249 5.93333C27.0149 5.85325 26.926 5.7476 26.8659 5.62549L25.5658 2.99008L24.2707 5.62214C24.2106 5.74424 24.1217 5.84989 24.0117 5.92997C23.9017 6.01005 23.7738 6.06215 23.6391 6.08178L20.7294 6.50117L22.8347 8.5528C22.9322 8.64768 23.0051 8.76482 23.0471 8.89414C23.0892 9.02345 23.0992 9.16106 23.0763 9.29511L22.5789 12.1922L25.1791 10.8242C25.3006 10.7623 25.4353 10.7306 25.5716 10.7319H25.5675Z" />
  </svg>
);
