import { AuditableEntity, IAuditableEntity } from '../common/auditable-entity';
import { IdRef } from '../common/id-ref';

export enum PartnerProfileStatus {
  Active = 'Active',
  Disabled = 'Disabled',
}

export interface IPartnerProfile extends IAuditableEntity {
  id: number;
  referralCode: string;
  commissionRate: number;
  totalProfit?: number;
  pendingPayout?: number;
  status: PartnerProfileStatus;
  user: IdRef;
  name?: string;
  email?: string;
}

export class PartnerProfile extends AuditableEntity implements IPartnerProfile {
  public id: number;
  public referralCode: string;
  public commissionRate: number;
  public totalProfit?: number;
  public pendingPayout?: number;
  public status: PartnerProfileStatus;
  public user: IdRef;
  public name?: string;
  public email?: string;

  constructor(json: IPartnerProfile) {
    super(json);
    this.id = json.id;
    this.referralCode = json.referralCode;
    this.commissionRate = json.commissionRate;
    this.totalProfit = json.totalProfit;
    this.pendingPayout = json.pendingPayout;
    this.status = json.status;
    this.user = json.user;
    this.name = json.name;
    this.email = json.email;
  }
}
