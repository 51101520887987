import { ApprovalActivity } from '../store/model';
import axios, { AxiosResponse } from 'axios';

const getAllByProjectId = (projectId: number): Promise<any> =>
  axios.get(`/v1/api/project/${projectId}/approval-activities`).then((response: AxiosResponse) => response.data);

const create = (projectId: number, activity: ApprovalActivity): Promise<any> =>
  axios
    .post(`/v1/api/project/${projectId}/approval-activities`, {
      action: activity.action,
      comment: activity.comment,
      status: activity.status,
    })
    .then((response: AxiosResponse) => response.data);

const update = (projectId: number, activity: ApprovalActivity): Promise<any> =>
  axios
    .put(`/v1/api/project/${projectId}/approval-activities/${activity.id}`, {
      action: activity.action,
      comment: activity.comment,
      status: activity.status,
    })
    .then((response: AxiosResponse) => response.data);

const deleteApproval = (projectId: number, activityId: number): Promise<any> =>
  axios
    .delete(`/v1/api/project/${projectId}/approval-activities/${activityId}`)
    .then((response: AxiosResponse) => response.data);

export const ApprovalActivityService = {
  getAllByProjectId,
  create,
  update,
  deleteApproval,
};
