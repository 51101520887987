export enum SubscriptionStatus {
  Active = 'active',
  Unpaid = 'unpaid',
  Canceled = 'canceled',

  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  Trialing = 'trialing',
  PastDue = 'past_due',
}
