import { createSelector, Selector } from 'reselect';
import { NotificationState } from '../model/notification-state';
import { AppState } from '../../../../store/app.state';

const notificationsStateSelector = (state: AppState) => state.notifications;

const getLast: Selector<AppState, NotificationState> = createSelector(
  notificationsStateSelector,
  notifications => notifications,
);

export const NotificationSelectors = {
  getLast,
};
