export enum AnalyticsEvent {
  USER_AUTHENTICATED = 'User Authenticated',

  USER_DETAILED_UPDATED = 'user Detailed Updated',
  USER_PASSWORD_UPDATED = 'user Password Updated',
  USER_AVATAR_UPDATED = 'user Avatar Updated',

  PROJECT_CREATED = 'Project Created',
  PROJECT_STARTED = 'Project Started',
  PROJECT_UPDATED = 'Project Updated',
  PROJECT_DELETED = 'Project Deleted',
  PROJECT_STATUS_UPDATED = 'Project Status Updated',
  PROJECT_OPEN_UPDATED = 'Project Open Updated',
  PROJECT_CHANGES_PUBLISHED = 'Project Changes Published',
  PROBLEM_STATEMENT_UPDATED = 'Problem Statement Updated',
  SOLUTION_UPDATED = 'Solution Updated',

  TOOL_USED = 'Tool Used',
  TOOL_UPDATED = 'Tool Updated',
  TOOL_DELETED = 'Tool Deleted/Canceled',

  IDEA_GENERATED = 'Idea Generated',
  IDEA_UPDATED = 'Idea Updated',
  IDEA_DELETED = 'Idea Deleted',
  IDEA_WINNING = 'Idea Selected As Winning',

  IDEA_COMMENT_CREATED = 'Idea Comment Created',
  IDEA_COMMENT_UPDATED = 'Idea Comment Updated',
  IDEA_COMMENT_DELETED = 'Idea Comment Deleted',

  TASK_CREATED = 'Task Created',
  TASK_UPDATED = 'Task Updated',
  TASK_STATUS_UPDATED = 'Task Status Updated',
  TASK_DUE_DATE_UPDATED = 'Task Due Date Updated',
  TASK_DELETED = 'Task Deleted',
  TASK_ASSIGNEE_CREATED = 'Task Assignee created',
  TASK_ASSIGNEE_DELETED = 'Task Assignee deleted',

  EBS_IDEA_CREATED = 'EBS - Idea Created',
  EBS_IDEA_UPDATED = 'EBS - Idea Updated',
  EBS_IDEAS_UPDATED = 'EBS - Ideas Updated',
  EBS_IDEA_DELETED = 'EBS - Idea deleted',

  WORKSPACE_CREATED = 'Workspace Created',
  WORKSPACE_UPDATED = 'Workspace Updated',
  WORKSPACE_COMPLETED = 'Workspace Completed',
  WORKSPACE_PLAN_UPDATED = 'Workspace Plan Updated',
  WORKSPACE_CANCEL_SUBSCRIPTION = 'Workspace Cancel Subscription',
  WORKSPACE_DELETED = 'Workspace Deleted',
  WORKSPACE_SEATS_UPDATED = 'Workspace Seats Updated',

  WORKSPACE_MEMBER_INVITED = 'Workspace Member Invited',
  WORKSPACE_MEMBER_REMOVED = 'Workspace Member Removed',
  WORKSPACE_MEMBER_LEVEL_UPDATED = 'Workspace Member Level Updated',
  WORKSPACE_INVITATION_ACCEPTED = 'Workspace Invitation Accepted',

  PROJECT_ASSIGNEE_CREATED = 'Project Assignee Created',
  PROJECT_ASSIGNEE_DELETED = 'Project Assignee Deleted',

  OVERVIEW_ATTACHMENT_CREATED = 'Overview Attachment Created',
  SOLUTION_ATTACHMENT_CREATED = 'Solution Attachment Created',
  TASK_ATTACHMENT_CREATED = 'Task Attachment Created',
  ATTACHMENT_UPDATED = 'Attachment Updated',
  ATTACHMENT_DELETED = 'Attachment Deleted',

  MEETING_CREATED = 'Meeting Created',
  MEETING_UPDATED = 'Meeting Updated',
  MEETING_RELEASED = 'Meeting Released',
  MEETING_STARTED = 'Meeting Started',
  MEETING_FINISHED = 'Meeting Finished',
  MEETING_DELETED = 'Meeting Deleted',

  ATTENDEE_CREATED = 'Attendee Created',
  ATTENDEE_UPDATED = 'Attendee Updated',
  ATTENDEE_DELETED = 'Attendee Deleted',

  AGENDA_CREATED = 'Agenda Created',
  AGENDA_UPDATED = 'Agenda Updated',
  AGENDA_DELETED = 'Agenda Deleted',

  ASSISTANCE_ASKED = 'ASSISTANCE_ASKED',

  CHAT_MESSAGE_SEND = 'CHAT_MESSAGE_SEND',
  CHAT_CREATED = 'CHAT_CREATED',
  CHAT_UPDATED = 'CHAT_UPDATED',
  CHAT_DELETED = 'CHAT_DELETED',
  CHAT_RETRIED = 'CHAT_RETRIED',

  PLAN_UPGRADE_OPEN = 'PLAN_UPGRADE_OPEN',

  API_KEY_CREATED = 'API_KEY_CREATED',
  API_KEY_TITLE_UPDATE = 'API_KEY_TITLE_UPDATE',
  API_KEY_INVALIDATED = 'API_KEY_INVALIDATED',

  VIDEO_HELPER_STARTED = 'VideoHelper - video started',
}
