import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const StarIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon width={18} height={18} viewBox="0 0 18 18" {...props}>
      <svg width="18" height="17" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 6.3893L11.7703 5.50199L9 0L6.22969 5.50199L0 6.3893L4.509 10.6974L3.44827 16.7592L9 13.8984L14.5517 16.7592L13.491 10.6974L18 6.3893ZM9 12.3139L5.32462 14.2079L6.0261 10.1989L3.0702 7.37469L7.15676 6.79268L9 3.13189L10.8432 6.79268L14.9298 7.37469L11.9739 10.1989L12.6753 14.2079L9 12.3139Z" />
      </svg>
    </SvgIcon>
  );
};
