import axios, { AxiosResponse } from 'axios';
import {
  EbsIdea,
  EbsIdeaDifficultyVariant,
  EbsUtilization,
  IEbsIdea,
  IEbsUtilization,
} from '@priz/shared/src/models/tools/ebs';

const update = (utilization: EbsUtilization): Promise<IEbsUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/ebs/${utilization.id}`, utilization)
    .then((response: AxiosResponse<IEbsUtilization>) => response.data);

const doDelete = (utilizationId: number): Promise<any> =>
  axios.delete(`/v1/api/<ws-id>/team/<t-id>/ebs/${utilizationId}`);

const getAll = (utilizationId: number): Promise<IEbsIdea[]> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/ebs/${utilizationId}/ideas`)
    .then((response: AxiosResponse<IEbsIdea[]>) => response.data);

const createIdea = (
  utilizationId: number,
  description: string,
  nodeId: string,
  x?: number,
  y?: number,
  validation?: EbsIdeaDifficultyVariant,
  implementation?: EbsIdeaDifficultyVariant,
): Promise<IEbsIdea> =>
  axios
    .post(`/v1/api/<ws-id>/team/<t-id>/ebs/${utilizationId}/idea`, {
      description,
      nodeId,
      x,
      y,
      validation,
      implementation,
    })
    .then((response: AxiosResponse<IEbsIdea>) => response.data);

const updateIdea = (
  utilizationId: number,
  ideaId: number,
  description: string,
  x?: number,
  y?: number,
  validation?: EbsIdeaDifficultyVariant,
  implementation?: EbsIdeaDifficultyVariant,
  resetLatestRankingScore?: boolean,
): Promise<EbsIdea> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/ebs/${utilizationId}/idea/${ideaId}`, {
      description,
      x,
      y,
      validation,
      implementation,
      resetLatestRankingScore,
    })
    .then((response: AxiosResponse<IEbsIdea>) => response.data);

const updateIdeas = (utilizationId: number, ideas: EbsIdea[]): Promise<EbsIdea[]> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/ebs/${utilizationId}/ideas`, { ideas })
    .then((response: AxiosResponse<IEbsIdea[]>) => response.data);

const deleteIdea = (utilizationId: number, ideaId: number): Promise<any> =>
  axios.delete(`/v1/api/<ws-id>/team/<t-id>/ebs/${utilizationId}/idea/${ideaId}`);

export const EbsUtilizationApi = {
  update,
  createIdea,
  delete: doDelete,
  deleteIdea,
  getAll,
  updateIdea,
  updateIdeas,
};
