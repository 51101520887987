import React from 'react';
import { Box, BoxProps, Paper, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { PgProgressBar } from '../PgProgressBar';

// import { Circle } from '@mui/icons-material';

interface ProgressBarWithLegendProps extends BoxProps {
  value?: number;
  label?: string;
  greenValue?: number;
  blueValue?: number;
}

export const ProgressBarWithLegend: React.FC<ProgressBarWithLegendProps> = ({
  value,
  label,
  greenValue = 70,
  blueValue = 30,
  ...rest
}) => {
  if (typeof value === 'undefined') return null;

  return (
    <Paper component={Box} variant={'outlined'} p={2} {...rest}>
      <Box mb={1}>
        <PgProgressBar
          value={value}
          color={value >= greenValue ? 'success' : value >= blueValue ? 'primary' : 'error'}
          variant={'determinate'}
        />
      </Box>

      {label && (
        <Typography>
          <Trans>{label}</Trans>: <b>{value}%</b>
        </Typography>
      )}

      {/*<Box mt={1}>*/}
      {/*  {[*/}
      {/*    {*/}
      {/*      value: greenValue,*/}
      {/*      label: `${greenValue} - 100`,*/}
      {/*    },*/}
      {/*    {*/}
      {/*      value: blueValue,*/}
      {/*      label: `${blueValue} - ${greenValue - 1}`,*/}
      {/*    },*/}
      {/*    {*/}
      {/*      value: 0,*/}
      {/*      label: `0 - ${blueValue - 1}`,*/}
      {/*    },*/}
      {/*  ].map((row, key) => (*/}
      {/*    <Grid key={key} container spacing={1} alignItems={'center'}>*/}
      {/*      <Grid item xs={'auto'}>*/}
      {/*        <Circle*/}
      {/*          sx={{ display: 'block', fontSize: 14 }}*/}
      {/*          color={row.value >= greenValue ? 'success' : row.value >= blueValue ? 'primary' : 'error'}*/}
      {/*        />*/}
      {/*      </Grid>*/}

      {/*      <Grid item xs={true}>*/}
      {/*        {row.label}*/}
      {/*      </Grid>*/}
      {/*    </Grid>*/}
      {/*  ))}*/}
      {/*</Box>*/}
    </Paper>
  );
};
