import { TitleContainer } from '../../../react/modules/title-container/component';
import { PageTitleWithDocLink } from '../../../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import React from 'react';
import { useStyles } from './styles';
import { Button } from '@mui/material';

export const ReportHeader: React.FC = () => {
  const styles = useStyles();

  const printClickHandler = () => {
    window.print();
  };

  return (
    <div className={styles.root}>
      <TitleContainer>
        <PageTitleWithDocLink title={<Trans>Project report</Trans>} />

        <ButtonsWrap>
          <Button variant={'pg_rounded'} color={'pg_orange_solid'} onClick={printClickHandler}>
            <Trans>Print</Trans>
          </Button>
        </ButtonsWrap>
      </TitleContainer>
    </div>
  );
};
