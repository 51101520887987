import axios, { AxiosResponse } from 'axios';
import { IIdeaComment } from '@priz/shared/src/models/idea';

const getAllIdeasCommentsForProject = (projectId: number): Promise<IIdeaComment[]> =>
  axios
    .get(`/v1/api/<ws-id>/project/${projectId}/ideas/comments`)
    .then((response: AxiosResponse<IIdeaComment[]>) => response.data);

const getIdeaComments = (projectId: number, ideaId: number): Promise<IIdeaComment[]> =>
  axios
    .get(`/v1/api/<ws-id>/project/${projectId}/idea/${ideaId}/comments`)
    .then((response: AxiosResponse<IIdeaComment[]>) => response.data);

const getIdeaComment = (projectId: number, ideaId: number, commentId: number): Promise<IIdeaComment> =>
  axios
    .get(`/v1/api/<ws-id>/project/${projectId}/idea/${ideaId}/comment/${commentId}`)
    .then((response: AxiosResponse<IIdeaComment>) => response.data);

export interface CreateIdeaCommentCommand {
  gainDescription: string;
  gainValue: number;

  costDescription: string;
  costValue: number;

  riskDescription: string;
  riskValue: number;
}

const createComment = (projectId: number, ideaId: number, command: CreateIdeaCommentCommand): Promise<IIdeaComment> =>
  axios
    .post(`/v1/api/<ws-id>/project/${projectId}/idea/${ideaId}/comment/create`, command)
    .then((response: AxiosResponse<IIdeaComment>) => response.data);

export interface UpdateIdeaCommentCommand {
  gainDescription?: string;
  gainValue?: number;

  costDescription?: string;
  costValue?: number;

  riskDescription?: string;
  riskValue?: number;
}

const updateComment = (
  projectId: number,
  ideaId: number,
  commentId: number,
  command: UpdateIdeaCommentCommand,
): Promise<IIdeaComment> =>
  axios
    .patch(`/v1/api/<ws-id>/project/${projectId}/idea/${ideaId}/comment/${commentId}/update`, command)
    .then((response: AxiosResponse<IIdeaComment>) => response.data);

const deleteComment = (projectId: number, ideaId: number, commentId: number): Promise<IIdeaComment> =>
  axios
    .delete(`/v1/api/<ws-id>/project/${projectId}/idea/${ideaId}/comment/${commentId}/delete`)
    .then((response: AxiosResponse<IIdeaComment>) => response.data);

export const IdeaCommentsApi = {
  getAllIdeasCommentsForProject,
  getIdeaComments,
  getIdeaComment,
  createComment,
  updateComment,
  deleteComment,
};
