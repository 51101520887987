import axios, { AxiosResponse } from 'axios';
import { User } from '@priz/shared/src/models/security/user';
import {
  IProject,
  Project,
  PublicScope,
  ProjectCertificationStatus,
  ProjectStatus,
} from '@priz/shared/src/models/project';
import { TemplateType } from '@priz/shared/src/models/template';

const isMyProject = (project: Project, userId: number): boolean => project.owner.id === userId;

const isProjectPendingReview = (project: Project, currentUser: User): boolean => {
  return (
    (!project.reviewer || !project.reviewer.id) &&
    !isMyProject(project, currentUser.id) &&
    project.certificationStatus === ProjectCertificationStatus.AvailableForReview
  );
};

const isProjectReviewedByUser = (project: Project, userId: number): boolean =>
  project.reviewer && project.reviewer.id === userId && project.certificationStatus !== ProjectCertificationStatus.None;

const loadProjects = (): Promise<Project[]> =>
  axios.get(`/v1/api/<ws-id>/project`).then((response: AxiosResponse<Project[]>) => response.data);

const loadProject = (id: number): Promise<Project> =>
  axios.get(`/v1/api/project/${id}`).then((response: AxiosResponse<Project>) => response.data);

export interface CreateProjectCommand {
  title: string;
  status: ProjectStatus;
  description?: string;
  industry?: string;
  area?: string;
  goal?: string;
  utilizationId?: number;
  template?: TemplateType;
}

const createProject = (command: CreateProjectCommand): Promise<Project> =>
  axios.post(`/v1/api/<ws-id>-<t-id>/project`, command).then((response: AxiosResponse<Project>) => response.data);

const updateProblemStatement = (project: Partial<Project>): Promise<Project> =>
  axios
    .put(`/v1/api/<ws-id>/project/${project.id}/problem-statement`, {
      currentSituation: project.currentSituation,
      disadvantages: project.disadvantages,
      idealFinalResult: project.idealFinalResult,
      gaps: project.gaps,
      problemStatement: project.problemStatement,
    })
    .then((response: AxiosResponse<Project>) => response.data);

const updateProject = (project: Project): Promise<any> =>
  axios
    .put(`/v1/api/<ws-id>/project/${project.id}`, {
      title: project.title,
      industry: project.industry,
      area: project.area,
      goal: project.goal,
      description: project.description,
      status: project.status,
      template: project.template,
    })
    .then((response: AxiosResponse<Project>) => response.data);

const updateSolution = (projectId: number, solution: string): Promise<Project> =>
  axios
    .put(`/v1/api/<ws-id>/project/${projectId}/solution`, {
      solution,
    })
    .then((response: AxiosResponse<Project>) => response.data);

const updateStatus = (projectId: number, status: ProjectStatus): Promise<Project> =>
  axios
    .put(`/v1/api/<ws-id>/project/${projectId}/update-status`, {
      status,
    })
    .then((response: AxiosResponse<Project>) => response.data);

export interface UpdatePublicInfoProps {
  open: boolean;
  draft: boolean;
  publicTitle?: string;
  publicDescription?: string;
  topicId?: number;
  keywords?: string[];
  publicScopes?: PublicScope[];
  poster?: File;
  posterKey?: string;
}

const updatePublicInfo = (projectId: number, props: UpdatePublicInfoProps): Promise<Project> => {
  const formData = new FormData();
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  Object.keys(props).forEach(key => {
    const value = props[key];

    if (Array.isArray(value)) {
      value.forEach(item => {
        if (typeof item !== 'undefined' && item !== null) formData.append(key, item);
      });
    } else {
      if (typeof value !== 'undefined' && value !== null) formData.append(key, value);
    }
  });

  return axios
    .post(`/v1/api/<ws-id>/project/${projectId}/update-public-info`, formData, config)
    .then((response: AxiosResponse<Project>) => response.data);
};

const publishChanges = (projectId: number): Promise<Project> =>
  axios
    .post(`/v1/api/<ws-id>/project/${projectId}/publish-changes`)
    .then((response: AxiosResponse<Project>) => response.data);

const deleteProjectById = (projectId: number): Promise<any> => axios.delete(`/v1/api/<ws-id>/project/${projectId}`);

const unpublish = (projectId: number): Promise<IProject> =>
  axios.put(`/v1/api/project/${projectId}/unpublish`).then((response: AxiosResponse<Project>) => response.data);

export const ProjectApi = {
  createProject,
  deleteProjectById,
  isMyProject,
  isProjectPendingReview,
  isProjectReviewedByUser,
  loadProject,
  loadProjects,
  updateProblemStatement,
  updateSolution,
  updateProject,
  updateStatus,
  updatePublicInfo,
  publishChanges,
  unpublish,
};
