import { RouterActionMeta } from '../../../shared/store/types';
import { FluxStandardAction } from 'flux-standard-action';
import {
  SfmUtilization,
  ISfmUtilization,
  OptionalComponentRankMap,
  SfmRankMap,
  SfmVersionsRanksMap,
  SfmRank,
} from '@priz/shared/src/models/tools/sfm';
import { createPgAction } from '../../../shared/store/action-creator';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { ToolType, ToolUtilization } from '@priz/shared/src/models/tools';
import { SfmUpdateNetworkDataCommand, SfmUtilizationApi } from '../services/sfm-utilization.api';
import { NavigateFunction } from 'react-router-dom';
import { ProblematicComponentPreview, SfmComponentImprovement, SfmComponentsEliminationEffectMap } from '../model';
import { ToolsUtils } from '../../../tools/utils';

export enum SfmUtilizationActionType {
  Update = 'sfm-utilization/update',
  Delete = 'sfm-utilization/delete',
  UpdateNetworkData = 'sfm-utilization/update-network-data',
  CreateNewVersion = 'sfm-utilization/create-new-version',
  UpdateVersionTitle = 'sfm-utilization/update-version-title',
  SwitchVersion = 'sfm-utilization/switch-version',
  RemoveVersion = 'sfm-utilization/remove-version',
  CalcRank = 'sfm-utilization/calc/rank',
  CalcRanks = 'sfm-utilization/calc/ranks',
  CalcVersionRanks = 'sfm-utilization/calc/version-ranks',
  CalcComponentsRanks = 'sfm-utilization/calc/components-ranks',
  CalcVersionComponentsRanks = 'sfm-utilization/calc/version-components-ranks',
  GetComponentsEliminationEffect = 'sfm-utilization/component/elimination-effect/list',
  GetProblematicComponents = 'sfm-utilization/component/problematic/list',
  GetComponentsImprovements = 'sfm-utilization/component/improvement/list',
  GetComponentImprovement = 'sfm-utilization/component/improvement/get',
  UpdateComponentRecommendation = 'sfm-utilization/component/recommendation/update',
}

type SfmUtilizationActionPayload =
  | ISfmUtilization
  | OptionalComponentRankMap
  | SfmRank
  | SfmRankMap
  | SfmVersionsRanksMap
  | SfmComponentsEliminationEffectMap
  | SfmComponentImprovement[]
  | SfmComponentImprovement
  | ProblematicComponentPreview[];

interface SfmUtilizationActionMeta extends RouterActionMeta {
  utilization?: ISfmUtilization;
  utilizationId?: number;
  pfmId?: number;
  projectId?: number;
  versionId?: string;
  navigate?: NavigateFunction;
  nodeId?: string;
  parentId?: number;
}

export type SfmUtilizationAction = FluxStandardAction<string, SfmUtilizationActionPayload, SfmUtilizationActionMeta>;

const updateUtilization = createPgAction(
  SfmUtilizationActionType.Update,
  SfmUtilizationApi.updateUtilization,
  (utilization: SfmUtilization) => ({ utilization }),
  (_payload, { utilization }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: utilization.project?.id,
      tool_type: ToolType.SFM,
      utilization_id: utilization.id,
    });
  },
);

const updateNetworkData = createPgAction(
  SfmUtilizationActionType.UpdateNetworkData,
  SfmUtilizationApi.updateNetworkData,
  (utilizationId: number, command: SfmUpdateNetworkDataCommand, projectId?: number) => ({
    projectId,
    utilizationId,
    versionId: command.versionId,
  }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.SFM,
      utilization_id: utilizationId,
    });
  },
);

const createNewVersion = createPgAction(
  SfmUtilizationActionType.CreateNewVersion,
  SfmUtilizationApi.createNewVersion,
  (utilizationId: number, _command, projectId?: number) => ({ projectId, utilizationId }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.SFM,
      utilization_id: utilizationId,
    });
  },
);

const updateVersionTitle = createPgAction(
  SfmUtilizationActionType.UpdateVersionTitle,
  SfmUtilizationApi.updateVersionTitle,
  (utilizationId: number, _command, projectId?: number) => ({ projectId, utilizationId }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.SFM,
      utilization_id: utilizationId,
    });
  },
);

const switchVersion = createPgAction(
  SfmUtilizationActionType.SwitchVersion,
  SfmUtilizationApi.switchVersion,
  (utilizationId: number, _command, projectId?: number) => ({ projectId, utilizationId }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.SFM,
      utilization_id: utilizationId,
    });
  },
);

const removeVersion = createPgAction(
  SfmUtilizationActionType.RemoveVersion,
  SfmUtilizationApi.removeVersion,
  (utilizationId: number, _command, projectId?: number) => ({ projectId, utilizationId }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.SFM,
      utilization_id: utilizationId,
    });
  },
);

const doDelete = createPgAction(
  SfmUtilizationActionType.Delete,
  SfmUtilizationApi.delete,
  (utilizationId: number, projectId?: number, parent?: ToolUtilization, navigate?: NavigateFunction) => ({
    projectId,
    utilizationId,
    navigate,
    parent,
  }),
  (_payload, { projectId, utilizationId, navigate, parent }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_DELETED, {
      project_id: projectId,
      tool_type: ToolType.SFM,
      utilization_id: utilizationId,
    });

    if (navigate) {
      if (parent) {
        navigate(ToolsUtils.resolvePathByUtilization(parent));
      } else {
        navigate(ToolsUtils.resolveListPath(projectId));
      }
    }
  },
);

const calcSfmRank = createPgAction(
  SfmUtilizationActionType.CalcRank,
  SfmUtilizationApi.getSfmRank,
  (utilizationId: number, projectId?: number) => ({ projectId, utilizationId }),
);

const calcSfmRanks = createPgAction(
  SfmUtilizationActionType.CalcRanks,
  SfmUtilizationApi.getSfmRanks,
  (pfmId: number, projectId?: number) => ({ projectId, pfmId }),
);

const calcSfmVersionsRanks = createPgAction(
  SfmUtilizationActionType.CalcVersionRanks,
  SfmUtilizationApi.getSfmVersionsRanks,
  (utilizationId: number, projectId?: number) => ({ projectId, utilizationId }),
);

const calcComponentsRanks = createPgAction(
  SfmUtilizationActionType.CalcComponentsRanks,
  SfmUtilizationApi.getComponentsRanks,
  (utilizationId: number, projectId?: number) => ({ projectId, utilizationId }),
);

const calcVersionComponentsRanks = createPgAction(
  SfmUtilizationActionType.CalcVersionComponentsRanks,
  SfmUtilizationApi.getVersionComponentsRanks,
  (utilizationId: number, versionId: string, projectId?: number) => ({ projectId, utilizationId, versionId }),
);

const getComponentsEliminationEffect = createPgAction(
  SfmUtilizationActionType.GetComponentsEliminationEffect,
  SfmUtilizationApi.getComponentsEliminationEffect,
  (utilizationId: number, versionId: string, projectId?: number) => ({
    projectId,
    utilizationId,
    versionId,
  }),
);

const getProblematicComponents = createPgAction(
  SfmUtilizationActionType.GetProblematicComponents,
  SfmUtilizationApi.getProblematicComponents,
  (utilizationId: number, versionId: string, projectId?: number) => ({
    projectId,
    utilizationId,
    versionId,
  }),
);

const getVersionImprovements = createPgAction(
  SfmUtilizationActionType.GetComponentsImprovements,
  SfmUtilizationApi.getVersionImprovements,
  (utilizationId: number, versionId: string, projectId?: number) => ({
    projectId,
    utilizationId,
    versionId,
  }),
);

const getComponentImprovement = createPgAction(
  SfmUtilizationActionType.GetComponentImprovement,
  SfmUtilizationApi.getComponentImprovement,
  (utilizationId: number, versionId: string, nodeId: string, projectId?: number) => ({
    projectId,
    utilizationId,
    versionId,
    nodeId,
  }),
);

const updateComponentRecommendation = createPgAction(
  SfmUtilizationActionType.UpdateComponentRecommendation,
  SfmUtilizationApi.updateComponentRecommendation,
  (utilizationId: number, versionId: string, nodeId: string, _command, projectId?: number) => ({
    projectId,
    utilizationId,
    versionId,
    nodeId,
  }),
);

export const SfmUtilizationActions = {
  updateUtilization,
  updateNetworkData,
  createNewVersion,
  updateVersionTitle,
  switchVersion,
  removeVersion,
  delete: doDelete,
  calcSfmRank,
  calcSfmRanks,
  calcSfmVersionsRanks,
  calcComponentsRanks,
  calcVersionComponentsRanks,
  getComponentsEliminationEffect,
  getProblematicComponents,
  getVersionImprovements,
  getComponentImprovement,
  updateComponentRecommendation,
};
