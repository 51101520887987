import React from 'react';
import { PfmStepType, PfmUtilization } from '../../../models/tools/pfm';
import { OperationEfficiency, OperationMeritType, SfmRankMap, SfmUtilization } from '../../../models/tools/sfm';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { resolvePfmTypeIcon } from '../../../utils/pfm';
import { OperationMeritLabel } from '../../operation-merit-label/component';

interface PfmPrescriptionProps {
  pfmUtilization: PfmUtilization;
  sfmUtilizationArray: SfmUtilization[];
  sfmRanks: SfmRankMap;
}

const operationTypeLabels = {
  [PfmStepType.Productive]: 'Consider improving',
  [PfmStepType.Providing]: 'Consider eliminating',
  [PfmStepType.Corrective]: 'Do nothing and eliminate it when possible',
  [PfmStepType.Metrology]: 'Consider the necessity of the information and eliminate it when possible',
};

export const PfmPrescription: React.FC<PfmPrescriptionProps> = ({ pfmUtilization, sfmUtilizationArray, sfmRanks }) => {
  const { t } = useTranslation();

  const sfmTitlesMap = sfmUtilizationArray.reduce((map: { [sfmId: number]: string | undefined }, sfm) => {
    map[sfm.id] = sfm.title;
    return map;
  }, {});

  const steps = pfmUtilization?.diagramData?.steps || [];

  return (
    <TableContainer className={'pfm-prescription'} component={Paper} variant={'outlined'}>
      <Table sx={{ minWidth: 650 }} size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Trans>Operation</Trans>
            </TableCell>
            <TableCell>
              <Trans>Type</Trans>
            </TableCell>
            <TableCell>
              <Trans>Merit</Trans>
            </TableCell>
            <TableCell>
              <Trans>Recommendation</Trans>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {steps.map((step, key) => {
            if (!sfmRanks[step.sfmId]) return null;

            const sfmTitle = sfmTitlesMap[step.sfmId];
            const title = sfmTitle ? t(sfmTitle) : `${t('Step')} ${step.index}`;
            const meritIsNotDefined = sfmRanks[step.sfmId].operationMerit.type === OperationMeritType.NotDefined;
            const efficiencyIsNotDefined =
              sfmRanks[step.sfmId].operationMerit.efficiency === OperationEfficiency.NotDefined;

            if (!step.type || (meritIsNotDefined && efficiencyIsNotDefined)) return null;

            return (
              <TableRow key={key}>
                <TableCell component="th" scope="row">
                  {title}
                </TableCell>

                <TableCell>
                  <Trans>
                    <Grid container spacing={1} alignItems={'center'} flexWrap={'nowrap'}>
                      <Grid item>{resolvePfmTypeIcon(step.type)}</Grid>

                      <Grid item>
                        <Trans>{step.type}</Trans>
                      </Grid>
                    </Grid>
                  </Trans>
                </TableCell>

                <TableCell>
                  <OperationMeritLabel operationMerit={sfmRanks[step.sfmId].operationMerit} />
                </TableCell>

                <TableCell>
                  <Trans>{operationTypeLabels[step.type]}</Trans>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
