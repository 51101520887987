import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';

export const useStyles = makeStyles({
  pipeCardTitleContainer: {
    backgroundColor: pgColorScheme.white,
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
  },
  pieOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: '50%',
    zIndex: 2,
    width: '100%',
    height: '100%',
    color: pgColorScheme.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
