import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { Project, ProjectStatus } from '@priz/shared/src/models/project';
import { Box, Chip, Grid, IconButton, Typography } from '@mui/material';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { ProjectActions } from '../store/actions/project.actions';
import { ProjectAssignees } from '../../assignment/project-assignees/component';
import { format } from 'date-fns';
import { useFlag } from '@unleash/proxy-client-react';
import { UnleashFeatureName } from '@priz/shared/src/services/unleash';
import { LinkCard } from '../../link-card/component';
import { DeleteOutlined } from '@mui/icons-material';
import { FolderOpenOutlined, LanguageOutlined, LockOutlined } from '@mui/icons-material';
import { UserAvatar } from '@priz/shared/src/components/user-avatar/component';
import { ProjectAssigneeSelectors } from '../../assignment/store/selectors/project-assignee.selectors';
import { pgColorScheme } from '@priz/shared/src/theme';
import { ProjectSelector } from '../store/selectors';

interface ProjectCardProps {
  project: Project;
}

export const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
  const dispatch = useDispatch();
  const isTemplatesEnabled = useFlag(UnleashFeatureName.Templates);
  const { id, title, dateCreated, status } = project;

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

  const assignments = useSelector(ProjectAssigneeSelectors.getProjectAssignees(project.id));
  const isProjectRemoving = useSelector(ProjectSelector.isProjectRemovingById(project.id));

  const link =
    status === ProjectStatus.Todo
      ? ['/start-project', id]
      : isTemplatesEnabled && project.template
      ? ['/project', id, 'template']
      : ['/project', id, 'overview'];

  const openDeleteConfirmation = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
  };

  const deleteProject = () => {
    dispatch(ProjectActions.deleteProject(project));
  };

  return (
    <>
      <LinkCard to={link.join('/')}>
        <Box p={2}>
          <Grid container spacing={1} justifyContent={'flex-start'}>
            <Grid item>
              <Chip
                label={<Trans>Project</Trans>}
                icon={<FolderOpenOutlined />}
                color={'primary'}
                variant={'filled'}
                size={'small'}
                sx={{
                  backgroundColor: pgColorScheme.gray700,
                  borderRadius: 1,
                }}
              />
            </Grid>

            <Grid item>
              <Chip
                label={project.open ? 'open' : 'private'}
                icon={project.open ? <LanguageOutlined /> : <LockOutlined />}
                color={project.open ? 'success' : 'default'}
                variant={'outlined'}
                size={'small'}
              />
            </Grid>
          </Grid>
        </Box>

        <Box px={2} mb={1}>
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
        </Box>

        <Box px={2} mb={1}>
          <Typography variant="caption" color={'text_color.light'}>
            <Trans>Used at</Trans>: {format(dateCreated, 'MMM d yyyy h:mm:ss aaa')}
          </Typography>
        </Box>

        <Box pl={2} pr={1} pb={1}>
          <Grid container justifyContent={'space-between'} spacing={1}>
            <Grid item>
              {assignments.length === 1 && <UserAvatar user={assignments[0]?.user} size={28} showName />}

              {assignments.length > 1 && (
                <ProjectAssignees
                  projectId={project.id}
                  sx={{ '& .MuiAvatar-root': { width: 28, height: 28, fontSize: 14 } }}
                  avatarProps={{ sx: { width: 28, height: 28 } }}
                />
              )}
            </Grid>

            <Grid item>
              <IconButton onClick={openDeleteConfirmation} color={'error'} size={'small'}>
                <DeleteOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </LinkCard>

      <PgConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        confirmTitle={<Trans>Confirm delete</Trans>}
        okButtonText={<Trans>Delete</Trans>}
        confirmContent={
          <React.Fragment>
            <Trans>Are you sure you want to delete this project?</Trans>
          </React.Fragment>
        }
        loading={isProjectRemoving}
        disabled={isProjectRemoving}
        onConfirm={deleteProject}
        onClose={closeDeleteConfirmation}
      />
    </>
  );
};
