import React, { useEffect } from 'react';
import { CftNodeRelations } from '../store/model';
import { useToolTabs } from '../../tools/utils';
import { Alert, Box, BoxProps, Tab, Tabs } from '@mui/material';
import { Trans } from 'react-i18next';
import { TabPanel } from '../../react/elements/tab-panel/component';
import { useStyles } from './styles';
import { MeetingCardCompact } from '../../meetings/meeting-card-compact/component';
import { RowToolCardCompact } from '../../tools/row-tool-card-compact/component';
import { IdeaCardCompact } from '../../idea/idea-card-compact/comonent';
import { TaskCardCompact } from '../../tasks/task-card-compact/component';

interface CftNodeRelationsRendererProps extends BoxProps {
  relations: CftNodeRelations;
  tabsContainerProps?: BoxProps;
}

enum RelationsCategory {
  Meetings = 'Meetings',
  Tools = 'Tools',
  Tasks = 'Tasks',
  Ideas = 'Ideas',
}

export const CftNodeRelationsRenderer: React.FC<CftNodeRelationsRendererProps> = ({
  relations,
  tabsContainerProps,
  ...rest
}) => {
  const styles = useStyles();
  const { meetings, tools, tasks, ideas } = relations;

  const [activeTab, setActiveTab] = useToolTabs<RelationsCategory>(RelationsCategory.Meetings);

  useEffect(() => {
    if (meetings.length) {
      return setActiveTab(RelationsCategory.Meetings);
    }

    if (tools.length) {
      return setActiveTab(RelationsCategory.Tools);
    }

    if (tasks.length) {
      return setActiveTab(RelationsCategory.Tasks);
    }

    if (ideas.length) {
      return setActiveTab(RelationsCategory.Ideas);
    }
  }, []);

  const tabChangeHandler = (_event: React.SyntheticEvent, newValue: RelationsCategory) => {
    setActiveTab(newValue);
  };

  return (
    <Box {...rest}>
      <Box mb={2} display={'flex'} justifyContent={'flex-end'} {...tabsContainerProps}>
        <Tabs className={styles.tabs} value={activeTab} orientation={'horizontal'} onChange={tabChangeHandler}>
          <Tab className={styles.tab} value={RelationsCategory.Meetings} label={<Trans>Meetings</Trans>} />
          <Tab className={styles.tab} value={RelationsCategory.Tools} label={<Trans>Tools</Trans>} />
          <Tab className={styles.tab} value={RelationsCategory.Tasks} label={<Trans>Tasks</Trans>} />
          <Tab className={styles.tab} value={RelationsCategory.Ideas} label={<Trans>Ideas</Trans>} />
        </Tabs>
      </Box>

      <TabPanel index={RelationsCategory.Meetings} value={activeTab}>
        <div className={styles.tabContent}>
          {meetings.map((meeting, key) => (
            <MeetingCardCompact key={meeting.id} meeting={meeting} mt={key ? 1 : 0} />
          ))}

          {!meetings.length && (
            <Alert severity={'info'}>
              <Trans>No meetings yet</Trans>
            </Alert>
          )}
        </div>
      </TabPanel>

      <TabPanel index={RelationsCategory.Tools} value={activeTab}>
        <div className={styles.tabContent}>
          {tools.map((tool, key) => (
            <RowToolCardCompact key={tool.id} tool={tool} mt={key ? 1 : 0} linkVariant={'icon'} editable={false} />
          ))}

          {!tools.length && (
            <Alert severity={'info'}>
              <Trans>No tools yet</Trans>
            </Alert>
          )}
        </div>
      </TabPanel>

      <TabPanel index={RelationsCategory.Tasks} value={activeTab}>
        <div className={styles.tabContent}>
          {tasks.map((task, key) => (
            <TaskCardCompact key={task.id} task={task} mt={key ? 1 : 0} />
          ))}

          {!tasks.length && (
            <Alert severity={'info'}>
              <Trans>No tasks yet</Trans>
            </Alert>
          )}
        </div>
      </TabPanel>

      <TabPanel index={RelationsCategory.Ideas} value={activeTab}>
        <div className={styles.tabContent}>
          {ideas.map((idea, key) => (
            <IdeaCardCompact key={idea.id} idea={idea} mt={key ? 1 : 0} />
          ))}

          {!ideas.length && (
            <Alert severity={'info'}>
              <Trans>No ideas yet</Trans>
            </Alert>
          )}
        </div>
      </TabPanel>
    </Box>
  );
};
