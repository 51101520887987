import * as React from "react";

export const ReactComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="320" height="320" viewBox="0 0 320 320" fill="#3C3C3C" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M228.453 228.453C231.968 224.939 237.667 224.939 241.181 228.453L317.368 304.639C320.882 308.154 320.882 313.853 317.368 317.367V317.367C313.853 320.882 308.154 320.882 304.64 317.367L228.453 241.181C224.939 237.667 224.939 231.968 228.453 228.453V228.453Z"/>
    <path d="M140.093 202.693C135.123 202.693 131.093 198.663 131.093 193.693V85.9494C131.093 80.9788 135.123 76.9493 140.093 76.9493V76.9493C145.064 76.9493 149.093 80.9788 149.093 85.9493V193.693C149.093 198.663 145.064 202.693 140.093 202.693V202.693Z"/>
    <path d="M76.9492 139.907C76.9492 134.936 80.9787 130.907 85.9492 130.907H193.693C198.663 130.907 202.693 134.936 202.693 139.907V139.907C202.693 144.878 198.663 148.907 193.693 148.907H85.9492C80.9787 148.907 76.9492 144.878 76.9492 139.907V139.907Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M140 262C207.379 262 262 207.379 262 140C262 72.6213 207.379 18 140 18C72.6213 18 18 72.6213 18 140C18 207.379 72.6213 262 140 262ZM140 280C217.32 280 280 217.32 280 140C280 62.6801 217.32 0 140 0C62.6801 0 0 62.6801 0 140C0 217.32 62.6801 280 140 280Z"/>
  </svg>
);
