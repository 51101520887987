import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';

export const useStyles = makeStyles({
  nothingToShow: {
    color: pgColorScheme.gray,
    textAlign: 'center',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  tableViewMode: {
    '& > *': {
      pageBreakInside: 'avoid',
    },
  },
  tableCell: {
    padding: '8px 0',
    border: 'none',
  },
  tableCellDivider: {
    padding: '16px 0',
  },
});
