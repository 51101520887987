import React from 'react';
import {
  FeatureLimit,
  featureLimitDescriptionMap,
  PlanFeatureSet,
  PlanFeaturesMap,
} from '@priz/shared/src/models/workspace';
import { ToolType } from '@priz/shared/src/models/tools';
import { Box, TableCell, TableRow } from '@mui/material';
import { Trans } from 'react-i18next';
import { ToolTypeDataMap } from '@priz/shared/src/data/tool-type-data-map';
import { PlanLevel } from '@priz/shared/src/models/workspace';
import { PlanFeatureIndicator } from '../../plan-feature-indicator/component';

interface ToolsRowProps {
  baseFeatureSet: PlanFeatureSet;
  data: PlanFeaturesMap;
  colsWidth?: string[];
}

export const ToolsRow: React.FC<ToolsRowProps> = ({ baseFeatureSet, data, colsWidth }) => {
  return (
    <>
      {Object.keys(baseFeatureSet[FeatureLimit.Tools]).map((toolType: ToolType) => (
        <TableRow key={toolType} hover={true}>
          <TableCell width={colsWidth?.[0] || '20%'}>
            <Box pl={2}>
              <Trans>{ToolTypeDataMap[toolType].name}</Trans>
            </Box>
          </TableCell>
          {Object.keys(data).map((level: PlanLevel, key) => (
            <TableCell key={level} align="center" width={colsWidth?.[key + 1] || `${80 / Object.keys(data).length}%`}>
              <PlanFeatureIndicator
                value={data[level][FeatureLimit.Tools][toolType].enabled}
                maxItems={data[level][FeatureLimit.Tools][toolType].maxItems}
                maxItemsTooltip={featureLimitDescriptionMap[toolType]?.description}
              />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};
