import React from 'react';
import { Task } from '@priz/shared/src/models/task';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { RankingScore } from '@priz/shared/src/components/ranking-score/component';
import { QuillContentViewer } from '@priz/shared/src/components/quill-content-viewer/component';
import { TaskInfo } from '../task-info/component';
import { GrayLabelContainer } from '../../content-containers/gray-label-container/component';

interface TaskInfoCardProps {
  task: Task;
  disabled?: boolean;
}

export const TaskInfoCard: React.FC<TaskInfoCardProps> = ({ task, disabled }) => {
  return (
    <Paper variant={'outlined'}>
      <Box p={1}>
        <Grid container spacing={1}>
          {typeof task.latestRankingScore === 'number' && (
            <Grid item xs={'auto'}>
              <RankingScore rankingScore={task.latestRankingScore} small />
            </Grid>
          )}

          <Grid item xs={true}>
            <Box sx={{ display: 'flex', alignItems: 'center', minHeight: 26 }}>
              <Typography variant={'body2'}>
                <b>{task.title}</b>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {task.details && (
        <Box p={1}>
          <GrayLabelContainer label={'Details'} flexDirection={'column'}>
            <QuillContentViewer content={task.details} />
          </GrayLabelContainer>
        </Box>
      )}

      {task.resolution && (
        <Box p={1}>
          <GrayLabelContainer label={'Resolution'} flexDirection={'column'}>
            <QuillContentViewer content={task.resolution} />
          </GrayLabelContainer>
        </Box>
      )}

      <Box p={1} maxWidth={'100%'}>
        <TaskInfo task={task} disabled={disabled} />
      </Box>
    </Paper>
  );
};
