import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  select: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: 14,
    lineHeight: '1',
    letterSpacing: 0,
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  menuOptionWrap: {
    '&._focused': {
      backgroundColor: pgColorScheme.background,
    },
    '&._selected': {
      // backgroundColor: 'rgba(0, 0, 0, 0.03)',
      backgroundColor: 'rgba(17, 152, 210, 0.08)',
      color: pgColorScheme.darkText,
    },
  },
  letter: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    background: pgColorScheme.backgroundMediumGray,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    width: 'calc(100% - 40px)',
    paddingLeft: 10,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  singleValue: {
    'width': '100%',

    '&._assign $letter': {
      background: pgColorScheme.darkBlue,
      color: pgColorScheme.white,
    },

    '&._conflict $letter': {
      background: pgColorScheme.darkOrange,
      color: pgColorScheme.white,
    },
  },
});
