import React, { useContext, useEffect } from 'react';
import {
  CanvasControlButton,
  CanvasControlsContainer,
  CanvasControlsGroup,
} from '@priz/shared/src/components/canvas-controls';
import { FullscreenButton } from '@priz/shared/src/components/fullscreen-button/component';
import { CftContext } from '@priz/shared/src/components/cft/cft-context/component';
import { FullscreenContainerContext } from '@priz/shared/src/components/fullscreen-container/component';
import { ElementsViewerButton } from '@priz/shared/src/components/elements-viewer/elements-viewer-button/component';

import { ReactComponent as CameraIcon } from '@priz/shared/src/assets/icons/center';
import { ReactComponent as UndoIcon } from '../../../assets/icons/undo.svg';
import { ReactComponent as RedoIcon } from '../../../assets/icons/redo.svg';

interface CftDiagramControlsProps {
  undo: () => void;
  redo: () => void;
  isAtStart: boolean;
  isAtEnd: boolean;
  disabled?: boolean;
}

export const CftDiagramControls: React.FC<CftDiagramControlsProps> = ({ undo, redo, isAtStart, isAtEnd, disabled }) => {
  const fullscreenContext = useContext(FullscreenContainerContext);
  const { fitContent } = useContext(CftContext);

  useEffect(() => {
    fitContent();
  }, [fullscreenContext.enabled]);

  return (
    <CanvasControlsContainer>
      <CanvasControlsGroup>
        <CanvasControlButton tooltip={'Recenter'} onClick={fitContent}>
          <CameraIcon />
        </CanvasControlButton>

        <FullscreenButton />
      </CanvasControlsGroup>

      <CanvasControlsGroup>
        <ElementsViewerButton />
      </CanvasControlsGroup>

      <CanvasControlsGroup>
        <CanvasControlButton tooltip={'Undo'} onClick={undo} disabled={isAtStart || disabled}>
          <UndoIcon />
        </CanvasControlButton>

        <CanvasControlButton tooltip={'Redo'} onClick={redo} disabled={isAtEnd || disabled}>
          <RedoIcon />
        </CanvasControlButton>
      </CanvasControlsGroup>
    </CanvasControlsContainer>
  );
};
