import React from 'react';
import { Trans } from 'react-i18next';
import { Button } from '@mui/material';

export interface FormSubmitButtonProps {
  text?: string;
  disabled?: boolean;
}

export const FormSubmitButton: React.FC<FormSubmitButtonProps> = ({ text = 'Save', disabled }) => {
  return (
    <Button variant={'pg_rounded'} color={'pg_orange_solid'} type={'submit'} disabled={disabled}>
      <Trans>{text}</Trans>
    </Button>
  );
};
