import { OptionalComponentRank, OptionalComponentRankMap } from '../../models/tools/sfm';

interface IdentifiableComponentRank extends OptionalComponentRank {
  id: string;
}

export const getComponentsRanksArray = (ranksMap: OptionalComponentRankMap): IdentifiableComponentRank[] => {
  return Object.keys(ranksMap || {}).map((id: string) => ({ id, ...ranksMap[id] }));
};

export const getMaxRanks = (
  componentsRanksMap: OptionalComponentRankMap,
): {
  maxFunctionalRank: number;
  maxProblematicRank: number;
} => {
  const values = Object.values(componentsRanksMap || {});
  const functionalRanks = values.map(item => item?.functionalRank || 0);
  const problematicRanks = values.map(item => item?.problematicRank || 0);

  return {
    maxFunctionalRank: Math.max(...functionalRanks),
    maxProblematicRank: Math.max(...problematicRanks),
  };
};
