import { put, takeLatest } from 'redux-saga/effects';
import { FluxStandardAction } from 'flux-standard-action';
import { NotificationActions } from './notification.actions';
import { removeActionSuffix, toFailed, toSuccess } from '../../../../shared/store/action-creator';
import { UserActionType } from '../../../../user/store/actions/user.actions';
import { ProjectAssignmentActionType } from '../../../../assignment/store/actions';
import { ProjectActionType } from '../../../../project/store/actions/project.actions';
import {
  DomainActionType,
  DomainPrincipleActionType,
  FortyPrinciplesUtilizationActionType,
  ParameterActionType,
  PrincipleActionType,
} from '../../../../forty-principles/store/actions';
import { ActionSuffix } from '../../../../shared/store/action-suffix.enum';
import { WorkspaceActionType } from '../../../../workspace/store/actions';
import { AnyAction, resolveAxiosErrorResponse } from '@priz/shared/src/services/axios';
import { SfmUtilizationActionType } from '../../../../sfm/store/actions';
import { RrmUtilizationActionType } from '../../../../rrm/store/actions/rrm-utilization.actions';
import { UimUtilizationActionType } from '../../../../uim/store/actions/uim-utilization.actions';
import {
  FiveWhysCauseActionType,
  FiveWhysSolutionActionType,
  FiveWhysUtilizationActionType,
} from '../../../../five-whys/store/actions';
import { ApaActionActionType, ApaUtilizationActionType } from '../../../../apa/store/actions';
import { EbsUtilizationActionType } from '../../../../ebs/store/actions';
import { NineWindowsUtilizationActionType } from '../../../../nine-windows/store/actions';
import { CecUtilizationActionType } from '../../../../cec/store/actions';
import { PMapUtilizationActionType } from '../../../../pmap/store/actions';
import { PfmUtilizationActionType } from '../../../../pfm/store/actions';
import { RrmGoalActionType } from '../../../../rrm/store';
import { ProjectIdeaActionType } from '../../../../idea/store';
import { TaskActionType } from '../../../../tasks/store/actions/task.actions';
import { AgendaActionType, AttendeeActionType, MeetingActionType } from '../../../../meetings/store/actions';
import { defaultMessagesMap, successMessagesMap, severityMap } from '../data';

interface ActionHandlerProps {
  message?: string;
  duration?: number;
}

export interface NotificationActionMeta {
  preventNotification?: boolean;
}

type NotificationAction = FluxStandardAction<string, null, NotificationActionMeta>;

const errorCodeMessagesMap: { [key: number]: string } = {};

const ignoreMap: { [key: string]: number[] } = {
  [toFailed(ProjectAssignmentActionType.Create)]: [402],
};

function* defaultActionHandler(action: AnyAction, props?: ActionHandlerProps) {
  const { code, message: axiosMessage } = resolveAxiosErrorResponse(action);

  if (!ignoreMap[action.type]?.includes(code)) {
    const { message: propsMessage, duration: propsDuration } = props || {};

    const actionSuffix = '/' + action.type.split('/').pop();
    const text = propsMessage || errorCodeMessagesMap[code] || axiosMessage || defaultMessagesMap[actionSuffix];
    const severity = severityMap[actionSuffix] || 'info';
    const duration = propsDuration || actionSuffix === ActionSuffix.Failed ? 10000 : 2000;

    yield put(
      // @ts-ignore
      NotificationActions.add(text, severity, duration),
    );
  }
}

export function* notificationSagas() {
  ///////////////////////////////////////////////////
  //////////////// SUCCESS MESSAGES /////////////////
  ///////////////////////////////////////////////////

  yield takeLatest(
    Object.keys(successMessagesMap).map(actionType => toSuccess(actionType)),
    (action: NotificationAction) => {
      if (!action.meta.preventNotification) {
        return defaultActionHandler(action, { message: successMessagesMap[removeActionSuffix(action.type)] });
      }
    },
  );

  ///////////////////////////////////////////////////
  //////////// DEFAULT SUCCESS AND FAILED ///////////
  ///////////////////////////////////////////////////

  yield takeLatest(
    [
      ...Object.values(ParameterActionType),
      ...Object.values(DomainActionType),
      ...Object.values(PrincipleActionType),
      ...Object.values(DomainPrincipleActionType),
    ].reduce((array: string[], actionType) => {
      array.push(toSuccess(actionType), toFailed(actionType));
      return array;
    }, []),
    actionType => defaultActionHandler(actionType, { duration: 500 }),
  );

  ///////////////////////////////////////////////////
  ///////////////// DEFAULT FAILED //////////////////
  ///////////////////////////////////////////////////

  yield takeLatest(
    [
      // user
      ...Object.values(UserActionType),

      // workspace
      ...Object.values(WorkspaceActionType),

      // project
      ...Object.values(ProjectActionType),
      ...Object.values(ProjectAssignmentActionType),

      // tasks
      ...Object.values(TaskActionType),

      // ideas
      ...Object.values(ProjectIdeaActionType),

      // meetings
      ...Object.values(MeetingActionType),
      ...Object.values(AttendeeActionType),
      ...Object.values(AgendaActionType),

      // Tools
      ...Object.values(FortyPrinciplesUtilizationActionType),
      ...Object.values(RrmUtilizationActionType),
      ...Object.values(RrmGoalActionType),
      ...Object.values(UimUtilizationActionType),
      ...Object.values(FiveWhysUtilizationActionType),
      ...Object.values(FiveWhysCauseActionType),
      ...Object.values(FiveWhysSolutionActionType),
      ...Object.values(ApaUtilizationActionType),
      ...Object.values(ApaActionActionType),
      ...Object.values(EbsUtilizationActionType),
      ...Object.values(NineWindowsUtilizationActionType),
      ...Object.values(CecUtilizationActionType),
      ...Object.values(PMapUtilizationActionType),
      ...Object.values(SfmUtilizationActionType),
      ...Object.values(PfmUtilizationActionType),
    ].map(actionType => toFailed(actionType)),
    defaultActionHandler,
  );
}
