import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  preloader: {
    width: '100%',
    height: '100%',
    borderRadius: '4px',
    background: pgColorScheme.backgroundMediumGray,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
