import { put, takeLatest } from 'redux-saga/effects';
import { ProjectAction, ProjectActions, ProjectActionType } from '../actions/project.actions';
import { toSuccess } from '../../../shared/store/action-creator';
import {
  FortyPrinciplesUtilizationAction,
  FortyPrinciplesUtilizationActionType,
} from '../../../forty-principles/store/actions';
import { RrmUtilizationAction, RrmUtilizationActionType } from '../../../rrm/store/actions/rrm-utilization.actions';
import { RrmGoalAction, RrmGoalActionType } from '../../../rrm/store';
import { UimUtilizationAction, UimUtilizationActionType } from '../../../uim/store/actions/uim-utilization.actions';
import { EbsUtilizationAction, EbsUtilizationActionType } from '../../../ebs/store/actions';
import {
  FiveWhysCauseAction,
  FiveWhysCauseActionType,
  FiveWhysSolutionAction,
  FiveWhysSolutionActionType,
  FiveWhysUtilizationAction,
  FiveWhysUtilizationActionType,
} from '../../../five-whys/store/actions';
import {
  ApaActionAction,
  ApaActionActionType,
  ApaUtilizationAction,
  ApaUtilizationActionType,
} from '../../../apa/store/actions';
import { ProjectIdeaAction, ProjectIdeaActionType } from '../../../idea/store';
import { NineWindowsUtilizationAction, NineWindowsUtilizationActionType } from '../../../nine-windows/store/actions';
import { CecUtilizationAction, CecUtilizationActionType } from '../../../cec/store/actions';
import { SfmUtilizationAction, SfmUtilizationActionType } from '../../../sfm/store/actions';
import { PMapUtilizationAction, PMapUtilizationActionType } from '../../../pmap/store/actions';
import { ProjectAssignmentAction, ProjectAssignmentActionType } from '../../../assignment/store/actions';
import { PfmUtilizationActionType } from '../../../pfm/store/actions';
import { ISfmUtilization } from '@priz/shared/src/models/tools/sfm';
import { ToolUtilizationActionType } from '../actions/tools.actions';
import { CftUtilizationAction, CftUtilizationActionType } from '../../../cft/store/actions';
import { ICftUtilization } from '@priz/shared/src/models/tools/cft';

function* actionHandler(projectId: number) {
  if (typeof projectId !== 'undefined') {
    yield put(
      // @ts-ignore
      ProjectActions.setPublishCountdown(projectId),
    );
  }
}

export function* projectLastChangedSagas() {
  // PROJECT
  yield takeLatest(toSuccess(ProjectActionType.Create), (action: ProjectAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(ProjectActionType.Update), (action: ProjectAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(ProjectActionType.Delete), (action: ProjectAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(ToolUtilizationActionType.CreateTool), (action: ProjectAction) =>
    actionHandler(action.meta.projectId),
  );

  // 40Ps
  yield takeLatest(toSuccess(FortyPrinciplesUtilizationActionType.Create), (action: FortyPrinciplesUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(FortyPrinciplesUtilizationActionType.Update), (action: FortyPrinciplesUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(FortyPrinciplesUtilizationActionType.Delete), (action: FortyPrinciplesUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );

  // RRM
  yield takeLatest(toSuccess(RrmUtilizationActionType.Create), (action: RrmUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(RrmUtilizationActionType.Update), (action: RrmUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(RrmUtilizationActionType.Delete), (action: RrmUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(RrmUtilizationActionType.StartRanking), (action: RrmUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(RrmUtilizationActionType.RankRecord), (action: RrmUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );

  yield takeLatest(toSuccess(RrmGoalActionType.PerceptionCreate), (action: RrmGoalAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(RrmGoalActionType.PerceptionUpdate), (action: RrmGoalAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(RrmGoalActionType.PerceptionDelete), (action: RrmGoalAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(RrmGoalActionType.PerceptionsReplace), (action: RrmGoalAction) =>
    actionHandler(action.meta.projectId),
  );

  // UIM
  yield takeLatest(toSuccess(UimUtilizationActionType.CreateForProjectTasks), (action: UimUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(UimUtilizationActionType.Update), (action: UimUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(UimUtilizationActionType.Delete), (action: UimUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(UimUtilizationActionType.TaskCreate), (action: UimUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(UimUtilizationActionType.TaskUpdate), (action: UimUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(UimUtilizationActionType.TaskDelete), (action: UimUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(UimUtilizationActionType.TasksReplace), (action: UimUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );

  // EBS
  yield takeLatest(toSuccess(EbsUtilizationActionType.Update), (action: EbsUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(EbsUtilizationActionType.Delete), (action: EbsUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(EbsUtilizationActionType.IdeaCreate), (action: EbsUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(EbsUtilizationActionType.IdeaUpdate), (action: EbsUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(EbsUtilizationActionType.IdeasUpdate), (action: EbsUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(EbsUtilizationActionType.IdeaDelete), (action: EbsUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );

  // 5WHYS
  yield takeLatest(toSuccess(FiveWhysUtilizationActionType.Update), (action: FiveWhysUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(FiveWhysUtilizationActionType.Delete), (action: FiveWhysUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );

  yield takeLatest(toSuccess(FiveWhysCauseActionType.Create), (action: FiveWhysCauseAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(FiveWhysCauseActionType.Update), (action: FiveWhysCauseAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(FiveWhysCauseActionType.Delete), (action: FiveWhysCauseAction) =>
    actionHandler(action.meta.projectId),
  );

  yield takeLatest(toSuccess(FiveWhysSolutionActionType.Create), (action: FiveWhysSolutionAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(FiveWhysSolutionActionType.Update), (action: FiveWhysSolutionAction) =>
    actionHandler(action.meta.projectId),
  );

  // APA
  yield takeLatest(toSuccess(ApaUtilizationActionType.Update), (action: ApaUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(ApaUtilizationActionType.Delete), (action: ApaUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );

  yield takeLatest(toSuccess(ApaActionActionType.Create), (action: ApaActionAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(ApaActionActionType.Update), (action: ApaActionAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(ApaActionActionType.ApaAction_Delete), (action: ApaActionAction) =>
    actionHandler(action.meta.projectId),
  );

  // Ideas
  yield takeLatest(toSuccess(ProjectIdeaActionType.Create), (action: ProjectIdeaAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(ProjectIdeaActionType.Update), (action: ProjectIdeaAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(ProjectIdeaActionType.Delete), (action: ProjectIdeaAction) =>
    actionHandler(action.meta.projectId),
  );

  // 9 windows
  yield takeLatest(toSuccess(NineWindowsUtilizationActionType.Update), (action: NineWindowsUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(NineWindowsUtilizationActionType.Delete), (action: NineWindowsUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );

  // CEC
  yield takeLatest(toSuccess(CecUtilizationActionType.Update), (action: CecUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(CecUtilizationActionType.Delete), (action: CecUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );

  // SFM
  yield takeLatest(
    [
      toSuccess(SfmUtilizationActionType.Update),
      toSuccess(SfmUtilizationActionType.Delete),
      toSuccess(SfmUtilizationActionType.SwitchVersion),
    ],
    (action: SfmUtilizationAction) => actionHandler(action.meta.projectId),
  );

  yield takeLatest(toSuccess(SfmUtilizationActionType.UpdateNetworkData), (action: SfmUtilizationAction) => {
    const payload = action.payload as ISfmUtilization;

    if (payload?.diagramData?.versionId === action.meta.versionId) {
      return actionHandler(action.meta.projectId);
    }
  });

  // PFM
  yield takeLatest(toSuccess(PfmUtilizationActionType.Update), (action: SfmUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(PfmUtilizationActionType.Delete), (action: SfmUtilizationAction) =>
    actionHandler(action.meta.projectId),
  );

  // Perception Map
  yield takeLatest(
    [toSuccess(PMapUtilizationActionType.Update), toSuccess(PMapUtilizationActionType.Delete)],
    (action: PMapUtilizationAction) => actionHandler(action.meta.projectId),
  );

  // CFT
  yield takeLatest(
    [
      toSuccess(CftUtilizationActionType.Update),
      toSuccess(CftUtilizationActionType.Delete),
      toSuccess(CftUtilizationActionType.SwitchVersion),
    ],
    (action: SfmUtilizationAction) => actionHandler(action.meta.projectId),
  );

  yield takeLatest(toSuccess(CftUtilizationActionType.UpdateVersionData), (action: CftUtilizationAction) => {
    const payload = action.payload as ICftUtilization;

    if (payload?.diagramData?.versionId === action.meta.versionId) {
      return actionHandler(action.meta.projectId);
    }
  });

  // Project assignments
  yield takeLatest(toSuccess(ProjectAssignmentActionType.Create), (action: ProjectAssignmentAction) =>
    actionHandler(action.meta.projectId),
  );
  yield takeLatest(toSuccess(ProjectAssignmentActionType.Delete), (action: ProjectAssignmentAction) =>
    actionHandler(action.meta.projectId),
  );
}
