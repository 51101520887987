import { createStyles, makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { alpha, Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      transition: 'opacity .15s ease',
    },

    loading: {
      opacity: 0.6,
      pointerEvents: 'none',
    },

    title: {},

    textContainer: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '100%',
      overflow: 'hidden',
      borderBottomLeftRadius: 'inherit',
      transition: 'background-color .15s ease',

      ['&:last-child']: {
        borderBottomRightRadius: 'inherit',
      },
    },

    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderLeft: `1px solid ${pgColorScheme.gray3}`,
      borderBottomRightRadius: 'inherit',
      transition: 'background-color .15s ease',
    },

    icon: {
      display: 'block',
      color: 'inherit',
      fontSize: 'inherit',
    },

    tabContainer: {
      maxWidth: 'calc(20% - 10px)',
      marginRight: 5,
    },

    tab: {
      display: 'flex',
      alignItems: 'stretch',
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      borderTop: `1px solid ${pgColorScheme.gray3}`,
      color: pgColorScheme.darkText,
    },

    tabDefault: {
      cursor: 'pointer',

      ['& $textContainer, & $iconContainer']: {
        backgroundColor: alpha(pgColorScheme.darkGray, 0.1),

        ['&:hover']: {
          backgroundColor: alpha(pgColorScheme.darkGray, 0.2),
        },
      },
    },

    tabNew: {
      cursor: 'pointer',

      ['& $textContainer, & $iconContainer']: {
        backgroundColor: alpha(pgColorScheme.orange, 0.2),

        ['&:hover']: {
          backgroundColor: alpha(pgColorScheme.orange, 0.4),
        },
      },
    },

    tabActive: {
      ['& $textContainer, & $iconContainer']: {
        backgroundColor: alpha(pgColorScheme.blue, 0.2),
      },

      ['& $iconContainer']: {
        cursor: 'pointer',

        ['&:hover']: {
          backgroundColor: alpha(pgColorScheme.blue, 0.4),
        },
      },
    },

    tabMain: {
      cursor: 'pointer',

      ['& $textContainer, & $iconContainer']: {
        backgroundColor: alpha(pgColorScheme.green, 0.3),

        ['&:hover']: {
          backgroundColor: alpha(pgColorScheme.green, 0.5),
        },
      },
    },

    tabMainActive: {
      ['& $textContainer, & $iconContainer']: {
        backgroundColor: alpha(pgColorScheme.green, 0.8),
      },

      ['& $iconContainer']: {
        cursor: 'pointer',

        ['&:hover']: {
          backgroundColor: alpha(pgColorScheme.green, 1),
        },
      },
    },

    [theme.breakpoints.down('lg')]: {
      root: {
        overflowX: 'auto',
      },
      tabContainer: {
        maxWidth: 250,
      },
    },
  }),
);
