import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaywallSelectors, CustomBlocker } from './store';
import { PlanActions, WorkspaceActions, WorkspaceMembersActions } from '../../../workspace/store/actions';
import { PlanSelectors } from '../../../workspace/store/selectors/plan.selectors';
import { AuthService } from '@priz/shared/src/services/auth';
import { FeatureBlockerDialog } from './feature-blocker-dialog/component';
import { TeamBlockerDialog } from './team-blocker-dialog/component';
import { WorkspaceSelectors } from '../../../workspace/store/selectors';
import { WorkspaceMembersSelectors } from '../../../workspace/store/selectors/workspace-members.selectors';
import { UserSelectors } from '../../../user/store/selectors/user.selectors';
import { UserActions } from '../../../user/store/actions/user.actions';

interface PayWallProps {
  anonymous?: boolean;
}

export const PayWall: React.FC<PayWallProps> = ({ anonymous }) => {
  const dispatch = useDispatch();

  const paywall = useSelector(PaywallSelectors.getLast);

  const isPlanLoaded = useSelector(PlanSelectors.isLoaded);
  const workspacesAreLoaded = useSelector(WorkspaceSelectors.isLoaded);
  const workspaceMembersAreLoaded = useSelector(WorkspaceMembersSelectors.isLoaded);
  const currentUserIsLoaded = useSelector(UserSelectors.isLoaded);

  const isAuthenticated = AuthService.isAuthenticated();

  useEffect(() => {
    if (isAuthenticated && !isPlanLoaded) dispatch(PlanActions.loadAll());
  }, [isPlanLoaded, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !workspacesAreLoaded) dispatch(WorkspaceActions.loadAll());
  }, [workspacesAreLoaded, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !workspaceMembersAreLoaded) {
      dispatch(WorkspaceMembersActions.loadAll());
    }
  }, [workspaceMembersAreLoaded, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !currentUserIsLoaded) dispatch(UserActions.loadCurrentUser());
  }, [currentUserIsLoaded, isAuthenticated]);

  if (paywall?.blocker === CustomBlocker.Seats) {
    return <TeamBlockerDialog />;
  }

  return <FeatureBlockerDialog anonymous={anonymous} />;
};
