import { IdRef } from '../../common/id-ref';
import { AuditableEntity, IAuditableEntity } from '../../common/auditable-entity';
import { Identifiable } from '../../common/entity-collection-state';

export interface IRank extends IAuditableEntity, Identifiable {
  left: IdRef;
  right: IdRef;

  leftRank?: number;
  rightRank?: number;
}

export class Rank extends AuditableEntity implements IRank {
  public static isCompleted = (rank: Rank): boolean =>
    Rank.isValidRankValue(rank.rightRank) && Rank.isValidRankValue(rank.leftRank) && rank.leftRank !== rank.rightRank;

  public static isValidRankValue = (rankValue: number | undefined): boolean => rankValue === 1 || rankValue === 0;

  public id: number;

  public left: IdRef;
  public right: IdRef;

  public leftRank?: number;
  public rightRank?: number;

  constructor(json: IRank) {
    super(json);

    this.id = json.id;

    this.left = json.left;
    this.right = json.right;

    this.leftRank = json.leftRank;
    this.rightRank = json.rightRank;
  }
}
