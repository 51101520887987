import React, { useState } from 'react';
import { PublicUser } from '@priz/shared/src/models/user';
import { TableRow, TableCell, IconButton, Collapse, Box, Grid, Chip } from '@mui/material';
import { format } from 'date-fns';
import { UserAvatar } from '@priz/shared/src/components/user-avatar/component';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { CommissionHistory } from '../commission-history/components';
import { ReferralWorkspaces } from '../referral-workspaces/components';
import { Trans } from 'react-i18next';
import { useStyles } from './styles';

interface ReferralTableRowProps {
  referral: PublicUser;
}

export const ReferralTableRow: React.FC<ReferralTableRowProps> = ({ referral }) => {
  const styles = useStyles();

  const [isHistoryOpen, setIsHistoryOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell className={styles.userInfoCell}>
          <UserAvatar user={referral} emailToColor={true} size={30} />
        </TableCell>

        <TableCell className={styles.userInfoCell}>{referral.email}</TableCell>

        <TableCell className={styles.userInfoCell}>
          {format(referral.profile.dateCreated, 'MMM d yyyy h:mm aaa')}
        </TableCell>

        <TableCell align="right" className={styles.userInfoCell}>
          <IconButton size="small" onClick={() => setIsHistoryOpen(currentState => !currentState)}>
            {isHistoryOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow className={styles.detailsRow}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={isHistoryOpen} timeout="auto" unmountOnExit>
            <Box py={1}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Chip label={<Trans>Workspaces</Trans>} size={'small'} component={Box} mb={1} />
                  <ReferralWorkspaces referralId={referral.id} />
                </Grid>

                <Grid item xs={8}>
                  <Chip label={<Trans>Commissions history</Trans>} size={'small'} component={Box} mb={1} />
                  <CommissionHistory referralId={referral.id} />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
