import React, { useEffect } from 'react';
import { AuthService } from '@priz/shared/src/services/auth';
import { AuthFormType } from '@priz/shared/src/models/auth0';

export const Logout: React.FC = () => {
  useEffect(() => {
    AuthService.logout(AuthFormType.Login);
  });

  return null;
};
