import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { SideNavItem } from '../../shared/section-sidenav-item/component';
import { Project } from '@priz/shared/src/models/project';
import { Badge } from '@mui/material';
import { ProjectFlowService } from '../services';
import { UserSettingsSelectors } from '../../user/store/selectors/user-settings.selectors';
import { UserSettingsActions } from '../../user/store/actions/user-settings.actions';
import { SessionStorageKey, SessionStorageService } from '@priz/shared/src/services/local-storage';
import { TipPopupWrapper } from '../../tip-popup-wrapper/component';
import { copyObject } from '@priz/shared/src/utils/common';
// import {HitsCountLimits} from "@priz/shared/src/models/user/user-settings";

import { ReactComponent as FileIcon } from '../../../assets/icons/file.svg';
import { ReactComponent as FileCheckIcon } from '../../../assets/icons/file-check.svg';

export interface PublishProjectButtonProps {
  project: Project;
  onClick?: () => void;
  popperPlacement?: 'bottom-end' | 'right-start' | 'left-start';
  id?: string;
}

export const PublishProjectButton: React.FC<PublishProjectButtonProps> = ({
  popperPlacement,
  project,
  onClick,
  id,
}) => {
  const dispatch = useDispatch();
  const isProjectOpen = ProjectFlowService.isProjectOpen(project);
  const isProjectCompleted = ProjectFlowService.isProjectCompleted(project);
  const userSettings = useSelector(UserSettingsSelectors.settings);
  // const isLoaded = useSelector(UserSettingsSelectors.isLoaded);
  // const shownInThisSession = SessionStorageService.getItem(SessionStorageKey.NewProjectPublishAdvantagesTipShown);
  // const showTooltips = !project.open && !shownInThisSession && isLoaded;
  // const showPopup = showTooltips && userSettings.tips.publishing.newProjectPublishAdvantages.hitsCount < HitsCountLimits.publishing.newProjectPublishAdvantages;

  useEffect(() => {
    dispatch(UserSettingsActions.load());
  }, []);

  const clickHandler = () => {
    if (onClick) onClick();
  };

  const closeHandler = () => {
    const userSettingsCopy = copyObject(userSettings);

    userSettingsCopy.tips.publishing.newProjectPublishAdvantages.hitsCount += 1;
    userSettingsCopy.tips.publishing.newProjectPublishAdvantages.hitsTotal += 1;

    SessionStorageService.setItem(SessionStorageKey.NewProjectPublishAdvantagesTipShown, 'true');

    dispatch(
      UserSettingsActions.update({
        settings: userSettingsCopy,
      }),
    );
  };

  return (
    <TipPopupWrapper
      open={false}
      title={'Publish your project'}
      text={
        'Publish it on the PrizHub and get unlimited numbers of perceptions and project ideas on Free Plan, upgrade yourself and your team in the community, involve investors, followers and contributors to your projects.'
      }
      onClose={closeHandler}
      popperPlacement={popperPlacement}
    >
      <SideNavItem
        id={id}
        title={<Trans>Publish project</Trans>}
        icon={
          isProjectCompleted && !isProjectOpen ? (
            <Badge variant="dot" color="secondary">
              <FileIcon />
            </Badge>
          ) : isProjectOpen ? (
            <FileCheckIcon />
          ) : (
            <FileIcon />
          )
        }
        navigate={['/project', project.id, 'publish']}
        onClick={clickHandler}
        variant={project.open ? 'green' : undefined}
      />
    </TipPopupWrapper>
  );
};
