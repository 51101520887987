import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      display: 'flex',
      alignItems: 'stretch',
    },
    assistantSvg: {
      height: 200,
      width: 150,
      flex: '0 0 auto',
      marginTop: 'auto',
    },
    content: {
      boxSizing: 'border-box',
      flex: '1 0 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },

    [theme.breakpoints.down('lg')]: {
      assistantSvg: {
        height: 160,
        width: 120,
      },
    },

    [theme.breakpoints.down('sm')]: {
      assistantSvg: {
        height: 150,
        width: 100,
      },
    },
  }),
);
