import React from 'react';
import { Plan, PlanInterval, PlanLevel } from '@priz/shared/src/models/workspace';

import CurrencyFormat from 'react-currency-format';
import { Trans } from 'react-i18next';

interface PlanPriceStringProps {
  plan: Plan;
  level: PlanLevel;
  interval?: PlanInterval;
  decimalScale?: number;
  quantity?: number;
}

export const PlanPriceString: React.FC<PlanPriceStringProps> = ({
  plan,
  level,
  interval,
  decimalScale = 2,
  quantity = 1,
}) => {
  switch (level) {
    case PlanLevel.Personal:
      return <Trans>Free</Trans>;

    case PlanLevel.Enterprise:
      return <Trans>Custom</Trans>;

    default:
      if (!plan) return null;

      return (
        <CurrencyFormat
          value={(interval === PlanInterval.Yearly ? plan.cost / 12 : plan.cost) * quantity}
          displayType={'text'}
          prefix={'$'}
          decimalScale={decimalScale}
          fixedDecimalScale={true}
        />
      );
  }
};
