import { pgColorScheme } from '../../../theme';

/*
  Spacings and paddings are multiplied by theme spacing
  Example: for row spacing 10 result value is '80px'
*/

interface Font {
  fontSize: number;
  letterSpacing: number;
  lineHeight: number;
  fontFamily: string;
}

interface Colors {
  arrows: {
    default: string;
  };
  step: {
    background: string;
    border: string;
  };
  variant: {
    border: string;
    index: string;
  };
  process: {
    background: string;
  };
  statement: {
    background: string;
  };
  abstraction: {
    background: string;
  };
  status: {
    verified: string;
    problematic: string;
    blocker: string;
    default: string;
  };
}

interface Paddings {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

interface NodeProps {
  width: {
    min: number;
    max: number;
  };
  height: {
    min: number;
  };
  paddings: Paddings;
}

interface NodeContainerProps {
  paddings: Paddings;
}

interface SpacingProps {
  row: number;
  col: number;
  branch: number;
  container: number;
}

interface CftDiagramSettings {
  font: {
    default: Font;
    step?: Partial<Font>;
    variant?: Partial<Font>;
    process?: Partial<Font>;
    statement?: Partial<Font>;
    abstraction?: Partial<Font>;
  };
  colors: Colors;
  spacing: SpacingProps;
  node: {
    step: NodeProps;
    statement: NodeProps;
    abstraction: NodeProps;
    variant: NodeContainerProps;
  };
}

export const cftSettings: CftDiagramSettings = {
  font: {
    default: {
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 1.2,
      fontFamily: '"Roboto", sans-serif',
    },
  },
  colors: {
    arrows: {
      default: pgColorScheme.darkText,
    },
    step: {
      background: pgColorScheme.white,
      border: '#2d9bf0',
    },
    variant: {
      border: '#2d9bf0',
      index: pgColorScheme.darkText,
    },
    process: {
      background: '#2d9bf0',
    },
    statement: {
      background: '#fac710',
    },
    abstraction: {
      background: '#8fd14f',
    },
    status: {
      verified: pgColorScheme.green,
      problematic: pgColorScheme.orange,
      blocker: pgColorScheme.coral,
      default: '#2d9bf0',
    },
  },
  spacing: {
    row: 10,
    col: 10,
    branch: 20,
    container: 5,
  },
  node: {
    step: {
      width: { min: 300, max: 450 },
      height: { min: 80 },
      paddings: { top: 2, bottom: 2, right: 3, left: 3 },
    },
    statement: {
      width: { min: 500, max: 750 },
      height: { min: 80 },
      paddings: { top: 2, bottom: 2, right: 3, left: 3 },
    },
    abstraction: {
      width: { min: 500, max: 750 },
      height: { min: 80 },
      paddings: { top: 2, bottom: 2, right: 3, left: 3 },
    },
    variant: {
      paddings: { top: 3, bottom: 7, right: 8, left: 8 },
    },
  },
};
