import React from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import { Info } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { SignupDialogCallerButton } from '../../security/signup-dialog-caller-button/component';
import { Link as RouterLink } from 'react-router-dom';

export const PublicToolAuthMessage: React.FC = () => {
  return (
    <>
      <Grid container alignItems={'center'} justifyContent={'flex-end'} spacing={1}>
        <Grid item>
          <Info color={'primary'} fontSize={'large'} sx={{ display: 'block' }} />
        </Grid>

        <Grid item>
          <Typography component={Box} pr={1}>
            <Trans>Register and log in to save your progress.</Trans> <Trans>If you have an account,</Trans>{' '}
            <Link component={RouterLink} to={'/login'}>
              <b>
                <Trans>login</Trans>
              </b>
            </Link>{' '}
            <Trans>before starting.</Trans>
          </Typography>
        </Grid>

        <Grid item>
          <SignupDialogCallerButton size={'pg_medium'} />
        </Grid>
      </Grid>
    </>
  );
};
