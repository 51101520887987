import React from 'react';
import parse from 'html-react-parser';
import { useStyles } from './styles';

export interface QuillContentViewerProps {
  content: string;
  className?: string;
  autoHeight?: boolean;
}

export const QuillContentViewer: React.FC<QuillContentViewerProps> = ({ content, autoHeight, className }) => {
  const styles = useStyles();
  const rootClassNames = [styles.root, 'ql-container', 'ql-bubble'];

  if (autoHeight) rootClassNames.push('_height-auto');
  if (className) rootClassNames.push(className);

  return (
    <div className={rootClassNames.join(' ')}>
      <div className={'ql-editor'}>{parse(content)}</div>
    </div>
  );
};
