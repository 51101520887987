import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useStyles } from './styles';
import { Principle } from '@priz/shared/src/models/tools/forty-principles/principle';
import { MatrixElement } from '@priz/shared/src/models/tools/forty-principles/matrix-element';
import { Domain } from '@priz/shared/src/models/tools/forty-principles/domain';
import { Parameter } from '@priz/shared/src/models/tools/forty-principles/parameter';
import { CellContent } from '../cell-content/component';
import { TabPanel } from '../../elements/tab-panel/component';

export interface ColumnToDisplay {
  propName: string;
  label?: string;
  editable?: boolean;
}

interface CellFieldsProps {
  row: Partial<Principle> | MatrixElement | Domain | Parameter;
  rowId: string;
  columnsToDisplay: ColumnToDisplay[];
  useQuillForProps: string[];
  onChange: (prop: string, val: string | number) => void;
}

export const CellFields: React.FC<CellFieldsProps> = ({ row, rowId, columnsToDisplay, useQuillForProps, onChange }) => {
  const styles = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (_event, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      {columnsToDisplay.length > 1 && (
        <Box mb={1}>
          <Tabs
            orientation="horizontal"
            variant="standard"
            value={activeTab}
            className={styles.tabs}
            onChange={handleTabChange}
          >
            {columnsToDisplay.map((col, key) => {
              return <Tab key={key} label={col.label || `tab ${key}`} className={styles.tab} />;
            })}
          </Tabs>
        </Box>
      )}

      {columnsToDisplay.map((col, key) => {
        return (
          <TabPanel key={key} index={key} value={activeTab}>
            <CellContent
              row={row}
              rowId={rowId}
              column={col.propName}
              editable={col.editable}
              useQuillForColumns={useQuillForProps}
              onChange={val => onChange(col.propName, val)}
            />
          </TabPanel>
        );
      })}
    </>
  );
};
