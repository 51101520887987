import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import { WorkspaceMember } from '@priz/shared/src/models/workspace';
import { Attendee } from '../store/model';
import { MeetingStatus } from '@priz/shared/src/models/meetings';
import { AssigneeSelectorDialog } from '../../assignment/assignee-selector-dialog/component';
import { WorkspaceMembersSelectors } from '../../workspace/store/selectors/workspace-members.selectors';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { AttendeeItem, AttendeeItemHandlers } from '../attendee-item/component';
import { Trans } from 'react-i18next';

interface AttendeesListHandlers extends AttendeeItemHandlers {
  onAttendeeAdd?: (email: string, userId?: number) => void;
}

interface AttendeesListProps extends AttendeesListHandlers {
  attendees: Attendee[];
  meetingStatus: MeetingStatus;
  disabled?: boolean;
  editable?: boolean;
  viewMode?: boolean;
}

export const AttendeesList: React.FC<AttendeesListProps> = ({
  attendees,
  meetingStatus,
  onAttendeeAdd,
  editable,
  disabled,
  viewMode,
  ...rest
}) => {
  const [assignmentDialogOpen, setAssignmentDialogOpen] = useState(false);

  const workspaceMembers: WorkspaceMember[] = useSelector(WorkspaceMembersSelectors.getAll);
  const isLoading = useSelector(WorkspaceMembersSelectors.isLoading);
  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const excludeIds = [currentUser?.id, ...attendees.map(a => a?.user?.id)].filter(id => typeof id !== 'undefined');

  const handleAssignmentDialogOpen = () => {
    setAssignmentDialogOpen(true);
  };

  const handleAssignmentDialogClose = () => {
    setAssignmentDialogOpen(false);
  };

  const addHandler = (email: string, id?: number) => {
    if (onAttendeeAdd) onAttendeeAdd(email, id);
    handleAssignmentDialogClose();
  };

  return (
    <>
      {!!attendees.length && (
        <Box display={'flex'} flexWrap={'wrap'} alignItems={'center'} mb={editable ? 1 : 0}>
          {attendees.map(attendee => (
            <Box key={attendee.id} mb={1} mr={1} maxWidth={'100%'}>
              <AttendeeItem
                attendee={attendee}
                meetingStatus={meetingStatus}
                disabled={disabled}
                editable={editable}
                viewMode={viewMode}
                {...rest}
              />
            </Box>
          ))}
        </Box>
      )}

      {editable && (
        <Box>
          <Button
            size={'small'}
            onClick={handleAssignmentDialogOpen}
            disabled={!workspaceMembers || disabled || isLoading}
          >
            <Trans>Add attendee</Trans>
          </Button>
        </Box>
      )}

      {editable && (
        <AssigneeSelectorDialog
          isOpen={assignmentDialogOpen}
          title={'Add an attendee'}
          onMemberAssign={member => {
            addHandler(member.email, member.id);
          }}
          onInvite={addHandler}
          onClose={handleAssignmentDialogClose}
          excludeIds={excludeIds}
        />
      )}
    </>
  );
};
