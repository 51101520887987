import { AppState } from '../../../store/app.state';
import { createSelector, Selector } from 'reselect';
import { EntityCollectionStatus, EntityMap } from '@priz/shared/src/models/common/entity-collection-state';
import {
  AssigneesMap,
  ProjectsAssigneesMap,
  ProjectAssignee,
  ProjectAssigneeWithMember,
} from '@priz/shared/src/models/assignment';
import { WorkspaceMembersSelectors } from '../../../workspace/store/selectors/workspace-members.selectors';

const projectAssigneeCollectionSelector = (state: AppState) => state.projectAssignees;

const projectAssigneeEntitiesSelector: Selector<AppState, EntityMap<ProjectAssignee>> = createSelector(
  projectAssigneeCollectionSelector,
  collection => collection.entities,
);

const projectAssigneeLookupByProjectIdSelector = (projectId: number): Selector<AppState, number[]> =>
  createSelector(projectAssigneeCollectionSelector, collection => collection.lookups.byProjectId[projectId] || []);

const projectAssigneeStatusSelector: Selector<AppState, EntityCollectionStatus> = createSelector(
  projectAssigneeCollectionSelector,
  collection => collection.statuses,
);

const isLoaded: Selector<AppState, boolean> = createSelector(
  projectAssigneeStatusSelector,
  statuses => statuses.loaded,
);

const isLoading: Selector<AppState, boolean> = createSelector(
  projectAssigneeStatusSelector,
  statuses => statuses.loading,
);

const getProjectAssignees = (projectId: number): Selector<AppState, ProjectAssigneeWithMember[]> =>
  createSelector(
    [
      projectAssigneeLookupByProjectIdSelector(projectId),
      projectAssigneeEntitiesSelector,
      WorkspaceMembersSelectors.getWorkspaceMembersEntityMap,
    ],
    (ids, assignees, members) =>
      ids.reduce((arr, id) => {
        const assignee = assignees[id];
        const member = assignee && members[assignee.userId];

        if (assignee && member) {
          arr.push({ ...assignee, user: member } as ProjectAssigneeWithMember);
        }
        return arr;
      }, []),
  );

const getProjectAssigneesWithMemberMap = (
  projectId: number,
): Selector<AppState, { [key: number]: ProjectAssigneeWithMember }> =>
  createSelector(getProjectAssignees(projectId), assigneesArray =>
    assigneesArray.reduce((map: { [key: number]: ProjectAssigneeWithMember }, assignee) => {
      map[assignee.userId] = assignee;
      return map;
    }, {}),
  );

const getAllProjectsAssigneesMap: Selector<AppState, ProjectsAssigneesMap> = createSelector(
  [projectAssigneeEntitiesSelector, WorkspaceMembersSelectors.getWorkspaceMembersEntityMap],
  (assignees, members) => {
    const map = {};

    Object.values(assignees).forEach(assignee => {
      const member = assignee && members[assignee.userId];

      if (member) {
        if (!map[assignee.projectId]) map[assignee.projectId] = {};
        map[assignee.projectId][assignee.userId] = { ...assignee, user: member } as ProjectAssigneeWithMember;
      }
    });

    return map;
  },
);

const getAllAssigneesMap: Selector<AppState, AssigneesMap> = createSelector(
  [projectAssigneeEntitiesSelector, WorkspaceMembersSelectors.getWorkspaceMembersEntityMap],
  (assignees, members) => {
    const map = {};

    Object.values(assignees).forEach(assignee => {
      const member = assignee && members[assignee.userId];

      if (member) {
        map[assignee.userId] = { ...assignee, user: member } as ProjectAssigneeWithMember;
      }
    });

    return map;
  },
);

export const ProjectAssigneeSelectors = {
  isLoaded,
  isLoading,
  getAllAssigneesMap,
  getAllProjectsAssigneesMap,
  getProjectAssigneesWithMemberMap,
  getProjectAssignees,
};
