import React, { useState } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { Workspace } from '@priz/shared/src/models/workspace';

interface CommentProps {
  workspace: Workspace;
  onUpdateMfa: (workspaceId: number, value: boolean) => void;
}

export const AdminWorkspaceCard: React.FC<CommentProps> = ({ workspace, onUpdateMfa }) => {
  const [isMfaConfirmationOpen, setIsMfaConfirmationOpen] = useState(false);

  const updateMfaHandler = () => {
    closeMfaConfirmation();
    onUpdateMfa(workspace.id, !workspace.allowToUpdateMfa);
  };

  const openMfaConfirmation = () => {
    setIsMfaConfirmationOpen(true);
  };

  const closeMfaConfirmation = () => {
    setIsMfaConfirmationOpen(false);
  };

  return (
    <Paper variant={'outlined'} component={Box} p={2}>
      <Typography variant={'h6'}>{workspace.name}</Typography>

      {[
        { label: 'Id', value: workspace.id },
        { label: 'Billing Email', value: workspace.billingEmail },
        { label: 'Plan Level', value: workspace.type },
        { label: 'Allowed to use MFA', value: workspace.allowToUpdateMfa.toString() },
        { label: 'MFA enabled', value: workspace.mfa.toString() },
      ].map((item, key) => (
        <Typography key={key} variant={'body2'} component={Box} mt={1}>
          <Trans>{item.label}</Trans>: <b>{item.value}</b>
        </Typography>
      ))}

      <Box mt={2}>
        <Button
          variant={'outlined'}
          onClick={openMfaConfirmation}
          color={workspace.allowToUpdateMfa ? 'error' : 'primary'}
        >
          <Trans>{workspace.allowToUpdateMfa ? 'Disable MFA settings' : 'Enable MFA settings'}</Trans>
        </Button>
      </Box>

      <PgConfirmationDialog
        isOpen={isMfaConfirmationOpen}
        confirmTitle={
          <Trans>
            {workspace.allowToUpdateMfa ? 'Confirm MFA settings disabling ' : 'Confirm MFA settings enabling'}
          </Trans>
        }
        okButtonText={<Trans>{workspace.allowToUpdateMfa ? 'Disable' : 'Enable'}</Trans>}
        confirmContent={
          <React.Fragment>
            <Trans>
              {workspace.allowToUpdateMfa
                ? 'Are you sure you want to disable MFA settings?'
                : 'Are you sure you want to enable MFA settings?'}
            </Trans>
          </React.Fragment>
        }
        onConfirm={updateMfaHandler}
        onClose={closeMfaConfirmation}
      />
    </Paper>
  );
};
