import { IToolUtilization, ToolUtilization } from '../index';
import { IUimUtilization } from '../uim';

export interface IEbsUtilization extends IToolUtilization {
  grouped?: boolean;
}

export class EbsUtilization extends ToolUtilization implements IUimUtilization {
  public grouped?: boolean;

  constructor(json: IEbsUtilization) {
    super(json);

    this.grouped = json.grouped;
  }
}
