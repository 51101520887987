import { RouterActionMeta } from '../../../shared/store/types';
import { FluxStandardAction } from 'flux-standard-action';
import { createPgAction } from '../../../shared/store/action-creator';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { ToolType } from '@priz/shared/src/models/tools';
import { IPfmUtilization, PfmUtilization } from '@priz/shared/src/models/tools/pfm';
import { PfmUtilizationApi } from '../services';
import { NavigateFunction } from 'react-router-dom';
import { ToolsUtils } from '../../../tools/utils';

export enum PfmUtilizationActionType {
  Update = 'pfm-utilization/update',
  Delete = 'pfm-utilization/delete',
}

type PfmUtilizationActionPayload = IPfmUtilization;

interface PfmUtilizationActionMeta extends RouterActionMeta {
  utilization?: IPfmUtilization;
  utilizationId?: number;
  projectId?: number;
  navigate?: NavigateFunction;
}

export type PfmUtilizationAction = FluxStandardAction<string, PfmUtilizationActionPayload, PfmUtilizationActionMeta>;

const update = createPgAction(
  PfmUtilizationActionType.Update,
  PfmUtilizationApi.update,
  (utilizationId: number, _data: Partial<PfmUtilization>, projectId?: number) => ({ projectId, utilizationId }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.PFM,
      utilization_id: utilizationId,
    });
  },
);

const doDelete = createPgAction(
  PfmUtilizationActionType.Delete,
  PfmUtilizationApi.delete,
  (utilizationId: number, projectId?: number, navigate?: NavigateFunction) => ({ projectId, utilizationId, navigate }),
  (_payload, { projectId, utilizationId, navigate }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_DELETED, {
      project_id: projectId,
      tool_type: ToolType.PFM,
      utilization_id: utilizationId,
    });

    if (navigate) {
      navigate(ToolsUtils.resolveListPath(projectId));
    }
  },
);

export const PfmUtilizationActions = {
  update,
  delete: doDelete,
};
