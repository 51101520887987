import React, { useEffect, useState } from 'react';
import { ProfileCompletionForm } from '../profile-completion-form/component';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { UserSettingsSelectors } from '../../user/store/selectors/user-settings.selectors';
import { UserSettingsActions } from '../../user/store/actions/user-settings.actions';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { Close } from '@mui/icons-material';
import { copyObject } from '@priz/shared/src/utils/common';
import { useExcludedPaths } from '../../utils';
import { useStyles } from './styles';
import { ProjectFeatureBlockerSelectors } from '../../project-feature-blocker/store/selectors';
import { AxiosService } from '@priz/shared/src/services/axios';

import { ReactComponent as BackgroundImage } from '../../../assets/img/projects-list-pg.svg';
import { DialogsSelectors } from '../../dialogs/store/selectors';

export const ProfileCompletionDialog: React.FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [isExcluded] = useExcludedPaths(['-callback', 'billing']);

  const [open, setOpen] = useState(false);
  const [closeDialogAfterUpdating, setCloseDialogAfterUpdating] = useState(false);

  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const userSettings = useSelector(UserSettingsSelectors.settings);
  const areUserSettingsLoaded = useSelector(UserSettingsSelectors.isLoaded);
  const areUserSettingsUpdating = useSelector(UserSettingsSelectors.isUpdating);
  const areUserSettingsUpdated = useSelector(UserSettingsSelectors.isUpdated);
  const isProjectFeatureBlockerActive = useSelector(ProjectFeatureBlockerSelectors.isBlockerActive);
  const isPlanUpgradeDialogActive = useSelector(DialogsSelectors.isPlanUpgradeDialogOpenActive);

  useEffect(() => {
    if (currentUser && !areUserSettingsLoaded) {
      dispatch(UserSettingsActions.load());
    }
  }, [currentUser, areUserSettingsLoaded]);

  useEffect(() => {
    const isProfileCompleted = !!userSettings?.global?.userProfileCompleted;
    const isAnonymous = AxiosService.isAnonymousAccessEnabled();
    const isBlocked = isExcluded || isProjectFeatureBlockerActive || isAnonymous || isPlanUpgradeDialogActive;

    if (areUserSettingsLoaded && !isProfileCompleted && !isBlocked) {
      openDialog();
    }
  }, [isExcluded, isProjectFeatureBlockerActive, areUserSettingsLoaded, isPlanUpgradeDialogActive]);

  useEffect(() => {
    if (closeDialogAfterUpdating && areUserSettingsUpdated) {
      closeDialog();
    }
  }, [closeDialogAfterUpdating, areUserSettingsUpdated]);

  const completeProfile = () => {
    const userSettingsCopy = copyObject(userSettings);

    userSettingsCopy.global.userProfileCompleted = true;

    dispatch(UserSettingsActions.update({ settings: userSettingsCopy }));
    setCloseDialogAfterUpdating(true);
  };

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  if (isExcluded) {
    return null;
  }

  return (
    <Dialog open={open} maxWidth={'md'} fullWidth>
      <DialogTitle className={styles.dialogTitle}>
        <Box pt={{ xs: 3, sm: 0 }}>
          <Trans>Complete your user profile</Trans>
        </Box>

        <IconButton
          onClick={completeProfile}
          disabled={areUserSettingsUpdating}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent className={styles.dialogContent}>
        <ProfileCompletionForm onComplete={closeDialog} pb={3} />
      </DialogContent>

      <BackgroundImage className={styles.dialogBackground} />
    </Dialog>
  );
};
