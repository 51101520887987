import { RrmUtilization } from '@priz/shared/src/models/tools/rrm/rrm-utilization';
import { Rank } from '@priz/shared/src/models/tools/rrm';

const isRankingStarted = (rrmUtilization: RrmUtilization): boolean => rrmUtilization.ranks.length > 0;
const isRankingCompleted = (rrmUtilization: RrmUtilization): boolean =>
  calculatePercentageComplete(rrmUtilization) === 100;

const findNextEmptyRandomRank = (ranks: Rank[], currentNextRankId?: number): Rank => {
  const notCompletedAvailableRanks = ranks.filter(r => !Rank.isCompleted(r) && currentNextRankId !== r.id);

  return notCompletedAvailableRanks[Math.floor(Math.random() * notCompletedAvailableRanks.length)];
};

const calculatePercentageComplete = (rrmUtilization: RrmUtilization) => {
  const totalRanksCount = rrmUtilization.ranks.length;
  const rankedRanksCount = rrmUtilization.ranks.filter(r => Rank.isCompleted(r)).length;

  return (rankedRanksCount / totalRanksCount) * 100;
};

export const RrmUtilizationService = {
  calculatePercentageComplete,
  findNextEmptyRandomRank,
  isRankingStarted,
  isRankingCompleted,
};
