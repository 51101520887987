import { ToolType } from '../tools';
import { IdRef } from '../common/id-ref';
import { AuditableEntity, IAuditableEntity } from '../common/auditable-entity';
import { Identifiable } from '../common/entity-collection-state';
import { TaskStatus } from './task-status.enum';
import { UimFlawCost, UimFlawExpectancy, UimPriority } from '../tools/uim';

export interface ITask extends IAuditableEntity, Identifiable {
  title: string;
  details?: string;
  resolution?: string;
  status: TaskStatus;
  dueDate?: Date;
  solvingProblem?: boolean;
  latestRankingScore?: number;

  project: IdRef;
  workspace: IdRef;

  sourceId?: string;
  sourceToolType?: ToolType;
  sourceToolUtilization?: IdRef;

  flawExpectancy?: UimFlawExpectancy;
  flawCost?: UimFlawCost;
  priority?: UimPriority;
}

export class Task extends AuditableEntity implements ITask {
  public id: number;
  public title: string;
  public details?: string;
  public resolution?: string;
  public status: TaskStatus;
  public dueDate?: Date;
  public solvingProblem?: boolean = false;
  public latestRankingScore?: number;

  public project: IdRef;
  public workspace: IdRef;

  public sourceId?: string;
  public sourceToolType?: ToolType;
  public sourceToolUtilization?: IdRef;

  public flawExpectancy?: UimFlawExpectancy;
  public flawCost?: UimFlawCost;
  public priority?: UimPriority;

  constructor(json: ITask) {
    super(json);

    this.id = json.id;
    this.title = json.title;
    this.details = json.details;
    this.resolution = json.resolution;
    this.status = json.status;
    this.dueDate = json.dueDate && new Date(json.dueDate);
    this.solvingProblem = json.solvingProblem;
    this.latestRankingScore = json.latestRankingScore;
    this.project = json.project;
    this.workspace = json.workspace;
    this.sourceId = json.sourceId;
    this.sourceToolType = json.sourceToolType;
    this.sourceToolUtilization = json.sourceToolUtilization;
    this.flawExpectancy = json.flawExpectancy;
    this.flawCost = json.flawCost;
    this.priority = json.priority || UimPriority.Undefined;
  }
}
