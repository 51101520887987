import React from 'react';
import { Project } from '@priz/shared/src/models/project';
import { Divider, List, Box, useTheme, useMediaQuery, Grid, MenuItem } from '@mui/material';
import { SideNavItem } from '../../shared/section-sidenav-item/component';
import { ButtonCreateNew } from '../../react/elements/button-create-new/component';
import { Trans } from 'react-i18next';
import { ProjectSaveStatus } from '../project-save-status/component';
import { ProjectFlowService } from '../services';
import { useStyles } from './styles';
import { ListItemWithMenu } from '../../react/elements/list-item-with-menu/component';
import { CompleteProject } from '../complete-project/component';
import { MarkProjectAsInProgress } from '../mark-project-as-in-progress/component';
import { PublishProjectButton } from '../publish-project-button/component';
import { ProjectPublishCountdownButton } from '../project-publish-countdown-button/component';
import { ProjectGlobeButton } from '../project-globe-button/component';
import { TasksMenuItem } from '../../tasks/tasks-menu-item/component';
import { MeetingsMenuItem } from '../../meetings/meetings-menu-item/component';
import { TemplateChangeDialogContainer } from '../template/template-change-dialog-container/component';
import { MediationRounded } from '@mui/icons-material';
import { useFlag } from '@unleash/proxy-client-react';
import { UnleashFeatureName } from '@priz/shared/src/services/unleash';

import { ReactComponent as HamburgerIcon } from '../../../assets/icons/pg-hamburger.svg';
import { ReactComponent as LightbulbIcon } from '../../../assets/icons/pg-lightbulb.svg';
import { ReactComponent as ToolsIcon } from '../../../assets/icons/pg-tools.svg';
import { ReactComponent as OverviewIcon } from '../../../assets/icons/project-overview.svg';
import { ReactComponent as PdfIcon } from '../../../assets/icons/pdf.svg';
import { ReactComponent as FlagDoneIcon } from '../../../assets/icons/flag-done.svg';

export interface ProjectMenuProps {
  project: Project;
  collapse: boolean;
  navItemClickHandler?: () => void;
}

export const ProjectMenu: React.FC<ProjectMenuProps> = ({ project, collapse, navItemClickHandler }) => {
  const theme = useTheme();
  const styles = useStyles();
  const isTemplatesEnabled = useFlag(UnleashFeatureName.Templates);
  const isUpLgBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));
  const isDownSmBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  const isProjectCompleted = ProjectFlowService.isProjectCompleted(project);
  const isProjectLocked = ProjectFlowService.isProjectLocked(project);
  const minifyElements = collapse && !isUpLgBreakpoint && !isDownSmBreakpoint;

  const clickHandler = () => {
    if (navItemClickHandler) navItemClickHandler();
  };

  return (
    <>
      <Box p={1}>
        <Grid container alignItems={'center'} spacing={minifyElements ? 2 : 1}>
          <Grid item>
            <ProjectSaveStatus projectId={project.id} useIcon={minifyElements} />
          </Grid>

          {project.open && (
            <>
              <Grid
                item
                className={`${styles.countdownButtonWrap} ${minifyElements ? styles.collapsedMenuMinWidth : ''}`}
              >
                <ProjectGlobeButton />
              </Grid>

              <Grid
                item
                className={`${styles.countdownButtonWrap} ${minifyElements ? styles.collapsedMenuMinWidth : ''}`}
              >
                <ProjectPublishCountdownButton project={project} />
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      <Box p={1}>
        <ButtonCreateNew projectId={project.id} disabled={isProjectLocked} />
      </Box>

      <List className={styles.list}>
        {isTemplatesEnabled && project?.template && (
          <>
            <SideNavItem
              id={'project-template-navitem'}
              title={<Trans>Template</Trans>}
              icon={<MediationRounded />}
              navigate={['/project', project.id, 'template']}
              onClick={clickHandler}
            />

            <Divider style={{ width: '100%' }} />
          </>
        )}

        <SideNavItem
          id={'project-overview-navitem'}
          title={<Trans>Overview</Trans>}
          icon={<OverviewIcon />}
          navigate={['/project', project.id, 'overview']}
          onClick={clickHandler}
        />

        <Divider style={{ width: '100%' }} />

        <PublishProjectButton
          project={project}
          onClick={clickHandler}
          popperPlacement={isDownSmBreakpoint || isUpLgBreakpoint ? 'left-start' : 'bottom-end'}
          id={'project-publish-navitem'}
        />

        <Divider style={{ width: '100%' }} />

        <TasksMenuItem projectId={project.id} onClick={clickHandler} id={'project-tasks-navitem'} />

        <Divider style={{ width: '100%' }} />

        <MeetingsMenuItem projectId={project.id} onClick={clickHandler} id={'project-meetings-navitem'} />

        <Divider style={{ width: '100%' }} />

        <SideNavItem
          id={'project-problem-statement-navitem'}
          title={<Trans>Problem statement</Trans>}
          icon={<HamburgerIcon />}
          navigate={['/project', project.id, 'problem-statement']}
          onClick={clickHandler}
        />

        <Divider style={{ width: '100%' }} />

        <SideNavItem
          id={'project-tools-navitem'}
          title={<Trans>Creative tools</Trans>}
          icon={<ToolsIcon />}
          navigate={['/project', project.id, 'tools', 'list']}
          onClick={clickHandler}
        />

        <Divider style={{ width: '100%' }} />

        <SideNavItem
          id={'project-ideas-navitem'}
          title={<Trans>Ideas</Trans>}
          icon={<LightbulbIcon />}
          navigate={['/project', project.id, 'ideas']}
          onClick={clickHandler}
        />

        <Divider style={{ width: '100%' }} />

        <SideNavItem
          id={'project-solution-navitem'}
          title={<Trans>Propose solution</Trans>}
          icon={<FlagDoneIcon />}
          navigate={['/project', project.id, 'solution']}
          onClick={clickHandler}
        />

        <Divider style={{ width: '100%' }} />

        <SideNavItem
          id={'project-report-navitem'}
          title={<Trans>Report</Trans>}
          icon={<PdfIcon />}
          navigate={['/project', project.id, 'report']}
          onClick={clickHandler}
        />

        <Divider style={{ width: '100%' }} />

        <CompleteProject projectId={project.id} disabled={isProjectLocked} completed={isProjectCompleted} />

        <Divider style={{ width: '100%' }} />

        <Box mt={'auto'} width={'100%'} pb={11}>
          <ListItemWithMenu>
            {isProjectCompleted && <MarkProjectAsInProgress projectId={project.id} />}

            {isTemplatesEnabled && (
              <TemplateChangeDialogContainer projectId={project.id} disabled={isProjectLocked}>
                <MenuItem disabled={isProjectLocked}>
                  <Trans>{project.template ? 'Change project type' : 'Define project type'}</Trans>
                </MenuItem>
              </TemplateChangeDialogContainer>
            )}
          </ListItemWithMenu>
        </Box>
      </List>
    </>
  );
};
