import { environment } from '@priz/shared/src/environments/environment';
import axios, { AxiosResponse } from 'axios';
import { Domain } from '@priz/shared/src/models/tools/forty-principles/domain';
import { Principle } from '@priz/shared/src/models/tools/forty-principles/principle';
import { DomainPrinciple } from '@priz/shared/src/models/tools/forty-principles/domain-principle';

const loadDomains = (): Promise<Domain[]> =>
  axios.get(`${environment.apiDomain}/v1/api/40p/domains`).then((response: AxiosResponse<Domain[]>) => response.data);

export interface UpdateDomainCommand {
  title: string;
  titleZh?: string;
  titleEs?: string;
}

const updateDomain = (domainId: number, command: UpdateDomainCommand): Promise<Domain> =>
  axios
    .put(`${environment.apiDomain}/v1/api/40p/domain/${domainId}`, command)
    .then((response: AxiosResponse<Principle>) => response.data);

const createDomain = (title: string): Promise<{ domain: Domain; domainPrinciples: DomainPrinciple[] }> =>
  axios
    .post(`${environment.apiDomain}/v1/api/40p/domain`, {
      title,
    })
    .then((response: AxiosResponse<{ domain: Domain; domainPrinciples: DomainPrinciple[] }>) => response.data);

const deleteDomain = (domainId: number): Promise<{ domain: Domain; domainPrinciples: DomainPrinciple[] }> =>
  axios
    .delete(`${environment.apiDomain}/v1/api/40p/domain/${domainId}`)
    .then((response: AxiosResponse<{ domain: Domain; domainPrinciples: DomainPrinciple[] }>) => response.data);

export const DomainService = {
  loadDomains,
  updateDomain,
  createDomain,
  deleteDomain,
};
