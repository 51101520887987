import React from 'react';
import {
  CftNodeData,
  CftNodePosition,
  CftNodesMap,
  OptionalCftNodeControlsRenderer,
  OptionalCftNodeTextEditorRenderer,
} from '../../../models/tools/cft';
import { CftDataUtils } from '../../../utils/cft';
import { useStyles } from './styles';
import { Box, BoxProps } from '@mui/material';
import { CftStep } from '../cft-step/component';
import { cftSettings } from '../data';

interface CftProcessProps
  extends BoxProps,
    CftNodePosition,
    OptionalCftNodeControlsRenderer,
    OptionalCftNodeTextEditorRenderer {
  node: CftNodeData;
  nodesMap: CftNodesMap;
}

export const CftProcess: React.FC<CftProcessProps> = ({
  node,
  nodesMap,
  controlsRenderer,
  editorRenderer,
  inRootBranch,
  ...rest
}) => {
  const styles = useStyles();
  const tree = CftDataUtils.resolveTree(nodesMap, node.id);

  return (
    <Box id={node.id} className={styles.root} {...rest}>
      <Box display={'flex'} alignItems={'center'} mb={2}>
        <div className={styles.titleContainer}>
          {editorRenderer && editorRenderer({ node, inRootBranch, className: styles.title })}

          {!editorRenderer && <div className={styles.title}>{node.description}</div>}
        </div>

        {controlsRenderer && controlsRenderer({ node, inRootBranch, className: styles.controls })}
      </Box>

      <Box className={styles.stepsContainer}>
        {tree.map((level, levelKey) => (
          <CftStep
            key={levelKey}
            mt={levelKey ? cftSettings.spacing.row : 0}
            node={level.nodes[0]}
            inProcess={true}
            controlsRenderer={controlsRenderer}
            editorRenderer={editorRenderer}
          />
        ))}
      </Box>
    </Box>
  );
};
