import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { RrmUtilization } from '@priz/shared/src/models/tools/rrm/rrm-utilization';
import { Perception, RrmGoalType } from '@priz/shared/src/models/tools/rrm';
import { RrmGoalActions } from '../store';
import { RrmGoalSelector } from '../store/selectors';
import { Trans } from 'react-i18next';
import { ToolTitle } from '../../tools/tool-title/component';
import { ToolType } from '@priz/shared/src/models/tools';
import { FeatureSetSelectors } from '../../workspace/store/selectors/feature-set.selectors';
import { PaywallActions } from '../../react/modules/paywall/store';
import { ProjectSelector, ToolUtilizationSelector } from '../../project/store/selectors';
import { ArrayUtils, SortDirection } from '@priz/shared/src/utils/common';
import { CsvDataSelector, CSVImportType } from '../../csv-data-selector/component';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { LinearLoader } from '../../react/elements/linear-loader/component';
import { StartRankingButton } from '../start-ranking-button/component';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { RrmPerception } from '../rrm-perception/component';
import { ToolUtilizationActionType } from '../../project/store/actions/tools.actions';

interface RrmPerceptionManagerProps {
  utilization: RrmUtilization;
}

export const RrmPerceptionManager: React.FC<RrmPerceptionManagerProps> = ({ utilization }) => {
  const dispatch = useDispatch();
  const perceptionForm = React.createRef<HTMLFormElement>();

  const [newPerception, setNewPerception] = useState<string>(undefined);
  const [perceptions, setPerceptions] = useState<Perception[]>([]);
  const [isImporting, setIsImporting] = useState(false);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);

  const goal = useSelector(RrmGoalSelector.getGoalById(utilization && utilization.goal.id));
  const featureSet = useSelector(WorkspaceSelectors.getApplicableFeatureSet(utilization?.project?.id));

  const isUpdating = useSelector(RrmGoalSelector.isUpdatingByGoalId(utilization && utilization.goal.id));
  const isRrmLimitHit = useSelector(FeatureSetSelectors.isRrmLimitHit(utilization));
  const isProjectLocked = useSelector(ProjectSelector.isProjectLockedById(utilization?.project?.id));
  const isCreatingByGoalId = useSelector(RrmGoalSelector.isCreatingByGoalId(goal?.id));
  const isCompletenessUpdating = useSelector(
    ToolUtilizationSelector.isPendingByActionType(utilization?.id, ToolUtilizationActionType.UpdateCompleteness),
  );

  const isDisabled = !!utilization?.completed || isCompletenessUpdating || isProjectLocked;

  useEffect(() => {
    if (isImporting && !isUpdating) {
      setIsImporting(false);
      closeImportDialog();
    }
  }, [isImporting, isUpdating]);

  useEffect(() => {
    if (goal) {
      setPerceptions(goal.perceptions);
    }
  }, [goal]);

  const createPerceptionHandler = (e?: FormEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (isRrmLimitHit) {
      dispatch(PaywallActions.show(ToolType.RRM));
    } else {
      dispatch(
        RrmGoalActions.createPerception(utilization.id, newPerception, utilization.goal.id, utilization.project?.id),
      );
      setNewPerception('');
    }
  };

  const importHandler = (items: string[], importType: CSVImportType, limitHit: boolean) => {
    if (items.length) {
      const isAdding = importType === CSVImportType.Add;

      dispatch(
        RrmGoalActions.replacePerceptions(
          utilization.id,
          items,
          isAdding ? null : perceptions.map(p => p.id),
          goal.id,
          utilization.project?.id,
        ),
      );

      setIsImporting(true);
    }

    if (limitHit) {
      dispatch(PaywallActions.show(ToolType.RRM));
    }
  };

  const perceptionValueChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNewPerception(event.target.value);
  };

  const openImportDialog = () => {
    setIsImportDialogOpen(true);
  };

  const closeImportDialog = () => {
    setIsImportDialogOpen(false);
  };

  return (
    <>
      <ContentContainer>
        <PageTitleWithDocLink
          titleComponent={
            <ToolTitle
              initialTitle={'Items or options for ranking'}
              tool={utilization}
              preset={'large'}
              editable={!isDisabled}
            />
          }
          oneLiner={<Trans>List all the options you interested in ranking</Trans>}
          docsUrl={'https://www.priz.guru/knowledge-base/round-robin-ranking/'}
        />

        <Box>
          <Box mb={1}>
            <CsvDataSelector
              open={isImportDialogOpen}
              onOpen={openImportDialog}
              onClose={closeImportDialog}
              onImport={importHandler}
              planLimit={featureSet?.Tools?.RRM?.maxItems}
              currentItemsCount={perceptions.length}
              loading={isImporting}
              disabled={isDisabled}
            />
          </Box>

          <form onSubmit={createPerceptionHandler} ref={perceptionForm}>
            <Grid container alignItems={'center'} spacing={1}>
              <Grid item xs={12} sm={true}>
                <TextField
                  disabled={isDisabled}
                  id="rrm-new-perception"
                  multiline
                  value={newPerception}
                  onChange={perceptionValueChangeHandler}
                  variant={'outlined'}
                  fullWidth={true}
                  onKeyDown={event => {
                    if (event.ctrlKey && event.key === 'Enter') {
                      createPerceptionHandler();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={'auto'} textAlign={'right'}>
                <Button
                  variant={'pg_rounded'}
                  color={'pg_orange_solid'}
                  disabled={!newPerception || isDisabled}
                  type={'submit'}
                >
                  <Trans>Add</Trans>
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>

        <Box mt={3}>
          {!perceptions.length && <LinearLoader loading={isCreatingByGoalId} />}

          {!!perceptions.length && (
            <>
              <Box mb={1}>
                <Typography variant={'h6'}>
                  <Trans>Options in this session</Trans>
                </Typography>
              </Box>

              <LinearLoader loading={isCreatingByGoalId} />

              <Grid container direction="column" justifyContent="space-between" alignItems="stretch" spacing={2}>
                {perceptions.sort(ArrayUtils.sorterByParam('rankingScore', SortDirection.DESC)).map(p => (
                  <Grid item key={p.id}>
                    <RrmPerception utilization={utilization} perception={p} disabled={isDisabled} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Box>
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={utilization.project?.id}
          utilization={utilization}
          disabled={isDisabled}
          rightColContent={
            <StartRankingButton
              projectId={utilization.project?.id}
              utilizationId={utilization.id}
              goalType={RrmGoalType.SELF}
              goalId={utilization.goal.id}
              itemsCount={perceptions?.length}
              buttonText={'Rank options'}
              buttonProps={{
                color: 'pg_orange_solid',
              }}
              disabled={perceptions?.length < 3 || isDisabled}
            />
          }
          workingFlowProps={{ nextVariant: 'ideas' }}
        />
      </ContentFooter>
    </>
  );
};
