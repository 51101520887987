import { IdeaParameterFactor } from '../store/model/idea-parameter-response';
import { Box, Typography } from '@mui/material';
import { IdeaParameterType } from '@priz/shared/src/models/idea';
import { Trans } from 'react-i18next';

export const resolveIdeaParametersContent = (parameters: IdeaParameterFactor[], parameter: IdeaParameterType) => {
  const ideaParameterTypeTextMap: { [key in IdeaParameterType]: string } = {
    [IdeaParameterType.gain]: 'gain',
    [IdeaParameterType.cost]: 'cost',
    [IdeaParameterType.risk]: 'risk',
  };

  return (
    <>
      <Typography variant={'h6'} component={Box} mb={2}>
        <Trans>{`Factors that may affect ${ideaParameterTypeTextMap[parameter]}`}</Trans>
      </Typography>

      {parameters.map((parameter, key) => (
        <Box key={key} mb={2}>
          <Typography variant={'body1'} component={Box} mb={1}>
            <b>{parameter.factor}</b>
          </Typography>

          <Typography variant={'body2'}>{parameter.description}</Typography>
        </Box>
      ))}
    </>
  );
};
