import { createSelector, Selector } from 'reselect';
import { ToolType } from '@priz/shared/src/models/tools';
import { ArrayUtils } from '@priz/shared/src/utils/common';
import { AppState } from '../../../store/app.state';
import { Task, TaskCollection, TaskStatus } from '@priz/shared/src/models/task';

const taskCollectionSelector = (state: AppState): TaskCollection => state.tasks;

const tasksEntitiesSelector = createSelector(taskCollectionSelector, collection => collection.entities);

const tasksLookupSelector = createSelector(taskCollectionSelector, collection => collection.lookups);

const taskIdsByProjectIdSelector = (projectId: number) =>
  createSelector(tasksLookupSelector, lookup => lookup.byProjectId[projectId] || []);

const tasksStatusesSelector = createSelector(taskCollectionSelector, collection => collection.statuses);

const isCreating = (projectId: number): Selector<AppState, boolean> =>
  createSelector(tasksStatusesSelector, statuses => statuses.byProjectId[projectId]?.creating || false);

const isCreated = (projectId: number): Selector<AppState, boolean> =>
  createSelector(tasksStatusesSelector, statuses => statuses.byProjectId[projectId]?.created || false);

const isLoading = (projectId: number): Selector<AppState, boolean> =>
  createSelector(tasksStatusesSelector, statuses => statuses.byProjectId[projectId]?.loading || false);

const isLoaded = (projectId: number): Selector<AppState, boolean> =>
  createSelector(tasksStatusesSelector, statuses => statuses.byProjectId[projectId]?.loaded || false);

const isUpdating = (projectId: number): Selector<AppState, boolean> =>
  createSelector(tasksStatusesSelector, statuses => statuses.byProjectId[projectId]?.updating || false);

const isUpdatingById = (projectId: number, taskId): Selector<AppState, boolean> =>
  createSelector(tasksStatusesSelector, statuses =>
    (statuses.byProjectId[projectId]?.updatingIds || []).includes(taskId),
  );

const isUpdated = (projectId: number): Selector<AppState, boolean> =>
  createSelector(tasksStatusesSelector, statuses => statuses.byProjectId[projectId]?.updated || false);

const isAnyUpdated = (): Selector<AppState, boolean> =>
  createSelector(tasksStatusesSelector, statuses => !!Object.values(statuses.byProjectId).find(item => item.updated));

const isEveryUpdated = (): Selector<AppState, boolean> =>
  createSelector(tasksStatusesSelector, statuses => Object.values(statuses.byProjectId).every(item => item.updated));

const getAllByProjectId = (projectId: number): Selector<AppState, Task[]> =>
  createSelector([tasksEntitiesSelector, taskIdsByProjectIdSelector(projectId)], (entityMap, ids) =>
    ids
      .map(id => entityMap[id])
      .sort(ArrayUtils.sorterByDateCreated)
      .reverse(),
  );

const getById = (taskId: number): Selector<AppState, Task> =>
  createSelector(tasksEntitiesSelector, entities => entities[taskId]);

const isTaskLoaded = (taskId: number): Selector<AppState, boolean> => createSelector(getById(taskId), task => !!task);

const getAllByProjectIdAndUtilization = (projectId: number, sourceToolType: ToolType, sourceUtilizationId: number) =>
  createSelector(getAllByProjectId(projectId), projectTasks =>
    projectTasks.filter(
      t =>
        t.sourceToolType === sourceToolType &&
        t.sourceToolUtilization &&
        t.sourceToolUtilization.id === sourceUtilizationId,
    ),
  );

const isProgressAvailableByProjectId = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getAllByProjectId(projectId), tasks => tasks.some(t => t && !!t.dueDate));

const getPercentageCompleteByProjectId = (projectId: number): Selector<AppState, number> =>
  createSelector(getAllByProjectId(projectId), allTasks => {
    const affectingTasks = allTasks.filter(t => t && !!t.dueDate);

    let overallTime = 0;
    let completedTime = 0;

    affectingTasks.forEach(t => {
      const taskTime = +t.dueDate - +t.dateCreated;

      overallTime += taskTime;

      if (t.status === TaskStatus.Done) {
        completedTime += taskTime;
      }
    });

    if (!overallTime) {
      return 0;
    }

    return (completedTime / overallTime) * 100;
  });

const isRemoving = (projectId: number): Selector<AppState, boolean> =>
  createSelector(tasksStatusesSelector, statuses => statuses.byProjectId[projectId]?.removing || false);

const isRemoved = (projectId: number): Selector<AppState, boolean> =>
  createSelector(tasksStatusesSelector, statuses => statuses.byProjectId[projectId]?.removed || false);

export const TaskSelectors = {
  getAllByProjectId,
  getAllByProjectIdAndUtilization,
  getById,
  getPercentageCompleteByProjectId,
  isProgressAvailableByProjectId,
  isTaskLoaded,
  isLoaded,
  isLoading,
  isUpdating,
  isUpdatingById,
  isUpdated,
  isAnyUpdated,
  isEveryUpdated,
  isCreating,
  isCreated,
  isRemoving,
  isRemoved,
};
