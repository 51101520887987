import React, { useState, useEffect } from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { format, isToday, isBefore } from 'date-fns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { InsertInvitationRounded } from '@mui/icons-material';
import { useStyles } from './styles';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PickersDay } from '@mui/x-date-pickers';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import { TimeZoneService } from '@priz/shared/src/services/time';
import moment, { Moment } from 'moment-timezone';

export enum DateAdapterType {
  DateFns = 'DateFns',
  Moment = 'Moment',
}

const dateAdaptersMap = {
  [DateAdapterType.DateFns]: AdapterDateFns,
  [DateAdapterType.Moment]: AdapterMoment,
};

interface DueDateButtonProps {
  value: Moment | Date;
  selectTime?: boolean;
  onChange?: (date: Date) => void;
  onError?: (error: DateValidationError) => void;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  adapter?: DateAdapterType;
}

const dateFnsCheckDateIsValid = (date: Date, checkTime?: boolean) => {
  const isBeforeDate = isBefore(date, new Date());
  return checkTime ? !isBeforeDate : isToday(date) || !isBeforeDate;
};

const momentCheckDateIsValid = (date: Moment, checkTime?: boolean) => {
  const isToday = date?.isSame(moment(), 'day');
  const isBeforeDate = date?.isBefore(moment().add(1, 'minute'));
  return checkTime ? !isBeforeDate : isToday || !isBeforeDate;
};

const checkDateIsValid = (value: Moment | Date, adapterType: DateAdapterType, checkTime?: boolean) => {
  return adapterType === DateAdapterType.DateFns
    ? dateFnsCheckDateIsValid(value as Date, checkTime)
    : momentCheckDateIsValid(value as Moment, checkTime);
};

const formatLabel = (value: Moment | Date, showTime?: boolean): string => {
  if (value instanceof Date) return format(value, showTime ? 'MMM d yyyy HH:mm' : 'MMM d yyyy');
  if (moment.isMoment(value)) return TimeZoneService.format(value, undefined, showTime);
};

const checkValueTypeIsValid = (value: Moment | Date, adapterType: DateAdapterType): boolean => {
  if (!value) return true;
  if (value instanceof Date && adapterType === DateAdapterType.DateFns) return true;
  return moment.isMoment(value) && adapterType === DateAdapterType.Moment;
};

const resolveTodayDate = (value: Moment | Date): Moment | Date => {
  if (moment.isMoment(value)) {
    return value.set('hour', 23).set('minute', 59).set('second', 59);
  } else {
    return new Date(value.getFullYear(), value.getMonth(), value.getDate(), 23, 59, 59);
  }
};

const resolveValues = (value: Moment | Date): { dateValue: Date | null; momentValue: Moment | null } => {
  return moment.isMoment(value) ? { dateValue: null, momentValue: value } : { dateValue: value, momentValue: null };
};

const resolveMinTime = (date: Moment | Date): Moment | Date | undefined => {
  if (moment.isMoment(date)) {
    return date.isSame(moment(), 'day') ? moment().add(5, 'minute') : undefined;
  } else {
    return isToday(date) ? new Date(new Date().getTime() + 5 * 60 * 1000) : undefined;
  }
};

export const DateTimePickerButton: React.FC<DueDateButtonProps> = ({
  value,
  selectTime,
  placeholder,
  onChange,
  onError,
  disabled,
  loading,
  adapter = DateAdapterType.DateFns,
}) => {
  const styles = useStyles();
  const [dateValue, setDateValue] = useState(value);
  const [pickerIsOpen, setPickerIsOpen] = useState(false);

  useEffect(() => {
    if (value) setDateValue(value);
  }, [value]);

  if (!checkValueTypeIsValid(value, adapter)) {
    console.error(`Incorrect date type for ${adapter} adapter`);
    return null;
  }

  const dueDateIsValid = checkDateIsValid(value, adapter, true);

  const openHandler = () => {
    setPickerIsOpen(true);
  };

  const closeHandler = () => {
    setPickerIsOpen(false);
  };

  const changeHandler = (value: Date | Moment) => {
    const date = selectTime ? value : resolveTodayDate(value);

    if (onChange && checkDateIsValid(date, adapter, selectTime)) {
      const { dateValue, momentValue } = resolveValues(date);
      const result = momentValue ? momentValue.toDate() : dateValue;

      onChange(result);
    }
  };

  const errorHandler = (error: DateValidationError) => {
    if (onError) onError(error);
  };

  const renderIcon = () => {
    return (
      <>
        {loading && <FontAwesomeIcon icon={faSpinner} size={'xs'} spin={true} />}

        {!loading && <InsertInvitationRounded />}
      </>
    );
  };

  return (
    <LocalizationProvider dateAdapter={dateAdaptersMap[adapter]}>
      <DateTimePicker
        ampm={false}
        ampmInClock={true}
        hideTabs={true}
        showToolbar={selectTime}
        dayOfWeekFormatter={(day: string) => day}
        toolbarTitle={
          <Box mb={1}>
            <Typography variant={'h6'} className={styles.selectorTitle}>
              <Trans>{selectTime ? 'Select date & time' : 'Select date'}</Trans>
            </Typography>
          </Box>
        }
        componentsProps={{
          actionBar: {
            actions: selectTime ? ['cancel', 'accept'] : [],
          },
        }}
        DialogProps={{
          className: styles.root,
        }}
        PaperProps={{
          className: styles.root,
        }}
        reduceAnimations={true}
        open={pickerIsOpen}
        closeOnSelect={!selectTime}
        value={dateValue}
        disablePast={false}
        views={selectTime ? ['year', 'day', 'hours', 'minutes'] : ['year', 'day']}
        minTime={resolveMinTime(dateValue)}
        minutesStep={5}
        disabled={disabled}
        onChange={v => {
          const { dateValue, momentValue } = resolveValues(v);
          setDateValue(dateValue || momentValue);
        }}
        onAccept={changeHandler}
        onClose={closeHandler}
        onError={errorHandler}
        renderInput={params => (
          <div className={styles.inputContainer}>
            <input ref={params.inputRef} className={styles.input} disabled />

            <Tooltip title={!dueDateIsValid ? <Trans>Date has expired</Trans> : ''} arrow={true}>
              <div>
                <Button
                  size="small"
                  endIcon={renderIcon()}
                  onClick={openHandler}
                  disabled={disabled || loading}
                  className={styles.button}
                  color={dueDateIsValid ? 'primary' : 'error'}
                >
                  {value ? formatLabel(value, selectTime) : <Trans>{placeholder || 'Not set'}</Trans>}
                </Button>
              </div>
            </Tooltip>
          </div>
        )}
        renderDay={(day, _value, DayComponentProps) => {
          return (
            <PickersDay
              {...DayComponentProps}
              disabled={!checkDateIsValid(day, adapter)}
              className={styles.pickersDay}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};
