import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../store/app.state';
import { UserSettings } from '@priz/shared/src/models/user/user-settings';

const userSettingsSelector = (state: AppState) => state.userSettings;

const settings: Selector<AppState, UserSettings> = createSelector(
  userSettingsSelector,
  userStateObj => userStateObj.settings,
);

const isLoading: Selector<AppState, boolean> = createSelector(
  userSettingsSelector,
  userStateObj => userStateObj.loading,
);
const isLoaded: Selector<AppState, boolean> = createSelector(userSettingsSelector, userStateObj => userStateObj.loaded);
const isUpdating: Selector<AppState, boolean> = createSelector(
  userSettingsSelector,
  userStateObj => userStateObj.updating,
);
const isUpdated: Selector<AppState, boolean> = createSelector(
  userSettingsSelector,
  userStateObj => userStateObj.updated,
);

export const UserSettingsSelectors = {
  settings,
  isLoading,
  isLoaded,
  isUpdating,
  isUpdated,
};
