import { combineActions, handleActions } from 'redux-actions';
import { toStarted, toFailed, toSuccess } from '../../../../shared/store/action-creator';
import { CollectionStatusesService } from '@priz/shared/src/services/statuses/collection-statuses.service';
import { ApiKeysAction, ApiKeysActionType } from '../actions';
import { ApiKeysState, DefaultApiKeysState } from '../model';
import { ApiKey } from '@priz/shared/src/models/api-keys';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';

const addApiKeyToState = (
  state: ApiKeysState,
  userId: number,
  apiKey: ApiKey,
  statuses: Partial<EntityCollectionStatus>,
): ApiKeysState => {
  return {
    ...state,
    keys: {
      byUserId: {
        ...state.keys.byUserId,
        [userId]: {
          ...(state.keys.byUserId[userId] || {}),
          [apiKey.id]: apiKey,
        },
      },
    },
    statuses: CollectionStatusesService.resolveStatusesByUserId(state.statuses, userId, statuses),
  };
};

export const apiKeysReducers = handleActions(
  {
    // List

    [toStarted(ApiKeysActionType.Load)]: (state: ApiKeysState, action: ApiKeysAction): ApiKeysState =>
      CollectionStatusesService.setStatusesByUserId(state, action.meta.userId, {
        loading: true,
        loaded: false,
      }),
    [toFailed(ApiKeysActionType.Load)]: (state: ApiKeysState, action: ApiKeysAction): ApiKeysState =>
      CollectionStatusesService.setStatusesByUserId(state, action.meta.userId, {
        loading: false,
        loaded: false,
        error: true,
      }),
    [toSuccess(ApiKeysActionType.Load)]: (state: ApiKeysState, action: ApiKeysAction): ApiKeysState => {
      const apiKeysArray = action.payload as ApiKey[];

      const apiKeysMap = apiKeysArray.reduce((map: { [id: number]: ApiKey }, item) => {
        map[item.id] = item;
        return map;
      }, {});

      return {
        ...state,
        keys: {
          byUserId: {
            ...state.keys.byUserId,
            [action.meta.userId]: {
              ...(state.keys.byUserId[action.meta.userId] || {}),
              ...apiKeysMap,
            },
          },
        },
        statuses: CollectionStatusesService.resolveStatusesByUserId(state.statuses, action.meta.userId, {
          loading: false,
          loaded: true,
          error: false,
        }),
      };
    },

    // Create

    [toStarted(ApiKeysActionType.Create)]: (state: ApiKeysState, action: ApiKeysAction): ApiKeysState =>
      CollectionStatusesService.setStatusesByUserId(state, action.meta.userId, {
        creating: true,
        created: false,
      }),
    [toFailed(ApiKeysActionType.Create)]: (state: ApiKeysState, action: ApiKeysAction): ApiKeysState =>
      CollectionStatusesService.setStatusesByUserId(state, action.meta.userId, {
        creating: false,
        created: false,
        error: true,
      }),
    [toSuccess(ApiKeysActionType.Create)]: (state: ApiKeysState, action: ApiKeysAction): ApiKeysState =>
      addApiKeyToState(state, action.meta.userId, action.payload as ApiKey, {
        creating: false,
        created: true,
        error: false,
      }),

    // Update

    [combineActions(toStarted(ApiKeysActionType.Invalidate), toStarted(ApiKeysActionType.UpdateTitle))]: (
      state: ApiKeysState,
      action: ApiKeysAction,
    ): ApiKeysState =>
      CollectionStatusesService.setStatusesByUserId(state, action.meta.userId, {
        updating: true,
        updated: false,
      }),
    [combineActions(toFailed(ApiKeysActionType.Invalidate), toFailed(ApiKeysActionType.UpdateTitle))]: (
      state: ApiKeysState,
      action: ApiKeysAction,
    ): ApiKeysState =>
      CollectionStatusesService.setStatusesByUserId(state, action.meta.userId, {
        updating: false,
        updated: false,
        error: true,
      }),
    [combineActions(toSuccess(ApiKeysActionType.Invalidate), toSuccess(ApiKeysActionType.UpdateTitle))]: (
      state: ApiKeysState,
      action: ApiKeysAction,
    ): ApiKeysState =>
      addApiKeyToState(state, action.meta.userId, action.payload as ApiKey, {
        updating: false,
        updated: true,
        error: false,
      }),
  },

  DefaultApiKeysState,
);
