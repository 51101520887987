import React from 'react';
import { Box, BoxProps, Step, StepLabel, Stepper } from '@mui/material';
import { useStyles } from './styles';

interface StepsIndicationProps extends BoxProps {
  stepsCount: number;
  activeStep: number;
}

export const StepsIndication: React.FC<StepsIndicationProps> = ({ stepsCount, activeStep, ...rest }) => {
  const styles = useStyles();

  return (
    <Box pt={2} pb={3} {...rest}>
      <Stepper activeStep={activeStep}>
        {[...new Array(stepsCount)].map((_item, key) => (
          <Step key={key + 1} expanded>
            <StepLabel classes={{ iconContainer: styles.stepIconContainer }} />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
