import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {environment} from "@priz/shared/src/environments/environment";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {router} from "./app/routing/router";
import {RouterProvider} from "react-router-dom";
import {PrizLoadingOverlay} from "@priz/shared/src/components/priz-loading-overlay/component";
import { FlagProvider } from '@unleash/proxy-client-react';

if (environment.production) {
  fetch(`/version.txt?${Math.random()}`)
    .then(response => (response.ok ? response.text() : undefined))
    .then(releaseName => {
      Sentry.init({
        dsn: 'https://224d6d94425f4244abec116758bd9d5d@sentry.io/1793505',
        integrations: [new BrowserTracing()],
        environment: 'prod',
        release: releaseName?.trim(),
      });
    });
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<PrizLoadingOverlay />}>
      <FlagProvider config={environment.unleash}>
        <RouterProvider router={router} />
      </FlagProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
);
