import React from 'react';
import { Box, Grid } from '@mui/material';
import { PgProgressBar } from '../../react/elements/PgProgressBar';
import { Trans, useTranslation } from 'react-i18next';
import { Workspace, WorkspaceMember } from '@priz/shared/src/models/workspace';
import { WorkspaceUtils } from '../../workspace/services';

interface SeatsBarProps {
  workspace: Workspace;
  members: WorkspaceMember[];
}

export const SeatsBar: React.FC<SeatsBarProps> = ({ workspace, members = [] }) => {
  const { t } = useTranslation();

  const membersCount = WorkspaceUtils.countChargeableMembers(workspace, members);

  return (
    <>
      <Box>
        <Grid container direction="row" justifyContent="space-between" alignItems="stretch" spacing={0}>
          <Grid item xs={6} textAlign={'left'}>
            <Trans>Seats</Trans>
          </Grid>
          <Grid item xs={6} textAlign={'right'}>
            {`${membersCount} ${t('of')} ${workspace.licensedSeats} ${t('seats used')}`}
          </Grid>
        </Grid>
      </Box>

      <Box>
        <PgProgressBar
          variant="determinate"
          color={'primary'}
          value={Math.ceil((membersCount / workspace.licensedSeats) * 100)}
        />
      </Box>
    </>
  );
};
