import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';

export const useStyles = makeStyles({
  '@keyframes hideOverlay': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },

  'root': {
    'position': 'relative',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: -10,
      left: -10,
      right: 'auto',
      bottom: 'auto',
      margin: 'auto',
      width: 'calc(100% + 20px)',
      height: 'calc(100% + 20px)',
      zIndex: 5,
      background: pgColorScheme.workbenchBackground,
      opacity: '0',
      pointerEvents: 'none',
      animation: '$hideOverlay .3s ease-in-out .1s both',
    },

    '&._compact': {
      '& $letterColumn': {
        width: 36,
      },

      '& $letter': {
        minWidth: 26,
        height: 26,
        fontSize: 12,
      },

      '& $descriptionWrap': {
        minHeight: 26,
      },
    },
  },
  'card': {
    overflow: 'visible',
  },
  'textAreaWrap': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 'auto',
    minHeight: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 5,
    borderColor: pgColorScheme.placeholder,
    boxSizing: 'border-box',
  },
  'textArea': {
    display: 'block',
    width: '100%',
    height: 'auto',
    minHeight: 'calc(1em + 20px)',
    resize: 'none',
    padding: '10px',
    boxSizing: 'border-box',
    textAlign: 'left',
    background: 'transparent',
    outline: 'none',
    fontFamily: '"Roboto", sans-serif',
    fontSize: 14,
    lineHeight: '1.2',
    letterSpacing: 0,
    overflow: 'auto',
    border: 'none',
  },
  'descriptionWrap': {
    minHeight: 36,
  },
  'description': {
    wordBreak: 'break-word',
  },
  'letterColumn': {
    width: 70,
  },
  'blockingRank': {
    '&._low': {
      fontWeight: 300,
      color: pgColorScheme.textGray,
    },
    '&._med': {},
    '&._high': {
      color: pgColorScheme.red,
    },
  },
  'letterWrap': {
    width: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  'letter': {
    'lineHeight': 1,
    'textAlign': 'center',
    'background': pgColorScheme.mediumBackground,
    'borderRadius': 50,
    'padding': '0 4px',
    'height': 36,
    'minWidth': 36,
    'boxSizing': 'border-box',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',

    '&._small': {
      height: 26,
      minWidth: 26,
    },
  },
  'iconButton': {
    '& svg': {
      fill: pgColorScheme.blue,
    },
  },
  'addNewPerceptionButton': {
    'backgroundColor': pgColorScheme.blue,

    '& svg': {
      fill: pgColorScheme.white,
    },

    '&.MuiIconButton-root:hover': {
      backgroundColor: pgColorScheme.orange,
    },
  },
});
