import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      zIndex: 5,
    },
    menuList: {
      maxHeight: 'calc(100vh - 130px)',
      overflow: 'auto',
    },
    menuItem: {
      position: 'relative',
      display: 'block',
      minHeight: 'unset',
      with: '100%',
      maxWidth: 280,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    workspaceButtonText: {
      maxWidth: 200,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    [theme.breakpoints.down('md')]: {
      workspaceButtonText: {
        maxWidth: 140,
      },
    },
  }),
);
