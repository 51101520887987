import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 410,
    borderRadius: 100,
    background: pgColorScheme.mediumBackground,
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  button: {
    borderRadius: 'inherit',
    transition: 'background-color .15s linear, color .15s linear',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    boxSizing: 'border-box',
    color: pgColorScheme.darkText,

    ['&:hover']: {
      color: pgColorScheme.blue,
    },
  },
  active: {
    backgroundColor: pgColorScheme.white,
  },
  inactive: {
    backgroundColor: pgColorScheme.mediumBackground,
  },
  textHighlight: {
    color: pgColorScheme.blue,
  },
});
