import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../store/app.state';
import { OptionalComponentRankMap, SfmRank, SfmRankMap, SfmVersionsRanksMap } from '@priz/shared/src/models/tools/sfm';
import {
  ProblematicComponentPreview,
  ProblematicComponentPreviewsMap,
  SfmComponentEliminationEffect,
  SfmComponentImprovement,
  SfmComponentImprovementMap,
  SfmComponentsEliminationEffectMap,
} from '../model';
import { EntityStatusId } from '@priz/shared/src/models/common/entity-collection-state';

const sfmResultSelector = (state: AppState) => state.sfmResult;
const sfmResultStatusesSelector = createSelector(sfmResultSelector, collection => collection.statuses);

const getComponentsRanksByUtilizationId = (utilizationId: number): Selector<AppState, OptionalComponentRankMap> =>
  createSelector(sfmResultSelector, collection => collection.componentsRanks.byUtilizationId[utilizationId]);

const getComponentsRanksByVersionId = (versionId: string): Selector<AppState, OptionalComponentRankMap> =>
  createSelector(sfmResultSelector, collection => collection.versionComponentsRanks.byVersionId[versionId]);

const getSfmRankByUtilizationId = (utilizationId: number): Selector<AppState, SfmRank> =>
  createSelector(sfmResultSelector, collection => collection.sfmRanks.byUtilizationId[utilizationId]);

const getSfmRanksByParentUtilizationId = (utilizationId: number): Selector<AppState, SfmRankMap> =>
  createSelector(sfmResultSelector, collection => collection.sfmRanks.byPfmId[utilizationId] || ({} as SfmRankMap));

const getSfmVersionsRanksByUtilizationId = (utilizationId: number): Selector<AppState, SfmVersionsRanksMap> =>
  createSelector(
    sfmResultSelector,
    collection => collection.sfmVersionsRanks.byUtilizationId[utilizationId] || ({} as SfmVersionsRanksMap),
  );

const getComponentsEliminationEffectMap = (
  utilizationId: number,
  versionId: string,
): Selector<AppState, SfmComponentsEliminationEffectMap> =>
  createSelector(sfmResultSelector, collection => {
    return (
      collection.componentsEliminationEffect?.byUtilizationId?.[utilizationId]?.byVersionId?.[versionId] ||
      ({} as SfmComponentsEliminationEffectMap)
    );
  });

const getComponentsEliminationEffect = (
  utilizationId: number,
  versionId: string,
  nodeId: string,
): Selector<AppState, SfmComponentEliminationEffect> =>
  createSelector(getComponentsEliminationEffectMap(utilizationId, versionId), effects => effects[nodeId]);

const getProblematicComponents = (
  utilizationId: number,
  versionId: string,
): Selector<AppState, ProblematicComponentPreview[]> =>
  createSelector(sfmResultSelector, collection => {
    return collection.problematicComponents?.byUtilizationId?.[utilizationId]?.byVersionId?.[versionId] || [];
  });

const getProblematicComponentsMap = (
  utilizationId: number,
  versionId: string,
): Selector<AppState, ProblematicComponentPreviewsMap> =>
  createSelector(getProblematicComponents(utilizationId, versionId), previews => {
    return previews.reduce((map: ProblematicComponentPreviewsMap, item) => {
      map[item.id] = item;
      return map;
    }, {});
  });

const getComponentsImprovementsMap = (
  utilizationId: number,
  versionId: string,
): Selector<AppState, SfmComponentImprovementMap> =>
  createSelector(sfmResultSelector, collection => {
    return (
      collection.componentsImprovements?.byUtilizationId?.[utilizationId]?.byVersionId?.[versionId] ||
      ({} as SfmComponentImprovementMap)
    );
  });

const getComponentImprovement = (
  utilizationId: number,
  versionId: string,
  nodeId: string,
): Selector<AppState, SfmComponentImprovement> =>
  createSelector(getComponentsImprovementsMap(utilizationId, versionId), improvements => improvements[nodeId]);

const isLoadingByUtilizationId = (utilizationId: number): Selector<AppState, boolean> =>
  createSelector(sfmResultStatusesSelector, statuses => !!statuses.byUtilizationId[utilizationId]?.loading);

const isLoadedByUtilizationId = (utilizationId: number): Selector<AppState, boolean> =>
  createSelector(sfmResultStatusesSelector, statuses => !!statuses.byUtilizationId[utilizationId]?.loaded);

const isLoadingByVersionId = (versionId: string): Selector<AppState, boolean> =>
  createSelector(sfmResultStatusesSelector, statuses => !!statuses.byVersionId[versionId]?.loading);

const isLoadedByVersionId = (versionId: string): Selector<AppState, boolean> =>
  createSelector(sfmResultStatusesSelector, statuses => !!statuses.byVersionId[versionId]?.loaded);

const isNodeLoadingById = (nodeId: string): Selector<AppState, boolean> =>
  createSelector(sfmResultStatusesSelector, statuses => !!statuses.byNodeId[nodeId]?.loading);

const isNodeLoadedById = (nodeId: string): Selector<AppState, boolean> =>
  createSelector(sfmResultStatusesSelector, statuses => !!statuses.byNodeId[nodeId]?.loaded);

const isNodeFailedById = (nodeId: string): Selector<AppState, boolean> =>
  createSelector(sfmResultStatusesSelector, statuses => !!statuses.byNodeId[nodeId]?.error);

const isLoadingByEntityStatusId = (utilizationId: number, entityId: EntityStatusId): Selector<AppState, boolean> =>
  createSelector(
    sfmResultStatusesSelector,
    statuses => !!statuses.byUtilizationId[utilizationId]?.loadingIds?.includes(entityId),
  );

const isFailedByEntityStatusId = (utilizationId: number, entityId: EntityStatusId): Selector<AppState, boolean> =>
  createSelector(
    sfmResultStatusesSelector,
    statuses => !!statuses.byUtilizationId[utilizationId]?.errorIds?.includes(entityId),
  );

export const SfmResultSelectors = {
  getComponentsRanksByUtilizationId,
  getComponentsRanksByVersionId,
  getSfmRankByUtilizationId,
  getSfmRanksByParentUtilizationId,
  getSfmVersionsRanksByUtilizationId,
  getComponentsEliminationEffectMap,
  getComponentsEliminationEffect,
  getProblematicComponents,
  getProblematicComponentsMap,
  getComponentsImprovementsMap,
  getComponentImprovement,
  isLoadingByUtilizationId,
  isLoadedByUtilizationId,
  isLoadingByVersionId,
  isLoadedByVersionId,
  isNodeLoadingById,
  isNodeLoadedById,
  isNodeFailedById,
  isLoadingByEntityStatusId,
  isFailedByEntityStatusId,
};
