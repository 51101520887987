export enum DelimiterType {
  SemiColon = ';',
  Comma = ',',
  Colon = ':',
  Bar = '|',
  Caret = '^',
}

export const delimitersOptions = [
  {
    label: 'Semi-colon',
    value: DelimiterType.SemiColon,
  },
  {
    label: 'Comma',
    value: DelimiterType.Comma,
  },
  {
    label: 'Colon',
    value: DelimiterType.Colon,
  },
  {
    label: 'Bar',
    value: DelimiterType.Bar,
  },
  {
    label: 'Caret',
    value: DelimiterType.Caret,
  },
];
