import { IToolUtilization, ToolUtilization } from '../index';
import { Perception } from './perception';
import { AccessLevel } from '../../security/access-level';
import { PMapContributedData } from './pmap-contributed-data';

export interface IPMapUtilizationData {
  perceptions: Perception[];
  allPerceptionsWereConnected: boolean;
}

export interface IPMapUtilization extends IToolUtilization {
  data?: IPMapUtilizationData;
  contributedData?: PMapContributedData;
  publicAccessLevel?: AccessLevel;
}

export class PMapUtilization extends ToolUtilization implements IPMapUtilization {
  public data?: IPMapUtilizationData;
  public contributedData?: PMapContributedData;
  public publicAccessLevel?: AccessLevel;

  constructor(json: IPMapUtilization) {
    super(json);

    this.data = json.data;
    this.contributedData = json.contributedData;
    this.publicAccessLevel = json.publicAccessLevel;
  }
}
