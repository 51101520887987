import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: 'auto',
    zIndex: 2,
  },
  tab: {
    position: 'relative',
    height: 'auto',
  },
  childContainer: {
    position: 'relative',
    width: '100%',
    height: 'auto',
  },
  slider: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
  },
});
