import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, Tab, Tabs, TextField, Typography } from '@mui/material';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { ToolTypeDataMap } from '@priz/shared/src/data/tool-type-data-map';
import { ToolType } from '@priz/shared/src/models/tools';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { UimUtilizationActions } from '../store/actions/uim-utilization.actions';
import { ProjectSelector, ToolUtilizationSelector } from '../../project/store/selectors';
import { UimTaskSelector } from '../store/selectors/uim-task.selectors';
import { UimTaskItem } from '../uim-task-item/component';
import { UimGoal } from '@priz/shared/src/models/tools/uim';
import { UimToolUtilizationSummary } from '../uim-tool-utilization-summary/component';
import { Trans } from 'react-i18next';
import { ToolTitle } from '../../tools/tool-title/component';
import { FeatureSetSelectors } from '../../workspace/store/selectors/feature-set.selectors';
import { PaywallActions } from '../../react/modules/paywall/store';
import { TitleContainer } from '../../react/modules/title-container/component';
import { TabPanel } from '../../react/elements/tab-panel/component';
import { CsvDataSelector, CSVImportType } from '../../csv-data-selector/component';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { LinearLoader } from '../../react/elements/linear-loader/component';
import { TipPopupWrapper } from '../../tip-popup-wrapper/component';
import { UserSettingsActions } from '../../user/store/actions/user-settings.actions';
import { UserSettingsSelectors } from '../../user/store/selectors/user-settings.selectors';
import { copyObject } from '@priz/shared/src/utils/common';
import { HitsCountLimits } from '@priz/shared/src/models/user/user-settings';
import { DefaultTipProps } from '@priz/shared/src/models/user/tips';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { ToolUtilizationActions, ToolUtilizationActionType } from '../../project/store/actions/tools.actions';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { useToolTabs } from '../../tools/utils';

export interface UimToolUtilizationProps {
  utilizationId: number;
  projectId?: number;
}

export const PureUimToolUtilization: React.FC<UimToolUtilizationProps> = ({ utilizationId, projectId }) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useToolTabs(0);

  const [taskIdToDelete, setTaskIdToDelete] = useState<number>();
  const [newTask, setNewTask] = useState<string>(undefined);
  const [isImporting, setIsImporting] = useState(false);
  const [importIsOpen, setImportIsOpen] = useState(false);

  const userSettings = useSelector(UserSettingsSelectors.settings);
  const utilization = useSelector(ToolUtilizationSelector.getUimUtilization(utilizationId));
  const featureSet = useSelector(WorkspaceSelectors.getApplicableFeatureSet(projectId));
  const tasks = useSelector(UimTaskSelector.getAllByUtilizationId(utilizationId));

  const isUpdating = useSelector(UimTaskSelector.isUpdatingByUtilizationId(utilizationId));
  const isCreating = useSelector(UimTaskSelector.isCreatingByUtilizationId(utilizationId));
  const areTasksLoaded = useSelector(UimTaskSelector.isLoadedByUtilizationId(utilizationId));
  const isUimLimitHit = useSelector(FeatureSetSelectors.isUimLimitHit(utilizationId, projectId));
  const isProjectLocked = useSelector(ProjectSelector.isProjectLockedById(projectId));
  const userSettingsAreLoaded = useSelector(UserSettingsSelectors.isLoaded);
  const isCompletenessUpdating = useSelector(
    ToolUtilizationSelector.isPendingByActionType(utilizationId, ToolUtilizationActionType.UpdateCompleteness),
  );

  const isDisabled = !!utilization?.completed || isCompletenessUpdating || isProjectLocked;
  const allTasksDefined = tasks?.length ? !tasks.filter(t => !t.flawCost || !t.flawExpectancy).length : false;
  const apaResultTipHitsCount = userSettings.tips.tools[ToolType.APA]?.result?.hitsCount || 0;
  const resultTipHitsLimitReached = apaResultTipHitsCount >= HitsCountLimits.tools[ToolType.APA];
  const showResultTip = userSettingsAreLoaded && allTasksDefined && !resultTipHitsLimitReached;
  const isSelfGoalUim = utilization?.uimGoal === UimGoal.Self;

  useEffect(() => {
    dispatch(UserSettingsActions.load());
  }, []);

  useEffect(() => {
    if (isImporting && !isUpdating) {
      setIsImporting(false);
      closeImport();
    }
  }, [isImporting, isUpdating]);

  useEffect(() => {
    dispatch(ToolUtilizationActions.loadTool(utilizationId, projectId));
    dispatch(UimUtilizationActions.loadTasks(utilizationId));
  }, [utilizationId, projectId]);

  const taskForm = React.createRef<HTMLFormElement>();

  const handleTaskValueChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNewTask(event.target.value);
  };

  const handleCreateTask = (e?: FormEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (isUimLimitHit) {
      dispatch(PaywallActions.show(ToolType.UIM));
    } else {
      dispatch(UimUtilizationActions.createTask(utilization.id, newTask));
      setNewTask('');
    }
  };

  const handleDeleteTask = (taskId: number) => () => {
    setTaskIdToDelete(taskId);
  };

  const handleDeleteTaskClose = () => {
    setTaskIdToDelete(undefined);
  };

  const doDeleteTask = () => {
    if (taskIdToDelete) {
      dispatch(UimUtilizationActions.deleteTask(utilization.id, taskIdToDelete));
      setTaskIdToDelete(undefined);
    }
  };

  const handleChange = (_event, newValue: number) => {
    setActiveTab(newValue);
  };

  const importHandler = (items: string[], importType: CSVImportType, limitHit: boolean) => {
    if (items.length) {
      const isAdding = importType === CSVImportType.Add;

      dispatch(UimUtilizationActions.replaceTasks(utilization.id, items, isAdding ? null : tasks.map(t => t.id)));

      setIsImporting(true);
    }

    if (limitHit) {
      dispatch(PaywallActions.show(ToolType.UIM));
    }
  };

  const openImport = () => {
    setImportIsOpen(true);
  };

  const closeImport = () => {
    setImportIsOpen(false);
  };

  const resultTipDone = () => {
    const userSettingsCopy = copyObject(userSettings);

    if (!userSettingsCopy.tips.tools[ToolType.APA]) {
      userSettingsCopy.tips.tools[ToolType.APA] = {
        ignoreTips: false,
        result: DefaultTipProps,
      };
    }

    userSettingsCopy.tips.tools[ToolType.APA].result.hitsCount += 1;
    userSettingsCopy.tips.tools[ToolType.APA].result.hitsTotal += 1;

    dispatch(
      UserSettingsActions.update({
        settings: userSettingsCopy,
      }),
    );
  };

  if (!utilization || !areTasksLoaded) {
    return <LoadingOverlay loading={true} backdropStyles={{ backgroundColor: 'transparent' }} />;
  }

  return (
    <>
      <ContentContainer display={'flex'} alignItems={'stretch'}>
        <Grid container direction={'column'} wrap={'nowrap'}>
          <Grid item xs={'auto'}>
            <TitleContainer>
              <PageTitleWithDocLink
                titleComponent={
                  <ToolTitle
                    initialTitle={'Prioritize your tasks'}
                    tool={utilization}
                    preset={'large'}
                    editable={!isDisabled}
                  />
                }
                oneLiner={<Trans>Task is something that you need to do as a result of a flaw.</Trans>}
                docsUrl={ToolTypeDataMap[ToolType.UIM].readMoreLink}
              />

              <Box display="flex" justifyContent="flex-end">
                <Tabs
                  orientation={'horizontal'}
                  variant={'scrollable'}
                  scrollButtons={'auto'}
                  allowScrollButtonsMobile={true}
                  value={activeTab}
                  onChange={handleChange}
                >
                  <Tab label={<Trans>Tasks</Trans>} />
                  <Tab
                    onClick={showResultTip ? resultTipDone : undefined}
                    label={
                      <TipPopupWrapper
                        open={showResultTip}
                        text={'View result summary'}
                        popperPlacement={'bottom'}
                        onClose={resultTipDone}
                      >
                        <Trans>Result</Trans>
                      </TipPopupWrapper>
                    }
                  />
                </Tabs>
              </Box>
            </TitleContainer>
          </Grid>

          <Grid item xs={true} position={'relative'}>
            <TabPanel index={0} value={activeTab} stretch>
              <Box mb={1}>
                <CsvDataSelector
                  open={importIsOpen}
                  onOpen={openImport}
                  onClose={closeImport}
                  onImport={importHandler}
                  planLimit={featureSet?.Tools?.UIM?.maxItems}
                  currentItemsCount={tasks.length}
                  loading={isImporting}
                  disabled={isDisabled || !isSelfGoalUim}
                />
              </Box>

              <Box>
                <form onSubmit={handleCreateTask} ref={taskForm}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} sm={true}>
                      <TextField
                        id="uim-new-task"
                        multiline
                        value={newTask}
                        onChange={handleTaskValueChange}
                        variant={'outlined'}
                        fullWidth={true}
                        onKeyDown={event => {
                          if (event.ctrlKey && event.key === 'Enter') {
                            handleCreateTask();
                          }
                        }}
                        disabled={isDisabled || !isSelfGoalUim}
                      />
                    </Grid>

                    <Grid item xs={12} sm={'auto'} textAlign={'right'}>
                      <Button
                        variant={'pg_rounded'}
                        color={'pg_orange_solid'}
                        disabled={!newTask || isDisabled || !isSelfGoalUim}
                        type={'submit'}
                      >
                        <Trans>Add task</Trans>
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>

              <Box mt={2}>
                {!tasks?.length && <LinearLoader loading={isCreating} />}

                {!!tasks?.length && (
                  <>
                    <Box mb={1}>
                      <Typography variant={'h6'}>
                        <Trans>Tasks in this session</Trans>
                      </Typography>
                    </Box>

                    <LinearLoader loading={isCreating} />

                    <Grid container direction="column" justifyContent="space-between" alignItems="stretch" spacing={3}>
                      {tasks.map(task => (
                        <Grid item key={task.id}>
                          <UimTaskItem
                            utilization={utilization}
                            task={task}
                            onDelete={handleDeleteTask(task.id)}
                            disabled={isDisabled}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              </Box>
            </TabPanel>

            <TabPanel index={1} value={activeTab} stretch>
              <UimToolUtilizationSummary
                tasks={tasks}
                projectId={utilization.project?.id}
                utilizationId={utilization.id}
                disabled={isDisabled}
              />
            </TabPanel>
          </Grid>
        </Grid>
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={projectId}
          utilization={utilization}
          disabled={isDisabled}
          workingFlowProps={{ nextVariant: 'ideas' }}
        />
      </ContentFooter>

      <PgConfirmationDialog
        isOpen={!!taskIdToDelete}
        okButtonText={<Trans>Delete</Trans>}
        confirmContent={<Trans>Are you sure you want to delete this task?</Trans>}
        confirmTitle={<Trans>Confirm delete</Trans>}
        onConfirm={doDeleteTask}
        onClose={handleDeleteTaskClose}
      />
    </>
  );
};
