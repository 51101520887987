import React, { useEffect } from 'react';
import { Box, BoxProps, Grid, Paper } from '@mui/material';
import { Trans } from 'react-i18next';
import { ReactHookFormText } from '@priz/shared/src/components/form-elements';
import { validatorRules } from '@priz/shared/src/utils/form';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { UserSelectors } from '../../../user/store/selectors/user.selectors';
import { UserAvatar } from '@priz/shared/src/components/user-avatar/component';

interface CreatePerceptionFormProps extends BoxProps {
  loading?: boolean;
  onCreate?: (text: string) => void;
}

export const CreatePerceptionForm: React.FC<CreatePerceptionFormProps> = ({ loading, onCreate, ...rest }) => {
  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      text: '',
    },
  });

  const currentUser = useSelector(UserSelectors.currentUserSelector);

  useEffect(() => {
    if (!loading) {
      reset({ text: '' });
    }
  }, [loading]);

  const submitHandler = () => {
    handleSubmit(formData => {
      if (onCreate) onCreate(formData.text);
    })();
  };

  return (
    <Paper variant={'outlined'} component={Box} p={2} {...rest}>
      <ReactHookFormText
        name={'text'}
        control={control}
        disabled={loading}
        rules={{
          ...validatorRules.required(),
        }}
      />

      <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <UserAvatar
            user={currentUser}
            showName={!!currentUser}
            defaultAvatar={!currentUser}
            label={!currentUser && <Trans>Anonymous</Trans>}
            size={30}
            emailToColor
          />
        </Grid>

        <Grid item>
          <LoadingButton
            variant={'pg_rounded'}
            color={'pg_orange_solid'}
            size={'pg_small'}
            disabled={loading}
            loading={loading}
            onClick={submitHandler}
          >
            <Trans>Submit</Trans>
          </LoadingButton>
        </Grid>
      </Grid>
    </Paper>
  );
};
