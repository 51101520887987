import React, { useContext, useEffect } from 'react';
import { CanvasControlButton, CanvasControlsContainer } from '../../canvas-controls';
import { FullscreenButton } from '../../fullscreen-button/component';
import { FullscreenContainerContext } from '../../fullscreen-container/component';

import { ReactComponent as CameraIcon } from '../../../assets/icons/center';

interface CanvasControlsProps {
  onSetCenter?: () => void;
}

export const DiagramControls: React.FC<CanvasControlsProps> = ({ onSetCenter }) => {
  const fullscreenContext = useContext(FullscreenContainerContext);

  useEffect(() => {
    if (onSetCenter) {
      onSetCenter();
    }
  }, [fullscreenContext.enabled]);

  return (
    <CanvasControlsContainer>
      <CanvasControlButton tooltip={'Recenter'} onClick={onSetCenter}>
        <CameraIcon />
      </CanvasControlButton>

      <FullscreenButton />
    </CanvasControlsContainer>
  );
};
