import React, { useEffect, useState } from 'react';
import { Alert, Box, BoxProps, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { Trans } from 'react-i18next';
import { LocalesService } from '@priz/shared/src/components/locales/services';
import { LocalizedVideoGuide, VideoGuide, videoGuides } from './data/video-data-array';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { FieldTitle } from '@priz/shared/src/components/form-elements';
import { AnalyticsEvent } from '../../shared/analytics-event.enum';
import { VideoPlayer } from '../../react/elements/video-player/component';
import { GuideCard } from '../guide-card/component';

interface VideoGuidesProps extends BoxProps {
  pathname: string;
  onView?: () => void;
  onGuidesLoad?: (count: number) => void;
}

const resolveVideoGuides = (routePath: string): VideoGuide[] => {
  const pathArray = routePath.split('/');

  const conditionalVideoGuide = videoGuides.find(videoObj => {
    return videoObj.condition.every(item => pathArray.indexOf(item) !== -1);
  });

  return conditionalVideoGuide?.videos || [];
};

export const VideoGuides: React.FC<VideoGuidesProps> = ({ pathname, onView, onGuidesLoad, ...rest }) => {
  const [guides, setGuides] = useState<LocalizedVideoGuide[]>([]);
  const [videoUrl, setVideoUrl] = useState<string>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const currentLocale = LocalesService.getCurrentLocale();

  const handleOpen = (url: string) => {
    if (onView) {
      onView();
    }

    setVideoUrl(url);
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setVideoUrl(null);
    setIsDialogOpen(false);
  };

  const handleVideoStart = () => {
    void AnalyticsService.track(AnalyticsEvent.VIDEO_HELPER_STARTED, {
      page_title: document.title,
      selected_locale: currentLocale,
    });
  };

  useEffect(() => {
    const videoGuides = resolveVideoGuides(pathname);
    const localizedVideoGuides: LocalizedVideoGuide[] = [];

    videoGuides.forEach(guide => {
      const localizedUrl = guide.localesVideoUrlMap[currentLocale];

      if (localizedUrl && guide.title.length) {
        localizedVideoGuides.push({
          title: guide.title,
          url: localizedUrl,
        });
      }
    });

    if (onGuidesLoad) {
      onGuidesLoad(localizedVideoGuides.length);
    }

    setGuides(localizedVideoGuides);
  }, [pathname, currentLocale]);

  if (!guides.length) return null;

  return (
    <Box {...rest}>
      <FieldTitle
        text={
          <>
            <Trans>Video tutorials</Trans>:
          </>
        }
      />

      {!guides.length && (
        <Alert severity={'info'}>
          <Trans>There are no videos for this page</Trans>
        </Alert>
      )}

      {guides.map((guide, key) => (
        <GuideCard
          key={[pathname, key].join('-')}
          mt={key ? 1 : 0}
          title={guide.title}
          onButtonClick={() => handleOpen(guide.url)}
          buttonText={'watch'}
        />
      ))}

      <Dialog open={isDialogOpen} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
        <DialogContent sx={{ display: 'flex' }}>
          <VideoPlayer videoUrl={videoUrl} onStart={handleVideoStart} />
        </DialogContent>

        <DialogActions style={{ textAlign: 'right' }}>
          <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose}>
            <Trans>Close</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
