import { User } from '../security/user';
import { Auth0ConnectionType } from '../security/auth0-connection-type';

export interface UserUpdateStatuses {
  avatarUpdating?: boolean;
  profileUpdating?: boolean;
  passwordUpdating?: boolean;
  privacySettingsUpdating?: boolean;
  securitySettingsUpdating?: boolean;
  securitySettingsUpdated?: boolean;
  verificationEmailSending?: boolean;
  connectionsUpdating?: boolean;
  connectionsUpdated?: boolean;
  loadingConnection?: Auth0ConnectionType;
  policiesUpdating?: boolean;
}

export interface CurrentUser extends UserUpdateStatuses {
  user?: User;
  loading: boolean;
  error?: any;
  loaded: boolean;
}

export const DefaultUserUpdateStatuses: UserUpdateStatuses = {
  avatarUpdating: false,
  profileUpdating: false,
  passwordUpdating: false,
  verificationEmailSending: false,
};

export const DefaultCurrentUser: CurrentUser = {
  error: undefined,
  loading: false,
  loaded: false,
  user: undefined,
  ...DefaultUserUpdateStatuses,
};
