import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../store/app.state';
import { PublicUser } from '@priz/shared/src/models/user';

const publicUserCollectionSelector = (state: AppState) => state.users;

const publicUserEntitiesSelector = createSelector(publicUserCollectionSelector, collection => collection.entities);

const publicUserByIdSelector = (id: number): Selector<AppState, PublicUser> =>
  createSelector(publicUserEntitiesSelector, entities => entities[id]);

const allPublicUsersByIdsSelector = (ids: number[]) =>
  createSelector(publicUserEntitiesSelector, entities => {
    return entities
      ? ids.reduce((users, id) => {
          if (entities[id]) {
            users.push(entities[id]);
          }

          return users;
        }, [])
      : [];
  });

export const PublicUserSelectors = {
  publicUserEntitiesSelector,
  allPublicUsersByIdsSelector,
  publicUserByIdSelector,
};
