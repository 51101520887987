import React from 'react';
import { AuthCallback, UserLoaderProps } from '../auth-callback/component';
import { UserService } from '../services/user.service';
import { User } from '@priz/shared/src/models/security/user';

export const AcceptInvitationCallback: React.FC = () => {
  const userLoader = (props: UserLoaderProps = {}) => {
    const { invitationToken } = props;

    if (invitationToken) {
      return UserService.acceptInvitation(invitationToken);
    } else {
      return new Promise<User>((_resolve, reject) => reject({}));
    }
  };

  return (
    <>
      <AuthCallback userLoader={userLoader} />
    </>
  );
};
