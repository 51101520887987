import { DomainPrinciple } from '@priz/shared/src/models/tools/forty-principles/domain-principle';
import {
  DefaultEntityCollectionStatus,
  EntityCollectionStatus,
} from '@priz/shared/src/models/common/entity-collection-state';

export interface DomainPrincipleList {
  domainPrinciples: DomainPrinciple[];
  statuses: EntityCollectionStatus;
}

export const DefaultDomainPrincipleList: DomainPrincipleList = {
  domainPrinciples: [],
  statuses: DefaultEntityCollectionStatus,
};
