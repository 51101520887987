import { put, select, takeLatest } from 'redux-saga/effects';
import { toSuccess } from '../../../shared/store/action-creator';
import { DomainPrincipleActions, CreateDomainAction, DeleteDomainAction, DomainActionType } from '../actions';
import { InventivePrinciplesSelectors } from '../selectors/InventivePrinciplesSelectors';
import { DomainPrinciple } from '@priz/shared/src/models/tools/forty-principles/domain-principle';

function* onDomainCreate(action: CreateDomainAction) {
  const domainPrinciples: DomainPrinciple[] = yield select(InventivePrinciplesSelectors.getDomainPrinciples);

  yield put(
    // @ts-ignore
    DomainPrincipleActions.setState([...domainPrinciples, ...action.payload.domainPrinciples]),
  );
}

function* onDomainDelete(action: DeleteDomainAction) {
  const domainPrinciples: DomainPrinciple[] = yield select(InventivePrinciplesSelectors.getDomainPrinciples);

  yield put(
    // @ts-ignore
    DomainPrincipleActions.setState(domainPrinciples?.filter(item => item.domain.id !== action.meta.domainId)),
  );
}

export function* domainSagas() {
  yield takeLatest(toSuccess(DomainActionType.Create), onDomainCreate);
  yield takeLatest(toSuccess(DomainActionType.Delete), onDomainDelete);
}
