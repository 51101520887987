import React, { useEffect, useRef, useState } from 'react';
import { Drawer, Box, Hidden, useTheme, useMediaQuery } from '@mui/material';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';
import { Workspace } from '@priz/shared/src/models/workspace';
import { User } from '@priz/shared/src/models/security/user';
import { WorkspaceMenu } from '../../workspace/workspace-menu/component';
import { Project } from '@priz/shared/src/models/project';
import { ProjectMenu } from '../../project/project-menu/component';
import { UserMenu } from '../../user-profile/user-menu/component';

import { ReactComponent as LogoDarkThemeIcon } from '../../../assets/img/dark-theme-logo.svg';
import { ReactComponent as MiniLogoDarkThemeIcon } from '../../../assets/img/dark-theme-logo-mini.svg';
import { ReactComponent as AngleRightIcon } from '../../../assets/icons/angle-right.svg';

export interface AppSideNavProps {
  project?: Project;
  workspace?: Workspace;
  currentUser?: User;
  hideLogo?: boolean;
}

export const AppSideNav: React.FC<AppSideNavProps> = ({ project, workspace, currentUser, hideLogo }) => {
  const theme = useTheme();
  const styles = useStyles();
  const wrapperRef = useRef(null);
  const isUpLgBreakpoint = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const isDownMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const isDownSmBreakpoint = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const openMod = menuIsOpen ? ' _open' : '';

  const toggleOpenMenu = () => {
    setMenuIsOpen(isOpen => !isOpen);
  };

  const closeMenu = () => {
    if (menuIsOpen && isDownMdBreakpoint) setMenuIsOpen(false);
  };

  const handleClickOutside = e => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      closeMenu();
    }
  };

  useEffect(() => {
    ['mousedown', 'touchstart'].forEach(e => document.addEventListener(e, handleClickOutside));

    return () => {
      ['mousedown', 'touchstart'].forEach(e => document.removeEventListener(e, handleClickOutside));
    };
  }, [menuIsOpen, isDownMdBreakpoint]);

  return (
    <div className={`${styles.root}${openMod}`} ref={wrapperRef}>
      <Hidden lgUp>
        <div className={`${styles.openMenuButton}${openMod}`} onClick={toggleOpenMenu}>
          <AngleRightIcon />
        </div>
      </Hidden>

      <Drawer
        variant="permanent"
        classes={{
          root: styles.drawerRoot,
          paper: `${styles.drawerPaper}${openMod}`,
        }}
        anchor="left"
      >
        {!hideLogo && (
          <Hidden smDown>
            <Box component={Link} to={'/'} mb={3} className={styles.logoWrapper}>
              {menuIsOpen || isUpLgBreakpoint ? (
                <LogoDarkThemeIcon className={styles.logo} />
              ) : (
                <MiniLogoDarkThemeIcon className={styles.logo} />
              )}
            </Box>
          </Hidden>
        )}

        {project && <ProjectMenu project={project} collapse={!menuIsOpen} navItemClickHandler={closeMenu} />}

        {workspace && currentUser && <WorkspaceMenu navItemClickHandler={closeMenu} />}

        {!workspace && currentUser && (
          <UserMenu
            showAvatar={menuIsOpen || isUpLgBreakpoint || isDownSmBreakpoint}
            user={currentUser}
            navItemClickHandler={closeMenu}
          />
        )}
      </Drawer>
    </div>
  );
};
