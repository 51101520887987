import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../store/app.state';
import { DialogType } from '../model';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';

const dialogsSelector = (state: AppState) => state.dialogs;

const isOpen = (dialogType: DialogType): Selector<AppState, boolean> =>
  createSelector(dialogsSelector, dialogs => !!dialogs[dialogType]?.open);

const isAnyOpen: Selector<AppState, boolean> = createSelector(
  dialogsSelector,
  dialogs => !!Object.values(dialogs).find(dialogState => dialogState.open),
);

const isPlanUpgradeDialogOpenActive: Selector<AppState, boolean> = createSelector(
  isOpen(DialogType.PlanUpgrade),
  isOpen => isOpen || !!LocalStorageService.getItem(LocalStorageKey.OpenPlanUpgradeDialog),
);

export const DialogsSelectors = {
  isOpen,
  isAnyOpen,
  isPlanUpgradeDialogOpenActive,
};
