import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import { CrumbType } from '../model';
import {
  FormatListBulletedOutlined,
  HomeRounded,
  SnippetFolder,
  SettingsApplicationsOutlined,
  HandymanOutlined,
  VisibilityOutlined,
  CloudUploadOutlined,
  EventAvailableOutlined,
  RecordVoiceOverOutlined,
  DynamicFormOutlined,
  LightbulbOutlined,
  SportsScoreOutlined,
  AssessmentOutlined,
} from '@mui/icons-material';

const crumbIconProps: Partial<SvgIconTypeMap> = {
  props: {
    color: 'inherit',
    sx: {
      fontSize: 18,
      display: 'block',
    },
  },
};

export const crumbIconsMap: { [key in CrumbType]: JSX.Element } = {
  [CrumbType.Home]: <HomeRounded {...crumbIconProps.props} />,
  [CrumbType.ProjectList]: <FormatListBulletedOutlined {...crumbIconProps.props} />,
  [CrumbType.Project]: <SnippetFolder {...crumbIconProps.props} />,
  [CrumbType.Overview]: <VisibilityOutlined {...crumbIconProps.props} />,
  [CrumbType.Publish]: <CloudUploadOutlined {...crumbIconProps.props} />,
  [CrumbType.Tasks]: <EventAvailableOutlined {...crumbIconProps.props} />,
  [CrumbType.Meetings]: <RecordVoiceOverOutlined {...crumbIconProps.props} />,
  [CrumbType.ProblemStatement]: <DynamicFormOutlined {...crumbIconProps.props} />,
  [CrumbType.ToolsList]: <SettingsApplicationsOutlined {...crumbIconProps.props} />,
  [CrumbType.Tool]: <HandymanOutlined {...crumbIconProps.props} />,
  [CrumbType.Ideas]: <LightbulbOutlined {...crumbIconProps.props} />,
  [CrumbType.Solution]: <SportsScoreOutlined {...crumbIconProps.props} />,
  [CrumbType.Report]: <AssessmentOutlined {...crumbIconProps.props} />,
};
