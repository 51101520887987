import { pgColorScheme } from '../../theme';

export const drawRank = (ctx: CanvasRenderingContext2D, rank: number, left: number, top: number, width: number) => {
  const circleRadius = 12;
  const circleMargin = 4;
  const fontSize = 14;

  ctx.beginPath();
  ctx.fillStyle = pgColorScheme.white;
  ctx.arc(
    left + width - circleRadius - circleMargin,
    top + circleRadius + circleMargin,
    circleRadius,
    0,
    2 * Math.PI,
    false,
  );
  ctx.fill();

  ctx.beginPath();
  ctx.font = `bold ${fontSize}px roboto`;
  ctx.fillStyle = pgColorScheme.gray700;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillText(
    rank.toString(),
    left + width - circleRadius - circleMargin,
    top + circleRadius + circleMargin + fontSize * 0.1,
  );
  ctx.fill();
};
