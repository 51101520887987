import React from 'react';
import { Alert } from '@mui/material';
import { Trans } from 'react-i18next';

export const PublicToolMessage: React.FC = () => {
  return (
    <Alert severity={'error'} variant={'outlined'}>
      <Trans>
        You are currently using this tool as an anonymous user. While it's free to use, there are a few limitations.
        This tool is publicly available, and anyone can claim ownership. To reserve the tool exclusively for your use,
        please register an account and claim ownership of your work.
      </Trans>
    </Alert>
  );
};
