import { Box, BoxProps, Typography } from '@mui/material';
import { PlanInterval } from '@priz/shared/src/models/workspace';
import { Trans } from 'react-i18next';
import React, { useState } from 'react';
import { useStyles } from './styles';

export interface IntervalSelectorProps {
  initialInterval?: PlanInterval;
  onPlanSelect?: (newInterval: PlanInterval) => void;
  buttonsProps?: BoxProps;
}

export const IntervalSelector: React.FC<IntervalSelectorProps> = ({ initialInterval, onPlanSelect, buttonsProps }) => {
  const styles = useStyles();

  const [interval, setInterval] = useState<PlanInterval>(initialInterval);

  const changeHandler = (newInterval: PlanInterval) => {
    if (newInterval) selectHandler(newInterval);
  };

  const selectHandler = (newInterval: PlanInterval) => {
    setInterval(newInterval);
    if (onPlanSelect) onPlanSelect(newInterval);
  };

  return (
    <Box p={0.5} mx={'auto'} className={styles.root}>
      {[PlanInterval.Monthly, PlanInterval.Yearly].map((planInterval: PlanInterval) => (
        <Box
          px={2}
          py={1}
          minHeight={48}
          key={planInterval}
          className={[styles.button, planInterval === interval ? styles.active : styles.inactive].join(' ')}
          onClick={() => {
            changeHandler(planInterval);
          }}
          width={'50%'}
          {...buttonsProps}
        >
          <Typography component={'div'} variant={'subtitle1'}>
            {planInterval === PlanInterval.Monthly && <Trans>Monthly</Trans>}

            {planInterval === PlanInterval.Yearly && (
              <>
                <Trans>Yearly</Trans>{' '}
                <span className={styles.textHighlight}>
                  ~17% <Trans>less</Trans>
                </span>
              </>
            )}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
