import React, { useEffect } from 'react';
import { InputBase } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useStyles } from './styles';
import { validateOptions } from '@priz/shared/src/utils/form';

interface FiveWhysNodeTextareaProps {
  placeholder?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  onError?: () => void;
  compact?: boolean;
}

export const FiveWhysNodeTextarea: React.FC<FiveWhysNodeTextareaProps> = ({
  placeholder,
  defaultValue,
  onChange,
  onError,
  compact,
}) => {
  const styles = useStyles();

  const { handleSubmit, control, getValues, setValue } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      text: defaultValue || '',
    },
  });

  useEffect(() => {
    if (getValues().text !== defaultValue) {
      setValue('text', defaultValue);
    }
  }, [defaultValue]);

  const changeHandler = () => {
    handleSubmit(
      formData => {
        if (onChange) onChange(formData.text);
      },
      () => {
        if (onError) onError();
      },
    )();
  };

  return (
    <Controller
      name={'text'}
      control={control}
      rules={{
        validate: {
          ...validateOptions.hasText(''),
        },
      }}
      render={({ field, fieldState: { error } }) => {
        const rootClassNames = [styles.root];

        if (error) rootClassNames.push('error');
        if (compact) rootClassNames.push('compact');

        return (
          <InputBase
            value={field.value}
            placeholder={placeholder}
            onChange={e => {
              field.onChange(e);
              changeHandler();
            }}
            fullWidth
            multiline
            className={rootClassNames.join(' ')}
            inputProps={{ className: styles.textarea }}
          />
        );
      }}
    />
  );
};
