import React from 'react';
import { Box, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import {
  AuthDialogContainer,
  SharedAuthDialogContainerProps,
} from '@priz/shared/src/components/security/auth-dialog-container/component';
import { SignUpForm } from '@priz/shared/src/components/security/signup-form/component';
import { Auth0RedirectKey } from '@priz/shared/src/models/auth0';
import { DialogsSelectors } from '../../dialogs/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { DialogType } from '../../dialogs/store/model';
import { DialogsActions } from '../../dialogs/store/actions';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';

export interface SharedSignupDialogProps extends SharedAuthDialogContainerProps {
  beforeSend?: () => void;
}

interface SignupDialogProps extends Omit<SharedSignupDialogProps, 'open'> {
  forceOpen?: boolean;
  errorMessage?: string;
}

export const SignupDialog: React.FC<SignupDialogProps> = ({
  forceOpen,
  beforeSend,
  errorMessage,
  onClose,
  ...rest
}) => {
  const dispatch = useDispatch();

  const isOpenByState = useSelector(DialogsSelectors.isOpen(DialogType.Signup));

  const closeHandler = () => {
    removeSignupCallbackEffects();

    dispatch(DialogsActions.close(DialogType.Signup));

    if (onClose) {
      onClose();
    }
  };

  const removeSignupCallbackEffects = () => {
    LocalStorageService.removeItem(LocalStorageKey.OpenPlanUpgradeDialog);
  };

  return (
    <AuthDialogContainer
      open={forceOpen || isOpenByState}
      onClose={forceOpen ? undefined : closeHandler}
      sx={forceOpen ? { backdropFilter: 'blur(2px)' } : undefined}
      {...rest}
    >
      <Typography variant={'h6'} component={Box} mb={4}>
        <Trans>Register for free, no credit card required</Trans>
      </Typography>

      <SignUpForm redirectKey={Auth0RedirectKey.Signup} beforeSend={beforeSend} errorMessage={errorMessage} />
    </AuthDialogContainer>
  );
};
