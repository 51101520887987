import React from 'react';
import { useStyles } from './styles';
import { Box, Paper, Typography, BoxProps } from '@mui/material';
import { Trans } from 'react-i18next';

import { ReactComponent as BackToWsIcon } from '../../../assets/img/back-to-ws.svg';

export interface AuthContainerProps {
  title: string;
  backButton?: boolean;
  contentBoxProps?: BoxProps;
}

export const AuthContainer: React.FC<AuthContainerProps> = ({
  title,
  backButton = true,
  contentBoxProps,
  children,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Box my={{ xs: 1, sm: 3, md: 5 }}>
          <Typography variant={'h5'} className={styles.title}>
            <Trans>{title}</Trans>
          </Typography>
        </Box>

        <Paper>
          <Box className={styles.backgroundWrap}>
            <Box py={4} px={{ xs: 2, sm: 10 }}>
              <Box pb={3} className={styles.contentWrapper} {...contentBoxProps}>
                {children}
              </Box>
            </Box>
          </Box>
        </Paper>

        {backButton && (
          <Box className={styles.flexCenterWrap} mt={2}>
            <a href={'https://www.priz.guru'} className={styles.backToWebsiteLink}>
              <BackToWsIcon className={styles.backToWebsiteIcon} />

              <Typography variant={'caption'} component={'span'} className={styles.backToWebsiteText}>
                <Trans>Back to website</Trans>
              </Typography>
            </a>
          </Box>
        )}
      </div>
    </div>
  );
};
