import React from 'react';
import { AuthFormContainer } from '../auth-form-container/component';
import { Backdrop, BackdropProps, BoxProps } from '@mui/material';
import { useStyles } from './styles';

export interface SharedAuthDialogContainerProps extends BackdropProps {
  onClose?: () => void;
}

export interface AuthDialogContainerProps extends SharedAuthDialogContainerProps {
  containerProps?: BoxProps;
}

export const AuthDialogContainer: React.FC<AuthDialogContainerProps> = ({
  onClose,
  containerProps,
  children,
  className,
  ...rest
}) => {
  const styles = useStyles();
  const backdropClassnames = [styles.backdrop];

  if (className) {
    backdropClassnames.push(className);
  }

  return (
    <Backdrop className={backdropClassnames.join(' ')} {...rest}>
      <div className={styles.closeArea} onClick={onClose} />

      <AuthFormContainer contentBoxProps={containerProps} overflowScroll={true} onClose={onClose}>
        {children}
      </AuthFormContainer>
    </Backdrop>
  );
};
