import React from 'react';
import { useSelector } from 'react-redux';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import { Box, Divider, Typography } from '@mui/material';
import { UserContextSelectors } from '../../security/store/selectors';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { PasswordForm } from './password-form/component';
import { PrivacyForm } from './privacy-form/component';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { ConnectAccountButtons } from '../connect-account-buttons/component';
import { MfaForm } from './mfa-form/component';

export const SecuritySetting: React.FC = () => {
  const user = useSelector(UserContextSelectors.getCurrentUser);
  const isUserLoaded = useSelector(UserSelectors.isLoaded);
  const isUserContextLoaded = useSelector(UserContextSelectors.isLoaded);

  if (!isUserLoaded || !isUserContextLoaded) {
    return <LoadingOverlay loading={true} backdropStyles={{ backgroundColor: 'transparent' }} />;
  }

  return (
    <>
      <PageTitleWithDocLink title={<Trans>Security & Privacy</Trans>} />

      <Box pt={3} maxWidth={655}>
        <Typography variant={'h6'} component={Box} mb={2}>
          <Trans>Connections</Trans>
        </Typography>

        <ConnectAccountButtons user={user} />
      </Box>

      {user.auth0Id && (
        <>
          <Box my={{ xs: 4, md: 6 }}>
            <Divider />
          </Box>

          <Box maxWidth={655}>
            <Typography variant={'h6'} component={Box} mb={2}>
              <Trans>Password Update</Trans>
            </Typography>

            <PasswordForm />
          </Box>
        </>
      )}

      <Box my={{ xs: 4, md: 6 }}>
        <Divider />
      </Box>

      <Box maxWidth={655}>
        <Typography variant={'h6'} component={Box} mb={2}>
          <Trans>MFA</Trans>
        </Typography>

        <MfaForm user={user} />
      </Box>

      <Box my={{ xs: 4, md: 6 }}>
        <Divider />
      </Box>

      <Box maxWidth={655}>
        <Typography variant={'h6'} component={Box} mb={2}>
          <Trans>Privacy Settings</Trans>
        </Typography>

        <PrivacyForm user={user} />
      </Box>
    </>
  );
};
