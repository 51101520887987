import React from 'react';
import { useStyles } from './styles';
import { Box, Grid, Typography } from '@mui/material';
import { RankingScore } from '../../ranking-score/component';

export interface FiveWhysNodeRenderers {
  nodeControlsRenderer?: () => JSX.Element;
  textEditorRenderer?: () => JSX.Element;
}

export interface FiveWhysNodeProps extends FiveWhysNodeRenderers {
  description: string;
  viewMode?: boolean;
  compact?: boolean;
  deadEndAchieved?: boolean;
  rank?: number;
  onRef?: (el: HTMLDivElement) => void;
}

export const FiveWhysNode: React.FC<FiveWhysNodeProps> = ({
  description,
  viewMode,
  deadEndAchieved,
  rank,
  compact,
  nodeControlsRenderer,
  textEditorRenderer,
  onRef,
}) => {
  const styles = useStyles();
  const hasRank = typeof rank !== 'undefined';

  const renderNodeDecor = () => {
    return (
      <>
        {hasRank && (
          <Box className={styles.rankContainer} pt={1} pr={1}>
            <RankingScore rankingScore={rank} small={true} />
          </Box>
        )}
        <div className={styles.nodeBorder} />
      </>
    );
  };

  const refHandler = (el: HTMLDivElement) => {
    if (el && onRef) onRef(el);
  };

  return (
    <Grid ref={refHandler} item sm={'auto'} className={styles.root}>
      {!viewMode && textEditorRenderer && (
        <Box
          component={'label'}
          boxSizing={'border-box'}
          pr={hasRank ? 4 : 0}
          className={[styles.contentWrap, styles.textareaWrap].join(' ')}
        >
          {textEditorRenderer && textEditorRenderer()}
          {renderNodeDecor()}
        </Box>
      )}

      {viewMode && (
        <div className={styles.contentWrap}>
          <Box p={compact ? 1 : 2} pr={hasRank ? 5 : compact ? 1 : 2}>
            <Typography className={styles.description}>{description}</Typography>
          </Box>

          {renderNodeDecor()}
        </div>
      )}

      {nodeControlsRenderer && !viewMode && !deadEndAchieved && (
        <div className={styles.causeControlsWrap}>{nodeControlsRenderer && nodeControlsRenderer()}</div>
      )}
    </Grid>
  );
};
