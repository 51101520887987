import React, { useState } from 'react';
import { Menu } from '@mui/material';
import { Trans } from 'react-i18next';
import { SideNavItem, SideNavItemProps } from '../../../shared/section-sidenav-item/component';
import flattenChildren from 'react-keyed-flatten-children';

import { ReactComponent as GearIcon } from '../../../../assets/icons/gear.svg';

export interface ButtonCreateNewProps {
  sideNavItemProps?: SideNavItemProps;
  disabled?: boolean;
}

export const ListItemWithMenu: React.FC<ButtonCreateNewProps> = ({ sideNavItemProps, children, disabled }) => {
  const childrenArray = flattenChildren(children);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <SideNavItem
        title={<Trans>Settings</Trans>}
        icon={<GearIcon />}
        variant={'blue'}
        onClick={openMenu}
        {...sideNavItemProps}
        disabled={disabled}
      />

      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu}>
        {childrenArray.map((child, key) => (
          <div key={key} onClick={closeMenu}>
            {child}
          </div>
        ))}
      </Menu>
    </React.Fragment>
  );
};
