import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Auth0Provider, AuthService } from '@priz/shared/src/services/auth';
import { PrizLoadingOverlay } from '@priz/shared/src/components/priz-loading-overlay/component';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../shared/analytics-event.enum';
import { UserContextActions } from '../store/actions';
import { UserContextSelectors } from '../store/selectors';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { WorkspaceMembersActions } from '../../workspace/store/actions';
import { Auth0ParseHashError } from 'auth0-js';
import { User } from '@priz/shared/src/models/security/user';
import { AuthErrorCode } from '@priz/shared/src/components/security/auth-error-message/AuthErrorCode';
import { resolveRedirect } from '../utils';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { CookiesKey } from '@priz/shared/src/services/cookies';
import { UserSettingsSelectors } from '../../user/store/selectors/user-settings.selectors';
import { UserSettingsActions } from '../../user/store/actions/user-settings.actions';
import { AuthFormType } from '@priz/shared/src/models/auth0';
import { AxiosService } from '@priz/shared/src/services/axios';

const trackUser = (user: User) => {
  AnalyticsService.setUserIdentity(user.id.toString());

  const properties = {
    id: user.id,
    email: user.email,
    createdDate: user.dateCreated,
    firstName: user.profile.firstName,
    lastName: user.profile.lastName,
    organization: user.profile.organization,
    location: user.profile.location,
  };

  AnalyticsService.setUserProperties(properties);

  AnalyticsService.track(AnalyticsEvent.USER_AUTHENTICATED);
};

export interface UserLoaderProps {
  invitationToken?: string;
  primaryAccessToken?: string;
  referralCode?: string;
}

interface AuthCallbackProps {
  userLoader: (props?: UserLoaderProps) => Promise<User>;
}

export const AuthCallback: React.FC<AuthCallbackProps> = ({ userLoader }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const isLoaded = useSelector(UserContextSelectors.isLoaded);
  const workspaceProjectsCountMap = useSelector(UserContextSelectors.workspaceProjectsCountMap);
  const areUserSettingsLoaded = useSelector(UserSettingsSelectors.isLoaded);

  useEffect(() => {
    AxiosService.disableAnonymousAccess();

    if (LocalStorageService.isAvailable()) {
      AuthService.handleAuthentication(successCallback, failureCallback);
    } else {
      Auth0Provider.crossOriginVerification();
    }
  }, []);

  useEffect(() => {
    if (isLoaded && !areUserSettingsLoaded) {
      dispatch(UserSettingsActions.load());
    }

    if (isLoaded && areUserSettingsLoaded && LocalStorageService.isAvailable()) {
      const invitationToken = LocalStorageService.getItem(LocalStorageKey.InvitationToken);

      if (invitationToken) {
        LocalStorageService.removeItem(LocalStorageKey.InvitationToken);
        dispatch(WorkspaceMembersActions.acceptInvitation(invitationToken, navigate));
      } else {
        resolveRedirect({
          navigate,
          workspaceProjectsCountMap,
        });
      }
    }
  }, [isLoaded, areUserSettingsLoaded]);

  const successCallback = () => {
    const invitationToken = LocalStorageService.getItem(LocalStorageKey.InvitationToken);
    const primaryAccessToken = LocalStorageService.getItem(LocalStorageKey.PrimaryAccessToken);
    const lastSubmittedAuthFormType = LocalStorageService.getItem(LocalStorageKey.LastSubmittedAuthFormType);
    const referralCode = Cookies.get(CookiesKey.ReferralCode);

    if (lastSubmittedAuthFormType) LocalStorageService.removeItem(LocalStorageKey.LastSubmittedAuthFormType);

    userLoader({ invitationToken, primaryAccessToken, referralCode }).then(
      user => {
        if (invitationToken) LocalStorageService.removeItem(LocalStorageKey.InvitationToken);
        if (primaryAccessToken) LocalStorageService.removeItem(LocalStorageKey.PrimaryAccessToken);
        if (referralCode) Cookies.remove(CookiesKey.ReferralCode);

        AuthService.setUserInfo(user);
        trackUser(user);
        dispatch(UserContextActions.load(pathname, navigate));
      },
      error => {
        const { response } = error || {};
        const { status } = response || {};

        if (+status === 401) {
          LocalStorageService.setItem(LocalStorageKey.LastAuthErrorCode, AuthErrorCode.PgUnauthorized);
        }

        if (+status === 409) {
          LocalStorageService.setItem(LocalStorageKey.LastAuthErrorCode, AuthErrorCode.PgConflictEmail);
        }

        if (+status === 412) {
          LocalStorageService.setItem(LocalStorageKey.LastAuthErrorCode, AuthErrorCode.PgUnverifiedEmail);
        }

        AuthService.logout(lastSubmittedAuthFormType as AuthFormType);
      },
    );
  };

  const failureCallback = (error: Auth0ParseHashError) => {
    //TODO: handle error
    console.log(error);
    navigate('/');
  };

  return <PrizLoadingOverlay />;
};
