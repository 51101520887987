import React from 'react';
import { MenuItem, Select, SelectChangeEvent, SelectProps, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { HelperText } from '@priz/shared/src/components/form-elements';

interface SeatsQuantitySelectProps extends SelectProps {
  value: number;
  minNumber?: number;
  helperText?: React.ReactNode;
  onQuantitySelect?: (value: number) => void;
}

export const SeatsQuantitySelect: React.FC<SeatsQuantitySelectProps> = ({
  value,
  minNumber,
  helperText,
  onQuantitySelect,
  ...rest
}) => {
  const changeHandler = (event: SelectChangeEvent) => {
    if (onQuantitySelect) {
      onQuantitySelect(+event.target.value);
    }
  };

  return (
    <>
      <Select
        value={value.toString()}
        variant={'standard'}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 300,
            },
          },
        }}
        onChange={changeHandler}
        {...rest}
      >
        {[...Array(100).keys()].map(key => {
          const number = key + 1;

          return (
            <MenuItem key={number} value={number.toString()} disabled={number < minNumber}>
              <Typography noWrap>
                {number}&nbsp;<Trans>{number > 1 ? 'seats' : 'seat'}</Trans>
              </Typography>
            </MenuItem>
          );
        })}
      </Select>

      {helperText && <HelperText text={helperText} />}
    </>
  );
};
