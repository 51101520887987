import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
    padding: 12,
    marginBottom: 12,
    backgroundColor: 'rgba(255,255,255,0.8)',
    borderRadius: 4,
  },
});
