import { FluxStandardAction } from 'flux-standard-action';
import { createPgAction } from '../../../shared/store/action-creator';
import { WorkspaceMembership } from '@priz/shared/src/models/workspace';
import { IUser } from '@priz/shared/src/models/security/user';
import { UserContextService } from '@priz/shared/src/services/user';
import { UserService } from '../../services/user.service';
import { UserContextSelectors } from '../selectors';
import { WorkspaceUtils } from '../../../workspace/services';
import { AuthService } from '@priz/shared/src/services/auth';
import { NavigateFunction } from 'react-router-dom';
import { Team } from '@priz/shared/src/models/team';
import { AuthFormType } from '@priz/shared/src/models/auth0';
import { WorkspaceProjectsCountMap } from '../model';

export enum UserContextActionType {
  UserContext_Load = 'userContext/load',
}

interface UserContextActionMeta {
  userId?: number;
  pathname?: string;
  redirect?: string;
  navigate?: NavigateFunction;
}

export interface UserContextActionPayload {
  currentUser: IUser;
  workspaceMemberships: WorkspaceMembership[];
  teams: Team[];
  workspaceProjectsCountMap: WorkspaceProjectsCountMap;
}

export type UserContextAction = FluxStandardAction<string, UserContextActionPayload, UserContextActionMeta>;

const loadSuccessCallback = (userContextPayload: UserContextActionPayload, meta: UserContextActionMeta) => {
  const selectedWorkspaceId = UserContextService.getSelectedWorkspaceId();
  const selectedTeamId = UserContextService.getSelectedTeamId();

  let selectedWorkspaceMembership =
    selectedWorkspaceId && userContextPayload.workspaceMemberships.find(wm => wm.workspace.id === selectedWorkspaceId);

  let selectedTeam = selectedTeamId && userContextPayload.teams.find(t => t.id === selectedTeamId);

  if ((!selectedWorkspaceId || !selectedWorkspaceMembership) && userContextPayload.workspaceMemberships.length > 0) {
    const personalWorkspaceMembership = userContextPayload.workspaceMemberships.find(wm =>
      WorkspaceUtils.isPersonalOrPro(wm.workspace),
    );
    const initialWorkspaceMembership = personalWorkspaceMembership || userContextPayload.workspaceMemberships[0];

    UserContextService.setSelectedWorkspaceId(initialWorkspaceMembership.workspace.id);
    selectedWorkspaceMembership = initialWorkspaceMembership;
  }

  if ((!selectedTeamId || !selectedTeam) && userContextPayload.teams.length > 0) {
    const firstTeam = userContextPayload.teams.at(0);
    if (!firstTeam) {
      throw new Error(`Could not resolve a default team for user - ${userContextPayload.currentUser.id}`);
    }
    selectedTeam = firstTeam;
    UserContextService.setSelectedTeamId(selectedTeam.id);
  }

  if (
    selectedWorkspaceMembership &&
    (!selectedWorkspaceMembership.workspace.plan || !selectedWorkspaceMembership.workspace.plan.id) &&
    !meta?.pathname?.startsWith('/workspace/billing')
  ) {
    if (meta.navigate) meta.navigate(['/workspace', 'billing'].join('/'));
  }

  if (meta.navigate && meta.redirect) {
    meta.navigate(meta.redirect);
  }
};

const load = createPgAction(
  UserContextActionType.UserContext_Load,
  UserService.getUserContext,
  (pathname?: string, navigate?: NavigateFunction, redirect?: string) => ({ pathname, navigate, redirect }),
  loadSuccessCallback,
  () => AuthService.logout(AuthFormType.Login),
  UserContextSelectors.isLoaded,
);

const forceLoad = createPgAction(
  UserContextActionType.UserContext_Load,
  UserService.getUserContext,
  (pathname?: string, navigate?: NavigateFunction, redirect?: string) => ({ pathname, navigate, redirect }),
  loadSuccessCallback,
  () => AuthService.logout(AuthFormType.Login),
);

export const UserContextActions = {
  load,
  forceLoad,
};
