import React from 'react';
import { Avatar, Divider, Grid, Typography } from '@mui/material';
import { PMapContributedPerception } from '@priz/shared/src/models/tools/pmap/pmap-contributed-perception';
import { Trans } from 'react-i18next';

interface ContributedPerceptionAvatarProps {
  perception: PMapContributedPerception;
  showEmail?: boolean;
}

export const ContributedPerceptionAvatar: React.FC<ContributedPerceptionAvatarProps> = ({
  perception,
  showEmail = true,
}) => {
  return (
    <Grid container spacing={1} alignItems={'center'}>
      <Grid item>
        <Avatar
          src={perception.userAvatar}
          sx={{
            width: 30,
            height: 30,
          }}
        />
      </Grid>

      <Grid item>
        <Typography variant={'body2'}>{perception.userName || <Trans>Anonymous</Trans>}</Typography>
      </Grid>

      {showEmail && perception.userEmail && (
        <>
          <Grid item>
            <Divider orientation={'vertical'} sx={{ height: 20 }} />
          </Grid>

          <Grid item>
            <Typography variant={'body2'}>{perception.userEmail}</Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
