import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: 50,
    },
    pageTitle: {
      color: pgColorScheme.lightGray,
      textTransform: 'uppercase',
      textAlign: 'center',
      fontWeight: 700,
      fontSize: 64,
    },
    imgWrap: {
      textAlign: 'center',
    },
    img: {
      width: 600,
      height: 'auto',
    },

    [theme.breakpoints.down('lg')]: {
      pageTitle: {
        fontSize: 54,
      },
      img: {
        width: 500,
      },
    },

    [theme.breakpoints.down('md')]: {
      pageTitle: {
        fontSize: 42,
      },
      img: {
        width: 400,
      },
    },

    [theme.breakpoints.down('sm')]: {
      pageTitle: {
        fontSize: 32,
      },
      img: {
        width: 280,
      },
    },
  }),
);
