import { createSelector, Selector } from 'reselect';
import { EntityCollectionStatus, EntityMap } from '@priz/shared/src/models/common/entity-collection-state';
import { AppState } from '../../../store/app.state';
import {
  LevelPlansMap,
  OrderedPlanLevelMap,
  Plan,
  PlanCollection,
  PlanInterval,
  PlanLevel,
  SubscriptionStatus,
} from '@priz/shared/src/models/workspace';
import { WorkspaceSelectors } from './workspace.selectors';

const planCollectionSelector = (state: AppState): PlanCollection => state.plans;

const getPlanEntityMap: Selector<AppState, EntityMap<Plan>> = createSelector(
  planCollectionSelector,
  collection => collection.entities,
);

const statusesSelector: Selector<AppState, EntityCollectionStatus> = createSelector(
  planCollectionSelector,
  collection => collection.statuses,
);

const getById = (id: number): Selector<AppState, Plan> => createSelector(getPlanEntityMap, entityMap => entityMap[id]);

const getAllActive = (exclude: PlanLevel[] = []): Selector<AppState, Plan[]> =>
  createSelector(getPlanEntityMap, entityMap =>
    Object.values(entityMap)
      .filter(p => p.active && !exclude.includes(p.level))
      .sort((a, b) => OrderedPlanLevelMap[a.level] - OrderedPlanLevelMap[b.level]),
  );

const getAllGroupedByLevelAndInterval = (exclude: PlanLevel[] = []): Selector<AppState, LevelPlansMap> =>
  createSelector(getAllActive(exclude), plans => {
    return plans.reduce((map, plan) => {
      if (map[plan.level]) {
        map[plan.level][plan.interval] = { ...plan };
      } else {
        // @ts-ignore
        map[plan.level] = { [plan.interval]: { ...plan } };

        if (plan.level === PlanLevel.Personal && plan.interval === PlanInterval.Monthly) {
          map[PlanLevel.Personal][PlanInterval.Yearly] = { ...plan };
        }
      }

      return map;
    }, {} as LevelPlansMap);
  });

const getCurrentPlan: Selector<AppState, Plan | undefined> = createSelector(
  [getPlanEntityMap, WorkspaceSelectors.getSelectedWorkspace],
  (plansMap, workspace) => {
    const isActive = workspace && workspace.plan && workspace.subscriptionStatus !== SubscriptionStatus.Canceled;
    return isActive ? plansMap[workspace.plan.id] : undefined;
  },
);

const isPlanSet: Selector<AppState, boolean> = createSelector(
  WorkspaceSelectors.getSelectedWorkspace,
  workspace => !!workspace?.plan?.id,
);

const isLoading: Selector<AppState, boolean> = createSelector(statusesSelector, statuses => statuses.loading);
const isLoaded: Selector<AppState, boolean> = createSelector(statusesSelector, statuses => statuses.loaded);
const isCreating: Selector<AppState, boolean> = createSelector(statusesSelector, statuses => statuses.creating);

export const PlanSelectors = {
  getAllActive,
  getAllGroupedByLevelAndInterval,
  getById,
  getCurrentPlan,
  isPlanSet,
  isCreating,
  isLoaded,
  isLoading,
};
