import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectSelector } from '../../project/store/selectors';
import { CertificateSelectors } from '../store/selectors/certificate.selectors';
import { CertificateActions } from '../store';
import { UserContextSelectors } from '../../security/store/selectors';
import { GenerateCertificate } from '../generate-certificate/component';
import { EditCertificate } from '../edit-certificate/component';
import { UserActions } from '../../user/store/actions/user.actions';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';

export interface CertificateProps {
  projectId: number;
}

export const Certificate: React.FC<CertificateProps> = ({ projectId }) => {
  const dispatch = useDispatch();

  const project = useSelector(ProjectSelector.getById(projectId));
  const certificate = useSelector(CertificateSelectors.certificateByProjectIdSelector(projectId));
  const user = useSelector(UserContextSelectors.getCurrentUser);
  const certificateIsLoading = useSelector(CertificateSelectors.isCertificateLoadingByProjectIdSelector(projectId));
  const userIsLoaded = useSelector(UserSelectors.isLoaded);

  useEffect(() => {
    dispatch(CertificateActions.loadCertificateByProjectId(projectId));
    dispatch(UserActions.loadCurrentUser());
  }, []);

  if (certificateIsLoading || !userIsLoaded) return null;

  return (
    <ContentContainer>
      {!certificate && project && <GenerateCertificate project={project} />}

      {certificate && project && user && <EditCertificate certificate={certificate} project={project} user={user} />}
    </ContentContainer>
  );
};
