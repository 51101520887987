import React from 'react';
import { NotificationActions } from '../react/modules/notification/store';
import { IconButton, IconButtonProps } from '@mui/material';
import { ContentCopyOutlined } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

interface CopyToClipboardButtonProps extends IconButtonProps {
  value: string;
  successMessage?: string;
  errorMessage?: string;
}

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  value,
  successMessage,
  errorMessage,
  ...rest
}) => {
  const dispatch = useDispatch();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value).then(
      () => {
        dispatch(NotificationActions.add(successMessage || 'Copied to clipboard!', 'success'));
      },
      () => {
        dispatch(NotificationActions.add(errorMessage || 'Failed to copy.', 'error'));
      },
    );
  };

  return (
    <IconButton size={'small'} onClick={copyToClipboard} {...rest}>
      <ContentCopyOutlined />
    </IconButton>
  );
};
