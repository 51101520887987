import React, { useEffect, useState } from 'react';
import { Box, BoxProps, Portal } from '@mui/material';
import { PgPopper, PgPopperProps } from '../pg-popper/component';

interface PopperWrapProps extends Partial<PgPopperProps> {
  openHandler?: 'click' | 'hover' | 'manually';
  onOpenToggle?: (open: boolean) => void;
  portalContainer?: Element;
  containerProps?: BoxProps;
  disabled?: boolean;
}

export const PopperWrap: React.FC<PopperWrapProps> = ({
  open,
  anchorEl,
  openHandler = 'click',
  onOpenToggle,
  portalContainer,
  containerProps,
  onClose,
  children,
  disabled,
  ...rest
}) => {
  const [popperIsOpen, setPopperIsOpen] = useState<boolean>(open || false);
  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);
  const childrenArray = React.Children.toArray(children);

  useEffect(() => {
    if (typeof open !== 'undefined') setPopperIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (onOpenToggle) onOpenToggle(popperIsOpen);
  }, [popperIsOpen]);

  const openPopper = () => {
    setPopperIsOpen(true);
  };

  const closePopper = () => {
    setPopperIsOpen(false);
    if (onClose) onClose();
  };

  const onWrapperRef = (element: HTMLDivElement) => {
    if (element) setWrapperRef(element);
  };

  return (
    <Box
      ref={onWrapperRef}
      {...(!disabled && openHandler === 'hover'
        ? {
            onMouseEnter: openPopper,
            onMouseLeave: closePopper,
          }
        : {})}
      {...(!disabled && openHandler === 'click'
        ? {
            onClick: openPopper,
          }
        : {})}
      {...containerProps}
    >
      {childrenArray[0]}

      {(anchorEl || wrapperRef) && (
        <Portal disablePortal={!portalContainer} container={portalContainer}>
          <PgPopper
            open={disabled ? false : popperIsOpen}
            anchorEl={anchorEl || wrapperRef}
            onClose={closePopper}
            {...rest}
          >
            {childrenArray[1]}
          </PgPopper>
        </Portal>
      )}
    </Box>
  );
};
