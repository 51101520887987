import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Network } from '@priz/shared/src/lib/vis/esnext';
import { DataSet } from 'vis-data';
import { DragWrapper } from '../drag-wrapper/component';
import { DropWrapper } from '../drop-wrapper/component';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import {
  addNode,
  HistoryListener,
  manageNetworkEvents,
  useNetworkHistory,
} from '@priz/shared/src/components/network-diagram/utils';
import { EdgeProps, NodeProps } from '@priz/shared/src/models/vis-network';
import { PaywallActions } from '../../modules/paywall/store';
import { ToolType } from '@priz/shared/src/models/tools';
import { VisScreenshotButton } from '../vis-screenshot-button/component';
import { NetworkSetCenterButton } from '@priz/shared/src/components/network-set-center-button/component';
import { FullscreenButton } from '@priz/shared/src/components/fullscreen-button/component';
import { ElementsViewerButton } from '@priz/shared/src/components/elements-viewer/elements-viewer-button/component';
import {
  CanvasControlButton,
  CanvasControlsContainer,
  CanvasControlsGroup,
} from '@priz/shared/src/components/canvas-controls';

import { ReactComponent as UndoIcon } from '../../../../assets/icons/undo.svg';
import { ReactComponent as RedoIcon } from '../../../../assets/icons/redo.svg';
import { ReactComponent as CursorIcon } from '../../../../assets/icons/cursor.svg';
import { ReactComponent as HandIcon } from '../../../../assets/icons/cursor-hand-2.svg';

export type NodeTypeButton = {
  type: string;
  icon: React.FC;
  tooltip?: string;
};

export type ExtraButton = {
  icon: React.FC;
  callback: () => void;
  tooltip?: string;
  active?: boolean;
};

type DefaultControls = 'edge-mode' | 'history' | 'viewer-mode';

export interface NetworkToolsMenuProps {
  network: Network;
  nodesDataSet: DataSet<NodeProps>;
  edgesDataSet: DataSet<EdgeProps>;
  toolType: ToolType;
  checkIsLimitReached?: () => boolean;
  addNodeButtons?: NodeTypeButton[];
  extraButtons?: ExtraButton[];
  onToggleEdgeMod?: (isEnabled: boolean) => void;
  nodePropsDecorator?: (nodeProps: Partial<NodeProps>) => Partial<NodeProps>;
  nodeDataDecorator?: (nodeProps: Partial<NodeProps>) => Partial<NodeProps>;
  controls?: DefaultControls[];
  viewMode?: boolean;
  disabled?: boolean;
  undoCallback?: () => void;
  redoCallback?: () => void;
  historyListener?: HistoryListener;
  historyKeyboardControl?: boolean;
}

const networkEvents = (modifyingCallback: (isModifying: boolean) => void) => ({
  dragStart: () => {
    modifyingCallback(true);
  },
  dragEnd: () => {
    modifyingCallback(false);
  },
  controlNodeDragging: () => {
    modifyingCallback(true);
  },
  controlNodeDragEnd: () => {
    modifyingCallback(false);
  },
});

export const NetworkToolsMenu: React.FC<NetworkToolsMenuProps> = ({
  network,
  nodesDataSet,
  edgesDataSet,
  addNodeButtons,
  extraButtons,
  onToggleEdgeMod,
  nodePropsDecorator,
  nodeDataDecorator,
  controls = [],
  viewMode,
  disabled,
  checkIsLimitReached,
  toolType,
  undoCallback,
  redoCallback,
  historyListener = HistoryListener.All,
  historyKeyboardControl,
}) => {
  const dispatch = useDispatch();
  const [edgeMode, setEdgeMode] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedNodeType, setSelectedNodeType] = useState(null);
  const [isModifying, setIsModifying] = useState(false);
  const [historyStart, historyEnd, undoChanges, redoChanges] = useNetworkHistory(
    nodesDataSet,
    edgesDataSet,
    historyKeyboardControl,
    undefined,
    historyListener,
    undoCallback,
    redoCallback,
    disabled || isModifying,
  );

  useEffect(() => {
    const events = networkEvents(modifyingCallback);

    if (network) manageNetworkEvents(network, events);

    return () => {
      if (network) {
        manageNetworkEvents(network, events, true);
      }
    };
  }, [network]);

  useEffect(() => {
    if (onToggleEdgeMod) onToggleEdgeMod(edgeMode);
  }, [edgeMode]);

  useEffect(() => {
    if (network) document.addEventListener('keydown', documentKeyDownHandler);

    return () => {
      document.removeEventListener('keydown', documentKeyDownHandler);
    };
  }, [selectedNodeType]);

  const modifyingCallback = (modifying: boolean) => {
    setIsModifying(modifying);
  };

  const documentKeyDownHandler = e => {
    if (selectedNodeType && (e.key === 'Escape' || e.keyCode === 27)) {
      setSelectedNodeType(null);
    }
  };

  const nodeClickHandler = (type: string) => {
    if (disabled) return null;
    setSelectedNodeType(type);
  };

  const toggleEdgeMode = (enable: boolean) => {
    if (disabled) return null;

    network.unselectAll();
    network.disableEditMode();

    if (enable) {
      network.setOptions({ interaction: { dragNodes: false } });
      network.addEdgeMode();
      setEdgeMode(true);
    } else {
      network.setOptions({ interaction: { dragNodes: true } });
      setEdgeMode(false);
    }
  };

  const draggingStateChangeHandler = (dragging: boolean) => {
    setIsDragging(dragging);
  };

  const dropAreaClickHandler = (x: number, y: number) => {
    if (selectedNodeType) {
      if (checkIsLimitReached && checkIsLimitReached()) {
        dispatch(PaywallActions.show(toolType));
      } else {
        const data = { type: selectedNodeType, ...network.DOMtoCanvas({ x, y }) };

        addNode({
          nodesDataSet,
          nodePropsDecorator,
          nodeProps: nodeDataDecorator ? nodeDataDecorator(data) : data,
        });
      }
    }

    setSelectedNodeType(null);
  };

  const nodeDropHandler = (type, x, y) => {
    if (checkIsLimitReached && checkIsLimitReached()) {
      dispatch(PaywallActions.show(toolType));
    } else {
      const data = { type, ...network.DOMtoCanvas({ x, y }) };

      addNode({
        nodesDataSet,
        nodePropsDecorator,
        nodeProps: nodeDataDecorator ? nodeDataDecorator(data) : data,
      });
    }
  };

  const undoClickHandler = () => {
    network.unselectAll();
    undoChanges();
  };

  const redoClickHandler = () => {
    network.unselectAll();
    redoChanges();
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <DropWrapper isDragging={isDragging} nodeIsSelected={!!selectedNodeType} onClick={dropAreaClickHandler} />

      <CanvasControlsContainer>
        {!viewMode && controls.includes('edge-mode') && (
          <CanvasControlsGroup>
            <CanvasControlButton
              tooltip={'Drag mode'}
              active={edgeMode}
              disabled={disabled}
              onClick={() => {
                toggleEdgeMode(true);
              }}
            >
              <CursorIcon />
            </CanvasControlButton>

            <CanvasControlButton
              tooltip={'Edge mode'}
              active={!edgeMode}
              disabled={disabled}
              onClick={() => {
                toggleEdgeMode(false);
              }}
            >
              <HandIcon />
            </CanvasControlButton>
          </CanvasControlsGroup>
        )}

        {!viewMode && addNodeButtons && (
          <CanvasControlsGroup>
            {addNodeButtons.map((item, key) => {
              const { type, icon: Icon, tooltip } = item;

              return (
                <CanvasControlButton
                  key={key}
                  tooltip={tooltip}
                  disabled={disabled}
                  onClick={() => {
                    nodeClickHandler(type);
                  }}
                >
                  {disabled ? (
                    <Icon />
                  ) : (
                    <DragWrapper
                      onDraggingStateChange={draggingStateChangeHandler}
                      data={type}
                      onDrop={nodeDropHandler}
                    >
                      <Icon />
                    </DragWrapper>
                  )}
                </CanvasControlButton>
              );
            })}
          </CanvasControlsGroup>
        )}

        {extraButtons && (
          <CanvasControlsGroup>
            {extraButtons.map(({ tooltip, icon: Icon, callback, active }, key) => {
              return (
                <CanvasControlButton key={key} tooltip={tooltip} active={active} onClick={callback}>
                  <Icon />
                </CanvasControlButton>
              );
            })}
          </CanvasControlsGroup>
        )}

        <CanvasControlsGroup>
          <NetworkSetCenterButton id={`${toolType.toLowerCase()}-center-button`} network={network} />
          <FullscreenButton id={`${toolType.toLowerCase()}-fullscreen-button`} />
        </CanvasControlsGroup>

        <CanvasControlsGroup>
          <VisScreenshotButton id={`${toolType.toLowerCase()}-screenshot-button`} network={network} />
          {controls?.includes('viewer-mode') && <ElementsViewerButton />}
        </CanvasControlsGroup>

        {!viewMode && controls.includes('history') && (
          <CanvasControlsGroup>
            <CanvasControlButton tooltip={'Undo'} disabled={historyStart || disabled} onClick={undoClickHandler}>
              <UndoIcon />
            </CanvasControlButton>

            <CanvasControlButton tooltip={'Redo'} disabled={historyEnd || disabled} onClick={redoClickHandler}>
              <RedoIcon />
            </CanvasControlButton>
          </CanvasControlsGroup>
        )}
      </CanvasControlsContainer>
    </DndProvider>
  );
};
