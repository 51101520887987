import { OrderedPlanLevelMap, PlanLevel } from '@priz/shared/src/models/workspace';

const isLevelHigherThanOther = (level: PlanLevel, other: PlanLevel) =>
  OrderedPlanLevelMap[level] > OrderedPlanLevelMap[other];

const isLevelPersonalOrPro = (level: PlanLevel): boolean => [PlanLevel.Personal, PlanLevel.PersonalPro].includes(level);

export const PlanLevelUtils = {
  isLevelHigherThanOther,
  isLevelPersonalOrPro,
};
