import { makeStyles } from '@mui/styles';
import { cftSettings } from '../data';

export const useStyles = makeStyles({
  root: {
    backgroundImage: `
      linear-gradient(to right, ${cftSettings.colors.variant.border} 50%, transparent 50%),
      linear-gradient(to right, ${cftSettings.colors.variant.border} 50%, transparent 50%),
      linear-gradient(to bottom, ${cftSettings.colors.variant.border} 50%, transparent 50%),
      linear-gradient(to bottom, ${cftSettings.colors.variant.border} 50%, transparent 50%)
    `,
    backgroundPosition: 'left top, left bottom, left top, right top',
    backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
    backgroundSize: '25px 2px, 25px 2px, 2px 25px, 2px 25px',
  },
  letter: {
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: '50%',
    borderColor: cftSettings.colors.variant.index,
    color: cftSettings.colors.variant.index,
  },
  titleContainer: {
    flex: '1 0 0',
    boxSizing: 'border-box',
    paddingRight: 20,
    marginRight: 'auto',
  },
  title: {
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    ...cftSettings.font.default,
    ...(cftSettings.font.variant || {}),
  },
  stepsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  controls: {
    display: 'flex',
  },
  divider: {
    alignSelf: 'stretch',
    width: 2,
    height: 'auto',
    backgroundColor: cftSettings.colors.variant.border,
  },
});
