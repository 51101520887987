import React, { useEffect, useState } from 'react';
import { CurvedArrows, CurvedArrowData } from '../../curved-arrows/component';
import { CftNodeData, CftNodesMap, CftNodeType } from '../../../models/tools/cft';
import { cftSettings } from '../data';

export interface CftArrowsRef {
  refresh: () => void;
}

interface CftArrowsProps {
  nodes?: CftNodeData[];
  nodesMap?: CftNodesMap;
  arrowsRef?: (ref: CftArrowsRef) => void;
}

const defaultArrowsProps: Omit<CurvedArrowData, 'startElement' | 'endElement'> = {
  allowedStartSides: ['bottom'],
  allowedEndSides: ['top'],
  color: cftSettings.colors.arrows.default,
  controlPointStretch: 15 * cftSettings.spacing.row,
  strokeWidth: 1,
};

export const CftArrows: React.FC<CftArrowsProps> = ({ nodes = [], nodesMap = {}, arrowsRef }) => {
  const [data, setData] = useState<CurvedArrowData[]>([]);
  const [, setRefreshToggle] = useState(0);

  useEffect(() => {
    resolveData();
  }, [nodes?.length]);

  const refresh = () => {
    setRefreshToggle(Math.random());
  };

  const resolveData = () => {
    const arrowsData: CurvedArrowData[] = [];

    nodes.forEach(fromNode => {
      if ([CftNodeType.Variant, CftNodeType.Process].includes(fromNode.type)) {
        return null;
      }

      const startElement = document.getElementById(fromNode.id);

      if (startElement) {
        fromNode.to.forEach(toId => {
          const toNode = nodesMap[toId];

          if (toNode && [CftNodeType.Process].includes(toNode.type)) {
            return null;
          }

          const endElement = document.getElementById(toId);

          if (endElement) {
            arrowsData.push({
              startElement,
              endElement,
              ...(toNode.type === CftNodeType.Abstraction
                ? {
                    allowedStartSides: ['right'],
                    allowedEndSides: ['left'],
                    color: cftSettings.colors.abstraction.background,
                    strokeWidth: 5,
                    headSize: 10,
                  }
                : {}),
            });
          }
        });
      }
    });

    setData(arrowsData);
  };

  if (arrowsRef) {
    arrowsRef({
      refresh,
    });
  }

  return <CurvedArrows data={data} globalArrowProps={defaultArrowsProps} />;
};
