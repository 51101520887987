import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { useForm } from 'react-hook-form';
import { ReactHookFormText, ReactHookFormQuill } from '../../../react/form-elements';
import { validateOptions } from '@priz/shared/src/utils/form';
import parse from 'html-react-parser';
import { getTextAndImages } from '@priz/shared/src/utils/form';
import { ClickAwayListener } from '@mui/material';

export interface CellEditorProps {
  name: string;
  value: string | number;
  onChange: (val: string | number) => void;
  useQuill?: boolean;
}

export const CellEditor: React.FC<CellEditorProps> = ({ name, value, onChange, useQuill }) => {
  const [editing, setEditing] = useState(false);
  const [quillRef, setQuillRef] = useState(null);

  const { handleSubmit, getValues, control } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      [name]: value || '',
    },
  });

  const textChangeHandler = () => {
    handleSubmit(onTextUpdate)();
  };

  useEffect(() => {
    if (editing && quillRef) quillRef.focus();
  }, [editing, quillRef]);

  const enableEditing = () => {
    setEditing(true);
  };

  const disableEditing = () => {
    setEditing(false);
  };

  const onQuillRef = (_name, ref) => {
    setQuillRef(ref);
  };

  const onTextUpdate = useCallback(
    debounce(() => {
      const val = getValues()[name];
      const hasText = typeof val === 'string' ? getTextAndImages(val)?.length : true;

      onChange(hasText ? val : '');
    }, 500),
    [onChange],
  );

  return (
    <ClickAwayListener onClickAway={disableEditing}>
      <div>
        {useQuill ? (
          editing ? (
            <ReactHookFormQuill
              onRef={onQuillRef}
              onChange={textChangeHandler}
              name={name}
              control={control}
              rules={{
                validate: {
                  ...validateOptions.hasText('Field is required'),
                },
              }}
              wrapperProps={{
                mb: 0,
              }}
            />
          ) : (
            <div className={'ql-container ql-snow'} onClick={enableEditing}>
              <div className={'ql-editor'}>{value ? parse(value.toString()) : ''}</div>
            </div>
          )
        ) : (
          <ReactHookFormText
            multiline={true}
            name={name}
            control={control}
            onChange={textChangeHandler}
            rules={{
              validate: {
                ...validateOptions.hasText('Field is required'),
              },
            }}
            wrapperProps={{
              mb: 0,
            }}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};
