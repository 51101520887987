import React from 'react';
import { Box, BoxProps } from '@mui/material';
import flattenChildren from 'react-keyed-flatten-children';
import { useStyles } from './styles';

export interface ButtonsWrapProps extends BoxProps {
  spacing?: number;
  stretch?: boolean;
}

export const ButtonsWrap: React.FC<ButtonsWrapProps> = ({ children, spacing, stretch, ...rest }) => {
  const styles = useStyles();
  const childrenArray = flattenChildren(children);

  return (
    <Box
      mt={-1}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      flexWrap={'wrap'}
      className={`${styles.root}${stretch ? ' _stretch' : ''}`}
      {...rest}
    >
      {childrenArray.map((child, key) => (
        <Box
          key={key}
          mt={1}
          mr={!stretch && spacing && key !== childrenArray.length - 1 ? spacing : 0}
          className={styles.buttonWrap}
        >
          {child}
        </Box>
      ))}
    </Box>
  );
};
