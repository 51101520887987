import { createBrowserRouter } from 'react-router-dom';
import App, { AppProvider } from '../App';
import { ErrorPage, ErrorPageType } from '../error-page/component';
import { appRoutes } from './app-routes';
import { projectRoutes } from './project-routes';
import { userProfileRoutes } from './user-profile-routes';
import { workspaceRoutes } from './workspace-routes';
import { adminRoutes } from './admin-routes';
import { playgroundRoutes } from './playground-routes';
import { teamRoutes } from './team-routes';
import { RootResolver } from './root-resolver/component';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: (
      <AppProvider>
        <ErrorPage />
      </AppProvider>
    ),
    children: [
      ...appRoutes,
      ...projectRoutes,
      ...workspaceRoutes,
      ...userProfileRoutes,
      ...teamRoutes,
      ...playgroundRoutes,
      ...adminRoutes,

      {
        path: '',
        element: <RootResolver />,
      },
      {
        path: '*',
        element: <ErrorPage type={ErrorPageType.NotFound} />,
      },
    ],
  },
]);
