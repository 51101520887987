import { environment } from '../../../../environments/environment';

declare global {
  interface Window {
    gtag: any;
  }
}

const track = (event: string): Promise<unknown> => {
  return new Promise(resolve => {
    if (typeof window?.gtag !== 'undefined') {
      window.gtag('send', 'event', environment.environmentKey, event, {
        hitCallback: (response: unknown) => {
          resolve(response);
        },
      });
    } else {
      resolve({});
    }
  });
};

const trackPageView = (page: string): void => {
  if (typeof window?.gtag !== 'undefined') {
    window.gtag('send', 'pageview', page);
  }
};

export const GoogleAnalyticsService = {
  track,
  trackPageView,
};
