import React, { useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material';
import { Trans } from 'react-i18next';
import { PlanUpgrade } from '../plan-upgrade/component';
import { useDispatch, useSelector } from 'react-redux';
import { DialogsSelectors } from '../../dialogs/store/selectors';
import { DialogType } from '../../dialogs/store/model';
import { DialogsActions } from '../../dialogs/store/actions';
import { PlanSelectors } from '../../workspace/store/selectors/plan.selectors';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { PlanInterval } from '@priz/shared/src/models/workspace';
import { useExcludedPaths } from '../../utils';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';

export const PlanUpgradeDialog: React.FC<Partial<DialogProps>> = props => {
  const dispatch = useDispatch();

  const [isAutoShowingPrevented] = useExcludedPaths(['/workspace/new', '/workspace/settings', '/profile']);
  const [dialogContentElement, setDialogContentElement] = useState<HTMLDivElement>();
  const [isBillingFormActive, setIsBillingFormActive] = useState(false);
  const [isSuccessfullyUpdated, setIsSuccessfullyUpdated] = useState(false);

  const controlsContainerRef = useRef<HTMLDivElement>(null);

  const isOpen = useSelector(DialogsSelectors.isOpen(DialogType.PlanUpgrade));
  const selectedWorkspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const selectedWorkspaceIsLoaded = useSelector(WorkspaceSelectors.isLoaded);
  const currentPlan = useSelector(PlanSelectors.getCurrentPlan);
  const currentPlanIsLoaded = useSelector(PlanSelectors.isLoaded);
  const isUpdating = useSelector(WorkspaceSelectors.isUpdating);

  const workspaceName = selectedWorkspace?.name?.trim();

  useEffect(() => {
    const lsOpen = LocalStorageService.getItem(LocalStorageKey.OpenPlanUpgradeDialog);

    if (lsOpen) {
      LocalStorageService.removeItem(LocalStorageKey.OpenPlanUpgradeDialog);
      dispatch(DialogsActions.open(DialogType.PlanUpgrade));
    }
  }, []);

  useEffect(() => {
    if (
      !isAutoShowingPrevented &&
      currentPlanIsLoaded &&
      selectedWorkspaceIsLoaded &&
      selectedWorkspace &&
      !currentPlan
    ) {
      if (selectedWorkspace.type) showBillingForm();
      dispatch(DialogsActions.open(DialogType.PlanUpgrade));
    }
  }, [currentPlan, selectedWorkspace, currentPlanIsLoaded, selectedWorkspaceIsLoaded, isAutoShowingPrevented]);

  useEffect(() => {
    if (isOpen) {
      setIsBillingFormActive(false);
      setIsSuccessfullyUpdated(false);
    }
  }, [isOpen]);

  const closeHandler = () => {
    if (isUpdating) return null;

    if (!isSuccessfullyUpdated && isBillingFormActive) {
      hideBillingForm();
    } else {
      dispatch(DialogsActions.close(DialogType.PlanUpgrade));
    }
  };

  const hideBillingForm = () => {
    setIsBillingFormActive(false);
  };

  const showBillingForm = () => {
    setIsBillingFormActive(true);
  };

  const dialogContentRefHandler = (ref: HTMLDivElement) => {
    if (ref) setDialogContentElement(ref);
  };

  const successfulUpdateHandler = () => {
    setIsSuccessfullyUpdated(true);
  };

  if (!selectedWorkspace || !currentPlanIsLoaded) return null;

  return (
    <Dialog {...props} open={isOpen} fullWidth={true} maxWidth={isSuccessfullyUpdated ? 'sm' : 'lg'}>
      <DialogTitle>
        {!isSuccessfullyUpdated && (
          <>
            <Trans>Upgrade your</Trans>
            {workspaceName ? ' ' + workspaceName + ' ' : ' '}
            <Trans>workspace</Trans>
          </>
        )}

        {isSuccessfullyUpdated && workspaceName}
      </DialogTitle>

      <DialogContent ref={dialogContentRefHandler}>
        <PlanUpgrade
          initialPlanLevel={selectedWorkspace.type}
          initialPlanInterval={currentPlan?.interval || PlanInterval.Monthly}
          showBillingForm={isBillingFormActive}
          onShowBillingForm={showBillingForm}
          scrollContainer={dialogContentElement}
          onSuccessfulUpdate={successfulUpdateHandler}
          controlsContainer={controlsContainerRef.current}
        />
      </DialogContent>

      <DialogActions ref={controlsContainerRef} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          sx={{ order: 99 }}
          variant={'pg_rounded'}
          color={'pg_orange_outlined'}
          disabled={isUpdating}
          onClick={closeHandler}
        >
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
