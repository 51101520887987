import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  selectContainer: {
    textAlign: 'start',
  },
  option: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  indicator: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    marginRight: 10,
    flex: '0 0 auto',
  },
});
