import React, { useCallback, useState } from 'react';
import { Agenda } from '../store/model';
import { MeetingStatus } from '@priz/shared/src/models/meetings';
import { Checkbox, Chip, Grid, Hidden, IconButton, TableCell, TableRow } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import debounce from 'lodash/debounce';
import { ReactHookFormText } from '@priz/shared/src/components/form-elements';
import { validateOptions } from '@priz/shared/src/utils/form';
import { useForm } from 'react-hook-form';
import { CheckboxIcon } from '../../react/elements/checkbox-icon/component';

interface PureAgendaItemProps {
  agenda: Agenda;
  done?: boolean;
  text?: string | React.ReactNode;
  index?: number;
  onDoneToggle?: () => void;
  onDelete?: () => void;
  highlightDone?: boolean;
  showDoneIcon?: boolean;
  hover?: boolean;
  disabled?: boolean;
}

export const PureAgendaItem: React.FC<PureAgendaItemProps> = ({
  agenda,
  done,
  text,
  index,
  onDoneToggle,
  onDelete,
  highlightDone,
  showDoneIcon,
  hover,
  disabled,
}) => {
  const isDone = typeof done !== 'undefined' ? done : agenda.done;
  const agendaText = typeof text !== 'undefined' ? text : agenda.text;

  const renderCheckbox = () => {
    return <Checkbox size="small" checked={isDone} onClick={onDoneToggle} disabled={disabled} />;
  };

  const renderDeleteButton = () => {
    return (
      <IconButton size="medium" onClick={onDelete} disabled={disabled}>
        <CloseRounded fontSize="small" />
      </IconButton>
    );
  };

  return (
    <TableRow selected={highlightDone ? isDone : false} hover={hover}>
      <Hidden mdDown>
        {typeof index === 'number' && (
          <TableCell>
            <Chip label={index} />
          </TableCell>
        )}

        {onDoneToggle && <TableCell padding="checkbox">{renderCheckbox()}</TableCell>}

        {showDoneIcon && (
          <TableCell padding="checkbox">
            <CheckboxIcon checked={isDone} />
          </TableCell>
        )}

        <TableCell width={'100%'}>{agendaText}</TableCell>

        {onDelete && <TableCell>{renderDeleteButton()}</TableCell>}
      </Hidden>

      <Hidden mdUp>
        <TableCell>
          <Grid container alignItems={'center'} justifyContent={'space-between'}>
            {typeof index === 'number' && (
              <Grid item xs={'auto'}>
                <Chip label={index} size="small" />
              </Grid>
            )}

            {onDoneToggle && (
              <Grid item xs={true}>
                {renderCheckbox()}
              </Grid>
            )}

            {showDoneIcon && isDone && (
              <Grid item xs={true}>
                <CheckboxIcon checked={isDone} />
              </Grid>
            )}

            {onDelete && (
              <Grid item xs={'auto'}>
                {renderDeleteButton()}
              </Grid>
            )}
          </Grid>

          {agendaText}
        </TableCell>
      </Hidden>
    </TableRow>
  );
};

export interface AgendaItemHandlers {
  onAgendaDone?: (agendaId: number, done: boolean) => void;
  onAgendaUpdate?: (agendaId: number, text: string) => void;
  onAgendaUpdateDebounced?: (agendaId: number, text: string) => void;
  onAgendaDelete?: (agendaId: number) => void;
}

interface AgendaItemProps extends AgendaItemHandlers {
  agenda: Agenda;
  meetingStatus: MeetingStatus;
  index?: number;
  editable?: boolean;
  disabled?: boolean;
  viewMode?: boolean;
}

export const AgendaItem: React.FC<AgendaItemProps> = ({
  agenda,
  meetingStatus,
  onAgendaDone,
  onAgendaUpdate,
  onAgendaUpdateDebounced,
  onAgendaDelete,
  index,
  editable,
  disabled,
  viewMode,
}) => {
  const meetingIsActive = meetingStatus === MeetingStatus.Active;
  const [isDone, setIsDone] = useState(!!agenda.done);

  const { control, trigger, getValues } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      text: agenda.text,
    },
  });

  const debouncedUpdateHandler = useCallback(
    debounce(() => {
      trigger('text').then(isValid => {
        if (isValid) onAgendaUpdateDebounced(agenda.id, getValues().text);
      });
    }, 500),
    [],
  );

  const doneHandler = () => {
    setIsDone(!isDone);
    if (onAgendaDone) onAgendaDone(agenda.id, !isDone);
  };

  const deleteHandler = () => {
    if (onAgendaDelete) onAgendaDelete(agenda.id);
  };

  const renderText = () => {
    return (
      <>
        {!editable && agenda.text}

        {editable && (
          <ReactHookFormText
            name={'text'}
            control={control}
            onChange={e => {
              if (onAgendaUpdate) onAgendaUpdate(agenda.id, e.target.value);
              if (onAgendaUpdateDebounced) debouncedUpdateHandler();
            }}
            rules={{
              validate: {
                ...validateOptions.hasText('Field is required'),
              },
            }}
            wrapperProps={{ mb: 0 }}
            disabled={disabled}
            multiline={true}
          />
        )}
      </>
    );
  };

  return (
    <PureAgendaItem
      agenda={agenda}
      text={renderText()}
      done={isDone}
      index={index}
      onDoneToggle={editable && meetingIsActive && doneHandler}
      onDelete={editable && !meetingIsActive && deleteHandler}
      highlightDone={editable}
      showDoneIcon={viewMode}
      hover={!viewMode}
      disabled={disabled}
    />
  );
};
