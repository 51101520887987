import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
    height: '100%',
  },
  container: {
    background: pgColorScheme.backgroundMediumGray,
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: 10,
  },
  removeButton: {
    'padding': 0,
    'background': 'transparent',
    'borderRadius': 0,
    'width': 40,
    'height': 40,

    '& svg': {
      width: 16,
      height: 16,
      fill: pgColorScheme.darkGray,
    },
  },
  topPanel: {
    top: 'auto',
    bottom: '100%',
    left: '50%',
    right: 'auto',
    transform: 'translateX(-50%)',
  },
  topPanelContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 'auto',
    boxSizing: 'border-box',
    borderRadius: 4,
    backgroundColor: pgColorScheme.white,
    boxShadow: '0px 5px 20px 20px rgba(0, 0, 0, 0.02)',
  },
});
