import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { useStyles } from './styles';

import { ReactComponent as Svg } from '@priz/shared/src/assets/images/assistant.svg';

interface AssistantSvgProps extends BoxProps {
  loading?: boolean;
  hint?: boolean;
}

export const AssistantSvg: React.FC<AssistantSvgProps> = ({ loading, hint, className, ...rest }) => {
  const styles = useStyles();
  const rootClassNames = [styles.root];
  const svgClassNames = [styles.svg];

  if (className) rootClassNames.push(className);

  if (loading) svgClassNames.push(styles.loading);
  if (!loading && hint) svgClassNames.push(styles.hint);

  return (
    <Box className={rootClassNames.join(' ')} {...rest}>
      <Svg className={svgClassNames.join(' ')} />
    </Box>
  );
};
