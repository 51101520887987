import {
  TemplateActivityOptionType,
  TemplateCompletion,
  TemplatePreparingOptionType,
  TemplateResultOptionType,
} from '@priz/shared/src/models/template';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectSelector, ToolUtilizationSelector } from '../../store/selectors';
import { TaskSelectors } from '../../../tasks/store/selectors/task.selectors';
import { IdeasSelectors, ProjectIdeaActions } from '../../../idea/store';
import { MeetingSelectors } from '../../../meetings/store/selectors';
import { ToolType, ToolUtilization } from '@priz/shared/src/models/tools';
import { useEffect } from 'react';
import { ToolUtilizationActions } from '../../store/actions/tools.actions';

type ToolsCompletion = { [key in ToolType]: boolean };

const resolveToolsCompletion = (tools: ToolUtilization[] = []): ToolsCompletion => {
  const toolsCompletion = {} as ToolsCompletion;
  const toolTypesMap = tools.map(tool => tool.type);

  Object.values(ToolType).forEach(toolType => {
    toolsCompletion[toolType] = toolTypesMap.includes(toolType);
  });

  return toolsCompletion;
};

export const useTemplateCompletion = (projectId: number): TemplateCompletion => {
  const dispatch = useDispatch();

  const project = useSelector(ProjectSelector.getById(projectId));
  const isProblemStatementDefined = useSelector(ProjectSelector.isProblemStatementDefinedById(projectId));
  const tools = useSelector(ToolUtilizationSelector.getAllByProjectId(projectId));
  const tasks = useSelector(TaskSelectors.getAllByProjectId(projectId));
  const ideas = useSelector(IdeasSelectors.getAllByProjectId(projectId));
  const meetings = useSelector(MeetingSelectors.getAllByProjectId(projectId));

  const toolsCompletion = resolveToolsCompletion(tools);

  useEffect(() => {
    dispatch(ToolUtilizationActions.loadTools(projectId));
    dispatch(ProjectIdeaActions.loadProjectIdeas(projectId));
  }, [projectId]);

  return {
    [TemplatePreparingOptionType.ProjectBackground]: !!project?.description,
    [TemplatePreparingOptionType.ProblemStatement]: isProblemStatementDefined,

    [TemplateActivityOptionType.Tasks]: !!tasks?.length,
    [TemplateActivityOptionType.TasksPriority]: toolsCompletion[ToolType.UIM],
    [TemplateActivityOptionType.Meetings]: !!meetings?.length,
    [TemplateActivityOptionType.Publishing]: !!project?.open,

    [TemplateResultOptionType.Ideas]: !!Object.keys(ideas || []).length,
    [TemplateResultOptionType.Solution]: !!project?.solution,
    [TemplateResultOptionType.Report]: false, // TODO

    ...toolsCompletion,
  };
};
