import React, { forwardRef, useState } from 'react';
import { isValidPhoneNumber, parsePhoneNumber, Country } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';
import { ReactHookFormElement, ValidatorControllerProps } from '@priz/shared/src/models/form';
import { FieldTitle, HelperText } from '@priz/shared/src/components/form-elements';
import { Controller } from 'react-hook-form';
import { Box, TextField, TextFieldProps } from '@mui/material';
import { CountrySelect } from './country-select/component';
import { useStyles } from './styles';

interface ReactHookFormPhoneInput extends ReactHookFormElement, ValidatorControllerProps {
  placeholder?: string;
  helperText?: React.ReactNode;
  disabled?: boolean;
  formatValidation?: boolean;
}

const resolveCountryCode = (value?: string): Country | undefined => {
  return value ? parsePhoneNumber(value)?.country : undefined;
};

const RefTextField = forwardRef<React.FC, TextFieldProps>(({ className, ...rest }, ref) => {
  const styles = useStyles();

  return (
    <TextField {...rest} className={className} inputProps={{ className: styles.phoneInput }} inputRef={ref} fullWidth />
  );
});

RefTextField.displayName = 'RefTextField';

export const ReactHookFormPhoneInput: React.FC<ReactHookFormPhoneInput> = ({
  wrapperProps,
  control,
  rules,
  name,
  fieldTitle,
  helperText,
  fieldTitleWrapperProps,
  formatValidation,
  disabled,
}) => {
  const styles = useStyles();
  const [country, setCountry] = useState<Country>();

  return (
    <Box mb={3} {...wrapperProps}>
      <FieldTitle text={fieldTitle} {...fieldTitleWrapperProps} />

      <Controller
        name={name}
        control={control}
        rules={{
          ...rules,
          validate: {
            ...rules?.validate,
            ...(formatValidation
              ? { matchFormat: v => (v?.length ? isValidPhoneNumber(v) || 'Wrong phone number' : true) }
              : {}),
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <Box position={'relative'}>
            <Box p={0.5} className={styles.selectContainer}>
              <CountrySelect
                country={country || resolveCountryCode(field.value)}
                onChange={value => {
                  field.onChange('');
                  setCountry(value);
                }}
                disabled={disabled}
              />
            </Box>

            <PhoneInput
              country={country}
              international
              withCountryCallingCode
              value={field.value}
              onChange={value => {
                setCountry(resolveCountryCode(value));
                field.onChange(value);
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                e.stopPropagation();
              }}
              inputComponent={RefTextField}
              disabled={disabled}
            />

            <HelperText text={helperText} error={error?.message} />
          </Box>
        )}
      />
    </Box>
  );
};
