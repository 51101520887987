import { UimPriority } from '../models/tools/uim';

export const PriorityLabelMap = {
  [UimPriority.DoFirst]: 'Do first',
  [UimPriority.DoLater]: 'Do later',
  [UimPriority.Delegate]: 'Delegate',
  [UimPriority.Drop]: 'Drop',
  [UimPriority.Undefined]: 'Not defined',
};

export const PriorityClassMap = {
  [UimPriority.DoFirst]: 'doFirst',
  [UimPriority.DoLater]: 'doLater',
  [UimPriority.Delegate]: 'delegate',
  [UimPriority.Drop]: 'drop',
  [UimPriority.Undefined]: 'undefined',
};
