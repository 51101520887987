import React from 'react';

export interface TabPanelProps {
  value: number | string;
  index: number | string;
  useClassModifier?: boolean;
  className?: string;
  renderOnlyIfActive?: boolean;
  stretch?: boolean;
}

export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  renderOnlyIfActive,
  value,
  index,
  className,
  useClassModifier,
  stretch,
  ...rest
}) => {
  const wrapperClassName = `${className || ''}${useClassModifier && value !== index ? ' _hidden' : ''}`;
  const hidden = value !== index;

  if (renderOnlyIfActive && hidden) return null;

  return (
    <div
      hidden={!useClassModifier && hidden}
      className={wrapperClassName.length ? wrapperClassName : null}
      style={stretch ? { width: '100%', height: '100%' } : null}
      {...rest}
    >
      {children}
    </div>
  );
};
