import { CftNodeData } from '@priz/shared/src/models/tools/cft';
import { CftNodeRelations } from '../store/model';

interface NodeRelationsResolverProps extends Omit<CftNodeRelations, 'relationsCount' | 'categoriesCount'> {
  node: CftNodeData;
}

const resolveNodeRelations = (props: NodeRelationsResolverProps): CftNodeRelations => {
  const { node, meetings, tools, tasks, ideas } = props;

  const relations: CftNodeRelations = {
    meetings: [],
    tools: [],
    tasks: [],
    ideas: [],
    relationsCount: 0,
    categoriesCount: 0,
  };

  meetings.forEach(meeting => {
    if (meeting.sourceId === node.id) {
      relations.meetings.push(meeting);
    }
  });

  tools.forEach(tool => {
    if (tool.sourceId === node.id) {
      relations.tools.push(tool);
    }
  });

  tasks.forEach(task => {
    if (task.sourceId === node.id) {
      relations.tasks.push(task);
    }
  });

  ideas.forEach(idea => {
    if (idea.sourceId === node.id) {
      relations.ideas.push(idea);
    }
  });

  [relations.meetings, relations.tools, relations.tasks, relations.ideas].forEach(category => {
    const categoryRelationsCount = category.length;

    if (categoryRelationsCount) {
      relations.relationsCount += categoryRelationsCount;
      relations.categoriesCount += 1;
    }
  });

  return relations;
};

export const CftContentUtils = {
  resolveNodeRelations,
};
