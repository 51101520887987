import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../../theme';

export const useStyles = makeStyles({
  root: {
    height: 26,
    width: 26,
    display: 'block',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '50%',
    background: pgColorScheme.lightGray,
    zIndex: 2,
  },
  img: {
    display: 'block',
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
});
