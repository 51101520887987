import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, ClickAwayListener, Hidden, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { Trans } from 'react-i18next';
import { useStyles } from './styles';
import { PendoGuides } from './pendo-guides/component';
import { VideoGuides } from './video-guides/component';
import { AutoStoriesOutlined, QuestionMarkRounded } from '@mui/icons-material';
import { PgPopper } from '@priz/shared/src/components/pg-popper/component';

export const Guides: React.FC = () => {
  const theme = useTheme();
  const styles = useStyles();
  const isDownSmBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const { pathname } = useLocation();

  const [pendoGuidesCount, setPendoGuidesCount] = useState(0);
  const [videoGuidesCount, setVideoGuidesCount] = useState(0);
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [buttonRef, setButtonRef] = useState<HTMLDivElement>();

  const togglePopper = () => {
    setIsPopperOpen(currentState => !currentState);
  };

  const closePopper = () => {
    setIsPopperOpen(false);
  };

  const buttonRefHandler = (ref: HTMLDivElement) => {
    if (!buttonRef && ref) {
      setButtonRef(ref);
    }
  };

  const pendoGuidesLoadHandler = (loadedGuidesCount: number) => {
    setPendoGuidesCount(loadedGuidesCount);
  };

  const videoGuidesLoadHandler = (loadedGuidesCount: number) => {
    setVideoGuidesCount(loadedGuidesCount);
  };

  return (
    <ClickAwayListener onClickAway={closePopper}>
      <div>
        {(pendoGuidesCount > 0 || videoGuidesCount > 0) && (
          <Tooltip title={isPopperOpen ? '' : <Trans>Guides</Trans>} placement={'bottom'} disableInteractive arrow>
            <div ref={r => buttonRefHandler(r)} className={styles.button} onClick={togglePopper}>
              <Hidden smDown>
                <AutoStoriesOutlined className={styles.icon} />
              </Hidden>

              <Hidden smUp>
                <QuestionMarkRounded className={styles.icon} />
              </Hidden>
            </div>
          </Tooltip>
        )}

        <PgPopper
          open={isPopperOpen}
          anchorEl={buttonRef}
          onClose={closePopper}
          placement={isDownSmBreakpoint ? 'left' : 'top'}
          closeOnClickAway={false}
          keepMounted
          dropdownProps={{ sx: { maxHeight: 'calc(100vh - 120px)' } }}
        >
          <Box width={300} maxWidth={'calc(100vw - 64px)'}>
            <VideoGuides pathname={pathname} mb={2} onView={closePopper} onGuidesLoad={videoGuidesLoadHandler} />
            <PendoGuides pathname={pathname} onView={closePopper} onGuidesLoad={pendoGuidesLoadHandler} />
          </Box>
        </PgPopper>
      </div>
    </ClickAwayListener>
  );
};
