import React from 'react';
import { SvgIconProps, Tooltip, Typography } from '@mui/material';
import { useStyles } from './styles';
import { OperationEfficiency, OperationMerit, OperationMeritType } from '../../models/tools/sfm';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, InfoOutlined, WarningAmberOutlined } from '@mui/icons-material';

const resolveOperationMeritIcon = (efficiency: OperationEfficiency) => {
  const props: SvgIconProps = {
    fontSize: 'small',
    sx: {
      display: 'block',
      marginLeft: 0.5,
    },
  };

  switch (efficiency) {
    case OperationEfficiency.High:
      return <CheckCircleOutlined {...props} color={'success'} />;

    case OperationEfficiency.Low:
      return <InfoOutlined {...props} color={'secondary'} />;

    case OperationEfficiency.Lowest:
      return <WarningAmberOutlined {...props} color={'error'} />;

    case OperationEfficiency.NotDefined:
      return null;

    default:
      return null;
  }
};

const efficiencyLabelsMap = {
  [OperationEfficiency.High]: 'high efficiency',
  [OperationEfficiency.Low]: 'low efficiency',
  [OperationEfficiency.Lowest]: 'the lowest efficiency',
};

interface OperationMeritLabelProps {
  operationMerit: OperationMerit;
  useTooltip?: boolean;
  showNotDefined?: boolean;
}

export const OperationMeritLabel: React.FC<OperationMeritLabelProps> = ({
  operationMerit,
  useTooltip,
  showNotDefined,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const classNames = [styles.root];
  const tooltip = [];

  let label = null;

  if (operationMerit.type === OperationMeritType.Ideal) {
    classNames.push(styles.idealOperationMerit);
    label = t('Ideal operation');
  }

  if (operationMerit.type === OperationMeritType.Harmful) {
    classNames.push(styles.harmfulOperationMerit);
    label = t('Harmful operation');
  }

  if (operationMerit.type === OperationMeritType.NotDefined) {
    classNames.push(styles.notDefinedOperationMerit);
    label = t('Not defined');
  }

  if (typeof operationMerit.value === 'number') {
    classNames.push(styles.numberOperationMerit);

    if (operationMerit.efficiency === OperationEfficiency.High) classNames.push(styles.highEfficiency);
    if (operationMerit.efficiency === OperationEfficiency.Low) classNames.push(styles.lowEfficiency);
    if (operationMerit.efficiency === OperationEfficiency.Lowest) classNames.push(styles.lowestEfficiency);

    label = operationMerit.value;
  }

  if (useTooltip) {
    tooltip.push(`${t('Operation Mеrit')}: ${label}`);

    if (operationMerit.efficiency && operationMerit.efficiency !== OperationEfficiency.NotDefined) {
      tooltip.push(t(efficiencyLabelsMap[operationMerit.efficiency]));
    }
  }

  if (operationMerit.type === OperationMeritType.NotDefined && !showNotDefined) return null;

  return (
    <Tooltip title={tooltip.join(', ')} placement={'top'} disableInteractive arrow>
      <Typography variant={'subtitle2'} className={classNames.join(' ')} noWrap>
        OE {label}
        {operationMerit.efficiency && resolveOperationMeritIcon(operationMerit.efficiency)}
      </Typography>
    </Tooltip>
  );
};
