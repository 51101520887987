import React from 'react';
import { PgProgressBar } from '../../../react/elements/PgProgressBar';
import { RrmUtilization } from '@priz/shared/src/models/tools/rrm/rrm-utilization';
import { RrmUtilizationService } from '../../services/rrm-utilization.service';

interface RrmProgressBarProps {
  utilization: RrmUtilization;
}

export const RrmProgressBar: React.FC<RrmProgressBarProps> = ({ utilization }) => {
  const percentageComplete = RrmUtilizationService.calculatePercentageComplete(utilization);

  return <PgProgressBar variant="determinate" color={'success'} value={percentageComplete} />;
};
