import { EntityCollectionStatus, EntityMap, LookupMap } from '@priz/shared/src/models/common/entity-collection-state';
import { Attendee } from './attendee';

export interface AttendeesLookups {
  byProjectId: LookupMap;
}

export interface AttendeesCollectionStatuses {
  byProjectId?: { [projectId: number]: EntityCollectionStatus };
}

export interface AttendeesCollection {
  entities: EntityMap<Attendee>;

  lookups: AttendeesLookups;

  statuses: AttendeesCollectionStatuses;
}

export const DefaultAttendeesCollection: AttendeesCollection = {
  entities: {},

  lookups: {
    byProjectId: {},
  },

  statuses: {
    byProjectId: {},
  },
};
