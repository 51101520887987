import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { PaywallActions, PaywallSelectors } from '../store';
import { WorkspaceSelectors } from '../../../../workspace/store/selectors';
import { WorkspaceMembersSelectors } from '../../../../workspace/store/selectors/workspace-members.selectors';
import { UserSelectors } from '../../../../user/store/selectors/user.selectors';
import { WorkspaceMembershipLevel } from '@priz/shared/src/models/workspace';
import { FinancialsManagementType } from '@priz/shared/src/models/workspace/financials-management-type.enum';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { ManageSeats } from '../../../../billing/manage-seats/component';
import { useQuery } from 'react-query';
import { WorkspaceApi } from '../../../../workspace/services';

export const TeamBlockerDialog: React.FC = () => {
  const dispatch = useDispatch();

  const paywall = useSelector(PaywallSelectors.getLast);
  const selectedWorkspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const workspaceMembers = useSelector(WorkspaceMembersSelectors.getAll);
  const currentUser = useSelector(UserSelectors.currentUserSelector);

  const workspacesAreLoaded = useSelector(WorkspaceSelectors.isLoaded);
  const workspaceMembersAreLoaded = useSelector(WorkspaceMembersSelectors.isLoaded);
  const currentUserIsLoaded = useSelector(UserSelectors.isLoaded);
  const isLoaded = workspacesAreLoaded && workspaceMembersAreLoaded && currentUserIsLoaded;

  const isSelf = selectedWorkspace?.financialsManagementType === FinancialsManagementType.Self;
  const isManaged = selectedWorkspace?.financialsManagementType === FinancialsManagementType.Managed;
  const workspaceAdmin = workspaceMembers?.find(member => member.membershipLevel === WorkspaceMembershipLevel.Admin);
  const isCurrentUserAdmin =
    typeof currentUser?.id !== 'undefined' &&
    typeof workspaceAdmin?.id !== 'undefined' &&
    currentUser.id === workspaceAdmin.id;

  const {
    isLoading: isLoadingSub,
    data: subDetails,
    isFetching: isFetchingSub,
  } = useQuery(['current-plan-details'], () => WorkspaceApi.getSubscriptionDetails(), {
    refetchOnWindowFocus: false,
    enabled: paywall.show && isSelf && isCurrentUserAdmin,
  });

  const handleClose = () => {
    dispatch(PaywallActions.hide());
  };

  return (
    <Dialog open={paywall.show} fullWidth={true} maxWidth={'sm'} onClose={handleClose}>
      <DialogTitle>
        <Trans>Seats limit reached</Trans>
      </DialogTitle>

      <DialogContent>
        {(!isLoaded || isLoadingSub || isFetchingSub) && (
          <Box py={2} position={'relative'}>
            <LoadingOverlay loading={true} backdropStyles={{ position: 'relative', background: 'transparent' }} />
          </Box>
        )}

        {isLoaded && !isLoadingSub && !isFetchingSub && isSelf && isCurrentUserAdmin && (
          <ManageSeats
            membersCount={workspaceMembers.length}
            seatsCount={subDetails.subscription.items?.data[0]?.quantity}
            onClose={handleClose}
          />
        )}

        {isLoaded && isSelf && !isCurrentUserAdmin && (
          <>
            <Alert severity={'info'}>
              <Trans>Contact admin</Trans>
            </Alert>
          </>
        )}

        {isLoaded && isManaged && (
          <>
            <Alert severity={'info'}>
              <Trans>Please, contact with PRIZ Guru Team members</Trans>
            </Alert>
          </>
        )}
      </DialogContent>

      {!(isSelf && isCurrentUserAdmin) && (
        <DialogActions sx={{ textAlign: 'right' }}>
          <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose}>
            <Trans>Cancel</Trans>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
