import { combineActions, handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { toObjectMap } from '../../../shared/store/reducer-utils';
import { ArrayUtil } from '../../../shared/utils/array-util';
import { UimUtilizationAction, UimUtilizationActionType } from '../actions/uim-utilization.actions';
import { UimFlawCost } from '@priz/shared/src/models/tools/uim/uim-flaw-cost.enum';
import { UimFlawExpectancy } from '@priz/shared/src/models/tools/uim/uim-flaw-expectancy.enum';
import { UimPriority } from '@priz/shared/src/models/tools/uim/uim-priority.enum';
import { IUimTask, UimTask } from '@priz/shared/src/models/tools/uim/uim-task';
import {
  DefaultUimTaskCollection,
  UimTaskCollection,
  UimTaskCollectionStatuses,
} from '@priz/shared/src/models/tools/uim/uim-task-collection';
import { copyObject } from '@priz/shared/src/utils/common';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';

const priorityMatrix = {
  [UimFlawExpectancy.Unexpected + UimFlawCost.High]: UimPriority.DoFirst,
  [UimFlawExpectancy.Expected + UimFlawCost.High]: UimPriority.DoLater,
  [UimFlawExpectancy.Unexpected + UimFlawCost.Low]: UimPriority.Delegate,
  [UimFlawExpectancy.Expected + UimFlawCost.Low]: UimPriority.Drop,
};

const uimTaskInstantiator = (payload: any): UimTask => {
  const task = new UimTask(payload);

  const priority = priorityMatrix[task.flawExpectancy + task.flawCost];
  if (priority) {
    task.priority = priority;
  }

  return task;
};

const resolveStatuses = (
  state: UimTaskCollection,
  utilizationId: number,
  statuses: EntityCollectionStatus,
): UimTaskCollectionStatuses => {
  const statusesCopy = copyObject(state.statuses);

  statusesCopy.byUtilizationId[utilizationId] = {
    ...statusesCopy.byUtilizationId[utilizationId],
    ...statuses,
  };

  return statusesCopy;
};

const setStatuses = (
  state: UimTaskCollection,
  utilizationId: number,
  statuses: EntityCollectionStatus,
): UimTaskCollection => {
  return {
    ...state,
    statuses: resolveStatuses(state, utilizationId, statuses),
  };
};

const setUimTasks = (state: UimTaskCollection, utilizationId: number, tasksPayload: any): UimTaskCollection => {
  const tasksMap = toObjectMap<UimTask>(tasksPayload, uimTaskInstantiator);
  const entitiesCopy = { ...state.entities, ...tasksMap };
  const lookupsCopy = copyObject(state.lookups);

  Object.values(tasksMap).forEach((task: UimTask) => {
    lookupsCopy.byUtilizationId[utilizationId] = ArrayUtil.concatInDistinctArray(
      lookupsCopy.byUtilizationId[utilizationId],
      task.id,
    );
  });

  return {
    ...state,
    entities: entitiesCopy,
    lookups: lookupsCopy,
    statuses: resolveStatuses(state, utilizationId, {
      loaded: true,
      loading: false,
      error: false,
      creating: false,
    }),
  };
};

const replaceUimTaskEntity = (
  state: UimTaskCollection,
  utilizationId: number,
  taskId: number,
  taskPayload: any,
): UimTaskCollection => {
  const updatedTask = uimTaskInstantiator(taskPayload);

  const entitiesCopy = { ...state.entities, [taskId]: updatedTask };

  return {
    ...state,
    entities: entitiesCopy,
    statuses: resolveStatuses(state, utilizationId, { updating: false, error: false }),
  };
};

const replaceUimTasks = (
  state: UimTaskCollection,
  utilizationId: number,
  newTasks: IUimTask[],
  deletedIds?: number[],
): UimTaskCollection => {
  const newTasksMap = toObjectMap<UimTask>(newTasks, uimTaskInstantiator);
  const lookupsCopy = copyObject(state.lookups);
  const entitiesCopy = { ...state.entities, ...newTasksMap };

  if (deletedIds) {
    deletedIds.forEach(id => {
      delete entitiesCopy[id];
    });
  }

  if (deletedIds && lookupsCopy.byUtilizationId[utilizationId]) {
    lookupsCopy.byUtilizationId[utilizationId] = lookupsCopy.byUtilizationId[utilizationId].filter(
      id => !deletedIds.includes(id),
    );
  }

  Object.values(newTasksMap).forEach((task: UimTask) => {
    lookupsCopy.byUtilizationId[utilizationId] = ArrayUtil.concatInDistinctArray(
      lookupsCopy.byUtilizationId[utilizationId],
      task.id,
    );
  });

  return {
    ...state,
    entities: entitiesCopy,
    lookups: lookupsCopy,
    statuses: resolveStatuses(state, utilizationId, { updating: false, error: false }),
  };
};

const removeUimTask = (state: UimTaskCollection, utilizationId: number, taskId: number): UimTaskCollection => {
  const entitiesCopy = { ...state.entities };
  delete entitiesCopy[taskId];

  const lookupsCopy = copyObject(state.lookups);
  lookupsCopy.byUtilizationId[utilizationId] = lookupsCopy.byUtilizationId[utilizationId].filter(id => id !== taskId);

  return {
    ...state,
    entities: entitiesCopy,
    lookups: lookupsCopy,
    statuses: resolveStatuses(state, utilizationId, { removing: false, error: false }),
  };
};

export const uimTaskReducers = handleActions(
  {
    // load

    [toStarted(UimUtilizationActionType.TasksLoad)]: (state: UimTaskCollection, action: UimUtilizationAction) =>
      setStatuses(state, action.meta.utilizationId, { loaded: false, loading: true, error: false }),

    [toFailed(UimUtilizationActionType.TasksLoad)]: (state: UimTaskCollection, action: UimUtilizationAction) =>
      setStatuses(state, action.meta.utilizationId, { loaded: false, loading: false, error: true }),

    [toSuccess(UimUtilizationActionType.TasksLoad)]: (
      state: UimTaskCollection,
      action: UimUtilizationAction,
    ): UimTaskCollection => setUimTasks(state, action.meta.utilizationId, action.payload as IUimTask[]),

    // create

    [toStarted(UimUtilizationActionType.TaskCreate)]: (state: UimTaskCollection, action: UimUtilizationAction) =>
      setStatuses(state, action.meta.utilizationId, { creating: true, error: false }),

    [toFailed(UimUtilizationActionType.TaskCreate)]: (state: UimTaskCollection, action: UimUtilizationAction) =>
      setStatuses(state, action.meta.utilizationId, { creating: false, error: true }),

    [toSuccess(UimUtilizationActionType.TaskCreate)]: (
      state: UimTaskCollection,
      action: UimUtilizationAction,
    ): UimTaskCollection => setUimTasks(state, action.meta.utilizationId, [action.payload as IUimTask]),

    // update

    [combineActions(toStarted(UimUtilizationActionType.TaskUpdate), toStarted(UimUtilizationActionType.TasksReplace))]:
      (state: UimTaskCollection, action: UimUtilizationAction) =>
        setStatuses(state, action.meta.utilizationId, { updating: true, error: false }),

    [combineActions(toFailed(UimUtilizationActionType.TaskUpdate), toFailed(UimUtilizationActionType.TasksReplace))]: (
      state: UimTaskCollection,
      action: UimUtilizationAction,
    ) => setStatuses(state, action.meta.utilizationId, { updating: false, error: true }),

    [toSuccess(UimUtilizationActionType.TaskUpdate)]: (
      state: UimTaskCollection,
      action: UimUtilizationAction,
    ): UimTaskCollection =>
      replaceUimTaskEntity(state, action.meta.utilizationId, action.meta.taskId, action.payload as IUimTask),

    [toSuccess(UimUtilizationActionType.TasksReplace)]: (
      state: UimTaskCollection,
      action: UimUtilizationAction,
    ): UimTaskCollection =>
      replaceUimTasks(state, action.meta.utilizationId, action.payload as IUimTask[], action.meta.idsToDelete),

    // delete

    [toStarted(UimUtilizationActionType.TaskDelete)]: (state: UimTaskCollection, action: UimUtilizationAction) =>
      setStatuses(state, action.meta.utilizationId, { removing: true, error: false }),

    [toFailed(UimUtilizationActionType.TaskDelete)]: (state: UimTaskCollection, action: UimUtilizationAction) =>
      setStatuses(state, action.meta.utilizationId, { removing: false, error: true }),

    [toSuccess(UimUtilizationActionType.TaskDelete)]: (
      state: UimTaskCollection,
      action: UimUtilizationAction,
    ): UimTaskCollection => removeUimTask(state, action.meta.utilizationId, action.meta.taskId),
  },
  DefaultUimTaskCollection,
);
