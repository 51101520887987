import React from 'react';
import { Paper, PaperProps } from '@mui/material';
import { Link } from 'react-router-dom';
import { useStyles } from './styles';

export const LinkCard: React.FC<PaperProps<typeof Link>> = ({ children, className, ...rest }) => {
  const styles = useStyles();
  const rootClassNames = [styles.toolCard];

  if (className) {
    rootClassNames.push(className);
  }

  return (
    <Paper component={Link} variant={'outlined'} className={rootClassNames.join(' ')} {...rest}>
      {children}
    </Paper>
  );
};
