import { createSelector, Selector } from 'reselect';
import { CertificateCollection } from '..';
import { AppState } from '../../../store/app.state';

const certificatesStateSelector = state => state.certificates;

const certificatesSelector = createSelector(
  certificatesStateSelector,
  (certificatesState: CertificateCollection) => certificatesState.certificates,
);

const loadingCertificateIdsSelector = createSelector(
  certificatesStateSelector,
  (certificatesState: CertificateCollection) => certificatesState.loadingIds,
);

const certificateByProjectIdSelector = (projectId: number) =>
  createSelector(certificatesSelector, certificateMap => certificateMap[projectId]);

const isCertificateLoadingByProjectIdSelector = (projectId: number): Selector<AppState, boolean> =>
  createSelector(loadingCertificateIdsSelector, loadingIdsMap => !!loadingIdsMap[projectId] || false);

const isLoading = createSelector(
  certificatesStateSelector,
  (certificatesState: CertificateCollection) => certificatesState.statuses.loading,
);

const isLoaded = createSelector(
  certificatesStateSelector,
  (certificatesState: CertificateCollection) => certificatesState.statuses.loaded,
);

const isCreating = createSelector(
  certificatesStateSelector,
  (certificatesState: CertificateCollection) => certificatesState.statuses.creating,
);

const isSaving = createSelector(
  certificatesStateSelector,
  (certificatesState: CertificateCollection) => certificatesState.statuses.saving,
);

export const CertificateSelectors = {
  loadingCertificateIdsSelector,
  certificateByProjectIdSelector,
  isCertificateLoadingByProjectIdSelector,
  isLoading,
  isLoaded,
  isCreating,
  isSaving,
};
