import { useEffect, useState } from 'react';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';

export const useToolTabs = <T>(initialValue: T): [T, (v: T) => void] => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const lsTab = LocalStorageService.getItem(LocalStorageKey.LastViewedToolTab);
    const switchToTab = LocalStorageService.getItem(LocalStorageKey.SwitchToLastViewedToolTab);

    if (lsTab && switchToTab) {
      LocalStorageService.removeItem(LocalStorageKey.LastViewedToolTab);
      LocalStorageService.removeItem(LocalStorageKey.SwitchToLastViewedToolTab);

      if (typeof value === 'number') {
        setValue(parseInt(lsTab) as T);
      } else {
        setValue(lsTab as T);
      }
    }
  }, []);

  useEffect(() => {
    LocalStorageService.setItem(LocalStorageKey.LastViewedToolTab, value.toString());
  }, [value]);

  const set = (v: T) => {
    setValue(v);
  };

  return [value, set];
};
