import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { WorkspaceApi } from '../../workspace/services';
import { useSelector } from 'react-redux';
import { PlanSelectors } from '../../workspace/store/selectors/plan.selectors';
import {
  AllPlanLevels,
  LevelPlansMap,
  PlanFeaturesMap,
  PlanInterval,
  PlanLevel,
} from '@priz/shared/src/models/workspace';
import { Box, CircularProgress, Typography } from '@mui/material';
import { FeaturesComparisonTable } from '../features-comparison-table/component';
import { BillingForm } from '../billing-form/component';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from '@priz/shared/src/environments/environment';
import { PlansCardsSlider } from '../plans-cards-slider/component';
import { CurrentPlanMessage } from '../current-plan-message/component';
import { Trans } from 'react-i18next';
import { PgLink } from '../../react/elements/pg-link/component';
import { WorkspaceSelectors } from '../../workspace/store/selectors';

const resolveApplicablePlanFeaturesMap = (planFeaturesMap: PlanFeaturesMap): PlanFeaturesMap => {
  if (!planFeaturesMap) return {} as PlanFeaturesMap;

  return Object.keys(planFeaturesMap)
    .filter((key: PlanLevel) => AllPlanLevels.includes(key))
    .reduce((obj, key: PlanLevel) => {
      obj[key] = planFeaturesMap[key];
      return obj;
    }, {} as PlanFeaturesMap);
};

const stripePromise = loadStripe(environment.stripeApiKey);

interface PlanUpgradeProps {
  initialPlanInterval: PlanInterval;
  initialPlanLevel?: PlanLevel;
  showBillingForm?: boolean;
  onShowBillingForm?: () => void;
  scrollContainer?: HTMLDivElement;
  onSuccessfulUpdate?: () => void;
  controlsContainer?: HTMLDivElement;
}

export const PlanUpgrade: React.FC<PlanUpgradeProps> = ({
  initialPlanLevel,
  initialPlanInterval,
  showBillingForm,
  onShowBillingForm,
  scrollContainer,
  controlsContainer,
  onSuccessfulUpdate,
}) => {
  const [selectedPlanLevel, setSelectedPlanLevel] = useState<PlanLevel>(initialPlanLevel);
  const [selectedPlanInterval, setSelectedPlanInterval] = useState<PlanInterval>(initialPlanInterval);
  const [selectedQuantitiesMap, setSelectedQuantitiesMap] = useState<{ [key in PlanLevel]?: number }>();
  const [isBillingFormOpen, setIsBillingFormOpen] = useState(showBillingForm || false);
  const [featuresTableContainer, setFeaturesTableContainer] = useState<HTMLDivElement>();

  const { isLoading, isError, data, isFetching } = useQuery(
    ['all-plans-feature-map'],
    () => WorkspaceApi.getAllPlansFeatures(),
    { keepPreviousData: true, refetchOnWindowFocus: false },
  );

  const currentPlan = useSelector(PlanSelectors.getCurrentPlan);
  const plansMap: LevelPlansMap = useSelector(PlanSelectors.getAllGroupedByLevelAndInterval());
  const plansLoaded = useSelector(PlanSelectors.isLoaded);
  const workspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const applicableLevels = resolveApplicablePlanFeaturesMap(data);

  useEffect(() => {
    if (typeof showBillingForm !== 'undefined') setIsBillingFormOpen(showBillingForm);
  }, [showBillingForm]);

  useEffect(() => {
    if (workspace && currentPlan) {
      setSelectedQuantitiesMap(currentState => ({
        ...currentState,
        [currentPlan.level]: workspace?.licensedSeats || 1,
      }));
    }
  }, [workspace, currentPlan]);

  const planSelectHandler = (planLevel: PlanLevel) => {
    setSelectedPlanLevel(planLevel);

    if (onShowBillingForm) {
      onShowBillingForm();
    } else {
      setIsBillingFormOpen(true);
    }
  };

  const intervalChangeHandler = (interval: PlanInterval) => {
    setSelectedPlanInterval(interval);
  };

  const seatsQuantityChangeHandler = (level: PlanLevel, value: number) => {
    setSelectedQuantitiesMap(currentState => ({
      ...currentState,
      [level]: value,
    }));
  };

  if (
    isLoading ||
    isError ||
    isFetching ||
    !plansLoaded ||
    (isBillingFormOpen && !selectedPlanLevel && !selectedQuantitiesMap)
  ) {
    return (
      <Box py={10} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (isBillingFormOpen) {
    return (
      <Box maxWidth={800} mx={'auto'}>
        <Elements stripe={stripePromise}>
          <BillingForm
            plan={plansMap[selectedPlanLevel][selectedPlanInterval]}
            planLevel={selectedPlanLevel}
            planInterval={selectedPlanInterval}
            quantity={selectedQuantitiesMap ? selectedQuantitiesMap[selectedPlanLevel] || 1 : 1}
            submitButtonText={'Upgrade'}
            onChangeInterval={intervalChangeHandler}
            onChangeSeatsQuantity={seatsQuantityChangeHandler}
            onSuccessfulUpdate={onSuccessfulUpdate}
            controlsContainer={controlsContainer}
          />
        </Elements>
      </Box>
    );
  }

  const featuresTableContainerRefHandler = (ref: HTMLDivElement) => {
    if (ref) setFeaturesTableContainer(ref);
  };

  const compareHandler = () => {
    const scrollContainerPositionTop = scrollContainer.getBoundingClientRect().top;
    const featuresTableContainerPositionTop = featuresTableContainer.getBoundingClientRect().top;
    const scrollTo = featuresTableContainerPositionTop - scrollContainerPositionTop + scrollContainer.scrollTop;

    scrollContainer.scrollTo({
      top: scrollTo,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {currentPlan && (
        <Box mb={2}>
          <CurrentPlanMessage currentPlan={currentPlan} />
        </Box>
      )}

      <Box mb={6}>
        <PlansCardsSlider
          currentPlan={currentPlan}
          applicableLevels={applicableLevels}
          selectedQuantitiesMap={selectedQuantitiesMap || {}}
          plansMap={plansMap}
          interval={selectedPlanInterval}
          onIntervalChange={intervalChangeHandler}
          onSelectPlanLevel={planSelectHandler}
          onSelectSeatsQuantity={seatsQuantityChangeHandler}
          onCompare={compareHandler}
        />

        <Box mt={1} textAlign={'right'}>
          <PgLink target={'_blank'} href="https://www.priz.guru/plans-pricing/education-license/">
            <Trans>Individual licenses for students and teachers available. Apply now!</Trans>
          </PgLink>
        </Box>
      </Box>

      <Box ref={featuresTableContainerRefHandler}>
        <Typography component={Box} variant={'h6'} mb={1}>
          <Trans>Plans features comparison table</Trans>:
        </Typography>

        <Box overflow={'auto'}>
          <Box minWidth={{ sm: 700 }}>
            <FeaturesComparisonTable
              plansMap={plansMap}
              applicableLevels={applicableLevels}
              planFeaturesMap={data}
              colsWidth={['32%', '17%', '17%', '17%', '17%']}
              isExpanded={true}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
