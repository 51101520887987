import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../../theme';

export const useStyles = makeStyles({
  root: {
    'background': pgColorScheme.backgroundMediumGray,
    'borderRadius': 10,
    'boxSizing': 'border-box',
    'position': 'absolute',
    'width': '100%',
    'height': '100%',
    'top': 0,
    'right': 0,
    'bottom': 0,
    'left': 0,
    'overflow': 'auto',

    '&._compact': {
      '& $causeRow': {
        'marginBottom': 50,

        '&:last-child': {
          marginBottom: 0,
        },

        '&._dead-end': {
          '&::before': {
            width: 'calc(100% + 20px)',
            height: 'calc(100% + 20px)',
            top: -10,
            left: -10,
          },
        },
      },
    },
  },
  causeRow: {
    'position': 'relative',
    'marginBottom': 100,

    '&:last-child': {
      marginBottom: 0,
    },

    '&._dead-end': {
      '&::before': {
        content: '""',
        width: 'calc(100% + 30px)',
        height: 'calc(100% + 30px)',
        position: 'absolute',
        top: -15,
        left: -15,
        right: 'auto',
        bottom: 'auto',
        boxSizing: 'border-box',
        border: `2px solid ${pgColorScheme.green}`,
        pointerEvents: 'none',
        borderRadius: 10,
      },
    },
  },
});
