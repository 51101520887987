import React, { ChangeEvent, useEffect, useState } from 'react';
import { useStyles } from './styles';
import { CftNodeTextEditorRendererProps, CftNodeType } from '@priz/shared/src/models/tools/cft';
import { ClickAwayListener, InputBase } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getTextWidth } from '@priz/shared/src/utils/text';

const defaultPlaceholderTextMap: { [key in CftNodeType]: string } = {
  [CftNodeType.Statement]: 'Change description and goal',
  [CftNodeType.Abstraction]: 'Reason for alternative Change',
  [CftNodeType.Step]: 'Step description',
  [CftNodeType.Variant]: 'Option description',
  [CftNodeType.Process]: 'Sequence description',
};

const rootPlaceholderTextMap: { [key in CftNodeType]: string } = {
  ...defaultPlaceholderTextMap,
};

const branchPlaceholderTextMap: { [key in CftNodeType]: string } = {
  ...defaultPlaceholderTextMap,
  [CftNodeType.Statement]: 'New change description and goal',
};

interface CftNodeTextEditorProps extends CftNodeTextEditorRendererProps {
  onChange?: (nodeId: string, text: string) => void;
  disabled?: boolean;
}

export const CftNodeTextEditor: React.FC<CftNodeTextEditorProps> = ({
  node,
  inRootBranch,
  className,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const rootClassNames = [styles.label];
  const placeholder = t(inRootBranch ? rootPlaceholderTextMap[node.type] : branchPlaceholderTextMap[node.type]);

  const [labelRef, setLabelRef] = useState<HTMLElement>();
  const [textAreaRef, setTextAreaRef] = useState<HTMLTextAreaElement>();
  const [text, setText] = useState(node.description || '');

  useEffect(() => {
    if (textAreaRef && document.activeElement !== textAreaRef && text !== node.description) {
      setText(node.description || '');
    }
  }, [textAreaRef, text, node.description]);

  const changeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);

    if (onChange) {
      onChange(node.id, e.target.value);
    }
  };

  const keydownHandler = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    e.stopPropagation();
  };

  const clickAwayHandler = () => {
    if (textAreaRef) {
      textAreaRef.blur();
    }
  };

  const textAreaRefHandler = (el: HTMLTextAreaElement) => {
    if (!textAreaRef && el) {
      setTextAreaRef(el);
    }
  };

  const labelRefHandler = (el: HTMLElement) => {
    if (!labelRef && el) {
      setLabelRef(el);
    }
  };

  if (className) {
    rootClassNames.push(className);
  }

  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <label ref={r => labelRefHandler(r)} className={rootClassNames.join(' ')}>
        {!labelRef && <span className={[styles.textarea, styles.initialText].join(' ')}>{text || placeholder}</span>}

        {labelRef && (
          <InputBase
            multiline
            className={styles.textarea}
            value={text}
            placeholder={placeholder}
            onChange={changeHandler}
            onKeyDown={keydownHandler}
            inputRef={r => textAreaRefHandler(r)}
            style={{ width: Math.ceil(getTextWidth(text || placeholder, { fontElement: labelRef })) }}
            disabled={disabled}
          />
        )}
      </label>
    </ClickAwayListener>
  );
};
