import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import ReactPlayer from 'react-player';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';

interface VideoPlayerProps {
  videoUrl: string;
  onStart?: () => void;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl, onStart }) => {
  const styles = useStyles();

  const [url, setUrl] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(false);
    setUrl(videoUrl);
  }, [videoUrl]);

  const handleReady = () => {
    setIsReady(true);
  };

  return (
    <div className={styles.root}>
      {videoUrl && <LoadingOverlay loading={!isReady} backdropStyles={{ zIndex: 4 }} />}

      {videoUrl && (
        <ReactPlayer
          url={url}
          playing={true}
          onReady={handleReady}
          onStart={onStart}
          width={'100%'}
          height={'100%'}
          volume={1}
          playsinline={true}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 3,
            opacity: isReady ? 1 : 0,
          }}
          config={{
            youtube: {
              playerVars: { controls: 0, el: 0, fs: 0 },
            },
            vimeo: {
              playerOptions: { autoplay: 1, byline: 0, controls: 1, keyboard: 1, portrait: 0, title: 0 },
            },
            file: {
              attributes: {
                controls: true,
              },
            },
          }}
        />
      )}
    </div>
  );
};
