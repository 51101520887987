import React, { ReactNode, useEffect, useState } from 'react';
import { alpha, Box, BoxProps } from '@mui/material';
import { useStyles } from './styles';
import { Trans } from 'react-i18next';

export type FilledLabelColorVariant = 'gray' | 'blue' | 'blue_medium' | 'green' | 'purple';

interface FilledLabelProps extends BoxProps {
  text: ReactNode | string;
  preset?: 'gray' | 'blue' | 'blue_medium' | 'green' | 'purple';
  size?: 'medium' | 'small';
  hover?: boolean;
  color?: string;
}

const resolveColorProps = (color?: string) => {
  return color
    ? {
        'backgroundColor': alpha(color, 0.1),
        'color': alpha(color, 0.7),

        '&.interactive': {
          '&:hover': {
            backgroundColor: alpha(color, 0.175),
          },
        },
      }
    : undefined;
};

export const FilledLabel: React.FC<FilledLabelProps> = ({ text, preset, size = 'medium', color, hover, ...rest }) => {
  const [colorState, setColorState] = useState<string | undefined>();
  const styles = useStyles();
  const classNames = [styles.root, styles[size]];

  if (!preset && !color) classNames.push(styles['gray']);
  if (preset) classNames.push(styles[preset]);
  if (hover) classNames.push('interactive');

  useEffect(() => {
    if (color) setColorState(color);
  }, [color]);

  return (
    <Box className={classNames.join(' ')} {...rest} sx={resolveColorProps(colorState)}>
      <Trans>{text}</Trans>
    </Box>
  );
};
