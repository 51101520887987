import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Goal } from '../../models/tools/rrm';
import { Trans } from 'react-i18next';
import { useStyles } from './styles';
import { ArrayUtils, SortDirection } from '../../utils/common';
import { QuillContentViewer } from '../quill-content-viewer/component';

export interface RrmRankingSummaryTableProps {
  goal: Goal;
}

export const RrmRankingSummaryTable: React.FC<RrmRankingSummaryTableProps> = ({ goal }) => {
  const styles = useStyles();

  if (!goal) return null;

  return (
    <TableContainer component={Paper} variant={'outlined'}>
      <Table className={styles.table} size={'small'}>
        <TableHead className={styles.header}>
          <TableRow>
            <TableCell className={`${styles.cell}`}>
              <Trans>Option</Trans>
            </TableCell>
            <TableCell align="center" className={`${styles.cell}`}>
              <Trans>Rank</Trans>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody className={styles.body}>
          {goal.perceptions.sort(ArrayUtils.sorterByParam('rankingScore', SortDirection.DESC)).map(perception => (
            <TableRow key={perception.id}>
              <TableCell component="th" scope="row" className={styles.cell}>
                <Box p={1}>
                  <QuillContentViewer content={perception.definition} />
                </Box>
              </TableCell>
              <TableCell align="center" className={styles.cell} width={'80px'}>
                <Typography variant={'subtitle2'}>{perception.rankingScore}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
