export enum AssistanceType {
  AskProjectDescriptionFeedback = 'AskProjectDescriptionFeedback',
  AskCurrentSituationFeedback = 'AskCurrentSituationFeedback',
  AskDisadvantagesFeedback = 'AskDisadvantagesFeedback',
  AskProblemStatementFeedback = 'AskProblemStatementFeedback',
  AskIdealFinalResult = 'AskIdealFinalResult',
  AskIdealFinalResultFeedback = 'AskIdealFinalResultFeedback',
  AskGaps = 'AskGaps',
  AskGapsFeedback = 'AskGapsFeedback',
  AskCurrentSituation = 'AskCurrentSituation',
  AskDisadvantages = 'AskDisadvantages',
  AskFiveWhysCause = 'AskCause',
  AskFiveWhysCauseSolution = 'AskCauseSolution',
  AskCecCause = 'AskCecCause',
  AskApaCustomerDescription = 'AskApaCustomerDescription',
  AskApaCustomerActions = 'AskApaCustomerActions',
  AskApaActionPurpose = 'AskApaActionPurpose',
  AskApaPreventingAction = 'AskApaPreventingAction',
  AskApaCustomerNeeds = 'AskApaCustomerNeeds',
  AskProblemStatementHint = 'AskProblemStatementHint',
  AskToolSuggestionsHint = 'AskToolSuggestionsHint',
  AskIdeaParameterHint = 'AskIdeaParameterHint',
}

export enum AssistantVariant {
  Inline = 'Inline',
  Dialog = 'Dialog',
  Popper = 'Popper',
}
