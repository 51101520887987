import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';

export const useStyles = makeStyles({
  root: {
    'display': 'inline-flex',
    'alignItems': 'center',
    'borderRadius': 4,
    'lineHeight': 1,
    'fontWeight': '700',
    'transition': 'background-color .15s ease',
    'whiteSpace': 'nowrap',
    'boxSizing': 'border-box',

    '&.interactive': {
      cursor: 'pointer',
    },
  },
  medium: {
    height: 24,
    fontSize: 16,
    padding: '4px 10px',
  },
  small: {
    height: 20,
    fontSize: 12,
    padding: '4px 6px',
  },
  gray: {
    backgroundColor: pgColorScheme.gray500,
    color: pgColorScheme.gray100,
  },
  green: {
    background: pgColorScheme.green,
    color: pgColorScheme.white,
  },
  blue: {
    background: pgColorScheme.blueLight,
    color: pgColorScheme.white,
  },
  blue_medium: {
    background: pgColorScheme.blue,
    color: pgColorScheme.white,
  },
  purple: {
    background: pgColorScheme.purple2,
    color: pgColorScheme.white,
  },
});
