import React, { useState } from 'react';
import { Task } from '@priz/shared/src/models/task';
import { DashboardItemCard } from '../../dashboard-item-card/component';
import { Avatar, Chip, Grid, Tooltip } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TaskAssigneeSelectors } from '../../../assignment/store/selectors/task-assignee.selectors';
import { UserUtils } from '@priz/shared/src/utils/user/user-utils';
import { format } from 'date-fns';
import { TaskEditor } from '../../../tasks/task-editor/component';
import { useStyles } from '../../styles';
import { Status } from '@priz/shared/src/components/status/component';
import { TaskSelectors } from '../../../tasks/store/selectors/task.selectors';

interface DashboardTaskProps {
  task: Task;
}

export const DashboardTask: React.FC<DashboardTaskProps> = ({ task }) => {
  const styles = useStyles();

  const [isTaskEditorOpen, setIsTaskEditorOpen] = useState(false);

  const isUpdating = useSelector(TaskSelectors.isUpdatingById(task.project.id, task.id));
  const assignees = useSelector(TaskAssigneeSelectors.getTaskAssignees(task.id));
  const assignee = assignees?.[0];

  const openTaskEditor = () => {
    setIsTaskEditorOpen(true);
  };

  const closeTaskEditor = () => {
    setIsTaskEditorOpen(false);
  };

  return (
    <DashboardItemCard
      text={task.title || task.details}
      path={['/project', task.project.id, 'tasks']}
      hash={`#task-${task.id}`}
      loading={isUpdating}
      controls={
        <Grid container alignItems={'center'} spacing={1}>
          {task.dueDate && (
            <Grid item>
              <Chip
                label={format(task.dueDate, 'MMM d yyyy')}
                size={'small'}
                variant={'filled'}
                color={task.dueDate.getTime() < new Date().getTime() ? 'error' : 'default'}
              />
            </Grid>
          )}

          {assignee && (
            <Grid item>
              <Tooltip
                title={UserUtils.getShowableName(assignee.user)}
                arrow={true}
                placement={'top'}
                disableInteractive={true}
              >
                <Avatar src={assignee.user.profile.avatarUrl} sx={{ width: 24, height: 24 }}>
                  {UserUtils.resolveInitials(assignee.user)}
                </Avatar>
              </Tooltip>
            </Grid>
          )}

          <Grid item>
            <Status status={task.status} size={24} showLabel={false} showTooltip={true} />
          </Grid>

          <Grid item>
            <Tooltip title={<Trans>Edit</Trans>} arrow={true} placement={'top'} disableInteractive={true}>
              <EditOutlined
                className={[styles.controlIcon, styles.interactiveControlIcon].join(' ')}
                onClick={openTaskEditor}
              />
            </Tooltip>

            <TaskEditor isOpen={isTaskEditorOpen} task={task} onClose={closeTaskEditor} />
          </Grid>
        </Grid>
      }
    />
  );
};
