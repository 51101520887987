import { IToolUtilization, ToolUtilization } from '../index';
import { SfmNodes } from './nodes';
import { SfmEdges } from './edges';

export interface SfmNetworkData {
  edges: SfmEdges;
  nodes: SfmNodes;
}

export interface SfmNamedNetworkData extends SfmNetworkData {
  index: number;
  title: string;
}

export interface SfmDiagramDataVersions {
  [versionId: string]: SfmNamedNetworkData;
}

export interface SfmDiagramData extends Partial<SfmNetworkData> {
  versionId?: string;
  versions?: SfmDiagramDataVersions;
}

export interface ISfmUtilization extends IToolUtilization {
  diagramData?: SfmDiagramData;
  dataVersion?: number;
}

export class SfmUtilization extends ToolUtilization implements ISfmUtilization {
  public diagramData?: SfmDiagramData;
  public dataVersion?: number;

  constructor(json: ISfmUtilization) {
    super(json);

    this.diagramData = json.diagramData;
    this.dataVersion = json.dataVersion;
  }
}
