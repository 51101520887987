import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    ['& .MuiDateTimePickerToolbar-timeContainer .PrivatePickersToolbarText-root, & .MuiDateTimePickerToolbar-dateContainer .MuiTypography-h4']:
      {
        'fontSize': '2rem',
        'lineHeight': 1,
        'letterSpacing': 0,

        '&.Mui-selected': {
          color: pgColorScheme.blue,
        },
      },
    ['& .MuiDateTimePickerToolbar-dateContainer .MuiTypography-subtitle1']: {
      '&.Mui-selected': {
        color: pgColorScheme.blue,
      },
    },
    ['& .MuiPickersToolbar-penIconButton']: {
      display: 'none',
    },
    ['& .MuiDialogActions-root']: {
      display: 'block',
      borderTop: '1px solid rgba(0,0,0,.1)',
      padding: 8,
      textAlign: 'right',
    },
  },
  selectorTitle: {
    textTransform: 'none',
    color: pgColorScheme.darkGray,
  },
  inputContainer: {
    position: 'relative',
  },
  input: {
    position: 'absolute',
    pointerEvents: 'none',
    opacity: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
  },
  button: {
    textTransform: 'none',
  },
  pickersDay: {
    ['&.Mui-selected.Mui-disabled']: {
      backgroundColor: pgColorScheme.coral,
      color: pgColorScheme.white,
      opacity: 0.7,
    },
  },
});
