import React from 'react';
import { ToolUtilization } from '@priz/shared/src/models/tools';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { Box, ButtonProps, IconButton, Tooltip } from '@mui/material';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { ToolUtilizationActions, ToolUtilizationActionType } from '../../project/store/actions/tools.actions';
import { ToolCompleteCommand } from '../../project/services';
import { LoadingButton } from '@mui/lab';
import { TaskAltRounded, RotateLeftRounded } from '@mui/icons-material';
import { useStyles } from './styles';

export interface CompleteToolButtonProps extends ButtonProps {
  utilization: ToolUtilization;
}

export const CompleteToolButton: React.FC<CompleteToolButtonProps> = ({ utilization, ...rest }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const isUpdating = useSelector(
    ToolUtilizationSelector.isPendingByActionType(utilization.id, ToolUtilizationActionType.UpdateCompleteness),
  );

  const toggleCompleteButton = () => {
    const command: ToolCompleteCommand = {
      complete: !utilization.completed,
    };

    dispatch(ToolUtilizationActions.updateCompleteStatus(utilization.id, command));
  };

  return (
    <Box className={styles.root}>
      <LoadingButton
        className={styles.button}
        variant={'pg_rounded'}
        color={utilization.completed ? 'pg_orange_solid' : 'pg_orange_outlined'}
        onClick={toggleCompleteButton}
        disabled={isUpdating || utilization.completed}
        loading={isUpdating}
        startIcon={utilization.completed ? <TaskAltRounded /> : undefined}
        {...rest}
      >
        <Trans>{utilization.completed ? 'Completed' : 'Complete tool'}</Trans>
      </LoadingButton>

      {!isUpdating && utilization.completed && (
        <Tooltip title={<Trans>Reactivate tool</Trans>} placement={'top'} disableInteractive arrow>
          <div>
            <IconButton color={'primary'} onClick={toggleCompleteButton} disabled={isUpdating}>
              <RotateLeftRounded />
            </IconButton>
          </div>
        </Tooltip>
      )}
    </Box>
  );
};
