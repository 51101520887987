import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../store/app.state';
import { AssistantHintData, AssistantState } from '../model';

const assistantStateSelector = (state: AppState): AssistantState => state.assistant;
const hintsSelector = createSelector(assistantStateSelector, collection => collection.hints);
const statusesSelector = createSelector(assistantStateSelector, collection => collection.statuses);

const activeId = createSelector(assistantStateSelector, state => state.activeAssistantId);

const hintByAssistantId = (assistantId: string): Selector<AppState, AssistantHintData | null> =>
  createSelector(hintsSelector, hints => hints.byAssistantId[assistantId] || null);

const isLoadingByAssistantId = (assistantId: string): Selector<AppState, boolean> =>
  createSelector(statusesSelector, statuses => statuses.byAssistantId[assistantId]?.loading || false);

export const AssistantSelectors = {
  activeId,
  hintByAssistantId,
  isLoadingByAssistantId,
};
