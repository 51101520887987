import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { dashboardApi } from '../services/dashboard.api';
import { DashboardWidgetCard } from '../dashboard-widget-card/component';
import { useDispatch, useSelector } from 'react-redux';
import { TaskAssigneeActions } from '../../assignment/store/actions';
import { DashboardTask } from './dashboard-task/component';
import { Task } from '@priz/shared/src/models/task';
import { TaskSelectors } from '../../tasks/store/selectors/task.selectors';

interface DashboardTasksWidgetProps {
  workspaceId: number;
}

export const DashboardTasksWidget: React.FC<DashboardTasksWidgetProps> = ({ workspaceId }) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);

  const isAnyUpdated = useSelector(TaskSelectors.isAnyUpdated());

  const {
    data = { items: [], totalCount: 0 },
    isLoading,
    isFetching,
    refetch,
  } = useQuery(['dashboard-tasks', workspaceId, page], () => dashboardApi.getTasks(page), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    dispatch(TaskAssigneeActions.fetchAll());
  }, []);

  useEffect(() => {
    if (isAnyUpdated) void refetch();
  }, [isAnyUpdated]);

  return (
    <DashboardWidgetCard
      title={'Tasks'}
      placeholder={'Tasks list is empty'}
      currentPage={page}
      itemsCountPerPage={10}
      totalItemsCount={data.totalCount}
      isLoading={isLoading}
      isFetching={isFetching}
      onChangePage={newPage => {
        setPage(newPage);
      }}
    >
      {data.items.map(item => (
        <DashboardTask key={item.id} task={new Task(item)} />
      ))}
    </DashboardWidgetCard>
  );
};
