import { handleActions } from 'redux-actions';
import { DefaultNotificationState, NotificationState } from '../model/notification-state';
import { NotificationAction, NotificationActionType } from './notification.actions';
import { toSuccess } from '../../../../shared/store/action-creator';

export const notificationReducers = handleActions(
  {
    [toSuccess(NotificationActionType.Add)]: (
      state: NotificationState,
      action: NotificationAction,
    ): NotificationState => ({
      ...state,
      ...action.payload,
      show: true,
    }),
    [toSuccess(NotificationActionType.Remove)]: (state: NotificationState): NotificationState => ({
      ...state,
      show: false,
    }),
  },
  DefaultNotificationState,
);
