import React, { useState } from 'react';
import { Button, ButtonProps, Tooltip } from '@mui/material';

import { Trans } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { MeetingRecorder, SharedMeetingRecorderProps } from '../meeting-recorder/component';
import { ProjectFeatureBlockerActions } from '../../project-feature-blocker/store/actions';
import {
  ProjectFeatureBlockerType,
  ProjectFeatureResolverBlockerProps,
} from '../../project-feature-blocker/store/model';
import { useDispatch } from 'react-redux';
import { ToolUtilization } from '@priz/shared/src/models/tools';

export interface CreateMeetingButtonProps extends ButtonProps, SharedMeetingRecorderProps {
  text?: string;
  tooltip?: string;
  sourceTool?: ToolUtilization;
}

export const CreateMeetingButton: React.FC<CreateMeetingButtonProps> = ({
  projectId,
  text,
  tooltip,
  navigateToMeetingPage,
  sourceTool,
  ...rest
}) => {
  const dispatch = useDispatch();

  const [isRecorderOpen, setIsRecorderOpen] = useState(false);

  const openRecorder = () => {
    if (typeof projectId !== 'undefined') {
      setIsRecorderOpen(true);
    } else {
      const blockerProps: ProjectFeatureResolverBlockerProps = {
        utilizationId: sourceTool?.id,
        blocker: ProjectFeatureBlockerType.Meeting,
        meetingRecorderProps: {
          sourceToolId: sourceTool?.id,
          sourceToolType: sourceTool?.type,
        },
      };

      dispatch(ProjectFeatureBlockerActions.set(blockerProps));
    }
  };

  const closeRecorder = () => {
    setIsRecorderOpen(false);
  };

  return (
    <>
      <Tooltip title={tooltip ? <Trans>{tooltip}</Trans> : ''} placement={'top'} disableInteractive arrow>
        <div>
          <Button
            variant={'pg_rounded'}
            color={'pg_orange_solid'}
            onClick={openRecorder}
            startIcon={<AddIcon />}
            {...rest}
          >
            <Trans>{text || 'Create new meeting'}</Trans>
          </Button>
        </div>
      </Tooltip>

      <MeetingRecorder
        open={isRecorderOpen}
        projectId={projectId}
        navigateToMeetingPage={navigateToMeetingPage}
        onClose={closeRecorder}
      />
    </>
  );
};
