import { createPgAction } from '../../../../shared/store/action-creator';
import { FluxStandardAction } from 'flux-standard-action';
import { AlertColor } from '@mui/material/Alert/Alert';

export enum NotificationActionType {
  Add = 'notification/add',
  Remove = 'notification/remove',
}

export interface NotificationActionPayload {
  message?: string;
  severity?: AlertColor;
  duration?: number;
}

export type NotificationAction = FluxStandardAction<NotificationActionType, NotificationActionPayload>;

const add = createPgAction(
  NotificationActionType.Add,
  (message, severity, duration) =>
    new Promise(resolve =>
      resolve({
        duration,
        message,
        severity,
      }),
    ),
);

const remove = createPgAction(NotificationActionType.Remove, (): Promise<void> => new Promise(resolve => resolve()));

export const NotificationActions = {
  add,
  remove,
};
