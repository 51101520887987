export enum MeetingsPeriodFilterType {
  All = 'All',
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}

export enum MeetingStatusFilterType {
  All = 'All',
  Draft = 'Draft',
  Released = 'Released',
  Active = 'Active',
  Finished = 'Finished',
}

export enum AttendeeRoleFilterType {
  All = 'All',
  AwaitingResponse = 'AwaitingResponse',
  Lead = 'Lead',
  Contributor = 'Contributor',
  Observer = 'Observer',
  Decline = 'Decline',
}
