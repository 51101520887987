import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from './styles';
import { Box, IconButton, Paper, TextField, Tooltip } from '@mui/material';
import { Network } from '@priz/shared/src/lib/vis/esnext';
import { DataSet } from 'vis-data';
import { Hamburger } from '../../modules/Hamburger';
import { pgColorScheme } from '@priz/shared/src/theme';
import {
  manageNetworkEvents,
  removeEdge,
  removeNode,
  replaceUniqueElementTypeIfExists,
  revertEdgeDirection,
  updateEdge,
  updateNode,
  useRemoveNetworkElementsByKeyboard,
} from '@priz/shared/src/components/network-diagram/utils';
import { EdgeProps, NodeProps } from '@priz/shared/src/models/vis-network';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { Trans } from 'react-i18next';
import { SyncAltRounded, DeleteOutlineRounded } from '@mui/icons-material';

export type SwitchNetworkElementTypeButton = {
  type: string;
  icon: React.FC;
  disabledTooltip?: string;
  activeTooltip?: string;
};

export interface NetworkElementsEditorMenuProps {
  network: Network;
  nodesDataSet: DataSet<NodeProps>;
  edgesDataSet: DataSet<EdgeProps>;
  changeNodeTypes?: SwitchNetworkElementTypeButton[];
  changeEdgeTypes?: SwitchNetworkElementTypeButton[];
  nodePropsDecorator?: (nodeProps: Partial<NodeProps>) => Partial<NodeProps>;
  edgePropsDecorator?: (edgeProps: Partial<EdgeProps>) => Partial<EdgeProps>;
  disableChangeNodeTypeFor?: string[];
  disableRemoveNodeFor?: string[];
  uniqueNodesTypes?: { type: string; replaceByType: string }[];
  focusInputOnElementSelect?: boolean;
  disabled?: boolean;
  menuInitiallyOpen?: boolean;
}

const networkEvents = (
  changeNodeSelectionCallback: (id: string | null) => void,
  changeEdgeSelectionCallback: (id: string | null) => void,
  modifyingCallback: (isModifying: boolean) => void,
) => ({
  selectNode: data => {
    if (data?.nodes?.length) changeNodeSelectionCallback(data.nodes[0]);
  },
  deselectNode: () => {
    changeNodeSelectionCallback(null);
  },
  selectEdge: data => {
    if (data?.edges?.length) changeEdgeSelectionCallback(data.edges[0]);
  },
  deselectEdge: () => {
    changeEdgeSelectionCallback(null);
  },
  dragStart: () => {
    modifyingCallback(true);
  },
  dragEnd: () => {
    modifyingCallback(false);
  },
  controlNodeDragging: () => {
    modifyingCallback(true);
  },
  controlNodeDragEnd: () => {
    modifyingCallback(false);
  },
});

export const NetworkElementsEditorMenu: React.FC<NetworkElementsEditorMenuProps> = ({
  network,
  nodesDataSet,
  edgesDataSet,
  changeNodeTypes,
  changeEdgeTypes,
  nodePropsDecorator,
  edgePropsDecorator,
  disableChangeNodeTypeFor = [],
  disableRemoveNodeFor,
  uniqueNodesTypes,
  focusInputOnElementSelect,
  menuInitiallyOpen,
  disabled,
}) => {
  const styles = useStyles();
  const inputRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(menuInitiallyOpen);
  const [selectedNode, setSelectedNode] = useState<{ id: string; type: string }>(null);
  const [selectedEdge, setSelectedEdge] = useState<{ id: string; type: string }>(null);
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [isModifying, setIsModifying] = useState(false);
  const [inputText, setInputText] = useState('');
  const [removedNodeId, removedEdgeId] = useRemoveNetworkElementsByKeyboard({
    network,
    nodesDataSet,
    edgesDataSet,
    removeExceptionNodesTypes: disableRemoveNodeFor,
    disabled: inputIsFocused || isModifying,
  });

  useEffect(() => {
    if (selectedNode && removedNodeId && selectedNode?.id === removedNodeId) setSelectedNode(null);
    if (selectedEdge && removedEdgeId && selectedEdge?.id === removedEdgeId) setSelectedEdge(null);
  }, [removedNodeId, removedEdgeId]);

  useEffect(() => {
    const events = networkEvents(changeNodeSelectionHandler, changeEdgeSelectionHandler, modifyingCallback);

    if (network) manageNetworkEvents(network, events);

    return () => {
      if (network) {
        manageNetworkEvents(network, events, true);
      }
    };
  }, [network]);

  useEffect(() => {
    if (selectedNode) {
      const { label } = nodesDataSet.get(selectedNode?.id);
      setInputText(label || '');
    }

    if (selectedEdge) {
      const { label } = edgesDataSet.get(selectedEdge?.id);
      setInputText(label || '');
    }
  }, [selectedNode, selectedEdge]);

  useEffect(() => {
    if (focusInputOnElementSelect && isMenuOpen && (selectedEdge || selectedNode) && inputRef?.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  }, [selectedNode, selectedEdge, isMenuOpen]);

  const toggleMenuHandler = (open: boolean) => {
    setIsMenuOpen(open);
  };

  const changeNodeSelectionHandler = id => {
    setSelectedEdge(null);

    if (id) {
      const node = nodesDataSet.get(id) as Partial<NodeProps>;
      if (node) setSelectedNode({ type: node?.type, id });
    } else {
      setSelectedNode(null);
    }
  };

  const modifyingCallback = (modifying: boolean) => {
    setIsModifying(modifying);
  };

  const changeEdgeSelectionHandler = id => {
    setSelectedNode(null);

    if (id) {
      const edge = edgesDataSet.get(id) as unknown as EdgeProps;
      if (edge) setSelectedEdge({ type: edge?.type, id });
    } else {
      setSelectedEdge(null);
    }
  };

  const textChangeHandler = event => {
    if (disabled) return null;

    const value = event?.target?.value;

    setInputText(value);

    if (selectedNode) {
      updateNode({ nodesDataSet, id: selectedNode?.id, data: { label: value } });
    }

    if (selectedEdge) {
      updateEdge({ edgesDataSet, id: selectedEdge?.id, data: { label: value } });
    }

    network.redraw();
  };

  const switchNodeTypeHandler = (type: string) => {
    if (disabled) return null;

    if (uniqueNodesTypes) {
      replaceUniqueElementTypeIfExists({
        nodesDataSet,
        nodePropsDecorator,
        uniqueTypes: uniqueNodesTypes,
        replaceType: type,
      });
    }

    updateNode({
      nodesDataSet,
      nodePropsDecorator,
      id: selectedNode.id,
      data: { type },
    });

    setSelectedNode({ type, id: selectedNode.id });
  };

  const switchEdgeTypeHandler = (type: string) => {
    if (disabled) return null;

    updateEdge({
      edgesDataSet,
      edgePropsDecorator,
      id: selectedEdge.id,
      data: { type },
    });

    setSelectedEdge({ type, id: selectedEdge.id });
  };

  const renderSwitchTypeButtons = (
    types: SwitchNetworkElementTypeButton[],
    selectedItem: Partial<{ type: string }>,
    clickHandler: (type: string) => void,
  ) => {
    return types.map(({ type, icon: Icon, disabledTooltip, activeTooltip }, key) => (
      <Tooltip
        title={
          selectedItem.type === type ? (
            disabledTooltip ? (
              <Trans>{disabledTooltip}</Trans>
            ) : (
              ''
            )
          ) : activeTooltip ? (
            <Trans>{activeTooltip}</Trans>
          ) : (
            ''
          )
        }
        placement="top"
        arrow={true}
        key={key}
      >
        <div
          style={{
            opacity: disabled || selectedItem.type === type ? 0.3 : 1,
            pointerEvents: disabled || selectedItem.type === type ? 'none' : 'auto',
          }}
          onClick={() => {
            if (clickHandler) clickHandler(type);
          }}
        >
          <Icon />
        </div>
      </Tooltip>
    ));
  };

  const revertEdgeHandler = () => {
    revertEdgeDirection({ id: selectedEdge.id, edgesDataSet });
  };

  const deleteClickHandler = () => {
    if (selectedNode) {
      removeNode({
        nodesDataSet,
        id: selectedNode?.id,
        removeConnectedEdges: { edgesDataSet, network },
      });
      setSelectedNode(null);
    }

    if (selectedEdge) {
      removeEdge({ edgesDataSet, id: selectedEdge?.id });
      setSelectedEdge(null);
    }
  };

  const inputFocusHandler = () => {
    setInputIsFocused(true);
  };

  const inputBlurHandler = () => {
    setInputIsFocused(false);
  };

  if (!selectedNode && !selectedEdge) return null;

  return (
    <div className={`${styles.root}${isMenuOpen ? ' _open' : ''}`}>
      <div className={styles.hamburgerWrap}>
        <Hamburger
          p={2}
          active={isMenuOpen}
          callback={toggleMenuHandler}
          barProps={{
            barColor: pgColorScheme.blue,
            barColorHover: pgColorScheme.darkGray,
            barColorActive: pgColorScheme.blue,
            barColorActiveHover: pgColorScheme.darkGray,
          }}
        />
      </div>

      <Paper className={styles.menuWrap}>
        <Box p={2} pt={6}>
          <div className={styles.menu}>
            <TextField
              inputRef={inputRef}
              multiline
              minRows={1}
              maxRows={4}
              variant="outlined"
              className={styles.textField}
              onChange={textChangeHandler}
              value={inputText}
              onFocus={inputFocusHandler}
              onBlur={inputBlurHandler}
              onKeyDown={e => e.stopPropagation()}
              disabled={disabled}
              size={'small'}
            />

            {selectedNode && (
              <>
                {changeNodeTypes && !disableChangeNodeTypeFor.includes(selectedNode.type) && (
                  <ButtonsWrap mt={1} spacing={1}>
                    {renderSwitchTypeButtons(changeNodeTypes, selectedNode, switchNodeTypeHandler)}
                  </ButtonsWrap>
                )}
              </>
            )}

            {selectedEdge && (
              <>
                <ButtonsWrap mt={1} spacing={1}>
                  {changeEdgeTypes && renderSwitchTypeButtons(changeEdgeTypes, selectedEdge, switchEdgeTypeHandler)}
                </ButtonsWrap>
              </>
            )}

            <ButtonsWrap mt={1} justifyContent={'center'} spacing={1}>
              {!disableRemoveNodeFor.includes(selectedNode?.type || selectedEdge?.type) && (
                <Tooltip
                  title={<Trans>{selectedNode ? 'Remove node' : selectedEdge ? 'Remove edge' : ''}</Trans>}
                  placement="top"
                  arrow={true}
                >
                  <div>
                    <IconButton onClick={deleteClickHandler} className={styles.iconButton} disabled={disabled}>
                      {/*<TrashIcon fill={pgColorScheme.blue} />*/}
                      <DeleteOutlineRounded className={styles.revertIcon} />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
              {selectedEdge && (
                <Tooltip title={<Trans>Revert direction</Trans>} placement="top" arrow={true}>
                  <div>
                    <IconButton onClick={revertEdgeHandler} className={styles.iconButton} disabled={disabled}>
                      <SyncAltRounded className={styles.revertIcon} />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
            </ButtonsWrap>
          </div>
        </Box>
      </Paper>
    </div>
  );
};
