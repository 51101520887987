let fieldsCounter = 0;

export const generateUniqFieldId = (fieldName: string) => {
  return `${fieldName}-${fieldsCounter++}`;
};

export const getTextAndImages = (str: string): string => {
  return str?.replace(/(<(?!img|\/img).*?>)/gi, '')?.trim() || '';
};

export const fixQuillEmptyValue = (str: string): string => {
  return getTextAndImages(str)?.length ? str : '';
};
