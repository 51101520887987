import React, { useRef, useEffect, useState } from 'react';
import { Network, Options as OptionsType } from '../../lib/vis/esnext';
import { DataSet } from 'vis-data';
import { BoxProps } from '@mui/material';
import { EdgeProps, NodeProps } from '../../models/vis-network';
import { disabledInteraction, viewModeInteraction } from './options';

interface NetworkDiagramProps extends BoxProps {
  options: OptionsType;
  nodesDataSet: DataSet<NodeProps>;
  edgesDataSet: DataSet<EdgeProps>;
  onNetworkInit?: (network: Network) => void;
  onAddEdge?: (from: string, to: string) => void;
  edgeMode?: boolean;
  disabled?: boolean;
  viewMode?: boolean;
  styledCursor?: boolean;
  redrawOnFontsLoad?: boolean;
}

export const NetworkDiagram: React.FC<NetworkDiagramProps> = ({
  options,
  nodesDataSet,
  edgesDataSet,
  onNetworkInit,
  edgeMode,
  onAddEdge,
  disabled,
  viewMode,
  styledCursor,
  redrawOnFontsLoad,
}) => {
  const canvasRef = useRef<HTMLDivElement | null>(null);
  const [network, setNetwork] = useState<Network | null>(null);
  const [cursorVariant, setCursorVariant] = useState<'cursor' | 'hand' | null>(null);

  useEffect(() => {
    if (styledCursor) {
      setCursorVariant(edgeMode ? 'cursor' : 'hand');
    } else {
      setCursorVariant(null);
    }
  }, [edgeMode, styledCursor]);

  useEffect(() => {
    nodesDataSet.on('*', dataSetChangeCallback);
    edgesDataSet.on('*', dataSetChangeCallback);

    return () => {
      nodesDataSet.off('*', dataSetChangeCallback);
      edgesDataSet.off('*', dataSetChangeCallback);
    };
  }, [network, edgeMode]);

  const dataSetChangeCallback = () => {
    if (!viewMode && !disabled && edgeMode && network) network.addEdgeMode();
  };

  useEffect(() => {
    if (!network && canvasRef?.current) {
      setNetwork(new Network(canvasRef.current, { nodes: nodesDataSet, edges: edgesDataSet }, options));
    }

    if (network && !viewMode && !disabled) {
      network.setOptions({
        ...options,
        manipulation: options.manipulation
          ? {
              ...options.manipulation,
              addEdge: addEdgeNetworkCallback,
            }
          : false,
      });
      if (edgeMode) network.addEdgeMode();
    }

    if (network && (viewMode || disabled)) {
      network.setOptions({
        ...options,
        ...(disabled ? disabledInteraction : viewModeInteraction),
        manipulation: false,
      });
    }

    if (network && onNetworkInit) onNetworkInit(network);
  }, [network, viewMode, disabled]);

  useEffect(() => {
    const fontsReadyPromise = (document as any)?.fonts?.ready as Promise<Animation>;

    if (redrawOnFontsLoad && network && fontsReadyPromise) {
      fontsReadyPromise?.then(() => {
        const nodes = nodesDataSet.get();
        nodesDataSet.clear();
        nodesDataSet.add(nodes);
        network.fit();
      });
    }
  }, [network]);

  const addEdgeNetworkCallback = (data: EdgeProps, callback: () => void) => {
    if (network && data.from !== data.to) {
      if (onAddEdge) {
        onAddEdge(data.from, data.to);
      } else {
        callback();
      }
    }
  };

  // return <div className={styles.container} ref={canvasRef} />;
  return <div className={`vis-network-container${cursorVariant ? ' ' + cursorVariant : ''}`} ref={canvasRef} />;
};
