import React, { SyntheticEvent, useState } from 'react';
import { useStyles } from './styles';
import { Popper, Box, BoxProps, ClickAwayListener, Fade, Paper, PopperProps, PaperProps } from '@mui/material';

export interface PgPopperProps extends PopperProps {
  onClose?: () => void;
  arrow?: boolean;
  closeOnClickAway?: boolean;
  stopPropagation?: boolean;
  variant?: 'white' | 'blue';
  shadow?: boolean;
  hideIfEscaped?: boolean;
  hideIfReferenceHidden?: boolean;
  fadeContainerProps?: BoxProps;
  contentProps?: BoxProps;
  dropdownProps?: PaperProps & BoxProps;
  zIndex?: 'front' | 'back' | 'default';
}

export const PgPopper: React.FC<PgPopperProps> = ({
  open = false,
  children,
  onClose,
  arrow = true,
  closeOnClickAway = true,
  stopPropagation,
  variant = 'white',
  hideIfEscaped,
  hideIfReferenceHidden,
  fadeContainerProps,
  contentProps,
  dropdownProps,
  shadow,
  zIndex = 'front',
  ...rest
}) => {
  const styles = useStyles();
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const { className: popperClassName, modifiers = [], transition = true, ...restPopperProps } = rest || {};
  const { className: dropdownClassName, ...restDropdownProps } = dropdownProps || {};
  const { className: contentClassName, ...restContentProps } = contentProps || {};
  const popperClassNames = [styles.popper, styles[variant]];
  const dropdownClassNames = [styles.paper, shadow ? styles.paperShadow : styles.paperBorder];
  const contentClassNames = [styles.content];

  if (popperClassName) popperClassNames.push(popperClassName);
  if (hideIfEscaped) popperClassNames.push(styles.hidePopperIfEscaped);
  if (hideIfReferenceHidden) popperClassNames.push(styles.hidePopperIfReferenceHidden);
  if (zIndex === 'front') popperClassNames.push(styles.zFront);
  if (zIndex === 'back') popperClassNames.push(styles.zBack);
  if (arrow) popperClassNames.push('arrow-padding');

  if (dropdownClassName) dropdownClassNames.push(dropdownClassName);
  if (contentClassName) contentClassNames.push(contentClassName);

  const popperModifiers = [
    {
      name: 'arrow',
      enabled: true,
      options: {
        element: arrowRef,
      },
    },
    {
      name: 'preventOverflow',
    },
    ...modifiers,
  ];

  const handleArrowRef = (element: HTMLDivElement) => {
    setArrowRef(element);
  };

  const clickAwayHandler = () => {
    if (closeOnClickAway) closePopper();
  };

  const closePopper = () => {
    if (onClose) onClose();
  };

  const popperClickHandler = (e: SyntheticEvent) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
  };

  const renderContent = () => {
    return (
      <ClickAwayListener onClickAway={clickAwayHandler}>
        <Box className={contentClassNames.join(' ')} {...restContentProps}>
          {arrow && <div className={styles.arrow} ref={handleArrowRef} />}

          <Paper component={Box} p={2} elevation={1} className={dropdownClassNames.join(' ')} {...restDropdownProps}>
            {children}
          </Paper>
        </Box>
      </ClickAwayListener>
    );
  };

  return (
    <Popper
      open={open}
      modifiers={popperModifiers}
      className={popperClassNames.join(' ')}
      onClick={popperClickHandler}
      transition={transition}
      {...restPopperProps}
    >
      {transition
        ? ({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Box {...(fadeContainerProps || {})}>{renderContent()}</Box>
            </Fade>
          )
        : renderContent()}
    </Popper>
  );
};
