import { MatrixElement } from '@priz/shared/src/models/tools/forty-principles/matrix-element';
import { MatrixElementAction, MatrixElementActionType } from '../actions';
import { MatrixElementList } from '../model';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';

import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
  elements: [],
  error: null,
  loading: false,
};

export const matrixElementReducers = handleActions(
  {
    [toStarted(MatrixElementActionType.Fetch)]: (state: MatrixElementList): MatrixElementList => ({
      ...state,
      loading: true,
    }),
    [toFailed(MatrixElementActionType.Fetch)]: (state: MatrixElementList): MatrixElementList => ({
      ...state,
      loading: false,
      error: true,
    }),
    [toSuccess(MatrixElementActionType.Fetch)]: (
      state: MatrixElementList,
      action: MatrixElementAction,
    ): MatrixElementList => {
      const elements = action.payload.map(elementData => new MatrixElement(elementData));

      return {
        ...state,
        elements,
        loading: false,
        error: false,
      };
    },
  },
  INITIAL_STATE,
);
