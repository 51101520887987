import { Auth0Provider } from './auth0-provider';
import { Auth0DecodedHash, Auth0Error, Auth0ParseHashError } from 'auth0-js';
import { environment } from '../../environments/environment';
import { AxiosService } from '../axios';
import { User } from '../../models/security/user';
import { LocalStorageKey, LocalStorageService } from '../local-storage';
import Cookies from 'js-cookie';
import { CookiesKey } from '../../services/cookies';
import { Auth0RedirectKey, AuthFormType } from '../../models/auth0';
import { AnalyticsService } from '../analytics/services/analytics.service';

const auth0 = Auth0Provider.getWebAuth();

interface AuthResult {
  expiresIn?: number;
  accessToken?: string;
}

const setLocalStorageToken = (expiresIn: number, accessToken: string) => {
  const expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime());

  LocalStorageService.setItem(LocalStorageKey.AccessToken, accessToken);
  LocalStorageService.setItem(LocalStorageKey.ExpiresAt, expiresAt);
};

const setCookieToken = (expiresIn: number, accessToken: string) => {
  Cookies.set(CookiesKey.AccessToken, accessToken, { expires: expiresIn / 60 / 60 / 24 });
  Cookies.set(CookiesKey.ExpiresIn, expiresIn.toString(), { expires: 365 });
};

const mergeCookieTokenToLocalStorage = () => {
  const expiresIn = Cookies.get(CookiesKey.ExpiresIn);
  const accessToken = Cookies.get(CookiesKey.AccessToken);

  if (accessToken && expiresIn && !isNaN(parseInt(expiresIn))) {
    setLocalStorageToken(parseInt(expiresIn), accessToken);
    AxiosService.setAuthorizationToken(accessToken);
  }
};

const setSession = (expiresIn: number, accessToken: string): void => {
  setCookieToken(expiresIn, accessToken);
  setLocalStorageToken(expiresIn, accessToken);
  AxiosService.setAuthorizationToken(accessToken);
};

const clearAuthData = (): void => {
  Cookies.remove(CookiesKey.AccessToken);

  LocalStorageService.removeItem(LocalStorageKey.PrimaryAccessToken);
  LocalStorageService.removeItem(LocalStorageKey.AccessToken);
  LocalStorageService.removeItem(LocalStorageKey.ExpiresAt);
  LocalStorageService.removeItem(LocalStorageKey.User);
  LocalStorageService.removeItem(LocalStorageKey.State);
  LocalStorageService.removeItem(LocalStorageKey.Version);
  LocalStorageService.removeItem(LocalStorageKey.RefUrl);

  AxiosService.clearAuthorizationToken();
};

const logout = (redirectKey?: AuthFormType): void => {
  clearAuthData();
  AnalyticsService.resetUserIdentity();

  auth0.logout({
    clientID: environment.auth0.clientID,
    returnTo: (redirectKey && environment.logoutRedirects[redirectKey]) || environment.logoutRedirects.default,
  });
};

const isTokenExpired = (offset = 0): boolean => {
  const lsExpiresAt = LocalStorageService.getItem(LocalStorageKey.ExpiresAt);
  const expiresAt = lsExpiresAt ? JSON.parse(lsExpiresAt) : null;

  return new Date().getTime() >= expiresAt - offset;
};

const isAuthenticated = (offset = 0): boolean => {
  const lsUser = LocalStorageService.getItem(LocalStorageKey.User);
  const user = lsUser ? JSON.parse(lsUser) : null;

  return user && !isTokenExpired(offset);
};

const loginWithEmailPassword = (
  email: string,
  password: string,
  redirectKey: Auth0RedirectKey,
  failureCallback?: (error: Auth0Error) => void,
): void => {
  LocalStorageService.setItem(LocalStorageKey.LastSubmittedAuthFormType, AuthFormType.Login);

  auth0.login(
    {
      password,
      realm: 'priz-db',
      username: email,
      redirectUri: environment.authRedirects[redirectKey],
    },
    error => {
      if (error) {
        if (failureCallback) failureCallback(error);
      }
    },
  );
};

const signupWithEmailPassword = (
  email: string,
  password: string,
  redirectKey: Auth0RedirectKey,
  failureCallback?: (error: Auth0Error) => void,
) => {
  LocalStorageService.setItem(LocalStorageKey.LastSubmittedAuthFormType, AuthFormType.SignUp);

  auth0.redirect.signupAndLogin(
    {
      connection: 'priz-db',
      email,
      password,
      redirectUri: environment.authRedirects[redirectKey],
    },
    error => {
      if (error) {
        if (failureCallback) failureCallback(error);
      }
    },
  );
};

const requestPasswordReset = (
  email: string,
  successCallback: (response: string) => void,
  failureCallback?: (error: Auth0Error) => void,
) => {
  LocalStorageService.setItem(LocalStorageKey.LastSubmittedAuthFormType, AuthFormType.RequestPasswordReset);

  auth0.changePassword(
    {
      connection: 'priz-db',
      email,
    },
    (error, response) => {
      if (error) {
        if (failureCallback) failureCallback(error);
      } else {
        successCallback(response);
      }
    },
  );
};

const handleAuthentication = (
  successCallback?: (authResult: Auth0DecodedHash) => void,
  failureCallback?: (error: Auth0ParseHashError) => void,
) => {
  auth0.parseHash((error, authResult: Auth0DecodedHash | null) => {
    if (authResult?.expiresIn && authResult?.accessToken) {
      setSession(authResult.expiresIn, authResult.accessToken);
      if (successCallback) successCallback(authResult);
    } else if (error) {
      if (failureCallback) failureCallback(error);
    }
  });
};

const renewToken = (renewTokenErrorCallback?: () => void): Promise<null> => {
  return new Promise((resolve, reject) => {
    auth0.checkSession(
      { redirectUri: environment.authRedirects[Auth0RedirectKey.Login] },
      (error, result: AuthResult) => {
        if (error || !result?.expiresIn || !result?.accessToken) {
          reject(null);

          if (renewTokenErrorCallback) {
            renewTokenErrorCallback();
          } else {
            logout();
          }
        } else {
          setSession(result.expiresIn, result.accessToken);
          resolve(null);
        }
      },
    );
  });
};

const authPromise = (renewTokenErrorCallback?: () => void): Promise<null> => {
  return new Promise((resolve, reject) => {
    if (AuthService.isAuthenticated()) {
      resolve(null);
    } else {
      renewToken(renewTokenErrorCallback).then(
        () => resolve(null),
        () => reject(null),
      );
    }
  });
};

const getAccessToken = (): string | null => {
  return LocalStorageService.getItem(LocalStorageKey.AccessToken);
};

const setUserInfo = (user: User) => {
  if (user) {
    LocalStorageService.setItem(LocalStorageKey.User, JSON.stringify(user));
  }
};

const getUser = (): User | null => {
  const lsUser = LocalStorageService.getItem(LocalStorageKey.User);

  return lsUser ? (JSON.parse(lsUser) as User) : null;
};

const authorize = () => {
  auth0.authorize({});
};

const setLastSubmittedFormByRedirectKeyAndFormType = (redirectKey: Auth0RedirectKey, formType?: AuthFormType) => {
  if (redirectKey === Auth0RedirectKey.Login) {
    LocalStorageService.setItem(LocalStorageKey.LastSubmittedAuthFormType, AuthFormType.Login);
  }

  if (redirectKey === Auth0RedirectKey.Signup) {
    LocalStorageService.setItem(LocalStorageKey.LastSubmittedAuthFormType, AuthFormType.SignUp);
  }

  if (formType) {
    LocalStorageService.setItem(LocalStorageKey.LastSubmittedAuthFormType, formType);
  }
};

const authorizeWithGoogle = (redirectKey: Auth0RedirectKey, formType?: AuthFormType) => {
  setLastSubmittedFormByRedirectKeyAndFormType(redirectKey, formType);

  auth0.authorize({
    connection: 'google-oauth2',
    redirectUri: environment.authRedirects[redirectKey],
  });
};

const authorizeWithLinkedIn = (redirectKey: Auth0RedirectKey, formType?: AuthFormType) => {
  setLastSubmittedFormByRedirectKeyAndFormType(redirectKey, formType);

  auth0.authorize({
    connection: 'linkedin',
    redirectUri: environment.authRedirects[redirectKey],
  });
};

const access = (roles: string[]): boolean => {
  const userRoles = getUser()?.roles;

  if (!userRoles) {
    logout();
    return false;
  }

  for (let i = 0; i < roles.length; ++i) {
    for (let j = 0; j < userRoles.length; ++j) {
      if (roles[i] === userRoles[j]) {
        return true;
      }
    }
  }
  return false;
};

const resolveTokens = (): {
  lsAccessToken: string | null;
  cookieAccessToken: string | null;
  isTheSameUser: boolean;
  isTokenExpired: boolean;
} => {
  const lsToken = getAccessToken();
  const cookieToken = Cookies.get(CookiesKey.AccessToken) || null;
  const isTheSame = lsToken === cookieToken;
  const expired = isTokenExpired(60 * 60 * 1000);

  return {
    lsAccessToken: lsToken,
    cookieAccessToken: cookieToken,
    isTheSameUser: isTheSame,
    isTokenExpired: expired,
  };
};

export const AuthService = {
  handleAuthentication,
  isTokenExpired,
  isAuthenticated,
  authorizeWithGoogle,
  authorizeWithLinkedIn,
  loginWithEmailPassword,
  signupWithEmailPassword,
  requestPasswordReset,
  logout,
  clearAuthData,
  renewToken,
  authPromise,
  getAccessToken,
  getUser,
  setUserInfo,
  access,
  authorize,
  mergeCookieTokenToLocalStorage,
  resolveTokens,
};
