import React from 'react';
import { LevelPlansMap, Plan, PlanInterval, PlanLevel } from '@priz/shared/src/models/workspace';
import { Box, Chip, Grid, Paper, PaperProps } from '@mui/material';
import { useStyles } from './styles';
import { PlanSelectButton } from '../plan-select-button/component';
import { Trans } from 'react-i18next';
import { planLevelFeaturesMap } from '../data/plan-level-features';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SeatsQuantitySelect } from '../seats-quantity-select/component';
import { PlanLevelUtils } from '../../workspace/services';
import { PlanPrice } from '../plan-price/component';

interface PlanCardProps extends PaperProps {
  cardPlanLevel: PlanLevel;
  currentPlan?: Plan;
  selectedPlanLevel?: PlanLevel;
  plansMap: LevelPlansMap;
  planInterval?: PlanInterval;
  loading?: boolean;
  selectedQuantity: number;
  onSelectPlanLevel: (level: PlanLevel) => void;
  onSelectSeatsQuantity: (level: PlanLevel, value: number) => void;
  footer: JSX.Element;
}

export const PlanCard: React.FC<PlanCardProps> = ({
  currentPlan,
  selectedQuantity,
  selectedPlanLevel,
  cardPlanLevel,
  plansMap,
  planInterval,
  loading,
  onSelectPlanLevel,
  onSelectSeatsQuantity,
  footer,
  ...rest
}) => {
  const styles = useStyles();
  const cardPlan = plansMap[cardPlanLevel][planInterval];

  const handleSelectPlanLevel = (level: PlanLevel) => {
    onSelectPlanLevel(level);
  };

  const changeSeatsQuantityHandler = (value: number) => {
    if (onSelectSeatsQuantity) onSelectSeatsQuantity(cardPlanLevel, value);
  };

  return (
    <Paper variant={'outlined'} {...rest}>
      <Box p={1} className={styles.cardHeader}>
        <Grid container spacing={1} alignItems={'baseline'} justifyContent={'space-between'}>
          <Grid item>
            <Chip label={<Trans>{cardPlan?.name || ''}</Trans>} size={'small'} color={'primary'} />
          </Grid>
          {cardPlanLevel != PlanLevel.Enterprise && (
            <Grid item>
              <SeatsQuantitySelect
                value={selectedQuantity}
                onQuantitySelect={changeSeatsQuantityHandler}
                disabled={PlanLevelUtils.isLevelPersonalOrPro(cardPlanLevel)}
              />
            </Grid>
          )}
        </Grid>
      </Box>

      <Box p={2} className={styles.cardContent}>
        <PlanPrice plan={cardPlan} level={cardPlanLevel} interval={planInterval} quantity={selectedQuantity} mb={2} />

        <PlanSelectButton
          planLevel={cardPlanLevel}
          currentPlan={currentPlan}
          selectedPlanLevel={selectedPlanLevel}
          onPlanSelect={handleSelectPlanLevel}
          loading={loading}
        />
      </Box>

      {!!planLevelFeaturesMap[cardPlanLevel]?.length && (
        <Box p={2}>
          {planLevelFeaturesMap[cardPlanLevel].map((feature, key) => (
            <Box key={key} mt={key ? 1.5 : 0} display={'flex'}>
              <Box mr={1}>
                <FontAwesomeIcon icon={faCheck} size={'1x'} className={styles.checkIcon} />
              </Box>
              <Trans>{feature}</Trans>
            </Box>
          ))}
        </Box>
      )}

      {footer}
    </Paper>
  );
};
