import { EntityCollectionStatus, EntityMap, LookupMap } from '@priz/shared/src/models/common/entity-collection-state';
import { Chat } from './chat';

export interface ChatLookups {
  byProjectId: LookupMap;
}

export interface ChatStatuses {
  byProjectId: { [projectId: number]: EntityCollectionStatus };
}

export interface ChatState {
  entities: EntityMap<Chat>;
  lookups: ChatLookups;
  statuses: ChatStatuses;
  activeChatId?: number;
}

export const DefaultChatState: ChatState = {
  entities: {},

  lookups: {
    byProjectId: {},
  },

  statuses: {
    byProjectId: {},
  },
};
