import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../../theme';

export const useStyles = makeStyles({
  root: {
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',

    ['&:last-child']: {
      marginRight: 0,
    },
  },

  bordered: {
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: `3px solid ${pgColorScheme.blue}`,
      boxSizing: 'border-box',
      top: 0,
      left: 0,
      right: 'auto',
      bottom: 'auto',
    },
  },
});
