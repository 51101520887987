import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import QuillCore from 'quill';
import ReactQuill, { Quill } from 'react-quill';
import ImageCompress from 'quill-image-compress';
import { FieldTitle, HelperText } from '@priz/shared/src/components/form-elements';
import { ValidatorControllerProps, ReactHookFormElement, DefaultInput } from '@priz/shared/src/models/form';
import { Box } from '@mui/material';
import { useStyles } from './styles';
import ImageResize from 'quill-image-resize-module-react';
import Delta from 'quill-delta';
import { useTranslation } from 'react-i18next';

window['Quill'] = Quill;

Quill.register('modules/imageCompress', ImageCompress);
Quill.register('modules/imageResize', ImageResize);

const icons = Quill.import('ui/icons');

icons['undo'] = `
  <svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>
`;

icons['redo'] = `
    <svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>
`;

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ size: ['small', false, 'large', 'huge'] }],
  [{ color: [] }, { background: [] }],
  ['blockquote', 'code-block'],
  [{ script: 'sub' }, { script: 'super' }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ align: ['center', 'right', 'justify'] }, { direction: 'rtl' }],
  ['link', 'image'],
  ['clean'],
  ['undo', 'redo'],
];

interface QuillHistory {
  undo: () => void;
  redo: () => void;
  stack: {
    undo: Delta[];
    redo: Delta[];
  };
}

interface QuillType extends QuillCore {
  history: QuillHistory;
}

export type ReactHookFormQuillProps = ReactHookFormElement &
  DefaultInput &
  ValidatorControllerProps & {
    readOnly?: boolean;
    onChange?: (val: string) => void;
    onRef?: (name: string, ref: ReactQuill) => void;
    onBlur?: () => void;
  };

const checkIsHistoryChangeAvailable = (type: 'undo' | 'redo', history?: QuillHistory) => {
  return history ? history.stack[type].length > 0 : false;
};

export const ReactHookFormQuill: React.FC<ReactHookFormQuillProps> = ({
  name,
  control,
  fieldTitle,
  fieldTitleWrapperProps,
  placeholder,
  helperText,
  wrapperProps,
  rules,
  disabled,
  readOnly,
  onChange,
  onRef,
  onBlur,
}) => {
  const styles = useStyles();
  const [quillInstance, setQuillInstance] = useState<ReactQuill | null>(null);
  const [undoIsAvailable, setUndoIsAvailable] = useState(false);
  const [redoIsAvailable, setRedoIsAvailable] = useState(false);
  const quillRef = useRef<ReactQuill>(null);
  const rootClassNames = [];

  const { t } = useTranslation();

  if (readOnly) rootClassNames.push(styles.readOnly);
  if (!undoIsAvailable) rootClassNames.push(styles.disableUndo);
  if (!redoIsAvailable) rootClassNames.push(styles.disableRedo);

  useEffect(() => {
    const quillHistory = quillInstance ? (quillInstance.getEditor() as QuillType).history : null;

    setUndoIsAvailable(checkIsHistoryChangeAvailable('undo', quillHistory));
    setRedoIsAvailable(checkIsHistoryChangeAvailable('redo', quillHistory));
  });

  useEffect(() => {
    if (quillInstance) {
      quillRef.current = quillInstance;
      if (onRef) onRef(name, quillInstance);
    }
  }, [quillInstance]);

  const refHandler = (quill: ReactQuill) => {
    if (!quillInstance && quill) setQuillInstance(quill);
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        handlers: {
          undo: () => {
            if (quillRef.current) {
              const history = (quillRef.current.getEditor() as QuillType).history;
              quillRef.current?.editor?.root?.click();
              history.undo();
              setUndoIsAvailable(checkIsHistoryChangeAvailable('undo', history));
            }
          },
          redo: () => {
            if (quillRef.current) {
              const history = (quillRef.current.getEditor() as QuillType).history;
              quillRef.current?.editor?.root?.click();
              history.redo();
              setRedoIsAvailable(checkIsHistoryChangeAvailable('redo', history));
            }
          },
        },
        container: toolbarOptions,
      },
      history: {
        delay: 1000,
        maxStack: 100,
        userOnly: true,
      },
      clipboard: {
        matchVisual: false,
      },
      imageCompress: {
        quality: 0.7,
        maxWidth: 1600,
        maxHeight: 1600,
        imageType: 'image/jpeg',
        debug: false,
        suppressErrorLogging: true,
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize'],
      },
    }),
    [],
  );

  return (
    <Box mb={3} className={rootClassNames.join(' ')} {...wrapperProps}>
      <FieldTitle text={fieldTitle} {...fieldTitleWrapperProps} />

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <Box className={`${styles.quillContainer}${disabled ? ' _disabled' : ''}`}>
            <div translate="no" className={'notranslate'}>
              <ReactQuill
                ref={refHandler}
                onBlur={() => {
                  if (onBlur) onBlur();
                }}
                theme="snow"
                readOnly={readOnly}
                value={field.value || ''}
                modules={modules}
                placeholder={t(placeholder)}
                className={`${styles.quill}${error?.message ? ' _error' : ''}`}
                onChange={(val, _delta, source) => {
                  if (source === 'user') {
                    field.onChange(val);
                    if (onChange) onChange(val);
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                  e.stopPropagation();
                }}
              />
            </div>

            <HelperText error={error?.message} text={helperText} />
          </Box>
        )}
      />
    </Box>
  );
};
