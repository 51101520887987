import { Certificate } from './certificate';
import {
  DefaultEntityCollectionStatus,
  EntityCollectionStatus,
  EntityMap,
  LookupMap,
} from '@priz/shared/src/models/common/entity-collection-state';

export interface CertificateCollection {
  certificates: EntityMap<Certificate>;
  loadingIds: LookupMap;
  statuses: EntityCollectionStatus;
}

export const DefaultCertificateCollection: CertificateCollection = {
  certificates: {},
  loadingIds: {},
  statuses: DefaultEntityCollectionStatus,
};
