import React from 'react';
import {
  CftNodeData,
  CftNodesMap,
  CftNodeType,
  OptionalCftNodeControlsRenderer,
  OptionalCftNodeTextEditorRenderer,
} from '../../../models/tools/cft';
import { BoxProps } from '@mui/material';
import { CftVariant } from '../cft-variant/component';
import { CftProcess } from '../cft-process/component';
import { CftStep } from '../cft-step/component';

interface CftNodeProps extends BoxProps, OptionalCftNodeControlsRenderer, OptionalCftNodeTextEditorRenderer {
  node: CftNodeData;
  nodesMap: CftNodesMap;
  variantIndex: number;
  inRootBranch: boolean;
  disableInteractive?: boolean;
}

export const CftNodeResolver: React.FC<CftNodeProps> = ({
  node,
  nodesMap,
  variantIndex,
  inRootBranch,
  disableInteractive,
  ...rest
}) => {
  if (node.type === CftNodeType.Variant) {
    return <CftVariant node={node} inRootBranch={inRootBranch} nodesMap={nodesMap} index={variantIndex} {...rest} />;
  }

  if (node.type === CftNodeType.Process) {
    return <CftProcess node={node} inRootBranch={inRootBranch} nodesMap={nodesMap} {...rest} />;
  }

  if ([CftNodeType.Abstraction, CftNodeType.Statement, CftNodeType.Step].includes(node.type)) {
    return <CftStep node={node} inRootBranch={inRootBranch} disableInteractive={disableInteractive} {...rest} />;
  }

  return null;
};
