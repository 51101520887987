import React, { useState } from 'react';
import {
  Box,
  BoxProps,
  Chip,
  ClickAwayListener,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from '@mui/material';
import { ApiKey, ApiKeyStatus } from '@priz/shared/src/models/api-keys';
import { Trans } from 'react-i18next';
import { Menu as MenuIcon, VisibilityOutlined } from '@mui/icons-material';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { CopyToClipboardButton } from '../../../copy-to-clipboard-button/components';

export interface SharedApiKeyCardProps extends BoxProps {
  apiKey: ApiKey;
  disabled?: boolean;
}

export interface ApiKeyCardProps extends SharedApiKeyCardProps {
  onEditTitle?: () => void;
  onInvalidate?: () => void;
  closeMenu?: boolean;
  copy?: boolean;
  view?: boolean;
}

const cutKey = (key: string): string => {
  const prefix = 'priz-api-key_';
  const hiddenPart = '***';

  if (key.startsWith(prefix)) {
    return key.replace(key.slice(prefix.length + 3, -3), hiddenPart);
  }

  return hiddenPart;
};

export const ApiKeyCard: React.FC<ApiKeyCardProps> = ({
  apiKey,
  closeMenu,
  onEditTitle,
  onInvalidate,
  copy,
  view,
  disabled,
  ...rest
}) => {
  const [isApiKeyVisible, setIsApiKeyVisible] = useState(false);

  const isApiKeyActive = apiKey.status === ApiKeyStatus.Active;

  const toggleApiKeyVisibility = () => {
    setIsApiKeyVisible(currentState => !currentState);
  };

  const hideApiKey = () => {
    setIsApiKeyVisible(false);
  };

  return (
    <ClickAwayListener onClickAway={hideApiKey}>
      <Paper component={Box} variant={'outlined'} {...rest}>
        {!!apiKey.title?.length && (
          <Box py={1} px={2} borderBottom={'inherit'}>
            <Typography variant={'body2'} sx={{ wordBreak: 'break-all' }} color={'text_color.light'}>
              {apiKey.title}
            </Typography>
          </Box>
        )}

        <Box py={1} px={2}>
          <Grid
            container
            spacing={1}
            alignItems={{ xs: 'stretch', sm: 'center' }}
            justifyContent={{ xs: 'center', sm: 'flex-start' }}
            flexDirection={{ xs: 'column-reverse', sm: 'row' }}
          >
            <Grid item xs={12} sm={true}>
              <Box pr={{ xs: 0, sm: 2 }}>
                <Typography variant={'subtitle2'} sx={{ wordBreak: 'break-all' }}>
                  {isApiKeyVisible ? apiKey.key : cutKey(apiKey.key)}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={'auto'}>
              <Grid container spacing={1} alignItems={'center'} justifyContent={'space-between'}>
                <Grid item xs={'auto'}>
                  <Chip
                    label={<Trans>{isApiKeyActive ? 'Active' : 'Expired'}</Trans>}
                    color={isApiKeyActive ? 'success' : 'error'}
                    size={'small'}
                  />
                </Grid>

                <Grid item xs={'auto'}>
                  <Grid container>
                    {copy && (
                      <Grid item>
                        <CopyToClipboardButton
                          value={apiKey.key}
                          successMessage={'API key copied to clipboard!'}
                          errorMessage={'Failed to copy API key.'}
                        />
                      </Grid>
                    )}

                    {view && (
                      <Grid item>
                        <IconButton
                          size={'small'}
                          onClick={toggleApiKeyVisibility}
                          color={isApiKeyVisible ? 'primary' : 'default'}
                        >
                          <VisibilityOutlined />
                        </IconButton>
                      </Grid>
                    )}

                    {(onEditTitle || onInvalidate) && (
                      <Grid item>
                        <PopperWrap placement={'top'} open={closeMenu ? false : undefined} dropdownProps={{ p: 0 }}>
                          <IconButton size={'small'}>
                            <MenuIcon />
                          </IconButton>

                          <MenuList>
                            {onEditTitle && (
                              <MenuItem onClick={onEditTitle} disabled={disabled}>
                                <Typography>
                                  <Trans>{apiKey.title?.length ? 'Rename' : 'Set title'}</Trans>
                                </Typography>
                              </MenuItem>
                            )}

                            {onInvalidate && (
                              <MenuItem onClick={onInvalidate} disabled={disabled || !isApiKeyActive}>
                                <Typography color={'error'}>
                                  <Trans>Invalidate</Trans>
                                </Typography>
                              </MenuItem>
                            )}
                          </MenuList>
                        </PopperWrap>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </ClickAwayListener>
  );
};
