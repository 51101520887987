import React from 'react';
import { Plan, PlanInterval, PlanLevel } from '@priz/shared/src/models/workspace';
import { Alert, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import CurrencyFormat from 'react-currency-format';

interface CurrentPlanMessageProps {
  currentPlan: Plan;
}

export const CurrentPlanMessage: React.FC<CurrentPlanMessageProps> = ({ currentPlan }) => {
  return (
    <Alert severity="success">
      {currentPlan && currentPlan.level !== PlanLevel.Enterprise ? (
        <Typography variant={'body1'} component={'span'}>
          <Trans>You're currently on the</Trans>{' '}
          <b>
            <Trans>{currentPlan.name}</Trans>
          </b>{' '}
          <Trans>plan for</Trans>{' '}
          <CurrencyFormat
            value={currentPlan.cost}
            displayType={'text'}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true}
          />{' '}
          <Trans>{`per member per ${currentPlan.interval === PlanInterval.Yearly ? 'year' : 'month'}`}</Trans>.{' '}
          <Trans>Discounts may apply if applicable. See invoices for more details.</Trans>
        </Typography>
      ) : (
        <Typography variant={'body1'} component={'span'}>
          <Trans>You're currently on the</Trans>{' '}
          <b>
            <Trans>{currentPlan.name}</Trans>
          </b>{' '}
          <Trans>plan</Trans>.
        </Typography>
      )}
    </Alert>
  );
};
