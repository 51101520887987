import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    ['&:hover $button']: {
      opacity: 1,
    },
  },
  button: {
    opacity: 0.3,
    transition: 'opacity .15s ease',
  },
  relationsBackdrop: {
    position: 'absolute',
    width: 'calc(100% + 10px)',
    height: 'calc(100% + 10px)',
    top: -5,
    right: 'auto',
    bottom: 'auto',
    left: -5,
    borderRadius: 'inherit',
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: '#c300e6',
    boxSizing: 'border-box',
    zIndex: -1,
    pointerEvents: 'none',
  },
  relationsButton: {
    borderRadius: 8,
    color: '#c300e6',
  },
  relationsIcon: {
    display: 'block',
    marginBottom: 1,
  },
  relationsPopper: {
    ['&[data-popper-placement="top"]']: {
      ['& $relationsContent']: {
        flexDirection: 'column-reverse',
      },
      ['& $relationsTabs']: {
        marginBottom: 0,
        marginTop: 16,
      },
    },
  },
  relationsContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  relationsTabs: {
    marginBottom: 16,
  },
});
