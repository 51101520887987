import React, { useState } from 'react';
import { Avatar, Box, Checkbox, Divider, IconButton, SvgIconProps, Typography } from '@mui/material';
import { Attendee, AttendeeStatus } from '../store/model';
import { MeetingStatus } from '@priz/shared/src/models/meetings';
import { useSelector } from 'react-redux';
import { WorkspaceMembersSelectors } from '../../workspace/store/selectors/workspace-members.selectors';
import { UserUtils } from '@priz/shared/src/utils/user/user-utils';
import { useStyles } from './styles';
import {
  CloseRounded,
  HeadphonesOutlined,
  RecordVoiceOverOutlined,
  PersonRounded,
  DoDisturbAltOutlined,
  HelpOutlineOutlined,
} from '@mui/icons-material';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { Trans } from 'react-i18next';
import { CheckboxIcon } from '../../react/elements/checkbox-icon/component';
import { WorkspaceMember } from '@priz/shared/src/models/workspace';

const resolveStatusIcon = (status: AttendeeStatus) => {
  const props: SvgIconProps = {
    fontSize: 'small',
    sx: {
      display: 'block',
    },
  };

  switch (status) {
    case AttendeeStatus.Contributor:
      return <RecordVoiceOverOutlined {...props} color={'success'} />;

    case AttendeeStatus.Observer:
      return <HeadphonesOutlined {...props} color={'primary'} />;

    case AttendeeStatus.Decline:
      return <DoDisturbAltOutlined {...props} color={'error'} />;

    default:
      return <HelpOutlineOutlined {...props} color={'action'} />;
  }
};

export interface PureAttendeeItemProps {
  attendee: Attendee;
  workspaceMember?: WorkspaceMember;
  attended?: boolean;
  showAttendedIcon?: boolean;
  showExplanationPopup?: boolean;
  onAttendedToggle?: () => void;
  onDelete?: () => void;
  disabled?: boolean;
}

export const PureAttendeeItem: React.FC<PureAttendeeItemProps> = ({
  attendee,
  workspaceMember,
  attended,
  showAttendedIcon,
  showExplanationPopup,
  onAttendedToggle,
  onDelete,
  disabled,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {onAttendedToggle && (
        <Box mr={0.25}>
          <Checkbox
            className={styles.control}
            size="small"
            checked={attended}
            onClick={onAttendedToggle}
            disabled={disabled}
          />
        </Box>
      )}

      {showAttendedIcon && (
        <Box mr={0.25}>
          <CheckboxIcon checked={typeof attended !== 'undefined' ? attended : attendee.attended} />
        </Box>
      )}

      <Box mr={1}>
        <Avatar src={workspaceMember?.profile?.avatarUrl} sx={{ width: 24, height: 24 }}>
          {workspaceMember && UserUtils.resolveInitials(workspaceMember)}
          {!workspaceMember && <PersonRounded />}
        </Avatar>
      </Box>

      <Box mr={1} overflow={'hidden'} display={'flex'}>
        <Typography variant={'caption'} lineHeight={1.2} noWrap>
          {workspaceMember ? UserUtils.getShowableName(workspaceMember) : attendee.email}
        </Typography>
      </Box>

      <Box mr={onDelete ? 0.25 : 1}>
        {!showExplanationPopup && resolveStatusIcon(attendee.status)}

        {showExplanationPopup && (
          <PopperWrap openHandler={'hover'} variant={'blue'}>
            {resolveStatusIcon(attendee.status)}

            <Box maxWidth={500}>
              <Typography variant={'subtitle2'} display={'block'}>
                <Trans>Status</Trans>:
              </Typography>

              <Typography variant={'body2'} display={'block'}>
                {attendee.status || <Trans>Awaiting response</Trans>}
              </Typography>

              {[AttendeeStatus.Contributor, AttendeeStatus.Observer].includes(attendee.status) && (
                <>
                  <Box my={1}>
                    <Divider />
                  </Box>

                  <Typography variant={'subtitle2'} display={'block'}>
                    <Trans>Explanation</Trans>:
                  </Typography>

                  <Typography variant={'body2'} display={'block'}>
                    {attendee.explanation}
                  </Typography>
                </>
              )}
            </Box>
          </PopperWrap>
        )}
      </Box>

      {onDelete && (
        <IconButton className={styles.control} size="medium" onClick={onDelete} disabled={disabled}>
          <CloseRounded fontSize="small" />
        </IconButton>
      )}
    </div>
  );
};

export interface AttendeeItemHandlers {
  onAttendeeUpdate?: (attendeeId: number, attended: boolean) => void;
  onAttendeeDelete?: (attendeeId: number) => void;
}

interface AttendeeItemProps extends AttendeeItemHandlers {
  attendee: Attendee;
  meetingStatus: MeetingStatus;
  disabled?: boolean;
  editable?: boolean;
  viewMode?: boolean;
}

export const AttendeeItem: React.FC<AttendeeItemProps> = ({
  attendee,
  meetingStatus,
  onAttendeeUpdate,
  onAttendeeDelete,
  editable,
  disabled,
  viewMode,
}) => {
  const meetingIsActive = meetingStatus === MeetingStatus.Active;
  const [isAttended, setIdAttended] = useState(!!attendee.attended);

  const user = useSelector(WorkspaceMembersSelectors.getById(attendee?.user?.id));

  const handleDelete = () => {
    if (onAttendeeDelete) onAttendeeDelete(attendee.id);
  };

  const handleUpdate = () => {
    setIdAttended(!isAttended);
    if (onAttendeeUpdate) onAttendeeUpdate(attendee.id, !isAttended);
  };

  return (
    <PureAttendeeItem
      attendee={attendee}
      workspaceMember={user}
      attended={isAttended}
      showAttendedIcon={viewMode}
      showExplanationPopup={!viewMode}
      onAttendedToggle={editable && meetingIsActive && handleUpdate}
      onDelete={editable && !meetingIsActive && handleDelete}
      disabled={disabled}
    />
  );
};
