import React, { useState } from 'react';
import { WorkspaceMember, WorkspaceMembershipLevel } from '@priz/shared/src/models/workspace';
import { Chip, Grid, IconButton, MenuItem, Select, SelectChangeEvent, TableCell } from '@mui/material';
import { WorkspaceMembersActions } from '../store/actions';
import { Trans } from 'react-i18next';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { useDispatch, useSelector } from 'react-redux';
import { WorkspaceMembersSelectors } from '../store/selectors/workspace-members.selectors';
import { useStyles } from './styles';
import { UserContextSelectors } from '../../security/store/selectors';
import { UserStatus } from '@priz/shared/src/models/security/user-status.enum';

import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';

interface WorkspaceMemberItemProps {
  member: WorkspaceMember;
}

export const WorkspaceMemberItem: React.FC<WorkspaceMemberItemProps> = ({ member }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [selectedMemberForDeletion, setSelectedMemberForDeletion] = useState<WorkspaceMember>(null);
  const isRemoving = useSelector(WorkspaceMembersSelectors.isRemoving);
  const currentUser = useSelector(UserContextSelectors.getCurrentUser);

  const disabled = member.id === currentUser.id;

  const openDeleteConfirmation = (member: WorkspaceMember) => {
    setSelectedMemberForDeletion(member);
  };

  const closeDeleteConfirmation = () => {
    setSelectedMemberForDeletion(null);
  };

  const deleteMember = () => {
    dispatch(WorkspaceMembersActions.remove(selectedMemberForDeletion.email));
    closeDeleteConfirmation();
  };

  const isWorkspaceAdmin = (member: WorkspaceMember) => {
    return member.membershipLevel === WorkspaceMembershipLevel.Admin;
  };

  // TODO: check
  const handleMembershipLevelChange = (event: SelectChangeEvent) => {
    dispatch(WorkspaceMembersActions.updateMembershipLevel(member.email, event.target.value));
  };

  return (
    <>
      <TableCell>{member.profile.firstName}</TableCell>
      <TableCell>{member.profile.lastName}</TableCell>
      <TableCell>{member.email}</TableCell>

      <TableCell>
        <Select
          value={member.membershipLevel}
          onChange={handleMembershipLevelChange}
          disabled={disabled}
          size={'small'}
        >
          <MenuItem value={WorkspaceMembershipLevel.Admin}>
            <Trans>{WorkspaceMembershipLevel.Admin}</Trans>
          </MenuItem>
          <MenuItem value={WorkspaceMembershipLevel.Member}>
            <Trans>{WorkspaceMembershipLevel.Member}</Trans>
          </MenuItem>
        </Select>
      </TableCell>

      <TableCell>
        <Grid container spacing={1}>
          <Grid item>
            <Chip
              label={<Trans>{member.status}</Trans>}
              size={'small'}
              color={member.status === UserStatus.Active ? 'success' : 'default'}
            />
          </Grid>

          {member.nonChargeable && (
            <Grid item>
              <Chip label={<Trans>non-chargeable</Trans>} size={'small'} color={'primary'} />
            </Grid>
          )}
        </Grid>
      </TableCell>

      <TableCell width={'3%'}>
        <IconButton
          onClick={() => {
            if (!isWorkspaceAdmin(member)) openDeleteConfirmation(member);
          }}
          disabled={isRemoving || isWorkspaceAdmin(member)}
        >
          <TrashIcon className={styles.controlIcon} />
        </IconButton>
      </TableCell>

      <PgConfirmationDialog
        isOpen={!!selectedMemberForDeletion}
        confirmTitle={<Trans>Confirm delete</Trans>}
        onConfirm={deleteMember}
        onClose={closeDeleteConfirmation}
        disabled={isRemoving}
      />
    </>
  );
};
