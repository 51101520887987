import { TFunction } from 'i18next';
import { DiagramDataVersions, Version, Versions } from '../../models/tools';
import { LocalStorageKey, LocalStorageService } from '../../services/local-storage';

const getVersionTitle = (diagramData: DiagramDataVersions, versionId: string): string => {
  return diagramData?.versions?.[versionId]?.title || '';
};

const getVersionsTitlesMap = (diagramData: DiagramDataVersions, t: TFunction): { [key: string]: string } => {
  const versions = diagramData?.versions || {};
  const orderedVersionsKeys = getOrderedVersionsKeys(versions);

  return orderedVersionsKeys.reduce((map: { [key: string]: string }, versionKey, index) => {
    const version = versions[versionKey];
    map[versionKey] = version?.title?.length ? version?.title : `${t('Version')} ${index + 1}`;
    return map;
  }, {});
};

const getOrderedVersionsKeys = (versions: Versions): string[] => {
  return Object.keys(versions).sort((a, b) => {
    return versions[a].index - versions[b].index;
  });
};

const getOrderedVersions = <V extends Version>(versions: Versions<V>): V[] => {
  return Object.values(versions).sort((a, b) => {
    return a.index - b.index;
  });
};

const resolveLastViewedToolVersion = (diagramData: DiagramDataVersions): string | undefined => {
  const lsLastViewedVersion = LocalStorageService.getItem(LocalStorageKey.LastViewedToolVersion);

  let lsVersion: string;

  if (lsLastViewedVersion) {
    const versionsIds = Object.keys(diagramData.versions || {});
    const allVersions = [diagramData.versionId, ...versionsIds];

    if (allVersions.includes(lsLastViewedVersion)) {
      LocalStorageService.removeItem(LocalStorageKey.LastViewedToolVersion);
      lsVersion = lsLastViewedVersion;
    }
  }

  return lsVersion;
};

export const ToolVersionUtils = {
  getVersionTitle,
  getVersionsTitlesMap,
  getOrderedVersionsKeys,
  getOrderedVersions,
  resolveLastViewedToolVersion,
};
