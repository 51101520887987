import { ActionTypeStatus, EntityCollectionStatus, EntityStatusId } from '../../models/common/entity-collection-state';
import { copyObject } from '../../utils/common';

interface IdsUpdate {
  target: keyof Pick<
    EntityCollectionStatus,
    | 'createdIds'
    | 'removingIds'
    | 'removedIds'
    | 'updatingIds'
    | 'updatedIds'
    | 'loadedIds'
    | 'loadingIds'
    | 'savingIds'
    | 'savedIds'
    | 'errorIds'
  >;
  method: 'add' | 'remove' | 'set';
  ids: EntityStatusId[];
}

interface ActionUpdate {
  type: string;
  statuses: ActionTypeStatus;
}

export interface SharedUpdateStatusesProps extends EntityCollectionStatus {
  idsUpdate?: IdsUpdate[];
  actionUpdate?: ActionUpdate;
}

interface UpdateStatusesProps extends SharedUpdateStatusesProps {
  collection: EntityCollectionStatus;
}

const updateStatusesCollection = (props: UpdateStatusesProps): EntityCollectionStatus => {
  const { collection, idsUpdate, actionUpdate, ...rest } = props;

  const collectionCopy = copyObject({
    ...collection,
    ...rest,
  });

  if (idsUpdate) {
    idsUpdate.forEach(update => {
      if (update.method === 'add') {
        collectionCopy[update.target] = [...(collectionCopy[update.target] || []), ...update.ids];
      }
      if (update.method === 'remove') {
        collectionCopy[update.target] = [...(collectionCopy[update.target] || [])].filter(
          id => !update.ids.includes(id),
        );
      }
      if (update.method === 'set') {
        collectionCopy[update.target] = update.ids;
      }
    });
  }

  if (actionUpdate) {
    if (!collectionCopy.actionTypes) {
      collectionCopy.actionTypes = {};
    }

    collectionCopy.actionTypes[actionUpdate.type] = {
      ...(collectionCopy.actionTypes[actionUpdate.type] || {}),
      ...actionUpdate.statuses,
    };
  }

  return collectionCopy;
};

type StateWithStatuses = { statuses: EntityCollectionStatus };

const setStatuses = <I extends StateWithStatuses>(state: I, statuses: Partial<EntityCollectionStatus>): I => {
  return {
    ...state,
    statuses: {
      ...state.statuses,
      ...statuses,
    },
  };
};

type ByProjectIdStatusesMap = { [projectId: number]: EntityCollectionStatus };
type ByProjectIdStatusesObject = { byProjectId: ByProjectIdStatusesMap };
type StateWithByProjectIdStatuses = { statuses: ByProjectIdStatusesObject };

const resolveStatusesByProjectId = <I extends ByProjectIdStatusesObject>(
  stateStatuses: I,
  projectId: number,
  statuses: Partial<EntityCollectionStatus>,
): I => {
  const statusesCopy = copyObject(stateStatuses);

  statusesCopy.byProjectId[projectId] = {
    ...(statusesCopy.byProjectId[projectId] || {}),
    ...(statuses || {}),
  };

  return statusesCopy;
};

const setStatusesByProjectId = <I extends StateWithByProjectIdStatuses>(
  state: I,
  projectId: number,
  statuses: Partial<EntityCollectionStatus>,
): I => {
  return {
    ...state,
    statuses: resolveStatusesByProjectId(state.statuses, projectId, statuses),
  };
};

type ByUtilizationIdStatusesMap = { [utilizationId: number]: EntityCollectionStatus };
type ByUtilizationIdStatusesObject = { byUtilizationId: ByUtilizationIdStatusesMap };
type StateWithByUtilizationIdStatuses = { statuses: ByUtilizationIdStatusesObject };

const resolveStatusesByUtilizationId = <I extends ByUtilizationIdStatusesObject>(
  stateStatuses: I,
  utilizationId: number,
  statuses: Partial<EntityCollectionStatus>,
): ByUtilizationIdStatusesObject => {
  const statusesCopy = copyObject(stateStatuses);

  statusesCopy.byUtilizationId[utilizationId] = {
    ...(statusesCopy.byUtilizationId[utilizationId] || {}),
    ...(statuses || {}),
  };

  return statusesCopy;
};

const setStatusesByUtilizationId = <I extends StateWithByUtilizationIdStatuses>(
  state: I,
  utilizationId: number,
  statuses: Partial<EntityCollectionStatus>,
): I => {
  return {
    ...state,
    statuses: resolveStatusesByUtilizationId(state.statuses, utilizationId, statuses),
  };
};

type ByUserIdStatusesMap = { [userId: number]: EntityCollectionStatus };
type ByUserIdStatusesObject = { byUserId: ByUserIdStatusesMap };
type StateWithByUserIdStatuses = { statuses: ByUserIdStatusesObject };

const resolveStatusesByUserId = <I extends ByUserIdStatusesObject>(
  stateStatuses: I,
  userId: number,
  statuses: Partial<EntityCollectionStatus>,
): ByUserIdStatusesObject => {
  const statusesCopy = copyObject(stateStatuses);

  statusesCopy.byUserId[userId] = {
    ...(statusesCopy.byUserId[userId] || {}),
    ...(statuses || {}),
  };

  return statusesCopy;
};

const setStatusesByUserId = <I extends StateWithByUserIdStatuses>(
  state: I,
  userId: number,
  statuses: Partial<EntityCollectionStatus>,
): I => {
  return {
    ...state,
    statuses: resolveStatusesByUserId(state.statuses, userId, statuses),
  };
};

export const CollectionStatusesService = {
  updateStatusesCollection,
  setStatuses,
  setStatusesByProjectId,
  setStatusesByUtilizationId,
  setStatusesByUserId,
  resolveStatusesByProjectId,
  resolveStatusesByUtilizationId,
  resolveStatusesByUserId,
};
