import React, { ReactNode } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useStyles } from './styles';
import { Locales } from '@priz/shared/src/models/locales';
import { LocaleButton } from '@priz/shared/src/components/locales/locale-button/component';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';

import { ReactComponent as PgLogo } from '../../../assets/img/light-theme-logo.svg';

export interface AppPublicNavbarProps {
  loginButton?: boolean;
  localeButtons?: boolean;
  redirectUrl?: string;
  content?: ReactNode;
}

export const AppPublicNavbar: React.FC<AppPublicNavbarProps> = ({
  loginButton,
  redirectUrl,
  content,
  localeButtons = true,
}) => {
  const styles = useStyles();
  const navigate = useNavigate();

  const loginClickHandler = () => {
    if (redirectUrl) {
      LocalStorageService.setItem(LocalStorageKey.AuthCallbackRedirect, redirectUrl);
    }

    navigate('/login');
  };

  return (
    <Box className={styles.header} py={{ xs: 1, sm: 2 }} px={{ xs: 2, sm: 3, md: 5 }} width={'100%'}>
      <Grid container spacing={2} justifyContent={'flex-end'} alignItems={'center'}>
        <Grid item marginRight={'auto'}>
          <a className={styles.logoContainer} href={'/'}>
            <PgLogo className={styles.logo} />
          </a>
        </Grid>

        {content && <Grid item>{content}</Grid>}

        {localeButtons && (
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <LocaleButton locale={Locales.EN} />
              </Grid>
              <Grid item>
                <LocaleButton locale={Locales.ES} />
              </Grid>
              <Grid item>
                <LocaleButton locale={Locales.ZH} />
              </Grid>
            </Grid>
          </Grid>
        )}

        {loginButton && (
          <Grid item>
            <Button variant={'pg_rounded'} color={'pg_orange_solid'} size={'pg_small'} onClick={loginClickHandler}>
              <Trans>Login</Trans>
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
