import { NodeProps } from '../../vis-network';
import { SfmEdgeProps } from './edges';

export enum SfmNodeType {
  PRODUCT = 'PRODUCT',
  COMPONENT = 'COMPONENT',
  SUPER_SYSTEM = 'SUPER_SYSTEM',
}

export interface SfmNodeProps extends NodeProps {
  type: SfmNodeType;
  x?: number;
  y?: number;
}

export interface SfmNodesMap {
  [id: string]: SfmNodeProps;
}

export interface SfmEdgesMap {
  [id: string]: SfmEdgeProps;
}

export type SfmNodes = SfmNodeProps[];
