import React, { useEffect, useState } from 'react';
import { Controller, UseFormTrigger } from 'react-hook-form';
import { FieldTitle, HelperText } from '@priz/shared/src/components/form-elements';
import { ValidatorControllerProps, ReactHookFormElement, DefaultInput } from '@priz/shared/src/models/form';
import { Box } from '@mui/material';
import { UseFormClearErrors } from 'react-hook-form/dist/types/form';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import { DateAdapterType, DateTimePickerButton } from '../../../date-time-picker-button/component';
import moment from 'moment-timezone';

type ReactHookFormDateButtonProps = ReactHookFormElement &
  DefaultInput &
  ValidatorControllerProps & {
    clearErrors: UseFormClearErrors<any>;
    trigger: UseFormTrigger<any>;
    onChange?: (date: Date) => void;
    validatePastDate?: boolean;
    selectTime?: boolean;
    adapter?: DateAdapterType;
  };

export const ReactHookFormDateButton: React.FC<ReactHookFormDateButtonProps> = ({
  name,
  control,
  placeholder,
  fieldTitle,
  fieldTitleWrapperProps,
  helperText,
  wrapperProps,
  rules,
  disabled,
  clearErrors,
  trigger,
  onChange,
  validatePastDate = true,
  selectTime,
  adapter,
}) => {
  const [errorText, setErrorText] = useState<string | null | undefined>(undefined);

  const errorHandler = (error: DateValidationError) => {
    if (validatePastDate) setErrorText(error ? 'Invalid Date' : null);
  };

  useEffect(() => {
    if (typeof errorText !== 'undefined') {
      trigger(name).then(isValid => {
        if (isValid) {
          clearErrors(name);
        }
      });
    }
  }, [errorText]);

  return (
    <Box mb={3} {...wrapperProps}>
      <FieldTitle text={fieldTitle} {...fieldTitleWrapperProps} />

      <Controller
        name={name}
        control={control}
        rules={{
          ...rules,
          validate: {
            ...rules?.validate,
            hasNotCustomErrors: () => errorText || true,
          },
        }}
        render={({ field: { onChange: fieldOnChange, value }, fieldState: { error } }) => {
          const filedValue = value && adapter === DateAdapterType.Moment ? moment(value) : value;

          return (
            <>
              <DateTimePickerButton
                value={filedValue}
                onChange={date => {
                  fieldOnChange(date);
                  if (onChange) onChange(date);
                }}
                onError={errorHandler}
                disabled={disabled}
                placeholder={placeholder}
                selectTime={selectTime}
                adapter={adapter}
              />

              <HelperText error={error?.message} text={helperText} />
            </>
          );
        }}
      />
    </Box>
  );
};
