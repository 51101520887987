import cloneDeep from 'lodash/cloneDeep';
import { handleActions } from 'redux-actions/es';
import { IRank, Rank } from '@priz/shared/src/models/tools/rrm';
import {
  DefaultUtilizationCollection,
  UtilizationCollection,
  UtilizationCollectionStatuses,
} from '@priz/shared/src/models/project';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { RrmUtilization } from '@priz/shared/src/models/tools/rrm/rrm-utilization';
import { RrmUtilizationService } from '../../services/rrm-utilization.service';
import { RrmUtilizationAction, RrmUtilizationActionType } from '../actions/rrm-utilization.actions';
import { StartRankingResult, UpdateRankResult } from '../../services';
import { resolveUtilizationCollectionStatuses } from '../../../project/store/reducers/tool-utilizations.reducers';

const findRankById = (ranks: Rank[], rankId: number): Rank => ranks.find(r => r.id === rankId);

const setRanksInUtilization = (
  state: UtilizationCollection,
  action: RrmUtilizationAction,
  statuses?: Partial<UtilizationCollectionStatuses>,
): UtilizationCollection => {
  const payload = action.payload as StartRankingResult;
  const utilizationState = cloneDeep(state.entities[action.meta.utilizationId]) as RrmUtilization;

  utilizationState.ranks = payload.ranks.map(rankPayload => new Rank(rankPayload));
  utilizationState.percentageComplete = RrmUtilizationService.calculatePercentageComplete(utilizationState);
  utilizationState.smart = action.meta.smart || false;

  const entitiesCopy = { ...state.entities, [action.meta.utilizationId]: utilizationState };

  return {
    ...state,
    entities: entitiesCopy,
    statuses: {
      ...state.statuses,
      ...(statuses || {}),
    },
  };
};

const calculateAndSetUtilizationStatus = (
  state: UtilizationCollection,
  action: RrmUtilizationAction,
): UtilizationCollection => {
  if (action.meta.percentageComplete < 100) {
    return state;
  }

  const utilizationState = cloneDeep(state.entities[action.meta.utilizationId]) as RrmUtilization;

  utilizationState.percentageComplete = RrmUtilizationService.calculatePercentageComplete(utilizationState);

  const entitiesCopy = { ...state.entities, [action.meta.utilizationId]: utilizationState };

  return {
    ...state,
    entities: entitiesCopy,
  };
};

export const rrmUtilizationReducers = handleActions(
  {
    // RankRecord

    [toSuccess(RrmUtilizationActionType.RankRecord)]: (
      state: UtilizationCollection,
      action: RrmUtilizationAction,
    ): UtilizationCollection => {
      const utilizationState = cloneDeep(state.entities[action.meta.utilizationId]) as RrmUtilization;
      const payload = action.payload as UpdateRankResult;
      const updatedRank = new Rank(payload.updatedRank);

      const rankToUpdate = findRankById(utilizationState.ranks, updatedRank.id);
      const index = utilizationState.ranks.indexOf(rankToUpdate);
      utilizationState.ranks[index] = updatedRank;

      if (action.meta.smart && payload.newRank) {
        utilizationState.ranks.push(payload.newRank);
      }

      const entitiesCopy = { ...state.entities, [action.meta.utilizationId]: utilizationState };

      return {
        ...state,
        entities: entitiesCopy,
      };
    },

    // SkipSmart

    [toSuccess(RrmUtilizationActionType.SkipSmart)]: (
      state: UtilizationCollection,
      action: RrmUtilizationAction,
    ): UtilizationCollection => {
      const utilizationState = cloneDeep(state.entities[action.meta.utilizationId]) as RrmUtilization;
      const payload = action.payload as IRank;
      const updatedRank = new Rank(payload);

      const rankToUpdate = findRankById(utilizationState.ranks, updatedRank.id);
      const index = utilizationState.ranks.indexOf(rankToUpdate);
      utilizationState.ranks[index] = updatedRank;

      const entitiesCopy = { ...state.entities, [action.meta.utilizationId]: utilizationState };

      return {
        ...state,
        entities: entitiesCopy,
      };
    },

    // UtilizationSetState

    [toStarted(RrmUtilizationActionType.UtilizationSetState)]: (
      state: UtilizationCollection,
      action: RrmUtilizationAction,
    ) => calculateAndSetUtilizationStatus(state, action),

    // StartRanking

    [toStarted(RrmUtilizationActionType.StartRanking)]: (
      state: UtilizationCollection,
      action: RrmUtilizationAction,
    ): UtilizationCollection => {
      return {
        ...state,
        statuses: resolveUtilizationCollectionStatuses({
          state,
          meta: action.meta,
          globalStatuses: {
            loading: true,
            idsUpdate: [{ target: 'loadingIds', method: 'add', ids: [action.meta.utilizationId] }],
          },
        }),
      };
    },
    [toFailed(RrmUtilizationActionType.StartRanking)]: (
      state: UtilizationCollection,
      action: RrmUtilizationAction,
    ): UtilizationCollection => {
      return {
        ...state,
        statuses: resolveUtilizationCollectionStatuses({
          state,
          meta: action.meta,
          globalStatuses: {
            error: true,
            loading: false,
            idsUpdate: [{ target: 'loadingIds', method: 'remove', ids: [action.meta.utilizationId] }],
          },
        }),
      };
    },
    [toSuccess(RrmUtilizationActionType.StartRanking)]: (
      state: UtilizationCollection,
      action: RrmUtilizationAction,
    ): UtilizationCollection => {
      return setRanksInUtilization(
        state,
        action,
        resolveUtilizationCollectionStatuses({
          state,
          meta: action.meta,
          globalStatuses: {
            error: false,
            loading: false,
            idsUpdate: [{ target: 'loadingIds', method: 'remove', ids: [action.meta.utilizationId] }],
          },
        }),
      );
    },
  },
  DefaultUtilizationCollection,
);
