import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { useStyles } from './styles';
import { Box, Typography } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { ReactHookFormQuill } from '../../react/form-elements';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import { Project } from '@priz/shared/src/models/project';
import { ProjectSelector } from '../store/selectors';
import { ProjectActions } from '../store/actions/project.actions';
import { ProjectFlowService } from '../services';
import { fixQuillEmptyValue } from '@priz/shared/src/utils/form';
import { AttachmentsLoader } from '../../attachments/attachments-loader/component';
import { AttachmentType } from '../../attachments/store/model';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';

export interface ProjectSolutionProps {
  projectId: number;
}

export const ProjectSolution: React.FC<ProjectSolutionProps> = ({ projectId }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const project: Project = useSelector(ProjectSelector.getById(projectId));
  const isProjectLocked = ProjectFlowService.isProjectLocked(project);

  const {
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { isDirty },
  } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      solution: '',
    },
  });

  const watchValuesChange = useWatch({ control });

  useEffect(() => {
    if (!isDirty && project?.solution) {
      reset({
        solution: project.solution,
      });
    }
  }, [project]);

  useEffect(() => {
    if (isDirty) {
      handleSubmit(onTextUpdate)();
    }
  }, [watchValuesChange]);

  const onTextUpdate = useCallback(
    debounce(() => {
      dispatch(ProjectActions.updateSolution(projectId, fixQuillEmptyValue(getValues().solution)));
    }, 500),
    [],
  );

  if (!project) return null;

  return (
    <>
      <ContentContainer>
        <PageTitleWithDocLink title={<Trans>Propose solution</Trans>} />

        <Box className={styles.root}>
          <form onSubmit={e => e.preventDefault()}>
            <ReactHookFormQuill
              name={'solution'}
              placeholder={'Describe the solution you created for the problem you were working on'}
              control={control}
              readOnly={isProjectLocked}
            />
          </form>

          <Box mb={2}>
            <Typography variant={'h6'}>
              <Trans>Attachments</Trans>
            </Typography>
          </Box>

          <AttachmentsLoader projectId={projectId} type={AttachmentType.Solution} disabled={isProjectLocked} />
        </Box>
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={projectId}
          workingFlowProps={{
            backVariant: 'ideas',
            nextVariant: 'report',
            hideIdeaButton: true,
          }}
        />
      </ContentFooter>
    </>
  );
};
