import * as React from "react";

export const ReactComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="#08445E" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M56.9546 50.7996H53.6311L49.6528 26.9291C48.654 20.937 43.5201 16.5879 37.4453 16.5879H22.5547C16.4799 16.5879 11.346 20.937 10.3472 26.9291L6.36894 50.7996H3.04536C1.36613 50.7996 0 52.1657 0 53.8449V55.9183C0 57.5976 1.36613 58.9637 3.04536 58.9637H56.9546C58.6339 58.9637 60 57.5976 60 55.9183V53.8449C60 52.1657 58.6339 50.7996 56.9546 50.7996ZM12.2646 27.2487C13.1066 22.1977 17.4341 18.5317 22.5547 18.5317H37.4453C42.5659 18.5317 46.8934 22.1978 47.7352 27.2487L51.6604 50.7996H30.9719V41.262C33.9058 40.7946 36.1555 38.2474 36.1555 35.184C36.1555 31.7899 33.3941 29.0285 30 29.0285C26.6059 29.0285 23.8445 31.7898 23.8445 35.184C23.8445 38.2474 26.0942 40.7946 29.0281 41.262V50.7996H8.33961L12.2646 27.2487ZM30 39.3957C27.6776 39.3957 25.7883 37.5062 25.7883 35.184C25.7883 32.8618 27.6776 30.9723 30 30.9723C32.3224 30.9723 34.2117 32.8618 34.2117 35.184C34.2117 37.5062 32.3224 39.3957 30 39.3957ZM58.0562 55.9183C58.0562 56.5257 57.562 57.0199 56.9546 57.0199H3.04536C2.43797 57.0199 1.94384 56.5257 1.94384 55.9183V53.8449C1.94384 53.2375 2.43797 52.7434 3.04536 52.7434H56.9546C57.562 52.7434 58.0562 53.2375 58.0562 53.8449V55.9183Z" />
    <path d="M0.971922 36.1558H6.15551C6.6924 36.1558 7.12743 35.7206 7.12743 35.1838C7.12743 34.6471 6.6924 34.2119 6.15551 34.2119H0.971922C0.435032 34.2119 0 34.6471 0 35.1838C0 35.7206 0.435032 36.1558 0.971922 36.1558Z" />
    <path d="M52.873 35.1838C52.873 35.7206 53.3081 36.1558 53.845 36.1558H59.028C59.5649 36.1558 60 35.7206 60 35.1838C60 34.6471 59.5649 34.2119 59.028 34.2119H53.845C53.3081 34.2119 52.873 34.6469 52.873 35.1838Z" />
    <path d="M30.0002 14.3848C30.5371 14.3848 30.9722 13.9497 30.9722 13.4129V2.00903C30.9722 1.47227 30.5371 1.03711 30.0002 1.03711C29.4634 1.03711 29.0283 1.47227 29.0283 2.00903V13.4129C29.0283 13.9497 29.4634 14.3848 30.0002 14.3848Z" />
    <path d="M11.6883 18.2474C11.8781 18.4371 12.1269 18.5321 12.3756 18.5321C12.6243 18.5321 12.8731 18.4372 13.0629 18.2474C13.4425 17.8678 13.4425 17.2524 13.0629 16.8729L8.91607 12.7261C8.53637 12.3465 7.92108 12.3465 7.54151 12.7261C7.16194 13.1056 7.16194 13.7211 7.54151 14.1005L11.6883 18.2474Z" />
    <path d="M47.6242 18.5321C47.8729 18.5321 48.1217 18.4372 48.3116 18.2474L52.4584 14.1005C52.838 13.7209 52.838 13.1055 52.4584 12.7261C52.0788 12.3465 51.4635 12.3465 51.0839 12.7261L46.937 16.8729C46.5575 17.2525 46.5575 17.8679 46.937 18.2474C47.1267 18.4371 47.3756 18.5321 47.6242 18.5321Z" />
    <path d="M51.502 26.2783C51.657 26.6525 52.0188 26.8786 52.4004 26.8786C52.5242 26.8786 52.6503 26.8547 52.7719 26.8043L55.2748 25.7676C55.7707 25.5622 56.0063 24.9937 55.8008 24.4977C55.5954 24.0019 55.0267 23.7662 54.5309 23.9717L52.028 25.0084C51.532 25.2138 51.2965 25.7824 51.502 26.2783Z" />
    <path d="M20.1904 14.0404C20.3454 14.4147 20.7072 14.6407 21.0887 14.6407C21.2126 14.6407 21.3387 14.6169 21.4602 14.5665C21.9562 14.3611 22.1918 13.7925 21.9862 13.2966L20.583 9.90887C20.3776 9.41306 19.8091 9.17733 19.3132 9.38286C18.8172 9.58826 18.5816 10.1569 18.7872 10.6527L20.1904 14.0404Z" />
    <path d="M38.5397 14.5663C38.6612 14.6167 38.7872 14.6406 38.9112 14.6406C39.2926 14.6406 39.6544 14.4146 39.8095 14.0403L41.2127 10.6526C41.4183 10.1566 41.1828 9.58812 40.6867 9.38272C40.1913 9.17758 39.6223 9.41278 39.4169 9.90872L38.0137 13.2965C37.8083 13.7924 38.0438 14.3609 38.5397 14.5663Z" />
    <path d="M4.71694 25.787L7.17098 26.8035C7.29253 26.8539 7.4185 26.8778 7.54251 26.8778C7.9239 26.8778 8.28571 26.6518 8.44083 26.2775C8.64636 25.7816 8.41089 25.2131 7.91482 25.0077L5.46078 23.9912C4.96523 23.7859 4.39633 24.0212 4.19093 24.5172C3.98553 25.0131 4.221 25.5816 4.71694 25.787Z" />
  </svg>
);
