import React, { useState, useCallback, ChangeEvent } from 'react';
import debounce from 'lodash/debounce';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PerceptionTextFieldProps {
  value?: string;
  onSave?: (text: string) => void;
  disabled?: boolean;
}

export const PerceptionTextField: React.FC<PerceptionTextFieldProps> = ({ value, onSave, disabled }) => {
  const { t } = useTranslation();
  const [text, setText] = useState(value || '');

  const updateText = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;

    setText(value);
    changeHandler(value);
  };

  const changeHandler = useCallback(
    debounce((text: string) => {
      if (onSave) onSave(text);
    }, 500),
    [],
  );

  return (
    <TextField
      fullWidth
      multiline
      minRows={1}
      maxRows={5}
      placeholder={t('Description')}
      value={text}
      onChange={updateText}
      disabled={disabled}
      size={'small'}
    />
  );
};
