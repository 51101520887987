import { createTheme } from '@mui/material';
import { pgColorScheme, pgTheme as SharedPgTheme } from '@priz/shared/src/theme';

export const theme = createTheme({
  ...SharedPgTheme,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1600,
    },
  },
});

export const pgTheme = createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          'whiteSpace': 'nowrap',

          '&.Mui-disabled': {
            background: 'rgba(0,0,0,.12)',
          },
        },
      },
    },

    MuiBadge: {
      styleOverrides: {
        dot: {
          width: 8,
          height: 8,
          border: 'none',
          boxShadow: `0 0 0 3px ${pgColorScheme.darkGray}`,
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('md')]: {
            minHeight: 64,
          },
          [theme.breakpoints.down('md')]: {
            minHeight: 56,
          },
          [theme.breakpoints.down('sm')]: {
            minHeight: 48,
          },
        },
      },
    },
  },
});
