import React, { useEffect } from 'react';
import { TableRow, TableCell, TableContainer, TableBody, Table, TableHead, Paper, Alert } from '@mui/material';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PartnershipActions } from '../store/actions';
import { PartnershipSelectors } from '../store/selectors';
import { useStyles } from './styles';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';

interface ReferralWorkspacesProps {
  referralId: number;
}

export const ReferralWorkspaces: React.FC<ReferralWorkspacesProps> = ({ referralId }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const planLevels = useSelector(PartnershipSelectors.getPlanLevelsByReferralId(referralId));
  const isLoading = useSelector(PartnershipSelectors.arePlanLevelsLoadingByReferralId(referralId));
  const isLoaded = useSelector(PartnershipSelectors.arePlanLevelsLoadedByReferralId(referralId));

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      dispatch(PartnershipActions.loadReferralsPlanLevels(referralId));
    }
  }, [referralId, isLoading, isLoaded]);

  if (!isLoaded) {
    return (
      <LoadingOverlay
        loading={true}
        backdropStyles={{ backgroundColor: 'transparent', position: 'relative' }}
        progressContainerProps={{ py: 2 }}
      />
    );
  }

  if (isLoaded && !planLevels?.length) {
    return (
      <Alert severity={'info'}>
        <Trans>Referral has no workspaces yet</Trans>
      </Alert>
    );
  }

  return (
    <TableContainer component={Paper} variant={'outlined'}>
      <Table size={'small'}>
        <TableHead className={styles.tableHead}>
          <TableRow>
            <TableCell>
              <Trans>Plan level</Trans>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {planLevels.map((planLevel, key) => (
            <TableRow key={key}>
              <TableCell>{planLevel}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
