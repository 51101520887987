import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 'auto',
    bottom: 'auto',
    width: '100px',
    height: '100px',
    boxSizing: 'border-box',
    transformOrigin: '0 0',
    pointerEvents: 'none',
  },
  nodeTextArea: {
    'width': '100%',
    'height': '60px',
    'border': 0,
    'resize': 'none',
    'boxSizing': 'border-box',
    'textAlign': 'center',
    'background': 'transparent',
    'outline': 'none',
    'fontFamily': '"Roboto", sans-serif',
    'fontSize': 14,
    'lineHeight': '1',
    'letterSpacing': 0,
    'overflow': 'hidden',
    'pointerEvents': 'auto',

    '&::placeholder': {
      color: pgColorScheme.placeholder,
    },
  },
  textAreaLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  panelContainer: {
    position: 'absolute',
    pointerEvents: 'auto',
  },
  topPanelContainer: {
    top: 'auto',
    bottom: '100%',
    left: 'auto',
    right: 0,
  },
  rightPanelContainer: {
    top: 0,
    bottom: 'auto',
    left: '100%',
    right: 'auto',
  },
  bottomPanelContainer: {
    top: '100%',
    bottom: 'auto',
    left: 'auto',
    right: 0,
  },
  leftPanelContainer: {
    top: 0,
    bottom: 'auto',
    left: 'auto',
    right: '100%',
  },
});
