import React, { useEffect, useState } from 'react';
import { Idea } from '@priz/shared/src/models/idea';
import { useForm, useWatch } from 'react-hook-form';
import { IdeasCommentsActions, IdeasCommentsSelectors } from '../store';
import { IdeaComment, IdeaParameterType } from '@priz/shared/src/models/idea';
import { Alert, Box, Button, Chip, Grid, Portal, Tab, Tabs, Typography } from '@mui/material';
import { ReactHookFormText } from '@priz/shared/src/components/form-elements';
import { ReactHookFormSlider } from '../../react/form-elements';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteForeverOutlined } from '@mui/icons-material';
import { CreateIdeaCommentCommand, UpdateIdeaCommentCommand } from '../services/idea-comments-api';
import { resolveValueByColorType, ValueColorType } from '@priz/shared/src/utils/common';
import { LoadingButton } from '@mui/lab';
import { Assistant } from '../../assistant/component';
import { AssistanceType } from '../../assistant/store/model';
import { HintRowType } from '../../assistant/hint-content/component';
import { useProjectDataCompleteness } from '../../assistant/utils';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { QuillContentViewer } from '@priz/shared/src/components/quill-content-viewer/component';
import { ScrollContainer } from '../../content-containers/scroll-container/component';
import { AssistantSelectors } from '../../assistant/store/selectors';
import { AssistantActions } from '../../assistant/store/actions';

interface IdeaCommentFormProps {
  idea: Idea;
  comment?: IdeaComment;
  controlsContainer?: HTMLDivElement;
  onCreate?: () => void;
  onUpdate?: () => void;
  onDelete?: () => void;
  disabled?: boolean;
}

const colorsMap: { [key in ValueColorType]: 'success' | 'primary' | 'error' } = {
  [ValueColorType.Green]: 'success',
  [ValueColorType.Blue]: 'primary',
  [ValueColorType.Red]: 'error',
};

interface FormData {
  gainDescription: string;
  gainValue: number;
  costDescription: string;
  costValue: number;
  riskDescription: string;
  riskValue: number;
}

const resolveFormData = (comment?: IdeaComment): FormData => {
  return {
    gainDescription: comment?.gainDescription,
    gainValue: comment?.gainValue || null,

    costDescription: comment?.costDescription,
    costValue: comment?.costValue || null,

    riskDescription: comment?.riskDescription,
    riskValue: comment?.riskValue || null,
  };
};

enum TabType {
  Idea = 'Idea',
  Assistant = 'Assistant',
}

export const IdeaCommentForm: React.FC<IdeaCommentFormProps> = ({
  idea,
  comment,
  controlsContainer,
  onCreate,
  onUpdate,
  onDelete,
  disabled,
}) => {
  const dispatch = useDispatch();

  const [callCreateCallback, setCallCreateCallback] = useState(false);
  const [callUpdateCallback, setCallUpdateCallback] = useState(false);
  const [callDeleteCallback, setCallDeleteCallback] = useState(false);
  const [assistantContainer, setAssistantContainer] = useState<HTMLDivElement>();
  const [isRemoveConfirmationOpen, setIsRemoveConfirmationOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(TabType.Idea);

  const activeAssistantId = useSelector(AssistantSelectors.activeId);

  const { control, setValue, reset, handleSubmit } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: resolveFormData(comment),
  });

  const { isAiAssistantEnabled, isAllProjectDataDone, message } = useProjectDataCompleteness(idea.project.id);

  const isCreating = useSelector(IdeasCommentsSelectors.isCreatingByIdeaId(idea.id));
  const isCreated = useSelector(IdeasCommentsSelectors.isCreatedByIdeaId(idea.id));
  const isUpdating = useSelector(IdeasCommentsSelectors.isUpdatingByIdeaId(idea.id));
  const isUpdated = useSelector(IdeasCommentsSelectors.isUpdatedByIdeaId(idea.id));
  const isRemoving = useSelector(IdeasCommentsSelectors.isRemovingByIdeaId(idea.id));
  const isRemoved = useSelector(IdeasCommentsSelectors.isRemovedByIdeaId(idea.id));

  const isAnyLoading = isCreating || isUpdating || isRemoving;

  useEffect(() => {
    if (callCreateCallback && isCreated) {
      setCallCreateCallback(false);
      if (onCreate) onCreate();
    }
  }, [callCreateCallback, isCreated]);

  useEffect(() => {
    if (callUpdateCallback && isUpdated) {
      setCallUpdateCallback(false);
      if (onUpdate) onUpdate();
    }
  }, [callUpdateCallback, isUpdated]);

  useEffect(() => {
    if (callDeleteCallback && isRemoved) {
      setCallDeleteCallback(false);
      if (onDelete) onDelete();
    }
  }, [callDeleteCallback, isRemoved]);

  useEffect(() => {
    if (comment) {
      reset(resolveFormData(comment));
    }
  }, [comment?.lastUpdated]);

  const gainValue = useWatch({
    control,
    name: 'gainValue',
  });

  const costValue = useWatch({
    control,
    name: 'costValue',
  });

  const riskValue = useWatch({
    control,
    name: 'riskValue',
  });

  const descriptions = useWatch({
    control,
    name: ['gainDescription', 'costDescription', 'riskDescription'],
  });

  const areAllValuesDefined = [gainValue, costValue, riskValue].every(v => v !== null);
  const areAllDescriptionsDefined = descriptions.every(d => (d || '').trim().length > 0);

  const createComment = () => {
    handleSubmit(formData => {
      const command: CreateIdeaCommentCommand = {
        ...formData,
      };

      dispatch(IdeasCommentsActions.createComment(idea.project.id, idea.id, command));
      setCallCreateCallback(true);
    })();
  };

  const updateComment = () => {
    handleSubmit(formData => {
      const command: UpdateIdeaCommentCommand = {
        ...formData,
      };

      dispatch(IdeasCommentsActions.updateComment(idea.project.id, idea.id, comment.id, command));
      setCallUpdateCallback(true);
    })();
  };

  const removeComment = () => {
    closeRemoveConfirmation();
    dispatch(IdeasCommentsActions.deleteComment(idea.project.id, idea.id, comment.id));
    setCallDeleteCallback(true);
  };

  const openRemoveConfirmation = () => {
    setIsRemoveConfirmationOpen(true);
  };

  const closeRemoveConfirmation = () => {
    setIsRemoveConfirmationOpen(false);
  };

  const changeTabHandler = (_event, type: TabType) => {
    dispatch(AssistantActions.setActiveAssistant(null));
    setActiveTab(type);
  };

  const getHintHandler = () => {
    setActiveTab(TabType.Assistant);
  };

  return (
    <Box pt={3}>
      {(!areAllValuesDefined || !areAllDescriptionsDefined) && (
        <Box mb={4}>
          <Alert severity={'warning'}>
            <Trans>To ensure rating calculation set Gain, Cost, Risk and add comments</Trans>
          </Alert>
        </Box>
      )}

      {areAllValuesDefined && areAllDescriptionsDefined && (
        <Box mb={4}>
          <Alert severity={'success'}>
            <Trans>Valid for rating calculation</Trans>
          </Alert>
        </Box>
      )}

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Box pb={10}>
            {[
              {
                title: 'Gain',
                descriptionFieldName: 'gainDescription',
                valueFieldName: 'gainValue',
                ideaParameterType: IdeaParameterType.gain,
                value: gainValue,
                reverseValueColor: false,
              },
              {
                title: 'Cost',
                descriptionFieldName: 'costDescription',
                valueFieldName: 'costValue',
                ideaParameterType: IdeaParameterType.cost,
                value: costValue,
                reverseValueColor: true,
              },
              {
                title: 'Risk',
                descriptionFieldName: 'riskDescription',
                valueFieldName: 'riskValue',
                ideaParameterType: IdeaParameterType.risk,
                value: riskValue,
                reverseValueColor: true,
              },
            ].map((item, key) => (
              <Box key={key} mt={key ? 4 : 0}>
                <Box mb={1}>
                  <Grid container spacing={2} alignItems={'flex-end'}>
                    <Grid item xs={'auto'}>
                      <Typography variant={'subtitle1'}>
                        <b>
                          <Trans>{item.title}</Trans>
                        </b>
                      </Typography>
                    </Grid>

                    <Grid item xs={true}>
                      <Grid container spacing={2} alignItems={'center'}>
                        <Grid item xs={true}>
                          <ReactHookFormSlider
                            name={item.valueFieldName}
                            control={control}
                            setValue={setValue}
                            min={0}
                            max={10}
                            marks
                            color={
                              item.value !== null
                                ? resolveValueByColorType({
                                    value: item.value,
                                    green: 6,
                                    blue: 3,
                                    valuesMap: colorsMap,
                                    reverse: item.reverseValueColor,
                                  })
                                : 'gray'
                            }
                            disabled={disabled || isAnyLoading}
                            wrapperProps={{ mb: 0 }}
                            style={{ display: 'block' }}
                          />
                        </Grid>

                        {item.value !== null && (
                          <Grid item xs={'auto'}>
                            <Grid item xs={'auto'}>
                              <Chip
                                size={'small'}
                                label={item.value}
                                onDelete={() => setValue(item.valueFieldName as keyof FormData, null)}
                                disabled={disabled || isAnyLoading}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    {isAiAssistantEnabled && (
                      <Grid item xs={'auto'}>
                        <Assistant
                          type={AssistanceType.AskIdeaParameterHint}
                          projectId={idea.project.id}
                          actionProps={{ ideaId: idea.id, ideaParameterType: item.ideaParameterType }}
                          assistantContainer={assistantContainer}
                          contentProps={{
                            hintRowsType: HintRowType.Content,
                          }}
                          inlineAssistantProps={{
                            paperProps: { variant: 'outlined' },
                            showAssistantSvg: false,
                          }}
                          buttonProps={{
                            tooltip: message,
                          }}
                          onGetHint={getHintHandler}
                          disabled={disabled || isAnyLoading || !isAllProjectDataDone}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>

                <ReactHookFormText
                  name={item.descriptionFieldName}
                  control={control}
                  disabled={disabled || isAnyLoading}
                  wrapperProps={{ mb: 0 }}
                  multiline
                />
              </Box>
            ))}
          </Box>
        </Grid>

        <Grid item xs={6} display={'flex'} flexDirection={'column'}>
          {isAiAssistantEnabled && (
            <Box pb={2} flex={'0 0 auto'}>
              <Tabs
                orientation={'horizontal'}
                value={activeTab}
                onChange={changeTabHandler}
                variant={'fullWidth'}
                sx={{ backgroundColor: 'rgba(0,0,0,.05)' }}
              >
                <Tab value={TabType.Idea} label={<Trans>Idea</Trans>} />
                <Tab value={TabType.Assistant} label={<Trans>Assistant</Trans>} />
              </Tabs>
            </Box>
          )}

          <ScrollContainer containerProps={{ sx: { flex: '1 0 0' } }}>
            {activeTab === TabType.Idea && (
              <>{!!idea.content?.length && <QuillContentViewer content={idea.content} autoHeight />}</>
            )}

            {activeTab === TabType.Assistant && (
              <>
                {!activeAssistantId && (
                  <Box mb={2}>
                    <Alert severity={'info'}>
                      <Trans>Click "Get a hint" for assistant</Trans>
                    </Alert>
                  </Box>
                )}

                <div ref={r => setAssistantContainer(r)} />
              </>
            )}
          </ScrollContainer>
        </Grid>
      </Grid>

      <Portal container={controlsContainer} disablePortal={!controlsContainer}>
        {!comment && (
          <LoadingButton
            variant={'pg_rounded'}
            color={'pg_orange_solid'}
            onClick={createComment}
            disabled={disabled || isAnyLoading}
            loading={isAnyLoading}
          >
            <Trans>Submit</Trans>
          </LoadingButton>
        )}

        {comment && (
          <>
            <LoadingButton
              variant={'pg_rounded'}
              color={'pg_orange_solid'}
              onClick={updateComment}
              disabled={disabled || isAnyLoading}
              loading={isAnyLoading}
            >
              <Trans>Update comment</Trans>
            </LoadingButton>

            <PopperWrap
              open={isRemoveConfirmationOpen}
              openHandler={'manually'}
              onClose={closeRemoveConfirmation}
              placement={'top'}
            >
              <LoadingButton
                variant={'pg_rounded'}
                color={'pg_orange_outlined'}
                startIcon={<DeleteForeverOutlined />}
                onClick={openRemoveConfirmation}
                disabled={disabled || isAnyLoading}
                loading={isAnyLoading}
              >
                <Trans>Remove comment</Trans>
              </LoadingButton>

              <div>
                <Box mb={2}>
                  <Typography variant={'subtitle2'} component={'div'}>
                    <Trans>Confirm delete</Trans>
                  </Typography>

                  <Typography variant={'body2'} component={'div'}>
                    <Trans>Are you sure you want to delete your comment?</Trans>
                  </Typography>
                </Box>

                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant={'outlined'}
                      color={'error'}
                      size={'small'}
                      onClick={removeComment}
                      disabled={disabled}
                    >
                      <Trans>Confirm</Trans>
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant={'outlined'}
                      color={'primary'}
                      size={'small'}
                      onClick={closeRemoveConfirmation}
                      disabled={disabled}
                    >
                      <Trans>Cancel</Trans>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </PopperWrap>
          </>
        )}
      </Portal>
    </Box>
  );
};
