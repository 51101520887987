import React, { useEffect, useState } from 'react';
import { AuthService } from '../../services/auth';
import { User } from '../../models/security/user';
import { HubPublicUser } from '../../models/user/hub-public-user';
import { UserService } from '../../services/user';
import { LoadingOverlay } from '../loading-overlay/component';

interface AuthWatcherProps {
  onLoadUser?: (id: number) => void;
  onResetUser?: () => void;
  userListener?: { enabled: boolean; user?: User | HubPublicUser | null; pathname?: string };
  pathNameListener?: { enabled: boolean; pathname?: string };
  loading?: boolean;
}

let authCheckInterval: NodeJS.Timer;

export const AuthWatcher: React.FC<AuthWatcherProps> = ({
  onLoadUser,
  onResetUser,
  userListener,
  pathNameListener,
  loading,
}) => {
  const [isUserLoading, setUserIsLoading] = useState(false);

  useEffect(() => {
    unbindEvents();
    bindEvents();

    return () => {
      unbindEvents();
    };
  }, []);

  useEffect(() => {
    clearInterval(authCheckInterval);

    authCheckInterval = setInterval(() => {
      checkAuth();
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(authCheckInterval);
    };
  }, []);

  useEffect(() => {
    if (userListener?.enabled) {
      checkAuth();
    }
  }, [pathNameListener?.enabled, pathNameListener?.pathname]);

  useEffect(() => {
    if (userListener?.enabled) {
      const { lsAccessToken, isTheSameUser } = AuthService.resolveTokens();

      if (!userListener?.user && lsAccessToken && isTheSameUser) {
        loadUser();
      }
    }
  }, [userListener?.enabled, userListener?.user?.id, userListener?.pathname]);

  const bindEvents = () => {
    if (typeof window !== 'undefined' && window.addEventListener) {
      window.addEventListener('visibilitychange', checkAuth);
      window.addEventListener('focus', checkAuth);
    }
  };

  const unbindEvents = () => {
    if (typeof window !== 'undefined' && window.removeEventListener) {
      window.removeEventListener('visibilitychange', checkAuth);
      window.removeEventListener('focus', checkAuth);
    }
  };

  const checkAuth = () => {
    if (document.visibilityState === 'visible') {
      const { lsAccessToken, cookieAccessToken, isTheSameUser, isTokenExpired } = AuthService.resolveTokens();

      if (cookieAccessToken && lsAccessToken && isTheSameUser && isTokenExpired) {
        void AuthService.renewToken(resetUser);
      }

      if (cookieAccessToken && (!lsAccessToken || (lsAccessToken && !isTheSameUser))) {
        AuthService.mergeCookieTokenToLocalStorage();
        loadUser();
      }

      if (!cookieAccessToken && lsAccessToken) {
        resetUser();
      }
    }
  };

  const loadUser = () => {
    setUserIsLoading(true);

    UserService.login(resetUser)
      .then(user => {
        AuthService.setUserInfo(user);

        if (onLoadUser) {
          onLoadUser(user.id);
        }
      })
      .finally(() => {
        setUserIsLoading(false);
      });
  };

  const resetUser = () => {
    if (onResetUser) {
      onResetUser();
    }
  };

  return isUserLoading || loading ? (
    <LoadingOverlay loading={true} backdropStyles={{ backgroundColor: 'rgba(255,255,255,.7)' }} />
  ) : null;
};
