import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

interface MfaDialogProps {
  open: boolean;
  onClose?: () => void;
}

export const MfaDialog: React.FC<MfaDialogProps> = ({ open, onClose }) => {
  const { pathname } = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (typeof open !== 'undefined') {
      setIsOpen(open);
    }
  }, [open]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setIsOpen(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle>
        <Trans>MFA is required</Trans>
      </DialogTitle>

      <DialogContent>
        <Typography variant={'body1'} component={Box}>
          <Trans>
            The workspace you are trying to access requires all the users to have MFA enabled. Please, enable MFA to
            continue.
          </Trans>
        </Typography>

        {!pathname.includes('profile/security-setting') && (
          <>
            <Typography variant={'body1'} component={Box} mt={2}>
              <Trans>The workspace you are trying to access requires all the users to have MFA enabled</Trans>.{' '}
              <Trans>Visit</Trans>{' '}
              <Link to={'/profile/security-setting'} component={NavLink}>
                <Trans>security settings</Trans>
              </Link>{' '}
              <Trans>to enabled MFA</Trans>.
            </Typography>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose}>
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
