import { PlanLevel } from '@priz/shared/src/models/workspace';

export const planLevelFeaturesMap = {
  [PlanLevel.Personal]: [
    'Single member in personal workspace',
    'Virtual facilitator',
    'Unlimited projects',
    'Unlimited project tasks',
    'Limited access to all creative tools & idea manager',
    'Automatic reports',
    'Full access to PRIZ Hub',
    'Self onboarding',
    'Email & chat support',
    'Perfect for evaluating the PRIZ Platform',
  ],
  [PlanLevel.PersonalPro]: [
    'Everything from the Personal plan',
    'Single member in personal workspace',
    'Full access to all creative tools',
    'Full access to idea manager',
    'Self onboarding',
    'Email & chat support',
    'Perfect for problem-solving and innovation consultants, or educators',
  ],
  [PlanLevel.Team]: [
    'Everything from the Personal Pro plan',
    'Single team workspace',
    'Multiple workspace members (ideal for up to 20 members)',
    'Collaboration with team members',
    'Guided onboarding & training',
    'Email & chat support',
    'Perfect for small and midsize organizations',
  ],
  [PlanLevel.Organization]: [],
  [PlanLevel.Enterprise]: [
    'Everything from the Team plan',
    'Unlimited workspaces with unlimited members',
    'Guided onboarding & training',
    'Email, chat & phone on demand support',
    'Dedicated account manager',
    'Enhanced security',
    'SSO (on request)',
    'Detailed analytics (coming soon)',
    'Perfect for larger organizations who want to be more organized and permissions control',
  ],
};
