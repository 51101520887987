import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  scrollContainer: {
    width: '100%',
    height: '100%',
    minHeight: 300,
    overflowX: 'auto',
  },
  toolContainer: {
    height: '100%',
    minWidth: 500,
  },
  matrixRoot: {
    height: '100%',
  },
  cell: {
    height: '30%',
  },
  textField: {
    height: '100% !important',
    textAlign: 'center',
    textAlignLast: 'center',
  },
  textFieldRoot: {
    height: '100%',
    maxHeight: '100%',
    textAlign: 'center',
    textAlignLast: 'center',
  },
  textFieldInput: {
    maxHeight: '100% !important',
    textAlign: 'center',
    textAlignLast: 'center',
    overflowX: 'hidden !important' as 'hidden',
    overflowY: 'auto !important' as 'auto',
  },
});
