import React, { useState } from 'react';
import { Network } from '../../../lib/vis/declarations';
import { NetworkDiagram } from '../../../components/network-diagram/component';
import { options } from '../../../data/pmap-options';
import { pgColorScheme } from '../../../theme';
import { useNetworkDataset } from '../../../components/network-diagram/utils';
import { Box } from '@mui/material';
import { CalcResult, PMapUtilization } from '../../../models/tools/pmap';
import { constructPerceptionMapNetworkData } from '../../../utils/pmap';
import { PMapCard } from '../../../components/pmap-card/component';
import { PMapResultTable } from '../../../components/pmap-result-table/component';
import { FullscreenContainer } from '../../../components/fullscreen-container/component';
import { DiagramControls } from '../diagram-controls/component';

export interface PerceptionMapViewerProps {
  utilization: PMapUtilization;
  result?: CalcResult;
  recenterButton?: boolean;
  fullscreenButton?: boolean;
  disableInteractive?: boolean;
}

export const PerceptionMapViewer: React.FC<PerceptionMapViewerProps> = ({
  utilization,
  result,
  disableInteractive,
}) => {
  const perceptions = utilization?.data?.perceptions || [];
  const diagramData = constructPerceptionMapNetworkData(perceptions);
  const { nodesDataSet, edgesDataSet } = useNetworkDataset(diagramData);
  const [visNetwork, setVisNetwork] = useState<Network | null>(null);

  const networkInitHandler = (network: Network) => {
    setVisNetwork(network);
  };

  return (
    <>
      <Box
        mb={2}
        style={{
          width: '100%',
          height: '100mm',
          position: 'relative',
          background: pgColorScheme.workbenchBackground,
          boxShadow: `1000px 0 0 ${pgColorScheme.workbenchBackground} inset`,
          borderRadius: 10,
        }}
      >
        <FullscreenContainer
          style={{
            background: pgColorScheme.workbenchBackground,
            width: '100%',
            height: '100%',
          }}
          fullscreenDisabledStyle={{
            borderRadius: 'inherit',
          }}
          usePortal
        >
          {!disableInteractive && visNetwork && (
            <DiagramControls
              onSetCenter={() => {
                visNetwork.fit();
              }}
            />
          )}

          <NetworkDiagram
            options={options}
            nodesDataSet={nodesDataSet}
            edgesDataSet={edgesDataSet}
            onNetworkInit={networkInitHandler}
            viewMode={true}
            disabled={disableInteractive}
          />
        </FullscreenContainer>
      </Box>

      {result && (
        <Box mb={2}>
          {perceptions.map(item => (
            <PMapCard key={item.letter} perception={item} calcResult={result} compact={true} mb={1} />
          ))}
        </Box>
      )}

      {result && <PMapResultTable result={result.perceptions} />}
    </>
  );
};
