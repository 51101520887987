import { FortyPrinciplesUtilization } from '@priz/shared/src/models/tools/forty-principles/forty-principles-utilization';
import { MatrixElement } from '@priz/shared/src/models/tools/forty-principles/matrix-element';
import { Principle } from '@priz/shared/src/models/tools/forty-principles/principle';
import { DomainPrinciple } from '@priz/shared/src/models/tools/forty-principles/domain-principle';
import { getMatchingPrincipleIds } from '@priz/shared/src/utils/inventive-principles/inventive-principles';

export const resolveSelectedPrincipleId = (selectedPrincipleId: number, matchingPrinciples: Principle[]): number => {
  const isSelectedPrincipleInvalid =
    selectedPrincipleId === null || !getPrincipleIds(matchingPrinciples).includes(selectedPrincipleId);
  return isSelectedPrincipleInvalid ? matchingPrinciples[0]?.id : selectedPrincipleId;
};

export const getPrincipleIds = (principles: Principle[]): number[] => {
  return principles.map(p => p.id);
};

export const getPrincipleById = (id: number, principles: Principle[]): Principle => {
  return principles.find(p => p.id === id);
};

export const doMatchingElementsExist = (
  utilization: FortyPrinciplesUtilization,
  matrixElements: MatrixElement[],
): boolean => {
  const matchingPrincipleIds = getMatchingPrincipleIds(utilization, matrixElements);

  return Array.isArray(matchingPrincipleIds) && matchingPrincipleIds.length > 0;
};

export const resolvePrincipleDescription = (
  principle: Principle,
  domainPrinciples: DomainPrinciple[],
  selectedDomainId: number,
): {
  en: string;
  zh: string;
  es: string;
} => {
  if (selectedDomainId) {
    const dp = domainPrinciples.find(dp => dp.domain.id === selectedDomainId && dp.principle.id === principle.id);

    return {
      en: dp?.description?.split('\\"')?.join('"') || '',
      zh: dp?.descriptionZh?.split('\\"')?.join('"') || '',
      es: dp?.descriptionEs?.split('\\"')?.join('"') || '',
    };
  } else {
    return {
      en: principle.description?.split('\\"')?.join('"') || '',
      zh: principle.descriptionZh?.split('\\"')?.join('"') || '',
      es: principle.descriptionEs?.split('\\"')?.join('"') || '',
    };
  }
};
