import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToolUtilizationSelector } from '../../store/selectors';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { ToolUtilizationActions } from '../../store/actions/tools.actions';
import { ToolsList } from '../../../tools/tools-list/components';
import { EmptyToolsList } from '../../../tools/empty-tools-list/component';

interface ProjectToolsListProps {
  projectId: number;
  disabled?: boolean;
}

export const ProjectToolsList: React.FC<ProjectToolsListProps> = ({ projectId, disabled }) => {
  const dispatch = useDispatch();

  const loading = useSelector(ToolUtilizationSelector.isLoadingByProjectId(projectId));
  const tools = useSelector(ToolUtilizationSelector.getAllVisibleByProjectId(projectId));

  useEffect(() => {
    dispatch(ToolUtilizationActions.loadTools(projectId));
  }, [projectId]);

  if (loading) {
    return <LoadingOverlay loading={true} backdropStyles={{ backgroundColor: 'transparent' }} />;
  }

  if (tools.length === 0) {
    return <EmptyToolsList projectId={projectId} checkHash />;
  }

  return <ToolsList tools={tools} disabled={disabled} />;
};
