import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  svg: {
    fill: pgColorScheme.white,
    width: 20,
    height: 20,
    paddingTop: 4,
    opacity: 0.4,
  },
});
