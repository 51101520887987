import axios, { AxiosResponse } from 'axios';
import { ToolType } from '@priz/shared/src/models/tools';
import { ITask, Task, TaskStatus } from '@priz/shared/src/models/task';

const getAllByProjectId = (projectId: number): Promise<ITask[]> =>
  axios.get(`/v1/api/<ws-id>/project/${projectId}/tasks`).then((response: AxiosResponse<ITask[]>) => response.data);

const getByProjectIdAndTaskId = (projectId: number, taskId: number): Promise<ITask> =>
  axios
    .get(`/v1/api/<ws-id>/project/${projectId}/task/${taskId}`)
    .then((response: AxiosResponse<ITask>) => response.data);

export interface CreateTaskCommand {
  title: string;
  details?: string;
  sourceId?: string;
  sourceToolType?: ToolType;
  sourceToolUtilizationId?: number;
}

const create = (projectId: number, command: CreateTaskCommand): Promise<ITask> =>
  axios
    .post(`/v1/api/<ws-id>/project/${projectId}/task`, command)
    .then((response: AxiosResponse<ITask>) => response.data);

const update = (task: Task): Promise<ITask> =>
  axios
    .put(`/v1/api/<ws-id>/project/${task.project.id}/task/${task.id}`, {
      title: task.title,
      details: task.details,
      dueDate: task.dueDate,
      resolution: task.resolution,
      solvingProblem: task.solvingProblem,
      status: task.status,
    })
    .then((response: AxiosResponse<ITask>) => response.data);

const updateStatus = (projectId: number, taskId: number, status: TaskStatus): Promise<ITask> =>
  axios
    .put(`/v1/api/<ws-id>/project/${projectId}/task/${taskId}/status`, { status })
    .then((response: AxiosResponse<ITask>) => response.data);

const updateDueDate = (projectId: number, taskId: number, dueDate: Date): Promise<ITask> =>
  axios
    .put(`/v1/api/<ws-id>/project/${projectId}/task/${taskId}/due-date`, { dueDate })
    .then((response: AxiosResponse<ITask>) => response.data);

const doDelete = (task: Task): Promise<any> =>
  axios.delete(`/v1/api/<ws-id>/project/${task.project.id}/task/${task.id}`);

export const TaskApi = {
  create,
  delete: doDelete,
  getAllByProjectId,
  getByProjectIdAndTaskId,
  update,
  updateStatus,
  updateDueDate,
};
