import React from 'react';
import { UimPriority, UimTask } from '../../../models/tools/uim';
import { UimTaskSummaryTable } from '../../../components/uim-summary-table/component';

export interface UimViewerProps {
  tasks: UimTask[];
}

export const UimViewer: React.FC<UimViewerProps> = ({ tasks }) => {
  return (
    <>
      <UimTaskSummaryTable tasks={tasks} priority={UimPriority.DoFirst} />
      <UimTaskSummaryTable tasks={tasks} priority={UimPriority.DoLater} />
      <UimTaskSummaryTable tasks={tasks} priority={UimPriority.Delegate} />
      <UimTaskSummaryTable tasks={tasks} priority={UimPriority.Drop} />
    </>
  );
};
