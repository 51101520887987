import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AppNavbar } from '../navigation/app-navbar/component';
import { PageTitleWithDocLink } from '../shared/PageTitleWithDocLink';
import { SearchInput } from '../react/elements/search-input/component';
import { ProjectApi } from '../project/services';
import { AdminProjectCard } from './project/component';
import { Alert, Box } from '@mui/material';
import { Trans } from 'react-i18next';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { Project } from '@priz/shared/src/models/project';
import { LinearLoader } from '../react/elements/linear-loader/component';
import { PageContainer } from '../content-containers/page-container/component';

export const ProjectsManager: React.FC = () => {
  const queryClient = useQueryClient();
  const [loadedProject, setLoadedProject] = useState<Project | undefined>();
  const [searchProjectId, setSearchProjectId] = useState<number | undefined>();

  const { isFetching, data, refetch } = useQuery(
    ['project-manager', searchProjectId],
    () => ProjectApi.loadProject(searchProjectId),
    {
      enabled: typeof searchProjectId !== 'undefined',
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const unpublishMutation = useMutation((projectId: number) => ProjectApi.unpublish(projectId), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['project-manager', searchProjectId]);
    },
    onError: () => {
      void refetch();
    },
  });

  useEffect(() => {
    setLoadedProject(data);
  }, [data]);

  const changeHandler = (text: string) => {
    setSearchProjectId(+text);
  };

  const clearHandler = () => {
    setLoadedProject(undefined);
    setSearchProjectId(undefined);
  };

  const unpublishHandler = (projectId: number) => {
    unpublishMutation.mutate(projectId);
  };

  return (
    <PageContainer>
      <AppNavbar />

      <>
        <PageTitleWithDocLink title={'Projects manager'} />

        <SearchInput
          onChangeDebounced={changeHandler}
          onClear={clearHandler}
          type={'number'}
          placeholder={'Search by id'}
          inputProps={{ min: 0 }}
        />

        <LinearLoader mt={2} loading={isFetching && loadedProject?.id !== searchProjectId} />

        {typeof searchProjectId !== 'undefined' && !isFetching && !loadedProject && (
          <Box>
            <Alert severity={'info'}>
              <Trans>Project not found</Trans>
            </Alert>
          </Box>
        )}

        {loadedProject && (
          <Box position={'relative'}>
            <AdminProjectCard project={loadedProject} onUnpublish={unpublishHandler} />

            <LoadingOverlay
              loading={isFetching && loadedProject?.id === searchProjectId}
              backdropStyles={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
            />
          </Box>
        )}
      </>
    </PageContainer>
  );
};
