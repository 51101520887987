import React, { useState } from 'react';
import { useStyles } from './styles';
import { Portal } from '@mui/material';

interface FullscreenContainerContextProps {
  enabled: boolean;
  toggle: () => void;
}

export const FullscreenContainerContext = React.createContext<FullscreenContainerContextProps>({
  enabled: false,
  toggle: () => {
    return;
  },
});

interface FullscreenContainerProps {
  className?: string;
  style?: React.CSSProperties;
  fullscreenEnabledStyle?: React.CSSProperties;
  fullscreenDisabledStyle?: React.CSSProperties;
  containerRef?: React.LegacyRef<HTMLDivElement>;
  usePortal?: boolean;
}

export const FullscreenContainer: React.FC<FullscreenContainerProps> = ({
  className,
  style,
  fullscreenEnabledStyle,
  fullscreenDisabledStyle,
  containerRef,
  children,
  usePortal,
}) => {
  const styles = useStyles();
  const rootClassNames = [styles.root];

  const [isFullscreenModeEnabled, setIsFullscreenModeEnabled] = useState(false);

  if (className) rootClassNames.push(className);
  if (isFullscreenModeEnabled) rootClassNames.push('_fullscreen');

  const fullscreenToggleHandler = () => {
    setIsFullscreenModeEnabled(currentState => !currentState);
  };

  return (
    <FullscreenContainerContext.Provider value={{ enabled: isFullscreenModeEnabled, toggle: fullscreenToggleHandler }}>
      <Portal disablePortal={usePortal ? !isFullscreenModeEnabled : true}>
        <div
          className={rootClassNames.join(' ')}
          style={{
            ...style,
            ...(isFullscreenModeEnabled ? fullscreenEnabledStyle : fullscreenDisabledStyle),
          }}
          ref={containerRef}
        >
          {children}
        </div>
      </Portal>
    </FullscreenContainerContext.Provider>
  );
};
