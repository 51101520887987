import { Options } from '../../lib/vis/esnext';

export const viewModeInteraction: Options = {
  interaction: {
    dragNodes: false,
    hideEdgesOnDrag: false,
    hideEdgesOnZoom: false,
    hideNodesOnDrag: false,
    hover: true,
    hoverConnectedEdges: true,
    keyboard: false,
    navigationButtons: false,
    selectable: true,
    selectConnectedEdges: true,
  },
};

export const disabledInteraction: Options = {
  interaction: {
    dragNodes: false,
    dragView: false,
    hideEdgesOnDrag: false,
    hideEdgesOnZoom: false,
    hideNodesOnDrag: false,
    hover: false,
    hoverConnectedEdges: false,
    keyboard: false,
    multiselect: false,
    navigationButtons: false,
    selectable: false,
    selectConnectedEdges: false,
    zoomView: false,
  },
};
