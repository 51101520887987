import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  text: {
    textTransform: 'uppercase',
  },
});
