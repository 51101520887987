import { UserProperties } from '../analytics/model/user-properties';
import { Pendo, Metadata, Guide } from './pendo-model';

declare const pendo: Pendo;

const getPendo = (wait: 'loaded' | 'ready' | 'guides'): Promise<Pendo> => {
  return new Promise<Pendo>((resolve, reject) => {
    let resolvePendoInterval = null;

    const resolvePendo = () => {
      if (typeof pendo !== 'undefined') {
        clearInterval(resolvePendoInterval);
        resolvePendoInterval = null;

        if (wait === 'loaded') {
          resolve(pendo);
        }

        if (wait === 'ready') {
          if (pendo.isReady()) {
            resolve(pendo);
          } else {
            pendo.events.ready(() => {
              resolve(pendo);
            });
          }
        }

        if (wait === 'guides') {
          pendo.events.guidesLoaded(() => {
            resolve(pendo);
          });

          pendo.events.guidesFailed(() => {
            reject();
          });

          if (pendo.isReady()) {
            pendo.loadGuides();
          } else {
            pendo.events.ready(() => {
              pendo.loadGuides();
            });
          }
        }
      }
    };

    resolvePendoInterval = setInterval(() => {
      resolvePendo();
    }, 500);

    resolvePendo();
  });
};

const initialize = (properties: UserProperties) => {
  getPendo('loaded').then(pendo => {
    pendo.initialize({
      visitor: {
        id: properties.id.toString(),
        email: properties.email,
      },

      account: {
        id: properties.selectedWorkspaceId.toString(),
        account_tier: properties.planLevel,
        //is_paying: properties.,
        //accountCreationDate: <account-creation-date-goes-here>,
      },
    });
  });
};

const track = (event: string, parameters: Metadata = {}) => {
  getPendo('ready').then(pendo => {
    pendo.track(event, parameters);
  });
};

const getActiveGuides = (): Promise<Guide[]> => {
  return getPendo('guides').then(pendo => pendo.getActiveGuides());
};

const showGuide = (guideId: string): Promise<void> => {
  return getPendo('guides').then(pendo => pendo.showGuideById(guideId));
};

export const PendoService = {
  initialize,
  track,
  getActiveGuides,
  showGuide,
};

export const pendoTrack = PendoService.track;
