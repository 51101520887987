import React, { useEffect, useState } from 'react';
import { ToolType } from '@priz/shared/src/models/tools';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IdeasSelectors, ProjectIdeaActions } from '../store';
import { makeStyles } from '@mui/styles';
import { Trans } from 'react-i18next';
import { validateOptions } from '@priz/shared/src/utils/form';
import { useForm } from 'react-hook-form';
import { IdeaStatusSelector } from '../idea-status-selector/component';
import { Idea, IdeaStatus } from '@priz/shared/src/models/idea';
import { LoadingButton } from '@mui/lab';
import { IdeaCommand } from '../services/IdeaApi';
import { ReactHookFormQuill } from '../../react/form-elements';

const useStyles = makeStyles({
  actions: {
    textAlign: 'right',
  },
  errorLabel: {
    'opacity': 0,
    'pointerEvents': 'none',
    'transition': 'opacity .1s ease',

    '&._show': {
      opacity: 1,
      pointerEvents: 'auto',
    },
  },
});

export interface IdeaRecorderProps {
  projectId: number;
  idea?: Idea;
  sourceToolId?: number;
  sourceToolType?: ToolType;
  sourceId?: string;
  isOpen: boolean;
  onClose?: () => void;
  edit?: boolean;
}

const resolveDefaultProps = (
  idea?: Idea,
): {
  content: string;
  details: string;
  status?: IdeaStatus;
} => {
  return {
    content: idea?.content || '',
    details: idea?.details || '',
    status: idea?.status,
  };
};

export const IdeaRecorder: React.FC<IdeaRecorderProps> = ({
  idea,
  projectId,
  sourceToolId,
  sourceToolType,
  sourceId,
  isOpen,
  onClose,
  edit = false,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const isCreating = useSelector(IdeasSelectors.isCreating(projectId));
  const isCreated = useSelector(IdeasSelectors.isCreated(projectId));
  const isUpdating = useSelector(IdeasSelectors.isUpdating(projectId));
  const isUpdated = useSelector(IdeasSelectors.isUpdated(projectId));

  const { handleSubmit, reset, setValue, control, formState } = useForm({
    shouldFocusError: false,
    defaultValues: resolveDefaultProps(idea),
  });

  const handleSave = () => {
    handleSubmit(formData => {
      if (edit) {
        dispatch(ProjectIdeaActions.update(projectId, idea.id, formData));
      } else {
        const command: IdeaCommand = {
          content: formData.content,
          sourceToolType,
          sourceToolUtilizationId: sourceToolId,
          sourceId,
        };

        dispatch(ProjectIdeaActions.create(projectId, command));
      }
    })();
  };

  useEffect(() => {
    if (isCreated) handleClose();
  }, [isCreated]);

  useEffect(() => {
    if (isUpdated) handleClose();
  }, [isUpdated]);

  useEffect(() => {
    setOpen(isOpen);
    if (isOpen) reset(resolveDefaultProps(idea));
  }, [isOpen]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setOpen(false);
    }
  };

  const statusSelectHandler = (status: IdeaStatus) => {
    setValue('status', status, { shouldDirty: true });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle id="alert-dialog-title">
        {edit ? <Trans>Edit an idea</Trans> : <Trans>Record new idea</Trans>}
      </DialogTitle>

      <DialogContent>
        <ReactHookFormQuill
          name={'content'}
          control={control}
          placeholder={'Start describing your idea...'}
          rules={{
            validate: {
              ...validateOptions.hasText('Idea cannot be empty'),
            },
          }}
          disabled={isCreating || isUpdating}
        />

        {edit && (
          <>
            <ReactHookFormQuill
              name={'details'}
              control={control}
              fieldTitle={'Details, value & risks'}
              disabled={isCreating || isUpdating}
            />

            <IdeaStatusSelector
              status={idea?.status}
              onSelect={statusSelectHandler}
              disabled={isCreating || isUpdating}
            />
          </>
        )}
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose}>
          <Trans>Cancel</Trans>
        </Button>

        <LoadingButton
          variant={'pg_rounded'}
          color={'pg_orange_solid'}
          onClick={handleSave}
          disabled={!formState.isDirty || isCreating || isUpdating}
          loading={isCreating || isUpdating}
        >
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
