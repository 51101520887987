import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  transformWrapper: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  transformComponent: {
    position: 'relative',
    height: 'auto',
    width: 'auto',
    userSelect: 'none',
    transformOrigin: '0 0',
    backfaceVisibility: 'hidden',
    flex: '0 0 auto',
  },
});
