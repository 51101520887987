import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToolUtilization } from '@priz/shared/src/models/tools';
import { Trans } from 'react-i18next';
import { FeatureSetSelectors } from '../../workspace/store/selectors/feature-set.selectors';
import { FeatureLimit } from '@priz/shared/src/models/workspace';
import { Button, ButtonProps, Tooltip } from '@mui/material';
import { ProjectFeatureBlockerActions } from '../../project-feature-blocker/store/actions';
import { ProjectFeatureBlockerType } from '../../project-feature-blocker/store/model';
import { IdeaRecorder } from '../idea-recorder/component';
import { PaywallActions } from '../../react/modules/paywall/store';
import AddIcon from '@mui/icons-material/Add';

interface NewIdeaButtonProps extends ButtonProps {
  projectId?: number;
  sourceTool?: ToolUtilization;
  text?: string;
  tooltip?: string;
}

export const NewIdeaButton: React.FC<NewIdeaButtonProps> = ({
  projectId,
  sourceTool,
  text,
  tooltip,
  onClick,
  ...rest
}) => {
  const dispatch = useDispatch();

  const [recorderOpen, setRecorderOpen] = useState(false);

  const isIdeasLimitHit = useSelector(FeatureSetSelectors.isIdeasLimitHit(projectId));

  const handleClick = () => {
    if (typeof projectId !== 'undefined') {
      if (isIdeasLimitHit) {
        dispatch(PaywallActions.show(FeatureLimit.MaxIdeas));
      } else {
        setRecorderOpen(true);
      }
    } else {
      dispatch(
        ProjectFeatureBlockerActions.set({
          utilizationId: sourceTool?.id,
          blocker: ProjectFeatureBlockerType.Idea,
          ideaRecorderProps: {
            sourceToolId: sourceTool?.id,
            sourceToolType: sourceTool?.type,
          },
        }),
      );
    }
  };

  const handleClose = () => {
    setRecorderOpen(false);
  };

  return (
    <>
      <Tooltip title={tooltip ? <Trans>{tooltip}</Trans> : ''} placement={'top'} disableInteractive arrow>
        <div>
          <Button
            variant={'pg_rounded'}
            color={'pg_orange_solid'}
            onClick={onClick || handleClick}
            startIcon={<AddIcon />}
            {...rest}
          >
            <Trans>{text || 'Record an idea'}</Trans>
          </Button>
        </div>
      </Tooltip>

      <IdeaRecorder
        isOpen={recorderOpen}
        projectId={projectId}
        sourceToolId={sourceTool?.id}
        sourceToolType={sourceTool?.type}
        onClose={handleClose}
      />
    </>
  );
};
