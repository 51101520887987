import React, { useEffect, useState } from 'react';
import { Task } from '@priz/shared/src/models/task';
import { Grid, IconButton, SvgIconProps, Tooltip } from '@mui/material';
import { DeleteOutlineRounded, ModeEditOutlineOutlined, VisibilityOutlined } from '@mui/icons-material';
import { TaskEditor } from '../task-editor/component';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TaskActions } from '../store/actions/task.actions';
import { TaskViewer } from '../task-viewer/component';
import { TaskSelectors } from '../store/selectors/task.selectors';

interface TaskControlsProps {
  task: Task;
  iconsProps?: SvgIconProps;
  disabled?: boolean;
}

export const TaskControls: React.FC<TaskControlsProps> = ({ task, iconsProps, disabled }) => {
  const dispatch = useDispatch();

  const [isTaskViewerOpen, setIsTaskViewerOpen] = useState(false);
  const [isTaskEditorOpen, setIsTaskEditorOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const isRemoving = useSelector(TaskSelectors.isRemoving(task.project.id));
  const isRemoved = useSelector(TaskSelectors.isRemoved(task.project.id));

  useEffect(() => {
    if (isRemoved) closeDialogs();
  }, [isRemoved]);

  const deleteTask = () => {
    dispatch(TaskActions.delete(task));
  };

  const openTaskViewer = () => {
    setIsTaskViewerOpen(true);
  };

  const openTaskEditor = () => {
    setIsTaskEditorOpen(true);
  };

  const openDeleteConfirmationDialog = () => {
    setIsDeleteConfirmOpen(true);
  };

  const closeDialogs = () => {
    setIsTaskViewerOpen(false);
    setIsTaskEditorOpen(false);
    setIsDeleteConfirmOpen(false);
  };

  return (
    <>
      <Grid container>
        <Grid item>
          <Tooltip title={<Trans>View</Trans>} placement={'top'} arrow disableInteractive>
            <IconButton size={'small'} color={'primary'} onClick={openTaskViewer}>
              <VisibilityOutlined {...(iconsProps || {})} />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item>
          <Tooltip title={<Trans>Edit</Trans>} placement={'top'} arrow disableInteractive>
            <IconButton disabled={disabled} size={'small'} color={'primary'} onClick={openTaskEditor}>
              <ModeEditOutlineOutlined {...(iconsProps || {})} />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item>
          <Tooltip title={<Trans>Delete</Trans>} placement={'top'} arrow disableInteractive>
            <IconButton disabled={disabled} size={'small'} color={'error'} onClick={openDeleteConfirmationDialog}>
              <DeleteOutlineRounded {...(iconsProps || {})} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <TaskViewer isOpen={isTaskViewerOpen} task={task} onClose={closeDialogs} />

      <TaskEditor isOpen={isTaskEditorOpen} task={task} onClose={closeDialogs} />

      <PgConfirmationDialog
        isOpen={isDeleteConfirmOpen}
        confirmTitle={<Trans>Confirm delete</Trans>}
        cancelButtonText={<Trans>Cancel</Trans>}
        okButtonText={<Trans>Delete</Trans>}
        confirmContent={<Trans>Are you sure you want to delete this task?</Trans>}
        onConfirm={deleteTask}
        onClose={closeDialogs}
        disabled={isRemoving}
        loading={isRemoving}
      />
    </>
  );
};
