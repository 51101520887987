import React, { useState } from 'react';
import { Box, Hidden, IconButton, Menu } from '@mui/material';
import { useStyles } from './styles';

import { Menu as MenuIcon, MenuOpen as MenuOpenIcon } from '@mui/icons-material';

export interface ContentControlBarProps {
  leftColContent?: React.ReactNode;
  leftColCollapsingContent?: React.ReactNode;
  rightColContent?: React.ReactNode;
  rightColCollapsingContent?: React.ReactNode;
}

export const ContentControlBar: React.FC<ContentControlBarProps> = ({
  leftColContent,
  leftColCollapsingContent,
  rightColContent,
  rightColCollapsingContent,
}) => {
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        className={styles.projectContentCtrlBar}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'stretch'}
        flexWrap={'nowrap'}
      >
        <Box className={[styles.contentCol, styles.leftCol].join(' ')} py={1} pr={1} pl={{ xs: 1, md: 2 }}>
          {leftColContent}

          {leftColCollapsingContent && <Hidden mdDown>{leftColCollapsingContent}</Hidden>}
        </Box>

        <Box className={[styles.contentCol, styles.rightCol].join(' ')} py={1} pl={1} pr={{ xs: 1, md: 2 }}>
          {rightColCollapsingContent && <Hidden mdDown>{rightColCollapsingContent}</Hidden>}

          {rightColContent}

          {(leftColCollapsingContent || rightColCollapsingContent) && (
            <Hidden mdUp>
              <Box ml={1}>
                <IconButton className={styles.hamburgerButton} onClick={openMenu}>
                  {!anchorEl && <MenuIcon className={styles.hamburgerIcon} />}
                  {anchorEl && <MenuOpenIcon className={styles.hamburgerIcon} />}
                </IconButton>
              </Box>
            </Hidden>
          )}
        </Box>
      </Box>

      {(leftColCollapsingContent || rightColCollapsingContent) && (
        <Hidden mdUp>
          <Menu
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={closeMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Box px={2}>
              {leftColCollapsingContent && (
                <Box className={styles.menuButtonsContainer} py={1}>
                  {leftColCollapsingContent}
                </Box>
              )}

              {rightColCollapsingContent && (
                <Box className={styles.menuButtonsContainer} py={1}>
                  {rightColCollapsingContent}
                </Box>
              )}
            </Box>
          </Menu>
        </Hidden>
      )}
    </>
  );
};
