import { createSelector, Selector } from 'reselect';
import { ArrayUtils } from '@priz/shared/src/utils/common';
import { AppState } from '../../../store/app.state';
import { Agenda, AgendasCollection } from '../model';

const agendasCollectionSelector = (state: AppState): AgendasCollection => state.agendas;

const agendasEntitiesSelector = createSelector(agendasCollectionSelector, collection => collection.entities);

const agendasLookupSelector = createSelector(agendasCollectionSelector, collection => collection.lookups);

const agendasStatusesSelector = createSelector(agendasCollectionSelector, collection => collection.statuses);

const statusesIdsByProjectIdSelector = (projectId: number) =>
  createSelector(agendasLookupSelector, lookup => lookup.byProjectId[projectId] || []);

const getAllByProjectId = (projectId: number): Selector<AppState, Agenda[]> =>
  createSelector([agendasEntitiesSelector, statusesIdsByProjectIdSelector(projectId)], (entityMap, ids) =>
    ids.map(id => entityMap[id]).sort(ArrayUtils.sorterByDateCreated),
  );

const getAllByProjectIdAndMeetingId = (projectId: number, meetingId: number): Selector<AppState, Agenda[]> =>
  createSelector([agendasEntitiesSelector, statusesIdsByProjectIdSelector(projectId)], (entityMap, ids) =>
    ids
      .map(id => entityMap[id])
      .filter(attendee => attendee.meeting.id === meetingId)
      .sort(ArrayUtils.sorterByDateCreated),
  );

const getById = (agendaId: number): Selector<AppState, Agenda> =>
  createSelector(agendasEntitiesSelector, entities => entities[agendaId]);

const isLoaded = (projectId: number): Selector<AppState, boolean> =>
  createSelector(agendasStatusesSelector, statuses => statuses.byProjectId[projectId]?.loaded || false);

const isLoading = (projectId: number): Selector<AppState, boolean> =>
  createSelector(agendasStatusesSelector, statuses => statuses.byProjectId[projectId]?.loading || false);

const isCreating = (projectId: number): Selector<AppState, boolean> =>
  createSelector(agendasStatusesSelector, statuses => statuses.byProjectId[projectId]?.creating || false);

const isUpdating = (projectId: number): Selector<AppState, boolean> =>
  createSelector(agendasStatusesSelector, statuses => statuses.byProjectId[projectId]?.updating || false);

const isRemoving = (projectId: number): Selector<AppState, boolean> =>
  createSelector(agendasStatusesSelector, statuses => statuses.byProjectId[projectId]?.removing || false);

export const AgendaSelectors = {
  getAllByProjectId,
  getAllByProjectIdAndMeetingId,
  getById,
  isLoaded,
  isLoading,
  isCreating,
  isUpdating,
  isRemoving,
};
