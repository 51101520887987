import { AuditableEntity, IAuditableEntity } from '../common/auditable-entity';
import { Identifiable } from '../common/entity-collection-state';
import { Profile } from './profile';
import { Role } from './role.enum';
import { IdRef } from '../common/id-ref';
import { PartnerProfile } from './partner-profile';
import { Auth0ConnectionType } from '../../models/security/auth0-connection-type';
import { ApiKey } from '../../models/api-keys';

export interface IUser extends IAuditableEntity, Identifiable {
  id: number;
  auth0Id?: string;
  googleId?: string;
  linkedinId?: string;
  connections?: Auth0ConnectionType[];
  activeConnection?: Auth0ConnectionType;
  username: string;
  email: string;
  emailVerified?: boolean;
  verificationEmailSentAt?: Date;
  emailVerifiedAt?: Date;
  exposeInHub?: boolean;
  showEmailInHub?: boolean;
  mfa?: boolean;
  policiesAcceptedAt?: Date;
  profile: Profile;
  roles: Role[];
  referrer?: IdRef;
  nonChargeable: boolean;

  partnerProfile?: PartnerProfile;
  apiKeys?: ApiKey[];
}

export class User extends AuditableEntity implements IUser {
  public id: number;
  public auth0Id?: string;
  public googleId?: string;
  public linkedinId?: string;
  public connections?: Auth0ConnectionType[];
  public activeConnection?: Auth0ConnectionType;
  public username: string;
  public email: string;
  public emailVerified?: boolean;
  public verificationEmailSentAt?: Date;
  public emailVerifiedAt?: Date;
  public exposeInHub?: boolean;
  public showEmailInHub?: boolean;
  public mfa?: boolean;
  public policiesAcceptedAt?: Date;
  public profile: Profile;
  public referrer?: IdRef;
  public roles: Role[];
  public nonChargeable: boolean;

  public partnerProfile?: PartnerProfile;
  public apiKeys?: ApiKey[];

  constructor(json: IUser) {
    super(json);

    this.id = json.id;
    this.auth0Id = json.auth0Id;
    this.googleId = json.googleId;
    this.linkedinId = json.linkedinId;
    this.username = json.username;
    this.email = json.email;
    this.emailVerified = json.emailVerified;
    this.verificationEmailSentAt = json.verificationEmailSentAt && new Date(json.verificationEmailSentAt);
    this.emailVerifiedAt = json.emailVerifiedAt && new Date(json.emailVerifiedAt);
    this.policiesAcceptedAt = json.policiesAcceptedAt && new Date(json.policiesAcceptedAt);
    this.exposeInHub = json.exposeInHub;
    this.showEmailInHub = json.showEmailInHub;
    this.mfa = json.mfa;
    this.profile = json.profile && new Profile(json.profile);
    this.roles = json.roles;
    this.referrer = json.referrer;
    this.nonChargeable = json.nonChargeable;

    if (typeof json.connections !== 'undefined') {
      this.connections = json.connections;
    }

    if (typeof json.activeConnection !== 'undefined') {
      this.activeConnection = json.activeConnection;
    }

    this.partnerProfile = json.partnerProfile;
    this.apiKeys = json.apiKeys;
  }
}
