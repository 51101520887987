import { RouterActionMeta } from '../../../shared/store/types';
import { FluxStandardAction } from 'flux-standard-action';
import { CecUtilization, ICecUtilization } from '@priz/shared/src/models/tools/cec';
import { createPgAction } from '../../../shared/store/action-creator';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { ToolType } from '@priz/shared/src/models/tools';
import { CecUtilizationApi } from '../../services/cec-utilization.api';
import { NavigateFunction } from 'react-router-dom';
import { ToolsUtils } from '../../../tools/utils';

export enum CecUtilizationActionType {
  Update = 'cec-utilization/update',
  Delete = 'cec-utilization/delete',
}

type CecUtilizationActionPayload = ICecUtilization;

interface CecUtilizationActionMeta extends RouterActionMeta {
  utilization?: ICecUtilization;
  utilizationId?: number;
  projectId?: number;
  navigate?: NavigateFunction;
}

export type CecUtilizationAction = FluxStandardAction<string, CecUtilizationActionPayload, CecUtilizationActionMeta>;

const update = createPgAction(
  CecUtilizationActionType.Update,
  CecUtilizationApi.update,
  (utilization: CecUtilization) => ({ projectId: utilization.project?.id, utilization }),
  (_payload, { projectId, utilization }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      utilization_id: utilization.id,
      tool_type: ToolType.CEC,
    });
  },
);

const doDelete = createPgAction(
  CecUtilizationActionType.Delete,
  CecUtilizationApi.delete,
  (utilizationId: number, projectId?: number, navigate?: NavigateFunction) => ({ projectId, utilizationId, navigate }),
  (_payload, { projectId, utilizationId, navigate }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_DELETED, {
      project_id: projectId,
      tool_type: ToolType.CEC,
      utilization_id: utilizationId,
    });

    if (navigate) {
      navigate(ToolsUtils.resolveListPath(projectId));
    }
  },
);

export const CecUtilizationActions = {
  update,
  delete: doDelete,
};
