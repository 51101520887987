import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  contentContainer: {
    flex: '1 0 0',
    width: '100%',
    overflow: 'hidden',
  },
  scrollContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    boxSizing: 'border-box',
    overflow: 'auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 0',
  },
});
