import React from 'react';
import { Project } from '@priz/shared/src/models/project';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { ToolUtilization } from '@priz/shared/src/models/tools';
import { ProjectCard } from '../project-card/component';
import { ToolCard } from '../../tools/tool-card/component';
import { EntityMap } from '@priz/shared/src/models/common/entity-collection-state';
import { WorkspaceMember } from '@priz/shared/src/models/workspace';

interface ProjectsAndToolsListProps {
  list: (Project | ToolUtilization)[];
  childrenToolsMap: { [parentToolId: number]: ToolUtilization[] };
  workspaceMembersMap: EntityMap<WorkspaceMember>;
  title?: string;
  divider?: boolean;
}

const gelLastUpdateDate = (item: Project | ToolUtilization): Date => {
  if (item instanceof Project) {
    return item.lastWorkedOnAt || item.dateCreated;
  }

  if (item instanceof ToolUtilization) {
    return item.lastUpdated || item.dateCreated;
  }
};

export const ProjectsAndToolsList: React.FC<ProjectsAndToolsListProps> = ({
  list,
  childrenToolsMap,
  workspaceMembersMap,
  title,
  divider,
}) => {
  if (!list.length) {
    return null;
  }

  return (
    <>
      {divider && (
        <Box mt={4} mb={2}>
          <Divider />
        </Box>
      )}

      {title && (
        <Box mb={2}>
          <Typography variant="h6" component="div">
            <Trans>{title}</Trans>
          </Typography>
        </Box>
      )}

      <Grid container spacing={2}>
        {list
          .sort((left, right) => {
            const leftDate = gelLastUpdateDate(left);
            const rightDate = gelLastUpdateDate(right);

            if (leftDate < rightDate) {
              return 1;
            }

            if (leftDate > rightDate) {
              return -1;
            }

            return 0;
          })
          .map((item, index) => {
            const key = [index, item.id].join('-');

            if (item instanceof Project) {
              return (
                <Grid key={key} item xs={3}>
                  <ProjectCard project={item} />
                </Grid>
              );
            }

            if (item instanceof ToolUtilization) {
              return (
                <Grid key={key} item xs={3}>
                  <ToolCard
                    tool={item}
                    childrenTools={childrenToolsMap[item.id]}
                    author={workspaceMembersMap[item.createdBy.id]}
                  />
                </Grid>
              );
            }

            return null;
          })}
      </Grid>
    </>
  );
};
