import { IToolUtilization, ToolUtilization } from '../index';
import { NodeOptions, EdgeOptions } from '../../../lib/vis/declarations';

export interface CecNodeProps extends NodeOptions {
  id: string | number;
  label?: string;
  type?: string;
  potentialSolution?: string;
  color?: { [key: string]: string };
  borderWidth?: number;
  placeholder?: string;
}

export interface CecEdgeProps extends EdgeOptions {
  id?: string;
  from: string;
  to: string;
  label?: string;
  reverse?: boolean;
}

export interface CecDiagramData {
  nodes: CecNodeProps[];
  edges: CecEdgeProps[];
}

export interface ICecUtilization extends IToolUtilization {
  diagramData?: CecDiagramData;
}

export class CecUtilization extends ToolUtilization implements ICecUtilization {
  public diagramData?: CecDiagramData;

  constructor(json: ICecUtilization) {
    super(json);

    this.diagramData = json.diagramData;
  }
}
