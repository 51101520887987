import { IdRef } from '../../common/id-ref';
import { AuditableEntity, IAuditableEntity } from '../../common/auditable-entity';
import { Identifiable } from '../../common/entity-collection-state';
import { ApaActionStatus } from './apa-action-status.enum';

export interface IApaAction extends IAuditableEntity, Identifiable {
  customerAction: string;
  actionPurpose?: string;
  preventingAction?: string;
  customerNeeds?: string;

  status: ApaActionStatus;

  utilization: IdRef;
}

export class ApaAction extends AuditableEntity implements IApaAction {
  public id: number;

  public customerAction: string;
  public actionPurpose?: string;
  public preventingAction?: string;
  public customerNeeds?: string;

  public status: ApaActionStatus = ApaActionStatus.New;

  public utilization: IdRef;

  public constructor(json: IApaAction) {
    super(json);

    this.id = json.id;
    this.customerAction = json.customerAction;
    this.actionPurpose = json.actionPurpose;
    this.preventingAction = json.preventingAction;
    this.customerNeeds = json.customerNeeds;

    this.status = json.status;

    this.utilization = json.utilization;
  }
}
