export const filledArrowsMap = {
  blue: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuNjI4MjkgMC4zNTI3NzVDNC43OTcwNSAtMC4xMjE3OTIgNS40NTAwNyAtMC4xMTYxNjMgNS42MTExMSAwLjM2MTI0Nkw5Ljk3MDYzIDEzLjI4NTRDMTAuMDg4NSAxMy42MzUgOS44MzY3NiAxNCA5LjQ3Nzc1IDE0SDAuNTIyMjhDMC4xNTk1ODEgMTQgLTAuMDkyNDk0IDEzLjYyOCAwLjAzMjMzODMgMTMuMjc3TDQuNjI4MjkgMC4zNTI3NzVaIiBmaWxsPSIjMTE5OEQyIi8+Cjwvc3ZnPgo=',
  blueBold:
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNzA1NjUgMC4yODQyNTlDNi45MDI0NiAtMC4wOTkyNjUxIDcuNDUyNjIgLTAuMDkzNDU0NyA3LjY0MTI4IDAuMjk0MTRMMTMuOTQ2NiAxMy4yNDhDMTQuMTE1NyAxMy41OTU0IDEzLjg2MjcgMTQgMTMuNDc2MyAxNEgwLjUyMzczOEMwLjEzMjUxMiAxNCAtMC4xMjAyMzEgMTMuNTg2MiAwLjA1ODM5NjIgMTMuMjM4MUw2LjcwNTY1IDAuMjg0MjU5WiIgZmlsbD0iIzExOThEMiIvPgo8L3N2Zz4K',
  orange:
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuNjI4MjkgMC4zNTI3NzVDNC43OTcwNSAtMC4xMjE3OTIgNS40NTAwNyAtMC4xMTYxNjMgNS42MTExMSAwLjM2MTI0Nkw5Ljk3MDYzIDEzLjI4NTRDMTAuMDg4NSAxMy42MzUgOS44MzY3NiAxNCA5LjQ3Nzc1IDE0SDAuNTIyMjhDMC4xNTk1ODEgMTQgLTAuMDkyNDk0IDEzLjYyOCAwLjAzMjMzODMgMTMuMjc3TDQuNjI4MjkgMC4zNTI3NzVaIiBmaWxsPSIjRjg4MDFFIi8+Cjwvc3ZnPgo=',
  orangeBold:
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNzA1NjUgMC4yODQyNTlDNi45MDI0NiAtMC4wOTkyNjUxIDcuNDUyNjIgLTAuMDkzNDU0NyA3LjY0MTI4IDAuMjk0MTRMMTMuOTQ2NiAxMy4yNDhDMTQuMTE1NyAxMy41OTU0IDEzLjg2MjcgMTQgMTMuNDc2MyAxNEgwLjUyMzczOEMwLjEzMjUxMiAxNCAtMC4xMjAyMzEgMTMuNTg2MiAwLjA1ODM5NjIgMTMuMjM4MUw2LjcwNTY1IDAuMjg0MjU5WiIgZmlsbD0iI0Y4ODAxRSIvPgo8L3N2Zz4K',
  dark: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuNjI4MjkgMC4zNTI3NzVDNC43OTcwNSAtMC4xMjE3OTIgNS40NTAwNyAtMC4xMTYxNjMgNS42MTExMSAwLjM2MTI0Nkw5Ljk3MDYzIDEzLjI4NTRDMTAuMDg4NSAxMy42MzUgOS44MzY3NiAxNCA5LjQ3Nzc1IDE0SDAuNTIyMjhDMC4xNTk1ODEgMTQgLTAuMDkyNDk0IDEzLjYyOCAwLjAzMjMzODMgMTMuMjc3TDQuNjI4MjkgMC4zNTI3NzVaIiBmaWxsPSIjMUExOTJCIi8+Cjwvc3ZnPgo=',
  darkBold:
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNzA1NjUgMC4yODQyNTlDNi45MDI0NiAtMC4wOTkyNjUxIDcuNDUyNjIgLTAuMDkzNDU0NyA3LjY0MTI4IDAuMjk0MTRMMTMuOTQ2NiAxMy4yNDhDMTQuMTE1NyAxMy41OTU0IDEzLjg2MjcgMTQgMTMuNDc2MyAxNEgwLjUyMzczOEMwLjEzMjUxMiAxNCAtMC4xMjAyMzEgMTMuNTg2MiAwLjA1ODM5NjIgMTMuMjM4MUw2LjcwNTY1IDAuMjg0MjU5WiIgZmlsbD0iIzFBMTkyQiIvPgo8L3N2Zz4K',
  coral:
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuNjI4MjkgMC4zNTI3NzVDNC43OTcwNSAtMC4xMjE3OTIgNS40NTAwNyAtMC4xMTYxNjMgNS42MTExMSAwLjM2MTI0Nkw5Ljk3MDYzIDEzLjI4NTRDMTAuMDg4NSAxMy42MzUgOS44MzY3NiAxNCA5LjQ3Nzc1IDE0SDAuNTIyMjhDMC4xNTk1ODEgMTQgLTAuMDkyNDk0IDEzLjYyOCAwLjAzMjMzODMgMTMuMjc3TDQuNjI4MjkgMC4zNTI3NzVaIiBmaWxsPSIjRkE2NDY0Ii8+Cjwvc3ZnPgo=',
  coralBold:
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNzA1NjUgMC4yODQyNTlDNi45MDI0NiAtMC4wOTkyNjUxIDcuNDUyNjIgLTAuMDkzNDU0NyA3LjY0MTI4IDAuMjk0MTRMMTMuOTQ2NiAxMy4yNDhDMTQuMTE1NyAxMy41OTU0IDEzLjg2MjcgMTQgMTMuNDc2MyAxNEgwLjUyMzczOEMwLjEzMjUxMiAxNCAtMC4xMjAyMzEgMTMuNTg2MiAwLjA1ODM5NjIgMTMuMjM4MUw2LjcwNTY1IDAuMjg0MjU5WiIgZmlsbD0iI0Y4ODAxRSIvPgo8L3N2Zz4K',
};
