import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    ['& $selectSearchField']: {
      ['& .select-search__control']: {
        'borderColor': pgColorScheme.gray500,

        '&:hover': {
          borderColor: pgColorScheme.placeholder,
        },

        '&--is-focused': {
          'borderColor': pgColorScheme.blue,

          '&:hover': {
            borderColor: pgColorScheme.blue,
          },
        },
      },
    },
  },

  selectSearchField: {},
  cardContainer: {
    outline: 'none',
    position: 'relative',
    background: '#FFF',
    borderRadius: '4px',
    border: `1px solid ${pgColorScheme.gray500}`,
    padding: 10,
    minHeight: 42,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',

    ['& .StripeElement']: {
      width: '100%',
    },
  },
  textField: {
    ['& .MuiOutlinedInput-notchedOutline']: {
      borderColor: pgColorScheme.gray500,
    },
  },
});
