import { handleActions, combineActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import {
  DefaultProjectIdeasCommentsState,
  ProjectIdeasCommentsLookups,
  ProjectIdeasCommentsState,
  ProjectIdeasCommentsStatuses,
} from '../model';
import { IdeasCommentsAction, IdeasCommentsActionType } from '../actions';
import { EntityCollectionStatus, EntityMap } from '@priz/shared/src/models/common/entity-collection-state';
import { IdeaComment, IIdeaComment } from '@priz/shared/src/models/idea';

const resolveStatuses = (
  state: ProjectIdeasCommentsState,
  action: IdeasCommentsAction,
  statuses: Partial<EntityCollectionStatus>,
): ProjectIdeasCommentsStatuses => {
  return {
    byProjectId: {
      ...(state.statuses.byProjectId || {}),
      [action.meta.projectId]: {
        ...(state.statuses.byProjectId[action.meta.projectId] || {}),
        ...statuses,
      },
    },
    byIdeaId: {
      ...(state.statuses.byIdeaId || {}),
      ...(typeof action.meta.ideaId !== 'undefined'
        ? {
            [action.meta.ideaId]: {
              ...(state.statuses.byIdeaId[action.meta.ideaId] || {}),
              ...statuses,
            },
          }
        : {}),
    },
  };
};

const resolveLookups = (entities: EntityMap<IdeaComment>): ProjectIdeasCommentsLookups => {
  const lookups: ProjectIdeasCommentsLookups = {
    byProjectId: {},
    byIdeaId: {},
  };

  Object.keys(entities).forEach(id => {
    lookups.byProjectId[entities[id].project.id] = [...(lookups.byProjectId[entities[id].project.id] || []), +id];
    lookups.byIdeaId[entities[id].idea.id] = [...(lookups.byIdeaId[entities[id].idea.id] || []), +id];
  });

  return lookups;
};

const setStatuses = (
  state: ProjectIdeasCommentsState,
  action: IdeasCommentsAction,
  statuses: Partial<EntityCollectionStatus>,
): ProjectIdeasCommentsState => {
  return {
    ...state,
    statuses: resolveStatuses(state, action, statuses),
  };
};

const setComments = (
  state: ProjectIdeasCommentsState,
  action: IdeasCommentsAction,
  comments: IIdeaComment[],
  statuses: Partial<EntityCollectionStatus>,
): ProjectIdeasCommentsState => {
  const commentsMap = (comments || []).reduce((map: { [id: number]: IdeaComment }, comment) => {
    map[comment.id] = new IdeaComment(comment);
    return map;
  }, {});

  const entities = { ...state.entities, ...commentsMap };

  return {
    ...state,
    entities,
    lookups: resolveLookups(entities),
    statuses: resolveStatuses(state, action, statuses),
  };
};

const removeComment = (
  state: ProjectIdeasCommentsState,
  action: IdeasCommentsAction,
  statuses: Partial<EntityCollectionStatus>,
) => {
  const entities = { ...state.entities };

  delete entities[action.meta.commentId];

  return {
    ...state,
    entities,
    lookups: resolveLookups(entities),
    statuses: resolveStatuses(state, action, statuses),
  };
};

export const projectIdeasCommentsReducers = handleActions(
  {
    // Load

    [combineActions(
      toStarted(IdeasCommentsActionType.LoadForProject),
      toStarted(IdeasCommentsActionType.LoadForIdea),
      toStarted(IdeasCommentsActionType.LoadComment),
    )]: (state: ProjectIdeasCommentsState, action: IdeasCommentsAction): ProjectIdeasCommentsState =>
      setStatuses(state, action, {
        loaded: false,
        loading: true,
        error: false,
      }),

    [combineActions(
      toFailed(IdeasCommentsActionType.LoadForProject),
      toFailed(IdeasCommentsActionType.LoadForIdea),
      toFailed(IdeasCommentsActionType.LoadComment),
    )]: (state: ProjectIdeasCommentsState, action: IdeasCommentsAction): ProjectIdeasCommentsState =>
      setStatuses(state, action, {
        loaded: false,
        loading: false,
        error: true,
      }),

    [combineActions(toSuccess(IdeasCommentsActionType.LoadForProject), toSuccess(IdeasCommentsActionType.LoadForIdea))]:
      (state: ProjectIdeasCommentsState, action: IdeasCommentsAction): ProjectIdeasCommentsState =>
        setComments(state, action, action.payload as IIdeaComment[], { loaded: true, loading: false, error: false }),

    [toSuccess(IdeasCommentsActionType.LoadComment)]: (
      state: ProjectIdeasCommentsState,
      action: IdeasCommentsAction,
    ): ProjectIdeasCommentsState =>
      setComments(state, action, [action.payload as IIdeaComment], { loaded: true, loading: false, error: false }),

    // Create

    [toStarted(IdeasCommentsActionType.CreateComment)]: (
      state: ProjectIdeasCommentsState,
      action: IdeasCommentsAction,
    ): ProjectIdeasCommentsState =>
      setStatuses(state, action, {
        created: false,
        creating: true,
        error: false,
      }),

    [toFailed(IdeasCommentsActionType.CreateComment)]: (
      state: ProjectIdeasCommentsState,
      action: IdeasCommentsAction,
    ): ProjectIdeasCommentsState =>
      setStatuses(state, action, {
        created: false,
        creating: false,
        error: true,
      }),

    [toSuccess(IdeasCommentsActionType.CreateComment)]: (
      state: ProjectIdeasCommentsState,
      action: IdeasCommentsAction,
    ): ProjectIdeasCommentsState =>
      setComments(state, action, [action.payload as IIdeaComment], {
        created: true,
        creating: false,
        error: false,
      }),

    // Update

    [toStarted(IdeasCommentsActionType.UpdateComment)]: (
      state: ProjectIdeasCommentsState,
      action: IdeasCommentsAction,
    ): ProjectIdeasCommentsState =>
      setStatuses(state, action, {
        updated: false,
        updating: true,
        error: false,
      }),

    [toFailed(IdeasCommentsActionType.UpdateComment)]: (
      state: ProjectIdeasCommentsState,
      action: IdeasCommentsAction,
    ): ProjectIdeasCommentsState =>
      setStatuses(state, action, {
        updated: false,
        updating: false,
        error: true,
      }),

    [toSuccess(IdeasCommentsActionType.UpdateComment)]: (
      state: ProjectIdeasCommentsState,
      action: IdeasCommentsAction,
    ): ProjectIdeasCommentsState =>
      setComments(state, action, [action.payload as IIdeaComment], {
        updated: true,
        updating: false,
        error: false,
      }),

    // Delete

    [toStarted(IdeasCommentsActionType.DeleteComment)]: (
      state: ProjectIdeasCommentsState,
      action: IdeasCommentsAction,
    ): ProjectIdeasCommentsState =>
      setStatuses(state, action, {
        removed: false,
        removing: true,
        error: false,
      }),

    [toFailed(IdeasCommentsActionType.DeleteComment)]: (
      state: ProjectIdeasCommentsState,
      action: IdeasCommentsAction,
    ): ProjectIdeasCommentsState =>
      setStatuses(state, action, {
        removed: false,
        removing: false,
        error: true,
      }),

    [toSuccess(IdeasCommentsActionType.DeleteComment)]: (
      state: ProjectIdeasCommentsState,
      action: IdeasCommentsAction,
    ): ProjectIdeasCommentsState =>
      removeComment(state, action, {
        removed: true,
        removing: false,
        error: false,
      }),
  },
  DefaultProjectIdeasCommentsState,
);
