import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },

  'spinnerIcon': {
    display: 'block',
    width: '1em',
    height: '1em',
    fill: 'currentColor',
    animation: '$rotate 2s infinite linear',
    transformOrigin: '50% 50%',
  },
});
