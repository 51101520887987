import React from 'react';
import { useStyles } from './styles';
import { Box } from '@mui/material';

export interface CanvasControlsContainerProps {
  disabled?: boolean;
}

export const CanvasControlsContainer: React.FC<CanvasControlsContainerProps> = ({ disabled, children }) => {
  const styles = useStyles();
  const rootClassNames = [styles.root];

  if (disabled) {
    rootClassNames.push(styles.disabled);
  }

  return (
    <Box p={1.25} className={rootClassNames.join(' ')}>
      {children}
    </Box>
  );
};
