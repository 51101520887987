import React from 'react';
import { useSelector } from 'react-redux';
import { Chip } from '@mui/material';
import { ProjectSaveStatusSelectors } from '../store/selectors';
import { faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStyles } from './styles';
import { Trans } from 'react-i18next';

export interface ProjectSaveStatusProps {
  projectId: number;
  useIcon?: boolean;
}

export const ProjectSaveStatus: React.FC<ProjectSaveStatusProps> = ({ projectId, useIcon }) => {
  const styles = useStyles();
  const isAnythingSaving = useSelector(ProjectSaveStatusSelectors.isAnythingSaving(projectId));

  return (
    <React.Fragment>
      {useIcon ? (
        <div className={styles.iconWrap}>
          {isAnythingSaving ? (
            <FontAwesomeIcon className={`${styles.icon} _loading`} style={{ fontSize: 16 }} icon={faSpinner} pulse />
          ) : (
            <FontAwesomeIcon className={styles.icon} style={{ fontSize: 14 }} icon={faCheck} />
          )}
        </div>
      ) : (
        <Chip
          size="small"
          color={'default'}
          variant={'filled'}
          disabled={!isAnythingSaving}
          className={styles.chip}
          label={isAnythingSaving ? <Trans>Saving...</Trans> : <Trans>All saved</Trans>}
        />
      )}
    </React.Fragment>
  );
};
