export type ProjectWizardInputValue = string | number;

export interface ProjectWizardInputCondition {
  inputName: string;
  inputValues?: ProjectWizardInputValue[];
}

export interface ProjectWizardInputOption {
  text: string;
  value: ProjectWizardInputValue;
}

export interface ProjectWizardFormInputData {
  name: string;
  value: ProjectWizardInputValue;
}

export interface ProjectWizardInputValuesMap {
  [key: string]: ProjectWizardInputValue;
}

export enum InputValueSuggesterType {
  Industry = 'Industry',
}

export interface ProjectWizardInput {
  name: string;
  conditions: ProjectWizardInputCondition[];
  options: ProjectWizardInputOption[];
  title?: string;
  subtitle?: string;
  suggester?: InputValueSuggesterType;
}

export interface ProjectWizardResult {
  conditions: ProjectWizardInputCondition[];
  projectProps: {
    title: string;
  };
}
