import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import { UserContextSelectors } from '../../security/store/selectors';
import { Trans } from 'react-i18next';
import { Box, Button, Divider, Hidden, MenuItem, MenuList, Typography } from '@mui/material';
import { UserContextActions } from '../../security/store/actions';
import { UserActions } from '../store/actions/user.actions';
import { AuthService } from '@priz/shared/src/services/auth';
import { Role } from '@priz/shared/src/models/security/role.enum';
import { WorkspaceSelector } from '../../navigation/workspace-selector/component';
import { RenderForRoles } from '../../react/security/render-for-roles/component';
import { UserAvatar } from '@priz/shared/src/components/user-avatar/component';
import { useNavigate, useLocation, matchPath, Link } from 'react-router-dom';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { settingsLinks } from './data';
import { LocaleButton } from '@priz/shared/src/components/locales/locale-button/component';
import { Locales } from '@priz/shared/src/models/locales';

import { ReactComponent as AngleArrowDownIcon } from '../../../assets/icons/pg-angle-arrow-down.svg';
import { DialogsSelectors } from '../../dialogs/store/selectors';

interface CurrentUserHeadingButtonProps {
  showWorkspaceSelectorOnMobile?: boolean;
}

export const CurrentUserHeadingButton: React.FC<CurrentUserHeadingButtonProps> = ({
  showWorkspaceSelectorOnMobile,
}) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const userContextLoaded = useSelector(UserContextSelectors.isLoaded);
  const user = useSelector(UserContextSelectors.getCurrentUser);
  const isAnyDialogOpen = useSelector(DialogsSelectors.isAnyOpen);

  useEffect(() => {
    dispatch(UserActions.loadCurrentUser());
    dispatch(UserContextActions.load(pathname, navigate));
  }, []);

  const isAdmin = () => {
    return AuthService.access([Role.ROLE_ADMIN]);
  };

  const isTranslator = () => {
    return AuthService.access([Role.ROLE_TRANSLATOR]);
  };

  return (
    <div className={styles.root}>
      {userContextLoaded && (
        <>
          <PopperWrap
            dropdownProps={{ p: 0 }}
            arrow={false}
            placement={'bottom-end'}
            open={isAnyDialogOpen ? false : undefined}
          >
            <Button className={styles.button} variant={'pg_transparent'} endIcon={<AngleArrowDownIcon />}>
              <UserAvatar user={user} />
            </Button>

            <MenuList>
              <Box maxWidth={140} px={1} mb={0.5}>
                <LocaleButton locale={Locales.EN} />
                <LocaleButton locale={Locales.ES} />
                <LocaleButton locale={Locales.ZH} />
              </Box>

              {showWorkspaceSelectorOnMobile && (
                <Box>
                  <Hidden smUp>
                    <WorkspaceSelector />
                    <Box py={1}>
                      <Divider />
                    </Box>
                  </Hidden>
                </Box>
              )}

              <MenuItem
                component={Link}
                to={'/profile'}
                disabled={!!matchPath('/profile', pathname)}
                className={styles.menuItem}
              >
                <Typography variant="body2" component="span">
                  <Trans>My Profile</Trans>
                </Typography>
              </MenuItem>

              <MenuItem component={Link} to={'/logout'} className={styles.menuItem}>
                <Typography variant="body2" component="span">
                  <Trans>Log out</Trans>
                </Typography>
              </MenuItem>

              {(isAdmin() || isTranslator()) && (
                <Box>
                  <Box py={1}>
                    <Divider />
                  </Box>

                  <Box px={2} py={1}>
                    <Typography variant="caption" component="span">
                      <Box fontWeight="fontWeightBold">
                        <Trans>Settings</Trans>
                      </Box>
                    </Typography>
                  </Box>

                  <RenderForRoles roles={[Role.ROLE_ADMIN, Role.ROLE_TRANSLATOR]}>
                    <>
                      {settingsLinks.map((item, key) => (
                        <RenderForRoles key={key} roles={item.roles}>
                          <MenuItem
                            component={Link}
                            to={item.to}
                            disabled={!!matchPath(item.to, pathname)}
                            className={styles.menuItem}
                          >
                            <Typography variant="body2" component="span">
                              <Trans>{item.label}</Trans>
                            </Typography>
                          </MenuItem>
                        </RenderForRoles>
                      ))}
                    </>
                  </RenderForRoles>
                </Box>
              )}
            </MenuList>
          </PopperWrap>
        </>
      )}
    </div>
  );
};
