import React from 'react';
import { useStyles } from './styles';
import { Perception, CalcResult } from '../../models/tools/pmap';
import { Box, Card, Grid, Typography, Divider } from '@mui/material';
import { Trans } from 'react-i18next';
import { BoxProps } from '@mui/material';

interface PMapItemProps extends BoxProps {
  perception: Perception;
  calcResult: CalcResult;
  compact?: boolean;
}

export const PMapCard: React.FC<PMapItemProps> = ({ perception, calcResult, compact, ...rest }) => {
  const styles = useStyles();
  const { letter, description, assign, conflict } = perception;
  const { blockingRank, sum } = calcResult.map[letter] || {};

  return (
    <Box className={`${styles.root}${compact ? ' _compact' : ''}`} mb={2} {...rest}>
      <Card className={styles.card} elevation={compact ? 0 : 3} variant={compact ? 'outlined' : 'elevation'}>
        <Box p={compact ? 1 : 2}>
          <Grid container spacing={compact ? 1 : 2}>
            <Grid item container xs={compact ? 7 : 8}>
              <Grid item className={styles.letterColumn}>
                <div className={styles.letterWrap}>
                  <Typography className={styles.letter} variant="h6" component="span">
                    {letter}
                  </Typography>
                </div>
              </Grid>
              <Grid item container alignItems={'flex-start'} xs={true}>
                <Grid container item xs={true} alignItems={'center'} className={styles.descriptionWrap}>
                  <Box pr={compact ? 1 : 4}>
                    <Typography variant="body1" component="span" className={styles.description}>
                      {description}
                    </Typography>
                  </Box>
                </Grid>

                <Divider orientation="vertical" flexItem />
              </Grid>
            </Grid>
            <Grid item container xs={compact ? 5 : 4}>
              <Grid item xs={12}>
                <Grid container spacing={compact ? 1 : 2}>
                  <Grid item xs={6}>
                    {assign && (
                      <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
                        <Box pr={compact ? 0.5 : 1}>
                          <Typography variant="caption" component="span">
                            <Trans>Leads to</Trans>:
                          </Typography>
                        </Box>
                        <Typography className={`${styles.letter} _small`} variant="subtitle2" component="span">
                          {assign}
                        </Typography>
                      </Box>
                    )}

                    {conflict && (
                      <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
                        <Box pr={compact ? 0.5 : 1}>
                          <Typography variant="caption" component="span">
                            <Trans>Contradicts with</Trans>:
                          </Typography>
                        </Box>
                        <Typography className={`${styles.letter} _small`} variant="subtitle2" component="span">
                          {conflict}
                        </Typography>
                      </Box>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
                      <Box pr={compact ? 0.5 : 1}>
                        <Typography variant="caption" component="span">
                          <Trans>Sum</Trans>:
                        </Typography>
                      </Box>
                      <Typography variant="subtitle2" component="span">
                        {sum}
                      </Typography>
                    </Box>

                    <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
                      <Box pr={compact ? 0.5 : 1}>
                        <Typography variant="caption" component="span">
                          <Trans>Blocking rank</Trans>:
                        </Typography>
                      </Box>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        className={`${styles.blockingRank} _${blockingRank}`}
                      >
                        {blockingRank}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};
