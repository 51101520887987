import { ActionSuffix } from '../../../../shared/store/action-suffix.enum';
import { UserActionType } from '../../../../user/store/actions/user.actions';

export const defaultMessagesMap = {
  [ActionSuffix.Success]: 'Data successfully updated!',
  [ActionSuffix.Failed]: 'Something went wrong!',
};

export const successMessagesMap = {
  [UserActionType.UpdateProfile]: 'Personal information successfully updated!',
  [UserActionType.UpdateAvatar]: 'Avatar successfully updated!',
  [UserActionType.UpdatePassword]: 'Password successfully changed!',
  [UserActionType.UpdatePrivacySettings]: 'Privacy settings updated!',
};
