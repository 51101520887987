import { Domain } from '@priz/shared/src/models/tools/forty-principles/domain';
import { CreateDomainAction, DeleteDomainAction, DomainAction, DomainActionType, DomainsAction } from '../actions';
import { DefaultDomainList, DomainList } from '../model';
import { handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { CollectionStatusesService } from '@priz/shared/src/services/statuses/collection-statuses.service';

export const domainReducers = handleActions(
  {
    // Fetch

    [toStarted(DomainActionType.Fetch)]: (state: DomainList): DomainList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        loading: true,
        loaded: false,
      }),
    }),
    [toFailed(DomainActionType.Fetch)]: (state: DomainList): DomainList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        loading: false,
        loaded: false,
        error: true,
      }),
    }),
    [toSuccess(DomainActionType.Fetch)]: (state: DomainList, action: DomainsAction): DomainList => {
      const domains = action.payload.map(domainData => new Domain(domainData));

      return {
        ...state,
        domains,
        statuses: CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses,
          loading: false,
          loaded: true,
          error: false,
        }),
      };
    },

    // Update

    [toStarted(DomainActionType.Update)]: (state: DomainList, action: DomainAction): DomainList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        updating: true,
        updated: false,
        idsUpdate: [{ target: 'updatingIds', method: 'add', ids: [action.meta.domainId] }],
      }),
    }),
    [toFailed(DomainActionType.Update)]: (state: DomainList, action: DomainAction): DomainList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        updating: false,
        updated: false,
        error: true,
        idsUpdate: [{ target: 'updatingIds', method: 'remove', ids: [action.meta.domainId] }],
      }),
    }),
    [toSuccess(DomainActionType.Update)]: (state: DomainList, action: DomainAction): DomainList => {
      const updatedDomains = state.domains.map(item => (item.id === action.payload.id ? action.payload : item));

      return {
        ...state,
        domains: updatedDomains,
        statuses: CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses,
          updating: false,
          updated: true,
          error: false,
          idsUpdate: [{ target: 'updatingIds', method: 'remove', ids: [action.meta.domainId] }],
        }),
      };
    },

    // Create

    [toStarted(DomainActionType.Create)]: (state: DomainList): DomainList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        creating: true,
        created: false,
      }),
    }),
    [toFailed(DomainActionType.Create)]: (state: DomainList): DomainList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        creating: false,
        created: false,
        error: true,
      }),
    }),
    [toSuccess(DomainActionType.Create)]: (state: DomainList, action: CreateDomainAction): DomainList => ({
      ...state,
      domains: [...state.domains, action.payload.domain],
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        creating: false,
        created: true,
        error: false,
      }),
    }),

    // Delete

    [toStarted(DomainActionType.Delete)]: (state: DomainList, action: DeleteDomainAction): DomainList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        removing: true,
        removed: false,
        idsUpdate: [{ target: 'removingIds', method: 'add', ids: [action.meta.domainId] }],
      }),
    }),
    [toFailed(DomainActionType.Delete)]: (state: DomainList, action: DeleteDomainAction): DomainList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        removing: false,
        removed: false,
        error: true,
        idsUpdate: [{ target: 'removingIds', method: 'remove', ids: [action.meta.domainId] }],
      }),
    }),
    [toSuccess(DomainActionType.Delete)]: (state: DomainList, action: DeleteDomainAction): DomainList => ({
      ...state,
      domains: state.domains.filter(d => d.id !== action?.meta?.domainId),
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        removing: false,
        removed: true,
        error: false,
        idsUpdate: [{ target: 'removingIds', method: 'remove', ids: [action.meta.domainId] }],
      }),
    }),
  },
  DefaultDomainList,
);
