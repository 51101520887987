import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const DeleteIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon width={18} height={18} viewBox="0 0 18 18" {...props}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.70312 7.83739H8.29688V15.185H9.70312V7.83739Z" fill="#1198D2" />
        <path d="M12.5156 7.83739H11.1094V15.185H12.5156V7.83739Z" fill="#1198D2" />
        <path d="M6.89062 7.83739H5.48438V15.185H6.89062V7.83739Z" fill="#1198D2" />
        <path
          d="M14.4844 2.56405H12.3398V2.10938C12.3398 0.946266 11.3936 0 10.2305 0H7.76953C6.60642 0 5.66016 0.946266 5.66016 2.10938V2.56405H3.51562C2.35252 2.56405 1.40625 3.51032 1.40625 4.67343V6.43359H2.8125V15.8906C2.8125 17.0537 3.75877 18 4.92188 18H13.0781C14.2412 18 15.1875 17.0537 15.1875 15.8906V6.43359H16.5938V4.67343C16.5938 3.51032 15.6475 2.56405 14.4844 2.56405ZM7.06641 2.10938C7.06641 1.72167 7.38183 1.40625 7.76953 1.40625H10.2305C10.6182 1.40625 10.9336 1.72167 10.9336 2.10938V2.56405H7.06641V2.10938ZM13.7812 15.8906C13.7812 16.2783 13.4658 16.5938 13.0781 16.5938H4.92188C4.53417 16.5938 4.21875 16.2783 4.21875 15.8906V6.43359H13.7812V15.8906ZM15.1875 5.02734H2.8125V4.67343C2.8125 4.28572 3.12792 3.9703 3.51562 3.9703H14.4844C14.8721 3.9703 15.1875 4.28572 15.1875 4.67343V5.02734Z"
          fill="#1198D2"
        />
      </svg>
    </SvgIcon>
  );
};
