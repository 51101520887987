import React from 'react';
import { Country } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import { Box } from '@mui/material';
import { useStyles } from './styles';
import { PublicOutlined } from '@mui/icons-material';

interface FlagIconProps {
  country?: Country;
  title?: string;
}

export const FlagIcon: React.FC<FlagIconProps> = ({ country, title = '' }) => {
  const styles = useStyles();
  const flagRenderer = flags[country];
  const rootClassNames = [styles.root];

  if (flagRenderer) rootClassNames.push(styles.shadow);

  return (
    <Box className={rootClassNames.join(' ')}>
      {flags[country] ? flags[country]({ title }) : <PublicOutlined sx={{ margin: 'auto' }} />}
    </Box>
  );
};
