import React from 'react';
import { IdeaComment } from '@priz/shared/src/models/idea';
import { Alert, Box, BoxProps, Chip, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { ProjectAssigneeSelectors } from '../../assignment/store/selectors/project-assignee.selectors';
import { UserAvatar } from '@priz/shared/src/components/user-avatar/component';
import { Trans } from 'react-i18next';
import { ValueBar } from '@priz/shared/src/components/value-bar/component';
import { WarningAmberRounded } from '@mui/icons-material';
import { checkIsCommentValid } from '../utils';

interface IdeaCommentCardProps extends BoxProps {
  comment: IdeaComment;
}

interface CommentRowData {
  title: string;
  description?: string;
  value?: number;
  reverseValueColor?: boolean;
}

const resolveCommentRowsData = (comment: IdeaComment): CommentRowData[] => {
  return [
    {
      title: 'Gain',
      description: comment.gainDescription,
      value: comment.gainValue,
    },
    {
      title: 'Cost',
      description: comment.costDescription,
      value: comment.costValue,
      reverseValueColor: true,
    },
    {
      title: 'Risk',
      description: comment.riskDescription,
      value: comment.riskValue,
      reverseValueColor: true,
    },
  ].filter(item => item.description?.length || typeof item.value === 'number');
};

export const IdeaCommentCard: React.FC<IdeaCommentCardProps> = ({ comment, ...rest }) => {
  const assignees = useSelector(ProjectAssigneeSelectors.getProjectAssigneesWithMemberMap(comment.project.id));

  const rowsData = resolveCommentRowsData(comment);
  const isValid = checkIsCommentValid(comment);

  return (
    <Paper variant={'outlined'} component={Box} p={2} {...rest}>
      <Grid container alignItems={'center'} spacing={1}>
        <Grid item xs={true}>
          {assignees[comment.createdBy.id]?.user && (
            <UserAvatar user={assignees[comment.createdBy.id].user} showName={true} />
          )}
        </Grid>

        {!isValid && (
          <Grid item xs={'auto'}>
            <Tooltip title={<Trans>Comment is not completed</Trans>} disableInteractive arrow placement={'top'}>
              <WarningAmberRounded fontSize={'medium'} color={'warning'} style={{ display: 'block', cursor: 'help' }} />
            </Tooltip>
          </Grid>
        )}
      </Grid>

      {!rowsData.length && (
        <Box mt={2}>
          <Alert severity={'info'}>
            <Trans>No details provided</Trans>
          </Alert>
        </Box>
      )}

      {rowsData.map((item, key) => (
        <Box key={key} mt={2}>
          <Box maxWidth={300}>
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item xs={'auto'}>
                <Typography variant={'subtitle2'}>
                  <Trans>{item.title}</Trans>
                </Typography>
              </Grid>

              {typeof item.value === 'number' && (
                <>
                  <Grid item xs={'auto'}>
                    <Chip label={item.value} size={'small'} />
                  </Grid>

                  <Grid item xs={true}>
                    <ValueBar value={item.value} green={6} blue={3} reverse={item.reverseValueColor} />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>

          {!!item.description?.length && (
            <Typography variant={'body2'} component={Box} mt={1}>
              {item.description}
            </Typography>
          )}
        </Box>
      ))}
    </Paper>
  );
};
