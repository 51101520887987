import { SagaMiddleware } from '@redux-saga/core';
import { all } from 'redux-saga/effects';
import { workspaceSagas } from '../workspace/store/sagas/workspace.sagas';
import { notificationSagas } from '../react/modules/notification/store';
import { allActionsDoneSagas } from '../react/preloader/store/sagas/all-actions-done.sagas';
import { domainSagas } from '../forty-principles/store/sagas/domain.sagas';
import { projectLastChangedSagas } from '../project/store/sagas/last-changed-listener.sagas';

function* rootSaga() {
  yield all([workspaceSagas(), notificationSagas(), allActionsDoneSagas(), projectLastChangedSagas(), domainSagas()]);
}

export const registerSagas = (sagaMiddleware: SagaMiddleware) => {
  sagaMiddleware.run(rootSaga);
};
