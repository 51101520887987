import { AuditableEntity } from '../../models/common/auditable-entity';

const sorterByDateCreated = (left: AuditableEntity, right: AuditableEntity): number => {
  if (left?.dateCreated < right?.dateCreated) {
    return -1;
  }
  if (left?.dateCreated > right?.dateCreated) {
    return 1;
  }
  return 0;
};

export enum SortDirection {
  ASC = 1,
  DESC = -1,
}

const sorterByParam = (param: string, direction: SortDirection) => {
  return (left: { [key: string]: any }, right: { [key: string]: any }): number => {
    if (left[param] === null || typeof left[param] === 'undefined') return -1 * direction;
    if (right[param] === null || typeof right[param] === 'undefined') return 1 * direction;
    if (left[param] < right[param]) return -1 * direction;
    if (left[param] > right[param]) return 1 * direction;
    return 0;
  };
};

const fromMap = <I>(value: { [key: number]: I }): I[] =>
  (value && typeof value === 'object' && Object.values(value)) || [];

const resolveUniqueItemsArray = <I>(array: I[]): I[] =>
  (array &&
    Array.isArray(array) &&
    (Array.from(new Set(array.map(item => JSON.stringify(item)))).map(item => JSON.parse(item)) as I[])) ||
  array;

export const resolveArraysDifference = <I>(arrays: I[][]): I[] => {
  const flatArray = arrays.reduce((acc, val) => acc.concat(val), []);
  const diffItems: I[] = [];

  flatArray.forEach(value => {
    if (flatArray.filter(item => item === value).length === 1) diffItems.push(value);
  });

  return diffItems;
};

export const ArrayUtils = {
  sorterByDateCreated,
  sorterByParam,
  fromMap,
  resolveUniqueItemsArray,
  resolveArraysDifference,
};
