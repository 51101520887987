import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import {
  FortyPrinciplesUtilization,
  IFortyPrinciplesUtilization,
} from '../../models/tools/forty-principles/forty-principles-utilization';
import { InventivePrinciplesContradictionMap } from '../../data/inventive-principles';
import { useStyles } from './styles';

interface ContradictionTableProps {
  utilization: FortyPrinciplesUtilization;
  highlight?: keyof IFortyPrinciplesUtilization;
}

export const ContradictionTable: React.FC<ContradictionTableProps> = ({ utilization, highlight }) => {
  const styles = useStyles();

  return (
    <TableContainer component={'div'} className={styles.root}>
      <Table>
        <TableBody>
          {InventivePrinciplesContradictionMap.map(({ name, label }, key) => (
            <TableRow key={key}>
              <TableCell component="th" scope="row" padding={'none'} align={'left'} className={styles.tableCell}>
                <Box pr={2}>
                  <Typography variant={'body1'} component={'span'} className={styles.bold}>
                    <Trans>{label}</Trans>
                  </Typography>
                </Box>
              </TableCell>

              <TableCell style={{ width: '100%' }} padding={'none'}>
                <Box pb={key === InventivePrinciplesContradictionMap.length - 1 ? 0 : 3}>
                  <Box pl={3} className={styles.textContainer}>
                    <Typography
                      variant={'body1'}
                      component={'span'}
                      className={highlight === name ? styles.highlight : undefined}
                    >
                      {utilization[name]}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
