import { Alert, Box, BoxProps, Link } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { WorkspaceSelectors } from '../../../../workspace/store/selectors';
import { WorkspaceMembersSelectors } from '../../../../workspace/store/selectors/workspace-members.selectors';
import { UserSelectors } from '../../../../user/store/selectors/user.selectors';
import { WorkspaceMembershipLevel } from '@priz/shared/src/models/workspace';
import { FinancialsManagementType } from '@priz/shared/src/models/workspace/financials-management-type.enum';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { WorkspaceActions, WorkspaceMembersActions } from '../../../../workspace/store/actions';
import { UserActions } from '../../../../user/store/actions/user.actions';
import { Link as RouterLink } from 'react-router-dom';

export const TeamBlockerMessage: React.FC<BoxProps> = props => {
  const dispatch = useDispatch();

  const selectedWorkspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const workspaceMembers = useSelector(WorkspaceMembersSelectors.getAll);
  const currentUser = useSelector(UserSelectors.currentUserSelector);

  const workspacesAreLoaded = useSelector(WorkspaceSelectors.isLoaded);
  const workspaceMembersAreLoaded = useSelector(WorkspaceMembersSelectors.isLoaded);
  const currentUserIsLoaded = useSelector(UserSelectors.isLoaded);
  const isLoaded = workspacesAreLoaded && workspaceMembersAreLoaded && currentUserIsLoaded;

  const isSelf = selectedWorkspace?.financialsManagementType === FinancialsManagementType.Self;
  const isManaged = selectedWorkspace?.financialsManagementType === FinancialsManagementType.Managed;
  const workspaceAdmin = workspaceMembers?.find(member => member.membershipLevel === WorkspaceMembershipLevel.Admin);
  const isCurrentUserAdmin =
    typeof currentUser?.id !== 'undefined' &&
    typeof workspaceAdmin?.id !== 'undefined' &&
    currentUser.id === workspaceAdmin.id;

  useEffect(() => {
    if (!workspacesAreLoaded) dispatch(WorkspaceActions.loadAll());
  }, [workspacesAreLoaded]);

  useEffect(() => {
    if (!workspaceMembersAreLoaded) dispatch(WorkspaceMembersActions.loadAll());
  }, [workspaceMembersAreLoaded]);

  useEffect(() => {
    if (!currentUserIsLoaded) dispatch(UserActions.loadCurrentUser());
  }, [currentUserIsLoaded]);

  if (!isLoaded) {
    return (
      <Box py={2} position={'relative'}>
        <LoadingOverlay loading={true} backdropStyles={{ position: 'relative', background: 'transparent' }} />
      </Box>
    );
  }

  return (
    <Box {...props}>
      {isSelf && isCurrentUserAdmin && (
        <Alert severity={'info'}>
          <Trans>There are no more seats available to invite new users</Trans>. <Trans>Please go to</Trans>{' '}
          <Link component={RouterLink} to={'/workspace/billing'}>
            <Trans>billing page</Trans>
          </Link>{' '}
          <Trans>to add more seats</Trans>. <Trans>Only non-chargeable users can be added.</Trans>
        </Alert>
      )}

      {isSelf && !isCurrentUserAdmin && (
        <Alert severity={'info'}>
          <Trans>
            There are no more seats available to invite new users. Contact workspace admin to add more seats.
          </Trans>{' '}
          <Trans>Only non-chargeable users can be added.</Trans>
        </Alert>
      )}

      {isManaged && (
        <Alert severity={'info'}>
          <Trans>
            There are no more seats available to invite new users. Please, contact with PRIZ Guru Team members to add
            more seats.
          </Trans>{' '}
          <Trans>Only non-chargeable users can be added.</Trans>
        </Alert>
      )}
    </Box>
  );
};
