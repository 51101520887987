import { FluxStandardAction } from 'flux-standard-action';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { createPgAction } from '../../../shared/store/action-creator';
import { IdeaCommentsApi } from '../../services/idea-comments-api';
import { IIdeaComment } from '@priz/shared/src/models/idea';

export enum IdeasCommentsActionType {
  LoadForProject = 'idea-comments/load-for-project',
  LoadForIdea = 'idea-comments/load-for-idea',
  LoadComment = 'idea-comment/load',
  CreateComment = 'idea-comment/create',
  UpdateComment = 'idea-comment/update',
  DeleteComment = 'idea-comment/delete',
}

type IdeasCommentsActionPayload = IIdeaComment | IIdeaComment[];

interface IdeasCommentsActionMeta {
  projectId: number;
  ideaId?: number;
  commentId?: number;
}

export type IdeasCommentsAction = FluxStandardAction<string, IdeasCommentsActionPayload, IdeasCommentsActionMeta>;

const getAllIdeasCommentsForProject = createPgAction(
  IdeasCommentsActionType.LoadForProject,
  IdeaCommentsApi.getAllIdeasCommentsForProject,
  (projectId: number) => ({ projectId }),
);
const getAllCommentsForIdea = createPgAction(
  IdeasCommentsActionType.LoadForIdea,
  IdeaCommentsApi.getIdeaComments,
  (projectId: number, ideaId: number) => ({ projectId, ideaId }),
);
const getIdeaComment = createPgAction(
  IdeasCommentsActionType.LoadComment,
  IdeaCommentsApi.getIdeaComment,
  (projectId: number, ideaId: number, commentId: number) => ({ projectId, ideaId, commentId }),
);

const createComment = createPgAction(
  IdeasCommentsActionType.CreateComment,
  IdeaCommentsApi.createComment,
  (projectId: number, ideaId: number) => ({ projectId, ideaId }),
  (_payload: IdeasCommentsActionPayload, meta: IdeasCommentsActionMeta) => {
    void AnalyticsService.track(AnalyticsEvent.IDEA_COMMENT_CREATED, {
      project_id: meta.projectId,
    });
  },
);

const updateComment = createPgAction(
  IdeasCommentsActionType.UpdateComment,
  IdeaCommentsApi.updateComment,
  (projectId: number, ideaId: number, commentId: number) => ({ projectId, ideaId, commentId }),
  (_payload, meta) => {
    void AnalyticsService.track(AnalyticsEvent.IDEA_COMMENT_UPDATED, {
      project_id: meta?.projectId,
      comment_id: meta?.commentId,
    });
  },
);

const deleteComment = createPgAction(
  IdeasCommentsActionType.DeleteComment,
  IdeaCommentsApi.deleteComment,
  (projectId: number, ideaId: number, commentId: number) => ({ projectId, ideaId, commentId }),
  (_payload, meta) => {
    void AnalyticsService.track(AnalyticsEvent.IDEA_COMMENT_DELETED, {
      project_id: meta?.projectId,
      comment_id: meta?.commentId,
    });
  },
);

export const IdeasCommentsActions = {
  getAllIdeasCommentsForProject,
  getAllCommentsForIdea,
  getIdeaComment,
  createComment,
  updateComment,
  deleteComment,
};
