import { IToolUtilization, ToolUtilization } from '../index';

// tslint:disable-next-line:no-empty-interface
export type IFiveWhysUtilization = IToolUtilization;

export class FiveWhysUtilization extends ToolUtilization implements IFiveWhysUtilization {
  constructor(json: IFiveWhysUtilization) {
    super(json);
  }
}
