import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { useStyles } from './styles';
import { Trans } from 'react-i18next';
import { AppPublicNavbar } from '../navigation/app-public-navbar/component';
import { PageContainer } from '../content-containers/page-container/component';

import errorImage from '@priz/shared/src/assets/images/error-screen.svg';
import notFoundImage from '@priz/shared/src/assets/images/not-found-screen.svg';
import { useRouteError } from 'react-router-dom';

export enum ErrorPageType {
  Error = 'Error',
  NotFound = 'NotFound',
}

interface ErrorPageProps {
  type?: ErrorPageType;
  renderNavbar?: boolean;
  customMessage?: string;
}

const dataMap = {
  [ErrorPageType.Error]: {
    image: errorImage,
    message: 'An error has occurred',
  },
  [ErrorPageType.NotFound]: {
    image: notFoundImage,
    message: 'Sorry, that page was here just a minute ago...',
  },
};

export const ErrorPage: React.FC<ErrorPageProps> = ({
  type = ErrorPageType.Error,
  renderNavbar = true,
  customMessage,
}) => {
  const error = useRouteError();
  const styles = useStyles();
  const { image, message } = dataMap[type];
  const errorMessage = (error as Error)?.message;

  return (
    <PageContainer variant={'narrow'}>
      {renderNavbar && <AppPublicNavbar />}

      <Box pb={10} className={styles.content}>
        <Box mb={6}>
          <img src={image} alt={'img'} className={styles.image} />
        </Box>

        <Typography component={Box} mb={2} variant={'h2_hub'} className={styles.title}>
          <Trans>{customMessage || errorMessage || message}</Trans>
        </Typography>

        <Typography variant={'body0'}>
          <Trans>Nevermind, let's just</Trans>{' '}
          <Link href={'/'} underline={'hover'}>
            <Trans>start over here</Trans>
          </Link>
        </Typography>
      </Box>
    </PageContainer>
  );
};
