import { LinearProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const PgProgressBar = withStyles({
  colorPrimary: {
    backgroundColor: pgColorScheme.lightGray,
  },
  colorSecondary: {
    backgroundColor: pgColorScheme.lightGray,
  },
  colorSuccess: {
    backgroundColor: pgColorScheme.lightGray,
  },
  root: {
    borderRadius: '10px',
    height: '8px',
  },
})(LinearProgress);
