import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  flexContainer: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  selectPageInput: {
    '& .MuiOutlinedInput-input': {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
});
