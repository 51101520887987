import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      zIndex: 3,
    },
    menuItem: {
      minHeight: 'unset',
    },
    popper: {
      zIndex: 999,
    },
    localeButtonsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      width: 'max-content',
      maxWidth: 150,
    },
    button: {},

    [theme.breakpoints.down('sm')]: {
      button: {
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
  }),
);
