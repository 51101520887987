import { EntityCollectionStatus, EntityMap, LookupMap } from '@priz/shared/src/models/common/entity-collection-state';
import { IdeaComment } from '@priz/shared/src/models/idea';

export interface ProjectIdeasCommentsLookups {
  byProjectId: LookupMap;
  byIdeaId: LookupMap;
}

export interface ProjectIdeasCommentsStatuses {
  byProjectId: {
    [projectId: number]: EntityCollectionStatus;
  };
  byIdeaId: {
    [ideaId: number]: EntityCollectionStatus;
  };
}

export interface ProjectIdeasCommentsState {
  entities: EntityMap<IdeaComment>;
  lookups: ProjectIdeasCommentsLookups;
  statuses: ProjectIdeasCommentsStatuses;
}

export const DefaultProjectIdeasCommentsState: ProjectIdeasCommentsState = {
  entities: {},
  lookups: {
    byProjectId: {},
    byIdeaId: {},
  },
  statuses: {
    byProjectId: {},
    byIdeaId: {},
  },
};
