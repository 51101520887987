import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CftUtilizationActions, CftUtilizationActionType } from '../store/actions';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { ToolVersionsTabs } from '../../tools/tool-version-tabs/component';
import { CftUtilization } from '@priz/shared/src/models/tools/cft';
import {
  CftCreateVersionCommand,
  CftRemoveVersionCommand,
  CftSwitchVersionCommand,
  CftUpdateVersionTitleCommand,
} from '../store/model';

interface CftVersionTabsProps {
  cftUtilization: CftUtilization;
  activeVersionId?: string;
  onVersionSelect?: (versionId: string) => void;
  disabled?: boolean;
}

export const CftVersionsTabs: React.FC<CftVersionTabsProps> = ({
  cftUtilization,
  activeVersionId,
  onVersionSelect,
  disabled,
}) => {
  const dispatch = useDispatch();

  const isVersionUpdatePending = useSelector(
    ToolUtilizationSelector.isAnyActionPending(cftUtilization.id, [
      CftUtilizationActionType.CreateNewVersion,
      CftUtilizationActionType.UpdateVersionTitle,
      CftUtilizationActionType.SwitchVersion,
      CftUtilizationActionType.RemoveVersion,
    ]),
  );

  const switchVersion = (versionId: string) => {
    const command: CftSwitchVersionCommand = {
      versionId,
    };

    dispatch(CftUtilizationActions.switchVersion(cftUtilization.id, command, cftUtilization.project?.id));
  };

  const renameVersion = (versionId: string, title: string) => {
    const command: CftUpdateVersionTitleCommand = {
      versionId,
      title,
    };

    dispatch(CftUtilizationActions.updateVersionTitle(cftUtilization.id, command, cftUtilization.project?.id));
  };

  const deleteVersion = (versionId: string) => {
    const command: CftRemoveVersionCommand = {
      versionId,
    };

    dispatch(CftUtilizationActions.removeVersion(cftUtilization.id, command, cftUtilization.project?.id));
  };

  const createNewVersion = (sourceVersionId?: string) => {
    const command: CftCreateVersionCommand = {
      sourceVersionId,
    };

    dispatch(CftUtilizationActions.createNewVersion(cftUtilization.id, command, cftUtilization.project?.id));
  };

  return (
    <ToolVersionsTabs
      utilizationId={cftUtilization.id}
      diagramData={cftUtilization.diagramData}
      activeVersionId={activeVersionId}
      loading={isVersionUpdatePending}
      disabled={disabled}
      onVersionCreate={createNewVersion}
      onVersionSwitch={switchVersion}
      onVersionDelete={deleteVersion}
      onVersionRename={renameVersion}
      onVersionSelect={onVersionSelect}
    />
  );
};
