import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    width: 'auto',
    height: 'auto',
    position: 'absolute',
    maxHeight: 'calc(100% - 20px)',
    top: 0,
    right: 0,
    left: 'auto',
    bottom: 'auto',
    zIndex: 15,
    paddingTop: 10,
    paddingRight: 10,

    ['&:not(._open)']: {
      pointerEvents: 'none',

      ['& $menuWrap']: {
        opacity: 0,
      },
    },
  },
  hamburgerWrap: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 'auto',
    bottom: 'auto',
    zIndex: 2,
    pointerEvents: 'auto',
  },
  menuWrap: {
    position: 'relative',
    width: 'auto',
    height: 'auto',
    backgroundColor: 'rgba(255,255,255,0.8)',
    zIndex: 1,
    transition: 'opacity .3s ease',
  },
  menu: {
    width: 240,
  },
  textField: {
    width: '100%',

    ['& .MuiOutlinedInput-inputMultiline']: {
      padding: 12,
    },
  },
  revertIcon: {
    display: 'block',
    width: 24,
    height: 'auto',
    color: pgColorScheme.blue,
  },
  iconButton: {
    padding: 8,
  },
});
