import { LocalStorageKey, LocalStorageService } from '../local-storage';
import { Locales } from '../../models/locales';
import moment, { Moment } from 'moment-timezone';
import 'moment/locale/es';
import 'moment/locale/zh-cn';

export interface TimeZoneLabel {
  label: string;
  codeLabel: string;
  offsetLabel: string;
}

export interface TimeZoneProps extends TimeZoneLabel {
  code: string;
}

export type anyDate = Date | string | number | Moment;

const getTimeZoneCodes = (): string[] => {
  return moment.tz.names();
};

const getLabel = (timeZoneCode: string): TimeZoneLabel => {
  const offset = moment().tz(timeZoneCode).format('Z');
  const codeLabel = timeZoneCode.split('_').join(' ');
  const offsetLabel = 'GMT' + offset;

  return {
    codeLabel,
    offsetLabel,
    label: `${codeLabel} ${offsetLabel}`,
  };
};

const getLocalTimeZone = (): TimeZoneProps => {
  const code = moment.tz.guess();
  const label = getLabel(code);

  return { code, ...label };
};

const getCurrentTimeZone = (): TimeZoneProps => {
  const code = moment().tz() || moment.tz.guess();
  const label = getLabel(code);

  return { code, ...label };
};

const getTimeZones = (): TimeZoneProps[] => {
  return getTimeZoneCodes().map(code => ({ code, ...getLabel(code) }));
};

const setTimeZone = (timeZoneCode?: string): void => {
  moment.tz.setDefault(timeZoneCode);
};

const initLocale = () => {
  const locale = (LocalStorageService.getItem(LocalStorageKey.Locale) as Locales) || Locales.EN;
  moment.locale(locale);
};

const format = (date: Moment | Date | number, format?: string, showTime = true): string => {
  const momentDate: Moment =
    typeof date === 'number' ? moment(new Date(date)) : date instanceof Date ? moment(date) : date;

  return momentDate.format(format ? format : showTime ? 'MMM D YYYY HH:mm' : 'MMM D YYYY');
};

export const TimeZoneService = {
  getTimeZoneCodes,
  getTimeZones,
  getLabel,
  getLocalTimeZone,
  getCurrentTimeZone,
  setTimeZone,
  initLocale,
  format,
};
