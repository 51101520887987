import React, { useEffect, useState } from 'react';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Trans } from 'react-i18next';
import {
  FeatureLimit,
  LevelPlansMap,
  PlanFeaturesMap,
  PlanInterval,
  PlanLevel,
} from '@priz/shared/src/models/workspace';
import { ScalarRow } from './scalar-row/component';
import { ToolsRow } from './tools-rows/component';
import { SecurityRows } from './security-rows/component';
import { SupportRows } from './support-rows/component';
import { useStyles } from './styles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { TitleTableRow } from './title-table-row/components';

interface FeaturesComparisonTableProps {
  planFeaturesMap: PlanFeaturesMap;
  applicableLevels: PlanFeaturesMap;
  plansMap?: LevelPlansMap;
  colsWidth?: string[];
  tableHeadRenderer?: () => JSX.Element;
  isExpanded?: boolean;
  showExpandButton?: boolean;
  onExpandToggle?: () => void;
  borderedColumns?: boolean;
}

export const FeaturesComparisonTable: React.FC<FeaturesComparisonTableProps> = ({
  applicableLevels,
  planFeaturesMap,
  plansMap,
  colsWidth,
  tableHeadRenderer,
  isExpanded,
  onExpandToggle,
  borderedColumns,
  showExpandButton,
}) => {
  const styles = useStyles();
  const numberOfLevels = Object.keys(applicableLevels).length;
  const baseFeatureSet = planFeaturesMap[Object.keys(applicableLevels)[0]];
  const tableClassNames = [styles.table];

  const [expanded, setExpanded] = useState(isExpanded || false);

  if (borderedColumns) tableClassNames.push(styles.tableBordered);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  const expandToggleHandler = () => {
    if (onExpandToggle) {
      onExpandToggle();
    } else {
      setExpanded(currentState => !currentState);
    }
  };

  const headRenderer = () => {
    if (!plansMap) return null;

    return (
      <TableHead>
        <TableRow>
          <TableCell />

          {Object.keys(applicableLevels).map((level: PlanLevel) => (
            <TableCell key={level} align="center">
              <Box>
                <Trans>{plansMap[level][PlanInterval.Yearly]?.name}</Trans>
              </Box>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  return (
    <>
      <TableContainer component={Paper} variant={'outlined'} className={styles.tableContainer}>
        <Table size="small" className={tableClassNames.join(' ')}>
          {tableHeadRenderer ? tableHeadRenderer() : headRenderer()}

          <TableBody>
            <TitleTableRow title={'Features'} columnsCount={numberOfLevels} renderEmptyCells={borderedColumns} />

            <ScalarRow data={applicableLevels} feature={FeatureLimit.MaxProjects} colsWidth={colsWidth} />
            {/*<ScalarRow data={applicableLevels} feature={FeatureLimit.MinWorkspaceMembers} colsWidth={colsWidth} />*/}
            <ScalarRow data={applicableLevels} feature={FeatureLimit.MaxWorkspaceMembers} colsWidth={colsWidth} />
            <ScalarRow data={applicableLevels} feature={FeatureLimit.MaxIdeas} colsWidth={colsWidth} />
            <ScalarRow data={applicableLevels} feature={FeatureLimit.MaxTasks} colsWidth={colsWidth} />
            <ScalarRow data={applicableLevels} feature={FeatureLimit.DataCrowdSourcing} colsWidth={colsWidth} />

            {expanded && (
              <>
                <TitleTableRow
                  title={'Creative tools'}
                  columnsCount={numberOfLevels}
                  renderEmptyCells={borderedColumns}
                />

                <ToolsRow baseFeatureSet={baseFeatureSet} data={applicableLevels} colsWidth={colsWidth} />

                <TitleTableRow title={'Security'} columnsCount={numberOfLevels} renderEmptyCells={borderedColumns} />

                <SecurityRows baseFeatureSet={baseFeatureSet} data={applicableLevels} colsWidth={colsWidth} />

                <TitleTableRow
                  title={'Training & Support'}
                  columnsCount={numberOfLevels}
                  renderEmptyCells={borderedColumns}
                />

                <SupportRows baseFeatureSet={baseFeatureSet} data={applicableLevels} colsWidth={colsWidth} />
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {showExpandButton && (
        <Box pl={2} mt={1}>
          <Button
            variant={'pg_link'}
            endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
            onClick={expandToggleHandler}
          >
            <Trans>{expanded ? 'Hide' : 'Show All'}</Trans>
          </Button>
        </Box>
      )}
    </>
  );
};
