import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PlanLevel } from '@priz/shared/src/models/workspace';
import { Button } from '@mui/material';
import { PlanSelectors } from '../../workspace/store/selectors/plan.selectors';
import { PlanActions, WorkspaceActions } from '../../workspace/store/actions';

interface CancelSubscriptionButtonProps {
  onCancellation?: () => void;
  disabled?: boolean;
}

export const CancelSubscriptionButton: React.FC<CancelSubscriptionButtonProps> = ({
  onCancellation,
  disabled = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentPlan = useSelector(PlanSelectors.getCurrentPlan);
  const [cancelConfirmOpen, setCancelConfirmOpen] = useState(false);

  const handleCancelOpen = () => {
    setCancelConfirmOpen(true);
  };

  const handleCancelClose = () => {
    setCancelConfirmOpen(false);
  };

  const handleCancelConfirm = () => {
    dispatch(WorkspaceActions.cancelSubscription(navigate));
    if (onCancellation) onCancellation();
    handleCancelClose();
  };

  useEffect(() => {
    dispatch(PlanActions.loadAll);
  }, []);

  return (
    <>
      {currentPlan && currentPlan.level != PlanLevel.Personal && (
        <Button
          variant={'pg_rounded'}
          color={'pg_red_text'}
          size={'pg_small'}
          onClick={handleCancelOpen}
          disabled={disabled}
        >
          <Trans>Cancel Subscription</Trans>
        </Button>
      )}

      <PgConfirmationDialog
        isOpen={cancelConfirmOpen}
        confirmTitle={<Trans>Confirm cancel the subscription</Trans>}
        confirmContent={
          <>
            <Trans>Are you sure you want to cancel the subscription for this workspace?</Trans>
            <br />
            <Trans>Once canceled the projects and all related data will not be accessible unless renewed!</Trans>
          </>
        }
        okButtonText={<Trans>Yes, cancel</Trans>}
        onClose={handleCancelClose}
        onConfirm={handleCancelConfirm}
      />
    </>
  );
};
