import React from 'react';
import { useStyles } from './styles';
import { Box, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { NewIdeaButton } from '../new-idea-button';

interface EmptyIdeasListProps {
  projectId: number;
}

export const EmptyIdeasList: React.FC<EmptyIdeasListProps> = ({ projectId }) => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Box mt={8} className={styles.title}>
        <Typography variant={'subtitle1'} component={'span'}>
          <Trans>You still do not have any recorded ideas.</Trans>
          <br />
          <Trans>Click “+ RECORD AN IDEA” to record one.</Trans>
        </Typography>
      </Box>

      <Box mt={4}>
        <ButtonsWrap justifyContent={'center'}>
          <NewIdeaButton projectId={projectId} text={'Record an idea'} startIcon={<div>+</div>} />
        </ButtonsWrap>
      </Box>
    </Box>
  );
};
