import React, { useState } from 'react';
import { Project } from '@priz/shared/src/models/project';
import { Box, Button, Grid, Link, Paper, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { ChevronRight } from '@mui/icons-material';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { FilledLabel } from '@priz/shared/src/components/filled-label/component';

interface CommentProps {
  project: Project;
  onUnpublish: (projectId: number) => void;
}

export const AdminProjectCard: React.FC<CommentProps> = ({ project, onUnpublish }) => {
  const [isUnpublishConfirmationOpen, setIsUnpublishConfirmationOpen] = useState(false);

  const unpublishHandler = () => {
    closeUnpublishConfirmation();
    onUnpublish(project.id);
  };

  const openUnpublishConfirmation = () => {
    setIsUnpublishConfirmationOpen(true);
  };

  const closeUnpublishConfirmation = () => {
    setIsUnpublishConfirmationOpen(false);
  };

  return (
    <Paper variant={'outlined'}>
      <Box p={2}>
        <Typography variant={'h6'}>{project.publicTitle || project.title}</Typography>
      </Box>

      <Box p={1}>
        <Grid container justifyContent={'space-between'} alignItems={'flex-end'}>
          <Grid item>
            <Box p={1}>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item>
                  <FilledLabel
                    text={project.open ? 'Public project' : 'Private project'}
                    preset={project.open ? 'green' : 'blue_medium'}
                    size={'small'}
                  />
                </Grid>

                {project.open && (
                  <Grid item>
                    <Link
                      href={`https://hub.priz.guru/project/${project.id}/overview`}
                      underline={'hover'}
                      target={'_blank'}
                    >
                      <Trans>Go to Hub project</Trans>&nbsp;
                      <ChevronRight fontSize={'inherit'} sx={{ verticalAlign: 'middle' }} />
                    </Link>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>

          <Grid item>
            <Grid container spacing={1} justifyContent={'flex-end'}>
              <Grid item>
                <Button variant={'outlined'} onClick={openUnpublishConfirmation} disabled={!project.open}>
                  <Trans>Unpublish</Trans>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <PgConfirmationDialog
        isOpen={isUnpublishConfirmationOpen}
        confirmTitle={<Trans>Confirm unpublish</Trans>}
        okButtonText={<Trans>Unpublish</Trans>}
        confirmContent={
          <React.Fragment>
            <Trans>Are you sure you want to unpublish this project?</Trans>
          </React.Fragment>
        }
        onConfirm={unpublishHandler}
        onClose={closeUnpublishConfirmation}
      />
    </Paper>
  );
};
