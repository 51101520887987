import { Locales } from './locales';
import enLocale from 'date-fns/locale/en-US';
import zhLocale from 'date-fns/locale/zh-CN';
import esLocale from 'date-fns/locale/es';

export const dateFnsLocaleMap = {
  [Locales.EN]: enLocale,
  [Locales.ZH]: zhLocale,
  [Locales.ES]: esLocale,
};
