import { Navigate, RouteObject } from 'react-router-dom';
import { RouteRenderer } from './route-renderer/component';
import { Role } from '@priz/shared/src/models/security/role.enum';
import { CreateWorkspace } from '../workspace/create-workspace/component';
import { SwitchToWorkspace } from '../workspace/switch-to-workspace/component';
import { Workspace } from '../workspace/workspace/component';
import { WorkspaceSettings } from '../workspace/workspace-settings/component';
import { BillingPage } from '../billing/billing-page/component';
import { WorkspaceMembers } from '../workspace/workspace-members/component';
import { documentTitleResolver } from './utils';

export const workspaceRoutes: RouteObject[] = [
  {
    path: 'workspace/new',
    element: (
      <RouteRenderer
        Component={CreateWorkspace}
        authGuard={true}
        forceBilling={false}
        mfaFree={true}
        roles={[Role.ROLE_USER]}
      />
    ),
    loader: documentTitleResolver('Create new workspace'),
  },
  {
    path: 'workspace/:workspaceId/switch',
    element: (
      <RouteRenderer
        Component={SwitchToWorkspace}
        authGuard={true}
        forceBilling={false}
        mfaFree={true}
        roles={[Role.ROLE_USER]}
      />
    ),
    loader: documentTitleResolver('Switching workspaces'),
  },
  {
    path: 'workspace',
    element: (
      <RouteRenderer
        Component={Workspace}
        authGuard={true}
        forceBilling={false}
        mfaFree={true}
        roles={[Role.ROLE_USER]}
      />
    ),
    children: [
      {
        path: 'settings',
        element: (
          <RouteRenderer
            Component={WorkspaceSettings}
            authGuard={true}
            forceBilling={false}
            mfaFree={true}
            roles={[Role.ROLE_USER]}
          />
        ),
        loader: documentTitleResolver('Workspace settings'),
      },
      {
        path: 'billing',
        element: (
          <RouteRenderer
            Component={BillingPage}
            authGuard={true}
            forceBilling={false}
            mfaFree={true}
            roles={[Role.ROLE_USER]}
          />
        ),
        loader: documentTitleResolver('Billing'),
      },
      {
        path: 'members',
        element: (
          <RouteRenderer
            Component={WorkspaceMembers}
            authGuard={true}
            forceBilling={false}
            mfaFree={true}
            roles={[Role.ROLE_USER]}
          />
        ),
        loader: documentTitleResolver('Manage workspace team'),
      },
      {
        path: '',
        element: <Navigate to="settings" replace={true} />,
      },
    ],
  },
];
