import { v1 as uuidv1 } from 'uuid';

interface IPerception {
  id?: string;
  letter: string;
  description: string;
  assign: string;
  conflict: string;
  latestRankingScore?: number;
}

export class Perception implements IPerception {
  public id?: string;
  public letter: string;
  public description: string;
  public assign: string;
  public conflict: string;
  public latestRankingScore?: number;

  constructor(props: IPerception) {
    this.id = props.id || uuidv1();
    this.letter = props.letter;
    this.description = props.description;
    this.assign = props.assign;
    this.conflict = props.conflict;
    this.latestRankingScore = props.latestRankingScore;
  }
}

export enum PMapPerceptionBlockingRank {
  High = 'High',
  Med = 'Med',
  Low = 'Low',
}

export interface PerceptionCalcProps {
  collector: number;
  loop: number;
  conflicts: number;
  sum: number;
  blockingRank: PMapPerceptionBlockingRank;
}

export interface PerceptionCalcResult extends PerceptionCalcProps {
  letter: string;
}

export interface PerceptionsChain {
  isLoop: boolean;
  path: string[];
}

export type LetterChain = {
  [letter: string]: string[];
};
