import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectSelector } from '../../store/selectors';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Trans } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { ReactHookFormSelect } from '../../../react/form-elements';
import { TemplateType } from '@priz/shared/src/models/template';
import { templateTypeTextMap } from '../data';
import { TemplateDescription } from '../template-description/component';
import { useNavigate } from 'react-router-dom';
import { ProjectActions } from '../../store/actions/project.actions';
import { LoadingButton } from '@mui/lab';

interface TemplateChangeDialogProps {
  projectId: number;
  open: boolean;
  onClose?: () => void;
}

export const TemplateChangeDialog: React.FC<TemplateChangeDialogProps> = ({ projectId, open, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const project = useSelector(ProjectSelector.getById(projectId));
  const isAnyProjectSaving = useSelector(ProjectSelector.isAnyProjectSaving);
  const isAnyProjectUpdated = useSelector(ProjectSelector.isAnyProjectUpdated);

  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      template: '',
    },
  });

  const template = useWatch({
    control,
    name: 'template',
  });

  useEffect(() => {
    if (open && project?.template) {
      reset({ template: project.template });
    }
  }, [project, open]);

  useEffect(() => {
    if (isAnyProjectUpdated) {
      closeHandler();
    }
  }, [isAnyProjectUpdated]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const submitHandler = () => {
    handleSubmit(formData => {
      dispatch(
        ProjectActions.updateProject(
          {
            ...project,
            template: formData.template.length ? formData.template : undefined,
          },
          false,
          navigate,
        ),
      );
    })();
  };

  const closeHandler = () => {
    if (!isAnyProjectSaving && onClose) onClose();
  };

  return (
    <Dialog open={isOpen} onClose={closeHandler} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>
        <Trans>{project.template ? 'Change project type' : 'Define project type'}</Trans>
      </DialogTitle>

      <DialogContent>
        <ReactHookFormSelect
          fieldTitle="Template"
          name={'template'}
          control={control}
          options={[
            { value: '', text: 'Without template' },
            ...Object.values(TemplateType).map(type => ({ value: type, text: templateTypeTextMap[type] })),
          ]}
          displayEmpty={true}
        />

        {!!template.length && <TemplateDescription type={template as TemplateType} mb={3} />}
      </DialogContent>

      <DialogActions>
        <Box textAlign={'right'}>
          <Button
            variant={'pg_rounded'}
            color={'pg_orange_outlined'}
            onClick={closeHandler}
            disabled={isAnyProjectSaving}
          >
            <Trans>Close</Trans>
          </Button>

          <LoadingButton
            variant={'pg_rounded'}
            color={'pg_orange_solid'}
            onClick={submitHandler}
            disabled={isAnyProjectSaving || (project?.template || '') === template}
            loading={isAnyProjectSaving}
          >
            <Trans>{project.template ? 'Change' : 'Define'}</Trans>
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
