import React, { ChangeEvent, useEffect, useState } from 'react';
import { IdType, Network as NetworkType } from '@priz/shared/src/lib/vis/esnext';
import { useStyles } from './styles';
import { CirclePicker } from 'react-color';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Hamburger } from '../../react/modules/Hamburger';
import { Alert, Box, IconButton, Paper, TextField, Tooltip } from '@mui/material';
import { CecNodeProps } from '@priz/shared/src/models/tools/cec';
import { Trans } from 'react-i18next';
import { FieldTitle } from '@priz/shared/src/components/form-elements';
import { cecNodeColors } from './data';
import { DataSet } from 'vis-data';
import { DataSetEventType } from '@priz/shared/src/models/vis-network';

import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as AddLinkedNode } from '../../../assets/icons/add-linked-node.svg';

interface CecNodeEditorProps {
  nodeId: IdType;
  network: NetworkType;
  nodesDataSet: DataSet<CecNodeProps, 'id'>;
  onLabelChange: (nodeId: IdType, value: string) => void;
  onNotesChange: (nodeId: IdType, value: string) => void;
  onColorChange: (nodeId: IdType, value: string) => void;
  onCause: (nodeId: IdType) => void;
  onDelete: (nodeId: IdType) => void;
  onEditStateChange?: (isEditing: boolean) => void;
  disabled?: boolean;
}

export const CecNodeEditor: React.FC<CecNodeEditorProps> = ({
  nodeId,
  network,
  nodesDataSet,
  onLabelChange,
  onNotesChange,
  onColorChange,
  onCause,
  onDelete,
  disabled,
  onEditStateChange,
}) => {
  const styles = useStyles();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [nodeType, setNodeType] = useState('');
  const [nodeColor, setNodeColor] = useState('');
  const [labelText, setLabelText] = useState('');
  const [notesText, setNotesText] = useState('');

  const menuClassNames = [styles.menu];

  if (isMenuOpen) menuClassNames.push('_open');

  useEffect(() => {
    if (nodeId) {
      const selectedNode = nodesDataSet.get(nodeId);
      const { type, color, label, potentialSolution } = selectedNode || {};
      const { border } = color || {};

      setNodeType(type || '');
      setNodeColor(border || '');
      setLabelText(label || '');
      setNotesText(potentialSolution || '');
    } else {
      setNodeType('');
      setNodeColor('');
      setLabelText('');
      setNotesText('');
    }
  }, [nodeId]);

  useEffect(() => {
    const dataSetChangeCallback = (e: DataSetEventType) => {
      if (e === 'update' && nodeId) {
        const node = nodesDataSet.get(nodeId);

        if (node) {
          setLabelText(node.label);
        }
      }
    };

    if (network) {
      nodesDataSet.on('*', dataSetChangeCallback);
    }

    return () => {
      if (network) {
        nodesDataSet.off('*', dataSetChangeCallback);
      }
    };
  }, [nodeId]);

  const labelChangeHandler = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLabelText(e.currentTarget.value);
    onLabelChange(nodeId, e.currentTarget.value);
  };

  const notesChangeHandler = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNotesText(e.currentTarget.value);
    onNotesChange(nodeId, e.currentTarget.value);
  };

  const colorChangeHandler = (value: string | { hex: string }) => {
    const color = typeof value === 'string' ? value : value.hex;

    setNodeColor(color);
    onColorChange(nodeId, color);
  };

  const causeHandler = () => {
    onCause(nodeId);
  };

  const deleteHandler = () => {
    onDelete(nodeId);
  };

  const textareaFocusHandler = () => {
    if (onEditStateChange) {
      onEditStateChange(true);
    }
  };

  const textareaBlurHandler = () => {
    if (onEditStateChange) {
      onEditStateChange(false);
    }
  };

  if (!nodeId) return null;

  return (
    <>
      <div className={styles.hamburgerContainer}>
        <Hamburger
          active={isMenuOpen}
          callback={() => {
            setIsMenuOpen(currentState => !currentState);
          }}
          p={2}
          barProps={{
            barColor: pgColorScheme.blue,
            barColorHover: pgColorScheme.darkGray,
            barColorActive: pgColorScheme.blue,
            barColorActiveHover: pgColorScheme.darkGray,
          }}
        />
      </div>

      {disabled && (
        <Paper className={menuClassNames.join(' ')}>
          {!!labelText?.length && (
            <Box mb={2}>
              <FieldTitle text={nodeType === 'first' ? 'Description' : 'Cause'} />

              <Paper variant={'outlined'} component={Box} p={1}>
                {labelText}
              </Paper>
            </Box>
          )}

          {!!notesText?.length && (
            <Box>
              <FieldTitle text={'Notes / Potential solution'} />

              <Paper variant={'outlined'} component={Box} p={1}>
                {notesText}
              </Paper>
            </Box>
          )}

          {!labelText?.length && !notesText?.length && (
            <Alert variant={'outlined'} severity={'info'}>
              <Trans>Empty</Trans>
            </Alert>
          )}
        </Paper>
      )}

      {!disabled && (
        <Paper className={menuClassNames.join(' ')}>
          <Box mb={2}>
            <FieldTitle text={nodeType === 'first' ? 'Description' : 'Cause'} />

            <TextField
              onChange={labelChangeHandler}
              onFocus={textareaFocusHandler}
              onBlur={textareaBlurHandler}
              onKeyDown={e => e.stopPropagation()}
              value={labelText}
              size={'small'}
              fullWidth
              multiline
              maxRows={4}
            />
          </Box>

          <Box mb={2}>
            <FieldTitle text={'Notes / Potential solution'} />

            <TextField
              onChange={notesChangeHandler}
              onFocus={textareaFocusHandler}
              onBlur={textareaBlurHandler}
              onKeyDown={e => e.stopPropagation()}
              value={notesText}
              size={'small'}
              fullWidth
              multiline
              maxRows={4}
            />
          </Box>

          <Box mb={2}>
            <FieldTitle text={'Border color'} />

            <CirclePicker
              width={'100%'}
              circleSize={30}
              circleSpacing={5}
              onChange={colorChangeHandler}
              colors={cecNodeColors}
              color={nodeColor}
            />
          </Box>

          <div>
            <FieldTitle text={'Actions'} />

            <Box display={'flex'} justifyContent={'flex-start'} alignItems={'flex-start'} flexWrap={'wrap'}>
              {nodeType !== 'first' && (
                <Tooltip title={<Trans>Remove</Trans>} placement={'top'} disableInteractive arrow>
                  <div>
                    <IconButton size={'medium'} onClick={deleteHandler}>
                      <TrashIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              )}

              <Tooltip title={<Trans>Add cause</Trans>} placement={'top'} disableInteractive arrow>
                <div>
                  <IconButton size={'medium'} onClick={causeHandler}>
                    <AddLinkedNode />
                  </IconButton>
                </div>
              </Tooltip>
            </Box>
          </div>
        </Paper>
      )}
    </>
  );
};
