import { FluxStandardAction } from 'flux-standard-action';
import { PrincipleService } from '../../services/principle.service';
import { createPgAction } from '../../../shared/store/action-creator';
import { DomainPrinciple } from '@priz/shared/src/models/tools/forty-principles/domain-principle';
import { Principle } from '@priz/shared/src/models/tools/forty-principles/principle';
import { ActionMeta } from '../../../shared/store/types';

export enum PrincipleActionType {
  Fetch = '40P/principle/fetch',
  Update = '40P/principle/update',
}

interface PrincipleMeta extends ActionMeta {
  principleId: number;
}

export type PrinciplesAction = FluxStandardAction<PrincipleActionType, DomainPrinciple[]>;
export type PrincipleAction = FluxStandardAction<PrincipleActionType, Principle, PrincipleMeta>;

const loadPrinciples = createPgAction(PrincipleActionType.Fetch, PrincipleService.getPrinciples);

const updatePrinciple = createPgAction(
  PrincipleActionType.Update,
  PrincipleService.updatePrinciple,
  (principleId: number) => ({ principleId }),
);

export const PrincipleActions = {
  loadPrinciples,
  updatePrinciple,
};
