import { createSelector, Selector } from 'reselect';
import { ArrayUtils } from '@priz/shared/src/utils/common';
import { AppState } from '../../../store/app.state';
import { Attendee, AttendeesCollection } from '../model';

const attendeesCollectionSelector = (state: AppState): AttendeesCollection => state.attendees;

const attendeesEntitiesSelector = createSelector(attendeesCollectionSelector, collection => collection.entities);

const attendeesLookupSelector = createSelector(attendeesCollectionSelector, collection => collection.lookups);

const attendeesStatusesSelector = createSelector(attendeesCollectionSelector, collection => collection.statuses);

const statusesIdsByProjectIdSelector = (projectId: number) =>
  createSelector(attendeesLookupSelector, lookup => lookup.byProjectId[projectId] || []);

const getAllByProjectId = (projectId: number): Selector<AppState, Attendee[]> =>
  createSelector([attendeesEntitiesSelector, statusesIdsByProjectIdSelector(projectId)], (entityMap, ids) =>
    ids.map(id => entityMap[id]).sort(ArrayUtils.sorterByDateCreated),
  );

const getAllByProjectIdAndMeetingId = (projectId: number, meetingId: number): Selector<AppState, Attendee[]> =>
  createSelector([attendeesEntitiesSelector, statusesIdsByProjectIdSelector(projectId)], (entityMap, ids) =>
    ids
      .map(id => entityMap[id])
      .filter(attendee => attendee.meeting.id === meetingId)
      .sort(ArrayUtils.sorterByDateCreated),
  );

const getByProjectIdAndMeetingIdAndUserId = (
  projectId: number,
  meetingId: number,
  userId: number,
): Selector<AppState, Attendee> =>
  createSelector(getAllByProjectIdAndMeetingId(projectId, meetingId), attendees =>
    attendees.find(attendee => attendee?.user?.id === userId),
  );

const getAllByProjectIdAndUserId = (projectId: number, userId: number): Selector<AppState, Attendee[]> =>
  createSelector([attendeesEntitiesSelector, statusesIdsByProjectIdSelector(projectId)], (entityMap, ids) =>
    ids
      .map(id => entityMap[id])
      .filter(attendee => attendee?.user?.id === userId)
      .sort(ArrayUtils.sorterByDateCreated),
  );

const getById = (attendeeId: number): Selector<AppState, Attendee> =>
  createSelector(attendeesEntitiesSelector, entities => entities[attendeeId]);

const isLoaded = (projectId: number): Selector<AppState, boolean> =>
  createSelector(attendeesStatusesSelector, statuses => statuses.byProjectId[projectId]?.loaded || false);

const isLoading = (projectId: number): Selector<AppState, boolean> =>
  createSelector(attendeesStatusesSelector, statuses => statuses.byProjectId[projectId]?.loading || false);

const isCreating = (projectId: number): Selector<AppState, boolean> =>
  createSelector(attendeesStatusesSelector, statuses => statuses.byProjectId[projectId]?.creating || false);

const isUpdating = (projectId: number): Selector<AppState, boolean> =>
  createSelector(attendeesStatusesSelector, statuses => statuses.byProjectId[projectId]?.updating || false);

const isUpdated = (projectId: number): Selector<AppState, boolean> =>
  createSelector(attendeesStatusesSelector, statuses => statuses.byProjectId[projectId]?.updated || false);

const isAnyUpdated = (): Selector<AppState, boolean> =>
  createSelector(
    attendeesStatusesSelector,
    statuses => !!Object.values(statuses.byProjectId).find(item => item.updated),
  );

const isEveryUpdated = (): Selector<AppState, boolean> =>
  createSelector(attendeesStatusesSelector, statuses =>
    Object.values(statuses.byProjectId).every(item => item.updated),
  );

const isRemoving = (projectId: number): Selector<AppState, boolean> =>
  createSelector(attendeesStatusesSelector, statuses => statuses.byProjectId[projectId]?.removing || false);

export const AttendeeSelectors = {
  getAllByProjectId,
  getAllByProjectIdAndMeetingId,
  getAllByProjectIdAndUserId,
  getByProjectIdAndMeetingIdAndUserId,
  getById,
  isLoaded,
  isLoading,
  isCreating,
  isUpdating,
  isUpdated,
  isAnyUpdated,
  isEveryUpdated,
  isRemoving,
};
