import React, { useEffect, useState } from 'react';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { TranslationsApi } from '../services/translations.api';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NewTranslationDialog } from '../new-translation-dialog/component';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translation } from '../model/translation';
import { RenderForRoles } from '../../react/security/render-for-roles/component';
import { Role } from '@priz/shared/src/models/security/role.enum';
import { EditTranslationDialog } from '../edit-translation-dialog/component';
import { AppNavbar } from '../../navigation/app-navbar/component';
import { SearchInput } from '../../react/elements/search-input/component';
import { PageContainer } from '../../content-containers/page-container/component';

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
  leftCell: {
    position: 'relative',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    width: '50%',
  },
  rightCell: {
    position: 'relative',
    width: '50%',
  },
  editButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  emptyOnlyCheckbox: {
    marginLeft: 8,
  },
});

type LocaleType = 'zh' | 'es';

export const TranslationsManager: React.FC = () => {
  const styles = useStyles();

  const [newOpen, setNewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const [editedId, setEditedId] = useState<number>();
  const [editedLocale, setEditedLocale] = useState<string>();
  const [editedValue, setEditedValue] = useState<string>();
  const [locale, setLocale] = useState<LocaleType>('es');

  const [emptyOnly, setEmptyOnly] = useState(false);
  const [match, setMatch] = useState<string | undefined>();
  const [page, setPage] = useState(0);

  const queryClient = useQueryClient();

  const { isLoading, data, isFetching } = useQuery(
    ['translations', locale, page, emptyOnly, match],
    () => TranslationsApi.list(locale, page, emptyOnly, match),
    { keepPreviousData: true, refetchOnWindowFocus: false },
  );

  const deleteMutation = useMutation((id: number) => TranslationsApi.delete(id), {
    onSuccess: _ => {
      onDelete();
    },
  });

  const onNewCreate = (_translation: Translation) => {
    queryClient.invalidateQueries(['translations', locale, page]);
  };

  const onDelete = () => {
    queryClient.invalidateQueries(['translations', locale, page]);
  };

  const onRecordEdited = (_translation: Translation) => {
    queryClient.invalidateQueries(['translations', locale, page]);
  };

  useEffect(() => {
    if (!isLoading || !isFetching) {
      queryClient.invalidateQueries(['translations', locale, page]);
    }
  }, [emptyOnly, locale]);

  const handleOpenEdit = (id: number, locale: string, value: string) => () => {
    setEditedId(id);
    setEditedLocale(locale);
    setEditedValue(value);
    setEditOpen(true);
  };

  const handleCloseEdit = () => {
    setEditedId(undefined);
    setEditedLocale(undefined);
    setEditedValue(undefined);
    setEditOpen(false);
  };

  const handleOpenNew = () => {
    setNewOpen(true);
  };

  const handleOnNewClosed = () => {
    setNewOpen(false);
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    queryClient.invalidateQueries(['translations', locale, newPage]);
  };

  const handleDelete = (id: number) => () => {
    deleteMutation.mutate(id);
  };

  const handleEmptyOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmptyOnly(event.target.checked);
  };

  // TODO: check
  const handleLocalChange = (event: SelectChangeEvent) => {
    setLocale(event.target.value as LocaleType);
    queryClient.invalidateQueries(['translations', event.target.value]);
  };

  const searchInputValueChangeHandler = (text: string) => {
    setPage(0);
    setMatch(text?.length ? text : undefined);
  };

  const clearSearch = () => {
    setPage(0);
    setMatch(undefined);
  };

  return (
    <PageContainer>
      <AppNavbar />

      <>
        <Box>
          <Grid container direction="row" justifyContent="space-between" alignItems="stretch">
            <Grid item>
              <PageTitleWithDocLink
                title={'Translations manager'}
                oneLiner={'String translations to different languages are managed here'}
              />
            </Grid>
            <Grid item>
              <RenderForRoles roles={[Role.ROLE_ADMIN]}>
                <Button
                  variant={'pg_rounded'}
                  color={'pg_orange_solid'}
                  startIcon={<FontAwesomeIcon icon={faPlus} size={'xs'} />}
                  onClick={handleOpenNew}
                >
                  New
                </Button>
              </RenderForRoles>
            </Grid>
          </Grid>
        </Box>
        <Box>
          {isLoading && <LinearProgress />}
          {!isLoading && (
            <>
              <TableContainer component={Paper}>
                <Table stickyHeader className={styles.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={styles.leftCell}>
                        <Grid container alignItems={'center'} spacing={4}>
                          <Grid item xs={'auto'}>
                            English
                          </Grid>

                          <Grid item xs={true}>
                            <SearchInput onChangeDebounced={searchInputValueChangeHandler} onClear={clearSearch} />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Select
                          value={locale}
                          onChange={handleLocalChange}
                          SelectDisplayProps={{ style: { paddingTop: 9, paddingBottom: 9 } }}
                        >
                          <MenuItem value={'zh'}>Chinese</MenuItem>
                          <MenuItem value={'es'}>Spanish</MenuItem>
                        </Select>
                        <FormControlLabel
                          className={styles.emptyOnlyCheckbox}
                          control={<Checkbox checked={emptyOnly} onChange={handleEmptyOnlyChange} />}
                          label="Show empty only"
                        />
                      </TableCell>
                      <RenderForRoles roles={[Role.ROLE_ADMIN]}>
                        <TableCell />
                      </RenderForRoles>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.translations.map(row => (
                      <TableRow key={row.id}>
                        <TableCell style={{ whiteSpace: 'pre-line' }} className={styles.leftCell}>
                          {row.en}
                          <RenderForRoles roles={[Role.ROLE_ADMIN]}>
                            <IconButton
                              aria-label="edit"
                              className={styles.editButton}
                              onClick={handleOpenEdit(row.id, 'en', row.en)}
                            >
                              <FontAwesomeIcon icon={faEdit} size={'xs'} />
                            </IconButton>
                          </RenderForRoles>
                        </TableCell>
                        <TableCell style={{ whiteSpace: 'pre-line' }} className={styles.rightCell}>
                          {row[locale]}
                          <RenderForRoles roles={[Role.ROLE_ADMIN, Role.ROLE_TRANSLATOR]}>
                            <IconButton
                              aria-label="edit"
                              className={styles.editButton}
                              onClick={handleOpenEdit(row.id, locale, row[locale])}
                            >
                              <FontAwesomeIcon icon={faEdit} size={'xs'} />
                            </IconButton>
                          </RenderForRoles>
                        </TableCell>
                        <RenderForRoles roles={[Role.ROLE_ADMIN]}>
                          <TableCell>
                            <IconButton aria-label="delete" onClick={handleDelete(row.id)}>
                              <FontAwesomeIcon icon={faTrash} size={'xs'} />
                            </IconButton>
                          </TableCell>
                        </RenderForRoles>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {data.count > 50 && (
                <TablePagination
                  rowsPerPageOptions={[50]}
                  component="div"
                  count={data.count}
                  rowsPerPage={50}
                  page={page}
                  onPageChange={handleChangePage}
                />
              )}
            </>
          )}
        </Box>
        <NewTranslationDialog isOpen={newOpen} onClose={handleOnNewClosed} onCreate={onNewCreate} />
        <EditTranslationDialog
          id={editedId}
          locale={editedLocale}
          value={editedValue}
          isOpen={editOpen}
          onClose={handleCloseEdit}
          onEdit={onRecordEdited}
        />
      </>
    </PageContainer>
  );
};
