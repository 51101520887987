import React, { CSSProperties } from 'react';
import { Trans } from 'react-i18next';
import { Button, ButtonProps } from '@mui/material';
import { useStyles } from './styles';

import { ReactComponent as SpinnerIcon } from '../../assets/icons/spinner-solid';

export interface LoadingButtonProps extends ButtonProps {
  text?: string;
  disabled?: boolean;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  style?: CSSProperties;
}

export const LoadingButton: React.FC<LoadingButtonProps> = ({
  text = 'Save',
  disabled,
  loading,
  type = 'button',
  onClick,
  children,
  style,
  ...rest
}) => {
  const styles = useStyles();

  return (
    <Button
      type={type}
      disabled={disabled || loading}
      endIcon={loading ? <SpinnerIcon className={styles.spinnerIcon} /> : null}
      onClick={() => {
        if (onClick) onClick();
      }}
      style={style}
      variant={'pg_rounded'}
      color={'pg_orange_solid'}
      {...rest}
    >
      {children ? children : <Trans>{text}</Trans>}
    </Button>
  );
};
