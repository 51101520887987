import { useEffect, useRef } from 'react';

export const useIsMounted = (): { checkIsMounted: () => boolean } => {
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const checkIsMounted = () => {
    return isMountedRef.current;
  };

  return {
    checkIsMounted,
  };
};
