import { User } from '@priz/shared/src/models/security/user';
import { PartnerProfile } from '@priz/shared/src/models/security/partner-profile';

export type UserAdministrationListData = { label: string; value: string | number }[];

export const resolveUserInfo = (user: User): UserAdministrationListData => {
  return [
    {
      label: 'Id:',
      value: user.id,
    },
    {
      label: 'Email:',
      value: user.email,
    },
  ];
};

export const resolvePartnerProfileInfo = (partnerProfile: PartnerProfile): UserAdministrationListData => {
  return [
    {
      label: 'Referral code:',
      value: partnerProfile.referralCode,
    },
    {
      label: 'Commission rate:',
      value: partnerProfile.commissionRate,
    },
    {
      label: 'Status:',
      value: partnerProfile.status,
    },
  ];
};
