import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { Box, Divider, Grid, Paper, Typography } from '@mui/material';
import { ProjectAssigneeActions, TaskAssigneeActions } from '../../../assignment/store/actions';
import { WorkspaceMembersActions } from '../../../workspace/store/actions';
import { ProjectAssigneeRole, ProjectAssigneeWithMember } from '@priz/shared/src/models/assignment';
import { ProjectAssigneeSelectors } from '../../../assignment/store/selectors/project-assignee.selectors';
import { useStyles } from './styles';
import { ProjectSelector, ToolUtilizationSelector } from '../../store/selectors';
import { IdeasSelectors, ProjectIdeaActions } from '../../../idea/store';
import { Idea } from '@priz/shared/src/models/idea/idea';
import { ToolType, ToolUtilization } from '@priz/shared/src/models/tools';
import { ArrayUtils, SortDirection } from '@priz/shared/src/utils/common';
import { DefaultToolsExtraData, ReportTool, ToolsExtraData } from '../tool/component';
import { ReportIdea } from '../idea/component';
import { Project } from '@priz/shared/src/models/project';
import { ReportUser } from '../user/component';
import { ProjectActions } from '../../store/actions/project.actions';
import { ReportTasksList } from '../tasks-list/component';
import { TaskActions } from '../../../tasks/store/actions/task.actions';
import { Task } from '@priz/shared/src/models/task';
import { TaskSelectors } from '../../../tasks/store/selectors/task.selectors';
import { resolveIdeaAuthor } from '@priz/shared/src/utils/ideas/ideas-utils';
import { EntityMap } from '@priz/shared/src/models/common/entity-collection-state';
import { MatrixElementActions, ParameterActions, PrincipleActions } from '../../../forty-principles/store/actions';
import { InventivePrinciplesSelectors } from '../../../forty-principles/store/selectors/InventivePrinciplesSelectors';
import { QuillContentViewer } from '@priz/shared/src/components/quill-content-viewer/component';
import { AgendaActions, AttendeeActions, MeetingActions } from '../../../meetings/store/actions';
import { MeetingSelectors } from '../../../meetings/store/selectors';
import { MeetingStatus } from '@priz/shared/src/models/meetings';
import { MeetingInfo } from '../../../meetings/meeting-info/component';
import { groupAndSortTools } from '@priz/shared/src/utils/tools/sort';

import { ReactComponent as LogoLightThemeIcon } from '../../../../assets/img/light-theme-logo.svg';
import { ToolUtilizationActions } from '../../store/actions/tools.actions';

export interface ReportDocumentProps {
  projectId: number;
}

export const ReportDocument: React.FC<ReportDocumentProps> = ({ projectId }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const assignees: ProjectAssigneeWithMember[] = useSelector(ProjectAssigneeSelectors.getProjectAssignees(projectId));
  const project: Project = useSelector(ProjectSelector.getById(projectId));
  const ideas: EntityMap<Idea> = useSelector(IdeasSelectors.getAllByProjectId(projectId));
  const tools: ToolUtilization[] = useSelector(ToolUtilizationSelector.getAllVisibleByProjectId(projectId));
  const isLoadedByProjectId = useSelector(ToolUtilizationSelector.isLoadedByProjectId(projectId));
  const tasks: Task[] = useSelector(TaskSelectors.getAllByProjectId(projectId));
  const meetings = useSelector(MeetingSelectors.getAllByProjectId(projectId));

  const principles = useSelector(InventivePrinciplesSelectors.getPrinciples);
  const parameters = useSelector(InventivePrinciplesSelectors.getParameters);
  const matrixElements = useSelector(InventivePrinciplesSelectors.getMatrixElements);

  const meetingsToReport = (meetings || []).filter(meeting => meeting.status !== MeetingStatus.Draft);

  const toolsExtraData: ToolsExtraData = {
    ...DefaultToolsExtraData,
    principles,
    matrixElements,
    parameters,
  };

  const filterWinningIdeas = (ideas: EntityMap<Idea>) => {
    return Object.values(ideas).filter((idea: Idea) => !!idea.selectedAsWinning);
  };

  const filterRelatedIdeas = (ideas: EntityMap<Idea>, toolId: number) => {
    return Object.values(ideas).filter((idea: Idea) => idea?.sourceToolUtilization?.id === toolId);
  };

  const filterLead = (assignees: ProjectAssigneeWithMember[]) => {
    return assignees.find(a => a.assigneeRole === ProjectAssigneeRole.Lead);
  };

  const filterContributors = (assignees: ProjectAssigneeWithMember[]) => {
    return assignees.filter(a => a.assigneeRole === ProjectAssigneeRole.Contributor);
  };

  useEffect(() => {
    dispatch(ProjectActions.loadProject(projectId));
    dispatch(ProjectAssigneeActions.fetchAll());
    dispatch(WorkspaceMembersActions.loadAll());
    dispatch(ProjectIdeaActions.loadProjectIdeas(projectId));
    dispatch(ToolUtilizationActions.loadTools(projectId));
    dispatch(TaskActions.fetchAllByProjectId(projectId));
    dispatch(TaskAssigneeActions.fetchAll());
    dispatch(AgendaActions.getAll(projectId));
    dispatch(AttendeeActions.getAll(projectId));
    dispatch(MeetingActions.getAll(projectId));
  }, []);

  useEffect(() => {
    if (isLoadedByProjectId && tools) {
      if (tools.find(tool => tool.type === ToolType.TOOL_40_PRINCIPLES)) {
        dispatch(PrincipleActions.loadPrinciples());
        dispatch(ParameterActions.loadParameters());
        dispatch(MatrixElementActions.loadMatrixElements());
      }
    }
  }, [isLoadedByProjectId]);

  if (!assignees || !project || !ideas || !tools) return null;

  return (
    <div className={styles.root}>
      <div className={`${styles.content} ${styles.preventChildrenBreaking}`}>
        <div className={`${styles.page} ${styles.flexCenter}`}>
          <Box className={`${styles.textAlignRight} ${styles.fullWidth}`} mb={4}>
            <Grid container alignItems={'center'} justifyContent={'flex-end'}>
              <Grid item>
                <Box pr={2}>
                  <Typography variant={'body1'} component={'span'}>
                    <Trans>Powered by</Trans>
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <LogoLightThemeIcon className={styles.logo} />
              </Grid>
            </Grid>
          </Box>

          <Box my={'auto'} className={styles.textAlignCenter}>
            <Box mb={1}>
              <Typography variant={'h4'} component={'div'} className={styles.projectTitle}>
                {project.title}
              </Typography>
            </Box>
          </Box>

          <Box mt={'auto'} pb={2} className={styles.fullWidth}>
            <Box mb={0.5}>
              <Typography variant="body2" component={'div'}>
                <Trans>Lead</Trans>
              </Typography>
            </Box>

            <ReportUser assignee={filterLead(assignees)} />

            {!!filterContributors(assignees).length && (
              <Box mt={2}>
                <Box mb={0.5}>
                  <Typography variant="body2" component={'div'}>
                    <Trans>Contributors</Trans>
                  </Typography>
                </Box>

                <Box mt={-0.5} className={styles.flexRow}>
                  {filterContributors(assignees).map((assignee, key) => (
                    <Box key={key} mt={0.5} mr={0.5}>
                      <ReportUser assignee={assignee} />
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </div>

        {!!project?.description && !!project.description.length && (
          <div className={styles.page}>
            <Box mb={1}>
              <Typography variant={'h4'} component={'div'} gutterBottom={false}>
                <Trans>Project background</Trans>
              </Typography>
            </Box>

            <QuillContentViewer content={project.description} className={'break'} />
          </div>
        )}

        {(project.currentSituation ||
          project.disadvantages ||
          project.problemStatement ||
          project.idealFinalResult ||
          project.gaps) && (
          <div className={styles.page}>
            {project.currentSituation && (
              <Box mb={2}>
                <Box mb={1}>
                  <Typography variant={'h4'} component={'div'} gutterBottom={false}>
                    <Trans>Current situation</Trans>
                  </Typography>
                </Box>

                <QuillContentViewer content={project.currentSituation} className={'break'} />
              </Box>
            )}

            {project.disadvantages && (
              <Box mb={2}>
                <Box mb={1}>
                  <Typography variant={'h4'} component={'div'} gutterBottom={false}>
                    <Trans>Disadvantages</Trans>
                  </Typography>
                </Box>

                <QuillContentViewer content={project.disadvantages} className={'break'} />
              </Box>
            )}

            {project.idealFinalResult && (
              <Box mb={2}>
                <Box mb={1}>
                  <Typography variant={'h4'} component={'div'} gutterBottom={false}>
                    <Trans>Ideal final result</Trans>
                  </Typography>
                </Box>

                <QuillContentViewer content={project.idealFinalResult} className={'break'} />
              </Box>
            )}

            {project.gaps && (
              <Box mb={2}>
                <Box mb={1}>
                  <Typography variant={'h4'} component={'div'} gutterBottom={false}>
                    <Trans>Gaps</Trans>
                  </Typography>
                </Box>

                <QuillContentViewer content={project.gaps} className={'break'} />
              </Box>
            )}

            {project.problemStatement && (
              <Box mb={2}>
                <Box mb={1}>
                  <Typography variant={'h4'} component={'div'} gutterBottom={false}>
                    <Trans>Problem statement</Trans>
                  </Typography>
                </Box>

                <QuillContentViewer content={project.problemStatement} className={'break'} />
              </Box>
            )}
          </div>
        )}

        {(!!filterWinningIdeas(ideas).length || !!project?.solution?.length) && (
          <div className={styles.page}>
            {!!filterWinningIdeas(ideas).length && (
              <Box mb={2}>
                <Box mb={1}>
                  <Typography variant={'h4'} component={'div'} gutterBottom={false}>
                    <Trans>Winning ideas</Trans>
                  </Typography>
                </Box>

                {filterWinningIdeas(ideas)
                  .sort(ArrayUtils.sorterByDateCreated)
                  .sort(ArrayUtils.sorterByParam('latestRankingScore', SortDirection.DESC))
                  .map((idea, key) => (
                    <ReportIdea
                      key={key}
                      idea={idea}
                      showRankingScore={true}
                      assignee={resolveIdeaAuthor(idea, assignees)}
                    />
                  ))}
              </Box>
            )}

            {!!project?.solution?.length && (
              <Box>
                <Box mb={1}>
                  <Typography variant={'h4'} component={'div'} gutterBottom={false}>
                    <Trans>Proposed solution</Trans>
                  </Typography>
                </Box>

                <QuillContentViewer content={project.solution} className={'break'} />
              </Box>
            )}
          </div>
        )}
        {(!!tools?.length || (ideas && !!Object.keys(ideas).length) || !!tasks?.length) && (
          <div className={styles.page}>
            {!!tools?.length && (
              <Box className={styles.preventChildrenBreaking}>
                <Box mb={1}>
                  <Typography variant={'h4'} component={'div'} gutterBottom={false}>
                    <Trans>Creative tools</Trans>
                  </Typography>
                </Box>

                {groupAndSortTools(tools).map((tool, key) => {
                  const relatedIdeas = filterRelatedIdeas(ideas, tool.id);

                  return (
                    <ReportTool
                      key={key}
                      tool={tool}
                      ideas={relatedIdeas}
                      assignees={assignees}
                      rootClassName={!key ? 'break' : undefined}
                      toolsExtraData={toolsExtraData}
                    />
                  );
                })}
              </Box>
            )}
            {ideas && !!Object.keys(ideas).length && (
              <Box className={styles.preventChildrenBreaking}>
                <Box py={2}>
                  <Divider />
                </Box>

                <Box mb={1}>
                  <Typography variant={'h4'} component={'div'} gutterBottom={false}>
                    <Trans>All ideas</Trans>
                  </Typography>
                </Box>

                {Object.values(ideas)
                  .sort(ArrayUtils.sorterByDateCreated)
                  .sort(ArrayUtils.sorterByParam('latestRankingScore', SortDirection.DESC))
                  .map((idea, key) => (
                    <ReportIdea
                      key={key}
                      idea={idea}
                      showRankingScore={true}
                      assignee={resolveIdeaAuthor(idea, assignees)}
                    />
                  ))}
              </Box>
            )}

            {tasks?.length > 0 && (
              <>
                <Box py={2}>
                  <Divider />
                </Box>

                <Box mb={1}>
                  <Typography variant={'h4'} component={'div'} gutterBottom={false}>
                    <Trans>All tasks</Trans>
                  </Typography>
                </Box>

                <ReportTasksList tasks={tasks} />
              </>
            )}
          </div>
        )}

        {!!meetingsToReport.length && (
          <div className={styles.page}>
            <Box className={styles.preventChildrenBreaking}>
              <Box mb={1}>
                <Typography variant={'h4'} component={'div'} gutterBottom={false}>
                  <Trans>Meetings</Trans>
                </Typography>
              </Box>

              {meetingsToReport
                .sort((a, b) => (a.date < b.date ? -1 : 1))
                .map(meeting => (
                  <Box key={meeting.id} mb={2}>
                    <Paper variant={'outlined'}>
                      <Box p={2}>
                        <MeetingInfo meeting={meeting} viewMode={true} />
                      </Box>
                    </Paper>
                  </Box>
                ))}
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};
