import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  roleText: {
    display: 'inline-block',
    color: pgColorScheme.blue,
  },
  link: {
    cursor: 'pointer',

    ['&:hover']: {
      textDecoration: 'underline',
    },
  },
});
