import { EntityCollectionStatus, EntityMap, LookupMap } from '../../common/entity-collection-state';
import { ApaAction } from './apa-action';

export interface ApaActionLookups {
  byUtilizationId: LookupMap;
}

export interface ApaActionCollection {
  entities: EntityMap<ApaAction>;

  lookups: ApaActionLookups;

  statuses: {
    byTeamId?: { [teamId: number]: EntityCollectionStatus };
    byProjectId?: { [projectId: number]: EntityCollectionStatus };
    byUtilizationId?: { [utilizationId: number]: EntityCollectionStatus };
  };
}

export const DefaultApaActionCollection = {
  entities: {},

  lookups: {
    byUtilizationId: {},
  },

  statuses: {
    byTeamId: {},
    byProjectId: {},
    byUtilizationId: {},
  },
};
