import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { Box } from '@mui/material';
import { UserActions } from '../../../user/store/actions/user.actions';
import { UserSelectors } from '../../../user/store/selectors/user.selectors';
import { Alert } from '@mui/material';
import { SecuritySettingsCommand } from '../../../security/services/user.service';
import { User } from '@priz/shared/src/models/security/user';
import { WorkspaceSelectors } from '../../../workspace/store/selectors';
import { AuthService } from '@priz/shared/src/services/auth';
import { LoadingButton } from '@mui/lab';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { HelperText } from '@priz/shared/src/components/form-elements';
import { MfaDialog } from '../mfa-dialog/component';
import { Auth0ConnectionType } from '@priz/shared/src/models/security/auth0-connection-type';

interface MfaFormProps {
  user: User;
}

export const MfaForm: React.FC<MfaFormProps> = ({ user }) => {
  const dispatch = useDispatch();

  const [authorizeAfterSaving, setAuthorizeAfterSaving] = useState(false);

  const isAnyWorkspaceRequireMfa = useSelector(WorkspaceSelectors.isAnyWorkspaceRequireMfa);
  const isCurrentUserSatisfyWorkspaceMfa = useSelector(WorkspaceSelectors.isCurrentUserSatisfyWorkspaceMfa);
  const isSecuritySettingsUpdating = useSelector(UserSelectors.isSecuritySettingsUpdating);
  const isSecuritySettingsUpdated = useSelector(UserSelectors.isSecuritySettingsUpdated);

  const isSocial = user.activeConnection !== Auth0ConnectionType.AUTH0;

  useEffect(() => {
    if (authorizeAfterSaving && isSecuritySettingsUpdated) {
      setAuthorizeAfterSaving(false);
      AuthService.authorize();
    }
  }, [authorizeAfterSaving, isSecuritySettingsUpdated]);

  const updateMfa = (useMfa: boolean) => {
    const command: SecuritySettingsCommand = {
      useMfa: useMfa,
    };

    dispatch(UserActions.updateSecuritySettings(command));

    if (useMfa) {
      LocalStorageService.setItem(LocalStorageKey.AuthCallbackRedirect, window.location.pathname);
      setAuthorizeAfterSaving(true);
    }
  };

  return (
    <>
      {!isCurrentUserSatisfyWorkspaceMfa && (
        <Box mb={2}>
          <Alert severity={'warning'}>
            <Trans>Selected workspace requires members to have MFA enabled.</Trans>
          </Alert>
        </Box>
      )}

      {isSocial && (
        <Box mb={2}>
          <Alert severity="warning">
            <Trans>
              You are logged in through social media. To enable MFA you have to be logged in with email & password.
            </Trans>
          </Alert>
        </Box>
      )}

      <LoadingButton
        variant={'outlined'}
        color={user?.mfa ? 'error' : 'primary'}
        onClick={() => updateMfa(!user?.mfa)}
        disabled={(!isAnyWorkspaceRequireMfa && !user?.mfa) || isSocial || isSecuritySettingsUpdating}
        loading={isSecuritySettingsUpdating}
      >
        <Trans>{user?.mfa ? 'Disable Multi-Factor Authentication' : 'Setup Multi-Factor Authentication'}</Trans>
      </LoadingButton>

      {!isAnyWorkspaceRequireMfa && !user?.mfa && (
        <Box mt={2}>
          <HelperText
            text={
              <>
                <Trans>
                  MFA is not enabled for any of your workspaces. Please enable it in the workspace settings or contact
                  the workspace admin to enable it.
                </Trans>
              </>
            }
          />
        </Box>
      )}

      <MfaDialog open={!isCurrentUserSatisfyWorkspaceMfa} />
    </>
  );
};
