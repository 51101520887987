import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  ['@keyframes slideUp']: {
    '0%': {
      opacity: 0,
      transform: 'translateY(20px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },

  slideUp: {
    animation: '$slideUp .3s ease-in-out .1s backwards',
  },
});
