import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { useStyles } from './styles';
import { Trans } from 'react-i18next';
import { AddOutlined, LockOutlined } from '@mui/icons-material';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ProjectTeamProps extends ChipProps {
  text: string;
  disabled?: boolean;
  loading?: boolean;
}

export const AddAssigneeButton: React.FC<ProjectTeamProps> = ({ text, disabled, loading, ...rest }) => {
  const styles = useStyles();

  const resolveAddButtonIcon = (disable?: boolean) => {
    return disable ? (
      <LockOutlined />
    ) : loading ? (
      <FontAwesomeIcon icon={faSpinner} size={'xs'} spin={true} style={{ width: 20, height: 20 }} />
    ) : (
      <AddOutlined className={styles.plusIcon} />
    );
  };

  return (
    <Chip
      avatar={resolveAddButtonIcon()}
      label={<Trans>{text}</Trans>}
      disabled={disabled}
      variant={'outlined'}
      {...rest}
    />
  );
};
