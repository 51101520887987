import React from 'react';
import { Box, Grid } from '@mui/material';
import { ToolType } from '@priz/shared/src/models/tools';
import { ToolOverviewCard } from '../tool-overview-card/component';
import { useDispatch, useSelector } from 'react-redux';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm/rrm-goal-type.enum';
import { RrmUtils } from '../../rrm/utils/rrm-utils';
import { useNavigate } from 'react-router-dom';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { ToolSuggestion } from '../../assistant/store/model';
import { ToolUtilizationActions } from '../../project/store/actions/tools.actions';
import { ToolUtilizationCommand } from '../../project/services';
import { LocalesService } from '@priz/shared/src/components/locales/services';

interface AvailableToolsListProps {
  projectId?: number;
  rrmGoalType?: RrmGoalType;
  suggestions?: ToolSuggestion[];
  parentId?: number;
  sourceId?: string;
  navigateOnCreate?: boolean;
}

export const AvailableToolsList: React.FC<AvailableToolsListProps> = ({
  projectId,
  rrmGoalType,
  suggestions,
  parentId,
  sourceId,
  navigateOnCreate = true,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isCreating = useSelector(ToolUtilizationSelector.isAnyCreating(projectId));

  const suggestionsMap = (suggestions || []).reduce((map, suggestion) => {
    map[suggestion.code] = suggestion;
    return map;
  }, {});

  const suggestionsToolsTypes = Object.keys(suggestionsMap);

  const createTool = (type: ToolType) => {
    const command: ToolUtilizationCommand = {
      type,
      title: type == ToolType.RRM && rrmGoalType ? RrmUtils.generateRrmGoalTitle(rrmGoalType) : undefined,
      locale: LocalesService.getCurrentLocale(),
      parentId,
      sourceId,
    };

    dispatch(ToolUtilizationActions.createTool(command, projectId, navigateOnCreate ? navigate : undefined));
  };

  return (
    <Box position={'relative'}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        {Object.keys(ToolType).map((type, key) => {
          const toolType = ToolType[type];
          const isSuggestionTool = suggestionsToolsTypes.includes(toolType);
          const orderIndex = isSuggestionTool ? key : Object.keys(ToolType).length + key + 1;

          return (
            <Grid item xs={12} sm={12} md={6} lg={4} key={key} order={orderIndex}>
              <ToolOverviewCard
                toolType={toolType}
                onToolAdd={() => {
                  createTool(toolType);
                }}
                projectId={projectId}
                highlight={isSuggestionTool}
                info={suggestionsMap[toolType]?.purpose}
              />
            </Grid>
          );
        })}
      </Grid>

      <LoadingOverlay loading={isCreating} backdropStyles={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }} />
    </Box>
  );
};
