import { createSelector, Selector } from 'reselect';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { AppState } from '../../../store/app.state';
import { ProjectIdeasCommentsState } from '../model';
import { IdeaComment } from '@priz/shared/src/models/idea';

const stateSelector = (state: AppState): ProjectIdeasCommentsState => state.projectIdeasComments;

const entitiesSelector = createSelector(stateSelector, (state: ProjectIdeasCommentsState) => state.entities);

const lookupsSelector = createSelector(stateSelector, (state: ProjectIdeasCommentsState) => state.lookups);

const statusesSelector = createSelector(stateSelector, (state: ProjectIdeasCommentsState) => state.statuses);

const getStatusesByProjectId = (projectId: number): Selector<AppState, EntityCollectionStatus> =>
  createSelector(statusesSelector, statuses => statuses.byProjectId[projectId] || {});

const getStatusesByIdeaId = (ideaId: number): Selector<AppState, EntityCollectionStatus> =>
  createSelector(statusesSelector, statuses => statuses.byIdeaId[ideaId] || {});

const getAllByProjectId = (projectId: number): Selector<AppState, IdeaComment[]> =>
  createSelector([entitiesSelector, lookupsSelector], (entities, lookups) => {
    return (lookups.byProjectId[projectId] || []).map(id => entities[id]);
  });

const getAllByIdeaId = (ideaId: number): Selector<AppState, IdeaComment[]> =>
  createSelector([entitiesSelector, lookupsSelector], (entities, lookups) => {
    return (lookups.byIdeaId[ideaId] || []).map(id => entities[id]);
  });

const getAllById = (commentId: number): Selector<AppState, IdeaComment> =>
  createSelector(entitiesSelector, entities => entities[commentId]);

const getByIdeaIdAndUserId = (ideaId: number, userId: number): Selector<AppState, IdeaComment | undefined> =>
  createSelector(getAllByIdeaId(ideaId), comments => comments.find(comment => comment.createdBy.id === userId));

const isCreatingByProjectId = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByProjectId(projectId), (statuses: EntityCollectionStatus) => !!statuses.creating);

const isCreatedByProjectId = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByProjectId(projectId), (statuses: EntityCollectionStatus) => !!statuses.created);

const isUpdatingByProjectId = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByProjectId(projectId), (statuses: EntityCollectionStatus) => !!statuses.updating);

const isUpdatedByProjectId = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByProjectId(projectId), (statuses: EntityCollectionStatus) => !!statuses.updated);

const isRemovingByProjectId = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByProjectId(projectId), (statuses: EntityCollectionStatus) => !!statuses.removing);

const isRemovedByProjectId = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByProjectId(projectId), (statuses: EntityCollectionStatus) => !!statuses.removed);

const isCreatingByIdeaId = (ideaId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByIdeaId(ideaId), (statuses: EntityCollectionStatus) => !!statuses.creating);

const isCreatedByIdeaId = (ideaId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByIdeaId(ideaId), (statuses: EntityCollectionStatus) => !!statuses.created);

const isUpdatingByIdeaId = (ideaId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByIdeaId(ideaId), (statuses: EntityCollectionStatus) => !!statuses.updating);

const isUpdatedByIdeaId = (ideaId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByIdeaId(ideaId), (statuses: EntityCollectionStatus) => !!statuses.updated);

const isRemovingByIdeaId = (ideaId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByIdeaId(ideaId), (statuses: EntityCollectionStatus) => !!statuses.removing);

const isRemovedByIdeaId = (ideaId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByIdeaId(ideaId), (statuses: EntityCollectionStatus) => !!statuses.removed);

export const IdeasCommentsSelectors = {
  getAllByProjectId,
  getAllByIdeaId,
  getAllById,
  getByIdeaIdAndUserId,
  isCreatingByProjectId,
  isCreatedByProjectId,
  isUpdatingByProjectId,
  isUpdatedByProjectId,
  isRemovingByProjectId,
  isRemovedByProjectId,
  isCreatingByIdeaId,
  isCreatedByIdeaId,
  isUpdatingByIdeaId,
  isUpdatedByIdeaId,
  isRemovingByIdeaId,
  isRemovedByIdeaId,
};
