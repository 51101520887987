import { createSelector } from 'reselect';
import { ArrayUtils } from '@priz/shared/src/utils/common';
import { AppState } from '../../../store/app.state';
import { ApprovalActivity, ApprovalActivityStatus } from '../model';

const approvalActivityCollectionSelector = (state: AppState) => state.approvalActivities;

const approvalActivityEntitiesSelector = createSelector(
  approvalActivityCollectionSelector,
  collection => collection.entities,
);

const approvalActivityLoadingMapSelector = createSelector(
  approvalActivityCollectionSelector,
  collection => collection.loadingByProjectId,
);

const areApprovalActivitiesLoadingByProjectIdSelector = (projectId: number) =>
  createSelector(approvalActivityLoadingMapSelector, loadingMap => loadingMap[projectId] || false);

const finalProjectApprovalActivitiesSelector = (projectId: number) =>
  createSelector(approvalActivityEntitiesSelector, (allActivities: { [id: number]: ApprovalActivity }) =>
    ArrayUtils.fromMap(allActivities)
      .filter((a: ApprovalActivity) => a.project.id === projectId && a.status === ApprovalActivityStatus.Final)
      .sort(ArrayUtils.sorterByDateCreated),
  );

const draftProjectApprovalActivity = (projectId: number, userId: number) =>
  createSelector(approvalActivityEntitiesSelector, (allActivities: { [id: number]: ApprovalActivity }) =>
    ArrayUtils.fromMap(allActivities).find(
      (a: ApprovalActivity) =>
        a.project.id === projectId && a.status === ApprovalActivityStatus.Draft && a.createdBy.id === userId,
    ),
  );

const lastFinalApprovalActivitySelector = (projectId: number) =>
  createSelector(
    finalProjectApprovalActivitiesSelector(projectId),
    finalActivities => finalActivities[finalActivities.length - 1],
  );

export const ApprovalActivitiesSelectors = {
  areApprovalActivitiesLoadingByProjectIdSelector,
  finalProjectApprovalActivitiesSelector,
  draftProjectApprovalActivity,
  lastFinalApprovalActivitySelector,
};
