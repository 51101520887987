import React from 'react';
import { Box, BoxProps, Paper, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { TemplateOption } from '../template-option/component';
import { pgColorScheme } from '@priz/shared/src/theme';
import { TemplateCategory, TemplateCompletion, TemplateType } from '@priz/shared/src/models/template';
import { templates } from '../data';

interface TemplateCategoryCardProps extends BoxProps {
  title: string;
  templateType: TemplateType;
  templateCategory: TemplateCategory;
  projectId?: number;
  templateCompletion?: TemplateCompletion;
  interactive?: boolean;
}

export const TemplateCategoryCard: React.FC<TemplateCategoryCardProps> = ({
  title,
  templateType,
  templateCategory,
  projectId,
  templateCompletion = {},
  interactive,
  ...rest
}) => {
  return (
    <Box display={'flex'} justifyContent={'flex-start'} {...rest}>
      <Paper variant={'outlined'} sx={{ backgroundColor: pgColorScheme.transparent }}>
        <Box px={{ xs: 1, md: 4 }} pt={{ xs: 1, md: 2 }} mb={{ xs: 1, md: 2 }}>
          <Typography variant={'h6'} textAlign={'center'}>
            <Trans>{title}</Trans>
          </Typography>
        </Box>

        <Box
          px={{ xs: 1, md: 4 }}
          pb={{ xs: 1, md: 4 }}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          {templates[templateType][templateCategory].map((option, key) => (
            <TemplateOption
              key={option}
              option={option}
              templateType={templateType}
              templateCategory={templateCategory}
              mt={key && { xs: 1, md: 2 }}
              interactive={interactive}
              done={!!templateCompletion[option]}
              projectId={projectId}
            />
          ))}
        </Box>
      </Paper>
    </Box>
  );
};
