import { ToolType } from '@priz/shared/src/models/tools';
import { CftTab } from '../../cft/store/model';
import { PfmTab } from '../../pfm/store/model';
import { SfmTab } from '../../sfm/store/model';

export const toolTypeWorkspaceTabMap = {
  [ToolType.CFT]: CftTab.Model,
  [ToolType.PFM]: PfmTab.Model,
  [ToolType.SFM]: SfmTab.Model,
};

export const toolTypeLinkBackTextMap = {
  [ToolType.PFM]: 'Back to flow',
  [ToolType.SFM]: 'Back to model',
  [ToolType.CFT]: 'Back to model',
};
