import { IToolUtilization, ToolType, ToolTypeUrlKeyMap, ToolUtilization } from '@priz/shared/src/models/tools';
import { EntityMap } from '@priz/shared/src/models/common/entity-collection-state';
import { WorkspaceMember } from '@priz/shared/src/models/workspace';
import { ToolTypeDataMap } from '@priz/shared/src/data/tool-type-data-map';
import { UserUtils } from '@priz/shared/src/utils/user/user-utils';

const groupByLevel = (
  tools: ToolUtilization[],
): {
  parentTools: ToolUtilization[];
  childrenToolsMap: {
    [parentId: number]: ToolUtilization[];
  };
} => {
  const parentTools = [];
  const childrenToolsMap = {};

  tools.forEach(tool => {
    if (tool.parentId) {
      if (!childrenToolsMap[tool.parentId]) childrenToolsMap[tool.parentId] = [];
      childrenToolsMap[tool.parentId].push(tool);
    } else {
      parentTools.push(tool);
    }
  });

  return {
    parentTools,
    childrenToolsMap,
  };
};

const groupByCompleteness = (
  tools: ToolUtilization[],
): {
  complete: ToolUtilization[];
  incomplete: ToolUtilization[];
} => {
  const groups = {
    complete: [],
    incomplete: [],
  };

  (tools || []).forEach(tool => {
    if (tool.completed) {
      groups.complete.push(tool);
    } else {
      groups.incomplete.push(tool);
    }
  });

  return groups;
};

const resolvePath = (type: ToolType, utilizationId: number, projectId?: number, publicId?: string): string => {
  if (publicId) {
    return ['/playground', publicId].join('/');
  }

  if (!publicId && projectId) {
    return ['/project', projectId, 'tools', ToolTypeUrlKeyMap[type], utilizationId].join('/');
  }

  if (!publicId && !projectId) {
    return ['/tools', ToolTypeUrlKeyMap[type], utilizationId].join('/');
  }

  return '/';
};

const resolvePathByUtilization = (tool: IToolUtilization): string => {
  const { id: utilizationId, type, project, publicId } = tool || {};
  const { id: projectId } = project || {};

  return resolvePath(type, utilizationId, projectId, publicId);
};

const resolveListPath = (projectId?: number): string => {
  if (typeof projectId !== 'undefined') {
    return ['/project', projectId, 'tools'].join('/');
  } else {
    return '/projects-and-tools';
  }
};

const filterByText = (
  tools: ToolUtilization[],
  workspaceMembersMap: EntityMap<WorkspaceMember>,
  text: string,
): ToolUtilization[] => {
  if (!text?.length) return tools;

  const textLowerCase = text.toLowerCase();

  return tools.filter(tool => {
    const { name } = ToolTypeDataMap[tool.type];
    const user = workspaceMembersMap[tool.createdBy.id];

    const matchToolTitle = (tool?.title || name)?.toLowerCase().includes(textLowerCase);
    const matchEmail = user?.email?.toLowerCase()?.includes(textLowerCase);
    const matchName = user && UserUtils.getShowableName(user)?.toLowerCase().includes(textLowerCase);

    return matchToolTitle || matchEmail || matchName;
  });
};

export const ToolsUtils = {
  groupByLevel,
  groupByCompleteness,
  resolvePath,
  resolvePathByUtilization,
  resolveListPath,
  filterByText,
};
