import React from 'react';
import { Grid, GridProps, Typography, TypographyProps } from '@mui/material';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Trans } from 'react-i18next';

interface GrayLabelContainerProps extends GridProps {
  label: string | JSX.Element;
  labelProps?: TypographyProps;
  colon?: boolean;
}

export const GrayLabelContainer: React.FC<GrayLabelContainerProps> = ({
  label,
  labelProps,
  colon = true,
  children,
  ...rest
}) => {
  return (
    <Grid container alignItems={'baseline'} spacing={1} {...rest}>
      <Grid item>
        <Typography
          variant={'body2'}
          component={'span'}
          {...(labelProps || {})}
          sx={{ color: pgColorScheme.placeholder }}
        >
          {typeof label === 'string' ? <Trans>{label}</Trans> : label}
          {colon && ':'}
        </Typography>
      </Grid>

      <Grid item maxWidth={'100%'}>
        {children}
      </Grid>
    </Grid>
  );
};
