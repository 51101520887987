import { environment } from '@priz/shared/src/environments/environment';
import axios, { AxiosResponse } from 'axios';
import { Parameter } from '@priz/shared/src/models/tools/forty-principles/parameter';
import { Principle } from '@priz/shared/src/models/tools/forty-principles/principle';

const getParameters = (): Promise<Parameter[]> =>
  axios
    .get(`${environment.apiDomain}/v1/api/40p/parameters`)
    .then((response: AxiosResponse<Parameter[]>) => response.data);

export interface UpdateParameterCommand {
  title: string;
  titleZh?: string;
  titleEs?: string;
  description?: string;
  descriptionZh?: string;
  descriptionEs?: string;
}

const updateParameter = (parameterId: number, command: UpdateParameterCommand): Promise<Principle> =>
  axios
    .put(`${environment.apiDomain}/v1/api/40p/parameter/${parameterId}`, command)
    .then((response: AxiosResponse<Principle>) => response.data);

export const ParameterService = {
  getParameters,
  updateParameter,
};
