import { IFortyPrinciplesUtilization } from '../models/tools/forty-principles/forty-principles-utilization';

export const InventivePrinciplesContradictionMap: {
  name: keyof Pick<IFortyPrinciplesUtilization, 'contradictionIf' | 'contradictionThen' | 'contradictionBut'>;
  label: string;
}[] = [
  {
    name: 'contradictionIf',
    label: 'If',
  },
  {
    name: 'contradictionThen',
    label: 'Then',
  },
  {
    name: 'contradictionBut',
    label: 'But',
  },
];
