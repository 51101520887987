import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    background: pgColorScheme.backgroundMediumGray,
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: 10,

    ['& .vis-network']: {
      outline: 'none',
    },

    ['& .react-colorful']: {
      width: '100%',
      height: 100,

      ['&__hue']: {
        height: 10,
      },
      ['&__pointer']: {
        height: 20,
        width: 20,
      },
      ['&__saturation, &__last-control']: {
        borderRadius: 5,
      },
    },
  },
});
