import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { pgColorScheme } from '@priz/shared/src/theme';

import problemStatementBg from '../../../assets/img/problem-statement-bg.svg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightPane: {
      width: 520,
      height: 520,
      backgroundImage: `url(${problemStatementBg})`,
      backgroundPosition: 'center top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '80% auto',
    },
    stepContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      borderLeftWidth: 4,
      borderLeftStyle: 'solid',
      transition: 'opacity .15s ease',
    },

    stepContainerActive: {
      borderLeftColor: pgColorScheme.blue,
      opacity: 1,
    },

    stepContainerDisabled: {
      pointerEvents: 'none',
      borderLeftColor: pgColorScheme.gray,
      opacity: 0.5,
    },

    caption: {
      color: pgColorScheme.textGray,
    },

    [theme.breakpoints.down('lg')]: {
      rightPane: {
        width: 400,
        height: 400,
      },
    },

    [theme.breakpoints.down('md')]: {
      rightPane: {
        width: 300,
        height: 300,
      },
      title: {
        padding: '6px 10px',
      },
    },

    [theme.breakpoints.down('sm')]: {
      title: {
        padding: '4px 10px',
      },
      fieldContainer: {
        width: '100%',

        ['&._border-left']: {
          borderLeft: `none`,
        },
      },
    },
  }),
);
