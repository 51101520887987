import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../store/app.state';
import { ProjectFeatureBlockerType, ProjectFeatureResolverBlockerProps } from '../model';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';

const projectFeatureBlockerSelector = (state: AppState) => state.projectFeatureBlocker;

const projectFeatureBlockerStatusesSelector: Selector<AppState, EntityCollectionStatus> = createSelector(
  projectFeatureBlockerSelector,
  state => state.statuses,
);

const getBlockerType: Selector<AppState, ProjectFeatureBlockerType> = createSelector(
  projectFeatureBlockerSelector,
  state => state.blocker,
);

const getBlockerData: Selector<AppState, Omit<ProjectFeatureResolverBlockerProps, 'blocker'>> = createSelector(
  projectFeatureBlockerSelector,
  state => ({
    utilizationId: state.utilizationId,
    ideaRecorderProps: state.ideaRecorderProps,
    taskRecorderProps: state.taskRecorderProps,
    rrmStarterProps: state.rrmStarterProps,
    ebsRankingProps: state.ebsRankingProps,
    fortyPrinciplesCommand: state.fortyPrinciplesCommand,
  }),
);

const isAnyCreating: Selector<AppState, boolean> = createSelector(
  projectFeatureBlockerStatusesSelector,
  statuses => statuses.creating,
);

const isBlockerActive: Selector<AppState, boolean> = createSelector(
  [projectFeatureBlockerSelector, isAnyCreating],
  (state, isCreating) => {
    const isBlockerTypeDefined = state.blocker !== ProjectFeatureBlockerType.NotDefined;
    const isStateMockDefined = !!LocalStorageService.getItem(LocalStorageKey.ProjectFeatureBlockerMock);

    return isBlockerTypeDefined || isCreating || isStateMockDefined;
  },
);

export const ProjectFeatureBlockerSelectors = {
  getBlockerType,
  getBlockerData,
  isAnyCreating,
  isBlockerActive,
};
