import React from 'react';
import { SvgIconProps, Tooltip, Typography } from '@mui/material';
import { useStyles } from './styles';
import { OperationPerfectness, OperationPerfectnessType } from '../../models/tools/sfm';
import { useTranslation } from 'react-i18next';
import {
  SentimentVerySatisfied,
  SentimentSatisfiedRounded,
  SentimentNeutralRounded,
  SentimentDissatisfiedRounded,
} from '@mui/icons-material';

const resolveOperationPerfectnessIcon = (type: OperationPerfectnessType) => {
  const props: SvgIconProps = {
    fontSize: 'small',
    sx: {
      display: 'block',
      marginLeft: 0.5,
    },
  };

  switch (type) {
    case OperationPerfectnessType.High:
      return <SentimentVerySatisfied {...props} color={'success'} />;

    case OperationPerfectnessType.Acceptable:
      return <SentimentSatisfiedRounded {...props} color={'primary'} />;

    case OperationPerfectnessType.Medium:
      return <SentimentNeutralRounded {...props} color={'warning'} />;

    case OperationPerfectnessType.Low:
      return <SentimentDissatisfiedRounded {...props} color={'error'} />;

    case OperationPerfectnessType.NotDefined:
      return null;
  }
};

const labelsMap = {
  [OperationPerfectnessType.High]: 'High',
  [OperationPerfectnessType.Acceptable]: 'Acceptable',
  [OperationPerfectnessType.Medium]: 'Medium',
  [OperationPerfectnessType.Low]: 'Low',
};

interface OperationPerfectnessProps {
  operationPerfectness: OperationPerfectness;
  useTooltip?: boolean;
  showNotDefined?: boolean;
}

export const OperationPerfectnessLabel: React.FC<OperationPerfectnessProps> = ({
  operationPerfectness,
  useTooltip,
  showNotDefined,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const classNames = [styles.root];
  const tooltip = [];

  let label = null;

  if (operationPerfectness.type === OperationPerfectnessType.NotDefined) {
    classNames.push(styles.notDefinedOperationPerfectness);
    label = t('Not defined');
  }

  if (operationPerfectness.type !== OperationPerfectnessType.NotDefined) {
    classNames.push(styles.numberOperationPerfectness);

    if (operationPerfectness.type === OperationPerfectnessType.High) classNames.push(styles.high);
    if (operationPerfectness.type === OperationPerfectnessType.Acceptable) classNames.push(styles.acceptable);
    if (operationPerfectness.type === OperationPerfectnessType.Medium) classNames.push(styles.medium);
    if (operationPerfectness.type === OperationPerfectnessType.Low) classNames.push(styles.low);

    label = operationPerfectness.value;
  }

  if (useTooltip) {
    tooltip.push(`${t('Operation Perfectness')}: ${label}`);

    if (operationPerfectness.type !== OperationPerfectnessType.NotDefined) {
      tooltip.push(t(labelsMap[operationPerfectness.type]));
    }
  }

  if (operationPerfectness.type === OperationPerfectnessType.NotDefined && !showNotDefined) return null;

  return (
    <Tooltip title={tooltip.join(', ')} placement={'top'} disableInteractive arrow>
      <Typography variant={'subtitle2'} className={classNames.join(' ')} noWrap>
        OP {label}
        {resolveOperationPerfectnessIcon(operationPerfectness.type)}
      </Typography>
    </Tooltip>
  );
};
