import React from 'react';
import { Box, BoxProps, Tooltip } from '@mui/material';
import { useStyles } from './styles';
import { Trans } from 'react-i18next';

import { ReactComponent as StarIcon } from '../../assets/icons/outlined-star-icon';

export interface WinningButtonProps extends BoxProps {
  isActive?: boolean;
  disabled?: boolean;
}

const resolveAwardIconTooltip = (active: boolean, editable: boolean) => {
  return !editable ? 'Winning' : active ? 'Mark this idea as not winning' : 'Mark this idea as winning';
};

export const WinningButton: React.FC<WinningButtonProps> = ({ isActive, onClick, disabled, ...rest }) => {
  const styles = useStyles();
  const iconWrapClassNames = [styles.iconWrap];

  if (onClick) iconWrapClassNames.push(styles.editable);
  if (isActive) iconWrapClassNames.push(styles.active);
  if (disabled) iconWrapClassNames.push(styles.disabled);

  return (
    <Tooltip title={<Trans>{resolveAwardIconTooltip(!!isActive, !!onClick)}</Trans>} placement="top" arrow={true}>
      <Box className={iconWrapClassNames.join(' ')} onClick={onClick} {...rest}>
        <StarIcon className={styles.icon} />
      </Box>
    </Tooltip>
  );
};
