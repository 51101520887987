import {
  TemplateActivityOptionType,
  TemplateOptionType,
  TemplatePreparingOptionType,
  TemplateResultOptionType,
} from '@priz/shared/src/models/template';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToolType } from '@priz/shared/src/models/tools';
import { ToolUtilizationCommand } from '../../services';
import { ToolUtilizationActions } from '../../store/actions/tools.actions';

export const useTemplateOptionHandler = (
  option: TemplateOptionType,
  projectId: number,
  done?: boolean,
): (() => void) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateToToolsList = () => {
    navigate(['/project', projectId, 'tools'].join('/'));
  };

  const createTool = (toolType: Exclude<ToolType, ToolType.RRM>) => {
    const command: ToolUtilizationCommand = {
      type: toolType as ToolType,
    };

    dispatch(ToolUtilizationActions.createTool(command, projectId, navigate));
  };

  return () => {
    switch (option) {
      case TemplatePreparingOptionType.ProjectBackground:
        navigate(['/project', projectId, 'overview'].join('/'));
        break;
      case TemplatePreparingOptionType.ProblemStatement:
        navigate(['/project', projectId, 'problem-statement'].join('/'));
        break;

      case TemplateActivityOptionType.Tasks:
        navigate(['/project', projectId, 'tasks'].join('/'));
        break;
      case TemplateActivityOptionType.Meetings:
        navigate(['/project', projectId, 'meetings'].join('/'));
        break;
      case TemplateActivityOptionType.Publishing:
        navigate(['/project', projectId, 'publish'].join('/'));
        break;
      case TemplateActivityOptionType.TasksPriority:
        done ? navigate(['/project', projectId, 'tasks'].join('/')) : createTool(ToolType.UIM);
        break;

      case TemplateResultOptionType.Ideas:
        navigate(['/project', projectId, 'ideas'].join('/'));
        break;
      case TemplateResultOptionType.Solution:
        navigate(['/project', projectId, 'solution'].join('/'));
        break;
      case TemplateResultOptionType.Report:
        navigate(['/project', projectId, 'report'].join('/'));
        break;

      case ToolType.TOOL_40_PRINCIPLES:
        done ? navigateToToolsList() : createTool(option);
        break;
      case ToolType.UIM:
        done ? navigateToToolsList() : createTool(option);
        break;
      case ToolType.APA:
        done ? navigateToToolsList() : createTool(option);
        break;
      case ToolType.FIVE_WHYS:
        done ? navigateToToolsList() : createTool(option);
        break;
      case ToolType.EBS:
        done ? navigateToToolsList() : createTool(option);
        break;
      case ToolType.NINE_WINDOWS:
        done ? navigateToToolsList() : createTool(option);
        break;
      case ToolType.CEC:
        done ? navigateToToolsList() : createTool(option);
        break;
      case ToolType.P_MAP:
        done ? navigateToToolsList() : createTool(option);
        break;
      case ToolType.SFM:
        done ? navigateToToolsList() : createTool(option);
        break;
      case ToolType.PFM:
        done ? navigateToToolsList() : createTool(option);
        break;
    }
  };
};
