import { Box, Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RrmUtilization } from '@priz/shared/src/models/tools/rrm/rrm-utilization';
import { Perception, Rank } from '@priz/shared/src/models/tools/rrm';
import { RrmUtilizationActions } from '../../store/actions/rrm-utilization.actions';
import { RrmGoalSelector } from '../../store/selectors';
import { RrmRankingOption } from '../RrmRankingOption/RrmRankingOption';
import { Trans } from 'react-i18next';
import { RrmUtilizationService } from '../../services/rrm-utilization.service';
import { useStyles } from './styles';

interface RrmOptionsSelectorProps {
  utilization: RrmUtilization;
}

enum SelectionSides {
  left = 'left',
  right = 'right',
}

export const RrmOptionsSelector: React.FC<RrmOptionsSelectorProps> = ({ utilization }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const [currentRank, setCurrentRank] = useState<Rank>(undefined);
  const [selectedSide, setSelectedSide] = useState<SelectionSides>(undefined);
  const [rightPerception, setRightPerception] = useState<Perception>(undefined);
  const [leftPerception, setLeftPerception] = useState<Perception>(undefined);

  const goal = useSelector(RrmGoalSelector.getGoalById(utilization.goal.id));

  const resolvePerception = (perceptionId: number): Perception =>
    goal && goal.perceptions.find(p => p.id === perceptionId);

  useEffect(() => {
    if (utilization) setNextComparison();
  }, [utilization]);

  const handleSkipPair = () => {
    if (utilization.smart) {
      dispatch(RrmUtilizationActions.skipSmartRank(utilization.id, currentRank.id, utilization.project?.id));
    } else {
      setNextComparison();
    }

    setSelectedSide(undefined);
  };

  const setNextComparison = () => {
    const rank = RrmUtilizationService.findNextEmptyRandomRank(
      utilization.ranks,
      utilization.smart ? undefined : currentRank?.id,
    );

    if (rank) {
      setCurrentRank(rank);
      setLeftPerception(resolvePerception(rank.left.id));
      setRightPerception(resolvePerception(rank.right.id));
    }
  };

  const handleSelection = (side: SelectionSides) => () => {
    setSelectedSide(side);
  };

  const recordRanking = () => {
    const updatedRank = {
      ...currentRank,
      leftRank: selectedSide === SelectionSides.left ? 1 : 0,
      rightRank: selectedSide === SelectionSides.right ? 1 : 0,
    };

    dispatch(
      RrmUtilizationActions.recordRank(
        utilization.id,
        updatedRank,
        utilization.smart,
        utilization.goal.id,
        goal.sourceUtilizationId,
        utilization.project?.id,
      ),
    );

    setSelectedSide(undefined);
  };

  const isLastRank = () => {
    return utilization.smart
      ? goal.perceptions.filter(p => p.rankingScore === null).length === 1
      : utilization.ranks.filter(r => !Rank.isCompleted(r)).length === 1;
  };

  const resolveNextButtonName = () => (isLastRank() ? 'Save & See Results' : 'Save & Next');

  if (!currentRank || !rightPerception || !leftPerception) {
    return <></>;
  }

  return (
    <>
      <Grid container mt={{ xs: 2, sm: 4 }} spacing={{ xs: 1, sm: 2, md: 4 }}>
        <Grid item xs={12} sm={6}>
          <Box key={`${leftPerception.id}-${rightPerception.id}`} className={styles.option}>
            <RrmRankingOption
              perception={leftPerception}
              selected={selectedSide === SelectionSides.left}
              onSelect={handleSelection(SelectionSides.left)}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box key={`${rightPerception.id}-${leftPerception.id}`} className={styles.option}>
            <RrmRankingOption
              perception={rightPerception}
              selected={selectedSide === SelectionSides.right}
              onSelect={handleSelection(SelectionSides.right)}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container mt={{ xs: 2, sm: 4, md: 6 }} rowSpacing={1} justifyContent={'center'}>
        <Grid item>
          <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleSkipPair} disabled={isLastRank()}>
            <Trans>Skip this pair (for now)</Trans>
          </Button>
        </Grid>

        <Grid item>
          <Button variant={'pg_rounded'} color={'pg_orange_solid'} onClick={recordRanking} disabled={!selectedSide}>
            <Trans>{resolveNextButtonName()}</Trans>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
