import React from 'react';
import { LastPage, FirstPage, KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import { useStyles } from './styles';
import { Box, MenuItem, Select, Typography, IconButton } from '@mui/material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';

export type EditableTableTitle = { label: string; sortParameter: string };

export const TablePaginationActions: React.FC<TablePaginationActionsProps> = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}) => {
  const styles = useStyles();

  const totalPages = Math.ceil(count / rowsPerPage);

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const handleSelectPage = event => {
    onPageChange(event, event.target.value - 1);
  };

  return (
    <Box className={styles.flexContainer}>
      <Box className={styles.flexContainer} ml={{ xs: 2, sm: 3 }}>
        <Box mr={{ xs: 1, sm: 2 }}>
          <Typography variant={'body2'} component={'span'}>
            page:
          </Typography>
        </Box>

        <Select
          value={page + 1}
          onChange={handleSelectPage}
          variant={'outlined'}
          className={styles.selectPageInput}
          disabled={totalPages <= 1}
        >
          {[...new Array(totalPages)].map((_item, key) => (
            <MenuItem key={key} value={key + 1}>
              {key + 1}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box className={styles.flexContainer} ml={1}>
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
          <FirstPage />
        </IconButton>

        <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
          <KeyboardArrowLeft />
        </IconButton>

        <IconButton onClick={handleNextButtonClick} disabled={page >= totalPages - 1}>
          <KeyboardArrowRight />
        </IconButton>

        <IconButton onClick={handleLastPageButtonClick} disabled={page >= totalPages - 1}>
          <LastPage />
        </IconButton>
      </Box>
    </Box>
  );
};
