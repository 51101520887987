import React, { ReactNode, useEffect, useState } from 'react';
import { PieChart, pieChartDefaultProps } from 'react-minimal-pie-chart';
import { Data } from 'react-minimal-pie-chart/types/commonTypes';
import { Box, BoxProps, Grid, IconButton, Paper, PaperProps, Typography, TypographyProps } from '@mui/material';
import { pgColorScheme } from '../../theme';
import { Trans } from 'react-i18next';
import { useStyles } from './styles';
import { HelpOutlineOutlined } from '@mui/icons-material';
import { PopperWrap } from '../popper-wrap/component';

interface LegendItem extends BoxProps {
  label: ReactNode;
  icon?: ReactNode;
  iconContainerProps?: Partial<BoxProps>;
  labelContainerProps?: Partial<TypographyProps>;
}

interface PieChardCardProps {
  chartData: Data;
  legendData?: LegendItem[];
  cardTitle?: string;
  paperContainerProps?: Partial<PaperProps>;
  pieContainerProps?: Partial<BoxProps>;
  maxWidth?: number;
  annotation?: ReactNode;
}

export const PieChardCard: React.FC<PieChardCardProps> = ({
  chartData,
  legendData,
  cardTitle,
  annotation,
  paperContainerProps,
  pieContainerProps,
  maxWidth,
}) => {
  const styles = useStyles();
  const [mounted, setMounted] = useState(false);

  const nonZeroValuesCount = chartData.reduce((sum, item) => {
    if (item.value > 0) sum += 1;
    return sum;
  }, 0);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <Paper variant={'outlined'} sx={{ background: 'transparent', minHeight: '100%' }} {...(paperContainerProps || {})}>
      {cardTitle && (
        <Box className={styles.pipeCardTitleContainer} p={1} textAlign={'center'}>
          <Typography variant={'h6'} display={'inline'} sx={{ verticalAlign: 'middle' }}>
            <Trans>{cardTitle}</Trans>
          </Typography>

          {annotation && (
            <PopperWrap containerProps={{ display: 'inline-block' }} shadow>
              <IconButton color={'primary'} size={'small'}>
                <HelpOutlineOutlined />
              </IconButton>

              <Box>{annotation}</Box>
            </PopperWrap>
          )}
        </Box>
      )}

      <Box p={2}>
        <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
          <Grid item>
            <Box maxWidth={maxWidth || 300}>
              {mounted && (
                <Box position={'relative'} {...(pieContainerProps || {})}>
                  <PieChart
                    segmentsShift={nonZeroValuesCount > 1 ? 0.5 : 0}
                    radius={pieChartDefaultProps.radius - 0.5}
                    label={({ dataEntry }) =>
                      dataEntry.percentage ? `${Math.round(dataEntry.percentage * 10) / 10}%` : ''
                    }
                    labelPosition={nonZeroValuesCount > 1 ? 50 : 0}
                    background={nonZeroValuesCount > 1 ? pgColorScheme.transparent : pgColorScheme.gray400}
                    style={{
                      fontFamily: '"Roboto", sans-serif',
                      fontSize: 8,
                    }}
                    labelStyle={{
                      fill: pgColorScheme.white,
                      pointerEvents: 'none',
                    }}
                    data={chartData}
                  />

                  {!nonZeroValuesCount && (
                    <Box className={styles.pieOverlay}>
                      <Typography variant={'h6'}>
                        <Trans>No data</Trans>
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Grid>

          {legendData && (
            <Grid item>
              <Box px={2} display={'flex'} flexDirection={'column'}>
                {legendData.map((item, key) => {
                  const { icon, label, iconContainerProps, labelContainerProps, ...rest } = item;

                  return (
                    <Box key={key} mt={key ? 1 : 0} display={'flex'} {...rest}>
                      {icon && (
                        <Box pr={1} {...(iconContainerProps || {})}>
                          {icon}
                        </Box>
                      )}

                      {typeof label === 'string' && (
                        <Typography variant={'body1'} {...(labelContainerProps || {})}>
                          <Trans>{label}</Trans>
                        </Typography>
                      )}

                      {typeof label !== 'string' && label}
                    </Box>
                  );
                })}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};
