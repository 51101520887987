import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SfmUtilization } from '@priz/shared/src/models/tools/sfm';
import { Alert, Box, Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { ReactHookFormSelect } from '../../react/form-elements';
import { useForm } from 'react-hook-form';
import { SfmLegend } from '@priz/shared/src/components/sfm-legend/component';
import { SfmRanksFilterType, SfmResultTable } from '@priz/shared/src/components/sfm-result-table/component';
import { SfmResultSelectors } from '../store/selectors';
import { SfmUtilizationActions } from '../store/actions';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { SfmVersionsComparison } from '@priz/shared/src/components/sfm-versions-comparison/component';
import { stringToHash } from '@priz/shared/src/utils/common';
import { SfmSummary } from '@priz/shared/src/components/sfm-summary/component';
import { SfmComponentHelper } from '../sfm-component-helper/component';
import { ToolVersionUtils } from '@priz/shared/src/utils/tools';

export interface SfmResultProps {
  utilization: SfmUtilization;
  active: boolean;
  activeVersionId?: string;
  onVersionSelect?: (versionId: string) => void;
  viewMode?: boolean;
  disabled?: boolean;
}

enum SortProps {
  Functional = 'Functional',
  Problematic = 'Problematic',
}

export const SfmResultViewer: React.FC<SfmResultProps> = ({
  utilization,
  activeVersionId,
  onVersionSelect,
  active,
  viewMode,
  disabled,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [sortBy, setSortBy] = useState<SortProps>(SortProps.Functional);
  const [showRanksType, setShowRanksType] = useState<SfmRanksFilterType>(SfmRanksFilterType.All);

  const [loadedDiagramDataHash, setLoadedDiagramDataHash] = useState<number>();
  const [loadedVersionHash, setLoadedVersionHash] = useState<{ [versionId: string]: number }>({});

  const { setValue, control } = useForm({
    defaultValues: { sortBy, showRanksType },
  });

  const versionsRanksMap = useSelector(SfmResultSelectors.getSfmVersionsRanksByUtilizationId(utilization.id));
  const componentsRanks = useSelector(SfmResultSelectors.getComponentsRanksByVersionId(activeVersionId));
  const problematicComponentsMap = useSelector(
    SfmResultSelectors.getProblematicComponentsMap(utilization.id, activeVersionId),
  );

  const isLoaded = useSelector(SfmResultSelectors.isLoadedByUtilizationId(utilization.id));
  const isVersionLoaded = useSelector(SfmResultSelectors.isLoadedByVersionId(activeVersionId));
  const isVersionLoading = useSelector(SfmResultSelectors.isLoadingByVersionId(activeVersionId));

  const versions = utilization?.diagramData?.versions;

  useEffect(() => {
    if (active && versions) {
      const currentDataHash = stringToHash(
        JSON.stringify(ToolVersionUtils.getOrderedVersions(utilization.diagramData.versions)),
      );

      if (currentDataHash !== loadedDiagramDataHash) {
        setLoadedDiagramDataHash(currentDataHash);
        dispatch(SfmUtilizationActions.calcSfmVersionsRanks(utilization.id, utilization.project?.id));
      }
    }
  }, [active, versions]);

  useEffect(() => {
    const version = versions?.[activeVersionId];

    if (active && version) {
      const versionHash = stringToHash(JSON.stringify(version));

      if (loadedVersionHash[activeVersionId] !== versionHash) {
        setLoadedVersionHash(currentData => ({
          ...currentData,
          [activeVersionId]: versionHash,
        }));

        dispatch(
          SfmUtilizationActions.calcVersionComponentsRanks(utilization.id, activeVersionId, utilization.project?.id),
        );

        dispatch(
          SfmUtilizationActions.getProblematicComponents(utilization.id, activeVersionId, utilization.project?.id),
        );
      }
    }
  }, [utilization?.lastUpdated, active, activeVersionId]);

  const selectRanksToShowHandler = e => {
    const ranksType = e?.target?.value as SfmRanksFilterType;
    const sortType = ranksType === SfmRanksFilterType.Problematic ? SortProps.Problematic : SortProps.Functional;

    setValue('sortBy', sortType);
    setSortBy(sortType);
    setShowRanksType(ranksType);
  };

  const selectSortHandler = e => {
    setSortBy(e?.target?.value as SortProps);
  };

  if (!isLoaded) {
    return <LoadingOverlay loading={true} backdropStyles={{ background: 'transparent' }} />;
  }

  return (
    <div>
      {versions && Object.keys(versions).length > 1 && (
        <Box mb={4}>
          <SfmVersionsComparison
            versions={versions}
            ranksMap={versionsRanksMap}
            mainVersionId={utilization?.diagramData?.versionId}
            activeVersionId={activeVersionId}
            onVersionSelect={onVersionSelect}
          />
        </Box>
      )}

      {isVersionLoading && (
        <Box py={20} position={'relative'}>
          <LoadingOverlay loading={true} backdropStyles={{ background: 'transparent' }} />
        </Box>
      )}

      {!isVersionLoading && !utilization?.diagramData && (
        <Box my={4}>
          <Alert severity="info">
            <Trans>Nothing to show</Trans>
          </Alert>
        </Box>
      )}

      {isVersionLoaded && !isVersionLoading && utilization?.diagramData && componentsRanks && (
        <>
          <Box mb={2}>
            <SfmSummary
              sfmDiagramData={utilization.diagramData}
              componentsRanksMap={componentsRanks}
              sfmRank={versionsRanksMap[activeVersionId || utilization?.diagramData?.versionId]}
              activeVersionId={activeVersionId}
              useTooltips={true}
              showAnnotations={true}
            />
          </Box>

          <Box mb={2}>
            <Grid container alignItems={'center'} justifyContent={'space-between'} spacing={2}>
              <Grid item xs={'auto'}>
                {showRanksType !== SfmRanksFilterType.NoImpact && <SfmLegend />}
              </Grid>

              {!viewMode && (
                <Grid item xs={'auto'}>
                  <Grid container alignItems={'flex-end'} justifyContent={'flex-end'} direction={'row'} spacing={2}>
                    {showRanksType === SfmRanksFilterType.All && (
                      <Grid item xs={'auto'}>
                        <ReactHookFormSelect
                          variant={'standard'}
                          name={'sortBy'}
                          control={control}
                          options={[
                            { value: SortProps.Functional, text: t('Order by Functional') },
                            { value: SortProps.Problematic, text: t('Order by Problematic') },
                          ]}
                          onChange={selectSortHandler}
                          wrapperProps={{
                            width: { xs: '100%', md: 250, lg: 350 },
                            mb: 0,
                          }}
                        />
                      </Grid>
                    )}

                    <Grid item xs={'auto'}>
                      <ReactHookFormSelect
                        variant={'standard'}
                        name={'showRanksType'}
                        control={control}
                        options={[
                          { value: SfmRanksFilterType.All, text: t('Show All') },
                          { value: SfmRanksFilterType.Functional, text: t('Show only Functional') },
                          { value: SfmRanksFilterType.Problematic, text: t('Show only Problematic') },
                          { value: SfmRanksFilterType.NoImpact, text: t('Show only No Impact') },
                        ]}
                        onChange={selectRanksToShowHandler}
                        wrapperProps={{
                          width: { xs: '100%', md: 250, lg: 350 },
                          mb: 0,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>

          <Box>
            <SfmResultTable
              componentsRanksMap={componentsRanks}
              sfmDiagramData={utilization.diagramData}
              activeVersionId={activeVersionId}
              sortFunction={(a, b) => {
                return sortBy === SortProps.Functional
                  ? (b.functionalRank || 0) - (a.functionalRank || 0)
                  : (b.problematicRank || 0) - (a.problematicRank || 0);
              }}
              ranksFilterType={showRanksType}
              rankHelperRenderer={nodeId => {
                const preview = problematicComponentsMap[nodeId];

                if (!preview) return null;

                return (
                  <SfmComponentHelper
                    preview={preview}
                    utilization={utilization}
                    versionId={activeVersionId}
                    editable={!disabled}
                  />
                );
              }}
              dividers
            />

            {/* TODO */}
            {/*<SfmPrescription componentsRanksMap={componentsRanks} sfmDiagramData={utilization.diagramData} mt={4} />*/}
          </Box>
        </>
      )}
    </div>
  );
};
