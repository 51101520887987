import React from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import { Box, BoxProps } from '@mui/material';
import { useStyles } from './styles';

export interface TabPanelProps {
  index: number;
  wrapperProps?: BoxProps;
  tabsProps?: BoxProps;
}

export const SlidingTabs: React.FC<TabPanelProps> = ({ children, index, wrapperProps, tabsProps }) => {
  const styles = useStyles();
  const childrenArray = flattenChildren(children);

  return (
    <Box className={styles.root} {...wrapperProps}>
      <div
        className={styles.slider}
        style={{
          width: `${childrenArray.length * 100}%`,
          transform: `translateX(-${(100 * index) / childrenArray.length}%)`,
        }}
      >
        {childrenArray.map((child, key) => (
          <Box
            key={key}
            className={`${styles.tab}${key === index ? ' _active' : ''}`}
            style={{
              width: `${100 / childrenArray.length}%`,
            }}
            {...tabsProps}
          >
            <Box className={styles.childContainer} hidden={index !== key}>
              {child}
            </Box>
          </Box>
        ))}
      </div>
    </Box>
  );
};
