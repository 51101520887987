import React, { useState } from 'react';
import { Box, Button, Chip, Grid, Paper, Typography } from '@mui/material';
import { Meeting } from '../store/model';
import { MeetingStatus } from '@priz/shared/src/models/meetings';
import { Trans } from 'react-i18next';
import {
  KeyboardDoubleArrowRightRounded,
  RemoveRedEyeOutlined,
  InsertInvitationRounded,
  TimerOutlined,
  PersonRounded,
} from '@mui/icons-material';
import { MeetingViewer } from '../meeting-viewer/component';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { useSelector } from 'react-redux';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { MeetingStatusIndicator } from '../meetings-status-indicator/component';
import { AttendeeRole } from '../attendee-role/component';
import { Assignee } from '../../assignment/assignee/component';
import { WorkspaceMembersSelectors } from '../../workspace/store/selectors/workspace-members.selectors';
import { AttendeeSelectors } from '../store/selectors';
import { resolveDurationLabel } from '../../time-picker-button/component';
import { TimeZoneService } from '@priz/shared/src/services/time';
import { Link } from 'react-router-dom';
import { ToolLinkResolver } from '../../tools/tool-link-resolver/component';

export interface MeetingCardProps {
  meeting: Meeting;
}

export const MeetingCard: React.FC<MeetingCardProps> = ({ meeting }) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const attendees = useSelector(AttendeeSelectors.getAllByProjectIdAndMeetingId(meeting.project.id, meeting.id));
  const lead = useSelector(WorkspaceMembersSelectors.getById(meeting.createdBy.id));
  const isLead = currentUser?.id === meeting.createdBy.id;

  const openViewer = () => {
    setIsViewerOpen(true);
  };

  const closeViewer = () => {
    setIsViewerOpen(false);
  };

  if (meeting.status === MeetingStatus.Draft && !isLead) return null;

  return (
    <Paper variant={'outlined'} component={Box} p={2}>
      <Box mb={2}>
        <Grid container alignItems={'center'} justifyContent={'space-between'} spacing={1}>
          <Grid item xs={true}>
            <Typography variant={'h6'}>{meeting.title}</Typography>
          </Grid>

          <Grid item xs={'auto'}>
            <MeetingStatusIndicator meeting={meeting} />
          </Grid>
        </Grid>
      </Box>

      <Box mb={2}>
        <AttendeeRole meeting={meeting} />
      </Box>

      <Grid container alignItems={'center'} justifyContent={'space-between'} spacing={1}>
        <Grid item overflow={'hidden'}>
          <Grid container spacing={1} alignItems={'center'}>
            {lead && (
              <Grid item>
                <Assignee assignee={lead} variant="filled" size={'small'} />
              </Grid>
            )}

            {!!attendees.length && (
              <Grid item>
                <Chip label={`+${attendees.length}`} icon={<PersonRounded />} size="small" />
              </Grid>
            )}

            <Grid item>
              <Chip
                label={meeting.date ? TimeZoneService.format(meeting.date) : <Trans>Date not set</Trans>}
                icon={<InsertInvitationRounded />}
                variant="outlined"
                size="small"
              />
            </Grid>

            {meeting.durationMs && (
              <Grid item>
                <Chip
                  label={resolveDurationLabel(meeting.durationMs)}
                  icon={<TimerOutlined />}
                  variant="outlined"
                  size={'small'}
                />
              </Grid>
            )}

            {meeting.sourceToolUtilization && (
              <Grid item overflow={'hidden'}>
                <ToolLinkResolver utilizationId={meeting.sourceToolUtilization.id} sourceId={meeting.sourceId} />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item>
          <ButtonsWrap spacing={1}>
            <Button size="small" endIcon={<RemoveRedEyeOutlined />} onClick={openViewer}>
              <Trans>Overview</Trans>
            </Button>

            {isLead && (
              <Button
                component={Link}
                to={['/project', meeting.project.id, 'meeting', meeting.id].join('/')}
                size={'small'}
                endIcon={<KeyboardDoubleArrowRightRounded />}
              >
                <Trans>Open</Trans>
              </Button>
            )}
          </ButtonsWrap>
        </Grid>
      </Grid>

      <MeetingViewer open={isViewerOpen} meeting={meeting} onClose={closeViewer} />
    </Paper>
  );
};
