import React, { useEffect, useState } from 'react';
import Select, {
  components,
  createFilter,
  SelectComponentsConfig,
  Props,
  GroupBase,
  OnChangeValue,
  MultiValue,
  OptionProps,
  SingleValueProps,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Controller } from 'react-hook-form';
import { FieldTitle, HelperText } from '@priz/shared/src/components/form-elements';
import { ValidatorControllerProps, ReactHookFormElement, DefaultInput } from '@priz/shared/src/models/form';
import { Box, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { useStyles } from './styles';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { GroupProps } from 'react-select/dist/declarations/src/components/Group';

type OptionValue = string | number;

export interface Option {
  value: OptionValue;
  label: string;
  extraData?: any;
}

export interface OptionData {
  value: OptionValue;
  label: string;
  data: Option;
}

export interface OptionGroup {
  label: string;
  options: Option[];
  className?: string;
}

export type OptionOrOptionsGroup = Option | OptionGroup;

export interface FilterConfig<D = unknown> {
  ignoreCase?: boolean;
  ignoreAccents?: boolean;
  trim?: boolean;
  matchFrom?: 'start' | 'any';
  stringify?: (optionData: FilterOptionOption<D>) => string;
}

export type ReactHookFormSelectSearchProps = ReactHookFormElement &
  DefaultInput &
  ValidatorControllerProps & {
    options: OptionOrOptionsGroup[];
    noOptionsMessage?: string | JSX.Element;
    onSelect?: (newValue: OnChangeValue<Option, boolean>) => void;
    selectComponents?: SelectComponentsConfig<Option, boolean, GroupBase<Option>>;
    filterConfig?: FilterConfig;
    menuPosition?: 'absolute' | 'fixed';
    menuShouldBlockScroll?: boolean;
    creatable?: boolean;
    clearable?: boolean;
    isMulti?: boolean;
    size?: 'medium' | 'small';
    className?: string;
    inputValue?: string;
    onInputChange?: (value: string) => void;
  };

const defaultFilterConfig: FilterConfig = {
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  matchFrom: 'any',
};

const getOptionByValue = (options: OptionOrOptionsGroup[], value: OptionValue) => {
  const flatOptions = [];

  options.forEach(option => {
    if ('options' in option) {
      flatOptions.push(...option.options);
    }

    if ('value' in option) {
      flatOptions.push(option);
    }
  });

  return flatOptions.find(item => item.value === value) || { value, label: value };
};

export const ReactHookFormSelectSearch: React.FC<ReactHookFormSelectSearchProps> = ({
  name,
  control,
  fieldTitle,
  fieldTitleWrapperProps,
  placeholder,
  helperText,
  wrapperProps,
  rules,
  disabled,
  options,
  filterConfig,
  noOptionsMessage,
  selectComponents,
  onSelect,
  menuPosition,
  menuShouldBlockScroll,
  creatable,
  clearable = true,
  isMulti,
  size = 'medium',
  className,
  inputValue,
  onInputChange,
}) => {
  const styles = useStyles();

  const [showValue, setShowValue] = useState(true);
  const [inputValueState, setInputValueState] = useState(inputValue || '');

  useEffect(() => {
    if (typeof inputValue === 'string' && inputValue !== inputValueState) {
      setInputValueState(inputValue);
    }
  }, [inputValue, inputValueState]);

  const rootClassNames = [styles.root, size];

  if (className) rootClassNames.push(className);

  const hideInputValue = () => {
    if (!isMulti) setShowValue(false);
  };

  const showInputValue = () => {
    if (!isMulti) setShowValue(true);
  };

  const SingleValue = (props: SingleValueProps<Option, boolean, GroupBase<Option>>) => {
    return (
      <components.SingleValue {...props}>
        <Box display={'flex'} alignItems="center">
          {props?.data?.label}
        </Box>
      </components.SingleValue>
    );
  };

  const Option = (props: OptionProps<Option, boolean, GroupBase<Option>>) => {
    return (
      <components.Option {...props}>
        <Box display={'flex'} alignItems="center">
          <Typography variant={'body1'} component={'span'}>
            {props?.data?.label}
          </Typography>
        </Box>
      </components.Option>
    );
  };

  const Group = (props: GroupProps<Option, false, OptionGroup>) => {
    const { children, ...rest } = props;

    return (
      <div className={props?.data?.className}>
        <components.Group {...rest}>{children}</components.Group>
      </div>
    );
  };

  const handleSelect = (newValue: OnChangeValue<Option, boolean>) => {
    if (onSelect) onSelect(newValue);
  };

  const noOptionsMessageCallback = () => {
    const message = noOptionsMessage || 'No matching options';
    return typeof noOptionsMessage === 'string' ? <Trans>{message}</Trans> : message;
  };

  const inputChangeHandler = (value: string) => {
    if (onInputChange) {
      onInputChange(value);
    } else {
      setInputValueState(value);
    }
  };

  return (
    <Box mb={3} {...wrapperProps} className={rootClassNames.join(' ')}>
      <FieldTitle text={fieldTitle} {...fieldTitleWrapperProps} />

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const classNames = [styles.select];

          if (error?.message) classNames.push('_error');

          const selectProps: Props<Option, boolean, GroupBase<Option>> = {
            // menuIsOpen: true,
            className: classNames.join(' '),
            classNamePrefix: 'select-search',
            isDisabled: !!disabled,
            onMenuOpen: hideInputValue,
            onMenuClose: showInputValue,
            controlShouldRenderValue: showValue,
            backspaceRemovesValue: false,
            autoFocus: false,
            tabSelectsValue: false,
            menuPlacement: 'auto',
            menuPosition: menuPosition || 'absolute',
            menuShouldBlockScroll: menuShouldBlockScroll || false,
            closeMenuOnScroll: true,
            menuShouldScrollIntoView: true,
            isClearable: clearable,
            isSearchable: true,
            components: { Option, SingleValue, Group, ...(selectComponents || {}) },
            placeholder: <Trans>{placeholder || ''}</Trans>,
            closeMenuOnSelect: true,
            value: isMulti ? value : getOptionByValue(options, value) || '',
            options: options,
            isMulti: !!isMulti,
            filterOption: createFilter({
              ...defaultFilterConfig,
              ...filterConfig,
            }),
            inputValue: inputValueState,
            onInputChange: inputChangeHandler,
            onChange: (newValue: OnChangeValue<Option, boolean>) => {
              const value = isMulti ? (newValue as MultiValue<Option>) : (newValue as Option)?.value;

              onChange(value || '');
              handleSelect(newValue);

              if (newValue === null) {
                inputChangeHandler('');
              }
            },
            onKeyDown: e => {
              e.stopPropagation();
            },
            noOptionsMessage: noOptionsMessageCallback,
          };

          const creatableProps = {
            onCreateOption: (inputValue: string) => {
              const newOption = { value: inputValue, label: inputValue };

              onChange(isMulti ? [...value, newOption] : inputValue);
              handleSelect(newOption);
            },
          };

          return (
            <>
              {creatable ? <CreatableSelect {...selectProps} {...creatableProps} /> : <Select {...selectProps} />}

              <HelperText error={error?.message} text={helperText} />
            </>
          );
        }}
      />
    </Box>
  );
};
