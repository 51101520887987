import { Role } from '@priz/shared/src/models/security/role.enum';

export const settingsLinks: { to: string; label: string; roles: Role[] }[] = [
  {
    to: '/admin/general',
    label: 'General',
    roles: [Role.ROLE_ADMIN],
  },
  {
    to: '/admin/translations',
    label: 'Translations',
    roles: [Role.ROLE_ADMIN, Role.ROLE_TRANSLATOR],
  },
  {
    to: '/admin/topics',
    label: 'Topics',
    roles: [Role.ROLE_ADMIN],
  },
  {
    to: '/admin/40p',
    label: '40 Principles',
    roles: [Role.ROLE_ADMIN],
  },
  {
    to: '/admin/comments',
    label: 'Comments',
    roles: [Role.ROLE_ADMIN],
  },
  {
    to: '/admin/projects',
    label: 'Projects',
    roles: [Role.ROLE_ADMIN],
  },
  {
    to: '/admin/users',
    label: 'Users',
    roles: [Role.ROLE_ADMIN],
  },
  {
    to: '/admin/workspaces',
    label: 'Workspaces',
    roles: [Role.ROLE_ADMIN],
  },
  {
    to: '/admin/commissions',
    label: 'Commissions',
    roles: [Role.ROLE_ADMIN],
  },
];
