import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      display: 'block',
    },

    iconExtraSmall: {
      width: 22,
      height: 22,
    },

    iconSmall: {
      width: 26,
      height: 26,
    },

    iconMedium: {
      width: 32,
      height: 32,
    },

    iconLarge: {
      width: 40,
      height: 40,
    },

    [theme.breakpoints.down('sm')]: {
      iconMedium: {
        width: 26,
        height: 26,
      },

      iconLarge: {
        width: 32,
        height: 32,
      },
    },
  }),
);
