import { LetterChain, Perception } from '@priz/shared/src/models/tools/pmap/perception';

interface AssignFilterProps {
  letter: string;
  assign: string;
  perceptionsArray: Perception[];
}

interface ContradictFilterProps {
  letter: string;
  conflict: string;
  connections: LetterChain;
  conflicts: LetterChain;
  perceptionsArray: Perception[];
}

export interface PerceptionOption {
  value: string;
  label: string;
}

export const convertPerceptionToOption = (perception: Perception): PerceptionOption => {
  if (perception) {
    const { letter, description, ...rest } = perception;

    return {
      value: letter,
      label: description,
      ...rest,
    };
  }

  return null;
};

const checkIfLetterChainsHasMatches = (connections, firstLetter, secondLetter) => {
  const connection = connections[firstLetter];
  return connection ? connection.indexOf(secondLetter) !== -1 : false;
};

const checkIfLetterAlreadyContradicted = (conflicts, letter) => {
  return Object.keys(conflicts).join('-').indexOf(letter) !== -1;
};

export const filterAvailableContradictPerceptions = ({
  letter,
  conflict,
  connections,
  conflicts,
  perceptionsArray,
}: ContradictFilterProps): PerceptionOption[] => {
  return perceptionsArray
    .map(item => {
      const { letter: currentLetter } = item;

      const isSelfLetter = letter === currentLetter;
      const isConflict = conflict === currentLetter;

      const perceptionIsAvailable =
        !isSelfLetter &&
        !checkIfLetterAlreadyContradicted(conflicts, currentLetter) &&
        checkIfLetterChainsHasMatches(connections, letter, currentLetter);

      if (isConflict) return convertPerceptionToOption(item);
      return perceptionIsAvailable ? convertPerceptionToOption(item) : null;
    })
    .filter(item => !!item);
};

export const filterAvailableAssignPerceptions = ({
  letter,
  assign,
  perceptionsArray,
}: AssignFilterProps): PerceptionOption[] => {
  return perceptionsArray
    .map(item => {
      const { letter: currentLetter, assign: currentAssign } = item;

      const isSelfLetter = letter === currentLetter;
      const isCloseLoop = letter === currentAssign;

      if (currentLetter === assign) return convertPerceptionToOption(item);
      return !isSelfLetter && !isCloseLoop ? convertPerceptionToOption(item) : null;
    })
    .filter(item => !!item);
};
