import React, { useState } from 'react';
import { Popover, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FieldTitle, HelperText } from '@priz/shared/src/components/form-elements';
import { Box } from '@mui/material';
import {
  ReactHookFormSetValue,
  ValidatorControllerProps,
  ReactHookFormElement,
  DefaultInput,
} from '@priz/shared/src/models/form';
import { useStyles } from './styles';
import { pgColorScheme } from '@priz/shared/src/theme';

type Value = string | number | null;

interface Option {
  value: Value;
  text: string;
}

export type ValueColorProps = {
  [key in string | number]: {
    textColor: string;
    backgroundColor: string;
    backgroundHoverColor: string;
  };
};

export type ReactHookFormSelectButtonProps = ReactHookFormElement &
  DefaultInput &
  ValidatorControllerProps & {
    options?: Option[];
    onChange?: (value: Value) => void;
    valueColorProps?: ValueColorProps;
    setValue: ReactHookFormSetValue;
  };

export const ReactHookFormSelectButton: React.FC<ReactHookFormSelectButtonProps> = ({
  name,
  control,
  fieldTitle,
  fieldTitleWrapperProps,
  options = [],
  placeholder = '',
  helperText,
  wrapperProps,
  rules,
  disabled,
  setValue,
  onChange,
  valueColorProps,
}) => {
  const styles = useStyles();
  const buttonClassnames = [styles.button];
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  if (disabled) buttonClassnames.push(styles.disabled);

  const selectHandle = (value: Value) => {
    setValue(name, value);
    closePopover();
    if (onChange) onChange(value);
  };

  const refHandle = (element: HTMLDivElement | null) => {
    if (element) setAnchorEl(element);
  };

  const renderOptions = (selectedValue: Value) => {
    return options.map(({ value, text }, key) => {
      const classNames = [styles.option];

      if (value === selectedValue) classNames.push(styles.activeOption);

      return (
        <Box
          key={key}
          className={classNames.join(' ')}
          onClick={() => {
            selectHandle(value);
          }}
          px={1}
          py={0.5}
        >
          <Typography variant={'body2'} component={'span'}>
            {text?.length ? text : <span>&nbsp;</span>}
          </Typography>
        </Box>
      );
    });
  };

  const openPopover = () => {
    setOpen(true);
  };

  const closePopover = () => {
    setOpen(false);
  };

  return (
    <Box mb={3} {...wrapperProps}>
      <FieldTitle text={fieldTitle} {...fieldTitleWrapperProps} />

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => {
          const {
            textColor = pgColorScheme.gray,
            backgroundColor = pgColorScheme.background,
            backgroundHoverColor = pgColorScheme.backgroundMediumGray,
          } = (valueColorProps && valueColorProps[field.value]) || {};

          const colorStyles = {
            button: {
              backgroundColor,
              'color': textColor,

              '&:hover': {
                backgroundColor: backgroundHoverColor,
              },
            },
          };

          return (
            <>
              <div
                className={buttonClassnames.join(' ')}
                ref={r => refHandle(r)}
                onClick={openPopover}
                style={colorStyles.button}
              >
                <Typography variant={'body2'} component={'span'} className={styles.buttonText}>
                  {options.find(item => item.value === field.value)?.text || placeholder}
                </Typography>
              </div>

              {anchorEl && (
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={closePopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box py={0.5}>{renderOptions(field.value)}</Box>
                </Popover>
              )}

              <HelperText error={error?.message} text={helperText} />
            </>
          );
        }}
      />
    </Box>
  );
};
