import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { PrizLoadingOverlay } from '@priz/shared/src/components/priz-loading-overlay/component';
import { AuthService } from '@priz/shared/src/services/auth';
import { WorkspaceMembersActions } from '../../workspace/store/actions';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PublicWorkspaceApi } from '../../workspace/services/public.api';

export const AcceptInvitation: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [getTokenInfo, setGetTokenInfo] = useState(false);
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  const { isFetched, data } = useQuery(['check-token', token], () => PublicWorkspaceApi.getInvitationInfo(token), {
    enabled: getTokenInfo && !!token,
    retry: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (token) {
      if (AuthService.isAuthenticated()) {
        dispatch(WorkspaceMembersActions.acceptInvitation(token, navigate));
      } else {
        setGetTokenInfo(true);
      }
    } else {
      navigate('/');
    }
  }, [token]);

  useEffect(() => {
    if (!isFetched) return null;
    if (!data) return navigate('/');

    if (data.accept_method) {
      LocalStorageService.setItem(LocalStorageKey.InvitationToken, token);

      switch (data.accept_method) {
        case 'login':
          return navigate('/login');

        case 'signup':
          return navigate('/signup');

        default:
          return navigate('/');
      }
    }
  }, [isFetched, data]);

  return <PrizLoadingOverlay />;
};
