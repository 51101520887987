import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    display: 'flex',
    backgroundColor: pgColorScheme.white,
    borderRadius: 'inherit',
    width: '100%',
    height: '100%',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
  },
  contentContainer: {
    color: pgColorScheme.darkGray,
  },
});
