import { createSelector, Selector } from 'reselect';
import { ToolUtilizationSelector } from '../../../project/store/selectors';
import { AppState } from '../../../store/app.state';

const isCustomerDetailsComplete = (utilizationId: number): Selector<AppState, boolean> =>
  createSelector(
    ToolUtilizationSelector.getApaUtilization(utilizationId),
    utilization =>
      (utilization &&
        utilization.customer &&
        utilization.customer.length > 0 &&
        utilization.customerDescription &&
        utilization.customerDescription.length > 0) ||
      false,
  );

export const ApaUtilizationSelectors = {
  isCustomerDetailsComplete,
};
