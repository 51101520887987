import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { useStyles } from './styles';
import { getPositionInHtmlElement } from '@priz/shared/src/components/network-diagram/utils';

interface DropWrapperProps {
  isDragging?: boolean;
  nodeIsSelected?: boolean;
  onClick?: (x: number, y: number) => void;
}

export const DropWrapper: React.FC<DropWrapperProps> = ({ isDragging, nodeIsSelected, onClick }) => {
  const styles = useStyles();
  const containerRef = useRef(null);

  const [, drop] = useDrop(() => ({
    accept: 'node',
    drop(item, monitor) {
      const { x, y } = monitor.getClientOffset();
      return { item, delta: getPositionInHtmlElement(containerRef.current, x, y) };
    },
  }));

  const clickHandler = e => {
    const { clientX, pageX, clientY, pageY } = e;
    const { x, y } = getPositionInHtmlElement(containerRef.current, clientX || pageX, clientY || pageY);

    if (onClick) onClick(x, y);
  };

  return (
    <div
      ref={containerRef}
      hidden={!isDragging && !nodeIsSelected}
      onClick={clickHandler}
      className={`${styles.root}${nodeIsSelected ? ' _cross' : ''}`}
    >
      <div ref={drop} className={styles.dropArea} />
    </div>
  );
};
