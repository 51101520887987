import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';
import { alpha } from '@mui/material';

export const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    lineHeight: 1,
    borderRadius: 4,
    maxWidth: '100%',
    boxSizing: 'border-box',
    cursor: 'default',
  },
  numberOperationPerfectness: {
    color: pgColorScheme.darkText,
    background: pgColorScheme.background,
    padding: '1px 2px 1px 8px',
  },
  notDefinedOperationPerfectness: {
    color: pgColorScheme.textGray,
    background: pgColorScheme.background,
    padding: '4px 8px',
  },
  high: {
    background: alpha(pgColorScheme.green, 0.2),
  },
  acceptable: {
    background: alpha(pgColorScheme.blue, 0.2),
  },
  medium: {
    background: alpha(pgColorScheme.orange, 0.2),
  },
  low: {
    background: alpha(pgColorScheme.coral, 0.2),
  },
});
