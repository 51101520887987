import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { EbsIdea, EbsIdeaDifficultyVariant } from '@priz/shared/src/models/tools/ebs';
import { validatorRules } from '@priz/shared/src/utils/form';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Tooltip } from '@mui/material';
import { ReactHookFormSelect } from '../../react/form-elements';
import { useStyles } from './styles';
import { colorsMap } from '@priz/shared/src/data/ebs-options';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm';
import {
  EbsCategoriesValidationResult,
  resolveNodeType,
  splitOnCategoriesAndValidate,
} from '@priz/shared/src/utils/ebs';
import { RrmToolStarter } from '../../rrm/RrmToolStarter';

export interface SharedEbsRankIdeasDialogProps {
  projectId: number;
  utilizationId: number;
  ebsIdeas?: EbsIdea[];
  ebsCategoriesValidationResult?: EbsCategoriesValidationResult;
}

export interface EbsRankIdeasDialogProps extends SharedEbsRankIdeasDialogProps {
  open: boolean;
  onClose?: () => void;
}

type EbsIdeasRankVariant = [EbsIdeaDifficultyVariant, EbsIdeaDifficultyVariant];

const options: (EbsIdeasRankVariant | null)[] = [
  null,
  [EbsIdeaDifficultyVariant.Easy, EbsIdeaDifficultyVariant.Easy],
  [EbsIdeaDifficultyVariant.Easy, EbsIdeaDifficultyVariant.NotEasy],
  [EbsIdeaDifficultyVariant.NotEasy, EbsIdeaDifficultyVariant.Easy],
  [EbsIdeaDifficultyVariant.NotEasy, EbsIdeaDifficultyVariant.NotEasy],
];

export const EbsRankIdeasDialog: React.FC<EbsRankIdeasDialogProps> = ({
  open,
  utilizationId,
  ebsIdeas,
  ebsCategoriesValidationResult,
  projectId,
  onClose,
}) => {
  const styles = useStyles();

  const [rankingDialogIsOpen, setRankingDialogIsOpen] = useState(false);

  const categoriesValidationResult = ebsCategoriesValidationResult || splitOnCategoriesAndValidate(ebsIdeas || []);

  const { control, reset } = useForm<{
    rankVariant: string | null;
  }>({
    shouldFocusError: false,
    defaultValues: {
      rankVariant: null,
    },
  });

  const rankVariant = useWatch({
    control,
    name: 'rankVariant',
  });

  useEffect(() => {
    if (typeof open !== 'undefined') {
      setRankingDialogIsOpen(open);
    }
  }, [open]);

  useEffect(() => {
    reset();
  }, [ebsIdeas]);

  const variantArray = rankVariant ? rankVariant.split('-') : [];
  const implementation = variantArray[0] as EbsIdeaDifficultyVariant;
  const validation = variantArray[1] as EbsIdeaDifficultyVariant;
  const categoryType = implementation && validation ? resolveNodeType(implementation, validation) : null;
  const selectedCategoryItemsCount = categoryType ? categoriesValidationResult[categoryType].itemsCount : 0;

  const handleRankingCancel = () => {
    if (onClose) {
      onClose();
    } else {
      setRankingDialogIsOpen(false);
    }
  };

  const preventClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Dialog open={rankingDialogIsOpen} onClose={handleRankingCancel} maxWidth={'md'}>
      <DialogTitle>
        <Trans>Ranking</Trans>
      </DialogTitle>

      <DialogContent>
        <Box className={styles.selectContainer} mt={2}>
          <ReactHookFormSelect
            fieldTitle="Select ideas type for ranking"
            name={'rankVariant'}
            control={control}
            rules={{
              ...validatorRules.required(),
            }}
            wrapperProps={{ mb: 0 }}
          >
            {options.map((item, key) => {
              const type = item ? resolveNodeType(item[0], item[1]) : null;
              const { isCountValid = false, isContentValid = false } = categoriesValidationResult[type] || {};
              const disabled = !isCountValid || !isContentValid;

              const tooltipTitle = disabled ? (
                <Trans>
                  {!isCountValid
                    ? 'At least 3 ideas in this category is required for ranking'
                    : !isContentValid
                    ? 'All ideas in this category must have a description'
                    : ''}
                </Trans>
              ) : (
                ''
              );

              return (
                <MenuItem key={key} value={item ? item.join('-') : null} disabled={disabled}>
                  <Tooltip title={tooltipTitle} placement={'right'} arrow={true}>
                    <div
                      onClick={e => {
                        if (disabled) preventClick(e);
                      }}
                      style={{ pointerEvents: 'auto' }}
                    >
                      {item && (
                        <div className={styles.option}>
                          <div className={styles.indicator} style={{ background: colorsMap[type] }} />

                          <span>
                            <Trans>Implementation</Trans>:&nbsp;
                            <b>
                              <Trans>{item[0] === EbsIdeaDifficultyVariant.Easy ? 'Easy' : 'Not Easy'}</Trans>
                            </b>
                            ,&nbsp;
                          </span>

                          <span>
                            <Trans>Validation</Trans>:&nbsp;
                            <b>
                              <Trans>{item[1] === EbsIdeaDifficultyVariant.Easy ? 'Easy' : 'Not Easy'}</Trans>
                            </b>
                          </span>
                        </div>
                      )}

                      {!item && <span>&nbsp;</span>}
                    </div>
                  </Tooltip>
                </MenuItem>
              );
            })}
          </ReactHookFormSelect>
        </Box>

        <Box mt={2}>
          <RrmToolStarter
            projectId={projectId}
            utilizationId={utilizationId}
            goalType={RrmGoalType.EBS}
            implementation={implementation}
            validation={validation}
            disabled={selectedCategoryItemsCount < 3}
            itemsCount={selectedCategoryItemsCount}
          />
        </Box>
      </DialogContent>

      <DialogActions sx={{ textAlign: 'right' }}>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleRankingCancel}>
          <Trans>Cancel</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
