import { LocalStorageKey, LocalStorageService } from '../local-storage';

const setSelectedWorkspaceId = (workspaceId: number) =>
  LocalStorageService.setItem(LocalStorageKey.SelectedWorkspaceId, workspaceId.toString());

const setSelectedTeamId = (teamId: number) =>
  LocalStorageService.setItem(LocalStorageKey.SelectedTeamId, teamId.toString());

const getSelectedWorkspaceId = (): number | undefined => {
  const lsWorkspaceId = LocalStorageService.getItem(LocalStorageKey.SelectedWorkspaceId);

  return typeof lsWorkspaceId === 'string' ? +lsWorkspaceId : undefined;
};

const getSelectedTeamId = (): number | undefined => {
  const lsTeamId = LocalStorageService.getItem(LocalStorageKey.SelectedTeamId);

  return typeof lsTeamId === 'string' ? +lsTeamId : undefined;
};

const clearSelectedWorkspaceId = () => LocalStorageService.removeItem(LocalStorageKey.SelectedWorkspaceId);
const clearSelectedTeamId = () => LocalStorageService.removeItem(LocalStorageKey.SelectedTeamId);

export const UserContextService = {
  getSelectedWorkspaceId,
  setSelectedWorkspaceId,
  clearSelectedWorkspaceId,
  getSelectedTeamId,
  setSelectedTeamId,
  clearSelectedTeamId,
};
