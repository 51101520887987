import React, { useContext, useEffect } from 'react';
import {
  CftNodeData,
  CftNodesMap,
  CftNodeType,
  OptionalCftNodeControlsRenderer,
  OptionalCftNodeTextEditorRenderer,
} from '../../../models/tools/cft';
import { CftDataUtils } from '../../../utils/cft';
import { Box } from '@mui/material';
import { useStyles } from './styles';
import { CftNodeResolver } from '../cft-node-resolver/component';
import { CftArrows } from '../cft-arrows/component';
import { CftBranchLevelSpaceResolver } from '../cft-level-space-resolver/component';
import { DragAndZoomContainer } from '../../drag-and-zoom-container/component';
import { cftSettings } from '../data';
import { ReactZoomPanPinchProps } from 'react-zoom-pan-pinch';
import { CftContext } from '../cft-context/component';

interface CftDiagramProps extends OptionalCftNodeControlsRenderer, OptionalCftNodeTextEditorRenderer {
  versionNodes?: CftNodeData[];
  versionNodesMap?: CftNodesMap;
  dragAndZoomProps?: Omit<ReactZoomPanPinchProps, 'ref'>;
  disableInteractive?: boolean;
}

export const CftDiagram: React.FC<CftDiagramProps> = ({
  versionNodes,
  versionNodesMap,
  controlsRenderer,
  editorRenderer,
  dragAndZoomProps,
  disableInteractive,
}) => {
  const styles = useStyles();
  const { setCanvasRef, setArrowsRef, setSpacersRef, refreshArrows, fitContent } = useContext(CftContext);

  const nodesMap = versionNodesMap || CftDataUtils.resolveNodesMap(versionNodes);
  const branches = CftDataUtils.resolveBranches(nodesMap);

  let variantsCounter = 0;

  useEffect(() => {
    fitContent();
  }, []);

  return (
    <DragAndZoomContainer
      panning={{ velocityDisabled: true, excluded: ['textarea', 'cft-control'] }}
      pinch={{ excluded: ['textarea', 'cft-control'] }}
      contentProps={{ className: styles.content, p: cftSettings.spacing.container }}
      canvasRef={setCanvasRef}
      disabled={disableInteractive}
      {...(dragAndZoomProps || {})}
    >
      <CftArrows nodes={versionNodes} nodesMap={nodesMap} arrowsRef={setArrowsRef} />

      {branches
        .sort((l, r) => l.index - r.index)
        .map((branch, branchKey) => (
          <Box
            key={branchKey}
            mr={branchKey < branches.length - 1 ? cftSettings.spacing.branch : 0}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'flex-start'}
          >
            {branch.fromId && nodesMap[branch.fromId] && (
              <CftBranchLevelSpaceResolver
                parentNode={nodesMap[branch.fromId]}
                spacerRef={setSpacersRef}
                onSpacingChange={refreshArrows}
              />
            )}

            {branch.tree.map((level, levelKey) => {
              const rowSpacing = levelKey ? cftSettings.spacing.row * (level.nodes.length > 1 ? 1.6 : 1) : 0;

              if (level.nodes.length > 1) {
                return (
                  <Box
                    key={levelKey}
                    mt={rowSpacing}
                    display={'flex'}
                    alignItems={'flex-start'}
                    justifyContent={'flex-start'}
                  >
                    {level.nodes.map((node, key) => {
                      const columnOffset = key ? cftSettings.spacing.col : 0;

                      if (node.type === CftNodeType.Variant) {
                        variantsCounter += 1;
                      }

                      return (
                        <CftNodeResolver
                          key={key}
                          node={node}
                          nodesMap={nodesMap}
                          ml={columnOffset}
                          controlsRenderer={controlsRenderer}
                          editorRenderer={editorRenderer}
                          variantIndex={variantsCounter}
                          inRootBranch={!!branch.root.root}
                          disableInteractive={disableInteractive}
                        />
                      );
                    })}
                  </Box>
                );
              }

              if (level.nodes.length) {
                if (level.nodes[0].type === CftNodeType.Variant) {
                  variantsCounter += 1;
                }

                return (
                  <CftNodeResolver
                    key={levelKey}
                    node={level.nodes[0]}
                    nodesMap={nodesMap}
                    mt={rowSpacing}
                    controlsRenderer={controlsRenderer}
                    editorRenderer={editorRenderer}
                    variantIndex={variantsCounter}
                    inRootBranch={!!branch.root.root}
                    disableInteractive={disableInteractive}
                  />
                );
              }
            })}
          </Box>
        ))}
    </DragAndZoomContainer>
  );
};
