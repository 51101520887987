export const setDocumentTitle = (text: string): null => {
  const documentTitleParts = [];

  documentTitleParts.unshift(text);
  documentTitleParts.unshift('PRIZ Guru');

  document.title = documentTitleParts.join(' | ');

  return null;
};
