import {
  Agenda,
  Attendee,
  IMeeting,
  MeetingCreateCommand,
  MeetingFullUpdateCommand,
  MeetingUpdateCommand,
} from '../store/model';
import axios, { AxiosResponse } from 'axios';

const getAll = (projectId: number): Promise<IMeeting> =>
  axios.get(`/v1/api/<ws-id>/project/${projectId}/meetings`).then((response: AxiosResponse<IMeeting>) => response.data);

const get = (projectId: number, meetingId: number): Promise<IMeeting> =>
  axios
    .get(`/v1/api/<ws-id>/project/${projectId}/meeting/${meetingId}`)
    .then((response: AxiosResponse<IMeeting>) => response.data);

const create = (projectId: number, command: MeetingCreateCommand): Promise<IMeeting> =>
  axios
    .post(`/v1/api/<ws-id>/project/${projectId}/meeting`, command)
    .then((response: AxiosResponse<IMeeting>) => response.data);

const update = (projectId: number, meetingId: number, command: MeetingUpdateCommand): Promise<IMeeting> =>
  axios
    .patch(`/v1/api/<ws-id>/project/${projectId}/meeting/${meetingId}`, command)
    .then((response: AxiosResponse<IMeeting>) => response.data);

const fullUpdate = (
  projectId: number,
  meetingId: number,
  command: MeetingFullUpdateCommand,
): Promise<{ meeting: IMeeting; attendees: Attendee[]; agenda: Agenda[] }> =>
  axios
    .patch(`/v1/api/<ws-id>/project/${projectId}/meeting/${meetingId}/full-update`, command)
    .then((response: AxiosResponse<{ meeting: IMeeting; attendees: Attendee[]; agenda: Agenda[] }>) => response.data);

const release = (projectId: number, meetingId: number): Promise<IMeeting> =>
  axios
    .patch(`/v1/api/<ws-id>/project/${projectId}/meeting/${meetingId}/release`)
    .then((response: AxiosResponse<IMeeting>) => response.data);

const start = (projectId: number, meetingId: number): Promise<IMeeting> =>
  axios
    .patch(`/v1/api/<ws-id>/project/${projectId}/meeting/${meetingId}/start`)
    .then((response: AxiosResponse<IMeeting>) => response.data);

const finish = (projectId: number, meetingId: number): Promise<IMeeting> =>
  axios
    .patch(`/v1/api/<ws-id>/project/${projectId}/meeting/${meetingId}/finish`)
    .then((response: AxiosResponse<IMeeting>) => response.data);

const doDelete = (projectId: number, meetingId: number): Promise<IMeeting> =>
  axios
    .delete(`/v1/api/<ws-id>/project/${projectId}/meeting/${meetingId}`)
    .then((response: AxiosResponse<IMeeting>) => response.data);

export const MeetingApi = {
  getAll,
  get,
  create,
  update,
  fullUpdate,
  release,
  start,
  finish,
  delete: doDelete,
};
