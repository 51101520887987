import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { alpha } from '@mui/material';

export const useStyles = makeStyles({
  editButton: {
    display: 'block',
    borderRadius: '50%',
    backgroundColor: alpha(pgColorScheme.darkGray, 0),
  },

  enabledEditButton: {
    color: pgColorScheme.blue,
    cursor: 'pointer',
    transition: 'color .15s ease, background-color .15s ease',

    ['&:hover']: {
      color: pgColorScheme.orange,
      backgroundColor: alpha(pgColorScheme.darkGray, 0.1),
    },
  },

  disabledEditButton: {
    color: pgColorScheme.gray500,
    backgroundColor: alpha(pgColorScheme.darkGray, 0.1),
  },
});
