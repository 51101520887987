import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../store/app.state';
import { Goal } from '@priz/shared/src/models/tools/rrm';

const rrmGoalCollectionSelector = (state: AppState) => state.rrmGoals;

const rrmGoalEntitiesSelector = createSelector(rrmGoalCollectionSelector, collection => collection.entities);

const rrmGoalStatusesSelector = createSelector(rrmGoalCollectionSelector, collection => collection.statuses);

const getGoalById = (goalId?: number): Selector<AppState, Goal> =>
  createSelector(rrmGoalEntitiesSelector, entities => goalId && entities[goalId]);

const isLoadingByGoalId = (goalId: number) =>
  createSelector(rrmGoalStatusesSelector, statuses => !!statuses.byGoalId[goalId]?.loading);

const isUpdatingByGoalId = (goalId: number) =>
  createSelector(rrmGoalStatusesSelector, statuses => !!statuses.byGoalId[goalId]?.updating);

const isCreatingByGoalId = (goalId: number) =>
  createSelector(rrmGoalStatusesSelector, statuses => !!statuses.byGoalId[goalId]?.creating);

const isPerceptionRemoving = (goalId: number, perceptionId: number) =>
  createSelector(rrmGoalStatusesSelector, statuses => {
    return statuses.byGoalId[goalId]?.removingIds?.includes(perceptionId) || false;
  });

export const RrmGoalSelector = {
  getGoalById,
  isLoadingByGoalId,
  isUpdatingByGoalId,
  isCreatingByGoalId,
  isPerceptionRemoving,
};
