import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FiveWhysUtilization } from '@priz/shared/src/models/tools/five-whys';
import { FiveWhysCausesSelector } from '../../../five-whys/store/selectors/five-whys-causes.selectors';
import { FiveWhysSolutionSelectors } from '../../../five-whys/store/selectors/five-whys-solution.selectors';
import { FiveWhysCauseActions, FiveWhysSolutionActions } from '../../../five-whys/store/actions';
import { FiveWhysViewer } from '@priz/shared/src/components/tools-viewers';

export const FiveWhysReportResult: React.FC<{ tool: FiveWhysUtilization }> = ({ tool }) => {
  const dispatch = useDispatch();

  const causes = useSelector(FiveWhysCausesSelector.getAllByUtilizationId(tool?.id));
  const solutions = useSelector(FiveWhysSolutionSelectors.getAllByUtilizationId(tool?.id));

  useEffect(() => {
    dispatch(FiveWhysCauseActions.loadCauses(tool.id));
    dispatch(FiveWhysSolutionActions.loadSolutions(tool.id));
  }, []);

  return <FiveWhysViewer causes={causes || []} solutions={solutions || []} />;
};
