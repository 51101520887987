import React from 'react';
import { ApaAction, ApaActionStatus } from '../../models/tools/apa';
import { Box, Card, Grid, IconButton, Typography } from '@mui/material';
import { useStyles } from './styles';
import { ButtonsWrap } from '../../components/buttons-wrap/component';
import { Status } from '../../components/status/component';
import { StatusesTypes } from '../../components/status/component';
import { pgColorScheme } from '../../theme';

import { ReactComponent as TrashIcon } from '../../assets/icons/trash';

interface ApaCardProps {
  apaAction: ApaAction;
  compact?: boolean;
  finished?: boolean;
  disabled?: boolean;
  onDelete?: (e: React.SyntheticEvent) => void;
}

export const ApaActionCard: React.FC<ApaCardProps> = ({ apaAction, compact, finished, disabled, onDelete }) => {
  const styles = useStyles();
  const showStatus = apaAction.status === ApaActionStatus.Completed ? StatusesTypes.Done : apaAction.status;

  const deleteClickHandler = (e: React.SyntheticEvent) => {
    if (onDelete) onDelete(e);
  };

  return (
    <Card className={styles.root} elevation={compact ? 0 : 3} variant={compact ? 'outlined' : 'elevation'}>
      <Box p={compact ? 1 : 2} pb={{ xs: 1, sm: 1, md: compact ? 1 : 2 }}>
        <Grid container>
          <Grid item xs={compact ? true : 12} sm={true}>
            <Box display={'flex'} height={{ xs: 'auto', sm: '100%' }}>
              <Box my={'auto'} pr={{ xs: 0, sm: 1, md: 3 }} className={styles.textWrap}>
                <Typography variant={'body1'} component={'span'} className={styles.text}>
                  {apaAction.customerAction}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={compact ? 'auto' : 12} sm={'auto'}>
            <Box pt={{ xs: 0.5, sm: 0 }} minHeight={{ xs: 0, sm: 24 }} className={styles.controlsWrap}>
              <ButtonsWrap spacing={1}>
                <Status status={showStatus} disabled={finished || disabled} />

                {onDelete && !finished && (
                  <IconButton onClick={deleteClickHandler} disabled={disabled}>
                    <TrashIcon fill={pgColorScheme.blue} />
                  </IconButton>
                )}
              </ButtonsWrap>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};
