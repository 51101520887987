import React from 'react';
import { useStyles } from './styles';
import { Box, Grid, Typography, BoxProps } from '@mui/material';
import { Trans } from 'react-i18next';
import { FiveWhysEdge } from '../five-whys-edge/component';
import { FiveWhysCause, FiveWhysSolution } from '../../../models/tools/five-whys';
import { sortByChain } from '../../../utils/five-whys';
import { FiveWhysNode } from '../five-whys-node/component';

export interface FiveWhysDiagramNodeRendererProps {
  cause: FiveWhysCause;
  description: string;
  deadEndAchieved: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  rank?: number;
  compact?: boolean;
  viewMode?: boolean;
}

interface FiveWhysDiagramProps {
  causes: FiveWhysCause[];
  solutions: FiveWhysSolution[];
  viewMode?: boolean;
  compact?: boolean;
  boxProps?: BoxProps;
  causeNodeRenderer?: (props: FiveWhysDiagramNodeRendererProps) => JSX.Element;
  solutionNodeRenderer?: (props: FiveWhysDiagramNodeRendererProps) => JSX.Element;
}

export const FiveWhysDiagram: React.FC<FiveWhysDiagramProps> = ({
  causes,
  solutions,
  viewMode,
  compact,
  boxProps,
  causeNodeRenderer,
  solutionNodeRenderer,
}) => {
  const styles = useStyles();
  const isDeadEndAchieved = !!(causes && causes.find(c => c.deadEnd));

  const getSolutionText = (causeId: number) => {
    const relatedSolution = solutions.find(c => c?.cause?.id === causeId);
    return relatedSolution ? relatedSolution.description : '';
  };

  const getSolutionRank = (causeId: number) => {
    const relatedSolution = solutions.find(c => c?.cause?.id === causeId);
    return relatedSolution?.latestRankingScore;
  };

  return (
    <Box className={`${styles.root}${compact ? ' _compact' : ''}`} px={3} pt={3} pb={20} {...boxProps}>
      <Box minWidth={isDeadEndAchieved ? 540 : 240}>
        <Box pb={compact ? 2 : 3}>
          <Grid container justifyContent="center">
            <Grid
              item
              container
              xs={compact ? true : isDeadEndAchieved ? 5 : 12}
              direction={'column'}
              alignItems={'center'}
            >
              <Typography variant={compact ? 'subtitle2' : 'h6'} component="span">
                <Trans>Causes Chain</Trans>
              </Typography>

              {!viewMode && (
                <Typography variant="subtitle1" component="span" gutterBottom={false}>
                  <Trans>Define a logical sequence of causes</Trans>
                </Typography>
              )}
            </Grid>

            {isDeadEndAchieved && (
              <>
                <Grid item xs={compact ? 1 : 2} />

                <Grid
                  item
                  container
                  xs={compact ? true : isDeadEndAchieved ? 5 : 12}
                  direction={'column'}
                  alignItems={'center'}
                >
                  <Typography variant={compact ? 'subtitle2' : 'h6'} component="span">
                    <Trans>Root Cause Analysis</Trans>
                  </Typography>

                  {!viewMode && (
                    <Typography variant="subtitle1" component="span" gutterBottom={false}>
                      <Trans>For each cause, describe how you could eliminate the cause.</Trans>
                    </Typography>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Box>

        {causes &&
          sortByChain(causes).map((cause, index) => {
            const isFirst = !index;
            const isLast = index === causes.length - 1;
            const { id, description = '', deadEnd } = cause;

            const causeProps: FiveWhysDiagramNodeRendererProps = {
              cause,
              description,
              deadEndAchieved: isDeadEndAchieved,
              isFirst,
              isLast,
              compact,
              viewMode,
            };

            const solutionProps: FiveWhysDiagramNodeRendererProps = {
              cause,
              deadEndAchieved: false,
              rank: getSolutionRank(id),
              description: getSolutionText(id),
              compact,
              viewMode,
            };

            return (
              <Grid
                key={index}
                container
                justifyContent="center"
                alignItems={'stretch'}
                className={`${styles.causeRow}${deadEnd ? ' _dead-end' : ''}`}
              >
                <Grid
                  item
                  container
                  xs={compact ? true : isDeadEndAchieved ? 5 : 12}
                  direction={'column'}
                  alignItems={'stretch'}
                >
                  <Box width={'100%'} maxWidth={!isDeadEndAchieved ? 660 : undefined} mx={'auto'}>
                    {causeNodeRenderer ? (
                      causeNodeRenderer(causeProps)
                    ) : (
                      <FiveWhysNode {...causeProps} viewMode={true} compact={compact} />
                    )}

                    {deadEnd && (
                      <Box pt={compact ? 1 : 2} px={1}>
                        <Typography variant={compact ? 'caption' : 'subtitle2'} component="div">
                          <Trans>This is the fundamental reason for the problem (FRP).</Trans>
                        </Typography>

                        {!compact && (
                          <Typography variant="subtitle2" component="div" gutterBottom={false}>
                            <Trans>
                              Eliminating it will not solve the current problem, but can prevent it from happening in
                              the future.
                            </Trans>
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>

                  {!isLast && !deadEnd && <FiveWhysEdge variant={'down'} label={'Why?'} compact={compact} />}
                </Grid>

                {isDeadEndAchieved && (
                  <>
                    <FiveWhysEdge variant={'left'} compact={compact} />

                    <Grid item xs={compact ? true : isDeadEndAchieved ? 5 : 12}>
                      {solutionNodeRenderer ? (
                        solutionNodeRenderer(solutionProps)
                      ) : (
                        <FiveWhysNode {...solutionProps} viewMode={true} compact={compact} />
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            );
          })}
      </Box>
    </Box>
  );
};
