import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  problemStatementWrap: {
    'borderLeft': `1px solid ${pgColorScheme.placeholder}`,

    '& .ql-editor': {
      'padding': 0,

      '& > *:last-child': {
        marginBottom: 0,
      },
    },
  },
  workspaceContainer: {
    background: pgColorScheme.backgroundMediumGray,
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: 10,
  },
});
