import axios, { AxiosRequestConfig } from 'axios';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth';
import { UserContextService } from '../../services/user';
import { LocalStorageKey, LocalStorageService } from '../local-storage';
import qs from 'qs';
import { AxiosService } from './axios-service';

const updateAxiosConfig = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const workspaceId = UserContextService.getSelectedWorkspaceId();
  const teamId = UserContextService.getSelectedTeamId();

  let token: string;

  if (config.params?.anonymous) {
    token = process.env.REACT_APP_ENVIRONMENT_ANONYMOUS_API_KEY || '';
  } else {
    token = LocalStorageService.getItem(LocalStorageKey.AccessToken) || config?.params?.token;
  }

  const url = config?.url
    ?.replace('<ws-id>', workspaceId?.toString() || 'ws-id')
    ?.replace('<t-id>', teamId?.toString() || 't-id');

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return {
    ...config,
    params: {
      ...(config.params || {}),
      anonymous: undefined,
    },
    url,
  };
};

type UnknownParams = { [key: string]: unknown };

const clearParams = (params: UnknownParams): UnknownParams => {
  const clearedParams: UnknownParams = {};

  Object.keys(params).forEach(key => {
    if (!['skipAuthCheck', 'renewTokenErrorCallback'].includes(key)) {
      clearedParams[key] = params[key];
    }
  });

  return clearedParams;
};

export interface AxiosRequestParams {
  params: {
    skipAuthCheck?: boolean;
    renewTokenErrorCallback?: () => void;
    token?: string;
  };
}

const init = () => {
  axios.defaults.baseURL = environment.apiDomain;
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  axios.defaults.paramsSerializer = {
    serialize: params => qs.stringify(clearParams(params)),
  };

  axios.interceptors.request.use(
    (config: AxiosRequestConfig<AxiosRequestParams>) => {
      return new Promise((resolve, reject) => {
        const isAnonymous = AxiosService.isAnonymousAccessEnabled();
        const skipAuth0 = config?.params?.skipAuthCheck;

        if (isAnonymous || skipAuth0) {
          resolve(updateAxiosConfig(config));
        }

        if (!isAnonymous && !skipAuth0) {
          AuthService.authPromise(config?.params?.renewTokenErrorCallback).then(
            () => {
              resolve(updateAxiosConfig(config));
            },
            () => {
              reject();
            },
          );
        }
      });
    },
    error => {
      // TODO: Do something with request error
      const { response } = error || {};
      console.log('Request Error: ', response);
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(undefined, error => {
    // TODO: Do something with response error

    const { response } = error || {};
    const { status } = response || {};

    console.log('Response Error: ', response);

    if (status === 401) AuthService.renewToken();

    return Promise.reject(error);
  });
};

export const AxiosConfig = {
  init,
};
