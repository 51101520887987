import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Trans } from 'react-i18next';
import { ReactHookFormText } from '../../../react/form-elements';
import { validatorRules } from '@priz/shared/src/utils/form';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { ApiKey } from '@priz/shared/src/models/api-keys';

interface ApiKeyTitleEditorDialogProps {
  apiKey: ApiKey;
  open?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onSave?: (title: string) => void;
  onCancel?: () => void;
}

export const ApiKeyTitleEditorDialog: React.FC<ApiKeyTitleEditorDialogProps> = ({
  apiKey,
  open,
  onSave,
  onCancel,
  disabled,
  loading,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { handleSubmit, reset, control } = useForm({
    shouldFocusError: false,
    defaultValues: {
      title: apiKey.title || '',
    },
  });

  useEffect(() => {
    if (typeof open !== 'undefined') {
      setIsOpen(open);
    }

    if (open) {
      reset({ title: apiKey.title || '' });
    }
  }, [open]);

  const handleClose = () => {
    if (onCancel) {
      onCancel();
    } else {
      setIsOpen(false);
    }
  };

  const handleSave = () => {
    handleSubmit(formData => {
      if (onSave) onSave(formData.title);
    })();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>
        <Trans>Edit API key title</Trans>
      </DialogTitle>

      <DialogContent>
        <ReactHookFormText
          fieldTitle={'API key title'}
          name={'title'}
          control={control}
          rules={{
            ...validatorRules.required(),
          }}
          wrapperProps={{ mb: 2 }}
          disabled={disabled || loading}
        />
      </DialogContent>

      <DialogActions>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose}>
          <Trans>Cancel</Trans>
        </Button>

        <LoadingButton
          variant={'pg_rounded'}
          color={'pg_orange_solid'}
          onClick={handleSave}
          disabled={disabled || loading}
          loading={loading}
        >
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
