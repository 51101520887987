import React from 'react';
import { AppNavbar } from '../../navigation/app-navbar/component';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { PageContainer } from '../../content-containers/page-container/component';
import { Box, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { TermsAndPolicySettings } from './terms/component';

export const GeneralSettingsPage: React.FC = () => {
  return (
    <PageContainer>
      <AppNavbar />

      <>
        <PageTitleWithDocLink title={'General'} />

        <Typography variant={'h6'} component={Box} mb={2}>
          <Trans>Terms & Policy</Trans>
        </Typography>

        <Box maxWidth={500}>
          <TermsAndPolicySettings />
        </Box>
      </>
    </PageContainer>
  );
};
