import { CalcResult, IPMapUtilization, PMapUtilization } from '@priz/shared/src/models/tools/pmap';
import axios, { AxiosResponse } from 'axios';
import { AccessLevel } from '@priz/shared/src/models/security/access-level';
import { PMapContributedPerceptionStatus } from '@priz/shared/src/models/tools/pmap/pmap-contributed-perception';

const update = (utilization: PMapUtilization): Promise<IPMapUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/p-map/${utilization.id}`, {
      title: utilization.title,
      data: utilization.data,
      conclusion: utilization.conclusion,
      subject: utilization.subject,
    })
    .then((response: AxiosResponse<IPMapUtilization>) => response.data);

const doDelete = (utilizationId: number): Promise<any> =>
  axios.delete(`/v1/api/<ws-id>/team/<t-id>/p-map/${utilizationId}`);

const getResult = (utilizationId: number): Promise<CalcResult> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/p-map/${utilizationId}/result`)
    .then((response: AxiosResponse<CalcResult>) => response.data);

export interface PerceptionMapUpdatePublicAccessLevelCommand {
  accessLevel: AccessLevel;
}

const updatePublicAccessLevel = (
  utilizationId: number,
  command: PerceptionMapUpdatePublicAccessLevelCommand,
): Promise<IPMapUtilization> =>
  axios
    .patch(`/v1/api/<ws-id>/team/<t-id>/p-map/${utilizationId}/public-access-level`, command)
    .then((response: AxiosResponse<IPMapUtilization>) => response.data);

export type ResolveContributedPerceptionStatus =
  | PMapContributedPerceptionStatus.Accepted
  | PMapContributedPerceptionStatus.Rejected;

export interface PerceptionMapResolveContributedPerceptionCommand {
  perceptionId: string;
  status: ResolveContributedPerceptionStatus;
}

const resolveContributedPerception = (
  utilizationId: number,
  command: PerceptionMapResolveContributedPerceptionCommand,
): Promise<IPMapUtilization> =>
  axios
    .patch(`/v1/api/<ws-id>/team/<t-id>/p-map/${utilizationId}/resolve-contributed-perception`, command)
    .then((response: AxiosResponse<IPMapUtilization>) => response.data);

export const PMapUtilizationApi = {
  delete: doDelete,
  update,
  getResult,
  updatePublicAccessLevel,
  resolveContributedPerception,
};
