import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const EditIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon width={18} height={18} viewBox="0 0 18 18" {...props}>
      <svg width="19" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.6328 2.57157L15.426 0.367072C14.9358 -0.12265 14.1385 -0.122298 13.6486 0.367775L1.85042 12.1714C1.48175 12.5397 1.2002 12.9957 1.03626 13.4902L1.02575 13.5219L0 18L4.48217 16.9762L4.51381 16.9657C5.0087 16.8021 5.46513 16.521 5.83422 16.1523L17.6336 4.34764C17.8707 4.11032 18.0013 3.79488 18.0011 3.45943C18.001 3.12405 17.8702 2.80872 17.6328 2.57157ZM1.87071 16.1305L2.28966 14.3014L3.7019 15.7122L1.87071 16.1305ZM4.98824 15.0098L2.99328 13.0169L12.7456 3.26015L14.7406 5.25307L4.98824 15.0098ZM15.7346 4.25865L13.7396 2.26573L14.5378 1.46718L16.5328 3.4601L15.7346 4.25865Z"
          fill="#1198D2"
        />
      </svg>
    </SvgIcon>
  );
};
