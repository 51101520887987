import { FluxStandardAction } from 'flux-standard-action';
import { Certificate } from '../model/certificate';
import { CertificateService } from '../../services/certificate.service';
import { createPgAction } from '../../../shared/store/action-creator';

interface CertificateMeta {
  projectId?: number;
}

// interface CertificatePayload {
//   certificatePayload?: any;
// }

export enum CertificateActionType {
  Set = 'certificate/set',
  Load = 'certificate/load',
  Update = 'certificate/update',
}

export type CertificateAction = FluxStandardAction<CertificateActionType, Certificate, CertificateMeta>;

// ### if !certificateCollectionState.loadingIds[projectId]
const loadCertificateByProjectId = createPgAction(
  CertificateActionType.Load,
  CertificateService.getByProjectId,
  (projectId: number) => ({ projectId }),
);

// ### if !certificateCollectionState.certificates[projectId]
const generateCertificateByProjectId = createPgAction(
  CertificateActionType.Set,
  CertificateService.generate,
  (projectId: number) => ({ projectId }),
);

const updateCertificate = createPgAction(
  CertificateActionType.Update,
  CertificateService.update,
  (projectId: number) => ({ projectId }),
);

const createFullDocument = createPgAction(
  CertificateActionType.Update,
  CertificateService.createFullDocument,
  (projectId: number) => ({ projectId }),
);

export const CertificateActions = {
  loadCertificateByProjectId,
  generateCertificateByProjectId,
  updateCertificate,
  createFullDocument,
};
