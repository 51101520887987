import { createStyles, makeStyles } from '@mui/styles';
import { alpha, Theme } from '@mui/material';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ['&.medium']: {
        ['& .select-search__control']: {
          minHeight: 55,
        },
      },

      ['&.small']: {
        ['& .select-search__control']: {
          minHeight: 42,
        },
      },

      ['& .select-search']: {
        ['&__menu']: {
          zIndex: 3,
        },

        ['&__control']: {
          outline: 'none',
          cursor: 'text',
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: pgColorScheme.lightGray,

          ['&:hover']: {
            borderColor: pgColorScheme.placeholder,
          },

          ['&--is-focused']: {
            borderColor: pgColorScheme.blue,
            boxShadow: `0 0 0 1px ${pgColorScheme.blue}`,

            ['&:hover']: {
              borderColor: pgColorScheme.blue,
            },
          },
        },

        ['&__value-container']: {
          padding: '6px 14px',
        },

        ['&__indicator']: {
          cursor: 'pointer',
        },

        ['&__option']: {
          cursor: 'pointer',
          transition: 'background-color .1s ease',

          ['&--is-focused']: {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
          ['&--is-selected']: {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            color: pgColorScheme.darkText,
          },
          ['&:active']: {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
          },
        },

        ['&__multi-value']: {
          background: pgColorScheme.white,
          border: `1px solid ${pgColorScheme.blue}`,
          borderRadius: 4,
        },

        ['&__multi-value__label']: {
          fontSize: 14,
          padding: 7,
          color: pgColorScheme.blue,
        },

        ['&__multi-value__remove']: {
          paddingLeft: 5,
          paddingRight: 5,
          cursor: 'pointer',
          color: pgColorScheme.textGray,

          ['& svg']: {
            width: 18,
            height: 18,
          },

          ['&:hover']: {
            color: pgColorScheme.coral,
            backgroundColor: alpha(pgColorScheme.coral, 0.3),
          },
        },

        ['&._error:not(:hover) &__control']: {
          borderColor: pgColorScheme.coral,
        },
      },
    },

    select: {
      fontFamily: '"Roboto", sans-serif',
      fontSize: 16,
      lineHeight: '1',
      letterSpacing: 0,
    },

    [theme.breakpoints.down('lg')]: {
      root: {
        ['&.medium']: {
          ['& .select-search__control']: {
            minHeight: 51,
          },
        },
      },
    },

    [theme.breakpoints.down('md')]: {
      root: {
        ['&.medium']: {
          ['& .select-search__control']: {
            minHeight: 47,
          },
        },
      },
    },

    [theme.breakpoints.down('sm')]: {
      root: {
        ['&.medium']: {
          ['& .select-search__control']: {
            minHeight: 43,
          },
        },
      },
    },
  }),
);
