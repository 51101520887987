export const getEbsWarningText = (t: (s: string) => string): string[] => {
  return [t('This note is not categories yet.'), t('Please, define implementation'), t('and validation difficulties.')];
};

export const getEbsDifficultiesText = (
  t: (s: string) => string,
): {
  implementationLabel: string;
  validationLabel: string;
  easy: string;
  notEasy: string;
} => {
  return {
    implementationLabel: t('Implementation'),
    validationLabel: t('Validation'),
    easy: t('easy'),
    notEasy: t('not easy'),
  };
};
