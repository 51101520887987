import React from 'react';
import { Avatar, Chip, ChipProps } from '@mui/material';
import { UserUtils } from '@priz/shared/src/utils/user/user-utils';
import { ProjectAssigneeRole, ProjectAssigneeWithMember } from '@priz/shared/src/models/assignment';
import { useStyles } from './styles';

export interface ReportUserProps extends ChipProps {
  assignee: ProjectAssigneeWithMember;
}

export const ReportUser: React.FC<ReportUserProps> = ({ assignee, ...rest }) => {
  const styles = useStyles();
  const isLead = assignee?.assigneeRole === ProjectAssigneeRole.Lead;
  const variant = isLead ? 'filled' : 'outlined';

  if (!assignee?.user) return null;

  return (
    <Chip
      avatar={<Avatar src={assignee.user.profile.avatarUrl}>{UserUtils.resolveInitials(assignee.user)}</Avatar>}
      label={UserUtils.getShowableName(assignee.user)}
      variant={variant}
      className={`${styles.root} _${variant}`}
      {...rest}
    />
  );
};
