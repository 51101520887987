import { createStyles, makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileDrop: {
      '& .file-drop-target': {
        position: 'relative',
        borderRadius: 4,
        overflow: 'hidden',
      },

      '& .file-drop-target.file-drop-dragging-over-frame': {
        '& $dropContainer': {
          outlineColor: pgColorScheme.blue,
        },
      },
      '& .file-drop-target.file-drop-dragging-over-frame.file-drop-dragging-over-target': {
        '& $dropContainer': {
          outlineColor: pgColorScheme.green,
          backgroundColor: pgColorScheme.white,
        },
        '& $overlay': {
          opacity: 0.3,
          pointerEvents: 'none',
        },
      },

      '&._error': {
        '& $dropContainer': {
          outlineColor: pgColorScheme.coral,
        },
      },
    },
    dropContainer: {
      minHeight: 320,
      width: '100%',
      borderRadius: 'inherit',
      outlineWidth: 6,
      outlineStyle: 'dashed',
      outlineColor: pgColorScheme.gray,
      outlineOffset: -1,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      backgroundOrigin: 'border-box',
    },
    overlay: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    plusIconContainer: {
      width: 38,
      height: 38,
      borderRadius: '50%',
      background: pgColorScheme.lightGray,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    [theme.breakpoints.down('xl')]: {
      dropContainer: {
        minHeight: 240,
      },
    },

    [theme.breakpoints.down('sm')]: {
      dropContainer: {
        minHeight: 200,
      },
    },
  }),
);
