import React from 'react';
import { Perception, PerceptionCalcResult, PMapPerceptionBlockingRank } from '@priz/shared/src/models/tools/pmap';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Trans } from 'react-i18next';
import { StartRankingButton } from '../../rrm/start-ranking-button/component';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm';
import { useStyles } from './styles';
import { lettersToNumber } from '@priz/shared/src/utils/convertation';

interface PMapResultingTableProps {
  projectId: number;
  utilizationId: number;
  result: PerceptionCalcResult[];
  perceptions: Perception[];
  disabled?: boolean;
}

type tableRowData = {
  result: PerceptionCalcResult;
  perception: Perception;
};

type ResultingTableRowsData = { [rank in PMapPerceptionBlockingRank]: tableRowData[] };

const blockingRanks = [PMapPerceptionBlockingRank.High, PMapPerceptionBlockingRank.Med, PMapPerceptionBlockingRank.Low];
const columnsWidth = ['65%', '15%', '20%'];

const sortRows = (rows: tableRowData[]) => {
  const hasRanks = rows.some(row => typeof row.perception.latestRankingScore === 'number');

  return rows.sort((a, b) => {
    const aVal = hasRanks ? b.perception.latestRankingScore : lettersToNumber(a.result.letter);
    const bVal = hasRanks ? a.perception.latestRankingScore : lettersToNumber(b.result.letter);

    return aVal - bVal;
  });
};

export const PMapResultingTable: React.FC<PMapResultingTableProps> = ({
  projectId,
  utilizationId,
  perceptions = [],
  result,
  disabled,
}) => {
  const styles = useStyles();

  const blockingRankClassNamesMap = {
    [PMapPerceptionBlockingRank.Low]: styles.blockingRankLow,
    [PMapPerceptionBlockingRank.Med]: styles.blockingRankMed,
    [PMapPerceptionBlockingRank.High]: styles.blockingRankHigh,
  };

  const perceptionsMap = perceptions.reduce((map: { [letter: string]: Perception }, item) => {
    map[item.letter] = item;
    return map;
  }, {});

  const separatedResult = result.reduce((map: ResultingTableRowsData, item) => {
    const relatedPerception = perceptionsMap[item.letter];

    if (relatedPerception) {
      map[item.blockingRank] = [
        ...(map[item.blockingRank] || []),
        {
          result: item,
          perception: relatedPerception,
        },
      ];
    }

    return map;
  }, {} as ResultingTableRowsData);

  return (
    <>
      {blockingRanks.map(blockingRank => {
        const rowsCount = separatedResult[blockingRank]?.length;

        const areAllPerceptionsFilled = !separatedResult[blockingRank]?.some(i => {
          return !i.perception.description.length;
        });

        let rankingButtonTooltip = '';

        if (rowsCount < 3) {
          rankingButtonTooltip = 'At least 3 perceptions required for ranking.';
        }

        if (!areAllPerceptionsFilled) {
          rankingButtonTooltip = 'All perceptions must have a description';
        }

        if (!rowsCount) return null;

        return (
          <Box key={blockingRank} mb={2}>
            <TableContainer component={Paper} variant={'outlined'} sx={{ maxHeight: 210 }}>
              <Table size={'small'} className={styles.table} stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    {['Perception', 'Blocking rank', 'Ranking score'].map((item, key) => (
                      <TableCell key={key} align={'left'} width={columnsWidth[key]}>
                        <Grid container spacing={1} alignItems={'center'} flexWrap={'nowrap'}>
                          <Grid item>
                            <Typography variant={'subtitle2'} whiteSpace={'nowrap'}>
                              <Trans>{item}</Trans>
                            </Typography>
                          </Grid>

                          {key === 2 && blockingRank !== PMapPerceptionBlockingRank.Low && (
                            <Grid item>
                              <Tooltip
                                title={rankingButtonTooltip.length ? <Trans>{rankingButtonTooltip}</Trans> : ''}
                                placement={'top'}
                                arrow
                                disableInteractive
                              >
                                <div>
                                  <StartRankingButton
                                    projectId={projectId}
                                    utilizationId={utilizationId}
                                    goalType={RrmGoalType.P_MAP}
                                    itemsCount={rowsCount}
                                    buttonText={'Rank perceptions'}
                                    blockingRank={blockingRank}
                                    buttonProps={{
                                      size: 'pg_small',
                                      disabled: disabled || rowsCount < 3 || !areAllPerceptionsFilled,
                                    }}
                                  />
                                </div>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {sortRows(separatedResult[blockingRank]).map(item => {
                    return (
                      <TableRow key={item.perception.id || item.perception.letter}>
                        <TableCell width={columnsWidth[0]}>
                          <Grid container spacing={1} alignItems={'baseline'}>
                            <Grid item xs={'auto'}>
                              <Typography variant={'subtitle2'} className={styles.letter}>
                                {item.perception.letter}
                              </Typography>
                            </Grid>

                            <Grid item xs={true}>
                              <Typography variant={'body2'}>{item.perception.description}</Typography>
                            </Grid>
                          </Grid>
                        </TableCell>

                        <TableCell width={columnsWidth[1]}>
                          <Typography variant={'body2'} className={blockingRankClassNamesMap[blockingRank]}>
                            {item.result.blockingRank}
                          </Typography>
                        </TableCell>

                        <TableCell width={columnsWidth[2]}>{item.perception.latestRankingScore}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        );
      })}
    </>
  );
};
