import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { UsersAdministrationApi } from '../../../users-manager/api/users-administration.api';
import { AxiosError } from 'axios';
import { NotificationActions } from '../../../react/modules/notification/store';
import { useDispatch } from 'react-redux';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Trans } from 'react-i18next';

export const TermsAndPolicySettings: React.FC = () => {
  const dispatch = useDispatch();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const mutation = useMutation(() => UsersAdministrationApi.resetAcceptedPolicies(), {
    onSuccess: () => {
      dispatch(NotificationActions.add('Successfully updated', 'success', 10000));
    },
    onError: (error: AxiosError<{ message: string }>) => {
      dispatch(
        NotificationActions.add(
          error?.response?.data?.message || error?.message || 'Something went wrong',
          'error',
          10000,
        ),
      );
    },
  });

  const openConfirmation = () => {
    setIsConfirmationOpen(true);
  };

  const closeConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const confirmHandler = () => {
    closeConfirmation();
    mutation.mutate();
  };

  return (
    <>
      <Paper variant={'outlined'} component={Box} p={1}>
        <Grid container spacing={1}>
          <Grid item xs={true}>
            <Box p={1}>
              <Typography variant={'body1'}>
                <Trans>Reset accepted policies for ALL users</Trans>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={'auto'}>
            <LoadingButton variant={'outlined'} loading={mutation.isLoading} onClick={openConfirmation} color={'error'}>
              <Trans>Reset</Trans>
            </LoadingButton>
          </Grid>
        </Grid>
      </Paper>

      <Dialog open={isConfirmationOpen} onClose={closeConfirmation} maxWidth={'xs'} fullWidth>
        <DialogTitle>
          <Trans>Confirm reset</Trans>
        </DialogTitle>

        <DialogContent>
          <Trans>Are you sure you want to reset accepted policies for ALL users?</Trans>
        </DialogContent>

        <DialogActions sx={{ textAlign: 'right' }}>
          <Button variant={'text'} color={'error'} onClick={confirmHandler}>
            <Trans>Reset</Trans>
          </Button>

          <Button variant={'outlined'} color={'primary'} onClick={closeConfirmation}>
            <Trans>Cancel</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
