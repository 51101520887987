import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import apaBg from '../../../assets/img/apa-bg.svg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightPane: {
      width: 520,
      height: 520,
      backgroundImage: `url(${apaBg})`,
      backgroundPosition: 'center top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% auto',
    },

    [theme.breakpoints.down('xl')]: {
      rightPane: {
        width: 420,
        height: 420,
      },
    },

    [theme.breakpoints.down('lg')]: {
      rightPane: {
        width: 320,
        height: 320,
      },
    },
  }),
);
