import React from 'react';
import { Task } from '@priz/shared/src/models/task';
import { Box, BoxProps, Grid, Paper, Typography } from '@mui/material';
import { TaskControls } from '../task-controls/components';
import { RankingScore } from '@priz/shared/src/components/ranking-score/component';
import { AssignmentLateOutlined } from '@mui/icons-material';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Status } from '@priz/shared/src/components/status/component';

interface TaskCardCompactProps extends BoxProps {
  task: Task;
  disabled?: boolean;
}

export const TaskCardCompact: React.FC<TaskCardCompactProps> = ({ task, disabled, ...rest }) => {
  return (
    <Paper variant={'outlined'} component={Box} pl={1} pr={0.5} py={0.5} {...rest}>
      <Grid container alignItems={'center'} spacing={1}>
        <Grid item>
          <AssignmentLateOutlined fontSize={'medium'} sx={{ display: 'block', color: pgColorScheme.darkOrange }} />
        </Grid>

        {typeof task.latestRankingScore === 'number' && (
          <Grid item xs={'auto'}>
            <RankingScore rankingScore={task.latestRankingScore} />
          </Grid>
        )}

        <Grid item xs={true}>
          <Typography variant={'subtitle2'} noWrap>
            {task.title}
          </Typography>
        </Grid>

        <Grid item>
          <Status status={task.status} variant={'chip'} containerChipPros={{ size: 'extra_small' }} />
        </Grid>

        <Grid item>
          <TaskControls task={task} disabled={disabled} iconsProps={{ fontSize: 'small' }} />
        </Grid>
      </Grid>
    </Paper>
  );
};
