import { CrumbType } from '../model';
import { ToolTypeUrlKeyMap } from '@priz/shared/src/models/tools';

export const conditionalCrumbTypes = [
  {
    condition: ['overview'],
    type: CrumbType.Overview,
  },
  {
    condition: ['publish'],
    type: CrumbType.Publish,
  },
  {
    condition: ['tasks'],
    type: CrumbType.Tasks,
  },
  {
    condition: ['meetings'],
    type: CrumbType.Meetings,
  },
  {
    condition: ['problem-statement'],
    type: CrumbType.ProblemStatement,
  },
  {
    condition: ['ideas'],
    type: CrumbType.Ideas,
  },
  {
    condition: ['solution'],
    type: CrumbType.Solution,
  },
  {
    condition: ['report'],
    type: CrumbType.Report,
  },
  {
    condition: ['tools'],
    type: CrumbType.ToolsList,
  },
  {
    condition: Object.values(ToolTypeUrlKeyMap),
    type: CrumbType.Tool,
  },
];
