import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { ToolType, ToolUtilization } from '@priz/shared/src/models/tools';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { SharedWorkingFlowButtonsProps, WorkingFlowButtons } from '../../project/working-flow-buttons/component';
import { ContentControlBar, ContentControlBarProps } from '../content-control-bar/component';
import { DeleteToolButton } from '../../tools/delete-tool-button/component';
import { BackToParentToolButton } from '../../tools/back-to-parent-tool-button/component';
import { PublicToolMessage } from '../../playground/public-tool-message/component';
import { CompleteToolButton } from '../../tools/complete-tool-button/component';

export interface SharedProjectContentControlBarProps {
  workingFlowProps?: SharedWorkingFlowButtonsProps;
  hideWorkingFlowButtons?: boolean;
}

interface ProjectContentControlBarProps extends ContentControlBarProps, SharedProjectContentControlBarProps {
  projectId?: number;
  utilization?: ToolUtilization;
  parentUtilization?: ToolUtilization;
  disabled?: boolean;
}

export const ProjectContentControlBar: React.FC<ProjectContentControlBarProps> = ({
  projectId,
  workingFlowProps,
  utilization,
  parentUtilization,
  disabled,
  leftColContent,
  leftColCollapsingContent,
  rightColCollapsingContent,
  hideWorkingFlowButtons,
  ...rest
}) => {
  const [isBackToTemplateVisible, setIsBackToTemplateVisible] = useState(false);

  const isPublicTool = !!utilization?.publicId;
  const hasParentTool = typeof utilization?.parentId === 'number';
  const isStandaloneTool = !isPublicTool && typeof projectId === 'undefined';
  const isToolDeletionAllowed = utilization && !isPublicTool && ![ToolType.APA].includes(utilization.type);

  useEffect(() => {
    const showBackToTemplateNavigation = LocalStorageService.getItem(LocalStorageKey.ShowBackToTemplateNavigation);

    if (showBackToTemplateNavigation) {
      LocalStorageService.removeItem(LocalStorageKey.ShowBackToTemplateNavigation);
      setIsBackToTemplateVisible(true);
    }
  }, []);

  return (
    <ContentControlBar
      leftColContent={
        leftColContent || isToolDeletionAllowed || isPublicTool ? (
          <>
            {isToolDeletionAllowed && (
              <DeleteToolButton utilization={utilization} parentUtilization={parentUtilization} disabled={disabled} />
            )}

            {isPublicTool && <PublicToolMessage />}

            {leftColContent}
          </>
        ) : undefined
      }
      leftColCollapsingContent={
        leftColCollapsingContent || hasParentTool || isStandaloneTool || isBackToTemplateVisible ? (
          <>
            {!hasParentTool && isStandaloneTool && <CompleteToolButton utilization={utilization} />}

            {hasParentTool && <BackToParentToolButton utilization={utilization} />}

            {!hasParentTool && isStandaloneTool && (
              <Button component={Link} to={`/projects-and-tools`} variant={'pg_rounded'} color={'pg_blue_solid'}>
                <Trans>Back to List</Trans>
              </Button>
            )}

            {isBackToTemplateVisible && (
              <Button
                component={Link}
                to={`/project/${projectId}/template`}
                variant={'pg_rounded'}
                color={'pg_blue_solid'}
              >
                <Trans>Back to Template</Trans>
              </Button>
            )}

            {leftColCollapsingContent}
          </>
        ) : undefined
      }
      rightColCollapsingContent={
        !hideWorkingFlowButtons || rightColCollapsingContent ? (
          <>
            {!hideWorkingFlowButtons && (
              <div>
                <WorkingFlowButtons
                  projectId={projectId}
                  sourceTool={utilization}
                  disabled={disabled}
                  {...(workingFlowProps || {})}
                />
              </div>
            )}

            {rightColCollapsingContent}
          </>
        ) : undefined
      }
      {...rest}
    />
  );
};
