import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { ProjectForm } from '../project-form/component';
import { ProjectSelector } from '../store/selectors';
import { ProjectActions } from '../store/actions/project.actions';
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import { AppNavbar } from '../../navigation/app-navbar/component';

import { ReactComponent as NewProjectBg } from '../../../assets/img/new-project-bg.svg';
import { PageContainer } from '../../content-containers/page-container/component';

export interface NewProjectProps {
  projectId?: number;
}

export const NewProject: React.FC<NewProjectProps> = ({ projectId }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const navigate = useNavigate();

  const project = useSelector(ProjectSelector.getById(projectId));

  useEffect(() => {
    if (projectId) {
      dispatch(ProjectActions.loadProject(projectId));
    }
  }, [projectId]);

  const handleCancel = () => {
    navigate('/projects-and-tools');
  };

  if (projectId && !project) return null;

  return (
    <PageContainer variant={'narrow'}>
      <AppNavbar />

      <>
        <Box display="flex" flexDirection="column" className={styles.root}>
          <Box mb={2}>
            <Typography component={'div'} className={styles.pageTitle}>
              <Trans>{projectId ? 'Start project' : 'New project'}</Trans>
            </Typography>
          </Box>

          <Box>
            <ProjectForm project={project} onCancel={handleCancel} />
          </Box>

          <Box className={styles.imgWrap} mt={10}>
            <NewProjectBg className={styles.img} />
          </Box>
        </Box>
      </>
    </PageContainer>
  );
};
