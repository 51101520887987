import { HintLinesSeparatorType } from '../hint-content/component';
import { AssistantHintData } from '../store/model';
import { ReactNode } from 'react';

const splitTextOnRows = (text: string, linesSeparator: HintLinesSeparatorType): string[] => {
  if (linesSeparator === HintLinesSeparatorType.LineBreak) return text.split('\n');
  if (linesSeparator === HintLinesSeparatorType.OrderNumber) return text.split(/\s(?=\d+\.)/);
  return [];
};

interface HitTextResolverResult {
  rawText: string | null;
  textRows: string[] | null;
  content: ReactNode;
}

export const resolveHintText = (
  hintData: AssistantHintData,
  linesSeparator: HintLinesSeparatorType = HintLinesSeparatorType.LineBreak,
): HitTextResolverResult => {
  const { text, variants, content } = hintData || {};
  const result: HitTextResolverResult = { rawText: null, textRows: null, content: null };

  if (text && text.length) {
    result.rawText = text;
    result.textRows = splitTextOnRows(text, linesSeparator);
  }

  if (variants && variants.join('').length) {
    result.rawText = variants.join('');
    result.textRows = variants;
  }

  if (content) {
    result.content = content;
  }

  return result;
};
