import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToolType, ToolUtilization } from '@priz/shared/src/models/tools';
import { ToolTypeDataMap } from '@priz/shared/src/data/tool-type-data-map';
import { Box, Button, Grid, SvgIconProps, Tooltip, Typography, TypographyProps } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { CecUtilizationActions } from '../../cec/store/actions';
import { UimUtilizationActions } from '../../uim/store/actions/uim-utilization.actions';
import { NineWindowsUtilizationActions } from '../../nine-windows/store/actions';
import { EbsUtilizationActions } from '../../ebs/store/actions';
import { ApaUtilizationActions } from '../../apa/store/actions';
import { FiveWhysUtilizationActions } from '../../five-whys/store/actions';
import { PMapUtilizationActions } from '../../pmap/store/actions';
import { RrmUtilizationActions } from '../../rrm/store/actions/rrm-utilization.actions';
import { FortyPrinciplesUtilizationActions } from '../../forty-principles/store/actions';
import { SfmUtilizationActions } from '../../sfm/store/actions';
import { PfmUtilizationActions } from '../../pfm/store/actions';
import {
  FortyPrinciplesUtilizationCommand,
  resolve40pInitialUpdateCommandData,
} from '../../forty-principles/services/forty-principles-utilization.service';
import { FortyPrinciplesUtilization } from '@priz/shared/src/models/tools/forty-principles/forty-principles-utilization';

import { EditOutlined } from '@mui/icons-material';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { ReactHookFormText } from '@priz/shared/src/components/form-elements';
import { validateOptions } from '@priz/shared/src/utils/form';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { ToolIcon } from '../tool-icon/component';
import { useStyles } from './styles';
import { CftUtilizationActions } from '../../cft/store/actions';

type PresetType = 'small' | 'medium' | 'large';

interface ToolTitleProps {
  tool: ToolUtilization;
  preset?: PresetType;
  typographyProps?: Partial<TypographyProps>;
  iconProps?: Partial<SvgIconProps>;
  initialTitle?: string;
  editable?: boolean;
  disableIcon?: boolean;
  titleTooltip?: string;
}

const presets: {
  [key in PresetType]: {
    typography?: Partial<TypographyProps>;
    icon?: Partial<SvgIconProps>;
  };
} = {
  small: {
    typography: {
      variant: 'subtitle2',
    },
    icon: {
      sx: { fontSize: 16, padding: 0.5 },
    },
  },
  medium: {
    typography: {
      variant: 'body0',
    },
    icon: {
      sx: { fontSize: 20, padding: 0.5 },
    },
  },
  large: {
    typography: {
      variant: 'h4',
    },
    icon: {
      sx: { fontSize: 24, padding: 0.75 },
    },
  },
};

export const ToolTitle: React.FC<ToolTitleProps> = ({
  tool,
  preset = 'medium',
  initialTitle,
  editable = true,
  disableIcon,
  typographyProps,
  iconProps,
  titleTooltip,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { title, type } = tool || {};
  const { name: dataName } = (type && ToolTypeDataMap[type]) || {};

  const dataText = dataName ? t(dataName) : null;
  const customText = initialTitle ? t(initialTitle) : null;
  const initialText = title || customText || dataText || '';

  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [closeOnUpdate, setCloseOnUpdate] = useState(false);

  const isUpdating = useSelector(ToolUtilizationSelector.isUpdatingByUtilizationId(tool?.id));
  const isUpdated = useSelector(ToolUtilizationSelector.isUpdatedByUtilizationId(tool?.id));

  const { handleSubmit, control, reset } = useForm({
    shouldFocusError: false,
    defaultValues: {
      text: '',
    },
  });

  useEffect(() => {
    if (closeOnUpdate && isUpdated) {
      setCloseOnUpdate(false);
      closeEditor();
    }
  }, [closeOnUpdate, isUpdated]);

  const saveTitle = (text: string) => {
    switch (tool.type) {
      case ToolType.UIM:
        dispatch(UimUtilizationActions.update({ ...tool, title: text }));
        break;
      case ToolType.NINE_WINDOWS:
        dispatch(NineWindowsUtilizationActions.update({ ...tool, title: text }));
        break;
      case ToolType.CEC:
        dispatch(CecUtilizationActions.update({ ...tool, title: text }));
        break;
      case ToolType.EBS:
        dispatch(EbsUtilizationActions.update({ ...tool, title: text }));
        break;
      case ToolType.APA:
        dispatch(ApaUtilizationActions.update({ ...tool, title: text }));
        break;
      case ToolType.FIVE_WHYS:
        dispatch(FiveWhysUtilizationActions.update({ ...tool, title: text }));
        break;
      case ToolType.P_MAP:
        dispatch(PMapUtilizationActions.update({ ...tool, title: text }));
        break;
      case ToolType.RRM:
        dispatch(RrmUtilizationActions.update({ ...tool, title: text }));
        break;
      case ToolType.TOOL_40_PRINCIPLES:
        const fortyPrinciplesUpdateCommand: FortyPrinciplesUtilizationCommand = {
          ...resolve40pInitialUpdateCommandData(tool as FortyPrinciplesUtilization),
          title: text,
        };

        dispatch(FortyPrinciplesUtilizationActions.update(tool.id, fortyPrinciplesUpdateCommand, tool.project?.id));
        break;
      case ToolType.SFM:
        dispatch(SfmUtilizationActions.updateUtilization({ ...tool, title: text }));
        break;
      case ToolType.PFM:
        dispatch(PfmUtilizationActions.update(tool.id, { title: text }, tool.project?.id));
        break;
      case ToolType.CFT:
        dispatch(CftUtilizationActions.updateUtilization(tool.id, { title: text }, tool.project?.id));
        break;
      default:
        throw new Error(`Unsupported tool: ${tool.type}`);
    }
  };

  const editClickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isEditorOpen) {
      reset({ text: initialText });
      openEditor();
    } else {
      closeEditor();
    }
  };

  const openEditor = () => {
    setIsEditorOpen(true);
  };

  const closeEditor = () => {
    setIsEditorOpen(false);
  };

  const focusInput = (el: HTMLInputElement | HTMLTextAreaElement) => {
    if (el) {
      el.focus();
    }
  };

  const saveHandler = () => {
    handleSubmit(formData => {
      if (formData.text !== initialText) {
        saveTitle(formData.text);
        setCloseOnUpdate(true);
      } else {
        closeEditor();
      }
    })();
  };

  const inputKeyDownHandler = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      saveHandler();
    }
    if (e.key === 'Escape') {
      closeEditor();
    }
  };

  if (!tool) return null;

  return (
    <Grid container alignItems={'center'} flexWrap={'nowrap'}>
      {!disableIcon && (
        <Grid item xs={'auto'}>
          <Box pr={2}>
            <ToolIcon tool={tool} size={'medium'} />
          </Box>
        </Grid>
      )}

      <Grid item overflow={'hidden'} position={'relative'}>
        <Tooltip title={titleTooltip ? t(titleTooltip) : ''} placement={'top'} disableInteractive arrow>
          <Typography {...(presets[preset].typography || {})} {...(typographyProps || {})} noWrap={true}>
            {initialText}
          </Typography>
        </Tooltip>
      </Grid>

      {editable && (
        <Grid item>
          <PopperWrap open={isEditorOpen} openHandler={'manually'} onClose={closeEditor} stopPropagation shadow>
            <Box pl={0.5}>
              <Tooltip
                title={tool.publicId ? t('You must be logged in to change the title.') : ''}
                placement={'top'}
                disableInteractive
                arrow
              >
                <EditOutlined
                  className={[
                    styles.editButton,
                    tool.publicId ? styles.disabledEditButton : styles.enabledEditButton,
                  ].join(' ')}
                  {...(presets[preset].icon || {})}
                  {...(iconProps || {})}
                  onClick={tool.publicId ? undefined : editClickHandler}
                />
              </Tooltip>
            </Box>

            <Box width={400}>
              <ReactHookFormText
                name={'text'}
                control={control}
                rules={{
                  validate: {
                    ...validateOptions.hasText('Title is required'),
                  },
                }}
                wrapperProps={{ mb: 2 }}
                size={'small'}
                onMount={focusInput}
                onKeyDown={inputKeyDownHandler}
              />

              <Grid container spacing={1} justifyContent={'flex-end'}>
                <Grid item>
                  <Button color={'primary'} variant={'text'} size={'small'} onClick={closeEditor} disabled={isUpdating}>
                    <Trans>Cancel</Trans>
                  </Button>
                </Grid>

                <Grid item>
                  <LoadingButton
                    color={'primary'}
                    variant={'outlined'}
                    size={'small'}
                    onClick={saveHandler}
                    disabled={isUpdating}
                    loading={isUpdating}
                  >
                    <Trans>Save</Trans>
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </PopperWrap>
        </Grid>
      )}
    </Grid>
  );
};
