import axios, { AxiosResponse } from 'axios';
import { ApiKey } from '@priz/shared/src/models/api-keys';

const list = (): Promise<ApiKey[]> =>
  axios.get(`/v1/api/api-keys/list`).then((response: AxiosResponse<ApiKey[]>) => response.data);

const create = (): Promise<ApiKey> =>
  axios.post(`/v1/api/api-keys/create`).then((response: AxiosResponse<ApiKey>) => response.data);

const updateTitle = (apiKeyId: number, title: string): Promise<ApiKey> =>
  axios
    .patch(`/v1/api/api-keys/update-title/${apiKeyId}`, { title })
    .then((response: AxiosResponse<ApiKey>) => response.data);

const invalidate = (apiKeyId: number): Promise<ApiKey> =>
  axios.patch(`/v1/api/api-keys/invalidate/${apiKeyId}`).then((response: AxiosResponse<ApiKey>) => response.data);

export const ApiKeysApi = {
  list,
  create,
  updateTitle,
  invalidate,
};
