import { EntityCollectionStatus, EntityMap } from '../common/entity-collection-state';
import { Project } from './index';

export interface ProjectCollectionStatus extends EntityCollectionStatus {
  moving?: boolean;
}

export const DefaultProjectCollectionStatus: ProjectCollectionStatus = {
  creating: false,
  error: false,
  loaded: false,
  loading: false,
  saving: false,
  moving: false,
};

export type PublishCountdowns = EntityMap<Date>;

export interface ProjectCollection {
  entities: EntityMap<Project>;

  status: ProjectCollectionStatus;

  publishCountdowns: PublishCountdowns;
}

export const DefaultProjectCollection = {
  entities: {},

  status: DefaultProjectCollectionStatus,

  publishCountdowns: {},
};
