import axios, { AxiosResponse } from 'axios';
import { Topic } from '@priz/shared/src/models/project';

const list = (page?: number, size?: number): Promise<{ topics: Topic[]; count: number }> =>
  axios
    .post(`/v1/public-api/topics`, { page, size })
    .then((response: AxiosResponse<{ topics: Topic[]; count: number }>) => response.data);

export const PublicTopicsApi = {
  list,
};
