import { FluxStandardAction } from 'flux-standard-action';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { ToolType } from '@priz/shared/src/models/tools';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { createPgAction } from '../../../shared/store/action-creator';
import { ActionMeta, RouterActionMeta } from '../../../shared/store/types';
import { UimUtilizationApi } from '../../services/uim-utilization.api';
import { IUimTask, IUimUtilization, UimUtilization } from '@priz/shared/src/models/tools/uim';
import { NavigateFunction } from 'react-router-dom';
import { ToolsUtils } from '../../../tools/utils';

export enum UimUtilizationActionType {
  CreateForProjectTasks = 'uimUtilization/create-for-project-tasks',
  Update = 'uimUtilization/update',
  Delete = 'uimUtilization/delete',
  TasksLoad = 'uimTasks/fetch',
  TaskCreate = 'uimTask/create',
  TaskUpdate = 'uimTask/update',
  TaskDelete = 'uimTask/delete',
  TasksReplace = 'uimTasks/replace',
}

interface UimUtilizationMeta extends ActionMeta, RouterActionMeta {
  projectId?: number;
  utilizationId?: number;
  taskId?: number;
  sourceUtilizationId?: number;
  descriptionsToCreate?: string[];
  idsToDelete?: number[];
  utilization?: UimUtilization;
}

type UimUtilizationPayload = IUimUtilization | IUimTask | IUimTask[];

export type UimUtilizationAction = FluxStandardAction<string, UimUtilizationPayload, UimUtilizationMeta>;

const createForProjectTasks = createPgAction(
  UimUtilizationActionType.CreateForProjectTasks,
  UimUtilizationApi.createForProjectTasks,
  (projectId: number, navigate?: NavigateFunction) => ({ projectId, navigate }),
  (payload: UimUtilizationPayload, { projectId, navigate }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_USED, {
      project_id: projectId,
      tool_type: ToolType.UIM,
    });

    if (navigate) {
      navigate(ToolsUtils.resolvePathByUtilization(payload as IUimUtilization));
    }
  },
);

const doDelete = createPgAction(
  UimUtilizationActionType.Delete,
  UimUtilizationApi.delete,
  (utilizationId: number, projectId?: number, navigate?: NavigateFunction) => ({ projectId, utilizationId, navigate }),
  (_: UimUtilizationPayload, { projectId, navigate, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_DELETED, {
      project_id: projectId,
      tool_type: ToolType.UIM,
      utilization_id: utilizationId,
    });

    if (navigate) {
      navigate(ToolsUtils.resolveListPath(projectId));
    }
  },
);

const update = createPgAction(
  UimUtilizationActionType.Update,
  UimUtilizationApi.update,
  (utilization: UimUtilization) => ({ utilization }),
);

const loadTasks = createPgAction(
  UimUtilizationActionType.TasksLoad,
  UimUtilizationApi.getAllTasks,
  (utilizationId: number) => ({ utilizationId }),
);

const createTask = createPgAction(
  UimUtilizationActionType.TaskCreate,
  UimUtilizationApi.createTask,
  (utilizationId: number, description: string) => ({ utilizationId, description }),
  (_payload, { utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      tool_type: ToolType.UIM,
      utilization_id: utilizationId,
    });
  },
);

const updateTask = createPgAction(
  UimUtilizationActionType.TaskUpdate,
  UimUtilizationApi.updateTask,
  (utilizationId: number, taskId: number) => ({ utilizationId, taskId }),
  (_payload, { utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      tool_type: ToolType.UIM,
      utilization_id: utilizationId,
    });
  },
);

const replaceTasks = createPgAction(
  UimUtilizationActionType.TasksReplace,
  UimUtilizationApi.replaceTasks,
  (utilizationId: number, descriptionsToCreate: string[], idsToDelete: number[]) => ({
    utilizationId,
    descriptionsToCreate,
    idsToDelete,
  }),
  (_payload, { utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      tool_type: ToolType.UIM,
      utilization_id: utilizationId,
    });
  },
);

const deleteTask = createPgAction(
  UimUtilizationActionType.TaskDelete,
  UimUtilizationApi.deleteTask,
  (utilizationId: number, taskId: number) => ({ utilizationId, taskId }),
  (_payload, { utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      tool_type: ToolType.UIM,
      utilization_id: utilizationId,
    });
  },
);

export const UimUtilizationActions = {
  createForProjectTasks,
  update,
  createTask,
  delete: doDelete,
  deleteTask,
  loadTasks,
  updateTask,
  replaceTasks,
};
