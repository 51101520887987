import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Workspace } from '@priz/shared/src/models/workspace';
import { Project, ProjectStatus } from '@priz/shared/src/models/project';
import { Alert, Box, Button, Grid, Hidden } from '@mui/material';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans, useTranslation } from 'react-i18next';
import { AppNavbar } from '../../navigation/app-navbar/component';
import { ProjectSelector, ToolUtilizationSelector } from '../store/selectors';
import { ProjectActions } from '../store/actions/project.actions';
import { EmptyProjectsList } from '../empty-project-list/component';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { TitleContainer } from '../../react/modules/title-container/component';
import { ProjectAssigneeActions } from '../../assignment/store/actions';
import { WorkspaceMembersActions } from '../../workspace/store/actions';
import { UserContextSelectors } from '../../security/store/selectors';
import { UserContextActions } from '../../security/store/actions';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SearchInput } from '../../react/elements/search-input/component';
import { ProjectAssigneeSelectors } from '../../assignment/store/selectors/project-assignee.selectors';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { PrizLoadingOverlay } from '@priz/shared/src/components/priz-loading-overlay/component';
import { PageContainer } from '../../content-containers/page-container/component';
import { ToolUtilizationActions } from '../store/actions/tools.actions';
import { ButtonCreateTool } from '../../tools/button-create-tool/component';
import { ToolUtilization } from '@priz/shared/src/models/tools';
import { useStyles } from './styles';
import { AddRounded } from '@mui/icons-material';
import { WorkspaceMembersSelectors } from '../../workspace/store/selectors/workspace-members.selectors';
import { ReactHookFormSelect, ReactHookFormSelectChangeEvent } from '../../react/form-elements';
import { useForm } from 'react-hook-form';
import { LinearLoader } from '../../react/elements/linear-loader/component';
import { ProjectsAndToolsList } from '../projects-and-tools-list/component';
import { ProjectUtils } from '../utils';
import { ToolsUtils } from '../../tools/utils';

import { ReactComponent as ProjectsListImage } from '../../../assets/img/projects-list-pg.svg';

interface ProjectsAndToolsProps {
  workspace?: Workspace;
  project?: Project;
}

enum CategoryFilterType {
  All = 'All',
  Tools = 'Tools',
  Projects = 'Projects',
}

export const ProjectsAndTools: React.FC<ProjectsAndToolsProps> = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [searchInputValue, setSearchInputValue] = useState('');
  const [categoryFilterType, setCategoryFilterType] = useState<CategoryFilterType>(CategoryFilterType.All);

  const { control } = useForm({
    defaultValues: {
      filterCategory: CategoryFilterType.All,
    },
  });

  const teamTools = useSelector(ToolUtilizationSelector.getAllByTeamId());
  const myProjects = useSelector(ProjectSelector.getMyProjects);
  const projectsAssigneesMap = useSelector(ProjectAssigneeSelectors.getAllProjectsAssigneesMap);
  const workspaceMembersMap = useSelector(WorkspaceMembersSelectors.getWorkspaceMembersEntityMap);

  const isUserContextLoaded = useSelector(UserContextSelectors.isLoaded);
  const areTeamToolsLoaded = useSelector(ToolUtilizationSelector.isLoadedByTeam());
  const areTeamToolsLoading = useSelector(ToolUtilizationSelector.isLoadingByTeam());
  // const areTeamToolsFailed = useSelector(ToolUtilizationSelector.isFailedByTeam());
  const areProjectsLoaded = useSelector(ProjectSelector.projectsLoaded);
  const areProjectsLoading = useSelector(ProjectSelector.areProjectsLoading);
  const areProjectsFailed = useSelector(ProjectSelector.isFailed);
  const projectsError = useSelector(ProjectSelector.errorCode);
  const areProjectsAndToolsLoaded = areProjectsLoaded && areTeamToolsLoaded;

  const { parentTools, childrenToolsMap } = ToolsUtils.groupByLevel(teamTools);

  const haveProjectsOrTools = !!myProjects.length || !!parentTools.length;

  const filteredProjects = ProjectUtils.filterByText(myProjects, projectsAssigneesMap, searchInputValue);
  const filteredParentTools = ToolsUtils.filterByText(parentTools, workspaceMembersMap, searchInputValue);

  const toolsGroups = ToolsUtils.groupByCompleteness(filteredParentTools);
  const projectsGroups = ProjectUtils.groupByStatus(filteredProjects);

  const activeProjectsAndTools: (Project | ToolUtilization)[] = [];
  const todoProjectsAndTools: (Project | ToolUtilization)[] = [];
  const completedProjectsAndTools: (Project | ToolUtilization)[] = [];

  if ([CategoryFilterType.All, CategoryFilterType.Projects].includes(categoryFilterType)) {
    activeProjectsAndTools.push(...projectsGroups[ProjectStatus.InProgress]);
    todoProjectsAndTools.push(...projectsGroups[ProjectStatus.Todo]);
    completedProjectsAndTools.push(...projectsGroups[ProjectStatus.Completed]);
  }

  if ([CategoryFilterType.All, CategoryFilterType.Tools].includes(categoryFilterType)) {
    activeProjectsAndTools.push(...toolsGroups.incomplete);
    completedProjectsAndTools.push(...toolsGroups.complete);
  }

  useEffect(() => {
    if (areProjectsFailed && projectsError === 403) {
      throw new Error('You do not have access to this workspace');
    }
  }, [areProjectsFailed, projectsError]);

  useEffect(() => {
    dispatch(ToolUtilizationActions.loadTools());
    dispatch(ProjectActions.loadProjects());
    dispatch(ProjectAssigneeActions.fetchAll());
    dispatch(WorkspaceMembersActions.loadAll());
    dispatch(UserContextActions.load(pathname, navigate));
  }, []);

  const searchInputValueChangeHandler = (text: string) => {
    setSearchInputValue(text);
  };

  const clearSearch = () => {
    setSearchInputValue('');
  };

  const renderSearchInput = () => {
    return (
      <SearchInput
        initialValue={searchInputValue}
        onChangeDebounced={searchInputValueChangeHandler}
        onClear={clearSearch}
        placeholder={'Search by title or user'}
      />
    );
  };

  const categorySelectChangeHandler = (e: ReactHookFormSelectChangeEvent) => {
    setCategoryFilterType(e?.target?.value as CategoryFilterType);
  };

  if (!isUserContextLoaded) {
    return <PrizLoadingOverlay />;
  }

  return (
    <>
      <PageContainer scrollContainerProps={{ pb: 0 }}>
        <AppNavbar />

        <>
          {haveProjectsOrTools && (
            <TitleContainer fitButtons={true} mb={2}>
              <Grid container alignItems={'center'} spacing={2}>
                <Grid item xs={'auto'}>
                  <PageTitleWithDocLink title={<Trans>Projects & Tools</Trans>} wrapperProps={{ mb: 0 }} />
                </Grid>

                <Hidden lgDown>
                  <Grid item>
                    <Box maxWidth={300}>{renderSearchInput()}</Box>
                  </Grid>
                </Hidden>

                <Grid item>
                  <ReactHookFormSelect
                    variant={'standard'}
                    name={'filterCategory'}
                    control={control}
                    options={[
                      { value: CategoryFilterType.All, text: t('Show All') },
                      { value: CategoryFilterType.Tools, text: t('Show only Tools') },
                      { value: CategoryFilterType.Projects, text: t('Show only Projects') },
                    ]}
                    onChange={categorySelectChangeHandler}
                    wrapperProps={{
                      width: { xs: '100%', md: 200, lg: 250 },
                      mb: 0,
                    }}
                  />
                </Grid>
              </Grid>

              <ButtonsWrap>
                <ButtonCreateTool startIcon={<AddRounded />} text={'Add New Tool'} />

                <Button
                  component={Link}
                  to={'/project-wizard'}
                  variant={'pg_rounded'}
                  color={'pg_orange_solid'}
                  startIcon={<AddRounded />}
                >
                  <Trans>Add New Project</Trans>
                </Button>
              </ButtonsWrap>
            </TitleContainer>
          )}

          {haveProjectsOrTools && (
            <Hidden lgUp>
              <Box mb={2} maxWidth={500}>
                {renderSearchInput()}
              </Box>
            </Hidden>
          )}

          <Box>
            <LinearLoader loading={haveProjectsOrTools && (areTeamToolsLoading || areProjectsLoading)} />
            <LoadingOverlay
              loading={!haveProjectsOrTools && (areTeamToolsLoading || areProjectsLoading)}
              backdropStyles={{ backgroundColor: 'transparent' }}
            />

            {/*{areTeamToolsFailed && (*/}
            {/*  <Box mb={2}>*/}
            {/*    <Alert severity={'error'}>*/}
            {/*      <Trans>Couldn't load tools</Trans>*/}
            {/*    </Alert>*/}
            {/*  </Box>*/}
            {/*)}*/}

            {areProjectsFailed && (
              <Box mb={2}>
                <Alert severity={'error'}>
                  <Trans>Couldn't load projects</Trans>
                </Alert>
              </Box>
            )}

            {areProjectsAndToolsLoaded && !haveProjectsOrTools && (
              <Box pt={5} pb={10}>
                <EmptyProjectsList />
              </Box>
            )}

            {haveProjectsOrTools &&
              !activeProjectsAndTools.length &&
              !todoProjectsAndTools.length &&
              !completedProjectsAndTools.length && (
                <Box py={4}>
                  <Alert severity={'info'}>
                    <Trans>No matches found</Trans>
                  </Alert>
                </Box>
              )}

            <ProjectsAndToolsList
              list={activeProjectsAndTools}
              childrenToolsMap={childrenToolsMap}
              workspaceMembersMap={workspaceMembersMap}
            />

            <ProjectsAndToolsList
              title={'Todo'}
              list={todoProjectsAndTools}
              childrenToolsMap={childrenToolsMap}
              workspaceMembersMap={workspaceMembersMap}
              divider={!!activeProjectsAndTools.length}
            />

            <ProjectsAndToolsList
              title={'Completed'}
              list={completedProjectsAndTools}
              childrenToolsMap={childrenToolsMap}
              workspaceMembersMap={workspaceMembersMap}
              divider={!!activeProjectsAndTools.length || !!todoProjectsAndTools.length}
            />
          </Box>

          {haveProjectsOrTools && (
            <Box pt={5} mt={'auto'} className={styles.backgroundContainer}>
              <ProjectsListImage className={styles.backgroundImage} />
            </Box>
          )}
        </>
      </PageContainer>
    </>
  );
};
