import React from 'react';
import { TipPopupContent, TipPopupContentProps } from '../tip-popup-content/component';
import { BoxProps, PopperPlacementType, PopperProps } from '@mui/material';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';

export interface TipPopupWrapperProps extends TipPopupContentProps {
  open: boolean;
  popperPlacement?: PopperPlacementType;
  wrapperProps?: BoxProps;
  popperProps?: Partial<PopperProps>;
  portalContainer?: Element;
}

export const TipPopupWrapper: React.FC<TipPopupWrapperProps> = ({
  open,
  popperPlacement,
  title,
  text,
  okButtonText,
  onClose,
  children,
  extraButtons,
  maxWidth,
  showCrossButton,
  wrapperProps,
  popperProps,
  portalContainer,
}) => {
  return (
    <PopperWrap
      open={open}
      closeOnClickAway={false}
      stopPropagation={true}
      openHandler={'manually'}
      variant={'blue'}
      portalContainer={portalContainer}
      placement={popperPlacement}
      containerProps={{
        width: '100%',
        ...wrapperProps,
      }}
      {...popperProps}
    >
      {children}

      <TipPopupContent
        title={title}
        text={text}
        okButtonText={okButtonText}
        onClose={onClose}
        extraButtons={extraButtons}
        maxWidth={maxWidth}
        showCrossButton={showCrossButton}
      />
    </PopperWrap>
  );
};
