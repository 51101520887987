import { FluxStandardAction } from 'flux-standard-action';
import { createPgAction } from '../../../shared/store/action-creator';
import { ProjectFeatureResolverBlockerProps } from '../model';

export enum ProjectFeatureBlockerActionType {
  Set = 'project-feature-blocker/set',
  Clear = 'project-feature-blocker/clear',
}

interface ProjectFeatureBlockerActionMeta {
  preventMock?: boolean;
}

export type ProjectFeatureBlockerAction = FluxStandardAction<
  ProjectFeatureBlockerActionType,
  ProjectFeatureResolverBlockerProps,
  ProjectFeatureBlockerActionMeta
>;

const set = createPgAction(
  ProjectFeatureBlockerActionType.Set,
  (props: ProjectFeatureResolverBlockerProps) => new Promise(resolve => resolve(props)),
  (_props: ProjectFeatureResolverBlockerProps, preventMock?: boolean) => ({ preventMock }),
);

const clear = createPgAction(
  ProjectFeatureBlockerActionType.Clear,
  (): Promise<void> => new Promise(resolve => resolve()),
);

export const ProjectFeatureBlockerActions = {
  set,
  clear,
};
