import React, { useCallback, useEffect } from 'react';
import { Alert, Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Agenda, Attendee, Meeting } from '../store/model';
import { MeetingStatus } from '@priz/shared/src/models/meetings';
import { Trans, useTranslation } from 'react-i18next';
import { FieldTitle, HelperText, ReactHookFormText } from '@priz/shared/src/components/form-elements';
import { validateOptions, validatorRules } from '@priz/shared/src/utils/form';
import { useForm } from 'react-hook-form';
import { AgendaActions, AttendeeActions, MeetingActions } from '../store/actions';
import { ReactHookFormDateButton } from '../../react/form-elements/date-button/component';
import { AttendeesList } from '../attendees-list/component';
import { AgendasList } from '../agendas-list/component';
import debounce from 'lodash/debounce';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { MeetingStatusIndicator } from '../meetings-status-indicator/component';
import { MeetingEditorControls } from '../meeting-editor-controls/component';
import { ReactHookFormQuill } from '../../react/form-elements';
import { ReactHookFormDurationButton } from '../../react/form-elements/duration-button/component';
import { copyObject } from '@priz/shared/src/utils/common';
import { MeetingSelectors } from '../store/selectors';
import { DateAdapterType } from '../../date-time-picker-button/component';
import { TimeZoneService } from '@priz/shared/src/services/time';
import { useNavigate } from 'react-router-dom';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';

export interface MeetingEditorProps {
  meeting: Meeting;
  attendeesList: Attendee[];
  agendaList: Agenda[];
}

const resolveInitialProps = (
  meeting: Meeting,
  attendees: Attendee[],
  agenda: Agenda[],
): {
  title: string;
  date: Date | null;
  durationMs: number | null;
  notes: string;
  attendees: Attendee[];
  agenda: Agenda[];
} => {
  return {
    title: meeting.title || '',
    date: meeting.date ? meeting.date.toDate() : null,
    durationMs: meeting.durationMs || null,
    notes: meeting.notes || '',
    attendees: attendees || [],
    agenda: agenda || [],
  };
};

export const MeetingEditor: React.FC<MeetingEditorProps> = ({ meeting, attendeesList, agendaList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const isUpdating = useSelector(MeetingSelectors.isUpdating(meeting.project.id));
  const isFailed = useSelector(MeetingSelectors.isFailed(meeting.project.id));

  const {
    handleSubmit,
    watch,
    register,
    getValues,
    reset,
    setValue,
    control,
    clearErrors,
    trigger,
    formState,
    getFieldState,
  } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: resolveInitialProps(meeting, attendeesList, agendaList),
  });

  const { title, date, durationMs, notes, attendees, agenda } = watch();
  const isLead = currentUser?.id === meeting.createdBy.id;
  const meetingIsReleased = meeting.status === MeetingStatus.Released;
  const meetingIsActive = meeting.status === MeetingStatus.Active;
  const meetingIsFinished = meeting.status === MeetingStatus.Finished;
  const allowUpdate = isLead && !meetingIsFinished;
  const showLoading = meetingIsReleased && isUpdating;
  const hasUnsavedChanges = meetingIsReleased && formState.isDirty;

  register('attendees', { required: t('Attendees are required') });
  register('agenda', {
    required: t('Agenda is required'),
    validate: agenda =>
      !agenda.filter(item => !item?.text?.trim()?.length).length || t('All records must have a description'),
  });

  useEffect(() => {
    if (meetingIsReleased && formState.isDirty && !isUpdating) {
      reset(resolveInitialProps(meeting, attendeesList, agendaList));
    }
  }, [isUpdating]);

  useEffect(() => {
    const { isDirty } = getFieldState('title', formState);
    if (!meetingIsReleased && isDirty) saveMeetingTitle();
  }, [title]);

  useEffect(() => {
    const { isDirty } = getFieldState('date', formState);
    if (!meetingIsReleased && isDirty) saveMeetingDate();
  }, [date]);

  useEffect(() => {
    const { isDirty } = getFieldState('durationMs', formState);
    if (!meetingIsReleased && isDirty) saveMeetingDuration();
  }, [durationMs]);

  useEffect(() => {
    const { isDirty } = getFieldState('notes', formState);
    if (!meetingIsReleased && isDirty) saveMeetingNotes();
  }, [notes]);

  useEffect(() => {
    if (!meetingIsReleased) setValue('attendees', attendeesList);
  }, [attendeesList]);

  useEffect(() => {
    if (!meetingIsReleased) setValue('agenda', agendaList);
  }, [agendaList]);

  const saveMeetingTitle = useCallback(
    debounce(() => {
      trigger('title').then(isValid => {
        if (isValid) dispatch(MeetingActions.update(meeting.project.id, meeting.id, { title: getValues().title }));
      });
    }, 500),
    [],
  );

  const saveMeetingDate = useCallback(
    debounce(() => {
      trigger('date').then(isValid => {
        if (isValid) dispatch(MeetingActions.update(meeting.project.id, meeting.id, { date: getValues().date }));
      });
    }, 500),
    [],
  );

  const saveMeetingDuration = useCallback(
    debounce(() => {
      trigger('durationMs').then(isValid => {
        if (isValid)
          dispatch(MeetingActions.update(meeting.project.id, meeting.id, { durationMs: getValues().durationMs }));
      });
    }, 500),
    [],
  );

  const saveMeetingNotes = useCallback(
    debounce(() => {
      trigger('notes').then(isValid => {
        if (isValid) dispatch(MeetingActions.update(meeting.project.id, meeting.id, { notes: getValues().notes }));
      });
    }, 500),
    [],
  );

  const attendeeAddHandler = (email: string, userId?: number) => {
    if (meetingIsReleased) {
      setValue('attendees', [...attendees, { id: Math.random(), user: { id: userId }, email } as Attendee], {
        shouldDirty: true,
      });
    } else {
      dispatch(AttendeeActions.create(meeting.project.id, { meetingId: meeting.id, email }));
    }
  };

  const attendeeUpdateHandler = (attendeeId: number, attended: boolean) => {
    dispatch(AttendeeActions.update(meeting.project.id, attendeeId, { attended }));
  };

  const attendeeDeleteHandler = (attendeeId: number) => {
    if (meetingIsReleased) {
      setValue(
        'attendees',
        attendees.filter(item => item.id !== attendeeId),
        { shouldDirty: true },
      );
    } else {
      dispatch(AttendeeActions.delete(meeting.project.id, attendeeId));
    }
  };

  const agendaAddHandler = () => {
    if (meetingIsReleased) {
      setValue('agenda', [...agenda, { id: Math.random(), text: '' } as Agenda], { shouldDirty: true });
    } else {
      dispatch(AgendaActions.create(meeting.project.id, { meetingId: meeting.id }));
    }
  };

  const agendaDoneHandler = (agendaId: number, done: boolean) => {
    dispatch(AgendaActions.update(meeting.project.id, agendaId, { done }));
  };

  const agendaUpdateHandler = (agendaId: number, text: string) => {
    setValue(
      'agenda',
      copyObject(agenda).map(item => {
        if (item.id === agendaId) item.text = text;
        return item;
      }),
      { shouldDirty: true },
    );
  };

  const agendaDebouncedUpdateHandler = (agendaId: number, text: string) => {
    if (!meetingIsReleased) {
      dispatch(AgendaActions.update(meeting.project.id, agendaId, { text }));
    }
  };

  const agendaDeleteHandler = (agendaId: number) => {
    if (meetingIsReleased) {
      setValue(
        'agenda',
        agenda.filter(item => item.id !== agendaId),
        { shouldDirty: true },
      );
    } else {
      dispatch(AgendaActions.delete(meeting.project.id, agendaId));
    }
  };

  const fullUpdateHandler = () => {
    handleSubmit(formData => {
      dispatch(
        MeetingActions.fullUpdate(meeting.project.id, meeting.id, {
          title: formData.title,
          date: formData.date,
          durationMs: formData.durationMs,
          attendeesEmails: formData.attendees.map(attendee => attendee.email),
          agenda: formData.agenda.map(agenda => ({ id: agenda.id, text: agenda.text })),
        }),
      );
    })();
  };

  const releaseHandler = () => {
    handleSubmit(() => {
      dispatch(MeetingActions.release(meeting.project.id, meeting.id));
    })();
  };

  const startHandler = () => {
    dispatch(MeetingActions.start(meeting.project.id, meeting.id));
  };

  const finishHandler = () => {
    dispatch(MeetingActions.finish(meeting.project.id, meeting.id));
  };

  const deleteHandler = () => {
    dispatch(MeetingActions.delete(meeting.project.id, meeting.id, navigate));
  };

  return (
    <>
      <ContentContainer>
        <PageTitleWithDocLink
          title={
            <div>
              <Trans>Meeting</Trans> <MeetingStatusIndicator meeting={meeting} />
            </div>
          }
        />

        <Box position={'relative'}>
          <LoadingOverlay loading={showLoading} backdropStyles={{ backgroundColor: 'rgba(248, 248, 248, 0.6)' }} />

          {hasUnsavedChanges && (
            <Box mb={4}>
              <Alert severity={'warning'}>
                <Trans>
                  You have unsaved changes. If you leave this page you will lose this changes. For saving changes click
                  on "Update" button.
                </Trans>
              </Alert>
            </Box>
          )}

          {isFailed && (
            <Box mb={4}>
              <Alert severity={'error'}>
                <Trans>Something went wrong.</Trans>
              </Alert>
            </Box>
          )}

          <ReactHookFormText
            name={'title'}
            fieldTitle={'Title'}
            control={control}
            rules={{
              validate: {
                ...validateOptions.hasText('Field is required'),
              },
            }}
            wrapperProps={{ mb: 2 }}
            disabled={showLoading || !allowUpdate || meetingIsActive}
          />

          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item>
                <ReactHookFormDateButton
                  name={'date'}
                  fieldTitle={'Date and time'}
                  control={control}
                  clearErrors={clearErrors}
                  trigger={trigger}
                  rules={{
                    ...validatorRules.required(),
                  }}
                  wrapperProps={{ mb: 0 }}
                  disabled={showLoading || !allowUpdate || meetingIsActive}
                  validatePastDate={false}
                  selectTime={true}
                  adapter={DateAdapterType.Moment}
                  helperText={TimeZoneService.getCurrentTimeZone().label}
                />
              </Grid>

              <Grid item>
                <ReactHookFormDurationButton
                  name={'durationMs'}
                  fieldTitle={'Duration'}
                  control={control}
                  rules={{
                    ...validatorRules.required(),
                    validate: {
                      minDuration: v => v >= 60 * 5 * 100 || 'Duration is required',
                    },
                  }}
                  disabled={showLoading || !allowUpdate || meetingIsActive}
                />
              </Grid>
            </Grid>
          </Box>

          <Box mb={2}>
            <FieldTitle text={'Attendees'} />

            <AttendeesList
              attendees={attendees}
              meetingStatus={meeting.status}
              onAttendeeAdd={attendeeAddHandler}
              onAttendeeUpdate={attendeeUpdateHandler}
              onAttendeeDelete={attendeeDeleteHandler}
              editable={allowUpdate}
              viewMode={meeting.status === MeetingStatus.Finished}
              disabled={showLoading}
            />

            <HelperText error={getFieldState('attendees', formState)?.error?.message} />
          </Box>

          <Box mb={2}>
            <FieldTitle text={'Agenda'} />

            <AgendasList
              agendaItems={agenda}
              meetingStatus={meeting.status}
              onAgendaAdd={agendaAddHandler}
              onAgendaDone={agendaDoneHandler}
              onAgendaUpdate={agendaUpdateHandler}
              onAgendaUpdateDebounced={agendaDebouncedUpdateHandler}
              onAgendaDelete={agendaDeleteHandler}
              editable={allowUpdate}
              viewMode={meeting.status === MeetingStatus.Finished}
              disabled={showLoading}
            />

            <HelperText error={getFieldState('agenda', formState)?.error?.message} />
          </Box>

          {[MeetingStatus.Active, MeetingStatus.Finished].includes(meeting.status) && (
            <Box mb={2}>
              <FieldTitle text={'Meeting minutes'} />

              <ReactHookFormQuill name={'notes'} control={control} disabled={showLoading || !isLead} />
            </Box>
          )}

          {meetingIsReleased && (
            <Box mb={2}>
              <Alert severity={'info'}>
                <Trans>Meeting is published. After start you will able to</Trans>:
                <ul>
                  <li>
                    <Trans>Mark attendance</Trans>
                  </li>
                  <li>
                    <Trans>Mark completed agenda items</Trans>
                  </li>
                  <li>
                    <Trans>Write meeting minutes</Trans>
                  </li>
                </ul>
              </Alert>
            </Box>
          )}
        </Box>
      </ContentContainer>

      <ContentFooter>
        <MeetingEditorControls
          meeting={meeting}
          onRelease={releaseHandler}
          onStart={startHandler}
          onFinish={finishHandler}
          onUpdate={fullUpdateHandler}
          onDelete={deleteHandler}
          disabled={showLoading}
          disableStart={hasUnsavedChanges}
        />
      </ContentFooter>
    </>
  );
};
