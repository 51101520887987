import { IToolUtilization, ToolUtilization } from '../index';

export interface INineWindowsUtilization extends IToolUtilization {
  pastSupersystem: string;
  presentSupersystem: string;
  futureSupersystem: string;
  pastSystem: string;
  presentSystem: string;
  futureSystem: string;
  pastSubsystem: string;
  presentSubsystem: string;
  futureSubsystem: string;
}

export class NineWindowsUtilization extends ToolUtilization implements INineWindowsUtilization {
  public pastSupersystem: string;
  public presentSupersystem: string;
  public futureSupersystem: string;
  public pastSystem: string;
  public presentSystem: string;
  public futureSystem: string;
  public pastSubsystem: string;
  public presentSubsystem: string;
  public futureSubsystem: string;

  constructor(json: INineWindowsUtilization) {
    super(json);

    this.pastSupersystem = json.pastSupersystem;
    this.presentSupersystem = json.presentSupersystem;
    this.futureSupersystem = json.futureSupersystem;
    this.pastSystem = json.pastSystem;
    this.presentSystem = json.presentSystem;
    this.futureSystem = json.futureSystem;
    this.pastSubsystem = json.pastSubsystem;
    this.presentSubsystem = json.presentSubsystem;
    this.futureSubsystem = json.futureSubsystem;
  }
}
