import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    '& $tableCell, & .MuiTableCell-root': {
      border: 'none',
    },
  },
  bold: {
    fontWeight: 700,
  },
  tableCell: {
    verticalAlign: 'top',
  },
  textContainer: {
    borderLeft: '2px solid rgba(74, 85, 104, 0.2)',
  },
  highlight: {
    fontWeight: 600,
  },
});
