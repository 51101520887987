import { Certificate } from '../store';
import axios, { AxiosResponse } from 'axios';

const getByProjectId = (projectId: number): Promise<any> =>
  axios.get(`/v1/api/project/${projectId}/certificate`).then((response: AxiosResponse) => response.data);

const generate = (projectId: number): Promise<any> =>
  axios.post(`/v1/api/project/${projectId}/certificate/generate`).then((response: AxiosResponse) => response.data);

const createFullDocument = (projectId: number): Promise<any> =>
  axios.post(`/v1/api/project/${projectId}/certificate/full-doc`).then((response: AxiosResponse) => response.data);

const update = (certificate: Certificate): Promise<any> =>
  axios
    .put(`/v1/api/project/${certificate.project.id}/certificate`, {
      abstractContent: certificate.abstractContent,
      fullDocument: certificate.fullDocument,
      title: certificate.title,
    })
    .then((response: AxiosResponse) => response.data);

const fetchCertificateImage = (projectId: number): Promise<any> =>
  axios
    .get(`/v1/api/project/${projectId}/certificate-file`, { responseType: 'blob' })
    .then((response: AxiosResponse) => response.data);

export const CertificateService = {
  getByProjectId,
  generate,
  createFullDocument,
  update,
  fetchCertificateImage,
};
