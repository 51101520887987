import React, { useState } from 'react';
import { Alert, Box, Button, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { useStyles } from './styles';
import { ProjectWizardForm } from '../project-wizard-form/component';
import { ProjectWizardInputValuesMap } from '../model/inputs';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { AuthService } from '@priz/shared/src/services/auth';
import { useForm } from 'react-hook-form';
import { ReactHookFormText } from '@priz/shared/src/components/form-elements';
import { validateOptions, validatorRules } from '@priz/shared/src/utils/form';
import { AuthForm } from '../../../security/auth-form/component';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { ReactHookFormQuill } from '../../../react/form-elements';
import { useNavigate, Link } from 'react-router-dom';
import { PublicPageContainer } from '../../../content-containers/public-page-container/component';
import { inputsData } from '../inputs-data';

export const ProjectWizardPage: React.FC = () => {
  const navigate = useNavigate();
  const styles = useStyles();
  const [formData, setFormData] = useState<ProjectWizardInputValuesMap | null>(null);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showTitleInput, setShowTitleInput] = useState(false);

  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      title: '',
      challenges: '',
    },
  });

  const formChangeHandler = (data: ProjectWizardInputValuesMap, finished: boolean) => {
    setFormData(finished ? data : null);
  };

  const submitChallenges = () => {
    handleSubmit(() => {
      setShowTitleInput(true);
    })();
  };

  const createProjectHandler = () => {
    handleSubmit(({ title, challenges }) => {
      LocalStorageService.setItem(
        LocalStorageKey.CreateProjectProps,
        JSON.stringify({
          title: title,
          description: challenges,
          ...formData,
        }),
      );

      if (AuthService.isAuthenticated()) {
        navigate('/project-wizard-callback');
      } else {
        setShowLoginForm(true);
      }
    })();
  };

  return (
    <PublicPageContainer
      title={<Trans>Let's get started</Trans>}
      oneLiner={<Trans>This wizard will help you to narrow down the theme for your next innovation project.</Trans>}
    >
      <Alert severity={'info'} variant={'outlined'}>
        <Typography variant={'subtitle2'}>
          <Trans>
            Even through the details in this wizard are optional, it is very important to have them. This information
            will help you dedicated AI assistant to be more focused on what you are working on.
          </Trans>
        </Typography>
      </Alert>

      <Box width={'100%'} pt={4} pb={8}>
        <div className={[styles.formWrapper, formData ? styles.borderLeftGreen : styles.borderLeftBlue].join(' ')}>
          <ProjectWizardForm inputs={inputsData} onFormChange={formChangeHandler} disabled={showLoginForm} />
        </div>

        <Box mt={4}>
          {formData && (
            <>
              <Box mb={2}>
                <Alert severity={'success'}>
                  <Trans>
                    Done! Now that you have roughly narrowed on a goal that you are targeting, it is time to describe
                    the challenges that you have in achieving the goal. Describe the challenges and choose the one that
                    you will solve in this project. After giving this project a title, you'll be able to create the
                    project and dive right into it.
                  </Trans>
                </Alert>
              </Box>

              <ReactHookFormQuill
                name={'challenges'}
                fieldTitle={'Challenges'}
                rules={{
                  ...validatorRules.required(),
                  validate: {
                    ...validateOptions.hasText('Field is required'),
                  },
                }}
                control={control}
                disabled={showLoginForm}
              />

              {showTitleInput && (
                <ReactHookFormText
                  fieldTitle={'Project title'}
                  name={'title'}
                  control={control}
                  rules={{
                    ...validatorRules.required(),
                  }}
                  disabled={showLoginForm}
                />
              )}
            </>
          )}

          {!showLoginForm && (
            <ButtonsWrap justifyContent={'flex-end'}>
              <Button component={Link} to={'/new-project'} variant={'pg_rounded'} color={'pg_orange_outlined'}>
                <Trans>{AuthService.isAuthenticated() ? 'Skip project wizard' : 'Skip project wizard and login'}</Trans>
              </Button>

              {!showTitleInput && formData && (
                <Button variant={'pg_rounded'} color={'pg_orange_solid'} onClick={submitChallenges}>
                  <Trans>Next</Trans>
                </Button>
              )}

              {showTitleInput && formData && (
                <Button variant={'pg_rounded'} color={'pg_orange_solid'} onClick={createProjectHandler}>
                  <Trans>Create project</Trans>
                </Button>
              )}
            </ButtonsWrap>
          )}

          {showLoginForm && (
            <Box maxWidth={500} margin={'auto'}>
              <AuthForm />
            </Box>
          )}
        </Box>
      </Box>
    </PublicPageContainer>
  );
};
