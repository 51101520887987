import React from 'react';
import { Project, ProjectStatus } from '@priz/shared/src/models/project';
import { DashboardItemCard } from '../../dashboard-item-card/component';
import { Grid } from '@mui/material';
import { ProjectAssignees } from '../../../assignment/project-assignees/component';
import { useStyles } from './styles';
import { Status } from '@priz/shared/src/components/status/component';
import { useFlag } from '@unleash/proxy-client-react';
import { UnleashFeatureName } from '@priz/shared/src/services/unleash';

interface DashboardProjectProps {
  project: Project;
}

export const DashboardProject: React.FC<DashboardProjectProps> = ({ project }) => {
  const styles = useStyles();
  const isTemplatesEnabled = useFlag(UnleashFeatureName.Templates);

  const path =
    project.status === ProjectStatus.Todo
      ? ['/start-project', project.id]
      : project.template && isTemplatesEnabled
      ? ['/project', project.id, 'template']
      : ['/project', project.id, 'overview'];

  return (
    <DashboardItemCard
      text={project.title}
      path={path}
      controls={
        <Grid container spacing={1} alignItems={'center'}>
          <Grid item>
            <ProjectAssignees className={styles.assignees} projectId={project.id} spacing={5} />
          </Grid>

          <Grid item>
            <Status status={project.status} size={24} showLabel={false} showTooltip={true} />
          </Grid>
        </Grid>
      }
    />
  );
};
