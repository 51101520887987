import axios, { AxiosResponse } from 'axios';
import { Agenda, Attendee, IMeeting } from '../../meetings/store/model';
import { Project } from '@priz/shared/src/models/project';
import { Task } from '@priz/shared/src/models/task';
import { Idea } from '@priz/shared/src/models/idea';

export interface DashboardResult<I> {
  items: I;
  totalCount: number;
}

export interface MeetingsDashboardResult extends DashboardResult<IMeeting[]> {
  attendees: Attendee[];
  agenda: Agenda[];
}

const getProjects = (page: number): Promise<DashboardResult<Project[]>> =>
  axios
    .get(`/v1/api/<ws-id>/dashboard/projects/${page}`)
    .then((response: AxiosResponse<DashboardResult<Project[]>>) => response.data);

const getMeetings = (page: number): Promise<MeetingsDashboardResult> =>
  axios
    .get(`/v1/api/<ws-id>/dashboard/meetings/${page}`)
    .then((response: AxiosResponse<MeetingsDashboardResult>) => response.data);

const getTasks = (page: number): Promise<DashboardResult<Task[]>> =>
  axios
    .get(`/v1/api/<ws-id>/dashboard/tasks/${page}`)
    .then((response: AxiosResponse<DashboardResult<Task[]>>) => response.data);

const getIdeas = (page: number): Promise<DashboardResult<Idea[]>> =>
  axios
    .get(`/v1/api/<ws-id>/dashboard/ideas/${page}`)
    .then((response: AxiosResponse<DashboardResult<Idea[]>>) => response.data);

export const dashboardApi = {
  getProjects,
  getMeetings,
  getTasks,
  getIdeas,
};
