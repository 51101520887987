import React, { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { IdType, Network, Network as NetworkType } from '@priz/shared/src/lib/vis/esnext';
import { PaywallActions } from '../../react/modules/paywall/store';
import { useStyles } from './styles';
import { Alert, Snackbar } from '@mui/material';
import { v1 as uuidv1 } from 'uuid';
import {
  minifyCecEdgesProps,
  minifyCecNodesProps,
  resolveEdgeConnectionConflicts,
  sortHierarchicalNodes,
} from './utils';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { ToolType } from '@priz/shared/src/models/tools';
import {
  addEdge,
  addNode,
  bindNetworkEvents,
  HistoryListener,
  HistorySenderId,
  unbindNetworkEvents,
  useNetworkDataset,
} from '@priz/shared/src/components/network-diagram/utils';
import { CecEdgeProps, CecNodeDefaultPlaceholder, CecNodeProps } from '@priz/shared/src/models/tools/cec';
import { NetworkToolsMenu } from '../../react/network/network-tools-menu/component';
import {
  constructNodePropsDecorator,
  edgePropsDecorator,
  getCecTranslatedOptions,
  viewOptions,
} from '@priz/shared/src/data/cec-options';
import { DataSetEventType, EdgeProps } from '@priz/shared/src/models/vis-network';
import { Trans, useTranslation } from 'react-i18next';
import { FullscreenContainer } from '@priz/shared/src/components/fullscreen-container/component';
import { CecNodeEditor } from '../cec-node-editor/component';
import { CecNodeWidget } from '../cec-node-widget/component';
import { CecNodeViewer } from '../cec-node-viewer/component';
import { ElementsViewerProvider } from '@priz/shared/src/components/elements-viewer/elements-viewer-context/component';

interface CauseAndEffectChainDiagramProps {
  projectId?: number;
  utilizationId?: number;
  nodes?: CecNodeProps[];
  edges?: CecEdgeProps[];
  disabled?: boolean;
  finished?: boolean;
  defaultPlaceholder?: string;
  changeCallback?: (props: { nodes: CecNodeProps[]; edges: CecEdgeProps[] }) => void;
  fitOnInit?: boolean;
  historyKeyboardControl?: boolean;
}

export const CauseAndEffectChainDiagram: React.FC<CauseAndEffectChainDiagramProps> = ({
  projectId,
  utilizationId,
  nodes = [],
  edges = [],
  finished = false,
  disabled,
  defaultPlaceholder = CecNodeDefaultPlaceholder,
  changeCallback,
  fitOnInit,
  historyKeyboardControl,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const canvasRef = useRef(null);

  const [network, setNetwork] = useState<NetworkType | null>(null);
  const [networkIsFit, setNetworkIsFit] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '' });
  const [isNodeTextareaFocused, setIsNodeTextareaFocused] = useState(false);
  const [selectedNodeId, setSelectedNodeId] = useState<string>();
  const [focusedNodeId, setFocusedNodeId] = useState<string>();

  const nodePropsDecorator = constructNodePropsDecorator(t(defaultPlaceholder));
  const translatedEdgePropsDecorator = (edge: Partial<CecEdgeProps>) => edgePropsDecorator(edge, t('Causes'));

  const { nodesDataSet, edgesDataSet } = useNetworkDataset<CecNodeProps, CecEdgeProps>(
    { nodes, edges },
    nodePropsDecorator,
    translatedEdgePropsDecorator,
  );

  const featureSet = useSelector(WorkspaceSelectors.getApplicableFeatureSet(projectId));

  useEffect(() => {
    const translatedOptions = getCecTranslatedOptions({ edgeLabel: t('Why?') });

    if (!network) {
      setNetwork(new Network(canvasRef.current, { nodes: nodesDataSet, edges: edgesDataSet }, translatedOptions));
    } else {
      network.setOptions({
        manipulation: {
          enabled: false,
          addEdge: addEdgeNetworkCallback,
        },
      });
    }
  }, [network]);

  useEffect(() => {
    const nodesDataSetChangeCallback = (e: DataSetEventType) => {
      network.addEdgeMode();

      if (e !== 'update') {
        setSelectedNodeId(null);
        setFocusedNodeId(null);
      }
    };

    const edgesDataSetChangeCallback = () => {
      network.addEdgeMode();
    };

    if (network) {
      nodesDataSet.on('*', nodesDataSetChangeCallback);
      edgesDataSet.on('*', edgesDataSetChangeCallback);
    }

    return () => {
      if (network) {
        nodesDataSet.off('*', nodesDataSetChangeCallback);
        edgesDataSet.off('*', edgesDataSetChangeCallback);
      }
    };
  }, [network]);

  useEffect(() => {
    const editModeEvents = {
      controlNodeDragEnd: eventProps => {
        const { controlEdge } = eventProps;
        const { from, to } = controlEdge || {};

        if (from && !to) {
          addNodeAndEdge(from);
        }
      },
      doubleClick: ({ nodes }) => {
        setFocusedNodeId(nodes[0]);
      },
      selectNode: ({ nodes }) => {
        setFocusedNodeId(null);
        setSelectedNodeId(nodes[0]);
      },
      deselectNode: () => {
        setSelectedNodeId(null);
        setFocusedNodeId(null);
      },
    };
    const viewModeEvents = {
      selectNode: ({ nodes }) => {
        setSelectedNodeId(nodes[0]);
      },
      deselectNode: () => {
        setSelectedNodeId(null);
      },
    };

    if (network) {
      if (!finished && !disabled) {
        unbindNetworkEvents(network, viewModeEvents);
        bindNetworkEvents(network, editModeEvents);
        network.addEdgeMode();
      } else {
        unbindNetworkEvents(network, editModeEvents);
        bindNetworkEvents(network, viewModeEvents);
        setSelectedNodeId(null);
        setFocusedNodeId(null);
        setIsNodeTextareaFocused(false);
        network.setOptions(viewOptions);
        network.disableEditMode();
      }
    }

    return () => {
      if (network) {
        unbindNetworkEvents(network, editModeEvents);
        unbindNetworkEvents(network, viewModeEvents);
      }
    };
  }, [network, finished, disabled, featureSet]);

  useEffect(() => {
    const documentKeyDownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.keyCode === 27) {
        setSelectedNodeId(null);
        setFocusedNodeId(null);
        network.unselectAll();
      }

      if (e.key === 'Backspace' || e.keyCode === 8 || e.key === 'Delete' || e.keyCode === 46) {
        if (!isNodeTextareaFocused) {
          const selectedNode = network.getSelectedNodes()[0];
          const selectedEdge = network.getSelectedEdges()[0];

          if (!selectedNode && selectedEdge) {
            removeEdge(selectedEdge);
          }

          if (selectedNode) {
            removeSelectedNode();
          }
        }
      }
    };

    if (!finished && !disabled) {
      document.addEventListener('keydown', documentKeyDownHandler);
    } else {
      document.removeEventListener('keydown', documentKeyDownHandler);
    }

    return () => {
      document.removeEventListener('keydown', documentKeyDownHandler);
    };
  }, [network, finished, disabled, isNodeTextareaFocused]);

  useEffect(() => {
    if (network && fitOnInit && !networkIsFit) {
      network.fit();
      setNetworkIsFit(true);
    }
  }, [network, fitOnInit, networkIsFit]);

  const updateNodesOrder = () => {
    const nodes = nodesDataSet.get();
    const edges = edgesDataSet.get();
    const sortedNodes = sortHierarchicalNodes(nodes, edges);
    const currentNodesOrderKey = nodes.map(n => n.id).join('-');
    const sortedNodesOrderKey = sortedNodes.map(n => n.id).join('-');

    if (currentNodesOrderKey !== sortedNodesOrderKey) {
      nodesDataSet.clear();
      nodesDataSet.add(sortedNodes, HistorySenderId.Save);
    }
  };

  const addNodeAndEdge = (fromNodeId: IdType) => {
    if (!nodesDataSet.get(fromNodeId)) return null;

    if (featureSet?.Tools?.CEC?.maxItems && nodesDataSet.get().length >= featureSet?.Tools?.CEC?.maxItems) {
      dispatch(PaywallActions.show(ToolType.CEC));
    } else {
      const newId = uuidv1();

      addNode({
        nodeProps: { id: newId },
        edgeProps: { from: fromNodeId as string },
        nodesDataSet,
        edgesDataSet,
        nodePropsDecorator,
        edgePropsDecorator: translatedEdgePropsDecorator,
        senderId: HistorySenderId.Save,
      });

      updateNodesOrder();
      setSelectedNodeId(newId);
      setFocusedNodeId(newId);
      network.selectNodes([newId]);
      dataSetChangeHandler();
    }
  };

  const removeSelectedNode = () => {
    const selectedNodeId = network.getSelectedNodes()[0];
    const node = nodesDataSet.get(selectedNodeId);

    if (node && node.type !== 'first') {
      removeNode(selectedNodeId);
    }
  };

  const removeEdge = (edgeId: IdType) => {
    const edge = edgesDataSet.get(edgeId);

    if (edge) {
      edgesDataSet.remove(edgeId, HistorySenderId.Save);
      removeUnrelatedNetworkElements();
      dataSetChangeHandler();
    }
  };

  const removeNode = (nodeId: IdType) => {
    const node = nodesDataSet.get(nodeId);

    if (node && node.type !== 'first') {
      const connectedEdges = network.getConnectedEdges(nodeId);

      nodesDataSet.remove(nodeId, HistorySenderId.Save);
      edgesDataSet.remove(connectedEdges, HistorySenderId.Save);

      removeUnrelatedNetworkElements();
      dataSetChangeHandler();
    }
  };

  const removeUnrelatedNetworkElements = () => {
    nodesDataSet.getIds().forEach(id => {
      const nodeEdges = getSiblingNodes(id, 'to');

      if (!nodeEdges.length && nodesDataSet.get(id).type !== 'first') {
        edgesDataSet.remove(network.getConnectedEdges(id), HistorySenderId.Save);
        nodesDataSet.remove(id, HistorySenderId.Save);
      }
    });
  };

  const getSiblingNodes = (nodeId, prop: 'from' | 'to') => {
    return edgesDataSet.get({ filter: item => item[prop] === nodeId }).map(item => item.to);
  };

  const updateNodeLabel = (nodeId: IdType, text: string) => {
    const node = nodesDataSet.get(nodeId);

    if (node) {
      nodesDataSet.updateOnly({ id: nodeId, label: text }, HistorySenderId.Save);
      dataSetChangeHandler();
    }
  };

  const updateNodeNotes = (nodeId: IdType, text: string) => {
    const node = nodesDataSet.get(nodeId);

    if (node) {
      nodesDataSet.updateOnly(
        {
          id: nodeId,
          borderWidth: text.length ? 3 : 1,
          potentialSolution: text,
        },
        HistorySenderId.Save,
      );

      dataSetChangeHandler();
    }
  };

  const colorChangeHandler = (nodeId: IdType, color: string) => {
    const node = nodesDataSet.get(nodeId);

    if (node) {
      nodesDataSet.updateOnly({ id: nodeId, color: { border: color } }, HistorySenderId.Save);
      dataSetChangeHandler();
    }
  };

  const addEdgeNetworkCallback = (edge: EdgeProps, _requiredCallbackProp) => {
    const nodeFrom = nodesDataSet.get(edge.from);
    const nodeTo = nodesDataSet.get(edge.to);

    if (nodeFrom && nodeTo && edge.from !== edge.to) {
      const edges = edgesDataSet.get();
      const nodes = nodesDataSet.get();
      const { isNoConflicts, errorMessage } = resolveEdgeConnectionConflicts(edge, nodes, edges);

      if (isNoConflicts) {
        addEdge({
          edgesDataSet,
          edgeProps: edge,
          edgePropsDecorator: translatedEdgePropsDecorator,
          senderId: HistorySenderId.Save,
        });

        dataSetChangeHandler();
      } else if (errorMessage.length) {
        setNotification({
          open: true,
          message: errorMessage,
        });
      }
    }
  };

  const closeNotification = () => {
    setNotification(state => ({ ...state, open: false }));
  };

  const saveNetworkData = () => {
    const nodesData = minifyCecNodesProps(nodesDataSet.get());
    const edgesData = minifyCecEdgesProps(edgesDataSet.get());

    if (changeCallback) {
      changeCallback({
        nodes: nodesData,
        edges: edgesData,
      });
    }
  };

  const dataSetChangeHandler = useCallback(
    debounce(() => {
      saveNetworkData();
    }, 500),
    [nodesDataSet, edgesDataSet],
  );

  const nodeEditingHandler = (isEditing: boolean) => {
    setIsNodeTextareaFocused(isEditing);
  };

  return (
    <FullscreenContainer className={styles.root}>
      <div className={'vis-network-container'} ref={canvasRef} />

      {network && (
        <ElementsViewerProvider>
          <CecNodeViewer network={network} nodesDataSet={nodesDataSet} container={canvasRef.current} />

          <NetworkToolsMenu
            network={network}
            nodesDataSet={nodesDataSet}
            edgesDataSet={edgesDataSet}
            viewMode={finished}
            disabled={disabled}
            controls={['history', 'viewer-mode']}
            toolType={ToolType.CEC}
            undoCallback={dataSetChangeHandler}
            redoCallback={dataSetChangeHandler}
            historyListener={HistoryListener.Custom}
            historyKeyboardControl={historyKeyboardControl}
          />
        </ElementsViewerProvider>
      )}

      <CecNodeEditor
        nodeId={selectedNodeId}
        network={network}
        nodesDataSet={nodesDataSet}
        onLabelChange={updateNodeLabel}
        onNotesChange={updateNodeNotes}
        onColorChange={colorChangeHandler}
        onCause={addNodeAndEdge}
        onDelete={removeNode}
        onEditStateChange={nodeEditingHandler}
        disabled={disabled || finished}
      />

      {!disabled && !finished && (
        <CecNodeWidget
          nodeId={focusedNodeId}
          projectId={projectId}
          utilizationId={utilizationId}
          network={network}
          nodesDataSet={nodesDataSet}
          edgesDataSet={edgesDataSet}
          canvasContainer={canvasRef.current}
          onLabelChange={updateNodeLabel}
          onEditStateChange={nodeEditingHandler}
          placeholder={defaultPlaceholder}
        />
      )}

      <Snackbar
        open={notification.open}
        onClose={closeNotification}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Alert onClose={closeNotification} severity="warning" sx={{ width: '100%' }}>
          <Trans>{notification.message}</Trans>
        </Alert>
      </Snackbar>
    </FullscreenContainer>
  );
};
