import { PfmDiagramData, PfmStep, PfmStepType } from '@priz/shared/src/models/tools/pfm';
import { copyObject } from '@priz/shared/src/utils/common';

export type ChangeStepIndexDirection = 1 | -1;

export const changeStepIndex = (
  step: PfmStep,
  steps: PfmStep[],
  direction: ChangeStepIndexDirection,
): PfmDiagramData => {
  const newIndex = step.index + direction;

  const newSteps = steps.map(item => {
    if (item.sfmId === step.sfmId) item.index = newIndex;
    if (item.sfmId !== step.sfmId && item.index === newIndex) item.index += direction * -1;
    return item;
  });

  return {
    steps: newSteps,
  };
};

export const changeStepType = (sfmId: number, steps: PfmStep[], type: PfmStepType): PfmDiagramData => {
  return {
    steps: steps.map(step => {
      if (step.sfmId === sfmId) step.type = type;
      return step;
    }),
  };
};

export const deleteStepBySfmId = (sfmId: number, steps: PfmStep[]): PfmDiagramData => {
  const currentIndex = steps.find(step => step.sfmId === sfmId)?.index;
  const newSteps = copyObject(steps)
    .filter(step => step.sfmId !== sfmId)
    .map(step => {
      if (step.index > currentIndex) step.index -= 1;
      return step;
    });

  return {
    steps: newSteps,
  };
};
