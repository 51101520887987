import React, { useRef } from 'react';
import { MenuItem, Select, SelectChangeEvent, SelectProps, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FieldTitle, HelperText } from '@priz/shared/src/components/form-elements';
import { ValidatorControllerProps, ReactHookFormElement, DefaultInput } from '@priz/shared/src/models/form';
import { Box } from '@mui/material';
import { useStyles } from './styles';
import { Trans } from 'react-i18next';

interface Option {
  value: string | number;
  text: string;
}

export type ReactHookFormSelectChangeEvent = SelectChangeEvent<{ name?: string; value: unknown }>;

export type ReactHookFormSelectProps = ReactHookFormElement &
  DefaultInput &
  ValidatorControllerProps & {
    options?: Option[];
    onChange?: (e: ReactHookFormSelectChangeEvent) => void;
    displayEmpty?: boolean;
    variant?: 'filled' | 'outlined' | 'standard';
    selectProps?: SelectProps;
  };

export const ReactHookFormSelect: React.FC<ReactHookFormSelectProps> = ({
  name,
  control,
  fieldTitle,
  fieldTitleWrapperProps,
  options,
  children,
  placeholder,
  helperText,
  wrapperProps,
  rules,
  disabled,
  onChange,
  displayEmpty,
  variant = 'outlined',
  selectProps,
}) => {
  const styles = useStyles();
  const selectRef = useRef(null);

  const renderOptions = () => {
    return (
      options?.map(({ value, text }, key) => (
        <MenuItem key={key} value={value} className={styles.menuItem}>
          <Typography noWrap>{text?.length ? <Trans>{text}</Trans> : <span>&nbsp;</span>}</Typography>
        </MenuItem>
      )) || []
    );
  };

  return (
    <Box mb={3} {...wrapperProps}>
      <FieldTitle text={fieldTitle} {...fieldTitleWrapperProps} />

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <Select
                ref={selectRef}
                fullWidth
                displayEmpty={displayEmpty}
                variant={variant}
                onChange={e => {
                  field.onChange(e);
                  if (onChange) onChange(e);
                }}
                value={field.value === null ? '' : field.value}
                error={!!error?.message}
                placeholder={placeholder}
                disabled={disabled}
                size={'medium'}
                {...selectProps}
              >
                {options && renderOptions()}
                {children}
              </Select>

              <HelperText error={error?.message} text={helperText} />
            </>
          );
        }}
      />
    </Box>
  );
};
