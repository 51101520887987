import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    width: 'auto',
    height: 'auto',
    position: 'absolute',
    maxHeight: 'calc(100% - 20px)',
    top: 0,
    left: 0,
    right: 'auto',
    bottom: 'auto',
    zIndex: 2,
    overflow: 'auto',
  },
  disabled: {
    opacity: 0.3,
    pointerEvents: 'none',
  },
});
