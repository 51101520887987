import React, { useState } from 'react';
import { Agenda, Attendee, Meeting } from '../../../meetings/store/model';
import { DashboardItemCard } from '../../dashboard-item-card/component';
import { Avatar, Box, Grid, Tooltip, Typography } from '@mui/material';
import { VisibilityOutlined, ManageAccountsOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { MeetingViewer } from '../../../meetings/meeting-viewer/component';
import { useSelector } from 'react-redux';
import { WorkspaceMembersSelectors } from '../../../workspace/store/selectors/workspace-members.selectors';
import { UserUtils } from '@priz/shared/src/utils/user/user-utils';
import { useStyles } from '../../styles';
import { Status } from '@priz/shared/src/components/status/component';
import { AttendingButton } from '../../../meetings/attending-button/component';

interface DashboardMeetingProps {
  meeting: Meeting;
  attendees: Attendee[];
  agenda: Agenda[];
}

export const DashboardMeeting: React.FC<DashboardMeetingProps> = ({ meeting, attendees = [], agenda = [] }) => {
  const styles = useStyles();

  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const lead = useSelector(WorkspaceMembersSelectors.getById(meeting.createdBy.id));

  const openViewer = () => {
    setIsViewerOpen(true);
  };

  const closeViewer = () => {
    setIsViewerOpen(false);
  };

  return (
    <DashboardItemCard
      text={meeting.title}
      path={['/project', meeting.project.id, 'meetings']}
      hash={`#meeting-${meeting.id}`}
      controls={
        <Grid container alignItems={'center'} spacing={1}>
          {lead && (
            <Grid item>
              <Box display={'flex'} alignItems={'center'}>
                <Tooltip
                  title={UserUtils.getShowableName(lead)}
                  arrow={true}
                  placement={'top'}
                  disableInteractive={true}
                >
                  <Avatar src={lead.profile.avatarUrl} sx={{ width: 24, height: 24 }}>
                    {UserUtils.resolveInitials(lead)}
                  </Avatar>
                </Tooltip>

                {!!attendees?.length && (
                  <Box ml={1}>
                    <Typography variant={'subtitle2'}>{`+${attendees.length}`}</Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          )}

          <Grid item>
            <Status status={meeting.status} size={24} showLabel={false} showTooltip={true} />
          </Grid>

          <Grid item>
            <AttendingButton meeting={meeting} attendees={attendees}>
              <ManageAccountsOutlined className={[styles.controlIcon, styles.interactiveControlIcon].join(' ')} />
            </AttendingButton>
          </Grid>

          <Grid item>
            <Tooltip title={<Trans>Overview</Trans>} arrow={true} placement={'top'} disableInteractive={true}>
              <VisibilityOutlined
                className={[styles.controlIcon, styles.interactiveControlIcon].join(' ')}
                onClick={openViewer}
              />
            </Tooltip>

            <MeetingViewer
              open={isViewerOpen}
              meeting={meeting}
              forcedAttendees={attendees}
              forcedAgenda={agenda}
              onClose={closeViewer}
            />
          </Grid>
        </Grid>
      }
    />
  );
};
