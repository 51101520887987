import * as React from "react";

export const ReactComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 60 60" fill='#08445E' {...props}>
    <path d="M44.8,18.2H57c1.7,0,3-1.3,3-3V3c0-1.7-1.3-3-3-3H44.8c-1.7,0-3,1.3-3,3v12.2c0,0.5,0.1,1,0.4,1.4l-4.8,4.8
	C37,21.2,36.5,21,36,21h-5v-2.8h5c1.7,0,3-1.3,3-3V3c0-1.7-1.3-3-3-3H23.8c-1.7,0-3,1.3-3,3v12.2c0,1.7,1.3,3,3,3H29V21h-5.2
	c-0.4,0-0.7,0.1-1,0.2l-5-5c0.1-0.3,0.2-0.7,0.2-1V3c0-1.7-1.3-3-3-3H2.8c-1.7,0-3,1.3-3,3v12.2c0,1.7,1.3,3,3,3H15
	c0.6,0,1.1-0.2,1.5-0.4l4.7,4.7c-0.3,0.4-0.4,1-0.4,1.5v5.2H18V24c0-1.7-1.3-3-3-3H2.8c-1.7,0-3,1.3-3,3v12.2c0,1.7,1.3,3,3,3H15
	c1.7,0,3-1.3,3-3v-4.9h2.8v4.9c0,0.5,0.1,1,0.4,1.4l-4.7,4.7c-0.5-0.3-1-0.4-1.6-0.4H2.8c-1.7,0-3,1.3-3,3V57c0,1.7,1.3,3,3,3H15
	c1.7,0,3-1.3,3-3V44.8c0-0.3-0.1-0.7-0.2-1l4.9-4.9c0.3,0.1,0.7,0.2,1.1,0.2H29v2.7h-5.2c-1.7,0-3,1.3-3,3V57c0,1.7,1.3,3,3,3H36
	c1.7,0,3-1.3,3-3V44.8c0-1.7-1.3-3-3-3h-5v-2.7h5c0.6,0,1.1-0.2,1.5-0.4l4.7,4.7c-0.2,0.4-0.4,0.9-0.4,1.4V57c0,1.7,1.3,3,3,3H57
	c1.7,0,3-1.3,3-3V44.8c0-1.7-1.3-3-3-3H44.8c-0.4,0-0.8,0.1-1.2,0.2l-4.9-4.9c0.1-0.3,0.2-0.7,0.2-1v-4.9h2.8v4.9c0,1.7,1.3,3,3,3
	H57c1.7,0,3-1.3,3-3V24c0-1.7-1.3-3-3-3H44.8c-1.7,0-3,1.3-3,3v5.2H39V24c0-0.4-0.1-0.8-0.2-1.1l4.9-4.9
	C44.1,18.1,44.4,18.2,44.8,18.2z M22.8,15.2V3c0-0.6,0.4-1,1-1H36c0.6,0,1,0.4,1,1v12.2c0,0.6-0.4,1-1,1H23.8
	C23.3,16.2,22.8,15.7,22.8,15.2z M16,36.2c0,0.6-0.4,1-1,1H2.8c-0.6,0-1-0.4-1-1V24c0-0.6,0.4-1,1-1H15c0.6,0,1,0.4,1,1V36.2z
	 M37,44.8V57c0,0.6-0.4,1-1,1H23.8c-0.6,0-1-0.4-1-1V44.8c0-0.6,0.4-1,1-1H36C36.6,43.8,37,44.3,37,44.8z M43.8,24c0-0.6,0.4-1,1-1
	H57c0.6,0,1,0.4,1,1v12.2c0,0.6-0.4,1-1,1H44.8c-0.6,0-1-0.4-1-1V24z M43.8,3c0-0.6,0.4-1,1-1H57c0.6,0,1,0.4,1,1v12.2
	c0,0.6-0.4,1-1,1H44.8c-0.6,0-1-0.4-1-1V3z M15,16.2H2.8c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1H15c0.6,0,1,0.4,1,1v12.2
	C16,15.7,15.6,16.2,15,16.2z M16,57c0,0.6-0.4,1-1,1H2.8c-0.6,0-1-0.4-1-1V44.8c0-0.6,0.4-1,1-1H15c0.6,0,1,0.4,1,1V57z M23.8,37.2
	c-0.6,0-1-0.4-1-1V24c0-0.6,0.4-1,1-1H36c0.6,0,1,0.4,1,1v12.2c0,0.6-0.4,1-1,1H23.8z M57,43.8c0.6,0,1,0.4,1,1V57c0,0.6-0.4,1-1,1
	H44.8c-0.6,0-1-0.4-1-1V44.8c0-0.6,0.4-1,1-1H57z" />
  </svg>

);
