import React, { useState } from 'react';
import { PublicUser } from '@priz/shared/src/models/user';
import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { Trans } from 'react-i18next';
import { ReferralTableRow } from '../referral-table-row/components';
import { useStyles } from './styles';

interface ReferralsTableProps {
  referrals: PublicUser[];
}

enum OrderBy {
  Email = 'Email',
  Date = 'Date',
}

enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc',
}

const orderDirectionReverseMap = {
  [OrderDirection.ASC]: OrderDirection.DESC,
  [OrderDirection.DESC]: OrderDirection.ASC,
};

const sortReferrals = (referrals: PublicUser[], orderBy: OrderBy, direction: OrderDirection): PublicUser[] => {
  const directionOffset = {
    [OrderDirection.ASC]: 1,
    [OrderDirection.DESC]: -1,
  };

  return referrals.sort((left, right) => {
    const leftValues = {
      [OrderBy.Email]: left.email,
      [OrderBy.Date]: left.profile.dateCreated,
    };
    const rightValues = {
      [OrderBy.Email]: right.email,
      [OrderBy.Date]: right.profile.dateCreated,
    };

    if (leftValues[orderBy] < rightValues[orderBy]) {
      return directionOffset[direction] * -1;
    }
    if (leftValues[orderBy] > rightValues[orderBy]) {
      return directionOffset[direction];
    }

    return 0;
  });
};

export const ReferralsTable: React.FC<ReferralsTableProps> = ({ referrals }) => {
  const styles = useStyles();

  const [orderBy, setOrderBy] = useState<OrderBy>(OrderBy.Date);
  const [orderDirection, setOrderDirection] = useState<OrderDirection>(OrderDirection.ASC);

  const sortHandler = (cell: OrderBy) => {
    setOrderBy(cell);
    setOrderDirection(cell === orderBy ? orderDirectionReverseMap[orderDirection] : OrderDirection.ASC);
  };

  return (
    <TableContainer component={Paper} variant={'outlined'}>
      <Table size={'small'}>
        <TableHead className={styles.tableHead}>
          <TableRow>
            <TableCell />

            <TableCell>
              <TableSortLabel
                active={orderBy === OrderBy.Email}
                direction={orderBy === OrderBy.Email ? orderDirection : OrderDirection.ASC}
                onClick={() => sortHandler(OrderBy.Email)}
              >
                <Trans>Email</Trans>
              </TableSortLabel>
            </TableCell>

            <TableCell>
              <TableSortLabel
                active={orderBy === OrderBy.Date}
                direction={orderBy === OrderBy.Date ? orderDirection : OrderDirection.ASC}
                onClick={() => sortHandler(OrderBy.Date)}
              >
                <Trans>Date</Trans>
              </TableSortLabel>
            </TableCell>

            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {sortReferrals(referrals, orderBy, orderDirection).map(referral => (
            <ReferralTableRow key={referral.id} referral={referral} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
