import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backgroundContainer: {
      width: 485,
      maxWidth: '100%',
    },

    backgroundImage: {
      display: 'block',
      width: '100%',
      height: 'auto',
    },

    [theme.breakpoints.down('lg')]: {
      backgroundContainer: {
        width: 400,
      },
    },
    [theme.breakpoints.down('md')]: {
      backgroundContainer: {
        width: 320,
      },
    },
    [theme.breakpoints.down('sm')]: {
      backgroundContainer: {
        width: 280,
      },
    },
  }),
);
