import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  ['@keyframes showHint']: {
    '0%': {
      opacity: 0,
      transform: 'translateY(5px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  hintContainer: {
    paddingLeft: 10,
    borderLeft: `2px solid ${pgColorScheme.blue}`,
    animation: '$showHint .3s ease-in-out .1s backwards',
    width: '100%',
    boxSizing: 'border-box',
  },
});
