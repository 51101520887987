import { AuditableEntity, IAuditableEntity } from '../common/auditable-entity';
import { Identifiable } from '../common/entity-collection-state';
import { IdRef } from '../common/id-ref';

export enum IdeaParameterType {
  gain = 'gain',
  cost = 'cost',
  risk = 'risk',
}

export interface IIdeaComment extends IAuditableEntity, Identifiable {
  gainDescription?: string;
  gainValue?: number;

  costDescription?: string;
  costValue?: number;

  riskDescription?: string;
  riskValue?: number;

  workspace: IdRef;
  project: IdRef;
  idea: IdRef;
}

export class IdeaComment extends AuditableEntity implements IIdeaComment {
  public id: number;

  public gainDescription?: string;
  public gainValue?: number;

  public costDescription?: string;
  public costValue?: number;

  public riskDescription?: string;
  public riskValue?: number;

  public workspace: IdRef;
  public project: IdRef;
  public idea: IdRef;

  constructor(json: IIdeaComment) {
    super(json);

    this.id = json.id;

    this.gainDescription = json.gainDescription;
    this.gainValue = json.gainValue;

    this.costDescription = json.costDescription;
    this.costValue = json.costValue;

    this.riskDescription = json.riskDescription;
    this.riskValue = json.riskValue;

    this.workspace = json.workspace;
    this.project = json.project;
    this.idea = json.idea;
  }
}
