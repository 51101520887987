import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';
import { alpha } from '@mui/material';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  col: {
    boxSizing: 'border-box',
    borderRadius: 4,
    backgroundColor: pgColorScheme.white,
  },
  colDefault: {
    border: `1px solid ${pgColorScheme.gray3}`,
  },
  colActive: {
    border: `1px solid ${pgColorScheme.blue}`,
  },
  colMain: {
    ['& $headerCell']: {
      backgroundColor: alpha(pgColorScheme.green, 0.1),
    },
  },
  headerCell: {
    borderBottom: `1px solid ${pgColorScheme.gray3}`,
  },
});
