import axios, { AxiosResponse } from 'axios';
import { IPartnerCommission, PartnerCommission } from '@priz/shared/src/models/security/partner-commission';
import { PartnerCommissionStatus } from '@priz/shared/src/models/security/partner-commission-status';

const getAllByReferrerId = (referrerId: number): Promise<PartnerCommission[]> =>
  axios
    .get(`/v1/api/referrer/${referrerId}/commissions`)
    .then((response: AxiosResponse<IPartnerCommission[]>) => (response.data || []).map(i => new PartnerCommission(i)));

export interface PartnerCommissionStatusesUpdateCommand {
  commissionsIds: number[];
  status: PartnerCommissionStatus;
}

const updatePartnerCommissionsStatuses = (
  referrerId: number,
  command: PartnerCommissionStatusesUpdateCommand,
): Promise<PartnerCommission[]> =>
  axios
    .patch(`/v1/api/referrer/${referrerId}/commissions`, command)
    .then((response: AxiosResponse<IPartnerCommission[]>) => (response.data || []).map(i => new PartnerCommission(i)));

export const CommissionsAdministrationApi = {
  getAllByReferrerId,
  updatePartnerCommissionsStatuses,
};
