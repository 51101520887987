import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../../theme';

export const useStyles = makeStyles({
  root: {
    'position': 'relative',

    '&._compact': {
      '& $label': {
        fontSize: 12,
      },

      '& $arrow': {
        '&._left': {
          'borderBottom': `1px solid ${pgColorScheme.darkGray}`,
          'height': 0,
          'top': 20,

          '&::before, &::after': {
            borderRight: `1px solid ${pgColorScheme.darkGray}`,
            width: 0,
            height: 6,
          },

          '&::before': {
            top: -6,
          },
        },

        '&._down': {
          'borderRight': `1px solid ${pgColorScheme.darkGray}`,
          'width': 0,
          'height': 'calc(100% + 50px)',

          '&::before, &::after': {
            borderBottom: `1px solid ${pgColorScheme.darkGray}`,
            width: 6,
            height: 0,
          },

          '&::before': {
            left: -6,
          },
        },
      },
    },
  },
  label: {
    position: 'absolute',
    top: '50%',
    right: 'auto',
    bottom: 'auto',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 14,
    textShadow: `-2px 0 ${pgColorScheme.white}, 2px 0 ${pgColorScheme.white}, 0 -2px ${pgColorScheme.white}, 0 2px ${pgColorScheme.white}`,
  },
  arrow: {
    'position': 'absolute',
    'right': 0,
    'bottom': 'auto',
    'left': 0,
    'margin': 'auto',
    'background': pgColorScheme.darkGray,
    'pointerEvents': 'none',

    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: pgColorScheme.darkGray,
    },

    '&._left': {
      'width': '100%',
      'height': 1,
      'top': 30,

      '&::before, &::after': {
        right: 'auto',
        width: 2,
        height: 8,
      },

      '&::before': {
        transformOrigin: '0 100%',
        transform: 'rotate(45deg)',
        top: -8,
      },

      '&::after': {
        transformOrigin: '0 0',
        transform: 'rotate(-45deg)',
        top: 1,
      },
    },

    '&._down': {
      'width': 1,
      'height': 'calc(100% + 100px)',
      'top': 0,

      '&::before, &::after': {
        top: 'auto',
        width: 8,
        height: 2,
      },

      '&::before': {
        left: -8,
        transformOrigin: '100% 100%',
        transform: 'rotate(45deg)',
      },

      '&::after': {
        left: 1,
        transformOrigin: '0 100%',
        transform: 'rotate(-45deg)',
      },
    },
  },
});
