import axios, { AxiosResponse } from 'axios';
import { CecUtilization, ICecUtilization } from '@priz/shared/src/models/tools/cec';

const update = (utilization: CecUtilization): Promise<ICecUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/cec/${utilization.id}`, {
      title: utilization.title,
      diagramData: utilization.diagramData,
      subject: utilization.subject,
      conclusion: utilization.conclusion,
    })
    .then((response: AxiosResponse<ICecUtilization>) => response.data);

const doDelete = (utilizationId: number): Promise<any> =>
  axios.delete(`/v1/api/<ws-id>/team/<t-id>/cec/${utilizationId}`);

export const CecUtilizationApi = {
  delete: doDelete,
  update,
};
