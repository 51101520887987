import { IdRef } from '../common/id-ref';
import { AuditableEntity, IAuditableEntity } from '../common/auditable-entity';
import { Identifiable } from '../common/entity-collection-state';
import { ToolType } from './tool-type';

export interface ToolUtilizationMetaData {
  aiAssistanceCount: number;
}

export const DefaultToolUtilizationMeta = {
  aiAssistanceCount: 0,
};

export interface IToolUtilization extends IAuditableEntity, Identifiable {
  title?: string;
  project?: IdRef;

  toolUtilizationChainId: number;
  sourceToolUtilization?: IdRef;
  sourceId?: string;

  workspace: IdRef;
  team: IdRef;

  subject?: string;
  conclusion?: string;
  completed?: boolean;

  parentId?: number;
  publicId?: string;
  accessToken?: string;
  metaData?: ToolUtilizationMetaData;

  type?: ToolType;
}

export class ToolUtilization extends AuditableEntity implements IToolUtilization {
  public id: number;
  public title?: string;
  public project?: IdRef;
  public toolUtilizationChainId: number;
  public sourceToolUtilization?: IdRef;
  public sourceId?: string;
  public workspace: IdRef;
  public team: IdRef;
  public subject?: string;
  public conclusion?: string;
  public parentId?: number;
  public type?: ToolType;
  public publicId?: string;
  public metaData: ToolUtilizationMetaData;
  public accessToken?: string;
  public completed?: boolean;

  constructor(json: IToolUtilization) {
    super(json);
    this.id = json.id;
    this.title = json.title;
    this.project = json.project;
    this.toolUtilizationChainId = json.toolUtilizationChainId;
    this.sourceToolUtilization = json.sourceToolUtilization;
    this.sourceId = json.sourceId;
    this.workspace = json.workspace;
    this.team = json.team;
    this.subject = json.subject;
    this.conclusion = json.conclusion;
    this.parentId = json.parentId;
    this.type = json.type;
    this.publicId = json.publicId;
    this.metaData = json.metaData || DefaultToolUtilizationMeta;
    this.completed = json.completed;
  }
}
