import { useEffect, useState } from 'react';

interface EventHandlers<D> {
  onHistoryChange?: (data: D) => void;
}

interface History<D> {
  saveInHistory: (data: D) => void;
  replaceHistory: (data: D) => void;
  clearHistory: () => void;
  moveHistoryBack: () => void;
  moveHistoryForward: () => void;
  isHistoryAtStart: boolean;
  isHistoryAtEnd: boolean;
  isHistoryClear: boolean;
  historyEvents: EventHandlers<D>;
}

interface HistoryHookProps<D> {
  initialState?: D;
  maxCache?: number;
  keyboardControl?: boolean;
  onHistoryChange?: (data: D) => void;
  disabled?: boolean;
}

export const useHistory = <D>(props: HistoryHookProps<D>): History<D> => {
  const { initialState, maxCache = 1000, keyboardControl = true, onHistoryChange, disabled } = props;

  const [cache, setCache] = useState<D[]>(initialState ? [initialState] : []);
  const [pointer, setPointer] = useState(0);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(true);
  const [eventHandlers] = useState<EventHandlers<D>>({});

  useEffect(() => {
    if (keyboardControl && !disabled) {
      document.addEventListener('keydown', documentKeyDownHandler);
    } else {
      document.removeEventListener('keydown', documentKeyDownHandler);
    }

    return () => {
      document.removeEventListener('keydown', documentKeyDownHandler);
    };
  }, [pointer, cache, keyboardControl, disabled]);

  useEffect(() => {
    setIsAtStart(pointer <= 0);
    setIsAtEnd(pointer >= cache.length - 1);
  }, [pointer]);

  useEffect(() => {
    setPointer(cache.length - 1);
  }, [cache]);

  const documentKeyDownHandler = (e: KeyboardEvent) => {
    const zButtonIsPressed = e.key === 'z';

    if (e.ctrlKey && !e.shiftKey && zButtonIsPressed) {
      undo();
    }

    if (e.ctrlKey && e.shiftKey && zButtonIsPressed) {
      redo();
    }
  };

  const save = (data: D) => {
    setCache(currentState => {
      const cacheCopy = [...currentState];
      const lastRecord = cacheCopy[cacheCopy.length - 1];
      const lastRecordKey = JSON.stringify(lastRecord);
      const dataKey = JSON.stringify(data);

      if (lastRecordKey !== dataKey) {
        if (cacheCopy.length >= maxCache) {
          cacheCopy.shift();
        }
        return [...cacheCopy, data];
      } else {
        return currentState;
      }
    });
  };

  const replace = (data: D) => {
    setCache([data]);
    setPointer(0);
  };

  const clear = () => {
    setCache([]);
    setPointer(0);
  };

  const undo = () => {
    const newIndex = moveHistoryIndex(-1);

    if (onHistoryChange) {
      onHistoryChange(cache[newIndex]);
    }

    if (eventHandlers.onHistoryChange) {
      eventHandlers.onHistoryChange(cache[newIndex]);
    }
  };

  const redo = () => {
    const newIndex = moveHistoryIndex(1);

    if (onHistoryChange) {
      onHistoryChange(cache[newIndex]);
    }

    if (eventHandlers.onHistoryChange) {
      eventHandlers.onHistoryChange(cache[newIndex]);
    }
  };

  const moveHistoryIndex = (direction: 1 | -1): number => {
    const newPointer = Math.min(Math.max(pointer + direction, 0), cache.length - 1);
    setPointer(newPointer);
    return newPointer;
  };

  return {
    saveInHistory: save,
    replaceHistory: replace,
    clearHistory: clear,
    moveHistoryBack: undo,
    moveHistoryForward: redo,
    isHistoryAtStart: isAtStart,
    isHistoryAtEnd: isAtEnd,
    isHistoryClear: !cache.length,
    historyEvents: eventHandlers,
  };
};
