import { alpha } from '@mui/material';
import { pgColorScheme } from '@priz/shared/src/theme';
import { TemplateCategory } from '@priz/shared/src/models/template';

export const templateCategoryColorMap: { [key in TemplateCategory]: string } = {
  [TemplateCategory.Preparing]: alpha(pgColorScheme.blue, 0.5),
  [TemplateCategory.Tools]: alpha(pgColorScheme.purple, 0.5),
  [TemplateCategory.Activity]: alpha(pgColorScheme.orange, 0.5),
  [TemplateCategory.Result]: alpha(pgColorScheme.green, 0.5),
};
