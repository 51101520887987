import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    assistantSvg: {
      height: 200,
      width: 'auto',
      marginBottom: -32,
    },

    [theme.breakpoints.down('md')]: {
      assistantSvg: {
        height: 150,
      },
    },
  }),
);
