import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { PageContainer } from '../../content-containers/page-container/component';
import { AppPublicNavbar } from '../../navigation/app-public-navbar/component';
import { ProjectFeatureBlocker } from '../../project-feature-blocker/component';
import { AxiosService } from '@priz/shared/src/services/axios';
import { AuthService } from '@priz/shared/src/services/auth';
import { PrizLoadingOverlay } from '@priz/shared/src/components/priz-loading-overlay/component';
import { PublicToolAuthMessage } from '../public-tool-auth-message/component';
import { useDispatch, useSelector } from 'react-redux';
import { PlaygroundSelectors } from '../store/selectors';
import { WorkspaceActions } from '../../workspace/store/actions';
import { UserContextActions } from '../../security/store/actions';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { UserContextSelectors } from '../../security/store/selectors';
import { PayWall } from '../../react/modules/paywall/component';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';

export const PlaygroundRoot: React.FC = () => {
  const dispatch = useDispatch();

  const [isConfigReady, setIsConfigReady] = useState(false);

  const isAuthenticationRequired = useSelector(PlaygroundSelectors.isAuthenticationRequired);
  const isWorkspaceLoaded = useSelector(WorkspaceSelectors.isLoaded);
  const isUserContextLoaded = useSelector(UserContextSelectors.isLoaded);

  useEffect(() => {
    AuthService.clearAuthData();
    AxiosService.enableAnonymousAccess();
    LocalStorageService.removeItem(LocalStorageKey.ProjectFeatureBlockerMock);
    setIsConfigReady(true);

    return () => {
      AuthService.clearAuthData();
      AxiosService.disableAnonymousAccess();
      setIsConfigReady(false);
    };
  }, []);

  useEffect(() => {
    if (isConfigReady) {
      dispatch(WorkspaceActions.loadAll());
      dispatch(UserContextActions.forceLoad());
    }
  }, [isConfigReady]);

  if (!isConfigReady || !isWorkspaceLoaded || !isUserContextLoaded) {
    return <PrizLoadingOverlay />;
  }

  return (
    <>
      <PageContainer variant={'clear'}>
        <AppPublicNavbar content={!isAuthenticationRequired && <PublicToolAuthMessage />} localeButtons={false} />

        <Outlet />
      </PageContainer>

      {!isAuthenticationRequired && (
        <>
          <PayWall anonymous />
          <ProjectFeatureBlocker anonymous />
        </>
      )}
    </>
  );
};
