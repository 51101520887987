import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, BoxProps } from '@mui/material';
import { FilesSelectButton } from '../files-select-button/component';
import { AttachmentType } from '../store/model';
import { AttachmentsList } from '../attachments-list/component';
import { AttachmentsActions } from '../store/actions';
import { AttachmentsSelectors } from '../store/selectors';

export interface AttachmentsLoaderProps extends BoxProps {
  projectId: number;
  type: AttachmentType;
  taskId?: number;
  compact?: boolean;
  loadingText?: string;
  disabled?: boolean;
}

export const AttachmentsLoader: React.FC<AttachmentsLoaderProps> = ({
  projectId,
  type,
  taskId,
  compact,
  loadingText,
  disabled,
  ...rest
}) => {
  const dispatch = useDispatch();

  const creatingAttachments = useSelector(
    AttachmentsSelectors.getCreatingAttachmentsByProjectIdAndType(projectId, type, taskId),
  );
  const isLoaded = useSelector(AttachmentsSelectors.isLoaded(projectId));

  const createAttachment = (file: File) => {
    switch (type) {
      case AttachmentType.Overview:
        dispatch(AttachmentsActions.createOverviewAttachment(projectId, file));
        break;

      case AttachmentType.Solution:
        dispatch(AttachmentsActions.createSolutionAttachment(projectId, file));
        break;

      case AttachmentType.Task:
        if (taskId) dispatch(AttachmentsActions.createTaskAttachment(projectId, taskId, file));
        break;
    }
  };

  const fileSelectHandler = (files: File[]) => {
    const creatingAttachmentsNames = creatingAttachments.map(a => a.fileName);

    files.forEach(f => {
      if (!creatingAttachmentsNames.includes(f.name)) {
        createAttachment(f);
      }
    });
  };

  return (
    <Box {...rest}>
      <AttachmentsList
        projectId={projectId}
        taskId={taskId}
        type={type}
        pb={2}
        onCreate={createAttachment}
        compact={compact}
        loadingText={loadingText}
        disabled={disabled}
      />

      {isLoaded && (
        <FilesSelectButton
          onSelect={fileSelectHandler}
          maxFilesCount={5}
          maxFileSizeInBytes={1024 * 1024 * 10}
          disabled={disabled}
        />
      )}
    </Box>
  );
};
