import { combineActions, handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { ProjectFeatureBlockerAction, ProjectFeatureBlockerActionType } from '../actions';
import {
  DefaultProjectFeatureBlockerState,
  ProjectFeatureBlockerType,
  ProjectFeatureResolverBlockerProps,
  ProjectFeatureResolverBlockerState,
} from '../model';
import { RrmUtilizationActionType } from '../../../rrm/store/actions/rrm-utilization.actions';
import { MeetingActionType } from '../../../meetings/store/actions';
import { ProjectIdeaActionType } from '../../../idea/store';
import { TaskActionType } from '../../../tasks/store/actions/task.actions';
import { FortyPrinciplesUtilizationActionType } from '../../../forty-principles/store/actions';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';

const setPayloadMock = (payload: ProjectFeatureResolverBlockerProps) => {
  LocalStorageService.setItem(LocalStorageKey.ProjectFeatureBlockerMock, JSON.stringify(payload));
};

const clearPayloadMock = () => {
  LocalStorageService.removeItem(LocalStorageKey.ProjectFeatureBlockerMock);
};

export const projectFeatureBlockerReducers = handleActions(
  {
    [toSuccess(ProjectFeatureBlockerActionType.Set)]: (
      state: ProjectFeatureResolverBlockerState,
      action: ProjectFeatureBlockerAction,
    ): ProjectFeatureResolverBlockerState => {
      if (!action.meta.preventMock) {
        setPayloadMock(action.payload);
      }

      return {
        ...state,
        ...action.payload,
      };
    },

    [combineActions(
      toSuccess(ProjectFeatureBlockerActionType.Clear),
      toSuccess(RrmUtilizationActionType.Create),
      toSuccess(MeetingActionType.Create),
      toSuccess(ProjectIdeaActionType.Create),
      toSuccess(TaskActionType.Task_Create),
    )]: (state: ProjectFeatureResolverBlockerState): ProjectFeatureResolverBlockerState => {
      clearPayloadMock();

      return {
        ...state,
        blocker: ProjectFeatureBlockerType.NotDefined,
      };
    },

    // FortyPrinciples

    [toStarted(FortyPrinciplesUtilizationActionType.Create)]: (
      state: ProjectFeatureResolverBlockerState,
    ): ProjectFeatureResolverBlockerState => {
      clearPayloadMock();

      return {
        ...state,
        statuses: {
          ...state.statuses,
          creating: true,
        },
      };
    },

    [toFailed(FortyPrinciplesUtilizationActionType.Create)]: (
      state: ProjectFeatureResolverBlockerState,
    ): ProjectFeatureResolverBlockerState => {
      clearPayloadMock();

      return {
        ...state,
        blocker: ProjectFeatureBlockerType.NotDefined,
        statuses: {
          ...state.statuses,
          creating: false,
        },
      };
    },

    [toSuccess(FortyPrinciplesUtilizationActionType.Create)]: (
      state: ProjectFeatureResolverBlockerState,
    ): ProjectFeatureResolverBlockerState => {
      clearPayloadMock();

      return {
        ...state,
        blocker: ProjectFeatureBlockerType.NotDefined,
        statuses: {
          ...state.statuses,
          creating: false,
        },
      };
    },
  },

  DefaultProjectFeatureBlockerState,
);
