import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Link, Tab, Tabs, Typography } from '@mui/material';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import { ProjectFlowService } from '../../project/services';
import { ProjectSelector, ToolUtilizationSelector } from '../../project/store/selectors';
import { EbsIdea, EbsIdeaNodeProps } from '@priz/shared/src/models/tools/ebs';
import { EbsUtilizationActions } from '../store/actions';
import { ToolTitle } from '../../tools/tool-title/component';
import { EbsIdeaSelectors } from '../store/selectors';
import { useStyles } from './styles';
import { ToolSubjectConclusion } from '../../tools/tool-subject-conclusion/component';
import { TabPanel } from '../../react/elements/tab-panel/component';
import { TitleContainer } from '../../react/modules/title-container/component';
import { EbsWorkspace } from '../ebs-workspace/component';
import { EbsRankIdeasButton } from '../ebs-rank-ideas-button/comonent';
import { UserSettingsActions } from '../../user/store/actions/user-settings.actions';
import { QuillContentViewer } from '@priz/shared/src/components/quill-content-viewer/component';
import { Link as RouterLink } from 'react-router-dom';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { ToolUtilizationActions, ToolUtilizationActionType } from '../../project/store/actions/tools.actions';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { useToolTabs } from '../../tools/utils';

interface EbsUtilizationProps {
  utilizationId: number;
  projectId?: number;
}

export const EbsView: React.FC<EbsUtilizationProps> = ({ projectId, utilizationId }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useToolTabs(0);

  const project = useSelector(ProjectSelector.getById(projectId));
  const utilization = useSelector(ToolUtilizationSelector.getEbsUtilization(utilizationId));
  const ebsIdeas = useSelector(EbsIdeaSelectors.getAllByUtilizationId(utilizationId));

  const ebsIdeasLoaded = useSelector(EbsIdeaSelectors.loadedByUtilizationId(utilizationId));
  const ebsIdeasLoading = useSelector(EbsIdeaSelectors.loadingByUtilizationId(utilizationId));
  const isProjectLocked = useSelector(ProjectSelector.isProjectLockedById(projectId));
  const isCompletenessUpdating = useSelector(
    ToolUtilizationSelector.isPendingByActionType(utilizationId, ToolUtilizationActionType.UpdateCompleteness),
  );

  const isDisabled = !!utilization?.completed || isCompletenessUpdating || isProjectLocked;

  const isProblemStatementDefined = ProjectFlowService.areProjectDetailedCompleted(project);

  useEffect(() => {
    if (utilization && LocalStorageService.getItem(LocalStorageKey.EbsEnableGrouping)) {
      setActiveTab(1);
    }
  }, [utilization?.id]);

  useEffect(() => {
    dispatch(UserSettingsActions.load());
  }, []);

  useEffect(() => {
    dispatch(ToolUtilizationActions.loadTool(utilizationId, projectId));
    dispatch(EbsUtilizationActions.fetchIdeas(utilizationId));
  }, [utilizationId, projectId]);

  const subjectChangeHandler = (subject: string) => {
    dispatch(EbsUtilizationActions.update({ ...utilization, subject }));
  };

  const handleTabChange = (_event, newValue: number) => {
    setActiveTab(newValue);
  };

  const onGroup = (grouped: boolean) => {
    dispatch(EbsUtilizationActions.update({ ...utilization, grouped }));
  };

  const onAdd = (idea: EbsIdeaNodeProps) => {
    const { id, x, y, implementation, validation } = idea;

    dispatch(EbsUtilizationActions.createIdea(utilizationId, '', id.toString(), x, y, validation, implementation));
  };

  const onUpdate = (ideas: EbsIdea[]) => {
    const ideasForUpdate = [];

    ideas.forEach(idea => {
      const { description, implementation, validation, x, y, id, latestRankingScore } = idea;
      const data = { description, implementation, validation, x, y, latestRankingScore };

      if (typeof id === 'number') ideasForUpdate.push({ id, ...data });

      if (typeof id === 'string') {
        const relatedIdea = ebsIdeas.find(item => item.nodeId === id);

        if (relatedIdea)
          ideasForUpdate.push({
            id: relatedIdea.id,
            ...data,
          });
      }
    });

    if (ideasForUpdate.length === 1) {
      const { id, x, y, description, implementation, validation, latestRankingScore } = ideasForUpdate[0];
      const relatedIdea = ebsIdeas.find(item => item.id === id);
      const currentRankingScoreIsDefined = typeof relatedIdea.latestRankingScore !== 'undefined';
      const newRankingScoreIsDefined = typeof latestRankingScore !== 'undefined';
      const resetLatestRankingScore = currentRankingScoreIsDefined && !newRankingScoreIsDefined;

      dispatch(
        EbsUtilizationActions.updateIdea(
          utilizationId,
          id,
          description,
          x,
          y,
          validation,
          implementation,
          resetLatestRankingScore,
        ),
      );
    }

    if (ideasForUpdate.length > 1) {
      dispatch(EbsUtilizationActions.updateIdeas(utilizationId, ideasForUpdate));
    }
  };

  const onRemove = (id: number) => {
    dispatch(EbsUtilizationActions.deleteIdea(utilizationId, id));
  };

  if (!utilization) {
    return null;
  }

  return (
    <>
      <ContentContainer display={'flex'} alignItems={'stretch'}>
        <Grid container direction={'column'} wrap={'nowrap'}>
          <Grid item xs={'auto'}>
            <TitleContainer>
              <PageTitleWithDocLink
                titleComponent={
                  <ToolTitle
                    initialTitle={'Effective Brainstorming (EBS)'}
                    tool={utilization}
                    preset={'large'}
                    editable={!isDisabled}
                  />
                }
                oneLiner={
                  <Trans>Brainstorm tasks with your team, collect them in one place, rank and act on them.</Trans>
                }
                docsUrl={'https://www.priz.guru/knowledge-base/effective-brainstorming/'}
              />

              {!utilization.publicId && (
                <Box display="flex" justifyContent="flex-end" alignItems={'center'}>
                  <Tabs
                    orientation={'horizontal'}
                    variant={'scrollable'}
                    scrollButtons={'auto'}
                    allowScrollButtonsMobile={true}
                    value={activeTab}
                    onChange={handleTabChange}
                  >
                    <Tab label={<Trans>Subject</Trans>} />
                    <Tab label={<Trans>Ideas</Trans>} />
                  </Tabs>
                </Box>
              )}
            </TitleContainer>
          </Grid>

          <Grid item xs={true}>
            {!utilization.publicId && (
              <TabPanel index={0} value={activeTab} stretch>
                <Box>
                  {isProblemStatementDefined && (
                    <Box pb={1} pl={2} mb={3} className={styles.problemStatementWrap}>
                      <Box mb={1}>
                        <Typography variant={'h6'} component={'span'} gutterBottom={false}>
                          <Trans>Problem statement</Trans>:
                        </Typography>
                      </Box>

                      <QuillContentViewer content={project.problemStatement} />
                    </Box>
                  )}

                  {!isProblemStatementDefined && (
                    <Box
                      display={'flex'}
                      alignItems={'baseline'}
                      justifyContent={'flex-start'}
                      flexWrap={'wrap'}
                      mb={2}
                    >
                      <Typography variant={'h6'} component={'span'}>
                        <Box display={'inline'} pr={1}>
                          <Trans>Problem statement is not defined. For better results</Trans>
                        </Box>

                        <Box display={'inline-block'}>
                          <Typography variant={'body2'}>
                            <Link
                              component={RouterLink}
                              to={['/project', projectId, 'problem-statement'].join('/')}
                              underline={'hover'}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              <Trans>Define problem statement</Trans>
                            </Link>
                          </Typography>
                        </Box>
                      </Typography>
                    </Box>
                  )}
                </Box>

                <ToolSubjectConclusion
                  onContentChange={subjectChangeHandler}
                  initialContent={utilization?.subject}
                  disabled={isDisabled}
                  placeholder={'Describe the system that you are analyzing in this brainstorming session'}
                />
              </TabPanel>
            )}

            <TabPanel index={utilization.publicId ? 0 : 1} value={activeTab} stretch>
              <div className={styles.workspaceContainer}>
                {ebsIdeasLoaded && !ebsIdeasLoading ? (
                  <EbsWorkspace
                    ideas={ebsIdeas}
                    onAdd={onAdd}
                    onUpdate={onUpdate}
                    onRemove={onRemove}
                    onGroup={onGroup}
                    disabled={isDisabled}
                    fitOnInit={activeTab === 1}
                    projectId={projectId}
                    grouped={utilization.grouped}
                  />
                ) : (
                  <LoadingOverlay
                    loading={true}
                    backdropStyles={{
                      background: 'transparent',
                    }}
                  />
                )}
              </div>
            </TabPanel>
          </Grid>
        </Grid>
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={projectId}
          utilization={utilization}
          disabled={isDisabled}
          rightColContent={
            <EbsRankIdeasButton
              projectId={projectId}
              utilizationId={utilizationId}
              ebsIdeas={ebsIdeas}
              disabled={isDisabled}
            />
          }
          workingFlowProps={{ nextVariant: 'ideas' }}
        />
      </ContentFooter>
    </>
  );
};
