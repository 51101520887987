import React from 'react';
import {
  CftNodeData,
  CftNodePosition,
  CftNodeStatus,
  CftNodeType,
  OptionalCftNodeControlsRenderer,
  OptionalCftNodeTextEditorRenderer,
} from '../../../models/tools/cft';
import { useStyles } from './styles';
import { Box, BoxProps } from '@mui/material';
import { CftNodeIndicators } from '../cft-node-indicators/component';

interface CftStepProps
  extends BoxProps,
    OptionalCftNodeControlsRenderer,
    OptionalCftNodeTextEditorRenderer,
    CftNodePosition {
  node: CftNodeData;
  disableInteractive?: boolean;
}

export const CftStep: React.FC<CftStepProps> = ({
  node,
  controlsRenderer,
  editorRenderer,
  inProcess,
  inVariant,
  inRootBranch,
  disableInteractive,
  ...rest
}) => {
  const styles = useStyles();
  const rootClassNames = [styles.root];

  const typeClassNamesMap = {
    [CftNodeType.Statement]: styles.statement,
    [CftNodeType.Abstraction]: styles.abstraction,
    [CftNodeType.Step]: styles.step,
    [CftNodeType.Variant]: '',
    [CftNodeType.Process]: '',
  };

  const statusClassNamesMap = {
    [CftNodeStatus.Blocker]: styles.blockerStep,
    [CftNodeStatus.Problematic]: styles.problematicStep,
    [CftNodeStatus.Verified]: styles.verifiedStep,
    default: styles.defaultStep,
  };

  rootClassNames.push(typeClassNamesMap[node.type]);

  if (node.type === CftNodeType.Step) {
    rootClassNames.push(statusClassNamesMap[node.status || 'default']);
  }

  return (
    <Box id={node.id} className={rootClassNames.join(' ')} {...rest}>
      {editorRenderer && editorRenderer({ node, inRootBranch, className: styles.content })}

      {!editorRenderer && <div className={styles.content}>{node.description}</div>}

      {(node.status || node.notes) && (
        <Box className={styles.indicatorsContainer} pr={1}>
          <CftNodeIndicators node={node} disableInteractive={disableInteractive} />
        </Box>
      )}

      {controlsRenderer &&
        controlsRenderer({ node, inProcess, inVariant, inRootBranch, className: styles.controlsContainer })}
    </Box>
  );
};
