import { Domain } from '@priz/shared/src/models/tools/forty-principles/domain';
import {
  DefaultEntityCollectionStatus,
  EntityCollectionStatus,
} from '@priz/shared/src/models/common/entity-collection-state';

export interface DomainList {
  domains: Domain[];
  statuses: EntityCollectionStatus;
}

export const DefaultDomainList: DomainList = {
  domains: [],
  statuses: DefaultEntityCollectionStatus,
};
