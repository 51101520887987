import { AuditableEntity, IAuditableEntity } from '../../common/auditable-entity';
import { Identifiable } from '../../common/entity-collection-state';

export interface IPerception extends IAuditableEntity, Identifiable {
  definition: string;
  perceptionSourceId?: number;
  rankingScore?: number | null;
}

export class Perception extends AuditableEntity implements IPerception {
  public id: number;
  public definition: string;
  public perceptionSourceId?: number;
  public rankingScore?: number | null;

  constructor(json: IPerception) {
    super(json);
    this.id = json.id;
    this.definition = json.definition;
    this.perceptionSourceId = json.perceptionSourceId;
    this.rankingScore = typeof json.rankingScore === 'undefined' ? null : json.rankingScore;
  }
}
