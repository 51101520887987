import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { Plan, PlanInterval, PlanLevel } from '@priz/shared/src/models/workspace';
import { Box, BoxProps, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { pgColorScheme } from '@priz/shared/src/theme';
import { PlanPriceString } from '../plan-price-string/component';

interface PlanPriceProps extends BoxProps {
  plan: Plan;
  level: PlanLevel;
  interval: PlanInterval;
  quantity: number;
}

export const PlanPrice: React.FC<PlanPriceProps> = ({ plan, level, interval, quantity, ...rest }) => {
  const renderDescription = () => {
    switch (level) {
      case PlanLevel.Personal:
        return (
          <>
            <Trans>Single User</Trans>
            <br />
            <Trans>Free forever</Trans>
          </>
        );

      case PlanLevel.Enterprise:
        return (
          <>
            <Trans>Contact us</Trans>
            <br />
            <Trans>for details</Trans>
          </>
        );

      default:
        return (
          <>
            {interval === PlanInterval.Monthly && (
              <>
                {quantity === 1 && <Trans>Per User</Trans>}
                {quantity > 1 && (
                  <>
                    <Trans>Per</Trans>
                    {' ' + quantity + ' '}
                    <Trans>Users</Trans>
                  </>
                )}
                <br />
                <Trans>Per Month</Trans>
              </>
            )}

            {interval === PlanInterval.Yearly && (
              <>
                {quantity === 1 && <Trans>per user per year</Trans>}
                {quantity > 1 && (
                  <>
                    <Trans>per</Trans>
                    {' ' + quantity + ' '}
                    <Trans>users per year</Trans>
                  </>
                )}
                <br />
                <b style={{ color: pgColorScheme.blue }}>
                  <CurrencyFormat
                    value={(plan.cost / 12) * quantity}
                    displayType={'text'}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </b>{' '}
                {quantity === 1 && <Trans>per user per month</Trans>}
                {quantity > 1 && (
                  <>
                    <Trans>per</Trans>
                    {' ' + quantity + ' '}
                    <Trans>users per month</Trans>
                  </>
                )}
              </>
            )}
          </>
        );
    }
  };

  return (
    <Box sx={{ color: pgColorScheme.darkGray }} {...rest}>
      <Typography variant={'h5'} component={Box} mb={1}>
        <b>
          <PlanPriceString plan={plan} level={level} quantity={quantity} decimalScale={0} />
        </b>
      </Typography>

      <Typography variant={'body2'}>{renderDescription()}</Typography>
    </Box>
  );
};
