import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { CalcResult } from '@priz/shared/src/models/tools/pmap';

export interface PMapResultsCollection {
  byUtilizationId: {
    [utilizationId: number]: CalcResult;
  };
}

export interface PMapResultStatuses {
  byUtilizationId: { [utilizationId: number]: EntityCollectionStatus };
}

export interface PMapResultCollection {
  result: PMapResultsCollection;
  statuses: PMapResultStatuses;
}

export const DefaultPMapResultCollection: PMapResultCollection = {
  result: {
    byUtilizationId: {},
  },

  statuses: {
    byUtilizationId: {},
  },
};
