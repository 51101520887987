import { FluxStandardAction } from 'flux-standard-action';
import { IGoal, IPerception, Perception } from '@priz/shared/src/models/tools/rrm';
import { createPgAction } from '../../../shared/store/action-creator';
import { ActionMeta } from '../../../shared/store/types';
import { RrmUtilizationApi } from '../../services';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { ToolType } from '@priz/shared/src/models/tools';

export enum RrmGoalActionType {
  Fetch = 'rrmGoal/fetch',
  PerceptionCreate = 'rrmGoal/perception/create',
  PerceptionUpdate = 'rrmGoal/perception/update',
  PerceptionDelete = 'rrmGoal/perception/delete',
  PerceptionsReplace = 'rrmGoal/perceptions/replaced',
}

interface RrmGoalMeta extends ActionMeta {
  goalId?: number;
  projectId?: number;
  perceptionId?: number;
  utilizationId?: number;
  definitionsToCreate?: string[];
  idsToDelete?: number[];
  perception?: IPerception;
}

type RrmGoalActionPayload = IGoal | IPerception | IPerception[];

export type RrmGoalAction = FluxStandardAction<string, RrmGoalActionPayload, RrmGoalMeta>;

const loadRrmGoal = createPgAction(RrmGoalActionType.Fetch, RrmUtilizationApi.getGoal, (goalId: number) => ({
  goalId,
}));

const createPerception = createPgAction(
  RrmGoalActionType.PerceptionCreate,
  RrmUtilizationApi.createPerception,
  (utilizationId: number, definition: string, goalId: number, projectId?: number) => ({
    definition,
    goalId,
    projectId,
    utilizationId,
  }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.RRM,
      utilization_id: utilizationId,
    });
  },
);

const replacePerceptions = createPgAction(
  RrmGoalActionType.PerceptionsReplace,
  RrmUtilizationApi.replacePerceptions,
  (
    utilizationId: number,
    definitionsToCreate: string[],
    idsToDelete: number[],
    goalId: number,
    projectId?: number,
  ) => ({
    definitionsToCreate,
    idsToDelete,
    goalId,
    projectId,
    utilizationId,
  }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.RRM,
      utilization_id: utilizationId,
    });
  },
);

const updatePerception = createPgAction(
  RrmGoalActionType.PerceptionUpdate,
  RrmUtilizationApi.updatePerception,
  (utilizationId: number, perception: Perception, goalId: number, projectId?: number) => ({
    goalId,
    perception,
    projectId,
    utilizationId,
  }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.RRM,
      utilization_id: utilizationId,
    });
  },
);

const deletePerception = createPgAction(
  RrmGoalActionType.PerceptionDelete,
  RrmUtilizationApi.deletePerception,
  (utilizationId: number, perceptionId: number, goalId: number, projectId?: number) => ({
    goalId,
    perceptionId,
    projectId,
    utilizationId,
  }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.RRM,
      utilization_id: utilizationId,
    });
  },
);

export const RrmGoalActions = {
  loadRrmGoal,
  createPerception,
  deletePerception,
  replacePerceptions,
  updatePerception,
};
