import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import { NetworkDiagram } from '@priz/shared/src/components/network-diagram/component';
import { manageNetworkEvents, updateNode, useNetworkDataset } from '@priz/shared/src/components/network-diagram/utils';
import { Network } from '@priz/shared/src/lib/vis/esnext';
import { NetworkToolsMenu } from '../../react/network/network-tools-menu/component';
import { colorsMap, nodePropsDecorator, options } from '@priz/shared/src/data/ebs-options';
import { NetworkNodeWidget } from '../../react/network/network-node-widget/component';
import { Box, Divider, Grid, IconButton, Typography, BoxProps } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  EbsIdea,
  EbsIdeaCategory,
  EbsIdeaDifficultyVariant,
  EbsIdeaNodeProps,
} from '@priz/shared/src/models/tools/ebs';
import { ToolType } from '@priz/shared/src/models/tools';
import { Trans, useTranslation } from 'react-i18next';
import {
  debounceByKey,
  resolveNodePropsByCoords,
  setWarningsRender,
  toggleEbsIdeasSorting,
  toggleWarningsShowState,
} from '../utils';
import {
  resolveNodeType,
  convertEbsIdeasToNodes,
  convertEbsNodesToIdeas,
  updateNodesPositionIfUnset,
  drawAreas,
  drawAreasLabels,
  drawLines,
} from '@priz/shared/src/utils/ebs';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { ReactHookFormSelectButton, ValueColorProps } from '../../react/form-elements';
import { pgColorScheme } from '@priz/shared/src/theme';
import { getEbsDifficultiesText, getEbsWarningText } from '@priz/shared/src/data/ebs-text';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { FullscreenContainer } from '@priz/shared/src/components/fullscreen-container/component';

import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as AddNote } from '../../../assets/icons/add-note.svg';
import { ReactComponent as SortIcon } from '../../../assets/icons/sort.svg';

const networkEvents = (network: Network, drawingCallback: (ctx) => void, dragEndCallback: (nodeId) => void) => ({
  dragStart: data => {
    if (data?.nodes?.length) {
      network.unselectAll();
      network.selectNodes(data.nodes, false);
    }
  },
  dragEnd: data => {
    if (data?.nodes?.length) {
      dragEndCallback(data.nodes[0]);
    }
  },
  afterDrawing: ctx => {
    drawingCallback(ctx);
  },
});

interface EbsWorkspaceProps extends BoxProps {
  ideas: EbsIdea[];
  grouped?: boolean;
  onAdd?: (idea: EbsIdeaNodeProps) => void;
  onUpdate?: (nodes: EbsIdea[]) => void;
  onRemove?: (nodeId: string | number) => void;
  onGroup?: (grouped: boolean) => void;
  viewMode?: boolean;
  disabled?: boolean;
  fitOnInit?: boolean;
  projectId?: number;
}

const getRelatedId = (ideas: EbsIdea[], id: string | number): number | undefined => {
  if (typeof id === 'number') return id;
  if (typeof id === 'string') return ideas.find(i => i.nodeId === id)?.id || undefined;
  return undefined;
};

const difficultyVariantSelectProps = [
  { value: '', text: 'not selected' },
  { value: EbsIdeaDifficultyVariant.Easy, text: 'easy' },
  { value: EbsIdeaDifficultyVariant.NotEasy, text: 'not easy' },
];

const selectColorProps: ValueColorProps = {
  [EbsIdeaDifficultyVariant.Easy]: {
    textColor: pgColorScheme.white,
    backgroundColor: pgColorScheme.green,
    backgroundHoverColor: pgColorScheme.green2,
  },
  [EbsIdeaDifficultyVariant.NotEasy]: {
    textColor: pgColorScheme.white,
    backgroundColor: pgColorScheme.redBright,
    backgroundHoverColor: pgColorScheme.mediumRed,
  },
};

export const EbsWorkspace: React.FC<EbsWorkspaceProps> = ({
  ideas,
  onAdd,
  onUpdate,
  onRemove,
  onGroup,
  viewMode,
  disabled,
  fitOnInit,
  projectId,
  grouped,
}) => {
  const styles = useStyles();

  const { t } = useTranslation();
  const warningText = getEbsWarningText(t);
  const translations = getEbsDifficultiesText(t);
  const ebsIdeasNodes = convertEbsIdeasToNodes(ideas);

  const [network, setNetwork] = useState(null);
  const [networkIsFit, setNetworkIsFit] = useState(false);
  const [selectedNode, setSelectedNode] = useState<EbsIdeaNodeProps | null>(null);
  const [addedNodeId, setAddedNodeId] = useState<string | number | null>(null);
  const { nodesDataSet, edgesDataSet } = useNetworkDataset<EbsIdeaNodeProps>(
    {
      edges: [],
      nodes: grouped ? setWarningsRender(ebsIdeasNodes, warningText, EbsIdeaCategory.NOT_DEFINED) : ebsIdeasNodes,
    },
    nodePropsDecorator,
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const [sortEnabled, setSortEnabled] = useState(grouped);
  const selectedNodeRelatedId = getRelatedId(ideas, selectedNode?.id);
  const [nodeIdToRemove, setNodeIdToRemove] = useState(null);
  const featureSet = useSelector(WorkspaceSelectors.getApplicableFeatureSet(projectId));

  const { getValues, reset, control, setValue } = useForm<{
    implementation: EbsIdeaDifficultyVariant | '';
    validation: EbsIdeaDifficultyVariant | '';
  }>({
    shouldFocusError: false,
    defaultValues: {
      implementation: '',
      validation: '',
    },
  });

  useEffect(() => {
    if (network && LocalStorageService.getItem(LocalStorageKey.EbsEnableGrouping)) {
      LocalStorageService.removeItem(LocalStorageKey.EbsEnableGrouping);
      setSortEnabled(true);
      toggleEbsIdeasSorting(network, nodesDataSet, warningText, true);
    }
  }, [network]);

  useEffect(() => {
    if (!selectedNode && nodeIdToRemove) {
      nodesDataSet.remove(nodeIdToRemove);
    }
  }, [selectedNode, nodeIdToRemove]);

  useEffect(() => {
    nodesDataSet.on('add', dataSetAddCallback);
    nodesDataSet.on('update', dataSetUpdateCallback);

    return () => {
      nodesDataSet.off('add', dataSetAddCallback);
      nodesDataSet.off('update', dataSetUpdateCallback);
    };
  }, [ideas]);

  useEffect(() => {
    if (network) {
      updateNodesPositionIfUnset(network, nodesDataSet);
    }
  }, [network, nodesDataSet]);

  useEffect(() => {
    const events = networkEvents(network, drawingCallback, dragEndHandler);

    if (network) {
      manageNetworkEvents(network, events);
      network.redraw();
    }

    if (network?.body?.container) {
      if (sortEnabled) {
        network.body.container.addEventListener('mousemove', networkMousemoveCallback);
      } else {
        network.body.container.removeEventListener('mousemove', networkMousemoveCallback);
      }
    }

    return () => {
      if (network) {
        manageNetworkEvents(network, events, true);
        network.redraw();
      }

      if (network?.body?.container) {
        network.body.container.removeEventListener('mousemove', networkMousemoveCallback);
      }
    };
  }, [network, sortEnabled]);

  useEffect(() => {
    if (network && fitOnInit && !networkIsFit) {
      network.fit();
      setNetworkIsFit(true);
    }
  }, [network, fitOnInit, networkIsFit]);

  const networkMousemoveCallback = (e: MouseEvent) => {
    const ctx = network.canvas.getContext('2d');
    const { x, y } = network.DOMtoCanvas({ x: e.offsetX, y: e.offsetY });

    toggleWarningsShowState(network, ctx, x, y);
  };

  const checkIsLimitReached = (): boolean => {
    return featureSet?.Tools?.EBS?.maxItems && nodesDataSet.get().length >= featureSet?.Tools?.EBS?.maxItems;
  };

  const dataSetAddCallback = (_event, data: { items: (string | number)[] }) => {
    const firstId = data.items[0];

    setAddedNodeId(firstId);

    if (onAdd) {
      onAdd(nodesDataSet.get(firstId));
    }
  };

  const dataSetUpdateCallback = (_event, data: { data: EbsIdeaNodeProps[] }, senderId?: string) => {
    if (senderId !== 'ignore' && onUpdate) {
      const key = data.data.map(item => item.id).join('-');

      debounceByKey(
        () => {
          onUpdate(convertEbsNodesToIdeas(data.data));
        },
        1000,
        key,
      );
    }
  };

  const dragEndHandler = (id: string) => {
    const coords = network.getPosition(id);
    const data = nodesDataSet.get(id);
    const { implementation, validation } = data;
    const resolvePropsByCoords = sortEnabled && implementation && validation;
    const currentType = resolveNodeType(data.implementation, data.validation);
    const positionProps = resolvePropsByCoords ? resolveNodePropsByCoords(coords.x, coords.y) : {};
    const resetRankingScore =
      sortEnabled && typeof data.latestRankingScore !== 'undefined' && currentType !== positionProps?.type;

    updateNode({
      nodesDataSet,
      nodePropsDecorator,
      id,
      data: {
        ...data,
        ...coords,
        ...positionProps,
        latestRankingScore: resetRankingScore ? undefined : data.latestRankingScore,
      },
    });

    nodeSelectHandler(nodesDataSet.get(id));
  };

  const networkInitHandler = network => {
    setNetwork(network);
  };

  const drawingCallback = ctx => {
    if (sortEnabled) {
      drawAreasLabels(ctx, translations);
      drawLines(ctx, network);
      drawAreas(ctx, network);
    }
  };

  const selectImplementationHandler = () => {
    const { implementation } = getValues();
    const { validation } = nodesDataSet.get(selectedNode.id);
    const newType = resolveNodeType(implementation, validation);

    updateNode<EbsIdeaNodeProps>({
      nodesDataSet,
      nodePropsDecorator,
      id: selectedNode.id,
      data: {
        implementation: implementation === '' ? undefined : implementation,
        type: newType,
        extraRenderFunction: newType !== EbsIdeaCategory.NOT_DEFINED ? null : selectedNode.extraRenderFunction,
      },
    });

    setSelectedNode(node => ({
      ...node,
      type: newType,
    }));
  };

  const selectValidationHandler = () => {
    const { validation } = getValues();
    const { implementation } = nodesDataSet.get(selectedNode.id);
    const newType = resolveNodeType(implementation, validation);

    updateNode<EbsIdeaNodeProps>({
      nodesDataSet,
      nodePropsDecorator,
      id: selectedNode.id,
      data: {
        validation: validation === '' ? undefined : validation,
        type: newType,
        extraRenderFunction: newType !== EbsIdeaCategory.NOT_DEFINED ? null : selectedNode.extraRenderFunction,
      },
    });

    setSelectedNode(node => ({
      ...node,
      type: newType,
    }));
  };

  const nodeSelectHandler = (node: EbsIdeaNodeProps) => {
    const { implementation = '', validation = '' } = node;

    reset({
      implementation,
      validation,
    });

    setSelectedNode(node);
  };

  const nodeRemoveHandler = () => {
    const idToRemove = selectedNode?.id;

    if (typeof idToRemove !== 'undefined') {
      if (idToRemove === addedNodeId) setAddedNodeId(null);
      setSelectedNode(null);
      setNodeIdToRemove(idToRemove);
      onRemove(selectedNodeRelatedId);
    }
  };

  const sortHandler = () => {
    setSortEnabled(!grouped);
    toggleEbsIdeasSorting(network, nodesDataSet, warningText, !grouped);
    if (onGroup) onGroup(!grouped);
  };

  const renderImplementationSelect = () => {
    return (
      <Grid container spacing={1} alignItems={'baseline'} wrap={'nowrap'}>
        <Grid item>
          <Typography variant={'body2'} component={'span'} noWrap>
            <Trans>Implement</Trans>
          </Typography>
        </Grid>

        <Grid item>
          <ReactHookFormSelectButton
            name={'implementation'}
            control={control}
            options={difficultyVariantSelectProps.map(option => ({ ...option, text: t(option.text) }))}
            setValue={setValue}
            onChange={selectImplementationHandler}
            valueColorProps={selectColorProps}
            wrapperProps={{ mb: 0 }}
          />
        </Grid>
      </Grid>
    );
  };

  const renderValidationSelect = () => {
    return (
      <Grid container spacing={1} alignItems={'baseline'} wrap={'nowrap'}>
        <Grid item>
          <Typography variant={'body2'} component={'span'} noWrap>
            <Trans>Validate</Trans>
          </Typography>
        </Grid>

        <Grid item>
          <ReactHookFormSelectButton
            name={'validation'}
            control={control}
            options={difficultyVariantSelectProps.map(option => ({ ...option, text: t(option.text) }))}
            setValue={setValue}
            onChange={selectValidationHandler}
            valueColorProps={selectColorProps}
            wrapperProps={{ mb: 0 }}
          />
        </Grid>
      </Grid>
    );
  };

  const memorizedToolsMenu = useMemo(() => {
    if (!network) return null;

    return (
      <NetworkToolsMenu
        network={network}
        nodesDataSet={nodesDataSet}
        edgesDataSet={edgesDataSet}
        nodePropsDecorator={nodePropsDecorator}
        checkIsLimitReached={checkIsLimitReached}
        viewMode={viewMode}
        disabled={disabled}
        toolType={ToolType.EBS}
        historyKeyboardControl={false}
        addNodeButtons={
          !disabled
            ? [
                {
                  type: EbsIdeaCategory.NOT_DEFINED,
                  icon: () => <AddNote />,
                  tooltip: 'Add idea',
                },
              ]
            : undefined
        }
        extraButtons={
          !disabled
            ? [
                {
                  icon: () => <SortIcon />,
                  tooltip: sortEnabled ? 'Disable sorting' : 'Enable sorting',
                  active: sortEnabled,
                  callback: sortHandler,
                },
              ]
            : undefined
        }
      />
    );
  }, [network, viewMode, disabled, grouped, sortEnabled]);

  return (
    <div className={styles.root}>
      <FullscreenContainer className={styles.container} containerRef={containerRef}>
        <NetworkDiagram
          options={options}
          nodesDataSet={nodesDataSet}
          edgesDataSet={edgesDataSet}
          onNetworkInit={networkInitHandler}
          viewMode={disabled}
        />

        {network && containerRef.current && (
          <>
            {memorizedToolsMenu}

            {!disabled && (
              <NetworkNodeWidget
                network={network}
                nodesDataSet={nodesDataSet}
                canvasContainer={containerRef.current}
                topPanelContent={
                  <Box className={styles.topPanelContent} mb={3}>
                    <Box p={1}>{renderImplementationSelect()}</Box>

                    <Divider orientation={'vertical'} flexItem={true} />

                    <Box p={1}>{renderValidationSelect()}</Box>

                    <Divider orientation={'vertical'} flexItem={true} />

                    <IconButton
                      size="small"
                      onClick={nodeRemoveHandler}
                      disabled={typeof selectedNodeRelatedId === 'undefined'}
                      className={styles.removeButton}
                    >
                      <TrashIcon />
                    </IconButton>
                  </Box>
                }
                onNodeSelect={nodeSelectHandler}
                editorBackground={colorsMap[selectedNode?.type || EbsIdeaCategory.NOT_DEFINED]}
                textareaPadding={'4px 10px'}
                widgetPadding={'28px 2px'}
                textareaFontSize={14}
                topPanelClassName={styles.topPanel}
              />
            )}
          </>
        )}
      </FullscreenContainer>
    </div>
  );
};
