import React, { useState } from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import { IdeaRecorder } from '../../../idea/idea-recorder/component';
import { TaskRecorder } from '../../../tasks/task-recorder/component';
import { Trans } from 'react-i18next';
import { useStyles } from './styles';
import { useSelector, useDispatch } from 'react-redux';
import { FeatureSetSelectors } from '../../../workspace/store/selectors/feature-set.selectors';
import { PaywallActions } from '../../modules/paywall/store';
import { FeatureLimit } from '@priz/shared/src/models/workspace';
import { ToolSelectorDialog } from '../../../tools/tool-selector-dialog/component';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm';

import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';

export interface ButtonCreateNewProps {
  projectId: number;
  text?: string | JSX.Element;
  disabled?: boolean;
}

export const ButtonCreateNew: React.FC<ButtonCreateNewProps> = ({ text, projectId, disabled }) => {
  const styles = useStyles();

  const [ideaRecorderOpen, setIdeaRecorderOpen] = useState(false);
  const [taskDialogOpen, setTaskDialogOpen] = useState(false);
  const [toolDialogOpen, setToolDialogOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const dispatch = useDispatch();
  const isIdeasLimitHit = useSelector(FeatureSetSelectors.isIdeasLimitHit(projectId));

  const openTaskDialog = () => {
    closeMenu();
    setTaskDialogOpen(true);
  };

  const closeTaskDialog = () => {
    setTaskDialogOpen(false);
  };

  const openIdeaRecorder = () => {
    closeMenu();

    if (isIdeasLimitHit) {
      dispatch(PaywallActions.show(FeatureLimit.MaxIdeas));
    } else {
      setIdeaRecorderOpen(true);
    }
  };

  const closeIdeaRecorderClose = () => {
    setIdeaRecorderOpen(false);
  };

  const openToolDialog = () => {
    closeMenu();
    setToolDialogOpen(true);
  };

  const closeToolDialog = () => {
    setToolDialogOpen(false);
  };

  const openMenu = e => {
    if (disabled) return false;
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <div className={`${styles.button}${disabled ? ' _disabled' : ''}`} onClick={openMenu}>
        <div className={styles.iconWrap}>
          <PlusIcon />
        </div>

        <Typography variant="body1" component="span" className={styles.label}>
          {text || <Trans>New</Trans>}
        </Typography>
      </div>

      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu}>
        <MenuItem onClick={openTaskDialog}>
          <Trans>Create new task</Trans>
        </MenuItem>

        <MenuItem onClick={openIdeaRecorder}>
          <Trans>Record new idea</Trans>
        </MenuItem>

        <MenuItem onClick={openToolDialog}>
          <Trans>Start new tool</Trans>
        </MenuItem>
      </Menu>

      {!disabled && (
        <>
          <IdeaRecorder isOpen={ideaRecorderOpen} projectId={projectId} onClose={closeIdeaRecorderClose} />
          <TaskRecorder isOpen={taskDialogOpen} projectId={projectId} onClose={closeTaskDialog} />
          <ToolSelectorDialog
            isOpen={toolDialogOpen}
            projectId={projectId}
            rrmGoalType={RrmGoalType.SELF}
            onClose={closeToolDialog}
          />
        </>
      )}
    </React.Fragment>
  );
};
