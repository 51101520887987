import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';

export const useStyles = makeStyles({
  root: {
    borderRadius: 0,
    color: pgColorScheme.white,
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '140%',
    textAlign: 'center',
    minWidth: 85,

    ['&._rounded']: {
      borderRadius: 4,
    },

    ['@media print']: {
      backgroundColor: 'none',
      fontWeight: 700,
      padding: 0,
      width: 'auto',
      color: pgColorScheme.darkText,
    },
  },
  DoFirst: {
    backgroundColor: pgColorScheme.green,
  },
  DoLater: {
    backgroundColor: '#FA6464',
  },
  Delegate: {
    backgroundColor: pgColorScheme.blue,
  },
  Drop: {
    backgroundColor: '#7B6BDF',
  },
  Undefined: {
    backgroundColor: pgColorScheme.gray,
  },
});
