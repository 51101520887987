import { FluxStandardAction } from 'flux-standard-action';
import { createPgAction } from '../../../shared/store/action-creator';
import { PlanService } from '../../services';
import { IPlan } from '@priz/shared/src/models/workspace';
import { PlanSelectors } from '../selectors/plan.selectors';

export enum PlanActionType {
  FetchAll = 'plan/fetchAll',
}

type PlanActionPayload = IPlan[];

export type PlanAction = FluxStandardAction<string, PlanActionPayload, undefined>;

const loadAll = createPgAction(
  PlanActionType.FetchAll,
  PlanService.getAll,
  undefined,
  undefined,
  undefined,
  PlanSelectors.isLoaded,
);

export const PlanActions = {
  loadAll,
};
