import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Divider, Grid, Link, Paper, Portal, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { validateOptions, validatorRules } from '@priz/shared/src/utils/form';
import { ReactHookFormText, FieldTitle, ReactHookFormSelectSearch, HelperText } from '../../react/form-elements';
import { LoadingButton } from '@priz/shared/src/components/loading-button/component';
import { useSelector, useDispatch } from 'react-redux';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { countriesList } from '../countries-list';
import { useStyles } from './styles';
import { Trans } from 'react-i18next';
import { CreatePaymentMethodData } from '@stripe/stripe-js';
import { CompleteWorkspaceData, Plan, PlanInterval, PlanLevel } from '@priz/shared/src/models/workspace';
import { WorkspaceActions } from '../../workspace/store/actions';
import { NotificationActions } from '../../react/modules/notification/store';
import { useQuery } from 'react-query';
import { PlanLevelUtils, WorkspaceApi } from '../../workspace/services';
import { PaymentMethod } from '@priz/shared/src/models/billing';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { SelectedBillingOptions } from '../selected-billing-options/components';
import { WorkspaceMembersSelectors } from '../../workspace/store/selectors/workspace-members.selectors';
import { Link as RouterLink } from 'react-router-dom';
import { DialogsActions } from '../../dialogs/store/actions';
import { DialogType } from '../../dialogs/store/model';

const countries = countriesList.map(c => ({ label: c.name, value: c.alpha2Code }));

const useCardOptions = () => {
  return useMemo(
    () => ({
      hidePostalCode: true,
      style: {
        base: {
          'font': 'inherit',
          'color': 'currentColor',
          'width': '100%',
          'border': 0,
          'height': '1.1876em',
          'margin': 0,
          'display': 'block',
          'padding': '6px 0 7px',
          'min-width': 0,
          'background': 'none',
          'box-sizing': 'content-box',
          'letter-spacing': 'inherit',
          'animation-duration': '0ms',
          '-webkit-tap-highlight-color': 'transparent',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: 'currentColor',
        },
      },
    }),
    [],
  );
};

interface BillingFormProps {
  plan: Plan;
  planLevel: PlanLevel;
  planInterval: PlanInterval;
  quantity: number;
  title?: string;
  onContainerRef?: (container: HTMLDivElement) => void;
  onLoadingStateChange?: (isLoading: boolean) => void;
  submitButtonText?: string;
  onChangeInterval?: (value: PlanInterval) => void;
  onChangeSeatsQuantity?: (level: PlanLevel, value: number) => void;
  onSuccessfulUpdate?: () => void;
  controlsContainer?: HTMLDivElement;
}

export const BillingForm: React.FC<BillingFormProps> = ({
  plan,
  planLevel,
  planInterval,
  quantity,
  title,
  onContainerRef,
  onLoadingStateChange,
  submitButtonText = 'Subscribe',
  onChangeInterval,
  onChangeSeatsQuantity,
  onSuccessfulUpdate,
  controlsContainer,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const options = useCardOptions();

  const formRef = useRef<HTMLFormElement>(null);

  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod | undefined>();
  const [usePaymentMethodOnFile, setUsePaymentMethodOnFile] = useState(false);
  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const [isUpdateRequested, setIsUpdateRequested] = useState(false);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);

  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const members = useSelector(WorkspaceMembersSelectors.getAll);
  const isUpdating = useSelector(WorkspaceSelectors.isUpdating);
  const isUpdated = useSelector(WorkspaceSelectors.isUpdated);
  const isFailed = useSelector(WorkspaceSelectors.isFailed);

  const { handleSubmit, control, getValues, reset } = useForm({
    mode: 'all',
    shouldFocusError: false,
    defaultValues: {
      billingEmail: currentUser?.email || '',
      billingName: '',
      coupon: '',
      address1: '',
      address2: '',
      city: '',
      country: '',
      postalCode: '',
      state: '',
    },
  });

  const { isLoading, data, isFetching } = useQuery(['default-payment-method'], WorkspaceApi.getPaymentMethod, {
    refetchOnWindowFocus: false,
  });

  const isAnyLoading = isUpdating || isLoading || isStripeLoading || (isUpdateRequested && !isSuccessMessageVisible);
  const planIsFree = plan.cost === 0;

  useEffect(() => {
    if (currentUser?.email && getValues().billingEmail !== currentUser.email) {
      reset({ billingName: currentUser.email });
    }
  }, [currentUser?.email]);

  useEffect(() => {
    if (isUpdateRequested && (isUpdated || isFailed)) {
      setIsUpdateRequested(false);

      if (isUpdated) {
        setIsSuccessMessageVisible(true);
        if (onSuccessfulUpdate) onSuccessfulUpdate();
      }
    }
  }, [isUpdateRequested, isUpdated, isFailed]);

  useEffect(() => {
    if (onLoadingStateChange) onLoadingStateChange(isLoading || isFetching);
  }, [isLoading, isFetching]);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setDefaultPaymentMethod(data);
    }
  }, [isLoading, isFetching, data]);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setUsePaymentMethodOnFile(!!defaultPaymentMethod);
    }
  }, [isLoading, isFetching, defaultPaymentMethod]);

  const handleAddAnotherCard = () => {
    setUsePaymentMethodOnFile(false);
  };

  const handleUseDefaultMethod = () => {
    setUsePaymentMethodOnFile(true);
  };

  const changeSeatsQuantityHandler = (value: number) => {
    if (onChangeSeatsQuantity) onChangeSeatsQuantity(planLevel, value);
  };

  const submitClickHandler = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  const formSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    handleSubmit(formData => {
      void submit(formData);
    })();
  };

  const submit = async data => {
    if (isAnyLoading) return null;

    setIsStripeLoading(true);

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        address: {
          city: data.city,
          country: data.country,
          line1: data.address1,
          line2: data.address2.length ? data.address2 : undefined,
          postal_code: data.postalCode,
          state: data.state.length ? data.state : undefined,
        },
        email: data.billingEmail,
        name: data.name,
      },
      metadata: {},
    } as CreatePaymentMethodData);

    if (payload.paymentMethod?.id) {
      const completeData: CompleteWorkspaceData = {
        billingEmail: data.billingEmail,
        city: data.city,
        country: data.country,
        coupon: data.coupon.length ? data.coupon : undefined,
        line1: data.address1,
        line2: data.address2.length ? data.address2 : undefined,
        name: data.name,
        paymentMethodId: payload.paymentMethod.id,
        planId: plan.id,
        postalCode: data.postalCode,
        state: data.state.length ? data.state : undefined,
        quantity: quantity,
      };

      dispatch(WorkspaceActions.updatePlan(completeData));
      setIsUpdateRequested(true);
    } else if (payload.error) {
      dispatch(NotificationActions.add(payload.error.message, 'error'));
    }

    setIsStripeLoading(false);
  };

  const handleSubscribeWithDefaultPaymentMethod = () => {
    const subscribeData: CompleteWorkspaceData = {
      planId: plan.id,
      quantity: quantity,
    };

    dispatch(WorkspaceActions.updatePlan(subscribeData));
    setIsUpdateRequested(true);
  };

  const closePlanUpgrade = () => {
    dispatch(DialogsActions.close(DialogType.PlanUpgrade));
  };

  if (isSuccessMessageVisible) {
    return (
      <>
        <Alert severity={'success'}>
          <Trans>Plan successfully updated!</Trans>
        </Alert>

        <Box mt={4} mb={2}>
          <SelectedBillingOptions plan={plan} level={planLevel} interval={planInterval} quantity={quantity} />
        </Box>
      </>
    );
  }

  if (!currentUser || isFetching || isLoading) {
    return (
      <Box py={10} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (PlanLevelUtils.isLevelPersonalOrPro(plan.level) && members.length > 1) {
    return (
      <Alert severity={'info'}>
        <Trans>{plan.name}</Trans> <Trans>plan is only allowing one member</Trans>. <Trans>Please visit</Trans>{' '}
        <Link component={RouterLink} to={'/workspace/members'} onClick={closePlanUpgrade}>
          <Trans>team page</Trans>
        </Link>{' '}
        <Trans>to remove members from this workspace</Trans>.
      </Alert>
    );
  }

  if (planIsFree) {
    return (
      <>
        <Alert severity={'info'}>
          <Trans>You have chosen Personal free plan. No payment required.</Trans>
        </Alert>

        <Portal container={controlsContainer} disablePortal={!controlsContainer}>
          <LoadingButton
            text={'Accept'}
            disabled={isAnyLoading}
            loading={isAnyLoading}
            onClick={handleSubscribeWithDefaultPaymentMethod}
          />
        </Portal>
      </>
    );
  }

  if (defaultPaymentMethod && usePaymentMethodOnFile) {
    return (
      <>
        {title && (
          <Box mb={2}>
            <Typography variant={'h6'}>
              <Trans>{title}</Trans>
            </Typography>
          </Box>
        )}

        <Box mx={'auto'} maxWidth={600} mb={3}>
          <Box mb={2}>
            <SelectedBillingOptions
              plan={plan}
              level={planLevel}
              interval={planInterval}
              quantity={quantity}
              onChangeInterval={onChangeInterval}
              onChangeSeatsQuantity={changeSeatsQuantityHandler}
            />
          </Box>

          <Paper variant={'outlined'}>
            <Box p={3} textAlign={'center'}>
              <Box>
                <Typography variant={'body2'} component={'span'}>
                  <Trans>Use payment method on file</Trans>:
                </Typography>
              </Box>

              <Box>
                <Typography variant={'body2'} component={'span'}>
                  <Trans>Card ending with</Trans> <strong>{defaultPaymentMethod.card.last4}</strong>
                </Typography>
              </Box>

              <Box my={2}>
                <Grid container alignItems={'center'}>
                  <Grid item xs>
                    <Divider />
                  </Grid>

                  <Grid item xs={'auto'}>
                    <Box px={1}>
                      <Typography variant={'caption'} component={'span'}>
                        <Trans>Or</Trans>
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs>
                    <Divider />
                  </Grid>
                </Grid>
              </Box>

              <Button variant={'pg_link'} onClick={handleAddAnotherCard}>
                <Trans>Add another card</Trans>
              </Button>
            </Box>
          </Paper>
        </Box>

        <Box textAlign={'center'}>
          <Portal container={controlsContainer} disablePortal={!controlsContainer}>
            <LoadingButton
              text={submitButtonText}
              disabled={isAnyLoading}
              loading={isAnyLoading}
              onClick={handleSubscribeWithDefaultPaymentMethod}
            />
          </Portal>
        </Box>
      </>
    );
  }

  return (
    <div
      className={styles.root}
      ref={r => {
        if (onContainerRef) onContainerRef(r);
      }}
    >
      {title && (
        <Box mb={2}>
          <Typography variant={'h6'}>
            <Trans>{title}</Trans>
          </Typography>
        </Box>
      )}

      <form ref={formRef} onSubmit={formSubmitHandler}>
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={'auto'}>
              <SelectedBillingOptions
                plan={plan}
                level={planLevel}
                interval={planInterval}
                quantity={quantity}
                onChangeInterval={onChangeInterval}
                onChangeSeatsQuantity={changeSeatsQuantityHandler}
              />
            </Grid>

            {!!defaultPaymentMethod && (
              <Grid item xs={true}>
                <Box textAlign={'right'}>
                  <Button variant={'pg_link'} onClick={handleUseDefaultMethod}>
                    <Trans>Use payment method on file</Trans>
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>

        <Box flexGrow={1}>
          <Grid container columnSpacing={3}>
            <Grid item sm={6} xs={12}>
              <ReactHookFormText
                fieldTitle={'Billing email'}
                name={'billingEmail'}
                control={control}
                rules={{
                  ...validatorRules.required(),
                  validate: {
                    ...validateOptions.hasText('Email is required'),
                  },
                }}
                wrapperProps={{ mb: 0 }}
                helperText={'Invoices will be sent to this email'}
                fieldTitleWrapperProps={{ mb: 0 }}
                size={'small'}
                className={styles.textField}
                disabled={isAnyLoading}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ReactHookFormText
                fieldTitle={'Billing/Company name'}
                helperText={'Invoices will be issued to this name'}
                name={'billingName'}
                control={control}
                rules={{
                  ...validatorRules.required(),
                  validate: {
                    ...validateOptions.hasText('Email is required'),
                  },
                }}
                wrapperProps={{ mb: 0 }}
                fieldTitleWrapperProps={{ mb: 0 }}
                size={'small'}
                className={styles.textField}
                disabled={isAnyLoading}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FieldTitle text={'Credit card'} mb={0} />
              <label className={styles.cardContainer}>
                <CardElement
                  options={{
                    ...options,
                    disabled: isAnyLoading,
                  }}
                />
              </label>
              <HelperText text={<span>&nbsp;</span>} />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ReactHookFormText
                fieldTitle={'Coupon'}
                name={'coupon'}
                control={control}
                wrapperProps={{ mb: 0 }}
                helperText={<span>&nbsp;</span>}
                fieldTitleWrapperProps={{ mb: 0 }}
                size={'small'}
                className={styles.textField}
                disabled={isAnyLoading}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ReactHookFormText
                fieldTitle={'Address 1'}
                name={'address1'}
                control={control}
                rules={{
                  ...validatorRules.required(),
                  validate: {
                    ...validateOptions.hasText('Address is required'),
                  },
                }}
                wrapperProps={{ mb: 0 }}
                helperText={<span>&nbsp;</span>}
                fieldTitleWrapperProps={{ mb: 0 }}
                size={'small'}
                className={styles.textField}
                disabled={isAnyLoading}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ReactHookFormText
                fieldTitle={'Address 2'}
                name={'address2'}
                control={control}
                wrapperProps={{ mb: 0 }}
                helperText={<span>&nbsp;</span>}
                fieldTitleWrapperProps={{ mb: 0 }}
                size={'small'}
                className={styles.textField}
                disabled={isAnyLoading}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ReactHookFormText
                fieldTitle={'City'}
                name={'city'}
                control={control}
                rules={{
                  ...validatorRules.required(),
                  validate: {
                    ...validateOptions.hasText('City is required'),
                  },
                }}
                wrapperProps={{ mb: 0 }}
                helperText={<span>&nbsp;</span>}
                fieldTitleWrapperProps={{ mb: 0 }}
                size={'small'}
                className={styles.textField}
                disabled={isAnyLoading}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ReactHookFormText
                fieldTitle={'State / Province / Region'}
                name={'state'}
                control={control}
                wrapperProps={{ mb: 0 }}
                helperText={<span>&nbsp;</span>}
                fieldTitleWrapperProps={{ mb: 0 }}
                size={'small'}
                className={styles.textField}
                disabled={isAnyLoading}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ReactHookFormSelectSearch
                fieldTitle="Country"
                name={'country'}
                control={control}
                options={countries}
                wrapperProps={{
                  mb: { xs: 1, sm: 0 },
                }}
                rules={{
                  ...validatorRules.required(),
                }}
                helperText={<span>&nbsp;</span>}
                fieldTitleWrapperProps={{ mb: 0 }}
                size={'small'}
                className={styles.selectSearchField}
                disabled={isAnyLoading}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ReactHookFormText
                fieldTitle={'Postal code'}
                name={'postalCode'}
                control={control}
                rules={{
                  ...validatorRules.required(),
                  validate: {
                    ...validateOptions.hasText('Postal code is required'),
                  },
                }}
                wrapperProps={{ mb: 0 }}
                helperText={<span>&nbsp;</span>}
                fieldTitleWrapperProps={{ mb: 0 }}
                size={'small'}
                className={styles.textField}
                disabled={isAnyLoading}
              />
            </Grid>
          </Grid>

          <Box mt={1} textAlign={'center'}>
            <Portal container={controlsContainer} disablePortal={!controlsContainer}>
              <LoadingButton
                onClick={submitClickHandler}
                text={submitButtonText}
                disabled={isAnyLoading}
                loading={isAnyLoading}
              />
            </Portal>
          </Box>
        </Box>
      </form>
    </div>
  );
};
