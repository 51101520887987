import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { useStyles } from './styles';

interface AnimatedContainerProps extends BoxProps {
  show?: boolean;
  variant?: 'slide-up';
  disableAnimation?: boolean;
}

export const AnimatedContainer: React.FC<AnimatedContainerProps> = ({
  show = true,
  variant = 'slide-up',
  disableAnimation,
  children,
  ...rest
}) => {
  const styles = useStyles();
  const { className, ...restProps } = rest;
  const rootClassNames = [];

  if (!disableAnimation && variant === 'slide-up') rootClassNames.push(styles.slideUp);

  if (className) rootClassNames.push(className);

  if (!show) {
    return null;
  }

  return (
    <Box className={rootClassNames.length ? rootClassNames.join(' ') : undefined} {...restProps}>
      {children}
    </Box>
  );
};
