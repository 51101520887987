import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Trans } from 'react-i18next';
import { SfmComponentImprovementBaseProps } from '../sfm-component-iteration/component';
import { useDispatch, useSelector } from 'react-redux';
import { SfmResultSelectors } from '../store/selectors';
import { SfmUtilizationActions } from '../store/actions';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { SfmComponentIterations } from '../sfm-component-iterations/component';
import { ErrorOutlineOutlined, VisibilityOutlined } from '@mui/icons-material';
import { ProblematicComponentPreview } from '../store/model';

interface SfmComponentHelperProps extends SfmComponentImprovementBaseProps {
  preview: ProblematicComponentPreview;
}

export const SfmComponentHelper: React.FC<SfmComponentHelperProps> = ({ preview, ...rest }) => {
  const dispatch = useDispatch();

  const { id, hasRecommendations } = preview;
  const { utilization, versionId } = rest;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const componentImprovement = useSelector(SfmResultSelectors.getComponentImprovement(utilization?.id, versionId, id));
  const isNodeLoading = useSelector(SfmResultSelectors.isNodeLoadingById(id));
  const isNodeFailedById = useSelector(SfmResultSelectors.isNodeFailedById(id));

  useEffect(() => {
    if (isDialogOpen) {
      dispatch(SfmUtilizationActions.getComponentImprovement(utilization.id, versionId, id, utilization.project?.id));
    }
  }, [isDialogOpen]);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Button
        variant={'text'}
        color={hasRecommendations ? 'error' : 'primary'}
        startIcon={hasRecommendations ? <ErrorOutlineOutlined /> : <VisibilityOutlined />}
        size={'small'}
        onClick={openDialog}
      >
        <Trans>{hasRecommendations ? 'Recommendations' : 'View changes'}</Trans>
      </Button>

      <Dialog open={isDialogOpen} onClose={closeDialog} maxWidth={'lg'} fullWidth>
        <DialogTitle>
          <Trans>Recommendations</Trans>
        </DialogTitle>

        <DialogContent>
          {!isNodeLoading && isNodeFailedById && (
            <Box py={5}>
              <Alert severity={'error'}>
                <Trans>Something went wrong</Trans>
              </Alert>
            </Box>
          )}

          {isNodeLoading && (
            <Box py={10}>
              <LoadingOverlay loading={true} backdropStyles={{ position: 'relative', background: 'transparent' }} />
            </Box>
          )}

          {!isNodeLoading && componentImprovement && (
            <SfmComponentIterations
              improvementId={componentImprovement.id}
              improvementIterationsMap={componentImprovement.iterations}
              componentId={componentImprovement.componentId}
              {...rest}
            />
          )}
        </DialogContent>

        <DialogActions sx={{ textAlign: 'right' }}>
          <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={closeDialog}>
            <Trans>Close</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
