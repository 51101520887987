import { MatrixElement } from '@priz/shared/src/models/tools/forty-principles/matrix-element';

export interface MatrixElementList {
  elements: MatrixElement[];
  loading: boolean;
  error?: any;
}

export const DefaultMatrixElementList: MatrixElementList = {
  elements: [],
  loading: false,
};
