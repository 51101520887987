import { AppState } from '../../../../../store/app.state';
import { createPgAction } from '../../../../../shared/store/action-creator';
import { FluxStandardAction } from 'flux-standard-action';
import { Dispatch } from 'redux';
import { Observable } from 'rxjs/internal/Observable';
import { BlockerName } from '../model';

export enum PaywallActionType {
  Show = 'paywall/show',
  Hide = 'paywall/hide',
}

export interface PaywallActionPayload {
  blocker: BlockerName;
}

export type PaywallAction = FluxStandardAction<PaywallActionType, PaywallActionPayload>;

const show = (
  blocker: BlockerName,
): ((dispatch: Dispatch, getState: () => AppState) => Promise<any> | Observable<any>) => {
  const action = createPgAction(
    PaywallActionType.Show,
    blocker => new Promise(resolve => resolve({ blocker })),
    blocker => ({ blocker }),
  );

  return action(blocker);
};

const hide = createPgAction(PaywallActionType.Hide, (): Promise<void> => new Promise(resolve => resolve()));

export const PaywallActions = {
  show,
  hide,
};
