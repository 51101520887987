import React from 'react';
import { Avatar, Chip, ChipProps } from '@mui/material';
import { UserUtils } from '@priz/shared/src/utils/user/user-utils';
import { WorkspaceMember } from '@priz/shared/src/models/workspace';

interface AssigneeProps extends ChipProps {
  assignee: WorkspaceMember;
  onDelete?: (assignee: WorkspaceMember) => void;
  disabled?: boolean;
}

export const Assignee: React.FC<AssigneeProps> = ({ assignee, onDelete, disabled, ...rest }) => {
  const handleDelete = () => {
    if (disabled) return null;
    onDelete(assignee);
  };

  return (
    <Chip
      avatar={<Avatar src={assignee.profile.avatarUrl}>{UserUtils.resolveInitials(assignee)}</Avatar>}
      label={UserUtils.getShowableName(assignee)}
      onDelete={onDelete ? handleDelete : undefined}
      variant="outlined"
      disabled={disabled}
      {...rest}
    />
  );
};
