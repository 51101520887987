import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  label: {},
  textarea: {
    font: 'inherit',
    letterSpacing: 'inherit',
    color: 'inherit',
    minWidth: 1,
    maxWidth: '100%',
    padding: 0,
  },
  initialText: {
    opacity: 0,
    whiteSpace: 'inherit',
    wordBreak: 'inherit',
  },
});
