import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { Box, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { ButtonCreateTool } from '../button-create-tool/component';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { ToolSelectorDialog } from '../tool-selector-dialog/component';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm';
import { useLocation } from 'react-router-dom';

interface EmptyToolsListProps {
  projectId: number;
  checkHash?: boolean;
}

export const EmptyToolsList: React.FC<EmptyToolsListProps> = ({ projectId, checkHash }) => {
  const styles = useStyles();
  const { hash } = useLocation();

  const [isToolsSelectorDialogOpen, setIsToolsSelectorDialogOpen] = useState(false);

  useEffect(() => {
    if (checkHash && hash === '#create') {
      openToolsSelectorDialog();
    }
  }, [checkHash, hash]);

  const openToolsSelectorDialog = () => {
    setIsToolsSelectorDialogOpen(true);
  };

  const closeToolsSelectorDialog = () => {
    setIsToolsSelectorDialogOpen(false);
  };

  return (
    <>
      <Box className={styles.root}>
        <Box mt={8} className={styles.title}>
          <Typography variant={'subtitle1'} component={'span'}>
            <Trans>You still didn't use any tools in this project.</Trans>
            <br />
            <Trans>Click “+ NEW” to start using tools.</Trans>
          </Typography>
        </Box>

        <Box mt={4}>
          <ButtonsWrap justifyContent={'center'}>
            <ButtonCreateTool projectId={projectId} startIcon={<div>+</div>} />
          </ButtonsWrap>
        </Box>
      </Box>

      <ToolSelectorDialog
        projectId={projectId}
        isOpen={isToolsSelectorDialogOpen}
        onClose={closeToolsSelectorDialog}
        rrmGoalType={RrmGoalType.SELF}
      />
    </>
  );
};
