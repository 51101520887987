import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  title: {
    paddingBottom: 0,
  },
  actions: {
    textAlign: 'right',
  },
});
