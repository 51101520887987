import React from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { User } from '@priz/shared/src/models/security/user';
import { UpdateUserRestrictionsCommand } from '../api/users-administration.api';
import { Trans } from 'react-i18next';

interface UserRestrictionsProps {
  user: User;
  onUpdate: (props: UpdateUserRestrictionsCommand) => void;
  disabled?: boolean;
}

export const UserRestrictions: React.FC<UserRestrictionsProps> = ({ user, onUpdate, disabled }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const command: UpdateUserRestrictionsCommand = {
      [e.target.name]: e.target.checked,
    };

    onUpdate(command);
  };

  return (
    <FormGroup onChange={handleChange}>
      <FormControlLabel
        name={'nonChargeable'}
        control={<Switch />}
        label={<Trans>Non chargeable</Trans>}
        checked={user.nonChargeable}
        disabled={disabled}
      />
    </FormGroup>
  );
};
