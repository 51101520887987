import axios, { AxiosResponse } from 'axios';
import { User } from '../../models/security/user';
import { AxiosRequestParams } from '../axios';

const login = (renewTokenErrorCallback?: () => void): Promise<User> => {
  const axiosParams: AxiosRequestParams = {
    params: {
      renewTokenErrorCallback,
    },
  };

  return axios.post(`/v1/login`, null, axiosParams).then((response: AxiosResponse<User>) => response.data);
};

const signUp = (renewTokenErrorCallback?: () => void, referralCode?: string): Promise<User> => {
  const axiosParams: AxiosRequestParams = {
    params: {
      renewTokenErrorCallback,
    },
  };

  return axios
    .post(`/v1/signup${referralCode ? '?ref=' + referralCode : ''}`, null, axiosParams)
    .then((response: AxiosResponse<User>) => response.data);
};

export const UserService = {
  login,
  signUp,
};
