import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    img: {
      width: 330,
      height: 'auto',
    },
    title: {
      textAlign: 'center',
    },
    [theme.breakpoints.down('md')]: {
      img: {
        width: 240,
      },
    },
    [theme.breakpoints.down('sm')]: {
      img: {
        width: 200,
      },
    },
  }),
);
