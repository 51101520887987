import { RouterActionMeta } from '../../../shared/store/types';
import { FluxStandardAction } from 'flux-standard-action';
import { PMapUtilization, IPMapUtilization, CalcResult } from '@priz/shared/src/models/tools/pmap';
import { createPgAction } from '../../../shared/store/action-creator';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { ToolType } from '@priz/shared/src/models/tools';
import { PMapUtilizationApi } from '../../service';
import { NavigateFunction } from 'react-router-dom';
import { ToolsUtils } from '../../../tools/utils';

export enum PMapUtilizationActionType {
  Update = 'p-map-utilization/update',
  Delete = 'p-map-utilization/delete',
  UpdateAccessLevel = 'p-map-utilization/update/access-level',
  ResolveContributedPerception = 'p-map-utilization/contributed-perception/resolve',
  GetResult = 'p-map-utilization/result',
}

type PMapUtilizationActionPayload = IPMapUtilization | CalcResult;

interface PMapUtilizationActionMeta extends RouterActionMeta {
  utilization?: IPMapUtilization;
  utilizationId?: number;
  projectId?: number;
  navigate?: NavigateFunction;
}

export type PMapUtilizationAction = FluxStandardAction<string, PMapUtilizationActionPayload, PMapUtilizationActionMeta>;

const update = createPgAction(
  PMapUtilizationActionType.Update,
  PMapUtilizationApi.update,
  (utilization: PMapUtilization) => ({ utilization }),
  (_payload, { utilization }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: utilization.project?.id,
      tool_type: ToolType.P_MAP,
      utilization_id: utilization.id,
    });
  },
);

const doDelete = createPgAction(
  PMapUtilizationActionType.Delete,
  PMapUtilizationApi.delete,
  (utilizationId: number, projectId?: number, navigate?: NavigateFunction) => ({ projectId, utilizationId, navigate }),
  (_payload, { projectId, utilizationId, navigate }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_DELETED, {
      project_id: projectId,
      tool_type: ToolType.P_MAP,
      utilization_id: utilizationId,
    });

    if (navigate) {
      navigate(ToolsUtils.resolveListPath(projectId));
    }
  },
);

const updatePublicAccessLevel = createPgAction(
  PMapUtilizationActionType.UpdateAccessLevel,
  PMapUtilizationApi.updatePublicAccessLevel,
  (utilizationId: number, _command, projectId?: number) => ({ projectId, utilizationId }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.P_MAP,
      utilization_id: utilizationId,
    });
  },
);

const resolveContributedPerception = createPgAction(
  PMapUtilizationActionType.ResolveContributedPerception,
  PMapUtilizationApi.resolveContributedPerception,
  (utilizationId: number, _command, projectId?: number) => ({ projectId, utilizationId }),
);

const getResult = createPgAction(
  PMapUtilizationActionType.GetResult,
  PMapUtilizationApi.getResult,
  (utilizationId: number) => ({ utilizationId }),
);

export const PMapUtilizationActions = {
  update,
  delete: doDelete,
  getResult,
  updatePublicAccessLevel,
  resolveContributedPerception,
};
