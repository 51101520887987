import React, { useEffect } from 'react';
import { PageTitleWithDocLink } from '../../../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import { TemplateSpider } from '../template-spider/component';
import { useTemplateCompletion } from '../utils';
import { useSelector } from 'react-redux';
import { ProjectSelector } from '../../store/selectors';
import { useNavigate } from 'react-router-dom';
import { ContentContainer } from '../../../content-containers/page-container-with-aside-nav/content-container/component';

interface TemplatePageProps {
  projectId: number;
}

export const TemplatePage: React.FC<TemplatePageProps> = ({ projectId }) => {
  const navigate = useNavigate();
  const templateCompletion = useTemplateCompletion(projectId);

  const project = useSelector(ProjectSelector.getById(projectId));
  const isProjectLoaded = useSelector(ProjectSelector.isProjectLoadedById(projectId));

  useEffect(() => {
    if (isProjectLoaded && project && !project?.template) {
      navigate(['/project', projectId, 'overview'].join('/'));
    }
  }, [isProjectLoaded, project]);

  if (!isProjectLoaded || !project?.template) return null;

  return (
    <ContentContainer>
      <PageTitleWithDocLink
        title={<Trans>Project template</Trans>}
        oneLiner={<Trans>Templates</Trans>}
        docsUrl="https://www.priz.guru/knowledge-base/templates"
      />

      {project?.template && (
        <TemplateSpider
          projectId={project.id}
          type={project.template}
          templateCompletion={templateCompletion}
          interactive={true}
        />
      )}
    </ContentContainer>
  );
};
