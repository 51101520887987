import { FiveWhysCause } from '../../models/tools/five-whys';

export const sortByChain = (causes: FiveWhysCause[]): FiveWhysCause[] => {
  if (causes.length === 0) {
    return [];
  }

  const sorted = causes.filter(cause => typeof cause?.causing?.id === 'undefined');

  let nextCause = causes.find(
    c => sorted[sorted.length - 1] && c.causing && c.causing.id === sorted[sorted.length - 1]?.id,
  );

  while (nextCause) {
    sorted.push(nextCause);
    nextCause = causes.find(
      c => sorted[sorted.length - 1] && c.causing && c.causing.id === sorted[sorted.length - 1]?.id,
    );
  }

  if (causes.length !== sorted.length) {
    throw new Error('lost causes on sort');
  }

  return sorted;
};
