import { pgColorScheme } from '../theme';
import { SfmEdgeType } from '../models/tools/sfm';
import { filledArrowsMap } from '../components/network-diagram/images';
import { EdgeProps, NodeProps } from '../models/vis-network';
import { SfmNodeType } from '../models/tools/sfm';
import { Options } from '../lib/vis/esnext';

const useArrowsMap = filledArrowsMap;

const defaultArrowWidth = 10;
const defaultArrowHeight = 14;

const boldArrowWidth = 14;
const boldArrowHeight = 14;

const arrowOptionsMap = {
  [SfmEdgeType.USEFUL]: {
    color: pgColorScheme.blue,
    lineWidth: 1,
    imageSrc: useArrowsMap.blue,
    imageWidth: defaultArrowWidth,
    imageHeight: defaultArrowHeight,
    hoveredSrc: useArrowsMap.dark,
    dashes: false,
  },
  [SfmEdgeType.HARMFUL]: {
    color: pgColorScheme.coral,
    lineWidth: 1,
    imageSrc: useArrowsMap.coral,
    imageWidth: defaultArrowWidth,
    imageHeight: defaultArrowHeight,
    hoveredSrc: useArrowsMap.dark,
    dashes: false,
  },
  [SfmEdgeType.EXCESSIVE]: {
    color: pgColorScheme.blue,
    lineWidth: 3,
    imageSrc: useArrowsMap.blueBold,
    imageWidth: boldArrowWidth,
    imageHeight: boldArrowHeight,
    hoveredSrc: useArrowsMap.darkBold,
    dashes: false,
  },
  [SfmEdgeType.INSUFFICIENT]: {
    color: pgColorScheme.blue,
    lineWidth: 1,
    imageSrc: useArrowsMap.blue,
    imageWidth: defaultArrowWidth,
    imageHeight: defaultArrowHeight,
    hoveredSrc: useArrowsMap.dark,
    dashes: true,
  },
};

const nodeOptionsMap = {
  [SfmNodeType.PRODUCT]: {
    shape: 'ellipse',
    borderRadius: 0,
    borderColor: pgColorScheme.orange,
    backgroundColor: pgColorScheme.orange,
    selectedBorderColor: pgColorScheme.orange,
    hoveredBorderColor: pgColorScheme.darkText,
    hoveredBackgroundColor: pgColorScheme.white,
    selectedBackgroundColor: pgColorScheme.white,
  },
  [SfmNodeType.COMPONENT]: {
    shape: 'box',
    borderRadius: 30,
    borderColor: pgColorScheme.blue,
    backgroundColor: pgColorScheme.white,
    hoveredBorderColor: pgColorScheme.darkText,
    selectedBorderColor: pgColorScheme.blue,
    hoveredBackgroundColor: pgColorScheme.white,
    selectedBackgroundColor: pgColorScheme.white,
  },
  [SfmNodeType.SUPER_SYSTEM]: {
    shape: 'box',
    borderRadius: 10,
    borderColor: pgColorScheme.blueLight2,
    backgroundColor: pgColorScheme.blueLight2,
    hoveredBorderColor: pgColorScheme.darkText,
    selectedBorderColor: pgColorScheme.blue,
    hoveredBackgroundColor: pgColorScheme.lightBlue,
    selectedBackgroundColor: pgColorScheme.lightBlue,
  },
};

export const nodePropsDecorator = (node: Partial<NodeProps>): Partial<NodeProps> => {
  const nodeType: SfmNodeType = node.type as SfmNodeType;
  const props = nodeType ? nodeOptionsMap[nodeType] : null;

  if (props) {
    const {
      shape,
      borderRadius,
      borderColor,
      backgroundColor,
      hoveredBorderColor,
      selectedBorderColor,
      hoveredBackgroundColor,
      selectedBackgroundColor,
    } = props;

    return {
      ...node,
      shape,
      shapeProperties: {
        borderRadius: borderRadius,
      },
      color: {
        border: borderColor,
        background: backgroundColor,
        highlight: {
          border: pgColorScheme.orange,
          background: pgColorScheme.white,
        },
        hover: {
          border: pgColorScheme.darkText,
          background: pgColorScheme.white,
        },
      },

      margin: {
        top: 10,
        right: 20,
        bottom: 10,
        left: 20,
      },

      // @ts-ignore
      chosen: {
        node: chosenNode(hoveredBorderColor, selectedBorderColor, hoveredBackgroundColor, selectedBackgroundColor),
      },
    };
  } else {
    return node;
  }
};

const chosenNode = (
  hoveredBorderColor: string,
  selectedBorderColor: string,
  hoveredBackgroundColor: string,
  selectedBackgroundColor: string,
) => {
  //TODO: types
  //@ts-ignore
  return (values, _id, selected, hovering) => {
    if (hovering && !selected) {
      values.borderColor = hoveredBorderColor;
      values.color = hoveredBackgroundColor;
    }
    if (selected) {
      values.borderColor = selectedBorderColor;
      values.borderDashes = [5, 5];
      values.color = selectedBackgroundColor;
    }
  };
};

const chosenEdge = (hoveredSrc?: string) => {
  //TODO: types
  //@ts-ignore
  return (values, _id, selected, hovering) => {
    if (hovering) {
      values.color = pgColorScheme.darkText;
      if (hoveredSrc) values.toArrowSrc = hoveredSrc;
    }
    if (selected) {
      values.backgroundColor = 'rgba(0,0,0,.1)';
    }
  };
};

export const edgePropsDecorator = (edge: Partial<EdgeProps>): Partial<EdgeProps> => {
  const edgeType: SfmEdgeType = edge.type as SfmEdgeType;
  const props = edgeType ? arrowOptionsMap[edgeType] : null;

  if (props) {
    const { color, lineWidth, imageSrc, imageWidth, imageHeight, hoveredSrc, dashes = false } = props;

    return {
      ...edge,

      arrows: {
        to: {
          enabled: true,
          type: 'image',
          src: imageSrc,
          imageWidth,
          imageHeight,
        },
        from: false,
      },
      chosen: {
        // @ts-ignore
        edge: chosenEdge(hoveredSrc),
      },
      color: {
        color: color,
      },
      width: lineWidth,
      dashes: dashes,
    };
  } else {
    return edge;
  }
};

export const options: Options = {
  manipulation: {
    enabled: false,
  },
  edges: {
    physics: true,
    label: '',
    font: {
      align: 'middle',
      face: '"Roboto", sans-serif',
    },
    background: {
      enabled: true,
      color: 'rgba(0,0,0,0)',
      size: 20,
    },
    arrows: {
      to: {
        enabled: true,
        type: 'image',
        imageWidth: defaultArrowWidth,
        imageHeight: defaultArrowHeight,
        src: useArrowsMap.dark,
      },
    },
    smooth: {
      enabled: true,
      type: 'dynamic',
      roundness: 1,
    },
    endPointOffset: {
      from: 0,
      to: -1,
    },
    arrowStrikethrough: true,
    chosen: {
      // @ts-ignore
      edge: chosenEdge(),
    },
    color: {
      color: pgColorScheme.blue,
      highlight: pgColorScheme.orange,
      hover: pgColorScheme.darkText,
      inherit: 'from',
      opacity: 1.0,
    },
    dashes: false,
    hidden: false,
    hoverWidth: 0,
    labelHighlightBold: false,
    selectionWidth: 0,

    scaling: {
      label: {
        drawThreshold: 0,
      },
    },
  },

  interaction: {
    hover: true,
    selectConnectedEdges: false,
  },
  layout: {
    improvedLayout: false,
    hierarchical: false,
  },
  physics: {
    enabled: true,

    // repulsion: {
    //   centralGravity: 1,
    //   springLength: 300,
    //   springConstant: 0.05,
    //   nodeDistance: 200,
    //   damping: 0.5,
    // },

    barnesHut: {
      theta: 0.5,
      gravitationalConstant: -2000,
      centralGravity: 0.3,
      springLength: 200,
      springConstant: 0.05,
      damping: 0.5,
      avoidOverlap: 1,
    },

    // forceAtlas2Based: {
    //   theta: 0.5,
    //   gravitationalConstant: -50,
    //   centralGravity: 0.01,
    //   springConstant: 0.05,
    //   springLength: 100,
    //   damping: 0.5,
    //   avoidOverlap: 1,
    // },

    stabilization: {
      enabled: false,
      iterations: 3000,
      updateInterval: 100,
      onlyDynamicEdges: false,
      fit: true,
    },

    timestep: 0.5,
    adaptiveTimestep: true,

    // solver: 'repulsion',
    solver: 'barnesHut',
    // solver: 'forceAtlas2Based',
  },
  nodes: {
    physics: false,
    shadow: {
      enabled: false,
      size: 0,
    },
    borderWidth: 1,
    borderWidthSelected: 0,
    labelHighlightBold: false,

    color: {
      border: pgColorScheme.blue,
      background: pgColorScheme.white,
      highlight: {
        border: pgColorScheme.blue,
        background: pgColorScheme.white,
      },
      hover: {
        border: pgColorScheme.blue,
        background: pgColorScheme.white,
      },
    },

    opacity: 1,
    font: {
      color: '#222',
      size: 18,
      background: 'none',
      align: 'center',
      multi: 'html',
      face: '"Roboto", sans-serif',
    },
    widthConstraint: {
      minimum: 100,
      maximum: 400,
    },
    // @ts-ignore
    heightConstraint: {
      minimum: 40,
    },

    scaling: {
      label: {
        drawThreshold: 0,
      },
    },
  },
};
