import { combineActions, handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { toObjectMap } from '../../../shared/store/reducer-utils';
import { ArrayUtil } from '../../../shared/utils/array-util';
import { TaskAction, TaskActionType } from '../actions/task.actions';
import {
  DefaultTaskCollection,
  ITask,
  Task,
  TaskCollection,
  TaskCollectionStatuses,
} from '@priz/shared/src/models/task';
import { copyObject } from '@priz/shared/src/utils/common';
import { CollectionStatusesService } from '@priz/shared/src/services/statuses/collection-statuses.service';

const resolveStatuses = (
  projectId: number,
  state: TaskCollection,
  statuses: Partial<EntityCollectionStatus>,
): TaskCollectionStatuses => {
  const statusesCopy = copyObject(state.statuses);

  statusesCopy.byProjectId[projectId] = { ...(statusesCopy.byProjectId[projectId] || {}), ...statuses };

  return statusesCopy;
};

const setTaskCollectionStatuses = (
  state: TaskCollection,
  projectId: number,
  statuses: Partial<EntityCollectionStatus>,
): TaskCollection => {
  return {
    ...state,
    statuses: resolveStatuses(projectId, state, statuses),
  };
};

const taskInitializer = (taskData: ITask): Task => new Task(taskData);

const setAllTasks = (state: TaskCollection, projectId: number, tasksPayload: ITask[]): TaskCollection => {
  const newTaskMap = toObjectMap<Task>(tasksPayload, taskInitializer);

  const entitiesCopy = {
    ...state.entities,
    ...newTaskMap,
  };

  const lookupsCopy = copyObject(state.lookups);
  Object.values(newTaskMap).forEach((task: Task) => {
    lookupsCopy.byProjectId[projectId] = ArrayUtil.concatInDistinctArray(lookupsCopy.byProjectId[projectId], task.id);
  });

  return {
    ...state,
    statuses: resolveStatuses(projectId, state, { error: false, loaded: true, loading: false }),
    entities: entitiesCopy,
    lookups: lookupsCopy,
  };
};

const setTask = (
  state: TaskCollection,
  projectId: number,
  taskPayload: ITask,
  statuses?: Partial<EntityCollectionStatus>,
): TaskCollection => {
  const newTask = taskInitializer(taskPayload);

  const entitiesCopy = {
    ...state.entities,
    [newTask.id]: newTask,
  };

  const lookupsCopy = copyObject(state.lookups);
  lookupsCopy.byProjectId[projectId] = ArrayUtil.concatInDistinctArray(lookupsCopy.byProjectId[projectId], newTask.id);

  return {
    ...state,
    statuses: resolveStatuses(projectId, state, { error: false, loaded: true, loading: false, ...(statuses || {}) }),
    entities: entitiesCopy,
    lookups: lookupsCopy,
  };
};

const removeTask = (
  state: TaskCollection,
  projectId: number,
  taskId: number,
  statuses: EntityCollectionStatus,
): TaskCollection => {
  const entitiesCopy = { ...state.entities };
  delete entitiesCopy[taskId];

  const lookupsCopy = copyObject(state.lookups);
  lookupsCopy.byProjectId[projectId] = lookupsCopy.byProjectId[projectId].filter(id => id !== taskId);

  return {
    ...state,
    statuses: resolveStatuses(projectId, state, statuses),
    entities: entitiesCopy,
    lookups: lookupsCopy,
  };
};

export const taskReducers = handleActions(
  {
    // Fetch

    [combineActions(toStarted(TaskActionType.Tasks_Fetch), toStarted(TaskActionType.Task_Fetch))]: (
      state: TaskCollection,
      action: TaskAction,
    ) =>
      setTaskCollectionStatuses(state, action.meta.projectId, {
        loading: true,
      }),

    [combineActions(toFailed(TaskActionType.Tasks_Fetch), toFailed(TaskActionType.Task_Fetch))]: (
      state: TaskCollection,
      action: TaskAction,
    ) =>
      setTaskCollectionStatuses(state, action.meta.projectId, {
        loading: false,
        loaded: false,
        error: true,
      }),

    [toSuccess(TaskActionType.Task_Fetch)]: (state: TaskCollection, action: TaskAction) =>
      setTask(state, action.meta.projectId, action.payload as ITask),

    [toSuccess(TaskActionType.Tasks_Fetch)]: (state: TaskCollection, action: TaskAction) =>
      setAllTasks(state, action.meta.projectId, action.payload as ITask[]),

    // Update

    [combineActions(
      toStarted(TaskActionType.Task_Update),
      toStarted(TaskActionType.Task_Update_Status),
      toStarted(TaskActionType.Task_Update_DueDate),
    )]: (state: TaskCollection, action: TaskAction) =>
      setTaskCollectionStatuses(
        state,
        action.meta.projectId,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses.byProjectId[action.meta.projectId],
          updating: true,
          updated: false,
          idsUpdate: [{ target: 'updatingIds', method: 'add', ids: [action.meta.taskId] }],
        }),
      ),

    [combineActions(
      toFailed(TaskActionType.Task_Update),
      toFailed(TaskActionType.Task_Update_Status),
      toFailed(TaskActionType.Task_Update_DueDate),
    )]: (state: TaskCollection, action: TaskAction) =>
      setTaskCollectionStatuses(
        state,
        action.meta.projectId,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses.byProjectId[action.meta.projectId],
          updating: false,
          updated: false,
          error: true,
          idsUpdate: [{ target: 'updatingIds', method: 'remove', ids: [action.meta.taskId] }],
        }),
      ),

    [combineActions(
      toSuccess(TaskActionType.Task_Update),
      toSuccess(TaskActionType.Task_Update_Status),
      toSuccess(TaskActionType.Task_Update_DueDate),
    )]: (state: TaskCollection, action: TaskAction) =>
      setTask(
        state,
        action.meta.projectId,
        action.payload as ITask,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses.byProjectId[action.meta.projectId],
          updated: true,
          updating: false,
          error: false,
          idsUpdate: [{ target: 'updatingIds', method: 'remove', ids: [action.meta.taskId] }],
        }),
      ),

    // Create

    [toStarted(TaskActionType.Task_Create)]: (state: TaskCollection, action: TaskAction) =>
      setTaskCollectionStatuses(state, action.meta.projectId, {
        creating: true,
        created: false,
      }),

    [toFailed(TaskActionType.Task_Create)]: (state: TaskCollection, action: TaskAction) =>
      setTaskCollectionStatuses(state, action.meta.projectId, {
        creating: false,
        created: false,
        error: true,
      }),

    [toSuccess(TaskActionType.Task_Create)]: (state: TaskCollection, action: TaskAction) =>
      setTask(state, action.meta.projectId, action.payload as ITask, {
        creating: false,
        created: true,
        error: false,
      }),

    // Delete

    [toStarted(TaskActionType.Task_Delete)]: (state: TaskCollection, action: TaskAction) =>
      setTaskCollectionStatuses(
        state,
        action.meta.projectId,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses.byProjectId[action.meta.projectId],
          removing: true,
          removed: false,
          idsUpdate: [{ target: 'removingIds', method: 'add', ids: [action.meta.taskId] }],
        }),
      ),

    [toFailed(TaskActionType.Task_Delete)]: (state: TaskCollection, action: TaskAction) =>
      setTaskCollectionStatuses(
        state,
        action.meta.projectId,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses.byProjectId[action.meta.projectId],
          removing: false,
          removed: false,
          error: true,
          idsUpdate: [{ target: 'removingIds', method: 'remove', ids: [action.meta.taskId] }],
        }),
      ),

    [toSuccess(TaskActionType.Task_Delete)]: (state: TaskCollection, action: TaskAction) =>
      removeTask(
        state,
        action.meta.task.project.id,
        action.meta.task.id,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses.byProjectId[action.meta.projectId],
          removing: false,
          removed: true,
          error: true,
          idsUpdate: [
            { target: 'removingIds', method: 'remove', ids: [action.meta.taskId] },
            { target: 'removedIds', method: 'add', ids: [action.meta.taskId] },
          ],
        }),
      ),
  },
  DefaultTaskCollection,
);
