import { ClassNameMap, makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { HamburgerStylesProps } from './Hamburger';

export const useStyles = (props: HamburgerStylesProps = {}): ClassNameMap => {
  const parameters: HamburgerStylesProps = {
    barColor: pgColorScheme.blue,
    barColorHover: pgColorScheme.darkGray,
    barColorActive: pgColorScheme.white,
    barColorActiveHover: pgColorScheme.blue,
    barHeight: 3,
    barSpacing: 8,
    barWidth: 30,
    ...props,
  };

  return makeStyles({
    root: {
      'position': 'relative',
      'cursor': 'pointer',

      '&:hover $bar': {
        '&::before': {
          background: parameters.barColorHover,
        },
      },

      '&._active:hover $bar': {
        '&::before': {
          background: parameters.barColorActiveHover,
        },
      },

      '&._active $bar': {
        'transition': 'transform 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 0s 300ms linear',

        '&::before': {
          transition: 'transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), background 300ms ease',
          background: parameters.barColorActive,
        },

        '&:nth-child(1)': {
          'transform': `translateY(${parameters.barHeight + parameters.barSpacing}px)`,

          '&::before': {
            transform: 'rotate(45deg)',
          },
        },

        '&:nth-child(2)': {
          opacity: 0,
        },

        '&:nth-child(3)': {
          'transform': `translateY(-${parameters.barHeight + parameters.barSpacing}px)`,

          '&::before': {
            transform: 'rotate(-45deg)',
          },
        },
      },
    },

    barsContainer: {
      height: parameters.barHeight * 3 + parameters.barSpacing * 2,
      width: parameters.barWidth,
      position: 'relative',
      alignItems: 'center',
    },

    bar: {
      'position': 'absolute',
      'left': 0,
      'margin': 'auto',
      'width': parameters.barWidth,
      'height': parameters.barHeight,
      'willChange': 'transform',
      'transformOrigin': '50% 50%',
      'transition': 'transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 0s 300ms linear',

      '&::before': {
        content: '""',
        display: 'block',
        position: 'relative',
        width: '100%',
        height: '100%',
        background: parameters.barColor,
        transition: 'transform 300ms cubic-bezier(0.23, 1, 0.32, 1), background 300ms ease',
      },

      '&:nth-child(1)': {
        top: 0,
        right: 'auto',
        bottom: 'auto',
      },

      '&:nth-child(2)': {
        top: 0,
        right: 'auto',
        bottom: 0,
      },

      '&:nth-child(3)': {
        top: 'auto',
        right: 'auto',
        bottom: 0,
      },
    },
  })();
};
