import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ReactHookFormSelect, ReactHookFormText } from '../../react/form-elements';
import { validatorRules } from '@priz/shared/src/utils/form';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { CreatePartnerProfileCommand, UserPartnerProfileUpdateCommand } from '../api/users-administration.api';
import { PartnerProfile, PartnerProfileStatus } from '@priz/shared/src/models/security/partner-profile';

export interface PartnerProfileDialogSharedProps {
  partnerProfile?: PartnerProfile;
  onCreate?: (props: CreatePartnerProfileCommand) => void;
  onUpdate?: (props: UserPartnerProfileUpdateCommand) => void;
  loading?: boolean;
}

interface PartnerProfileDialogProps extends PartnerProfileDialogSharedProps {
  open?: boolean;
  onClose?: () => void;
}

interface FormValues {
  commissionRate: number;
  status: PartnerProfileStatus;
}

const resolveDefaultProps = (partnerProfile: PartnerProfile): FormValues => {
  return {
    commissionRate: partnerProfile?.commissionRate || 0,
    status: partnerProfile?.status || PartnerProfileStatus.Active,
  };
};

export const PartnerProfileDialog: React.FC<PartnerProfileDialogProps> = ({
  open,
  onClose,
  partnerProfile,
  onCreate,
  onUpdate,
  loading,
}) => {
  const { handleSubmit, control, reset } = useForm({
    shouldFocusError: false,
    defaultValues: resolveDefaultProps(partnerProfile),
  });

  useEffect(() => {
    if (open) {
      reset(resolveDefaultProps(partnerProfile));
    }
  }, [open]);

  const submitHandler = () => {
    handleSubmit(formData => {
      if (onCreate) {
        onCreate({ commissionRate: +formData.commissionRate });
      }

      if (onUpdate) {
        const command: UserPartnerProfileUpdateCommand = {};

        if (partnerProfile.commissionRate !== +formData.commissionRate) {
          command.commissionRate = +formData.commissionRate;
        }

        if (partnerProfile.status !== formData.status) {
          command.status = formData.status;
        }

        onUpdate(command);
      }

      if (onClose) {
        onClose();
      }
    })();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>
        {!!onCreate && 'Create partner profile'}
        {!!onUpdate && 'Edit partner profile'}
      </DialogTitle>

      <DialogContent>
        <ReactHookFormText
          control={control}
          name={'commissionRate'}
          fieldTitle={'Commission rate %'}
          type={'number'}
          rules={{
            ...validatorRules.required(),
            min: { value: 0, message: 'Min 0%' },
            max: { value: 100, message: 'Max 100%' },
          }}
          inputProps={{ min: 0, max: 100, step: 0.1 }}
        />

        {onUpdate && (
          <ReactHookFormSelect
            control={control}
            name={'status'}
            fieldTitle={'Status'}
            options={[PartnerProfileStatus.Active, PartnerProfileStatus.Disabled].map(status => ({
              value: status,
              text: status,
            }))}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={onClose}>
          Cancel
        </Button>

        <LoadingButton
          variant={'pg_rounded'}
          color={'pg_orange_solid'}
          onClick={submitHandler}
          disabled={loading}
          loading={loading}
        >
          {!!onCreate && 'Create'}
          {!!onUpdate && 'Update'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
