import { pgColorScheme } from '../../theme';

export const drawPlaceholder = (
  ctx: CanvasRenderingContext2D,
  text: string,
  left: number,
  top: number,
  width: number,
  height: number,
) => {
  const fontSize = 18;

  ctx.beginPath();
  ctx.font = `400 ${fontSize}px roboto`;
  ctx.fillStyle = pgColorScheme.placeholder;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillText(text, left + width / 2, top + height / 2);
  ctx.fill();
};
