import React, { useState } from 'react';
import { ToolUtilization } from '@priz/shared/src/models/tools';
import { Box, Collapse, Grid, IconButton, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { ToolTitle } from '../tool-title/component';
import { format } from 'date-fns';
import { ExpandMoreRounded, ExpandLessRounded } from '@mui/icons-material';
import { ToolIcon } from '../tool-icon/component';
import { LinkCard } from '../../link-card/component';
import { ToolsUtils } from '../utils';
import { RowToolCardCompact } from '../row-tool-card-compact/component';
import { useStyles } from './styles';

interface ToolCardProps {
  tool: ToolUtilization;
  childrenToolsMap?: { [parentId: number]: ToolUtilization[] };
  disabled?: boolean;
  root?: boolean;
}

export const RowToolCard: React.FC<ToolCardProps> = ({ tool, childrenToolsMap, root, disabled }) => {
  const styles = useStyles();
  const childrenTools = childrenToolsMap?.[tool.id] || [];

  const [showChildren, setShowChildren] = useState(false);

  const toggleShowChildren = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setShowChildren(currentState => !currentState);
  };

  return (
    <>
      {root && (
        <LinkCard to={ToolsUtils.resolvePathByUtilization(tool)}>
          <Box p={2}>
            <Grid container alignItems={'center'} flexWrap={'nowrap'} spacing={2}>
              <Grid item xs={'auto'}>
                <ToolIcon tool={tool} size={'large'} />
              </Grid>

              <Grid item xs={true} overflow={'hidden'}>
                <ToolTitle tool={tool} editable={!disabled} disableIcon />

                <Typography component={Box} variant={'caption'} color={'text_color.light'}>
                  <Trans>Used at</Trans>: {format(tool.dateCreated, 'MMM d yyyy h:mm:ss aaa')}
                </Typography>
              </Grid>

              {!!childrenTools.length && (
                <Grid item xs={'auto'}>
                  <IconButton color={'primary'} onClick={toggleShowChildren}>
                    {showChildren ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Box>
        </LinkCard>
      )}

      {!root && (
        <RowToolCardCompact
          tool={tool}
          controls={
            !!childrenTools.length && (
              <IconButton size={'small'} color={'primary'} onClick={toggleShowChildren}>
                {showChildren ? <ExpandLessRounded fontSize={'small'} /> : <ExpandMoreRounded fontSize={'small'} />}
              </IconButton>
            )
          }
        />
      )}

      {!!childrenTools.length && (
        <Collapse in={showChildren}>
          <Box className={styles.childToolsContainer} pl={3}>
            {childrenTools.map(childrenTool => (
              <Box key={childrenTool.id} mt={1}>
                <RowToolCard tool={childrenTool} childrenToolsMap={childrenToolsMap} />
              </Box>
            ))}
          </Box>
        </Collapse>
      )}
    </>
  );
};
