import { v1 as uuidv1 } from 'uuid';

export enum PMapContributedPerceptionStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

export interface IPMapContributedPerception {
  id?: string;
  status?: PMapContributedPerceptionStatus;
  description: string;
  dateCreated: number;
  userId?: number;
  userName?: string;
  userEmail?: string;
  userAvatar?: string;
}

export class PMapContributedPerception implements IPMapContributedPerception {
  public id: string;
  public status: PMapContributedPerceptionStatus;
  public description: string;
  public dateCreated: number;
  public userId?: number;
  public userName?: string;
  public userEmail?: string;
  public userAvatar?: string;

  constructor(json: IPMapContributedPerception) {
    this.id = json.id || uuidv1();
    this.status = json.status || PMapContributedPerceptionStatus.Pending;
    this.description = json.description;
    this.dateCreated = json.dateCreated;
    this.userId = json.userId;
    this.userName = json.userName;
    this.userEmail = json.userEmail;
    this.userAvatar = json.userAvatar;
  }
}
