const defaultAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const numberToLetters = (num: number, alphabet: string = defaultAlphabet): string => {
  let letters = '';

  do {
    num -= 1;
    letters = alphabet[num % alphabet.length] + letters;
    num = Math.floor(num / alphabet.length);
  } while (num > 0);

  return letters;
};

export const lettersToNumber = (letters: string, alphabet: string = defaultAlphabet): number => {
  let num = 0;

  for (let i = 0; i < letters.length; i++) {
    const letterIndex = alphabet.indexOf(letters[i]) + 1;
    num += letterIndex * Math.pow(alphabet.length, letters.length - i - 1);
  }

  return num;
};
