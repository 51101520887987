import React, { useState } from 'react';
import { Box, Link } from '@mui/material';
import { PublicPageContainer } from '../../content-containers/public-page-container/component';
import { ErrorPage, ErrorPageType } from '../../error-page/component';
import { useMutation, useQuery } from 'react-query';
import { PublicPMapApi } from './api/public-pmap.api';
import { ToolTypeDataMap } from '@priz/shared/src/data/tool-type-data-map';
import { ToolType } from '@priz/shared/src/models/tools';
import { Trans } from 'react-i18next';
import { PrizLoadingOverlay } from '@priz/shared/src/components/priz-loading-overlay/component';
import { QuillContentViewer } from '@priz/shared/src/components/quill-content-viewer/component';
import { CreatePerceptionForm } from './create-perception-form/component';
import { useSelector } from 'react-redux';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { PublicContributedPerceptionCard } from './public-contributed-perception-card/component';
import { PMapContributedPerception } from '@priz/shared/src/models/tools/pmap/pmap-contributed-perception';
import { AccessLevel } from '@priz/shared/src/models/security/access-level';

interface PMapPublicPageProps {
  utilizationId: number;
}

export const PMapPublicPage: React.FC<PMapPublicPageProps> = ({ utilizationId }) => {
  const [anonymousCreatingPerception, setAnonymousCreatingPerception] = useState<PMapContributedPerception>(null);
  const [anonymousPerceptions, setAnonymousPerceptions] = useState<PMapContributedPerception[]>([]);

  const isUserLoading = useSelector(UserSelectors.isLoading);
  const currentUser = useSelector(UserSelectors.currentUserSelector);

  const { data, isLoading, isFetching, isFetched, refetch } = useQuery(
    ['pmap-public-page', utilizationId, currentUser ? 'check-auth' : 'skip-auth'],
    () => PublicPMapApi.load(utilizationId, !currentUser),
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const mutationCallbacks: { onSuccess: () => void; onError: () => void } = {
    onSuccess: () => {
      if (currentUser) {
        void refetch();
      } else {
        setAnonymousPerceptions(currentState => [...currentState, anonymousCreatingPerception]);
        setAnonymousCreatingPerception(null);
      }
    },
    onError: () => {
      void refetch();
    },
  };

  const addPerceptionMutation = useMutation(
    ({ text, skipAuth }: { text: string; skipAuth: boolean }) =>
      PublicPMapApi.addPerception(utilizationId, { text }, skipAuth),
    mutationCallbacks,
  );

  const addPerceptionHandler = (text: string) => {
    if (!currentUser) {
      setAnonymousCreatingPerception(
        new PMapContributedPerception({
          description: text,
          dateCreated: new Date().getTime(),
        }),
      );
    }

    addPerceptionMutation.mutate({ text, skipAuth: !currentUser });
  };

  if (isLoading) {
    return <PrizLoadingOverlay />;
  }

  if (isFetched && !data) {
    return <ErrorPage type={ErrorPageType.NotFound} customMessage={'Page not found or access denied'} />;
  }

  return (
    <PublicPageContainer
      title={data.title || <Trans>{ToolTypeDataMap[ToolType.P_MAP].name}</Trans>}
      publicNavbarProps={{
        loginButton: true,
        redirectUrl: window.location.pathname,
      }}
    >
      {data?.accessLevel === AccessLevel.Project && (
        <Link href={`/project/${data.projectId}/tools/p-map/${utilizationId}`}>
          <Trans>Go to Perception mapping tool</Trans>
          {' >>'}
        </Link>
      )}

      <Box width={'100%'} pt={4} pb={8}>
        {data.subject && (
          <Box mb={4}>
            <QuillContentViewer content={data.subject} autoHeight />
          </Box>
        )}

        {isUserLoading && (
          <LoadingOverlay
            loading={true}
            backdropStyles={{ backgroundColor: 'transparent', position: 'relative' }}
            progressContainerProps={{ py: 4 }}
          />
        )}

        {!isUserLoading && currentUser && !!data.contributedData?.perceptions?.length && (
          <Box mb={4}>
            {data.contributedData.perceptions.map(perception => (
              <Box key={perception.id} mb={1}>
                <PublicContributedPerceptionCard perception={perception} />
              </Box>
            ))}
          </Box>
        )}

        {!isUserLoading && !currentUser && !!anonymousPerceptions?.length && (
          <Box mb={4}>
            {anonymousPerceptions.map(perception => (
              <Box key={perception.id} mb={1}>
                <PublicContributedPerceptionCard perception={perception} />
              </Box>
            ))}
          </Box>
        )}

        {!isUserLoading && (
          <CreatePerceptionForm
            onCreate={addPerceptionHandler}
            loading={isFetching || addPerceptionMutation.isLoading}
          />
        )}
      </Box>
    </PublicPageContainer>
  );
};
