import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, Link, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { WorkspaceSelectors } from '../store/selectors';
import { WorkspaceActions } from '../store/actions';
import { UpdateWorkspaceCommand, WorkspaceUtils } from '../services';
import { useForm } from 'react-hook-form';
import { HelperText, ReactHookFormText } from '../../react/form-elements';
import { validateOptions } from '@priz/shared/src/utils/form';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { ReactHookFormSwitch } from '../../react/form-elements/switch/component';

export const WorkspaceSettings: React.FC = () => {
  const dispatch = useDispatch();
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

  const { handleSubmit, reset, control } = useForm({
    shouldFocusError: false,
    defaultValues: {
      workspaceName: '',
      useExternalFileViewer: false,
      useAiAssistant: false,
      mfa: false,
    },
  });

  const workspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const isUpdating = useSelector(WorkspaceSelectors.isUpdating);
  const isRemoving = useSelector(WorkspaceSelectors.isRemoving);

  useEffect(() => {
    if (workspace) {
      reset({
        workspaceName: workspace.name,
        useExternalFileViewer: workspace.useExternalFileViewer,
        useAiAssistant: workspace.useAiAssistant,
        mfa: workspace.mfa,
      });
    }
  }, [workspace]);

  const updateTitleHandler = () => {
    handleSubmit(formData => {
      const command: UpdateWorkspaceCommand = {
        name: formData.workspaceName,
        useExternalFileViewer: formData.useExternalFileViewer,
        useAiAssistant: formData.useAiAssistant,
      };

      if (workspace.allowToUpdateMfa) {
        command.mfa = formData.mfa;
      }

      dispatch(WorkspaceActions.update(command));
    })();
  };

  const openDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
  };

  const deleteWorkspace = () => {
    dispatch(WorkspaceActions.delete(workspace.id));
  };

  return (
    <>
      <Box>
        <PageTitleWithDocLink
          title={<Trans>Workspace Settings</Trans>}
          oneLiner={<Trans>Here you can manage general settings of this workspace</Trans>}
          docsUrl={'https://www.priz.guru/knowledge-base/workspace-settings'}
        />
      </Box>

      <Box mb={2}>
        <ReactHookFormText
          fieldTitle={'Workspace Name'}
          name={'workspaceName'}
          control={control}
          rules={{
            validate: {
              ...validateOptions.hasText('Field is required'),
            },
          }}
          disabled={isUpdating || isRemoving}
        />
      </Box>

      <Box>
        <Box mb={2}>
          <Typography variant={'h6'}>
            <Trans>Security settings</Trans>
          </Typography>
        </Box>

        <ReactHookFormSwitch
          fieldTitle={'Attachments viewer'}
          label={'Use Microsoft Office Web Viewer'}
          name={'useExternalFileViewer'}
          control={control}
          disabled={isUpdating}
          wrapperProps={{
            mb: 0,
          }}
        />

        <Typography variant={'body2'} color={'text_color.light'}>
          <Trans>Allows to display attached Office Word, Excel, and PowerPoint files.</Trans>{' '}
          <Link href="#" target={'_blank'} underline="hover">
            <Trans>Read more »</Trans>
          </Link>
        </Typography>
      </Box>

      <ReactHookFormSwitch
        fieldTitle={'AI Assistant'}
        label={'Use AI assistant'}
        name={'useAiAssistant'}
        control={control}
        disabled={isUpdating}
        wrapperProps={{
          mb: 0,
          mt: 3,
        }}
      />

      <ReactHookFormSwitch
        fieldTitle={'Multi-Factor Authentication'}
        label={'Require Multi-Factor Authentication'}
        helperText={'Enforce required MFA for all workspace members.'}
        name={'mfa'}
        control={control}
        disabled={!workspace.allowToUpdateMfa || isUpdating}
        wrapperProps={{
          mb: 0,
          mt: 3,
        }}
      />

      {!workspace.allowToUpdateMfa && (
        <HelperText
          text={
            <>
              <Trans>MFA is a paid add-on for the current plan</Trans>. <Trans>Please</Trans>,{' '}
              <Link href={'https://www.priz.guru/contact-us/'} target={'_blank'}>
                <Trans>contact</Trans>
              </Link>{' '}
              <Trans>PRIZ Guru Team for more details.</Trans>.
            </>
          }
        />
      )}

      <Box textAlign={'right'}>
        <Button variant={'pg_rounded'} color={'pg_orange_solid'} disabled={isUpdating} onClick={updateTitleHandler}>
          <Trans>Update</Trans>
        </Button>
      </Box>

      {!WorkspaceUtils.isPersonalOrPro(workspace) && (
        <>
          <Box my={3}>
            <Divider />
          </Box>

          <Box mb={2}>
            <Typography variant={'h5'} component={'span'}>
              <Trans>Delete workspace</Trans>
            </Typography>
          </Box>

          <Box mb={1}>
            <Typography variant={'body2'} component={'span'}>
              <Trans>
                This section allows to delete you current workspace.Once deleted, any active subscription will be also
                canceled.
              </Trans>
              <br />
              <Trans>
                Deletion of the workspace is permanent and the data will not be accessible once the workspace is
                deleted.
              </Trans>
              <br />
              <Trans>After, successful deletion, you will be redirected to your personal workspace.</Trans>
            </Typography>
          </Box>

          <Box textAlign={'right'}>
            <Button variant={'pg_button_link'} onClick={openDeleteConfirmation} disabled={isUpdating}>
              <Trans>Delete workspace</Trans>
            </Button>
          </Box>
        </>
      )}

      <PgConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        disabled={isUpdating || isRemoving}
        loading={isUpdating || isRemoving}
        confirmTitle={<Trans>Confirm delete</Trans>}
        confirmContent={
          <>
            <Trans>Are you sure you want to delete this workspace?</Trans>
            <br />
            <Trans>All the data will be lost once deleted!</Trans>
          </>
        }
        onConfirm={deleteWorkspace}
        onClose={closeDeleteConfirmation}
      />
    </>
  );
};
