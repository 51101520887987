import {
  CompleteWorkspaceData,
  IInvitation,
  IWorkspace,
  SubscriptionDetails,
  IWorkspaceMember,
  PlanFeatureSet,
  PlanFeaturesMap,
  WorkspaceMembershipLevel,
  FeatureSetsCollection,
  Workspace,
} from '@priz/shared/src/models/workspace';
import axios, { AxiosResponse } from 'axios';
import { Invoice, PaymentMethod } from '@priz/shared/src/models/billing';

const getAllMy = (): Promise<IWorkspace[]> =>
  axios.get(`/v1/api/workspaces/my`).then((response: AxiosResponse<IWorkspace[]>) => response.data);

const getFeatureSet = (): Promise<FeatureSetsCollection> =>
  axios
    .get(`/v1/api/workspace/<ws-id>/features`)
    .then((response: AxiosResponse<FeatureSetsCollection>) => response.data);

const getAllPlansFeatures = (): Promise<PlanFeaturesMap> =>
  axios.get(`/v1/api/workspace/all-features`).then((response: AxiosResponse<PlanFeaturesMap>) => response.data);

const getFeatureSetForWorkspace = (workspaceId: number): Promise<PlanFeatureSet> =>
  axios
    .get(`/v1/api/workspace/${workspaceId}/features`)
    .then((response: AxiosResponse<PlanFeatureSet>) => response.data);

const getWorkspaceMembers = (): Promise<IWorkspaceMember[]> =>
  axios.get(`/v1/api/workspace/<ws-id>/members`).then((response: AxiosResponse<IWorkspaceMember[]>) => response.data);

const invite = (email: string): Promise<IWorkspaceMember[]> =>
  axios
    .post(`/v1/api/workspace/<ws-id>/invite`, { email })
    .then((response: AxiosResponse<IWorkspaceMember[]>) => response.data);

const acceptInvitation = (token: string): Promise<IInvitation[]> =>
  axios
    .post(`/v1/api/workspace/<ws-id>/invitation/accept/${token}`, null)
    .then((response: AxiosResponse<IInvitation[]>) => response.data);

const remove = (email: string): Promise<void> =>
  axios
    .put(`/v1/api/workspace/<ws-id>/member/remove`, { email })
    .then((response: AxiosResponse<void>) => response.data);

const updateMembershipLevel = (email: string, level: WorkspaceMembershipLevel): Promise<IWorkspaceMember> =>
  axios
    .patch(`/v1/api/workspace/<ws-id>/membership-level`, { email, level })
    .then((response: AxiosResponse<IWorkspaceMember>) => response.data);

const create = (name: string, email: string, numberOfSeats): Promise<IWorkspace> =>
  axios
    .post(`/v1/api/workspace`, { name, email, numberOfSeats })
    .then((response: AxiosResponse<IWorkspace>) => response.data);

export interface UpdateWorkspaceCommand {
  name?: string;
  useExternalFileViewer?: boolean;
  useAiAssistant?: boolean;
  mfa?: boolean;
}

const update = (command: UpdateWorkspaceCommand): Promise<IWorkspace> =>
  axios.patch(`/v1/api/workspace/<ws-id>`, command).then((response: AxiosResponse<IWorkspace>) => response.data);

const doDelete = (id: number): Promise<void> =>
  axios.delete(`/v1/api/workspace/${id}`).then((response: AxiosResponse<void>) => response.data);

const updatePlan = (completeWorkspaceData: CompleteWorkspaceData): Promise<IWorkspace> =>
  axios
    .put(`/v1/api/workspace/<ws-id>/plan`, completeWorkspaceData)
    .then((response: AxiosResponse<IWorkspace>) => response.data);

const complete = (completeWorkspaceData: CompleteWorkspaceData): Promise<IWorkspace> =>
  axios
    .post(`/v1/api/workspace/<ws-id>/complete`, completeWorkspaceData)
    .then((response: AxiosResponse<IWorkspace>) => response.data);

const getSubscriptionDetails = (): Promise<SubscriptionDetails | undefined> =>
  axios
    .get(`/v1/api/workspace/<ws-id>/subscription`)
    .then((response: AxiosResponse<SubscriptionDetails>) => response.data);

const getPaymentMethod = (): Promise<PaymentMethod | undefined> =>
  axios.get(`/v1/api/workspace/<ws-id>/payment-method`).then((response: AxiosResponse<PaymentMethod>) => response.data);

const cancelSubscription = (): Promise<IWorkspace> =>
  axios.delete(`/v1/api/workspace/<ws-id>/subscription`).then((response: AxiosResponse<IWorkspace>) => response.data);

const getInvoices = (): Promise<Invoice[] | undefined> =>
  axios.get(`/v1/api/workspace/<ws-id>/invoices`).then((response: AxiosResponse<Invoice[]>) => response.data);

const getUpcomingInvoice = (): Promise<Invoice | undefined> =>
  axios.get(`/v1/api/workspace/<ws-id>/upcoming-invoice`).then((response: AxiosResponse<Invoice>) => response.data);

interface SubscriptionUpdatePreview {
  amountDue: number;
  prorationDate: number;
}
const subscriptionUpdatePreview = (newSeatsCount: number): Promise<SubscriptionUpdatePreview> =>
  axios
    .post(`/v1/api/workspace/<ws-id>/subscription/preview`, { quantity: newSeatsCount })
    .then((response: AxiosResponse<SubscriptionUpdatePreview>) => response.data);

const setLicensedSeats = (newSeatsCount: number): Promise<IWorkspace> =>
  axios
    .post(`/v1/api/workspace/<ws-id>/subscription/seats`, { quantity: newSeatsCount })
    .then((response: AxiosResponse<IWorkspace>) => response.data);

const loadWorkspace = (workspaceId: number): Promise<Workspace> =>
  axios
    .get(`/v1/api/workspace/${workspaceId}`)
    .then((response: AxiosResponse<IWorkspace>) => new Workspace(response.data));

export interface UpdateWorkspaceSettingsCommand {
  allowToUpdateMfa?: boolean;
}

const updateSettings = (workspaceId: number, command: UpdateWorkspaceSettingsCommand): Promise<Workspace> =>
  axios
    .patch(`/v1/api/workspace/${workspaceId}/settings`, command)
    .then((response: AxiosResponse<IWorkspace>) => new Workspace(response.data));

export const WorkspaceApi = {
  getAllMy,
  getWorkspaceMembers,
  invite,
  acceptInvitation,
  remove,
  updateMembershipLevel,
  create,
  update,
  delete: doDelete,
  complete,
  updatePlan,
  getSubscriptionDetails,
  getPaymentMethod,
  cancelSubscription,
  getFeatureSet,
  getFeatureSetForWorkspace,
  getAllPlansFeatures,
  getInvoices,
  getUpcomingInvoice,
  subscriptionUpdatePreview,
  setLicensedSeats,
  loadWorkspace,
  updateSettings,
};
