import React from 'react';
import { useStyles } from './styles';
import { PopperProps, Tooltip, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { FiveWhysCause } from '@priz/shared/src/models/tools/five-whys';
import { checkHasCausing } from '@priz/shared/src/utils/five-whys';
import { Assistant } from '../../assistant/component';
import { AssistanceType, AssistantVariant } from '../../assistant/store/model';
import { useSelector } from 'react-redux';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { SharedHintContentProps } from '../../assistant/hint-content/component';

import { ReactComponent as TrashIcon } from '@priz/shared/src/assets/icons/trash.svg';
import { ReactComponent as AddLinkedNodeIcon } from '@priz/shared/src/assets/icons/add-linked-node.svg';

export interface FiveWhysNodeControlsEvents {
  onAddCause?: (causeId: number) => void;
  onRemove?: (causeId: number) => void;
  onFrp?: (causeId: number) => void;
  onUseHint?: (causeId: number, hint: string) => void;
}

export interface FiveWhysNodeControlsProps extends FiveWhysNodeControlsEvents {
  utilizationId: number;
  causeId: number;
  causes: FiveWhysCause[];
  projectId?: number;
  showFrpButton?: boolean;
  showRemoveButton?: boolean;
  showAddCauseButton?: boolean;
  showAssistantButton?: boolean;
  assistanceType?: AssistanceType;
  assistanceTargetCauseId?: number;
  assistantPopperProps?: Partial<PopperProps>;
  assistantContentProps?: SharedHintContentProps;
}

export const FiveWhysNodeControls: React.FC<FiveWhysNodeControlsProps> = ({
  utilizationId,
  causeId,
  causes,
  projectId,
  onFrp,
  onRemove,
  onAddCause,
  onUseHint,
  showFrpButton,
  showRemoveButton,
  showAddCauseButton,
  showAssistantButton,
  assistanceType,
  assistanceTargetCauseId,
  assistantPopperProps,
  assistantContentProps,
}) => {
  const styles = useStyles();
  const selectedWorkspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const isAiAssistantEnabled = useSelector(WorkspaceSelectors.isAiAssistantEnabled(selectedWorkspace?.id));

  const frpClickHandler = () => {
    onFrp(causeId);
  };

  const removeClickHandler = () => {
    onRemove(causeId);
  };

  const useHintHandler = (text: string[]) => {
    onUseHint(causeId, text.join('\n'));
  };

  const addCauseClickHandler = () => {
    if (!checkHasCausing(causes, causeId)) onAddCause(causeId);
  };

  return (
    <>
      {onFrp && showFrpButton && (
        <Tooltip title={<Trans>Fundamental Root Cause</Trans>} placement={'bottom'} disableInteractive arrow>
          <div className={styles.controlButton} onClick={frpClickHandler}>
            <Typography variant="subtitle2" component="span" gutterBottom={false}>
              FRP
            </Typography>
          </div>
        </Tooltip>
      )}

      {onRemove && showRemoveButton && (
        <Tooltip title={<Trans>Remove</Trans>} placement={'bottom'} disableInteractive arrow>
          <div className={styles.controlButton} onClick={removeClickHandler}>
            <TrashIcon />
          </div>
        </Tooltip>
      )}

      {onAddCause && showAddCauseButton && (
        <Tooltip title={<Trans>Add cause</Trans>} placement={'bottom'} disableInteractive arrow>
          <div className={styles.controlButton} onClick={addCauseClickHandler}>
            <AddLinkedNodeIcon />
          </div>
        </Tooltip>
      )}

      {isAiAssistantEnabled && showAssistantButton && assistanceType && assistanceTargetCauseId && (
        <Assistant
          type={assistanceType}
          projectId={projectId}
          onUseHint={useHintHandler}
          variant={AssistantVariant.Popper}
          popperAssistantProps={assistantPopperProps}
          contentProps={assistantContentProps}
          buttonProps={{
            className: styles.controlButton,
            variant: 'icon',
            tooltip: 'Get a hint',
            tooltipPlacement: 'bottom',
          }}
          actionProps={{ utilizationId, causeId: assistanceTargetCauseId }}
        />
      )}
    </>
  );
};
