import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  svg: {
    fill: alpha(pgColorScheme.white, 0.4),
    width: 24,
    height: 24,
  },
});
