import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { Box, Button, Grid, Hidden, IconButton, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ReactHookFormText } from '@priz/shared/src/components/form-elements';
import { validateOptions } from '@priz/shared/src/utils/form';
import { Trans, useTranslation } from 'react-i18next';
import { ConversationCommand } from '../services/chat.api';
import { useDispatch, useSelector } from 'react-redux';
import { ChatActions } from '../store/actions';
import { ChatSelectors } from '../store/selectors';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { AIChatMessage } from '../ai-chat-message/component';
import { AIChatTab } from '../ai-chat-tab/component';
import { Add, Close, MenuOutlined, MenuOpenOutlined } from '@mui/icons-material';
import { ConversationRole } from '../store/model';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { UserActions } from '../../user/store/actions/user.actions';
import { safeParseJson } from '@priz/shared/src/utils/common';

import { ReactComponent as PaperPlaneIcon } from '../icons/paper-plane.svg';
import { ReactComponent as AssistantSvg } from '@priz/shared/src/assets/images/assistant-simple.svg';
import { WorkspaceSelectors } from '../../workspace/store/selectors';

interface AIChatProps {
  projectId: number;
  onClose?: () => void;
}

export const AIChat: React.FC<AIChatProps> = ({ projectId, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const styles = useStyles();

  const [chatContentElement, setChatContentElement] = useState<HTMLDivElement>(null);
  const [chatPosition, setChatPosition] = useState<number>();
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { handleSubmit, reset, control } = useForm({
    shouldFocusError: false,
    defaultValues: {
      message: '',
    },
  });

  const selectedWorkspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const isUserLoaded = useSelector(UserSelectors.isLoaded);
  const isUserLoading = useSelector(UserSelectors.isLoaded);
  const allChats = useSelector(ChatSelectors.getAllByProjectId(projectId));
  const activeChat = useSelector(ChatSelectors.getActiveChat);

  const isLoaded = useSelector(ChatSelectors.isLoaded(projectId));
  const isAnyCreating = useSelector(ChatSelectors.isAnyCreating(projectId));
  const isSaving = useSelector(ChatSelectors.isSavingById(projectId, activeChat ? activeChat.id : -projectId));
  const isRemoving = useSelector(ChatSelectors.isRemovingById(projectId, activeChat ? activeChat.id : -projectId));
  const isMessageSendFailed = useSelector(
    ChatSelectors.isErrorById(projectId, activeChat ? activeChat.id : -projectId),
  );

  const rootClassNames = [styles.root];

  if (isMenuOpen) rootClassNames.push(styles.rootMenuOpen);

  useEffect(() => {
    if (scrollToBottom) {
      setScrollToBottom(false);

      if (chatContentElement) {
        chatContentElement.scrollTop = chatContentElement.scrollHeight;
      }
    }
  }, [scrollToBottom, chatContentElement]);

  useEffect(() => {
    dispatch(ChatActions.getChats(projectId));
  }, []);

  useEffect(() => {
    if (!isUserLoaded && !isUserLoading && !currentUser) {
      dispatch(UserActions.loadCurrentUser());
    }
  }, [currentUser, isUserLoaded, isUserLoading]);

  useEffect(() => {
    if (typeof activeChat?.id !== 'undefined') {
      setChatContentElement(null);
    }
  }, [activeChat?.id]);

  useEffect(() => {
    if (isLoaded && activeChat) {
      const lsChatsPositions = LocalStorageService.getItem(LocalStorageKey.AiChatsPositions);
      const chatsPositionsObject = safeParseJson(lsChatsPositions);
      const currentChatPosition = chatsPositionsObject[activeChat.id] || 0;
      const positionToSet = typeof currentChatPosition !== 'undefined' ? parseInt(currentChatPosition) : 0;

      setChatPosition(positionToSet);
    }
  }, [isLoaded, activeChat]);

  useEffect(() => {
    if (chatContentElement && chatPosition) {
      chatContentElement.scrollTop = chatPosition;
    }
  }, [chatContentElement, chatPosition]);

  useEffect(() => {
    if (typeof activeChat?.id !== 'undefined') {
      clearInput();
    }
  }, [activeChat?.id]);

  const sendMessageHandler = () => {
    handleSubmit(formData => {
      const command: ConversationCommand = {
        chatId: activeChat?.id,
        message: formData.message,
      };

      dispatch(ChatActions.sendChatMessage(projectId, command, selectedWorkspace?.id, currentUser?.id));
      clearInput();
      setScrollToBottom(true);
    })();
  };

  const createNewChat = () => {
    dispatch(ChatActions.createChat(projectId));
  };

  const chatScrollHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (activeChat) {
      const lsLastChatPosition = LocalStorageService.getItem(LocalStorageKey.AiChatsPositions);
      const lastChatPositionObject = safeParseJson(lsLastChatPosition);

      lastChatPositionObject[activeChat.id] = e.currentTarget.scrollTop;
      LocalStorageService.setItem(LocalStorageKey.AiChatsPositions, JSON.stringify(lastChatPositionObject));
    }
  };

  const chatContentRefHandler = (element: HTMLDivElement) => {
    if (!chatContentElement && element) {
      setChatContentElement(element);
    }
  };

  const retryHandler = () => {
    dispatch(ChatActions.retry(activeChat.project.id, activeChat.id));
  };

  const toggleMenu = () => {
    setIsMenuOpen(currentState => !currentState);
  };

  const inputKeyDownEventHandler = (e: React.KeyboardEvent<HTMLElement>) => {
    if (!e.shiftKey && e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      sendMessageHandler();
    }
  };

  const clearInput = () => {
    reset({ message: '' });
  };

  return (
    <Box className={rootClassNames.join(' ')}>
      <Box className={styles.header}>
        <Grid container alignItems={'center'}>
          <Grid item xs={'auto'}>
            <Box pt={1} pl={1}>
              <AssistantSvg className={styles.assistantSvg} />
            </Box>
          </Grid>

          <Grid item xs={true}>
            <Box p={1}>
              <Typography variant={'body1'}>
                <Trans>Chat with AI Assistant</Trans>
              </Typography>
            </Box>
          </Grid>

          <Hidden mdUp>
            <Grid item xs={'auto'}>
              <IconButton className={styles.closeButton} onClick={toggleMenu}>
                {isMenuOpen ? <MenuOpenOutlined /> : <MenuOutlined />}
              </IconButton>
            </Grid>
          </Hidden>

          {onClose && (
            <Grid item xs={'auto'}>
              <IconButton className={styles.closeButton} onClick={onClose}>
                <Close />
              </IconButton>
            </Grid>
          )}

          <Grid item xs={'auto'}>
            <Box pr={2} />
          </Grid>
        </Grid>
      </Box>

      <div className={styles.contentContainer}>
        <div className={styles.menuBackdrop} onClick={toggleMenu} />

        {!!allChats?.length && (
          <Box className={styles.chatsList} pb={4}>
            {allChats.map(chat => (
              <AIChatTab key={chat.id} chat={chat} activeChatId={activeChat?.id} />
            ))}

            <Box px={2} mt={1}>
              <Button
                variant={'pg_button_link'}
                color={'primary'}
                size={'pg_small'}
                startIcon={<Add sx={{ fontSize: 16 }} />}
                onClick={createNewChat}
                disabled={isAnyCreating}
              >
                <Trans>New chat</Trans>
              </Button>
            </Box>
          </Box>
        )}

        <div className={[styles.chat, allChats?.length ? styles.chatWithMenu : styles.chatFullWidth].join(' ')}>
          {(!isLoaded || !isUserLoaded) && (
            <LoadingOverlay loading={true} backdropStyles={{ background: 'transparent' }} />
          )}

          {isLoaded && isUserLoaded && (
            <>
              <Box
                p={{ xs: 1, sm: 2, md: 3 }}
                className={styles.content}
                onScroll={chatScrollHandler}
                ref={chatContentRefHandler}
                key={activeChat?.id}
              >
                <AIChatMessage
                  message={{
                    role: ConversationRole.Assistant,
                    content: '👋 Hi there! This is your personal assistant. I’m here to answer your questions.',
                    dateCreated: new Date().getTime(),
                  }}
                />

                {activeChat &&
                  activeChat.conversation.messages
                    .sort((a, b) => a.dateCreated - b.dateCreated)
                    .map((message, key) => (
                      <Box key={`${activeChat.id}${key}${message.dateCreated}`} mb={{ xs: 2, sm: 3, md: 4 }}>
                        <AIChatMessage message={message} />
                      </Box>
                    ))}

                {isSaving && (
                  <Box mb={{ xs: 2, sm: 3, md: 4 }}>
                    <AIChatMessage
                      message={{
                        role: ConversationRole.Assistant,
                        content: '',
                        dateCreated: new Date().getTime(),
                      }}
                      loading
                    />
                  </Box>
                )}

                {!isSaving && isMessageSendFailed && (
                  <Box mb={{ xs: 2, sm: 3, md: 4 }}>
                    <AIChatMessage
                      message={{
                        role: ConversationRole.Assistant,
                        content: '',
                        dateCreated: new Date().getTime(),
                      }}
                      onRetry={activeChat?.id > 0 ? retryHandler : sendMessageHandler}
                    />
                  </Box>
                )}
              </Box>

              <Box className={styles.bottom} p={1}>
                <Grid container spacing={1}>
                  <Grid item xs={true}>
                    <ReactHookFormText
                      name={'message'}
                      placeholder={t('Write a message')}
                      control={control}
                      size={'small'}
                      multiline
                      maxRows={3}
                      rules={{
                        validate: {
                          ...validateOptions.hasText('Field is required'),
                        },
                      }}
                      wrapperProps={{
                        mb: 0,
                      }}
                      onKeyDown={inputKeyDownEventHandler}
                      disabled={isSaving || isRemoving}
                    />
                  </Grid>

                  <Grid item xs={'auto'}>
                    <IconButton size={'large'} onClick={sendMessageHandler} disabled={isSaving || isRemoving}>
                      <PaperPlaneIcon className={styles.submitIcon} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </div>
      </div>
    </Box>
  );
};
