import React, { useEffect } from 'react';
import { CftNodeData } from '@priz/shared/src/models/tools/cft';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ReactHookFormText } from '@priz/shared/src/components/form-elements';

interface CftNodeNotesEditorProps {
  open: boolean;
  node: CftNodeData;
  onSubmit: (nodeId: string, text: string) => void;
  onClose: () => void;
}

export const CftNodeNotesEditor: React.FC<CftNodeNotesEditorProps> = ({ open, node, onSubmit, onClose }) => {
  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      notes: '',
    },
  });

  useEffect(() => {
    if (open) {
      reset({ notes: node?.notes || '' });
    }
  }, [open]);

  const submitHandler = () => {
    handleSubmit(data => {
      onSubmit(node.id, data.notes);
      onClose();
    })();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'md'}>
      <DialogTitle>
        <Trans>Notes editor</Trans>
      </DialogTitle>

      <DialogContent>
        <Typography component={Box} variant={'subtitle2'} mb={1}>
          <Trans>Description</Trans>
        </Typography>

        <Typography component={Box} variant={'body1'} mb={2}>
          {node?.description || '-'}
        </Typography>

        <Typography component={Box} variant={'subtitle2'} mb={1}>
          <Trans>Notes</Trans>
        </Typography>

        <ReactHookFormText name={'notes'} control={control} multiline />
      </DialogContent>

      <DialogActions sx={{ textAlign: 'right' }}>
        <Button variant={'pg_rounded'} color={'pg_orange_solid'} onClick={submitHandler}>
          <Trans>Save</Trans>
        </Button>

        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={onClose}>
          <Trans>Cancel</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
