import { createSelector, Selector } from 'reselect';
import { EntityCollectionStatus, EntityMap } from '@priz/shared/src/models/common/entity-collection-state';
import { AppState } from '../../../store/app.state';
import { Idea } from '@priz/shared/src/models/idea/idea';
import { IdeaList } from '@priz/shared/src/models/idea';

const ideasCollectionSelector = (state: AppState): EntityMap<IdeaList> => state.projectIdeasMap;

const ideasListSelector = (projectId: number): Selector<AppState, IdeaList> =>
  createSelector(ideasCollectionSelector, (entities: EntityMap<IdeaList>) => entities[projectId]);

const ideasListStatusesSelector = (projectId: number): Selector<AppState, EntityCollectionStatus> =>
  createSelector(ideasListSelector(projectId), (list: IdeaList) => list?.statuses || {});

const getAllByProjectId = (projectId: number): Selector<AppState, EntityMap<Idea>> =>
  createSelector(ideasListSelector(projectId), (list: IdeaList) => list?.ideas);

const getIdeasArrayByProjectId = (projectId: number): Selector<AppState, Idea[]> =>
  createSelector(ideasListSelector(projectId), (list: IdeaList) => Object.values(list?.ideas || {}));

const isCreating = (projectId: number): Selector<AppState, boolean> =>
  createSelector(ideasListStatusesSelector(projectId), (statuses: EntityCollectionStatus) => !!statuses.creating);

const isCreated = (projectId: number): Selector<AppState, boolean> =>
  createSelector(ideasListStatusesSelector(projectId), (statuses: EntityCollectionStatus) => !!statuses.created);

const isUpdating = (projectId: number): Selector<AppState, boolean> =>
  createSelector(ideasListStatusesSelector(projectId), (statuses: EntityCollectionStatus) => !!statuses.updating);

const isUpdated = (projectId: number): Selector<AppState, boolean> =>
  createSelector(ideasListStatusesSelector(projectId), (statuses: EntityCollectionStatus) => !!statuses.updated);

const isRemoving = (projectId: number): Selector<AppState, boolean> =>
  createSelector(ideasListStatusesSelector(projectId), (statuses: EntityCollectionStatus) => !!statuses.removing);

const isRemoved = (projectId: number): Selector<AppState, boolean> =>
  createSelector(ideasListStatusesSelector(projectId), (statuses: EntityCollectionStatus) => !!statuses.removed);

export const IdeasSelectors = {
  getAllByProjectId,
  getIdeasArrayByProjectId,
  isCreating,
  isUpdating,
  isCreated,
  isUpdated,
  isRemoving,
  isRemoved,
};
