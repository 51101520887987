import { createPgAction } from '../../../shared/store/action-creator';
import { AttendeeApi } from '../../services';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { IAttendee } from '../model';
import { FluxStandardAction } from 'flux-standard-action';

export enum AttendeeActionType {
  FetchAll = 'attendees/fetch',
  Fetch = 'attendee/fetch',
  Create = 'attendee/create',
  Update = 'attendee/update',
  Delete = 'attendee/delete',
}

export interface AttendeeActionMeta {
  projectId: number;
  attendeeId: number;
}

export type AttendeeActionPayload = IAttendee | IAttendee[];

export type AttendeeAction = FluxStandardAction<string, AttendeeActionPayload, AttendeeActionMeta>;

const getAll = createPgAction(AttendeeActionType.FetchAll, AttendeeApi.getAll, projectId => ({ projectId }));

const get = createPgAction(AttendeeActionType.Fetch, AttendeeApi.get, (projectId, attendeeId) => ({
  projectId,
  attendeeId,
}));

const create = createPgAction(
  AttendeeActionType.Create,
  AttendeeApi.create,
  projectId => ({ projectId }),
  (_payload, { projectId }) => {
    AnalyticsService.track(AnalyticsEvent.ATTENDEE_CREATED, {
      project_id: projectId,
    });
  },
);

const update = createPgAction(
  AttendeeActionType.Update,
  AttendeeApi.update,
  (projectId, attendeeId) => ({ projectId, attendeeId }),
  (_payload, { projectId, attendeeId }) => {
    AnalyticsService.track(AnalyticsEvent.ATTENDEE_UPDATED, {
      project_id: projectId,
      attendee_id: attendeeId,
    });
  },
);

const doDelete = createPgAction(
  AttendeeActionType.Delete,
  AttendeeApi.delete,
  (projectId, attendeeId) => ({ projectId, attendeeId }),
  (_payload, { projectId, attendeeId }) => {
    AnalyticsService.track(AnalyticsEvent.ATTENDEE_DELETED, {
      project_id: projectId,
      attendee_id: attendeeId,
    });
  },
);

export const AttendeeActions = {
  getAll,
  get,
  create,
  update,
  delete: doDelete,
};
