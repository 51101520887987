import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../../theme';

export const useStyles = makeStyles({
  label: {
    color: pgColorScheme.textGray,
  },
  bold: {
    fontWeight: 600,
  },
  listItem: {
    'paddingTop': 0,
    'paddingBottom': 0,

    '& .MuiListItemIcon-root': {
      'minWidth': 36,
      'color': pgColorScheme.darkText,

      '& .MuiTypography-root': {
        height: 24,
        minWidth: 24,
        padding: '0 3px',
        boxSizing: 'border-box',
        borderRadius: '50%',
        background: pgColorScheme.background,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
});
