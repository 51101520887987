import { createSelector, Selector } from 'reselect';
import { EntityCollectionStatus, EntityMap } from '@priz/shared/src/models/common/entity-collection-state';
import { AppState } from '../../../store/app.state';
import { ApaAction, ApaActionCollection, ApaActionLookups } from '@priz/shared/src/models/tools/apa';
import { UserContextService } from '@priz/shared/src/services/user';

const apaActionCollectionSelector = (state: AppState): ApaActionCollection => state.apaActions;

const apaActionEntitiesSelector: Selector<AppState, EntityMap<ApaAction>> = createSelector(
  apaActionCollectionSelector,
  collection => collection.entities || {},
);

const apaActionLookupsSelector: Selector<AppState, ApaActionLookups> = createSelector(
  apaActionCollectionSelector,
  collection => collection.lookups,
);

const apaActionStatusesSelector = createSelector(apaActionCollectionSelector, collection => collection.statuses);

const apaActionStatusByTeamIdSelector = (projectId: number): Selector<AppState, EntityCollectionStatus> =>
  createSelector(apaActionStatusesSelector, statuses => statuses.byTeamId[projectId]);

const apaActionStatusByProjectIdSelector = (projectId: number): Selector<AppState, EntityCollectionStatus> =>
  createSelector(apaActionStatusesSelector, statuses => statuses.byProjectId[projectId]);

const apaActionStatusByUtilizationIdSelector = (utilizationId: number): Selector<AppState, EntityCollectionStatus> =>
  createSelector(apaActionStatusesSelector, statuses => statuses.byUtilizationId[utilizationId]);

const isSavingByProjectId = (projectId: number) =>
  createSelector(apaActionStatusByProjectIdSelector(projectId), status => status && status.saving);

const isCreatingByProjectId = (projectId: number) =>
  createSelector(apaActionStatusByProjectIdSelector(projectId), status => status && status.creating);

const isCreatingByTeamId = (teamId: number) =>
  createSelector(apaActionStatusByTeamIdSelector(teamId), status => status && status.creating);

const isUpdatingByUtilizationId = (utilizationId: number) =>
  createSelector(apaActionStatusByUtilizationIdSelector(utilizationId), status => status && status.updating);

const isUpdatedByUtilizationId = (utilizationId: number) =>
  createSelector(apaActionStatusByUtilizationIdSelector(utilizationId), status => status && status.updated);

const isCreatingByProjectIdOrCurrentTeam = (projectId?: number) => {
  if (typeof projectId !== 'undefined') {
    return isCreatingByProjectId(projectId);
  } else {
    const teamId = UserContextService.getSelectedTeamId();
    return isCreatingByTeamId(teamId);
  }
};

const apaActionsLookupByUtilizationId = (utilizationId: number): Selector<AppState, number[]> =>
  createSelector(apaActionLookupsSelector, lookups => lookups.byUtilizationId[utilizationId] || []);

const getAllByUtilizatonId = (utilizationId: number): Selector<AppState, ApaAction[]> =>
  createSelector([apaActionEntitiesSelector, apaActionsLookupByUtilizationId(utilizationId)], (entityMap, ids) =>
    ids.reduce((arr, id) => {
      const action = entityMap[id];

      if (action) {
        arr.push(action);
      }
      return arr;
    }, []),
  );

const getById = (actionId: number): Selector<AppState, ApaAction> =>
  createSelector(apaActionEntitiesSelector, entityMap => entityMap[actionId]);

export const ApaActionSelectors = {
  getAllByUtilizatonId,
  getById,
  isSavingByProjectId,
  isUpdatingByUtilizationId,
  isUpdatedByUtilizationId,
  isCreatingByProjectId,
  isCreatingByProjectIdOrCurrentTeam,
};
