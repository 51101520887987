import React, { useEffect, useState } from 'react';
import { Status } from '@priz/shared/src/components/status/component';
import { Box, Button, ListItemText, MenuItem, MenuList } from '@mui/material';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { Trans } from 'react-i18next';
import { IdeaStatus } from '@priz/shared/src/models/idea';

interface IdeaStatusSelectorProps {
  status?: IdeaStatus;
  onSelect?: (status: IdeaStatus) => void;
  disabled?: boolean;
}

const options = [
  { value: IdeaStatus.Pending, text: 'Pending' },
  { value: IdeaStatus.Approved, text: 'Approved' },
  { value: IdeaStatus.Rejected, text: 'Rejected' },
  { value: IdeaStatus.Implemented, text: 'Implemented' },
];

export const IdeaStatusSelector: React.FC<IdeaStatusSelectorProps> = ({ status, onSelect, disabled }) => {
  const [value, setValue] = useState(status);
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);

  useEffect(() => {
    if (status && status !== value) {
      setValue(status);
    }
  }, [status]);

  const menuOpenHandle = (isOpen: boolean) => {
    setIsSelectMenuOpen(isOpen);
  };

  const selectHandle = (selectedValue: IdeaStatus) => {
    if (onSelect && selectedValue !== value) onSelect(selectedValue);
    setValue(selectedValue);
    setIsSelectMenuOpen(false);
  };

  return (
    <PopperWrap
      open={isSelectMenuOpen}
      onOpenToggle={menuOpenHandle}
      placement={'bottom-start'}
      dropdownProps={{ p: 0 }}
      arrow={false}
    >
      {!value && (
        <Button size={'small'} variant={'outlined'}>
          <Trans>Select status</Trans>
        </Button>
      )}

      {value && (
        <Button size="small" disabled={disabled}>
          <Status status={value} />
        </Button>
      )}

      <Box minWidth={200}>
        <MenuList>
          {options.map(item => (
            <MenuItem
              key={item.value}
              onClick={() => {
                selectHandle(item.value);
              }}
              selected={value === item.value}
              disabled={disabled}
            >
              <ListItemText>
                <Trans>{item.text}</Trans>
              </ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Box>
    </PopperWrap>
  );
};
