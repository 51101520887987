import { User } from '../../models/security/user';
import { UserUtils } from '../../utils/user/user-utils';
import { Workspace } from '../../models/workspace';
import { LocalesService } from '../../components/locales/services';
import { Locales } from '../../models/locales';
import { TimeZoneService } from '../time';

const getIntercom = (wait: 'loaded' | 'booted'): Promise<Intercom_.IntercomStatic> => {
  return new Promise<Intercom_.IntercomStatic>(resolve => {
    let intercomResolverInterval: NodeJS.Timeout | undefined = undefined;

    const clear = () => {
      clearInterval(intercomResolverInterval);
      intercomResolverInterval = undefined;
    };

    const resolveIntercom = () => {
      if ('Intercom' in window) {
        if (wait === 'loaded') {
          clear();
          resolve(window.Intercom);
        }

        if (wait === 'booted' && window.Intercom.booted) {
          clear();
          resolve(window.Intercom);
        }
      }
    };

    intercomResolverInterval = setInterval(() => {
      resolveIntercom();
    }, 500);

    resolveIntercom();
  });
};

interface IntercomUserResolverProps {
  user: User;
  workspaces?: Workspace[];
}

const resolveIntercomLanguageCode = (): string => {
  // The value of the language_override will only be used if it conforms to an ISO 639-1 two-letter code
  // such as 'en' for English or 'fr' for French. You’ll need to use a four-letter code for Chinese like 'zh-CN'.
  // For Norwegian, Intercom uses Norwegian Bokmål so the ISO code you should use for Norwegian is nb, not no

  const currentLocale = LocalesService.getCurrentLocale();

  const codesMap: { [key in Locales]: string } = {
    [Locales.EN]: 'en',
    [Locales.ES]: 'es',
    [Locales.ZH]: 'zh-CN',
  };

  return codesMap[currentLocale];
};

const resolveUserData = (props: IntercomUserResolverProps): Partial<Intercom_.IntercomSettings> => {
  const { user, workspaces = [] } = props;
  const { id: userId, email, profile } = user || {};
  const { phoneNumber, organization, location, timeZoneCode, avatarUrl } = profile || {};

  const dateCreatedTimestamp = user.dateCreated?.getTime();
  const unixDateCreatedTimestamp = dateCreatedTimestamp ? Math.floor(dateCreatedTimestamp / 1000) : undefined;
  const fullUserName = UserUtils.getShowableName(user);
  const languageCode = resolveIntercomLanguageCode();
  const timeZone = timeZoneCode ? TimeZoneService.getLabel(timeZoneCode) : undefined;

  const userData: Partial<Intercom_.IntercomSettings> = {
    user_id: userId.toString(),
    email: email,
    phone: phoneNumber,
    name: fullUserName,
    language_override: languageCode,
    created_at: unixDateCreatedTimestamp,

    // custom attributes
    organization,
    location,
    timezone: timeZone?.label,
  };

  if (workspaces.length) {
    userData.companies = workspaces.map(workspace => {
      const company: Intercom_.IntercomCompany = {
        id: workspace.id,
        name: workspace.name,
        plan: workspace.type,
        size: workspace.licensedSeats,
        user_count: workspace.workspaceMemberships?.length,
      };

      return company;
    });
  }

  if (avatarUrl) {
    userData.avatar = {
      type: 'avatar',
      image_url: avatarUrl,
    };
  }

  return userData;
};

const boot = (props: IntercomUserResolverProps) => {
  getIntercom('loaded').then(intercom => {
    intercom('boot', {
      // intercom settings
      api_base: 'https://api-iam.intercom.io',
      app_id: 'mopc9k8b',
      hide_default_launcher: false,

      // user data
      ...resolveUserData(props),
    });
  });
};

const track = (
  event: string,
  metadata?: { [key: string]: Intercom_.IntercomCustomAttribute | Date },
): Promise<void> => {
  return new Promise(resolve => {
    getIntercom('booted').then(intercom => {
      intercom('trackEvent', event, metadata);
      resolve();
    });
  });
};

const refresh = () => {
  getIntercom('loaded').then(intercom => {
    if (intercom.booted) {
      intercom('update');
    }
  });
};

export const IntercomService = {
  boot,
  track,
  refresh,
};
