import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    '&._compact': {
      '& .ql-editor': {
        padding: '0 10px 0 0',
      },
      '& $iconsWrap': {
        height: 26,
      },
    },
  },
  iconsWrap: {
    display: 'flex',
    alignItems: 'center',
    height: 42,
  },
  icon: {
    width: 18,
    height: 18,
    color: pgColorScheme.gray,
    display: 'block',
  },
});
