import { pgColorScheme } from '@priz/shared/src/theme';
import { drawRoundRect } from '@priz/shared/src/utils/ebs';

type EbsNodeId = string | number;

export type NodeWarnings = {
  [key in EbsNodeId]: {
    id: string | number;
    path: Path2D;
    show?: boolean;
  };
};

export const drawWarning = (
  ctx: CanvasRenderingContext2D,
  left: number,
  top: number,
  width: number,
  height: number,
  id: number,
  warnings: NodeWarnings,
  warningText: string[],
): Path2D => {
  const circleRadius = 9;
  const circleMargin = 4;
  const lineWidth = circleRadius * 0.2;
  const circleX = left + width - circleRadius - circleMargin;
  const circleY = top + height - circleRadius - circleMargin;

  if (warnings[id]?.show) {
    drawPopup(ctx, circleX + circleRadius, circleY + circleRadius, warningText);
  }

  const circle = new Path2D();
  circle.arc(circleX, circleY, circleRadius, 0, 2 * Math.PI, false);
  ctx.strokeStyle = pgColorScheme.orange;
  ctx.lineWidth = lineWidth;
  ctx.stroke(circle);

  ctx.beginPath();
  ctx.moveTo(circleX, circleY - circleRadius * 0.54);
  ctx.lineTo(circleX, circleY + circleRadius * 0.26);
  ctx.lineWidth = lineWidth;
  ctx.stroke();
  ctx.closePath();

  ctx.beginPath();
  ctx.moveTo(circleX, circleY + circleRadius * 0.26 + lineWidth);
  ctx.lineTo(circleX, circleY + circleRadius * 0.26 + lineWidth * 2);
  ctx.lineWidth = lineWidth;
  ctx.stroke();
  ctx.closePath();

  return circle;
};

export const drawPopup = (
  ctx: CanvasRenderingContext2D,
  left: number,
  top: number,
  text: string[],
  fontSize = 12,
  offsetX = 0,
  offsetY = 0,
) => {
  ctx.beginPath();
  ctx.globalCompositeOperation = 'source-over';
  ctx.font = `normal ${fontSize}px roboto`;
  ctx.textBaseline = 'top';
  ctx.textAlign = 'left';

  const textWidth = Math.max(...text.map(item => ctx.measureText(item).width));
  const textX = left + offsetX - textWidth / 2;
  const textY = top + offsetY + fontSize;

  ctx.fillStyle = pgColorScheme.white;
  ctx.strokeStyle = pgColorScheme.white;
  drawRoundRect(
    ctx,
    textX - fontSize / 2,
    textY - fontSize / 2,
    textWidth + fontSize,
    fontSize * (text.length + 1),
    fontSize / 4,
  );

  text.forEach((item, key) => {
    ctx.fillStyle = pgColorScheme.darkText;
    ctx.fillText(item, textX, textY + key * fontSize);
  });
  ctx.closePath();
};
