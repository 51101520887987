import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
    height: '100%',
  },
  container: {
    background: pgColorScheme.backgroundMediumGray,
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
});
