import React from 'react';
import { useStyles } from './styles';
import { Box } from '@mui/material';
import { ContentContainer } from './content-container/component';

interface PageContainerWithAsideNavProps {
  useContentContainer?: boolean;
}

export const PageContainerWithAsideNav: React.FC<PageContainerWithAsideNavProps> = ({
  useContentContainer,
  children,
}) => {
  const styles = useStyles();

  return (
    <div className={['page-container', styles.root].join(' ')}>
      <div className={'sidenav-container'}>{children[0]}</div>

      <div className={['page-content', styles.pageContent].join(' ')}>
        <div className={['header-container', styles.headerContainer].join(' ')}>{children[1]}</div>

        <Box className={['workbench-container', styles.workbenchContainer].join(' ')}>
          {useContentContainer && <ContentContainer>{children[2]}</ContentContainer>}

          {!useContentContainer && children[2]}
        </Box>
      </div>

      {children[3]}
    </div>
  );
};
