import React from 'react';
import { alpha, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Trans } from 'react-i18next';
import { PfmStepType } from '../../../models/tools/pfm';
import { pgColorScheme } from '../../../theme';
import { resolvePfmTypeIcon } from '../../../utils/pfm';

const diagnosisTableRowsData = {
  titles: ['Operation Type', 'Does it increase cost?', 'Does it increase product value?', 'Recommendation'],
  rows: [
    {
      type: PfmStepType.Productive,
      increaseCost: 'Yes',
      increaseValue: 'Yes',
      recommendation: 'Improve',
      color: pgColorScheme.green,
    },
    {
      type: PfmStepType.Providing,
      increaseCost: 'Yes',
      increaseValue: 'No',
      recommendation: 'Eliminate',
      color: pgColorScheme.blue,
    },
    {
      type: PfmStepType.Corrective,
      increaseCost: 'Yes',
      increaseValue: 'No',
      recommendation: 'Eliminate',
      color: pgColorScheme.orange,
    },
    {
      type: PfmStepType.Metrology,
      increaseCost: 'Yes',
      increaseValue: 'No',
      recommendation: 'Eliminate',
      color: pgColorScheme.purple,
    },
  ],
};

export const PfmDiagnosis: React.FC = () => {
  return (
    <TableContainer className={'pfm-diagnosis'} component={Paper} variant={'outlined'}>
      <Table sx={{ minWidth: 500 }} size={'small'}>
        <TableHead>
          <TableRow>
            {diagnosisTableRowsData.titles.map((title, key) => (
              <TableCell key={key}>
                <Trans>{title}</Trans>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {diagnosisTableRowsData.rows.map((row, key) => (
            <TableRow key={key} sx={{ backgroundColor: alpha(row.color, 0.1) }}>
              <TableCell component="th" scope="row">
                <Trans>
                  <Grid container spacing={1} alignItems={'center'} flexWrap={'nowrap'}>
                    <Grid item>{resolvePfmTypeIcon(row.type)}</Grid>

                    <Grid item>
                      <Trans>{row.type}</Trans>
                    </Grid>
                  </Grid>
                </Trans>
              </TableCell>

              <TableCell>
                <Trans>{row.increaseCost}</Trans>
              </TableCell>

              <TableCell>
                <Trans>{row.increaseValue}</Trans>
              </TableCell>

              <TableCell>
                <Trans>{row.recommendation}</Trans>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
