import React, { useRef } from 'react';
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchProps,
  ReactZoomPanPinchContentRef,
} from 'react-zoom-pan-pinch';
import { useStyles } from './styles';
import { Box, BoxProps } from '@mui/material';

export interface DragAndZoomContainerRef {
  fit: () => void;
  contextRef: React.MutableRefObject<ReactZoomPanPinchContentRef | null>;
  contentRef: React.MutableRefObject<HTMLDivElement | null>;
}

interface DragAndZoomContainerProps extends Omit<ReactZoomPanPinchProps, 'ref'> {
  contentProps?: BoxProps;
  canvasRef?: (ref: DragAndZoomContainerRef) => void;
}

export const DragAndZoomContainer: React.FC<DragAndZoomContainerProps> = ({
  contentProps,
  children,
  canvasRef,
  ...rest
}) => {
  const styles = useStyles();

  const contextRef = useRef<ReactZoomPanPinchContentRef>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const fit = () => {
    if (contextRef.current && contentRef.current) {
      contextRef.current.zoomToElement(contentRef.current, undefined, 0);
    }
  };

  if (canvasRef) {
    canvasRef({
      fit,
      contextRef,
      contentRef,
    });
  }

  return (
    <TransformWrapper
      minScale={0.1}
      maxScale={1}
      limitToBounds={false}
      doubleClick={{ disabled: true }}
      ref={contextRef}
      {...rest}
    >
      <TransformComponent wrapperClass={styles.transformWrapper} contentClass={styles.transformComponent}>
        <Box {...contentProps} component={'div'} ref={contentRef}>
          {children}
        </Box>
      </TransformComponent>
    </TransformWrapper>
  );
};
