import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import {
  PaywallSelectors,
  PaywallActions,
  PaywallState,
  blockerDataMap,
  CustomBlocker,
  defaultBlockerData,
} from '../store';
import { PlanActions } from '../../../../workspace/store/actions';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { PlanSelectors } from '../../../../workspace/store/selectors/plan.selectors';
import { AuthService } from '@priz/shared/src/services/auth';
import { PlanUpgradeButton } from '../../../../billing/plan-upgrade-button/component';
import { SignupDialogCallerButton } from '../../../../security/signup-dialog-caller-button/component';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';

interface FeatureBlockerDialogProps {
  anonymous?: boolean;
}

export const FeatureBlockerDialog: React.FC<FeatureBlockerDialogProps> = ({ anonymous }) => {
  const dispatch = useDispatch();

  const paywall: PaywallState = useSelector(PaywallSelectors.getLast);
  const isPlanLoaded = useSelector(PlanSelectors.isLoaded);
  const isAuthenticated = AuthService.isAuthenticated();

  const { title, description, anonymousTitle, anonymousDescription } = {
    ...defaultBlockerData,
    ...(blockerDataMap[paywall.blocker] || {}),
  };

  const titleText = (anonymous && anonymousTitle) || title;
  const descriptionText = (anonymous && anonymousDescription) || description;

  useEffect(() => {
    if (!isPlanLoaded && isAuthenticated) {
      dispatch(PlanActions.loadAll());
    }
  }, [isPlanLoaded, isAuthenticated]);

  const handleClose = () => {
    dispatch(PaywallActions.hide());
  };

  const signupDialogOpenHandler = () => {
    if (paywall.blocker !== CustomBlocker.AssistanceLimit) {
      LocalStorageService.setItem(LocalStorageKey.OpenPlanUpgradeDialog, 'true');
    }

    handleClose();
  };

  if (!paywall.blocker) {
    return null;
  }

  return (
    <>
      <Dialog open={paywall.show} fullWidth={true} maxWidth={'sm'} onClose={handleClose}>
        <DialogTitle>
          <Trans>{titleText}</Trans>
        </DialogTitle>

        {!!descriptionText?.length && (
          <DialogContent style={{ textAlign: 'center' }}>
            {descriptionText.map((row, key) => {
              return (
                <React.Fragment key={key}>
                  <Trans>{row}</Trans>
                  {descriptionText.length - 1 !== key && <br />}
                </React.Fragment>
              );
            })}
          </DialogContent>
        )}

        <DialogActions style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ButtonsWrap pt={1} spacing={1}>
            <Button variant={'pg_rounded'} color={'pg_orange_outlined'} size={'pg_small'} onClick={handleClose}>
              <Trans>Cancel</Trans>
            </Button>

            {!anonymous && <PlanUpgradeButton onClick={handleClose} />}

            {anonymous && <SignupDialogCallerButton size={'pg_small'} onClick={signupDialogOpenHandler} />}
          </ButtonsWrap>
        </DialogActions>
      </Dialog>
    </>
  );
};
