import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { ElementsViewerContext } from '@priz/shared/src/components/elements-viewer/elements-viewer-context/component';
import {
  HtmlElementViewer,
  HtmlElementViewerBaseProps,
} from '@priz/shared/src/components/elements-viewer/html-element-viewer/component';
import { CftNodesCollection } from '@priz/shared/src/models/tools/cft';

interface CftNodeViewerProps extends Omit<HtmlElementViewerBaseProps, 'watchElementsIds'> {
  data: CftNodesCollection;
}

export const CftNodeViewer: React.FC<CftNodeViewerProps> = ({ data, ...rest }) => {
  const viewerContext = useContext(ElementsViewerContext);

  const contentResolver = (nodeId: string) => {
    const node = data.nodesMap[nodeId];

    if (node) {
      return (
        <>
          <Typography variant={'subtitle2'}>
            <Trans>Description</Trans>
          </Typography>

          <Typography variant={'body2'} whiteSpace={'pre-line'}>
            {node.description || '-'}
          </Typography>

          {!!node.notes?.length && (
            <>
              <Typography variant={'subtitle2'} mt={2}>
                <Trans>Notes</Trans>
              </Typography>

              <Typography variant={'body2'} whiteSpace={'pre-line'}>
                {node.notes}
              </Typography>
            </>
          )}
        </>
      );
    }

    return null;
  };

  if (!viewerContext.enabled) {
    return null;
  }

  return (
    <HtmlElementViewer contentResolver={contentResolver} watchElementsIds={Object.keys(data.nodesMap)} {...rest} />
  );
};
