import React from 'react';
import { useQuery } from 'react-query';
import { WorkspaceApi } from '../../workspace/services';
import {
  Box,
  Button,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Trans } from 'react-i18next';
import CurrencyFormat from 'react-currency-format';
import { format } from 'date-fns';
import { FilledLabel } from '@priz/shared/src/components/filled-label/component';
import { CloudDownloadOutlined } from '@mui/icons-material';
import { Invoice } from '@priz/shared/src/models/billing';
import { useSelector } from 'react-redux';
import { PlanSelectors } from '../../workspace/store/selectors/plan.selectors';
import { WorkspaceSelectors } from '../../workspace/store/selectors';

const invoicesData: {
  label?: string;
  renderContent: (invoice: Invoice) => JSX.Element | string;
  tableCellProps?: TableCellProps;
}[] = [
  {
    label: 'Date',
    renderContent: invoice => format(invoice.periodStart * 1000, 'MMM d yyyy'),
  },
  {
    label: 'Payment due by',
    renderContent: invoice => format(invoice.periodEnd * 1000, 'MMM d yyyy'),
  },
  {
    label: 'Amount',
    renderContent: invoice => (
      <CurrencyFormat
        value={invoice.amountDue / 100}
        displayType={'text'}
        prefix={'$'}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    ),
  },
  {
    label: 'Status',
    renderContent: invoice => (
      <FilledLabel text={invoice.paid ? 'Paid' : 'Not paid'} preset={invoice.paid ? 'green' : 'gray'} size={'small'} />
    ),
  },
  {
    renderContent: invoice => (
      <Button variant="text" component={'a'} href={invoice.invoicePdf} download startIcon={<CloudDownloadOutlined />}>
        <Trans>Download pdf</Trans>
      </Button>
    ),
    tableCellProps: { width: '10%' },
  },
];

export const InvoiceList: React.FC = () => {
  const workspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const currentPlan = useSelector(PlanSelectors.getCurrentPlan);

  const { isLoading, isError, data, isFetching } = useQuery(
    ['invoices', workspace?.id, currentPlan?.id],
    () => WorkspaceApi.getInvoices(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  if (isLoading || isError || isFetching || !data || data.length < 1) {
    return null;
  }

  return (
    <Box>
      <Box mb={2}>
        <Typography variant={'h6'}>
          <Trans>Past Invoices</Trans>
        </Typography>
      </Box>

      <Hidden mdDown>
        <TableContainer component={Paper} variant={'outlined'}>
          <Table>
            <TableHead sx={{ background: pgColorScheme.background }}>
              <TableRow>
                {invoicesData.map((item, key) => (
                  <TableCell key={key} width={'20%'} {...item.tableCellProps}>
                    {item.label && <Trans>{item.label}</Trans>}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map(invoice => (
                <TableRow key={invoice.id}>
                  {invoicesData.map((item, key) => (
                    <TableCell key={key} width={'20%'} {...item.tableCellProps}>
                      {item.renderContent(invoice)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>

      <Hidden mdUp>
        <div>
          {data.map(invoice => (
            <Box key={invoice.id} mb={2}>
              <Paper>
                <Box p={1}>
                  {invoicesData.map((item, key) => (
                    <Box key={key} mb={key === invoicesData.length - 1 ? 0 : 1}>
                      {item.label && (
                        <Typography variant={'subtitle2'} component={'div'}>
                          <Trans>{item.label}</Trans>
                        </Typography>
                      )}

                      {item.renderContent(invoice)}
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Box>
          ))}
        </div>
      </Hidden>
    </Box>
  );
};
