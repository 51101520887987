import React, { useState } from 'react';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Trans, useTranslation } from 'react-i18next';
import { AttachFile, HelpOutline } from '@mui/icons-material';
import { HelperText } from '@priz/shared/src/components/form-elements';
import { bytesToMb } from '@priz/shared/src/utils/common';
import { CloseRounded } from '@mui/icons-material';
import mime from 'mime';

export interface FilesSelectButtonProps {
  onSelect: (files: File[]) => void;
  maxFilesCount?: number;
  maxFileSizeInBytes?: number;
  loading?: boolean;
  disabled?: boolean;
}

const acceptableFiles = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  webp: 'image/webp',
  heif: 'image/heif',
  heic: 'image/heic',
  txt: 'text/plain',
  rtf: 'application/rtf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pdf: 'application/pdf',
  mp4: 'video/mp4',
  mp3: 'audio/mpeg',
  csv: 'text/csv',
};

export const FilesSelectButton: React.FC<FilesSelectButtonProps> = ({
  onSelect,
  maxFilesCount,
  maxFileSizeInBytes,
  loading,
  disabled,
}) => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState<{ fileName: string; errorText: string }[]>([]);

  const helperText = [];

  if (maxFilesCount && maxFilesCount > 1) helperText.push(`${t('Up to')} ${maxFilesCount} ${t('files per upload')}.`);
  if (maxFileSizeInBytes) helperText.push(`${t('Max file size')}: ${bytesToMb(maxFileSizeInBytes)}MB.`);

  const fileSelectHandler = (files: FileList | null) => {
    const filesArray: File[] = files
      ? Array.from(files).filter(file => {
          const extension = mime.getExtension(file.type);
          const extensionIsValid = Object.keys(acceptableFiles).includes(extension);
          const fileSizeIsValid = maxFileSizeInBytes ? file.size <= maxFileSizeInBytes : true;

          if (!extensionIsValid) {
            setError(file.name, `${extension ? extension + ' ' : ''}${t('extension is not valid')}`);
          } else if (!fileSizeIsValid) {
            setError(file.name, `${t('file is too big')} (${bytesToMb(file.size)}mb)`);
          }

          return extensionIsValid && fileSizeIsValid;
        })
      : [];

    onSelect(filesArray.slice(0, maxFilesCount || filesArray.length));
  };

  const setError = (fileName: string, errorText: string) => {
    setErrors(currentState => [...currentState, { fileName, errorText }]);
  };

  const removeError = (fileName: string) => {
    setErrors(currentState => currentState.filter(error => error.fileName !== fileName));
  };

  return (
    <>
      {!!errors.length &&
        errors.map((error, key) => {
          return (
            <Box key={key} mt={0.5}>
              <Grid container alignItems={'center'} flexWrap={'nowrap'} spacing={1}>
                <Grid item>
                  <HelperText error={`${error.fileName}: ${error.errorText}`} />
                </Grid>

                <Grid item xs={'auto'}>
                  <IconButton
                    size={'small'}
                    onClick={() => {
                      removeError(error.fileName);
                    }}
                  >
                    <CloseRounded fontSize={'small'} />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          );
        })}

      <label>
        <input
          type="file"
          multiple={typeof maxFilesCount !== 'undefined' ? maxFilesCount > 1 : true}
          accept={Object.values(acceptableFiles).join(', ')}
          onChange={e => {
            fileSelectHandler(e.target.files);
          }}
          disabled={disabled}
          hidden
        />

        <LoadingButton
          component={'div'}
          size={'small'}
          loadingPosition={'start'}
          loading={loading}
          disabled={disabled || loading}
          startIcon={<AttachFile />}
        >
          <Trans>Attach files</Trans>
        </LoadingButton>
      </label>

      {!!helperText.length && (
        <Box mt={0.5}>
          <HelperText text={helperText.join(' ')} />

          <Tooltip
            title={`${t('Accepted file formats')}: ${Object.keys(acceptableFiles).join(', ')}`}
            placement={'top'}
            disableInteractive
            arrow
          >
            <HelpOutline sx={{ verticalAlign: 'sub', paddingLeft: 0.5 }} color={'action'} fontSize={'small'} />
          </Tooltip>
        </Box>
      )}
    </>
  );
};
