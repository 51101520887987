import React from 'react';
import { Trans } from 'react-i18next';
import { Button, ButtonProps } from '@mui/material';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm/rrm-goal-type.enum';
import { Goal } from '@priz/shared/src/models/tools/rrm';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { Link } from 'react-router-dom';
import { ToolsUtils } from '../utils';
import { ToolType } from '@priz/shared/src/models/tools';

const resolveBackToCallerButton = (goal: Goal): string => {
  switch (goal.goalType) {
    case RrmGoalType.IDEA:
      return 'Back to ideas manager';
    case RrmGoalType.TASK:
      return 'Back to tasks manager';
    case RrmGoalType.UIM_TASK:
      return 'Back to tasks list';
    case RrmGoalType.EBS:
      return 'Back to Brainstorming';
    case RrmGoalType.FIVE_WHYS_SOLUTION:
      return 'Back to 5 Whys Analysis';
    case RrmGoalType.P_MAP:
      return 'Back to Perception mapping';
    default:
      return undefined;
  }
};

const resolveRedirectUrl = (goal: Goal) => {
  switch (goal.goalType) {
    case RrmGoalType.IDEA:
      return ['/project', goal.project.id, 'ideas'].join('/');

    case RrmGoalType.TASK:
      return ['/project', goal.project.id, 'tasks'].join('/');

    case RrmGoalType.UIM_TASK:
      return ToolsUtils.resolvePath(ToolType.UIM, goal.sourceUtilizationId, goal.project.id);

    case RrmGoalType.EBS:
      LocalStorageService.setItem(LocalStorageKey.EbsEnableGrouping, 'true');
      return ToolsUtils.resolvePath(ToolType.EBS, goal.sourceUtilizationId, goal.project.id);

    case RrmGoalType.FIVE_WHYS_SOLUTION:
      return ToolsUtils.resolvePath(ToolType.FIVE_WHYS, goal.sourceUtilizationId, goal.project.id);

    case RrmGoalType.P_MAP:
      LocalStorageService.setItem(LocalStorageKey.PMapShowConclusion, 'true');
      return ToolsUtils.resolvePath(ToolType.P_MAP, goal.sourceUtilizationId, goal.project.id);

    default:
      return;
  }
};

export interface RedirectToCallerButtonProps extends Pick<ButtonProps, 'size'> {
  goal: Goal;
}

export const RedirectToCallerButton: React.FC<RedirectToCallerButtonProps> = ({ goal, ...rest }) => {
  return (
    <Button component={Link} to={resolveRedirectUrl(goal)} variant={'pg_rounded'} color={'pg_orange_solid'} {...rest}>
      <Trans>{resolveBackToCallerButton(goal)}</Trans>
    </Button>
  );
};
