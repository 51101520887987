import React from 'react';
import { Task } from '@priz/shared/src/models/task';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { TaskControls } from '../task-controls/components';
import { TaskQuickMenu } from '../task-quick-menu/components';
import { RankingScore } from '@priz/shared/src/components/ranking-score/component';
import { useStyles } from './styles';
import { PriorityIndicator } from '@priz/shared/src/components/priority-indicator/component';
import { UimPriority } from '@priz/shared/src/models/tools/uim';
import { ToolLinkResolver } from '../../tools/tool-link-resolver/component';

interface TaskCardProps {
  task: Task;
  disabled?: boolean;
}

export const TaskCard: React.FC<TaskCardProps> = ({ task, disabled }) => {
  const styles = useStyles();

  return (
    <Paper variant={'outlined'} component={Box} p={1}>
      <Grid container alignItems={'flex-start'} justifyContent={{ xs: 'space-between', sm: 'flex-start' }} spacing={1}>
        <Grid item xs={'auto'} order={1}>
          {typeof task.latestRankingScore === 'number' && (
            <Box className={styles.minHeightWrap}>
              <RankingScore rankingScore={task.latestRankingScore} />
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sm={true} order={{ xs: 4, sm: 2 }}>
          <Box className={styles.minHeightWrap}>
            <Typography variant={'body2'} sx={{ wordBreak: 'break-word' }}>
              <b>{task.title}</b>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={'auto'} order={3}>
          <TaskControls task={task} disabled={disabled} />
        </Grid>
      </Grid>

      <Box mt={1}>
        <Grid container alignItems={'center'} spacing={2}>
          <Grid item>
            <TaskQuickMenu task={task} disabled={disabled} />
          </Grid>

          {task.sourceToolUtilization && (
            <Grid item overflow={'hidden'}>
              <ToolLinkResolver utilizationId={task.sourceToolUtilization.id} sourceId={task.sourceId} />
            </Grid>
          )}

          {task.priority && task.priority !== UimPriority.Undefined && (
            <Grid item xs={'auto'} marginLeft={'auto'}>
              <PriorityIndicator priority={task.priority} size={'small'} rounded />
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};
