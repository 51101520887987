import React, { useEffect } from 'react';
import { Alert, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ApiKeysSelectors } from '../store/selectors';
import { ApiKeysActions } from '../store/actions';
import { LoadingButton } from '@mui/lab';
import { Trans } from 'react-i18next';
import { EditableApiKeyCard } from '../editable-api-key-card/component';
import { ApiKey, ApiKeyStatus } from '@priz/shared/src/models/api-keys';

interface ApiKeysManagerProps {
  userId: number;
  statusToShow: ApiKeyStatus;
}

type ApiKeysMap = {
  [ApiKeyStatus.Active]: ApiKey[];
  [ApiKeyStatus.Expired]: ApiKey[];
};

export const ApiKeysManager: React.FC<ApiKeysManagerProps> = ({ userId, statusToShow }) => {
  const dispatch = useDispatch();

  const apiKeys = useSelector(ApiKeysSelectors.getApiKeysByUserId(userId));
  const isCreating = useSelector(ApiKeysSelectors.isCreatingByUserId(userId));

  const apiKeysMap = apiKeys.reduce(
    (map, key) => {
      map[key.status].push(key);
      return map;
    },
    { [ApiKeyStatus.Active]: [], [ApiKeyStatus.Expired]: [] } as ApiKeysMap,
  );

  const isActiveApiKeysLimitReached = apiKeysMap[ApiKeyStatus.Active].length >= 5;

  useEffect(() => {
    dispatch(ApiKeysActions.load(userId));
  }, []);

  const create = () => {
    dispatch(ApiKeysActions.create(userId));
  };

  return (
    <Box>
      <Box mb={2}>
        {apiKeysMap[statusToShow].map(apiKey => (
          <EditableApiKeyCard key={apiKey.id} apiKey={apiKey} mb={2} />
        ))}

        {!apiKeysMap[statusToShow].length && (
          <Alert severity={'info'}>
            {statusToShow === ApiKeyStatus.Active && (
              <Trans>There is no API keys. Click "Create new API key" button to create new API key.</Trans>
            )}

            {statusToShow === ApiKeyStatus.Expired && <Trans>There is no expired API keys.</Trans>}
          </Alert>
        )}
      </Box>

      {isActiveApiKeysLimitReached && statusToShow !== ApiKeyStatus.Expired && (
        <Box mb={2}>
          <Alert severity={'info'}>
            <Trans>Active API keys limit reached.</Trans>
          </Alert>
        </Box>
      )}

      {statusToShow !== ApiKeyStatus.Expired && (
        <LoadingButton
          onClick={create}
          loading={isCreating}
          disabled={isCreating || isActiveApiKeysLimitReached}
          variant={'outlined'}
        >
          <Trans>Create new API key</Trans>
        </LoadingButton>
      )}
    </Box>
  );
};
