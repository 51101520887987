import React from 'react';
import { useStyles } from './styles';
import { Trans } from 'react-i18next';
import { Box } from '@mui/material';

export interface NavigationProps {
  onLoginNavigate?: () => void;
  onSignUpNavigate?: () => void;
}

export enum AuthTabsType {
  Login = 'login',
  Signup = 'signup',
  PasswordReset = 'password-reset',
}

export interface AuthTabsProps extends NavigationProps {
  activeTab: AuthTabsType;
}

export const AuthTabs: React.FC<AuthTabsProps> = ({ activeTab, onLoginNavigate, onSignUpNavigate }) => {
  const styles = useStyles();

  if (!onLoginNavigate || !onSignUpNavigate) {
    return null;
  }

  return (
    <Box className={styles.root} mb={3}>
      <div className={`${styles.tab}${activeTab === AuthTabsType.Login ? ' _active' : ''}`} onClick={onLoginNavigate}>
        <Trans>Login</Trans>
      </div>

      <div className={`${styles.tab}${activeTab === AuthTabsType.Signup ? ' _active' : ''}`} onClick={onSignUpNavigate}>
        <Trans>Sign up</Trans>
      </div>
    </Box>
  );
};
