import React from 'react';
import { Principle } from '@priz/shared/src/models/tools/forty-principles/principle';
import { Parameter } from '@priz/shared/src/models/tools/forty-principles/parameter';
import { MatrixElement } from '@priz/shared/src/models/tools/forty-principles/matrix-element';
import { FortyPrinciplesUtilization } from '@priz/shared/src/models/tools/forty-principles/forty-principles-utilization';
import {
  resolveMatchingPrinciples,
  resolveParameterTitleById,
} from '@priz/shared/src/utils/inventive-principles/inventive-principles';
import { FortyPrinciplesViewer } from '@priz/shared/src/components/tools-viewers';

interface FortyPrinciplesResultProps {
  utilization: FortyPrinciplesUtilization;
  principles: Principle[];
  parameters: Parameter[];
  matrixElements: MatrixElement[];
}

export const FortyPrinciplesResult: React.FC<FortyPrinciplesResultProps> = ({
  utilization,
  principles,
  parameters,
  matrixElements,
}) => {
  if (!principles || !parameters || !matrixElements) return null;

  const matchingPrinciples = resolveMatchingPrinciples(utilization, principles, matrixElements);

  if (utilization.improvingParameter) {
    utilization.improvingParameter.title = resolveParameterTitleById(parameters, utilization.improvingParameter.id);
  }

  if (utilization.worseningParameter) {
    utilization.worseningParameter.title = resolveParameterTitleById(parameters, utilization.worseningParameter.id);
  }

  return <FortyPrinciplesViewer utilization={utilization} matchingPrinciples={matchingPrinciples} />;
};
