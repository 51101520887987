import React from 'react';
import { Trans } from 'react-i18next';
import { ButtonsWrap } from '../../buttons-wrap/component';
import { Button } from '@mui/material';
import { Auth0RedirectKey, AuthFormType } from '../../../models/auth0';
import { AuthService } from '../../../services/auth';

interface SocialLoginButtonsProps {
  formType: AuthFormType;
  redirectKey: Auth0RedirectKey;
  beforeLogin?: () => void;
  beforeSignup?: () => void;
}

export const SocialLoginButtons: React.FC<SocialLoginButtonsProps> = ({
  formType,
  redirectKey,
  beforeLogin,
  beforeSignup,
}) => {
  const loginWithGoogle = () => {
    beforeSend();
    AuthService.authorizeWithGoogle(redirectKey);
  };

  const loginWithLinkedIn = () => {
    beforeSend();
    AuthService.authorizeWithLinkedIn(redirectKey);
  };

  const beforeSend = () => {
    if (redirectKey === Auth0RedirectKey.Login && beforeLogin) beforeLogin();
    if (redirectKey === Auth0RedirectKey.Signup && beforeSignup) beforeSignup();
  };

  return (
    <ButtonsWrap stretch={true}>
      <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={loginWithGoogle}>
        {formType === AuthFormType.Login && <Trans>Login with Google</Trans>}
        {formType === AuthFormType.SignUp && <Trans>Sign up with Google</Trans>}
      </Button>

      <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={loginWithLinkedIn}>
        {formType === AuthFormType.Login && <Trans>Login with LinkedIn</Trans>}
        {formType === AuthFormType.SignUp && <Trans>Sign up with LinkedIn</Trans>}
      </Button>
    </ButtonsWrap>
  );
};
