import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  title: {
    color: pgColorScheme.darkGray,
  },
  image: {
    display: 'block',
    width: 443,
    maxWidth: '100%',
    height: 'auto',
    margin: 'auto',
  },
  content: {
    textAlign: 'center',
  },
});
