import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useStyles } from './styles';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { Link } from 'react-router-dom';
import { MenuRounded } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';

interface DashboardItemCardProps {
  text: string;
  path: string | (string | number)[];
  hash?: string;
  controls?: JSX.Element;
  menuContent?: JSX.Element;
  loading?: boolean;
}

export const DashboardItemCard: React.FC<DashboardItemCardProps> = ({
  text,
  path,
  hash = '',
  controls,
  menuContent,
  loading = false,
}) => {
  const styles = useStyles();
  const clearText = text?.replace(/(<.*?>)/gi, '')?.trim() || '';
  const to = typeof path === 'string' ? path : path.join('/');

  return (
    <Box className={styles.root} pr={1} sx={{ pointerEvents: loading ? 'none' : 'auto' }}>
      <LoadingOverlay size={24} loading={loading} backdropStyles={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }} />

      <Grid container alignItems={'center'}>
        <Grid item xs={true} overflow={'hidden'}>
          <Box component={Link} display={'block'} p={1} className={styles.link} to={to + hash}>
            {!!clearText.length && (
              <Typography variant={'subtitle2'} noWrap>
                {clearText}
              </Typography>
            )}

            {!clearText.length && (
              <Typography variant={'body2'} fontStyle={'italic'} noWrap>
                <Trans>Click to view</Trans>
              </Typography>
            )}
          </Box>
        </Grid>

        {controls && (
          <Grid item xs={'auto'}>
            {controls}
          </Grid>
        )}

        {menuContent && (
          <Grid item xs={'auto'}>
            <PopperWrap>
              <MenuRounded />

              <Box>{menuContent}</Box>
            </PopperWrap>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
