import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { PgLink } from '../pg-link/component';
import { Trans } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

interface PgConfirmationDialogProps {
  isOpen: boolean;
  cancelButtonText?: string | ReactElement;
  okButtonText?: string | ReactElement;
  confirmTitle?: string | ReactElement;
  confirmContent?: string | ReactElement;
  linkText?: string | ReactElement;
  linkHref?: string;
  onConfirm?: () => void;
  onClose?: () => void;
  maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl';
  loading?: boolean;
  disabled?: boolean;
  submitDisabled?: boolean;
  showCancelButton?: boolean;
  showOkButton?: boolean;
  extraButtons?: JSX.Element;
}

export const PgConfirmationDialog: React.FC<PgConfirmationDialogProps> = ({
  cancelButtonText,
  confirmContent,
  confirmTitle,
  isOpen,
  okButtonText,
  onConfirm,
  onClose,
  linkText,
  linkHref,
  maxWidth,
  loading,
  disabled,
  submitDisabled,
  showCancelButton = true,
  showOkButton = true,
  extraButtons,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={maxWidth}>
      <DialogTitle>{confirmTitle || <Trans>Confirm...</Trans>}</DialogTitle>

      <DialogContent>
        <DialogContentText component={'div'}>{confirmContent || <Trans>Are you sure?</Trans>}</DialogContentText>
      </DialogContent>

      <DialogActions style={linkText && linkHref ? { display: 'flex' } : {}}>
        {linkText && linkHref && (
          <PgLink style={{ marginRight: 'auto' }} href={linkHref} target="_blank">
            {linkText}
          </PgLink>
        )}

        {showCancelButton && (
          <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose} disabled={disabled}>
            {cancelButtonText || <Trans>Cancel</Trans>}
          </Button>
        )}

        {showOkButton && (
          <LoadingButton
            variant={'pg_rounded'}
            color={'pg_orange_solid'}
            onClick={handleConfirm}
            disabled={disabled || submitDisabled || loading}
            loading={loading}
          >
            {okButtonText || <Trans>Yes</Trans>}
          </LoadingButton>
        )}

        {extraButtons}
      </DialogActions>
    </Dialog>
  );
};
