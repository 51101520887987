import { RouteObject } from 'react-router-dom';
import { RouteRenderer } from './route-renderer/component';
import { Dashboard } from '../dashboard/component';
import { AcceptInvitation } from '../security/accept-invitation/component';
import { VerifyEmail } from '../security/verify-email/component';
import { Login } from '../security/login/component';
import { Signup } from '../security/signup/component';
import { RequestPasswordReset } from '../security/request-password-reset/component';
import { Logout } from '../security/logout/component';
import { ProjectWizardPage } from '../project/project-wizard/project-wizard-page/component';
import { ProjectWizardCallback } from '../project/project-wizard/project-wizard-callback/component';
import { AcceptMeetingInvitation } from '../meetings/accept-meeting-invitation/component';
import { PMapPublicPage } from '../pmap/pmap-public-page/component';
import { LoginCallback } from '../security/login-callback/component';
import { SignUpCallback } from '../security/signup-callback/component';
import { AcceptInvitationCallback } from '../security/accept-invitation-callback/component';
import { ConnectAccountsCallback } from '../security/connect-accounts-callback/component';
import { documentTitleResolver } from './utils';

export const appRoutes: RouteObject[] = [
  {
    path: 'dashboard',
    element: <RouteRenderer Component={Dashboard} authGuard={true} />,
    loader: documentTitleResolver('Dashboard'),
  },
  {
    path: 'accept-invitation',
    element: <RouteRenderer Component={AcceptInvitation} />,
  },
  {
    path: 'accept-meeting-invitation',
    element: <RouteRenderer Component={AcceptMeetingInvitation} />,
    loader: documentTitleResolver('Meeting Invitation'),
  },
  {
    path: 'verify-email',
    element: <RouteRenderer Component={VerifyEmail} />,
  },
  {
    path: 'login-callback',
    element: <RouteRenderer Component={LoginCallback} />,
  },
  {
    path: 'signup-callback',
    element: <RouteRenderer Component={SignUpCallback} />,
  },
  {
    path: 'invitation-callback',
    element: <RouteRenderer Component={AcceptInvitationCallback} />,
  },
  {
    path: 'connect-callback',
    element: <RouteRenderer Component={ConnectAccountsCallback} />,
  },
  {
    path: 'login',
    element: <RouteRenderer Component={Login} />,
    loader: documentTitleResolver('Login'),
  },
  {
    path: 'signup',
    element: <RouteRenderer Component={Signup} />,
    loader: documentTitleResolver('Signup'),
  },
  {
    path: 'request-password-reset',
    element: <RouteRenderer Component={RequestPasswordReset} />,
    loader: documentTitleResolver('Password Reset'),
  },
  {
    path: 'logout',
    element: <RouteRenderer Component={Logout} />,
  },
  {
    path: 'project-wizard',
    element: <RouteRenderer Component={ProjectWizardPage} />,
    loader: documentTitleResolver('Project Wizard'),
  },
  {
    path: 'project-wizard-callback',
    element: <RouteRenderer Component={ProjectWizardCallback} authGuard={true} forceBilling={false} />,
    loader: documentTitleResolver('Project Wizard'),
  },
  {
    path: 'public-pmap/:utilizationId',
    element: <RouteRenderer Component={PMapPublicPage} forceBilling={false} />,
    loader: documentTitleResolver('PMap Public'),
  },
];
