import { createStyles, makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      wordBreak: 'break-word',
      padding: 8,
      boxSizing: 'border-box',

      ['&.arrow-padding']: {
        padding: 16,
      },

      ['&[data-popper-placement="top"], &[data-popper-placement="top-start"], &[data-popper-placement="top-end"]']: {
        ['& $arrow']: {
          top: 'calc(100% - 9px)',
          bottom: 'auto',

          ['&::before']: {
            transform: 'rotate(225deg)',
          },
        },
      },
      ['&[data-popper-placement="bottom"], &[data-popper-placement="bottom-start"], &[data-popper-placement="bottom-end"]']:
        {
          ['& $arrow']: {
            top: 'auto',
            bottom: 'calc(100% - 9px)',

            ['&::before']: {
              transform: 'rotate(45deg)',
            },
          },
        },

      ['&[data-popper-placement="left"], &[data-popper-placement="left-start"], &[data-popper-placement="left-end"]']: {
        ['& $arrow']: {
          right: 'auto',
          left: 'calc(100% - 9px)',

          ['&::before']: {
            transform: 'rotate(135deg)',
          },
        },
      },

      ['&[data-popper-placement="right"], &[data-popper-placement="right-start"], &[data-popper-placement="right-end"]']:
        {
          ['& $arrow']: {
            right: 'calc(100% - 9px)',
            left: 'auto',

            ['&::before']: {
              transform: 'rotate(315deg)',
            },
          },
        },
    },
    zFront: {
      zIndex: theme.zIndex.modal + 100,
    },
    zBack: {
      zIndex: theme.zIndex.modal - 1,
    },
    hidePopperIfEscaped: {
      ['&[data-popper-escaped]']: {
        display: 'none',
      },
    },
    hidePopperIfReferenceHidden: {
      ['&[data-popper-reference-hidden]']: {
        display: 'none',
      },
    },
    content: {
      position: 'relative',
    },
    paper: {
      borderRadius: 8,
      maxHeight: 'calc(100vh - 30px)',
      overflow: 'auto',
    },
    paperBorder: {
      boxShadow: '0px 0px 1px 1px rgba(0, 102, 157, 0.12)',
    },
    paperShadow: {
      boxShadow: '0 0 18px rgba(0,0,0,.2)',
    },
    avatarLink: {
      textDecoration: 'none',
    },
    nameLink: {
      display: 'block',
      wordBreak: 'break-word',
      textDecoration: 'none',
      color: pgColorScheme.darkText,

      ['&:hover']: {
        color: pgColorScheme.blue,
      },
    },
    arrow: {
      width: 18,
      height: 18,

      ['&::before']: {
        content: '""',
        margin: 'auto',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
        transformOrigin: '50% 50%',
        top: '0',
        left: '0',
        bottom: 'auto',
        right: 'auto',
        boxSizing: 'border-box',
        boxShadow: '-1px -1px 1px 0px rgb(0 102 157 / 12%)',
        zIndex: 1,
      },
    },
    white: {
      ['& $paper']: {
        backgroundColor: pgColorScheme.white,
        color: pgColorScheme.darkText,
      },
      ['& $arrow']: {
        ['&::before']: {
          backgroundColor: pgColorScheme.white,
        },
      },
    },
    blue: {
      ['& $paper']: {
        backgroundColor: pgColorScheme.blue,
        color: pgColorScheme.white,
      },
      ['& $arrow']: {
        ['&::before']: {
          backgroundColor: pgColorScheme.blue,
        },
      },
    },
  }),
);
