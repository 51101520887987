import React from 'react';
import { CellEditor } from '../cell-editor/component';
import { Principle } from '@priz/shared/src/models/tools/forty-principles/principle';
import { MatrixElement } from '@priz/shared/src/models/tools/forty-principles/matrix-element';
import { Domain } from '@priz/shared/src/models/tools/forty-principles/domain';
import { Parameter } from '@priz/shared/src/models/tools/forty-principles/parameter';

interface CellContentProps {
  row: Partial<Principle> | MatrixElement | Domain | Parameter;
  column: string;
  rowId: string;
  editable: boolean;
  useQuillForColumns: string[];
  onChange: (val: string | number) => void;
}

export const CellContent: React.FC<CellContentProps> = ({
  row,
  column,
  rowId,
  editable,
  useQuillForColumns,
  onChange,
}) => {
  return editable ? (
    <CellEditor
      name={`${column}-${rowId}`}
      value={row[column]}
      onChange={onChange}
      useQuill={useQuillForColumns.includes(column)}
    />
  ) : (
    <span>{row[column]}</span>
  );
};
