import { ToolType } from '@priz/shared/src/models/tools';
import { FeatureLimit } from '@priz/shared/src/models/workspace';

export enum CustomBlocker {
  Seats = 'Seats',
  AssistanceLimit = 'AssistanceLimit',
}

export type BlockerName = ToolType | FeatureLimit | CustomBlocker;

export type BlockerData = {
  title: string;
  description?: string[];
  anonymousTitle?: string;
  anonymousDescription?: string[];
};

export type Blocker = {
  [name in BlockerName]: BlockerData;
};

export const defaultBlockerData: BlockerData = {
  title: "You've reached a limit",
  anonymousDescription: [],
};

export const blockerDataMap: Partial<Blocker> = {
  // Tools

  [ToolType.RRM]: {
    title: `You've reached a limit of maximum allowed options`,
    description: [
      'Our free plan only allows 5 option in RRR tool.',
      'Upgrade workspace or create new to enable more options.',
    ],
  },
  [ToolType.UIM]: {
    title: `You've reached a limit of maximum allowed tasks`,
    description: [
      'Our free plan only allows 5 tasks in UIM tool.',
      'Upgrade workspace or create new to enable more tasks.',
    ],
  },
  [ToolType.FIVE_WHYS]: {
    title: `You've reached a limit of maximum allowed causes`,
    description: [
      'Our free plan only allows 5 causes in 5+ Whys tool.',
      'Upgrade workspace or create new to enable more causes.',
    ],
  },
  [ToolType.EBS]: {
    title: `You've reached a limit of maximum allowed ideas`,
    description: [
      'Our free plan only allows 5 ideas in EBS tool.',
      'Upgrade workspace or create new to enable more ides.',
    ],
  },
  [ToolType.CEC]: {
    title: `You've reached a limit of maximum allowed nodes`,
    description: [
      'Our free plan only allows 5 nodes in CEC tool.',
      'Upgrade workspace or create new to enable more nodes.',
    ],
  },
  [ToolType.P_MAP]: {
    title: `You've reached a limit of maximum allowed perceptions`,
    description: [
      'Our free plan only allows 5 perceptions in Perception Mapping tool.',
      'Upgrade workspace or create new to enable more perceptions.',
    ],
  },
  [ToolType.APA]: {
    title: `You've reached a limit of maximum allowed actions`,
    description: [
      'Our free plan only allows 1 action in APA tool.',
      'Upgrade workspace or create new to enable more actions.',
    ],
  },
  [ToolType.SFM]: {
    title: `You've reached a limit of maximum allowed nodes`,
    description: [
      'Our free plan only allows 8 nodes in SFM tool.',
      'Upgrade workspace or create new to enable more nodes.',
    ],
  },
  [ToolType.PFM]: {
    title: `You've reached a limit of maximum allowed steps`,
    description: [
      'Our free plan only allows 3 steps in PFM tool.',
      'Upgrade workspace or create new to enable more nodes.',
    ],
  },

  // Features

  [FeatureLimit.MaxProjects]: {
    title: `You've reached a limit of maximum allowed active projects`,
    description: [
      'Our free plan only allows 3 active projects at the same time.',
      'Upgrade workspace or create new to enable more projects.',
    ],
  },
  [FeatureLimit.MinWorkspaceMembers]: {
    title: 'Maximum number of workspaces has been reached',
  },
  [FeatureLimit.MaxWorkspaceMembers]: {
    title: 'Maximum number of members has been reached',
  },
  [FeatureLimit.MaxGuests]: {
    title: 'Maximum number of guests has been reached',
  },
  [FeatureLimit.MaxIdeas]: {
    title: `You've reached a limit of maximum allowed project ideas`,
    description: [`Our free plan only allows 3 ideas managed in the same project.`],
  },
  [FeatureLimit.MaxTasks]: {
    title: 'Maximum number of tasks has been reached',
  },
  [FeatureLimit.IdeasCertification]: {
    title: 'Ideas certification is not available on current plan',
  },
  [FeatureLimit.ProjectLevelPermissions]: {
    title: 'Project level permissions is not available on current plan',
  },
  [FeatureLimit.AuditTrail]: {
    title: 'Audit trail is not available on current plan',
  },
  [FeatureLimit.Encryption]: {
    title: 'Encryption is not available on current plan',
  },
  [FeatureLimit.SSO]: {
    title: 'SSO is not available on current plan',
  },
  [FeatureLimit.EmailChat]: {
    title: 'Email chat is not available on current plan',
  },
  [FeatureLimit.GuidedOnboarding]: {
    title: 'Guided onboarding is not available on current plan',
  },
  [FeatureLimit.PersonalizedOnboarding]: {
    title: 'Personalized onboarding is not available on current plan',
  },
  [FeatureLimit.SuccessManager]: {
    title: 'Success manager is not available on current plan',
  },
  [FeatureLimit.TrainingSessions]: {
    title: 'Training sessions is not available on current plan',
  },
  [FeatureLimit.TrainingCertification]: {
    title: 'Training certification is not available on current plan',
  },
  [FeatureLimit.Facilitation]: {
    title: 'Facilitation is not available on current plan',
  },
  [FeatureLimit.Tools]: {
    title: 'Tool is not available on current plan',
  },
  [FeatureLimit.DataCrowdSourcing]: {
    title: 'Data Crowd Sourcing is not available on current plan',
  },

  // Custom

  [CustomBlocker.AssistanceLimit]: {
    title: 'AI Assistant Limit Reached',
    description: [
      "You've reached the limit for unregistered access to the PRIZ AI Assistant for this tool. Please sign up to continue enjoying the benefits of our powerful assistant.",
    ],
  },
};
