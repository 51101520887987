import React from 'react';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import { NewsletterSubscription } from './newsletter-subscription/component';
import { Box, Typography } from '@mui/material';

export const NotificationsPage: React.FC = () => {
  return (
    <>
      <PageTitleWithDocLink title={<Trans>Notifications</Trans>} />

      <Box pt={3} maxWidth={655}>
        <Box mb={2}>
          <Typography variant={'h6'} component={Box} mb={1}>
            <Trans>Newsletter</Trans>
          </Typography>

          <NewsletterSubscription />
        </Box>
      </Box>
    </>
  );
};
