import axios, { AxiosResponse } from 'axios';
import { UimPriority } from '@priz/shared/src/models/tools/uim';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm/rrm-goal-type.enum';
import { IRrmUtilization, RrmUtilization } from '@priz/shared/src/models/tools/rrm/rrm-utilization';
import { IGoal, IPerception, IRank, Perception, Rank } from '@priz/shared/src/models/tools/rrm';
import { EbsIdeaDifficultyVariant } from '@priz/shared/src/models/tools/ebs';
import { PMapPerceptionBlockingRank } from '@priz/shared/src/models/tools/pmap';

export interface RrmUtilizationCommand {
  goalTitle: string;
  goalType: RrmGoalType;
  title?: string;
  utilizationId?: number;
  uimPriority?: UimPriority;
  smart?: boolean;
  implementation?: EbsIdeaDifficultyVariant;
  validation?: EbsIdeaDifficultyVariant;
  blockingRank?: PMapPerceptionBlockingRank;
}

const createForProject = (projectId: number, command: RrmUtilizationCommand): Promise<IRrmUtilization> =>
  axios
    .post(`/v1/api/<ws-id>/team/<t-id>/project/${projectId}/rrm`, command)
    .then((response: AxiosResponse<IRrmUtilization>) => response.data);

const createStandalone = (command: RrmUtilizationCommand): Promise<IRrmUtilization> =>
  axios
    .post(`/v1/api/<ws-id>/team/<t-id>/tool/rrm`, command)
    .then((response: AxiosResponse<IRrmUtilization>) => response.data);

const create = (command: RrmUtilizationCommand, projectId?: number): Promise<IRrmUtilization> => {
  if (typeof projectId !== 'undefined') {
    return createForProject(projectId, command);
  } else {
    return createStandalone(command);
  }
};

const update = (utilization: RrmUtilization): Promise<IRrmUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/rrm/${utilization.id}`, utilization)
    .then((response: AxiosResponse<IRrmUtilization>) => response.data);

const doDelete = (utilizationId: number): Promise<any> =>
  axios.delete(`/v1/api/<ws-id>/team/<t-id>/rrm/${utilizationId}`).then((response: AxiosResponse) => response.data);

const getGoal = (goalId: number): Promise<IGoal> =>
  axios.get(`/v1/api/<ws-id>/team/<t-id>/rrm/goal/${goalId}`).then((response: AxiosResponse<IGoal>) => response.data);

const createPerception = (utilizationId: number, definition: string): Promise<IPerception> =>
  axios
    .post(`/v1/api/<ws-id>/team/<t-id>/rrm/${utilizationId}/perception`, {
      definition,
    })
    .then((response: AxiosResponse<IPerception>) => response.data);

const replacePerceptions = (
  utilizationId: number,
  definitionsToCreate: string[],
  idsToDelete: number[],
): Promise<IPerception> =>
  axios
    .post(`/v1/api/<ws-id>/team/<t-id>/rrm/${utilizationId}/replace-perceptions`, {
      definitionsToCreate,
      idsToDelete,
    })
    .then((response: AxiosResponse<IPerception>) => response.data);

const updatePerception = (utilizationId: number, perception: Perception): Promise<IPerception> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/rrm/${utilizationId}/perception/${perception.id}`, {
      definition: perception.definition,
    })
    .then((response: AxiosResponse<IPerception>) => response.data);

const deletePerception = (utilizationId: number, perceptionId: number): Promise<any> =>
  axios
    .delete(`/v1/api/<ws-id>/team/<t-id>/rrm/${utilizationId}/perception/${perceptionId}`)
    .then((response: AxiosResponse) => response.data);

export type UpdateRankResult = {
  updatedRank: IRank;
  perceptions: IPerception[];
  newRank?: IRank | null;
};

const updateRank = (utilizationId: number, rank: Rank, smart: boolean): Promise<UpdateRankResult> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/rrm/${utilizationId}/rank/${rank.id}`, {
      leftRank: rank.leftRank,
      rightRank: rank.rightRank,
      smart,
    })
    .then((response: AxiosResponse<UpdateRankResult>) => response.data);

const skipSmartRank = (utilizationId: number, rankId: number): Promise<IRank> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/rrm/${utilizationId}/rank/${rankId}/skip-smart`)
    .then((response: AxiosResponse<IRank>) => response.data);

export type StartRankingResult = {
  ranks: IRank[];
  perceptions: IPerception[];
};

const startRanking = (utilizationId: number, smart: boolean): Promise<StartRankingResult> =>
  axios
    .post(`/v1/api/<ws-id>/team/<t-id>/rrm/${utilizationId}/start-ranking`, { smart })
    .then((response: AxiosResponse<StartRankingResult>) => response.data);

export const RrmUtilizationApi = {
  create,
  createForProject,
  createStandalone,
  update,
  createPerception,
  replacePerceptions,
  delete: doDelete,
  deletePerception,
  getGoal,
  startRanking,
  updatePerception,
  updateRank,
  skipSmartRank,
};
