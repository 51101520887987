import { createStyles, makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ['@keyframes showChatContent']: {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      height: '100%',
    },
    rootMenuOpen: {},
    header: {
      width: '100%',
      boxSizing: 'border-box',
      flex: '0 0 auto',
      background: pgColorScheme.blue,
      color: pgColorScheme.white,
    },
    assistantSvg: {
      display: 'block',
      height: 60,
      width: 'auto',
    },
    closeButton: {
      color: pgColorScheme.white,
    },
    contentContainer: {
      position: 'relative',
      width: '100%',
      flex: '1 0 0',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      overflow: 'hidden',
    },
    chatsList: {
      width: 200,
      height: '100%',
      overflow: 'auto',
      boxSizing: 'border-box',
      backgroundColor: pgColorScheme.gray50,
    },
    chat: {
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      overflow: 'auto',
      backgroundColor: pgColorScheme.white,
    },
    chatFullWidth: {
      width: '100%',
    },
    chatWithMenu: {
      width: 'calc(100% - 200px)',
    },
    content: {
      width: '100%',
      flex: '1 0 0',
      boxSizing: 'border-box',
      overflow: 'auto',
      animation: '$showChatContent .3s ease-in-out .1s backwards',
    },
    bottom: {
      width: '100%',
      boxSizing: 'border-box',
      flex: '0 0 auto',
      borderTop: `1px solid ${pgColorScheme.background}`,
    },
    submitIcon: {
      position: 'relative',
      display: 'block',
      width: 18,
      height: 18,
      left: -1,
      fill: pgColorScheme.blue,
    },
    menuBackdrop: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,.3)',
      pointerEvents: 'none',
      opacity: 0,
      transition: 'opacity .15s ease',
      zIndex: 3,
    },

    [theme.breakpoints.up('md')]: {
      menuBackdrop: {
        display: 'none',
      },
    },
    [theme.breakpoints.down('md')]: {
      chatsList: {
        position: 'absolute',
        left: 'auto',
        right: '100%',
        bottom: 'auto',
        top: 'auto',
        transition: 'transform .3s ease',
        zIndex: 5,
      },
      chatWithMenu: {
        width: '100%',
      },
      rootMenuOpen: {
        ['& $chatsList']: {
          transform: 'translateX(100%)',
        },
        ['& $menuBackdrop']: {
          pointerEvents: 'auto',
          opacity: 1,
        },
      },
    },
  }),
);
