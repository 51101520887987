import { createStyles, makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchContainer: {
      position: 'absolute',
      top: 0,
      right: 'auto',
      bottom: 'auto',
      left: 0,
      width: 'calc(100% - 17px)',
      backgroundColor: pgColorScheme.white,
      borderTopLeftRadius: 8,
      boxSizing: 'border-box',
      zIndex: 2,
    },

    selectButton: {},

    popperContent: {
      width: 360,
      maxWidth: 'calc(100vw - 40px)',
      maxHeight: 300,
    },

    [theme.breakpoints.down('lg')]: {
      selectButton: {
        padding: 6,
      },
    },

    [theme.breakpoints.down('md')]: {
      selectButton: {
        padding: 4,
      },
    },
  }),
);
