import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  toolCard: {
    display: 'block',
    textDecoration: 'none',
    transition: 'border-color .15s ease',

    ['&:hover']: {
      borderColor: pgColorScheme.blue,
    },
  },
});
