import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../store/app.state';
import { Project, ProjectCollection } from '@priz/shared/src/models/project';
import { ProjectApi, ProjectFlowService } from '../../services';
import { ArrayUtils } from '@priz/shared/src/utils/common';
import { projectDataCompletenessValuesMap } from '../model';

const projectsCollectionSelector = (state: AppState) => state.projects;

const projectEntitiesMapSelector = createSelector(
  projectsCollectionSelector,
  (projectCollection: ProjectCollection) => projectCollection.entities,
);

const projectPublishCountdownsMapSelector = createSelector(
  projectsCollectionSelector,
  (projectCollection: ProjectCollection) => projectCollection.publishCountdowns,
);

const projectsStatusSelector = createSelector(
  projectsCollectionSelector,
  (projectCollection: ProjectCollection) => projectCollection.status,
);

const getById = (projectId: number): Selector<AppState, Project | undefined> =>
  createSelector(projectEntitiesMapSelector, projectMap => {
    return typeof projectId !== 'undefined' ? projectMap[projectId] : undefined;
  });

const getPublishCountdownByProjectId = (projectId: number): Selector<AppState, Date> =>
  createSelector(projectPublishCountdownsMapSelector, countdownsMap => countdownsMap[projectId]);

const getAllSortedByCreatedDate = createSelector(projectEntitiesMapSelector, projectMap =>
  projectMap ? Object.values(projectMap).sort(ArrayUtils.sorterByDateCreated).reverse() : [],
);

const getMyProjects: Selector<AppState, Project[]> = createSelector(
  [getAllSortedByCreatedDate],
  allProjects => allProjects,
);

const getAllInReview = (reviewerId: number) =>
  createSelector(getAllSortedByCreatedDate, allProjects =>
    allProjects.filter((project: Project) => ProjectApi.isProjectReviewedByUser(project, reviewerId)),
  );

const isProjectLoadedById = (projectId: number) => createSelector(getById(projectId), project => !!project);

const isProjectDescriptionDefinedById = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getById(projectId), project => project && project.description && project.description.length > 0);

const isCurrentSituationDefinedById = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getById(projectId), project => project?.currentSituation?.length > 0);

const isDisadvantagesDefinedById = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getById(projectId), project => project?.disadvantages?.length > 0);

const isProblemStatementDefinedById = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getById(projectId), project => project?.problemStatement?.length > 0);

const isIdealFinalResultDefinedById = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getById(projectId), project => project?.idealFinalResult?.length > 0);

const isGapsDefinedById = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getById(projectId), project => project?.gaps?.length > 0);

const areProblemStatementStepsDefined = (projectId?: number): Selector<AppState, boolean> =>
  createSelector(
    [
      isCurrentSituationDefinedById(projectId),
      isDisadvantagesDefinedById(projectId),
      isProblemStatementDefinedById(projectId),
    ],
    (currentSituation, disadvantages, problemStatement) => currentSituation && disadvantages && problemStatement,
  );

const isProjectLockedById = (projectId?: number): Selector<AppState, boolean> => {
  if (typeof projectId === 'undefined') {
    return createSelector(
      () => false,
      result => result,
    );
  } else {
    return createSelector(getById(projectId), project => ProjectFlowService.isProjectLocked(project));
  }
};

const isProjectCompleted = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getById(projectId), project => ProjectFlowService.isProjectCompleted(project));

const projectsLoaded = createSelector(projectsStatusSelector, status => status.loaded);
const areProjectsLoading = createSelector(projectsStatusSelector, status => status.loading);
const isFailed = createSelector(projectsStatusSelector, status => status.error);
const errorCode = createSelector(projectsStatusSelector, status => status.errorCode);

const isProjectGettingCreated = createSelector(projectsStatusSelector, status => status.creating);
const isAnyProjectSaving = createSelector(projectsStatusSelector, status => status.saving);
const isAnyProjectUpdated = createSelector(projectsStatusSelector, status => status.updated);
const isAnyProjectMoving = createSelector(projectsStatusSelector, status => status.moving);

const isProjectRemovingById = (projectId?: number): Selector<AppState, boolean> =>
  createSelector(projectsStatusSelector, statuses => (statuses.removingIds || []).includes(projectId));

const isProjectOpen = (projectId?: number): Selector<AppState, boolean> =>
  createSelector(getById(projectId), project => project?.open || false);

const isProjectBackgroundCompleted = (projectId?: number): Selector<AppState, boolean> =>
  createSelector(
    getById(projectId),
    project => project?.metaData?.projectBackgroundCompleteness >= projectDataCompletenessValuesMap.blue,
  );

const isCurrentSituationCompleted = (projectId?: number): Selector<AppState, boolean> =>
  createSelector(
    getById(projectId),
    project => project?.metaData?.currentSituationCompleteness >= projectDataCompletenessValuesMap.blue,
  );

const isDisadvantagesCompleted = (projectId?: number): Selector<AppState, boolean> =>
  createSelector(
    getById(projectId),
    project => project?.metaData?.disadvantagesCompleteness >= projectDataCompletenessValuesMap.blue,
  );

const isProblemStatementCompleted = (projectId?: number): Selector<AppState, boolean> =>
  createSelector(
    getById(projectId),
    project => project?.metaData?.problemStatementCompleteness >= projectDataCompletenessValuesMap.blue,
  );

const isIdealFinalResultCompleted = (projectId?: number): Selector<AppState, boolean> =>
  createSelector(
    getById(projectId),
    project => project?.metaData?.idealFinalResultCompleteness >= projectDataCompletenessValuesMap.blue,
  );

const isGapsCompleted = (projectId?: number): Selector<AppState, boolean> =>
  createSelector(
    getById(projectId),
    project => project?.metaData?.gapsCompleteness >= projectDataCompletenessValuesMap.blue,
  );

const areAllProblemStatementStepsCompleted = (projectId?: number): Selector<AppState, boolean> =>
  createSelector(
    [
      isCurrentSituationCompleted(projectId),
      isDisadvantagesCompleted(projectId),
      isIdealFinalResultCompleted(projectId),
      isGapsCompleted(projectId),
      isProblemStatementCompleted(projectId),
    ],
    (currentSituation, disadvantages, ifr, gaps, problemStatement) =>
      currentSituation && disadvantages && ifr && gaps && problemStatement,
  );

export const ProjectSelector = {
  getAllInReview,
  getById,
  getMyProjects,
  isProjectDescriptionDefinedById,
  isCurrentSituationDefinedById,
  isDisadvantagesDefinedById,
  isProblemStatementDefinedById,
  areProblemStatementStepsDefined,
  isProjectLoadedById,
  isProjectLockedById,
  isIdealFinalResultDefinedById,
  isGapsDefinedById,
  getAllSortedByCreatedDate,
  getPublishCountdownByProjectId,
  isProjectBackgroundCompleted,
  isCurrentSituationCompleted,
  isDisadvantagesCompleted,
  isIdealFinalResultCompleted,
  isGapsCompleted,
  isProblemStatementCompleted,
  areAllProblemStatementStepsCompleted,
  areProjectsLoading,
  isAnyProjectSaving,
  isAnyProjectUpdated,
  isProjectGettingCreated,
  projectsLoaded,
  isAnyProjectMoving,
  isProjectCompleted,
  isProjectOpen,
  isProjectRemovingById,
  isFailed,
  errorCode,
};
