import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { RrmPerceptionManager } from '../RrmPerceptionManager';
import { RrmRankingManager } from '../RrmRankingManager';
import { RrmRankingSummary } from '../RrmRankingSummary';
import { RrmUtilizationService } from '../services/rrm-utilization.service';
import { RrmGoalActions } from '../store';
import { RrmGoalSelector } from '../store/selectors';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm';
import { RrmUtilizationActions } from '../store/actions/rrm-utilization.actions';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { ToolUtilizationActions } from '../../project/store/actions/tools.actions';

export interface RrmUtilizationComponentProps {
  utilizationId: number;
  projectId?: number;
}

export const PureRrmUtilizationComponent: React.FC<RrmUtilizationComponentProps> = ({ utilizationId, projectId }) => {
  const dispatch = useDispatch();

  const [rankingStarted, setRankingStarted] = useState<boolean>();
  const [rankingFinished, setRankingFinished] = useState<boolean>();

  const utilization = useSelector(ToolUtilizationSelector.getRrmUtilization(utilizationId));
  const goal = useSelector(RrmGoalSelector.getGoalById(utilization?.goal?.id));

  const isUpdatingByGoalId = useSelector(RrmGoalSelector.isUpdatingByGoalId(utilization?.goal?.id));
  const isUpdatingByUtilizationId = useSelector(ToolUtilizationSelector.isUpdatingByUtilizationId(utilizationId));
  const isUpdating = isUpdatingByGoalId || isUpdatingByUtilizationId;

  useEffect(() => {
    dispatch(ToolUtilizationActions.loadTool(utilizationId, projectId));
  }, [utilizationId, projectId]);

  useEffect(() => {
    if (utilization && !goal) {
      dispatch(RrmGoalActions.loadRrmGoal(utilization.goal.id));
    }
  }, [utilization]);

  useEffect(() => {
    if (!isUpdating && utilization && !utilization.smart) {
      setRankingStarted(RrmUtilizationService.isRankingStarted(utilization));
      setRankingFinished(RrmUtilizationService.isRankingCompleted(utilization));
    }
  }, [utilization, isUpdating]);

  useEffect(() => {
    if (!isUpdating && utilization && utilization.smart && goal) {
      const allDone = !goal.perceptions.some(p => p.rankingScore === null);

      setRankingStarted(!!goal.perceptions.length);

      if (allDone) {
        setRankingFinished(true);

        if (goal.goalType === RrmGoalType.SELF) {
          dispatch(RrmUtilizationActions.update({ ...utilization, smart: false }));
        }
      } else {
        setRankingFinished(false);
      }
    }
  }, [utilization, goal, isUpdating]);

  if (!utilization || !goal) {
    return (
      <LoadingOverlay
        loading={true}
        backdropStyles={{
          backgroundColor: 'transparent',
        }}
      />
    );
  }

  return (
    <>
      {(!rankingStarted || (rankingFinished && goal.goalType === RrmGoalType.SELF)) && (
        <RrmPerceptionManager utilization={utilization} />
      )}

      {rankingStarted && !rankingFinished && <RrmRankingManager utilizationId={utilization.id} />}

      {rankingFinished && goal.goalType !== RrmGoalType.SELF && <RrmRankingSummary utilizationId={utilization.id} />}
    </>
  );
};
