import { DialogType } from './dialog-type';

export interface DialogState {
  open: boolean;
}

const DefaultDialogState: DialogState = {
  open: false,
};

export interface DialogsState {
  [DialogType.PlanUpgrade]: DialogState;
}

export const DefaultDialogsState: DialogsState = {
  [DialogType.PlanUpgrade]: DefaultDialogState,
};
