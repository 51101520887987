import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    '& .MuiTablePagination-caption, & .MuiFormHelperText-root': {
      marginBottom: 0,
    },
    '& .MuiIconButton-root.Mui-disabled': {
      background: 'transparent',
    },
  },
  pagination: {
    background: pgColorScheme.background,
    borderRadius: 4,
  },
});
