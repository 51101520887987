import { Link } from '@mui/material';
import { withStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const PgLink = withStyles({
  root: {
    'border': 0,
    'color': pgColorScheme.blue,
    'cursor': 'pointer',
    'fontSize': '14px',
    'fontWeight': 'normal',
    'lineHeight': '16px',
    'textAlign': 'center',
    'textDecoration': 'none',
    'textTransform': 'uppercase',

    '&.lower': {
      textTransform: 'unset',
    },

    '&:focus': {
      outline: 'none',
    },

    '&:hover, &:active': {
      color: pgColorScheme.darkBlue,
      textDecoration: 'none',
    },

    '&.no-transform': {
      fontSize: 'inherit',
      textTransform: 'none',
    },
  },
})(Link);
