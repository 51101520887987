import { WebAuth } from 'auth0-js';
import { environment } from '../../environments/environment';

let auth0Instance: WebAuth;

const getWebAuth = (): WebAuth => {
  if (!auth0Instance) {
    auth0Instance = new WebAuth(environment.auth0);
  }

  return auth0Instance;
};

const crossOriginVerification = () => {
  getWebAuth().crossOriginVerification();
};

export const Auth0Provider = {
  getWebAuth,
  crossOriginVerification,
};
