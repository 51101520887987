import { FluxStandardAction } from 'flux-standard-action';
import { createPgAction } from '../../../shared/store/action-creator';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { IChat } from '../model';
import { ChatApi, ConversationCommand } from '../../services/chat.api';

export enum ChatActionType {
  LoadAll = 'chat/load-all',
  SendMessage = 'chat/message/send',
  Create = 'chat/create',
  Update = 'chat/update',
  Delete = 'chat/delete',
  Retry = 'chat/retry',
  SetActiveChat = 'chat/set-active',
}

export interface ChatActionMeta {
  projectId: number;
  workspaceId?: number;
  userId?: number;
  chatId?: number;
  conversationCommand?: ConversationCommand;
}

export type ChatActionPayload = IChat | IChat[];

export type ChatAction = FluxStandardAction<string, ChatActionPayload, ChatActionMeta>;

const getChats = createPgAction(ChatActionType.LoadAll, ChatApi.getChats, (projectId: number) => ({ projectId }));

const setActiveChat = createPgAction(
  ChatActionType.SetActiveChat,
  () => new Promise(resolve => resolve({})),
  (chatId: number) => ({ chatId }),
);

const sendChatMessage = createPgAction(
  ChatActionType.SendMessage,
  ChatApi.sendChatMessage,
  (projectId: number, conversationCommand: ConversationCommand, workspaceId, userId) => ({
    projectId,
    conversationCommand,
    workspaceId,
    userId,
    chatId: conversationCommand.chatId,
  }),
  (meta: ChatActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.CHAT_MESSAGE_SEND, {
      project_id: meta.projectId,
    });
  },
);

const retry = createPgAction(
  ChatActionType.Retry,
  ChatApi.getAnswer,
  (projectId: number, chatId) => ({ projectId, chatId }),
  (meta: ChatActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.CHAT_RETRIED, {
      project_id: meta.projectId,
      chat_id: meta.chatId,
    });
  },
);

const createChat = createPgAction(
  ChatActionType.Create,
  ChatApi.createChat,
  (projectId: number) => ({ projectId }),
  (meta: ChatActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.CHAT_CREATED, {
      project_id: meta.projectId,
    });
  },
);

const updateChat = createPgAction(
  ChatActionType.Update,
  ChatApi.updateChat,
  (projectId: number, chatId: number) => ({ projectId, chatId }),
  (meta: ChatActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.CHAT_UPDATED, {
      project_id: meta.projectId,
    });
  },
);

const deleteChat = createPgAction(
  ChatActionType.Delete,
  ChatApi.deleteChat,
  (projectId: number, chatId: number) => ({ projectId, chatId }),
  (meta: ChatActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.CHAT_DELETED, {
      project_id: meta.projectId,
    });
  },
);

export const ChatActions = {
  getChats,
  sendChatMessage,
  createChat,
  updateChat,
  deleteChat,
  retry,
  setActiveChat,
};
