import { EntityCollectionStatus, EntityMap, LookupMap } from '../../common/entity-collection-state';
import { EbsIdea } from './ebs-idea';

export interface EbsIdeaLookups {
  byUtilizationId: LookupMap;
}

export interface EbsIdeasStatuses {
  byUtilizationId?: { [utilizationId: number]: EntityCollectionStatus };
}

export interface EbsIdeaCollection {
  entities: EntityMap<EbsIdea>;

  lookups: EbsIdeaLookups;

  statuses: EbsIdeasStatuses;
}

export const DefaultEbsIdeaCollection: EbsIdeaCollection = {
  entities: {},

  lookups: {
    byUtilizationId: {},
  },

  statuses: {
    byUtilizationId: {},
  },
};
