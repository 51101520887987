import { handleActions } from 'redux-actions';
import { toSuccess } from '../../../shared/store/action-creator';
import { DialogsAction, DialogsActionPayload, DialogsActionType } from '../actions';
import { DefaultDialogsState, DialogsState, DialogState } from '../model';

const updateDialogState = (
  state: DialogsState,
  payload: DialogsActionPayload,
  update: Partial<DialogState>,
): DialogsState => {
  return {
    ...state,
    [payload.dialogType]: {
      ...state[payload.dialogType],
      ...update,
    },
  };
};

export const dialogsReducers = handleActions(
  {
    // Open

    [toSuccess(DialogsActionType.Open)]: (state: DialogsState, action: DialogsAction): DialogsState =>
      updateDialogState(state, action.payload, { open: true }),

    // Close

    [toSuccess(DialogsActionType.Close)]: (state: DialogsState, action: DialogsAction): DialogsState =>
      updateDialogState(state, action.payload, { open: false }),
  },

  DefaultDialogsState,
);
