import React, { SyntheticEvent } from 'react';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import { useStyles } from './styles';

export interface SideNavItemProps {
  id?: string;
  title: string | JSX.Element;
  icon: JSX.Element;
  navigate?: any[];
  onClick?: (e?: SyntheticEvent) => void;
  variant?: 'orange' | 'green' | 'orange-hover' | 'blue' | undefined;
  disabled?: boolean;
}

export const SideNavItem: React.FC<SideNavItemProps> = ({ id, title, icon, navigate, onClick, variant, disabled }) => {
  const styles = useStyles();
  const { pathname } = useLocation();
  const isActive = navigate ? matchPath(navigate.join('/'), pathname) : false;

  const clickHandler = e => {
    if (onClick) onClick(e);
  };

  return (
    <ListItemButton
      className={`${styles.root}${isActive ? ' _active' : ''}${variant ? ` _${variant}` : ''}`}
      disabled={disabled}
      component={navigate ? NavLink : 'div'}
      to={navigate ? navigate.join('/') : undefined}
      onClick={clickHandler}
      id={id}
    >
      <ListItemIcon className={styles.iconContainer}>{icon}</ListItemIcon>
      <ListItemText primaryTypographyProps={{ className: styles.title }}>{title}</ListItemText>
    </ListItemButton>
  );
};
