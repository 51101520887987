import * as React from "react";

export const ReactComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="#08445E" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.75 53.725C17.8783 53.725 21.225 50.3783 21.225 46.25C21.225 42.1217 17.8783 38.775 13.75 38.775C9.62167 38.775 6.275 42.1217 6.275 46.25C6.275 50.3783 9.62167 53.725 13.75 53.725ZM13.75 56C19.1348 56 23.5 51.6348 23.5 46.25C23.5 40.8652 19.1348 36.5 13.75 36.5C8.36522 36.5 4 40.8652 4 46.25C4 51.6348 8.36522 56 13.75 56Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.75 21.225C17.8783 21.225 21.225 17.8783 21.225 13.75C21.225 9.62167 17.8783 6.275 13.75 6.275C9.62167 6.275 6.275 9.62167 6.275 13.75C6.275 17.8783 9.62167 21.225 13.75 21.225ZM13.75 23.5C19.1348 23.5 23.5 19.1348 23.5 13.75C23.5 8.36522 19.1348 4 13.75 4C8.36522 4 4 8.36522 4 13.75C4 19.1348 8.36522 23.5 13.75 23.5Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M46.25 53.725C50.3783 53.725 53.725 50.3783 53.725 46.25C53.725 42.1217 50.3783 38.775 46.25 38.775C42.1217 38.775 38.775 42.1217 38.775 46.25C38.775 50.3783 42.1217 53.725 46.25 53.725ZM46.25 56C51.6348 56 56 51.6348 56 46.25C56 40.8652 51.6348 36.5 46.25 36.5C40.8652 36.5 36.5 40.8652 36.5 46.25C36.5 51.6348 40.8652 56 46.25 56Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M44.2223 17.3497C43.8884 16.7294 43.4439 16.2782 42.811 15.9282L43.912 13.9373C44.9238 14.4969 45.683 15.2634 46.2255 16.2714L44.2223 17.3497ZM40.6148 15.2075C39.8952 15.0701 39.0577 14.9709 38.0788 14.9012L38.2404 12.632C39.2745 12.7056 40.2063 12.8134 41.0413 12.9728L40.6148 15.2075ZM45.1472 22.0849C45.0996 21.1107 45.0211 20.2759 44.9008 19.5566L47.1446 19.1814C47.2845 20.0177 47.3694 20.9473 47.4195 21.974L45.1472 22.0849ZM35.4498 14.7857C34.63 14.7663 33.7457 14.7568 32.7906 14.753L32.7994 12.478C33.7633 12.4818 34.6637 12.4915 35.5036 12.5113L35.4498 14.7857ZM45.1959 27.3921C45.2063 26.4328 45.2121 25.5446 45.2082 24.7203L47.4832 24.7097C47.4871 25.551 47.4812 26.4526 47.4708 27.4167L45.1959 27.3921ZM28.5696 14.7595C28.1947 14.761 27.8117 14.7625 27.42 14.7638L27.412 12.4889C27.8015 12.4875 28.1838 12.486 28.5586 12.4845C29.0848 12.4825 29.5961 12.4805 30.0916 12.4789L30.0986 14.7539C29.6036 14.7555 29.0945 14.7574 28.5696 14.7595ZM45.1295 32.7718C45.1385 31.8283 45.1499 30.9313 45.1614 30.0795L47.4362 30.11C47.4248 30.961 47.4133 31.8545 47.4043 32.7936L45.1295 32.7718ZM24.7349 14.7698C24.2884 14.77 23.8327 14.7697 23.3677 14.7688L23.3722 12.4938C23.8352 12.4947 24.289 12.495 24.7339 12.4948L24.7349 14.7698ZM45.1125 36.8272C45.1116 36.3621 45.1118 35.9062 45.1128 35.4596L47.3878 35.465C47.3868 35.9086 47.3866 36.3611 47.3875 36.8228L45.1125 36.8272Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.8875 22.525V37.7187H12.6125V22.525H14.8875Z"/>
  </svg>
);
