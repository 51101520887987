import React, { useEffect, useState } from 'react';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AppNavbar } from '../navigation/app-navbar/component';
import { PageTitleWithDocLink } from '../shared/PageTitleWithDocLink';
import { SearchInput } from '../react/elements/search-input/component';
import { Alert, Box } from '@mui/material';
import { Trans } from 'react-i18next';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { LinearLoader } from '../react/elements/linear-loader/component';
import {
  CreatePartnerProfileCommand,
  GetUserByIdOrEmailCommand,
  UpdateUserRestrictionsCommand,
  UserAddRoleCommand,
  UserPartnerProfileUpdateCommand,
  UserRemoveRoleCommand,
  UsersAdministrationApi,
} from './api/users-administration.api';
import { User } from '@priz/shared/src/models/security/user';
import { copyObject } from '@priz/shared/src/utils/common';
import { UserAdministrationCard } from './user-administration-card/component';
import { Role } from '@priz/shared/src/models/security/role.enum';
import { AxiosError } from 'axios';
import { NotificationActions } from '../react/modules/notification/store';
import { useDispatch } from 'react-redux';
import { PageContainer } from '../content-containers/page-container/component';

const defaultSearchParams: GetUserByIdOrEmailCommand = {
  id: null,
  email: null,
};

const isAnyMutationLoading = (mutations?: UseMutationResult[]): boolean => {
  return mutations.some(mutation => mutation.isLoading);
};

export const UsersManager: React.FC = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [loadedUser, setLoadedUser] = useState<User | undefined>();
  const [searchParams, setSearchParams] = useState<GetUserByIdOrEmailCommand>(copyObject(defaultSearchParams));

  const { isFetching, data, refetch } = useQuery(
    ['user-manager', searchParams.id, searchParams.email],
    () => UsersAdministrationApi.getByIdOrEmail(searchParams),
    {
      enabled: searchParams.id !== null || searchParams.email !== null,
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const searchParameter = Object.values(searchParams).find(p => p !== null);
  const isSearchParamsMatchUser = [loadedUser?.id, loadedUser?.email].includes(searchParameter);

  const mutationCallbacks: { onSuccess: (user: User) => void; onError: (error: AxiosError) => void } = {
    onSuccess: user => {
      setLoadedUser(user);
    },
    onError: (error: AxiosError<{ message: string }>) => {
      dispatch(
        NotificationActions.add(
          error?.response?.data?.message || error?.message || 'Something went wrong',
          'error',
          10000,
        ),
      );
      void refetch();
    },
  };

  const addRoleMutation = useMutation(
    (props: { userId: number; command: UserAddRoleCommand }) =>
      UsersAdministrationApi.addUserRole(props.userId, props.command),
    mutationCallbacks,
  );

  const removeRoleMutation = useMutation(
    (props: { userId: number; command: UserRemoveRoleCommand }) =>
      UsersAdministrationApi.removeUserRole(props.userId, props.command),
    mutationCallbacks,
  );

  const createPartnerProfileMutation = useMutation(
    (props: { userId: number; command: CreatePartnerProfileCommand }) =>
      UsersAdministrationApi.createPartnerProfile(props.userId, props.command),
    mutationCallbacks,
  );

  const updatePartnerProfileMutation = useMutation(
    (props: { userId: number; command: UserPartnerProfileUpdateCommand }) =>
      UsersAdministrationApi.updatePartnerProfile(props.userId, props.command),
    mutationCallbacks,
  );

  const updateUserRestrictionsMutation = useMutation(
    (props: { userId: number; command: UpdateUserRestrictionsCommand }) =>
      UsersAdministrationApi.updateUserRestrictions(props.userId, props.command),
    mutationCallbacks,
  );

  const createApiKeyMutation = useMutation(
    (props: { userId: number }) => UsersAdministrationApi.createApiKey(props.userId),
    mutationCallbacks,
  );

  const invalidateApiKeyMutation = useMutation(
    (props: { userId: number; apiKeyId: number }) =>
      UsersAdministrationApi.invalidateApiKey(props.userId, props.apiKeyId),
    mutationCallbacks,
  );

  useEffect(() => {
    setLoadedUser(data);
  }, [data]);

  const changeHandler = (val: string) => {
    setSearchParams(isNaN(+val) ? { id: null, email: val } : { id: +val, email: null });
  };

  const clearHandler = () => {
    void queryClient.clear();
    setLoadedUser(undefined);
    setSearchParams(copyObject(defaultSearchParams));
  };

  const addRoleHandler = (userId: number, role: Role) => {
    addRoleMutation.mutate({ userId, command: { authority: role } });
  };

  const removeRoleHandler = (userId: number, role: Role) => {
    removeRoleMutation.mutate({ userId, command: { authority: role } });
  };

  const createPartnerProfileHandler = (userId: number, command: CreatePartnerProfileCommand) => {
    createPartnerProfileMutation.mutate({ userId, command });
  };

  const updatePartnerProfileHandler = (userId: number, command: UserPartnerProfileUpdateCommand) => {
    updatePartnerProfileMutation.mutate({ userId, command });
  };

  const updateRestrictionsHandler = (userId: number, command: UpdateUserRestrictionsCommand) => {
    updateUserRestrictionsMutation.mutate({ userId, command });
  };

  const createApiKey = (userId: number) => {
    createApiKeyMutation.mutate({ userId });
  };

  const invalidateApiKey = (userId: number, apiKeyId: number) => {
    invalidateApiKeyMutation.mutate({ userId, apiKeyId });
  };

  return (
    <PageContainer>
      <AppNavbar />

      <>
        <PageTitleWithDocLink title={'Users manager'} />

        <SearchInput
          onChangeDebounced={changeHandler}
          onClear={clearHandler}
          placeholder={'Search by id or email'}
          inputProps={{ min: 0 }}
        />

        <LinearLoader mt={2} loading={isFetching && !isSearchParamsMatchUser} />

        {(searchParams.id !== null || searchParams.email !== null) && !isFetching && !loadedUser && (
          <Box>
            <Alert severity={'info'}>
              <Trans>User not found</Trans>
            </Alert>
          </Box>
        )}

        {loadedUser && (
          <Box position={'relative'}>
            <UserAdministrationCard
              key={loadedUser.id}
              user={loadedUser}
              onAddRole={addRoleHandler}
              onRemoveRole={removeRoleHandler}
              onCreatePartnerProfile={createPartnerProfileHandler}
              onUpdatePartnerProfile={updatePartnerProfileHandler}
              onUpdateRestrictions={updateRestrictionsHandler}
              onCreateApiKey={createApiKey}
              onInvalidateApiKey={invalidateApiKey}
              loading={
                isFetching ||
                isAnyMutationLoading([
                  addRoleMutation,
                  removeRoleMutation,
                  createPartnerProfileMutation,
                  updatePartnerProfileMutation,
                  updateUserRestrictionsMutation,
                  invalidateApiKeyMutation,
                  createApiKeyMutation,
                ])
              }
            />

            <LoadingOverlay
              loading={isFetching && isSearchParamsMatchUser}
              backdropStyles={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
            />
          </Box>
        )}
      </>
    </PageContainer>
  );
};
