import React, { useEffect } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { TitleContainer } from '../../react/modules/title-container/component';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AgendaActions, AttendeeActions, MeetingActions } from '../store/actions';
import { AgendaSelectors, AttendeeSelectors, MeetingSelectors } from '../store/selectors';
import { CreateMeetingButton } from '../create-meeting-button/component';
import { WorkspaceMembersActions } from '../../workspace/store/actions';
import { MeetingsList } from '../meetings-list/component';
import { WorkspaceMembersSelectors } from '../../workspace/store/selectors/workspace-members.selectors';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ToolUtilizationActions } from '../../project/store/actions/tools.actions';

export interface MeetingsViewProps {
  projectId: number;
}

export const MeetingsView: React.FC<MeetingsViewProps> = ({ projectId }) => {
  const dispatch = useDispatch();

  const meetings = useSelector(MeetingSelectors.getAllByProjectId(projectId));
  const meetingsAreLoaded = useSelector(MeetingSelectors.isLoaded(projectId));
  const attendeesAreLoaded = useSelector(AttendeeSelectors.isLoaded(projectId));
  const agendaIsLoaded = useSelector(AgendaSelectors.isLoaded(projectId));
  const workspaceMembersAreLoaded = useSelector(WorkspaceMembersSelectors.isLoaded);

  const loaded = meetingsAreLoaded && attendeesAreLoaded && agendaIsLoaded && workspaceMembersAreLoaded;

  useEffect(() => {
    dispatch(AgendaActions.getAll(projectId));
    dispatch(AttendeeActions.getAll(projectId));
    dispatch(MeetingActions.getAll(projectId));
    dispatch(WorkspaceMembersActions.loadAll());
    dispatch(ToolUtilizationActions.loadTools(projectId));
  }, []);

  return (
    <ContentContainer id={'meetings-view'}>
      <Box>
        <TitleContainer>
          <PageTitleWithDocLink
            title={<Trans>Meetings</Trans>}
            oneLiner={<Trans>Meetings subtitle.</Trans>}
            docsUrl="https://www.priz.guru/knowledge-base/meetings-management/#.Y4XA9HZByUk"
          />

          {loaded && !!meetings.length && <CreateMeetingButton projectId={projectId} navigateToMeetingPage />}
        </TitleContainer>
      </Box>

      {loaded && (
        <Box pb={5}>
          {!meetings.length && (
            <Box mt={8}>
              <Grid container flexDirection={'column'} alignItems={'center'} textAlign={'center'} spacing={4}>
                <Grid item>
                  <Typography variant={'subtitle1'} component={'span'}>
                    <Trans>You still do not have any meetings.</Trans>
                    <br />
                    <Trans>Click “+ CREATE NEW MEETING” to record one.</Trans>
                  </Typography>
                </Grid>

                <Grid item>
                  <CreateMeetingButton projectId={projectId} navigateToMeetingPage />
                </Grid>
              </Grid>
            </Box>
          )}

          {!!meetings.length && <MeetingsList projectId={projectId} meetings={meetings} />}
        </Box>
      )}

      {!loaded && (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flex={'1 0 0'}>
          <CircularProgress disableShrink />
        </Box>
      )}
    </ContentContainer>
  );
};
