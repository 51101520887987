import { BlockerName } from './blocker';

export interface PaywallState {
  show: boolean;
  blocker?: BlockerName;
}

export const DefaultPaywallState: PaywallState = {
  show: false,
};
