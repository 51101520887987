import React from 'react';
import { Trans } from 'react-i18next';
import { Button } from '@mui/material';

export interface FormResetButtonProps {
  text?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const FormResetButton: React.FC<FormResetButtonProps> = ({ text = 'Cancel', disabled, onClick }) => {
  const clickHandler = () => {
    if (onClick) onClick();
  };

  return (
    <Button
      variant={'pg_rounded'}
      color={'pg_orange_outlined'}
      type={'reset'}
      disabled={disabled}
      onClick={clickHandler}
    >
      <Trans>{text}</Trans>
    </Button>
  );
};
