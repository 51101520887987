import React from 'react';
import { CftNodeData, CftNodeStatus } from '../../../models/tools/cft';
import {
  CommentOutlined,
  CheckCircleOutlineRounded,
  ErrorOutlineRounded,
  WarningAmberRounded,
} from '@mui/icons-material';
import { cftSettings } from '../data';
import { PopperWrap } from '../../popper-wrap/component';
import { Box } from '@mui/material';
import { Trans } from 'react-i18next';
import { useStyles } from './styles';

interface CftNodeIndicatorsProps {
  node: CftNodeData;
  disableInteractive?: boolean;
}

export const CftNodeIndicators: React.FC<CftNodeIndicatorsProps> = ({ node, disableInteractive }) => {
  const styles = useStyles();

  return (
    <>
      {node.status === CftNodeStatus.Blocker && (
        <PopperWrap
          openHandler={'hover'}
          placement={'left'}
          dropdownProps={{ pt: 1, pb: 1 }}
          disabled={disableInteractive}
          arrow
        >
          <ErrorOutlineRounded
            className={'cft-control'}
            sx={{ display: 'block', color: cftSettings.colors.status.blocker }}
          />

          <Box>
            <Trans>Blocker</Trans>
          </Box>
        </PopperWrap>
      )}

      {node.status === CftNodeStatus.Problematic && (
        <PopperWrap
          openHandler={'hover'}
          placement={'left'}
          dropdownProps={{ pt: 1, pb: 1 }}
          disabled={disableInteractive}
          arrow
        >
          <WarningAmberRounded
            className={'cft-control'}
            sx={{ display: 'block', color: cftSettings.colors.status.problematic }}
          />

          <Box>
            <Trans>Problematic</Trans>
          </Box>
        </PopperWrap>
      )}

      {node.status === CftNodeStatus.Verified && (
        <PopperWrap
          openHandler={'hover'}
          placement={'left'}
          dropdownProps={{ pt: 1, pb: 1 }}
          disabled={disableInteractive}
          arrow
        >
          <CheckCircleOutlineRounded
            className={'cft-control'}
            sx={{ display: 'block', color: cftSettings.colors.status.verified }}
          />

          <Box>
            <Trans>Verified</Trans>
          </Box>
        </PopperWrap>
      )}

      {node.notes && (
        <PopperWrap
          openHandler={'hover'}
          placement={'left'}
          dropdownProps={{ pt: 1, pb: 1 }}
          disabled={disableInteractive}
          arrow
        >
          <CommentOutlined
            className={'cft-control'}
            sx={{ display: 'block', color: cftSettings.colors.status.default }}
          />

          <Box className={styles.notes}>{node.notes || '-'}</Box>
        </PopperWrap>
      )}
    </>
  );
};
