import { AttendeeStatus, IAgenda, IAttendee, IMeeting } from '../store/model';
import axios, { AxiosResponse } from 'axios';
import { AxiosRequestParams } from '@priz/shared/src/services/axios';

interface AttendeePublicUpdateCommand {
  status: AttendeeStatus;
  explanation?: string;
}

const axiosParams: Partial<AxiosRequestParams> = {
  params: {
    skipAuthCheck: true,
  },
};

const getMeeting = (token: string): Promise<IMeeting> =>
  axios.get(`/v1/public-api/meeting/${token}`, axiosParams).then((response: AxiosResponse<IMeeting>) => response.data);

const getMeetingAgenda = (token: string): Promise<IAgenda[]> =>
  axios
    .get(`/v1/public-api/meeting/agenda/${token}`, axiosParams)
    .then((response: AxiosResponse<IAgenda[]>) => response.data);

const getMeetingAttendees = (token: string): Promise<IAttendee[]> =>
  axios
    .get(`/v1/public-api/meeting/attendees/${token}`, axiosParams)
    .then((response: AxiosResponse<IAttendee[]>) => response.data);

const getMeetingRequestedAttendee = (token: string): Promise<IAttendee> =>
  axios
    .get(`/v1/public-api/meeting/attendee/${token}`, axiosParams)
    .then((response: AxiosResponse<IAttendee>) => response.data);

const updateMeetingRequestedAttendee = (token: string, command: AttendeePublicUpdateCommand): Promise<IAttendee> =>
  axios
    .post(`/v1/public-api/meeting/attendee/${token}`, command, axiosParams)
    .then((response: AxiosResponse<IAttendee>) => response.data);

export const PublicMeetingsApi = {
  getMeeting,
  getMeetingAgenda,
  getMeetingAttendees,
  getMeetingRequestedAttendee,
  updateMeetingRequestedAttendee,
};
