import {
  ApprovalActivityAction,
  ApprovalActivitiesAction,
  ApprovalActivityActionType,
} from '../actions/approval-activity.actions';
import { ApprovalActivityCollection, ApprovalActivity, DefaultApprovalActivityCollection } from '../model';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { handleActions } from 'redux-actions';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { ProjectCollectionStatus } from '@priz/shared/src/models/project';

const setLoadingForProjectId = (
  state: ApprovalActivityCollection,
  projectId: number,
  statuses: Partial<EntityCollectionStatus>,
) => {
  const loadingIdsState = {
    ...state.loadingByProjectId,
    [projectId]: !!statuses?.loading,
  };

  return {
    ...state,
    loadingByProjectId: loadingIdsState,
    statuses: {
      ...state.statuses,
      ...statuses,
    },
  };
};

const setActivitiesAndLoadingByProjectId = (
  state: ApprovalActivityCollection,
  projectId: number,
  activitiesPayload: any[],
  statuses: Partial<EntityCollectionStatus>,
) => {
  const loadingIdsState = {
    ...state.loadingByProjectId,
    [projectId]: !!statuses?.loading,
  };

  const activityMapCopy = { ...state.entities };
  const newActivitiesMap = activitiesPayload.reduce((map, activityPayload) => {
    map[activityPayload.id] = new ApprovalActivity(activityPayload);
    return map;
  }, activityMapCopy);

  return {
    ...state,
    entities: newActivitiesMap,
    loadingByProjectId: loadingIdsState,
    statuses: {
      ...state.statuses,
      ...statuses,
    },
  };
};

const setActivity = (
  state: ApprovalActivityCollection,
  activityPayload: any,
  statuses: Partial<ProjectCollectionStatus>,
) => {
  const activityMapCopy = { ...state.entities };

  activityMapCopy[activityPayload.id] = new ApprovalActivity(activityPayload);

  return {
    ...state,
    entities: activityMapCopy,
    statuses: {
      ...state.statuses,
      ...statuses,
    },
  };
};

const removeActivity = (
  state: ApprovalActivityCollection,
  activityId: number,
  statuses: Partial<ProjectCollectionStatus>,
) => {
  const activityMapCopy = { ...state.entities };

  delete activityMapCopy[activityId];

  return {
    ...state,
    entities: activityMapCopy,
    statuses: {
      ...state.statuses,
      ...statuses,
    },
  };
};

const setStatuses = (
  state: ApprovalActivityCollection,
  statuses: Partial<EntityCollectionStatus>,
): ApprovalActivityCollection => ({
  ...state,
  statuses: {
    ...state.statuses,
    ...statuses,
  },
});

export const approvalActivityReducers = handleActions(
  {
    // ApprovalActivityActionType.Load
    [toStarted(ApprovalActivityActionType.Load)]: (
      state: ApprovalActivityCollection,
      action: ApprovalActivitiesAction,
    ) => setLoadingForProjectId(state, action.meta.projectId, { loading: true }),

    [toSuccess(ApprovalActivityActionType.Load)]: (
      state: ApprovalActivityCollection,
      action: ApprovalActivitiesAction,
    ) => {
      const activities = Array.isArray(action.payload) ? action.payload : [action.payload];

      return setActivitiesAndLoadingByProjectId(state, action.meta.projectId, activities, {
        loading: false,
        loaded: true,
        error: false,
      });
    },

    [toFailed(ApprovalActivityActionType.Load)]: (
      state: ApprovalActivityCollection,
      action: ApprovalActivitiesAction,
    ) => setLoadingForProjectId(state, action.meta.projectId, { loading: false, loaded: false, error: true }),

    // ApprovalActivityActionType.Create
    [toStarted(ApprovalActivityActionType.Create)]: (state: ApprovalActivityCollection) =>
      setStatuses(state, { creating: true }),

    [toSuccess(ApprovalActivityActionType.Create)]: (
      state: ApprovalActivityCollection,
      action: ApprovalActivityAction,
    ) => setActivity(state, action.payload, { creating: false, error: false }),

    [toFailed(ApprovalActivityActionType.Create)]: (state: ApprovalActivityCollection) =>
      setStatuses(state, { creating: false, error: true }),

    // ApprovalActivityActionType.Update
    [toStarted(ApprovalActivityActionType.Update)]: (state: ApprovalActivityCollection) =>
      setStatuses(state, { saving: true }),

    [toSuccess(ApprovalActivityActionType.Update)]: (
      state: ApprovalActivityCollection,
      action: ApprovalActivityAction,
    ) => setActivity(state, action.payload, { saving: false, error: false }),

    [toFailed(ApprovalActivityActionType.Update)]: (state: ApprovalActivityCollection) =>
      setStatuses(state, { saving: false, error: true }),

    // ApprovalActivityActionType.Delete
    [toStarted(ApprovalActivityActionType.Delete)]: (state: ApprovalActivityCollection) =>
      setStatuses(state, { removing: true }),

    [toSuccess(ApprovalActivityActionType.Delete)]: (
      state: ApprovalActivityCollection,
      action: ApprovalActivityAction,
    ) => removeActivity(state, action.meta.activityId, { removing: false, error: false }),

    [toFailed(ApprovalActivityActionType.Delete)]: (state: ApprovalActivityCollection) =>
      setStatuses(state, { removing: false, error: true }),
  },

  DefaultApprovalActivityCollection,
);
