import { createSelector, Selector } from 'reselect';
import { FortyPrinciplesUtilization } from '@priz/shared/src/models/tools/forty-principles/forty-principles-utilization';
import { ToolType, ToolUtilization } from '@priz/shared/src/models/tools';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm/rrm-goal-type.enum';
import { RrmUtilization } from '@priz/shared/src/models/tools/rrm/rrm-utilization';
import { ArrayUtils } from '@priz/shared/src/utils/common';
import {
  ActionTypeStatus,
  ActionTypeStatusMap,
  EntityCollectionStatus,
  EntityStatusId,
} from '@priz/shared/src/models/common/entity-collection-state';
import { AppState } from '../../../store/app.state';
import { UtilizationTypesMap } from '@priz/shared/src/models/project';
import { UserContextService } from '@priz/shared/src/services/user';
import { assistantLimits } from '../../../assistant/store/model';

const toolUtilizationCollectionSelector = (state: AppState) => state.toolUtilizations;

const toolUtilizationEntitiesSelector = createSelector(
  toolUtilizationCollectionSelector,
  collection => collection.entities || {},
);

const toolUtilizationLookupsSelector = createSelector(
  toolUtilizationCollectionSelector,
  collection => collection.lookups,
);

const toolUtilizationsLookupByProjectIdSelector = (projectId: number) =>
  createSelector(toolUtilizationLookupsSelector, lookupMap => lookupMap.byProjectId[projectId] || []);

const toolUtilizationsLookupByPublicIdSelector = (publicId: string) =>
  createSelector(toolUtilizationLookupsSelector, lookupMap => lookupMap.byPublicId[publicId] || -1);

const toolUtilizationsLookupByTeamSelector = () =>
  createSelector(toolUtilizationLookupsSelector, lookupMap => {
    const contextTeamId = UserContextService.getSelectedTeamId();
    return lookupMap.byTeamId[contextTeamId] || [];
  });

const toolUtilizationStatusesSelector = createSelector(
  toolUtilizationCollectionSelector,
  collection => collection.statuses,
);

const getStatusesByTeam = (): Selector<AppState, EntityCollectionStatus> =>
  createSelector(toolUtilizationStatusesSelector, statuses => {
    const contextTeamId = UserContextService.getSelectedTeamId();
    return statuses.byTeamId?.[contextTeamId] || {};
  });

const getStatusesByProjectId = (projectId: number): Selector<AppState, EntityCollectionStatus> =>
  createSelector(toolUtilizationStatusesSelector, statuses => statuses.byProjectId?.[projectId] || {});

const getStatusesByUtilizationId = (utilizationId: number) =>
  createSelector(toolUtilizationStatusesSelector, statuses => statuses.byUtilizationId?.[utilizationId] || {});

const getStatusesByPublicUtilizationId = (publicId: string) =>
  createSelector(toolUtilizationStatusesSelector, statuses => statuses.byPublicId?.[publicId] || {});

const getActionTypesStatutesMap = (utilizationId: number): Selector<AppState, ActionTypeStatusMap> =>
  createSelector(getStatusesByUtilizationId(utilizationId), statuses => statuses.actionTypes || {});

const getStatusesByActionType = (utilizationId: number, actionType: string): Selector<AppState, ActionTypeStatus> =>
  createSelector(getActionTypesStatutesMap(utilizationId), map => map[actionType] || {});

const getAllByProjectId = (projectId: number): Selector<AppState, ToolUtilization[]> =>
  createSelector(
    [toolUtilizationEntitiesSelector, toolUtilizationsLookupByProjectIdSelector(projectId)],
    (entities, ids) =>
      ids
        .map(id => entities[id])
        .sort(ArrayUtils.sorterByDateCreated)
        .reverse(),
  );

const getAllByTeamId = (): Selector<AppState, ToolUtilization[]> =>
  createSelector([toolUtilizationEntitiesSelector, toolUtilizationsLookupByTeamSelector()], (entities, ids) =>
    ids
      .map(id => entities[id])
      .filter(tool => !tool.project)
      .sort(ArrayUtils.sorterByDateCreated)
      .reverse(),
  );

const getAllVisibleByProjectId = (projectId: number) =>
  createSelector([getAllByProjectId(projectId)], tools =>
    tools.filter(t => {
      if (t.type === ToolType.RRM) {
        const rrmUtilization = t as RrmUtilization;

        return rrmUtilization.goalType === RrmGoalType.SELF;
      } else {
        return true;
      }
    }),
  );

const getById = <T extends ToolType>(utilizationId: number, type: T): Selector<AppState, UtilizationTypesMap[T]> =>
  createSelector(
    toolUtilizationEntitiesSelector,
    entities =>
      entities[utilizationId] &&
      entities[utilizationId].type === type &&
      (entities[utilizationId] as UtilizationTypesMap[T]),
  );

const getByPublicId = (publicId: string): Selector<AppState, ToolUtilization> =>
  createSelector(
    [toolUtilizationEntitiesSelector, toolUtilizationsLookupByPublicIdSelector(publicId)],
    (entities, id) => entities[id],
  );

const getByOnlyId = (utilizationId: number): Selector<AppState, ToolUtilization> =>
  createSelector(toolUtilizationEntitiesSelector, entities => entities[utilizationId] && entities[utilizationId]);

const getAllByParentId = (parentId: number): Selector<AppState, ToolUtilization[]> =>
  createSelector(toolUtilizationEntitiesSelector, entities => {
    return Object.values(entities).filter(tool => tool.parentId === parentId);
  });

const getParentTool = (parentId: number): Selector<AppState, ToolUtilization> =>
  createSelector(toolUtilizationEntitiesSelector, entities => {
    return Object.values(entities).find(tool => tool.id === parentId);
  });

const getByIds = <T extends ToolType>(
  utilizationIds: number[],
  type: T,
): Selector<AppState, UtilizationTypesMap[T][]> =>
  createSelector(toolUtilizationEntitiesSelector, entities =>
    utilizationIds
      .map(id => entities[id] && entities[id].type === type && (entities[id] as UtilizationTypesMap[T]))
      .filter(item => !!item),
  );

const getCecUtilization = (utilizationId: number) => {
  return getById(utilizationId, ToolType.CEC);
};

const getRrmUtilization = (utilizationId: number) => {
  return getById(utilizationId, ToolType.RRM);
};

const getUimUtilization = (utilizationId: number) => {
  return getById(utilizationId, ToolType.UIM);
};

const getFiveWhysUtilization = (utilizationId: number) => {
  return getById(utilizationId, ToolType.FIVE_WHYS);
};

const getApaUtilization = (utilizationId: number) => {
  return getById(utilizationId, ToolType.APA);
};

const getEbsUtilization = (utilizationId: number) => {
  return getById(utilizationId, ToolType.EBS);
};

const get40PUtilization = (utilizationId: number) => {
  return getById(utilizationId, ToolType.TOOL_40_PRINCIPLES);
};

const getNineWindowsUtilization = (utilizationId: number) => {
  return getById(utilizationId, ToolType.NINE_WINDOWS);
};

const getSfmUtilization = (utilizationId: number) => {
  return getById(utilizationId, ToolType.SFM);
};

const getPfmUtilization = (utilizationId: number) => {
  return getById(utilizationId, ToolType.PFM);
};

const getPMapUtilization = (utilizationId: number) => {
  return getById(utilizationId, ToolType.P_MAP);
};

const get40PForImprovementIteration = (
  improvementId: number,
  iterationId: number,
): Selector<AppState, FortyPrinciplesUtilization> =>
  createSelector(toolUtilizationEntitiesSelector, entities => {
    return Object.values(entities).find(tool => {
      if (tool.type === ToolType.TOOL_40_PRINCIPLES) {
        const tool40p = tool as FortyPrinciplesUtilization;

        if (tool40p.sourceImprovementId === improvementId && tool40p.sourceImprovementIterationId === iterationId) {
          return true;
        }
      }
    });
  });

// statuses by current team

const isLoadedByTeam = (): Selector<AppState, boolean> =>
  createSelector(getStatusesByTeam(), statuses => statuses.loaded);

const isLoadingByTeam = (): Selector<AppState, boolean> =>
  createSelector(getStatusesByTeam(), statuses => statuses.loading);

const isCreatingByTeam = (): Selector<AppState, boolean> =>
  createSelector(getStatusesByTeam(), statuses => statuses.creating);

const isCreatedByTeam = (): Selector<AppState, boolean> =>
  createSelector(getStatusesByTeam(), statuses => statuses.created);

const isSavingByTeam = (): Selector<AppState, boolean> =>
  createSelector(getStatusesByTeam(), statuses => statuses.saving);

const isRemovingByTeam = (): Selector<AppState, boolean> =>
  createSelector(getStatusesByTeam(), statuses => statuses.removing);

const isFailedByTeam = (): Selector<AppState, boolean> =>
  createSelector(getStatusesByTeam(), statuses => statuses.error);

const isEntityLoadingByTeam = (id: EntityStatusId): Selector<AppState, boolean> =>
  createSelector(getStatusesByTeam(), statuses => (statuses?.loadingIds || []).includes(id));

const lastCreatedIdByTeam = (): Selector<AppState, EntityStatusId> =>
  createSelector(getStatusesByTeam(), statuses => {
    const ids = statuses?.createdIds || [];
    return ids[ids.length - 1];
  });

// statuses by project

const isLoadedByProjectId = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByProjectId(projectId), statuses => statuses.loaded);

const isLoadingByProjectId = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByProjectId(projectId), statuses => statuses.loading);

const isCreatingByProjectId = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByProjectId(projectId), statuses => statuses.creating);

const isCreatedByProjectId = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByProjectId(projectId), statuses => statuses.created);

const isSavingByProjectId = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByProjectId(projectId), statuses => statuses.saving);

const isRemovingByProjectId = (projectId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByProjectId(projectId), statuses => statuses.removing);

const isEntityLoadingByProject = (projectId: number, id: EntityStatusId): Selector<AppState, boolean> =>
  createSelector(getStatusesByProjectId(projectId), statuses => (statuses?.loadingIds || []).includes(id));

const lastCreatedIdByProject = (projectId: number): Selector<AppState, EntityStatusId> =>
  createSelector(getStatusesByProjectId(projectId), statuses => {
    const ids = statuses?.createdIds || [];
    return ids[ids.length - 1];
  });

// fallback statuses

const isAnyCreating = (projectId?: number): Selector<AppState, boolean> => {
  if (typeof projectId !== 'undefined') {
    return isCreatingByProjectId(projectId);
  } else {
    return isCreatingByTeam();
  }
};

const isAnyCreated = (projectId?: number): Selector<AppState, boolean> => {
  if (typeof projectId !== 'undefined') {
    return isCreatedByProjectId(projectId);
  } else {
    return isCreatedByTeam();
  }
};

const isAnySaving = (projectId?: number): Selector<AppState, boolean> => {
  if (typeof projectId !== 'undefined') {
    return isSavingByProjectId(projectId);
  } else {
    return isSavingByTeam();
  }
};

const isAnyRemoving = (projectId?: number): Selector<AppState, boolean> => {
  if (typeof projectId !== 'undefined') {
    return isRemovingByProjectId(projectId);
  } else {
    return isRemovingByTeam();
  }
};

const isEntityLoading = (id: EntityStatusId, projectId?: number): Selector<AppState, boolean> => {
  if (typeof projectId !== 'undefined') {
    return isEntityLoadingByProject(projectId, id);
  } else {
    return isEntityLoadingByTeam(id);
  }
};

const isLoaded = (projectId?: number): Selector<AppState, boolean> => {
  if (typeof projectId !== 'undefined') {
    return isLoadedByProjectId(projectId);
  } else {
    return isLoadedByTeam();
  }
};

const lastCreatedId = (projectId?: number): Selector<AppState, EntityStatusId> => {
  if (typeof projectId !== 'undefined') {
    return lastCreatedIdByProject(projectId);
  } else {
    return lastCreatedIdByTeam();
  }
};

const isAssistanceLimitReached = (utilizationId: number): Selector<AppState, boolean> =>
  createSelector(toolUtilizationEntitiesSelector, entities => {
    const tool = entities[utilizationId];

    return tool && tool.publicId ? tool.metaData.aiAssistanceCount >= assistantLimits.anonymousToolUsageLimit : false;
  });

// statuses by utilization

const isUpdatingByUtilizationId = (utilizationId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByUtilizationId(utilizationId), statuses => statuses.updating);

const isUpdatedByUtilizationId = (utilizationId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByUtilizationId(utilizationId), statuses => statuses.updated);

const isRemovingByUtilizationId = (utilizationId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByUtilizationId(utilizationId), statuses => statuses.removing);

const isLoadedByUtilizationId = (utilizationId: number): Selector<AppState, boolean> =>
  createSelector(getStatusesByUtilizationId(utilizationId), statuses => statuses.loaded);

// statuses by publicId

const isLoadedByPublicUtilizationId = (publicId: string): Selector<AppState, boolean> =>
  createSelector(getStatusesByPublicUtilizationId(publicId), statuses => statuses.loaded);

const isLoadingByPublicUtilizationId = (publicId: string): Selector<AppState, boolean> =>
  createSelector(getStatusesByPublicUtilizationId(publicId), statuses => statuses.loading);

const isFailedByPublicUtilizationId = (publicId: string): Selector<AppState, boolean> =>
  createSelector(getStatusesByPublicUtilizationId(publicId), statuses => statuses.error);

const errorCodeByPublicUtilizationId = (publicId: string): Selector<AppState, number> =>
  createSelector(getStatusesByPublicUtilizationId(publicId), statuses => statuses.errorCode);

// statuses by action

const isPendingByActionType = (utilizationId: number, actionType: string): Selector<AppState, boolean> =>
  createSelector(getStatusesByActionType(utilizationId, actionType), status => status.pending);

const isAnyActionPending = (utilizationId: number, actionTypes: string[]): Selector<AppState, boolean> =>
  createSelector(getActionTypesStatutesMap(utilizationId), map => actionTypes.some(type => map[type]?.pending));

const isFailedByActionType = (utilizationId: number, actionType: string): Selector<AppState, boolean> =>
  createSelector(getStatusesByActionType(utilizationId, actionType), status => status.failed);

const isDoneByActionType = (utilizationId: number, actionType: string): Selector<AppState, boolean> =>
  createSelector(getStatusesByActionType(utilizationId, actionType), status => status.done);

export const ToolUtilizationSelector = {
  getById,
  getByIds,
  getByOnlyId,
  getByPublicId,
  getAllByProjectId,
  getAllByTeamId,
  getParentTool,
  getAllByParentId,
  getAllVisibleByProjectId,
  lastCreatedId,
  isAssistanceLimitReached,

  // specific tools selectors
  getCecUtilization,
  getApaUtilization,
  getFiveWhysUtilization,
  getEbsUtilization,
  getRrmUtilization,
  getUimUtilization,
  get40PUtilization,
  getNineWindowsUtilization,
  getSfmUtilization,
  getPfmUtilization,
  getPMapUtilization,
  get40PForImprovementIteration,

  // statuses by current team
  isLoadedByTeam,
  isLoadingByTeam,
  isCreatingByTeam,
  isCreatedByTeam,
  isSavingByTeam,
  isRemovingByTeam,
  isFailedByTeam,
  isEntityLoadingByTeam,

  // project statuses
  isLoadedByProjectId,
  isEntityLoadingByProject,
  isLoadingByProjectId,
  isCreatingByProjectId,
  isCreatedByProjectId,
  isSavingByProjectId,
  isRemovingByProjectId,

  // fallback statuses
  isAnyCreating,
  isAnyCreated,
  isAnySaving,
  isAnyRemoving,
  isEntityLoading,
  isLoaded,

  // utilization statuses
  isUpdatingByUtilizationId,
  isUpdatedByUtilizationId,
  isRemovingByUtilizationId,
  isLoadedByUtilizationId,

  // public utilization statuses
  isLoadedByPublicUtilizationId,
  isLoadingByPublicUtilizationId,
  isFailedByPublicUtilizationId,
  errorCodeByPublicUtilizationId,

  // action statuses
  isPendingByActionType,
  isAnyActionPending,
  isFailedByActionType,
  isDoneByActionType,
};
