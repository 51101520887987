import React, { useEffect, useState } from 'react';
import { Box, ListItemIcon, MenuItem, MenuList, Tooltip, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { useSelector } from 'react-redux';
import {
  SettingsOutlined,
  EditOutlined,
  DeleteOutlined,
  ContentCopyOutlined,
  CheckOutlined,
} from '@mui/icons-material';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { CreateVersionDialog } from './create-version-dialog/component';
import { EditVersionDialog } from './edit-version-dialog/component';
import { DiagramDataVersions } from '@priz/shared/src/models/tools';
import { ToolVersionUtils } from '@priz/shared/src/utils/tools';

export interface ToolVersionTabsCallbacks {
  onVersionCreate: (sourceVersionId?: string) => void;
  onVersionSelect: (versionId: string) => void;
  onVersionSwitch: (versionId: string) => void;
  onVersionDelete: (versionId: string) => void;
  onVersionRename: (versionId: string, text: string) => void;
}

interface ToolVersionTabsProps extends ToolVersionTabsCallbacks {
  utilizationId?: number;
  diagramData?: DiagramDataVersions;
  activeVersionId?: string;
  loading?: boolean;
  disabled?: boolean;
}

export const ToolVersionsTabs: React.FC<ToolVersionTabsProps> = ({
  utilizationId,
  diagramData,
  activeVersionId,
  loading,
  disabled,
  onVersionCreate,
  onVersionSelect,
  onVersionSwitch,
  onVersionDelete,
  onVersionRename,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const mainVersionId = diagramData?.versionId;
  const versions = diagramData?.versions;
  const isVersionsLimitReached = versions ? Object.keys(versions).length >= 5 : true;

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [isNewVersionDialogOpen, setIsNewVersionDialogOpen] = useState(false);

  const [editingVersionId, setEditingVersionId] = useState<string | null>(null);
  const [closeVersionsMenu, setCloseVersionsMenu] = useState(false);
  const [versionsCount, setVersionsCount] = useState<number>();

  const isUpdating = useSelector(ToolUtilizationSelector.isUpdatingByUtilizationId(utilizationId));
  const isUpdated = useSelector(ToolUtilizationSelector.isUpdatedByUtilizationId(utilizationId));

  const rootClassNames = [styles.root];

  if (loading) {
    rootClassNames.push(styles.loading);
  }

  useEffect(() => {
    if (isUpdated) {
      closeDialogs();
    }
  }, [isUpdated]);

  useEffect(() => {
    if (versions) {
      const newVersionsCount = Object.keys(versions).length;

      if (typeof versionsCount !== 'undefined' && versionsCount < newVersionsCount) {
        onVersionSelect(ToolVersionUtils.getOrderedVersionsKeys(versions).pop());
      }

      setVersionsCount(newVersionsCount);
    }
  }, [versions]);

  const switchVersion = (version: string) => {
    setCloseVersionsMenu(true);
    onVersionSwitch(version);
  };

  const renameVersion = (title: string) => {
    setEditingVersionId(null);
    onVersionRename(editingVersionId, title);
  };

  const deleteVersion = () => {
    if (editingVersionId === activeVersionId) {
      onVersionSelect(mainVersionId);
    }

    setEditingVersionId(null);
    onVersionDelete(editingVersionId);
  };

  const createNewVersion = (sourceVersionId: string) => {
    setCloseVersionsMenu(true);
    onVersionCreate(sourceVersionId);
  };

  const openNewVersionDialog = () => {
    setIsNewVersionDialogOpen(true);
    setCloseVersionsMenu(true);
  };

  const openEditDialog = (version: string) => {
    setEditingVersionId(version);
    setIsEditDialogOpen(true);
    setCloseVersionsMenu(true);
  };

  const openRemoveDialog = (version: string) => {
    setEditingVersionId(version);
    setIsRemoveDialogOpen(true);
    setCloseVersionsMenu(true);
  };

  const closeDialogs = () => {
    setIsEditDialogOpen(false);
    setIsRemoveDialogOpen(false);
    setIsNewVersionDialogOpen(false);
    setEditingVersionId(null);
    setCloseVersionsMenu(false);
  };

  const popperOpenToggleHandler = (open: boolean) => {
    if (!open) setCloseVersionsMenu(false);
  };

  if (!mainVersionId || !versions) return null;

  return (
    <Box className={rootClassNames.join(' ')}>
      {ToolVersionUtils.getOrderedVersionsKeys(versions).map((versionId, key) => {
        const version = versions[versionId];
        const isSelected = versionId === activeVersionId;
        const isMain = versionId === mainVersionId;
        const tabClassNames = [styles.tab];
        const title = version.title.length ? version.title : `${t('Version')} ${key + 1}`;

        if (!isMain && !isSelected) tabClassNames.push(styles.tabDefault);
        if (!isMain && isSelected) tabClassNames.push(styles.tabActive);
        if (isMain && !isSelected) tabClassNames.push(styles.tabMain);
        if (isMain && isSelected) tabClassNames.push(styles.tabMainActive);

        return (
          <div className={styles.tabContainer} key={versionId}>
            <Box className={tabClassNames.join(' ')}>
              <Tooltip title={title} arrow={true} disableInteractive={true} placement={'top'}>
                <Box
                  pl={isMain ? 0.5 : 1.5}
                  pr={1}
                  className={styles.textContainer}
                  onClick={() => {
                    if (!isSelected) onVersionSelect(versionId);
                  }}
                >
                  {isMain && (
                    <Box pr={0.5}>
                      <CheckOutlined fontSize="small" sx={{ display: 'block' }} />
                    </Box>
                  )}

                  <Typography variant={'subtitle2'} noWrap>
                    {title}
                  </Typography>
                </Box>
              </Tooltip>

              <PopperWrap
                placement={'top'}
                containerProps={{
                  className: styles.iconContainer,
                  px: 1,
                  py: 0.75,
                }}
                dropdownProps={{
                  p: 0,
                }}
                open={closeVersionsMenu ? false : undefined}
                onOpenToggle={popperOpenToggleHandler}
              >
                <SettingsOutlined className={styles.icon} />

                <Box>
                  <MenuList>
                    <MenuItem onClick={() => switchVersion(versionId)} disabled={disabled || isMain}>
                      <ListItemIcon>
                        <CheckOutlined fontSize="small" color={'success'} />
                      </ListItemIcon>

                      <Trans>{isMain ? 'Main' : 'Set as Main'}</Trans>
                    </MenuItem>

                    <MenuItem onClick={() => createNewVersion(versionId)} disabled={disabled || isVersionsLimitReached}>
                      <ListItemIcon>
                        <ContentCopyOutlined fontSize="small" color={'primary'} />
                      </ListItemIcon>

                      <Trans>Clone</Trans>
                    </MenuItem>

                    <MenuItem onClick={() => openEditDialog(versionId)} disabled={disabled}>
                      <ListItemIcon>
                        <EditOutlined fontSize="small" color={'primary'} />
                      </ListItemIcon>

                      <Trans>Rename</Trans>
                    </MenuItem>

                    <MenuItem
                      onClick={() => openRemoveDialog(versionId)}
                      disabled={disabled || isMain || Object.keys(versions || {}).length < 2}
                    >
                      <ListItemIcon>
                        <DeleteOutlined fontSize="small" color={'error'} />
                      </ListItemIcon>

                      <Trans>Delete</Trans>
                    </MenuItem>
                  </MenuList>
                </Box>
              </PopperWrap>
            </Box>
          </div>
        );
      })}

      {!disabled && !isVersionsLimitReached && (
        <div className={styles.tabContainer}>
          <Box className={[styles.tab, styles.tabNew].join(' ')} onClick={openNewVersionDialog}>
            <Box px={1.5} py={0.25} className={styles.textContainer}>
              <Typography variant={'subtitle2'} noWrap>
                + <Trans>Add new version</Trans>
              </Typography>
            </Box>
          </Box>
        </div>
      )}

      <EditVersionDialog
        editingVersionId={editingVersionId}
        open={isEditDialogOpen}
        diagramData={diagramData}
        onSubmit={renameVersion}
        onCancel={closeDialogs}
        loading={isUpdating}
      />

      <CreateVersionDialog
        open={isNewVersionDialogOpen}
        diagramData={diagramData}
        onSubmit={createNewVersion}
        onCancel={closeDialogs}
        loading={isUpdating}
      />

      <PgConfirmationDialog
        isOpen={isRemoveDialogOpen}
        confirmTitle={<Trans>Confirm deletion</Trans>}
        okButtonText={<Trans>Delete</Trans>}
        confirmContent={
          <React.Fragment>
            <Trans>Are you sure you want to delete this version?</Trans>
          </React.Fragment>
        }
        onConfirm={deleteVersion}
        onClose={closeDialogs}
        loading={isUpdating}
      />
    </Box>
  );
};
