import React from 'react';
import { useStyles } from './styles';
import { Box, Tooltip } from '@mui/material';
import { Trans } from 'react-i18next';

export interface CanvasControlButtonProps {
  id?: string;
  tooltip?: string;
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
}

export const CanvasControlButton: React.FC<CanvasControlButtonProps> = ({
  id,
  tooltip,
  onClick,
  active,
  disabled,
  children,
}) => {
  const styles = useStyles();
  const rootClassNames = [styles.root];

  if (active) {
    rootClassNames.push(styles.active);
  }

  if (disabled) {
    rootClassNames.push(styles.disabled);
  }

  return (
    <Tooltip title={tooltip?.length ? <Trans>{tooltip}</Trans> : ''} placement={'right'} arrow disableInteractive>
      <Box id={id} className={rootClassNames.join(' ')} onClick={onClick}>
        {children}
      </Box>
    </Tooltip>
  );
};
