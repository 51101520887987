import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { OptionalComponentRankMap, SfmRank, SfmRankMap, SfmVersionsRanksMap } from '@priz/shared/src/models/tools/sfm';
import {
  ProblematicComponentPreview,
  SfmComponentImprovementMap,
  SfmComponentsEliminationEffectMap,
} from './improvements';

export interface SfmComponentsRanks {
  byUtilizationId: {
    [utilizationId: number]: OptionalComponentRankMap;
  };
}

export interface SfmVersionComponentsRanks {
  byVersionId: {
    [byVersionId: number]: OptionalComponentRankMap;
  };
}

export interface SfmUtilizationsRanks {
  byPfmId: {
    [pfmId: number]: SfmRankMap;
  };
  byUtilizationId: {
    [utilizationId: number]: SfmRank;
  };
}

export interface SfmUtilizationVersionsRanks {
  byUtilizationId: {
    [utilizationId: number]: SfmVersionsRanksMap;
  };
}

export interface SfmComponentsEliminationEffectState {
  byUtilizationId: {
    [utilizationId: number]: {
      byVersionId: {
        [versionId: string]: SfmComponentsEliminationEffectMap;
      };
    };
  };
}

export interface SfmComponentsImprovementsState {
  byUtilizationId: {
    [utilizationId: number]: {
      byVersionId: {
        [versionId: string]: SfmComponentImprovementMap;
      };
    };
  };
}

export interface SfmProblematicComponentsState {
  byUtilizationId: {
    [utilizationId: number]: {
      byVersionId: {
        [versionId: string]: ProblematicComponentPreview[];
      };
    };
  };
}

export interface SfmResultStatuses {
  byUtilizationId: { [utilizationId: number]: EntityCollectionStatus };
  byVersionId: { [versionId: string]: EntityCollectionStatus };
  byNodeId: { [versionId: string]: EntityCollectionStatus };
}

export interface SfmResultCollection {
  componentsRanks: SfmComponentsRanks;
  versionComponentsRanks: SfmVersionComponentsRanks;
  sfmRanks: SfmUtilizationsRanks;
  sfmVersionsRanks: SfmUtilizationVersionsRanks;
  componentsEliminationEffect: SfmComponentsEliminationEffectState;
  componentsImprovements: SfmComponentsImprovementsState;
  problematicComponents: SfmProblematicComponentsState;
  statuses: SfmResultStatuses;
}

export const DefaultSfmResultCollection: SfmResultCollection = {
  componentsRanks: {
    byUtilizationId: {},
  },

  versionComponentsRanks: {
    byVersionId: {},
  },

  sfmRanks: {
    byPfmId: {},
    byUtilizationId: {},
  },

  sfmVersionsRanks: {
    byUtilizationId: {},
  },

  componentsEliminationEffect: {
    byUtilizationId: {},
  },

  componentsImprovements: {
    byUtilizationId: {},
  },

  problematicComponents: {
    byUtilizationId: {},
  },

  statuses: {
    byUtilizationId: {},
    byVersionId: {},
    byNodeId: {},
  },
};
