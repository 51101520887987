import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    minHeightWrap: {},

    [theme.breakpoints.up('sm')]: {
      minHeightWrap: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 34,
      },
    },
  }),
);
