import React, { useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useForm } from 'react-hook-form';
import { useStyles } from './styles';
import { Alert } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { getTextAndImages } from '@priz/shared/src/utils/form';
import { ReactHookFormQuill } from '../../react/form-elements';
import { QuillContentViewer } from '@priz/shared/src/components/quill-content-viewer/component';

export interface ToolSubjectConclusionProps {
  initialContent?: string;
  onContentChange?: (text: string) => void;
  disabled?: boolean;
  viewMode?: boolean;
  placeholder?: string;
}

export const ToolSubjectConclusion: React.FC<ToolSubjectConclusionProps> = ({
  onContentChange,
  initialContent,
  disabled,
  viewMode = false,
  placeholder,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const { getValues, control } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      content: initialContent || '',
    },
  });

  const changeHandler = useCallback(
    debounce(() => {
      if (onContentChange) onContentChange(getValues().content);
    }, 500),
    [onContentChange],
  );

  return (
    <form onSubmit={e => e.preventDefault()} className={styles.root}>
      {viewMode ? (
        getTextAndImages(initialContent)?.length ? (
          <QuillContentViewer content={initialContent} />
        ) : (
          <Alert severity={'info'}>
            <Trans>Nothing to show</Trans>
          </Alert>
        )
      ) : (
        <ReactHookFormQuill
          name={'content'}
          control={control}
          readOnly={disabled}
          onChange={changeHandler}
          placeholder={placeholder ? t(placeholder) : undefined}
        />
      )}
    </form>
  );
};
