import { TaskAssignee, TaskAssignment } from '@priz/shared/src/models/assignment';
import axios, { AxiosResponse } from 'axios';

const fetchAll = (): Promise<TaskAssignee[]> =>
  axios.get(`/v1/api/<ws-id>/task-assignees`).then((response: AxiosResponse<TaskAssignee[]>) => response.data);

const fetchAllByProject = (projectId: number): Promise<TaskAssignee[]> =>
  axios
    .get(`/v1/api/<ws-id>/project/${projectId}/task-assignees`)
    .then((response: AxiosResponse<TaskAssignee[]>) => response.data);

const create = (projectId: number, taskId: number, assigneeEmail: string): Promise<TaskAssignee> =>
  axios
    .post(`/v1/api/<ws-id>/project/${projectId}/task/${taskId}/assignee`, { assigneeEmail })
    .then((response: AxiosResponse<TaskAssignee>) => response.data);

const doDelete = (projectId: number, taskId: number, assigneeEmail: string): Promise<TaskAssignment> =>
  axios
    .delete(`/v1/api/<ws-id>/project/${projectId}/task/${taskId}/assignee/${assigneeEmail}`)
    .then((response: AxiosResponse<TaskAssignment>) => response.data);

export const TaskAssignmentApi = {
  fetchAll,
  fetchAllByProject,
  create,
  delete: doDelete,
};
