import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    assistantSvg: {
      height: 'auto',
      width: 100,
      flex: '0 0 auto',
      marginTop: 'auto',
    },
    popper: {
      'zIndex': theme.zIndex.modal + 100,
      'wordBreak': 'break-word',

      '&[data-popper-placement="top"], &[data-popper-placement="top-start"], &[data-popper-placement="top-end"]': {
        'paddingBottom': 8,

        '&.arrow-padding': {
          paddingBottom: 16,
        },

        '& $arrow': {
          'top': 'calc(100% - 9px)',
          'bottom': 'auto',

          '&::before': {
            transform: 'rotate(225deg)',
          },
        },
      },
      '&[data-popper-placement="bottom"], &[data-popper-placement="bottom-start"], &[data-popper-placement="bottom-end"]':
        {
          'paddingTop': 8,

          '&.arrow-padding': {
            paddingTop: 16,
          },

          '& $arrow': {
            'top': 'auto',
            'bottom': 'calc(100% - 9px)',

            '&::before': {
              transform: 'rotate(45deg)',
            },
          },
        },

      '&[data-popper-placement="left"], &[data-popper-placement="left-start"], &[data-popper-placement="left-end"]': {
        'paddingRight': 8,

        '&.arrow-padding': {
          paddingRight: 16,
        },

        '& $arrow': {
          'right': 'auto',
          'left': 'calc(100% - 9px)',

          '&::before': {
            transform: 'rotate(135deg)',
          },
        },
      },

      '&[data-popper-placement="right"], &[data-popper-placement="right-start"], &[data-popper-placement="right-end"]':
        {
          'paddingLeft': 8,

          '&.arrow-padding': {
            paddingLeft: 16,
          },

          '& $arrow': {
            'right': 'calc(100% - 9px)',
            'left': 'auto',

            '&::before': {
              transform: 'rotate(315deg)',
            },
          },
        },
    },
    arrow: {
      'width': 18,
      'height': 18,

      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
        transformOrigin: '50% 50%',
        top: '0',
        left: '0',
        bottom: 'auto',
        right: 'auto',
        boxSizing: 'border-box',
        boxShadow: '-1px -1px 1px 0px rgb(0 102 157 / 12%)',
        zIndex: 1,
        backgroundColor: pgColorScheme.white,
      },
    },
    [theme.breakpoints.down('lg')]: {},

    [theme.breakpoints.down('sm')]: {},
  }),
);
