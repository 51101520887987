import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles as useApaStyles } from '../styles';
import { Box, Button, Chip, Divider, Grid, Hidden, Typography } from '@mui/material';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { ToolTitle } from '../../tools/tool-title/component';
import { Trans, useTranslation } from 'react-i18next';
import { pgColorScheme } from '@priz/shared/src/theme';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { useForm } from 'react-hook-form';
import { ApaAction, ApaActionStatus } from '@priz/shared/src/models/tools/apa';
import { ProjectSelector, ToolUtilizationSelector } from '../../project/store/selectors';
import { ApaActionActions } from '../store/actions';
import { ApaActionSelectors } from '../store/selectors/apa-action.selectors';
import { ReactHookFormText } from '../../react/form-elements';
import { Link } from 'react-router-dom';
import { Assistant } from '../../assistant/component';
import { AssistanceType, AssistantVariant } from '../../assistant/store/model';
import { HintLinesSeparatorType, HintRowType } from '../../assistant/hint-content/component';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { ToolUtilizationActions, ToolUtilizationActionType } from '../../project/store/actions/tools.actions';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { ToolsUtils } from '../../tools/utils';

interface ApaProcessActionProps {
  utilizationId: number;
  actionId: number;
  projectId?: number;
}

export const ApaProcessAction: React.FC<ApaProcessActionProps> = ({ projectId, utilizationId, actionId }) => {
  const { t } = useTranslation();
  const apaStyles = useApaStyles();
  const dispatch = useDispatch();

  const [completeActionConfirmOpen, setCompleteActionConfirmOpen] = useState(false);
  const [action, setAction] = useState<ApaAction>(null);

  const selectedWorkspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const apaUtilization = useSelector(ToolUtilizationSelector.getApaUtilization(utilizationId));
  const apaAction = useSelector(ApaActionSelectors.getById(actionId));

  const isUpdatingByUtilizationId = useSelector(ApaActionSelectors.isUpdatingByUtilizationId(apaUtilization?.id));
  const isUpdatedByUtilizationId = useSelector(ApaActionSelectors.isUpdatedByUtilizationId(apaUtilization?.id));
  const isProjectLocked = useSelector(ProjectSelector.isProjectLockedById(projectId));
  const isAiAssistantEnabled = useSelector(WorkspaceSelectors.isAiAssistantEnabled(selectedWorkspace?.id));
  const isCompletenessUpdating = useSelector(
    ToolUtilizationSelector.isPendingByActionType(utilizationId, ToolUtilizationActionType.UpdateCompleteness),
  );

  const isDisabled = !!apaUtilization?.completed || isCompletenessUpdating || isProjectLocked;

  const isCustomerActionDefined = !!apaAction?.customerAction?.length;
  const isActionPurposeDone = !!apaAction?.actionPurpose?.length;
  const isPreventingActionDone = !!apaAction?.preventingAction?.length;
  const isCustomerNeedsDone = !!apaAction?.customerNeeds?.length;
  const allDone = isActionPurposeDone && isPreventingActionDone && isCustomerNeedsDone;
  const isActionCompleted = apaAction && apaAction.status === ApaActionStatus.Completed;

  const { control, reset, getValues, setValue } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      actionPurpose: '',
      preventingAction: '',
      customerNeeds: '',
    },
  });

  useEffect(() => {
    if (isUpdatedByUtilizationId) {
      closeCompleteActionConfirm();
    }
  }, [isUpdatedByUtilizationId]);

  useEffect(() => {
    dispatch(ToolUtilizationActions.loadTool(utilizationId, projectId));
    dispatch(ApaActionActions.get(utilizationId, actionId, projectId));
  }, [utilizationId, projectId]);

  useEffect(() => {
    if (apaAction && !action) {
      setAction(apaAction);
    }
  }, [apaAction, action]);

  useEffect(() => {
    reset({
      actionPurpose: action?.actionPurpose || '',
      preventingAction: action?.preventingAction || '',
      customerNeeds: action?.customerNeeds || '',
    });
  }, [action]);

  const openCompleteActionConfirm = () => {
    setCompleteActionConfirmOpen(true);
  };

  const closeCompleteActionConfirm = () => {
    setCompleteActionConfirmOpen(false);
  };

  const completeAction = () => {
    dispatch(ApaActionActions.complete(utilizationId, actionId, projectId));
  };

  const useActionPurposeHint = (text: string[]) => {
    setValue('actionPurpose', text[0]);
    formChangeHandler();
  };

  const usePreventingActionHint = (text: string[]) => {
    setValue('preventingAction', text[0]);
    formChangeHandler();
  };

  const useCustomerNeedsHint = (text: string[]) => {
    setValue('customerNeeds', text[0]);
    formChangeHandler();
  };

  const onTextUpdate = useCallback(
    debounce(() => {
      dispatch(ApaActionActions.update(utilizationId, { ...action, ...getValues() }, projectId));
    }, 500),
    [action],
  );

  const formChangeHandler = () => {
    onTextUpdate();
  };

  if (!apaUtilization || !apaAction) return null;

  return (
    <>
      <ContentContainer>
        <PageTitleWithDocLink
          titleComponent={
            <ToolTitle
              initialTitle={'Action Preventing Action (APA)'}
              tool={apaUtilization}
              preset={'large'}
              editable={!isDisabled}
            />
          }
          oneLiner={<Trans>Use APA to search for what your customer wants</Trans>}
          // docsUrl={'https://www.priz.guru/knowledge-base/#/'}
        />

        <Grid container>
          <Grid item xs={true}>
            <Box mb={2}>
              <Typography variant={'h6'}>
                <Trans>Customer definition</Trans>
              </Typography>
            </Box>

            <Box mb={1.5}>
              <Typography variant={'caption'} style={{ color: pgColorScheme.gray }}>
                <Trans>Customer</Trans>
              </Typography>

              <Typography variant={'body1'}>{apaUtilization.customer}</Typography>
            </Box>

            <Box mb={1.5}>
              <Typography variant={'caption'} style={{ color: pgColorScheme.gray }}>
                <Trans>Customer description</Trans>
              </Typography>

              <Typography variant={'body1'} whiteSpace={'pre-line'}>
                {apaUtilization.customerDescription}
              </Typography>
            </Box>

            <Box mb={2}>
              <Typography variant={'h6'}>
                <Trans>Action definition</Trans>
              </Typography>
            </Box>

            <Typography variant={'caption'} style={{ color: pgColorScheme.gray }}>
              <Trans>Action</Trans>
            </Typography>

            <Typography variant={'body1'} whiteSpace={'pre-line'}>
              {apaAction.customerAction}
            </Typography>

            <Box mt={2} mb={3}>
              <Divider />
            </Box>

            <Box mb={2}>
              <Typography variant={'h6'}>
                <Trans>Find customer needs</Trans>
              </Typography>
            </Box>

            <form onSubmit={e => e.preventDefault()} onChange={formChangeHandler}>
              <Box mb={1}>
                <Chip label={`1. ${t('Action purpose')}`} size={'small'} />
              </Box>

              <Grid container alignItems={'center'} spacing={1} mb={1}>
                <Grid item>
                  <Typography variant={'subtitle2'}>
                    <Trans>Why is the customer doing it? What is he trying to achieve doing it?</Trans>
                  </Typography>
                </Grid>

                {isAiAssistantEnabled && (
                  <Grid item>
                    <Assistant
                      type={AssistanceType.AskApaActionPurpose}
                      variant={AssistantVariant.Popper}
                      projectId={projectId}
                      actionProps={{ utilizationId, apaActionId: actionId }}
                      onUseHint={useActionPurposeHint}
                      disabled={isDisabled || isActionCompleted || !isCustomerActionDefined}
                      buttonProps={{
                        tooltip: !isCustomerActionDefined ? 'Customer action is required' : '',
                      }}
                      popperAssistantProps={{
                        placement: 'right',
                        modifiers: [
                          {
                            name: 'preventOverflow',
                            options: {
                              altAxis: true,
                            },
                          },
                        ],
                      }}
                    />
                  </Grid>
                )}
              </Grid>

              <Box mb={2}>
                <ReactHookFormText
                  multiline
                  name={'actionPurpose'}
                  control={control}
                  disabled={isDisabled || isActionCompleted}
                  wrapperProps={{ mb: 0 }}
                />
              </Box>

              <Box mb={1}>
                <Chip label={`2. ${t('Action Preventing Action')}`} size={'small'} />
              </Box>

              <Grid container alignItems={'center'} spacing={1} mb={1}>
                <Grid item>
                  <Typography variant={'subtitle2'}>
                    <Trans>What should be done to prevent the customer from doing the action?</Trans>
                  </Typography>
                </Grid>

                {isAiAssistantEnabled && (
                  <Grid item>
                    <Assistant
                      type={AssistanceType.AskApaPreventingAction}
                      variant={AssistantVariant.Popper}
                      projectId={projectId}
                      actionProps={{ utilizationId, apaActionId: actionId }}
                      onUseHint={usePreventingActionHint}
                      disabled={isDisabled || isActionCompleted || !isActionPurposeDone}
                      contentProps={{
                        linesSeparator: HintLinesSeparatorType.OrderNumber,
                        hintRowsType: HintRowType.Radio,
                      }}
                      buttonProps={{
                        tooltip: !isActionPurposeDone ? 'Action purpose is required' : '',
                      }}
                      popperAssistantProps={{
                        placement: 'right',
                        modifiers: [
                          {
                            name: 'preventOverflow',
                            options: {
                              altAxis: true,
                            },
                          },
                        ],
                      }}
                    />
                  </Grid>
                )}
              </Grid>

              <Box mb={2}>
                <ReactHookFormText
                  multiline
                  name={'preventingAction'}
                  control={control}
                  disabled={isDisabled || isActionCompleted}
                  wrapperProps={{ mb: 0 }}
                />
              </Box>

              <Box mb={1}>
                <Chip label={`3. ${t('Customer needs')}`} size={'small'} />
              </Box>

              <Grid container alignItems={'center'} spacing={1} mb={1}>
                <Grid item>
                  <Typography variant={'subtitle2'}>
                    <Trans>How can we achieve it?</Trans>
                  </Typography>
                </Grid>

                {isAiAssistantEnabled && (
                  <Grid item>
                    <Assistant
                      type={AssistanceType.AskApaCustomerNeeds}
                      variant={AssistantVariant.Popper}
                      projectId={projectId}
                      actionProps={{ utilizationId, apaActionId: actionId }}
                      onUseHint={useCustomerNeedsHint}
                      disabled={isDisabled || isActionCompleted || !isActionPurposeDone || !isPreventingActionDone}
                      contentProps={{
                        linesSeparator: HintLinesSeparatorType.OrderNumber,
                        hintRowsType: HintRowType.Radio,
                      }}
                      buttonProps={{
                        tooltip:
                          !isActionPurposeDone || !isPreventingActionDone
                            ? 'Action purpose and Preventing action are required'
                            : '',
                      }}
                      popperAssistantProps={{
                        placement: 'right',
                        modifiers: [
                          {
                            name: 'preventOverflow',
                            options: {
                              altAxis: true,
                            },
                          },
                        ],
                      }}
                    />
                  </Grid>
                )}
              </Grid>

              <Box mb={2}>
                <ReactHookFormText
                  multiline
                  name={'customerNeeds'}
                  control={control}
                  disabled={isDisabled || isActionCompleted}
                  wrapperProps={{ mb: 0 }}
                />
              </Box>
            </form>

            <Button
              variant={'pg_rounded'}
              color={'pg_orange_solid'}
              onClick={openCompleteActionConfirm}
              disabled={!allDone || isActionCompleted || isDisabled}
            >
              <Trans>Done</Trans>
            </Button>
          </Grid>

          <Hidden smDown>
            <Grid item>
              <Box ml={3} className={apaStyles.rightPane} />
            </Grid>
          </Hidden>
        </Grid>
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={projectId}
          utilization={apaUtilization}
          leftColContent={
            <Button
              component={Link}
              to={[ToolsUtils.resolvePathByUtilization(apaUtilization), 'actions'].join('/')}
              variant={'pg_rounded'}
              color={'pg_orange_outlined'}
            >
              <Trans>Back to actions</Trans>
            </Button>
          }
          workingFlowProps={{ nextVariant: 'ideas' }}
          disabled={isDisabled}
        />
      </ContentFooter>

      <PgConfirmationDialog
        isOpen={completeActionConfirmOpen}
        confirmTitle={<Trans>Please, confirm</Trans>}
        okButtonText={<Trans>Complete</Trans>}
        confirmContent={
          <React.Fragment>
            <Trans>
              Are you sure you finished processing this action? Once marked as finished, it cannot be undone.
            </Trans>
          </React.Fragment>
        }
        onConfirm={completeAction}
        onClose={closeCompleteActionConfirm}
        loading={isUpdatingByUtilizationId}
      />
    </>
  );
};
