import React, { useEffect } from 'react';
import { Alert, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Trans } from 'react-i18next';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { PartnershipSelectors } from '../store/selectors';
import { PartnershipActions } from '../store/actions';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { format } from 'date-fns';
import CurrencyFormat from 'react-currency-format';
import { PartnerCommissionStatus } from '@priz/shared/src/models/security/partner-commission-status';

interface CommissionHistoryProps {
  referralId: number;
}

export const CommissionHistory: React.FC<CommissionHistoryProps> = ({ referralId }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const commissions = useSelector(PartnershipSelectors.getCommissionsByReferralId(referralId));
  const isLoading = useSelector(PartnershipSelectors.areCommissionsLoadingByReferralId(referralId));
  const isLoaded = useSelector(PartnershipSelectors.areCommissionsLoadedByReferralId(referralId));

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      dispatch(PartnershipActions.loadReferralsCommissions(referralId));
    }
  }, [referralId, isLoading, isLoaded]);

  if (!isLoaded) {
    return (
      <LoadingOverlay
        loading={true}
        backdropStyles={{ backgroundColor: 'transparent', position: 'relative' }}
        progressContainerProps={{ py: 2 }}
      />
    );
  }

  if (isLoaded && !commissions?.length) {
    return (
      <Alert severity={'info'}>
        <Trans>There are no commissions yet</Trans>
      </Alert>
    );
  }

  return (
    <TableContainer component={Paper} variant={'outlined'}>
      <Table size={'small'}>
        <TableHead className={styles.tableHead}>
          <TableRow>
            {['Date', 'Rate', 'Value', 'Status'].map((item, key) => (
              <TableCell key={key}>
                <Trans>{item}</Trans>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {commissions
            .sort((l, r) => (l.date > r.date ? -1 : 1))
            .map((item, key) => (
              <TableRow key={key}>
                <TableCell>{format(item.date, 'MMM d yyyy HH:mm')}</TableCell>

                <TableCell>{item.rate}%</TableCell>

                <TableCell>
                  <CurrencyFormat
                    value={item.value}
                    displayType={'text'}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </TableCell>

                <TableCell>
                  {item.status === PartnerCommissionStatus.Pending && (
                    <Chip size={'small'} color={'primary'} label={<Trans>pending</Trans>} />
                  )}

                  {item.status === PartnerCommissionStatus.Paid && (
                    <Chip size={'small'} color={'success'} label={<Trans>paid</Trans>} />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
