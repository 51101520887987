import React, { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Attendee, Meeting } from '../store/model';
import { ManageAccountsOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { Trans } from 'react-i18next';
import { AttendeeSelectors } from '../store/selectors';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { AttendeeRole } from '../attendee-role/component';

export interface AttendingButtonProps {
  meeting: Meeting;
  attendees: Attendee[];
}

export const AttendingButton: React.FC<AttendingButtonProps> = ({ meeting, attendees, children }) => {
  const [isAttendeePopperOpen, setIsAttendeePopperOpen] = useState(false);

  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const isAnyAttendeeUpdated = useSelector(AttendeeSelectors.isAnyUpdated());

  const attendee = attendees.find(item => item?.user?.id === currentUser?.id);

  useEffect(() => {
    if (isAnyAttendeeUpdated) closeAttendeePopper();
  }, [isAnyAttendeeUpdated]);

  const closeAttendeePopper = () => {
    setIsAttendeePopperOpen(false);
  };

  const toggleAttendeePopper = () => {
    setIsAttendeePopperOpen(currentState => !currentState);
  };

  return (
    <PopperWrap open={isAttendeePopperOpen} openHandler={'manually'} onClose={closeAttendeePopper}>
      <Tooltip title={<Trans>Attending</Trans>} arrow={true} placement={'top'} disableInteractive={true}>
        <div onClick={toggleAttendeePopper}>
          {children || (
            <IconButton size={'small'} color={'primary'}>
              <ManageAccountsOutlined fontSize={'small'} />
            </IconButton>
          )}
        </div>
      </Tooltip>

      <Box>
        <AttendeeRole meeting={meeting} forcedAttendee={attendee} />
      </Box>
    </PopperWrap>
  );
};
