import { TemplateOptionType } from './options';

export enum TemplateType {
  General = 'General',
  ExcursionPrevention = 'ExcursionPrevention',
  LeanSixSigma = 'LeanSixSigma',
  RootCauseAnalysis = 'RootCauseAnalysis',
}

export enum TemplateCategory {
  Preparing = 'Preparing',
  Tools = 'Tools',
  Activity = 'Activity',
  Result = 'Result',
}

export type TemplateCompletion = {
  [key in TemplateOptionType]: boolean;
};
