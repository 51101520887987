import React, { useEffect, useState } from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { ToolTitle } from '../../tools/tool-title/component';
import { TitleContainer } from '../../react/modules/title-container/component';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { CftWorkspace } from '../cft-workspace/component';
import { Trans } from 'react-i18next';
import { useToolTabs } from '../../tools/utils';
import { CftTab, CftUpdateUtilizationCommand, CftUpdateVersionDataCommand } from '../store/model';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectSelector, ToolUtilizationSelector } from '../../project/store/selectors';
import { ToolType } from '@priz/shared/src/models/tools';
import { TabPanel } from '../../react/elements/tab-panel/component';
import { ToolSubjectConclusion } from '../../tools/tool-subject-conclusion/component';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { ToolUtilizationActions, ToolUtilizationActionType } from '../../project/store/actions/tools.actions';
import { ToolVersionUtils } from '@priz/shared/src/utils/tools';
import { CftVersionsTabs } from '../cft-versions-tabs/component';
import { CftNodeData } from '@priz/shared/src/models/tools/cft';
import { CftUtilizationActions } from '../store/actions';
import { CftContextProvider } from '@priz/shared/src/components/cft/cft-context/component';
import { ElementsViewerProvider } from '@priz/shared/src/components/elements-viewer/elements-viewer-context/component';
import { MeetingSelectors } from '../../meetings/store/selectors';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { MeetingActions } from '../../meetings/store/actions';
import { TaskActions } from '../../tasks/store/actions/task.actions';
import { TaskSelectors } from '../../tasks/store/selectors/task.selectors';
import { IdeasCommentsActions, IdeasSelectors, ProjectIdeaActions } from '../../idea/store';
import { ProjectAssigneeActions } from '../../assignment/store/actions';
import { WorkspaceMembersActions } from '../../workspace/store/actions';

export interface CftViewProps {
  utilizationId: number;
  projectId?: number;
}

const CftViewComponent: React.FC<CftViewProps> = ({ utilizationId, projectId }) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useToolTabs<CftTab>(CftTab.Subject);
  const [activeVersionId, setActiveVersionId] = useState<string>();

  const cftUtilization = useSelector(ToolUtilizationSelector.getById(utilizationId, ToolType.CFT));
  const isProjectLocked = useSelector(ProjectSelector.isProjectLockedById(projectId));
  const isLoading = useSelector(ToolUtilizationSelector.isLoadingByProjectId(projectId));
  const meetings = useSelector(MeetingSelectors.getAllByProjectId(projectId));
  const tools = useSelector(ToolUtilizationSelector.getAllVisibleByProjectId(projectId));
  const tasks = useSelector(TaskSelectors.getAllByProjectId(projectId));
  const ideas = useSelector(IdeasSelectors.getIdeasArrayByProjectId(projectId));
  const featureSet = useSelector(WorkspaceSelectors.getApplicableFeatureSet(projectId));
  const isCompletenessUpdating = useSelector(
    ToolUtilizationSelector.isPendingByActionType(utilizationId, ToolUtilizationActionType.UpdateCompleteness),
  );

  const isDisabled = !!cftUtilization?.completed || isCompletenessUpdating || isProjectLocked;

  useEffect(() => {
    dispatch(ToolUtilizationActions.loadTool(utilizationId, projectId));
  }, [utilizationId, projectId]);

  useEffect(() => {
    if (projectId) {
      dispatch(ToolUtilizationActions.loadTools(projectId));
      dispatch(MeetingActions.getAll(projectId));
      dispatch(TaskActions.fetchAllByProjectId(projectId));
      dispatch(ProjectIdeaActions.loadProjectIdeas(projectId));
      dispatch(IdeasCommentsActions.getAllIdeasCommentsForProject(projectId));
      dispatch(ProjectAssigneeActions.fetchAll());
      dispatch(WorkspaceMembersActions.loadAll());
    }
  }, [projectId]);

  useEffect(() => {
    if (cftUtilization?.id && cftUtilization.publicId) {
      setActiveTab(CftTab.Model);
    }
  }, [cftUtilization?.id]);

  useEffect(() => {
    const selectedVersionId = cftUtilization?.diagramData?.versionId;

    if (!activeVersionId && selectedVersionId) {
      const lastViewedVersion = ToolVersionUtils.resolveLastViewedToolVersion(cftUtilization.diagramData);

      if (lastViewedVersion) {
        setActiveVersionId(lastViewedVersion);
      } else {
        setActiveVersionId(selectedVersionId);
      }
    }
  }, [cftUtilization, activeVersionId]);

  const updateUtilization = (data: Partial<CftUpdateUtilizationCommand>) => {
    const command: CftUpdateUtilizationCommand = {
      title: cftUtilization.title,
      subject: cftUtilization.subject,
      conclusion: cftUtilization.conclusion,
      ...data,
    };

    dispatch(CftUtilizationActions.updateUtilization(cftUtilization.id, command));
  };

  const updateVersionData = (nodes: CftNodeData[]) => {
    const command: CftUpdateVersionDataCommand = {
      versionId: activeVersionId,
      nodes,
    };

    dispatch(CftUtilizationActions.updateVersionData(utilizationId, command, projectId));
  };

  const subjectChangeHandler = (text: string) => {
    updateUtilization({ subject: text });
  };

  const conclusionChangeHandler = (text: string) => {
    updateUtilization({ conclusion: text });
  };

  const tabChangeHandler = (_event: React.SyntheticEvent, newValue: CftTab) => {
    setActiveTab(newValue);
  };

  const versionChangeHandler = (versionId: string) => {
    setActiveVersionId(versionId);
  };

  if (!cftUtilization || !activeVersionId) {
    return <LoadingOverlay loading={true} backdropStyles={{ backgroundColor: 'transparent' }} />;
  }

  return (
    <>
      <ContentContainer display={'flex'} alignItems={'stretch'}>
        <Grid container direction={'column'} wrap={'nowrap'}>
          <Grid item xs={'auto'}>
            <TitleContainer>
              <PageTitleWithDocLink
                titleComponent={<ToolTitle tool={cftUtilization} preset={'large'} editable={!isDisabled} />}
                docsUrl={'https://www.priz.guru/knowledge-base/#'}
              />

              <Box display="flex" justifyContent="flex-end">
                {!cftUtilization.publicId && (
                  <Tabs
                    orientation={'horizontal'}
                    variant={'scrollable'}
                    scrollButtons={'auto'}
                    allowScrollButtonsMobile={true}
                    value={activeTab}
                    onChange={tabChangeHandler}
                  >
                    <Tab value={CftTab.Subject} label={<Trans>Subject</Trans>} />
                    <Tab value={CftTab.Model} label={<Trans>Model</Trans>} />
                    <Tab value={CftTab.Conclusion} label={<Trans>Conclusion</Trans>} />
                  </Tabs>
                )}
              </Box>
            </TitleContainer>
          </Grid>

          <Grid item xs={true}>
            {!cftUtilization.publicId && (
              <TabPanel index={CftTab.Subject} value={activeTab} stretch>
                <ToolSubjectConclusion
                  onContentChange={subjectChangeHandler}
                  initialContent={cftUtilization.subject}
                  placeholder={'Describe the system that you are analyzing with this functional model'}
                  disabled={isDisabled}
                />
              </TabPanel>
            )}

            <TabPanel index={CftTab.Model} value={activeTab} stretch>
              <Grid container flexDirection={'column'} height={'100%'}>
                <Grid item xs={true} position={'relative'}>
                  <CftWorkspace
                    utilization={cftUtilization}
                    meetings={meetings}
                    tools={tools}
                    tasks={tasks}
                    ideas={ideas}
                    featureSet={featureSet}
                    activeVersionId={activeVersionId}
                    inView={activeTab === CftTab.Model}
                    historyKeyboardControl={activeTab === CftTab.Model}
                    onDataChange={updateVersionData}
                    disabled={isDisabled}
                  />

                  <LoadingOverlay loading={isLoading} backdropStyles={{ backgroundColor: 'rgba(255,255,255,.7)' }} />
                </Grid>

                {/* temporarily hidden */}
                {Math.random() === 9 && (
                  <Grid item maxWidth={'100%'}>
                    <CftVersionsTabs
                      cftUtilization={cftUtilization}
                      activeVersionId={activeVersionId}
                      onVersionSelect={versionChangeHandler}
                      disabled={isDisabled}
                    />
                  </Grid>
                )}
              </Grid>
            </TabPanel>

            {!cftUtilization.publicId && (
              <TabPanel index={CftTab.Conclusion} value={activeTab} stretch>
                <ToolSubjectConclusion
                  onContentChange={conclusionChangeHandler}
                  initialContent={cftUtilization.conclusion}
                  placeholder={'Describe your conclusion that resulted form this functional modeling tool'}
                  disabled={isDisabled}
                />
              </TabPanel>
            )}
          </Grid>
        </Grid>
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={projectId}
          utilization={cftUtilization}
          workingFlowProps={{ nextVariant: 'ideas' }}
          disabled={isDisabled}
        />
      </ContentFooter>
    </>
  );
};

export const CftView: React.FC<CftViewProps> = props => {
  return (
    <CftContextProvider>
      <ElementsViewerProvider>
        <CftViewComponent {...props} />
      </ElementsViewerProvider>
    </CftContextProvider>
  );
};
