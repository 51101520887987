import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { Trans } from 'react-i18next';
import { DialogsActions } from '../../dialogs/store/actions';
import { DialogType } from '../../dialogs/store/model';
import { useDispatch } from 'react-redux';

interface SignupDialogCallerButtonProps extends ButtonProps {
  buttonText?: string;
}

export const SignupDialogCallerButton: React.FC<SignupDialogCallerButtonProps> = ({ buttonText, onClick, ...rest }) => {
  const dispatch = useDispatch();

  const clickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    dispatch(DialogsActions.open(DialogType.Signup));

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Button
      variant={'pg_rounded'}
      color={'pg_orange_solid'}
      size={'pg_medium'}
      onClick={clickHandler}
      {...(rest || {})}
    >
      <Trans>{buttonText || 'Sign up'}</Trans>
    </Button>
  );
};
