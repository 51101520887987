import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PrizLoadingOverlay } from '@priz/shared/src/components/priz-loading-overlay/component';
import { AuthService } from '@priz/shared/src/services/auth';

export const RootResolver: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      navigate('/dashboard', { replace: true });
    } else {
      navigate('/login', { replace: true });
    }
  });

  return <PrizLoadingOverlay />;
};
