import React from 'react';
import {
  SfmComponentImprovementBaseProps,
  SfmComponentImprovementIteration,
} from '../sfm-component-iteration/component';
import { ComponentImprovementIterationsMap } from '../store/model';

interface SfmComponentIterationsProps extends SfmComponentImprovementBaseProps {
  improvementId: number;
  improvementIterationsMap: ComponentImprovementIterationsMap;
}

export const SfmComponentIterations: React.FC<SfmComponentIterationsProps> = ({
  improvementId,
  improvementIterationsMap,
  ...rest
}) => {
  const iterationsIds = Object.keys(improvementIterationsMap || {});

  return (
    <>
      {iterationsIds.reverse().map((iterationId, key) => (
        <SfmComponentImprovementIteration
          key={iterationId}
          improvementId={improvementId}
          iterationId={+iterationId}
          improvementIteration={improvementIterationsMap[iterationId]}
          defaultExpanded={!key}
          editable
          {...rest}
        />
      ))}
    </>
  );
};
