import * as React from "react";

export const ReactComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="172" height="40" viewBox="0 0 172 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0)">
      <path d="M0 19.8925C0.101394 19.7705 0.0796665 19.6198 0.108636 19.4835C0.550423 17.5244 2.09306 16.082 4.06299 15.7806C5.96775 15.4935 7.86526 16.4121 8.83574 18.0913C9.84244 19.8207 9.66862 21.9951 8.39395 23.5308C8.22738 23.7318 8.19841 23.8753 8.32877 24.1193C10.4653 28.0446 13.2391 31.4246 16.9255 34.0439C19.2069 35.6657 21.7272 36.8211 24.3562 37.7468C24.5663 37.8186 24.7763 37.9119 25.0805 38.0267C24.4793 38.1774 23.9579 38.2205 23.4364 38.2563C19.062 38.5577 15.2887 37.0938 11.9427 34.4099C8.88644 31.9628 6.54714 28.9273 4.71481 25.5043C4.64963 25.3751 4.61342 25.2746 4.42511 25.2531C2.06409 24.9876 0.528696 23.5667 0.0579393 21.2344C0.0506969 21.1914 0.0217272 21.1483 0 21.1053C0 20.7106 0 20.3015 0 19.8925ZM2.67969 20.5097C2.67969 21.6363 3.59948 22.5549 4.71481 22.5549C5.8229 22.5477 6.72096 21.6435 6.7282 20.524C6.73544 19.3902 5.83014 18.4644 4.70757 18.4644C3.59224 18.4573 2.67969 19.3758 2.67969 20.5097Z" fill="#08445E"/>
      <path d="M30.7729 20.4737C30.7729 25.3033 26.862 29.2215 22.0458 29.2215C17.2152 29.2215 13.3115 25.2889 13.3187 20.445C13.326 15.6226 17.2441 11.7116 22.0603 11.7188C26.8765 11.7188 30.7802 15.6441 30.7729 20.4737ZM22.1183 25.9922C25.2108 25.9922 27.7239 23.581 27.7384 20.6029C27.7456 17.6032 25.2397 15.1633 22.1328 15.1633C19.0402 15.1633 16.5271 17.5745 16.5126 20.5526C16.4982 23.5523 19.0113 25.9922 22.1183 25.9922Z" fill="#57565C"/>
      <path d="M35.7051 9.18555C36.3062 9.73811 36.7408 10.384 37.1319 11.0514C38.9135 14.0582 39.4929 17.309 39.1525 20.7464C38.8411 23.8895 37.8489 26.8318 36.4438 29.652C35.8789 30.7787 35.2778 31.8838 34.5463 32.91C34.4522 33.0463 34.4811 33.1324 34.5391 33.2616C35.85 36.2756 33.9018 39.5982 30.6137 39.9713C28.5858 40.201 26.6666 39.1389 25.7613 37.2874C24.9212 35.5723 25.2978 33.398 26.6883 32.0058C27.9123 30.7787 29.4042 30.3266 31.1207 30.6064C31.2945 30.6351 31.4031 30.6495 31.5118 30.4773C33.7642 27.0112 35.3575 23.2939 36.0021 19.1963C36.4656 16.2613 36.3714 13.3406 35.8862 10.4127C35.821 10.0108 35.7631 9.59459 35.7051 9.18555ZM30.1212 37.2803C31.2728 37.2874 32.1925 36.3904 32.1998 35.2494C32.207 34.2017 31.3162 33.3118 30.2443 33.3047C29.0638 33.2975 28.1296 34.1945 28.1368 35.3355C28.1368 36.4191 29.0131 37.2731 30.1212 37.2803Z" fill="#12739C"/>
      <path d="M5.04797 14.9984C5.11316 14.4817 5.32319 14.0224 5.53322 13.5775C7.08309 10.3052 9.55275 7.92984 12.7756 6.2865C15.8247 4.73644 19.091 3.98295 22.4877 3.68155C23.6537 3.58108 24.8197 3.52367 25.9858 3.60261C26.203 3.61696 26.2537 3.49497 26.3407 3.37297C27.5791 1.64351 29.7156 0.925895 31.7507 1.57175C33.7424 2.20325 35.1257 4.05471 35.1257 6.10709C35.1257 8.20254 33.7279 10.0325 31.6856 10.5994C29.5925 11.1806 27.3473 10.298 26.232 8.46805C26.0727 8.20254 25.9206 7.92267 25.8264 7.62844C25.7757 7.46339 25.6816 7.40598 25.515 7.40598C21.5679 7.31987 17.7294 7.87243 14.0358 9.30049C10.8709 10.5204 8.10427 12.3647 5.57667 14.575C5.39561 14.7113 5.22179 14.8549 5.04797 14.9984ZM32.3881 6.04251C32.3881 4.90867 31.461 4.01165 30.3023 4.00447C29.1942 3.9973 28.2961 4.87997 28.2816 5.97792C28.2744 7.11893 29.2231 8.05184 30.3892 8.03748C31.5045 8.02313 32.3881 7.14046 32.3881 6.04251Z" fill="#1197D1"/>
      <path d="M109.838 27.7861C108.694 27.7861 107.543 27.7861 106.398 27.7861C103.356 27.7861 100.807 25.5974 100.365 22.5977C100.119 20.9185 100.286 19.2967 101.249 17.8471C102.429 16.0674 104.168 15.2565 106.275 15.2206C108.506 15.1848 110.737 15.2135 112.974 15.2063C113.228 15.2063 113.308 15.2852 113.3 15.5292C113.286 16.0674 113.279 16.6056 113.3 17.1439C113.315 17.4381 113.235 17.5314 112.924 17.5242C110.765 17.5098 108.607 17.517 106.442 17.517C104.312 17.517 102.705 19.0384 102.61 21.1553C102.538 22.8059 103.088 24.155 104.588 25.0161C105.189 25.3606 105.848 25.4826 106.536 25.4826C108.165 25.4826 109.795 25.4754 111.425 25.4897C111.678 25.4897 111.75 25.4323 111.75 25.174C111.736 24.3487 111.736 23.5235 111.75 22.6982C111.758 22.447 111.685 22.3753 111.432 22.3825C109.78 22.3968 108.129 22.3825 106.471 22.3968C106.181 22.3968 106.058 22.3322 106.08 22.0236C106.109 21.6074 106.101 21.184 106.08 20.7678C106.065 20.4879 106.152 20.3947 106.442 20.4018C107.84 20.4162 109.237 20.409 110.642 20.409C111.678 20.409 112.721 20.4162 113.757 20.4018C114.01 20.4018 114.083 20.4736 114.083 20.7248C114.075 22.9709 114.075 25.217 114.083 27.4704C114.083 27.7144 114.017 27.7933 113.764 27.7933C112.446 27.7789 111.142 27.7861 109.838 27.7861Z" fill="#1197D1"/>
      <path d="M62.3643 21.5144C62.3643 19.5266 62.3643 17.546 62.3643 15.5582C62.3643 15.3357 62.3861 15.2065 62.6685 15.2065C65.7321 15.2209 68.8028 15.185 71.8664 15.2352C74.2346 15.2783 75.828 16.8355 76.0525 19.1606C76.1322 19.9715 76.0815 20.7609 75.828 21.5359C75.4948 22.5621 74.843 23.3443 73.8653 23.8108C73.5466 23.9615 73.6335 24.0476 73.8146 24.2342C74.9009 25.3034 75.9728 26.387 77.0519 27.4706C77.1244 27.5424 77.262 27.5998 77.2185 27.7218C77.1678 27.8438 77.023 27.7864 76.9288 27.7864C76.0742 27.7936 75.2124 27.7864 74.3578 27.7936C74.1477 27.7936 74.0029 27.729 73.858 27.5711C72.9383 26.5593 72.004 25.5546 71.0842 24.5427C70.9466 24.3849 70.8017 24.3203 70.5845 24.3275C69.2229 24.3346 67.8541 24.3275 66.4925 24.3346C66.2245 24.3346 66.1304 24.2629 66.1376 23.9902C66.1594 23.5381 66.1594 23.0788 66.1376 22.6267C66.1231 22.3397 66.2318 22.2607 66.507 22.2679C68.0858 22.2823 69.6719 22.232 71.2508 22.2823C73.3004 22.3468 73.9015 21.0551 73.7639 19.4764C73.6553 18.2205 72.8586 17.5316 71.5694 17.5316C69.3822 17.5244 67.2023 17.5388 65.0151 17.5244C64.7036 17.5244 64.6674 17.6321 64.6674 17.8976C64.6747 21.0551 64.6674 24.2126 64.6819 27.3702C64.6819 27.7146 64.595 27.8294 64.2474 27.8079C63.7694 27.7792 63.2841 27.7792 62.7989 27.8079C62.4585 27.8294 62.3499 27.729 62.3499 27.3773C62.3716 25.4182 62.3643 23.4663 62.3643 21.5144Z" fill="#57565C"/>
      <path d="M131.37 21.4925C131.37 19.5191 131.37 17.5456 131.37 15.5722C131.37 15.3641 131.37 15.2134 131.667 15.2134C134.745 15.2277 137.823 15.1918 140.894 15.2421C143.226 15.278 144.841 16.8424 145.058 19.1531C145.181 20.4089 145.029 21.6074 144.262 22.6694C143.907 23.1646 143.451 23.5665 142.893 23.8104C142.545 23.9611 142.632 24.0544 142.835 24.2554C143.907 25.3103 144.964 26.3723 146.029 27.4344C146.116 27.5205 146.297 27.5851 146.217 27.7358C146.159 27.8506 146 27.7861 145.884 27.7861C145.037 27.7932 144.196 27.786 143.349 27.7932C143.154 27.7932 143.016 27.7358 142.886 27.5923C141.973 26.5876 141.046 25.5973 140.141 24.5926C139.974 24.4061 139.808 24.3271 139.554 24.3271C138.229 24.3415 136.896 24.3271 135.571 24.3415C135.267 24.3415 135.136 24.2841 135.158 23.9468C135.187 23.5162 135.18 23.0857 135.158 22.6551C135.143 22.3609 135.23 22.2676 135.542 22.2676C137.121 22.2819 138.707 22.2317 140.286 22.2819C142.48 22.3465 142.958 20.8539 142.777 19.3612C142.632 18.1843 141.864 17.5385 140.662 17.5313C138.439 17.5169 136.223 17.5313 133.999 17.5169C133.68 17.5169 133.695 17.6676 133.695 17.8901C133.695 21.0476 133.695 24.2051 133.702 27.3555C133.702 27.6928 133.637 27.8148 133.275 27.7932C132.761 27.7573 132.239 27.7717 131.718 27.7932C131.443 27.8076 131.37 27.7071 131.37 27.4416C131.377 25.461 131.37 23.4732 131.37 21.4925Z" fill="#1197D1"/>
      <path d="M47.3581 21.4856C47.3581 19.5121 47.3581 17.5387 47.3581 15.5652C47.3581 15.35 47.3581 15.2064 47.6551 15.2136C50.6606 15.228 53.6662 15.1849 56.6646 15.2423C59.2356 15.2925 60.8869 17.0364 60.9159 19.5911C60.9231 20.4881 60.8 21.3564 60.3582 22.153C59.5398 23.6241 58.2 24.2771 56.5704 24.3274C54.8685 24.3776 53.1665 24.3345 51.4646 24.3417C51.2183 24.3417 51.1242 24.2699 51.1314 24.0188C51.1459 23.5021 51.1531 22.9926 51.1314 22.4759C51.1169 22.196 51.2038 22.1099 51.4935 22.1099C53.0362 22.1243 54.5788 22.1243 56.1287 22.1171C56.8601 22.1171 57.5554 21.9951 58.0696 21.4066C58.7432 20.6316 58.8083 19.7346 58.4535 18.816C58.1203 17.9406 57.3816 17.5602 56.4908 17.5459C54.3108 17.5172 52.1236 17.5387 49.9364 17.5315C49.6395 17.5315 49.6757 17.6894 49.6757 17.8688C49.6757 21.0263 49.6757 24.1838 49.6757 27.3342C49.6757 27.7935 49.6757 27.7935 49.2049 27.7935C48.7342 27.7935 48.2634 27.7719 47.7927 27.8006C47.4523 27.8222 47.3436 27.7289 47.3436 27.3701C47.3654 25.4038 47.3581 23.4447 47.3581 21.4856Z" fill="#57565C"/>
      <path d="M86.3657 25.4685C86.6337 25.6048 86.7641 25.5689 86.8944 25.5689C89.4655 25.5689 92.0365 25.5761 94.6076 25.5618C94.9335 25.5618 95.0566 25.6407 95.0349 25.978C95.0059 26.4875 95.0204 27.0042 95.0276 27.5209C95.0349 27.7218 94.9697 27.7864 94.7597 27.7864C91.1023 27.7792 87.4449 27.7864 83.7947 27.7792C83.295 27.7792 82.8677 27.5783 82.6576 27.0903C82.4404 26.5664 82.5925 26.1 82.9691 25.7053C83.4181 25.2388 83.8816 24.7867 84.3379 24.3275C86.554 22.11 88.7702 19.8998 90.9864 17.6823C91.0443 17.6249 91.095 17.5603 91.1675 17.4814C91.0226 17.3809 90.8778 17.4168 90.7474 17.4168C88.2126 17.4168 85.6777 17.4096 83.1429 17.424C82.7952 17.424 82.6794 17.3379 82.7011 16.9862C82.7301 16.5126 82.7228 16.0318 82.7011 15.551C82.6866 15.2783 82.788 15.2065 83.056 15.2065C85.3156 15.2137 87.568 15.2137 89.8276 15.2137C91.153 15.2137 92.4856 15.2065 93.8109 15.2209C94.6438 15.2281 95.1725 15.795 95.0783 16.5341C95.0349 16.8858 94.8393 17.1513 94.5931 17.3953C93.4054 18.5865 92.2248 19.7849 91.0226 20.9618C89.4945 22.4545 87.9518 23.9328 86.3657 25.4685Z" fill="#57565C"/>
      <path d="M129.48 19.3113C129.48 20.5169 129.516 21.7296 129.473 22.9352C129.415 24.27 129.045 25.5043 128.024 26.4587C127.242 27.1907 126.279 27.5567 125.243 27.7361C123.36 28.0662 121.477 28.0734 119.608 27.6356C117.342 27.0974 116.045 25.5617 115.864 23.251C115.741 21.6148 115.828 19.9786 115.806 18.3425C115.792 17.4096 115.813 16.4767 115.799 15.5438C115.799 15.2926 115.871 15.2065 116.125 15.2137C116.704 15.228 117.284 15.2352 117.863 15.2137C118.145 15.1993 118.196 15.307 118.196 15.5653C118.189 17.7181 118.189 19.871 118.189 22.0239C118.189 22.4688 118.225 22.9065 118.305 23.3443C118.508 24.4494 119.181 25.1311 120.253 25.4182C121.347 25.7196 122.469 25.7483 123.592 25.6693C123.881 25.6478 124.171 25.6191 124.454 25.5761C126.3 25.3177 127.184 24.3776 127.213 22.5405C127.249 20.237 127.235 17.9263 127.227 15.6227C127.227 15.3141 127.293 15.2065 127.626 15.2208C128.133 15.2495 128.64 15.2495 129.147 15.2208C129.444 15.2065 129.509 15.3213 129.501 15.5868C129.487 16.8283 129.494 18.0698 129.494 19.3184C129.487 19.3113 129.48 19.3113 129.48 19.3113Z" fill="#1197D1"/>
      <path d="M160.398 19.2609C160.398 20.4449 160.412 21.629 160.39 22.8131C160.383 23.5092 160.246 24.1909 159.999 24.8439C159.492 26.2146 158.493 27.0829 157.11 27.4848C154.799 28.1593 152.46 28.1737 150.15 27.5207C148.093 26.9394 146.97 25.4754 146.775 23.3369C146.652 21.9735 146.731 20.61 146.717 19.2537C146.702 18.0337 146.724 16.8138 146.709 15.5939C146.702 15.2925 146.804 15.2063 147.101 15.2135C147.644 15.235 148.187 15.235 148.73 15.2135C149.027 15.1992 149.114 15.2925 149.114 15.5939C149.099 17.7969 149.107 19.9928 149.114 22.1959C149.114 22.5404 149.143 22.892 149.201 23.2293C149.396 24.4923 150.179 25.2243 151.403 25.4826C152.8 25.7768 154.205 25.7912 155.61 25.5329C157.363 25.2027 158.131 24.234 158.138 22.404C158.145 20.1579 158.152 17.9118 158.131 15.6584C158.131 15.2781 158.239 15.192 158.594 15.2135C159.036 15.2422 159.485 15.2207 159.934 15.2207C160.412 15.2207 160.412 15.2207 160.412 15.6728C160.412 16.8712 160.412 18.0625 160.412 19.2609C160.405 19.2609 160.405 19.2609 160.398 19.2609Z" fill="#1197D1"/>
      <path d="M93.3981 6.55887C94.5135 6.5804 95.8099 7.11862 96.8166 8.27398C97.4901 9.04901 97.6712 9.96756 97.4032 10.922C97.2076 11.6253 96.7224 12.1778 96.0996 12.5869C95.1436 13.2112 94.1224 13.5269 92.9419 13.4695C91.7541 13.4121 90.6967 13.1251 89.7552 12.4146C89.1179 11.9267 88.5674 11.3167 88.4371 10.5488C88.2632 9.53699 88.4733 8.56103 89.3134 7.82188C90.3635 6.88898 91.5948 6.47994 93.3981 6.55887Z" fill="#1197D1"/>
      <path d="M105.688 2.18164C107.079 2.30364 108.296 2.75573 109.194 3.83216C110.374 5.25304 110.215 6.78156 108.853 7.97281C107.695 8.99182 106.275 9.32193 104.769 9.02771C103.791 8.83395 102.9 8.40338 102.212 7.63553C100.93 6.20029 101.01 4.60001 102.437 3.32983C103.356 2.51174 104.486 2.20317 105.688 2.18164Z" fill="#1197D1"/>
      <path d="M118.022 6.17877C116.675 6.02807 115.444 5.59033 114.611 4.41343C114.3 3.96851 114.097 3.48053 114.133 2.91361C114.177 2.2247 114.503 1.66496 115.002 1.24874C116.27 0.193844 117.711 -0.13626 119.326 0.337367C120.347 0.638767 121.202 1.1411 121.745 2.074C122.411 3.22219 122.129 4.1838 121.238 5.03776C120.369 5.87737 119.246 6.14289 118.022 6.17877Z" fill="#1197D1"/>
      <path d="M130.783 0.00730633C131.819 0.0503634 132.717 0.430701 133.398 1.19855C134.426 2.34674 134.354 3.58104 133.21 4.59288C131.703 5.92765 129.263 5.77695 127.937 4.2556C126.945 3.11459 127.032 1.94487 128.154 0.911504C128.886 0.236944 129.784 -0.0357507 130.783 0.00730633Z" fill="#1197D1"/>
      <path d="M80.753 21.5069C80.753 23.466 80.7457 25.425 80.7602 27.3913C80.7602 27.6927 80.7095 27.8147 80.3691 27.7932C79.8549 27.7645 79.3334 27.7645 78.812 27.7932C78.515 27.8075 78.4209 27.7214 78.4281 27.42C78.4426 25.913 78.4354 24.406 78.4354 22.899C78.4354 20.4735 78.4426 18.048 78.4281 15.6152C78.4281 15.3138 78.4861 15.1919 78.8192 15.2062C79.3479 15.2349 79.8839 15.2277 80.4126 15.2062C80.6878 15.199 80.7602 15.278 80.7602 15.5507C80.753 17.5385 80.753 19.5263 80.753 21.5069Z" fill="#57565C"/>
      <path d="M141.48 6.68079C140.394 6.55162 139.438 6.23587 138.823 5.27426C138.265 4.39159 138.395 3.67397 139.076 2.97071C140.488 1.49959 143.153 1.84405 144.131 3.49457C144.638 4.35571 144.457 5.22403 143.581 5.956C142.965 6.46551 142.226 6.60903 141.48 6.68079Z" fill="#1197D1"/>
      <path d="M149.845 9.96777C149.259 9.97494 148.752 9.78836 148.288 9.49414C147.716 9.13533 147.311 8.61865 147.318 7.92256C147.332 7.17624 147.81 6.68826 148.44 6.37968C149.556 5.84147 150.642 5.97064 151.62 6.73131C152.177 7.16906 152.467 8.00867 152.25 8.511C151.946 9.21427 151.41 9.66637 150.664 9.8386C150.396 9.90318 150.121 10.0395 149.845 9.96777Z" fill="#1197D1"/>
      <path d="M157.718 12.1277C157.805 12.7808 157.081 13.4625 156.443 13.7065C155.277 14.1442 153.981 13.3979 153.706 12.4578C153.51 11.812 153.851 10.958 154.546 10.592C155.806 9.91746 157.16 10.4485 157.674 11.6469C157.74 11.7976 157.711 11.9483 157.718 12.1277Z" fill="#1197D1"/>
      <path d="M143.139 34.2591C142.581 34.2591 142.031 34.2734 141.473 34.2519C141.227 34.2447 141.169 34.3452 141.169 34.5676C141.184 35.113 141.401 35.3355 141.966 35.357C142.603 35.3857 143.24 35.3857 143.885 35.357C144.225 35.3427 144.479 35.2135 144.551 34.8762C144.609 34.625 144.776 34.6537 144.935 34.6825C145.138 34.7112 145.051 34.8906 145.044 34.9982C144.993 35.4073 144.588 35.7876 144.16 35.8235C143.32 35.8881 142.473 35.8881 141.633 35.8235C141.133 35.7876 140.735 35.3714 140.72 34.869C140.698 34.3452 140.698 33.8141 140.72 33.2903C140.749 32.6444 141.155 32.2497 141.799 32.2354C142.538 32.221 143.269 32.221 144.008 32.2354C144.653 32.2497 145.029 32.6372 145.066 33.2687C145.116 34.2519 145.116 34.2519 144.131 34.2519C143.791 34.2591 143.465 34.2591 143.139 34.2591ZM142.864 33.7783C143.32 33.7783 143.784 33.7783 144.24 33.7783C144.254 33.7783 144.262 33.7783 144.276 33.7783C144.378 33.7639 144.493 33.8141 144.559 33.685C144.747 33.2903 144.443 32.7449 143.986 32.709C143.291 32.6588 142.589 32.6516 141.886 32.7018C141.372 32.7377 141.169 32.9745 141.162 33.4912C141.162 33.6993 141.22 33.7926 141.444 33.7854C141.922 33.7711 142.393 33.7783 142.864 33.7783Z" fill="#57565C"/>
      <path d="M161.397 34.2591C160.84 34.2591 160.289 34.2662 159.731 34.2519C159.507 34.2447 159.442 34.3165 159.442 34.5389C159.442 35.1202 159.644 35.3355 160.238 35.3642C160.876 35.3929 161.513 35.3929 162.158 35.3642C162.505 35.3499 162.752 35.2135 162.824 34.8762C162.875 34.6466 163.012 34.6753 163.164 34.6753C163.396 34.6825 163.309 34.8619 163.302 34.9695C163.273 35.3929 162.867 35.7876 162.411 35.8235C161.571 35.8809 160.724 35.8881 159.883 35.8235C159.391 35.7876 159 35.3786 158.971 34.8619C158.949 34.3524 158.942 33.8357 158.964 33.319C159 32.6301 159.398 32.2497 160.101 32.2354C160.811 32.221 161.528 32.221 162.237 32.2354C162.896 32.2497 163.259 32.6014 163.316 33.2472C163.403 34.2591 163.403 34.2591 162.411 34.2591C162.071 34.2591 161.73 34.2591 161.397 34.2591ZM161.136 33.7783C161.614 33.7783 162.1 33.7783 162.578 33.7783C162.679 33.7783 162.817 33.8141 162.838 33.6563C162.896 33.2329 162.802 32.7521 162.23 32.709C161.549 32.6588 160.861 32.6588 160.173 32.7018C159.623 32.7377 159.42 32.9889 159.434 33.5414C159.442 33.728 159.507 33.7926 159.688 33.7854C160.173 33.7711 160.658 33.7783 161.136 33.7783Z" fill="#57565C"/>
      <path d="M85.388 34.2591C84.8448 34.2591 84.3016 34.2734 83.7584 34.2519C83.5122 34.2447 83.4543 34.338 83.4543 34.5605C83.4543 35.1202 83.657 35.3283 84.2364 35.357C84.8882 35.3857 85.54 35.3857 86.1846 35.357C86.5178 35.3427 86.7568 35.1991 86.8219 34.8834C86.8726 34.6322 87.0392 34.6609 87.1985 34.6824C87.4158 34.7112 87.3217 34.8834 87.3072 34.991C87.2492 35.4216 86.8437 35.7948 86.3946 35.8235C85.5618 35.8809 84.7289 35.8809 83.9033 35.8235C83.418 35.7876 83.0125 35.3929 82.9907 34.9121C82.9618 34.3523 82.9618 33.7854 82.9835 33.2257C83.0052 32.6516 83.4325 32.2497 84.0119 32.2354C84.7724 32.221 85.5328 32.221 86.2932 32.2354C86.9016 32.2497 87.2999 32.6588 87.3289 33.2616C87.3724 34.2519 87.3724 34.2519 86.3657 34.2519C86.0398 34.2591 85.7139 34.2591 85.388 34.2591ZM85.1272 33.7783C85.6125 33.7783 86.0905 33.7783 86.5757 33.7783C86.6771 33.7783 86.793 33.8213 86.8509 33.6778C87.0247 33.2759 86.7133 32.7449 86.257 32.709C85.5618 32.6588 84.8593 32.6516 84.1568 32.7018C83.6353 32.7377 83.4253 33.0032 83.447 33.5343C83.4542 33.7208 83.5194 33.7854 83.7005 33.7854C84.1857 33.7711 84.6565 33.7783 85.1272 33.7783Z" fill="#57565C"/>
      <path d="M92.1597 34.2591C91.602 34.2591 91.0516 34.2662 90.4939 34.2519C90.2694 34.2447 90.2042 34.3236 90.2042 34.5389C90.2042 35.1202 90.3998 35.3355 90.9937 35.357C91.6165 35.3857 92.2466 35.3786 92.8694 35.357C93.2388 35.3427 93.5068 35.2135 93.5864 34.8403C93.6371 34.5963 93.8109 34.6681 93.9486 34.6753C94.1441 34.6896 94.0644 34.8547 94.0572 34.9552C94.021 35.3857 93.6299 35.7804 93.1736 35.8163C92.3335 35.8809 91.4861 35.8809 90.646 35.8163C90.1463 35.7804 89.748 35.3785 89.7262 34.869C89.6973 34.3308 89.69 33.7926 89.719 33.2616C89.748 32.6301 90.168 32.2425 90.7909 32.2354C91.5368 32.221 92.2828 32.221 93.0288 32.2354C93.6444 32.2497 94.0282 32.6372 94.0644 33.2544C94.1224 34.2591 94.1224 34.2591 93.1229 34.2591C92.8115 34.2591 92.4856 34.2591 92.1597 34.2591ZM91.8917 33.7783C92.3625 33.7783 92.8332 33.7783 93.2967 33.7783C93.3112 33.7783 93.3185 33.7783 93.3329 33.7783C93.4343 33.7639 93.5502 33.807 93.6082 33.6706C93.7747 33.2759 93.4561 32.7377 93.0143 32.709C92.319 32.6588 91.6165 32.6588 90.9212 32.7018C90.3925 32.7377 90.2115 32.9602 90.2042 33.484C90.197 33.7208 90.2839 33.7926 90.5157 33.7854C90.9792 33.7711 91.4354 33.7783 91.8917 33.7783Z" fill="#57565C"/>
      <path d="M76.5813 32.3863C76.5813 31.9629 76.5885 31.5826 76.5813 31.2022C76.574 31.0085 76.6247 30.9224 76.842 30.9224C77.052 30.9224 77.0665 31.03 77.0665 31.1879C77.0665 32.659 77.0593 34.1301 77.0665 35.6012C77.0665 35.8596 76.8999 35.9241 76.7551 35.838C76.5161 35.6945 76.3061 35.8093 76.0815 35.8237C75.2704 35.8667 74.4665 35.8739 73.6553 35.8237C73.1122 35.7878 72.7138 35.3859 72.6993 34.8405C72.6776 34.3239 72.6776 33.8144 72.6921 33.2977C72.7066 32.659 73.1194 32.2571 73.764 32.2284C74.4737 32.1925 75.1835 32.1925 75.9005 32.2212C76.125 32.2428 76.3278 32.2787 76.5813 32.3863ZM74.8576 35.3572C75.1907 35.3572 75.5311 35.3644 75.8643 35.3572C76.335 35.3429 76.5451 35.1276 76.5523 34.6683C76.5595 34.2377 76.5595 33.8072 76.5523 33.3838C76.5451 32.9676 76.3133 32.7021 75.8932 32.6877C75.2197 32.6662 74.5462 32.6662 73.8726 32.6877C73.4236 32.7021 73.1918 32.9532 73.1773 33.391C73.1701 33.8215 73.1629 34.2521 73.1773 34.6755C73.1918 35.1132 73.4091 35.3357 73.8436 35.3501C74.184 35.3644 74.5172 35.3572 74.8576 35.3572Z" fill="#57565C"/>
      <path d="M53.0434 32.3286C54.0574 32.1492 55.0423 32.2209 56.0273 32.2568C56.5125 32.2711 56.8892 32.7017 56.9036 33.1969C56.9254 34.0078 56.9109 34.8187 56.9181 35.6368C56.9181 35.8305 56.824 35.8377 56.6719 35.8449C56.4981 35.8521 56.4401 35.7803 56.4401 35.6153C56.4474 34.9192 56.4401 34.2303 56.4401 33.5342C56.4401 32.9242 56.2735 32.7448 55.6652 32.7017C55.0134 32.6658 54.3615 32.6587 53.717 32.7017C53.2824 32.7304 53.0869 32.917 53.0724 33.3619C53.0434 34.0796 53.0507 34.7972 53.0434 35.5148C53.0434 35.6798 53.0796 35.8449 52.8044 35.8449C52.5364 35.8449 52.5582 35.6942 52.5582 35.522C52.5582 34.1011 52.5582 32.6802 52.5582 31.2521C52.5582 31.0799 52.5364 30.9292 52.8044 30.9292C53.0869 30.9292 53.0362 31.1014 53.0362 31.2593C53.0434 31.6253 53.0434 31.9841 53.0434 32.3286Z" fill="#57565C"/>
      <path d="M64.2691 34.0725C64.9427 33.5128 65.551 33.0391 66.1232 32.5153C66.4201 32.2426 66.7243 32.2139 67.1661 32.2856C66.7967 32.6014 66.4998 32.9028 66.1521 33.1396C65.8407 33.3549 65.8987 33.4984 66.1232 33.7352C66.7026 34.3452 67.2457 34.9839 67.8034 35.6082C67.8541 35.6656 67.9338 35.7159 67.9265 35.8307C67.6079 35.8881 67.3471 35.8809 67.1081 35.5867C66.6374 35.0126 66.1159 34.4744 65.6307 33.9146C65.5003 33.7568 65.4062 33.7281 65.2613 33.8788C65.1599 33.9792 65.0513 34.0797 64.9282 34.1515C64.3778 34.4672 64.1532 34.9265 64.2619 35.5508C64.2908 35.723 64.2546 35.8379 64.0229 35.845C63.7839 35.845 63.7839 35.7302 63.7839 35.558C63.7839 34.1228 63.7839 32.6875 63.7839 31.2523C63.7839 31.1088 63.7332 30.9437 63.9939 30.9365C64.2401 30.9365 64.2691 31.0442 64.2691 31.2379C64.2619 32.0273 64.2691 32.8167 64.2691 33.6061C64.2691 33.7352 64.2691 33.8572 64.2691 34.0725Z" fill="#57565C"/>
      <path d="M121.195 34.015C121.796 33.5126 122.397 33.0462 122.954 32.5367C123.251 32.264 123.563 32.1994 123.99 32.2783C123.744 32.5725 123.447 32.7735 123.186 32.9959C122.708 33.405 122.708 33.3978 123.114 33.8571C123.686 34.4958 124.258 35.1416 124.852 35.809C124.49 35.8879 124.222 35.8879 123.983 35.5865C123.512 35.0124 122.991 34.4742 122.513 33.9073C122.368 33.7423 122.266 33.7064 122.114 33.8786C122.042 33.9575 121.955 34.0293 121.868 34.0867C121.318 34.4312 120.999 34.8833 121.144 35.565C121.187 35.7659 121.086 35.8305 120.883 35.8377C120.68 35.8377 120.651 35.7516 120.651 35.5794C120.659 34.1226 120.659 32.6658 120.651 31.2019C120.651 31.0081 120.695 30.9292 120.912 30.9292C121.151 30.9292 121.158 31.0512 121.151 31.2234C121.151 32.0272 121.151 32.8237 121.151 33.6274C121.144 33.7494 121.108 33.8714 121.195 34.015Z" fill="#57565C"/>
      <path d="M109.911 32.3934C110.2 32.2356 110.454 32.2284 110.7 32.2284C111.388 32.2284 112.076 32.2212 112.764 32.2427C113.336 32.2571 113.742 32.659 113.757 33.2187C113.778 34.0153 113.764 34.819 113.771 35.6155C113.771 35.7878 113.706 35.838 113.539 35.838C113.365 35.838 113.307 35.7806 113.307 35.6084C113.315 34.9266 113.307 34.2449 113.307 33.5632C113.307 32.9173 113.134 32.7307 112.496 32.6948C111.845 32.659 111.193 32.6518 110.548 32.702C110.143 32.7307 109.933 32.946 109.925 33.3479C109.911 34.0727 109.911 34.8046 109.911 35.5366C109.911 35.7304 109.896 35.8452 109.643 35.8452C109.397 35.8452 109.411 35.716 109.411 35.551C109.411 34.1085 109.418 32.659 109.411 31.2165C109.411 31.03 109.447 30.9438 109.665 30.9438C109.896 30.9438 109.918 31.0515 109.911 31.2309C109.911 31.5969 109.911 31.9629 109.911 32.3934Z" fill="#57565C"/>
      <path d="M49.328 33.6491C49.328 34.2806 49.328 34.9121 49.328 35.5508C49.328 35.723 49.328 35.845 49.089 35.845C48.8573 35.845 48.8428 35.7374 48.8428 35.558C48.85 34.3165 48.8355 33.075 48.85 31.8335C48.8573 31.5178 48.7848 31.3886 48.4372 31.4102C47.8361 31.4389 47.235 31.4173 46.6266 31.4173C46.4528 31.4173 46.3369 31.4102 46.3369 31.1733C46.3369 30.9365 46.4528 30.9365 46.6266 30.9365C48.2417 30.9365 49.8567 30.9365 51.4718 30.9365C51.6456 30.9365 51.7687 30.9437 51.7687 31.1805C51.7687 31.4173 51.6384 31.4245 51.4718 31.4173C50.8562 31.4173 50.2406 31.4245 49.625 31.4102C49.386 31.403 49.3135 31.4891 49.3208 31.7187C49.3353 32.3646 49.328 33.0104 49.328 33.6491Z" fill="#57565C"/>
      <path d="M96.8527 26.6736C96.86 25.9345 97.3597 25.468 98.1202 25.4752C98.8154 25.4895 99.3514 26.0349 99.3369 26.7167C99.3224 27.4056 98.7503 27.9438 98.0405 27.9294C97.338 27.9151 96.8455 27.3912 96.8527 26.6736Z" fill="#57565C"/>
      <path d="M59.1271 34.0295C59.1271 33.5416 59.1271 33.0536 59.1271 32.5584C59.1271 32.257 59.2792 32.1566 59.5616 32.2642C59.6196 32.2857 59.634 32.3718 59.7354 32.3431C60.4742 32.1135 61.2419 32.2355 61.9951 32.2355C62.6179 32.2427 63.009 32.6661 63.0452 33.2832C63.0887 34.0367 63.0597 34.783 63.0452 35.5365C63.0452 35.7016 63.0452 35.8308 62.8062 35.8379C62.5382 35.8451 62.56 35.6944 62.56 35.5222C62.56 34.8405 62.56 34.1587 62.56 33.4842C62.56 32.9388 62.3572 32.7235 61.8068 32.702C61.336 32.6804 60.8652 32.6661 60.4017 32.6948C59.8006 32.7307 59.6196 32.9388 59.6196 33.5272C59.6196 34.1946 59.6196 34.862 59.6196 35.5365C59.6196 35.6872 59.6558 35.8379 59.4023 35.8451C59.156 35.8451 59.1343 35.7303 59.1343 35.5365C59.1343 35.0342 59.1271 34.5319 59.1271 34.0295Z" fill="#57565C"/>
      <path d="M145.522 34.0438C145.522 33.5271 145.529 33.0176 145.522 32.5009C145.522 32.3287 145.58 32.1852 145.761 32.257C146.101 32.3933 146.42 32.2211 146.753 32.2282C147.296 32.2426 147.839 32.2211 148.383 32.2426C148.984 32.2641 149.411 32.666 149.425 33.2616C149.447 34.0366 149.425 34.8189 149.433 35.5939C149.433 35.7733 149.382 35.8451 149.186 35.8451C148.991 35.8451 148.94 35.7733 148.947 35.5939C148.955 34.8978 148.955 34.2089 148.947 33.5128C148.947 32.9459 148.745 32.7306 148.18 32.7091C147.709 32.6875 147.238 32.6732 146.767 32.7019C146.181 32.7306 146.007 32.9387 146 33.5128C146 34.1945 146 34.8763 146 35.558C146 35.7302 145.993 35.8522 145.761 35.8451C145.529 35.8451 145.515 35.7302 145.515 35.5508C145.522 35.0485 145.522 34.5462 145.522 34.0438Z" fill="#57565C"/>
      <path d="M131.29 34.051C131.29 33.5343 131.298 33.0248 131.283 32.5081C131.276 32.3 131.37 32.178 131.544 32.2569C131.812 32.3789 132.036 32.2426 132.283 32.2426C132.92 32.2282 133.565 32.2211 134.202 32.2426C134.774 32.2641 135.187 32.709 135.194 33.276C135.201 34.0295 135.194 34.783 135.201 35.5365C135.201 35.7159 135.209 35.8522 134.948 35.845C134.68 35.8379 134.709 35.68 134.709 35.5149C134.709 34.8188 134.709 34.1299 134.709 33.4338C134.709 32.9459 134.499 32.7377 134.021 32.7019C133.492 32.666 132.956 32.6516 132.428 32.7019C131.971 32.7521 131.79 32.953 131.79 33.4051C131.783 34.1227 131.783 34.8404 131.79 35.558C131.79 35.7517 131.747 35.8307 131.529 35.8379C131.298 35.845 131.283 35.723 131.29 35.5508C131.29 35.0556 131.29 34.5533 131.29 34.051Z" fill="#57565C"/>
      <path d="M115.994 34.0655C115.994 33.5488 116.002 33.0393 115.987 32.5226C115.98 32.3073 116.06 32.171 116.27 32.2571C116.509 32.3504 116.719 32.2571 116.943 32.2499C117.602 32.2284 118.269 32.2212 118.928 32.2499C119.449 32.2715 119.876 32.6877 119.891 33.1972C119.92 34.0081 119.898 34.819 119.905 35.6299C119.905 35.8524 119.768 35.8309 119.623 35.8381C119.435 35.8524 119.406 35.7591 119.406 35.6012C119.413 34.898 119.413 34.1947 119.406 33.4842C119.406 32.9317 119.21 32.7379 118.66 32.702C118.153 32.6662 117.646 32.659 117.146 32.702C116.675 32.7451 116.48 32.9532 116.48 33.4197C116.472 34.1229 116.472 34.8262 116.48 35.5367C116.48 35.7304 116.465 35.8452 116.212 35.8452C115.958 35.8452 115.994 35.6874 115.994 35.5367C115.994 35.0487 115.994 34.5535 115.994 34.0655Z" fill="#57565C"/>
      <path d="M94.5642 34.0653C94.5642 33.5487 94.5714 33.0391 94.5569 32.5225C94.5497 32.3 94.6511 32.1708 94.8394 32.2569C95.0856 32.3718 95.2956 32.2426 95.5129 32.2426C96.1792 32.2282 96.8383 32.2211 97.5046 32.2426C98.0115 32.2641 98.4171 32.6588 98.4388 33.1683C98.475 33.9792 98.4606 34.7901 98.475 35.6082C98.4823 35.8235 98.3664 35.8307 98.1998 35.8307C98.026 35.8379 97.9681 35.7804 97.9753 35.6082C97.9826 34.9121 97.9826 34.2232 97.9753 33.5271C97.9753 32.9315 97.787 32.7377 97.1931 32.6947C96.7224 32.6588 96.2516 32.6588 95.7809 32.6947C95.2087 32.7377 95.0422 32.9243 95.0422 33.4841C95.0422 34.1658 95.0349 34.8475 95.0422 35.5293C95.0422 35.7159 95.0349 35.845 94.7814 35.8379C94.5279 35.8379 94.5569 35.6872 94.5569 35.5293C94.5642 35.0485 94.5642 34.5533 94.5642 34.0653Z" fill="#57565C"/>
      <path d="M156.146 35.2781C156.639 34.3093 157.102 33.4051 157.566 32.5081C157.624 32.4005 157.646 32.2426 157.827 32.2569C157.942 32.2641 158.095 32.2067 158.167 32.3C158.239 32.3933 158.124 32.4938 158.08 32.5871C157.566 33.5989 157.044 34.6036 156.53 35.6226C156.443 35.802 156.327 35.8522 156.139 35.8522C155.951 35.8522 155.842 35.8092 155.748 35.6226C155.248 34.6179 154.727 33.6204 154.227 32.6158C154.176 32.5225 153.959 32.3646 154.184 32.2785C154.329 32.2211 154.56 32.178 154.676 32.4292C154.937 32.9746 155.227 33.52 155.502 34.0582C155.712 34.4385 155.915 34.826 156.146 35.2781Z" fill="#57565C"/>
      <path d="M137.874 35.2781C138.373 34.3021 138.83 33.4123 139.293 32.5224C139.344 32.4291 139.358 32.2928 139.474 32.2713C139.612 32.2426 139.786 32.1995 139.887 32.2928C139.996 32.3933 139.858 32.5153 139.807 32.6086C139.3 33.6132 138.772 34.6035 138.272 35.6082C138.185 35.7876 138.077 35.845 137.881 35.8378C137.693 35.8378 137.577 35.7948 137.483 35.6154C136.983 34.6107 136.462 33.6132 135.955 32.6086C135.904 32.5081 135.737 32.3574 135.897 32.2784C136.063 32.2067 136.288 32.1708 136.418 32.4291C136.643 32.9028 136.896 33.3692 137.135 33.8357C137.374 34.2878 137.606 34.7471 137.874 35.2781Z" fill="#57565C"/>
      <path d="M107.608 34.2591C107.608 33.8429 107.593 33.4195 107.615 33.0033C107.622 32.7808 107.557 32.6947 107.333 32.7234C107.144 32.7521 107.021 32.7162 107.021 32.465C107.021 32.2282 107.151 32.2139 107.318 32.2354C107.572 32.2713 107.622 32.1565 107.608 31.934C107.593 31.6757 107.622 31.4102 107.615 31.1446C107.608 30.9509 107.709 30.9365 107.861 30.9365C108.021 30.9365 108.108 30.9724 108.1 31.1518C108.093 31.4173 108.115 31.6757 108.093 31.9412C108.071 32.1852 108.173 32.2569 108.404 32.2354C108.549 32.2211 108.694 32.2354 108.839 32.2282C109.006 32.2211 109.136 32.2139 109.136 32.4579C109.136 32.7019 109.02 32.7162 108.825 32.7234C108.1 32.7306 108.1 32.7377 108.1 33.4554C108.1 34.1586 108.093 34.8619 108.108 35.5652C108.108 35.7661 108.042 35.8307 107.84 35.8307C107.637 35.8307 107.601 35.7446 107.608 35.5723C107.615 35.1418 107.608 34.6968 107.608 34.2591Z" fill="#57565C"/>
      <path d="M99.6338 34.2805C99.6338 33.8715 99.6266 33.4696 99.6411 33.0606C99.6483 32.831 99.6121 32.6874 99.3369 32.7233C99.1486 32.752 99.0544 32.6946 99.0544 32.4722C99.0544 32.2425 99.1558 32.2066 99.3369 32.2353C99.5759 32.2712 99.6628 32.1779 99.6411 31.9411C99.6266 31.6899 99.6411 31.4388 99.6411 31.1876C99.6411 31.0154 99.6845 30.9293 99.8873 30.9364C100.09 30.9364 100.134 31.0154 100.126 31.1948C100.119 31.446 100.141 31.6971 100.119 31.9483C100.097 32.1923 100.206 32.264 100.431 32.2425C100.583 32.2282 100.742 32.2497 100.901 32.2353C101.075 32.2282 101.169 32.264 101.169 32.465C101.169 32.6731 101.09 32.7233 100.894 32.7305C100.126 32.752 100.126 32.7592 100.126 33.5342C100.126 34.2016 100.119 34.869 100.126 35.5436C100.126 35.7373 100.105 35.8521 99.8584 35.8521C99.5976 35.8521 99.6411 35.6871 99.6411 35.5436C99.6266 35.113 99.6338 34.6968 99.6338 34.2805Z" fill="#57565C"/>
      <path d="M150.584 34.2663C150.584 33.8501 150.577 33.4338 150.584 33.0104C150.591 32.8023 150.541 32.6875 150.302 32.7234C150.106 32.7521 149.99 32.7019 149.99 32.465C149.99 32.2282 150.128 32.2139 150.295 32.2426C150.519 32.2785 150.591 32.1708 150.591 31.9627C150.584 31.6972 150.606 31.4389 150.599 31.1733C150.599 31.0155 150.635 30.9365 150.816 30.9365C151.004 30.9365 151.084 31.0011 151.062 31.1805C151.055 31.2523 151.062 31.324 151.062 31.3958C151.069 31.6541 150.983 31.9842 151.113 32.1565C151.258 32.3502 151.605 32.2211 151.866 32.2211C152.069 32.2211 152.105 32.3072 152.105 32.4794C152.105 32.666 152.033 32.7162 151.852 32.7234C151.069 32.7521 151.069 32.7593 151.069 33.5415C151.069 34.2089 151.069 34.8762 151.069 35.5436C151.069 35.7159 151.069 35.8379 150.83 35.8379C150.599 35.8379 150.577 35.7302 150.584 35.5508C150.591 35.1274 150.584 34.6968 150.584 34.2663Z" fill="#57565C"/>
      <path d="M168.118 35.9672C167.546 35.9672 167.119 35.5438 167.126 34.9697C167.133 34.4386 167.546 34.0224 168.089 34.0152C168.668 34.0081 169.118 34.4243 169.132 34.9768C169.132 35.5366 168.69 35.9672 168.118 35.9672Z" fill="#57565C"/>
      <path d="M170.993 35.9668C170.436 35.9668 170.008 35.5506 170.001 35.0052C169.994 34.4598 170.421 34.0292 170.972 34.0221C171.551 34.0149 172 34.4311 172 34.9837C172 35.5506 171.573 35.9668 170.993 35.9668Z" fill="#57565C"/>
      <path d="M165.243 35.9669C164.671 35.9741 164.236 35.5435 164.236 34.9766C164.243 34.4456 164.663 34.0293 165.199 34.015C165.786 34.0006 166.264 34.4527 166.25 35.0053C166.242 35.5435 165.8 35.9597 165.243 35.9669Z" fill="#57565C"/>
      <path d="M79.6666 34.2876C79.6666 33.9288 79.6738 33.57 79.6593 33.2112C79.6521 33.0174 79.7535 32.7949 79.4783 32.6514C79.3914 32.6084 79.3914 32.3285 79.4783 32.2926C79.7535 32.1706 79.6593 31.9338 79.6956 31.7472C79.7752 31.3382 79.9997 31.0511 80.3836 30.8932C80.485 30.8502 80.6516 30.7856 80.695 30.9291C80.7385 31.0655 80.8037 31.2664 80.5719 31.3597C80.3039 31.4673 80.1518 31.6611 80.1301 31.9625C80.1156 32.1563 80.1591 32.2495 80.3836 32.2352C80.5574 32.2208 80.7457 32.1706 80.7312 32.4792C80.724 32.7447 80.5792 32.7375 80.4053 32.716C80.1736 32.6873 80.1229 32.7878 80.1229 33.003C80.1301 33.8427 80.1229 34.6751 80.1301 35.5147C80.1301 35.6869 80.1518 35.8376 79.8839 35.8376C79.6086 35.8376 79.6448 35.6726 79.6448 35.5075C79.6666 35.1057 79.6666 34.6966 79.6666 34.2876Z" fill="#57565C"/>
      <path d="M81.5786 34.2662C81.5786 33.9074 81.5858 33.5486 81.5786 33.1898C81.5713 33.0032 81.651 32.8023 81.412 32.6588C81.3106 32.6014 81.3178 32.3143 81.412 32.2713C81.6582 32.1708 81.5858 31.9842 81.6075 31.8263C81.6582 31.3958 81.8755 31.0872 82.2666 30.915C82.3825 30.8647 82.5635 30.7571 82.6287 30.958C82.6722 31.0944 82.7229 31.2881 82.4839 31.3742C82.2304 31.4675 82.0928 31.6756 82.0566 31.9483C82.0276 32.1564 82.0783 32.2641 82.3245 32.2354C82.4984 32.2138 82.6867 32.1851 82.6794 32.4794C82.6722 32.7449 82.5201 32.7377 82.3463 32.7162C82.0928 32.6803 82.0493 32.7951 82.0493 33.0176C82.0638 33.8572 82.0566 34.6896 82.0566 35.5292C82.0566 35.6799 82.1 35.8306 81.8393 35.8378C81.5786 35.8378 81.5858 35.6943 81.5858 35.5221C81.5786 35.1058 81.5786 34.6896 81.5786 34.2662Z" fill="#57565C"/>
      <path d="M87.7781 34.0295C87.7781 33.5128 87.7781 33.0033 87.7781 32.4867C87.7781 32.3862 87.7563 32.2211 87.9157 32.257C88.2778 32.3288 88.6181 32.1709 88.973 32.1709C89.1541 32.1709 89.2337 32.2211 89.2337 32.4149C89.2337 32.5943 89.183 32.6661 88.9875 32.6732C88.4371 32.7019 88.2633 32.8957 88.2633 33.4267C88.2633 34.13 88.256 34.8404 88.2633 35.5437C88.2633 35.7518 88.2198 35.8451 87.9881 35.8451C87.7346 35.8451 87.7636 35.6944 87.7636 35.5437C87.7636 35.0414 87.7636 34.539 87.7636 34.0367C87.7708 34.0295 87.7781 34.0295 87.7781 34.0295Z" fill="#57565C"/>
      <path d="M130.494 34.0367C130.494 34.5534 130.487 35.0629 130.494 35.5796C130.494 35.7447 130.472 35.8451 130.262 35.8451C130.059 35.8451 129.987 35.7877 129.987 35.5796C129.994 34.5534 129.994 33.5272 129.987 32.4938C129.987 32.3073 130.052 32.2427 130.24 32.2427C130.429 32.2427 130.494 32.3073 130.487 32.4938C130.487 33.0105 130.494 33.5272 130.494 34.0367Z" fill="#57565C"/>
      <path d="M115.198 34.0295C115.198 34.5462 115.191 35.0557 115.205 35.5724C115.212 35.7734 115.147 35.8451 114.937 35.8451C114.734 35.8451 114.698 35.759 114.705 35.5868C114.713 34.5606 114.713 33.5344 114.705 32.501C114.705 32.3288 114.742 32.2427 114.944 32.2427C115.154 32.2427 115.212 32.3288 115.205 32.5225C115.198 33.0249 115.198 33.5272 115.198 34.0295Z" fill="#57565C"/>
      <path d="M78.5077 34.0367C78.5077 34.5462 78.5077 35.0629 78.5077 35.5724C78.5077 35.7375 78.4932 35.8379 78.2832 35.8379C78.0732 35.8379 78.0225 35.759 78.0225 35.5652C78.0297 34.5534 78.0297 33.5416 78.0225 32.5297C78.0225 32.3503 78.0514 32.2427 78.276 32.2427C78.5222 32.2427 78.5077 32.379 78.5077 32.5441C78.5077 33.0392 78.5077 33.5416 78.5077 34.0367Z" fill="#57565C"/>
      <path d="M58.3305 34.0437C58.3305 34.5461 58.3305 35.0412 58.3305 35.5435C58.3305 35.7086 58.3304 35.8378 58.0914 35.8378C57.8669 35.8378 57.838 35.7373 57.8452 35.5579C57.8524 34.5461 57.8524 33.5342 57.8452 32.5224C57.8452 32.343 57.8669 32.2354 58.0914 32.2354C58.3304 32.2354 58.3305 32.3717 58.3305 32.5367C58.3305 33.0463 58.3305 33.5414 58.3305 34.0437Z" fill="#57565C"/>
      <path d="M152.786 34.0439C152.786 33.5344 152.786 33.0177 152.786 32.5082C152.786 32.3431 152.815 32.2427 153.018 32.2427C153.206 32.2427 153.271 32.3073 153.271 32.4938C153.264 33.5272 153.264 34.5678 153.271 35.6011C153.271 35.7949 153.191 35.8379 153.018 35.8451C152.808 35.8523 152.786 35.7518 152.786 35.5868C152.786 35.0701 152.786 34.5534 152.786 34.0439Z" fill="#57565C"/>
      <path d="M130.501 31.3671C130.45 31.5537 130.595 31.7977 130.255 31.7977C129.835 31.7977 130.023 31.4963 130.001 31.3169C129.98 31.1518 129.914 30.9365 130.248 30.9365C130.668 30.9437 130.4 31.2523 130.501 31.3671Z" fill="#57565C"/>
      <path d="M115.205 31.3597C115.162 31.5319 115.321 31.8046 114.937 31.8046C114.589 31.8046 114.705 31.5534 114.72 31.4027C114.734 31.2377 114.539 30.9506 114.944 30.9435C115.299 30.9291 115.176 31.1803 115.205 31.3597Z" fill="#57565C"/>
      <path d="M153.264 31.3742C153.264 31.4675 153.271 31.568 153.264 31.6613C153.249 31.8191 153.119 31.7904 153.018 31.7904C152.909 31.7904 152.793 31.7976 152.793 31.6397C152.793 31.4603 152.793 31.2809 152.793 31.1015C152.793 30.9149 152.923 30.9437 153.039 30.9365C153.17 30.9293 153.278 30.9437 153.271 31.1159C153.264 31.2092 153.264 31.2953 153.264 31.3742Z" fill="#57565C"/>
      <path d="M78.5222 31.3814C78.4643 31.5393 78.6381 31.7977 78.2832 31.7977C77.8921 31.7977 78.0442 31.5106 78.037 31.3384C78.0297 31.1805 77.9211 30.9437 78.2687 30.9365C78.6816 30.9365 78.4353 31.2379 78.5222 31.3814Z" fill="#57565C"/>
      <path d="M58.323 31.3814C58.323 31.4747 58.3303 31.5752 58.323 31.6685C58.3013 31.8264 58.1782 31.7905 58.0696 31.7977C57.9537 31.8048 57.8451 31.7977 57.8523 31.6398C57.8523 31.5106 57.8523 31.3814 57.8523 31.2451C57.8523 31.1016 57.8089 30.9365 58.0696 30.9365C58.3158 30.9365 58.352 31.0442 58.3375 31.2379C58.3303 31.2881 58.3375 31.3312 58.3375 31.3814C58.3303 31.3814 58.323 31.3814 58.323 31.3814Z" fill="#57565C"/>
      <path d="M101.93 36.3257C101.93 36.0817 101.93 35.8378 101.93 35.5938C101.93 35.4072 102.067 35.4359 102.183 35.4287C102.314 35.4216 102.4 35.4503 102.415 35.6153C102.444 35.9669 102.161 36.1176 101.93 36.3257Z" fill="#57565C"/>
      <path d="M22.0749 23.0497C20.5975 23.0425 19.4025 21.8944 19.4097 20.4878C19.4169 19.0813 20.6192 17.9474 22.1039 17.9546C23.5813 17.9618 24.7763 19.11 24.7691 20.5165C24.7619 21.9231 23.5524 23.0569 22.0749 23.0497Z" fill="#57565C"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="172" height="40" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);
