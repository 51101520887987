import { Card, CardActions, CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Perception } from '@priz/shared/src/models/tools/rrm';
import { Trans } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useStyles } from './styles';
import { QuillContentViewer } from '@priz/shared/src/components/quill-content-viewer/component';

interface RrmRankingOptionProps {
  perception: Perception;
  selected?: boolean;
  onSelect?: () => void;
  loading?: boolean;
}

export const RrmRankingOption: React.FC<RrmRankingOptionProps> = ({ perception, selected, onSelect, loading }) => {
  const styles = useStyles();

  const [buttonText, setButtonText] = useState('select');

  useEffect(() => {
    setButtonText(selected ? 'selected' : 'select');
  }, [selected]);

  const handleSelect = () => {
    if (onSelect) {
      onSelect();
    }
  };

  let buttonClass = `${styles.button}`;
  if (selected) {
    buttonClass = `${buttonClass} selected`;
  }

  return (
    <Card className={styles.root}>
      <CardContent className={styles.cardContent}>
        <QuillContentViewer content={perception.definition} />
      </CardContent>

      <CardActions className={styles.cardActions}>
        <LoadingButton className={buttonClass} onClick={handleSelect} disabled={loading} loading={loading}>
          <Trans>{buttonText}</Trans>
        </LoadingButton>
      </CardActions>
    </Card>
  );
};
