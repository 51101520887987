import { RouterActionMeta } from '../../../shared/store/types';
import { FluxStandardAction } from 'flux-standard-action';
import { INineWindowsUtilization, NineWindowsUtilization } from '@priz/shared/src/models/tools/nine-windows';
import { createPgAction } from '../../../shared/store/action-creator';
import { NineWindowsUtilizationApi } from '../../services/nine-windows-utilization.api';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { ToolType } from '@priz/shared/src/models/tools';
import { NavigateFunction } from 'react-router-dom';
import { ToolsUtils } from '../../../tools/utils';

export enum NineWindowsUtilizationActionType {
  Update = 'nine-windows-utilization/update',
  Delete = 'nine-windows-utilization/delete',
}

type NineWindowsUtilizationActionPayload = INineWindowsUtilization;

interface NineWindowsUtilizationActionMeta extends RouterActionMeta {
  utilization?: NineWindowsUtilization;
  utilizationId?: number;
  projectId?: number;
  navigate?: NavigateFunction;
}

export type NineWindowsUtilizationAction = FluxStandardAction<
  string,
  NineWindowsUtilizationActionPayload,
  NineWindowsUtilizationActionMeta
>;

const update = createPgAction(
  NineWindowsUtilizationActionType.Update,
  NineWindowsUtilizationApi.update,
  (utilization: NineWindowsUtilization) => ({ utilization }),
  (_payload, { utilization }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: utilization.project?.id,
      tool_type: ToolType.NINE_WINDOWS,
      utilization_id: utilization.id,
    });
  },
);

const doDelete = createPgAction(
  NineWindowsUtilizationActionType.Delete,
  NineWindowsUtilizationApi.delete,
  (utilizationId: number, projectId?: number, navigate?: NavigateFunction) => ({ projectId, utilizationId, navigate }),
  (_payload, { projectId, utilizationId, navigate }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_DELETED, {
      project_id: projectId,
      tool_type: ToolType.NINE_WINDOWS,
      utilization_id: utilizationId,
    });

    if (navigate) {
      navigate(ToolsUtils.resolveListPath(projectId));
    }
  },
);

export const NineWindowsUtilizationActions = {
  update,
  delete: doDelete,
};
