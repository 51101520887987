import React from 'react';
import { Chip, Grid, Link } from '@mui/material';
import { Trans } from 'react-i18next';
import { PlanPriceString } from '../plan-price-string/component';
import { Plan, PlanInterval, PlanLevel } from '@priz/shared/src/models/workspace';
import { SeatsQuantityPopper } from '../seats-quantity-popper/component';
import { IntervalSelectorPopper } from '../interval-selector-popper/component';
import { PlanLevelUtils } from '../../workspace/services';

interface SelectedBillingOptionsProps {
  plan: Plan;
  level: PlanLevel;
  interval: PlanInterval;
  quantity: number;
  onChangeInterval?: (value: PlanInterval) => void;
  onChangeSeatsQuantity?: (value: number) => void;
}

export const SelectedBillingOptions: React.FC<SelectedBillingOptionsProps> = ({
  plan,
  level,
  interval,
  quantity,
  onChangeInterval,
  onChangeSeatsQuantity,
}) => {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Trans>Plan</Trans>:&nbsp;
        <Chip
          label={
            <b>
              <Trans>{plan.name}</Trans>
            </b>
          }
          size={'small'}
        />
      </Grid>

      <Grid item>
        <Trans>Seats</Trans>:&nbsp;
        <Chip
          label={
            <>
              <b>{quantity}</b>
              {onChangeSeatsQuantity && !PlanLevelUtils.isLevelPersonalOrPro(level) && (
                <>
                  &nbsp;
                  <SeatsQuantityPopper value={quantity} onChange={onChangeSeatsQuantity}>
                    <Link component={'span'}>
                      <Trans>change</Trans>
                    </Link>
                  </SeatsQuantityPopper>
                </>
              )}
            </>
          }
          size={'small'}
        />
      </Grid>

      <Grid item>
        <Trans>Interval</Trans>:&nbsp;
        <Chip
          label={
            <>
              <b>
                <Trans>{interval}</Trans>
              </b>
              &nbsp;
              {onChangeInterval && (
                <IntervalSelectorPopper onPlanSelect={onChangeInterval} initialInterval={interval}>
                  <Link component={'span'}>
                    <Trans>change</Trans>
                  </Link>
                </IntervalSelectorPopper>
              )}
            </>
          }
          size={'small'}
        />
      </Grid>

      <Grid item>
        <Trans>Price</Trans>:&nbsp;
        <Chip
          label={
            <b>
              <PlanPriceString plan={plan} level={level} interval={interval} quantity={quantity} />
            </b>
          }
          size={'small'}
        />
        {interval == PlanInterval.Yearly && ` (average per month when paid annually)`}
      </Grid>
    </Grid>
  );
};
