import React, { ReactNode, useEffect, useState } from 'react';
import { UnleashFeatureName } from '@priz/shared/src/services/unleash';
import { useFlag, useFlags, useFlagsStatus, useUnleashClient } from '@unleash/proxy-client-react';
import { useNavigate } from 'react-router-dom';

export interface FeatureGuardProps {
  feature?: UnleashFeatureName;
  loadingElement?: ReactNode;
}

export const FeatureGuard: React.FC<FeatureGuardProps> = ({ feature, loadingElement, children }) => {
  const navigate = useNavigate();

  const client = useUnleashClient();
  const isFeatureEnabled = useFlag(feature);
  const flags = useFlags();
  const { flagsReady, flagsError } = useFlagsStatus();

  const [isAccessAllowed, setIsAccessAllowed] = useState(false);

  useEffect(() => {
    resolveAccess();
  }, [flagsReady, flagsError, flags, isFeatureEnabled]);

  const resolveAccess = () => {
    const clientContextUserId = client?.getContext()?.userId;

    if (flagsError) {
      return navigate('/');
    }

    if (!clientContextUserId) {
      return null;
    }

    if (clientContextUserId && !isFeatureEnabled) {
      return navigate('/');
    }

    setIsAccessAllowed(true);
  };

  return isAccessAllowed ? <>{children}</> : loadingElement ? <>{loadingElement}</> : null;
};
