import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',

    ['& svg']: {
      display: 'block',
    },
  },

  shadow: {
    boxShadow: '0 0 2px rgba(0,0,0,.2)',
  },
});
