import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { ElementViewerPopper, ElementViewerPopperBaseProps } from '../element-viewer-popper/component';
import { ElementsViewerContext } from '../elements-viewer-context/component';

export interface HtmlElementViewerBaseProps extends ElementViewerPopperBaseProps {
  watchElementsIds: string[];
}

interface HtmlElementViewerProps extends HtmlElementViewerBaseProps {
  contentResolver: (elementId: string) => ReactNode;
}

export const HtmlElementViewer: React.FC<HtmlElementViewerProps> = ({ contentResolver, watchElementsIds, ...rest }) => {
  const { setRefresh } = useContext(ElementsViewerContext);

  const [elementId, setElementId] = useState<string>();
  const [position, setPosition] = useState<Partial<DOMRect>>({});
  const [content, setContent] = useState<ReactNode>(null);

  useEffect(() => {
    manageEvents('bind');

    return () => {
      manageEvents('unbind');
    };
  }, [watchElementsIds.join('-')]);

  const manageEvents = (action: 'bind' | 'unbind') => {
    watchElementsIds.forEach(id => {
      const element = document.getElementById(id);

      if (element && action === 'bind') {
        element.addEventListener('mouseenter', focusHandler);
        element.addEventListener('touchstart', focusHandler);
        element.addEventListener('mouseleave', blurHandler);
        element.addEventListener('touchend', blurHandler);
      }

      if (element && action === 'unbind') {
        element.removeEventListener('mouseenter', focusHandler);
        element.removeEventListener('touchstart', focusHandler);
        element.removeEventListener('mouseleave', blurHandler);
        element.removeEventListener('touchend', blurHandler);
      }
    });
  };

  const focusHandler = (e: MouseEvent) => {
    const id = (e.target as HTMLDivElement)?.id;

    if (id) {
      setElementId(id);
    }
  };

  const blurHandler = () => {
    setElementId(undefined);
  };

  useEffect(() => {
    if (elementId) {
      setContent(contentResolver(elementId));
    } else {
      setContent(null);
    }
  }, [elementId]);

  useEffect(() => {
    resolvePosition();
  }, [elementId]);

  const resolvePosition = () => {
    const element = elementId ? document.getElementById(elementId) : undefined;

    if (element) {
      setPosition(element.getBoundingClientRect());
    }
  };

  const reset = () => {
    setElementId(undefined);
  };

  if (elementId) {
    setRefresh(() => {
      setContent(contentResolver(elementId));
      resolvePosition();
    });
  } else {
    setRefresh(() => {
      return;
    });
  }

  if (!elementId || !position || !content) return null;

  return <ElementViewerPopper content={content} position={position} onClose={reset} {...rest} />;
};
