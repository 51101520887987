import React from 'react';
import { Box, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { Task } from '@priz/shared/src/models/task';
import { ArrayUtils, SortDirection } from '@priz/shared/src/utils/common';
import { TaskInfoCard } from '../../../tasks/task-info-card/component';
import { categorizeTasks, TaskListCategory } from '../../../tasks/utils/categorization';
import { UimPriority } from '@priz/shared/src/models/tools/uim';

export interface ReportIdeaProps {
  tasks: Task[];
}

export const ReportTasksList: React.FC<ReportIdeaProps> = ({ tasks }) => {
  const categorizedTasks = categorizeTasks(tasks);

  return (
    <>
      {[TaskListCategory.Active, TaskListCategory.Completed].map(category => {
        if (!categorizedTasks[category]) return null;

        return (
          <React.Fragment key={category}>
            {category === TaskListCategory.Completed && (
              <Box mb={2}>
                <Typography variant={'subtitle2'} component={'span'}>
                  <Trans>Completed</Trans>:
                </Typography>
              </Box>
            )}

            {[
              UimPriority.DoFirst,
              UimPriority.Delegate,
              UimPriority.DoLater,
              UimPriority.Drop,
              UimPriority.Undefined,
            ].map(priority => {
              if (!categorizedTasks[category][priority]) return null;

              return categorizedTasks[category][priority]
                .sort(ArrayUtils.sorterByParam('latestRankingScore', SortDirection.DESC))
                .map(task => (
                  <Box mb={2} key={task.id}>
                    <TaskInfoCard task={task} disabled />
                  </Box>
                ));
            })}
          </React.Fragment>
        );
      })}
    </>
  );
};
