import React from 'react';
import { PlanFeaturesMap, FeatureLimit, featureLimitDescriptionMap } from '@priz/shared/src/models/workspace';
import { TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { PlanLevel } from '@priz/shared/src/models/workspace';
import { PlanFeatureIndicator } from '../../plan-feature-indicator/component';

interface ScalarRowProps {
  data: PlanFeaturesMap;
  feature: FeatureLimit;
  colsWidth?: string[];
}

export const ScalarRow: React.FC<ScalarRowProps> = ({ data, feature, colsWidth }) => {
  return (
    <TableRow hover={true}>
      <TableCell align="left" width={colsWidth?.[0] || '20%'}>
        <Tooltip
          title={featureLimitDescriptionMap[feature]?.description || ''}
          arrow
          placement="top"
          disableInteractive
        >
          <Typography component={'span'} className={'featureLimitText'}>
            <Trans>{featureLimitDescriptionMap[feature]?.text || feature}</Trans>
          </Typography>
        </Tooltip>
      </TableCell>
      {Object.keys(data).map((level: PlanLevel, key) => (
        <TableCell key={level} align="center" width={colsWidth?.[key + 1] || `${80 / Object.keys(data).length}%`}>
          <PlanFeatureIndicator value={data[level][feature]} />
        </TableCell>
      ))}
    </TableRow>
  );
};
