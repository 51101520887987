import React from 'react';
import { Task } from '@priz/shared/src/models/task';
import { Chip, Grid, GridProps, Typography } from '@mui/material';
import { Status } from '@priz/shared/src/components/status/component';
import { Assignee } from '../../assignment/assignee/component';
import { useSelector } from 'react-redux';
import { TaskAssigneeSelectors } from '../../assignment/store/selectors/task-assignee.selectors';
import { format } from 'date-fns';
import { GrayLabelContainer } from '../../content-containers/gray-label-container/component';
import { Trans } from 'react-i18next';
import { PriorityIndicator } from '@priz/shared/src/components/priority-indicator/component';
import { UimPriority } from '@priz/shared/src/models/tools/uim';
import { ToolLinkResolver } from '../../tools/tool-link-resolver/component';

interface TaskInfoItemContainerProps extends GridProps {
  label: string;
  columnDirection?: boolean;
  labelProps?: Partial<GridProps>;
}

const TaskInfoItemContainer: React.FC<TaskInfoItemContainerProps> = ({
  label,
  columnDirection,
  labelProps,
  children,
  ...rest
}) => {
  return (
    <Grid item xs={columnDirection ? 12 : false} {...rest}>
      <GrayLabelContainer label={label} flexDirection={columnDirection ? 'column' : 'row'} {...(labelProps || {})}>
        {children}
      </GrayLabelContainer>
    </Grid>
  );
};

interface TaskInfoProps extends GridProps {
  task: Task;
  columnDirection?: boolean;
  disabled?: boolean;
}

export const TaskInfo: React.FC<TaskInfoProps> = ({ task, columnDirection, disabled, ...rest }) => {
  const assignees = useSelector(TaskAssigneeSelectors.getTaskAssignees(task.id));
  const assignee = assignees?.[0];

  return (
    <Grid container alignItems={'center'} spacing={1} {...rest}>
      {task.sourceToolUtilization && (
        <TaskInfoItemContainer
          label={'Source'}
          columnDirection={columnDirection}
          overflow={'hidden'}
          labelProps={{ alignItems: columnDirection ? 'flex-start' : 'center' }}
        >
          <ToolLinkResolver
            utilizationId={task.sourceToolUtilization.id}
            sourceId={task.sourceId}
            disabled={disabled}
          />
        </TaskInfoItemContainer>
      )}

      {task.priority && task.priority !== UimPriority.Undefined && (
        <TaskInfoItemContainer label={'Priority'} columnDirection={columnDirection}>
          <PriorityIndicator priority={task.priority} size={'extra_small'} rounded />
        </TaskInfoItemContainer>
      )}

      <TaskInfoItemContainer
        label={'Status'}
        columnDirection={columnDirection}
        labelProps={{ alignItems: columnDirection ? 'flex-start' : 'center' }}
      >
        <Status status={task.status} />
      </TaskInfoItemContainer>

      {assignee && (
        <TaskInfoItemContainer
          label={'Assignee'}
          columnDirection={columnDirection}
          labelProps={{ alignItems: columnDirection ? 'flex-start' : 'center' }}
        >
          <Assignee assignee={assignee.user} size={'small'} />
        </TaskInfoItemContainer>
      )}

      <TaskInfoItemContainer label={'Solving the problem?'} columnDirection={columnDirection}>
        <Chip
          size={'extra_small'}
          color={task.solvingProblem ? 'success' : 'default'}
          label={<Trans>{task.solvingProblem ? 'Yes' : 'No'}</Trans>}
        />
      </TaskInfoItemContainer>

      {task.dueDate && (
        <TaskInfoItemContainer label={'Due date'} columnDirection={columnDirection}>
          <Typography variant={'subtitle2'}>{format(task.dueDate, 'MMM d yyyy h:mm aaa')}</Typography>
        </TaskInfoItemContainer>
      )}

      <TaskInfoItemContainer label={'Date created'} columnDirection={columnDirection}>
        <Typography variant={'subtitle2'}>{format(task.dateCreated, 'MMM d yyyy h:mm aaa')}</Typography>
      </TaskInfoItemContainer>
    </Grid>
  );
};
