import { NewFortyPrinciplesUtilizationCommand } from '../../../forty-principles/services/forty-principles-utilization.service';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { TaskRecorderProps } from '../../../tasks/task-recorder/component';
import { SharedEbsRankIdeasDialogProps } from '../../../ebs/ebs-rank-ideas-dialog/comonent';
import { RrmToolStarterProps } from '../../../rrm/RrmToolStarter';
import { IdeaRecorderProps } from '../../../idea/idea-recorder/component';
import { SharedMeetingRecorderProps } from '../../../meetings/meeting-recorder/component';
import { ToolSelectorDialogSharedProps } from '../../../tools/tool-selector-dialog/component';

export enum ProjectFeatureBlockerType {
  Idea = 'Idea',
  Task = 'Task',
  Meeting = 'Meeting',
  Tool = 'Tool',
  RRM = 'RRM',
  EBSRanking = 'EBSRanking',
  FortyPrinciples = 'FortyPrinciples',
  NotDefined = 'NotDefined',
}

export interface ProjectFeatureResolverBlockerProps {
  blocker: ProjectFeatureBlockerType;
  utilizationId?: number;
  meetingRecorderProps?: Pick<SharedMeetingRecorderProps, 'sourceToolId' | 'sourceToolType' | 'sourceId'>;
  toolRecorderProps?: Pick<ToolSelectorDialogSharedProps, 'parentId' | 'sourceId'>;
  ideaRecorderProps?: Pick<IdeaRecorderProps, 'sourceToolId' | 'sourceToolType' | 'sourceId'>;
  taskRecorderProps?: Pick<TaskRecorderProps, 'sourceToolId' | 'sourceToolType' | 'sourceId'>;
  rrmStarterProps?: Omit<RrmToolStarterProps, 'projectId' | 'utilizationId' | 'disabled'>;
  ebsRankingProps?: Pick<SharedEbsRankIdeasDialogProps, 'ebsCategoriesValidationResult'>;
  fortyPrinciplesCommand?: NewFortyPrinciplesUtilizationCommand;
}

export interface ProjectFeatureResolverBlockerState extends ProjectFeatureResolverBlockerProps {
  statuses: EntityCollectionStatus;
}

export const DefaultProjectFeatureBlockerState: ProjectFeatureResolverBlockerState = {
  blocker: ProjectFeatureBlockerType.NotDefined,
  statuses: {},
};
