import React from 'react';
import { ConversationMessage, ConversationRole } from '../store/model';
import { useStyles } from './styles';
import { Avatar, Box, Button, Skeleton, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { UserUtils } from '@priz/shared/src/utils/user/user-utils';
import { Trans } from 'react-i18next';
import { AIChatIcon } from '../ai-chat-icon/component';
import { WorkspaceMembersSelectors } from '../../workspace/store/selectors/workspace-members.selectors';
import { PersonRounded } from '@mui/icons-material';

interface AIChatMessageProps {
  message: ConversationMessage;
  loading?: boolean;
  onRetry?: () => void;
}

export const AIChatMessage: React.FC<AIChatMessageProps> = ({ message, loading, onRetry }) => {
  const styles = useStyles();

  const workspaceMember = useSelector(WorkspaceMembersSelectors.getById(message.userId));

  const isUser = message.role === ConversationRole.User;
  const isAssistant = message.role === ConversationRole.Assistant;

  const rootClassNames = [styles.root];

  if (isUser) rootClassNames.push(styles.rootUser);
  if (isAssistant) rootClassNames.push(styles.rootAssistant);

  return (
    <div className={rootClassNames.join(' ')}>
      {isUser && (
        <Tooltip title={workspaceMember?.email || ''} placement={'top'} arrow>
          <Avatar className={styles.avatar} src={workspaceMember?.profile?.avatarUrl}>
            {workspaceMember && UserUtils.resolveInitials(workspaceMember)}
            {!workspaceMember && <PersonRounded />}
          </Avatar>
        </Tooltip>
      )}

      {isAssistant && <AIChatIcon className={styles.avatar} />}

      <div className={styles.message}>
        <Box px={{ xs: 1.5, sm: 2 }} py={{ xs: 0.75, sm: 1.5 }}>
          {message.content}

          {loading && (
            <Box width={400} maxWidth={'100%'}>
              <Skeleton animation="wave" width={'100%'} />
              <Skeleton animation="wave" width={'100%'} />
              <Skeleton animation="wave" width={'50%'} />
            </Box>
          )}

          {onRetry && (
            <Box>
              <Box component={'span'} mr={1}>
                <Trans>Something went wrong</Trans>
              </Box>

              <Button variant={'outlined'} size={'pg_small'} onClick={onRetry}>
                <Trans>Retry</Trans>
              </Button>
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
};
