import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { Box, Typography } from '@mui/material';
import { FortyPrinciplesUtilization } from '@priz/shared/src/models/tools/forty-principles/forty-principles-utilization';
import { useForm } from 'react-hook-form';
import { ReactHookFormText, ReactHookFormSelect } from '../../react/form-elements';
import { FortyPrinciplesUtilizationActions, ParameterActions } from '../store/actions';
import { InventivePrinciplesSelectors } from '../store/selectors/InventivePrinciplesSelectors';
import { Trans } from 'react-i18next';
import { ContradictionTable } from '@priz/shared/src/components/contradiction-table/component';
import { getParameterDescriptionById } from '../utils';
import { resolveTranslation, TranslationResolver } from '../../react/translation-resolver/component';
import {
  FortyPrinciplesUtilizationCommand,
  resolve40pInitialUpdateCommandData,
} from '../services/forty-principles-utilization.service';

export interface InventivePrinciplesWorseningParameterProps {
  utilization: FortyPrinciplesUtilization;
  projectId?: number;
  disabled?: boolean;
}

export const InventivePrinciplesWorseningParameter: React.FC<InventivePrinciplesWorseningParameterProps> = ({
  utilization,
  projectId,
  disabled,
}) => {
  const dispatch = useDispatch();

  const [utilizationState, setUtilizationState] = useState(null);

  const { getValues, control, reset } = useForm({
    shouldFocusError: false,
    defaultValues: {
      worseningParameterDescription: '',
      worseningParameterId: null,
    },
  });

  const parameters = useSelector(InventivePrinciplesSelectors.getParameters);
  const parameterDescription = getParameterDescriptionById(parameters, getValues('worseningParameterId'));

  useEffect(() => {
    if (utilization && !utilizationState) {
      setUtilizationState(utilization);
    }
  }, [utilization, utilizationState]);

  useEffect(() => {
    dispatch(ParameterActions.loadParameters());
  }, []);

  useEffect(() => {
    reset({
      worseningParameterDescription: utilization?.worseningParameterDescription || '',
      worseningParameterId: utilization?.worseningParameter?.id || null,
    });
  }, [utilizationState]);

  const onTextUpdate = useCallback(
    debounce(() => {
      const command: FortyPrinciplesUtilizationCommand = {
        ...resolve40pInitialUpdateCommandData(utilization),
        ...getValues(),
      };

      dispatch(FortyPrinciplesUtilizationActions.update(utilization.id, command, projectId));
    }, 500),
    [utilization],
  );

  const changeHandler = () => {
    onTextUpdate();
  };

  return (
    <Box>
      <Box mb={6}>
        <ContradictionTable utilization={utilization} highlight={'contradictionBut'} />
      </Box>

      <form onSubmit={e => e.preventDefault()} onChange={changeHandler}>
        <ReactHookFormText
          fieldTitle={'Describe in your words what specific parameter is going to be worsened'}
          name={'worseningParameterDescription'}
          control={control}
          multiline={true}
          disabled={disabled}
        />

        <ReactHookFormSelect
          fieldTitle="Worsening parameter"
          name={'worseningParameterId'}
          control={control}
          options={parameters.map(p => {
            const title = resolveTranslation({
              en: p.title,
              zh: p.titleZh,
              es: p.titleEs,
            });

            return { value: p.id, text: `${p.id}. ${title}` };
          })}
          disabled={disabled}
          onChange={changeHandler}
        />

        {parameterDescription && (
          <Box pb={5}>
            <Box mb={2}>
              <Typography variant={'h6'} component={'span'}>
                <Trans>Parameter description</Trans>
              </Typography>
            </Box>

            <Box>
              <Typography variant={'body2'} component={'span'}>
                <TranslationResolver
                  en={parameterDescription.en}
                  zh={parameterDescription.zh}
                  es={parameterDescription.es}
                />
              </Typography>
            </Box>
          </Box>
        )}
      </form>
    </Box>
  );
};
