import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../store/app.state';
import { Role } from '@priz/shared/src/models/security/role.enum';
import { User } from '@priz/shared/src/models/security/user';
import { Auth0ConnectionType } from '@priz/shared/src/models/security/auth0-connection-type';

const currentUserStateSelector = (state: AppState) => state.currentUser;

const currentUserSelector: Selector<AppState, User> = createSelector(
  currentUserStateSelector,
  userStateObj => userStateObj.user,
);

const isLoading: Selector<AppState, boolean> = createSelector(
  currentUserStateSelector,
  userStateObj => userStateObj.loading,
);

const isLoaded: Selector<AppState, boolean> = createSelector(
  currentUserStateSelector,
  userStateObj => userStateObj.loaded,
);

const isAvatarUpdating: Selector<AppState, boolean> = createSelector(
  currentUserStateSelector,
  userStateObj => userStateObj.avatarUpdating,
);

const isProfileUpdating: Selector<AppState, boolean> = createSelector(
  currentUserStateSelector,
  userStateObj => userStateObj.profileUpdating,
);

const isPasswordUpdating: Selector<AppState, boolean> = createSelector(
  currentUserStateSelector,
  userStateObj => userStateObj.passwordUpdating,
);

const isPrivacySettingsUpdating: Selector<AppState, boolean> = createSelector(
  currentUserStateSelector,
  userStateObj => userStateObj.privacySettingsUpdating,
);

const isSecuritySettingsUpdating: Selector<AppState, boolean> = createSelector(
  currentUserStateSelector,
  userStateObj => userStateObj.securitySettingsUpdating,
);
const isSecuritySettingsUpdated: Selector<AppState, boolean> = createSelector(
  currentUserStateSelector,
  userStateObj => userStateObj.securitySettingsUpdated,
);

const isConnectionsUpdating: Selector<AppState, boolean> = createSelector(
  currentUserStateSelector,
  userStateObj => userStateObj.connectionsUpdating,
);
const isConnectionsUpdated: Selector<AppState, boolean> = createSelector(
  currentUserStateSelector,
  userStateObj => userStateObj.connectionsUpdated,
);
const isPoliciesUpdating: Selector<AppState, boolean> = createSelector(
  currentUserStateSelector,
  userStateObj => userStateObj.policiesUpdating,
);
const loadingConnection: Selector<AppState, Auth0ConnectionType> = createSelector(
  currentUserStateSelector,
  userStateObj => userStateObj.loadingConnection,
);

const isVerificationEmailSending: Selector<AppState, boolean> = createSelector(
  currentUserStateSelector,
  userStateObj => userStateObj.verificationEmailSending,
);

const isCurrentUserHasAnyRoles = (roles: Role[]): Selector<AppState, boolean> =>
  createSelector(currentUserSelector, currentUser => currentUser.roles.some(r => roles.includes(r)));

export const UserSelectors = {
  isLoading,
  isLoaded,
  currentUserSelector,
  isCurrentUserHasAnyRoles,
  isAvatarUpdating,
  isProfileUpdating,
  isPasswordUpdating,
  isPrivacySettingsUpdating,
  isSecuritySettingsUpdating,
  isSecuritySettingsUpdated,
  isConnectionsUpdating,
  isConnectionsUpdated,
  isPoliciesUpdating,
  loadingConnection,
  isVerificationEmailSending,
};
