import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useMutation } from 'react-query';
import { TranslationsApi } from '../services/translations.api';
import { Translation } from '../model/translation';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  actions: {
    textAlign: 'right',
  },
});

interface NewTranslationDialogProps {
  isOpen: boolean;
  onClose?: () => void;
  onCreate?: (data: Translation) => void;
}

export const NewTranslationDialog: React.FC<NewTranslationDialogProps> = ({ isOpen, onClose, onCreate }) => {
  const styles = useStyles();

  const [open, setOpen] = useState(false);
  const [english, setEnglish] = useState('');

  const mutation = useMutation((en: string) => TranslationsApi.create(en), {
    onSuccess: data => {
      if (onCreate) {
        onCreate(data);
      }
    },
  });

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleValueChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setEnglish(value);
  };

  const handleCreate = () => {
    mutation.mutate(english.trim());
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setEnglish('');
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'lg'}>
      <DialogTitle id="new-translation-dialog-title">New translation record</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Add a string in English that will eventually be translated to other languages
        </DialogContentText>
        <TextField
          autoFocus
          onChange={handleValueChange}
          variant={'outlined'}
          id="translation-field-en"
          label="English"
          type="text"
          fullWidth
          multiline
        />
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose}>
          Cancel
        </Button>
        <Button variant={'pg_rounded'} color={'pg_orange_solid'} onClick={handleCreate}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
