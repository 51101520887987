import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../../store/app.state';
import { PlanLevel } from '@priz/shared/src/models/workspace';
import { PartnerCommission } from '@priz/shared/src/models/security/partner-commission';

const partnershipSelector = (state: AppState) => state.partnership;

const profileSelector = createSelector(partnershipSelector, state => state.profile);
const referralsSelector = createSelector(partnershipSelector, state => state.referrals);
const referralsPlanLevelsSelector = createSelector(partnershipSelector, state => state.referralsPlanLevels);
const referralsCommissionsSelector = createSelector(partnershipSelector, state => state.referralsCommissions);

const profileStatusesSelector = createSelector(profileSelector, state => state.statuses);
const referralsStatusesSelector = createSelector(referralsSelector, state => state.statuses);
const referralsPlanLevelsStatusesSelector = createSelector(referralsPlanLevelsSelector, state => state.statuses);
const referralsCommissionsStatusesSelector = createSelector(referralsCommissionsSelector, state => state.statuses);

const getProfile = createSelector(profileSelector, collection => collection.data);
const getReferrals = createSelector(referralsSelector, collection => collection.list);
const getPlanLevelsByReferralId = (referralId: number): Selector<AppState, PlanLevel[]> => {
  return createSelector(referralsPlanLevelsSelector, collection => collection.map[referralId]);
};
const getCommissionsByReferralId = (referralId: number): Selector<AppState, PartnerCommission[]> => {
  return createSelector(referralsCommissionsSelector, collection => collection.map[referralId]);
};

const isProfileLoading = createSelector(profileStatusesSelector, statuses => !!statuses?.loading);
const isProfileLoaded = createSelector(profileStatusesSelector, statuses => !!statuses?.loaded);

const isReferralsLoading = createSelector(referralsStatusesSelector, statuses => !!statuses?.loading);
const isReferralsLoaded = createSelector(referralsStatusesSelector, statuses => !!statuses?.loaded);

const arePlanLevelsLoadingByReferralId = (referralId: number): Selector<AppState, boolean> => {
  return createSelector(referralsPlanLevelsStatusesSelector, statuses => !!statuses?.[referralId]?.loading);
};
const arePlanLevelsLoadedByReferralId = (referralId: number): Selector<AppState, boolean> => {
  return createSelector(referralsPlanLevelsStatusesSelector, statuses => !!statuses?.[referralId]?.loaded);
};

const areCommissionsLoadingByReferralId = (referralId: number): Selector<AppState, boolean> => {
  return createSelector(referralsCommissionsStatusesSelector, statuses => !!statuses?.[referralId]?.loading);
};
const areCommissionsLoadedByReferralId = (referralId: number): Selector<AppState, boolean> => {
  return createSelector(referralsCommissionsStatusesSelector, statuses => !!statuses?.[referralId]?.loaded);
};

export const PartnershipSelectors = {
  getProfile,
  getReferrals,
  getPlanLevelsByReferralId,
  isProfileLoading,
  isProfileLoaded,
  isReferralsLoading,
  isReferralsLoaded,
  arePlanLevelsLoadingByReferralId,
  arePlanLevelsLoadedByReferralId,
  getCommissionsByReferralId,
  areCommissionsLoadingByReferralId,
  areCommissionsLoadedByReferralId,
};
