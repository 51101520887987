import React, { useEffect, useRef } from 'react';
import { Box, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FieldTitle } from '../title/component';
import { HelperText } from '../helper-text/component';
import { ValidatorControllerProps, ReactHookFormElement } from '../../../models/form';
import { TextFieldProps } from '@mui/material/TextField/TextField';

export type ReactHookFormTextProps = ReactHookFormElement &
  ValidatorControllerProps &
  TextFieldProps & {
    onMount?: (el?: HTMLInputElement | HTMLTextAreaElement) => void;
  };

export const ReactHookFormText: React.FC<ReactHookFormTextProps> = ({
  type = 'text',
  size = 'medium',
  variant = 'outlined',
  onBlur,
  onChange,
  wrapperProps,
  control,
  rules,
  name,
  fieldTitle,
  helperText,
  fieldTitleWrapperProps,
  onMount,
  onKeyDown,
  ...rest
}) => {
  const ref = useRef<HTMLInputElement | HTMLTextAreaElement>();

  useEffect(() => {
    if (onMount) {
      onMount(ref.current);
    }
  }, []);

  return (
    <Box mb={3} {...wrapperProps}>
      <FieldTitle text={fieldTitle} {...fieldTitleWrapperProps} />

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <>
            <TextField
              fullWidth
              type={type}
              value={field.value}
              variant={variant}
              size={size}
              error={!!error?.message}
              inputRef={el => {
                ref.current = el;
                field.ref(el);
              }}
              onBlur={e => {
                field.onBlur();
                if (onBlur) onBlur(e);
              }}
              onChange={e => {
                field.onChange(e);
                if (onChange) onChange(e);
              }}
              onKeyDown={e => {
                e.stopPropagation();

                if (onKeyDown) {
                  onKeyDown(e);
                }
              }}
              {...rest}
            />

            <HelperText text={helperText} error={error?.message} />
          </>
        )}
      />
    </Box>
  );
};
