import React from 'react';
import { Box, Chip, Grid, Paper, Typography } from '@mui/material';
import { Agenda, Attendee, Meeting } from '../store/model';
import { Trans } from 'react-i18next';
import { FieldTitle } from '@priz/shared/src/components/form-elements';
import { AttendeesList } from '../attendees-list/component';
import { AgendasList } from '../agendas-list/component';
import { QuillContentViewer } from '@priz/shared/src/components/quill-content-viewer/component';
import { useSelector } from 'react-redux';
import { AgendaSelectors, AttendeeSelectors } from '../store/selectors';
import { Assignee } from '../../assignment/assignee/component';
import { WorkspaceMembersSelectors } from '../../workspace/store/selectors/workspace-members.selectors';
import { resolveDurationLabel } from '../../time-picker-button/component';
import { InsertInvitationRounded, TimerOutlined } from '@mui/icons-material';
import { TimeZoneService } from '@priz/shared/src/services/time';
import { AppState } from '../../store/app.state';
import { ToolLinkResolver } from '../../tools/tool-link-resolver/component';

export interface MeetingInfoProps {
  meeting: Meeting;
  viewMode?: boolean;
  showTitle?: boolean;
  forcedAgenda?: Agenda[];
  forcedAttendees?: Attendee[];
}

export const MeetingInfo: React.FC<MeetingInfoProps> = ({
  meeting,
  viewMode,
  forcedAgenda,
  forcedAttendees,
  showTitle = true,
}) => {
  const attendees = useSelector((state: AppState) =>
    forcedAttendees
      ? forcedAttendees
      : AttendeeSelectors.getAllByProjectIdAndMeetingId(meeting.project.id, meeting.id)(state),
  );
  const agenda = useSelector((state: AppState) =>
    forcedAgenda ? forcedAgenda : AgendaSelectors.getAllByProjectIdAndMeetingId(meeting.project.id, meeting.id)(state),
  );
  const lead = useSelector(WorkspaceMembersSelectors.getById(meeting.createdBy.id));

  return (
    <>
      {showTitle && (
        <Box mb={2}>
          <Typography variant={'h6'}>{meeting.title}</Typography>
        </Box>
      )}

      <Box mb={2}>
        <Grid container spacing={2}>
          {lead && (
            <Grid item>
              <FieldTitle text={'Meeting lead'} />
              <Assignee assignee={lead} variant="filled" />
            </Grid>
          )}

          <Grid item>
            <FieldTitle text={'Date and time'} />
            <Chip
              label={meeting.date ? TimeZoneService.format(meeting.date) : <Trans>Date not set</Trans>}
              icon={<InsertInvitationRounded />}
              variant="outlined"
            />
          </Grid>

          {meeting.durationMs && (
            <Grid item>
              <FieldTitle text={'Duration'} />
              <Chip label={resolveDurationLabel(meeting.durationMs)} icon={<TimerOutlined />} variant="outlined" />
            </Grid>
          )}

          {meeting.sourceToolUtilization && (
            <Grid item overflow={'hidden'}>
              <FieldTitle text={'Source'} />

              <ToolLinkResolver
                utilizationId={meeting.sourceToolUtilization.id}
                sourceId={meeting.sourceId}
                size={'medium'}
                disabled={viewMode}
              />
            </Grid>
          )}
        </Grid>
      </Box>

      {!!attendees.length && (
        <Box mb={2}>
          <FieldTitle text={'Attendees'} />
          <AttendeesList attendees={attendees} meetingStatus={meeting.status} viewMode={viewMode} />
        </Box>
      )}

      {!!agenda.length && (
        <Box mb={2}>
          <FieldTitle text={'Agenda'} />
          <AgendasList agendaItems={agenda} meetingStatus={meeting.status} viewMode={viewMode} />
        </Box>
      )}

      {meeting.notes && (
        <Box mb={2}>
          <FieldTitle text={'Meeting minutes'} />
          <Paper variant={'outlined'}>
            <Box p={2}>
              <QuillContentViewer content={meeting.notes} />
            </Box>
          </Paper>
        </Box>
      )}
    </>
  );
};
