import { ToolType } from '@priz/shared/src/models/tools';
import {
  TemplateActivityOptionType,
  TemplateCategory,
  TemplatePreparingOptionType,
  TemplateResultOptionType,
  TemplateType,
} from '@priz/shared/src/models/template';

interface TemplateData {
  [TemplateCategory.Preparing]: TemplatePreparingOptionType[];
  [TemplateCategory.Tools]: ToolType[];
  [TemplateCategory.Activity]: TemplateActivityOptionType[];
  [TemplateCategory.Result]: TemplateResultOptionType[];
}

type Templates = {
  [key in TemplateType]: TemplateData;
};

export const templates: Templates = {
  [TemplateType.General]: {
    [TemplateCategory.Preparing]: [
      TemplatePreparingOptionType.ProjectBackground,
      TemplatePreparingOptionType.ProblemStatement,
    ],
    [TemplateCategory.Tools]: [
      ToolType.TOOL_40_PRINCIPLES,
      ToolType.CEC,
      ToolType.APA,
      ToolType.EBS,
      ToolType.FIVE_WHYS,
      ToolType.P_MAP,
      ToolType.SFM,
      ToolType.PFM,
    ],
    [TemplateCategory.Activity]: [
      TemplateActivityOptionType.Tasks,
      TemplateActivityOptionType.TasksPriority,
      TemplateActivityOptionType.Meetings,
      TemplateActivityOptionType.Publishing,
    ],
    [TemplateCategory.Result]: [
      TemplateResultOptionType.Ideas,
      TemplateResultOptionType.Solution,
      TemplateResultOptionType.Report,
    ],
  },

  [TemplateType.ExcursionPrevention]: {
    [TemplateCategory.Preparing]: [TemplatePreparingOptionType.ProjectBackground],
    [TemplateCategory.Tools]: [
      ToolType.FIVE_WHYS,
      ToolType.SFM,
      ToolType.PFM,
      ToolType.EBS,
      ToolType.TOOL_40_PRINCIPLES,
    ],
    [TemplateCategory.Activity]: [
      TemplateActivityOptionType.Tasks,
      TemplateActivityOptionType.Meetings,
      TemplateActivityOptionType.Publishing,
    ],
    [TemplateCategory.Result]: [TemplateResultOptionType.Solution, TemplateResultOptionType.Report],
  },

  [TemplateType.LeanSixSigma]: {
    [TemplateCategory.Preparing]: [TemplatePreparingOptionType.ProjectBackground],
    [TemplateCategory.Tools]: [ToolType.SFM, ToolType.PFM, ToolType.EBS, ToolType.TOOL_40_PRINCIPLES],
    [TemplateCategory.Activity]: [
      TemplateActivityOptionType.Tasks,
      TemplateActivityOptionType.Meetings,
      TemplateActivityOptionType.Publishing,
    ],
    [TemplateCategory.Result]: [TemplateResultOptionType.Solution, TemplateResultOptionType.Report],
  },

  [TemplateType.RootCauseAnalysis]: {
    [TemplateCategory.Preparing]: [TemplatePreparingOptionType.ProjectBackground],
    [TemplateCategory.Tools]: [ToolType.CEC, ToolType.FIVE_WHYS, ToolType.EBS],
    [TemplateCategory.Activity]: [
      TemplateActivityOptionType.Tasks,
      TemplateActivityOptionType.Meetings,
      TemplateActivityOptionType.Publishing,
    ],
    [TemplateCategory.Result]: [TemplateResultOptionType.Solution, TemplateResultOptionType.Report],
  },
};
