import { handleActions } from 'redux-actions';
import { toStarted, toFailed, toSuccess } from '../../../shared/store/action-creator';
import { CollectionStatusesService } from '@priz/shared/src/services/statuses/collection-statuses.service';
import { PMapUtilizationAction, PMapUtilizationActionType } from '../actions';
import { DefaultPMapResultCollection, PMapResultCollection } from '../model';
import { CalcResult } from '@priz/shared/src/models/tools/pmap';

export const pMapResultReducers = handleActions(
  {
    [toStarted(PMapUtilizationActionType.GetResult)]: (
      state: PMapResultCollection,
      action: PMapUtilizationAction,
    ): PMapResultCollection =>
      CollectionStatusesService.setStatusesByUtilizationId(state, action.meta.utilizationId, {
        loading: true,
        loaded: false,
      }),

    [toFailed(PMapUtilizationActionType.GetResult)]: (
      state: PMapResultCollection,
      action: PMapUtilizationAction,
    ): PMapResultCollection =>
      CollectionStatusesService.setStatusesByUtilizationId(state, action.meta.utilizationId, {
        loading: false,
        loaded: false,
        error: true,
      }),

    [toSuccess(PMapUtilizationActionType.GetResult)]: (
      state: PMapResultCollection,
      action: PMapUtilizationAction,
    ): PMapResultCollection => ({
      ...state,
      result: {
        byUtilizationId: {
          ...state.result.byUtilizationId,
          [action.meta.utilizationId]: action.payload as CalcResult,
        },
      },
      statuses: CollectionStatusesService.resolveStatusesByUtilizationId(state.statuses, action.meta.utilizationId, {
        loading: false,
        loaded: true,
        error: false,
      }),
    }),
  },

  DefaultPMapResultCollection,
);
