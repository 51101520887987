import { FluxStandardAction } from 'flux-standard-action';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { Profile } from '@priz/shared/src/models/security/profile';
import { User } from '@priz/shared/src/models/security/user';
import {
  DeleteConnectionCommand,
  PrivacySettingsCommand,
  SecuritySettingsCommand,
  UserService,
} from '../../../security/services/user.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { createPgAction } from '../../../shared/store/action-creator';
import { NavigateFunction } from 'react-router-dom';
import { Auth0ConnectionType } from '@priz/shared/src/models/security/auth0-connection-type';
import { NotificationActionMeta } from '../../../react/modules/notification/store';

export enum UserActionType {
  Fetch = 'currentUser/fetch',
  UpdateProfile = 'currentUser/updateProfile',
  UpdatePassword = 'currentUser/updatePassword',
  UpdateAvatar = 'currentUser/updateAvatar',
  UpdatePrivacySettings = 'currentUser/updatePrivacySettings',
  UpdateSecuritySettings = 'currentUser/updateSecuritySettings',
  RequestVerificationEmail = 'currentUser/verificationEmail/request',
  DeleteConnection = 'currentUser/connection/delete',
  AcceptPolicies = 'currentUser/policies/accept',
  VerifyEmail = 'email/verify',
}

export interface UserActionMeta extends NotificationActionMeta {
  profile?: Profile;
  newPassword?: string;
  img?: File;
  token?: Profile;
  navigate?: NavigateFunction;
  connectionType?: Auth0ConnectionType;
}

export type UserAction = FluxStandardAction<UserActionType, User, UserActionMeta>;

const loadCurrentUser = createPgAction(UserActionType.Fetch, UserService.login);

const updateCurrentUserProfile = createPgAction(
  UserActionType.UpdateProfile,
  UserService.updateUserProfile,
  (profile: Profile, preventNotification?: boolean) => ({ profile, preventNotification }),
  (user: User) => {
    AnalyticsService.track(AnalyticsEvent.USER_DETAILED_UPDATED);

    if (user?.email && user?.profile) {
      AnalyticsService.setUserProperties({
        id: user.id,
        email: user.email,
        firstName: user.profile.firstName,
        lastName: user.profile.lastName,
        organization: user.profile.organization,
        location: user.profile.location,
      });
    }
  },
);

const updateCurrentUserPassword = createPgAction(
  UserActionType.UpdatePassword,
  UserService.updateUserPassword,
  (newPassword: string) => ({ newPassword }),
  () => {
    AnalyticsService.track(AnalyticsEvent.USER_PASSWORD_UPDATED);
  },
);

const updateCurrentUserAvatar = createPgAction(
  UserActionType.UpdateAvatar,
  UserService.updateUserAvatar,
  (img: File) => ({ img }),
  () => {
    AnalyticsService.track(AnalyticsEvent.USER_AVATAR_UPDATED);
  },
);

const requestVerificationEmail = createPgAction(
  UserActionType.RequestVerificationEmail,
  UserService.requestVerificationEmail,
);

const verifyEmail = createPgAction(
  UserActionType.VerifyEmail,
  UserService.verifyEmail,
  (token: string, navigate: NavigateFunction) => ({ token, navigate }),
  (_payload, { navigate }) => {
    if (navigate) navigate('/');
  },
);

const updatePrivacySettings = createPgAction(
  UserActionType.UpdatePrivacySettings,
  UserService.updatePrivacySettings,
  (command: PrivacySettingsCommand) => ({ ...command }),
);

const updateSecuritySettings = createPgAction(
  UserActionType.UpdateSecuritySettings,
  UserService.updateSecuritySettings,
  (command: SecuritySettingsCommand) => ({ ...command }),
);

const deleteConnection = createPgAction(
  UserActionType.DeleteConnection,
  UserService.deleteConnection,
  (command: DeleteConnectionCommand) => ({ ...command }),
);

const acceptPolicies = createPgAction(UserActionType.AcceptPolicies, UserService.acceptPolicies);

export const UserActions = {
  loadCurrentUser,
  updateCurrentUserProfile,
  updateCurrentUserPassword,
  updateCurrentUserAvatar,
  requestVerificationEmail,
  verifyEmail,
  updatePrivacySettings,
  updateSecuritySettings,
  deleteConnection,
  acceptPolicies,
};
