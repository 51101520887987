import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Alert, Box, BoxProps, Link, Typography } from '@mui/material';
import { NavigationProps } from '../auth-tabs/component';
import { AuthErrorCode } from '../auth-error-message/AuthErrorCode';
import { LocalStorageKey, LocalStorageService } from '../../../services/local-storage';
import { AuthFormType } from '../../../models/auth0';

export interface ErrorResponse {
  errorCode?: AuthErrorCode | string;
  errorMessage: string;
}

interface AuthErrorMessageProps extends BoxProps, Partial<NavigationProps> {
  errorResponse: ErrorResponse | null;
  formType: AuthFormType;
}

export const AuthErrorMessage: React.FC<AuthErrorMessageProps> = ({
  errorResponse,
  formType,
  onLoginNavigate,
  onSignUpNavigate,
  ...rest
}) => {
  const { t } = useTranslation();

  const [errorState, setErrorState] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    setErrorState(errorResponse);
  }, [errorResponse]);

  useEffect(() => {
    const lsErrorCode = LocalStorageService.getItem(LocalStorageKey.LastAuthErrorCode);

    if (lsErrorCode) {
      if (!errorResponse) {
        setErrorState({
          errorCode: lsErrorCode as AuthErrorCode,
          errorMessage: 'Error',
        });
      }

      LocalStorageService.removeItem(LocalStorageKey.LastAuthErrorCode);
    }
  }, []);

  if (!errorState?.errorCode && !errorState?.errorMessage) return null;

  return (
    <Box {...rest}>
      {errorState?.errorCode === AuthErrorCode.AccessDenied && (
        <Box mb={1}>
          <Alert severity="error">
            {onSignUpNavigate && (
              <Typography variant={'body2'} component={'span'}>
                {`${t('Wrong email or password. Not registered yet?')} ${t('Please')}, `}
                <Link component={'span'} underline={'hover'} onClick={onSignUpNavigate}>
                  <Trans>sign up</Trans>
                </Link>
                {`${t(
                  'You may have registered using Google or LinkedIn. If so, please log in using the respective platform to set your password.',
                )}`}
              </Typography>
            )}

            {!onSignUpNavigate && (
              <Typography variant={'body2'} component={'span'}>
                <Trans>Wrong email or password. Not registered yet?</Trans>
              </Typography>
            )}
          </Alert>
        </Box>
      )}

      {errorState?.errorCode === AuthErrorCode.InvalidSignup && (
        <Box mb={1}>
          <Alert severity="error">
            {onLoginNavigate && (
              <Typography variant={'body2'} component={'span'}>
                {`${t('Invalid sign up. Possibly you have an account already?')} ${t('Please try to')} `}
                <Link component={'span'} underline={'hover'} onClick={onLoginNavigate}>
                  <Trans>login</Trans>
                </Link>
                {`${t(
                  'You may have registered using Google or LinkedIn. If so, please log in using the respective platform to set your password.',
                )}`}
              </Typography>
            )}

            {!onLoginNavigate && (
              <Typography variant={'body2'} component={'span'}>
                <Trans>Invalid sign up. Possibly you have an account already?</Trans>
              </Typography>
            )}
          </Alert>
        </Box>
      )}

      {errorState?.errorCode === AuthErrorCode.BlockedUser && (
        <Box mb={1}>
          <Alert severity="error">
            <Typography variant={'body2'} component={'span'}>
              {`${t('This user is blocked!')} ${t('Please')}, `}
              <Link underline={'hover'} href={'https://www.priz.guru/contact-us/'} target={'_blank'}>
                <Trans>contact us</Trans>
              </Link>
              {` ${t('for more details')}`}
            </Typography>
          </Alert>
        </Box>
      )}

      {errorState?.errorCode === AuthErrorCode.PgUnverifiedEmail && (
        <Box mb={1}>
          <Alert severity="error">
            <Typography variant={'body2'} component={'span'}>
              {`${t('The account with this email exists. However, the email is not verified.')} ${t(
                'Please, login with email & password to verify your email.',
              )}`}
            </Typography>
          </Alert>
        </Box>
      )}

      {errorState?.errorCode === AuthErrorCode.PgConflictEmail && (
        <Box mb={1}>
          <Alert severity="error">
            <Typography variant={'body2'} component={'span'}>
              <Trans>Account already exists</Trans>
            </Typography>
          </Alert>
        </Box>
      )}

      {errorState?.errorCode === AuthErrorCode.PgUnauthorized && (
        <Box mb={1}>
          <Alert severity="error">
            <Typography variant={'body2'} component={'span'}>
              {formType === AuthFormType.Login && <Trans>Wrong email or password</Trans>}

              {formType === AuthFormType.SignUp && <Trans>Account already exists</Trans>}
            </Typography>
          </Alert>
        </Box>
      )}

      {![
        AuthErrorCode.AccessDenied,
        AuthErrorCode.BlockedUser,
        AuthErrorCode.InvalidSignup,
        AuthErrorCode.PgConflictEmail,
        AuthErrorCode.PgUnverifiedEmail,
        AuthErrorCode.PgUnauthorized,
      ].includes(errorState?.errorCode as AuthErrorCode) && (
        <Box mb={1}>
          <Alert severity="error">
            <Typography variant={'body2'} component={'span'}>
              <Trans>{errorState?.errorMessage || 'Something went wrong'}</Trans>
            </Typography>
          </Alert>
        </Box>
      )}
    </Box>
  );
};
