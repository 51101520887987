import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { CauseAndEffectChainDiagram } from '../cause-and-effect-chain-diagram/component';
import { ProjectSelector, ToolUtilizationSelector } from '../../project/store/selectors';
import { CecPreset } from '@priz/shared/src/models/tools/cec';
import { CecUtilizationActions } from '../store/actions';
import { ToolTitle } from '../../tools/tool-title/component';
import { TitleContainer } from '../../react/modules/title-container/component';
import { TabPanel } from '../../react/elements/tab-panel/component';
import { ToolSubjectConclusion } from '../../tools/tool-subject-conclusion/component';
import { Trans } from 'react-i18next';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { ToolUtilizationActions, ToolUtilizationActionType } from '../../project/store/actions/tools.actions';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { useToolTabs } from '../../tools/utils';

interface CauseAndEffectChainViewProps {
  utilizationId: number;
  projectId?: number;
}

export const CauseAndEffectChainView: React.FC<CauseAndEffectChainViewProps> = ({ utilizationId, projectId }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const [activeTab, setActiveTab] = useToolTabs(0);

  const cecUtilization = useSelector(ToolUtilizationSelector.getCecUtilization(utilizationId));
  const isProjectLocked = useSelector(ProjectSelector.isProjectLockedById(projectId));
  const isCompletenessUpdating = useSelector(
    ToolUtilizationSelector.isPendingByActionType(utilizationId, ToolUtilizationActionType.UpdateCompleteness),
  );

  const isDisabled = !!cecUtilization?.completed || isCompletenessUpdating || isProjectLocked;

  useEffect(() => {
    dispatch(ToolUtilizationActions.loadTool(utilizationId, projectId));
  }, [projectId, utilizationId]);

  const updateData = data => {
    dispatch(
      CecUtilizationActions.update({
        ...cecUtilization,
        diagramData: data,
      }),
    );
  };

  const conclusionChangeHandler = (conclusion: string) => {
    dispatch(
      CecUtilizationActions.update({
        ...cecUtilization,
        conclusion,
      }),
    );
  };

  const subjectChangeHandler = (subject: string) => {
    dispatch(
      CecUtilizationActions.update({
        ...cecUtilization,
        subject,
      }),
    );
  };

  const handleChange = (_event, newValue: number) => {
    setActiveTab(newValue);
  };

  if (!cecUtilization) {
    return null;
  }

  return (
    <>
      <ContentContainer display={'flex'} alignItems={'stretch'}>
        <Grid container direction={'column'} wrap={'nowrap'}>
          <Grid item xs={'auto'}>
            <TitleContainer>
              <PageTitleWithDocLink
                titleComponent={<ToolTitle tool={cecUtilization} preset={'large'} editable={!isDisabled} />}
              />

              {!cecUtilization.publicId && (
                <Box display="flex" justifyContent="flex-end">
                  <Tabs
                    orientation={'horizontal'}
                    variant={'scrollable'}
                    scrollButtons={'auto'}
                    allowScrollButtonsMobile={true}
                    value={activeTab}
                    onChange={handleChange}
                  >
                    <Tab label={<Trans>Subject</Trans>} id={'cec-subject'} />
                    <Tab label={<Trans>Diagram</Trans>} id={'cec-diagram'} />
                    <Tab label={<Trans>Conclusion</Trans>} id={'cec-conclusion'} />
                  </Tabs>
                </Box>
              )}
            </TitleContainer>
          </Grid>

          <Grid item xs={true}>
            {!cecUtilization.publicId && (
              <TabPanel index={0} value={activeTab} stretch>
                <ToolSubjectConclusion
                  onContentChange={subjectChangeHandler}
                  initialContent={cecUtilization?.subject}
                  disabled={isDisabled}
                  placeholder={'Describe the system that you are analyzing with cause & effect chain tool'}
                />
              </TabPanel>
            )}

            <TabPanel index={cecUtilization.publicId ? 0 : 1} value={activeTab} stretch>
              <Box className={styles.toolContainer}>
                {cecUtilization && (
                  <CauseAndEffectChainDiagram
                    projectId={projectId}
                    utilizationId={utilizationId}
                    nodes={cecUtilization?.diagramData?.nodes || CecPreset.nodes}
                    edges={cecUtilization?.diagramData?.edges || CecPreset.edges}
                    disabled={isDisabled}
                    changeCallback={updateData}
                    fitOnInit={activeTab === 1}
                    historyKeyboardControl={activeTab === 1}
                  />
                )}
              </Box>
            </TabPanel>

            {!cecUtilization.publicId && (
              <TabPanel index={2} value={activeTab} stretch>
                <ToolSubjectConclusion
                  onContentChange={conclusionChangeHandler}
                  initialContent={cecUtilization?.conclusion}
                  placeholder={'Describe your conclusion that resulted form this cause & effect chain tool'}
                  disabled={isDisabled}
                />
              </TabPanel>
            )}
          </Grid>
        </Grid>
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={projectId}
          utilization={cecUtilization}
          disabled={isDisabled}
          workingFlowProps={{ nextVariant: 'ideas' }}
        />
      </ContentFooter>
    </>
  );
};
