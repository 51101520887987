import React, { useRef, useState } from 'react';

interface ElementViewerContextProps {
  enabled: boolean;
  toggle: () => void;
  refresh: (delay?: boolean) => void;
  setRefresh: (func: () => void) => void;
}

export const ElementsViewerContext = React.createContext<ElementViewerContextProps>({
  enabled: false,
  toggle: () => {
    return;
  },
  refresh: () => {
    return;
  },
  setRefresh: () => {
    return;
  },
});

export const ElementsViewerProvider: React.FC = ({ children }) => {
  const [isViewerEnabled, setIsViewerEnabled] = useState(false);

  const refreshRef = useRef<() => void>();

  const toggle = () => {
    setIsViewerEnabled(currentState => !currentState);
  };

  const refresh = (delay?: boolean) => {
    if (refreshRef.current) {
      refreshRef.current();

      setTimeout(() => {
        if (delay) refresh();
      }, 0);
    }
  };

  const setRefresh = (ref: () => void) => {
    refreshRef.current = ref;
  };

  return (
    <ElementsViewerContext.Provider
      value={{
        enabled: isViewerEnabled,
        toggle,
        refresh,
        setRefresh,
      }}
    >
      {children}
    </ElementsViewerContext.Provider>
  );
};
