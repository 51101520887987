import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    'padding': 20,
    'background': pgColorScheme.background,
    'minHeight': '100%',

    '@media print': {
      padding: 0,
      background: pgColorScheme.white,
    },
  },
});
