import React from 'react';
import { Box, Divider, Paper, Typography, Alert } from '@mui/material';
import { Trans } from 'react-i18next';
import { pgColorScheme } from '../../../theme';
import { ApaAction, ApaUtilization } from '../../../models/tools/apa';
import { ApaActionCard } from '../../../components/apa-action-card/component';
import { useStyles } from './styles';

interface ApaViewerProps {
  utilization: ApaUtilization;
  apaActions: ApaAction[];
}

export const ApaViewer: React.FC<ApaViewerProps> = ({ utilization, apaActions }) => {
  const styles = useStyles();

  return (
    <>
      {!utilization.customer && !utilization.customerDescription && !apaActions?.length && (
        <Alert severity={'info'}>
          <Trans>Tool is empty</Trans>
        </Alert>
      )}

      {(utilization.customer || utilization.customerDescription) && (
        <>
          <Box mb={1}>
            <Typography variant={'subtitle2'} component={'span'}>
              <Trans>Customer definition</Trans>
            </Typography>
          </Box>

          {utilization.customer && (
            <Box mb={1}>
              <Typography variant={'caption'} style={{ color: pgColorScheme.gray }}>
                <Trans>Customer</Trans>
              </Typography>

              <Typography variant={'body1'} whiteSpace={'pre-line'}>
                {utilization.customer}
              </Typography>
            </Box>
          )}

          {utilization.customerDescription && (
            <Box mb={1}>
              <Typography variant={'caption'} style={{ color: pgColorScheme.gray }}>
                <Trans>Customer description</Trans>
              </Typography>

              <Typography variant={'body1'} whiteSpace={'pre-line'}>
                {utilization.customerDescription}
              </Typography>
            </Box>
          )}
        </>
      )}

      {!!apaActions?.length && (
        <>
          {(utilization.customer || utilization.customerDescription) && (
            <Box mt={2} mb={2}>
              <Divider />
            </Box>
          )}

          <Box mb={1}>
            <Typography variant={'subtitle2'} component={'span'}>
              <Trans>Customer actions</Trans>
            </Typography>
          </Box>

          <Box mb={1}>
            <Typography variant={'caption'} style={{ color: pgColorScheme.textGray }}>
              <Trans>Answer the question: What keeps him busy?</Trans>
            </Typography>
          </Box>

          {apaActions.map((item, key) => (
            <Box key={key} mb={2}>
              <ApaActionCard apaAction={item} finished={true} compact={true} />

              {(item.actionPurpose || item.preventingAction || item.customerNeeds) && (
                <Paper variant={'outlined'} className={styles.customerNeedsContainer}>
                  <Box p={2} pt={0}>
                    {item.actionPurpose && (
                      <Box pt={2}>
                        <Typography variant={'body2'} style={{ color: pgColorScheme.gray }}>
                          <Trans>Action purpose</Trans>:{' '}
                          <i>
                            <Trans>Why is the customer doing it? What is he trying to achieve doing it?</Trans>
                          </i>
                        </Typography>

                        <Typography variant={'body1'} whiteSpace={'pre-line'}>
                          {item.actionPurpose}
                        </Typography>
                      </Box>
                    )}

                    {item.preventingAction && (
                      <Box pt={2}>
                        <Typography variant={'body2'} style={{ color: pgColorScheme.gray }}>
                          <Trans>Action Preventing Action</Trans>:{' '}
                          <i>
                            <Trans>What should be done to prevent the customer from doing the action?</Trans>
                          </i>
                        </Typography>

                        <Typography variant={'body1'} whiteSpace={'pre-line'}>
                          {item.preventingAction}
                        </Typography>
                      </Box>
                    )}

                    {item.customerNeeds && (
                      <Box pt={2}>
                        <Typography variant={'body2'} style={{ color: pgColorScheme.gray }}>
                          <Trans>Customer needs</Trans>:{' '}
                          <i>
                            <Trans>How can we achieve it?</Trans>
                          </i>
                        </Typography>

                        <Typography variant={'body1'} whiteSpace={'pre-line'}>
                          {item.customerNeeds}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Paper>
              )}
            </Box>
          ))}
        </>
      )}
    </>
  );
};
