import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  button: {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'flex-start',
    'cursor': 'pointer',

    '&:hover': {
      '& $iconWrap': {
        background: pgColorScheme.darkOrange,
      },

      '& $label': {
        opacity: 1,
      },
    },

    '&._disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  },

  iconWrap: {
    'width': 38,
    'height': 38,
    'borderRadius': '50%',
    'background': pgColorScheme.orange,
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'flex': '0 0 auto',

    '& svg': {
      fill: pgColorScheme.white,
    },
  },

  label: {
    color: pgColorScheme.white,
    opacity: 0.4,
    paddingLeft: 10,
  },
});
