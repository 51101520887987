import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Grid, Tab, Tabs } from '@mui/material';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { ProjectSelector, ToolUtilizationSelector } from '../../project/store/selectors';
import { ToolTitle } from '../../tools/tool-title/component';
import { TitleContainer } from '../../react/modules/title-container/component';
import { TabPanel } from '../../react/elements/tab-panel/component';
import { ToolSubjectConclusion } from '../../tools/tool-subject-conclusion/component';
import { PfmUtilization } from '@priz/shared/src/models/tools/pfm';
import { PfmUtilizationActions } from '../store/actions';
import { PfmWorkspace } from '../pfm-workspace/component';
import { Trans } from 'react-i18next';
import { PfmSummary } from '@priz/shared/src/components/pfm-summary/component';
import { SfmUtilization } from '@priz/shared/src/models/tools/sfm';
import { SfmResultSelectors } from '../../sfm/store/selectors';
import { SfmUtilizationActions } from '../../sfm/store/actions';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { ToolUtilizationActions, ToolUtilizationActionType } from '../../project/store/actions/tools.actions';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { useToolTabs } from '../../tools/utils';
import { PfmTab } from '../store/model';

export interface PfmViewProps {
  utilizationId: number;
  projectId?: number;
}

export const PfmView: React.FC<PfmViewProps> = ({ projectId, utilizationId }) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useToolTabs(PfmTab.Process);

  const pfmUtilization = useSelector(ToolUtilizationSelector.getPfmUtilization(utilizationId));
  const allSfmUtilizationByParentId: SfmUtilization[] = useSelector(
    ToolUtilizationSelector.getAllByParentId(pfmUtilization?.id),
  );

  const sfmRanks = useSelector(SfmResultSelectors.getSfmRanksByParentUtilizationId(utilizationId));
  const isProjectLocked = useSelector(ProjectSelector.isProjectLockedById(projectId));
  const isUpdating = useSelector(ToolUtilizationSelector.isUpdatingByUtilizationId(utilizationId));
  const isPfmLoaded = useSelector(ToolUtilizationSelector.isLoadedByUtilizationId(utilizationId));
  const areAllToolsLoaded = useSelector(ToolUtilizationSelector.isLoaded(projectId));
  const isCompletenessUpdating = useSelector(
    ToolUtilizationSelector.isPendingByActionType(utilizationId, ToolUtilizationActionType.UpdateCompleteness),
  );

  const isDisabled = !!pfmUtilization?.completed || isCompletenessUpdating || isProjectLocked;

  useEffect(() => {
    if (pfmUtilization?.id && pfmUtilization.publicId) {
      setActiveTab(PfmTab.Model);
    }
  }, [pfmUtilization?.id]);

  useEffect(() => {
    dispatch(SfmUtilizationActions.calcSfmRanks(utilizationId, projectId));
  }, [allSfmUtilizationByParentId.filter(sfm => !!sfm.diagramData).length]);

  useEffect(() => {
    dispatch(ToolUtilizationActions.loadTools(projectId));
    dispatch(ToolUtilizationActions.loadTool(utilizationId, projectId));
  }, [utilizationId, projectId]);

  const doUpdateUtilization = (updatedUtilization: PfmUtilization) => {
    dispatch(PfmUtilizationActions.update(utilizationId, updatedUtilization, projectId));
  };

  const descriptionChangeHandler = (description: string) => {
    doUpdateUtilization({ ...pfmUtilization, subject: description });
  };

  const conclusionChangeHandler = (conclusion: string) => {
    doUpdateUtilization({ ...pfmUtilization, conclusion });
  };

  const toggleProcessFlowFinishedState = () => {
    doUpdateUtilization({ ...pfmUtilization, flowFinished: !pfmUtilization.flowFinished });
  };

  const handleChange = (_event, newValue: PfmTab) => {
    setActiveTab(newValue);
  };

  if (!pfmUtilization || !isPfmLoaded || !areAllToolsLoaded) {
    return <LoadingOverlay loading={true} backdropStyles={{ backgroundColor: 'transparent' }} />;
  }

  return (
    <>
      <ContentContainer display={'flex'} alignItems={'stretch'}>
        <Grid container direction={'column'} wrap={'nowrap'}>
          <Grid item xs={'auto'}>
            <TitleContainer>
              <PageTitleWithDocLink
                titleComponent={<ToolTitle tool={pfmUtilization} preset={'large'} editable={!isDisabled} />}
                docsUrl={'https://www.priz.guru/knowledge-base/process-functional-modeling/'}
              />

              <Box display="flex" justifyContent="flex-end">
                <Tabs
                  orientation="horizontal"
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile={true}
                  value={activeTab}
                  onChange={handleChange}
                >
                  {!pfmUtilization.publicId && <Tab value={PfmTab.Process} label={<Trans>Process</Trans>} />}

                  <Tab value={PfmTab.Model} label={<Trans>Model</Trans>} />
                  <Tab value={PfmTab.Summary} label={<Trans>Summary</Trans>} />

                  {!pfmUtilization.publicId && <Tab value={PfmTab.Conclusion} label={<Trans>Conclusion</Trans>} />}
                </Tabs>
              </Box>
            </TitleContainer>
          </Grid>

          <Grid item xs={true}>
            {!pfmUtilization.publicId && (
              <TabPanel index={PfmTab.Process} value={activeTab} stretch>
                <ToolSubjectConclusion
                  onContentChange={descriptionChangeHandler}
                  initialContent={pfmUtilization?.subject}
                  placeholder={'Describe the system that you are analyzing with this process functional modeling'}
                  disabled={isDisabled}
                />
              </TabPanel>
            )}

            <TabPanel index={PfmTab.Model} value={activeTab} stretch>
              <PfmWorkspace sfmRankMap={sfmRanks} utilization={pfmUtilization} disabled={isDisabled} />
            </TabPanel>

            <TabPanel index={PfmTab.Summary} value={activeTab} stretch>
              <PfmSummary
                sfmRankMap={sfmRanks}
                pfmUtilization={pfmUtilization}
                sfmUtilizationArray={allSfmUtilizationByParentId}
              />
            </TabPanel>

            {!pfmUtilization.publicId && (
              <TabPanel index={PfmTab.Conclusion} value={activeTab} stretch>
                <ToolSubjectConclusion
                  onContentChange={conclusionChangeHandler}
                  initialContent={pfmUtilization?.conclusion}
                  placeholder={'Describe your conclusion that resulted form this process functional modeling tool'}
                  disabled={isDisabled}
                />
              </TabPanel>
            )}
          </Grid>
        </Grid>
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={projectId}
          utilization={pfmUtilization}
          disabled={isDisabled}
          rightColContent={
            pfmUtilization &&
            !!pfmUtilization?.diagramData?.steps?.length &&
            activeTab === PfmTab.Model && (
              <Button
                variant={'pg_rounded'}
                color={'pg_blue_solid'}
                onClick={toggleProcessFlowFinishedState}
                disabled={isUpdating || isDisabled}
              >
                <Trans>{pfmUtilization.flowFinished ? 'Enable adding' : 'Disable adding'}</Trans>
              </Button>
            )
          }
          workingFlowProps={{ nextVariant: 'ideas' }}
        />
      </ContentFooter>
    </>
  );
};
