import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Box, BoxProps } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FieldTitle, HelperText } from '@priz/shared/src/components/form-elements';
import { ValidatorControllerProps, ReactHookFormElement, DefaultInput } from '@priz/shared/src/models/form';

type optionValue = string | number;

interface option {
  label: string;
  value: optionValue;
}

export type ReactHookFormRadioProps = ReactHookFormElement &
  DefaultInput &
  ValidatorControllerProps & {
    options: option[];
    row?: boolean;
    color?: 'primary' | 'secondary';
    helperTextProps?: BoxProps;
  };

export const ReactHookFormRadio: React.FC<ReactHookFormRadioProps> = ({
  name,
  control,
  fieldTitle,
  fieldTitleWrapperProps,
  options,
  helperText,
  wrapperProps,
  rules,
  disabled,
  row,
  color = 'secondary',
  helperTextProps,
}) => {
  return (
    <Box mb={3} {...wrapperProps}>
      <FieldTitle text={fieldTitle} {...fieldTitleWrapperProps} />

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <FormControl component="fieldset" disabled={disabled}>
              <RadioGroup value={value} onChange={onChange} row={row}>
                {options.map((option, key) => (
                  <FormControlLabel
                    key={key}
                    value={option.value}
                    label={option.label}
                    control={<Radio name={name} color={color} size={'small'} />}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            {(helperText || error?.message) && (
              <Box {...helperTextProps}>
                <HelperText error={error?.message} text={helperText} />
              </Box>
            )}
          </>
        )}
      />
    </Box>
  );
};
