import { Principle } from '@priz/shared/src/models/tools/forty-principles/principle';
import {
  DefaultEntityCollectionStatus,
  EntityCollectionStatus,
} from '@priz/shared/src/models/common/entity-collection-state';

export interface PrincipleList {
  principles: Principle[];
  statuses: EntityCollectionStatus;
}

export const DefaultPrincipleList: PrincipleList = {
  principles: [],
  statuses: DefaultEntityCollectionStatus,
};
