import { IToolUtilization, ToolUtilization } from '@priz/shared/src/models/tools';
import { CftDiagramData } from './cft-diagram-data';

export interface ICftUtilization extends IToolUtilization {
  diagramData?: CftDiagramData;
  dataVersion?: number;
}

export class CftUtilization extends ToolUtilization implements ICftUtilization {
  public diagramData?: CftDiagramData;
  public dataVersion?: number;

  constructor(json: ICftUtilization) {
    super(json);

    this.diagramData = json.diagramData;
    this.dataVersion = json.dataVersion;
  }
}
