import { IdRef } from '../../common/id-ref';
import { AuditableEntity, IAuditableEntity } from '../../common/auditable-entity';
import { Identifiable } from '../../common/entity-collection-state';

export interface IFiveWhysSolution extends IAuditableEntity, Identifiable {
  description: string;
  utilization: IdRef;
  cause?: IdRef;
  latestRankingScore?: number;
}

export class FiveWhysSolution extends AuditableEntity implements IFiveWhysSolution {
  public id: number;

  public description: string;
  public utilization: IdRef;
  public cause?: IdRef;
  public latestRankingScore?: number;

  constructor(json: IFiveWhysSolution) {
    super(json);

    this.id = json.id;
    this.description = json.description;
    this.utilization = json.utilization;
    this.cause = json.cause;
    this.latestRankingScore = json.latestRankingScore;
  }
}
