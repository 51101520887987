import React, { useContext } from 'react';
import { pgColorScheme } from '../../../theme';
import { Box } from '@mui/material';
import { FullscreenContainer } from '../../fullscreen-container/component';
import { CftUtilization } from '../../../models/tools/cft';
import { CftDiagram } from '../../cft/cft-diagram/component';
import { CftContext, CftContextProvider } from '../../cft/cft-context/component';
import { CftDataUtils } from '../../../utils/cft';
import { DiagramControls } from '../diagram-controls/component';

export interface CftViewerProps {
  utilization: CftUtilization;
  disableInteractive?: boolean;
}

export const CftViewerComponent: React.FC<CftViewerProps> = ({ utilization, disableInteractive }) => {
  const { fitContent } = useContext(CftContext);

  const nodes = CftDataUtils.resolveVersionNodes(utilization?.diagramData);
  const nodesMap = CftDataUtils.resolveNodesMap(nodes);

  return (
    <Box
      style={{
        width: '100%',
        height: '100mm',
        position: 'relative',
        background: pgColorScheme.workbenchBackground,
        boxShadow: `1000px 0 0 ${pgColorScheme.workbenchBackground} inset`,
        borderRadius: 10,
      }}
    >
      <FullscreenContainer
        style={{
          background: pgColorScheme.workbenchBackground,
          width: '100%',
          height: '100%',
        }}
        fullscreenDisabledStyle={{
          borderRadius: 'inherit',
        }}
        usePortal
      >
        <CftDiagram versionNodes={nodes} versionNodesMap={nodesMap} disableInteractive={disableInteractive} />

        {!disableInteractive && <DiagramControls onSetCenter={fitContent} />}
      </FullscreenContainer>
    </Box>
  );
};

export const CftViewer: React.FC<CftViewerProps> = props => {
  return (
    <CftContextProvider>
      <CftViewerComponent {...props} />
    </CftContextProvider>
  );
};
