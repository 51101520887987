import React from 'react';
import { alpha, Box, BoxProps, Grid, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useStyles } from './styles';
import { Trans } from 'react-i18next';
import { CheckCircleOutlineRounded } from '@mui/icons-material';
import { pgColorScheme } from '@priz/shared/src/theme';
import { TemplateCategory, TemplateOptionType, TemplateType } from '@priz/shared/src/models/template';
import { OptionsDescription, templateCategoryColorMap, templatesOptionsDescription } from '../data';
import { useTemplateOptionHandler } from '../utils';

interface TemplateOptionProps extends BoxProps {
  option: TemplateOptionType;
  templateType: TemplateType;
  templateCategory: TemplateCategory;
  projectId?: number;
  interactive?: boolean;
  done?: boolean;
}

export const TemplateOption: React.FC<TemplateOptionProps> = ({
  option,
  templateType,
  templateCategory,
  projectId,
  interactive,
  done,
  ...rest
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const isDownMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  const templateOptionHandler = useTemplateOptionHandler(option, projectId, done);
  const description = templatesOptionsDescription[templateType][templateCategory][option] as OptionsDescription;

  const clickHandler = () => {
    if (interactive && typeof projectId !== 'undefined') {
      templateOptionHandler();
    }
  };

  return (
    <Tooltip
      title={description?.full?.length ? <Trans>{description.full}</Trans> : ''}
      arrow={true}
      placement={'top'}
      disableInteractive={true}
    >
      <Box
        id={`${templateType}-${option}`}
        py={{ xs: 0.5, md: 1 }}
        px={{ xs: 1, md: 2 }}
        sx={{
          backgroundColor: done ? alpha(templateCategoryColorMap[templateCategory], 0.8) : pgColorScheme.transparent,
          borderColor: done ? pgColorScheme.transparent : templateCategoryColorMap[templateCategory],

          ['&:hover']: {
            backgroundColor: pgColorScheme.transparent,
            borderColor: templateCategoryColorMap[templateCategory],
          },
        }}
        className={[styles.root, interactive ? (done ? styles.done : styles.link) : styles.text].join(' ')}
        onClick={clickHandler}
        {...rest}
      >
        <Grid container flexWrap={'nowrap'} alignItems={'center'} spacing={1}>
          <Grid item>
            <Typography
              variant={isDownMdBreakpoint ? 'caption' : 'subtitle2'}
              sx={{ display: 'block', lineHeight: 1.2 }}
            >
              <Trans>{description.preview}</Trans>
            </Typography>
          </Grid>

          {done && (
            <Grid item>
              <CheckCircleOutlineRounded sx={{ display: 'block' }} />
            </Grid>
          )}
        </Grid>
      </Box>
    </Tooltip>
  );
};
