import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { pgColorScheme } from '../../../theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },

    tab: {
      'borderBottom': `1px solid ${pgColorScheme.lightGray}`,
      'color': pgColorScheme.darkText,
      'cursor': 'pointer',
      'display': 'block',
      'fontSize': 18,
      'lineHeight': '21px',
      'padding': '24px 0',
      'textAlign': 'center',
      'textDecoration': 'none',
      'textTransform': 'uppercase',
      'width': '50%',
      'transition': 'color .15s ease',

      '&:hover, &._active': {
        color: pgColorScheme.blue,
      },

      '&._active': {
        borderBottom: `2px solid ${pgColorScheme.blue}`,
      },
    },

    [theme.breakpoints.down('sm')]: {
      tab: {
        fontSize: 16,
        lineHeight: '18px',
      },
    },
  }),
);
