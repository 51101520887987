import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../../../store/app.state';
import { PaywallState } from '../model';

const paywallStateSelector = (state: AppState) => state.paywall;

const getLast: Selector<AppState, PaywallState> = createSelector(paywallStateSelector, paywall => paywall);

export const PaywallSelectors = {
  getLast,
};
