import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import { Box, Button, ButtonProps, Grid, Hidden, Link, Typography } from '@mui/material';
import { useStyles } from './styles';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { useForm } from 'react-hook-form';
import { ReactHookFormText, ReactHookFormImageDrop, ReactHookFormSelectSearch } from '../../react/form-elements';
import { validateOptions, validatorRules } from '@priz/shared/src/utils/form';
import { Project } from '@priz/shared/src/models/project';
import { ProjectActions } from '../store/actions/project.actions';
import { useQuery } from 'react-query';
import { PublicTopicsApi } from '../../topics/services';
import { Topic } from '@priz/shared/src/models/project';
import { ProjectSelector } from '../store/selectors';
import { Alert } from '@mui/material';
import { ProjectFlowService, UpdatePublicInfoProps } from '../services';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { pgColorScheme } from '@priz/shared/src/theme';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';

import { ReactComponent as CircleCheckIcon2 } from '../../../assets/icons/circle-check-2.svg';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';

export interface ProjectPublishProps {
  projectId: number;
}

interface KeywordSelectOption {
  value: string;
  label: string;
}

const Notice = () => {
  return (
    <Box px={1} minWidth={240}>
      <Typography variant={'body1'} component={'span'}>
        <b>
          <Trans>Notice</Trans>:
        </b>
        &nbsp;<Trans>The project and details will be available to all visitors of the PrizHub after publication</Trans>
      </Typography>
    </Box>
  );
};

const resolveTopicsSelectOptions = (topics: Topic[]): { value: number; label: string }[] => {
  return topics.map(topic => ({ value: topic.id, label: topic.label }));
};

const resolvePosterProps = (
  poster: File | string,
  posterKey: string,
  originalPosterKey: string,
): {
  poster: File | null;
  posterKey: string | null;
  originalPosterKey: string | null;
} => {
  return typeof poster === 'string'
    ? {
        poster: null,
        posterKey,
        originalPosterKey,
      }
    : {
        poster,
        posterKey: null,
        originalPosterKey: null,
      };
};

const optionsToKeywords = (options: KeywordSelectOption[]): string[] => {
  return options.map(option => option.label);
};

const keywordsToOptions = (keywords: string[]): KeywordSelectOption[] => {
  return keywords.map(keyword => ({ value: keyword, label: keyword }));
};

// const resolvePublicScopesSelectValue = (scopesMap: ScopesMap): PublicScope[] => {
//   const publicScopes = [];
//
//   Object.keys(scopesMap).forEach(scope => {
//     if (scopesMap[scope]) publicScopes.push(scope)
//   })
//
//   return publicScopes
// }

interface FormValues {
  publicTitle: string;
  publicDescription: string;
  topicId: number;
  keywords: KeywordSelectOption[];
  poster: File | string;
  posterKey: string;
  // publicScopes: PublicScope[];
}

interface UpdateData extends FormValues {
  originalPosterKey: string;
}

const resolveUpdateProps = (data: UpdateData, open: boolean, draft: boolean): UpdatePublicInfoProps => {
  const { keywords, poster, posterKey, originalPosterKey, ...rest } = data;

  return {
    open,
    draft,
    keywords: optionsToKeywords(keywords),
    ...rest,
    ...resolvePosterProps(poster, posterKey, originalPosterKey),
  };
};

const resolveFormValues = (project: Project): FormValues => {
  return {
    publicTitle: project?.publicTitle || project?.title || '',
    publicDescription: project?.publicDescription || '',
    topicId: project?.topic?.id || null,
    keywords: project?.keywords ? keywordsToOptions(project.keywords) : [],
    poster: project?.posterUrl || null,
    posterKey: project?.posterKey || null,
    // publicScopes: project?.publicScopes
    //   ? resolvePublicScopesSelectValue(project.publicScopes)
    //   : [
    //     PublicScope.Overview,
    //     PublicScope.Team,
    //     PublicScope.ProblemStatement,
    //     PublicScope.Tools,
    //     PublicScope.Ideas,
    //     PublicScope.Solution,
    //   ],
  };
};

export const ProjectPublish: React.FC<ProjectPublishProps> = ({ projectId }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const project = useSelector(ProjectSelector.getById(projectId));
  const isProjectOpen = ProjectFlowService.isProjectOpen(project);
  // const isProjectCompleted = ProjectFlowService.isProjectCompleted(project);
  const isAnyProjectSaving = useSelector(ProjectSelector.isAnyProjectSaving);

  const [isPublishDialogOpen, setIsPublishDialogOpen] = useState(false);
  const [projectIsPublished, setProjectIsPublished] = useState(false);

  const { handleSubmit, control, setValue, resetField, setError, clearErrors, getValues, reset, formState } = useForm({
    shouldFocusError: false,
    defaultValues: resolveFormValues(project),
  });

  const { isLoading, data } = useQuery(['topics', 0], () => PublicTopicsApi.list(0, 50), {
    keepPreviousData: true,
  });

  useEffect(() => {
    reset(resolveFormValues(project));
  }, [project]);

  useEffect(() => {
    if (isProjectOpen && projectIsPublished) {
      openPublishDialog();
    }
  }, [isProjectOpen, projectIsPublished]);

  const unpublish = () => {
    setProjectIsPublished(false);

    dispatch(
      ProjectActions.updatePublicInfo(projectId, {
        open: false,
        draft: false,
      }),
    );
  };

  const publish = () => {
    setProjectIsPublished(true);

    handleSubmit(formData => {
      dispatch(
        ProjectActions.updatePublicInfo(
          projectId,
          resolveUpdateProps({ ...formData, originalPosterKey: project.originalPosterKey }, true, false),
        ),
      );
    })();
  };

  const saveDraft = () => {
    dispatch(
      ProjectActions.updatePublicInfo(
        projectId,
        resolveUpdateProps({ ...getValues(), originalPosterKey: project.originalPosterKey }, false, true),
      ),
    );
  };

  const openPublishDialog = () => {
    setIsPublishDialogOpen(true);
  };

  const closePublishDialog = () => {
    setIsPublishDialogOpen(false);
  };

  const renderPublishButton = (props?: ButtonProps) => {
    return (
      <Button
        variant={'pg_rounded'}
        color={'pg_orange_solid'}
        onClick={publish}
        disabled={isAnyProjectSaving}
        {...props}
      >
        <Trans>{project.open ? 'Update' : 'Publish'}</Trans>
      </Button>
    );
  };

  const renderUnpublishButton = (props?: ButtonProps) => {
    return (
      <Button variant={'text'} color={'error'} onClick={unpublish} disabled={isAnyProjectSaving} {...props}>
        <Trans>Unpublish</Trans>
      </Button>
    );
  };

  const renderDraftButton = (props?: ButtonProps) => {
    return (
      <Button
        variant={'pg_rounded'}
        color={'pg_orange_outlined'}
        onClick={saveDraft}
        disabled={isAnyProjectSaving}
        {...props}
      >
        <Trans>Save a draft</Trans>
      </Button>
    );
  };

  if (isLoading) {
    return <LoadingOverlay loading={true} backdropStyles={{ backgroundColor: 'transparent' }} />;
  }

  return (
    <>
      <ContentContainer>
        <PageTitleWithDocLink
          title={
            <>
              <Trans>Publish the project on</Trans>&nbsp;
              <Link href={'https://hub.priz.guru/'} color={'primary'} underline={'none'} target={'_blank'}>
                PrizHub
              </Link>
            </>
          }
        />

        <Grid container>
          <Grid item xs={true}>
            {/* TODO: later */}
            {/*{!isProjectOpen && isProjectCompleted && (*/}
            {/*  <Box mb={4}>*/}
            {/*    <Alert severity={'info'}>*/}
            {/*      <Typography variant={'h6'} component={'span'}>*/}
            {/*        <Trans>Your project is completed. Publish it on the PrizHub and get advantages</Trans>*/}
            {/*      </Typography>*/}

            {/*      <ol>*/}
            {/*        <li>*/}
            {/*          <Trans>Get unlimited numbers of perceptions and project ideas on Free Plan</Trans>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*          <Trans>Upgrade yourself and your team in the community</Trans>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*          <Trans>Involve investors, followers and contributors to your projects</Trans>*/}
            {/*        </li>*/}
            {/*      </ol>*/}
            {/*    </Alert>*/}
            {/*  </Box>*/}
            {/*)}*/}

            {isProjectOpen && (
              <Box mb={4}>
                <Alert severity={'success'}>
                  <Typography variant={'subtitle2'} component={'div'}>
                    <Trans>Your project is published on PrizHub</Trans>
                  </Typography>

                  <div>
                    <Trans>
                      You can publish any changes then you ready, or let us automatically publish them after some time
                      of inactivity.
                    </Trans>
                  </div>

                  <div>
                    <Trans>
                      You can unpublished your project at any moment. Click «Unpublish» button at the bottom to do so.
                    </Trans>
                  </div>
                </Alert>
              </Box>
            )}

            {formState.isDirty && (
              <Box mb={4}>
                <Alert severity={'warning'}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={true}>
                      <Trans>You have unsaved changes. If you leave this page you will lose this changes.</Trans>
                    </Grid>

                    <Grid item xs={12} lg={'auto'}>
                      <Grid container spacing={1}>
                        <Grid item>
                          {renderPublishButton({ variant: 'outlined', color: 'primary', size: 'pg_extra_small' })}
                        </Grid>

                        <Grid item>
                          {renderDraftButton({ variant: 'text', color: 'primary', size: 'pg_extra_small' })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Alert>
              </Box>
            )}

            <Box mb={3}>
              <Typography variant={'h6'} component={'span'}>
                <Trans>General info</Trans>
              </Typography>
            </Box>

            <ReactHookFormText
              fieldTitle={'Public title'}
              name={'publicTitle'}
              control={control}
              rules={{
                ...validatorRules.required(),
              }}
              disabled={isAnyProjectSaving}
            />

            <ReactHookFormText
              fieldTitle={'Description'}
              name={'publicDescription'}
              control={control}
              multiline={true}
              minRows={4}
              rules={{
                validate: {
                  ...validateOptions.hasText('Field is required'),
                },
              }}
              disabled={isAnyProjectSaving}
            />

            <ReactHookFormSelectSearch
              fieldTitle="Topic"
              name={'topicId'}
              control={control}
              rules={{
                ...validatorRules.required(),
              }}
              options={resolveTopicsSelectOptions(data.topics)}
              disabled={isAnyProjectSaving}
            />

            {/*<ReactHookFormSelectSearch*/}
            {/*  title={'Key words'}*/}
            {/*  name={'keywords'}*/}
            {/*  control={control}*/}
            {/*  isMulti={true}*/}
            {/*  creatable={true}*/}
            {/*  options={[]}*/}
            {/*  disabled={isAnyProjectSaving}*/}
            {/*/>*/}

            <Box mb={3}>
              <Typography variant={'h6'} component={'span'}>
                <Trans>Add a cover</Trans>
              </Typography>
            </Box>

            <ReactHookFormImageDrop
              name={'poster'}
              control={control}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
              getValues={getValues}
              resetField={resetField}
              rules={{
                ...validatorRules.required('Image is required'),
              }}
              wrapperProps={{ mb: 2 }}
              disabled={isAnyProjectSaving}
              maxFileSizeInBytes={1024 * 1024 * 2}
            />

            <Box mb={5}>
              <Alert severity={'info'}>
                <Trans>Looking for nice inspirational images? You can find them at</Trans>{' '}
                <Link href={'https://pixabay.com/'} target={'_blank'} color={'primary'} underline={'hover'}>
                  Pixabay
                </Link>
                .
              </Alert>
            </Box>

            {/* TODO: later */}
            {/*<Box mb={3}>*/}
            {/*  <Typography variant={'h6'} component={'span'}>*/}
            {/*    <Trans>What would you like to publish?</Trans>*/}
            {/*  </Typography>*/}
            {/*</Box>*/}

            {/*<ReactHookFormMultiCheckbox*/}
            {/*  name={'publicScopes'}*/}
            {/*  control={control}*/}
            {/*  setValue={setValue}*/}
            {/*  row={true}*/}
            {/*  disabled={isAnyProjectSaving}*/}
            {/*  options={[*/}
            {/*    {*/}
            {/*      label: PublicScopeLabelMap[PublicScope.Overview],*/}
            {/*      value: PublicScope.Overview,*/}
            {/*    },*/}
            {/*    {*/}
            {/*      label: PublicScopeLabelMap[PublicScope.Team],*/}
            {/*      value: PublicScope.Team,*/}
            {/*    },*/}
            {/*    {*/}
            {/*      label: PublicScopeLabelMap[PublicScope.ProblemStatement],*/}
            {/*      value: PublicScope.ProblemStatement,*/}
            {/*    },*/}
            {/*    {*/}
            {/*      label: PublicScopeLabelMap[PublicScope.Tools],*/}
            {/*      value: PublicScope.Tools,*/}
            {/*    },*/}
            {/*    {*/}
            {/*      label: PublicScopeLabelMap[PublicScope.Ideas],*/}
            {/*      value: PublicScope.Ideas,*/}
            {/*    },*/}
            {/*    {*/}
            {/*      label: PublicScopeLabelMap[PublicScope.Solution],*/}
            {/*      value: PublicScope.Solution,*/}
            {/*    },*/}
            {/*  ]}*/}
            {/*/>*/}
          </Grid>

          <Hidden mdDown>
            <Grid item>
              <Box
                ml={{ xs: 0, sm: 2, md: 3, lg: 6, xl: 13 }}
                mr={{ xs: 0, sm: 1, md: 2, lg: 4, xl: 8 }}
                className={styles.rightPane}
              />
            </Grid>
          </Hidden>
        </Grid>
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={projectId}
          leftColCollapsingContent={<Notice />}
          rightColContent={renderPublishButton()}
          rightColCollapsingContent={
            <>
              {project.open && renderUnpublishButton()}
              {renderDraftButton()}
            </>
          }
          hideWorkingFlowButtons
        />
      </ContentFooter>

      <PgConfirmationDialog
        isOpen={isPublishDialogOpen}
        showOkButton={false}
        cancelButtonText={<Trans>Ok, I got it</Trans>}
        confirmContent={
          <Trans>
            You can continue editing the project. The changes can be published at any time of your convenience. If you
            decide to not publish the changes manually, they are going to be published automatically 4 hours after the
            last change.
          </Trans>
        }
        confirmTitle={
          <>
            <Box mb={1}>
              <CircleCheckIcon2 width={53} height={53} fill={pgColorScheme.blueSuperLight} />
            </Box>

            <Typography variant={'h5'} component={'span'}>
              <Trans>This project is published</Trans>
            </Typography>
          </>
        }
        onClose={closePublishDialog}
      />
    </>
  );
};
