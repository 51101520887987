import { FluxStandardAction } from 'flux-standard-action';
import { createPgAction } from '../../../shared/store/action-creator';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import {
  AssistanceType,
  AssistantHint,
  CecCausesResponse,
  CompletenessFeedback,
  DisadvantagesResponse,
  FiveWhysCausesResponse,
  FiveWhysSolutionsResponse,
  GapsResponse,
  IfrsResponse,
  ProblemStatementsResponse,
  ToolSuggestionsResponse,
} from '../model';
import { AssistantApi } from '../../api/assistant.api';
import { ToolType } from '@priz/shared/src/models/tools';
import { IdeaParameterResponse } from '../model/idea-parameter-response';
import { IdeaParameterType } from '@priz/shared/src/models/idea';

export enum AssistantActionType {
  GetHint = 'assistance/hint',
  GetProjectFeedback = 'assistance/project/feedback',
  GetToolSuggestionsHint = 'assistance/tool-suggestions',
  SetActive = 'assistance/set-active',
}

export interface AssistantActionProps {
  utilizationId?: number;
  causeId?: number;
  visNodeId?: string | number;
  apaActionId?: number;
  ideaId?: number;
  ideaParameterType?: IdeaParameterType;
}

export interface AssistantActionMeta extends AssistantActionProps {
  assistance: AssistanceType;
  assistantId: string;
  projectId?: number;
}

export type AssistantActionPayload =
  | AssistantHint
  | DisadvantagesResponse
  | ProblemStatementsResponse
  | CompletenessFeedback
  | ToolSuggestionsResponse
  | CecCausesResponse
  | FiveWhysCausesResponse
  | FiveWhysSolutionsResponse
  | IdeaParameterResponse
  | IfrsResponse
  | GapsResponse;

export type AssistantAction = FluxStandardAction<string, AssistantActionPayload, AssistantActionMeta>;

const setActiveAssistant = createPgAction(
  AssistantActionType.SetActive,
  () => new Promise(resolve => resolve({})),
  (assistantId: string) => ({ assistantId }),
);

// project

const getCurrentSituationHint = createPgAction(
  AssistantActionType.GetHint,
  AssistantApi.getCurrentSituationHint,
  (projectId: number, assistance: AssistanceType, assistantId: string) => ({ projectId, assistance, assistantId }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      assistance: meta.assistance,
    });
  },
);

const getProjectDescriptionFeedbackHint = createPgAction(
  AssistantActionType.GetProjectFeedback,
  AssistantApi.getDescriptionFeedback,
  (projectId: number, assistance: AssistanceType, assistantId: string) => ({ projectId, assistance, assistantId }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      assistance: meta.assistance,
    });
  },
);

const getCurrentSituationFeedback = createPgAction(
  AssistantActionType.GetProjectFeedback,
  AssistantApi.getCurrentSituationFeedback,
  (projectId: number, assistance: AssistanceType, assistantId: string) => ({ projectId, assistance, assistantId }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      assistance: meta.assistance,
    });
  },
);

const getDisadvantagesFeedback = createPgAction(
  AssistantActionType.GetProjectFeedback,
  AssistantApi.getDisadvantagesFeedback,
  (projectId: number, assistance: AssistanceType, assistantId: string) => ({ projectId, assistance, assistantId }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      assistance: meta.assistance,
    });
  },
);

const getProblemStatementFeedback = createPgAction(
  AssistantActionType.GetProjectFeedback,
  AssistantApi.getProblemStatementFeedback,
  (projectId: number, assistance: AssistanceType, assistantId: string) => ({ projectId, assistance, assistantId }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      assistance: meta.assistance,
    });
  },
);

const getIdealFinalResultHint = createPgAction(
  AssistantActionType.GetProjectFeedback,
  AssistantApi.getIdealFinalResultHint,
  (projectId: number, assistance: AssistanceType, assistantId: string) => ({ projectId, assistance, assistantId }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      assistance: meta.assistance,
    });
  },
);

const getIdealFinalResultFeedback = createPgAction(
  AssistantActionType.GetProjectFeedback,
  AssistantApi.getIdealFinalResultFeedback,
  (projectId: number, assistance: AssistanceType, assistantId: string) => ({ projectId, assistance, assistantId }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      assistance: meta.assistance,
    });
  },
);

const getGapsHint = createPgAction(
  AssistantActionType.GetProjectFeedback,
  AssistantApi.getGapsHint,
  (projectId: number, assistance: AssistanceType, assistantId: string) => ({ projectId, assistance, assistantId }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      assistance: meta.assistance,
    });
  },
);

const getGapsFeedback = createPgAction(
  AssistantActionType.GetProjectFeedback,
  AssistantApi.getGapsFeedback,
  (projectId: number, assistance: AssistanceType, assistantId: string) => ({ projectId, assistance, assistantId }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      assistance: meta.assistance,
    });
  },
);

const getToolSuggestionsHint = createPgAction(
  AssistantActionType.GetToolSuggestionsHint,
  AssistantApi.getToolSuggestionsHint,
  (projectId: number, assistance: AssistanceType, assistantId: string) => ({ projectId, assistance, assistantId }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      assistance: meta.assistance,
    });
  },
);

const getDisadvantagesHint = createPgAction(
  AssistantActionType.GetHint,
  AssistantApi.getDisadvantagesHint,
  (projectId: number, assistance: AssistanceType, assistantId: string) => ({ projectId, assistance, assistantId }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      assistance: meta.assistance,
    });
  },
);

const getProblemStatementHint = createPgAction(
  AssistantActionType.GetHint,
  AssistantApi.getProblemStatementHint,
  (projectId: number, assistance: AssistanceType, assistantId: string) => ({ projectId, assistance, assistantId }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      assistance: meta.assistance,
    });
  },
);

const getAssistantIdeaParameterHint = createPgAction(
  AssistantActionType.GetHint,
  AssistantApi.getAssistantIdeaParameterHint,
  (
    projectId: number,
    ideaId: number,
    ideaParameterType: IdeaParameterType,
    assistance: AssistanceType,
    assistantId: string,
  ) => ({
    projectId,
    ideaId,
    ideaParameterType,
    assistance,
    assistantId,
  }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      assistance: meta.assistance,
    });
  },
);

// tools

const getFiveWhysCauseHint = createPgAction(
  AssistantActionType.GetHint,
  AssistantApi.getFiveWhysCauseHint,
  (utilizationId: number, causeId: number, assistance: AssistanceType, assistantId: string) => ({
    utilizationId,
    causeId,
    assistance,
    assistantId,
  }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      utilization_id: meta.utilizationId,
      tool_type: ToolType.FIVE_WHYS,
      assistance: meta.assistance,
    });
  },
);

const getFiveWhysCauseSolutionHint = createPgAction(
  AssistantActionType.GetHint,
  AssistantApi.getFiveWhysCauseSolutionHint,
  (utilizationId: number, causeId: number, assistance: AssistanceType, assistantId: string) => ({
    utilizationId,
    causeId,
    assistance,
    assistantId,
  }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      utilization_id: meta.utilizationId,
      tool_type: ToolType.FIVE_WHYS,
      assistance: meta.assistance,
    });
  },
);

const getCecCauseHint = createPgAction(
  AssistantActionType.GetHint,
  AssistantApi.getCecCauseHint,
  (utilizationId: number, causeId: number, assistance: AssistanceType, assistantId: string) => ({
    utilizationId,
    causeId,
    assistance,
    assistantId,
  }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      utilization_id: meta.utilizationId,
      tool_type: ToolType.CEC,
      assistance: meta.assistance,
    });
  },
);

const getApaCustomerDescriptionHint = createPgAction(
  AssistantActionType.GetHint,
  AssistantApi.getApaCustomerDescriptionHint,
  (utilizationId: number, assistance: AssistanceType, assistantId: string) => ({
    utilizationId,
    assistance,
    assistantId,
  }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      utilization_id: meta.utilizationId,
      tool_type: ToolType.APA,
      assistance: meta.assistance,
    });
  },
);

const getApaCustomerActionsHint = createPgAction(
  AssistantActionType.GetHint,
  AssistantApi.getApaCustomerActionsHint,
  (utilizationId: number, assistance: AssistanceType, assistantId: string) => ({
    utilizationId,
    assistance,
    assistantId,
  }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      utilization_id: meta.utilizationId,
      tool_type: ToolType.APA,
      assistance: meta.assistance,
    });
  },
);

const getApaActionPurposeHint = createPgAction(
  AssistantActionType.GetHint,
  AssistantApi.getApaActionPurposeHint,
  (utilizationId: number, apaActionId: number, assistance: AssistanceType, assistantId: string) => ({
    utilizationId,
    apaActionId,
    assistance,
    assistantId,
  }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      utilization_id: meta.utilizationId,
      tool_type: ToolType.APA,
      assistance: meta.assistance,
    });
  },
);

const getApaPreventingActionHint = createPgAction(
  AssistantActionType.GetHint,
  AssistantApi.getApaPreventingActionHint,
  (utilizationId: number, apaActionId: number, assistance: AssistanceType, assistantId: string) => ({
    utilizationId,
    apaActionId,
    assistance,
    assistantId,
  }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      utilization_id: meta.utilizationId,
      tool_type: ToolType.APA,
      assistance: meta.assistance,
    });
  },
);

const getApaCustomerNeedsHint = createPgAction(
  AssistantActionType.GetHint,
  AssistantApi.getApaCustomerNeedsHint,
  (utilizationId: number, apaActionId: number, assistance: AssistanceType, assistantId: string) => ({
    utilizationId,
    apaActionId,
    assistance,
    assistantId,
  }),
  (meta: AssistantActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ASSISTANCE_ASKED, {
      project_id: meta.projectId,
      utilization_id: meta.utilizationId,
      tool_type: ToolType.APA,
      assistance: meta.assistance,
    });
  },
);

export const AssistantActions = {
  setActiveAssistant,

  // project
  getDisadvantagesHint,
  getProblemStatementHint,
  getCurrentSituationHint,
  getProjectDescriptionFeedbackHint,
  getCurrentSituationFeedback,
  getDisadvantagesFeedback,
  getIdealFinalResultHint,
  getIdealFinalResultFeedback,
  getGapsHint,
  getGapsFeedback,
  getProblemStatementFeedback,
  getToolSuggestionsHint,
  getAssistantIdeaParameterHint,

  // tools
  getFiveWhysCauseHint,
  getFiveWhysCauseSolutionHint,
  getCecCauseHint,
  getApaCustomerActionsHint,
  getApaCustomerDescriptionHint,
  getApaActionPurposeHint,
  getApaPreventingActionHint,
  getApaCustomerNeedsHint,
};
