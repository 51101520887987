import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  readOnly: {
    '& .ql-toolbar': {
      pointerEvents: 'none',
      opacity: 0.3,
    },
  },

  disableUndo: {
    '& .ql-undo': {
      pointerEvents: 'none',
      opacity: 0.3,
    },
  },

  disableRedo: {
    '& .ql-redo': {
      pointerEvents: 'none',
      opacity: 0.3,
    },
  },

  quillContainer: {
    '& .ql-toolbar.ql-snow': {
      border: 0,
      padding: 0,
    },

    '&._disabled': {
      opacity: 0.3,
      pointerEvents: 'none',
    },
  },

  quill: {
    '& .ql-container': {
      'borderColor': pgColorScheme.lightGray,

      '&:hover': {
        borderColor: pgColorScheme.placeholder,
      },
    },

    '&._error:not(:hover) .ql-container': {
      borderColor: pgColorScheme.coral,
    },
  },
});
