import { IdRef } from '../common/id-ref';
import { AuditableEntity, IAuditableEntity } from '../common/auditable-entity';
import { Identifiable } from '../common/entity-collection-state';
import { LicenseType } from './license-type.enum';
import { PlanLevel } from './plan-level.enum';
import { FinancialsManagementType } from './financials-management-type.enum';
import { SubscriptionStatus } from './subscription-status.enum';

export interface IWorkspace extends IAuditableEntity, Identifiable {
  name: string;
  type: PlanLevel;
  billingEmail: string;
  billingContact?: string;
  stripeCustomerId?: string;

  stripeSubscriptionId?: string;

  plan?: IdRef;
  licenseType: LicenseType;
  licenseExpiration?: Date;

  useExternalFileViewer?: boolean;
  useAiAssistant?: boolean;
  licensedSeats?: number;

  financialsManagementType: FinancialsManagementType;
  subscriptionStatus: SubscriptionStatus;

  workspaceMemberships: IdRef[];

  allowToUpdateMfa: boolean;
  mfa: boolean;
}

export class Workspace extends AuditableEntity implements IWorkspace {
  public id: number;
  public name: string;
  public type: PlanLevel;
  public billingEmail: string;
  public billingContact?: string;
  public stripeCustomerId?: string;
  public stripeSubscriptionId?: string;
  public plan?: IdRef;
  public licenseType = LicenseType.Standard;
  public licenseExpiration;
  public useExternalFileViewer?: boolean;
  public useAiAssistant?: boolean;
  public licensedSeats?: number;
  public financialsManagementType: FinancialsManagementType = FinancialsManagementType.Self;
  public subscriptionStatus: SubscriptionStatus;
  public allowToUpdateMfa: boolean;
  public mfa: boolean;
  public workspaceMemberships: IdRef[];

  constructor(json: IWorkspace) {
    super(json);
    this.id = json.id;
    this.name = json.name;
    this.type = json.type;
    this.billingEmail = json.billingEmail;
    this.licenseType = json.licenseType;
    this.licenseExpiration = json.licenseExpiration && new Date(json.licenseExpiration);
    this.useExternalFileViewer = json.useExternalFileViewer;
    this.useAiAssistant = json.useAiAssistant;
    this.licensedSeats = json.licensedSeats;
    this.financialsManagementType = json.financialsManagementType;
    this.subscriptionStatus = json.subscriptionStatus;
    this.allowToUpdateMfa = json.allowToUpdateMfa;
    this.mfa = json.mfa;
    this.workspaceMemberships = json.workspaceMemberships;

    if (json.plan) this.plan = json.plan;
    if (json.billingContact) this.billingContact = json.billingContact;
    if (json.stripeCustomerId) this.stripeCustomerId = json.stripeCustomerId;
    if (json.stripeSubscriptionId) this.stripeSubscriptionId = json.stripeSubscriptionId;
  }
}
