// import {endOfMonth, endOfWeek, isAfter, isBefore, isToday} from "date-fns";
import { Attendee, Meeting } from '../store/model';
import {
  MeetingStatus,
  AttendeeRoleFilterType,
  MeetingsPeriodFilterType,
  MeetingStatusFilterType,
} from '@priz/shared/src/models/meetings';
import moment, { Moment } from 'moment-timezone';

type AttendeeRequestsStatusesMap = { [key: number]: Attendee };

export const getAttendeeRequestsMap = (attendees: Attendee[]): AttendeeRequestsStatusesMap => {
  return attendees.reduce((obj: AttendeeRequestsStatusesMap, item: Attendee) => {
    obj[item.meeting.id] = item;
    return obj;
  }, {});
};

export const checkIsDateMatchPeriod = (filterType: MeetingsPeriodFilterType, date?: Moment): boolean => {
  const now = moment();
  const isToday = date?.isSame(moment(), 'day');
  const isNotPast = isToday || date?.isAfter(now);

  if (filterType === MeetingsPeriodFilterType.All) return true;
  if (filterType === MeetingsPeriodFilterType.Day) return isToday;
  if (filterType === MeetingsPeriodFilterType.Week) return isNotPast && date?.isBefore(now.endOf('week'));
  if (filterType === MeetingsPeriodFilterType.Month) return isNotPast && date?.isBefore(now.endOf('month'));
};

export const checkIsMeetingMatchStatus = (
  filterType: MeetingStatusFilterType,
  isLead: boolean,
  status?: string,
): boolean => {
  const hideMeeting = status === MeetingStatus.Draft && !isLead;
  return !hideMeeting && (filterType === MeetingStatusFilterType.All || filterType === status);
};

export const checkIsAttendeeMatchRole = (
  filterType: AttendeeRoleFilterType,
  isLead: boolean,
  attendee?: Attendee,
): boolean => {
  const requested = isLead ? true : !!attendee;
  const showAll = filterType === AttendeeRoleFilterType.All;
  const roleMatched = filterType === (attendee?.status as string);
  const leadRoleMatched = filterType === AttendeeRoleFilterType.Lead && isLead;
  const awaitingResponse =
    filterType === AttendeeRoleFilterType.AwaitingResponse && attendee && !attendee.status && !isLead;

  return requested && (showAll || roleMatched || leadRoleMatched || awaitingResponse);
};

export const filterAwaitingResponseMeetings = (
  meetings: Meeting[],
  attendeeRequests: Attendee[],
  currentUserId: number,
): Meeting[] => {
  const attendeeRequestsMap = getAttendeeRequestsMap(attendeeRequests);

  return meetings.filter(item => {
    const isLead = item.createdBy.id === currentUserId;

    return (
      !(item.status === MeetingStatus.Draft && !isLead) &&
      checkIsAttendeeMatchRole(AttendeeRoleFilterType.AwaitingResponse, isLead, attendeeRequestsMap[item.id])
    );
  });
};

export const filterMeetings = (
  meetings: Meeting[],
  attendeeRequests: Attendee[],
  currentUserId: number,
  period: MeetingsPeriodFilterType,
  meetingStatus: MeetingStatusFilterType,
  attendeeRole: AttendeeRoleFilterType,
): Meeting[] => {
  const attendeeRequestsMap = getAttendeeRequestsMap(attendeeRequests);

  return meetings.filter(item => {
    const isLead = item.createdBy.id === currentUserId;

    return (
      checkIsDateMatchPeriod(period, item.date) &&
      checkIsMeetingMatchStatus(meetingStatus, isLead, item.status) &&
      checkIsAttendeeMatchRole(attendeeRole, isLead, attendeeRequestsMap[item.id])
    );
  });
};
