import { FluxStandardAction } from 'flux-standard-action';
import { createPgAction } from '../../../shared/store/action-creator';
import { ApaAction, IApaAction } from '@priz/shared/src/models/tools/apa';
import { ApaActionApi } from '../../service';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { ToolType } from '@priz/shared/src/models/tools';

export enum ApaActionActionType {
  FetchAll = 'apa-action/fetchAll',
  Fetch = 'apa-action/fetch',
  Create = 'apa-action/create',
  Update = 'apa-action/update',
  ApaAction_Delete = 'apa-action/delete',
  Complete = 'apa-action/complete',
}

type ApaActionActionPayload = IApaAction | IApaAction[];

interface ApaActionMeta {
  action?: ApaAction;
  projectId?: number;
  utilizationId?: number;
  actionId?: number;
}

export type ApaActionAction = FluxStandardAction<string, ApaActionActionPayload, ApaActionMeta>;

const getAll = createPgAction(
  ApaActionActionType.FetchAll,
  ApaActionApi.getAll,
  (utilizationId: number, projectId?: number) => ({ projectId, utilizationId }),
  undefined,
);

const get = createPgAction(
  ApaActionActionType.Fetch,
  ApaActionApi.get,
  (utilizationId: number, actionId: number, projectId?: number) => ({
    actionId,
    projectId,
    utilizationId,
  }),
);

const create = createPgAction(
  ApaActionActionType.Create,
  ApaActionApi.create,
  (utilizationId: number, customerAction: string, projectId?: number) => ({
    customerAction,
    projectId,
    utilizationId,
  }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.APA,
      utilization_id: utilizationId,
    });
  },
);

const update = createPgAction(
  ApaActionActionType.Update,
  ApaActionApi.update,
  (utilizationId: number, apaAction: ApaAction, projectId?: number) => ({ projectId, utilizationId, apaAction }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.APA,
      utilization_id: utilizationId,
    });
  },
);

const complete = createPgAction(
  ApaActionActionType.Complete,
  ApaActionApi.complete,
  (utilizationId: number, actionId: number, projectId?: number) => ({
    actionId,
    projectId,
    utilizationId,
  }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.APA,
      utilization_id: utilizationId,
    });
  },
);

const doDelete = createPgAction(
  ApaActionActionType.ApaAction_Delete,
  ApaActionApi.delete,
  (utilizationId: number, actionId: number, projectId?: number) => ({
    actionId,
    projectId,
    utilizationId,
  }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.APA,
      utilization_id: utilizationId,
    });
  },
);

export const ApaActionActions = {
  delete: doDelete,
  complete,
  update,
  create,
  get,
  getAll,
};
