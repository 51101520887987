import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';
import { alpha } from '@mui/material';

export const useStyles = makeStyles({
  iconWrap: {
    width: 26,
    height: 26,
    display: 'flex',
    justifyContent: 'center',
    background: pgColorScheme.mediumBackground,
    alignItems: 'center',
    borderRadius: '50%',
  },
  icon: {
    width: 14,
    height: 14,
    display: 'block',
    fill: pgColorScheme.darkText,
    transition: 'fill .1s ease',
  },
  editable: {
    cursor: 'pointer',

    ['& $icon']: {
      fill: alpha(pgColorScheme.darkText, 0.3),
    },

    ['&:hover $icon']: {
      fill: pgColorScheme.blue,
    },
  },
  active: {
    ['& $icon']: {
      fill: pgColorScheme.orange,
    },
  },
  disabled: {
    pointerEvents: 'none',
  },
});
