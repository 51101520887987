import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, Hidden, Step, StepButton, Stepper, useMediaQuery, useTheme } from '@mui/material';
import { Trans } from 'react-i18next';
import { ProjectSelector, ToolUtilizationSelector } from '../../project/store/selectors';
import {
  DomainActions,
  DomainPrincipleActions,
  MatrixElementActions,
  ParameterActions,
  PrincipleActions,
} from '../store/actions';
import { InventivePrinciplesSelectors } from '../store/selectors/InventivePrinciplesSelectors';
import { SlidingTabs } from '../../react/elements/sliding-tabs/component';
import { InventivePrinciplesContradiction } from '../inventive-principles-contradiction/component';
import { InventivePrinciplesImprovingParameter } from '../inventive-principles-improving-parameter/component';
import { InventivePrinciplesWorseningParameter } from '../inventive-principles-worsening-parameter/component';
import { InventivePrinciplesResult } from '../inventive-principles-result/component';
import { isPrevStepCompleted, isStepCompleted } from './utils';
import { useStyles } from './styles';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { ToolTitle } from '../../tools/tool-title/component';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { useToolTabs } from '../../tools/utils';
import { ToolUtilizationActionType } from '../../project/store/actions/tools.actions';

export interface FortyPrinciplesStepsProps {
  utilizationId: number;
  projectId?: number;
}

const steps = [
  'Build engineering contradiction',
  'Choosing improving parameter',
  'Choosing worsening parameter',
  'Principles & solutions',
];

const nextStepButtonText = ['Choose improving parameter', 'Choose worsening parameter', 'See principles'];

export const FortyPrinciplesSteps: React.FC<FortyPrinciplesStepsProps> = ({ utilizationId, projectId }) => {
  const theme = useTheme();
  const styles = useStyles();
  const dispatch = useDispatch();
  const isDownMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  const [activeStep, setActiveStep] = useToolTabs(0);

  const utilization = useSelector(ToolUtilizationSelector.get40PUtilization(utilizationId));
  const isContradictionCompleted = useSelector(InventivePrinciplesSelectors.isContradictionCompleted(utilizationId));
  const isImprovingParameterCompleted = useSelector(
    InventivePrinciplesSelectors.isImprovingParameterCompleted(utilizationId),
  );
  const isWorseningParameterCompleted = useSelector(
    InventivePrinciplesSelectors.isWorseningParameterCompleted(utilizationId),
  );
  const isProjectLocked = useSelector(ProjectSelector.isProjectLockedById(projectId));
  const isCompletenessUpdating = useSelector(
    ToolUtilizationSelector.isPendingByActionType(utilizationId, ToolUtilizationActionType.UpdateCompleteness),
  );

  const isDisabled = !!utilization?.completed || isCompletenessUpdating || isProjectLocked;

  const currentStepCompleted = isStepCompleted(
    activeStep,
    isContradictionCompleted,
    isImprovingParameterCompleted,
    isWorseningParameterCompleted,
  );

  useEffect(() => {
    dispatch(ParameterActions.loadParameters());
    dispatch(PrincipleActions.loadPrinciples());
    dispatch(DomainPrincipleActions.loadDomainPrinciples());
    dispatch(DomainActions.loadDomains());
    dispatch(MatrixElementActions.loadMatrixElements());
  }, [utilizationId, projectId]);

  const handleChangeStep = (step: number) => {
    setActiveStep(step);
  };

  const handleNext = () => {
    setActiveStep(Math.min(activeStep + 1, steps.length - 1));
  };

  const handlePrev = () => {
    setActiveStep(Math.max(activeStep - 1, 0));
  };

  if (!utilization) {
    return null;
  }

  return (
    <>
      <ContentContainer display={'flex'} alignItems={'stretch'}>
        <Grid container direction={'column'} wrap={'nowrap'}>
          <Grid item xs={'auto'}>
            <PageTitleWithDocLink
              titleComponent={<ToolTitle tool={utilization} preset={'large'} editable={!isDisabled} />}
              docsUrl={'https://www.priz.guru/knowledge-base/40-inventive-principles/'}
            />
          </Grid>

          <Grid item xs={true}>
            <Box mt={1} mb={4}>
              <Stepper
                nonLinear
                activeStep={activeStep}
                orientation={isDownMdBreakpoint ? 'vertical' : 'horizontal'}
                className={styles.stepper}
              >
                {steps.map((item, key) => {
                  const currentStepCompleted = isStepCompleted(
                    key,
                    isContradictionCompleted,
                    isImprovingParameterCompleted,
                    isWorseningParameterCompleted,
                  );
                  const prevStepCompleted = isPrevStepCompleted(
                    key,
                    isContradictionCompleted,
                    isImprovingParameterCompleted,
                    isWorseningParameterCompleted,
                  );
                  const isActiveStep = key === activeStep;

                  return (
                    <Step
                      key={key}
                      active={isActiveStep}
                      completed={!isActiveStep && currentStepCompleted}
                      disabled={!prevStepCompleted}
                    >
                      <StepButton
                        onClick={() => {
                          handleChangeStep(key);
                        }}
                      >
                        <Trans>{item}</Trans>
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>

            <SlidingTabs index={activeStep}>
              <Box px={{ xs: 0, sm: 1 }}>
                <InventivePrinciplesContradiction
                  projectId={projectId}
                  utilization={utilization}
                  disabled={isDisabled}
                />
              </Box>

              <Box px={{ xs: 0, sm: 1 }}>
                <InventivePrinciplesImprovingParameter
                  projectId={projectId}
                  utilization={utilization}
                  disabled={isDisabled}
                />
              </Box>

              <Box px={{ xs: 0, sm: 1 }}>
                <InventivePrinciplesWorseningParameter
                  projectId={projectId}
                  utilization={utilization}
                  disabled={isDisabled}
                />
              </Box>

              <Box px={{ xs: 0, sm: 1 }}>
                <InventivePrinciplesResult utilization={utilization} disabled={isDisabled} />
              </Box>
            </SlidingTabs>
          </Grid>
        </Grid>
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={projectId}
          utilization={utilization}
          rightColContent={
            <>
              {activeStep > 0 && (
                <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handlePrev}>
                  <Trans>Back</Trans>
                </Button>
              )}

              {activeStep < 3 && (
                <Button
                  variant={'pg_rounded'}
                  color={'pg_orange_solid'}
                  disabled={!currentStepCompleted}
                  onClick={handleNext}
                >
                  <Hidden smUp>
                    <Trans>Next step</Trans>
                  </Hidden>

                  <Hidden smDown>
                    {nextStepButtonText[activeStep] && <Trans>{nextStepButtonText[activeStep]}</Trans>}
                  </Hidden>
                </Button>
              )}
            </>
          }
          workingFlowProps={{ nextVariant: 'ideas' }}
          disabled={isDisabled}
        />
      </ContentFooter>
    </>
  );
};
