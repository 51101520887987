import { FluxStandardAction } from 'flux-standard-action';
import { Domain } from '@priz/shared/src/models/tools/forty-principles/domain';
import { DomainService } from '../../services/domain.service';
import { createPgAction } from '../../../shared/store/action-creator';
import { DomainPrinciple } from '@priz/shared/src/models/tools/forty-principles/domain-principle';
import { ActionMeta } from '../../../shared/store/types';

export enum DomainActionType {
  Fetch = 'domain/fetch',
  Update = 'domain/update',
  Create = 'domain/create',
  Delete = 'domain/delete',
}

interface DomainMeta extends ActionMeta {
  domainId: number;
}

export type DomainsAction = FluxStandardAction<DomainActionType, Domain[]>;
export type DomainAction = FluxStandardAction<DomainActionType, Domain, DomainMeta>;
export type CreateDomainAction = FluxStandardAction<
  DomainActionType,
  { domain: Domain; domainPrinciples: DomainPrinciple[] }
>;
export type DeleteDomainAction = FluxStandardAction<DomainActionType, null, { domainId: number }>;

const loadDomains = createPgAction(DomainActionType.Fetch, DomainService.loadDomains);

const updateDomain = createPgAction(DomainActionType.Update, DomainService.updateDomain, (domainId: number) => ({
  domainId,
}));

const createDomain = createPgAction(DomainActionType.Create, DomainService.createDomain);

const deleteDomain = createPgAction(DomainActionType.Delete, DomainService.deleteDomain, (domainId: number) => ({
  domainId,
}));

export const DomainActions = {
  loadDomains,
  updateDomain,
  createDomain,
  deleteDomain,
};
