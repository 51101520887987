import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  toolContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
});
