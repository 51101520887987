import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TaskAssigneeSelectors } from '../store/selectors/task-assignee.selectors';
import { AddAssigneeButton } from '../add-assignee-button/component';
import { Assignee } from '../assignee/component';
import { TaskAssigneeWithMember } from '@priz/shared/src/models/assignment';
import { TaskAssigneeActions } from '../store/actions';
import { ChipProps } from '@mui/material';
import { AssigneeSelectorDialog } from '../assignee-selector-dialog/component';
import { WorkspaceMember } from '@priz/shared/src/models/workspace';
import { Task } from '@priz/shared/src/models/task';

interface TaskAssigneeButtonProps extends ChipProps {
  task: Task;
  text?: string;
}

export const TaskAssigneeButton: React.FC<TaskAssigneeButtonProps> = ({ task, text, ...rest }) => {
  const dispatch = useDispatch();

  const isLoaded = useSelector(TaskAssigneeSelectors.isLoaded);
  const isLoading = useSelector(TaskAssigneeSelectors.isLoading);
  const assignees = useSelector(TaskAssigneeSelectors.getTaskAssignees(task.id));
  const [assignmentDialogState, setAssignmentDialogState] = useState({ open: false, change: false });
  const [showLoading, setShowLoading] = useState(false);
  const [assignAfterDeletion, setAssignAfterDeletion] = useState<WorkspaceMember | null>(null);
  const assignee = assignees?.[0];

  useEffect(() => {
    if (showLoading && !isLoading) setShowLoading(false);
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && assignAfterDeletion) {
      setAssignAfterDeletion(null);
      assignMember(assignAfterDeletion);
    }
  }, [assignAfterDeletion, isLoading]);

  const handleAssignmentDialogOpen = (change?: boolean) => {
    setAssignmentDialogState({
      open: true,
      change,
    });
  };

  const handleAssignmentDialogClose = () => {
    setAssignmentDialogState(currentState => ({
      ...currentState,
      open: false,
    }));
  };

  const handleMemberAssign = (member: WorkspaceMember) => {
    if (assignee) {
      setAssignAfterDeletion(member);
      handleAssigneeDelete(assignee);
    } else {
      assignMember(member);
    }

    handleAssignmentDialogClose();
  };

  const assignMember = (member: WorkspaceMember) => {
    dispatch(TaskAssigneeActions.create(task.project.id, task.id, member.email));
    setShowLoading(true);
  };

  const handleAssigneeDelete = (assignee: TaskAssigneeWithMember) => {
    dispatch(TaskAssigneeActions.delete(task.project.id, task.id, assignee.user.email, assignee.id));
    setShowLoading(true);
  };

  return (
    <>
      {!assignee && (
        <AddAssigneeButton
          text={!isLoaded || showLoading ? 'loading...' : text || 'add assignee'}
          onClick={() => {
            handleAssignmentDialogOpen(false);
          }}
          disabled={!isLoaded || showLoading}
          loading={showLoading}
          {...rest}
        />
      )}

      {assignee && (
        <Assignee
          key={assignee.id}
          assignee={assignee.user}
          onDelete={() => handleAssigneeDelete(assignee)}
          onClick={() => {
            handleAssignmentDialogOpen(true);
          }}
          disabled={!isLoaded || showLoading}
          {...rest}
        />
      )}

      <AssigneeSelectorDialog
        isOpen={assignmentDialogState.open}
        title={assignmentDialogState.change ? 'Change an assignee' : 'Add an assignee'}
        onMemberAssign={handleMemberAssign}
        onClose={handleAssignmentDialogClose}
        defaultValue={assignee?.user}
      />
    </>
  );
};
