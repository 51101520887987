import { FluxStandardAction } from 'flux-standard-action';
import { MatrixElement } from '@priz/shared/src/models/tools/forty-principles/matrix-element';
import { MatrixElementService } from '../../services/matrix-element.service';
import { createPgAction } from '../../../shared/store/action-creator';

export enum MatrixElementActionType {
  Fetch = '40P/matrixElement/fetch',
}

export type MatrixElementAction = FluxStandardAction<MatrixElementActionType, MatrixElement[]>;

const loadMatrixElements = createPgAction(MatrixElementActionType.Fetch, MatrixElementService.loadMatrixElements);

export const MatrixElementActions = {
  loadMatrixElements,
};
