import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    color: pgColorScheme.white,
    boxSizing: 'border-box',
  },
  img: {
    position: 'relative',
    width: 160,
    height: 160,
    borderRadius: '50%',
    overflow: 'hidden',
    zIndex: 3,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: pgColorScheme.lightGray,
  },
  button: {
    'transition': 'color .3s ease',
    'color': pgColorScheme.blue,

    '&:hover': {
      color: pgColorScheme.white,
    },

    '& svg': {
      fill: pgColorScheme.blue,
      width: 16,
      height: 16,
    },
  },
  editor: {
    'display': 'block',
    'borderRadius': '50%',
    'overflow': 'hidden',

    '&._disabled': {
      pointerEvents: 'none',
      opacity: 0.8,
    },
  },
  text: {
    wordBreak: 'break-word',
  },
});
