import { pgColorScheme } from '@priz/shared/src/theme';
import { Options } from '@priz/shared/src/lib/vis/esnext';

const colors = {
  default: pgColorScheme.blue,
  hover: pgColorScheme.darkText,
  highlight: pgColorScheme.orange,
  background: pgColorScheme.white,
};

const arrows = {
  blue: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMS44ODAxIDYuMjc2NzRMMTEuMjc4OSA2Ljg3Nzg1QzExLjE5ODggNi45NTgxMyAxMS4xMDY2IDYuOTk4MTQgMTEuMDAyMiA2Ljk5ODE0QzEwLjg5ODIgNi45OTgxNCAxMC44MDU5IDYuOTU4MTMgMTAuNzI1OCA2Ljg3Nzg1TDYuMDAwMDYgMi4xNTIzNUwxLjI3NDUxIDYuODc3NzNDMS4xOTQzNiA2Ljk1OCAxLjEwMjEzIDYuOTk4MDEgMC45OTc5MzMgNi45OTgwMUMwLjg5MzY5NiA2Ljk5ODAxIDAuODAxNDYzIDYuOTU4IDAuNzIxMzU5IDYuODc3NzNMMC4xMjAyODMgNi4yNzY1N0MwLjA0MDAxIDYuMTk2NDYgMCA2LjEwNDIzIDAgNS45OTk5OUMwIDUuODk1ODQgMC4wNDAxMzYzIDUuODAzNjEgMC4xMjAyODMgNS43MjM1MUw1LjcyMzQ5IDAuMTIwMTg4QzUuODAzNTkgMC4wNDAwMzc4IDUuODk1ODcgLTEuMTQ0NDFlLTA1IDYuMDAwMDYgLTEuMTQ0NDFlLTA1QzYuMTA0MjYgLTEuMTQ0NDFlLTA1IDYuMTk2MzcgMC4wNDAwMzc4IDYuMjc2NDMgMC4xMjAxODhMMTEuODgwMSA1LjcyMzUxQzExLjk2MDIgNS44MDM2NSAxMiA1Ljg5NTg4IDEyIDUuOTk5OTlDMTIgNi4xMDQyMyAxMS45NjAyIDYuMTk2NDYgMTEuODgwMSA2LjI3Njc0WiIgZmlsbD0iIzExOThEMiIvPgo8L3N2Zz4K',
  dark: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMS44ODAxIDYuMjc2NzRMMTEuMjc4OSA2Ljg3Nzg1QzExLjE5ODggNi45NTgxMyAxMS4xMDY2IDYuOTk4MTQgMTEuMDAyMiA2Ljk5ODE0QzEwLjg5ODIgNi45OTgxNCAxMC44MDU5IDYuOTU4MTMgMTAuNzI1OCA2Ljg3Nzg1TDYuMDAwMDYgMi4xNTIzNUwxLjI3NDUxIDYuODc3NzNDMS4xOTQzNiA2Ljk1OCAxLjEwMjEzIDYuOTk4MDEgMC45OTc5MzMgNi45OTgwMUMwLjg5MzY5NiA2Ljk5ODAxIDAuODAxNDYzIDYuOTU4IDAuNzIxMzU5IDYuODc3NzNMMC4xMjAyODMgNi4yNzY1N0MwLjA0MDAxIDYuMTk2NDYgMCA2LjEwNDIzIDAgNS45OTk5OUMwIDUuODk1ODQgMC4wNDAxMzYzIDUuODAzNjEgMC4xMjAyODMgNS43MjM1MUw1LjcyMzQ5IDAuMTIwMTg4QzUuODAzNTkgMC4wNDAwMzc4IDUuODk1ODcgLTEuMTQ0NDFlLTA1IDYuMDAwMDYgLTEuMTQ0NDFlLTA1QzYuMTA0MjYgLTEuMTQ0NDFlLTA1IDYuMTk2MzcgMC4wNDAwMzc4IDYuMjc2NDMgMC4xMjAxODhMMTEuODgwMSA1LjcyMzUxQzExLjk2MDIgNS44MDM2NSAxMiA1Ljg5NTg4IDEyIDUuOTk5OTlDMTIgNi4xMDQyMyAxMS45NjAyIDYuMTk2NDYgMTEuODgwMSA2LjI3Njc0WiIgZmlsbD0iIzFBMTkyQiIvPgo8L3N2Zz4K',
  orange:
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMS44ODAxIDYuMjc2NzRMMTEuMjc4OSA2Ljg3Nzg1QzExLjE5ODggNi45NTgxMyAxMS4xMDY2IDYuOTk4MTQgMTEuMDAyMiA2Ljk5ODE0QzEwLjg5ODIgNi45OTgxNCAxMC44MDU5IDYuOTU4MTMgMTAuNzI1OCA2Ljg3Nzg1TDYuMDAwMDYgMi4xNTIzNUwxLjI3NDUxIDYuODc3NzNDMS4xOTQzNiA2Ljk1OCAxLjEwMjEzIDYuOTk4MDEgMC45OTc5MzMgNi45OTgwMUMwLjg5MzY5NiA2Ljk5ODAxIDAuODAxNDYzIDYuOTU4IDAuNzIxMzU5IDYuODc3NzNMMC4xMjAyODMgNi4yNzY1N0MwLjA0MDAxIDYuMTk2NDYgMCA2LjEwNDIzIDAgNS45OTk5OUMwIDUuODk1ODQgMC4wNDAxMzYzIDUuODAzNjEgMC4xMjAyODMgNS43MjM1MUw1LjcyMzQ5IDAuMTIwMTg4QzUuODAzNTkgMC4wNDAwMzc4IDUuODk1ODcgLTEuMTQ0NDFlLTA1IDYuMDAwMDYgLTEuMTQ0NDFlLTA1QzYuMTA0MjYgLTEuMTQ0NDFlLTA1IDYuMTk2MzcgMC4wNDAwMzc4IDYuMjc2NDMgMC4xMjAxODhMMTEuODgwMSA1LjcyMzUxQzExLjk2MDIgNS44MDM2NSAxMiA1Ljg5NTg4IDEyIDUuOTk5OTlDMTIgNi4xMDQyMyAxMS45NjAyIDYuMTk2NDYgMTEuODgwMSA2LjI3Njc0WiIgZmlsbD0iI0Y4ODAxRSIvPgo8L3N2Zz4K',
};

export const viewOptions: Options = {
  interaction: {
    dragNodes: false,
    hideEdgesOnDrag: false,
    hideEdgesOnZoom: false,
    hideNodesOnDrag: false,
    hover: true,
    hoverConnectedEdges: true,
    keyboard: false,
    navigationButtons: false,
    selectable: true,
    selectConnectedEdges: true,
  },
};

export const options: Options = {
  physics: {
    enabled: true,

    repulsion: {
      centralGravity: 1,
      springLength: 150,
      springConstant: 0.85,
      nodeDistance: 300,
      damping: 0.5,
    },

    stabilization: {
      enabled: true,
      iterations: 3000,
      updateInterval: 100,
      onlyDynamicEdges: false,
      fit: true,
    },

    timestep: 0.5,
    adaptiveTimestep: true,

    solver: 'repulsion',
  },

  nodes: {
    physics: true,
    shadow: {
      enabled: false,
      size: 0,
    },
    borderWidth: 1,
    borderWidthSelected: 1,
    labelHighlightBold: false,

    color: {
      border: colors.default,
      background: colors.background,
      highlight: {
        border: colors.highlight,
        background: colors.background,
      },
      hover: {
        border: colors.hover,
        background: colors.background,
      },
    },

    opacity: 1,

    font: {
      color: '#222',
      size: 18,
      background: 'none',
      align: 'center',
      multi: 'html',
      face: '"Roboto", sans-serif',
    },

    shape: 'circle',
    widthConstraint: {
      minimum: 40,
      maximum: 40,
    },
  },

  edges: {
    arrows: {
      to: {
        enabled: true,
        type: 'image',
        imageWidth: 12,
        imageHeight: 7,
        src: arrows.blue,
      },
    },
    chosen: {
      // @ts-ignore
      edge: (values, _id, selected, hovering) => {
        if (hovering) {
          values.color = colors.hover;
          values.toArrowSrc = arrows.dark;
        }
        if (selected) {
          values.color = colors.highlight;
          values.toArrowSrc = arrows.orange;
        }
      },
    },
    color: {
      color: colors.default,
      highlight: colors.highlight,
      hover: colors.hover,
      inherit: 'from',
      opacity: 1.0,
    },
    dashes: false,
    hidden: false,
    hoverWidth: 0,
    labelHighlightBold: false,
    selectionWidth: 0,
    width: 1,
  },
  interaction: {
    hover: true,
  },
};
