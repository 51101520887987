import React from 'react';
import { Box, BoxProps, Grid, IconButton, Paper } from '@mui/material';
import { KeyboardDoubleArrowRightRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { ToolUtilization } from '@priz/shared/src/models/tools';
import { ToolIcon } from '../tool-icon/component';
import { ToolTitle } from '../tool-title/component';
import { ToolsUtils } from '../utils';
import { LinkCard } from '../../link-card/component';

export interface RowToolCardCompactProps extends BoxProps {
  tool: ToolUtilization;
  controls?: JSX.Element;
  editable?: boolean;
  linkVariant?: 'wrap' | 'icon';
}

export const RowToolCardCompact: React.FC<RowToolCardCompactProps> = ({
  tool,
  controls,
  editable,
  linkVariant = 'wrap',
  ...rest
}) => {
  const renderContent = (linkIcon: boolean) => {
    return (
      <Grid container spacing={1} wrap={'nowrap'} alignItems={'center'}>
        <Grid item xs={'auto'}>
          <ToolIcon tool={tool} size={'small'} />
        </Grid>

        <Grid item xs={true} overflow={'hidden'}>
          <ToolTitle tool={tool} preset={'small'} editable={editable} disableIcon />
        </Grid>

        <Grid item>
          {controls}

          {linkIcon && (
            <IconButton
              component={Link}
              size={'small'}
              color={'primary'}
              to={ToolsUtils.resolvePathByUtilization(tool)}
              target={'_blank'}
            >
              <KeyboardDoubleArrowRightRounded fontSize={'small'} />
            </IconButton>
          )}
        </Grid>
      </Grid>
    );
  };

  if (linkVariant === 'wrap') {
    return (
      <LinkCard to={ToolsUtils.resolvePathByUtilization(tool)}>
        <Box pl={1} pr={0.5} py={0.5} {...rest}>
          {renderContent(false)}
        </Box>
      </LinkCard>
    );
  }

  if (linkVariant === 'icon') {
    return (
      <Paper variant={'outlined'} component={Box} pl={1} pr={0.5} py={0.5} {...rest}>
        {renderContent(true)}
      </Paper>
    );
  }

  return null;
};
