import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  comment: {
    position: 'relative',
  },
  commentContent: {
    position: 'relative',
    zIndex: 1,
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
  },
});
