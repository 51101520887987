import React from 'react';
import { Button, Grid, GridProps } from '@mui/material';
import { Trans } from 'react-i18next';
import { resolveHintText } from '../utils';
import { HintRowType } from '../hint-content/component';
import { AssistantHintData } from '../store/model';

export interface AssistantControlsBaseProps {
  retry?: boolean;
  retryText?: string;
}

export interface SharedAssistantControlsProps extends AssistantControlsBaseProps {
  onUseHint?: () => void;
  onRetry?: () => void;
  onClose?: () => void;
  useHintDisabled?: boolean;
  retryDisabled?: boolean;
  disabled?: boolean;
}

interface AssistantControlsProps extends SharedAssistantControlsProps {
  hintData?: AssistantHintData;
  hintRowsType?: HintRowType;
  loading?: boolean;
  containerProps?: GridProps;
}

const useHintButtonTextMap: { [key in HintRowType]: string } = {
  [HintRowType.Text]: 'Use this hint',
  [HintRowType.Content]: 'Use this hint',
  [HintRowType.Radio]: 'Use selected hint option',
  [HintRowType.Checkbox]: 'Use selected hint options',
};

export const AssistantControls: React.FC<AssistantControlsProps> = ({
  retry,
  retryText,
  hintData,
  onUseHint,
  onRetry,
  onClose,
  useHintDisabled,
  retryDisabled,
  hintRowsType,
  loading,
  containerProps,
}) => {
  const { rawText } = resolveHintText(hintData);

  return (
    <Grid container spacing={1} {...containerProps}>
      {onUseHint && rawText && (
        <Grid item>
          <Button variant={'outlined'} onClick={onUseHint} disabled={useHintDisabled}>
            <Trans>{useHintButtonTextMap[hintRowsType || HintRowType.Text]}</Trans>
          </Button>
        </Grid>
      )}

      {retry && onRetry && (
        <Grid item>
          <Button variant={'outlined'} onClick={onRetry} disabled={retryDisabled || loading}>
            <Trans>{retryText || 'Retry'}</Trans>
          </Button>
        </Grid>
      )}

      {onClose && (
        <Grid item>
          <Button onClick={onClose}>
            <Trans>Close</Trans>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
