import { IToolUtilization, ToolUtilization } from '../tool-utilization';
import { IdRef } from '../../common/id-ref';

interface Parameter extends IdRef {
  description: string;
  title: string;
}

export interface IFortyPrinciplesUtilization extends IToolUtilization {
  contradictionIf?: string;
  contradictionThen?: string;
  contradictionBut?: string;
  contradictionNotes?: string;

  improvingParameter?: Parameter;
  improvingParameterDescription?: string;

  worseningParameter?: Parameter;
  worseningParameterDescription?: string;

  sourceImprovementId?: number;
  sourceImprovementIterationId?: number;
}

export class FortyPrinciplesUtilization extends ToolUtilization implements IFortyPrinciplesUtilization {
  public contradictionIf?: string;
  public contradictionThen?: string;
  public contradictionBut?: string;
  public contradictionNotes?: string;

  public improvingParameter?: Parameter;
  public improvingParameterDescription?: string;

  public worseningParameter?: Parameter;
  public worseningParameterDescription?: string;

  public sourceImprovementId?: number;
  public sourceImprovementIterationId?: number;

  constructor(json: IFortyPrinciplesUtilization) {
    super(json);
    this.contradictionIf = json.contradictionIf;
    this.contradictionThen = json.contradictionThen;
    this.contradictionBut = json.contradictionBut;
    this.contradictionNotes = json.contradictionNotes;
    this.improvingParameter = json.improvingParameter;
    this.improvingParameterDescription = json.improvingParameterDescription;
    this.worseningParameter = json.worseningParameter;
    this.worseningParameterDescription = json.worseningParameterDescription;
    this.sourceImprovementId = json.sourceImprovementId;
    this.sourceImprovementIterationId = json.sourceImprovementIterationId;
  }
}
