import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { Idea } from '@priz/shared/src/models/idea/idea';
import { ToolType, ToolUtilization } from '@priz/shared/src/models/tools';
import { Trans } from 'react-i18next';
import { ToolTypeDataMap } from '@priz/shared/src/data/tool-type-data-map';
import { format } from 'date-fns';
import { ProjectAssigneeWithMember } from '@priz/shared/src/models/assignment';
import { ReportUser } from '../user/component';
import { ReportIdea } from '../idea/component';
import { useStyles } from './styles';
import { useStyles as useReportDocumentStyles } from '../document/styles';
import {
  ApaResult,
  FortyPrinciplesResult,
  EbsReportResult,
  UimReportResult,
  RrmReportResult,
  FiveWhysReportResult,
  SfmResult,
  PMapResult,
} from '../result';
import { ArrayUtils, SortDirection } from '@priz/shared/src/utils/common';
import { Parameter } from '@priz/shared/src/models/tools/forty-principles/parameter';
import { MatrixElement } from '@priz/shared/src/models/tools/forty-principles/matrix-element';
import { Principle } from '@priz/shared/src/models/tools/forty-principles/principle';
import { CecViewer, NineWindowsViewer } from '@priz/shared/src/components/tools-viewers';
import { QuillContentViewer } from '@priz/shared/src/components/quill-content-viewer/component';
import { PfmResult } from '../result/pfm';
import { useSelector } from 'react-redux';
import { ToolUtilizationSelector } from '../../store/selectors';
import { CftViewer } from '@priz/shared/src/components/tools-viewers/cft/component';
import { ApaUtilization } from '@priz/shared/src/models/tools/apa';
import { FortyPrinciplesUtilization } from '@priz/shared/src/models/tools/forty-principles/forty-principles-utilization';
import { PMapUtilization } from '@priz/shared/src/models/tools/pmap';
import { EbsUtilization } from '@priz/shared/src/models/tools/ebs';
import { CecUtilization } from '@priz/shared/src/models/tools/cec';
import { UimUtilization } from '@priz/shared/src/models/tools/uim';
import { RrmUtilization } from '@priz/shared/src/models/tools/rrm/rrm-utilization';
import { NineWindowsUtilization } from '@priz/shared/src/models/tools/nine-windows';
import { FiveWhysUtilization } from '@priz/shared/src/models/tools/five-whys';
import { SfmUtilization } from '@priz/shared/src/models/tools/sfm';
import { PfmUtilization } from '@priz/shared/src/models/tools/pfm';
import { CftUtilization } from '@priz/shared/src/models/tools/cft';

export interface ToolsExtraData {
  principles: Principle[];
  parameters: Parameter[];
  matrixElements: MatrixElement[];
}

export const DefaultToolsExtraData: ToolsExtraData = {
  principles: [],
  matrixElements: [],
  parameters: [],
};

export interface ReportToolProps {
  tool: ToolUtilization;
  ideas?: Idea[];
  assignees?: ProjectAssigneeWithMember[];
  rootClassName?: string;
  toolsExtraData: ToolsExtraData;
}

const resolveContent = (tool: ToolUtilization, data: ToolsExtraData) => {
  switch (tool.type) {
    case ToolType.APA:
      return (
        <div className={ToolType.APA}>
          <ApaResult utilization={tool as ApaUtilization} />
        </div>
      );

    case ToolType.TOOL_40_PRINCIPLES:
      return (
        <div className={ToolType.TOOL_40_PRINCIPLES}>
          <FortyPrinciplesResult
            utilization={tool as FortyPrinciplesUtilization}
            principles={data.principles}
            parameters={data.parameters}
            matrixElements={data.matrixElements}
          />
        </div>
      );

    case ToolType.P_MAP:
      return (
        <div className={ToolType.P_MAP}>
          <PMapResult utilization={tool as PMapUtilization} />
        </div>
      );

    case ToolType.EBS:
      return (
        <div className={ToolType.EBS}>
          <EbsReportResult tool={tool as EbsUtilization} />
        </div>
      );

    case ToolType.CEC:
      return (
        <div className={ToolType.CEC}>
          <CecViewer utilization={tool as CecUtilization} disableInteractive />
        </div>
      );

    case ToolType.UIM:
      return (
        <div className={ToolType.UIM}>
          <UimReportResult tool={tool as UimUtilization} />
        </div>
      );

    case ToolType.RRM:
      return (
        <div className={ToolType.RRM}>
          <RrmReportResult tool={tool as RrmUtilization} />
        </div>
      );

    case ToolType.NINE_WINDOWS:
      return (
        <div className={ToolType.NINE_WINDOWS}>
          <NineWindowsViewer utilization={tool as NineWindowsUtilization} />
        </div>
      );

    case ToolType.FIVE_WHYS:
      return (
        <div className={ToolType.FIVE_WHYS}>
          <FiveWhysReportResult tool={tool as FiveWhysUtilization} />
        </div>
      );

    case ToolType.SFM:
      return (
        <div className={ToolType.SFM}>
          <SfmResult utilization={tool as SfmUtilization} />
        </div>
      );

    case ToolType.PFM:
      return (
        <div className={ToolType.PFM}>
          <PfmResult utilization={tool as PfmUtilization} />
        </div>
      );

    case ToolType.CFT:
      if (Math.random() !== 2) return null;

      return (
        <div className={ToolType.CFT}>
          <CftViewer utilization={tool as CftUtilization} disableInteractive />
        </div>
      );

    default:
      return null;
  }
};

export const ReportTool: React.FC<ReportToolProps> = ({
  tool,
  ideas,
  assignees = [],
  rootClassName,
  toolsExtraData,
}) => {
  const styles = useStyles();
  const reportDocumentStyles = useReportDocumentStyles();
  const user = assignees.find(assignee => assignee.userId === tool?.updatedBy?.id || tool?.createdBy?.id);

  const parentTool = useSelector(ToolUtilizationSelector.getParentTool(tool.parentId));

  const { name, icon: Icon } = ToolTypeDataMap[tool.type];

  return (
    <Box mb={2} className={rootClassName}>
      <Paper variant={'outlined'}>
        <Box p={1}>
          <Box>
            <Grid container>
              <Grid item xs={'auto'}>
                <Box pr={1}>
                  <Icon className={styles.icon} />
                </Box>
              </Grid>

              <Grid item xs={true}>
                <Typography variant={'h6'} component={'span'} className={styles.title}>
                  {tool?.title || name}
                </Typography>

                {parentTool && (
                  <Typography variant={'body2'} component={'span'}>
                    {' '}
                    (<Trans>by</Trans> {parentTool.title || ToolTypeDataMap[parentTool.type].name})
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>

          {tool?.subject && (
            <Box mt={1}>
              <QuillContentViewer content={tool.subject} className={'break'} />
            </Box>
          )}

          <Box mt={1}>{resolveContent(tool, toolsExtraData)}</Box>

          {tool?.conclusion && (
            <Box mt={1}>
              <QuillContentViewer content={tool.conclusion} className={'break'} />
            </Box>
          )}

          {!!ideas.length && (
            <Box mt={1} className={reportDocumentStyles.preventChildrenBreaking}>
              <Typography variant={'body2'} component={'div'}>
                <Trans>Generated ideas</Trans>:
              </Typography>

              {ideas
                .sort(ArrayUtils.sorterByDateCreated)
                .sort(ArrayUtils.sorterByParam('latestRankingScore', SortDirection.DESC))
                .map((idea, key) => (
                  <ReportIdea
                    key={key}
                    idea={idea}
                    showRankingScore={true}
                    compact={true}
                    // assignee={resolveIdeaAuthor(idea, assignees)}
                  />
                ))}
            </Box>
          )}

          <Box mt={2}>
            <Grid container alignItems={'center'} justifyContent={'space-between'}>
              {user && (
                <Grid item xs={'auto'}>
                  <Box pr={1}>
                    <ReportUser assignee={user} size="small" />
                  </Box>
                </Grid>
              )}

              <Grid item xs={'auto'}>
                <Box pr={1}>
                  <Typography variant={'caption'} component={'div'}>
                    {format(tool.dateCreated, 'MMM d yyyy')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
