import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../../store/app.state';
import { ApiKey } from '@priz/shared/src/models/api-keys';

const apiKeysSelector = (state: AppState) => state.apiKeys;

const apiKeysStatusesSelector = createSelector(apiKeysSelector, collection => collection.statuses);

const getApiKeysByUserId = (userId: number): Selector<AppState, ApiKey[]> =>
  createSelector(apiKeysSelector, collection => Object.values(collection.keys.byUserId[userId] || {}));

const isLoadingByUserId = (userId: number): Selector<AppState, boolean> =>
  createSelector(apiKeysStatusesSelector, statuses => !!statuses.byUserId[userId]?.loading);

const isLoadedByUserId = (userId: number): Selector<AppState, boolean> =>
  createSelector(apiKeysStatusesSelector, statuses => !!statuses.byUserId[userId]?.loaded);

const isCreatingByUserId = (userId: number): Selector<AppState, boolean> =>
  createSelector(apiKeysStatusesSelector, statuses => !!statuses.byUserId[userId]?.creating);

const isCreatedByUserId = (userId: number): Selector<AppState, boolean> =>
  createSelector(apiKeysStatusesSelector, statuses => !!statuses.byUserId[userId]?.created);

const isUpdatingByUserId = (userId: number): Selector<AppState, boolean> =>
  createSelector(apiKeysStatusesSelector, statuses => !!statuses.byUserId[userId]?.updating);

const isUpdatedByUserId = (userId: number): Selector<AppState, boolean> =>
  createSelector(apiKeysStatusesSelector, statuses => !!statuses.byUserId[userId]?.updated);

export const ApiKeysSelectors = {
  getApiKeysByUserId,
  isLoadingByUserId,
  isLoadedByUserId,
  isCreatingByUserId,
  isCreatedByUserId,
  isUpdatingByUserId,
  isUpdatedByUserId,
};
