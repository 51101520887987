import { ProjectAssignee, ProjectAssigneeRole } from '@priz/shared/src/models/assignment';
import axios, { AxiosResponse } from 'axios';

const fetchAll = (): Promise<ProjectAssignee[]> =>
  axios.get(`/v1/api/<ws-id>/project-assignees`).then((response: AxiosResponse<ProjectAssignee[]>) => response.data);

const create = (
  projectId: number,
  assigneeEmail: string,
  assigneeRole: ProjectAssigneeRole,
): Promise<ProjectAssignee> =>
  axios
    .post(`/v1/api/<ws-id>/project/${projectId}/assignee`, { assigneeEmail, assigneeRole })
    .then((response: AxiosResponse<ProjectAssignee>) => response.data);

const doDelete = (projectId: number, assigneeEmail: string): Promise<void> =>
  axios.delete(`/v1/api/<ws-id>/project/${projectId}/assignee/${assigneeEmail}`);

export const ProjectAssignmentApi = {
  fetchAll,
  create,
  delete: doDelete,
};
