import {
  OptionalComponentRankMap,
  SfmDiagramData,
  // SfmDiagramDataVersions,
  SfmEdgesMap,
  SfmNamedNetworkData,
  SfmNetworkData,
  SfmNodeProps,
  SfmNodesMap,
  SfmNodeType,
} from '../../models/tools/sfm';
import { copyObject } from '../../utils/common';
// import { TFunction } from 'i18next';

export const resolveSfmNetworkData = (
  diagramData?: SfmDiagramData,
  versionId?: string,
  defaultData?: SfmNetworkData,
): SfmNetworkData => {
  const data: SfmNetworkData = defaultData ? copyObject(defaultData) : { nodes: [], edges: [] };

  if (diagramData) {
    const diagramDataCopy = copyObject(diagramData);
    const id = versionId || diagramDataCopy?.versionId;
    const version: SfmNamedNetworkData =
      id && diagramDataCopy.versions ? diagramDataCopy.versions[id] : ({} as SfmNamedNetworkData);

    data.nodes = version?.nodes || diagramDataCopy.nodes || [];
    data.edges = version?.edges || diagramDataCopy.edges || [];
  }

  return data;
};

export const getSfmNodesMap = (diagramData: SfmNetworkData): SfmNodesMap => {
  return (diagramData.nodes || []).reduce((map: SfmNodesMap, item) => {
    map[item.id] = item;
    return map;
  }, {});
};

export const getSfmEdgesMap = (diagramData: SfmNetworkData): SfmEdgesMap => {
  return (diagramData.edges || []).reduce((map: SfmEdgesMap, item) => {
    map[item.id] = item;
    return map;
  }, {});
};

export const getNoImpactComponents = (
  networkData: SfmNetworkData,
  componentsRanksMap: OptionalComponentRankMap,
  excludeIds: (string | number)[] = [],
): SfmNodeProps[] => {
  return networkData.nodes.filter(
    node =>
      node.type === SfmNodeType.COMPONENT &&
      !Object.keys(componentsRanksMap).includes(node.id as string) &&
      !excludeIds.includes(node.id),
  );
};
