import { pgColorScheme } from '../theme';
import { EbsIdeaCategory, EbsIdeaNodeProps } from '../models/tools/ebs';
import { NodeOptions, ChosenNodeValues, Options } from '../lib/vis/esnext';

export const colorsMap: { [key in EbsIdeaCategory]: string } = {
  [EbsIdeaCategory.IE_VE]: '#85F090',
  [EbsIdeaCategory.IE_VN]: '#FFB171',
  [EbsIdeaCategory.IN_VE]: '#FFB171',
  [EbsIdeaCategory.IN_VN]: '#E86E69',
  [EbsIdeaCategory.NOT_DEFINED]: '#FFFFD1',
};

export const nodePropsDecorator = (node: Partial<EbsIdeaNodeProps>): Partial<EbsIdeaNodeProps> & NodeOptions => {
  return {
    ...node,
    color: {
      background: colorsMap[node?.type || EbsIdeaCategory.NOT_DEFINED],
    },
  };
};

const chosenNode = (values: ChosenNodeValues, _id: string | number, selected: boolean, hovering: boolean) => {
  if (hovering && !selected) {
    values.shadowColor = 'rgba(33,33,33,0.2)';
  }
  if (selected) {
    values.borderColor = pgColorScheme.darkText;
    values.borderDashes = [5, 5];
  }
};

export const options: Options = {
  manipulation: {
    enabled: false,
  },
  edges: {
    physics: false,
    hidden: true,
  },

  interaction: {
    hover: false,
    selectConnectedEdges: false,
  },
  layout: {
    improvedLayout: false,
    hierarchical: false,
  },

  physics: {
    enabled: false,

    stabilization: {
      enabled: false,
    },
  },

  nodes: {
    physics: false,
    borderWidth: 1,
    borderWidthSelected: 0,
    labelHighlightBold: false,

    color: {
      border: 'rgba(0,0,0,.1)',
      background: colorsMap[EbsIdeaCategory.NOT_DEFINED],

      highlight: {
        border: 'rgba(0,0,0,.1)',
        background: colorsMap[EbsIdeaCategory.NOT_DEFINED],
      },
      hover: {
        border: 'rgba(0,0,0,.1)',
        background: colorsMap[EbsIdeaCategory.NOT_DEFINED],
      },
    },

    // @ts-ignore
    chosen: {
      node: chosenNode,
    },

    opacity: 1,

    font: {
      color: '#222',
      size: 14,
      background: 'none',
      align: 'center',
      face: '"Roboto", sans-serif',
      multi: false,
    },
    widthConstraint: {
      minimum: 120,
      maximum: 240,
    },
    // @ts-ignore
    heightConstraint: {
      minimum: 80,
    },

    shadow: {
      enabled: true,
      color: 'rgba(0, 0, 0, 0.15)',
      size: 24,
      x: 0,
      y: 15,
    },

    shape: 'box',

    shapeProperties: {
      borderRadius: 0,
    },

    margin: {
      top: 32,
      right: 12,
      bottom: 32,
      left: 12,
    },

    scaling: {
      label: {
        drawThreshold: 0,
      },
    },
  },
};
