import { createStyles, makeStyles } from '@mui/styles';

import apaBg from '../../assets/img/apa-bg.svg';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightPane: {
      width: 500,
      height: 500,
      backgroundImage: `url(${apaBg})`,
      backgroundPosition: 'center top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '80% auto',
    },

    [theme.breakpoints.down('lg')]: {
      rightPane: {
        width: 350,
        height: 350,
      },
    },
  }),
);
