import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: pgColorScheme.blue,
    },
  },
  toolContainer: {
    position: 'relative',
  },
});
