import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../../store/app.state';

const preloaderStateSelector = (state: AppState) => state.preloader;

const isLoading: Selector<AppState, boolean> = createSelector(preloaderStateSelector, preloader => preloader.loading);

export const PreloaderSelectors = {
  isLoading,
};
