import React from 'react';
import { Box, Divider, Grid, Paper, Typography } from '@mui/material';
import { User } from '@priz/shared/src/models/security/user';
import { FieldTitle } from '@priz/shared/src/components/form-elements';
import { Role } from '@priz/shared/src/models/security/role.enum';
import Select, { components, MultiValue, ActionMeta } from 'react-select';
import {
  resolvePartnerProfileInfo,
  resolveUserInfo,
  resolveUserRoleSelectDefaultOptions,
  resolveUserRoleSelectOptions,
  UserAdministrationListData,
  UserRoleSelectOption,
} from '../utils';
import { PartnerProfileButton } from '../partner-profile-button/component';
import {
  CreatePartnerProfileCommand,
  UpdateUserRestrictionsCommand,
  UserPartnerProfileUpdateCommand,
} from '../api/users-administration.api';
import { UserRestrictions } from '../user-restrictions/component';
import { ApiKeysList } from '../api-keys-list/component';
import { LoadingButton } from '@mui/lab';
import { Trans } from 'react-i18next';

interface UserAdministrationCardProps {
  user: User;
  onAddRole: (userId: number, role: Role) => void;
  onRemoveRole: (userId: number, role: Role) => void;
  onCreatePartnerProfile: (userId: number, command: CreatePartnerProfileCommand) => void;
  onUpdatePartnerProfile: (userId: number, command: UserPartnerProfileUpdateCommand) => void;
  onUpdateRestrictions: (userId: number, command: UpdateUserRestrictionsCommand) => void;
  onCreateApiKey: (userId: number) => void;
  onInvalidateApiKey: (userId: number, apiKeyId: number) => void;
  loading?: boolean;
}

const MultiValueRemove = props => {
  if (props.data.isFixed) {
    return null;
  }
  return <components.MultiValueRemove {...props} />;
};

export const UserAdministrationCard: React.FC<UserAdministrationCardProps> = ({
  user,
  onAddRole,
  onRemoveRole,
  onCreatePartnerProfile,
  onUpdatePartnerProfile,
  onUpdateRestrictions,
  onCreateApiKey,
  onInvalidateApiKey,
  loading,
}) => {
  const isPartner = user.roles.includes(Role.ROLE_PARTNER);

  const roleChangeHandler = (
    _newValue: MultiValue<UserRoleSelectOption>,
    actionMeta: ActionMeta<UserRoleSelectOption>,
  ) => {
    if (actionMeta.action === 'select-option') {
      onAddRole(user.id, actionMeta.option.value);
    }

    if (actionMeta.action === 'remove-value') {
      onRemoveRole(user.id, actionMeta.removedValue.value);
    }
  };

  const createPartnerProfileHandler = (command: CreatePartnerProfileCommand) => {
    onCreatePartnerProfile(user.id, command);
  };

  const updatePartnerProfileHandler = (command: UserPartnerProfileUpdateCommand) => {
    onUpdatePartnerProfile(user.id, command);
  };

  const updateRestrictionsHandler = (command: UpdateUserRestrictionsCommand) => {
    onUpdateRestrictions(user.id, command);
  };

  const createApiKeyHandler = () => {
    onCreateApiKey(user.id);
  };

  const invalidateApiKeyHandler = (apiKeyId: number) => {
    onInvalidateApiKey(user.id, apiKeyId);
  };

  const renderInfoList = (data: UserAdministrationListData) => {
    return data.map((item, key) => (
      <Grid key={key} container alignItems={'center'} justifyContent={'flex-start'} spacing={1}>
        <Grid item>
          <FieldTitle text={<>{item.label}</>} mb={0} />
        </Grid>

        <Grid item>
          <Typography variant={'subtitle2'}>{item.value}</Typography>
        </Grid>
      </Grid>
    ));
  };

  const renderTitle = (text: string) => {
    return (
      <Typography component={Box} variant={'h6'} mb={2} lineHeight={1}>
        {text}
      </Typography>
    );
  };

  return (
    <Paper variant={'outlined'}>
      <Box p={2}>
        {renderTitle('Info:')}

        {renderInfoList(resolveUserInfo(user))}
      </Box>

      <Divider />

      <Box p={2}>
        {renderTitle('Roles:')}

        <Select
          defaultValue={resolveUserRoleSelectDefaultOptions(user)}
          options={resolveUserRoleSelectOptions(user)}
          components={{ MultiValueRemove }}
          onChange={roleChangeHandler}
          isClearable={false}
          isDisabled={loading}
          isLoading={loading}
          isMulti
        />
      </Box>

      <Divider />

      {!user.partnerProfile && isPartner && (
        <>
          <Box p={2}>
            {renderTitle('Partner profile:')}

            <PartnerProfileButton onCreate={createPartnerProfileHandler} disabled={!isPartner || loading} />
          </Box>

          <Divider />
        </>
      )}

      {user.partnerProfile && (
        <>
          <Box p={2}>
            {renderTitle('Partner profile:')}

            {renderInfoList(resolvePartnerProfileInfo(user.partnerProfile))}

            <Box mt={1}>
              <PartnerProfileButton
                onUpdate={updatePartnerProfileHandler}
                partnerProfile={user.partnerProfile}
                disabled={loading}
              />
            </Box>
          </Box>

          <Divider />
        </>
      )}

      <Box p={2}>
        {renderTitle('Api keys:')}

        <LoadingButton
          variant={'outlined'}
          size={'small'}
          onClick={createApiKeyHandler}
          disabled={loading}
          loading={loading}
        >
          <Trans>Create API key</Trans>
        </LoadingButton>

        {!!user.apiKeys?.length && (
          <Box mt={2}>
            <ApiKeysList apiKeys={user.apiKeys || []} onInvalidate={invalidateApiKeyHandler} disabled={loading} />
          </Box>
        )}
      </Box>

      <Divider />

      <Box p={2}>
        {renderTitle('User restrictions:')}

        <UserRestrictions user={user} onUpdate={updateRestrictionsHandler} disabled={loading} />
      </Box>
    </Paper>
  );
};
