import { Project, ProjectCertificationStatus } from '@priz/shared/src/models/project';
import { User } from '@priz/shared/src/models/security/user';
import { UserService } from '../../security/services/user.service';
import { ApprovalActivityActionEnum } from '../store/model';

const finalApprovalActivityActionToProjectCertificationStatus = {
  [ApprovalActivityActionEnum.SubmittedForApproval]: ProjectCertificationStatus.AvailableForReview,
  [ApprovalActivityActionEnum.AcceptedForReview]: ProjectCertificationStatus.InReview,
  [ApprovalActivityActionEnum.ChangesRequested]: ProjectCertificationStatus.WaitingForRequiredUpdates,
  [ApprovalActivityActionEnum.SubmittedChangesForApproval]: ProjectCertificationStatus.InReview,
  [ApprovalActivityActionEnum.Approved]: ProjectCertificationStatus.Certified,
  [ApprovalActivityActionEnum.Rejected]: ProjectCertificationStatus.Rejected,
};

const resolveProjectCertificationStatusByApprovalActivityAction = (action: ApprovalActivityActionEnum) => {
  return finalApprovalActivityActionToProjectCertificationStatus[action];
};

const isUserProjectOwner = (project: Project, user: User): boolean => {
  return project.owner.id === user.id;
};

const isUserProjectReviewer = (project: Project, user: User): boolean => {
  return UserService.isReviewer(user) && project.reviewer.id === user.id;
};

const isApproveAvailable = (project: Project, user: User) => {
  return project.certificationStatus === ProjectCertificationStatus.InReview && isUserProjectReviewer(project, user);
};

const isActionRequiredAvailable = (project: Project, user: User) => {
  return project.certificationStatus === ProjectCertificationStatus.InReview && isUserProjectReviewer(project, user);
};

const isRejectAvailable = (project: Project, user: User) => {
  return project.certificationStatus === ProjectCertificationStatus.InReview && isUserProjectReviewer(project, user);
};

const isResubmitForReviewAvailable = (project: Project, user: User) => {
  return (
    project.certificationStatus === ProjectCertificationStatus.WaitingForRequiredUpdates &&
    isUserProjectOwner(project, user)
  );
};

const isProjectCertificationStarted = (project: Project) => {
  return project.certificationStatus !== ProjectCertificationStatus.None;
};

const isProjectCertified = (project: Project) => {
  return project.certificationStatus === ProjectCertificationStatus.Certified;
};

export const ProjectCertificationWorkflowService = {
  resolveProjectCertificationStatusByApprovalActivityAction,
  isApproveAvailable,
  isActionRequiredAvailable,
  isRejectAvailable,
  isResubmitForReviewAvailable,
  isProjectCertificationStarted,
  isProjectCertified,
};
