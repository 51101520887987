import React, { useState } from 'react';
import { Alert, Box, Collapse, Link } from '@mui/material';
import { ApiKeyCard } from '../../user-profile/api-keys/api-key-card/component';
import { ApiKey, ApiKeyStatus } from '@priz/shared/src/models/api-keys';
import { Trans } from 'react-i18next';

interface ApiKeysListProps {
  apiKeys: ApiKey[];
  onInvalidate: (apiKeyId: number) => void;
  disabled?: boolean;
}

export const ApiKeysList: React.FC<ApiKeysListProps> = ({ apiKeys, onInvalidate, disabled }) => {
  const [isExpiredListOpen, setIsExpiredListOpen] = useState(false);

  const activeApiKeys = apiKeys.filter(key => key.status === ApiKeyStatus.Active);
  const expiredApiKeys = apiKeys.filter(key => key.status === ApiKeyStatus.Expired);

  const toggleExpiredList = () => {
    setIsExpiredListOpen(currentState => !currentState);
  };

  return (
    <>
      {activeApiKeys.map(apiKey => (
        <ApiKeyCard
          key={apiKey.id}
          apiKey={apiKey}
          onInvalidate={() => onInvalidate(apiKey.id)}
          disabled={disabled}
          view
          mb={1}
        />
      ))}

      {!activeApiKeys.length && (
        <Alert severity={'info'}>
          <Trans>No active API keys</Trans>
        </Alert>
      )}

      {!!expiredApiKeys.length && (
        <Box mt={1}>
          <Collapse in={isExpiredListOpen}>
            {expiredApiKeys.map(apiKey => (
              <ApiKeyCard key={apiKey.id} apiKey={apiKey} disabled={disabled} view mb={1} />
            ))}
          </Collapse>

          <Box mt={1}>
            <Link component={'span'} onClick={toggleExpiredList}>
              <Trans>{isExpiredListOpen ? 'hide' : 'show'} expired keys</Trans>
            </Link>
          </Box>
        </Box>
      )}
    </>
  );
};
