let canvas: HTMLCanvasElement;

const getCanvas = (): HTMLCanvasElement => {
  if (canvas) {
    return canvas;
  } else {
    canvas = document.createElement('canvas');
    return canvas;
  }
};

const getCssStyle = (element: HTMLElement, prop: string): string => {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
};

const getCanvasFont = (el: HTMLElement): string => {
  const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
  const fontSize = getCssStyle(el, 'font-size') || '16px';
  const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';

  return `${fontWeight} ${fontSize} ${fontFamily}`;
};

interface GetTextWidthProps {
  font?: string;
  fontElement?: HTMLElement;
  letterSpacing?: number;
}

export const getTextWidth = (text: string, props?: GetTextWidthProps): number => {
  const canvas = getCanvas();
  const context = canvas.getContext('2d');
  const lines = text.split(/\r?\n|\r|\n/g);
  const letterSpacing =
    props?.letterSpacing || parseFloat(getCssStyle(props?.fontElement, 'letter-spacing') || '0px') || 0;

  context.font = props?.font || getCanvasFont(props?.fontElement || document.body);

  let maxLength = 0;

  lines.forEach(line => {
    const metrics = context.measureText(line);
    const lineWidth = metrics.width + line.length * letterSpacing;

    if (maxLength < lineWidth) {
      maxLength = lineWidth;
    }
  });

  return maxLength;
};
