import React from 'react';
import { Box, BoxProps, Grid, Paper, Typography } from '@mui/material';
import { Idea } from '@priz/shared/src/models/idea/idea';
import { ProjectAssigneeWithMember } from '@priz/shared/src/models/assignment';
import { Status } from '@priz/shared/src/components/status/component';
import { pgColorScheme } from '@priz/shared/src/theme';
import { TipsAndUpdatesOutlined } from '@mui/icons-material';
import { IdeaControls } from '../idea-controls/comonent';
import { Trans } from 'react-i18next';

export interface IdeaCardCompactProps extends BoxProps {
  idea: Idea;
  disabled?: boolean;
  assignee?: ProjectAssigneeWithMember;
}

export const IdeaCardCompact: React.FC<IdeaCardCompactProps> = ({ idea, assignee, disabled, ...rest }) => {
  const clearText = idea.content?.replace(/(<.*?>)/gi, '')?.trim() || '';

  return (
    <Paper variant={'outlined'} component={Box} pl={1} pr={0.5} py={0.5} {...rest}>
      <Grid container spacing={1} wrap={'nowrap'} alignItems={'center'}>
        <Grid item>
          <TipsAndUpdatesOutlined fontSize={'medium'} sx={{ display: 'block', color: pgColorScheme.orangeBright }} />
        </Grid>

        <Grid item xs={true} overflow={'hidden'}>
          <Typography variant={'subtitle2'} noWrap>
            {clearText || <Trans>No description</Trans>}
          </Typography>
        </Grid>

        <Grid item>
          <Status status={idea.status} variant={'chip'} containerChipPros={{ size: 'extra_small' }} />
        </Grid>

        <Grid item>
          <IdeaControls idea={idea} disabled={disabled} compact />
        </Grid>
      </Grid>
    </Paper>
  );
};
