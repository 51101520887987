import React, { useEffect, useRef, useState } from 'react';
import { Idea } from '@priz/shared/src/models/idea';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Trans } from 'react-i18next';
import { Add, ChevronLeft, EditOutlined } from '@mui/icons-material';
import { IdeaCommentForm } from '../idea-comment-form/component';
import { IdeaCommentsList } from '../idea-comments-list/component';
import { useSelector } from 'react-redux';
import { IdeasCommentsSelectors } from '../store';
import { UserSelectors } from '../../user/store/selectors/user.selectors';

interface IdeaCommentsDialogProps {
  idea: Idea;
  open?: boolean;
  onClose?: () => void;
}

enum TabType {
  List = 'List',
  Form = 'Form',
}

export const IdeaCommentsDialog: React.FC<IdeaCommentsDialogProps> = ({ idea, open, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(TabType.List);

  const controlsContainerRef = useRef<HTMLDivElement>(null);

  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const userComment = useSelector(IdeasCommentsSelectors.getByIdeaIdAndUserId(idea.id, currentUser?.id));

  useEffect(() => {
    if (open) {
      openList();
    }

    if (typeof open !== 'undefined') {
      setIsOpen(open);
    }
  }, [open]);

  const closeHandler = () => {
    if (onClose) {
      onClose();
    } else {
      setIsOpen(false);
    }
  };

  const openList = () => {
    setActiveTab(TabType.List);
  };

  const newComment = () => {
    setActiveTab(TabType.Form);
  };

  return (
    <Dialog open={isOpen} onClose={closeHandler} fullWidth maxWidth={'lg'}>
      <DialogTitle>
        {activeTab === TabType.List && <Trans>Comments</Trans>}
        {activeTab === TabType.Form && <Trans>{userComment ? 'Edit comment' : 'New comment'}</Trans>}
      </DialogTitle>

      <DialogContent>
        {activeTab === TabType.List && <IdeaCommentsList idea={idea} />}

        {activeTab === TabType.Form && (
          <IdeaCommentForm
            comment={userComment}
            idea={idea}
            controlsContainer={controlsContainerRef.current}
            onCreate={openList}
            onUpdate={openList}
            onDelete={openList}
          />
        )}
      </DialogContent>

      <DialogActions ref={controlsContainerRef} sx={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
        {activeTab === TabType.List && (
          <>
            {userComment && (
              <Button
                sx={{ order: 100 }}
                variant={'pg_rounded'}
                color={'pg_orange_solid'}
                startIcon={<EditOutlined />}
                onClick={newComment}
              >
                <Trans>Edit my comment</Trans>
              </Button>
            )}

            {!userComment && (
              <Button
                sx={{ order: 110 }}
                variant={'pg_rounded'}
                color={'pg_orange_solid'}
                startIcon={<Add />}
                onClick={newComment}
              >
                <Trans>Add comment</Trans>
              </Button>
            )}
          </>
        )}

        {activeTab === TabType.Form && (
          <Button
            sx={{ order: 120 }}
            variant={'pg_rounded'}
            color={'pg_orange_outlined'}
            startIcon={<ChevronLeft />}
            onClick={openList}
          >
            <Trans>Back</Trans>
          </Button>
        )}

        <Button sx={{ order: 130 }} variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={closeHandler}>
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
