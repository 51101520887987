import React from 'react';
import { AuthCallback, UserLoaderProps } from '../auth-callback/component';
import { UserService } from '../services/user.service';

export const SignUpCallback: React.FC = () => {
  const userLoader = (props: UserLoaderProps = {}) => {
    const { referralCode } = props;

    return UserService.signUp(referralCode);
  };

  return <AuthCallback userLoader={userLoader} />;
};
