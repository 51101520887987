import React, { useState } from 'react';
import { PartnerProfileDialog, PartnerProfileDialogSharedProps } from '../partner-profile-dialog/component';
import { Button } from '@mui/material';

interface PartnerProfileButtonProps extends PartnerProfileDialogSharedProps {
  disabled?: boolean;
}

export const PartnerProfileButton: React.FC<PartnerProfileButtonProps> = ({ disabled, ...rest }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Button variant={'outlined'} size={'small'} onClick={openDialog} disabled={disabled}>
        {!!rest?.onCreate && 'Create partner profile'}
        {!!rest?.onUpdate && 'Edit partner profile'}
      </Button>

      <PartnerProfileDialog open={isDialogOpen} onClose={closeDialog} {...rest} />
    </>
  );
};
