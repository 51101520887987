import React from 'react';
import { Grid } from '@mui/material';
import { OperationPerfectness } from '@priz/shared/src/models/tools/sfm';
import { ArrowForwardRounded } from '@mui/icons-material';
import { pgColorScheme } from '@priz/shared/src/theme';
import { OperationPerfectnessLabel } from '@priz/shared/src/components/operation-perfectness-label/component';

interface PerfectnessChangeResultProps {
  oldPerfectness: OperationPerfectness;
  newPerfectness: OperationPerfectness;
}

export const PerfectnessChangeResult: React.FC<PerfectnessChangeResultProps> = ({ oldPerfectness, newPerfectness }) => {
  return (
    <Grid container spacing={1} alignItems={'center'}>
      <Grid item>
        <OperationPerfectnessLabel operationPerfectness={oldPerfectness} showNotDefined />
      </Grid>

      <Grid item>
        <ArrowForwardRounded fontSize={'small'} sx={{ display: 'block', color: pgColorScheme.gray500 }} />
      </Grid>

      <Grid item>
        <OperationPerfectnessLabel operationPerfectness={newPerfectness} showNotDefined />
      </Grid>
    </Grid>
  );
};
