import axios, { AxiosResponse } from 'axios';
import { INineWindowsUtilization, NineWindowsUtilization } from '@priz/shared/src/models/tools/nine-windows';

const update = (utilization: NineWindowsUtilization): Promise<INineWindowsUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/9w/${utilization.id}`, {
      title: utilization.title,
      pastSupersystem: utilization.pastSupersystem,
      presentSupersystem: utilization.presentSupersystem,
      futureSupersystem: utilization.futureSupersystem,
      pastSystem: utilization.pastSystem,
      presentSystem: utilization.presentSystem,
      futureSystem: utilization.futureSystem,
      pastSubsystem: utilization.pastSubsystem,
      presentSubsystem: utilization.presentSubsystem,
      futureSubsystem: utilization.futureSubsystem,
      conclusion: utilization.conclusion,
    })
    .then((response: AxiosResponse<INineWindowsUtilization>) => response.data);

const doDelete = (utilizationId: number): Promise<any> =>
  axios.delete(`/v1/api/<ws-id>/team/<t-id>/9w/${utilizationId}`);

export const NineWindowsUtilizationApi = {
  delete: doDelete,
  update,
};
