import React from 'react';
import { useStyles } from './styles';
import { ToolUtilization } from '@priz/shared/src/models/tools';
import { ToolTypeDataMap } from '@priz/shared/src/data/tool-type-data-map';
import { Tooltip } from '@mui/material';
import { Trans } from 'react-i18next';

export interface ToolIconProps {
  tool: ToolUtilization;
  size?: 'extra-small' | 'small' | 'medium' | 'large';
}

export const ToolIcon: React.FC<ToolIconProps> = ({ tool, size = 'medium' }) => {
  const styles = useStyles();

  const { type } = tool || {};
  const { name, icon: Icon } = (type && ToolTypeDataMap[type]) || {};

  const iconClassNames = [styles.icon];

  if (size === 'extra-small') {
    iconClassNames.push(styles.iconExtraSmall);
  }

  if (size === 'small') {
    iconClassNames.push(styles.iconSmall);
  }

  if (size === 'medium') {
    iconClassNames.push(styles.iconMedium);
  }

  if (size === 'large') {
    iconClassNames.push(styles.iconLarge);
  }

  if (!Icon) return null;

  return (
    <Tooltip title={name?.length ? <Trans>{name}</Trans> : ''} placement={'top'} disableInteractive arrow>
      <div>
        <Icon className={iconClassNames.join(' ')} />
      </div>
    </Tooltip>
  );
};
