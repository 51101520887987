import { IToolUtilization, ToolUtilization } from '../index';
import { IdRef } from '../../common/id-ref';
import { IRank, Rank } from './index';
import { RrmGoalType } from './rrm-goal-type.enum';

export interface IRrmUtilization extends IToolUtilization {
  goal: IdRef;
  goalType?: RrmGoalType;
  smart?: boolean;

  ranks: Rank[];

  percentageComplete?: number;
}

export class RrmUtilization extends ToolUtilization implements IRrmUtilization {
  public goal: IdRef;
  public goalType?: RrmGoalType;
  public smart?: boolean;

  public ranks: Rank[];

  public percentageComplete?: number;

  constructor(json: IRrmUtilization) {
    super(json);

    this.goal = json.goal;
    this.goalType = json.goalType;
    this.smart = json.smart;

    this.ranks = (json.ranks && json.ranks.map((rankData: IRank) => new Rank(rankData))) || [];
  }
}
