import React, { useState } from 'react';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import { Box, Tab, Tabs } from '@mui/material';
import { ApiKeysManager } from './api-keys-manager/component';
import { useSelector } from 'react-redux';
import { UserContextSelectors } from '../../security/store/selectors';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { ApiKeyStatus } from '@priz/shared/src/models/api-keys';
import { TitleContainer } from '../../react/modules/title-container/component';

export const ApiKeysPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState(ApiKeyStatus.Active);

  const currentUser = useSelector(UserContextSelectors.getCurrentUser);
  const isLoaded = useSelector(UserContextSelectors.isLoaded);

  const handleTabChange = (_event, status: ApiKeyStatus) => {
    setActiveTab(status);
  };

  return (
    <>
      <TitleContainer>
        <PageTitleWithDocLink title={<Trans>API keys</Trans>} />

        <Box display={'flex'} justifyContent={'flex-end'}>
          <Tabs orientation="horizontal" variant="standard" value={activeTab} onChange={handleTabChange}>
            <Tab value={ApiKeyStatus.Active} label={<Trans>Active</Trans>} />
            <Tab value={ApiKeyStatus.Expired} label={<Trans>Expired</Trans>} />
          </Tabs>
        </Box>
      </TitleContainer>

      <div>
        {!isLoaded && <LoadingOverlay loading={true} backdropStyles={{ background: 'transparent' }} />}

        {isLoaded && <ApiKeysManager userId={currentUser.id} statusToShow={activeTab} />}
      </div>
    </>
  );
};
