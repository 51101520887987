import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  tableContainer: {
    overflow: 'visible',
  },
  table: {
    position: 'relative',
    zIndex: 2,
  },
  tableBordered: {
    ['& .MuiTableRow-root.MuiTableRow-hover:hover']: {
      backgroundColor: 'transparent',

      ['& td']: {
        position: 'relative',

        ['&::after']: {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 'auto',
          bottom: 0,
          margin: 'auto',
          background: 'rgba(0, 0, 0, 0.04)',
          width: '100%',
          height: '100%',
          zIndex: -1,
        },

        ['&:last-child']: {
          ['&::after']: {
            width: 'calc(100% - 10px)',
          },
        },
      },
    },

    ['& th:not(:first-child), & td:not(:first-child)']: {
      position: 'relative',

      ['&::before']: {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        background: pgColorScheme.white,
        width: 'calc(100% - 20px)',
        height: '100%',
        zIndex: -2,
        borderStyle: 'solid',
        borderColor: pgColorScheme.lightGray,
        borderWidth: '0 1px 0 1px',
      },
    },

    ['& thead th:not(:first-child)']: {
      ['&::before']: {
        height: 'calc(100% + 10px)',
        top: -10,
        borderRadius: '10px 10px 0 0',
        borderTopWidth: 1,
        background: pgColorScheme.background,
      },
    },
    ['& tbody tr:last-child td:not(:first-child)']: {
      ['&::before']: {
        height: 'calc(100% + 10px)',
        bottom: -10,
        borderRadius: '0 0 10px 10px',
        borderBottomWidth: 1,
      },
    },
    ['& .MuiTableCell-root']: {
      borderBottom: 'none !important',
    },
  },
});
