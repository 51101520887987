import React from 'react';
import { Dialog, DialogContent, DialogActions, DialogProps, Grid } from '@mui/material';
import { AssistantSvg } from '../assistant-svg/component';
import { useStyles } from './styles';
import { HintContent, SharedHintContentProps } from '../hint-content/component';
import { AssistantHintData } from '../store/model';
import { AssistantControls, SharedAssistantControlsProps } from '../assistant-controls/components';

export interface DialogAssistantProps extends DialogProps {
  hintData?: AssistantHintData;
  loading?: boolean;
  contentProps?: SharedHintContentProps;
  onSelectHintOptions?: (options: string[]) => void;
  controlsProps?: SharedAssistantControlsProps;
}

export const DialogAssistant: React.FC<DialogAssistantProps> = ({
  hintData,
  loading,
  contentProps,
  onSelectHintOptions,
  controlsProps,
  ...rest
}) => {
  const styles = useStyles();

  return (
    <Dialog {...rest} onClose={controlsProps?.onClose} fullWidth={true}>
      <DialogContent sx={{ minHeight: 100 }}>
        <HintContent hintData={hintData} loading={loading} onSelect={onSelectHintOptions} {...contentProps} />
      </DialogContent>

      <DialogActions sx={{ paddingTop: 4 }}>
        <Grid container spacing={1} alignItems={'flex-end'}>
          <Grid item xs={true}>
            <AssistantControls
              hintData={hintData}
              hintRowsType={contentProps?.hintRowsType}
              loading={loading}
              {...controlsProps}
            />
          </Grid>

          <Grid item xs={'auto'}>
            <AssistantSvg className={styles.assistantSvg} loading={loading} hint={!!hintData?.text} />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
