import React from 'react';
import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { PartnerProfile } from '@priz/shared/src/models/security/partner-profile';
import { pgColorScheme } from '@priz/shared/src/theme';
import CurrencyFormat from 'react-currency-format';
import { Trans } from 'react-i18next';

interface ProfitTableProps {
  partnerProfile: PartnerProfile;
}

export const ProfitTable: React.FC<ProfitTableProps> = ({ partnerProfile }) => {
  return (
    <TableContainer component={Paper} variant={'outlined'}>
      <Table size={'small'}>
        <TableHead sx={{ backgroundColor: pgColorScheme.background }}>
          <TableRow>
            {['Total', 'Pending payout'].map((item, key) => (
              <TableCell key={key}>
                <Trans>{item}</Trans>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>
              <CurrencyFormat
                value={partnerProfile.totalProfit}
                displayType={'text'}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </TableCell>

            <TableCell>
              <CurrencyFormat
                value={partnerProfile.pendingPayout}
                displayType={'text'}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
