import { NewsletterEmailsSubscriptionInfo, NewsletterSubscriptionStatus } from '../../../security/store/model';
import { AlertColor } from '@mui/material/Alert/Alert';

interface NewsletterSubscriptionPropsResolverResult {
  isSubscribed: boolean;
  isUnsubscribed: boolean;
  isCleaned: boolean;
  isPending: boolean;
  isArchived: boolean;
  isNotExist: boolean;
  alertColor: AlertColor;
  message: string;
}

export const resolveNewsletterSubscriptionProps = (
  info: NewsletterEmailsSubscriptionInfo,
  error?: boolean,
): NewsletterSubscriptionPropsResolverResult => {
  let alertColor: AlertColor = 'info';
  let message = '';

  const isNotExist = info && !info.exist;
  const isSubscribed = info?.status === NewsletterSubscriptionStatus.subscribed;
  const isUnsubscribed = info?.status === NewsletterSubscriptionStatus.unsubscribed;
  const isCleaned = info?.status === NewsletterSubscriptionStatus.cleaned;
  const isPending = info?.status === NewsletterSubscriptionStatus.pending;
  const isArchived = info?.status === NewsletterSubscriptionStatus.archived;

  if (isNotExist || isUnsubscribed) {
    alertColor = 'info';
    message = 'You are not subscribed to receive email news';
  }

  if (isSubscribed) {
    alertColor = 'success';
    message = 'You are subscribed to receive email news';
  }

  if (isCleaned) {
    alertColor = 'warning';
    message = 'Email address is misspelled or invalid';
  }

  if (isPending) {
    alertColor = 'warning';
    message = 'Email address is not confirmed';
  }

  if (isArchived) {
    alertColor = 'warning';
    message = 'Email is excluded from notifications';
  }

  if (error) {
    alertColor = 'error';
    message = 'Something went wrong';
  }

  return {
    isSubscribed,
    isUnsubscribed,
    isCleaned,
    isPending,
    isArchived,
    isNotExist,
    alertColor,
    message,
  };
};
