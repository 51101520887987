import { BreadcrumbsPath } from '../model';
import { conditionalCrumbTypes, generateCrumbsPropsMap, pathTitlesMap } from '../data';
import { copyObject } from '@priz/shared/src/utils/common';
import { useLocation } from 'react-router-dom';

export const useBreadcrumbsPath = (projectId: number, basePath: BreadcrumbsPath = []): { path: BreadcrumbsPath } => {
  const { pathname } = useLocation();
  const crumbsPropsMap = generateCrumbsPropsMap(projectId);

  const pathArray = pathname.split('/');
  const breadcrumbsPath: BreadcrumbsPath = [...basePath];

  pathArray.forEach(path => {
    conditionalCrumbTypes.forEach(item => {
      if (item.condition.includes(path) && crumbsPropsMap[item.type]) {
        const crumb = copyObject(crumbsPropsMap[item.type]);

        if (!crumb.text) {
          crumb.text = pathTitlesMap[path] || 'page';
        }

        breadcrumbsPath.push(crumb);
      }
    });
  });

  return {
    path: breadcrumbsPath,
  };
};
