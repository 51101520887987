import React, { useState } from 'react';
import { Network } from '../../../lib/vis/esnext';
import { NetworkDiagram } from '../../../components/network-diagram/component';
import { edgePropsDecorator, nodePropsDecorator, options } from '../../../data/sfm-options';
import { SfmUtilization, SfmPreset, OptionalComponentRankMap, SfmRank } from '../../../models/tools/sfm';
import { SfmLegend } from '../../../components/sfm-legend/component';
import { SfmResultTable } from '../../../components/sfm-result-table/component';
import { pgColorScheme } from '../../../theme';
import { useNetworkDataset } from '../../../components/network-diagram/utils';
import { Box } from '@mui/material';
import { resolveSfmNetworkData } from '../../../utils/sfm';
import { FullscreenContainer } from '../../../components/fullscreen-container/component';
import { SfmSummary } from '../../../components/sfm-summary/component';
import { DiagramControls } from '../diagram-controls/component';

export interface SfmViewerProps {
  utilization: SfmUtilization;
  componentsRanksMap: OptionalComponentRankMap;
  sfmRank?: SfmRank;
  disableInteractive?: boolean;
}

export const SfmViewer: React.FC<SfmViewerProps> = ({
  utilization,
  componentsRanksMap,
  sfmRank,
  disableInteractive,
}) => {
  const { nodesDataSet, edgesDataSet } = useNetworkDataset(
    resolveSfmNetworkData(utilization.diagramData, undefined, SfmPreset),
    nodePropsDecorator,
    edgePropsDecorator,
  );
  const [visNetwork, setVisNetwork] = useState<Network | null>(null);

  const networkInitHandler = (network: Network) => {
    setVisNetwork(network);
  };

  return (
    <>
      <Box
        style={{
          width: '100%',
          height: '100mm',
          position: 'relative',
          background: pgColorScheme.workbenchBackground,
          boxShadow: `1000px 0 0 ${pgColorScheme.workbenchBackground} inset`,
          borderRadius: 10,
        }}
      >
        <FullscreenContainer
          style={{
            background: pgColorScheme.workbenchBackground,
            width: '100%',
            height: '100%',
          }}
          fullscreenDisabledStyle={{
            borderRadius: 'inherit',
          }}
          usePortal
        >
          {!disableInteractive && visNetwork && (
            <DiagramControls
              onSetCenter={() => {
                visNetwork.fit();
              }}
            />
          )}

          <NetworkDiagram
            options={options}
            nodesDataSet={nodesDataSet}
            edgesDataSet={edgesDataSet}
            onNetworkInit={networkInitHandler}
            viewMode={true}
            disabled={disableInteractive}
          />
        </FullscreenContainer>
      </Box>

      {componentsRanksMap && utilization.diagramData && (
        <>
          {sfmRank && (
            <Box mt={2}>
              <SfmSummary
                sfmDiagramData={utilization.diagramData}
                componentsRanksMap={componentsRanksMap}
                sfmRank={sfmRank}
                useTooltips={!disableInteractive}
                showAnnotations={!disableInteractive}
                compact={true}
              />
            </Box>
          )}

          <Box mt={2}>
            <SfmLegend />
          </Box>

          <Box mt={2}>
            <SfmResultTable componentsRanksMap={componentsRanksMap} sfmDiagramData={utilization.diagramData} />
          </Box>
        </>
      )}
    </>
  );
};
