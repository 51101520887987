import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AppNavbar } from '../navigation/app-navbar/component';
import { PageTitleWithDocLink } from '../shared/PageTitleWithDocLink';
import { SearchInput } from '../react/elements/search-input/component';
import { Alert, Box } from '@mui/material';
import { Trans } from 'react-i18next';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { LinearLoader } from '../react/elements/linear-loader/component';
import { PageContainer } from '../content-containers/page-container/component';
import { AdminWorkspaceCard } from './workspace/component';
import { Workspace } from '@priz/shared/src/models/workspace';
import { UpdateWorkspaceSettingsCommand, WorkspaceApi } from '../workspace/services';

export const WorkspacesManager: React.FC = () => {
  const queryClient = useQueryClient();

  const [loadedWorkspace, setLoadedWorkspace] = useState<Workspace | undefined>();
  const [searchWorkspaceId, setSearchWorkspaceId] = useState<number | undefined>();

  const { isFetching, data, refetch } = useQuery(
    ['workspaces-manager', searchWorkspaceId],
    () => WorkspaceApi.loadWorkspace(searchWorkspaceId),
    {
      enabled: typeof searchWorkspaceId !== 'undefined',
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const mfaMutation = useMutation(
    (data: { workspaceId: number; allowToUpdateMfa: boolean }) => {
      const command: UpdateWorkspaceSettingsCommand = {
        allowToUpdateMfa: data.allowToUpdateMfa,
      };

      return WorkspaceApi.updateSettings(data.workspaceId, command);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['workspaces-manager', searchWorkspaceId]);
      },
      onError: () => {
        void refetch();
      },
    },
  );

  useEffect(() => {
    setLoadedWorkspace(data);
  }, [data]);

  const changeHandler = (text: string) => {
    setSearchWorkspaceId(+text);
  };

  const clearHandler = () => {
    setLoadedWorkspace(undefined);
    setSearchWorkspaceId(undefined);
  };

  const updateMfaHandler = (workspaceId: number, allowToUpdateMfa: boolean) => {
    mfaMutation.mutate({ workspaceId, allowToUpdateMfa });
  };

  return (
    <PageContainer>
      <AppNavbar />

      <>
        <PageTitleWithDocLink title={'Workspaces manager'} />

        <SearchInput
          onChangeDebounced={changeHandler}
          onClear={clearHandler}
          type={'number'}
          placeholder={'Search by id'}
          inputProps={{ min: 0 }}
        />

        <LinearLoader mt={2} loading={isFetching && loadedWorkspace?.id !== searchWorkspaceId} />

        {typeof searchWorkspaceId !== 'undefined' && !isFetching && !loadedWorkspace && (
          <Box>
            <Alert severity={'info'}>
              <Trans>Workspace not found</Trans>
            </Alert>
          </Box>
        )}

        {loadedWorkspace && (
          <Box position={'relative'}>
            <AdminWorkspaceCard workspace={loadedWorkspace} onUpdateMfa={updateMfaHandler} />

            <LoadingOverlay
              loading={isFetching && loadedWorkspace?.id === searchWorkspaceId}
              backdropStyles={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
            />
          </Box>
        )}
      </>
    </PageContainer>
  );
};
