import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectContainer: {
      position: 'absolute',
      top: 0,
      right: 'auto',
      bottom: 'auto',
      left: 0,
      zIndex: 2,
    },
    phoneInput: {
      paddingLeft: 60,
    },

    [theme.breakpoints.down('lg')]: {
      phoneInput: {
        paddingLeft: 54,
      },
    },

    [theme.breakpoints.down('md')]: {
      phoneInput: {
        paddingLeft: 50,
      },
    },

    [theme.breakpoints.down('sm')]: {
      phoneInput: {
        paddingLeft: 46,
      },
    },
  }),
);
