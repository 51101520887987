import { OptionalComponentRankMap, SfmEdgeType, SfmNetworkData, SfmNodeType, SfmRankMap } from '../../models/tools/sfm';

export interface SfmRanksEfficienciesCounterResult {
  effective: number;
  ineffective: number;
}

export const countSfmRanksEfficiencies = (
  sfmRanks: SfmRankMap | OptionalComponentRankMap,
): SfmRanksEfficienciesCounterResult => {
  let effectiveSum = 0;
  let ineffectiveSum = 0;

  Object.values(sfmRanks).forEach(rank => {
    effectiveSum += rank.functionalRank || 0;
    ineffectiveSum += rank.problematicRank || 0;
  });

  return {
    effective: effectiveSum,
    ineffective: ineffectiveSum,
  };
};

export interface SfmFunctionsConnectionsCounterResult {
  basic: number;
  components: number;
  superSystems: number;
}

export const countSfmFunctionsConnections = (data: SfmNetworkData): SfmFunctionsConnectionsCounterResult => {
  let productId: string | number;
  let componentsCount = 0;
  let superSystemsCount = 0;
  let basicFunctionsCount = 0;

  data.nodes.forEach(node => {
    if (node.type === SfmNodeType.PRODUCT) {
      productId = node.id;
    }
    if (node.type === SfmNodeType.COMPONENT) {
      componentsCount += 1;
    }
    if (node.type === SfmNodeType.SUPER_SYSTEM) {
      superSystemsCount += 1;
    }
  });

  data.edges.forEach(edge => {
    if (edge.to === productId && edge.type !== SfmEdgeType.HARMFUL) {
      basicFunctionsCount += 1;
    }
  });

  return {
    basic: basicFunctionsCount,
    components: componentsCount,
    superSystems: superSystemsCount,
  };
};
