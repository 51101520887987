import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { RrmUtilization } from '@priz/shared/src/models/tools/rrm/rrm-utilization';
import { Perception } from '@priz/shared/src/models/tools/rrm';
import { RrmGoalActions } from '../store';

const useStyles = makeStyles({
  actions: {
    textAlign: 'right',
  },
  title: {
    textAlign: 'left',
  },
});

interface RrmPerceptionEditorProps {
  utilization: RrmUtilization;
  perception?: Perception;
  isOpen: boolean;
  onClose: () => void;
}

export const RrmPerceptionEditor: React.FC<RrmPerceptionEditorProps> = ({
  utilization,
  perception,
  isOpen,
  onClose,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [definition, setDefinition] = useState<string>(undefined);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const handlePerceptionValueChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDefinition(event.target.value);
  };

  const handleUpdate = () => {
    dispatch(
      RrmGoalActions.updatePerception(
        utilization.id,
        { ...perception, definition },
        utilization.goal.id,
        utilization.project?.id,
      ),
    );
    handleClose();
  };

  useEffect(() => {
    if (perception) {
      setDefinition(perception.definition);
    }
  }, [perception]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle id="alert-dialog-title" className={styles.title}>
        Edit Perception
      </DialogTitle>

      <DialogContent>
        <TextField
          id="rrm-edit-perception"
          multiline
          value={definition}
          onChange={handlePerceptionValueChange}
          variant={'outlined'}
          fullWidth={true}
          onKeyDown={event => {
            if (event.ctrlKey && event.key === 'Enter') {
              handleUpdate();
            }
          }}
        />
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose}>
          Cancel
        </Button>

        <Button variant={'pg_rounded'} color={'pg_orange_solid'} onClick={handleUpdate}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
