import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CertificateActions, CertificateStatus } from '../store';
import { useTranslation } from 'react-i18next';
import { CertificateSelectors } from '../store/selectors/certificate.selectors';
import { CertificateService } from '../services/certificate.service';
import { useQuery } from 'react-query';

export interface FinalCertificateProps {
  projectId: number;
}

export const FinalCertificate: React.FC<FinalCertificateProps> = ({ projectId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isLoading: certificateImageLoading, data: certificateImageRequest } = useQuery(
    ['certificate-image'],
    () => CertificateService.fetchCertificateImage(projectId),
    { refetchOnWindowFocus: false },
  );

  const certificateLoading = useSelector(CertificateSelectors.isCertificateLoadingByProjectIdSelector(projectId));
  const certificate = useSelector(CertificateSelectors.certificateByProjectIdSelector(projectId));
  const isApproved = certificate?.status === CertificateStatus.Approved;

  useEffect(() => {
    dispatch(CertificateActions.loadCertificateByProjectId(projectId));
  }, []);

  return (
    <>
      {!certificateLoading && !certificateImageLoading && isApproved && (
        <img src={certificateImageRequest?.data} alt={t('Certificate image')} />
      )}
    </>
  );
};
