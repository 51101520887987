import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../store/app.state';
import { UserProjectSettings } from '@priz/shared/src/models/user/user-project-settings';

const UserProjectSettingsSelector = (state: AppState) => state.userProjectSettings;

const settings: Selector<AppState, UserProjectSettings> = createSelector(
  UserProjectSettingsSelector,
  userStateObj => userStateObj.settings,
);

const isLoading: Selector<AppState, boolean> = createSelector(
  UserProjectSettingsSelector,
  userStateObj => userStateObj.loading,
);

const isLoaded: Selector<AppState, boolean> = createSelector(
  UserProjectSettingsSelector,
  userStateObj => userStateObj.loaded,
);

export const UserProjectSettingsSelectors = {
  settings,
  isLoading,
  isLoaded,
};
