import { FluxStandardAction } from 'flux-standard-action';
import { ProjectAssignee } from '@priz/shared/src/models/assignment';
import { createPgAction } from '../../../shared/store/action-creator';
import { ProjectAssignmentApi } from '../../service';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { WorkspaceMember } from '@priz/shared/src/models/workspace/workspace-member';

export enum ProjectAssignmentActionType {
  FetchAll = 'project-assignment/fetch-all',
  Create = 'project-assignment/create',
  Delete = 'project-assignment/delete',
}

export interface NewProjectAssignmentResponse {
  assignee: ProjectAssignee;
  member: WorkspaceMember;
}

type ProjectAssignmentActionPayload = ProjectAssignee | ProjectAssignee[] | NewProjectAssignmentResponse | undefined;

interface ProjectAssignmentActionMeta {
  projectId?: number;
  assigneeEmail?: string;
  assignmentId?: number;
}

export type ProjectAssignmentAction = FluxStandardAction<
  string,
  ProjectAssignmentActionPayload,
  ProjectAssignmentActionMeta
>;

const fetchAll = createPgAction(ProjectAssignmentActionType.FetchAll, ProjectAssignmentApi.fetchAll);

const create = createPgAction(
  ProjectAssignmentActionType.Create,
  ProjectAssignmentApi.create,
  (projectId: number) => ({
    projectId,
  }),
  (_payload: NewProjectAssignmentResponse, { projectId }) => {
    AnalyticsService.track(AnalyticsEvent.PROJECT_ASSIGNEE_CREATED, {
      project_id: projectId,
    });
  },
);

const doDelete = createPgAction(
  ProjectAssignmentActionType.Delete,
  ProjectAssignmentApi.delete,
  (projectId: number, assigneeEmail: string, assignmentId: number) => ({ projectId, assigneeEmail, assignmentId }),
  (_payload: ProjectAssignee, { projectId }) => {
    AnalyticsService.track(AnalyticsEvent.PROJECT_ASSIGNEE_DELETED, {
      project_id: projectId,
    });
  },
);

export const ProjectAssigneeActions = {
  fetchAll,
  create,
  delete: doDelete,
};
