import React from 'react';
import { Outlet } from 'react-router-dom';
import { PageContainer } from '../../content-containers/page-container/component';
import { AppNavbar } from '../../navigation/app-navbar/component';
import { PayWall } from '../../react/modules/paywall/component';
import { ProjectFeatureBlocker } from '../../project-feature-blocker/component';

export const TeamRoot: React.FC = () => {
  return (
    <>
      <PageContainer variant={'clear'}>
        <AppNavbar />
        <Outlet />
      </PageContainer>

      <PayWall />
      <ProjectFeatureBlocker />
    </>
  );
};
