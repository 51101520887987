import React, { useState } from 'react';
import { Box, Button, ButtonProps, IconButton, Tooltip, TooltipProps } from '@mui/material';
import {
  AutoFixHighOutlined,
  CheckCircleOutlined,
  ErrorOutlineOutlined,
  InfoOutlined,
  RefreshRounded,
} from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { AssistantHintData } from '../store/model';
import { useStyles } from './styles';

const resolveCompletenessButtonProps = (value: number): Partial<ButtonProps> => {
  if (value >= 70)
    return {
      startIcon: <CheckCircleOutlined />,
      color: 'success',
    };

  if (value >= 30)
    return {
      startIcon: <InfoOutlined />,
      color: 'primary',
    };

  return {
    startIcon: <ErrorOutlineOutlined />,
    color: 'error',
  };
};

type AssistantButtonVariant = 'button' | 'icon' | 'completeness';

export interface AssistantButtonBaseProps {
  variant?: AssistantButtonVariant;
  tooltip?: string;
  tooltipPlacement?: 'bottom' | 'left' | 'right' | 'top';
  text?: string;
  className?: string;
  containerClassName?: string;
}

interface AssistantButtonProps extends AssistantButtonBaseProps {
  hintData?: AssistantHintData;
  disabled?: boolean;
  onGetHintClick?: (ref: HTMLButtonElement) => void;
  onViewHintClick?: (ref: HTMLButtonElement) => void;
}

export const AssistantButton: React.FC<AssistantButtonProps> = ({
  variant = 'button',
  tooltip,
  tooltipPlacement,
  text,
  hintData,
  className,
  disabled,
  onGetHintClick,
  onViewHintClick,
}) => {
  const styles = useStyles();

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const openTooltip = () => {
    setTooltipIsOpen(true);
  };

  const closeTooltip = () => {
    setTooltipIsOpen(false);
  };

  const getHintClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    closeTooltip();
    if (onGetHintClick) onGetHintClick(e.currentTarget);
  };

  const viewHintClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    closeTooltip();
    if (onViewHintClick) onViewHintClick(e.currentTarget);
  };

  const tooltipProps: Omit<TooltipProps, 'children'> = {
    title: tooltip?.length ? <Trans>{tooltip}</Trans> : '',
    open: tooltipIsOpen,
    onOpen: openTooltip,
    onClose: closeTooltip,
    placement: tooltipPlacement || 'top',
    arrow: true,
    disableInteractive: true,
  };

  if (variant === 'button') {
    return (
      <Tooltip {...tooltipProps}>
        <div>
          <Button
            variant={'outlined'}
            endIcon={<AutoFixHighOutlined />}
            size={'small'}
            disabled={disabled}
            className={className}
            onClick={getHintClickHandler}
          >
            <Trans>{text || 'Get a hint'}</Trans>
          </Button>
        </div>
      </Tooltip>
    );
  }

  if (variant === 'icon') {
    return (
      <Tooltip {...tooltipProps}>
        <div>
          <IconButton className={className} onClick={getHintClickHandler}>
            <AutoFixHighOutlined />
          </IconButton>
        </div>
      </Tooltip>
    );
  }

  if (variant === 'completeness') {
    return (
      <Box display={'flex'}>
        <Tooltip {...tooltipProps}>
          <div>
            <Button
              variant={'contained'}
              size={'small'}
              {...resolveCompletenessButtonProps(hintData.completeness)}
              className={styles.scoreButton}
              onClick={viewHintClickHandler}
            >
              {hintData.completeness} / 100
            </Button>
          </div>
        </Tooltip>

        <Button
          variant={'outlined'}
          size={'small'}
          startIcon={<RefreshRounded />}
          disabled={disabled}
          className={styles.refreshButton}
          onClick={getHintClickHandler}
        >
          <Trans>Refresh</Trans>
        </Button>
      </Box>
    );
  }

  return null;
};
