import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SideNavItem } from '../../shared/section-sidenav-item/component';
import { Trans } from 'react-i18next';
import { AttendeeActions, MeetingActions } from '../store/actions';
import { Badge } from '@mui/material';
import { UserContextSelectors } from '../../security/store/selectors';
import { AttendeeSelectors, MeetingSelectors } from '../store/selectors';
import { filterAwaitingResponseMeetings } from '../utils';

import { ReactComponent as MeetingIcon } from '../../../assets/icons/meeting-2.svg';

export interface MeetingsMenuItemProps {
  projectId: number;
  onClick?: () => void;
  id?: string;
}

export const MeetingsMenuItem: React.FC<MeetingsMenuItemProps> = ({ projectId, onClick, id }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(UserContextSelectors.getCurrentUser);
  const meetings = useSelector(MeetingSelectors.getAllByProjectId(projectId));
  const attendeeRequests = useSelector(AttendeeSelectors.getAllByProjectIdAndUserId(projectId, currentUser?.id));

  const awaitingResponseMeetings = filterAwaitingResponseMeetings(meetings, attendeeRequests, currentUser?.id);

  useEffect(() => {
    dispatch(AttendeeActions.getAll(projectId));
    dispatch(MeetingActions.getAll(projectId));
  }, []);

  const clickHandler = () => {
    if (onClick) onClick();
  };

  return (
    <SideNavItem
      id={id}
      title={<Trans>Meetings</Trans>}
      icon={
        awaitingResponseMeetings.length ? (
          <Badge badgeContent={awaitingResponseMeetings.length} color="secondary">
            <MeetingIcon />
          </Badge>
        ) : (
          <MeetingIcon />
        )
      }
      navigate={['/project', projectId, 'meetings']}
      onClick={clickHandler}
    />
  );
};
