import React from 'react';
import { useSelector } from 'react-redux';
import { ProjectAssigneeSelectors } from '../store/selectors/project-assignee.selectors';
import { AvatarGroup, AvatarGroupProps, AvatarProps, Tooltip } from '@mui/material';
import { UserAvatar } from '@priz/shared/src/components/user-avatar/component';
import { ProjectAssigneeWithMember } from '@priz/shared/src/models/assignment';
import { UserUtils } from '@priz/shared/src/utils/user/user-utils';

interface UserAvatarWithTooltipProps extends AvatarProps {
  assignee: ProjectAssigneeWithMember;
}

const UserAvatarWithTooltip: React.FC<UserAvatarWithTooltipProps> = ({ assignee, ...rest }) => {
  return (
    <Tooltip title={UserUtils.getShowableName(assignee.user)} placement="top" arrow={true} disableInteractive={true}>
      <div>
        <UserAvatar user={assignee.user} key={assignee.id} {...rest} />
      </div>
    </Tooltip>
  );
};

interface ProjectAssigneesProps extends AvatarGroupProps {
  projectId: number;
  avatarProps?: Partial<AvatarProps>;
}

export const ProjectAssignees: React.FC<ProjectAssigneesProps> = ({ projectId, avatarProps, ...rest }) => {
  const assignments = useSelector(ProjectAssigneeSelectors.getProjectAssignees(projectId));

  if (assignments.length === 0) {
    return null;
  }

  return (
    <AvatarGroup max={4} spacing={10} {...rest}>
      {assignments.map(assignee => (
        <UserAvatarWithTooltip assignee={assignee} key={assignee.id} {...avatarProps} />
      ))}
    </AvatarGroup>
  );
};
