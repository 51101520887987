export enum ToolType {
  TOOL_40_PRINCIPLES = 'TOOL_40_PRINCIPLES',
  RRM = 'RRM',
  UIM = 'UIM',
  FIVE_WHYS = 'FIVE_WHYS',
  APA = 'APA',
  EBS = 'EBS',
  NINE_WINDOWS = 'NINE_WINDOWS',
  CEC = 'CEC',
  P_MAP = 'P_MAP',
  SFM = 'SFM',
  PFM = 'PFM',
  CFT = 'CFT',
}

export const ToolTypeUrlKeyMap = {
  [ToolType.TOOL_40_PRINCIPLES]: '40-principles',
  [ToolType.RRM]: 'rrm',
  [ToolType.UIM]: 'uim',
  [ToolType.CEC]: 'cec',
  [ToolType.APA]: 'apa',
  [ToolType.EBS]: 'ebs',
  [ToolType.NINE_WINDOWS]: '9-w',
  [ToolType.FIVE_WHYS]: '5-whys',
  [ToolType.P_MAP]: 'p-map',
  [ToolType.SFM]: 'sfm',
  [ToolType.PFM]: 'pfm',
  [ToolType.CFT]: 'cft',
};

export const ToolTypeAPIUrlKeyMap = {
  [ToolType.TOOL_40_PRINCIPLES]: '40p',
  [ToolType.RRM]: 'rrm',
  [ToolType.UIM]: 'uim',
  [ToolType.CEC]: 'cec',
  [ToolType.APA]: 'apa',
  [ToolType.EBS]: 'ebs',
  [ToolType.NINE_WINDOWS]: '9w',
  [ToolType.FIVE_WHYS]: '5-whys',
  [ToolType.P_MAP]: 'p-map',
  [ToolType.SFM]: 'sfm',
  [ToolType.PFM]: 'pfm',
  [ToolType.CFT]: 'cft',
};
