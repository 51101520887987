import { UimPriority } from '@priz/shared/src/models/tools/uim';
import { Task, TaskStatus } from '@priz/shared/src/models/task';

export enum TaskListCategory {
  Active = 'Active',
  Completed = 'Completed',
  NotDefined = 'NotDefined',
}

export type CategorizedTasks = {
  [key in TaskListCategory]?: {
    [key in UimPriority]?: Task[];
  };
};

export const resolveTaskListCategory = (task: Task): TaskListCategory => {
  if ([TaskStatus.Todo, TaskStatus.InProgress].includes(task.status)) {
    return TaskListCategory.Active;
  }

  if (task.status === TaskStatus.Done) {
    return TaskListCategory.Completed;
  }

  return TaskListCategory.NotDefined;
};

export const categorizeTasks = (tasks: Task[]): CategorizedTasks => {
  const result: CategorizedTasks = {};

  tasks.forEach(task => {
    const category = resolveTaskListCategory(task);

    result[category] = {
      ...(result[category] || {}),
      [task.priority]: [...(result[category]?.[task.priority] || []), task],
    };
  });

  return result;
};
