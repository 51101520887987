import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link } from '@mui/material';
import { LockOutlined, PublicOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { PMapUtilization } from '@priz/shared/src/models/tools/pmap';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import { PerceptionMapUpdatePublicAccessLevelCommand } from '../service';
import { AccessLevel } from '@priz/shared/src/models/security/access-level';
import { PMapUtilizationActions } from '../store/actions';
import { FieldTitle, ReactHookFormSelect } from '../../react/form-elements';
import { CopyToClipboardButton } from '../../copy-to-clipboard-button/components';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { FeatureSetSelectors } from '../../workspace/store/selectors/feature-set.selectors';
import { PaywallActions } from '../../react/modules/paywall/store';
import { FeatureLimit } from '@priz/shared/src/models/workspace';

interface PMapShareButtonProps {
  utilization: PMapUtilization;
  disabled?: boolean;
}

const accessLevels = [
  AccessLevel.Anonymous,
  // AccessLevel.Registered,
  AccessLevel.Workspace,
  AccessLevel.Project,
];

const accessLevelLabels: { [key in AccessLevel]: string } = {
  [AccessLevel.Anonymous]: 'Open for everyone',
  // [AccessLevel.Registered]: 'Any registered users',
  [AccessLevel.Workspace]: 'Workspace users',
  [AccessLevel.Project]: 'Project users',
};

export const PMapShareButton: React.FC<PMapShareButtonProps> = ({ utilization, disabled }) => {
  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const isDataCrowdSourcingEnabled = useSelector(
    FeatureSetSelectors.isDataCrowdSourcingEnabled(utilization.project?.id),
  );
  const isUpdating = useSelector(ToolUtilizationSelector.isUpdatingByUtilizationId(utilization.id));

  const { control, reset } = useForm({
    shouldFocusError: false,
    defaultValues: {
      publicAccessLevel: utilization.publicAccessLevel || '',
    },
  });

  const selectedPublicAccessLevel = useWatch({
    control,
    name: 'publicAccessLevel',
  });

  useEffect(() => {
    if (isDialogOpen) {
      reset({ publicAccessLevel: utilization.publicAccessLevel || '' });
    }
  }, [isDialogOpen]);

  const publicLink = `${window.location.origin}/public-pmap/${utilization.id}`;

  const changePublicAccessHandler = () => {
    const command: PerceptionMapUpdatePublicAccessLevelCommand = {
      accessLevel: selectedPublicAccessLevel as AccessLevel,
    };

    dispatch(PMapUtilizationActions.updatePublicAccessLevel(utilization.id, command, utilization.project?.id));
  };

  const openDialog = () => {
    if (isDataCrowdSourcingEnabled) {
      setIsDialogOpen(true);
    } else {
      dispatch(PaywallActions.show(FeatureLimit.DataCrowdSourcing));
    }
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Button
        onClick={openDialog}
        startIcon={utilization.publicAccessLevel ? <PublicOutlined /> : <LockOutlined />}
        color={utilization.publicAccessLevel ? 'success' : 'primary'}
        size={'small'}
        disabled={disabled}
      >
        <Trans>{utilization.publicAccessLevel ? 'Shared' : 'Share'}</Trans>
      </Button>

      <Dialog open={isDialogOpen} onClose={closeDialog} maxWidth={'sm'} fullWidth>
        <DialogTitle>
          <Trans>Sharing settings</Trans>
        </DialogTitle>

        <DialogContent>
          <Box mb={2}>
            {!utilization.publicAccessLevel && (
              <Alert severity={'info'}>
                <Trans>
                  To generate a sharable link, select the access level for the public page. This page will allow users
                  to contribute their perceptions.
                </Trans>
              </Alert>
            )}

            {utilization.publicAccessLevel && (
              <>
                <FieldTitle text={'Link'} />

                <Grid container spacing={1} alignItems={'center'}>
                  <Grid item>
                    <Chip label={<Link href={publicLink}>{publicLink}</Link>} />
                  </Grid>

                  <Grid item>
                    <CopyToClipboardButton value={publicLink} />
                  </Grid>
                </Grid>
              </>
            )}
          </Box>

          <ReactHookFormSelect
            control={control}
            name={'publicAccessLevel'}
            fieldTitle={'Access level'}
            options={[
              { value: '', text: 'Not accessible' },
              ...accessLevels.map(level => ({ value: level, text: accessLevelLabels[level] })),
            ]}
            wrapperProps={{ mb: 0 }}
            selectProps={{ size: 'small' }}
            displayEmpty
          />
        </DialogContent>

        <DialogActions sx={{ textAlign: 'right' }}>
          <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={closeDialog}>
            <Trans>{selectedPublicAccessLevel === (utilization.publicAccessLevel || '') ? 'Close' : 'Cancel'}</Trans>
          </Button>

          <LoadingButton
            variant={'pg_rounded'}
            color={'pg_orange_solid'}
            onClick={changePublicAccessHandler}
            loading={isUpdating}
            disabled={isUpdating || selectedPublicAccessLevel === (utilization.publicAccessLevel || '')}
          >
            <Trans>Submit</Trans>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
