import { DefaultEntityCollectionStatus, EntityCollectionStatus, EntityMap } from '../common/entity-collection-state';
import { Plan } from './plan';

export interface PlanCollection {
  entities: EntityMap<Plan>;

  statuses: EntityCollectionStatus;
}

export const DefaultPlanCollection: PlanCollection = {
  entities: {},

  statuses: DefaultEntityCollectionStatus,
};
