import { Navigate, RouteObject } from 'react-router-dom';
import { RouteRenderer } from './route-renderer/component';
import { TeamRoot } from '../team/team-root/component';
import { toolsRoutes } from './tools-routes';

export const teamRoutes: RouteObject[] = [
  {
    path: 'tools',
    element: <RouteRenderer Component={TeamRoot} authGuard={true} />,
    children: [
      ...toolsRoutes,
      {
        path: '',
        element: <Navigate to="/" replace={true} />,
      },
    ],
  },
];
