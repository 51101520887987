import { handleActions, combineActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { ChatAction, ChatActionType } from '../actions';
import { Chat, ChatState, ConversationMessage, ConversationRole, DefaultChatState, IChat } from '../model';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { copyObject } from '@priz/shared/src/utils/common';
import { ArrayUtil } from '../../../shared/utils/array-util';
import { CollectionStatusesService } from '@priz/shared/src/services/statuses/collection-statuses.service';
import { ConversationCommand } from '../../services/chat.api';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { safeParseJson } from '@priz/shared/src/utils/common';

const chatsInstantiator = (iChats: IChat[]): Chat[] => iChats.map(chat => new Chat(chat));

const resolveChatId = (projectId: number, chats: Chat[], useLsId?: boolean): number => {
  const chatIds = chats.map(chat => chat.id);
  const lsLastViewedChats = LocalStorageService.getItem(LocalStorageKey.AiLastViewedChats);
  const lsChatId = safeParseJson(lsLastViewedChats)[projectId];
  const isChatsContainsLsId = typeof lsChatId !== 'undefined' && chatIds.includes(parseInt(lsChatId));

  return useLsId && isChatsContainsLsId ? parseInt(lsChatId) : chats[chats.length - 1]?.id;
};

const setChats = (
  state: ChatState,
  chats: Chat[],
  projectId: number,
  statuses?: Partial<EntityCollectionStatus>,
  setActiveChatId?: boolean,
  useLsChatId?: boolean,
): ChatState => {
  const entitiesCopy = { ...state.entities };
  const lookupsCopy = copyObject(state.lookups);

  if (entitiesCopy[-projectId]) {
    delete entitiesCopy[-projectId];
    lookupsCopy.byProjectId[projectId] = lookupsCopy.byProjectId[projectId].filter(id => id !== -projectId);
  }

  chats.forEach(chat => {
    entitiesCopy[chat.id] = chat;
  });

  chats.forEach(chat => {
    lookupsCopy.byProjectId[chat.project.id] = ArrayUtil.concatInDistinctArray(
      lookupsCopy.byProjectId[chat.project.id],
      chat.id,
    );
  });

  return {
    ...state,
    activeChatId:
      setActiveChatId || typeof state.activeChatId === 'undefined'
        ? resolveChatId(projectId, chats, useLsChatId)
        : state.activeChatId,
    entities: entitiesCopy,
    lookups: lookupsCopy,
    statuses: CollectionStatusesService.resolveStatusesByProjectId(state.statuses, projectId, statuses || {}),
  };
};

const setMessagePreview = (
  state: ChatState,
  projectId: number,
  command: ConversationCommand,
  workspaceId: number,
  userId: number,
  statuses?: Partial<EntityCollectionStatus>,
): ChatState => {
  const entitiesCopy = { ...state.entities };
  const lookupsCopy = copyObject(state.lookups);

  const message: ConversationMessage = {
    role: ConversationRole.User,
    content: command.message,
    dateCreated: new Date().getTime(),
    userId,
  };

  let activeChatId = state.activeChatId;

  if (typeof command.chatId !== 'undefined') {
    if (entitiesCopy[command.chatId]) {
      entitiesCopy[command.chatId] = {
        ...entitiesCopy[command.chatId],
        conversation: {
          ...entitiesCopy[command.chatId].conversation,
          messages: [...entitiesCopy[command.chatId].conversation.messages, message],
        },
      };
    }
  } else {
    const tmpChat = new Chat({
      id: -projectId,
      workspace: { id: workspaceId },
      project: { id: projectId },
      user: { id: userId },
      createdBy: { id: userId },
      dateCreated: new Date(),
      conversation: {
        messages: [message],
      },
    });

    entitiesCopy[tmpChat.id] = tmpChat;

    lookupsCopy.byProjectId[projectId] = ArrayUtil.concatInDistinctArray(
      lookupsCopy.byProjectId[projectId],
      tmpChat.id,
    );

    activeChatId = tmpChat.id;
  }

  return {
    ...state,
    activeChatId,
    entities: entitiesCopy,
    lookups: lookupsCopy,
    statuses: CollectionStatusesService.resolveStatusesByProjectId(state.statuses, projectId, statuses || {}),
  };
};

const removeChat = (
  state: ChatState,
  projectId: number,
  chatId: number,
  statuses?: Partial<EntityCollectionStatus>,
): ChatState => {
  const entitiesCopy = { ...state.entities };
  const lookupsCopy = copyObject(state.lookups);

  delete entitiesCopy[chatId];

  lookupsCopy.byProjectId[projectId] = lookupsCopy.byProjectId[projectId].filter(id => id !== chatId);

  const firstProjectChatId = lookupsCopy.byProjectId[projectId]?.length
    ? lookupsCopy.byProjectId[projectId][0]
    : undefined;

  return {
    ...state,
    activeChatId: chatId === state.activeChatId ? firstProjectChatId : state.activeChatId,
    entities: entitiesCopy,
    lookups: lookupsCopy,
    statuses: CollectionStatusesService.resolveStatusesByProjectId(state.statuses, projectId, statuses || {}),
  };
};

export const chatReducers = handleActions(
  {
    // LoadAll

    [toStarted(ChatActionType.LoadAll)]: (state: ChatState, action: ChatAction) =>
      CollectionStatusesService.setStatusesByProjectId(state, action.meta.projectId, {
        loading: true,
        loaded: false,
        error: false,
      }),

    [toFailed(ChatActionType.LoadAll)]: (state: ChatState, action: ChatAction) =>
      CollectionStatusesService.setStatusesByProjectId(state, action.meta.projectId, {
        loading: false,
        loaded: false,
        error: true,
      }),

    [toSuccess(ChatActionType.LoadAll)]: (state: ChatState, action: ChatAction) =>
      setChats(
        state,
        chatsInstantiator(action.payload as IChat[]),
        action.meta.projectId,
        { loading: false, loaded: true, error: false },
        true,
        true,
      ),

    // SendMessage, Retry

    [toStarted(ChatActionType.SendMessage)]: (state: ChatState, action: ChatAction) =>
      setMessagePreview(
        state,
        action.meta.projectId,
        action.meta.conversationCommand,
        action.meta.workspaceId,
        action.meta.userId,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses.byProjectId[action.meta.projectId],
          saving: false,
          saved: false,
          error: true,
          idsUpdate: [
            { target: 'savingIds', method: 'add', ids: [-action.meta.projectId, action.meta.chatId] },
            { target: 'savedIds', method: 'remove', ids: [-action.meta.projectId, action.meta.chatId] },
            { target: 'errorIds', method: 'remove', ids: [-action.meta.projectId, action.meta.chatId] },
          ],
        }),
      ),

    [toStarted(ChatActionType.Retry)]: (state: ChatState, action: ChatAction) =>
      CollectionStatusesService.setStatusesByProjectId(
        state,
        action.meta.projectId,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses.byProjectId[action.meta.projectId],
          saving: false,
          saved: false,
          error: true,
          idsUpdate: [
            { target: 'savingIds', method: 'add', ids: [-action.meta.projectId, action.meta.chatId] },
            { target: 'savedIds', method: 'remove', ids: [-action.meta.projectId, action.meta.chatId] },
            { target: 'errorIds', method: 'remove', ids: [-action.meta.projectId, action.meta.chatId] },
          ],
        }),
      ),

    [combineActions(toFailed(ChatActionType.SendMessage), toFailed(ChatActionType.Retry))]: (
      state: ChatState,
      action: ChatAction,
    ) =>
      CollectionStatusesService.setStatusesByProjectId(
        state,
        action.meta.projectId,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses.byProjectId[action.meta.projectId],
          saving: false,
          saved: false,
          error: true,
          idsUpdate: [
            { target: 'savingIds', method: 'remove', ids: [-action.meta.projectId, action.meta.chatId] },
            { target: 'savedIds', method: 'remove', ids: [-action.meta.projectId, action.meta.chatId] },
            { target: 'errorIds', method: 'add', ids: [-action.meta.projectId, action.meta.chatId] },
          ],
        }),
      ),

    [combineActions(toSuccess(ChatActionType.SendMessage), toSuccess(ChatActionType.Retry))]: (
      state: ChatState,
      action: ChatAction,
    ) =>
      setChats(
        state,
        chatsInstantiator([action.payload as IChat]),
        action.meta.projectId,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses.byProjectId[action.meta.projectId],
          saving: false,
          saved: true,
          error: false,
          idsUpdate: [
            { target: 'savingIds', method: 'remove', ids: [-action.meta.projectId, action.meta.chatId] },
            { target: 'savedIds', method: 'add', ids: [-action.meta.projectId, action.meta.chatId] },
            { target: 'errorIds', method: 'remove', ids: [-action.meta.projectId, action.meta.chatId] },
          ],
        }),
        true,
        false,
      ),

    // Create

    [toStarted(ChatActionType.Create)]: (state: ChatState, action: ChatAction) =>
      CollectionStatusesService.setStatusesByProjectId(state, action.meta.projectId, { creating: true }),

    [toFailed(ChatActionType.Create)]: (state: ChatState, action: ChatAction) =>
      CollectionStatusesService.setStatusesByProjectId(state, action.meta.projectId, {
        creating: false,
        created: false,
        error: true,
      }),

    [toSuccess(ChatActionType.Create)]: (state: ChatState, action: ChatAction) =>
      setChats(
        state,
        chatsInstantiator([action.payload as IChat]),
        action.meta.projectId,
        { creating: false, created: true, error: false },
        true,
        false,
      ),

    // Update

    [toStarted(ChatActionType.Update)]: (state: ChatState, action: ChatAction) =>
      CollectionStatusesService.setStatusesByProjectId(
        state,
        action.meta.projectId,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses.byProjectId[action.meta.projectId],
          updating: true,
          updated: false,
          error: false,
          idsUpdate: [
            { target: 'updatingIds', method: 'add', ids: [action.meta.chatId] },
            { target: 'updatedIds', method: 'remove', ids: [action.meta.chatId] },
          ],
        }),
      ),

    [toFailed(ChatActionType.Update)]: (state: ChatState, action: ChatAction) =>
      CollectionStatusesService.setStatusesByProjectId(
        state,
        action.meta.projectId,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses.byProjectId[action.meta.projectId],
          updating: false,
          updated: false,
          error: true,
          idsUpdate: [{ target: 'updatingIds', method: 'remove', ids: [action.meta.chatId] }],
        }),
      ),

    [toSuccess(ChatActionType.Update)]: (state: ChatState, action: ChatAction) =>
      setChats(
        state,
        chatsInstantiator([action.payload as IChat]),
        action.meta.projectId,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses.byProjectId[action.meta.projectId],
          updating: false,
          updated: true,
          error: false,
          idsUpdate: [
            { target: 'updatingIds', method: 'remove', ids: [action.meta.chatId] },
            { target: 'updatedIds', method: 'add', ids: [action.meta.chatId] },
          ],
        }),
      ),

    // Delete

    [toStarted(ChatActionType.Delete)]: (state: ChatState, action: ChatAction) =>
      CollectionStatusesService.setStatusesByProjectId(
        state,
        action.meta.projectId,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses.byProjectId[action.meta.projectId],
          removing: true,
          removed: false,
          error: false,
          idsUpdate: [
            { target: 'removingIds', method: 'add', ids: [action.meta.chatId] },
            { target: 'removedIds', method: 'remove', ids: [action.meta.chatId] },
          ],
        }),
      ),

    [toFailed(ChatActionType.Delete)]: (state: ChatState, action: ChatAction) =>
      CollectionStatusesService.setStatusesByProjectId(
        state,
        action.meta.projectId,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses.byProjectId[action.meta.projectId],
          removing: false,
          removed: false,
          error: true,
          idsUpdate: [{ target: 'removingIds', method: 'remove', ids: [action.meta.chatId] }],
        }),
      ),

    [toSuccess(ChatActionType.Delete)]: (state: ChatState, action: ChatAction) =>
      removeChat(
        state,
        action.meta.projectId,
        action.meta.chatId,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses.byProjectId[action.meta.projectId],
          removing: false,
          removed: true,
          error: false,
          idsUpdate: [
            { target: 'removingIds', method: 'remove', ids: [action.meta.chatId] },
            { target: 'removedIds', method: 'add', ids: [action.meta.chatId] },
          ],
        }),
      ),

    // SetActiveChat

    [toSuccess(ChatActionType.SetActiveChat)]: (state: ChatState, action: ChatAction) => ({
      ...state,
      activeChatId: action.meta.chatId,
    }),
  },

  DefaultChatState,
);
