import React from 'react';
import { useStyles } from './styles';
import { Box, BoxProps, SxProps, Typography, Tooltip, Chip, ChipProps } from '@mui/material';
import { pgColorScheme } from '../../theme';
import { ProgressStatus } from '../../models/common/progress-status';
import { TaskStatus } from '../../models/task';
import { ProjectStatus } from '../../models/project';
import { ApaActionStatus } from '../../models/tools/apa';
import { IdeaStatus } from '../../models/idea';
import { MeetingStatus } from '../../models/meetings';
import { Trans } from 'react-i18next';

import {
  CheckCircleOutlineOutlined,
  PublishedWithChangesOutlined,
  AccessTimeOutlined,
  DoNotDisturbAltOutlined,
  SettingsOutlined,
  UpdateRounded,
  CheckCircleOutlineRounded,
  HistoryEduOutlined,
  NewReleasesOutlined,
  ErrorOutlineOutlined,
  SportsScoreOutlined,
} from '@mui/icons-material';

export const StatusesTypes = {
  ...ProgressStatus,
  ...TaskStatus,
  ...ProjectStatus,
  ...ApaActionStatus,
  ...IdeaStatus,
  ...MeetingStatus,
};

const resolveProps = (
  status: keyof typeof StatusesTypes,
  size = 18,
): {
  color?: string;
  text?: string;
  disabledText?: string;
  icon?: JSX.Element;
} => {
  const svgSxProps: SxProps = {
    display: 'block',
    width: size,
    height: size,
  };

  switch (status) {
    case StatusesTypes.New:
      return {
        icon: <SettingsOutlined sx={{ ...svgSxProps, color: pgColorScheme.blue }} />,
        color: pgColorScheme.blue,
        text: 'Process this case',
        disabledText: 'Not processed',
      };

    case StatusesTypes.Todo:
      return {
        icon: <SettingsOutlined sx={{ ...svgSxProps, color: pgColorScheme.blue }} />,
        color: pgColorScheme.blue,
        text: 'Todo',
      };
    case StatusesTypes.Done:
      return {
        icon: <CheckCircleOutlineRounded sx={{ ...svgSxProps, color: pgColorScheme.green }} />,
        color: pgColorScheme.green,
        text: 'Done',
      };

    case StatusesTypes.InProgress:
      return {
        icon: <UpdateRounded sx={{ ...svgSxProps, color: pgColorScheme.coral }} />,
        color: pgColorScheme.coral,
        text: 'In progress',
        disabledText: 'Not completed',
      };

    case StatusesTypes.Completed:
      return {
        color: pgColorScheme.textGray,
        text: 'Completed',
      };

    case StatusesTypes.Archived:
      return {
        color: pgColorScheme.textGray,
        text: 'Archived',
      };

    case StatusesTypes.Pending:
      return {
        icon: <AccessTimeOutlined sx={{ ...svgSxProps, color: pgColorScheme.orange }} />,
        color: pgColorScheme.orange,
        text: 'Pending',
      };

    case StatusesTypes.Approved:
      return {
        icon: <CheckCircleOutlineOutlined sx={{ ...svgSxProps, color: pgColorScheme.blue }} />,
        color: pgColorScheme.blue,
        text: 'Approved',
      };

    case StatusesTypes.Rejected:
      return {
        icon: <DoNotDisturbAltOutlined sx={{ ...svgSxProps, color: pgColorScheme.coral }} />,
        color: pgColorScheme.coral,
        text: 'Rejected',
      };

    case StatusesTypes.Implemented:
      return {
        icon: <PublishedWithChangesOutlined sx={{ ...svgSxProps, color: pgColorScheme.green }} />,
        color: pgColorScheme.green,
        text: 'Implemented',
      };

    case StatusesTypes.Draft:
      return {
        icon: <HistoryEduOutlined sx={{ ...svgSxProps, color: pgColorScheme.textGray }} />,
        color: pgColorScheme.textGray,
        text: 'Draft',
      };

    case StatusesTypes.Released:
      return {
        icon: <NewReleasesOutlined sx={{ ...svgSxProps, color: pgColorScheme.blue }} />,
        color: pgColorScheme.blue,
        text: 'Released',
      };

    case StatusesTypes.Active:
      return {
        icon: <ErrorOutlineOutlined sx={{ ...svgSxProps, color: pgColorScheme.green }} />,
        color: pgColorScheme.green,
        text: 'Active',
      };

    case StatusesTypes.Finished:
      return {
        icon: <SportsScoreOutlined sx={{ ...svgSxProps, color: pgColorScheme.textGray }} />,
        color: pgColorScheme.textGray,
        text: 'Finished',
      };

    default:
      return {};
  }
};

interface StatusProps {
  status: keyof typeof StatusesTypes;
  size?: number;
  showLabel?: boolean;
  showTooltip?: boolean;
  iconBoxPros?: BoxProps;
  containerBoxPros?: BoxProps;
  containerChipPros?: ChipProps;
  variant?: 'default' | 'chip';
  disabled?: boolean;
}

export const Status: React.FC<StatusProps> = ({
  status,
  size,
  showLabel = true,
  showTooltip = false,
  variant = 'default',
  iconBoxPros,
  containerBoxPros,
  containerChipPros,
  disabled,
}) => {
  const styles = useStyles();
  const { icon, color = pgColorScheme.textGray, text, disabledText } = resolveProps(status, size);
  const textToShow = disabled ? disabledText || text : text;

  if (!status) return null;

  if (variant === 'default') {
    return (
      <Box className={styles.root} style={{ color: color }} {...containerBoxPros}>
        <Tooltip
          title={showTooltip && textToShow ? textToShow : ''}
          arrow={true}
          placement={'top'}
          disableInteractive={true}
        >
          <Box mr={showLabel && text ? 1 : 0} {...iconBoxPros}>
            {icon}
          </Box>
        </Tooltip>

        {showLabel && textToShow && (
          <Typography variant={'body2'} component={'span'} className={styles.text}>
            <Trans>{textToShow}</Trans>
          </Typography>
        )}
      </Box>
    );
  }

  if (variant === 'chip') {
    return (
      <Chip
        variant={'filled'}
        sx={{ backgroundColor: color, color: pgColorScheme.white }}
        label={<Trans>{textToShow}</Trans>}
        {...containerChipPros}
      />
    );
  }

  return null;
};
