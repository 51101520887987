import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Goal } from '@priz/shared/src/models/tools/rrm';
import { RrmUtilization } from '@priz/shared/src/models/tools/rrm/rrm-utilization';
import { RrmGoalSelector } from '../../../rrm/store/selectors';
import { RrmGoalActions } from '../../../rrm/store';
import { RrmViewer } from '@priz/shared/src/components/tools-viewers';

export const RrmReportResult: React.FC<{ tool: RrmUtilization }> = ({ tool }) => {
  const dispatch = useDispatch();

  const goal: Goal = useSelector(RrmGoalSelector.getGoalById(tool && tool?.goal?.id));

  useEffect(() => {
    if (tool?.goal?.id && tool?.project?.id) dispatch(RrmGoalActions.loadRrmGoal(tool.goal.id));
  }, []);

  if (!tool || !goal) return null;

  return <RrmViewer goal={goal} />;
};
