import React from 'react';
import { getBoxToBoxArrow } from 'curved-arrows';
import { ArrowOptions } from 'curved-arrows/src/getBoxToBoxArrow';
import { useStyles } from './styles';

export interface CurvedArrowProps extends ArrowOptions {
  color?: string;
  strokeWidth?: number;
  strokeDasharray?: number;
  headSize?: number;
  shapeRendering?: 'optimizeSpeed' | 'geometricPrecision' | 'crispEdges' | 'auto';
}

export interface CurvedArrowData extends CurvedArrowProps {
  startElement: HTMLElement;
  endElement: HTMLElement;
}

interface CurvedArrowsProps {
  data: CurvedArrowData[];
  globalArrowProps?: CurvedArrowProps;
}

export const CurvedArrows: React.FC<CurvedArrowsProps> = ({ data = [], globalArrowProps }) => {
  const styles = useStyles();

  return (
    <svg className={styles.root} xmlns="http://www.w3.org/2000/svg">
      {data.map((props, key) => {
        const {
          startElement,
          endElement,
          color = '#000',
          strokeWidth = 1,
          strokeDasharray = 0,
          headSize = 6,
          shapeRendering,
          ...rest
        } = {
          ...(globalArrowProps || {}),
          ...props,
        };

        const [sx, sy, c1x, c1y, c2x, c2y, ex, ey, ae] = getBoxToBoxArrow(
          startElement.offsetLeft,
          startElement.offsetTop,
          startElement.offsetWidth,
          startElement.offsetHeight,
          endElement.offsetLeft,
          endElement.offsetTop,
          endElement.offsetWidth,
          endElement.offsetHeight,
          {
            padStart: 0,
            padEnd: headSize,
            ...rest,
          },
        );

        return (
          <React.Fragment key={key}>
            <path
              d={`M ${sx} ${sy} C ${c1x} ${c1y}, ${c2x} ${c2y}, ${ex} ${ey}`}
              stroke={color}
              strokeWidth={strokeWidth}
              strokeDasharray={strokeDasharray}
              shapeRendering={shapeRendering || (sx === ex ? 'crispEdges' : 'geometricPrecision')}
              fill="none"
            />

            <polygon
              points={`0,${-headSize} ${headSize * 2},0, 0,${headSize}`}
              transform={`translate(${ex}, ${ey}) rotate(${ae})`}
              fill={color}
            />
          </React.Fragment>
        );
      })}
    </svg>
  );
};
