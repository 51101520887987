import { Project, ProjectCertificationStatus, ProjectStatus } from '@priz/shared/src/models/project';

const stringNotEmpty = (str: string) => {
  return str && str.length > 0;
};

const areProjectDetailedCompleted = (project?: Project): boolean => {
  const completed =
    project &&
    stringNotEmpty(project.title) &&
    stringNotEmpty(project.currentSituation) &&
    stringNotEmpty(project.problemStatement);

  return !!completed;
};

const isProjectCompleted = (project: Project): boolean => {
  return !project || project.status === ProjectStatus.Completed;
};

const isProjectOpen = (project: Project): boolean => {
  return !!project?.open;
};

const isProjectLocked = (project: Project): boolean => {
  return (
    !project ||
    [
      ProjectCertificationStatus.AvailableForReview,
      ProjectCertificationStatus.InReview,
      ProjectCertificationStatus.Certified,
    ].includes(project.certificationStatus) ||
    isProjectCompleted(project)
  );
};

export const ProjectFlowService = {
  areProjectDetailedCompleted,
  isProjectLocked,
  isProjectCompleted,
  isProjectOpen,
};
