import { FluxStandardAction } from 'flux-standard-action';
import { RouterActionMeta } from '../../../../shared/store/types';
import { createPgAction } from '../../../../shared/store/action-creator';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../../shared/analytics-event.enum';
import { ApiKeysApi } from '../../service';
import { ApiKey } from '@priz/shared/src/models/api-keys';

export enum ApiKeysActionType {
  Load = 'api-keys/load',
  Create = 'api-keys/create',
  Invalidate = 'api-keys/invalidate',
  UpdateTitle = 'api-keys/update-title',
}

type ApiKeysActionPayload = ApiKey | ApiKey[];

interface ApiKeysActionMeta extends RouterActionMeta {
  userId: number;
  apiKeyId?: number;
}

export type ApiKeysAction = FluxStandardAction<string, ApiKeysActionPayload, ApiKeysActionMeta>;

const load = createPgAction(ApiKeysActionType.Load, ApiKeysApi.list, (userId: number) => ({ userId }));

const create = createPgAction(
  ApiKeysActionType.Create,
  ApiKeysApi.create,
  (userId: number) => ({ userId }),
  (_payload, { userId }) => {
    AnalyticsService.track(AnalyticsEvent.API_KEY_CREATED, {
      user_id: userId,
    });
  },
);

const updateTitle = createPgAction(
  ApiKeysActionType.UpdateTitle,
  ApiKeysApi.updateTitle,
  (apiKeyId: number, _title: string, userId: number) => ({ apiKeyId, userId }),
  (_payload, { userId }) => {
    AnalyticsService.track(AnalyticsEvent.API_KEY_TITLE_UPDATE, {
      user_id: userId,
    });
  },
);

const invalidate = createPgAction(
  ApiKeysActionType.Invalidate,
  ApiKeysApi.invalidate,
  (apiKeyId: number, userId: number) => ({ apiKeyId, userId }),
  (_payload, { userId }) => {
    AnalyticsService.track(AnalyticsEvent.API_KEY_INVALIDATED, {
      user_id: userId,
    });
  },
);

export const ApiKeysActions = {
  load,
  create,
  updateTitle,
  invalidate,
};
