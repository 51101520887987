import { ToolType, ToolTypeUrlKeyMap } from '@priz/shared/src/models/tools';

export const pathTitlesMap: { [key: string]: string } = {
  [ToolTypeUrlKeyMap[ToolType.TOOL_40_PRINCIPLES]]: '40P',
  [ToolTypeUrlKeyMap[ToolType.RRM]]: 'RRM',
  [ToolTypeUrlKeyMap[ToolType.UIM]]: 'UIM',
  [ToolTypeUrlKeyMap[ToolType.CEC]]: 'CEC',
  [ToolTypeUrlKeyMap[ToolType.APA]]: 'APA',
  [ToolTypeUrlKeyMap[ToolType.EBS]]: 'EBS',
  [ToolTypeUrlKeyMap[ToolType.NINE_WINDOWS]]: '9W',
  [ToolTypeUrlKeyMap[ToolType.FIVE_WHYS]]: '5Whys',
  [ToolTypeUrlKeyMap[ToolType.P_MAP]]: 'PMap',
  [ToolTypeUrlKeyMap[ToolType.SFM]]: 'SFM',
  [ToolTypeUrlKeyMap[ToolType.PFM]]: 'PFM',
};
