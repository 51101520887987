import React from 'react';
import { OptionalComponentRank, OptionalComponentRankMap, SfmDiagramData } from '../../models/tools/sfm';
import { useStyles } from './styles';
import { Alert, Box, Chip, Grid, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { Trans } from 'react-i18next';
import { getComponentsRanksArray, getNoImpactComponents, getSfmNodesMap, resolveSfmNetworkData } from '../../utils/sfm';
import { SfmComponentLabel } from '../sfm-component-label/component';
import { SfmRelativeRankBars } from '../sfm-relative-rank-bars/component';

export enum SfmRanksFilterType {
  All = 'All',
  Functional = 'Functional',
  Problematic = 'Problematic',
  NoImpact = 'NoImpact',
}

export interface SfmResultTableProps {
  sfmDiagramData: SfmDiagramData;
  componentsRanksMap: OptionalComponentRankMap;
  activeVersionId?: string;
  sortFunction?: (a: OptionalComponentRank, b: OptionalComponentRank) => number;
  ranksFilterType?: SfmRanksFilterType;
  rankHelperRenderer?: (rankId: string) => React.ReactNode;
  dividers?: boolean;
}

const defaultSortFunction = (a: OptionalComponentRank, b: OptionalComponentRank) =>
  (b?.functionalRank || 0) - (a?.functionalRank || 0);

export const SfmResultTable: React.FC<SfmResultTableProps> = ({
  sfmDiagramData,
  componentsRanksMap,
  activeVersionId,
  sortFunction,
  ranksFilterType = SfmRanksFilterType.All,
  rankHelperRenderer,
  dividers,
}) => {
  const styles = useStyles();

  const networkData = resolveSfmNetworkData(sfmDiagramData, activeVersionId);
  const nodesMap = getSfmNodesMap(networkData);
  const functionsArray = getComponentsRanksArray(componentsRanksMap);
  const noImpactComponents = getNoImpactComponents(networkData, componentsRanksMap);

  const showAll = ranksFilterType === SfmRanksFilterType.All;
  const showProblematic = ranksFilterType === SfmRanksFilterType.Problematic;
  const showFunctional = ranksFilterType === SfmRanksFilterType.Functional;
  const showNoImpact = ranksFilterType === SfmRanksFilterType.NoImpact;

  const filterProblematicIsEmpty = showFunctional && !functionsArray.filter(item => !!item.functionalRank)?.length;
  const filterFunctionalIsEmpty = showProblematic && !functionsArray.filter(item => !!item.problematicRank)?.length;
  const filterNoImpactIsEmpty = showNoImpact && !noImpactComponents?.length;

  return (
    <>
      {(!functionsArray.length || filterProblematicIsEmpty || filterFunctionalIsEmpty || filterNoImpactIsEmpty) && (
        <Box my={4}>
          <Alert severity="info">
            <Trans>Nothing to show</Trans>
          </Alert>
        </Box>
      )}

      <Table size="small">
        <TableBody>
          {!showNoImpact &&
            functionsArray.sort(sortFunction || defaultSortFunction).map(item => {
              const { id, functionalRank, problematicRank } = item;

              if (showProblematic && !problematicRank) return null;
              if (showFunctional && !functionalRank) return null;

              return (
                <TableRow key={id}>
                  <TableCell className={dividers ? styles.tableCellDivider : styles.tableCell}>
                    <Box pr={1}>
                      <SfmComponentLabel text={nodesMap[id]?.label} short />
                    </Box>
                  </TableCell>

                  <TableCell width={'100%'} className={dividers ? styles.tableCellDivider : styles.tableCell}>
                    <SfmRelativeRankBars
                      componentId={id}
                      componentsRanksMap={componentsRanksMap}
                      showFunctionalBar={showFunctional || showAll}
                      showProblematicBar={showProblematic || showAll}
                      rankHelperRenderer={rankHelperRenderer}
                    />
                  </TableCell>
                </TableRow>
              );
            })}

          {(showAll || showNoImpact) &&
            !!noImpactComponents.length &&
            noImpactComponents.map(item => (
              <TableRow key={item.id}>
                <TableCell className={dividers ? styles.tableCellDivider : styles.tableCell}>
                  <Box pr={1}>
                    <SfmComponentLabel text={item.label} short />
                  </Box>
                </TableCell>

                <TableCell width={'100%'} className={dividers ? styles.tableCellDivider : styles.tableCell}>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item>
                      <Chip size={'small'} label={<Trans>No impact</Trans>} />
                    </Grid>

                    {rankHelperRenderer && <Grid item>{rankHelperRenderer(item.id as string)}</Grid>}
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
};
