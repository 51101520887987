//tslint:disable
import { IdRef } from './id-ref';

export interface IAuditableEntity {
  createdBy: IdRef;
  dateCreated: Date;
  updatedBy?: IdRef;
  lastUpdated?: Date;
  lastModified?: Date;
}

export class AuditableEntity implements IAuditableEntity {
  createdBy: IdRef;
  dateCreated: Date;
  updatedBy?: IdRef;
  lastUpdated?: Date;
  lastModified?: Date;

  constructor(json: AuditableEntity) {
    this.createdBy = json.createdBy;
    this.dateCreated = json.dateCreated && new Date(json.dateCreated);
    this.updatedBy = json.updatedBy;
    this.lastUpdated = json.lastUpdated && new Date(json.lastUpdated);
    this.lastModified = json.lastModified && new Date(json.lastModified);
  }
}
