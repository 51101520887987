import React from 'react';
import { Avatar, Box, AvatarProps, Typography } from '@mui/material';
import { UserUtils } from '../../utils/user/user-utils';
import { stringToColor } from '../../utils/styles';
import { HubPublicUser } from '../../models/user/hub-public-user';
import { User } from '../../models/security/user';
import { useStyles } from './styles';
import { WorkspaceMember } from '../../models/workspace';
import { PublicUser } from '../../models/user';
import { pgColorScheme } from '../../theme';
import { PersonRounded } from '@mui/icons-material';

export interface UserAvatarProps extends AvatarProps {
  user: HubPublicUser | User | WorkspaceMember | PublicUser;
  emailToColor?: boolean;
  size?: number;
  showName?: boolean;
  label?: string | JSX.Element;
  defaultAvatar?: boolean;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  user,
  size = 38,
  showName,
  label,
  emailToColor,
  defaultAvatar,
  ...rest
}) => {
  const styles = useStyles();
  const { className, ...restAvatarProps } = rest;
  const styleOverrides: { width: number; height: number; backgroundColor: string } = {
    width: size,
    height: size,
    backgroundColor: pgColorScheme.backgroundDarkGray,
  };

  if (!user) {
    return null;
  }

  if (emailToColor && user?.email) styleOverrides['backgroundColor'] = stringToColor(user.email);

  return (
    <Box display={'flex'} alignItems={'center'} flexWrap={'nowrap'}>
      <Avatar
        src={defaultAvatar ? undefined : UserUtils.getAvatarUrl(user)}
        sx={styleOverrides}
        className={`${className ? className : ''}`}
        style={{ fontSize: Math.floor(size * 0.4) }}
        alt={`${UserUtils.getShowableName(user)} avatar`}
        {...restAvatarProps}
      >
        {defaultAvatar ? <PersonRounded className={styles.defaultIcon} /> : UserUtils.resolveInitials(user)}
      </Avatar>

      {(showName || label) && (
        <Box pl={1.25} display={'flex'} flexDirection={'column'} overflow={'hidden'}>
          {showName && (
            <Box className={styles.nameContainer}>
              <Typography variant={'body1'} component={'span'} className={styles.name}>
                {UserUtils.getShowableName(user)}
              </Typography>
            </Box>
          )}

          {label && (
            <Box>
              <Typography variant={'body2'} component={'span'} className={styles.label}>
                {label}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
