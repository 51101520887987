import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  ['@keyframes showRankingOption']: {
    '0%': {
      opacity: 0,
      transform: 'translateY(5px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  option: {
    animation: '$showRankingOption .3s ease-in-out .1s backwards',

    ['& + &']: {
      animationDelay: '.3s',
    },
  },
});
