import { FluxStandardAction } from 'flux-standard-action';
import hash from 'object-hash';
import { Dispatch } from 'redux';
import { Selector } from 'reselect';
import { AppState } from '../../store/app.state';
import { ActionSuffix } from './action-suffix.enum';
import { ActionMeta, ActionPayload, ObjectMap } from './types';

type PromisePayloadCreator<T> = (...args: any[]) => Promise<T>;
type SimpleMetaCreator<M> = (...args: any[]) => M;
type SelectorMetaCreator<S, M> = (...args: any[]) => Selector<S, M>;
type MetaCreator<S, M> = SimpleMetaCreator<M> | SelectorMetaCreator<S, M>;

export const toStarted = (type: string) => type + ActionSuffix.Started;
export const toSuccess = (type: string) => type + ActionSuffix.Success;
export const toFailed = (type: string) => type + ActionSuffix.Failed;

export const removeActionSuffix = (type: string) =>
  type.replace(ActionSuffix.Started, '').replace(ActionSuffix.Success, '').replace(ActionSuffix.Failed, '');

export function createAction<P, M>(type: string, payload?: P, meta?: M) {
  return {
    meta,
    payload,
    type,
  };
}

function createStartedAction<M extends ActionMeta>(type: string, meta?: M): FluxStandardAction<string, undefined, M> {
  return {
    meta,
    type: toStarted(type),
  };
}

function createSuccessAction<P extends ActionPayload, M extends ActionMeta>(
  type: string,
  payload: P,
  meta?: M,
): FluxStandardAction<string, P, M> {
  return {
    meta,
    payload,
    type: toSuccess(type),
  };
}

function createFailureAction<P extends ActionPayload, M extends ActionMeta>(
  type: string,
  error: any,
  meta?: M,
): FluxStandardAction<string, P, M> {
  return {
    meta,
    error: true,
    payload: error,
    type: toFailed(type),
  };
}

function isFunction(value: any): boolean {
  return value && typeof value === 'function';
}

function isSelectorMetaCreator<S, M>(meta: M | Selector<S, M>): meta is Selector<S, M> {
  return isFunction(meta);
}

function createMeta<S, M>(metaCreator: MetaCreator<S, M>, args: any[], state: S): M {
  const meta = metaCreator(...args);
  return isSelectorMetaCreator<S, M>(meta) ? meta(state) : meta;
}

const CACHE: ObjectMap<Promise<any>> = {};

const cleanupArgsForCacheKey = (args: any[]): any[] => {
  return args.reduce((arr: any[], arg: any) => {
    if (typeof arg !== 'function') {
      arr.push(arg);
    }
    return arr;
  }, []);
};

export function createPgAction<S extends AppState, T extends ActionPayload, M extends ActionMeta>(
  type: string,
  payloadCreator: PromisePayloadCreator<T>,
  metaCreator?: MetaCreator<S, M>,
  onSuccessCallback?: (payload?: any, meta?: M) => void,
  onFailedCallback?: (meta?: M) => void,
  cachedBefore?: (state: S, ...args: any[]) => boolean,
  cachedValueSelector?: (...args: any[]) => Selector<S, T>,
) {
  return (...args: any[]) =>
    (dispatch: Dispatch, getState: () => S) => {
      const state = getState();
      const meta = metaCreator ? createMeta(metaCreator, args, state) : undefined;

      if (cachedBefore && cachedBefore(state, ...args)) {
        const cachedValue = cachedValueSelector ? cachedValueSelector(...args)(state) : true;
        const cachedAction = createAction(type, cachedValue, meta);

        return Promise.resolve(cachedAction);
      }

      const argsForCache = cleanupArgsForCacheKey(args);
      const cacheKey = `${type}-${hash.MD5({ type, args: argsForCache })}`;

      if (CACHE[cacheKey]) {
        return CACHE[cacheKey];
      }

      const payload = payloadCreator(...args);

      dispatch(createStartedAction(type, meta));
      CACHE[cacheKey] = payload;

      payload.then(
        (data: T) => {
          delete CACHE[cacheKey];
          dispatch(createSuccessAction(type, data, meta));
          if (onSuccessCallback) {
            onSuccessCallback(data, meta);
          }
        },
        error => {
          delete CACHE[cacheKey];
          dispatch(createFailureAction(type, error, meta));
          if (onFailedCallback) {
            onFailedCallback(meta);
          }
        },
      );
    };
}
