import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { ITopic, Topic } from '@priz/shared/src/models/project';
import { pgColorScheme } from '@priz/shared/src/theme';
import { TopicLabelPreview } from '../topic-label-preview/component';
import { ReactHookFormText } from '../../react/form-elements';
import { LoadingButton } from '@priz/shared/src/components/loading-button/component';
import { validateOptions, validatorRules } from '@priz/shared/src/utils/form';
import { ChromePicker } from 'react-color';
import { useMutation } from 'react-query';
import { TopicsApi } from '../services';
import { Alert } from '@mui/material';

interface TopicDialogProps {
  isOpen: boolean;
  edit?: boolean;
  topic?: Topic;
  disabled?: boolean;
  onClose?: () => void;
  onCreate?: (topic: Topic) => void;
  onUpdate?: (topic: Topic) => void;
}

const resolveValues = (topic?: ITopic): ITopic => {
  const { slug = '', label = '', description = '', color = pgColorScheme.textGray } = topic || {};

  return {
    slug,
    label,
    description,
    color,
  };
};

export const TopicDialog: React.FC<TopicDialogProps> = ({
  isOpen,
  onClose,
  edit,
  topic,
  disabled,
  onUpdate,
  onCreate,
}) => {
  const [open, setOpen] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const { control, handleSubmit, getValues, reset, setValue } = useForm<ITopic>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: resolveValues(),
  });

  const mutationCreate = useMutation((topic: ITopic) => TopicsApi.create(topic), {
    onSuccess: data => {
      if (onCreate) onCreate(data);
      handleClose();
    },
    onError: (error: { response: { data: { message } } }) => {
      setErrorText(error?.response?.data?.message || 'Error');
    },
  });

  const mutationUpdate = useMutation((topic: Topic) => TopicsApi.update(topic), {
    onSuccess: data => {
      if (onUpdate) onUpdate(data);
      handleClose();
    },
    onError: (error: { response: { data: { message } } }) => {
      setErrorText(error?.response?.data?.message || 'Error');
    },
  });

  const isLoading = mutationCreate.isLoading || mutationUpdate.isLoading;

  useEffect(() => {
    if (topic) reset(resolveValues(topic));
  }, [topic]);

  useEffect(() => {
    setOpen(isOpen);
    if (isOpen && !topic) reset();
  }, [isOpen, topic]);

  useWatch({ control, name: ['color', 'label'] });

  const handleChange = (color: { hex: string }) => {
    setValue('color', color.hex);
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const handleUpdate = () => {
    handleSubmit(formData => {
      setErrorText(null);
      mutationUpdate.mutate({ id: topic.id, ...formData });
    })();
  };

  const handleCreate = () => {
    handleSubmit(formData => {
      setErrorText(null);
      mutationCreate.mutate(formData);
    })();
  };

  const { color, label } = getValues();

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle>{edit ? 'Edit topic' : 'Create new topic'}</DialogTitle>

      <DialogContent>
        {errorText && (
          <Box mb={2}>
            <Alert severity={'error'}>{errorText}</Alert>
          </Box>
        )}

        <Grid container spacing={4}>
          <Grid item xs={12} sm={'auto'}>
            <Box mb={3}>
              <ChromePicker color={color} onChange={handleChange} disableAlpha />
            </Box>
          </Grid>

          <Grid item xs={12} sm={true} style={{ overflow: 'hidden' }}>
            <Box mb={3}>
              <TopicLabelPreview color={color} text={label || 'label preview'} />
            </Box>

            <ReactHookFormText
              fieldTitle={'Label'}
              name={'label'}
              control={control}
              rules={{
                ...validatorRules.required(),
              }}
              wrapperProps={{ mb: 2 }}
              disabled={disabled}
            />

            <ReactHookFormText
              fieldTitle={'Slug'}
              name={'slug'}
              control={control}
              rules={{
                validate: {
                  ...validateOptions.hasText('Field is required'),
                },
              }}
              wrapperProps={{ mb: 2 }}
              disabled={disabled}
            />
          </Grid>
        </Grid>

        <ReactHookFormText
          fieldTitle={'Description'}
          name={'description'}
          control={control}
          multiline={true}
          rules={{
            ...validatorRules.required(),
          }}
          wrapperProps={{ mb: 2 }}
          disabled={disabled}
        />
      </DialogContent>

      <DialogActions>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose}>
          Cancel
        </Button>

        {edit && (
          <LoadingButton onClick={handleUpdate} loading={isLoading} disabled={disabled}>
            Update
          </LoadingButton>
        )}

        {!edit && (
          <LoadingButton onClick={handleCreate} loading={isLoading} disabled={disabled}>
            Create
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
