import React, { useState } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { Trans } from 'react-i18next';
import { StartRankingDialog } from '../start-ranking-dialog/component';
import { RrmToolStarterProps } from '../RrmToolStarter';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm';
import { useDispatch } from 'react-redux';
import { ProjectFeatureBlockerActions } from '../../project-feature-blocker/store/actions';
import { ProjectFeatureBlockerType } from '../../project-feature-blocker/store/model';

export interface StartRankingButtonProps extends RrmToolStarterProps {
  buttonText?: string;
  buttonProps?: ButtonProps;
}

export const StartRankingButton: React.FC<StartRankingButtonProps> = ({
  buttonText,
  buttonProps,
  projectId,
  goalType,
  utilizationId,
  disabled,
  ...rest
}) => {
  const dispatch = useDispatch();

  const [isRankingDialogOpen, setIsRankingDialogOpen] = useState(false);

  const rankingButtonClickHandler = () => {
    if (typeof projectId !== 'undefined' || goalType === RrmGoalType.SELF) {
      openRankingDialog();
    } else {
      dispatch(
        ProjectFeatureBlockerActions.set({
          utilizationId,
          blocker: ProjectFeatureBlockerType.RRM,
          rrmStarterProps: {
            goalType,
            utilizationId,
            ...rest,
          },
        }),
      );
    }
  };

  const openRankingDialog = () => {
    setIsRankingDialogOpen(true);
  };

  const closeRankingDialog = () => {
    setIsRankingDialogOpen(false);
  };

  return (
    <>
      <Button
        variant={'pg_rounded'}
        color={'pg_orange_outlined'}
        onClick={rankingButtonClickHandler}
        disabled={disabled}
        {...buttonProps}
      >
        <Trans>{buttonText || 'Ranking'}</Trans>
      </Button>

      <StartRankingDialog
        isOpen={isRankingDialogOpen}
        onClose={closeRankingDialog}
        projectId={projectId}
        goalType={goalType}
        utilizationId={utilizationId}
        disabled={disabled}
        {...rest}
      />
    </>
  );
};
