import React, { useState } from 'react';
import { AuthTabsType } from '@priz/shared/src/components/security/auth-tabs/component';
import { LoginForm } from '@priz/shared/src/components/security/login-form/component';
import { SignUpForm } from '@priz/shared/src/components/security/signup-form/component';
import { RequestPasswordResetForm } from '@priz/shared/src/components/security/request-password-reset-form/component';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { useNavigate } from 'react-router-dom';
import { Auth0RedirectKey } from '@priz/shared/src/models/auth0';
import { unbindPageLeavingPreventing } from '@priz/shared/src/utils/common';

interface AuthFormProps {
  initialTab?: AuthTabsType;
  useNavigationTabs?: boolean;
}

export const AuthForm: React.FC<AuthFormProps> = ({ useNavigationTabs, initialTab }) => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<AuthTabsType>(initialTab || AuthTabsType.Login);

  const invitationToken = LocalStorageService.getItem(LocalStorageKey.InvitationToken);

  const loginNavigateHandler = () => {
    if (useNavigationTabs) {
      navigate('/login');
    } else {
      setActiveTab(AuthTabsType.Login);
    }
  };

  const signUpNavigateHandler = () => {
    if (useNavigationTabs) {
      navigate('/signup');
    } else {
      setActiveTab(AuthTabsType.Signup);
    }
  };

  const passwordResetNavigateHandler = () => {
    if (useNavigationTabs) {
      navigate('/request-password-reset');
    } else {
      setActiveTab(AuthTabsType.PasswordReset);
    }
  };

  return (
    <>
      {activeTab === AuthTabsType.Login && (
        <LoginForm
          redirectKey={invitationToken ? Auth0RedirectKey.AcceptInvitation : Auth0RedirectKey.Login}
          onLoginNavigate={loginNavigateHandler}
          onSignUpNavigate={signUpNavigateHandler}
          onResetPasswordNavigate={passwordResetNavigateHandler}
          beforeSend={unbindPageLeavingPreventing}
        />
      )}

      {activeTab === AuthTabsType.Signup && (
        <SignUpForm
          redirectKey={invitationToken ? Auth0RedirectKey.AcceptInvitation : Auth0RedirectKey.Signup}
          onLoginNavigate={loginNavigateHandler}
          onSignUpNavigate={signUpNavigateHandler}
          beforeSend={unbindPageLeavingPreventing}
        />
      )}

      {activeTab === AuthTabsType.PasswordReset && (
        <RequestPasswordResetForm onLoginNavigate={loginNavigateHandler} onSignUpNavigate={signUpNavigateHandler} />
      )}
    </>
  );
};
