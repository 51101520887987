import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    background: pgColorScheme.black,

    ['&::before']: {
      content: '""',
      width: '100%',
      display: 'block',
      paddingTop: '56.25%',
    },
  },
});
