import React from 'react';
import { useStyles } from './styles';
import { Grid, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

export const SfmLegend: React.FC = () => {
  const styles = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <div className={`${styles.legendIndicator} ${styles.functionalIndicator}`} />
          </Grid>
          <Grid item>
            <Typography className={styles.legendLabel} variant={'body2'} component={'div'}>
              <Trans>Functional rank</Trans>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <div className={`${styles.legendIndicator} ${styles.problematicIndicator}`} />
          </Grid>
          <Grid item>
            <Typography className={styles.legendLabel} variant={'body2'} component={'div'}>
              <Trans>Problematic rank</Trans>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
