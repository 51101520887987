import React, { useEffect, useState } from 'react';
import { AuthContainer } from '../auth-container/component';
import { AuthForm } from '../auth-form/component';
import { AuthTabsType } from '@priz/shared/src/components/security/auth-tabs/component';
import { AuthService } from '@priz/shared/src/services/auth';
import { useNavigate } from 'react-router-dom';
import { PrizLoadingOverlay } from '@priz/shared/src/components/priz-loading-overlay/component';

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      navigate('/dashboard', { replace: true });
    } else {
      setIsReady(true);
    }
  });

  if (!isReady) {
    return <PrizLoadingOverlay />;
  }

  return (
    <AuthContainer title={'Sign-in to PRIZ Platform'}>
      <AuthForm initialTab={AuthTabsType.Login} useNavigationTabs />
    </AuthContainer>
  );
};
