import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  button: () => ({
    minWidth: 60,
    minHeight: 22,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 7px',
    borderRadius: 20,
    boxSizing: 'border-box',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    lineHeight: 1,
  }),
  buttonText: {
    lineHeight: 'inherit',
  },
  disabled: {
    opacity: 0.7,
    pointerEvents: 'none',
  },
  option: {
    'cursor': 'pointer',
    'transition': 'background-color .15s ease',
    'backgroundColor': pgColorScheme.white,

    '&:hover': {
      backgroundColor: pgColorScheme.background,
    },
  },
  activeOption: {
    color: pgColorScheme.blue,
  },
});
