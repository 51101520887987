import React from 'react';
import { ComponentRank, OptionalComponentRank } from '../../models/tools/sfm';
import { useStyles } from './styles';
import { Box, Chip, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

export interface SfmRankBarsBaseProps {
  showFunctionalBar?: boolean;
  showProblematicBar?: boolean;
  backdrop?: boolean;
  spacing?: number;
  stretchNoImpact?: boolean;
}

interface SfmRankBarsProps extends SfmRankBarsBaseProps {
  componentRank: ComponentRank | OptionalComponentRank;
  functionalBarWidthMultiplier?: number;
  problematicBarWidthMultiplier?: number;
}

export const SfmRankBars: React.FC<SfmRankBarsProps> = ({
  componentRank,
  showFunctionalBar = true,
  showProblematicBar = true,
  functionalBarWidthMultiplier,
  problematicBarWidthMultiplier,
  spacing,
  backdrop,
  stretchNoImpact,
}) => {
  const styles = useStyles();
  const barWrapClassNames = [styles.barWrap];
  const noImpactBarClassNames = [styles.noImpact];

  if (backdrop) barWrapClassNames.push(styles.backdrop);
  if (stretchNoImpact) noImpactBarClassNames.push(styles.noImpactStretch);

  const { functionalRank = 0, problematicRank = 0 } = componentRank || {};
  const maxRank = Math.max(functionalRank, problematicRank);

  return (
    <>
      {showFunctionalBar && !!functionalRank && (
        <Box className={barWrapClassNames.join(' ')} mb={showProblematicBar && !!problematicRank ? spacing || 0.25 : 0}>
          <div
            className={[styles.bar, styles.functionalBar].join(' ')}
            style={{
              width: `${(functionalRank / (functionalBarWidthMultiplier || maxRank)) * 100}%`,
            }}
          >
            <Typography variant={'caption'} component={'div'} className={styles.barValue}>
              {functionalRank}
            </Typography>
          </div>
        </Box>
      )}

      {showProblematicBar && !!problematicRank && (
        <div className={barWrapClassNames.join(' ')}>
          <div
            className={[styles.bar, styles.problematicBar].join(' ')}
            style={{
              width: `${(problematicRank / (problematicBarWidthMultiplier || maxRank)) * 100}%`,
            }}
          >
            <Typography variant={'caption'} component={'div'} className={styles.barValue}>
              {problematicRank}
            </Typography>
          </div>
        </div>
      )}

      {!functionalRank && !problematicRank && (
        <Chip className={noImpactBarClassNames.join(' ')} size={'small'} label={<Trans>No impact</Trans>} />
      )}
    </>
  );
};
