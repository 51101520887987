import React from 'react';
import { AuthCallback } from '../auth-callback/component';
import { UserService } from '../services/user.service';

export const LoginCallback: React.FC = () => {
  const userLoader = () => {
    return UserService.login();
  };

  return <AuthCallback userLoader={userLoader} />;
};
