import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Trans } from 'react-i18next';
import { TaskActions } from '../store/actions/task.actions';
import { ToolType } from '@priz/shared/src/models/tools';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ReactHookFormQuill, ReactHookFormText } from '../../react/form-elements';
import { validatorRules } from '@priz/shared/src/utils/form';
import { useForm } from 'react-hook-form';
import { TaskSelectors } from '../store/selectors/task.selectors';
import { LoadingButton } from '@mui/lab';
import { CreateTaskCommand } from '../services/task.api';

const useStyles = makeStyles({
  actions: {
    textAlign: 'right',
  },
  errorLabel: {},
});

export interface TaskRecorderProps {
  projectId: number;
  sourceToolId?: number;
  sourceToolType?: ToolType;
  sourceId?: string;
  isOpen: boolean;
  onClose?: () => void;
  ideaId?: number;
}

export const TaskRecorder: React.FC<TaskRecorderProps> = ({
  projectId,
  sourceToolId,
  sourceToolType,
  sourceId,
  isOpen,
  onClose,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const isCreating = useSelector(TaskSelectors.isCreating(projectId));
  const isCreated = useSelector(TaskSelectors.isCreated(projectId));

  const { handleSubmit, reset, control } = useForm({
    shouldFocusError: false,
    defaultValues: {
      title: '',
      details: '',
    },
  });

  useEffect(() => {
    if (isCreated) handleClose();
  }, [isCreated]);

  const handleSave = () => {
    handleSubmit(formData => {
      const command: CreateTaskCommand = {
        title: formData.title,
        details: formData.details,
        sourceToolType: sourceToolType,
        sourceToolUtilizationId: sourceToolId,
        sourceId,
      };

      dispatch(TaskActions.create(projectId, command));
    })();
  };

  useEffect(() => {
    setOpen(isOpen);
    if (isOpen) reset();
  }, [isOpen]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>{<Trans>Create task</Trans>}</DialogTitle>

      <DialogContent>
        <ReactHookFormText
          name={'title'}
          fieldTitle={'Title'}
          control={control}
          rules={{
            ...validatorRules.required(),
          }}
          disabled={isCreating}
        />

        <ReactHookFormQuill name={'details'} fieldTitle={'Details'} control={control} disabled={isCreating} />
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose}>
          <Trans>Cancel</Trans>
        </Button>

        <LoadingButton
          variant={'pg_rounded'}
          color={'pg_orange_solid'}
          onClick={handleSave}
          disabled={isCreating}
          loading={isCreating}
        >
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
