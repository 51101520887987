import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, ClickAwayListener, Grid, useTheme } from '@mui/material';
import { ReactHookFormSelect } from '../react/form-elements';
import { useForm } from 'react-hook-form';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { TimerOutlined } from '@mui/icons-material';
import { intervalToDuration } from 'date-fns';
import { Trans } from 'react-i18next';

const hoursAndMinutesToMs = (hours: number, minutes: number): number => (hours * 60 * 60 + minutes * 60) * 1000;

export const resolveDurationLabel = (durationInMs: number): string | null => {
  const duration = intervalToDuration({ start: 0, end: durationInMs || 0 });
  const result = [];

  if (duration.hours) result.push(`${duration.hours}h`);
  if (duration.minutes) result.push(`${duration.minutes}m`);

  return result.length ? result.join(':') : null;
};

const resolveInitialValues = (durationInMs: number): { hours: number; minutes: number } => {
  const duration = intervalToDuration({ start: 0, end: durationInMs || 0 });

  return {
    hours: duration.hours,
    minutes: duration.minutes,
  };
};

interface TimePickerButtonProps {
  valueInMs: number;
  placeholder?: string;
  onChange?: (valueInMs: number) => void;
  disabled?: boolean;
}

export const TimePickerButton: React.FC<TimePickerButtonProps> = ({ valueInMs, placeholder, onChange, disabled }) => {
  const theme = useTheme();
  const [selectIsOpen, setSelectIsOpen] = useState(false);
  const [popperIsOpen, setPopperIsOpen] = useState(false);

  const { control, getValues, reset } = useForm({
    defaultValues: resolveInitialValues(valueInMs),
  });

  useEffect(() => {
    reset(resolveInitialValues(valueInMs));
  }, [valueInMs]);

  const getTotalDuration = (): number => {
    const values = getValues();
    return hoursAndMinutesToMs(values.hours, values.minutes);
  };

  const changeHandler = () => {
    if (onChange) onChange(getTotalDuration());
  };

  const openPopper = () => {
    setPopperIsOpen(true);
  };

  const closePopper = () => {
    if (!selectIsOpen) setPopperIsOpen(false);
  };

  const openSelectHandler = () => {
    setSelectIsOpen(true);
  };

  const closeSelectHandler = () => {
    setSelectIsOpen(false);
  };

  const memorizedSelects = useMemo(() => {
    return (
      <Grid container spacing={2}>
        <Grid item>
          <ReactHookFormSelect
            variant={'standard'}
            fieldTitle={'Hours'}
            name={'hours'}
            control={control}
            options={[...new Array(12)].map((_item, key) => ({
              value: key,
              text: key.toString(),
            }))}
            onChange={changeHandler}
            wrapperProps={{
              width: { xs: 100 },
              mb: 0,
            }}
            selectProps={{
              MenuProps: {
                style: { zIndex: theme.zIndex.modal + 101 },
              },
              onOpen: openSelectHandler,
              onClose: closeSelectHandler,
            }}
          />
        </Grid>

        <Grid item>
          <ReactHookFormSelect
            variant={'standard'}
            fieldTitle={'Minutes'}
            name={'minutes'}
            control={control}
            options={[...new Array(12)].map((_item, key) => ({
              value: key * 5,
              text: (key * 5).toString(),
            }))}
            onChange={changeHandler}
            wrapperProps={{
              width: { xs: 100 },
              mb: 0,
            }}
            selectProps={{
              MenuProps: {
                style: { zIndex: theme.zIndex.modal + 101 },
              },
              onOpen: openSelectHandler,
              onClose: closeSelectHandler,
            }}
          />
        </Grid>
      </Grid>
    );
  }, [valueInMs]);

  return (
    <div>
      <PopperWrap open={popperIsOpen} openHandler={'manually'} onClose={closePopper} closeOnClickAway={false}>
        <Button size={'small'} endIcon={<TimerOutlined />} onClick={openPopper} disabled={disabled}>
          {resolveDurationLabel(getTotalDuration()) || <Trans>{placeholder || 'Not set'}</Trans>}
        </Button>

        <ClickAwayListener onClickAway={closePopper}>
          <Box>
            {memorizedSelects}

            <Box mt={1} textAlign={'right'}>
              <Button onClick={closePopper} size={'small'}>
                <Trans>Close</Trans>
              </Button>
            </Box>
          </Box>
        </ClickAwayListener>
      </PopperWrap>
    </div>
  );
};
