import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { PgProgressBar } from '../../react/elements/PgProgressBar';
import { PMapUtilization } from '@priz/shared/src/models/tools/pmap';
import { useSelector } from 'react-redux';
import { PMapResultSelectors } from '../store/selectors/pmap-result.selectors';

interface PMapProgressBarProps {
  pMapUtilization: PMapUtilization;
}

export const PMapProgressBar: React.FC<PMapProgressBarProps> = ({ pMapUtilization }) => {
  const [assignedPercent, setAssignedPercent] = useState(0);

  const result = useSelector(PMapResultSelectors.getResultByUtilizationId(pMapUtilization.id));
  const isResultLoaded = useSelector(PMapResultSelectors.isLoadedByUtilizationId(pMapUtilization.id));

  useEffect(() => {
    if (isResultLoaded && result && pMapUtilization?.data) {
      const perceptionsCount = pMapUtilization.data.perceptions?.length || 0;
      const connectionsCount = Object.keys(result.connections).length;

      setAssignedPercent((connectionsCount / perceptionsCount) * 100);
    }
  }, [isResultLoaded]);

  return (
    <Box sx={{ width: '100%' }} mb={2}>
      <PgProgressBar
        value={assignedPercent}
        color={assignedPercent === 100 ? 'success' : 'primary'}
        variant="determinate"
      />
    </Box>
  );
};
