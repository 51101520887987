import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useStyles } from './styles';
import { BoxProps } from '@mui/material';
import { FullscreenContainer } from '@priz/shared/src/components/fullscreen-container/component';
import { CftUtilization } from '@priz/shared/src/models/tools/cft/cft-utilization';
import { CftDiagram } from '@priz/shared/src/components/cft/cft-diagram/component';
import { CftNodeControls, CftNodeControlsCallbacks } from '../cft-node-controls/component';
import {
  CftNodeControlsRendererProps,
  CftNodeData,
  CftNodesCollection,
  CftNodeTextEditorRendererProps,
  CftNodeType,
} from '@priz/shared/src/models/tools/cft';
import { CftDataUtils } from '@priz/shared/src/utils/cft';
import { CftContentUtils, CftManagementUtils } from '../utils';
import { CftNodeTextEditor } from '../cft-node-text-editor/component';
import debounce from 'lodash/debounce';
import { CftNodeNotesEditor } from '../cft-node-notes-editor/component';
import { CftDiagramControls } from '../cft-diagram-controls/component';
import { useHistory } from '../../utils';
import { useDispatch } from 'react-redux';
import { PaywallActions } from '../../react/modules/paywall/store';
import { ToolType, ToolUtilization } from '@priz/shared/src/models/tools';
import { CftContext } from '@priz/shared/src/components/cft/cft-context/component';
import { CftNodeViewer } from '../cft-node-viewer/component';
import { ElementsViewerContext } from '@priz/shared/src/components/elements-viewer/elements-viewer-context/component';
import { v4 as uuidv4 } from 'uuid';
import { MeetingRecorder } from '../../meetings/meeting-recorder/component';
import { PlanFeatureSet } from '@priz/shared/src/models/workspace';
import { Meeting } from '../../meetings/store/model';
import { TaskRecorder } from '../../tasks/task-recorder/component';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm';
import { ToolSelectorDialog } from '../../tools/tool-selector-dialog/component';
import { Task } from '@priz/shared/src/models/task';
import { Idea } from '@priz/shared/src/models/idea';
import { IdeaRecorder } from '../../idea/idea-recorder/component';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';

interface CftWorkspaceProps extends BoxProps {
  utilization: CftUtilization;
  featureSet: PlanFeatureSet;
  meetings: Meeting[];
  tools: ToolUtilization[];
  tasks: Task[];
  ideas: Idea[];
  activeVersionId?: string;
  historyKeyboardControl?: boolean;
  onDataChange?: (data: CftNodeData[]) => void;
  inView?: boolean;
  disabled?: boolean;
}

enum CftWorkspaceDialogType {
  Editor = 'Editor',
  Meeting = 'Meeting',
  Tool = 'Tool',
  Task = 'Task',
  Idea = 'Idea',
}

export const CftWorkspace: React.FC<CftWorkspaceProps> = ({
  utilization,
  featureSet,
  meetings,
  tools,
  tasks,
  ideas,
  activeVersionId,
  historyKeyboardControl,
  onDataChange,
  inView,
  disabled,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { fitContent, refreshArrows, refreshSpacers, zoomToElement } = useContext(CftContext);
  const { refresh: refreshViewer } = useContext(ElementsViewerContext);

  const {
    saveInHistory,
    replaceHistory,
    moveHistoryBack,
    moveHistoryForward,
    isHistoryAtStart,
    isHistoryAtEnd,
    historyEvents,
  } = useHistory<CftNodeData[]>({
    keyboardControl: historyKeyboardControl,
    disabled,
  });

  const [activeNode, setActiveNode] = useState<CftNodeData>();
  const [activeDialog, setActiveDialog] = useState<CftWorkspaceDialogType>();
  const [isNetworkFit, setIsNetworkFit] = useState(false);

  const [dataKeys, setDataKeys] = useState({
    version: '',
    iteration: '',
  });

  const dataSet = useRef<CftNodesCollection>({
    nodesArray: [],
    nodesMap: {},
  });

  useEffect(() => {
    const versionNodes = CftDataUtils.resolveVersionNodes(utilization.diagramData, activeVersionId);

    dataSet.current = CftDataUtils.resolveNodesCollection(versionNodes);

    replaceHistory(versionNodes);

    setDataKeys(state => ({
      ...state,
      version: activeVersionId,
    }));
  }, [activeVersionId]);

  useEffect(() => {
    if (inView && !isNetworkFit) {
      fitContent();
      setIsNetworkFit(true);
    }
  }, [isNetworkFit, inView]);

  useEffect(() => {
    if (inView) {
      refreshArrows();
    }
  }, [inView]);

  useEffect(() => {
    fitContent();
  }, [dataKeys.version]);

  useEffect(() => {
    const sourceId = LocalStorageService.getItem(LocalStorageKey.LinkSourceId);

    if (sourceId && inView && dataKeys.version.length && dataSet.current.nodesMap[sourceId]) {
      LocalStorageService.removeItem(LocalStorageKey.LinkSourceId);
      zoomToElement(sourceId);
    }
  }, [inView, dataKeys.version]);

  const controlsRenderer = (props: CftNodeControlsRendererProps) => {
    const { node, inVariant, inProcess, inRootBranch, ...rest } = props;
    const { leadsToStep, leadsToStatement, leadsToAbstraction } = CftDataUtils.resolveNextNodeIterations(
      dataSet.current.nodesMap,
      node,
    );
    const isStep = node.type === CftNodeType.Step;
    const isVariant = node.type === CftNodeType.Variant;
    const isStatement = node.type === CftNodeType.Statement;
    const isAbstraction = node.type === CftNodeType.Abstraction;

    const callbacks: CftNodeControlsCallbacks = {};

    if (!node.root) {
      callbacks.onDelete = removeNode;
    }

    if (isStep) {
      callbacks.onSetStatus = (id, status) => updateNode(id, { status });
      callbacks.onAddComment = nodeId => openDialog(nodeId, CftWorkspaceDialogType.Editor);
      callbacks.onCreateMeeting = nodeId => openDialog(nodeId, CftWorkspaceDialogType.Meeting);
      callbacks.onCreateTool = nodeId => openDialog(nodeId, CftWorkspaceDialogType.Tool);
      callbacks.onCreateTask = nodeId => openDialog(nodeId, CftWorkspaceDialogType.Task);
      callbacks.onCreateIdea = nodeId => openDialog(nodeId, CftWorkspaceDialogType.Idea);
    }

    if (isVariant) {
      callbacks.onAddProcess = nodeId => addNode(nodeId, CftNodeType.Process);
    }

    if (isAbstraction && !leadsToStatement) {
      callbacks.onAddStep = nodeId => addNode(nodeId, CftNodeType.Statement);
    }

    if (isStep || isStatement) {
      callbacks.onAddStep = nodeId => addNode(nodeId, CftNodeType.Step);
    }

    if ((isStep || isStatement) && !leadsToStep && !inVariant && !inProcess) {
      callbacks.onAddVariant = nodeId => addNode(nodeId, CftNodeType.Variant);
    }

    if ((isStep || isStatement) && inRootBranch && !inVariant && !inProcess && !leadsToAbstraction) {
      callbacks.onCreateAbstraction = nodeId => addNode(nodeId, CftNodeType.Abstraction);
    }

    return (
      <CftNodeControls
        node={node}
        utilizationId={utilization.id}
        projectId={utilization.project?.id}
        relations={isStep && CftContentUtils.resolveNodeRelations({ node, meetings, tools, tasks, ideas })}
        disabled={disabled}
        {...rest}
        {...callbacks}
      />
    );
  };

  const textEditorRenderer = (props: CftNodeTextEditorRendererProps) => {
    return <CftNodeTextEditor onChange={textChangeHandler} disabled={disabled} {...props} />;
  };

  const textChangeHandler = (nodeId: string, description: string) => {
    updateNode(nodeId, { description }, true);
    refreshSpacers(true);
    refreshArrows(true);
    refreshViewer(true);
    saveDataDebounced();
  };

  const checkIsLimitReached = () => {
    const maxItems = featureSet?.Tools?.CFT?.maxItems;
    return maxItems && dataSet.current.nodesArray.length >= maxItems;
  };

  const updateDataSet = (data: CftNodeData[]) => {
    const collection = CftDataUtils.resolveNodesCollection(data);

    dataSet.current.nodesMap = collection.nodesMap;
    dataSet.current.nodesArray = collection.nodesArray;
  };

  const saveData = (preventHistoryChange?: boolean) => {
    if (!preventHistoryChange) {
      saveInHistory(dataSet.current.nodesArray);
    }

    if (onDataChange) {
      onDataChange(dataSet.current.nodesArray);
    }

    setDataKeys(state => ({
      ...state,
      iteration: uuidv4(),
    }));
  };

  const saveDataDebounced = useCallback(
    debounce(() => {
      saveData();
    }, 500),
    [],
  );

  const addNode = (fromId: string, type: CftNodeType, preventSave?: boolean) => {
    if (checkIsLimitReached()) {
      dispatch(PaywallActions.show(ToolType.CFT));
    } else {
      updateDataSet(CftManagementUtils.addNode(dataSet.current.nodesArray, fromId, type));

      if (!preventSave) {
        saveData();
      }
    }
  };

  const updateNode = (
    nodeId: string,
    props: Partial<Pick<CftNodeData, 'status' | 'description' | 'notes'>>,
    preventSave?: boolean,
  ) => {
    updateDataSet(CftManagementUtils.updateNode(dataSet.current.nodesArray, nodeId, props));

    if (!preventSave) {
      saveData();
    }
  };

  const removeNode = (id: string, preventSave?: boolean) => {
    updateDataSet(CftManagementUtils.removeNode(dataSet.current.nodesArray, id));

    if (!preventSave) {
      saveData();
    }
  };

  const openDialog = (nodeId: string, type: CftWorkspaceDialogType) => {
    const node = dataSet.current.nodesMap[nodeId];

    if (node) {
      setActiveNode(node);
      setActiveDialog(type);
    }
  };

  const closeDialogs = () => {
    setActiveDialog(undefined);
  };

  historyEvents.onHistoryChange = data => {
    updateDataSet(data);
    saveData(true);
  };

  return (
    <div className={styles.root}>
      <FullscreenContainer className={styles.container}>
        <CftDiagram
          versionNodes={dataSet.current.nodesArray}
          versionNodesMap={dataSet.current.nodesMap}
          controlsRenderer={controlsRenderer}
          editorRenderer={textEditorRenderer}
          dragAndZoomProps={{
            onTransformed: () => {
              refreshViewer();
            },
          }}
        />

        <CftDiagramControls
          undo={moveHistoryBack}
          redo={moveHistoryForward}
          isAtStart={isHistoryAtStart}
          isAtEnd={isHistoryAtEnd}
          disabled={disabled}
        />
      </FullscreenContainer>

      <CftNodeViewer data={dataSet.current} />

      <CftNodeNotesEditor
        open={activeDialog === CftWorkspaceDialogType.Editor}
        node={activeNode}
        onSubmit={(id, notes) => updateNode(id, { notes })}
        onClose={closeDialogs}
      />

      {utilization.project?.id && (
        <MeetingRecorder
          open={activeDialog === CftWorkspaceDialogType.Meeting}
          projectId={utilization.project.id}
          sourceId={activeNode?.id}
          sourceToolId={utilization.id}
          sourceToolType={utilization.type}
          onClose={closeDialogs}
        />
      )}

      {utilization.project?.id && (
        <ToolSelectorDialog
          isOpen={activeDialog === CftWorkspaceDialogType.Tool}
          parentId={utilization.id}
          projectId={utilization.project.id}
          sourceId={activeNode?.id}
          rrmGoalType={RrmGoalType.SELF}
          disableAssistant={true}
          navigateOnCreate={false}
          onClose={closeDialogs}
        />
      )}

      {utilization.project?.id && (
        <TaskRecorder
          isOpen={activeDialog === CftWorkspaceDialogType.Task}
          projectId={utilization.project.id}
          sourceId={activeNode?.id}
          sourceToolId={utilization.id}
          sourceToolType={utilization.type}
          onClose={closeDialogs}
        />
      )}

      {utilization.project?.id && (
        <IdeaRecorder
          isOpen={activeDialog === CftWorkspaceDialogType.Idea}
          projectId={utilization.project.id}
          sourceId={activeNode?.id}
          sourceToolId={utilization.id}
          sourceToolType={utilization.type}
          onClose={closeDialogs}
        />
      )}
    </div>
  );
};
