import { IFiveWhysSolution } from '@priz/shared/src/models/tools/five-whys';
import axios, { AxiosResponse } from 'axios';

const fetchAll = (utilizationId: number): Promise<IFiveWhysSolution[]> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/5-whys/${utilizationId}/solution`)
    .then((response: AxiosResponse<IFiveWhysSolution[]>) => response.data);

const createSolution = (utilizationId: number, causeId: number, description: string): Promise<IFiveWhysSolution> =>
  axios
    .post(`/v1/api/<ws-id>/team/<t-id>/5-whys/${utilizationId}/cause/${causeId}/solution`, {
      description,
    })
    .then((response: AxiosResponse<IFiveWhysSolution>) => response.data);

const updateSolution = (utilizationId: number, solutionId: number, description: string): Promise<IFiveWhysSolution> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/5-whys/${utilizationId}/solution/${solutionId}`, {
      description,
    })
    .then((response: AxiosResponse<IFiveWhysSolution>) => response.data);

export const FiveWhysSolutionApi = {
  updateSolution,
  createSolution,
  fetchAll,
};
