import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SfmViewer } from '@priz/shared/src/components/tools-viewers';
import { SfmUtilization } from '@priz/shared/src/models/tools/sfm';
import { SfmResultSelectors } from '../../../sfm/store/selectors';
import { SfmUtilizationActions } from '../../../sfm/store/actions';

interface SfmResultProps {
  utilization: SfmUtilization;
}

export const SfmResult: React.FC<SfmResultProps> = ({ utilization }) => {
  const dispatch = useDispatch();

  const componentsRanks = useSelector(SfmResultSelectors.getComponentsRanksByUtilizationId(utilization?.id));
  const sfmRank = useSelector(SfmResultSelectors.getSfmRankByUtilizationId(utilization.id));
  const isLoaded = useSelector(SfmResultSelectors.isLoadedByUtilizationId(utilization?.id));

  useEffect(() => {
    if (!isLoaded && utilization) {
      dispatch(SfmUtilizationActions.calcComponentsRanks(utilization.id, utilization.project?.id));
    }
  }, [isLoaded, utilization]);

  useEffect(() => {
    if (utilization) {
      dispatch(SfmUtilizationActions.calcSfmRank(utilization.id, utilization.project?.id));
    }
  }, [utilization]);

  if (!utilization || !isLoaded) return null;

  return (
    <SfmViewer componentsRanksMap={componentsRanks} utilization={utilization} sfmRank={sfmRank} disableInteractive />
  );
};
