import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';

export interface NetworkToolsProps {
  data: string;
  onDraggingStateChange: (isDragging: boolean) => void;
  onDrop?: (data: string, x: number, y: number) => void;
}

export const DragWrapper: React.FC<NetworkToolsProps> = ({ data, onDraggingStateChange, children, onDrop }) => {
  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: 'node',
      item: { data },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<{ delta: { x: number; y: number } }>();

        if (item && dropResult && onDrop) {
          onDrop(item.data, dropResult.delta.x, dropResult.delta.y);
        }
      },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [onDrop],
  );

  useEffect(() => {
    if (onDraggingStateChange) onDraggingStateChange(isDragging);
  }, [isDragging]);

  return (
    <div ref={dragPreview} style={{ opacity: isDragging ? 0.3 : 1 }}>
      <div ref={drag}>{children}</div>
    </div>
  );
};
