import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Task, TaskStatus } from '@priz/shared/src/models/task';
import { Status } from '@priz/shared/src/components/status/component';
import { Box, Button, ListItemText, MenuItem, MenuList } from '@mui/material';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { Trans } from 'react-i18next';
import { TaskActions } from '../store/actions/task.actions';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';

interface StatusButtonProps {
  task: Task;
  disabled?: boolean;
}

const options = [
  { value: TaskStatus.Todo, text: 'Todo' },
  { value: TaskStatus.InProgress, text: 'In progress' },
  { value: TaskStatus.Done, text: 'Done' },
];

export const StatusButton: React.FC<StatusButtonProps> = ({ task, disabled }) => {
  const dispatch = useDispatch();
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);
  const [isDoneConfirmationOpen, setIsDoneConfirmationOpen] = useState(false);

  const menuOpenHandle = (isOpen: boolean) => {
    setIsSelectMenuOpen(isOpen);
  };

  const selectHandle = (value: TaskStatus) => {
    if (value !== TaskStatus.Done) {
      updateTaskStatus(value);
    } else {
      setIsDoneConfirmationOpen(true);
    }

    setIsSelectMenuOpen(false);
  };

  const updateTaskStatus = (status: TaskStatus) => {
    dispatch(TaskActions.updateStatus(task.project.id, task.id, status));
  };

  return (
    <>
      <PopperWrap
        open={isSelectMenuOpen}
        onOpenToggle={menuOpenHandle}
        placement={'bottom-start'}
        dropdownProps={{ p: 0 }}
        arrow={false}
      >
        <Button size="small" disabled={disabled}>
          <Status status={task.status} />
        </Button>

        <Box minWidth={200}>
          <MenuList>
            {options.map(item => (
              <MenuItem
                key={item.value}
                onClick={() => {
                  selectHandle(item.value);
                }}
                selected={task.status === item.value}
                disabled={disabled}
              >
                <ListItemText>
                  <Trans>{item.text}</Trans>
                </ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        </Box>
      </PopperWrap>

      <PgConfirmationDialog
        isOpen={isDoneConfirmationOpen}
        confirmTitle={<Trans>Confirm task finishing</Trans>}
        confirmContent={<Trans>Are you sure you want to finish this task?</Trans>}
        onConfirm={() => {
          updateTaskStatus(TaskStatus.Done);
          setIsDoneConfirmationOpen(false);
        }}
        onClose={() => {
          setIsDoneConfirmationOpen(false);
        }}
      />
    </>
  );
};
