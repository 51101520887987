import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  infoIcon: {
    marginRight: 10,
    verticalAlign: 'text-top',
  },
  gridColumn: {
    maxWidth: '100%',
  },
});
