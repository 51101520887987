import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ReactHookFormText } from '../../react/form-elements';
import { validateOptions } from '@priz/shared/src/utils/form';
import { LoadingButton } from '@mui/lab';
import { ProjectActions } from '../store/actions/project.actions';
import { CreateProjectCommand } from '../services';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProjectStatus } from '@priz/shared/src/models/project';
import { ProjectSelector } from '../store/selectors';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { SignUpForm } from '@priz/shared/src/components/security/signup-form/component';
import { Auth0RedirectKey } from '@priz/shared/src/models/auth0';
import { unbindPageLeavingPreventing } from '@priz/shared/src/utils/common';

interface MoveToolToProjectDialogProps {
  open: boolean;
  utilizationId?: number;
  onClose?: () => void;
  anonymous?: boolean;
}
enum MoveToolStep {
  Title = 'Title',
  Auth = 'Auth',
}

export const MoveToolToProjectDialog: React.FC<MoveToolToProjectDialogProps> = ({
  open,
  utilizationId,
  anonymous,
  onClose,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [step, setStep] = useState(MoveToolStep.Title);

  const isProjectCreating = useSelector(ProjectSelector.isProjectGettingCreated);

  const { handleSubmit, control, reset } = useForm({
    shouldFocusError: false,
    defaultValues: {
      projectTitle: '',
    },
  });

  useEffect(() => {
    if (open) {
      reset();
      goToProjectTitle();
    } else {
      LocalStorageService.removeItem(LocalStorageKey.ProjectTitleToCreate);
    }
  }, [open]);

  const createProject = () => {
    handleSubmit(formData => {
      const command: CreateProjectCommand = {
        title: formData.projectTitle.trim(),
        status: ProjectStatus.InProgress,
        utilizationId,
      };

      LocalStorageService.setItem(LocalStorageKey.LastViewedToolPath, pathname);

      dispatch(ProjectActions.createProject(command, navigate));
    })();
  };

  const goToProjectTitle = () => {
    setStep(MoveToolStep.Title);
  };

  const goToAuth = () => {
    handleSubmit(formDate => {
      LocalStorageService.setItem(LocalStorageKey.ProjectTitleToCreate, formDate.projectTitle);
      setStep(MoveToolStep.Auth);
    })();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>
        {!anonymous && <Trans>Project is required</Trans>}
        {anonymous && (
          <>
            {step === MoveToolStep.Title && <Trans>Step 1/2: Project title</Trans>}

            {step === MoveToolStep.Auth && <Trans>Step 2/2: Sign up</Trans>}
          </>
        )}
      </DialogTitle>

      <DialogContent>
        {step === MoveToolStep.Title && (
          <>
            <Typography component={Box} variant={'body1'} mb={2}>
              {anonymous && (
                <Trans>
                  This feature requires a project context. Please assign your project a relevant title. In the
                  subsequent step, you'll be asked to set up a new account, under which all your work will be securely
                  saved.
                </Trans>
              )}
              {!anonymous && (
                <Trans>
                  This feature is only available within the context of a project. You are welcome create create a new
                  project using the simple form below and the tool will automatically be moved into the new project.
                </Trans>
              )}
            </Typography>

            <ReactHookFormText
              name={'projectTitle'}
              fieldTitle={'Project title'}
              control={control}
              rules={{
                validate: {
                  ...validateOptions.hasText('Field is required'),
                },
              }}
              disabled={isProjectCreating || step !== MoveToolStep.Title}
            />
          </>
        )}

        {step === MoveToolStep.Auth && (
          <Box width={'100%'} maxWidth={500} mx={'auto'}>
            <h3 style={{ display: 'none' }}>registration</h3>

            <SignUpForm redirectKey={Auth0RedirectKey.Signup} beforeSend={unbindPageLeavingPreventing} />
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ textAlign: 'right' }}>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={onClose} disabled={isProjectCreating}>
          <Trans>Cancel</Trans>
        </Button>

        {anonymous && step === MoveToolStep.Title && (
          <Button variant={'pg_rounded'} color={'pg_orange_solid'} onClick={goToAuth}>
            <Trans>Next</Trans>
          </Button>
        )}

        {!anonymous && step === MoveToolStep.Title && (
          <LoadingButton
            variant={'pg_rounded'}
            color={'pg_orange_solid'}
            onClick={createProject}
            disabled={isProjectCreating}
            loading={isProjectCreating}
          >
            <Trans>Create project</Trans>
          </LoadingButton>
        )}

        {step === MoveToolStep.Auth && (
          <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={goToProjectTitle}>
            <Trans>Back</Trans>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
