import { AuditableEntity, IAuditableEntity } from '../../common/auditable-entity';
import { Identifiable } from '../../common/entity-collection-state';
import { UimFlawCost } from './uim-flaw-cost.enum';
import { UimFlawExpectancy } from './uim-flaw-expectancy.enum';
import { UimPriority } from './uim-priority.enum';
import { IdRef } from '../../../models/common/id-ref';

export interface IUimTask extends IAuditableEntity, Identifiable {
  description: string;
  flawExpectancy: UimFlawExpectancy;
  flawCost: UimFlawCost;
  priority: UimPriority;
  utilization: IdRef;
  latestRankingScore?: number;
  sourceTask?: IdRef;
}

export class UimTask extends AuditableEntity implements IUimTask {
  public id: number;

  public description: string;
  public flawExpectancy: UimFlawExpectancy;
  public flawCost: UimFlawCost;
  public priority: UimPriority;
  public utilization: IdRef;
  public latestRankingScore?: number;
  public sourceTask?: IdRef;

  constructor(json: IUimTask) {
    super(json);

    this.id = json.id;
    this.description = json.description;
    this.flawExpectancy = json.flawExpectancy;
    this.flawCost = json.flawCost;
    this.priority = json.priority;
    this.latestRankingScore = json.latestRankingScore;
    this.utilization = json.utilization;
    this.sourceTask = json.sourceTask;
  }
}
