import { Navigate, RouteObject } from 'react-router-dom';
import { RouteRenderer } from './route-renderer/component';
import { Project } from '../project/project/component';
import { EmptyRoot } from './empty-root/component';
import { NewProject } from '../project/new-project/component';
import { ProjectOverview } from '../project/project-overview/component';
import { ProblemStatement } from '../project/problem-statement/component';
import { TaskList } from '../tasks/task-list/component';
import { MeetingsView } from '../meetings/meetings-view/component';
import { MeetingPage } from '../meetings/meeting-page/component';
import { ProjectSolution } from '../project/project-solution/component';
import { ProjectReport } from '../project/project-report/component';
import { ProjectPublish } from '../project/project-publish/component';
import { IdeaManager } from '../idea/idea-manager/comonent';
import { ProjectReportPage } from '../project/project-report-page/component';
import { Certificate } from '../certificate/certificate/component';
import { ApprovalProcessActivity } from '../project-approval/approval-process-activity/component';
import { FinalCertificate } from '../certificate/final-certificate/component';
import { TemplatePage } from '../project/template/template-page/component';
import { UnleashFeatureName } from '@priz/shared/src/services/unleash';
import { documentTitleResolver } from './utils';
import { ProjectToolsPage } from '../project/project-tools-page/component';
import { toolsRoutes } from './tools-routes';
import { ProjectsAndTools } from '../project/projects-and-tools/component';

export const projectRoutes: RouteObject[] = [
  {
    path: 'projects-and-tools',
    element: <RouteRenderer Component={ProjectsAndTools} authGuard={true} />,
    loader: documentTitleResolver('Projects List'),
  },
  {
    path: 'new-project',
    element: <RouteRenderer Component={NewProject} authGuard={true} />,
    loader: documentTitleResolver('Create New Project'),
  },
  {
    path: 'start-project/:projectId',
    element: <RouteRenderer Component={NewProject} authGuard={true} />,
    loader: documentTitleResolver('Start the project'),
  },
  {
    path: 'project/:projectId',
    element: <RouteRenderer Component={Project} authGuard={true} />,
    children: [
      {
        path: 'template',
        element: (
          <RouteRenderer Component={TemplatePage} requiredFeature={UnleashFeatureName.Templates} authGuard={true} />
        ),
        loader: documentTitleResolver('Template'),
      },
      {
        path: 'overview',
        element: <RouteRenderer Component={ProjectOverview} authGuard={true} />,
        loader: documentTitleResolver('Project overview'),
      },
      {
        path: 'problem-statement',
        element: <RouteRenderer Component={ProblemStatement} authGuard={true} />,
        loader: documentTitleResolver('Problem Statement'),
      },
      {
        path: 'tasks',
        element: <RouteRenderer Component={TaskList} authGuard={true} />,
        loader: documentTitleResolver('Tasks'),
      },
      {
        path: 'meetings',
        element: <RouteRenderer Component={MeetingsView} authGuard={true} />,
        loader: documentTitleResolver('Meetings'),
      },
      {
        path: 'meeting/:meetingId',
        element: <RouteRenderer Component={MeetingPage} authGuard={true} />,
        loader: documentTitleResolver('Meeting'),
      },
      {
        path: 'solution',
        element: <RouteRenderer Component={ProjectSolution} authGuard={true} />,
        loader: documentTitleResolver('Solution'),
      },
      {
        path: 'report',
        element: <RouteRenderer Component={ProjectReport} authGuard={true} />,
        loader: documentTitleResolver('Report'),
      },
      {
        path: 'publish',
        element: <RouteRenderer Component={ProjectPublish} authGuard={true} />,
        loader: documentTitleResolver('Publish'),
      },
      {
        path: 'tools',
        element: <RouteRenderer Component={EmptyRoot} authGuard={true} />,
        children: [
          ...toolsRoutes,
          {
            path: 'list',
            element: <RouteRenderer Component={ProjectToolsPage} authGuard={true} />,
            loader: documentTitleResolver('Creative Tools'),
          },
          {
            path: '',
            element: <Navigate to="list" replace={true} />,
          },
        ],
      },
      {
        path: 'ideas',
        element: <RouteRenderer Component={IdeaManager} authGuard={true} />,
        loader: documentTitleResolver('Ideas'),
      },
      {
        path: 'report-page',
        element: <RouteRenderer Component={ProjectReportPage} authGuard={true} />,
        loader: documentTitleResolver('Project report'),
      },
      {
        path: 'certificate',
        element: <Navigate to="certificate-draft" replace={true} />,
      },
      {
        path: 'certificate-draft',
        element: <RouteRenderer Component={Certificate} authGuard={true} />,
        loader: documentTitleResolver('Certificate Draft'),
      },
      {
        path: 'certificate-review',
        element: <RouteRenderer Component={ApprovalProcessActivity} authGuard={true} />,
        loader: documentTitleResolver('Certificate Review'),
      },
      {
        path: 'certificate-final',
        element: <RouteRenderer Component={FinalCertificate} authGuard={true} />,
        loader: documentTitleResolver('Certificate Final'),
      },
      {
        path: 'details',
        element: <Navigate to="problem-statement" replace={true} />,
      },
      {
        path: '',
        element: <Navigate to="overview" replace={true} />,
      },
    ],
  },
];
