import { FortyPrinciplesConfigAction, FortyPrinciplesConfigActionType } from '../actions';
import { FortyPrinciplesConfig } from '../model';

const INITIAL_STATE = {
  selectedDomainId: null,
};

export function fortyPrinciplesConfigReducers(
  state: FortyPrinciplesConfig = INITIAL_STATE,
  action: FortyPrinciplesConfigAction,
) {
  switch (action.type) {
    case FortyPrinciplesConfigActionType.FP_CONFIG_SELECTED_DOMAIN_CHANGED:
      return Object.assign({}, state, {
        selectedDomainId: action.payload.selectedDomainId,
      });
  }

  return state;
}
