import { IProfile, Profile } from '../security/profile';

export interface IPublicUser {
  id?: number;
  email: string;

  profile?: IProfile;
}

export class PublicUser implements IPublicUser {
  public id?: number;
  public email: string;

  public profile?: Profile;

  constructor(json: IPublicUser) {
    this.id = json.id;
    this.email = json.email;
    this.profile = json.profile && new Profile(json.profile);
  }
}
