import { RouterActionMeta } from '../../../shared/store/types';
import { FluxStandardAction } from 'flux-standard-action';
import { createPgAction } from '../../../shared/store/action-creator';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { ToolType } from '@priz/shared/src/models/tools';
import { NavigateFunction } from 'react-router-dom';
import { ToolsUtils } from '../../../tools/utils';
import { ICftUtilization } from '@priz/shared/src/models/tools/cft';
import { CftUtilizationApi } from '../services/cft-utilization.api';
import {
  CftCreateVersionCommand,
  CftRemoveVersionCommand,
  CftSwitchVersionCommand,
  CftUpdateUtilizationCommand,
  CftUpdateVersionDataCommand,
  CftUpdateVersionTitleCommand,
} from '../model';

export enum CftUtilizationActionType {
  Update = 'cft-utilization/update',
  Delete = 'cft-utilization/delete',
  CreateNewVersion = 'cft-utilization/version/create',
  SwitchVersion = 'cft-utilization/version/switch',
  RemoveVersion = 'cft-utilization/version/remove',
  UpdateVersionData = 'cft-utilization/version/update/data',
  UpdateVersionTitle = 'cft-utilization/version/update/title',
}

interface CftUtilizationActionMeta extends RouterActionMeta {
  utilizationId?: number;
  projectId?: number;
  versionId?: string;
  navigate?: NavigateFunction;
}

export type CftUtilizationActionPayload = ICftUtilization;

export type CftUtilizationAction = FluxStandardAction<string, CftUtilizationActionPayload, CftUtilizationActionMeta>;

const updateUtilization = createPgAction(
  CftUtilizationActionType.Update,
  CftUtilizationApi.updateUtilization,
  (utilizationId: number, _command: CftUpdateUtilizationCommand, projectId?: number) => ({
    utilizationId,
    projectId,
  }),
  (_payload, { projectId, utilizationId }) => {
    void AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      tool_type: ToolType.CFT,
      utilization_id: utilizationId,
      project_id: projectId,
    });
  },
);

const updateVersionData = createPgAction(
  CftUtilizationActionType.UpdateVersionData,
  CftUtilizationApi.updateVersionData,
  (utilizationId: number, command: CftUpdateVersionDataCommand, projectId?: number) => ({
    projectId,
    utilizationId,
    versionId: command.versionId,
  }),
  (_payload, { projectId, utilizationId }) => {
    void AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      tool_type: ToolType.CFT,
      utilization_id: utilizationId,
      project_id: projectId,
    });
  },
);

const createNewVersion = createPgAction(
  CftUtilizationActionType.CreateNewVersion,
  CftUtilizationApi.createNewVersion,
  (utilizationId: number, _command: CftCreateVersionCommand, projectId?: number) => ({
    projectId,
    utilizationId,
  }),
  (_payload, { projectId, utilizationId }) => {
    void AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      tool_type: ToolType.CFT,
      utilization_id: utilizationId,
      project_id: projectId,
    });
  },
);

const updateVersionTitle = createPgAction(
  CftUtilizationActionType.UpdateVersionTitle,
  CftUtilizationApi.updateVersionTitle,
  (utilizationId: number, command: CftUpdateVersionTitleCommand, projectId?: number) => ({
    projectId,
    utilizationId,
    versionId: command.versionId,
  }),
  (_payload, { projectId, utilizationId }) => {
    void AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      tool_type: ToolType.CFT,
      utilization_id: utilizationId,
      project_id: projectId,
    });
  },
);

const switchVersion = createPgAction(
  CftUtilizationActionType.SwitchVersion,
  CftUtilizationApi.switchVersion,
  (utilizationId: number, command: CftSwitchVersionCommand, projectId?: number) => ({
    projectId,
    utilizationId,
    versionId: command.versionId,
  }),
  (_payload, { projectId, utilizationId }) => {
    void AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      tool_type: ToolType.CFT,
      utilization_id: utilizationId,
      project_id: projectId,
    });
  },
);

const removeVersion = createPgAction(
  CftUtilizationActionType.RemoveVersion,
  CftUtilizationApi.removeVersion,
  (utilizationId: number, command: CftRemoveVersionCommand, projectId?: number) => ({
    projectId,
    utilizationId,
    versionId: command.versionId,
  }),
  (_payload, { projectId, utilizationId }) => {
    void AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      tool_type: ToolType.CFT,
      utilization_id: utilizationId,
      project_id: projectId,
    });
  },
);

const deleteUtilization = createPgAction(
  CftUtilizationActionType.Delete,
  CftUtilizationApi.deleteUtilization,
  (utilizationId: number, projectId?: number, navigate?: NavigateFunction) => ({
    projectId,
    utilizationId,
    navigate,
  }),
  (_payload, { projectId, utilizationId, navigate }) => {
    void AnalyticsService.track(AnalyticsEvent.TOOL_DELETED, {
      tool_type: ToolType.CFT,
      utilization_id: utilizationId,
      project_id: projectId,
    });

    if (navigate) {
      navigate(ToolsUtils.resolveListPath(projectId));
    }
  },
);

export const CftUtilizationActions = {
  updateUtilization,
  deleteUtilization,
  createNewVersion,
  updateVersionData,
  updateVersionTitle,
  switchVersion,
  removeVersion,
};
