import { FluxStandardAction } from 'flux-standard-action';
import { createPgAction } from '../../../shared/store/action-creator';
import { UserProjectSettingsResponse, UserProjectSettings } from '@priz/shared/src/models/user/user-project-settings';
import { UserProjectSettingsApi } from '@priz/shared/src/services/user';

export enum UserProjectSettingsActionType {
  Fetch = 'userProjectSettings/fetch',
  Update = 'userProjectSettings/update',
}

export type UserProjectSettingsAction = FluxStandardAction<UserProjectSettingsActionType, UserProjectSettingsResponse>;

const load = createPgAction(UserProjectSettingsActionType.Fetch, UserProjectSettingsApi.get);

const update = createPgAction(
  UserProjectSettingsActionType.Fetch,
  UserProjectSettingsApi.update,
  (settings: UserProjectSettings) => ({ settings }),
);

export const UserProjectSettingsActions = {
  load,
  update,
};
