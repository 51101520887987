import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  scrollbar: {
    position: 'absolute',
    paddingRight: 16,
    boxSizing: 'border-box',
  },
});
