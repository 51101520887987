import React, { ReactNode, useEffect, useState } from 'react';
import { Role } from '@priz/shared/src/models/security/role.enum';
import { Workspace, PlanLevel, SubscriptionStatus } from '@priz/shared/src/models/workspace';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthService } from '@priz/shared/src/services/auth';
import { useDispatch, useSelector } from 'react-redux';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { WorkspaceActions } from '../../workspace/store/actions';
import { UserContextService } from '@priz/shared/src/services/user';
import { useIsMounted } from '@priz/shared/src/utils/hooks';
import { UserContextSelectors } from '../../security/store/selectors';
import { UserContextActions } from '../../security/store/actions';
import { AuthFormType } from '@priz/shared/src/models/auth0';

export interface AuthGuardProps {
  roles?: Role[];
  forceBilling?: boolean;
  loadingElement?: ReactNode;
  mfaFree?: boolean;
}

const isWorkspaceActive = (workspace: Workspace): boolean => {
  const isPersonal = workspace?.type === PlanLevel.Personal;
  const haveSelectedPlan = !!(workspace.plan && workspace.plan.id);
  const isNotCancelled = workspace.subscriptionStatus !== SubscriptionStatus.Canceled;

  return (isPersonal || haveSelectedPlan) && isNotCancelled;
};

export const AuthGuard: React.FC<AuthGuardProps> = ({
  roles,
  forceBilling = true,
  loadingElement,
  mfaFree,
  children,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { checkIsMounted } = useIsMounted();

  const [isAuthPromisePassed, setIsAuthPromisePassed] = useState(false);
  const [pathnameToCheck, setPathnameToCheck] = useState<string>();
  const [isAccessAllowed, setIsAccessAllowed] = useState(false);

  const currentUser = useSelector(UserContextSelectors.getCurrentUser);
  const isCurrentUserLoaded = useSelector(UserContextSelectors.isLoaded);
  const isCurrentUserLoading = useSelector(UserContextSelectors.isLoading);

  const selectedWorkspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const isWorkspaceLoaded = useSelector(WorkspaceSelectors.isLoaded);
  const isWorkspaceLoading = useSelector(WorkspaceSelectors.isLoading);
  const initialWorkspace = useSelector(WorkspaceSelectors.getInitialWorkspace());

  const roleIsValid = roles ? AuthService.access(roles) : true;

  useEffect(() => {
    AuthService.authPromise().then(
      () => {
        if (checkIsMounted()) {
          setIsAuthPromisePassed(true);
        }
      },
      () => {
        navigate('/login');
      },
    );
  }, []);

  useEffect(() => {
    if (isAuthPromisePassed && !isCurrentUserLoaded && !isCurrentUserLoading) {
      dispatch(UserContextActions.load());
    }
  }, [isAuthPromisePassed, isCurrentUserLoaded, isCurrentUserLoading]);

  useEffect(() => {
    if (isAuthPromisePassed && !isWorkspaceLoaded && !isWorkspaceLoading) {
      dispatch(WorkspaceActions.loadAll());
    }
  }, [isAuthPromisePassed, isWorkspaceLoaded, isWorkspaceLoading]);

  useEffect(() => {
    if (pathname !== pathnameToCheck && isCurrentUserLoaded && isWorkspaceLoaded) {
      setPathnameToCheck(pathname);
    }
  }, [pathname, pathnameToCheck, isCurrentUserLoaded, isWorkspaceLoaded]);

  useEffect(() => {
    if (pathnameToCheck) {
      resolveAccess();
    }
  }, [pathnameToCheck]);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.addEventListener) {
      window.addEventListener('visibilitychange', checkWorkspace);
      window.addEventListener('focus', checkWorkspace);
    }

    return () => {
      window.removeEventListener('visibilitychange', checkWorkspace);
      window.removeEventListener('focus', checkWorkspace);
    };
  }, [selectedWorkspace?.id]);

  const checkWorkspace = () => {
    if (document.visibilityState === 'visible') {
      const lsWorkspaceId = UserContextService.getSelectedWorkspaceId();

      if (typeof lsWorkspaceId !== 'number' || typeof selectedWorkspace?.id !== 'number') return;
      if (lsWorkspaceId !== selectedWorkspace.id) window.location.reload();
    }
  };

  const navigateOrResolveAccess = (path: string) => {
    if (pathname !== path) {
      navigate(path);
    } else {
      setIsAccessAllowed(true);
    }
  };

  const resolveAccess = () => {
    if (!roleIsValid) {
      return AuthService.logout(AuthFormType.Login);
    }

    if (selectedWorkspace && forceBilling && !isWorkspaceActive(selectedWorkspace)) {
      return navigateOrResolveAccess('/workspace/billing');
    }

    if (!selectedWorkspace && initialWorkspace) {
      return navigateOrResolveAccess(['/workspace', initialWorkspace.id, 'switch'].join('/'));
    }

    if (!selectedWorkspace && !initialWorkspace) {
      return navigateOrResolveAccess('/workspace/new');
    }

    if (!mfaFree && selectedWorkspace && selectedWorkspace.mfa && currentUser && !currentUser.mfa) {
      return navigateOrResolveAccess('/profile/security-setting');
    }

    setIsAccessAllowed(true);
  };

  return isAccessAllowed ? <>{children}</> : loadingElement ? <>{loadingElement}</> : null;
};
