import React from 'react';
import { Alert, Box, Button, CircularProgress } from '@mui/material';
import { Trans } from 'react-i18next';
import { InputValueSuggesterType } from '../model/inputs';
import { AutoFixHighOutlined } from '@mui/icons-material';

interface OptionsSuggesterMessageProps {
  type: InputValueSuggesterType;
  onSuggest?: () => void;
  isLoading?: boolean;
  isError?: boolean;
}

export const OptionsSuggesterMessage: React.FC<OptionsSuggesterMessageProps> = ({
  type,
  onSuggest,
  isLoading,
  isError,
}) => {
  return (
    <Box p={1}>
      <Box mb={2}>
        <Trans>No matching options</Trans>
      </Box>

      {isError && (
        <Box mb={2}>
          <Alert severity={'info'}>
            <Trans>Something is wrong. Try again in a bit.</Trans>
          </Alert>
        </Box>
      )}

      <Box textAlign={'center'}>
        {!isLoading && (
          <Button variant={'outlined'} endIcon={<AutoFixHighOutlined />} size={'small'} onClick={onSuggest}>
            {isError && <Trans>Retry</Trans>}

            {!isError && <Trans>{type === InputValueSuggesterType.Industry && 'Get matching industries'}</Trans>}
          </Button>
        )}

        {isLoading && <CircularProgress />}
      </Box>
    </Box>
  );
};
