import React from 'react';
import {
  CftNodeData,
  CftNodePosition,
  CftNodesMap,
  CftNodeType,
  OptionalCftNodeControlsRenderer,
  OptionalCftNodeTextEditorRenderer,
} from '../../../models/tools/cft';
import { CftDataUtils } from '../../../utils/cft';
import { useStyles } from './styles';
import { Box, BoxProps, Typography } from '@mui/material';
import { CftProcess } from '../cft-process/component';
import { CftStep } from '../cft-step/component';
import { cftSettings } from '../data';

interface CftVariantProps
  extends BoxProps,
    CftNodePosition,
    OptionalCftNodeControlsRenderer,
    OptionalCftNodeTextEditorRenderer {
  node: CftNodeData;
  nodesMap: CftNodesMap;
  index: number;
}

export const CftVariant: React.FC<CftVariantProps> = ({
  node,
  nodesMap,
  index,
  controlsRenderer,
  editorRenderer,
  inRootBranch,
  ...rest
}) => {
  const styles = useStyles();
  const tree = CftDataUtils.resolveTree(nodesMap, node.id);

  const categories: {
    processes: CftNodeData[];
    steps: CftNodeData[];
  } = {
    processes: [],
    steps: [],
  };

  tree.forEach(level => {
    level.nodes.forEach(node => {
      if (node.type === CftNodeType.Step) {
        categories.steps.push(node);
      }

      if (node.type === CftNodeType.Process) {
        categories.processes.push(node);
      }
    });
  });

  return (
    <Box id={node.id} className={styles.root} {...rest}>
      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} p={2}>
        <Typography variant={'h6'} className={styles.letter} component={Box} mr={2}>
          {index}
        </Typography>

        <Box
          className={styles.titleContainer}
          style={{ maxWidth: cftSettings.node.step.width.max * (categories.processes.length || 1) }}
        >
          {editorRenderer && editorRenderer({ node, inRootBranch, className: styles.title })}

          {!editorRenderer && <div className={styles.title}>{node.description}</div>}
        </Box>

        {controlsRenderer && controlsRenderer({ node, inRootBranch, className: styles.controls })}
      </Box>

      <Box
        display={'flex'}
        pt={cftSettings.node.variant.paddings.top}
        pb={cftSettings.node.variant.paddings.bottom}
        pl={cftSettings.node.variant.paddings.left}
        pr={cftSettings.node.variant.paddings.right}
      >
        {!!categories.steps.length && (
          <Box className={styles.stepsContainer}>
            {categories.steps.map((node, key) => (
              <CftStep
                key={key}
                mt={key ? cftSettings.spacing.row : 0}
                node={node}
                inVariant={true}
                controlsRenderer={controlsRenderer}
                editorRenderer={editorRenderer}
                inRootBranch={inRootBranch}
              />
            ))}
          </Box>
        )}

        {categories.processes.map((node, key) => (
          <React.Fragment key={key}>
            <CftProcess
              node={node}
              nodesMap={nodesMap}
              controlsRenderer={controlsRenderer}
              editorRenderer={editorRenderer}
              inRootBranch={inRootBranch}
            />

            {key < categories.processes.length - 1 && (
              <Box
                className={styles.divider}
                ml={cftSettings.node.variant.paddings.left}
                mr={cftSettings.node.variant.paddings.right}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};
