import { ApaActionCollection, DefaultApaActionCollection } from '@priz/shared/src/models/tools/apa';
import {
  FiveWhysCauseCollection,
  FiveWhysSolutionCollection,
  DefaultFiveWhysCauseCollection,
  DefaultFiveWhysSolutionCollection,
} from '@priz/shared/src/models/tools/five-whys';
import { CertificateCollection, DefaultCertificateCollection } from '../certificate/store';
import { DefaultEbsIdeaCollection, EbsIdeaCollection } from '@priz/shared/src/models/tools/ebs';
import {
  DefaultDomainList,
  DefaultDomainPrincipleList,
  DefaultFortyPrinciplesConfig,
  DefaultMatrixElementList,
  DefaultParameterList,
  DefaultPrincipleList,
  DomainList,
  DomainPrincipleList,
  FortyPrinciplesConfig,
  MatrixElementList,
  ParameterList,
  PrincipleList,
} from '../forty-principles/store/model';
import { ApprovalActivityCollection, DefaultApprovalActivityCollection } from '../project-approval/store/model';
import {
  DefaultProjectCollection,
  DefaultUtilizationCollection,
  ProjectCollection,
  UtilizationCollection,
} from '@priz/shared/src/models/project';
import { DefaultRrmGoalCollection, RrmGoalCollection } from '@priz/shared/src/models/tools/rrm';
import { EmptyUserContext, UserContext } from '../security/store/model';
import { DefaultTaskCollection, TaskCollection } from '@priz/shared/src/models/task';
import { DefaultUimTaskCollection, UimTaskCollection } from '@priz/shared/src/models/tools/uim';
import {
  CurrentUser,
  DefaultCurrentUser,
  DefaultPublicUserCollection,
  PublicUserCollection,
} from '@priz/shared/src/models/user';
import {
  DefaultPlanCollection,
  DefaultWorkspaceCollection,
  DefaultWorkspaceMembersCollection,
  PlanCollection,
  WorkspaceCollection,
  WorkspaceMembersCollection,
} from '@priz/shared/src/models/workspace';
import { DefaultProjectIdeasCommentsState, ProjectIdeasCommentsState } from '../idea/store';
import { EntityMap } from '@priz/shared/src/models/common/entity-collection-state';
import { DefaultProjectAssigneeCollection, ProjectAssigneeCollection } from '@priz/shared/src/models/assignment';
import { DefaultNotificationState, NotificationState } from '../react/modules/notification/model/notification-state';
import { DefaultPaywallState, PaywallState } from '../react/modules/paywall/store';
import { DefaultPreloaderState, PreloaderState } from '../react/preloader/store/model';
import {
  DefaultUserProjectSettingsState,
  DefaultUserSettingsState,
  UserProjectSettingsState,
  UserSettingsState,
} from '../user/store/model';
import {
  DefaultTaskAssigneeCollection,
  TaskAssigneeCollection,
} from '@priz/shared/src/models/assignment/task-assignee-collection';
import { AttachmentsCollection, DefaultAttachmentsCollection } from '../attachments/store/model';
import {
  AgendasCollection,
  AttendeesCollection,
  DefaultAgendasCollection,
  DefaultAttendeesCollection,
  DefaultMeetingsCollection,
  MeetingsCollection,
} from '../meetings/store/model';
import { AssistantState, DefaultAssistantState } from '../assistant/store/model';
import { DefaultDialogsState, DialogsState } from '../dialogs/store/model';
import { DefaultSfmResultCollection, SfmResultCollection } from '../sfm/store/model';
import { DefaultPMapResultCollection, PMapResultCollection } from '../pmap/store/model';
import { ApiKeysState, DefaultApiKeysState } from '../user-profile/api-keys/store/model';
import { DefaultPartnershipState, PartnershipState } from '../user-profile/partnership/store/model';
import { DefaultTeamCollection, TeamCollection } from '@priz/shared/src/models/team';
import { ChatState, DefaultChatState } from '../chat/store/model';
import {
  DefaultProjectFeatureBlockerState,
  ProjectFeatureResolverBlockerState,
} from '../project-feature-blocker/store/model';
import { DefaultPlaygroundState, PlaygroundState } from '../playground/store/model';
import { IdeaList } from '@priz/shared/src/models/idea';

export interface AppState {
  users: PublicUserCollection;
  currentUser: CurrentUser;
  userContext: UserContext;
  userSettings: UserSettingsState;
  userProjectSettings: UserProjectSettingsState;
  partnership: PartnershipState;
  apiKeys: ApiKeysState;

  domainList: DomainList;
  parameterList: ParameterList;
  principleList: PrincipleList;
  domainPrincipleList: DomainPrincipleList;
  ebsIdeas: EbsIdeaCollection;
  matrixElementList: MatrixElementList;
  fortyPrinciplesConfig: FortyPrinciplesConfig;

  projects: ProjectCollection;

  toolUtilizations: UtilizationCollection;

  sfmResult: SfmResultCollection;
  pmapResult: PMapResultCollection;

  plans: PlanCollection;
  projectIdeasMap: EntityMap<IdeaList>;
  projectIdeasComments: ProjectIdeasCommentsState;
  rrmGoals: RrmGoalCollection;

  uimTasks: UimTaskCollection;

  fiveWhysCauses: FiveWhysCauseCollection;
  fiveWhysSolutions: FiveWhysSolutionCollection;

  apaActions: ApaActionCollection;

  certificates: CertificateCollection;
  approvalActivities: ApprovalActivityCollection;

  tasks: TaskCollection;

  workspaces: WorkspaceCollection;
  workspaceMembers: WorkspaceMembersCollection;
  teams: TeamCollection;

  notifications: Readonly<NotificationState>;
  paywall: Readonly<PaywallState>;
  projectFeatureBlocker: Readonly<ProjectFeatureResolverBlockerState>;

  preloader: Readonly<PreloaderState>;

  projectAssignees: ProjectAssigneeCollection;
  taskAssignees: TaskAssigneeCollection;

  attachments: AttachmentsCollection;

  agendas: AgendasCollection;
  attendees: AttendeesCollection;
  meetings: MeetingsCollection;

  assistant: AssistantState;
  chat: ChatState;

  dialogs: DialogsState;

  playground: PlaygroundState;
}

export const DefaultAppState: AppState = {
  apaActions: DefaultApaActionCollection,
  approvalActivities: DefaultApprovalActivityCollection,
  fiveWhysCauses: DefaultFiveWhysCauseCollection,
  fiveWhysSolutions: DefaultFiveWhysSolutionCollection,
  certificates: DefaultCertificateCollection,
  users: DefaultPublicUserCollection,
  currentUser: DefaultCurrentUser,
  userContext: EmptyUserContext,
  userSettings: DefaultUserSettingsState,
  userProjectSettings: DefaultUserProjectSettingsState,
  partnership: DefaultPartnershipState,
  apiKeys: DefaultApiKeysState,
  domainList: DefaultDomainList,
  domainPrincipleList: DefaultDomainPrincipleList,
  ebsIdeas: DefaultEbsIdeaCollection,
  fortyPrinciplesConfig: DefaultFortyPrinciplesConfig,
  matrixElementList: DefaultMatrixElementList,
  parameterList: DefaultParameterList,
  plans: DefaultPlanCollection,
  principleList: DefaultPrincipleList,
  projectIdeasMap: {},
  projectIdeasComments: DefaultProjectIdeasCommentsState,
  projects: DefaultProjectCollection,
  rrmGoals: DefaultRrmGoalCollection,
  tasks: DefaultTaskCollection,
  toolUtilizations: DefaultUtilizationCollection,
  sfmResult: DefaultSfmResultCollection,
  pmapResult: DefaultPMapResultCollection,
  uimTasks: DefaultUimTaskCollection,
  workspaceMembers: DefaultWorkspaceMembersCollection,
  workspaces: DefaultWorkspaceCollection,
  teams: DefaultTeamCollection,
  notifications: DefaultNotificationState,
  paywall: DefaultPaywallState,
  projectFeatureBlocker: DefaultProjectFeatureBlockerState,
  preloader: DefaultPreloaderState,
  projectAssignees: DefaultProjectAssigneeCollection,
  taskAssignees: DefaultTaskAssigneeCollection,
  attachments: DefaultAttachmentsCollection,
  agendas: DefaultAgendasCollection,
  attendees: DefaultAttendeesCollection,
  meetings: DefaultMeetingsCollection,
  assistant: DefaultAssistantState,
  chat: DefaultChatState,
  dialogs: DefaultDialogsState,
  playground: DefaultPlaygroundState,
};

export const generateCleanAppState = (state: Partial<AppState>) => ({
  ...DefaultAppState,
  ...state,
});
