import React from 'react';
import { FormHelperText } from '@mui/material';
import { Trans } from 'react-i18next';

export interface HelperTextProps {
  text?: React.ReactNode;
  error?: string;
}

export const HelperText: React.FC<HelperTextProps> = ({ error, text }) => {
  const textIsEmpty = !text || (typeof text === 'string' && !text?.length);

  if (textIsEmpty && !error?.length) return null;

  return (
    <FormHelperText error={!!error} component={'span'}>
      {error || (typeof text === 'string' && <Trans>{error || text}</Trans>)}

      {!error && text && typeof text !== 'string' && text}
    </FormHelperText>
  );
};
