import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  option: {
    cursor: 'pointer',
  },
  avatar: {
    height: '24px',
    width: '24px',
  },
  errorMessage: {
    'color': pgColorScheme.coral,

    '&:not(._show)': {
      opacity: 0,
      pointerEvents: 'none',
    },
  },
});
