import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';

export const useStyles = makeStyles({
  root: {
    backgroundColor: pgColorScheme.gray400,
    borderRadius: 100,
  },
  bar: {
    borderRadius: 'inherit',
  },
  barGreen: {
    backgroundColor: pgColorScheme.green,
  },
  barBlue: {
    backgroundColor: pgColorScheme.blue,
  },
  barRed: {
    backgroundColor: pgColorScheme.coral,
  },
});
