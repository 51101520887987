import React from 'react';
import {
  FeatureLimit,
  featureLimitDescriptionMap,
  PlanFeatureSet,
  PlanFeaturesMap,
} from '@priz/shared/src/models/workspace';
import { Box, TableCell, TableRow } from '@mui/material';
import { Trans } from 'react-i18next';
import { PlanLevel } from '@priz/shared/src/models/workspace';
import { PlanFeatureIndicator } from '../../plan-feature-indicator/component';

interface SecurityRowsProps {
  baseFeatureSet: PlanFeatureSet;
  data: PlanFeaturesMap;
  colsWidth?: string[];
}

export const SecurityRows: React.FC<SecurityRowsProps> = ({ baseFeatureSet, data, colsWidth }) => {
  return (
    <>
      {Object.keys(baseFeatureSet[FeatureLimit.Security]).map((feature: FeatureLimit) => (
        <TableRow key={feature} hover={true}>
          <TableCell width={colsWidth?.[0] || '20%'}>
            <Box pl={2}>
              <Trans>{featureLimitDescriptionMap[feature]?.text || feature}</Trans>
            </Box>
          </TableCell>
          {Object.keys(data).map((level: PlanLevel, key) => (
            <TableCell key={level} align="center" width={colsWidth?.[key + 1] || `${80 / Object.keys(data).length}%`}>
              <PlanFeatureIndicator value={data[level][FeatureLimit.Security][feature]} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};
