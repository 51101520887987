import { handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { toObjectMap } from '../../../shared/store/reducer-utils';
import { ArrayUtil } from '../../../shared/utils/array-util';
import {
  ApaAction,
  ApaActionCollection,
  DefaultApaActionCollection,
  IApaAction,
} from '@priz/shared/src/models/tools/apa';
import { ApaActionAction, ApaActionActionType } from '../actions';
import { copyObject } from '@priz/shared/src/utils/common';

const setCollectionStatus = (
  state: ApaActionCollection,
  projectId: number,
  utilizationId: number,
  statuses: Partial<EntityCollectionStatus>,
): ApaActionCollection => {
  const statusesCopy = copyObject(state.statuses);
  statusesCopy.byProjectId[projectId] = { ...(statusesCopy.byProjectId[projectId] || {}), ...statuses };
  statusesCopy.byUtilizationId[utilizationId] = { ...(statusesCopy.byUtilizationId[utilizationId] || {}), ...statuses };

  return {
    ...state,
    statuses: statusesCopy,
  };
};

const apaActionInstantiator = (payload: any): ApaAction => new ApaAction(payload);

const setLoadedActions = (
  state: ApaActionCollection,
  utilizationId: number,
  actionsPayload: IApaAction[],
): ApaActionCollection => {
  const newActionMap = toObjectMap<ApaAction>(actionsPayload, apaActionInstantiator);

  const lookupsCopy = copyObject(state.lookups);
  Object.values(newActionMap).forEach((action: ApaAction) => {
    lookupsCopy.byUtilizationId[utilizationId] = ArrayUtil.concatInDistinctArray(
      lookupsCopy.byUtilizationId[utilizationId],
      action.id,
    );
  });

  const statusesCopy = copyObject(state.statuses);
  statusesCopy.byUtilizationId[utilizationId] = {
    ...(statusesCopy.byUtilizationId[utilizationId] || {}),
    error: false,
    loaded: true,
    loading: false,
  };

  return {
    ...state,
    entities: { ...state.entities, ...newActionMap },
    lookups: lookupsCopy,
    statuses: statusesCopy,
  };
};

const setAction = (
  state: ApaActionCollection,
  projectId: number,
  actionPayload: IApaAction,
  statuses?: EntityCollectionStatus,
): ApaActionCollection => {
  const action = apaActionInstantiator(actionPayload);
  const entitiesCopy = {
    ...state.entities,
    [action.id]: action,
  };

  const lookupsCopy = copyObject(state.lookups);
  lookupsCopy.byUtilizationId[action.utilization.id] = ArrayUtil.concatInDistinctArray(
    lookupsCopy.byUtilizationId[action.utilization.id],
    action.id,
  );

  const statusesCopy = copyObject(state.statuses);
  if (statuses) {
    statusesCopy.byUtilizationId[action.utilization.id] = {
      ...(statusesCopy.byUtilizationId[action.utilization.id] || {}),
      ...statuses,
    };
    statusesCopy.byProjectId[projectId] = {
      ...(statusesCopy.byUtilizationId[projectId] || {}),
      ...statuses,
    };
  }

  return {
    ...state,
    entities: entitiesCopy,
    lookups: lookupsCopy,
    statuses: statusesCopy,
  };
};

const removeAction = (state: ApaActionCollection, utilizationId: number, actionId: number): ApaActionCollection => {
  const entitiesCopy = { ...state.entities };
  delete entitiesCopy[actionId];

  const lookupsCopy = copyObject(state.lookups);
  lookupsCopy.byUtilizationId[utilizationId] = lookupsCopy.byUtilizationId[utilizationId].filter(id => id !== actionId);

  return {
    ...state,
    entities: entitiesCopy,
    lookups: lookupsCopy,
  };
};

export const apaActionReducers = handleActions(
  {
    /////////// FetchAll ///////////
    [toStarted(ApaActionActionType.FetchAll)]: (
      state: ApaActionCollection,
      action: ApaActionAction,
    ): ApaActionCollection =>
      setCollectionStatus(state, action.meta.projectId, action.meta.utilizationId, {
        loaded: false,
        loading: true,
      }),
    [toSuccess(ApaActionActionType.FetchAll)]: (
      state: ApaActionCollection,
      action: ApaActionAction,
    ): ApaActionCollection => setLoadedActions(state, action.meta.utilizationId, action.payload as IApaAction[]),
    [toFailed(ApaActionActionType.FetchAll)]: (
      state: ApaActionCollection,
      action: ApaActionAction,
    ): ApaActionCollection =>
      setCollectionStatus(state, action.meta.projectId, action.meta.utilizationId, {
        error: false,
        loaded: false,
        loading: false,
      }),

    /////////// Create ///////////
    [toStarted(ApaActionActionType.Create)]: (
      state: ApaActionCollection,
      action: ApaActionAction,
    ): ApaActionCollection =>
      setCollectionStatus(state, action.meta.projectId, action.meta.utilizationId, { creating: true }),
    [toSuccess(ApaActionActionType.Create)]: (
      state: ApaActionCollection,
      action: ApaActionAction,
    ): ApaActionCollection =>
      setAction(state, action.meta.projectId, action.payload as IApaAction, { creating: false }),
    [toFailed(ApaActionActionType.Create)]: (
      state: ApaActionCollection,
      action: ApaActionAction,
    ): ApaActionCollection =>
      setCollectionStatus(state, action.meta.projectId, action.meta.utilizationId, {
        creating: false,
        error: true,
      }),

    /////////// Update ////////////
    [toStarted(ApaActionActionType.Update)]: (
      state: ApaActionCollection,
      action: ApaActionAction,
    ): ApaActionCollection =>
      setCollectionStatus(state, action.meta.projectId, action.meta.utilizationId, { saving: true }),
    [toSuccess(ApaActionActionType.Update)]: (
      state: ApaActionCollection,
      action: ApaActionAction,
    ): ApaActionCollection => setAction(state, action.meta.projectId, action.payload as IApaAction, { saving: false }),
    [toFailed(ApaActionActionType.Update)]: (
      state: ApaActionCollection,
      action: ApaActionAction,
    ): ApaActionCollection =>
      setCollectionStatus(state, action.meta.projectId, action.meta.utilizationId, { saving: false, error: true }),

    // Complete
    [toStarted(ApaActionActionType.Complete)]: (
      state: ApaActionCollection,
      action: ApaActionAction,
    ): ApaActionCollection =>
      setCollectionStatus(state, action.meta.projectId, action.meta.utilizationId, {
        updating: true,
        updated: false,
        error: false,
      }),
    [toFailed(ApaActionActionType.Complete)]: (
      state: ApaActionCollection,
      action: ApaActionAction,
    ): ApaActionCollection =>
      setCollectionStatus(state, action.meta.projectId, action.meta.utilizationId, {
        updating: false,
        updated: false,
        error: true,
      }),
    [toSuccess(ApaActionActionType.Complete)]: (
      state: ApaActionCollection,
      action: ApaActionAction,
    ): ApaActionCollection =>
      setAction(state, action.meta.projectId, action.payload as IApaAction, {
        updating: false,
        updated: true,
        error: false,
      }),

    /////////// Other ////////////
    [toSuccess(ApaActionActionType.Fetch)]: (
      state: ApaActionCollection,
      action: ApaActionAction,
    ): ApaActionCollection => setAction(state, action.meta.projectId, action.payload as IApaAction),

    [toSuccess(ApaActionActionType.ApaAction_Delete)]: (
      state: ApaActionCollection,
      action: ApaActionAction,
    ): ApaActionCollection => removeAction(state, action.meta.utilizationId, action.meta.actionId),
  },
  DefaultApaActionCollection,
);
