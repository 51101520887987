import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { pgColorScheme } from '@priz/shared/src/theme';
import { RrmUtilization } from '@priz/shared/src/models/tools/rrm/rrm-utilization';
import { Rank } from '@priz/shared/src/models/tools/rrm';
import { RrmProgressBar } from '../RrmProgressBar';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RrmGoalSelector } from '../../store/selectors';
import { PgProgressBar } from '../../../react/elements/PgProgressBar';
import { Box } from '@mui/material';

interface RrmRankingProgressProps {
  utilization: RrmUtilization;
}

const useStyles = makeStyles({
  progressText: {
    color: pgColorScheme.black,
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '120%',
    textAlign: 'center',
  },
  root: {
    marginTop: '30px',
  },
});

export const RrmRankingProgress: React.FC<RrmRankingProgressProps> = ({ utilization }) => {
  const styles = useStyles();

  const [total, setTotal] = useState<number>();
  const [completed, setCompleted] = useState<number>();

  const goal = useSelector(RrmGoalSelector.getGoalById(utilization?.goal?.id));

  useEffect(() => {
    if (utilization && !utilization.smart) {
      const totalPairs = utilization.ranks.length;
      const completedPairs = utilization.ranks.filter(r => Rank.isCompleted(r)).length;

      setTotal(totalPairs);
      setCompleted(completedPairs);
    }
  }, [utilization]);

  useEffect(() => {
    if (utilization && utilization.smart && goal) {
      const totalPairs = goal.perceptions.length - 1;
      const completedPairs = Math.max(goal.perceptions.filter(p => p.rankingScore !== null).length - 1, 0);

      setTotal(totalPairs);
      setCompleted(completedPairs);
    }
  }, [utilization, goal]);

  return (
    <div className={styles.root}>
      <div className={styles.progressText}>
        <Trans>Completed</Trans> {completed} <Trans>of</Trans> {total} <Trans>pairs</Trans>
      </div>

      <Box mt={{ xs: 1, sm: 2.5 }}>
        {utilization.smart && (
          <PgProgressBar variant="determinate" color={'success'} value={Math.ceil((completed / total) * 100)} />
        )}

        {!utilization.smart && <RrmProgressBar utilization={utilization} />}
      </Box>
    </div>
  );
};
