import React from 'react';
import { Box, Checkbox, FormControlLabel, BoxProps } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FieldTitle, HelperText } from '@priz/shared/src/components/form-elements';
import { ValidatorControllerProps, ReactHookFormElement, DefaultInput } from '@priz/shared/src/models/form';

type ReactHookFormCheckboxProps = ReactHookFormElement &
  DefaultInput &
  ValidatorControllerProps & {
    label: string | JSX.Element;
    color?: 'primary' | 'secondary';
    checkboxContainerProps?: BoxProps;
  };

export const ReactHookFormCheckbox: React.FC<ReactHookFormCheckboxProps> = ({
  name,
  fieldTitle,
  fieldTitleWrapperProps,
  label,
  control,
  helperText,
  wrapperProps,
  rules,
  disabled,
  color = 'primary',
  checkboxContainerProps,
}) => {
  return (
    <Box mb={3} {...wrapperProps}>
      <FieldTitle text={fieldTitle} {...fieldTitleWrapperProps} />

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <Box {...checkboxContainerProps}>
                <FormControlLabel
                  disabled={disabled}
                  label={label}
                  control={<Checkbox color={color} checked={field.value} {...field} />}
                />
              </Box>

              <HelperText error={error?.message} text={helperText} />
            </>
          );
        }}
      />
    </Box>
  );
};
