import { createStyles, makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ['@keyframes hideOverlay']: {
      '0%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      },
    },

    root: {
      position: 'relative',

      ['&::before']: {
        content: '""',
        position: 'absolute',
        top: -10,
        left: -10,
        right: 'auto',
        bottom: 'auto',
        margin: 'auto',
        width: 'calc(100% + 20px)',
        height: 'calc(100% + 20px)',
        zIndex: 5,
        background: pgColorScheme.workbenchBackground,
        opacity: '0',
        pointerEvents: 'none',
        animation: '$hideOverlay .3s ease-in-out .1s both',
      },
    },

    card: {
      overflow: 'visible',
    },

    descriptionWrap: {
      minHeight: 36,
    },

    description: {
      wordBreak: 'break-word',
    },

    blockingRank: {
      ['&._low']: {
        fontWeight: 300,
        color: pgColorScheme.textGray,
      },

      ['&._med']: {},

      ['&._high']: {
        color: pgColorScheme.red,
      },
    },

    letterWrap: {
      width: '100%',
      height: 42,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },

    letter: {
      lineHeight: 1,
      textAlign: 'center',
      background: pgColorScheme.backgroundMediumGray,
      borderRadius: 50,
      padding: '0 4px',
      height: 36,
      minWidth: 36,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      ['&._small']: {
        height: 26,
        minWidth: 26,
      },
    },

    iconButton: {
      ['& svg']: {
        fill: pgColorScheme.blue,
      },
    },

    addNewPerceptionButton: {
      backgroundColor: pgColorScheme.blue,

      ['& svg']: {
        fill: pgColorScheme.white,
      },

      ['&.MuiIconButton-root:hover']: {
        backgroundColor: pgColorScheme.orange,
      },
    },

    [theme.breakpoints.down('lg')]: {
      letter: {
        height: 30,
        minWidth: 30,
      },
    },
  }),
);
