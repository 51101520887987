import { EdgeProps } from '../../vis-network';

export enum SfmEdgeType {
  USEFUL = 'USEFUL',
  HARMFUL = 'HARMFUL',
  EXCESSIVE = 'EXCESSIVE',
  INSUFFICIENT = 'INSUFFICIENT',
}

export interface SfmEdgeProps extends EdgeProps {
  id: string;
  type: SfmEdgeType;
}

export type SfmEdges = SfmEdgeProps[];
