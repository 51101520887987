import { v1 as uuidv1 } from 'uuid';
import { SfmNodes, SfmNodeType } from './nodes';
import { SfmEdges } from './edges';

const defaultTranslateFunction = (text: string): string => text;

export const generateSfmPreset = (
  translateFunction = defaultTranslateFunction,
): { nodes: SfmNodes; edges: SfmEdges } => {
  return {
    nodes: [
      {
        id: uuidv1(),
        label: translateFunction('Product'),
        type: SfmNodeType.PRODUCT,
        x: 300,
        y: 0,
      },
      {
        id: uuidv1(),
        label: translateFunction('Supersystem'),
        type: SfmNodeType.SUPER_SYSTEM,
        x: 0,
        y: -100,
      },
      {
        id: uuidv1(),
        label: translateFunction('Supersystem'),
        type: SfmNodeType.SUPER_SYSTEM,
        x: 0,
        y: 100,
      },
      {
        id: uuidv1(),
        label: translateFunction('Component'),
        type: SfmNodeType.COMPONENT,
        x: -300,
        y: 0,
      },
    ],
    edges: [],
  };
};

export const SfmPreset: { nodes: SfmNodes; edges: SfmEdges } = generateSfmPreset();
