import { drawWarning, NodeWarnings } from './canvas';
import { EbsIdeaCategory, EbsIdeaNodeProps } from '@priz/shared/src/models/tools/ebs';
import { Network } from '@priz/shared/src/lib/vis/declarations';

const ebsIdeasWarnings: NodeWarnings = {};

export const generateWarningsRenderFunction = (
  nodeId: number,
  warningText: string[],
): ((ctx: CanvasRenderingContext2D, left: number, top: number, width: number, height: number) => void) => {
  return (ctx: CanvasRenderingContext2D, left: number, top: number, width: number, height: number) => {
    const path = drawWarning(ctx, left, top, width, height, nodeId, ebsIdeasWarnings, warningText);

    if (!ebsIdeasWarnings[nodeId]) {
      ebsIdeasWarnings[nodeId] = {
        id: nodeId,
        path: path,
      };
    } else {
      ebsIdeasWarnings[nodeId].path = path;
    }
  };
};

export const setWarningsRender = (nodes: EbsIdeaNodeProps[], warningText: string[], type?: EbsIdeaCategory) => {
  return nodes.map(item => {
    const typeIsMatch = type ? item.type === type : true;

    if (typeIsMatch) {
      item.extraRenderFunction = generateWarningsRenderFunction(item.id, warningText);
    }

    return item;
  });
};

export const removeWarningsRender = (nodes: EbsIdeaNodeProps[], id?: number | string): EbsIdeaNodeProps[] => {
  Object.keys(ebsIdeasWarnings).forEach(key => {
    delete ebsIdeasWarnings[key];
  });

  return nodes.map(item => {
    if (typeof id === 'undefined' || id === item.id) delete item.extraRenderFunction;
    return item;
  });
};

export const toggleWarningsShowState = (
  network: Network,
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
): void => {
  Object.values(ebsIdeasWarnings).forEach(item => {
    if (ebsIdeasWarnings[item.id]) {
      const inPath = ctx.isPointInPath(item.path, x, y);
      const isShown = !!ebsIdeasWarnings[item.id].show;

      if ((isShown && !inPath) || (!isShown && inPath)) {
        ebsIdeasWarnings[item.id].show = inPath;
        network.redraw();
      }
    }
  });
};
