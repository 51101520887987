import { handleActions } from 'redux-actions';
import { toSuccess } from '../../../shared/store/action-creator';
import { PlaygroundActionType } from '../actions';
import { DefaultPlaygroundState, PlaygroundState } from '../model';

export const playgroundReducers = handleActions(
  {
    [toSuccess(PlaygroundActionType.EnableAuthenticationRequirement)]: (state: PlaygroundState): PlaygroundState => ({
      ...state,
      isAuthenticationRequired: true,
    }),

    [toSuccess(PlaygroundActionType.DisableAuthenticationRequirement)]: (state: PlaygroundState): PlaygroundState => ({
      ...state,
      isAuthenticationRequired: false,
    }),
  },

  DefaultPlaygroundState,
);
