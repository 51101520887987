import React, { DragEvent as ReactDragEvent, useEffect, useRef, useState } from 'react';
import { FileDrop } from 'react-file-drop';
import { useStyles } from './styles';
import {
  ReactHookFormResetField,
  ReactHookFormSetValue,
  ValidatorControllerProps,
  ReactHookFormElement,
  DefaultInput,
} from '@priz/shared/src/models/form';
import { Controller, UseFormGetValues } from 'react-hook-form';
import { FieldTitle, HelperText } from '@priz/shared/src/components/form-elements';
import { UseFormClearErrors, UseFormSetError } from 'react-hook-form/dist/types/form';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from '@mui/material';
import { pgColorScheme } from '@priz/shared/src/theme';
import { useWatch } from 'react-hook-form';
import { bytesToMb } from '@priz/shared/src/utils/common';
import mime from 'mime';

import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/pg-edit.svg';
import { ReactComponent as CrossIcon } from '../../../../assets/icons/cross-framed.svg';

interface FileDropWrapperProps {
  onDrop: (files: FileList | null, event: ReactDragEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  error?: boolean;
}

const FileDropWrapper: React.FC<FileDropWrapperProps> = ({ children, onDrop, disabled, error }) => {
  const styles = useStyles();

  return disabled ? (
    <div className={styles.fileDrop}>
      <div className={'file-drop-target'}>{children}</div>
    </div>
  ) : (
    <FileDrop onDrop={onDrop} className={`${styles.fileDrop}${error ? ' _error' : ''}`}>
      {children}
    </FileDrop>
  );
};

export type ReactHookFormImageDropProps = ReactHookFormElement &
  DefaultInput &
  ValidatorControllerProps & {
    setValue: ReactHookFormSetValue;
    setError: UseFormSetError<any>;
    clearErrors: UseFormClearErrors<any>;
    getValues: UseFormGetValues<any>;
    resetField: ReactHookFormResetField;
    onChange?: (file: File) => void;
    maxFileSizeInBytes?: number;
  };

const acceptableFiles = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  webp: 'image/webp',
};

export const ReactHookFormImageDrop: React.FC<ReactHookFormImageDropProps> = ({
  name,
  control,
  rules,
  helperText,
  disabled,
  setValue,
  setError,
  clearErrors,
  onChange,
  fieldTitle,
  fieldTitleWrapperProps,
  getValues,
  resetField,
  wrapperProps,
  maxFileSizeInBytes,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [defaultValue, setDefaultValue] = useState(getValues(name));
  const [edit, setEdit] = useState(false);
  const fileInputRef = useRef(null);

  const imageDropValue = useWatch({
    control,
    name,
  });

  useEffect(() => {
    if (typeof imageDropValue === 'string' && defaultValue !== imageDropValue) {
      setImagePreview(imageDropValue);
      setDefaultValue(imageDropValue);
      cancelEditHandler();
    }
  }, [imageDropValue, defaultValue]);

  const fileSelectHandler = (files: FileList | null) => {
    if (disabled) return null;

    const file = files?.length ? files[0] : null;

    if (file) {
      const extension = mime.getExtension(file.type);

      if (maxFileSizeInBytes && file.size > maxFileSizeInBytes) {
        const maxFileSizeMb = bytesToMb(maxFileSizeInBytes);
        const uploadedFileSizeMb = bytesToMb(file.size);

        setError(name, {
          type: 'custom',
          message: `${t('File is too big')}: ${uploadedFileSizeMb}MB. ${t('Max file size')}: ${maxFileSizeMb}MB.`,
        });

        return;
      }

      if (Object.keys(acceptableFiles).includes(extension)) {
        clearErrors(name);
        setImagePreview(URL.createObjectURL(file));
        setValue(name, file, { shouldDirty: true });
        if (onChange) onChange(file);
        cancelEditHandler();
      } else {
        setError(name, { type: 'custom', message: 'Wrong file type' });
      }
    } else {
      setError(name, { type: 'custom', message: 'File loading error' });
    }
  };

  const reset = () => {
    cancelEditHandler();
    setImagePreview(defaultValue);
    resetField(name);
  };

  const clear = () => {
    setImagePreview(null);
    setValue(name, null);
  };

  const editHandler = () => {
    setEdit(true);
  };

  const cancelEditHandler = () => {
    setEdit(false);
  };

  return (
    <Box mb={3} {...wrapperProps}>
      <FieldTitle text={fieldTitle} {...fieldTitleWrapperProps} />

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => {
          const preview = !imagePreview && typeof field.value === 'string' ? field.value : imagePreview;
          const changed = preview !== defaultValue;

          return (
            <>
              <FileDropWrapper onDrop={fileSelectHandler} disabled={disabled} error={!!error?.message}>
                <Box
                  className={styles.dropContainer}
                  style={!edit && preview ? { backgroundImage: `url(${preview})` } : undefined}
                  py={3}
                  px={2}
                >
                  {(!preview || edit) && (
                    <div className={styles.overlay}>
                      <Box mb={{ xs: 2, xl: 3 }}>
                        <div className={styles.plusIconContainer}>
                          <PlusIcon fill={pgColorScheme.white} />
                        </div>
                      </Box>

                      <Box mb={{ xs: 2, xl: 3 }}>
                        <Typography component={'span'} variant={'body1'}>
                          <Trans>Drop images here or press</Trans>
                        </Typography>
                      </Box>

                      <Button
                        variant={'pg_rounded'}
                        color={'pg_orange_outlined'}
                        {...{ component: 'label' }}
                        disabled={disabled}
                      >
                        <Trans>CHOOSE IMAGE</Trans>

                        <input
                          onChange={e => {
                            fileSelectHandler(e.target.files);
                          }}
                          value={''}
                          ref={fileInputRef}
                          type="file"
                          accept={Object.values(acceptableFiles).join(', ')}
                          disabled={disabled}
                          hidden
                        />
                      </Button>
                    </div>
                  )}
                </Box>
              </FileDropWrapper>

              <Box mt={0.25}>
                <Grid container spacing={2} justifyContent={'space-between'}>
                  <Grid item>
                    <HelperText text={helperText} error={error?.message} />
                  </Grid>

                  {changed && preview && (
                    <Grid item>
                      <Button
                        size="small"
                        color="secondary"
                        startIcon={<TrashIcon width={14} height={14} fill={pgColorScheme.blue} />}
                        onClick={reset}
                      >
                        <Trans>Reset</Trans>
                      </Button>
                    </Grid>
                  )}

                  {!edit && !changed && preview && (
                    <Grid item>
                      <Grid container>
                        <Grid item>
                          <Button
                            size="small"
                            color="secondary"
                            startIcon={<CrossIcon width={14} height={14} fill={pgColorScheme.blue} />}
                            onClick={clear}
                          >
                            <Trans>Clear</Trans>
                          </Button>
                        </Grid>

                        <Grid item>
                          <Button
                            size="small"
                            color="secondary"
                            startIcon={<EditIcon width={14} height={14} fill={pgColorScheme.blue} />}
                            onClick={editHandler}
                          >
                            <Trans>Edit</Trans>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {edit && !changed && (
                    <Grid item>
                      <Button
                        size="small"
                        color="secondary"
                        startIcon={<CrossIcon width={14} height={14} fill={pgColorScheme.blue} />}
                        onClick={cancelEditHandler}
                      >
                        <Trans>Cancel</Trans>
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </>
          );
        }}
      />
    </Box>
  );
};
