import React from 'react';
import { useStyles } from './styles';

import { ReactComponent as PrizLogo } from '../../assets/images/light-theme-logo';

export const PrizLoadingOverlay: React.FC = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <PrizLogo className={styles.logo} />
    </div>
  );
};
