import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  UimFlawCost,
  UimFlawExpectancy,
  UimGoal,
  UimPriority,
  UimTask,
  UimUtilization,
} from '@priz/shared/src/models/tools/uim';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { DeleteIcon } from '../../react/icons';
import { UimUtilizationActions } from '../store/actions/uim-utilization.actions';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { Trans } from 'react-i18next';
import { PriorityIndicator } from '@priz/shared/src/components/priority-indicator/component';
import { UpdateUimTaskCommand } from '../services/uim-utilization.api';

interface UimTaskItemProps {
  utilization: UimUtilization;
  task: UimTask;
  onDelete?: () => void;
  disabled?: boolean;
}

const priorityMap = {
  [`${UimFlawExpectancy.Unexpected}${UimFlawCost.High}`]: UimPriority.DoFirst,
  [`${UimFlawExpectancy.Expected}${UimFlawCost.High}`]: UimPriority.DoLater,
  [`${UimFlawExpectancy.Unexpected}${UimFlawCost.Low}`]: UimPriority.Delegate,
  [`${UimFlawExpectancy.Expected}${UimFlawCost.Low}`]: UimPriority.Drop,
};

const urgencyMap = {
  [UimFlawExpectancy.Unexpected]: 'High',
  [UimFlawExpectancy.Expected]: 'Low',
};

export const UimTaskItem: React.FC<UimTaskItemProps> = ({ utilization, task, onDelete, disabled }) => {
  const dispatch = useDispatch();

  const [taskId, setTaskId] = useState<number>();
  const [description, setDescription] = useState<string>('');
  const [expectancy, setExpectancy] = useState<UimFlawExpectancy>();
  const [cost, setCost] = useState<UimFlawCost>();
  const [priority, setPriority] = useState<UimPriority>();

  const isSelfGoalUim = utilization.uimGoal === UimGoal.Self;

  useEffect(() => {
    if (taskId !== task.id) {
      setTaskId(task.id);
      setDescription(task.description);
      setExpectancy(task.flawExpectancy);
      setCost(task.flawCost);
    }
  }, [task]);

  const updateTaskDescription = useCallback(
    debounce((description: string) => {
      const command: UpdateUimTaskCommand = {
        description: description,
      };

      dispatch(UimUtilizationActions.updateTask(utilization.id, task.id, command));
    }, 500),
    [],
  );

  const updateTaskFlawExpectancy = useCallback(
    debounce((flawExpectancy: UimFlawExpectancy) => {
      const command: UpdateUimTaskCommand = {
        flawExpectancy: flawExpectancy,
      };

      dispatch(UimUtilizationActions.updateTask(utilization.id, task.id, command));
    }, 500),
    [],
  );

  const updateTaskFlawCost = useCallback(
    debounce((flawCost: UimFlawCost) => {
      const command: UpdateUimTaskCommand = {
        flawCost: flawCost,
      };

      dispatch(UimUtilizationActions.updateTask(utilization.id, task.id, command));
    }, 500),
    [],
  );

  useEffect(() => {
    setPriority(priorityMap[`${expectancy}${cost}`]);
  }, [expectancy, cost]);

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
    updateTaskDescription(event.target.value);
  };

  const handleExpectancyChange = (event: SelectChangeEvent) => {
    setExpectancy(event.target.value as UimFlawExpectancy);
    updateTaskFlawExpectancy(event.target.value as UimFlawExpectancy);
  };

  const handleCostChange = (event: SelectChangeEvent) => {
    setCost(event.target.value as UimFlawCost);
    updateTaskFlawCost(event.target.value as UimFlawCost);
  };

  const handleDeleteTask = () => {
    if (onDelete) {
      onDelete();
    }
  };

  return (
    <>
      <Box mb={{ xs: 2, md: 0 }}>
        <Grid container justifyContent="space-between" alignItems="flex-start" rowSpacing={2} columnSpacing={1}>
          <Grid item xs={12} lg={true}>
            <Box mb={{ xs: 0, lg: 2 }}>
              <TextField
                id="standard-multiline-flexible"
                label={<Trans>Task</Trans>}
                multiline
                fullWidth
                value={description}
                variant={'outlined'}
                onChange={handleDescriptionChange}
                disabled={disabled || !isSelfGoalUim}
              />
            </Box>
          </Grid>

          <Grid item container xs={12} md={true} lg={'auto'} spacing={1}>
            <Grid item xs={6} lg={'auto'}>
              <Box width={{ xs: '100%', lg: 180 }}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="expectancy-select">
                    <Trans>Flaw expectancy</Trans>
                  </InputLabel>
                  <Select
                    value={expectancy || ''}
                    onChange={handleExpectancyChange}
                    label={<Trans>Flaw expectancy</Trans>}
                    labelId={'expectancy-select'}
                    disabled={disabled}
                  >
                    <MenuItem aria-label="None" value="">
                      <em />
                    </MenuItem>
                    <MenuItem value={UimFlawExpectancy.Expected}>
                      <Trans>{UimFlawExpectancy.Expected}</Trans>
                    </MenuItem>
                    <MenuItem value={UimFlawExpectancy.Unexpected}>
                      <Trans>{UimFlawExpectancy.Unexpected}</Trans>
                    </MenuItem>
                  </Select>

                  {urgencyMap[UimFlawExpectancy[expectancy]] && (
                    <FormHelperText>
                      <Trans>Urgency</Trans>: <Trans>{urgencyMap[UimFlawExpectancy[expectancy]]}</Trans>
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={6} lg={'auto'}>
              <Box width={{ xs: '100%', lg: 180 }}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="cost-select">
                    <Trans>Flaw Cost</Trans>
                  </InputLabel>
                  <Select
                    value={cost || ''}
                    onChange={handleCostChange}
                    label={<Trans>Flaw cost</Trans>}
                    labelId={'cost-select'}
                    disabled={disabled}
                  >
                    <MenuItem aria-label="None" value="">
                      <em />
                    </MenuItem>
                    <MenuItem value={UimFlawCost.Low}>
                      <Trans>{UimFlawCost.Low}</Trans>
                    </MenuItem>
                    <MenuItem value={UimFlawCost.High}>
                      <Trans>{UimFlawCost.High}</Trans>
                    </MenuItem>
                  </Select>

                  {UimFlawCost[cost] && (
                    <FormHelperText>
                      <Trans>Importance</Trans>: <Trans>{UimFlawCost[cost]}</Trans>
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          <Grid item>
            <Box py={{ xs: 0, md: 1.5 }} width={100} textAlign={'center'}>
              <PriorityIndicator priority={priority} />
            </Box>
          </Grid>

          <Grid item xs={'auto'}>
            <Box py={{ xs: 0, md: 1.5 }}>
              <Button
                variant={'pg_button_link'}
                onClick={handleDeleteTask}
                startIcon={<DeleteIcon />}
                disabled={disabled || !isSelfGoalUim}
              >
                <Trans>Delete task</Trans>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
    </>
  );
};
