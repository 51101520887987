import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Trans } from 'react-i18next';
import { validateOptions } from '@priz/shared/src/utils/form';
import { ReactHookFormText, FormResetButton, FormSubmitButton } from '../../react/form-elements';
import { useStyles as useFormStyles } from '../../react/form-elements/styles';
import { useForm } from 'react-hook-form';
import { ApaActionActions } from '../store/actions';

interface ApaActionRecorderProps {
  open: boolean;
  utilizationId: number;
  onClose: () => void;
  projectId?: number;
}

export const ApaActionRecorder: React.FC<ApaActionRecorderProps> = ({ open, utilizationId, projectId, onClose }) => {
  const dispatch = useDispatch();
  const formStyles = useFormStyles();

  const { handleSubmit, control, reset } = useForm({
    shouldFocusError: false,
    defaultValues: {
      text: '',
    },
  });

  useEffect(() => {
    if (open) reset();
  }, [open]);

  const save = data => {
    dispatch(ApaActionActions.create(utilizationId, data.text, projectId));
    handleClose();
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
      <form onSubmit={handleSubmit(save)} className={formStyles.form}>
        <DialogTitle>
          <Trans>Create new customer action</Trans>
        </DialogTitle>

        <DialogContent>
          <ReactHookFormText
            multiline
            name={'text'}
            control={control}
            rules={{
              validate: {
                ...validateOptions.hasText('Field is required'),
              },
            }}
            wrapperProps={{ mb: 0 }}
          />
        </DialogContent>

        <DialogActions style={{ textAlign: 'right' }}>
          <FormResetButton onClick={handleClose} />
          <FormSubmitButton text={'Create action'} />
        </DialogActions>
      </form>
    </Dialog>
  );
};
