import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { UimUtilizationActions } from '../store/actions/uim-utilization.actions';
import { Trans } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { TaskSelectors } from '../../tasks/store/selectors/task.selectors';
import { FeatureLimit } from '@priz/shared/src/models/workspace';
import { ToolType } from '@priz/shared/src/models/tools';
import { TaskStatus } from '@priz/shared/src/models/task';
import { PlanUpgradeButton } from '../../billing/plan-upgrade-button/component';

interface PrioritizeProjectTasksButtonProps extends LoadingButtonProps {
  projectId: number;
}

export const PrioritizeProjectTasksButton: React.FC<PrioritizeProjectTasksButtonProps> = ({
  projectId,
  children,
  ...rest
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tasks = useSelector(TaskSelectors.getAllByProjectId(projectId));
  const areTasksLoaded = useSelector(TaskSelectors.isLoaded(projectId));

  const featureSet = useSelector(WorkspaceSelectors.getApplicableFeatureSet(projectId));
  const isFeatureSetLoaded = useSelector(WorkspaceSelectors.isLoaded);

  const maxUimTasksPlanLimit = featureSet?.[FeatureLimit.Tools]?.[ToolType.UIM]?.maxItems;
  const notCompletedProjectTasksCount = tasks?.filter(task => task.status !== TaskStatus.Done)?.length;

  const [isPlanLimitDialogOpen, setIsPlanLimitDialogOpen] = useState(false);

  const openPlanLimitDialog = () => {
    setIsPlanLimitDialogOpen(true);
  };

  const closePlanLimitDialog = () => {
    setIsPlanLimitDialogOpen(false);
  };

  const prioritizeTasks = () => {
    dispatch(UimUtilizationActions.createForProjectTasks(projectId, navigate));
  };

  const prioritizeButtonClickHandler = () => {
    if (typeof maxUimTasksPlanLimit === 'undefined') return null;

    if (maxUimTasksPlanLimit === null || notCompletedProjectTasksCount <= maxUimTasksPlanLimit) {
      prioritizeTasks();
    } else {
      openPlanLimitDialog();
    }
  };

  if (!isFeatureSetLoaded || !areTasksLoaded) return null;

  return (
    <>
      <LoadingButton
        variant={'pg_rounded'}
        color={'pg_orange_outlined'}
        onClick={prioritizeButtonClickHandler}
        {...rest}
      >
        {children || <Trans>Prioritize tasks</Trans>}
      </LoadingButton>

      <Dialog open={isPlanLimitDialogOpen} onClose={closePlanLimitDialog} maxWidth={'sm'} fullWidth>
        <DialogTitle>
          <Trans>Project tasks prioritization</Trans>
        </DialogTitle>

        <DialogContent sx={{ textAlign: 'center' }}>
          <Trans>Current plan only allows</Trans>
          {' ' + maxUimTasksPlanLimit + ' '}
          <Trans>tasks in Urgency - Importance Matrix tool</Trans>.{' '}
          <Trans>Upgrade workspace plan to enable more tasks, or continue with limit.</Trans>
        </DialogContent>

        <DialogActions sx={{ textAlign: 'right' }}>
          <Button variant={'pg_rounded'} color={'pg_orange_outlined'} size={'pg_small'} onClick={closePlanLimitDialog}>
            <Trans>Cancel</Trans>
          </Button>

          <PlanUpgradeButton onClick={closePlanLimitDialog} />

          <Button variant={'pg_rounded'} color={'pg_orange_solid'} size={'pg_small'} onClick={prioritizeTasks}>
            <Trans>Continue</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
