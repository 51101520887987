import { CrumbProps, CrumbType } from '../model';

export const generateCrumbsPropsMap = (projectId: number): { [key in CrumbType]: CrumbProps } => {
  return {
    [CrumbType.Home]: {
      type: CrumbType.Home,
      text: 'Home',
      to: '/',
    },
    [CrumbType.ProjectList]: {
      type: CrumbType.ProjectList,
      text: 'Projects & Tools',
      to: '/projects-and-tools',
    },
    [CrumbType.Project]: {
      type: CrumbType.Project,
      text: 'Project',
      to: `/project/${projectId}`,
    },
    [CrumbType.Overview]: {
      type: CrumbType.Overview,
      text: 'Overview',
    },
    [CrumbType.Publish]: {
      type: CrumbType.Publish,
      text: 'Publish',
    },
    [CrumbType.Tasks]: {
      type: CrumbType.Tasks,
      text: 'Tasks',
    },
    [CrumbType.Meetings]: {
      type: CrumbType.Meetings,
      text: 'Meetings',
    },
    [CrumbType.ProblemStatement]: {
      type: CrumbType.ProblemStatement,
      text: 'Problem statement',
    },
    [CrumbType.ToolsList]: {
      type: CrumbType.ToolsList,
      text: 'Tools list',
      to: `/project/${projectId}/tools/list`,
    },
    [CrumbType.Tool]: {
      type: CrumbType.Tool,
      text: null,
    },
    [CrumbType.Ideas]: {
      type: CrumbType.Ideas,
      text: 'Ideas',
    },
    [CrumbType.Solution]: {
      type: CrumbType.Solution,
      text: 'Solution',
    },
    [CrumbType.Report]: {
      type: CrumbType.Report,
      text: 'Report',
    },
  };
};
