import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { TitleContainer } from '../../react/modules/title-container/component';
import { TabPanel } from '../../react/elements/tab-panel/component';
import { AppNavbar } from '../../navigation/app-navbar/component';
import { ParametersSettings } from '../parameter/component';
import { DomainsSettings } from '../domain/component';
import { PrinciplesSettings } from '../principle/component';
import { PageContainer } from '../../content-containers/page-container/component';

export const FortyPrinciplesSettings: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (_event, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <PageContainer>
      <AppNavbar />

      <>
        <TitleContainer>
          <PageTitleWithDocLink title={'40 Principles Settings'} />

          <Box display="flex" justifyContent="flex-end">
            <Tabs orientation="horizontal" variant="standard" value={activeTab} onChange={handleChangeTab}>
              <Tab label="parameters" />
              <Tab label="domains" />
              <Tab label="principles" />
            </Tabs>
          </Box>
        </TitleContainer>

        <TabPanel value={activeTab} index={0}>
          <ParametersSettings />
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <DomainsSettings />
        </TabPanel>

        <TabPanel value={activeTab} index={2}>
          <PrinciplesSettings />
        </TabPanel>
      </>
    </PageContainer>
  );
};
