import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  iconContainer: {
    minWidth: 40,
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  root: {
    'whiteSpace': 'nowrap',
    'overflow': 'hidden',
    'cursor': 'pointer',
    'color': 'rgba(255,255,255,0.4)',
    'width': '100%',
    'flex': '0 0 auto',

    '& svg': {
      width: 22,
      height: 22,
      fill: pgColorScheme.white,
      opacity: 0.4,
    },

    '&:hover, &._active': {
      'color': pgColorScheme.white,

      '& svg': {
        fill: pgColorScheme.blue,
        opacity: 1,
      },
    },

    '&._orange': {
      'color': pgColorScheme.orange,

      '& svg': {
        fill: pgColorScheme.orange,
        opacity: 1,
      },

      '&:hover, &._active': {
        'color': pgColorScheme.darkOrange,

        '& svg': {
          fill: pgColorScheme.darkOrange,
        },
      },
    },

    '&._green': {
      'color': pgColorScheme.green,

      '& svg': {
        fill: pgColorScheme.green,
        opacity: 1,
      },

      '&:hover, &._active': {
        'color': pgColorScheme.green2,

        '& svg': {
          fill: pgColorScheme.green2,
        },
      },
    },

    '&._orange-hover': {
      '&:hover, &._active': {
        'color': pgColorScheme.darkOrange,

        '& svg': {
          fill: pgColorScheme.darkOrange,
        },
      },
    },

    '&._blue': {
      '& svg': {
        fill: pgColorScheme.blue,
        opacity: 1,
      },
    },
  },
});
