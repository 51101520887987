import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.08)',
    borderRadius: 50,
    maxWidth: '100%',
    padding: 4,
  },

  control: {
    padding: 2,
  },
});
