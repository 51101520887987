import { getMosaicSortedNodesParameters, splitIdeasOnCategories } from '@priz/shared/src/utils/ebs';
import { EbsIdeaCategory, EbsIdeaNodeProps } from '@priz/shared/src/models/tools/ebs';
import { removeWarningsRender, setWarningsRender } from './warnings';
import { DataSet } from 'vis-data';
import { Network } from '@priz/shared/src/lib/vis/declarations';

export const toggleEbsIdeasSorting = (
  network: Network,
  nodesDataSet: DataSet<EbsIdeaNodeProps>,
  warningText: string[],
  enabled: boolean,
): void => {
  if (enabled) {
    const categories = splitIdeasOnCategories(nodesDataSet.get());
    const nodesUpdate = [];

    nodesUpdate.push(
      ...getMosaicSortedNodesParameters({
        network,
        nodes: [
          ...categories[EbsIdeaCategory.IE_VE],
          ...setWarningsRender(categories[EbsIdeaCategory.NOT_DEFINED], warningText),
        ],
        yMlt: -1,
        extraColumns: 2,
      }),
    );

    nodesUpdate.push(
      ...getMosaicSortedNodesParameters({
        network,
        nodes: categories[EbsIdeaCategory.IE_VN],
        xMlt: -1,
        yMlt: -1,
        extraColumns: 2,
      }),
    );

    nodesUpdate.push(
      ...getMosaicSortedNodesParameters({
        network,
        nodes: categories[EbsIdeaCategory.IN_VE],
        extraColumns: 2,
      }),
    );

    nodesUpdate.push(
      ...getMosaicSortedNodesParameters({
        network,
        nodes: categories[EbsIdeaCategory.IN_VN],
        xMlt: -1,
        extraColumns: 2,
      }),
    );

    nodesDataSet.updateOnly(nodesUpdate);
    network.fit();
  } else {
    nodesDataSet.updateOnly(removeWarningsRender(nodesDataSet.get()), 'ignore');
  }
};
