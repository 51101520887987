import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  notes: {
    maxWidth: 400,
    maxHeight: 200,
    overflow: 'auto',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
  },
});
