import { RouteObject } from 'react-router-dom';
import { RouteRenderer } from './route-renderer/component';
import { ToolCreatingPage } from '../playground/tool-creating-page/component';
import { PlaygroundRoot } from '../playground/playground-root/component';
import { PlaygroundToolLoader } from '../playground/playground-tool-loader/component';

export const playgroundRoutes: RouteObject[] = [
  {
    path: 'playground',
    element: <RouteRenderer Component={PlaygroundRoot} mfaFree={true} />,
    children: [
      {
        path: 'create/:type',
        element: <RouteRenderer Component={ToolCreatingPage} mfaFree={true} />,
      },
      {
        path: [':publicId'].join('/'),
        element: <PlaygroundToolLoader />,
      },
      {
        path: [':publicId', ':sub'].join('/'),
        element: <PlaygroundToolLoader />,
      },
      {
        path: [':publicId', ':sub', ':subId'].join('/'),
        element: <PlaygroundToolLoader />,
      },
    ],
  },
];
