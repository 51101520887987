import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { ReactHookFormSelect } from '../../react/form-elements';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AttendeeRoleFilterType,
  MeetingsPeriodFilterType,
  MeetingStatusFilterType,
} from '@priz/shared/src/models/meetings';

export interface MeetingsFilterSelectsProps {
  onPeriodChange?: (value: MeetingsPeriodFilterType) => void;
  onMeetingStatusChange?: (value: MeetingStatusFilterType) => void;
  onAttendeeRoleChange?: (value: AttendeeRoleFilterType) => void;
  periodDefaultValue?: MeetingsPeriodFilterType;
  meetingStatusDefaultValue?: MeetingStatusFilterType;
  attendeeRoleDefaultValue?: AttendeeRoleFilterType;
}

export const MeetingsFilterSelects: React.FC<MeetingsFilterSelectsProps> = ({
  onPeriodChange,
  onMeetingStatusChange,
  onAttendeeRoleChange,
  periodDefaultValue,
  meetingStatusDefaultValue,
  attendeeRoleDefaultValue,
}) => {
  const { t } = useTranslation();

  const { control, getValues, resetField, watch } = useForm({
    defaultValues: {
      period: MeetingsPeriodFilterType.All,
      meetingStatus: MeetingStatusFilterType.All,
      attendeeRole: AttendeeRoleFilterType.All,
    },
  });

  const { period, meetingStatus, attendeeRole } = watch();

  useEffect(() => {
    if (onPeriodChange) onPeriodChange(period);
  }, [period]);

  useEffect(() => {
    if (onMeetingStatusChange) onMeetingStatusChange(meetingStatus);
  }, [meetingStatus]);

  useEffect(() => {
    if (onAttendeeRoleChange) onAttendeeRoleChange(attendeeRole);
  }, [attendeeRole]);

  useEffect(() => {
    const currentValue = getValues().period;

    if (currentValue !== periodDefaultValue) {
      resetField('period', { defaultValue: periodDefaultValue });
    }
  }, [periodDefaultValue]);

  useEffect(() => {
    const currentValue = getValues().meetingStatus;

    if (currentValue !== meetingStatusDefaultValue) {
      resetField('meetingStatus', { defaultValue: meetingStatusDefaultValue });
    }
  }, [meetingStatusDefaultValue]);

  useEffect(() => {
    const currentValue = getValues().attendeeRole;

    if (currentValue !== attendeeRoleDefaultValue) {
      resetField('attendeeRole', { defaultValue: attendeeRoleDefaultValue });
    }
  }, [attendeeRoleDefaultValue]);

  return (
    <Grid container alignItems={'flex-end'} justifyContent={'flex-end'} direction={'row'} spacing={2}>
      {onPeriodChange && (
        <Grid item xs={'auto'}>
          <ReactHookFormSelect
            variant={'standard'}
            name={'period'}
            control={control}
            options={[
              { value: MeetingsPeriodFilterType.All, text: t('For all time') },
              { value: MeetingsPeriodFilterType.Day, text: t('Today') },
              { value: MeetingsPeriodFilterType.Week, text: t('This Week') },
              { value: MeetingsPeriodFilterType.Month, text: t('This Month') },
            ]}
            wrapperProps={{
              width: { xs: '100%', sm: 200 },
              mb: 0,
            }}
          />
        </Grid>
      )}

      {onMeetingStatusChange && (
        <Grid item xs={'auto'}>
          <ReactHookFormSelect
            variant={'standard'}
            name={'meetingStatus'}
            control={control}
            options={[
              { value: MeetingStatusFilterType.All, text: t('All meeting statuses') },
              { value: MeetingStatusFilterType.Draft, text: t('Draft') },
              { value: MeetingStatusFilterType.Released, text: t('Published') },
              { value: MeetingStatusFilterType.Active, text: t('Active') },
              { value: MeetingStatusFilterType.Finished, text: t('Finished') },
            ]}
            wrapperProps={{
              width: { xs: '100%', sm: 200 },
              mb: 0,
            }}
          />
        </Grid>
      )}

      {onAttendeeRoleChange && (
        <Grid item xs={'auto'}>
          <ReactHookFormSelect
            variant={'standard'}
            name={'attendeeRole'}
            control={control}
            options={[
              { value: AttendeeRoleFilterType.All, text: t('All attendee statuses') },
              { value: AttendeeRoleFilterType.AwaitingResponse, text: t('Awaiting response') },
              { value: AttendeeRoleFilterType.Lead, text: t('Meeting lead') },
              { value: AttendeeRoleFilterType.Contributor, text: t('Contributor') },
              { value: AttendeeRoleFilterType.Observer, text: t('Observer') },
              { value: AttendeeRoleFilterType.Decline, text: t('Declined') },
            ]}
            wrapperProps={{
              width: { xs: '100%', sm: 200 },
              mb: 0,
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};
