import { IToolUtilization, ToolUtilization } from '../index';

export interface IApaUtilization extends IToolUtilization {
  customer?: string;
  customerDescription?: string;
}

export class ApaUtilization extends ToolUtilization implements IApaUtilization {
  public customer?: string;
  public customerDescription?: string;

  constructor(json: IApaUtilization) {
    super(json);

    this.customer = json.customer;
    this.customerDescription = json.customerDescription;
  }
}
