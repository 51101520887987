import { ApaAction, IApaAction } from '@priz/shared/src/models/tools/apa';
import axios, { AxiosResponse } from 'axios';

const getAll = (utilizationId: number): Promise<IApaAction[]> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/apa/${utilizationId}/actions`)
    .then((response: AxiosResponse<IApaAction[]>) => response.data);

const get = (utilizationId: number, actionId: number): Promise<IApaAction> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/apa/${utilizationId}/action/${actionId}`)
    .then((response: AxiosResponse<IApaAction>) => response.data);

const update = (utilizationId: number, apaAction: Partial<ApaAction>): Promise<IApaAction> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/apa/${utilizationId}/action/${apaAction.id}`, {
      actionPurpose: apaAction.actionPurpose,
      customerNeeds: apaAction.customerNeeds,
      preventingAction: apaAction.preventingAction,
    })
    .then((response: AxiosResponse<IApaAction>) => response.data);

const complete = (utilizationId: number, actionId: number): Promise<IApaAction> =>
  axios
    .post(`/v1/api/<ws-id>/team/<t-id>/apa/${utilizationId}/action/${actionId}/complete`, null)
    .then((response: AxiosResponse<IApaAction>) => response.data);

const create = (utilizationId: number, customerAction: string): Promise<IApaAction> =>
  axios
    .post(`/v1/api/<ws-id>/team/<t-id>/apa/${utilizationId}/action`, {
      customerAction,
    })
    .then((response: AxiosResponse<IApaAction>) => response.data);

const doDelete = (utilizationId: number, actionId: number): Promise<any> =>
  axios.delete(`/v1/api/<ws-id>/team/<t-id>/apa/${utilizationId}/action/${actionId}`);

export const ApaActionApi = {
  getAll,
  get,
  update,
  complete,
  create,
  delete: doDelete,
};
