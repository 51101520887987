import { environment } from '../../../environments/environment';
import { UserProperties } from '../model/user-properties';
import { UserContextService } from '../../user';
import { GoogleAnalyticsService } from '../providers/ga/ga.service';
import { MixpanelService } from '../providers/mixpanel/mixpanel.service';
import { HubspotService } from '../providers/hubspot/hubspot.service';
import { IntercomService } from '../../intercom/intercom.service';
import { AxiosService } from '../../axios';

const init = () => {
  MixpanelService.init();
};

const setUserIdentity = (identity: string): void => {
  MixpanelService.setUserIdentity(identity);
};

const resetUserIdentity = (): void => {
  MixpanelService.resetUserIdentity();
};

const setUserProperties = (properties: UserProperties): void => {
  MixpanelService.setUserProperties(properties);
  HubspotService.setUserProperties(properties);
};

type EventMetadata = { [key: string]: string | number | boolean | Date | null | undefined };

const track = (event: string, metadata: EventMetadata = {}): Promise<any> => {
  const selectedWorkspaceId = UserContextService.getSelectedWorkspaceId();
  const isAnonymousAccessEnabled = AxiosService.isAnonymousAccessEnabled();

  const metadataCopy = { ...metadata };

  if (typeof selectedWorkspaceId !== 'undefined') {
    metadataCopy.workspace_id = selectedWorkspaceId;
  }

  if (isAnonymousAccessEnabled) {
    metadataCopy.anonymous = true;
  }

  return new Promise((resolve, reject) => {
    Promise.all([
      MixpanelService.track(event, metadataCopy),
      IntercomService.track(event, metadataCopy),
      ...(environment.production ? [GoogleAnalyticsService.track(event)] : []),
    ]).then(
      values => {
        resolve({
          event,
          meta: metadataCopy,
          mixpanelResponse: values[0],
          googleAnalyticsResponse: values[1],
        });
      },
      () => reject(),
    );
  });
};

const trackWithCallback = (
  event: string,
  metadata: EventMetadata,
  callback: () => void,
  forceCallbackCallDelay?: number,
) => {
  let callbackTimeout = forceCallbackCallDelay ? setTimeout(callback, forceCallbackCallDelay) : undefined;

  track(event, metadata).then(() => {
    clearTimeout(callbackTimeout);
    callbackTimeout = undefined;
    callback();
  });
};

const trackPageView = (page: string): void => {
  if (environment.production) {
    GoogleAnalyticsService.trackPageView(page);
    HubspotService.trackPageView(page);
  }
};

export const AnalyticsService = {
  init,
  setUserIdentity,
  resetUserIdentity,
  setUserProperties,
  track,
  trackWithCallback,
  trackPageView,
};
