import { FluxStandardAction } from 'flux-standard-action';
import { IToolUtilization } from '@priz/shared/src/models/tools';
import { createPgAction } from '../../../shared/store/action-creator';
import { AppState } from '../../../store/app.state';
import { ToolsApi, ToolUtilizationCommand } from '../../services';
import { ToolUtilizationSelector } from '../selectors';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { NavigateFunction } from 'react-router-dom';
import { ToolsUtils } from '../../../tools/utils';
import { AxiosError } from 'axios';

export enum ToolUtilizationActionType {
  FetchTools = 'tools/fetch',
  FetchTool = 'tool/fetch',
  CreateTool = 'tool/create',
  UpdateCompleteness = 'tool/update/completeness',
}

export type ToolUtilizationPayload = IToolUtilization[] | IToolUtilization | AxiosError;

export interface ToolUtilizationMeta {
  projectId?: number;
  utilizationId?: number;
  publicId?: string;
  navigate?: NavigateFunction;
  command?: ToolUtilizationCommand;
}

export type ToolUtilizationAction = FluxStandardAction<
  ToolUtilizationActionType,
  ToolUtilizationPayload,
  ToolUtilizationMeta
>;

const createTool = createPgAction(
  ToolUtilizationActionType.CreateTool,
  ToolsApi.createTool,
  (command: ToolUtilizationCommand, projectId?: number, navigate?: NavigateFunction) => ({
    projectId,
    command,
    navigate,
  }),
  (payload: ToolUtilizationPayload, { navigate }) => {
    const { type, project, team } = payload as IToolUtilization;

    AnalyticsService.track(AnalyticsEvent.TOOL_USED, {
      tool_type: type,
      team_id: team.id,
      project_id: project?.id,
    });

    if (navigate) {
      navigate(ToolsUtils.resolvePathByUtilization(payload as IToolUtilization));
    }
  },
);

const updateCompleteStatus = createPgAction(
  ToolUtilizationActionType.UpdateCompleteness,
  ToolsApi.updateCompleteStatus,
  (utilizationId: number) => ({ utilizationId }),
  (payload: ToolUtilizationPayload) => {
    const { type, project, team } = payload as IToolUtilization;

    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      tool_type: type,
      team_id: team.id,
      project_id: project?.id,
    });
  },
);

const loadTools = createPgAction(
  ToolUtilizationActionType.FetchTools,
  ToolsApi.getTools,
  (projectId?: number) => ({ projectId }),
  undefined,
  undefined,
  (state: AppState, projectId?: number) => {
    if (typeof projectId !== 'undefined') {
      return ToolUtilizationSelector.isLoadedByProjectId(projectId)(state);
    } else {
      return ToolUtilizationSelector.isLoadingByTeam()(state);
    }
  },
);

const loadTool = createPgAction(
  ToolUtilizationActionType.FetchTool,
  ToolsApi.getTool,
  (utilizationId: number, projectId?: number) => ({ utilizationId, projectId }),
);

const loadPublicTool = createPgAction(
  ToolUtilizationActionType.FetchTool,
  ToolsApi.getPublicTool,
  (publicId: string) => ({ publicId }),
);

export const ToolUtilizationActions = {
  loadTool,
  loadPublicTool,
  createTool,
  loadTools,
  updateCompleteStatus,
};
