import { createSelector, Selector } from 'reselect';
import { UserContextService } from '@priz/shared/src/services/user';
import { EntityCollectionStatus, EntityMap } from '@priz/shared/src/models/common/entity-collection-state';
import { AppState } from '../../../store/app.state';
import { Workspace, WorkspaceCollection } from '@priz/shared/src/models/workspace';
import { PlanFeatureSet } from '@priz/shared/src/models/workspace';
import { WorkspaceUtils } from '../../services';
import { ProjectSelector } from '../../../project/store/selectors';
import { WorkspaceMembersSelectors } from './workspace-members.selectors';
import { UserContextSelectors } from '../../../security/store/selectors';

const workspaceCollectionSelector = (state: AppState): WorkspaceCollection => state.workspaces;

const getWorkspaceEntityMap: Selector<AppState, EntityMap<Workspace>> = createSelector(
  workspaceCollectionSelector,
  collection => collection.entities,
);

const statusesSelector: Selector<AppState, EntityCollectionStatus> = createSelector(
  workspaceCollectionSelector,
  collection => collection.statuses,
);

const getById = (id: number): Selector<AppState, Workspace> =>
  createSelector(getWorkspaceEntityMap, entityMap => entityMap[id]);

const getSelectedWorkspace: Selector<AppState, Workspace> = createSelector(
  getWorkspaceEntityMap,
  entityMap => UserContextService.getSelectedWorkspaceId() && entityMap[UserContextService.getSelectedWorkspaceId()],
);

const getPersonalWorkspace: Selector<AppState, Workspace> = createSelector(getWorkspaceEntityMap, entities =>
  Object.values(entities).find(ws => WorkspaceUtils.isPersonalOrPro(ws)),
);

const getAll = (excludeSelected = false): Selector<AppState, Workspace[]> =>
  createSelector(getWorkspaceEntityMap, entityMap => {
    if (excludeSelected) {
      const selectedWorkspaceId = UserContextService.getSelectedWorkspaceId();
      return Object.values(entityMap).filter(ws => ws.id !== selectedWorkspaceId);
    } else {
      return Object.values(entityMap);
    }
  });

const getInitialWorkspace = (excludeIds?: number[]): Selector<AppState, Workspace> =>
  createSelector(
    [getAll(), getPersonalWorkspace, UserContextSelectors.getCurrentUser],
    (allWorkspaces, personalWorkspace, user) => {
      const workspaces = excludeIds
        ? allWorkspaces.filter(w => !excludeIds.includes(w.id) && (w.mfa ? user.mfa : true))
        : allWorkspaces;

      return personalWorkspace || workspaces[0];
    },
  );

const isCurrentUserSatisfyWorkspaceMfa: Selector<AppState, boolean> = createSelector(
  [getSelectedWorkspace, UserContextSelectors.getCurrentUser],
  (selectedWorkspace, user) => {
    return selectedWorkspace && user ? (selectedWorkspace.mfa ? user.mfa : true) : false;
  },
);

const isAnyWorkspaceRequireMfa: Selector<AppState, boolean> = createSelector(getAll(), workspaces => {
  return !!workspaces.find(w => w.mfa);
});

const isLoading: Selector<AppState, boolean> = createSelector(statusesSelector, statuses => statuses.loading);
const isLoaded: Selector<AppState, boolean> = createSelector(statusesSelector, statuses => statuses.loaded);

const isCreating: Selector<AppState, boolean> = createSelector(statusesSelector, statuses => statuses.creating);
const isCreated: Selector<AppState, boolean> = createSelector(statusesSelector, statuses => statuses.created);

const isUpdating: Selector<AppState, boolean> = createSelector(statusesSelector, statuses => statuses.updating);
const isUpdated: Selector<AppState, boolean> = createSelector(statusesSelector, statuses => statuses.updated);

const isRemoving: Selector<AppState, boolean> = createSelector(statusesSelector, statuses => statuses.removing);
const isRemoved: Selector<AppState, boolean> = createSelector(statusesSelector, statuses => statuses.removed);

const isFailed: Selector<AppState, boolean> = createSelector(statusesSelector, statuses => statuses.error);

const getApplicableFeatureSet = (projectId?: number): Selector<AppState, PlanFeatureSet> =>
  createSelector([workspaceCollectionSelector, ProjectSelector.isProjectOpen(projectId)], (collection, projectOpen) =>
    projectOpen ? collection.publicProjectFeatureSet : collection.currentFeatureSet,
  );

const isExternalFileViewerEnabled = (id: number): Selector<AppState, boolean> =>
  createSelector(getById(id), workspace => !!workspace?.useExternalFileViewer);

const isAiAssistantEnabled = (id: number): Selector<AppState, boolean> =>
  createSelector(getById(id), workspace => !!workspace?.useAiAssistant);

const isSeatsLimitHit = (): Selector<AppState, boolean> =>
  createSelector([getSelectedWorkspace, WorkspaceMembersSelectors.getAll], (workspace, members) =>
    workspace && members ? WorkspaceUtils.countChargeableMembers(workspace, members) >= workspace.licensedSeats : true,
  );

export const WorkspaceSelectors = {
  getById,
  getInitialWorkspace,
  getPersonalWorkspace,
  getSelectedWorkspace,
  getWorkspaceEntityMap,
  getAll,
  getApplicableFeatureSet,
  isCurrentUserSatisfyWorkspaceMfa,
  isAnyWorkspaceRequireMfa,
  isExternalFileViewerEnabled,
  isAiAssistantEnabled,
  isSeatsLimitHit,
  isLoading,
  isLoaded,
  isCreating,
  isCreated,
  isUpdating,
  isUpdated,
  isRemoving,
  isRemoved,
  isFailed,
};
