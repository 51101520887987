import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { ApiKey } from '@priz/shared/src/models/api-keys';

export interface ApiKeysMap {
  [apiKeyId: number]: ApiKey;
}

export interface ApiKeysCollection {
  byUserId: {
    [userId: number]: ApiKeysMap;
  };
}

export interface ApiKeysStatuses {
  byUserId: {
    [userId: number]: EntityCollectionStatus;
  };
}

export interface ApiKeysState {
  keys: ApiKeysCollection;
  statuses: ApiKeysStatuses;
}

export const DefaultApiKeysState: ApiKeysState = {
  keys: {
    byUserId: {},
  },

  statuses: {
    byUserId: {},
  },
};
