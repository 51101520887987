import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { Box, Divider, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { FortyPrinciplesUtilization } from '@priz/shared/src/models/tools/forty-principles/forty-principles-utilization';
import { useForm } from 'react-hook-form';
import { ReactHookFormText } from '../../react/form-elements';
import { FortyPrinciplesUtilizationActions } from '../store/actions';
import { Trans } from 'react-i18next';
import { useStyles } from './styles';
import { InventivePrinciplesContradictionMap } from '@priz/shared/src/data/inventive-principles';
import {
  FortyPrinciplesUtilizationCommand,
  resolve40pInitialUpdateCommandData,
} from '../services/forty-principles-utilization.service';

export interface InventivePrinciplesContradictionProps {
  utilization: FortyPrinciplesUtilization;
  projectId?: number;
  disabled?: boolean;
}

export const InventivePrinciplesContradiction: React.FC<InventivePrinciplesContradictionProps> = ({
  utilization,
  projectId,
  disabled,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const [utilizationState, setUtilizationState] = useState(null);

  const { getValues, control, reset } = useForm({
    shouldFocusError: false,
    defaultValues: {
      contradictionIf: '',
      contradictionThen: '',
      contradictionBut: '',
      contradictionNotes: '',
    },
  });

  useEffect(() => {
    if (utilization && !utilizationState) {
      setUtilizationState(utilization);
    }
  }, [utilization, utilizationState]);

  useEffect(() => {
    reset({
      contradictionIf: utilization?.contradictionIf || '',
      contradictionThen: utilization?.contradictionThen || '',
      contradictionBut: utilization?.contradictionBut || '',
      contradictionNotes: utilization?.contradictionNotes || '',
    });
  }, [utilizationState]);

  const onTextUpdate = useCallback(
    debounce(() => {
      const command: FortyPrinciplesUtilizationCommand = {
        ...resolve40pInitialUpdateCommandData(utilization),
        ...getValues(),
      };

      dispatch(FortyPrinciplesUtilizationActions.update(utilization?.id, command, projectId));
    }, 500),
    [utilization],
  );

  const formChangeHandler = () => {
    onTextUpdate();
  };

  return (
    <Box>
      <Box mb={2}>
        <Typography variant={'h6'} component={'span'}>
          <Trans>Build an engineering contradiction</Trans>
        </Typography>
      </Box>

      {/*<Box mb={3}>*/}
      {/*  <Typography variant={'body2'} component={'span'}>*/}
      {/*    <Trans>*/}
      {/*      if something will be done, then something will be improved, but something else will be worsening.*/}
      {/*    </Trans>*/}
      {/*  </Typography>*/}
      {/*</Box>*/}

      <form onSubmit={e => e.preventDefault()} onChange={formChangeHandler}>
        <TableContainer component={'div'}>
          <Table>
            <TableBody>
              {InventivePrinciplesContradictionMap.map(({ name, label }, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row" padding={'none'} align={'right'} className={styles.tableCell}>
                    <Box pr={2} className={styles.tableCellLabelWrap}>
                      <Typography variant={'subtitle2'} component={'span'}>
                        <Trans>{label}</Trans>
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell style={{ width: '100%' }} padding={'none'} className={styles.tableCell}>
                    <ReactHookFormText name={name} control={control} multiline={true} disabled={disabled} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box mt={1} mb={3}>
          <Divider />
        </Box>

        <ReactHookFormText
          fieldTitle={'Notes'}
          name={'contradictionNotes'}
          control={control}
          multiline={true}
          disabled={disabled}
        />
      </form>
    </Box>
  );
};
