import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  controlButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 35,
    height: 35,
    borderRadius: '50%',
    background: pgColorScheme.lightGray,
    marginLeft: 5,
    cursor: 'pointer',

    ['& svg']: {
      width: 18,
      height: 18,
      display: 'block',
      fill: pgColorScheme.darkGray,
    },

    ['&:hover']: {
      background: pgColorScheme.gray,
    },
  },
});
