import React from 'react';
import { Box, BoxProps, LinearProgress } from '@mui/material';

interface LinearLoaderProps extends BoxProps {
  loading?: boolean;
}

export const LinearLoader: React.FC<LinearLoaderProps> = ({ loading, ...rest }) => {
  return (
    <Box pb={2} position={'relative'} {...rest}>
      <Box position={'absolute'} top={0} left={0} width={'100%'}>
        {loading && <LinearProgress />}
      </Box>
    </Box>
  );
};
