import { IFiveWhysCause } from '@priz/shared/src/models/tools/five-whys';
import axios, { AxiosResponse } from 'axios';

const fetchAll = (utilizationId: number): Promise<IFiveWhysCause[]> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/5-whys/${utilizationId}/cause`)
    .then((response: AxiosResponse<IFiveWhysCause[]>) => response.data);

const createCause = (
  utilizationId: number,
  description: string | undefined,
  causedCauseId: number,
): Promise<IFiveWhysCause> =>
  axios
    .post(`/v1/api/<ws-id>/team/<t-id>/5-whys/${utilizationId}/cause`, {
      causedCauseId,
      description,
    })
    .then((response: AxiosResponse<IFiveWhysCause>) => response.data);

export interface UpdateFiveWhysCauseCommand {
  description?: string;
  deadEnd?: boolean;
}

const updateCause = (
  utilizationId: number,
  causeId: number,
  command: UpdateFiveWhysCauseCommand,
): Promise<IFiveWhysCause> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/5-whys/${utilizationId}/cause/${causeId}`, command)
    .then((response: AxiosResponse<IFiveWhysCause>) => response.data);

const deleteCause = (utilizationId: number, causeId: number): Promise<any> =>
  axios
    .delete(`/v1/api/<ws-id>/team/<t-id>/5-whys/${utilizationId}/cause/${causeId}`)
    .then((response: AxiosResponse<IFiveWhysCause>) => response.data);

export const FiveWhysCauseApi = {
  updateCause,
  createCause,
  deleteCause,
  fetchAll,
};
