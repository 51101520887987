import React, { useEffect, useState } from 'react';
import { EbsIdea, EbsIdeaCategory, EbsIdeaDifficultyVariantTextMap, EbsIdeaNodeProps } from '../../../models/tools/ebs';
import { manageNetworkEvents, useNetworkDataset } from '../../../components/network-diagram/utils';
import { Network } from '../../../lib/vis/esnext';
import { Box, Typography } from '@mui/material';
import { pgColorScheme } from '../../../theme';
import { NetworkDiagram } from '../../../components/network-diagram/component';
import {
  convertEbsIdeasToNodes,
  drawAreas,
  drawAreasLabels,
  drawLines,
  splitIdeasOnCategories,
  updateNodesPositionIfUnset,
} from '../../../utils/ebs';
import { nodePropsDecorator, options } from '../../../data/ebs-options';
import { ArrayUtils, SortDirection } from '../../../utils/common';
import { IdeaCard } from '../../../components/idea-card/component';
import { Trans, useTranslation } from 'react-i18next';
import { getEbsDifficultiesText } from '../../../data/ebs-text';
import { FullscreenContainer } from '../../../components/fullscreen-container/component';
import { DiagramControls } from '../diagram-controls/component';

export interface EbsViewer {
  ideas: EbsIdea[];
  grouped?: boolean;
  disableInteractive?: boolean;
}

const networkEvents = (drawingCallback: (ctx: CanvasRenderingContext2D) => void) => ({
  afterDrawing: (ctx?: CanvasRenderingContext2D) => {
    if (ctx) drawingCallback(ctx);
  },
});

export const EbsViewer: React.FC<EbsViewer> = ({ ideas, grouped, disableInteractive }) => {
  const { t } = useTranslation();
  const translations = getEbsDifficultiesText(t);

  const { nodesDataSet, edgesDataSet } = useNetworkDataset<EbsIdeaNodeProps>(
    { edges: [], nodes: convertEbsIdeasToNodes(ideas) },
    nodePropsDecorator,
  );
  const [visNetwork, setVisNetwork] = useState<Network | null>(null);

  const networkInitHandler = (network: Network) => {
    setVisNetwork(network);
  };

  const categories = splitIdeasOnCategories(ideas);

  useEffect(() => {
    const events = networkEvents(drawingCallback);

    if (visNetwork) {
      updateNodesPositionIfUnset(visNetwork, nodesDataSet);
      manageNetworkEvents(visNetwork, events);
    }

    return () => {
      if (visNetwork) {
        manageNetworkEvents(visNetwork, events, true);
      }
    };
  }, [visNetwork, grouped]);

  const drawingCallback = (ctx: CanvasRenderingContext2D) => {
    if (visNetwork && grouped) {
      drawAreasLabels(ctx, translations);
      drawLines(ctx, visNetwork);
      drawAreas(ctx, visNetwork);
    }
  };

  if (!ideas?.length) return null;

  return (
    <>
      <Box
        mb={2}
        style={{
          width: '100%',
          height: '100mm',
          position: 'relative',
          background: pgColorScheme.workbenchBackground,
          boxShadow: `1000px 0 0 ${pgColorScheme.workbenchBackground} inset`,
          borderRadius: 10,
        }}
      >
        <FullscreenContainer
          style={{
            background: pgColorScheme.workbenchBackground,
            width: '100%',
            height: '100%',
          }}
          fullscreenDisabledStyle={{
            borderRadius: 'inherit',
          }}
          usePortal
        >
          {!disableInteractive && visNetwork && (
            <DiagramControls
              onSetCenter={() => {
                visNetwork.fit();
              }}
            />
          )}

          <NetworkDiagram
            options={options}
            nodesDataSet={nodesDataSet}
            edgesDataSet={edgesDataSet}
            onNetworkInit={networkInitHandler}
            viewMode={true}
            disabled={disableInteractive}
            redrawOnFontsLoad
          />
        </FullscreenContainer>
      </Box>

      {Object.keys(categories).map(c => {
        const key = c as EbsIdeaCategory;

        if (!categories[key as EbsIdeaCategory]?.length) return null;

        const { implementation, validation } = EbsIdeaDifficultyVariantTextMap[key];

        return (
          <Box key={key} mb={2}>
            <Box mb={1}>
              <Typography variant={'body1'} component={'span'}>
                <Trans>Implementation</Trans>:&nbsp;
                <b>
                  <Trans>{implementation}</Trans>
                </b>
                ,&nbsp;
                <Trans>Validation</Trans>:&nbsp;
                <b>
                  <Trans>{validation}</Trans>
                </b>
              </Typography>
            </Box>

            {categories[key]
              .sort(ArrayUtils.sorterByParam('latestRankingScore', SortDirection.DESC))
              .map((idea: EbsIdea, key) => (
                <IdeaCard
                  key={key}
                  content={idea?.description || ''}
                  rank={idea.latestRankingScore}
                  date={idea.lastUpdated || idea.dateCreated}
                />
              ))}
          </Box>
        );
      })}
    </>
  );
};
