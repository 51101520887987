import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';

export const useStyles = makeStyles({
  nameContainer: {
    maxWidth: '100%',
    display: 'flex',
  },
  name: {
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  label: {
    color: pgColorScheme.gray600,
    display: 'block',
  },
  defaultIcon: {
    width: '70%',
    height: '70%',
  },
});
