import { ComponentProblematicType, ComponentRecommendationType } from '../store/model';

type RecommendationsDescriptionMap = {
  [key in ComponentProblematicType]?: {
    title: string;
    descriptions: {
      [key in ComponentRecommendationType]?: string;
    };
  };
};

export const componentRecommendationsDescriptionMap: RecommendationsDescriptionMap = {
  [ComponentProblematicType.NoImpact]: {
    title: 'The component has no impact on the product',
    descriptions: {
      [ComponentRecommendationType.Verify]: 'Verify the model and add relevant functions for the “no impact” component',
      [ComponentRecommendationType.Eliminate]: 'Eliminate “no impact” component if possible',
    },
  },
  [ComponentProblematicType.PureProblematic]: {
    title: 'The component has only problematic rank',
    descriptions: {
      [ComponentRecommendationType.Verify]: 'Verify the model and add relevant useful functions if they exist',
      [ComponentRecommendationType.Eliminate]: 'Eliminate harmful component if possible',
      [ComponentRecommendationType.Mitigate]:
        'Mitigate the most harmful function by introducing an additional component',
    },
  },
  [ComponentProblematicType.Conflict]: {
    title: 'The component has both functional and problematic rank',
    descriptions: {
      [ComponentRecommendationType.Mitigate]:
        'Mitigate the most harmful function by introducing an additional component',
      [ComponentRecommendationType.Resolve]: 'Resolve the conflicting functions with Inventive principles',
    },
  },
  [ComponentProblematicType.Functionality]: {
    title: 'The component has both useful and insufficient or excessive function',
    descriptions: {
      [ComponentRecommendationType.Verify]: 'Verify if its function truly insufficient or excessive',
      [ComponentRecommendationType.Mitigate]: 'Improve functionality to eliminate insufficiency or excessiveness',
    },
  },
  [ComponentProblematicType.Insufficient]: {
    title: 'The component is producing an insufficient function',
    descriptions: {
      [ComponentRecommendationType.Verify]: 'Verify if its function is truly insufficient',
      [ComponentRecommendationType.Mitigate]: 'Modify the component to address its functional inadequacies',
    },
  },
  [ComponentProblematicType.Excessive]: {
    title: 'The component is producing an excessive function',
    descriptions: {
      [ComponentRecommendationType.Verify]: 'Verify if its function is truly excessive',
      [ComponentRecommendationType.Mitigate]: 'Modify the component to reduce its excessive functionality',
    },
  },
  [ComponentProblematicType.Skew]: {
    title: 'The component is producing both insufficient and excessive functions',
    descriptions: {
      [ComponentRecommendationType.Verify]: 'Verify if its functions are truly insufficient and excessive',
      [ComponentRecommendationType.Mitigate]: 'Modify the component to optimize its functions',
    },
  },
  [ComponentProblematicType.Ineffective]: {
    title: 'Component has no problematic rank but the functional rank is very low',
    descriptions: {
      [ComponentRecommendationType.Verify]:
        'Check the model and search for additional functions that the component can provide',
      [ComponentRecommendationType.Resolve]: 'Resolve the conflicting functions with Inventive principles',
    },
  },
};
