import { createStyles, makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 4,
      height: '100%',
      width: '100%',
    },
    cardContent: {
      height: 192,
      overflowY: 'auto',
      whiteSpace: 'pre-wrap',
    },
    cardActions: {
      height: 48,
      padding: 0,
    },
    button: {
      backgroundColor: pgColorScheme.lightGray,
      borderRadius: '0 0 4px 4px',
      color: pgColorScheme.black,
      height: '100%',
      width: '100%',

      ['&.selected']: {
        backgroundColor: pgColorScheme.green,
        color: pgColorScheme.white,
      },
    },
    [theme.breakpoints.down('sm')]: {
      cardContent: {
        height: 'auto',
      },
      cardActions: {
        height: 'auto',
      },
    },
  }),
);
