import React from 'react';
import { useStyles } from './styles';
import { Box, BoxProps } from '@mui/material';

export const AIChatIcon: React.FC<BoxProps> = ({ className, ...rest }) => {
  const styles = useStyles();
  const rootClassNames = [styles.root];

  if (className) rootClassNames.push(className);

  return <Box className={rootClassNames.join(' ')} {...rest} />;
};
