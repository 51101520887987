import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  tableCell: {
    verticalAlign: 'top',
    border: 'none',
  },
  tableCellLabelWrap: {
    minHeight: 56,
    display: 'flex',
    alignItems: 'center',
  },
  editIconButton: {
    'marginLeft': 5,

    '& .MuiSvgIcon-root': {
      width: 18,
      height: 18,
    },
  },
});
