import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Trans } from 'react-i18next';
import { Idea } from '@priz/shared/src/models/idea/idea';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { IdeasSelectors, ProjectIdeaActions } from '../store';
import { IdeaCommentsButton } from '../idea-comments-button/component';
import { DeleteOutlineRounded, ModeEditOutlineOutlined, VisibilityOutlined } from '@mui/icons-material';
import { IdeaRecorder } from '../idea-recorder/component';
import { IdeaViewer } from '../idea-viewer/component';

export interface IdeaControlsProps {
  idea: Idea;
  compact?: boolean;
  disabled?: boolean;
}

export const IdeaControls: React.FC<IdeaControlsProps> = ({ idea, compact, disabled }) => {
  const dispatch = useDispatch();

  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

  const isRemoving = useSelector(IdeasSelectors.isRemoving(idea.project.id));
  const isRemoved = useSelector(IdeasSelectors.isRemoved(idea.project.id));

  useEffect(() => {
    if (isRemoved) closeDialogs();
  }, [isRemoved]);

  const deleteIdea = () => {
    dispatch(ProjectIdeaActions.delete(idea.project.id, idea.id));
  };

  const openViewer = () => {
    setIsViewerOpen(true);
  };

  const openEditor = () => {
    setIsEditorOpen(true);
  };

  const openDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const closeDialogs = () => {
    setIsEditorOpen(false);
    setIsViewerOpen(false);
    setIsDeleteConfirmationOpen(false);
  };

  return (
    <>
      <Box display={'flex'} alignItems={'center'}>
        <IdeaCommentsButton idea={idea} compact={compact} mr={1} />

        <Tooltip title={<Trans>View</Trans>} placement={'top'} arrow disableInteractive>
          <div>
            <IconButton size={'small'} color={'primary'} onClick={openViewer}>
              <VisibilityOutlined fontSize={compact ? 'small' : 'medium'} />
            </IconButton>
          </div>
        </Tooltip>

        <Tooltip title={<Trans>Edit</Trans>} placement={'top'} arrow disableInteractive>
          <div>
            <IconButton size={'small'} color={'primary'} onClick={openEditor} disabled={disabled}>
              <ModeEditOutlineOutlined fontSize={compact ? 'small' : 'medium'} />
            </IconButton>
          </div>
        </Tooltip>

        <Tooltip title={<Trans>Delete</Trans>} placement={'top'} arrow disableInteractive>
          <div>
            <IconButton size={'small'} color={'error'} onClick={openDeleteConfirmation} disabled={disabled}>
              <DeleteOutlineRounded fontSize={compact ? 'small' : 'medium'} />
            </IconButton>
          </div>
        </Tooltip>
      </Box>

      <IdeaViewer open={isViewerOpen} idea={idea} onClose={closeDialogs} />

      <IdeaRecorder edit={true} isOpen={isEditorOpen} idea={idea} projectId={idea.project.id} onClose={closeDialogs} />

      <PgConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        confirmTitle={<Trans>Confirm idea deletion</Trans>}
        cancelButtonText={<Trans>Cancel</Trans>}
        okButtonText={<Trans>Delete</Trans>}
        confirmContent={<Trans>Are you sure you want to delete this idea? This operation cannot be undone!</Trans>}
        onConfirm={deleteIdea}
        onClose={closeDialogs}
        disabled={isRemoving}
        loading={isRemoving}
      />
    </>
  );
};
