import { Navigate, RouteObject } from 'react-router-dom';
import { RouteRenderer } from './route-renderer/component';
import { UserProfile } from '../user-profile/profile/user-profile';
import { Role } from '@priz/shared/src/models/security/role.enum';
import { PersonalInformation } from '../user-profile/personal-information/component';
import { SecuritySetting } from '../user-profile/security-setting/component';
import { ApiKeysPage } from '../user-profile/api-keys/component';
import { PartnershipPage } from '../user-profile/partnership/component';
import { UnleashFeatureName } from '@priz/shared/src/services/unleash';
import { NotificationsPage } from '../user-profile/notifications-page/component';
import { documentTitleResolver } from './utils';

export const userProfileRoutes: RouteObject[] = [
  {
    path: 'profile',
    element: (
      <RouteRenderer
        Component={UserProfile}
        authGuard={true}
        forceBilling={false}
        mfaFree={true}
        roles={[Role.ROLE_USER]}
      />
    ),
    children: [
      {
        path: 'personal-information',
        element: <RouteRenderer Component={PersonalInformation} forceBilling={false} authGuard={true} mfaFree={true} />,
        loader: documentTitleResolver('Personal Information'),
      },
      {
        path: 'security-setting',
        element: <RouteRenderer Component={SecuritySetting} forceBilling={false} authGuard={true} mfaFree={true} />,
        loader: documentTitleResolver('Security Setting'),
      },
      {
        path: 'notifications',
        element: <RouteRenderer Component={NotificationsPage} forceBilling={false} authGuard={true} mfaFree={true} />,
        loader: documentTitleResolver('Newsletter'),
      },
      {
        path: 'partnership',
        element: (
          <RouteRenderer
            Component={PartnershipPage}
            requiredFeature={UnleashFeatureName.Partnership}
            authGuard={true}
            mfaFree={true}
          />
        ),
        loader: documentTitleResolver('Partnership'),
      },
      {
        path: 'api-keys',
        element: (
          <RouteRenderer
            Component={ApiKeysPage}
            requiredFeature={UnleashFeatureName.ApiKeys}
            authGuard={true}
            mfaFree={true}
          />
        ),
        loader: documentTitleResolver('API Keys'),
      },
      {
        path: '',
        element: <Navigate to="personal-information" replace={true} />,
      },
    ],
  },
];
