import { IdRef } from '../common/id-ref';
import { AttendeeRoleFilterType, MeetingsPeriodFilterType, MeetingStatusFilterType } from '../meetings';

export interface UserProjectSettings {
  meetings: {
    filters: {
      period: MeetingsPeriodFilterType | null;
      status: MeetingStatusFilterType | null;
      role: AttendeeRoleFilterType | null;
    };
  };
}

export interface UserProjectSettingsResponse {
  id: number;
  settings: UserProjectSettings;
  user: IdRef;
}

export const DefaultUserProjectSettings: UserProjectSettings = {
  meetings: {
    filters: {
      period: null,
      status: null,
      role: null,
    },
  },
};
