import { DefaultUserSettings, UserSettings } from '@priz/shared/src/models/user/user-settings';

export interface UserSettingsState {
  settings: UserSettings;
  loading: boolean;
  loaded: boolean;
  updating: boolean;
  updated: boolean;
  error?: any;
}

export const DefaultUserSettingsState: UserSettingsState = {
  loading: false,
  loaded: false,
  updating: false,
  updated: false,
  settings: DefaultUserSettings,
};
