import { AuditableEntity, IAuditableEntity } from '../common/auditable-entity';

export interface IProfile extends IAuditableEntity {
  id: number;
  title?: string;
  firstName?: string;
  lastName?: string;
  organization?: string;
  location?: string;
  phoneNumber?: string;
  avatarUrl?: string;
  timeZoneCode?: string;
}

export class Profile extends AuditableEntity implements IProfile {
  public id: number;
  public title?: string;
  public firstName?: string;
  public lastName?: string;
  public organization?: string;
  public location?: string;
  public phoneNumber?: string;
  public avatarUrl?: string;
  public timeZoneCode?: string;

  constructor(json: IProfile) {
    super(json);
    this.id = json.id;
    this.title = json.title;
    this.firstName = json.firstName;
    this.lastName = json.lastName;
    this.organization = json.organization;
    this.location = json.location;
    this.phoneNumber = json.phoneNumber;
    this.avatarUrl = json.avatarUrl;
    this.timeZoneCode = json.timeZoneCode;
  }
}
