import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { ReactHookFormSelect } from '../../../react/form-elements';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { DiagramDataVersions } from '@priz/shared/src/models/tools';
import { ToolVersionUtils } from '@priz/shared/src/utils/tools';

interface CreateVersionDialogProps {
  diagramData?: DiagramDataVersions;
  open?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onSubmit?: (sourceVersion?: string) => void;
  onCancel?: () => void;
}

export const CreateVersionDialog: React.FC<CreateVersionDialogProps> = ({
  diagramData,
  open,
  onSubmit,
  onCancel,
  disabled,
  loading,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { handleSubmit, reset, control } = useForm({
    shouldFocusError: false,
    defaultValues: {
      version: '',
    },
  });

  const versions = diagramData?.versions;
  const versionsIds = versions ? ToolVersionUtils.getOrderedVersionsKeys(versions) : [];
  const options = versionsIds.map((id, key) => {
    const title = versions[id].title;
    const text = `${t('Clone')} "${title.length ? title : `${t('Version')} ${key + 1}`}"`;

    return {
      value: id,
      text: text,
    };
  });

  useEffect(() => {
    if (typeof open !== 'undefined') {
      setIsOpen(open);
    }

    if (open) {
      reset({
        version: '',
      });
    }
  }, [open]);

  const handleClose = () => {
    if (onCancel) {
      onCancel();
    } else {
      setIsOpen(false);
    }
  };

  const handleSave = () => {
    handleSubmit(formData => {
      if (onSubmit) onSubmit(formData.version);
    })();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>
        <Trans>Create new version</Trans>
      </DialogTitle>

      <DialogContent>
        <ReactHookFormSelect
          displayEmpty={true}
          variant={'standard'}
          name={'version'}
          control={control}
          options={[{ value: '', text: t('Default') }, ...options]}
          wrapperProps={{
            mb: 0,
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose}>
          <Trans>Cancel</Trans>
        </Button>

        <LoadingButton
          variant={'pg_rounded'}
          color={'pg_orange_solid'}
          onClick={handleSave}
          disabled={disabled || loading}
          loading={loading}
        >
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
