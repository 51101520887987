import React from 'react';
import { Typography } from '@mui/material';
import { useStyles } from './styles';

interface TopicLabelPreviewProps {
  text: string;
  color: string;
}

export const TopicLabelPreview: React.FC<TopicLabelPreviewProps> = ({ text, color }) => {
  const styles = useStyles({ color });

  return (
    <div className={`${styles.root} ${styles.rootColorProps}`}>
      <Typography className={styles.text} variant={'body1'} component={'span'}>
        {text}
      </Typography>
    </div>
  );
};
