import React from 'react';
import { IdeaComment } from '@priz/shared/src/models/idea';
import { Box, BoxProps, Grid, Tooltip, Typography } from '@mui/material';
import { ValueBar } from '@priz/shared/src/components/value-bar/component';
import { Trans, useTranslation } from 'react-i18next';
import { resolveCommentsSummary } from '../utils';
import { pgColorScheme } from '@priz/shared/src/theme';
import { resolveValueByColorType, ValueColorType } from '@priz/shared/src/utils/common';
import { HelpRounded } from '@mui/icons-material';

interface IdeaCommentsSummaryProps extends BoxProps {
  comments: IdeaComment[];
}

export const IdeaCommentsSummary: React.FC<IdeaCommentsSummaryProps> = ({ comments, ...rest }) => {
  const { t } = useTranslation();
  const { gainSum, costSum, riskSum, rating, totalCount, validCount, invalidCount } = resolveCommentsSummary(comments);

  const ratingColor = resolveValueByColorType({
    value: rating,
    green: 70,
    blue: 30,
    valuesMap: {
      [ValueColorType.Green]: pgColorScheme.green,
      [ValueColorType.Blue]: pgColorScheme.blue,
      [ValueColorType.Red]: pgColorScheme.coral,
    },
  });

  if (!validCount) return null;

  return (
    <Box {...rest}>
      <Box mb={2}>
        <Grid container spacing={1} alignItems={'center'}>
          <Grid item>
            <Typography variant={'subtitle1'}>
              <b>
                <Trans>Summary of</Trans>
                {' ' + validCount}
                {invalidCount ? `/${totalCount} ` : ' '}
                <Trans>{comments.length > 1 ? 'comments' : 'comment'}</Trans>
              </b>
            </Typography>
          </Grid>

          {!!invalidCount && (
            <Grid item>
              <Tooltip
                title={`${invalidCount} ${invalidCount > 1 ? t('comments are') : t('comment is')} ${t(
                  'not completed',
                )}`}
                disableInteractive
                arrow
                placement={'top'}
              >
                <div>
                  <HelpRounded color={'primary'} fontSize={'small'} style={{ display: 'block', cursor: 'help' }} />
                </div>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={'auto'}>
          <Box
            textAlign={'center'}
            p={4}
            sx={{
              borderRadius: 2,
              border: 2,
              borderStyle: 'solid',
              borderColor: ratingColor,
              color: ratingColor,
            }}
          >
            <Typography variant={'body1'}>
              <b>
                <Trans>Rating</Trans>
              </b>
            </Typography>
            <Typography variant={'h5'}>
              <b>{rating}%</b>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={true}>
          <Typography variant={'subtitle2'}>
            <Trans>Gain</Trans>
          </Typography>

          <ValueBar
            value={gainSum ? gainSum / comments.length : 0}
            green={6}
            blue={3}
            containerProps={{ mb: 1 }}
            barProps={{ pt: 2 }}
          />

          <Typography variant={'subtitle2'}>
            <Trans>Cost</Trans>
          </Typography>

          <ValueBar
            value={costSum ? costSum / comments.length : 0}
            green={6}
            blue={3}
            reverse
            containerProps={{ mb: 1 }}
            barProps={{ pt: 2 }}
          />

          <Typography variant={'subtitle2'}>
            <Trans>Risk</Trans>
          </Typography>

          <ValueBar
            value={riskSum ? riskSum / comments.length : 0}
            green={6}
            blue={3}
            reverse
            containerProps={{ mb: 1 }}
            barProps={{ pt: 2 }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
