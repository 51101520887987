import React from 'react';
import { AppBar, Box, Grid, Hidden, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useStyles } from './styles';
import { WorkspaceSelector } from '../workspace-selector/component';
import { CurrentUserHeadingButton } from '../../user/current-user-heading-button/component';
import { Workspace } from '@priz/shared/src/models/workspace';
import { Project } from '@priz/shared/src/models/project';
import { Link } from 'react-router-dom';
import { PlanUpgradeDialog } from '../../billing/plan-upgrade-dialog/component';
import { Breadcrumbs } from '../../react/elements/breadcrumbs/component';
import { LockOutlined, PublicOutlined } from '@mui/icons-material';
import { ManageSeatsDialog } from '../../billing/manage-seats-dialog/component';
import { CrumbType } from '../../react/elements/breadcrumbs/model';
import { Trans } from 'react-i18next';
import { Chat } from '../../chat/component';
import { useSelector } from 'react-redux';
import { WorkspaceSelectors } from '../../workspace/store/selectors';

import lightThemeLogoIcon from '../../../assets/img/light-theme-logo.svg';
import lightThemeLogoMiniIcon from '../../../assets/img/light-theme-logo-mini.svg';
import { Guides } from '../../guides/component';

interface AppNavbarProps {
  workspace?: Workspace;
  project?: Project;
  padding?: boolean;
}

export const AppNavbar: React.FC<AppNavbarProps> = ({ workspace, project, padding = true, children }) => {
  const theme = useTheme();
  const styles = useStyles();
  const isDownMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const selectedWorkspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const isAiAssistantEnabled = useSelector(WorkspaceSelectors.isAiAssistantEnabled(selectedWorkspace?.id));

  return (
    <AppBar className={[styles.root, 'app-navbar'].join(' ')} position="relative" color="default">
      <Box px={{ xs: 0, sm: padding ? 2 : 0 }}>
        <Toolbar className={styles.toolbar}>
          <Box className={styles.leftCol}>
            {(workspace || project) && (
              <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} width={'100%'}>
                <Hidden smUp>
                  <Link to={'/'} className={styles.logoWrap}>
                    <img className={styles.logo} src={lightThemeLogoMiniIcon} alt={'logo'} />
                  </Link>
                </Hidden>

                {project?.title && (
                  <Grid container className={styles.descriptionContainer}>
                    <Grid item xs={12} className={styles.titleWrap}>
                      <Grid container alignItems={'center'}>
                        <Grid item xs={'auto'}>
                          <Box pr={0.5}>
                            <Tooltip
                              title={<Trans>{project.open ? 'Public project' : 'Private project'}</Trans>}
                              arrow={true}
                            >
                              {project.open ? <PublicOutlined color={'success'} /> : <LockOutlined color={'primary'} />}
                            </Tooltip>
                          </Box>
                        </Grid>

                        <Grid item xs={true} display={'flex'} overflow={'hidden'}>
                          <Typography variant="body0" component="span" className={styles.title} gutterBottom={false}>
                            {project.title}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Breadcrumbs
                        projectId={project.id}
                        basePath={[CrumbType.Home, CrumbType.ProjectList, CrumbType.Project]}
                        minify={isDownMdBreakpoint}
                      />
                    </Grid>
                  </Grid>
                )}
              </Box>
            )}

            {!project && !workspace && (
              <>
                <Hidden mdDown>
                  <Link to={'/'} className={styles.logoWrap}>
                    <img className={styles.logo} src={lightThemeLogoIcon} alt={'logo'} />
                  </Link>
                </Hidden>

                <Hidden mdUp>
                  <Link to={'/'} className={styles.logoWrap}>
                    <img className={styles.logo} src={lightThemeLogoMiniIcon} alt={'logo'} />
                  </Link>
                </Hidden>
              </>
            )}

            {children}
          </Box>

          <Box ml={'auto'} display={'flex'} alignItems={'center'}>
            {/*<RenderForRoles roles={[Role.ROLE_ADMIN]}>*/}
            <Box mr={1}>
              <Guides />
            </Box>
            {/*</RenderForRoles>*/}

            {isAiAssistantEnabled && typeof project?.id !== 'undefined' && (
              <Box mr={{ xs: 1, sm: 2 }}>
                <Chat projectId={project.id} />
              </Box>
            )}

            <Hidden smDown>
              <WorkspaceSelector />
            </Hidden>

            <CurrentUserHeadingButton showWorkspaceSelectorOnMobile={true} />
          </Box>
        </Toolbar>
      </Box>

      <PlanUpgradeDialog />
      <ManageSeatsDialog />
    </AppBar>
  );
};
