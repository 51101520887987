import React from 'react';
import { TemplateType } from '@priz/shared/src/models/template';
import { Box, BoxProps, Grid, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { templateTypeDescription } from '../data';
import { useStyles } from './styles';

interface TemplateDescriptionProps extends BoxProps {
  type: TemplateType;
}

export const TemplateDescription: React.FC<TemplateDescriptionProps> = ({ type, ...rest }) => {
  const styles = useStyles();

  return (
    <Box {...rest}>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        <Grid item xs={12} md={6}>
          <Typography variant={'body1'}>
            <Trans>{templateTypeDescription[type].text}</Trans>
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <img className={styles.img} src={templateTypeDescription[type].img} alt={type} />
        </Grid>
      </Grid>
    </Box>
  );
};
