import React, { useEffect } from 'react';
import { User } from '@priz/shared/src/models/security/user';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ReactHookFormText } from '@priz/shared/src/components/form-elements';
import { validatorRules } from '@priz/shared/src/utils/form';

interface ConnectAccountDialogProps {
  user: User;
  isOpen?: boolean;
  onSubmit?: (password: string) => void;
  onClose?: () => void;
}

export const ConnectAccountDialog: React.FC<ConnectAccountDialogProps> = ({ user, isOpen, onSubmit, onClose }) => {
  const { handleSubmit, control, reset } = useForm({
    shouldFocusError: false,
    defaultValues: {
      email: user.email,
      password: '',
    },
  });

  useEffect(() => {
    if (isOpen) {
      reset({
        email: user.email,
        password: '',
      });
    }
  }, [isOpen, user?.id]);

  const loginWithEmailPassword = () => {
    handleSubmit(formData => {
      if (onSubmit) onSubmit(formData.password);
    })();
  };

  return (
    <Dialog open={isOpen} maxWidth={'sm'} fullWidth onClose={onClose}>
      <DialogTitle>
        <Typography variant={'h5'} component={'span'}>
          <Trans>{'Set password'}</Trans>
        </Typography>
      </DialogTitle>

      <DialogContent>
        <ReactHookFormText fieldTitle={'Email'} name={'email'} type={'email'} control={control} disabled={true} />

        <ReactHookFormText
          fieldTitle={'Password'}
          name={'password'}
          type={'password'}
          control={control}
          rules={{
            ...validatorRules.required(),
            ...validatorRules.password(),
          }}
        />
      </DialogContent>

      <DialogActions sx={{ textAlign: 'right' }}>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={onClose}>
          <Trans>Cancel</Trans>
        </Button>

        <Button variant={'pg_rounded'} color={'pg_orange_solid'} onClick={loginWithEmailPassword}>
          <Trans>Submit</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
