import React from 'react';
import { Grid, Link, Typography } from '@mui/material';
import { ToolIcon, ToolIconProps } from '../tool-icon/component';
import { ToolTypeDataMap } from '@priz/shared/src/data/tool-type-data-map';
import { Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { ToolsUtils } from '../utils';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { toolTypeWorkspaceTabMap } from '../model';

export interface ToolLinkProps extends ToolIconProps {
  sourceId?: string;
  disabled?: boolean;
}

export const ToolLink: React.FC<ToolLinkProps> = ({ tool, sourceId, disabled, ...rest }) => {
  const { title, type } = tool || {};
  const { name } = (type && ToolTypeDataMap[type]) || {};

  const clickHandler = () => {
    if (sourceId) {
      const tab = toolTypeWorkspaceTabMap[tool.type];

      if (tab) {
        LocalStorageService.setItem(LocalStorageKey.LastViewedToolTab, tab);
        LocalStorageService.setItem(LocalStorageKey.SwitchToLastViewedToolTab, 'true');
      }

      LocalStorageService.setItem(LocalStorageKey.LinkSourceId, sourceId);
    }
  };

  const renderContent = () => {
    return (
      <Grid container spacing={1} wrap={'nowrap'} alignItems={'center'}>
        <Grid item xs={'auto'}>
          <ToolIcon tool={tool} size={'extra-small'} {...rest} />
        </Grid>

        <Grid item xs={true} overflow={'hidden'}>
          <Typography variant={'inherit'} noWrap>
            {title || <Trans>{name}</Trans>}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  if (disabled) {
    return renderContent();
  }

  return (
    <Link component={RouterLink} to={ToolsUtils.resolvePathByUtilization(tool)} onClick={clickHandler}>
      {renderContent()}
    </Link>
  );
};
