import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CertificateActions } from '../store';
import { Project } from '@priz/shared/src/models/project';
import { ProjectFlowService } from '../../project/services';
// import {environment} from "@priz/shared/src/environments/environment";
import { useTranslation } from 'react-i18next';
import { CertificateSelectors } from '../store/selectors/certificate.selectors';
import { Button } from '@mui/material';

export interface GenerateCertificateProps {
  project: Project;
}

export const GenerateCertificate: React.FC<GenerateCertificateProps> = ({ project }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // TODO: load image
  // const sampleCertificateUrl = `${environment.apiDomain}/v1/certificate/sample`;

  const isProjectLocked = ProjectFlowService.isProjectLocked(project);
  const isSaving = useSelector(CertificateSelectors.isSaving);

  const generate = () => {
    dispatch(CertificateActions.generateCertificateByProjectId(project.id));
  };

  return (
    <>
      <img src={'#'} className="sample-certificate" alt={t('Sample certificate image')} />

      <Button
        variant={'pg_rounded'}
        color={'pg_orange_solid'}
        className="generate-certificate-button"
        onClick={generate}
        disabled={isSaving || isProjectLocked}
      >
        Generate
      </Button>
    </>
  );
};
