import React, { useEffect } from 'react';
import { AppNavbar } from '../navigation/app-navbar/component';
import { Grid } from '@mui/material';
import { PageTitleWithDocLink } from '../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import { DashboardProjectsWidget } from './dashboard-projects-widget/component';
import { DashboardMeetingsWidget } from './dashboard-meetings-widget/component';
import { DashboardTasksWidget } from './dashboard-tasks-widget/component';
import { DashboardIdeasWidget } from './dashboard-ideas-widget/component';
import { useDispatch, useSelector } from 'react-redux';
import { WorkspaceSelectors } from '../workspace/store/selectors';
import { WorkspaceMembersActions } from '../workspace/store/actions';
import { PrizLoadingOverlay } from '@priz/shared/src/components/priz-loading-overlay/component';
import { WorkspaceMembersSelectors } from '../workspace/store/selectors/workspace-members.selectors';
import { PageContainer } from '../content-containers/page-container/component';

export const Dashboard: React.FC = () => {
  const dispatch = useDispatch();

  const selectedWorkspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const isWorkspaceLoaded = useSelector(WorkspaceSelectors.isLoaded);
  const areWorkspaceMembersLoaded = useSelector(WorkspaceMembersSelectors.isLoaded);

  useEffect(() => {
    dispatch(WorkspaceMembersActions.loadAll());
  }, []);

  if (!isWorkspaceLoaded || !areWorkspaceMembersLoaded) return <PrizLoadingOverlay />;

  return (
    <>
      <PageContainer>
        <AppNavbar />

        <>
          <PageTitleWithDocLink title={<Trans>Dashboard</Trans>} />

          <Grid container spacing={{ xs: 2, lg: 4 }}>
            <Grid item xs={12} lg={6}>
              <DashboardProjectsWidget workspaceId={selectedWorkspace.id} />
            </Grid>

            <Grid item xs={12} lg={6}>
              <DashboardIdeasWidget workspaceId={selectedWorkspace.id} />
            </Grid>

            <Grid item xs={12} lg={6}>
              <DashboardTasksWidget workspaceId={selectedWorkspace.id} />
            </Grid>

            <Grid item xs={12} lg={6}>
              <DashboardMeetingsWidget workspaceId={selectedWorkspace.id} />
            </Grid>
          </Grid>
        </>
      </PageContainer>
    </>
  );
};
