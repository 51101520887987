import React, { useEffect } from 'react';
import { PMapUtilization } from '@priz/shared/src/models/tools/pmap';
import { PerceptionMapViewer } from '@priz/shared/src/components/tools-viewers';
import { useDispatch, useSelector } from 'react-redux';
import { PMapResultSelectors } from '../../../pmap/store/selectors/pmap-result.selectors';
import { PMapUtilizationActions } from '../../../pmap/store/actions';

interface PMapResultProps {
  utilization: PMapUtilization;
}

export const PMapResult: React.FC<PMapResultProps> = ({ utilization }) => {
  const dispatch = useDispatch();

  const result = useSelector(PMapResultSelectors.getResultByUtilizationId(utilization?.id));

  useEffect(() => {
    dispatch(PMapUtilizationActions.getResult(utilization.id));
  }, []);

  return <PerceptionMapViewer result={result} utilization={utilization} disableInteractive />;
};
