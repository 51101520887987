import React from 'react';
import { useSelector } from 'react-redux';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import { TitleContainer } from '../../react/modules/title-container/component';
import { ProjectSelector } from '../store/selectors';
import { ButtonCreateTool } from '../../tools/button-create-tool/component';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { ProjectToolsList } from './project-tools-list/component';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';

export interface ProjectToolsPageProps {
  projectId: number;
}

export const ProjectToolsPage: React.FC<ProjectToolsPageProps> = ({ projectId }) => {
  const isProjectLockedById = useSelector(ProjectSelector.isProjectLockedById(projectId));

  return (
    <>
      <ContentContainer>
        <TitleContainer>
          <PageTitleWithDocLink
            title={<Trans>Creative tools</Trans>}
            oneLiner={
              <Trans>
                We all think under psychological inertia which directs us to solve problem based on our previous
                experience only.
              </Trans>
            }
            docsUrl={'https://www.priz.guru/knowledge-base/creative-tools/'}
          />

          <ButtonCreateTool projectId={projectId} />
        </TitleContainer>

        <ProjectToolsList projectId={projectId} disabled={isProjectLockedById} />
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar projectId={projectId} workingFlowProps={{ nextVariant: 'ideas' }} />
      </ContentFooter>
    </>
  );
};
