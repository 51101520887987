import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 100,
    backgroundColor: pgColorScheme.gray200,
  },
  button: {
    margin: 0,
  },
});
