import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';

export const useStyles = makeStyles({
  legendLabel: {
    lineHeight: 1,
  },
  legendIndicator: {
    width: 12,
    height: 12,
    borderRadius: '50%',
  },
  functionalIndicator: {
    background: pgColorScheme.blue,
  },
  problematicIndicator: {
    background: pgColorScheme.coral,
  },
});
