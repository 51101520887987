import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../store/app.state';
import { User } from '@priz/shared/src/models/security/user';
import { UserContext, WorkspaceProjectsCountMap } from '../model';
import { CurrentUser } from '@priz/shared/src/models/user';

const userContextSelector = (state: AppState): UserContext => state.userContext;
const currentUserSelector = (state: AppState): CurrentUser => state.currentUser;

const isLoading: Selector<AppState, boolean> = createSelector(
  [userContextSelector, currentUserSelector],
  (context, user) => context?.loading || user?.loading,
);

const isLoaded: Selector<AppState, boolean> = createSelector(userContextSelector, context => context.loaded);

const getCurrentUser: Selector<AppState, User> = createSelector(
  [userContextSelector, currentUserSelector],
  (context, user) => ({
    ...context.currentUser,
    profile: user?.user?.profile || context.currentUser?.profile,
    connections: user?.user?.connections,
    activeConnection: user?.user?.activeConnection,
    auth0Id: user?.user?.auth0Id,
    googleId: user?.user?.googleId,
    linkedinId: user?.user?.linkedinId,
  }),
);

const workspaceProjectsCountMap: Selector<AppState, WorkspaceProjectsCountMap> = createSelector(
  userContextSelector,
  context => context.workspaceProjectsCountMap,
);

export const UserContextSelectors = {
  getCurrentUser,
  workspaceProjectsCountMap,
  isLoaded,
  isLoading,
};
