import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { SideNavItem } from '../../shared/section-sidenav-item/component';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { useSelector, useDispatch } from 'react-redux';
import { ProjectSelector } from '../store/selectors';
import { ProjectActions } from '../store/actions/project.actions';
import { ProjectStatus } from '@priz/shared/src/models/project';
// import { alpha } from '@mui/styles';
// import { pgColorScheme } from '@priz/shared/src/theme';
// import { Box, Typography } from '@mui/material';
// import {useNavigate} from "react-router-dom";

import { ReactComponent as CircleCheckIcon } from '../../../assets/icons/checked.svg';

export interface CompleteProjectProps {
  projectId: number;
  disabled?: boolean;
  completed?: boolean;
}

export const CompleteProject: React.FC<CompleteProjectProps> = ({ projectId, disabled, completed }) => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  // const [publishDialogOpen, setPublishDialogOpen] = useState(false);
  const [projectCompleteRequested, setProjectCompleteRequested] = useState(false);
  const isAnyProjectSaving = useSelector(ProjectSelector.isAnyProjectSaving);
  const isProjectCompleted = useSelector(ProjectSelector.isProjectCompleted(projectId));

  const handleConfirmOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmDialogOpen(false);
  };

  // const handlePublishOpen = () => {
  //   setPublishDialogOpen(true);
  // };

  // const handlePublishClose = () => {
  //   setPublishDialogOpen(false);
  // };

  // const navigateToPublish = () => {
  //   handlePublishClose();
  //   navigate(['/project', projectId, 'publish'].join('/'));
  // };

  const handleComplete = () => {
    setProjectCompleteRequested(true);
    dispatch(ProjectActions.updateStatus(projectId, ProjectStatus.Completed));
  };

  useEffect(() => {
    if (!isAnyProjectSaving && isProjectCompleted) {
      handleConfirmClose();
      if (projectCompleteRequested) {
        setProjectCompleteRequested(false);
        // handlePublishOpen();
      }
    }
  }, [isAnyProjectSaving, isProjectCompleted, projectCompleteRequested]);

  return (
    <>
      <SideNavItem
        title={<Trans>{completed ? 'Completed' : 'Complete project'}</Trans>}
        icon={<CircleCheckIcon />}
        onClick={handleConfirmOpen}
        variant={'orange-hover'}
        disabled={disabled || completed}
      />

      <PgConfirmationDialog
        isOpen={confirmDialogOpen}
        disabled={isAnyProjectSaving}
        loading={isAnyProjectSaving}
        confirmContent={
          <>
            <Trans>You are about to mark this project as completed.</Trans>
            <br />
            <Trans>Once completed, all the data will be frozen.</Trans>
            <br />
            <Trans>But, don't worry. You can always re-enable it.</Trans>
          </>
        }
        okButtonText={<Trans>Complete project</Trans>}
        confirmTitle={<Trans>Please, confirm</Trans>}
        onConfirm={handleComplete}
        onClose={handleConfirmClose}
      />

      {/* TODO: later */}
      {/*<PgConfirmationDialog*/}
      {/*  isOpen={publishDialogOpen}*/}
      {/*  confirmContent={*/}
      {/*    <>*/}
      {/*      <ol style={{ textAlign: 'left' }}>*/}
      {/*        <li>*/}
      {/*          <Trans>Get unlimited numbers of perceptions and project ideas on Free Plan</Trans>*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <Trans>Upgrade yourself and your team in the community</Trans>*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <Trans>Involve investors, followers and contributors to your projects</Trans>*/}
      {/*        </li>*/}
      {/*      </ol>*/}
      {/*    </>*/}
      {/*  }*/}
      {/*  okButtonText={<Trans>Publish</Trans>}*/}
      {/*  confirmTitle={*/}
      {/*    <>*/}
      {/*      <Box mb={1}>*/}
      {/*        <CircleCheckIcon2 width={53} height={53} fill={pgColorScheme.blueSuperLight} />*/}
      {/*      </Box>*/}

      {/*      <Typography variant={'h5'} component={'span'}>*/}
      {/*        <span>*/}
      {/*          <Trans>Your project is completed.</Trans>*/}
      {/*        </span>*/}
      {/*        <br />*/}
      {/*        <span>*/}
      {/*          <Trans>Publish it on the PrizHub and</Trans>&nbsp;*/}
      {/*          <span style={{ backgroundColor: alpha(pgColorScheme.blue, 0.1) }}>*/}
      {/*            <Trans>get advantages</Trans>*/}
      {/*          </span>*/}
      {/*        </span>*/}
      {/*      </Typography>*/}
      {/*    </>*/}
      {/*  }*/}
      {/*  onConfirm={navigateToPublish}*/}
      {/*  onClose={handlePublishClose}*/}
      {/*/>*/}
    </>
  );
};
