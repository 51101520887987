import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { validationPatterns, validatorRules } from '../../../utils/form';
import { AuthErrorMessage, ErrorResponse } from '../auth-error-message/component';
import { SocialLoginButtons } from '../social-login-buttons/component';
import { AuthTabs, AuthTabsType, NavigationProps } from '../auth-tabs/component';
import { ReactHookFormText } from '../../form-elements';
import { LoadingButton } from '../../loading-button/component';
import { Alert, Box, Divider, Link, Typography } from '@mui/material';
import { AuthErrorCode } from '../auth-error-message/AuthErrorCode';
import { Auth0Error } from 'auth0-js';
import { AuthService } from '../../../services/auth';
import { Auth0RedirectKey, AuthFormType } from '../../../models/auth0';

interface LoginFormProps extends NavigationProps {
  redirectKey: Auth0RedirectKey;
  onResetPasswordNavigate: () => void;
  beforeSend?: () => void;
  errorMessage?: string;
}

export const LoginForm: React.FC<LoginFormProps> = ({
  redirectKey,
  onLoginNavigate,
  onSignUpNavigate,
  onResetPasswordNavigate,
  errorMessage,
  beforeSend,
}) => {
  const [requestFailureResponse, setRequestFailureResponse] = useState<ErrorResponse | null>(null);
  const [loading, setLoading] = useState(false);

  const { handleSubmit, control } = useForm({
    shouldFocusError: true,
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const loginWithEmailPass = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    handleSubmit(formData => {
      setLoading(true);
      setRequestFailureResponse(null);

      if (beforeSend) beforeSend();

      AuthService.loginWithEmailPassword(formData.email, formData.password, redirectKey, onRequestFailure);
    })();
  };

  const onRequestFailure = (error: Auth0Error) => {
    setLoading(false);

    setRequestFailureResponse({
      errorCode: AuthErrorCode[error?.code as keyof typeof AuthErrorCode] || error?.code,
      errorMessage: error?.error_description || 'Error',
    });
  };

  return (
    <>
      <AuthTabs activeTab={AuthTabsType.Login} onLoginNavigate={onLoginNavigate} onSignUpNavigate={onSignUpNavigate} />

      {errorMessage && (
        <Box mb={1}>
          <Alert severity="error">
            <Typography variant={'body2'} component={'span'}>
              <Trans>{errorMessage}</Trans>
            </Typography>
          </Alert>
        </Box>
      )}

      <AuthErrorMessage
        formType={AuthFormType.Login}
        errorResponse={requestFailureResponse}
        onLoginNavigate={onLoginNavigate}
        onSignUpNavigate={onSignUpNavigate}
        mb={1}
      />

      <Box py={1} />

      <form onSubmit={loginWithEmailPass}>
        <ReactHookFormText
          fieldTitle={'Email *'}
          name={'email'}
          type={'email'}
          control={control}
          disabled={loading}
          rules={{
            ...validatorRules.required('Email is required'),
            ...validatorRules.pattern(validationPatterns.email, 'A valid email is required'),
          }}
        />

        <ReactHookFormText
          fieldTitle={'Password *'}
          name={'password'}
          type={'password'}
          control={control}
          disabled={loading}
          rules={{
            ...validatorRules.required(),
            ...validatorRules.password(),
          }}
        />

        <LoadingButton type={'submit'} style={{ width: '100%', margin: 0 }} loading={loading}>
          <Trans>Log in</Trans>
        </LoadingButton>
      </form>

      <Box textAlign={'center'} mt={1.25}>
        <Link component={'span'} variant={'body2'} underline={'hover'} onClick={onResetPasswordNavigate}>
          <Trans>FORGOT YOUR PASSWORD?</Trans>
        </Link>
      </Box>

      <Box textAlign={'center'} mt={2.25}>
        {onSignUpNavigate && (
          <>
            <Typography variant={'caption'} component={'span'}>
              <Trans>Don’t have an account?</Trans>
            </Typography>
            &nbsp;
            <Link component={'span'} variant={'body2'} underline={'hover'} onClick={onSignUpNavigate}>
              <Trans>CREATE A PRIZ GURU ACCOUNT</Trans>
            </Link>
          </>
        )}
      </Box>

      <Box mt={'auto'} mb={2}>
        <Divider>
          <Typography component={'span'} variant={'body2'}>
            or
          </Typography>
        </Divider>
      </Box>

      <SocialLoginButtons formType={AuthFormType.Login} redirectKey={redirectKey} beforeLogin={beforeSend} />
    </>
  );
};
