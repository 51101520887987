import React, { useState } from 'react';
import {
  alpha,
  Box,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ArrayUtils, SortDirection, stringToHash } from '@priz/shared/src/utils/common';
import { useStyles } from './styles';
import { TablePaginationActions } from './pagination-actions/component';
import { pgColorScheme } from '@priz/shared/src/theme';
import { PgConfirmationDialog } from '../elements/PgConfirmationDialog';
import { Trans } from 'react-i18next';
import { Principle } from '@priz/shared/src/models/tools/forty-principles/principle';
import { MatrixElement } from '@priz/shared/src/models/tools/forty-principles/matrix-element';
import { Domain } from '@priz/shared/src/models/tools/forty-principles/domain';
import { Parameter } from '@priz/shared/src/models/tools/forty-principles/parameter';
import { EntityStatusId } from '@priz/shared/src/models/common/entity-collection-state';
import { CellFields, ColumnToDisplay } from './cell-fields/component';

import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';

export type EditableTableTitle = { label: string; sortParameter: string };

export interface ColumnsToDisplayMap {
  [key: string]: ColumnToDisplay[];
}

export interface EditableTableProps {
  rowsArray: Partial<Principle>[] | MatrixElement[] | Domain[] | Parameter[];
  columnsToDisplayMap: ColumnsToDisplayMap;
  useQuillForProps?: string[];
  titlesArray: EditableTableTitle[];
  onChange: (row, col, val: string | number) => void;
  columnsWidth?: { [name: string]: string };
  rowHash?: string | number;
  onDelete?: (row) => void;
  loadingIds?: EntityStatusId[];
}

const sortRows = (rowsArray, sortByParam: string, order: SortDirection) => {
  return rowsArray.sort(ArrayUtils.sorterByParam(sortByParam, order));
};

export const EditableTable: React.FC<EditableTableProps> = ({
  rowsArray,
  columnsToDisplayMap,
  titlesArray,
  onChange,
  columnsWidth,
  useQuillForProps = [],
  rowHash,
  onDelete,
  loadingIds = [],
}) => {
  const theme = useTheme();
  const styles = useStyles();

  const [orderDirection, setOrderDirection] = React.useState<SortDirection>(SortDirection.ASC);
  const [orderBy, setOrderBy] = React.useState('id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  const isDownMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  const handleChangePage = (_event, newPage) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const changeSortDirection = (sortParameter: string) => {
    setOrderDirection(orderDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC);
    setOrderBy(sortParameter);
  };

  const onChangeHandler = (row, col, val) => {
    if (onChange) {
      onChange(row, col, val);
    }
  };

  const handleDeleteConfirmationOpen = row => {
    setRowToDelete(row);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirmationClose = () => {
    setRowToDelete(null);
    setDeleteConfirmOpen(false);
  };

  const handleDelete = () => {
    if (onDelete && rowToDelete) onDelete(rowToDelete);
    handleDeleteConfirmationClose();
  };

  const renderPagination = () => {
    return (
      <TablePagination
        className={styles.pagination}
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={rowsArray.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
        labelRowsPerPage={'Show:'}
      />
    );
  };

  const renderCellsForEachColumn = (row, rowId) => {
    return (
      <>
        {Object.keys(columnsToDisplayMap).map(rowKey => {
          const columnsToDisplay = columnsToDisplayMap[rowKey];
          const width = columnsWidth && columnsWidth[rowKey] ? columnsWidth[rowKey] : null;

          return (
            <TableCell key={rowKey} width={width}>
              <CellFields
                row={row}
                rowId={rowId}
                columnsToDisplay={columnsToDisplay}
                useQuillForProps={useQuillForProps}
                onChange={(prop, val) => onChangeHandler(row, prop, val)}
              />
            </TableCell>
          );
        })}

        {onDelete && <TableCell width={'5%'}>{renderDeleteButton(row)}</TableCell>}
      </>
    );
  };

  const renderColumnsAsContentOfSingleCell = (row, rowId) => {
    return (
      <TableCell>
        {Object.keys(columnsToDisplayMap).map((rowKey, key) => {
          const columnsToDisplay = columnsToDisplayMap[rowKey];

          return (
            <Box key={rowKey} mb={2}>
              <Typography variant={'subtitle2'} component={'span'}>
                {titlesArray[key]?.label || rowKey}:&nbsp;
              </Typography>

              <CellFields
                row={row}
                rowId={rowId}
                columnsToDisplay={columnsToDisplay}
                useQuillForProps={useQuillForProps}
                onChange={(prop, val) => onChangeHandler(row, prop, val)}
              />
            </Box>
          );
        })}

        {onDelete && renderDeleteButton(row)}
      </TableCell>
    );
  };

  const renderDeleteButton = row => {
    return (
      <IconButton
        onClick={() => {
          handleDeleteConfirmationOpen(row);
        }}
      >
        <TrashIcon fill={pgColorScheme.blue} />
      </IconButton>
    );
  };

  return (
    <>
      <Paper className={styles.root} elevation={3}>
        {rowsArray.length > 10 && (
          <>
            {renderPagination()}
            <Divider />
          </>
        )}

        <TableContainer>
          <Table aria-labelledby="tableTitle" size={'medium'}>
            {!isDownMdBreakpoint && (
              <TableHead>
                <TableRow>
                  {titlesArray.map((item, key) => {
                    return (
                      <TableCell key={key} align={'left'} padding={'normal'}>
                        {item.sortParameter ? (
                          <TableSortLabel
                            active={item.label === orderBy}
                            direction={orderDirection === SortDirection.ASC ? 'asc' : 'desc'}
                            onClick={() => {
                              changeSortDirection(item.sortParameter);
                            }}
                          >
                            <span>{item.label}</span>
                          </TableSortLabel>
                        ) : (
                          <span>{item.label}</span>
                        )}
                      </TableCell>
                    );
                  })}

                  {onDelete && <TableCell width={'5%'} padding={'normal'} />}
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {sortRows(rowsArray, orderBy, orderDirection)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  const rowId = `${typeof row?.id !== 'undefined' ? row.id : stringToHash(JSON.stringify(row))}${
                    rowHash || ''
                  }`;

                  const isLoading = loadingIds.includes(row?.id) || loadingIds.includes(row?.domainPrincipleId);

                  return (
                    <React.Fragment key={rowId}>
                      <TableRow>
                        <TableCell
                          colSpan={titlesArray.length}
                          sx={{ border: 'none', padding: 0, position: 'relative' }}
                        >
                          {isLoading && (
                            <LinearProgress sx={{ position: 'absolute', width: '100%', top: 0, left: 0 }} />
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow
                        hover
                        sx={{
                          verticalAlign: 'top',
                          position: 'relative',
                          transition: 'background-color .15s ease',
                          backgroundColor: isLoading
                            ? `${alpha(pgColorScheme.blue, 0.1)} !important`
                            : pgColorScheme.white,
                        }}
                      >
                        {isDownMdBreakpoint
                          ? renderColumnsAsContentOfSingleCell(row, rowId)
                          : renderCellsForEachColumn(row, rowId)}

                        <TableCell colSpan={titlesArray.length} sx={{ padding: 0 }} />
                      </TableRow>
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        {rowsArray.length > 10 && renderPagination()}
      </Paper>

      <PgConfirmationDialog
        isOpen={deleteConfirmOpen}
        confirmTitle={<Trans>Delete confirmation</Trans>}
        onConfirm={handleDelete}
        onClose={handleDeleteConfirmationClose}
      />
    </>
  );
};
