import { handleActions } from 'redux-actions';
import { DefaultTeamCollection, Team, TeamCollection } from '@priz/shared/src/models/team';
//import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { toObjectMap } from '../../../shared/store/reducer-utils';
import { copyObject } from '@priz/shared/src/utils/common';
import { ArrayUtil } from '../../../shared/utils/array-util';
import { toSuccess } from '../../../shared/store/action-creator';
import { UserContextAction, UserContextActionType } from '../../../security/store/actions';

const teamInstantiator = (payload: Team): Team => payload;

//const setStatuses = (
//  state: TeamCollection,
//  statuses: Partial<EntityCollectionStatus>,
//): TeamCollection => ({
//  ...state,
//  statuses: {
//    ...state.statuses,
//    ...statuses,
//  },
//});

const setTeams = (state: TeamCollection, teams: Team[]): TeamCollection => {
  const teamsMap = toObjectMap<Team>(teams, teamInstantiator);
  const entitiesCopy = { ...state.entities, ...teamsMap };

  const lookupsCopy = copyObject(state.lookups);
  Object.values(teamsMap).forEach((team: Team) => {
    lookupsCopy.byWorkspaceId[team.workspace.id] = ArrayUtil.concatInDistinctArray(
      lookupsCopy.byWorkspaceId[team.workspace.id],
      team.id,
    );
  });

  const statusesCopy = { ...state.statuses, loaded: true, loading: false, error: false };

  return {
    ...state,
    entities: entitiesCopy,
    lookups: lookupsCopy,
    statuses: statusesCopy,
  };
};

export const teamReducers = handleActions(
  {
    [toSuccess(UserContextActionType.UserContext_Load)]: (
      state: TeamCollection,
      action: UserContextAction,
    ): TeamCollection => setTeams(state, action.payload.teams),
  },
  DefaultTeamCollection,
);
