import axios, { AxiosResponse } from 'axios';
import {
  IUimTask,
  IUimUtilization,
  UimFlawCost,
  UimFlawExpectancy,
  UimUtilization,
} from '@priz/shared/src/models/tools/uim';

const createForProjectTasks = (projectId: number): Promise<IUimUtilization> =>
  axios
    .post(`/v1/api/<ws-id>/team/<t-id>/project/${projectId}/uim/project-tasks`)
    .then((response: AxiosResponse<IUimUtilization>) => response.data);

const update = (utilization: UimUtilization): Promise<IUimUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/uim/${utilization.id}`, utilization)
    .then((response: AxiosResponse<IUimUtilization>) => response.data);

const doDelete = (utilizationId: number): Promise<any> =>
  axios
    .delete(`/v1/api/<ws-id>/team/<t-id>/uim/${utilizationId}`)
    .then((response: AxiosResponse<IUimUtilization>) => response.data);

const getAllTasks = (utilizationId: number): Promise<IUimTask[]> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/uim/${utilizationId}/task`)
    .then((response: AxiosResponse<IUimTask[]>) => response.data);

const createTask = (utilizationId: number, description: string): Promise<IUimTask> =>
  axios
    .post(`/v1/api/<ws-id>/team/<t-id>/uim/${utilizationId}/task`, {
      description,
    })
    .then((response: AxiosResponse<IUimTask>) => response.data);

export interface UpdateUimTaskCommand {
  description?: string;
  flawExpectancy?: UimFlawExpectancy;
  flawCost?: UimFlawCost;
}

const updateTask = (utilizationId: number, taskId: number, command: UpdateUimTaskCommand): Promise<IUimTask> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/uim/${utilizationId}/task/${taskId}`, command)
    .then((response: AxiosResponse<IUimTask>) => response.data);

const deleteTask = (utilizationId: number, taskId: number): Promise<any> =>
  axios
    .delete(`/v1/api/<ws-id>/team/<t-id>/uim/${utilizationId}/task/${taskId}`)
    .then((response: AxiosResponse<IUimTask>) => response.data);

const replaceTasks = (
  utilizationId: number,
  descriptionsToCreate: string[],
  idsToDelete: number[],
): Promise<IUimTask> =>
  axios
    .post(`/v1/api/<ws-id>/team/<t-id>/uim/${utilizationId}/replace-tasks`, {
      descriptionsToCreate,
      idsToDelete,
    })
    .then((response: AxiosResponse<IUimTask>) => response.data);

export const UimUtilizationApi = {
  createForProjectTasks,
  update,
  createTask,
  delete: doDelete,
  deleteTask,
  getAllTasks,
  updateTask,
  replaceTasks,
};
