import React, { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { DomainActions } from '../store';
import { InventivePrinciplesSelectors } from '../store/selectors/InventivePrinciplesSelectors';
import { EditableTable } from '../../react/editable-table/component';
import { ReactHookFormText, FormSubmitButton } from '../../react/form-elements';
import { validateOptions } from '@priz/shared/src/utils/form';
import { useForm } from 'react-hook-form';
import { UpdateDomainCommand } from '../services/domain.service';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';

export const DomainsSettings: React.FC = () => {
  const dispatch = useDispatch();

  const domains = useSelector(InventivePrinciplesSelectors.getDomains);
  const isLoaded = useSelector(InventivePrinciplesSelectors.isDomainsLoaded);

  const updatingIds = useSelector(InventivePrinciplesSelectors.getDomainsUpdatingIds);
  const removingIds = useSelector(InventivePrinciplesSelectors.getDomainsRemovingIds);
  const loadingIds = [...updatingIds, ...removingIds];

  const { handleSubmit, control, reset } = useForm({
    shouldFocusError: false,
    defaultValues: {
      domainTitle: '',
    },
  });

  useEffect(() => {
    dispatch(DomainActions.loadDomains());
  }, []);

  const onChangeHandler = useCallback(
    debounce((row, col, val) => {
      const domain = { ...row, [col]: val };

      const command: UpdateDomainCommand = {
        title: domain.title,
        titleZh: domain.titleZh,
        titleEs: domain.titleEs,
      };

      dispatch(DomainActions.updateDomain(domain.id, command));
    }, 500),
    [],
  );

  const saveDomain = data => {
    dispatch(DomainActions.createDomain(data.domainTitle));
    reset();
  };

  const handleDelete = row => {
    dispatch(DomainActions.deleteDomain(row?.id));
  };

  return (
    <Box>
      <Box mb={4}>
        <form onSubmit={handleSubmit(saveDomain)}>
          <Grid container>
            <Grid item xs={12} sm={true}>
              <Box pr={{ xs: 0, sm: 3 }}>
                <ReactHookFormText
                  name={'domainTitle'}
                  control={control}
                  rules={{
                    validate: {
                      ...validateOptions.hasText('Domain title cannot be empty'),
                    },
                  }}
                  wrapperProps={{ mb: 0 }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={'auto'}>
              <Box pt={{ xs: 2, sm: 0.5 }} textAlign={'right'}>
                <FormSubmitButton text={'Create domain'} />
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>

      {!isLoaded && (
        <Box py={10}>
          <LoadingOverlay loading={true} backdropStyles={{ backgroundColor: 'transparent' }} />
        </Box>
      )}

      {isLoaded && (
        <EditableTable
          rowsArray={domains}
          columnsToDisplayMap={{
            id: [{ propName: 'id' }],
            title: [
              { propName: 'title', label: 'en', editable: true },
              { propName: 'titleZh', label: 'cn', editable: true },
              { propName: 'titleEs', label: 'es', editable: true },
            ],
          }}
          titlesArray={[
            { label: 'No.', sortParameter: 'id' },
            { label: 'Title', sortParameter: 'title' },
          ]}
          columnsWidth={{
            id: '5%',
          }}
          onChange={onChangeHandler}
          onDelete={handleDelete}
          loadingIds={loadingIds}
        />
      )}
    </Box>
  );
};
