import { FluxStandardAction } from 'flux-standard-action';
import { AxiosError } from 'axios';

export interface ErrorData {
  code?: number;
  message?: string;
}

interface AnyObj {
  [key: string]: any;
}

export type AnyAction = FluxStandardAction<string, AnyObj, AnyObj>;

export const resolveAxiosErrorResponse = (action: AnyAction): ErrorData => {
  const errorData = (action?.payload || {}) as AxiosError<{ message: string }>;

  const code = errorData?.response?.status;
  const message = errorData?.response?.data?.message;
  const cutMessage = typeof message === 'string' ? message.split(';')[0] : message;

  return {
    code,
    message: cutMessage,
  };
};
