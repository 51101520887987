import React, { useEffect, useState } from 'react';
import { Alert, Box, BoxProps, Grid, IconButton, Skeleton, Tooltip } from '@mui/material';
import { FieldTitle } from '@priz/shared/src/components/form-elements';
import { Trans } from 'react-i18next';
import { CheckCircleOutlineRounded, InfoOutlined, ReplayRounded } from '@mui/icons-material';
import { PendoService } from '@priz/shared/src/services/pendo/pendo.service';
import { GuideCard } from '../guide-card/component';
import { useIsMounted } from '@priz/shared/src/utils/hooks';
import { Guide } from '@priz/shared/src/services/pendo/pendo-model';

interface PendoGuidesProps extends BoxProps {
  pathname: string;
  onView?: () => void;
  onGuidesLoad?: (count: number) => void;
}

export const PendoGuides: React.FC<PendoGuidesProps> = ({ pathname, onView, onGuidesLoad, ...rest }) => {
  const { checkIsMounted } = useIsMounted();

  const [guides, setGuides] = useState<Guide[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  useEffect(() => {
    loadGuides();
  }, [pathname]);

  const loadGuides = () => {
    setIsLoading(true);

    PendoService.getActiveGuides()
      .then(guides => {
        if (checkIsMounted()) {
          setIsLoading(false);
          setIsFailed(false);
          setGuides(guides);

          if (onGuidesLoad) {
            onGuidesLoad(guides.length);
          }
        }
      })
      .catch(() => {
        if (checkIsMounted()) {
          setIsLoading(false);
          setIsFailed(true);
          setGuides([]);

          if (onGuidesLoad) {
            onGuidesLoad(0);
          }
        }
      });
  };

  const showGuide = (guideId: string) => {
    if (onView) {
      onView();
    }

    PendoService.showGuide(guideId);
  };

  if (!guides.length) return null;

  return (
    <Box {...rest}>
      <FieldTitle
        text={
          <>
            <Trans>Guides</Trans>:
          </>
        }
      />

      {isLoading && (
        <>
          <Skeleton animation="wave" height={28} width={'100%'} />
          <Skeleton animation="wave" height={28} width={'90%'} />
        </>
      )}

      {!isLoading && isFailed && (
        <>
          <Grid container spacing={1} alignItems={'center'}>
            <Grid item xs={true}>
              <Alert severity={'error'}>
                <Trans>Couldn't load guides</Trans>
              </Alert>
            </Grid>

            <Grid item xs={'auto'}>
              <Tooltip title={<Trans>Retry</Trans>} placement={'top'} disableInteractive arrow>
                <IconButton size={'medium'} onClick={loadGuides}>
                  <ReplayRounded />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      )}

      {!isLoading && !isFailed && !guides.length && (
        <Alert severity={'info'}>
          <Trans>There are no guides for this page</Trans>
        </Alert>
      )}

      {!isLoading &&
        guides.map((guide, key) => (
          <GuideCard
            key={guide.id}
            mt={key ? 1 : 0}
            title={guide.name}
            onButtonClick={() => showGuide(guide.id)}
            icon={
              guide.isComplete() ? (
                <CheckCircleOutlineRounded color={'success'} sx={{ display: 'block' }} />
              ) : (
                <InfoOutlined color={'disabled'} sx={{ display: 'block' }} />
              )
            }
          />
        ))}
    </Box>
  );
};
