import React from 'react';
import { Box, BoxProps, Container, ContainerProps } from '@mui/material';
import { useStyles } from './styles';

type PageContainerVariant = 'fullPage' | 'narrow' | 'clear';

const resolveProps = (
  variant: PageContainerVariant,
): {
  variantScrollContainerProps?: BoxProps;
  variantContainerProps?: Partial<ContainerProps>;
} => {
  switch (variant) {
    case 'fullPage':
      return {
        variantScrollContainerProps: {
          pt: { xs: 3 },
          px: { xs: 0, md: 1, lg: 2 },
          pb: { xs: 8 },
        },
        variantContainerProps: {
          maxWidth: false,
        },
      };

    case 'narrow':
      return {
        variantScrollContainerProps: {
          pt: { xs: 4, sm: 8 },
        },
        variantContainerProps: {
          maxWidth: 'md',
        },
      };

    case 'clear':
      return {
        variantContainerProps: {
          maxWidth: false,
          sx: {
            px: { xs: 0 },
          },
        },
      };

    default:
      return {};
  }
};

export interface PageContainerProps {
  variant?: PageContainerVariant;
  scrollContainerProps?: BoxProps;
  containerProps?: Partial<ContainerProps>;
}

export const PageContainer: React.FC<PageContainerProps> = ({
  variant = 'fullPage',
  scrollContainerProps,
  containerProps,
  children,
}) => {
  const styles = useStyles();
  const { variantScrollContainerProps, variantContainerProps } = resolveProps(variant);

  return (
    <div className={styles.root}>
      {children[0]}

      <div className={styles.contentContainer}>
        <Box className={styles.scrollContainer} {...variantScrollContainerProps} {...scrollContainerProps}>
          <Container className={styles.content} {...variantContainerProps} {...containerProps}>
            {children[1]}
          </Container>

          {children[2]}
        </Box>
      </div>
    </div>
  );
};
