import React, { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Box, BoxProps, Typography } from '@mui/material';
import { useStyles } from './styles';

export interface FieldTitleProps extends BoxProps {
  text?: string | ReactNode;
}

export const FieldTitle: React.FC<FieldTitleProps> = ({ text, ...rest }) => {
  const styles = useStyles();

  if (!text) return null;

  return (
    <Box mb={1} {...rest}>
      <Typography variant="body2" component="span" className={styles.fieldTitle}>
        {typeof text === 'string' ? <Trans>{text}</Trans> : text}
      </Typography>
    </Box>
  );
};
