import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { DataSetEventType, NodeProps } from '../../../models/vis-network';
import { Network } from '../../../lib/vis/declarations';
import { DataSet } from 'vis-data';
import { bindNetworkEvents, unbindNetworkEvents, VisNetworkEvents } from '../../network-diagram/utils';
import { ElementViewerPopper, ElementViewerPopperBaseProps } from '../element-viewer-popper/component';
import { ElementsViewerContext } from '../elements-viewer-context/component';

export interface VisNodeViewerBaseProps extends ElementViewerPopperBaseProps {
  network: Network;
  nodesDataSet: DataSet<NodeProps>;
  container: HTMLElement;
}

interface VisNodeViewerProps extends VisNodeViewerBaseProps {
  contentResolver: (nodeId: string) => ReactNode;
}

export const VisNodeViewer: React.FC<VisNodeViewerProps> = ({
  contentResolver,
  network,
  container,
  nodesDataSet,
  ...rest
}) => {
  const { setRefresh } = useContext(ElementsViewerContext);

  const [nodeId, setNodeId] = useState<string>();
  const [position, setPosition] = useState<Partial<DOMRect>>({});
  const [content, setContent] = useState<ReactNode>(null);

  useEffect(() => {
    nodesDataSet.on('*', dataUpdateHandler);

    return () => {
      nodesDataSet.off('*', dataUpdateHandler);
    };
  }, [nodeId]);

  useEffect(() => {
    if (nodeId) {
      setContent(contentResolver(nodeId));
    } else {
      setContent(null);
    }
  }, [nodeId]);

  useEffect(() => {
    resolvePosition();
  }, [nodeId]);

  useEffect(() => {
    const events: Partial<VisNetworkEvents> = {
      hoverNode: data => {
        if (data.node) {
          setNodeId(data.node as string);
        }
      },
      blurNode: () => {
        reset();
      },
      doubleClick: () => {
        reset();
      },
      controlNodeDragging: () => {
        reset();
      },
      dragging: () => {
        resolvePosition();
      },
      zoom: () => {
        resolvePosition();
      },
    };

    if (network) {
      bindNetworkEvents(network, events);
    }

    return () => {
      if (network) {
        unbindNetworkEvents(network, events);
      }
    };
  }, [network, nodeId]);

  const resolvePosition = () => {
    if (nodeId && nodesDataSet.get(nodeId)) {
      const boundingBox = network.getBoundingBox(nodeId);
      const bbLeftTopDom = network.canvasToDOM({ x: boundingBox.left, y: boundingBox.top });
      const bRightBottomDom = network.canvasToDOM({ x: boundingBox.right, y: boundingBox.bottom });
      const containerRect = container.getBoundingClientRect();

      setPosition({
        top: bbLeftTopDom.y + containerRect.top,
        left: bbLeftTopDom.x + containerRect.left,

        bottom: bRightBottomDom.y + containerRect.bottom,
        right: bRightBottomDom.x + containerRect.right,

        height: bRightBottomDom.y - bbLeftTopDom.y,
        width: bRightBottomDom.x - bbLeftTopDom.x,
      });
    }
  };

  const dataUpdateHandler = (e: DataSetEventType) => {
    if (e === 'update' && nodeId) {
      setContent(contentResolver(nodeId));
      resolvePosition();
    } else {
      setContent(null);
    }
  };

  const reset = () => {
    setNodeId(undefined);
  };

  if (nodeId) {
    setRefresh(() => {
      setContent(contentResolver(nodeId));
      resolvePosition();
    });
  } else {
    setRefresh(() => {
      return;
    });
  }

  if (!nodeId || !position || !content) return null;

  return <ElementViewerPopper content={content} position={position} onClose={reset} {...rest} />;
};
