import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Hidden } from '@mui/material';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { ToolTitle } from '../../tools/tool-title/component';
import { Trans } from 'react-i18next';
import { useStyles as useApaStyles } from '../styles';
import { ProjectSelector, ToolUtilizationSelector } from '../../project/store/selectors';
import { ApaResult } from '../../project/project-report/result';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { ToolUtilizationActions, ToolUtilizationActionType } from '../../project/store/actions/tools.actions';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
// import { ApaResult } from '../apa-result/component';

interface ApaReportProps {
  utilizationId: number;
  projectId?: number;
}

export const ApaReport: React.FC<ApaReportProps> = ({ utilizationId, projectId }) => {
  const apaStyles = useApaStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ToolUtilizationActions.loadTool(utilizationId, projectId));
  }, [utilizationId, projectId]);

  const apaUtilization = useSelector(ToolUtilizationSelector.getApaUtilization(utilizationId));
  const isProjectLocked = useSelector(ProjectSelector.isProjectLockedById(projectId));
  const isCompletenessUpdating = useSelector(
    ToolUtilizationSelector.isPendingByActionType(utilizationId, ToolUtilizationActionType.UpdateCompleteness),
  );

  const isDisabled = !!apaUtilization?.completed || isCompletenessUpdating || isProjectLocked;

  if (!apaUtilization) return null;

  return (
    <>
      <ContentContainer>
        <PageTitleWithDocLink
          titleComponent={
            <ToolTitle
              tool={apaUtilization}
              initialTitle={'Action Preventing Action (APA)'}
              preset={'large'}
              editable={!isDisabled}
            />
          }
          oneLiner={<Trans>Use APA to search for what your customer wants</Trans>}
          // docsUrl={'https://www.priz.guru/knowledge-base/#/'}
        />

        <Grid container>
          <Grid item xs={true}>
            <ApaResult utilization={apaUtilization} />
          </Grid>

          <Hidden smDown>
            <Grid item>
              <Box ml={3} className={apaStyles.rightPane} />
            </Grid>
          </Hidden>
        </Grid>
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={projectId}
          utilization={apaUtilization}
          workingFlowProps={{ nextVariant: 'ideas' }}
          disabled={isDisabled}
        />
      </ContentFooter>
    </>
  );
};
