import React, { CSSProperties } from 'react';
import { useStyles } from './styles';

interface InsufficientIconProps {
  styleProps?: CSSProperties;
  onClick?: () => void;
}

export const InsufficientIcon: React.FC<InsufficientIconProps> = ({ styleProps, onClick, ...rest }) => {
  const styles = useStyles();

  return (
    <div
      className={`${styles.arrow} ${styles.insufficient}`}
      onClick={() => {
        if (onClick) onClick();
      }}
      style={{
        ...styleProps,
      }}
      {...rest}
    />
  );
};
