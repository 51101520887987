import { createSelector, Selector } from 'reselect';
import { EntityMap } from '@priz/shared/src/models/common/entity-collection-state';
import { AppState } from '../../../store/app.state';
import { EbsIdea, EbsIdeaLookups, EbsIdeasStatuses } from '@priz/shared/src/models/tools/ebs';

const ebsIdeaCollectionSelector = (state: AppState) => state.ebsIdeas;

const ebsIdeaEntitiesSelector: Selector<AppState, EntityMap<EbsIdea>> = createSelector(
  ebsIdeaCollectionSelector,
  collection => collection.entities,
);

const ebsIdeaLookupsSelector: Selector<AppState, EbsIdeaLookups> = createSelector(
  ebsIdeaCollectionSelector,
  collection => collection.lookups,
);

const ebsIdeasStatusesSelector: Selector<AppState, EbsIdeasStatuses> = createSelector(
  ebsIdeaCollectionSelector,
  collection => collection.statuses,
);

const getIdsByUtilizationId = (utilizationId: number): Selector<AppState, number[]> =>
  createSelector(ebsIdeaLookupsSelector, lookups => lookups.byUtilizationId[utilizationId] || []);

const loadedByUtilizationId = (utilizationId: number): Selector<AppState, boolean> =>
  createSelector(ebsIdeasStatusesSelector, statuses => statuses.byUtilizationId[utilizationId]?.loaded || false);

const loadingByUtilizationId = (utilizationId: number): Selector<AppState, boolean> =>
  createSelector(ebsIdeasStatusesSelector, statuses => statuses.byUtilizationId[utilizationId]?.loading || false);

const getAllByUtilizationId = (utilizationId: number): Selector<AppState, EbsIdea[]> =>
  createSelector([ebsIdeaEntitiesSelector, getIdsByUtilizationId(utilizationId)], (entities: EntityMap<EbsIdea>, ids) =>
    ids.map(id => entities[id]),
  );

export const EbsIdeaSelectors = {
  getAllByUtilizationId,
  loadedByUtilizationId,
  loadingByUtilizationId,
};
