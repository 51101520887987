import React from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

interface TitleTableRowProps {
  title: string;
  columnsCount: number;
  renderEmptyCells?: boolean;
}

export const TitleTableRow: React.FC<TitleTableRowProps> = ({ title, columnsCount, renderEmptyCells }) => {
  return (
    <TableRow>
      <TableCell align="left" colSpan={renderEmptyCells ? 1 : columnsCount + 1}>
        <Typography variant={'button'}>
          <Trans>{title}</Trans>
        </Typography>
      </TableCell>

      {renderEmptyCells && [...new Array(columnsCount)].map((_item, key) => <TableCell key={key} />)}
    </TableRow>
  );
};
