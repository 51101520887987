import { ToolType } from '@priz/shared/src/models/tools';
import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../store/app.state';
import { WorkspaceSelectors } from './workspace.selectors';
import { ToolUtilizationSelector } from '../../../project/store/selectors';
import { WorkspaceMembersSelectors } from './workspace-members.selectors';
import { RrmUtilization } from '@priz/shared/src/models/tools/rrm/rrm-utilization';
import { RrmGoalSelector } from '../../../rrm/store/selectors';
import { UimTaskSelector } from '../../../uim/store/selectors/uim-task.selectors';
import { FiveWhysCausesSelector } from '../../../five-whys/store/selectors/five-whys-causes.selectors';
import { EbsIdeaSelectors } from '../../../ebs/store/selectors';
import { CecUtilization } from '@priz/shared/src/models/tools/cec';
import { PMapUtilization } from '@priz/shared/src/models/tools/pmap';
import { ApaActionSelectors } from '../../../apa/store/selectors/apa-action.selectors';
import { IdeasSelectors } from '../../../idea/store';

const isToolEnabled = (toolType: ToolType, projectId?: number): Selector<AppState, boolean> =>
  createSelector(
    WorkspaceSelectors.getApplicableFeatureSet(projectId),
    featureSet => featureSet.Tools[toolType].enabled,
  );

const isMembersLimitHit = (projectId?: number): Selector<AppState, boolean> =>
  createSelector(
    [WorkspaceMembersSelectors.getAll, WorkspaceSelectors.getApplicableFeatureSet(projectId)],
    (members, featureSet) => featureSet.MaxWorkspaceMembers && members?.length >= featureSet.MaxWorkspaceMembers,
  );

const isIdeasLimitHit = (projectId: number): Selector<AppState, boolean> =>
  createSelector(
    [IdeasSelectors.getAllByProjectId(projectId), WorkspaceSelectors.getApplicableFeatureSet(projectId)],
    (ideas, featureSet) => featureSet.MaxIdeas && ideas && Object.keys(ideas).length >= featureSet.MaxIdeas,
  );

const isRrmLimitHit = (utilization: RrmUtilization): Selector<AppState, boolean> =>
  createSelector(
    [
      RrmGoalSelector.getGoalById(utilization && utilization.goal.id),
      WorkspaceSelectors.getApplicableFeatureSet(utilization.project?.id),
    ],
    (goal, featureSet) => featureSet.Tools.RRM.maxItems && goal?.perceptions?.length >= featureSet.Tools.RRM.maxItems,
  );

const isUimLimitHit = (utilizationId: number, projectId?: number): Selector<AppState, boolean> =>
  createSelector(
    [UimTaskSelector.getAllByUtilizationId(utilizationId), WorkspaceSelectors.getApplicableFeatureSet(projectId)],
    (tasks, featureSet) => featureSet.Tools.UIM.maxItems && tasks?.length >= featureSet.Tools.UIM.maxItems,
  );

const isFiveWhysLimitHit = (utilizationId: number, projectId?: number): Selector<AppState, boolean> =>
  createSelector(
    [
      FiveWhysCausesSelector.getAllByUtilizationId(utilizationId),
      WorkspaceSelectors.getApplicableFeatureSet(projectId),
    ],
    (causes, featureSet) =>
      featureSet.Tools.FIVE_WHYS.maxItems && causes?.length >= featureSet.Tools.FIVE_WHYS.maxItems,
  );

const isEbsLimitHit = (utilizationId: number, projectId?: number): Selector<AppState, boolean> =>
  createSelector(
    [EbsIdeaSelectors.getAllByUtilizationId(utilizationId), WorkspaceSelectors.getApplicableFeatureSet(projectId)],
    (ideas, featureSet) => featureSet.Tools.EBS.maxItems && ideas?.length >= featureSet.Tools.EBS.maxItems,
  );

const isCecLimitHit = (utilizationId: number, projectId?: number): Selector<AppState, boolean> =>
  createSelector(
    [
      ToolUtilizationSelector.getById(utilizationId, ToolType.CEC),
      WorkspaceSelectors.getApplicableFeatureSet(projectId),
    ],
    (utilization: CecUtilization, featureSet) =>
      featureSet.Tools.CEC.maxItems && utilization?.diagramData?.nodes?.length >= featureSet.Tools.CEC.maxItems,
  );

const isPMapLimitHit = (utilizationId: number, projectId?: number): Selector<AppState, boolean> =>
  createSelector(
    [
      ToolUtilizationSelector.getById(utilizationId, ToolType.P_MAP),
      WorkspaceSelectors.getApplicableFeatureSet(projectId),
    ],
    (utilization: PMapUtilization, featureSet) =>
      featureSet.Tools.P_MAP.maxItems && utilization?.data?.perceptions?.length >= featureSet.Tools.P_MAP.maxItems,
  );

const isApaLimitHit = (utilizationId: number, projectId?: number): Selector<AppState, boolean> =>
  createSelector(
    [ApaActionSelectors.getAllByUtilizatonId(utilizationId), WorkspaceSelectors.getApplicableFeatureSet(projectId)],
    (actions, featureSet) => featureSet.Tools.APA.maxItems && actions?.length >= featureSet.Tools.APA.maxItems,
  );

const isDataCrowdSourcingEnabled = (projectId?: number): Selector<AppState, boolean> =>
  createSelector(WorkspaceSelectors.getApplicableFeatureSet(projectId), featureSet => featureSet.DataCrowdSourcing);

export const FeatureSetSelectors = {
  isToolEnabled,
  isMembersLimitHit,
  isIdeasLimitHit,
  isRrmLimitHit,
  isUimLimitHit,
  isFiveWhysLimitHit,
  isEbsLimitHit,
  isCecLimitHit,
  isPMapLimitHit,
  isApaLimitHit,
  isDataCrowdSourcingEnabled,
};
