import mixpanel from 'mixpanel-browser';
import { environment } from '../../../../environments/environment';
import { UserProperties } from '../../model/user-properties';
import { LocalStorageKey, LocalStorageService } from '../../../local-storage';

const init = (): void => {
  mixpanel.init(environment.mixpanelToken, { debug: true, track_pageview: true });
};

const setUserIdentity = (identity: string): void => {
  mixpanel.identify(identity);
};

const resetUserIdentity = (): void => {
  mixpanel.reset();
};

const setUserProperties = (properties: UserProperties): void => {
  const propertyMap = {
    $created: properties.createdDate,
    $email: properties.email,
    $first_name: properties.firstName,
    $last_name: properties.lastName,
    $name: `${properties.firstName} ${properties.lastName}`,
  };

  mixpanel.people.set(propertyMap);
};

const track = (event: string, parameters: { [key: string]: unknown }): Promise<any> => {
  const lsUser = LocalStorageService.getItem(LocalStorageKey.User);
  const user = lsUser ? JSON.parse(lsUser) : null;

  if (user) {
    setUserIdentity(user.id.toString());
  }

  return new Promise(resolve => {
    mixpanel.track(event, parameters, response => {
      resolve(response);
    });
  });
};

export const MixpanelService = {
  init,
  setUserIdentity,
  resetUserIdentity,
  setUserProperties,
  track,
};
