import { Identifiable } from '../common/entity-collection-state';
import { IAuditableEntity } from '../common/auditable-entity';
import { IdRef } from '../common/id-ref';

export enum ApiKeyStatus {
  Active = 'Active',
  Expired = 'Expired',
}

export interface ApiKey extends IAuditableEntity, Identifiable {
  title?: string;
  key: string;
  status: ApiKeyStatus;
  user: IdRef;
}
