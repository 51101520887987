import { EntityCollectionStatus, EntityMap, LookupMap } from '../common/entity-collection-state';
import { ProjectAssignee } from './project-assignee';

export interface ProjectAssigneeCollection {
  entities: EntityMap<ProjectAssignee>;

  lookups: {
    byProjectId: LookupMap;
  };

  statuses: EntityCollectionStatus;
}

export const DefaultProjectAssigneeCollection: ProjectAssigneeCollection = {
  entities: {},

  lookups: {
    byProjectId: {},
  },

  statuses: {},
};
