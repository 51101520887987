import { FluxStandardAction } from 'flux-standard-action';
import { IRank, Rank } from '@priz/shared/src/models/tools/rrm';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { ToolType } from '@priz/shared/src/models/tools';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { createPgAction } from '../../../shared/store/action-creator';
import { ActionMeta, RouterActionMeta } from '../../../shared/store/types';
import { UimUtilization } from '@priz/shared/src/models/tools/uim';
import { IRrmUtilization, RrmUtilization } from '@priz/shared/src/models/tools/rrm/rrm-utilization';
import { RrmUtilizationApi, RrmUtilizationCommand, StartRankingResult, UpdateRankResult } from '../../services';
import { NavigateFunction } from 'react-router-dom';
import { ToolsUtils } from '../../../tools/utils';

export enum RrmUtilizationActionType {
  Create = 'rrmUtilization/create',
  Update = 'rrmUtilization/update',
  Delete = 'rrmUtilization/delete',
  StartRanking = 'rrmUtilization/startRanking',
  RankRecord = 'rrmUtilization/ranking/record',
  UtilizationSetState = 'rrmUtilization/setState',
  SkipSmart = 'rrmUtilization/skipSmart',
}

interface RrmUtilizationMeta extends ActionMeta, RouterActionMeta {
  projectId?: number;
  utilizationId?: number;
  sourceUtilizationId?: number;
  goalSourceUtilizationId?: number;
  goalId?: number;
  nextEmptyRankId?: number;
  percentageComplete?: number;
  smart?: boolean;
  rankId?: boolean;
  utilization?: UimUtilization;
}

type RrmUtilizationPayload = IRrmUtilization | IRank | IRank[] | UpdateRankResult | StartRankingResult;

export type RrmUtilizationAction = FluxStandardAction<string, RrmUtilizationPayload, RrmUtilizationMeta>;

const create = createPgAction(
  RrmUtilizationActionType.Create,
  RrmUtilizationApi.create,
  (command: RrmUtilizationCommand, projectId?: number, navigate?: NavigateFunction) => ({
    projectId,
    command,
    navigate,
  }),
  (payload: RrmUtilizationPayload, meta: RrmUtilizationMeta) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_USED, {
      project_id: meta.projectId,
      tool_type: ToolType.RRM,
    });

    if (meta.navigate) {
      meta.navigate(ToolsUtils.resolvePathByUtilization(payload as RrmUtilization));
    }
  },
);

const update = createPgAction(
  RrmUtilizationActionType.Update,
  RrmUtilizationApi.update,
  (utilization: UimUtilization) => ({ utilization }),
);

const doDelete = createPgAction(
  RrmUtilizationActionType.Delete,
  RrmUtilizationApi.delete,
  (utilizationId: number, projectId?: number, navigate?: NavigateFunction) => ({ projectId, utilizationId, navigate }),
  (_payload: RrmUtilizationPayload, meta: RrmUtilizationMeta) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_DELETED, {
      project_id: meta.projectId,
      tool_type: ToolType.RRM,
      utilization_id: meta.projectId,
    });

    if (meta.navigate) {
      meta.navigate(ToolsUtils.resolveListPath(meta.projectId));
    }
  },
);

const recordRank = createPgAction(
  RrmUtilizationActionType.RankRecord,
  RrmUtilizationApi.updateRank,
  (
    utilizationId: number,
    updatedRank: Rank,
    smart: boolean,
    goalId: number,
    goalSourceUtilizationId: number,
    projectId: number,
  ) => ({
    goalId,
    projectId,
    updatedRank,
    utilizationId,
    goalSourceUtilizationId,
    smart,
  }),
);

const skipSmartRank = createPgAction(
  RrmUtilizationActionType.SkipSmart,
  RrmUtilizationApi.skipSmartRank,
  (utilizationId: number, rankId: number, goalId: number, projectId?: number) => ({
    goalId,
    projectId,
    rankId,
    utilizationId,
  }),
);

const startRanking = createPgAction(
  RrmUtilizationActionType.StartRanking,
  RrmUtilizationApi.startRanking,
  (utilizationId: number, smart: boolean, goalId: number, projectId?: number) => ({
    projectId,
    utilizationId,
    smart,
    goalId,
  }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.RRM,
      utilization_id: utilizationId,
    });
  },
);

const setUtilizationModuleState = createPgAction(
  RrmUtilizationActionType.UtilizationSetState,
  () => new Promise(resolve => resolve({})),
  (utilizationId: number, nextEmptyRankId?: number, percentageComplete?: number) => ({
    nextEmptyRankId,
    percentageComplete,
    utilizationId,
  }),
);

export const RrmUtilizationActions = {
  create,
  update,
  delete: doDelete,
  recordRank,
  skipSmartRank,
  setUtilizationModuleState,
  startRanking,
};
