import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { handleActions } from 'redux-actions';
import { UserSettingsAction, UserSettingsActionType } from '../actions/user-settings.actions';
import { DefaultUserSettingsState, UserSettingsState } from '../model';

export const userSettingsReducers = handleActions(
  {
    // Fetch

    [toStarted(UserSettingsActionType.Fetch)]: (state: UserSettingsState): UserSettingsState => ({
      ...state,
      loading: true,
      error: false,
    }),

    [toFailed(UserSettingsActionType.Fetch)]: (state: UserSettingsState): UserSettingsState => ({
      ...state,
      error: true,
      loading: false,
      loaded: false,
    }),

    [toSuccess(UserSettingsActionType.Fetch)]: (
      state: UserSettingsState,
      action: UserSettingsAction,
    ): UserSettingsState => ({
      ...state,
      loading: false,
      loaded: true,
      error: false,
      settings: { ...state.settings, ...action.payload.settings },
    }),

    // Update

    [toStarted(UserSettingsActionType.Update)]: (state: UserSettingsState): UserSettingsState => ({
      ...state,
      updated: false,
      updating: true,
      error: false,
    }),

    [toFailed(UserSettingsActionType.Update)]: (state: UserSettingsState): UserSettingsState => ({
      ...state,
      updated: false,
      updating: false,
      error: true,
    }),

    [toSuccess(UserSettingsActionType.Update)]: (
      state: UserSettingsState,
      action: UserSettingsAction,
    ): UserSettingsState => ({
      ...state,
      updated: true,
      updating: false,
      error: false,
      settings: { ...state.settings, ...action.payload.settings },
    }),
  },
  DefaultUserSettingsState,
);
