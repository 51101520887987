import { createStyles, makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    rootUser: {
      flexDirection: 'row-reverse',

      ['& $avatar']: {
        marginLeft: 20,
      },

      ['& $message']: {
        ['&::before']: {
          right: 'auto',
          left: 'calc(100% - 8px)',
          borderWidth: '10px 0px 10px 20px',
          borderLeftColor: pgColorScheme.gray50,
        },
      },
    },
    rootAssistant: {
      ['& $avatar']: {
        backgroundColor: pgColorScheme.blue,
        color: pgColorScheme.white,
        marginRight: 20,
      },

      ['& $message']: {
        ['&::before']: {
          right: 'calc(100% - 8px)',
          left: 'auto',
          borderWidth: '10px 20px 10px 0px',
          borderRightColor: pgColorScheme.gray50,
        },
      },
    },
    avatar: {
      width: 40,
      height: 40,
      flex: '0 0 auto',
    },
    message: {
      position: 'relative',
      borderRadius: 16,
      whiteSpace: 'pre-line',
      wordBreak: 'break-word',
      backgroundColor: pgColorScheme.gray50,

      ['&::before']: {
        content: '""',
        position: 'absolute',
        top: 8,
        bottom: 'auto',
        borderStyle: 'solid',
        boxSizing: 'border-box',
        borderColor: 'transparent',
      },
    },

    [theme.breakpoints.down('sm')]: {
      avatar: {
        width: 36,
        height: 36,
      },
      message: {
        borderRadius: 8,

        ['&::before']: {
          top: 6,
        },
      },
      rootUser: {
        ['& $avatar']: {
          marginLeft: 10,
        },

        ['& $message']: {
          ['&::before']: {
            left: 'calc(100% - 3px)',
            borderWidth: '12px 0 12px 10px',
          },
        },
      },
      rootAssistant: {
        ['& $avatar']: {
          marginRight: 10,
        },

        ['& $message']: {
          ['&::before']: {
            right: 'calc(100% - 3px)',
            borderWidth: '12px 10px 12px 0px',
          },
        },
      },
    },
  }),
);
