import React from 'react';
import { Box, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import CurrencyFormat from 'react-currency-format';
import { Trans } from 'react-i18next';
import { format } from 'date-fns';
import { useStyles } from './styles';
import { Invoice } from '@priz/shared/src/models/billing';
import { Plan, PlanInterval, SubscriptionDetails, Workspace, WorkspaceMember } from '@priz/shared/src/models/workspace';
import { PlanUpgradeButton } from '../plan-upgrade-button/component';
import { CancelSubscriptionButton } from '../cancel-subscription-button/component';
import { ManageSeatsButton } from '../manage-seats-button/component';
import { SeatsBar } from '../seats-bar/component';
import { FinancialsManagementType } from '@priz/shared/src/models/workspace/financials-management-type.enum';
import { PlanLevelUtils } from '../../workspace/services';
import { SubscriptionStatusIndicator } from '../subscription-status-indicator/components';

interface BillingSummaryProps {
  workspace: Workspace;
  members?: WorkspaceMember[];
  invoice?: Invoice;
  subDetails?: SubscriptionDetails;
  currentPlan?: Plan;
  onSubscriptionUpdate?: () => void;
}

export const BillingSummary: React.FC<BillingSummaryProps> = ({
  invoice,
  subDetails,
  currentPlan,
  members,
  workspace,
  onSubscriptionUpdate,
}) => {
  const styles = useStyles();

  if (!invoice || !subDetails || !currentPlan) {
    return null;
  }

  return (
    <Box>
      <Box mb={2}>
        <Typography variant={'h6'}>
          <Trans>Summary</Trans>
        </Typography>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="stretch" spacing={3}>
          <Grid item xs={4}>
            <Card variant="outlined" className={styles.summaryCard}>
              <CardHeader subheader={'Next bill amount'} />
              <CardContent className={styles.cardContent}>
                <Typography gutterBottom variant="h5" component="div">
                  <CurrencyFormat
                    value={invoice.total / 100}
                    displayType={'text'}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </Typography>
                {invoice.total != invoice.subtotal && (
                  <Typography gutterBottom component="div">
                    <CurrencyFormat
                      value={invoice.subtotal / 100}
                      displayType={'text'}
                      prefix={'$'}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />{' '}
                    before discount
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card variant="outlined" className={styles.summaryCard}>
              <CardHeader subheader={'Next payment due'} />
              <CardContent className={styles.cardContent}>
                <Typography gutterBottom variant="h5" component="div">
                  {format(subDetails.subscription.currentPeriodEnd * 1000, 'MMM d yyyy')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card variant="outlined" className={styles.summaryCard}>
              <CardHeader
                subheader={
                  <Box>
                    <Grid container justifyContent={'flex-start'} alignItems={'center'} spacing={1}>
                      <Grid item>
                        <Trans>Subscription</Trans> (<strong>{currentPlan.name}</strong>)
                      </Grid>

                      <Grid item>
                        <SubscriptionStatusIndicator status={workspace.subscriptionStatus} />
                      </Grid>

                      <Grid item marginLeft={'auto'}>
                        <PlanUpgradeButton variant={'outlined'} color={'primary'} size={'small'} />
                      </Grid>
                    </Grid>
                  </Box>
                }
              />

              <CardContent className={styles.cardContent}>
                <Typography gutterBottom component="div">
                  <CurrencyFormat
                    value={currentPlan.cost}
                    displayType={'text'}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                  {` per seat / ${currentPlan.interval === PlanInterval.Yearly ? 'year' : 'month'}`}
                </Typography>

                <Box>
                  <SeatsBar workspace={workspace} members={members} />

                  <Box textAlign={'right'} mt={1}>
                    <ManageSeatsButton
                      disabled={
                        workspace.financialsManagementType != FinancialsManagementType.Self ||
                        PlanLevelUtils.isLevelPersonalOrPro(workspace.type)
                      }
                    />
                    <CancelSubscriptionButton
                      onCancellation={onSubscriptionUpdate}
                      disabled={workspace.financialsManagementType != FinancialsManagementType.Self}
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
