import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  indicatorsGroup: {
    '&:not(:last-child)': {
      marginRight: 10,
    },
  },

  maxItemsValue: {
    'color': pgColorScheme.gray,
    'display': 'flex',
    'alignItems': 'center',
    'cursor': 'help',

    '& .MuiSvgIcon-root': {
      fontSize: 16,
      lineHeight: 1,
    },

    '& .MuiTypography-root': {
      fontWeight: 'bold',
      lineHeight: 1,
    },
  },

  activeValue: {
    color: pgColorScheme.green,
  },

  disabledValue: {
    color: pgColorScheme.textGray,
  },

  numberValue: {
    color: pgColorScheme.textGray,
    fontWeight: 'bold',
    lineHeight: 1,
  },
});
