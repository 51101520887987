import { AuditableEntity, IAuditableEntity } from '@priz/shared/src/models/common/auditable-entity';
import { Identifiable } from '@priz/shared/src/models/common/entity-collection-state';
import { Conversation } from './conversation';
import { IdRef } from '@priz/shared/src/models/common/id-ref';

export interface IChat extends IAuditableEntity, Identifiable {
  workspace: IdRef;
  project: IdRef;
  user: IdRef;
  name?: string;
  shared?: boolean;
  conversation: Conversation;
}

export class Chat extends AuditableEntity implements IChat {
  public id: number;
  public workspace: IdRef;
  public project: IdRef;
  public user: IdRef;
  public name?: string;
  public shared?: boolean;
  public conversation: Conversation;

  constructor(json: IChat) {
    super(json);

    this.id = json.id;
    this.user = json.user;
    this.project = json.project;
    this.workspace = json.workspace;
    this.name = json.name;
    this.shared = json.shared;
    this.conversation = json.conversation;
  }
}
