import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions } from '../../../user/store/actions/user.actions';
import { UserSelectors } from '../../../user/store/selectors/user.selectors';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { useForm } from 'react-hook-form';
import { ReactHookFormText, FormResetButton } from '../../../react/form-elements';
import { LoadingButton } from '@priz/shared/src/components/loading-button/component';
import { validatorRules, validateOptions } from '@priz/shared/src/utils/form';

export const PasswordForm: React.FC = () => {
  const dispatch = useDispatch();

  const isPasswordUpdating = useSelector(UserSelectors.isPasswordUpdating);

  const { handleSubmit, getValues, trigger, reset, control } = useForm({
    shouldFocusError: false,
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
  });

  const save = data => {
    dispatch(UserActions.updateCurrentUserPassword(data.repeatPassword));
    reset();
  };

  return (
    <form onSubmit={handleSubmit(save)}>
      <ReactHookFormText
        name={'password'}
        fieldTitle={'Password'}
        type={'password'}
        control={control}
        rules={{
          ...validatorRules.required(),
          ...validatorRules.password(),
          validate: {
            ...validateOptions.trigger(trigger, 'repeatPassword'),
          },
        }}
        disabled={isPasswordUpdating}
      />

      <ReactHookFormText
        name={'repeatPassword'}
        fieldTitle={'Repeat password'}
        type={'password'}
        control={control}
        rules={{
          ...validatorRules.required(),
          validate: {
            ...validateOptions.match(() => getValues().password, 'Passwords does not match'),
          },
        }}
        disabled={isPasswordUpdating}
      />

      <ButtonsWrap>
        <FormResetButton disabled={isPasswordUpdating} onClick={reset} />

        <LoadingButton disabled={isPasswordUpdating} loading={isPasswordUpdating} type={'submit'} />
      </ButtonsWrap>
    </form>
  );
};
