import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApaUtilization } from '@priz/shared/src/models/tools/apa';
import { ApaViewer } from '@priz/shared/src/components/tools-viewers';
import { ApaActionSelectors } from '../../../apa/store/selectors/apa-action.selectors';
import { ApaActionActions } from '../../../apa/store/actions';

interface ApaResultProps {
  utilization: ApaUtilization;
  compact?: boolean;
}

export const ApaResult: React.FC<ApaResultProps> = ({ utilization }) => {
  const dispatch = useDispatch();

  const apaActions = useSelector(ApaActionSelectors.getAllByUtilizatonId(utilization?.id));

  useEffect(() => {
    dispatch(ApaActionActions.getAll(utilization.id, utilization.project?.id));
  }, []);

  if (!utilization || !apaActions) return null;

  return <ApaViewer utilization={utilization} apaActions={apaActions} />;
};
