import { Parameter } from '@priz/shared/src/models/tools/forty-principles/parameter';
import { ParameterAction, ParameterActionType, ParametersAction } from '../actions';
import { DefaultParameterList, ParameterList } from '../model';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { handleActions } from 'redux-actions';
import { CollectionStatusesService } from '@priz/shared/src/services/statuses/collection-statuses.service';

export const parameterReducers = handleActions(
  {
    // Fetch

    [toStarted(ParameterActionType.Fetch)]: (state: ParameterList): ParameterList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        loading: true,
        loaded: false,
      }),
    }),
    [toFailed(ParameterActionType.Fetch)]: (state: ParameterList): ParameterList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        loading: false,
        loaded: false,
        error: true,
      }),
    }),
    [toSuccess(ParameterActionType.Fetch)]: (state: ParameterList, action: ParametersAction): ParameterList => {
      const parameters = action.payload.map(parameterData => new Parameter(parameterData));

      return {
        ...state,
        parameters,
        statuses: CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses,
          loading: false,
          loaded: true,
          error: false,
        }),
      };
    },

    // Update

    [toStarted(ParameterActionType.Update)]: (state: ParameterList, action: ParameterAction): ParameterList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        updating: true,
        updated: false,
        idsUpdate: [{ target: 'updatingIds', method: 'add', ids: [action.meta.parameterId] }],
      }),
    }),
    [toFailed(ParameterActionType.Update)]: (state: ParameterList, action: ParameterAction): ParameterList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        updating: false,
        updated: false,
        error: true,
        idsUpdate: [{ target: 'updatingIds', method: 'remove', ids: [action.meta.parameterId] }],
      }),
    }),
    [toSuccess(ParameterActionType.Update)]: (state: ParameterList, action: ParameterAction): ParameterList => {
      const updatedParameters = state.parameters.map(item => (item.id === action.payload.id ? action.payload : item));

      return {
        ...state,
        parameters: updatedParameters,
        statuses: CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses,
          updating: false,
          updated: true,
          error: false,
          idsUpdate: [{ target: 'updatingIds', method: 'remove', ids: [action.meta.parameterId] }],
        }),
      };
    },
  },
  DefaultParameterList,
);
