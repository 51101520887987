import React, { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { CloudUploadOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { pgColorScheme } from '@priz/shared/src/theme';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { delimitersOptions, DelimiterType } from './data';
import { CSVDataArray, CSVToArray, detectDelimiter } from './utils';

export enum CSVImportType {
  Add = 'Add',
  Replace = 'Replace',
}

interface CSVDataSelectorProps {
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onImport?: (values: string[], importType: CSVImportType, limitHit: boolean) => void;
  buttonText?: string;
  planLimit?: number;
  importLimit?: number;
  maxPreviewCount?: number;
  currentItemsCount?: number;
  loading?: boolean;
  disabled?: boolean;
}

interface StyledTableCellProps {
  active?: boolean;
  header?: boolean;
}

const StyledTableCell: React.FC<StyledTableCellProps> = ({ active, header, children }) => {
  const defaultBgColor = header ? pgColorScheme.background : pgColorScheme.white;
  const activeBgColor = header ? pgColorScheme.backgroundDarkGray : pgColorScheme.backgroundMediumGray;

  return (
    <TableCell
      sx={{
        minWidth: 200,
        backgroundColor: active ? activeBgColor : defaultBgColor,
      }}
    >
      {children}
    </TableCell>
  );
};

export const CsvDataSelector: React.FC<CSVDataSelectorProps> = ({
  open,
  onOpen,
  onClose,
  onImport,
  buttonText,
  planLimit,
  importLimit = 100,
  maxPreviewCount = 100,
  currentItemsCount = 0,
  loading,
  disabled,
}) => {
  const theme = useTheme();

  const [selectorIsOpen, setSelectorIsOpen] = useState(false);
  const [delimiter, setDelimiter] = useState<DelimiterType | null>();
  const [firstRowIsHeader, setFirstRowIsHeader] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState<string | null>(null);
  const [importType, setImportType] = useState<CSVImportType | null>(null);
  const [fileContent, setFileContent] = useState<string | null>();
  const [tableData, setTableData] = useState<CSVDataArray | null>(null);

  const [fileIsLoading, setFileIsLoading] = useState(false);
  const [isImporting, setIsImporting] = useState(false);

  const isDownSmBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  const allowImport = !disabled && selectedColumn && importType;

  useEffect(() => {
    if (typeof open !== 'undefined') {
      setSelectorIsOpen(open);
    }
  }, [open]);

  useEffect(() => {
    if (fileContent && !delimiter) {
      setDelimiter(detectDelimiter(fileContent));
    }
  }, [fileContent, delimiter]);

  useEffect(() => {
    if (!fileContent) {
      setTableData(null);
      return;
    }

    if (delimiter) {
      setTableData(CSVToArray(fileContent, delimiter, firstRowIsHeader));
    }
  }, [fileContent, delimiter, firstRowIsHeader]);

  const headerPropsChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedColumn(null);
    setFirstRowIsHeader(event.target.checked);
  };

  const delimiterChangeHandler = (event: SelectChangeEvent) => {
    setDelimiter(event.target.value as DelimiterType);
  };

  const columnSelectHandler = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    setSelectedColumn(event.target.checked ? key : null);
  };

  const importTypeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImportType(event.target.value as CSVImportType);
  };

  const openSelectorDialog = () => {
    if (onOpen) {
      onOpen();
    } else {
      setSelectorIsOpen(true);
    }
  };

  const closeSelectorDialog = () => {
    if (onClose) {
      onClose();
    } else {
      setSelectorIsOpen(false);
    }
  };

  const fileSelectHandler = (files: FileList | null) => {
    setFileContent(null);
    setDelimiter(null);
    setSelectedColumn(null);
    setIsImporting(false);
    setFileIsLoading(true);

    const reader = new FileReader();

    reader.onload = () => {
      setFileIsLoading(false);

      if (typeof reader.result === 'string') {
        setFileContent(reader.result);
        openSelectorDialog();
      }
    };

    reader.readAsText(files[0]);
  };

  const importHandler = () => {
    setIsImporting(true);

    setTimeout(() => {
      if (onImport) {
        const columnValues = [];

        tableData.forEach(row => {
          if (row[selectedColumn]?.length) columnValues.push(row[selectedColumn]);
        });

        const reduceCount = importType === CSVImportType.Add ? currentItemsCount : 0;
        const limitHit = planLimit && columnValues.length + reduceCount > planLimit;
        const sliceTo = Math.max(planLimit - reduceCount, 0);
        const result = (planLimit ? columnValues.slice(0, sliceTo) : columnValues).slice(0, importLimit);

        onImport(result, importType, limitHit);
      }

      if (!onClose) closeSelectorDialog();
    }, 500);
  };

  return (
    <div>
      <label>
        <input
          type="file"
          accept=".csv"
          onChange={e => {
            fileSelectHandler(e.target.files);
            e.target.value = null;
          }}
          disabled={disabled}
          hidden
        />

        <LoadingButton
          component={'div'}
          size={'small'}
          loadingPosition={'start'}
          loading={fileIsLoading || loading}
          disabled={disabled || loading}
          startIcon={<CloudUploadOutlined />}
        >
          <Trans>{buttonText || 'Import from CSV'}</Trans>
        </LoadingButton>
      </label>

      <Dialog open={selectorIsOpen} onClose={closeSelectorDialog} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle>
          <Trans>Select column for import</Trans>
        </DialogTitle>

        <DialogContent>
          <Box mb={2}>
            <Grid container spacing={2} alignItems={'center'}>
              <Grid item>
                <FormControlLabel
                  labelPlacement="start"
                  label={
                    <Typography variant={'body1'}>
                      <Trans>Use first row as header</Trans>
                    </Typography>
                  }
                  control={<Checkbox size={'small'} checked={firstRowIsHeader} onChange={headerPropsChangeHandler} />}
                  sx={{ margin: 0 }}
                />
              </Grid>

              <Grid item>
                <Grid container alignItems={'center'} spacing={1}>
                  <Grid item>
                    <Typography variant={'body1'}>
                      <Trans>Delimiter to use</Trans>:
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Select
                      variant="standard"
                      sx={{ minWidth: 60 }}
                      value={delimiter}
                      onChange={delimiterChangeHandler}
                    >
                      {delimitersOptions.map((item, key) => (
                        <MenuItem key={key} value={item.value}>
                          <Trans>
                            {item.label} - {item.value}
                          </Trans>
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container alignItems={'center'}>
                  <Grid item>
                    <Typography variant={'body1'}>
                      <Trans>Import type</Trans>:
                    </Typography>
                  </Grid>

                  <Grid item>
                    <RadioGroup row name="import-type" value={importType} onChange={importTypeChangeHandler}>
                      <FormControlLabel
                        labelPlacement="start"
                        label={
                          <Typography variant={'body1'}>
                            <Trans>Add</Trans>
                          </Typography>
                        }
                        control={<Radio size={'small'} />}
                        value={CSVImportType.Add}
                      />

                      <FormControlLabel
                        labelPlacement="start"
                        label={
                          <Typography variant={'body1'}>
                            <Trans>Replace</Trans>
                          </Typography>
                        }
                        control={<Radio size={'small'} />}
                        value={CSVImportType.Replace}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {importType === CSVImportType.Replace && (
            <Box mb={2}>
              <Alert severity={'warning'}>
                <Trans>Current data will be deleted and replaced by imported one</Trans>
              </Alert>
            </Box>
          )}

          {tableData?.length > importLimit && (
            <Box mb={2}>
              <Alert severity={'warning'}>
                <Trans>Import limit</Trans>
                {': '}
                {importLimit} <Trans>records</Trans>
              </Alert>
            </Box>
          )}

          {!!tableData?.length && (
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 300 }}>
                <Table stickyHeader size={isDownSmBreakpoint ? 'small' : 'medium'}>
                  <TableHead sx={{ backgroundColor: pgColorScheme.background }}>
                    <TableRow>
                      {Object.keys(tableData[0]).map(key => (
                        <StyledTableCell key={key} active={selectedColumn === key} header={true}>
                          <FormControlLabel
                            label={key}
                            control={
                              <Radio
                                size={'small'}
                                checked={selectedColumn === key}
                                onChange={e => {
                                  columnSelectHandler(e, key);
                                }}
                              />
                            }
                            sx={{ margin: 0 }}
                          />
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {tableData.slice(0, maxPreviewCount).map((row, rowIndex) => (
                      <React.Fragment key={rowIndex}>
                        <TableRow>
                          {Object.keys(row).map((key, columnIndex) => (
                            <StyledTableCell key={columnIndex} active={selectedColumn === key}>
                              {row[key]}
                            </StyledTableCell>
                          ))}
                        </TableRow>

                        {tableData.length > maxPreviewCount && rowIndex === maxPreviewCount - 1 && (
                          <TableRow>
                            {Object.keys(row).map((key, columnIndex) => (
                              <StyledTableCell key={columnIndex} active={selectedColumn === key}>
                                ...
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </DialogContent>

        <DialogActions style={{ textAlign: 'right' }}>
          <ButtonsWrap justifyContent={'flex-end'}>
            <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={closeSelectorDialog}>
              <Trans>Close</Trans>
            </Button>

            <Tooltip title={!allowImport ? <Trans>You should select column and type of import first</Trans> : ''}>
              <div>
                <LoadingButton
                  onClick={importHandler}
                  disabled={disabled || !allowImport || loading || isImporting}
                  loading={loading || isImporting}
                  variant={'pg_rounded'}
                  color={'pg_orange_solid'}
                >
                  <Trans>Import</Trans>
                </LoadingButton>
              </div>
            </Tooltip>
          </ButtonsWrap>
        </DialogActions>
      </Dialog>
    </div>
  );
};
