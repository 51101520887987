import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { alpha } from '@mui/material';

export const useStyles = makeStyles({
  suggestedOptionsGroup: {
    margin: 4,
    borderRadius: 4,
    borderStyle: 'dashed',
    borderColor: pgColorScheme.green,
    borderWidth: 2,
    backgroundColor: alpha(pgColorScheme.green, 0.01),
  },
  defaultOptionsGroup: {},
});
