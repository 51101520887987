import { Parameter } from '@priz/shared/src/models/tools/forty-principles/parameter';

export const getParameterDescriptionById = (
  parameters: Parameter[],
  id: number,
): {
  en: string;
  zh: string;
  es: string;
} => {
  const parameter = parameters.find(p => p.id === id);

  return {
    en: parameter?.description || '',
    zh: parameter?.descriptionZh || '',
    es: parameter?.descriptionEs || '',
  };
};
