import React, { ReactNode } from 'react';
import { AuthService } from '@priz/shared/src/services/auth';
import { AppNavbar } from '../../navigation/app-navbar/component';
import { AppPublicNavbar, AppPublicNavbarProps } from '../../navigation/app-public-navbar/component';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Box } from '@mui/material';
import { PageContainer, PageContainerProps } from '../page-container/component';
import { useStyles } from './styles';

import { ReactComponent as BottomBackgroundImage } from '../../../assets/img/projects-list-pg.svg';

interface PublicPageContainerProps extends PageContainerProps {
  title: ReactNode;
  oneLiner?: ReactNode;
  publicNavbarProps?: Partial<AppPublicNavbarProps>;
  showAuthenticatedNavbar?: boolean;
}

export const PublicPageContainer: React.FC<PublicPageContainerProps> = ({
  title,
  oneLiner,
  publicNavbarProps,
  showAuthenticatedNavbar = true,
  children,
  ...rest
}) => {
  const styles = useStyles();

  return (
    <PageContainer variant={'narrow'} {...rest}>
      {showAuthenticatedNavbar && AuthService.isAuthenticated() ? (
        <AppNavbar />
      ) : (
        <AppPublicNavbar {...publicNavbarProps} />
      )}

      <>
        <PageTitleWithDocLink title={title} oneLiner={oneLiner} />

        {children}

        <Box mt={'auto'}>
          <BottomBackgroundImage className={styles.bottomImage} />
        </Box>
      </>
    </PageContainer>
  );
};
