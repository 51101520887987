import { handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { toObjectMap } from '../../../shared/store/reducer-utils';
import { ArrayUtil } from '../../../shared/utils/array-util';
import {
  FiveWhysCause,
  FiveWhysCauseCollection,
  DefaultFiveWhysCauseCollection,
  IFiveWhysCause,
} from '@priz/shared/src/models/tools/five-whys';
import { FiveWhysCauseAction, FiveWhysCauseActionType } from '../actions';
import { copyObject } from '@priz/shared/src/utils/common';

const fiveWhysCauseInstantiator = (payload: any): FiveWhysCause => new FiveWhysCause(payload);

const setStatus = (
  state: FiveWhysCauseCollection,
  utilizationId: number,
  loading: boolean,
  loaded: boolean,
): FiveWhysCauseCollection => {
  const statusesCopy = copyObject(state.statuses);
  statusesCopy.byUtilizationId[utilizationId] = {
    loaded,
    loading,
  };

  return {
    ...state,
    statuses: statusesCopy,
  };
};

const setCecCauses = (
  state: FiveWhysCauseCollection,
  utilizationId: number,
  causesPayload: any,
): FiveWhysCauseCollection => {
  const causesMap = toObjectMap<FiveWhysCause>(causesPayload, fiveWhysCauseInstantiator);
  const entitiesCopy = { ...state.entities, ...causesMap };

  const lookupsCopy = copyObject(state.lookups);
  Object.values(causesMap).forEach((cause: FiveWhysCause) => {
    lookupsCopy.byUtilizationId[utilizationId] = ArrayUtil.concatInDistinctArray(
      lookupsCopy.byUtilizationId[utilizationId],
      cause.id,
    );
  });

  const statusesCopy = copyObject(state.statuses);
  statusesCopy.byUtilizationId[utilizationId] = {
    loaded: true,
    loading: false,
  };

  return {
    ...state,
    entities: entitiesCopy,
    lookups: lookupsCopy,
    statuses: statusesCopy,
  };
};

const updateFiveWhysCause = (state: FiveWhysCauseCollection, causePayload: any): FiveWhysCauseCollection => {
  const updatedCause = fiveWhysCauseInstantiator(causePayload);

  const entitiesCopy = {
    ...state.entities,
    [updatedCause.id]: updatedCause,
  };

  return {
    ...state,
    entities: entitiesCopy,
  };
};

const removeFiveWhysCause = (
  state: FiveWhysCauseCollection,
  utilizationId: number,
  causeId: number,
): FiveWhysCauseCollection => {
  const entitiesCopy = { ...state.entities };
  delete entitiesCopy[causeId];

  const lookupsCopy = copyObject(state.lookups);
  lookupsCopy.byUtilizationId[utilizationId] = lookupsCopy.byUtilizationId[utilizationId].filter(id => id != causeId);

  return {
    ...state,
    entities: entitiesCopy,
    lookups: lookupsCopy,
  };
};

export const fiveWhysCauseReducers = handleActions(
  {
    [toStarted(FiveWhysCauseActionType.Fetch)]: (
      state: FiveWhysCauseCollection,
      action: FiveWhysCauseAction,
    ): FiveWhysCauseCollection => setStatus(state, action.meta.utilizationId, true, false),
    [toSuccess(FiveWhysCauseActionType.Fetch)]: (
      state: FiveWhysCauseCollection,
      action: FiveWhysCauseAction,
    ): FiveWhysCauseCollection => setCecCauses(state, action.meta.utilizationId, action.payload as IFiveWhysCause[]),
    [toFailed(FiveWhysCauseActionType.Fetch)]: (
      state: FiveWhysCauseCollection,
      action: FiveWhysCauseAction,
    ): FiveWhysCauseCollection => setStatus(state, action.meta.utilizationId, false, false),
    [toSuccess(FiveWhysCauseActionType.Create)]: (
      state: FiveWhysCauseCollection,
      action: FiveWhysCauseAction,
    ): FiveWhysCauseCollection => setCecCauses(state, action.meta.utilizationId, [action.payload as IFiveWhysCause]),
    [toSuccess(FiveWhysCauseActionType.Update)]: (
      state: FiveWhysCauseCollection,
      action: FiveWhysCauseAction,
    ): FiveWhysCauseCollection => updateFiveWhysCause(state, action.payload as IFiveWhysCause),
    [toSuccess(FiveWhysCauseActionType.Delete)]: (
      state: FiveWhysCauseCollection,
      action: FiveWhysCauseAction,
    ): FiveWhysCauseCollection => removeFiveWhysCause(state, action.meta.utilizationId, action.meta.causeId),
  },
  DefaultFiveWhysCauseCollection,
);
