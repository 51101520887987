import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 'auto',
    bottom: 'auto',
    width: '100px',
    height: '100px',
    padding: 15,
    boxSizing: 'border-box',
    transformOrigin: '0 0',

    ['&::before']: {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 'auto',
      bottom: 'auto',
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      borderRadius: 16,
      borderWidth: 2,
      borderStyle: 'dashed',
      borderColor: pgColorScheme.green,
      zIndex: -1,
      pointerEvents: 'none',
    },
  },
  controls: {
    position: 'absolute',
    left: '100%',
    top: 0,
    right: 'auto',
    bottom: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  textAreaContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: '15px 35px',
    boxSizing: 'border-box',
    backgroundColor: pgColorScheme.white,
    borderRadius: 8,
  },
  textArea: {
    width: '100%',
    height: '60px',
    border: 0,
    resize: 'none',
    padding: 0,
    boxSizing: 'border-box',
    textAlign: 'center',
    background: 'transparent',
    outline: 'none',
    fontFamily: '"Roboto", sans-serif',
    fontSize: 18,
    lineHeight: '1',
    letterSpacing: 0,
    overflow: 'hidden',

    ['&::placeholder']: {
      color: pgColorScheme.placeholder,
    },
  },
});
