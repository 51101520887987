import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  slider: {
    ['& .slick-track']: {
      display: 'flex',
      alignItems: 'stretch',
    },
    ['& .slick-slide']: {
      height: 'auto',

      ['& > div']: {
        height: '100%',
      },
    },
  },
  planCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  sliderButton: {
    border: `1px solid ${pgColorScheme.gray3}`,
  },
});
