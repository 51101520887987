import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { Box, Chip, Divider, Grid, Hidden, Typography } from '@mui/material';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import { useStyles } from './styles';
import { useForm } from 'react-hook-form';
import { ProjectActions } from '../store/actions/project.actions';
import { Project } from '@priz/shared/src/models/project';
import { ProjectSelector } from '../store/selectors';
import { ProjectFlowService } from '../services';
import { ReactHookFormQuill } from '../../react/form-elements';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { fixQuillEmptyValue } from '@priz/shared/src/utils/form';
import { resolveProblemStatementStepProps } from './utils';
import { Assistant, AssistantProps } from '../../assistant/component';
import { AssistanceType, AssistantVariant } from '../../assistant/store/model';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { HintRowType } from '../../assistant/hint-content/component';
import {
  resolveCurrentSituationHintData,
  resolveDisadvantagesHintData,
  resolveProblemStatementHintData,
  useProjectDataCompleteness,
} from '../../assistant/utils';

export interface ProblemStatementProps {
  projectId: number;
}

enum FieldName {
  CurrentSituation = 'currentSituation',
  Disadvantages = 'disadvantages',
  IdealFinalResult = 'idealFinalResult',
  Gaps = 'gaps',
  ProblemStatement = 'problemStatement',
}

interface StepsDataMap {
  fieldName: FieldName;
  title: string;
  description: string;
  assistant?: Omit<AssistantProps, 'projectId'>;
  feedback?: Omit<AssistantProps, 'projectId'>;
  separate?: string;
}

const stepsDataMap: StepsDataMap[] = [
  {
    fieldName: FieldName.CurrentSituation,
    title: '1 Current situation',
    description: 'Describe in details the current situation',
    assistant: {
      type: AssistanceType.AskCurrentSituation,
    },
    // feedback: {
    //   type: AssistanceType.AskCurrentSituationFeedback,
    // },
  },
  {
    fieldName: FieldName.Disadvantages,
    title: '2 Disadvantages of current situation',
    description: 'List all the disadvantages of the situation you described above',
    assistant: {
      type: AssistanceType.AskDisadvantages,
      contentProps: {
        hintRowsType: HintRowType.Checkbox,
      },
    },
    // feedback: {
    //   type: AssistanceType.AskDisadvantagesFeedback,
    // },
  },
  {
    fieldName: FieldName.IdealFinalResult,
    title: '3 Ideal Final Result',
    description: 'Describe the ultimate goal to achieve',
    assistant: {
      type: AssistanceType.AskIdealFinalResult,
      contentProps: {
        hintRowsType: HintRowType.Radio,
      },
    },
    // feedback: {
    //   type: AssistanceType.AskIdealFinalResultFeedback,
    // },
  },
  {
    fieldName: FieldName.Gaps,
    title: '4 Gaps',
    description: 'Describe the gaps that need overcoming to reach the Ideal Final Result',
    assistant: {
      type: AssistanceType.AskGaps,
      contentProps: {
        hintRowsType: HintRowType.Checkbox,
      },
    },
    // feedback: {
    //   type: AssistanceType.AskGapsFeedback,
    // },
  },
  {
    fieldName: FieldName.ProblemStatement,
    title: '5 Problem to solve',
    description: 'Outline the problem to solve to move closer to the Ideal Final Result or the desired goal',
    assistant: {
      type: AssistanceType.AskProblemStatementHint,
      contentProps: {
        hintRowsType: HintRowType.Radio,
      },
    },
    // feedback: {
    //   type: AssistanceType.AskProblemStatementFeedback,
    // },
  },
];

type ProblemStatementFields = {
  [key in FieldName]: string;
};

const resolveDefaultValues = (project?: Project): ProblemStatementFields => ({
  [FieldName.CurrentSituation]: project?.currentSituation || '',
  [FieldName.Disadvantages]: project?.disadvantages || '',
  [FieldName.IdealFinalResult]: project?.idealFinalResult || '',
  [FieldName.Gaps]: project?.gaps || '',
  [FieldName.ProblemStatement]: project?.problemStatement || '',
});

export const ProblemStatement: React.FC<ProblemStatementProps> = ({ projectId }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const [assistantContainers, setAssistantContainers] = useState<{ [key: string]: HTMLDivElement }>({});

  const project: Project = useSelector(ProjectSelector.getById(projectId));

  const {
    isAiAssistantEnabled,
    isProjectBackgroundDefined,
    isCurrentSituationDefined,
    isDisadvantagesDefined,
    isIdealFinalResultDefined,
    isGapsDefined,
    isProblemStatementDefined,
    isProjectBackgroundCompleted,
  } = useProjectDataCompleteness(projectId);

  const isProjectLocked = ProjectFlowService.isProjectLocked(project);

  const stepsCompletenessMap = {
    [FieldName.CurrentSituation]: resolveCurrentSituationHintData(project),
    [FieldName.Disadvantages]: resolveDisadvantagesHintData(project),
    [FieldName.ProblemStatement]: resolveProblemStatementHintData(project),
  };

  const {
    watch,
    handleSubmit,
    getValues,
    setValue,
    control,
    reset,
    formState: { isDirty },
  } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: resolveDefaultValues(),
  });

  useEffect(() => {
    const subscription = watch((_value, { name }) => {
      if (name) handleSubmit(onTextUpdate)();
    });

    return () => subscription.unsubscribe();
  }, [watch, project]);

  useEffect(() => {
    if (!isDirty && project) {
      reset(resolveDefaultValues(project));
    }
  }, [project]);

  const onTextUpdate = useCallback(
    debounce(() => {
      const values = getValues();

      dispatch(
        ProjectActions.updateProblemStatement({
          id: projectId,
          currentSituation: fixQuillEmptyValue(values.currentSituation),
          disadvantages: fixQuillEmptyValue(values.disadvantages),
          idealFinalResult: fixQuillEmptyValue(values.idealFinalResult),
          gaps: fixQuillEmptyValue(values.gaps),
          problemStatement: fixQuillEmptyValue(values.problemStatement),
        }),
      );
    }, 500),
    [],
  );

  const assistantContainerRefCallback = (key: string, element: HTMLDivElement) => {
    if (!key || !element || assistantContainers[key]) return null;

    setAssistantContainers(currentState => ({
      ...currentState,
      [key]: element,
    }));
  };

  return (
    <>
      <ContentContainer>
        <PageTitleWithDocLink
          title={<Trans>Goal, Gaps and Problem to solve</Trans>}
          oneLiner={
            <Trans>Any project aims to solve a problem. Defining the right problem is half way to solution.</Trans>
          }
          docsUrl="https://www.priz.guru/knowledge-base/problem-statement/"
        />

        <Grid container>
          <Grid item xs={true}>
            {stepsDataMap.map((item, key) => {
              const { fieldName, separate, title, description, assistant, feedback } = item;

              const {
                isInputDisabled,
                isHintDisabled,
                isFeedbackDisabled,
                hintButtonTooltip,
                feedbackButtonTooltip,
                progressButtonTooltip,
              } = resolveProblemStatementStepProps({
                step: key + 1,
                isProjectBackgroundDefined,
                isCurrentSituationDefined,
                isDisadvantagesDefined,
                isIdealFinalResultDefined,
                isGapsDefined,
                isProblemStatementDefined,
                isProjectBackgroundCompleted,
              });

              const containerClassNames = [
                styles.stepContainer,
                isProjectLocked || isInputDisabled ? styles.stepContainerDisabled : styles.stepContainerActive,
              ];

              const completenessHintData = stepsCompletenessMap[fieldName];

              return (
                <Box key={key} mb={4}>
                  {separate && (
                    <>
                      <Box my={2}>
                        <Divider />
                      </Box>

                      <PageTitleWithDocLink title={<Trans>{separate}</Trans>} />
                    </>
                  )}

                  <Box className={containerClassNames.join(' ')} pl={2}>
                    <Box mb={1}>
                      <Grid container spacing={2} alignItems={'center'}>
                        <Grid item>
                          <Chip label={<Trans>{title}</Trans>} size={'small'} />
                        </Grid>

                        {isAiAssistantEnabled && assistant && assistantContainers[assistant.type] && (
                          <Grid item>
                            <Assistant
                              type={assistant.type}
                              projectId={projectId}
                              contentProps={assistant?.contentProps}
                              onUseHint={text => {
                                setValue(fieldName, text.map(row => `<p>${row}</p>`).join('<p><br/></p>'));
                              }}
                              assistantContainer={assistantContainers[assistant.type]}
                              inlineAssistantProps={{ py: 2 }}
                              buttonProps={{
                                tooltip: hintButtonTooltip,
                              }}
                              disabled={isProjectLocked || isHintDisabled}
                            />
                          </Grid>
                        )}

                        {feedback && (
                          <Grid item>
                            <Assistant
                              type={feedback.type}
                              variant={AssistantVariant.Dialog}
                              projectId={projectId}
                              initialHintData={completenessHintData}
                              buttonProps={{
                                text: 'Get feedback',
                                variant: completenessHintData ? 'completeness' : 'button',
                                tooltip: completenessHintData ? progressButtonTooltip : feedbackButtonTooltip,
                              }}
                              controlsProps={{ retry: !!completenessHintData, retryText: 'Reevaluate' }}
                              dialogAssistantProps={{ maxWidth: 'lg' }}
                              disabled={isProjectLocked || isFeedbackDisabled}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Box>

                    {isAiAssistantEnabled && assistant && (
                      <div style={{ width: '100%' }} ref={r => assistantContainerRefCallback(assistant.type, r)} />
                    )}

                    {description && (
                      <Box mb={1}>
                        <Typography variant={'subtitle2'} component={'span'}>
                          <Trans>{description}</Trans>
                        </Typography>
                      </Box>
                    )}

                    {/*{example && (*/}
                    {/*  <Box mt={1} mb={2}>*/}
                    {/*    <Typography variant={'subtitle2'} component={'span'}>*/}
                    {/*      <Trans>{example}</Trans>*/}
                    {/*    </Typography>*/}
                    {/*  </Box>*/}
                    {/*)}*/}

                    <ReactHookFormQuill
                      name={fieldName}
                      control={control}
                      readOnly={isProjectLocked || isInputDisabled}
                      wrapperProps={{ mb: 0 }}
                    />
                  </Box>
                </Box>
              );
            })}
          </Grid>

          <Hidden mdDown>
            <Grid item>
              <Box ml={{ xs: 0, sm: 1, md: 2, lg: 3 }} className={styles.rightPane} />
            </Grid>
          </Hidden>
        </Grid>
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar projectId={projectId} workingFlowProps={{ nextVariant: 'tools' }} />
      </ContentFooter>
    </>
  );
};
