import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  tabs: {
    minHeight: 0,
  },
  tab: {
    padding: '4px 8px',
    minHeight: 0,
    minWidth: 0,
    textTransform: 'none',
  },
  tabContent: {
    width: '100%',
    height: 'auto',
    maxHeight: 200,
    overflow: 'auto',
  },
});
