import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { Task } from '@priz/shared/src/models/task';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Paper } from '@mui/material';
import { useStyles as useFormStyles } from '../../react/form-elements/styles';
import { useForm } from 'react-hook-form';
import { FieldTitle, ReactHookFormCheckbox, ReactHookFormQuill, ReactHookFormText } from '../../react/form-elements';
import { getTextAndImages, validateOptions, validatorRules } from '@priz/shared/src/utils/form';
import { TaskActions } from '../store/actions/task.actions';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { TaskAssigneeButton } from '../../assignment/task-assignee-button/component';
import { StatusButton } from '../status-button/component';
import { DueDateButton } from '../due-date-button/component';
import { AttachmentsLoader } from '../../attachments/attachments-loader/component';
import { AttachmentType } from '../../attachments/store/model';
import { TaskSelectors } from '../store/selectors/task.selectors';
import { LoadingButton } from '@mui/lab';

interface TaskEditorProps {
  task: Task;
  isOpen: boolean;
  onClose?: () => void;
}

const constructTaskFormData = (task: Task) => {
  return {
    title: task.title || '',
    details: task.details || '',
    resolution: task.resolution || '',
    solvingProblem: !!task.solvingProblem,
  };
};

export const TaskEditor: React.FC<TaskEditorProps> = ({ task, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const formStyles = useFormStyles();
  const [open, setOpen] = useState(false);

  const isUpdating = useSelector(TaskSelectors.isUpdating(task.project.id));
  const isUpdated = useSelector(TaskSelectors.isUpdated(task.project.id));

  const { handleSubmit, control, reset } = useForm({
    shouldFocusError: false,
    defaultValues: constructTaskFormData(task),
  });

  useEffect(() => {
    if (isUpdated) handleClose();
  }, [isUpdated]);

  useEffect(() => {
    setOpen(isOpen);
    if (isOpen) reset(constructTaskFormData(task));
  }, [isOpen]);

  useEffect(() => {
    reset(constructTaskFormData(task));
  }, [task]);

  const save = data => {
    const updateData = { ...data };

    Object.keys(updateData).forEach(key => {
      const isEmpty = typeof updateData[key] === 'string' && !getTextAndImages(updateData[key])?.length;
      if (!updateData[key] || isEmpty) updateData[key] = undefined;
    });

    dispatch(
      TaskActions.update({
        ...task,
        ...updateData,
      }),
    );
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={handleSubmit(save)} className={formStyles.form}>
        <DialogTitle>{<Trans>Edit task</Trans>}</DialogTitle>

        <DialogContent>
          <Grid container spacing={4} flexDirection={{ xs: 'column-reverse', lg: 'row' }}>
            <Grid item xs={12} lg={8}>
              <ReactHookFormText
                name={'title'}
                fieldTitle={'Task title'}
                control={control}
                rules={{
                  ...validatorRules.required(),
                }}
                disabled={isUpdating}
              />

              <ReactHookFormQuill
                fieldTitle={'Task details'}
                name={'details'}
                control={control}
                rules={{
                  validate: {
                    ...validateOptions.hasText('Field is required'),
                  },
                }}
                disabled={isUpdating}
              />

              <ReactHookFormQuill
                fieldTitle={'Resolution'}
                name={'resolution'}
                control={control}
                placeholder={'Resolution details here...'}
                disabled={isUpdating}
              />

              <Box pb={{ xs: 2, lg: 0 }}>
                <Grid container spacing={{ xs: 1, lg: 4 }} alignItems={'flex-end'} justifyContent={'space-between'}>
                  <Grid item>
                    <ReactHookFormCheckbox
                      fieldTitle="Was it the solution?"
                      name={'solvingProblem'}
                      label={<Trans>Problem solved</Trans>}
                      control={control}
                      wrapperProps={{ mb: 0 }}
                      disabled={isUpdating}
                    />
                  </Grid>

                  <Grid item>
                    <ButtonsWrap spacing={1}>
                      <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose}>
                        <Trans>Cancel</Trans>
                      </Button>

                      <LoadingButton
                        variant={'pg_rounded'}
                        color={'pg_orange_solid'}
                        type={'submit'}
                        disabled={isUpdating}
                        loading={isUpdating}
                      >
                        <Trans>Update task</Trans>
                      </LoadingButton>
                    </ButtonsWrap>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Paper variant={'outlined'}>
                <Box p={2}>
                  <Grid
                    container
                    spacing={2}
                    flexDirection={{ xs: 'row', lg: 'column' }}
                    alignItems={{ xs: 'center', lg: 'flex-start' }}
                  >
                    <Grid item>
                      <Grid container alignItems={'center'} spacing={1}>
                        <Grid item>
                          <FieldTitle text={'Status'} mb={0} />
                        </Grid>
                        <Grid item>
                          <StatusButton task={task} />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container alignItems={'center'} spacing={1}>
                        <Grid item>
                          <FieldTitle text={'Due date'} mb={0} />
                        </Grid>
                        <Grid item>
                          <DueDateButton task={task} />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item maxWidth={'100%'}>
                      <Grid container alignItems={'center'} spacing={1}>
                        <Grid item>
                          <FieldTitle text={'Assignee'} mb={0} />
                        </Grid>
                        <Grid item maxWidth={'100%'}>
                          <TaskAssigneeButton task={task} text={'add'} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Box mt={2}>
                    <AttachmentsLoader
                      projectId={task.project.id}
                      type={AttachmentType.Task}
                      taskId={task.id}
                      compact={true}
                      loadingText={'Loading attachments...'}
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
};
