import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    width: 32,
    height: 'auto',
    cursor: 'pointer',
    marginBottom: 12,
    transform: 'translate(0, 0)',

    ['&:last-child']: {
      marginBottom: 0,
    },

    ['& svg']: {
      display: 'block',
      width: '100%',
      height: 'auto',
      fill: pgColorScheme.darkGray,
      transition: 'fill .15s ease',
    },

    ['&:hover']: {
      '& svg': {
        fill: pgColorScheme.blue,
      },
    },
  },
  disabled: {
    opacity: 0.3,
    pointerEvents: 'none',
  },
  active: {
    '& svg': {
      fill: pgColorScheme.blue,
    },
  },
});
