import React from 'react';
import { useStyles } from './styles';
import { Box, BoxProps } from '@mui/material';

export const ContentContainer: React.FC<BoxProps> = ({ children, ...rest }) => {
  const styles = useStyles();

  return (
    <Box p={{ xs: 2, sm: 3 }} className={['content-container', styles.root].join(' ')} {...rest}>
      {children}
    </Box>
  );
};
