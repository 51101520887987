import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { useStyles } from './styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { PerceptionOption } from '../utils';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';

interface PMapPerceptionsSelectProps {
  defaultValue: PerceptionOption;
  placeholder: string;
  options: PerceptionOption[];
  variant: 'left' | 'right';
  selectCallback?: (option: PerceptionOption) => void;
  mod?: string;
  disabled?: boolean;
  selectMenuPortalContainer?: HTMLDivElement;
}

const menuStyles = {
  left: {
    left: 0,
    right: 'auto',
  },
  right: {
    left: 'auto',
    right: 0,
  },
};

export const PMapPerceptionsSelect: React.FC<PMapPerceptionsSelectProps> = ({
  defaultValue,
  placeholder,
  options,
  variant,
  selectCallback,
  mod = '',
  disabled,
  selectMenuPortalContainer,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const { t } = useTranslation();
  const [showValue, setShowValue] = useState(true);

  const isDownSmBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  const selectHandler = option => {
    if (selectCallback) selectCallback(option);
  };

  const hideInputValue = () => {
    setShowValue(false);
  };

  const showInputValue = () => {
    setShowValue(true);
  };

  const Option = props => (
    <components.Option
      {...props}
      className={`${styles.menuOptionWrap}${props.isSelected ? ' _selected' : ''}${props.isFocused ? ' _focused' : ''}`}
    >
      <div className={styles.option}>
        <span className={styles.letter}>{props.value}</span>
        <span className={styles.text}>{props.label}</span>
      </div>
    </components.Option>
  );

  const SingleValue = props => (
    <components.SingleValue {...props} className={`${styles.singleValue}${mod}`}>
      <div className={styles.option}>
        <span className={`${styles.letter}`}>{props.data.value}</span>
        <span className={styles.text}>{props.data.label}</span>
      </div>
    </components.SingleValue>
  );

  return (
    <Select
      menuPortalTarget={selectMenuPortalContainer}
      isDisabled={!!disabled}
      className={styles.select}
      onMenuOpen={hideInputValue}
      onMenuClose={showInputValue}
      controlShouldRenderValue={showValue}
      backspaceRemovesValue={false}
      autoFocus={false}
      tabSelectsValue={false}
      menuPlacement={'auto'}
      menuPosition={'fixed'}
      closeMenuOnScroll={true}
      menuShouldBlockScroll={true}
      menuShouldScrollIntoView={true}
      isClearable={true}
      isSearchable={true}
      components={{ Option, SingleValue }}
      placeholder={t(placeholder)}
      noOptionsMessage={() => t('No available options')}
      closeMenuOnSelect={true}
      onChange={selectHandler}
      value={defaultValue || ''}
      options={options}
      styles={{
        menu: provided => ({
          ...provided,
          ...menuStyles[variant],
          width: isDownSmBreakpoint ? '100%' : '200%',
        }),
        control: (provided, state) => ({
          ...provided,
          height: 42,
          borderColor: state.isFocused ? pgColorScheme.blue : pgColorScheme.gray300,
          boxShadow: state.isFocused
            ? `0px 0px 0px 1px ${pgColorScheme.blue} inset`
            : `0px 0px 0px 1px transparent inset`,
          transition: 'all .1s ease',

          ['&:hover']: {
            borderColor: pgColorScheme.placeholder,
          },
        }),
        valueContainer: provided => ({
          ...provided,
          height: '100%',
        }),
        placeholder: provided => ({
          ...provided,
          font: 'inherit',
        }),
      }}
    />
  );
};
