import { combineActions, handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { AssistantAction, AssistantActionPayload, AssistantActionType } from '../actions';
import { AssistantCollectionStatuses, AssistantState, DefaultAssistantState } from '../model';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { copyObject } from '@priz/shared/src/utils/common';
import { resolveIdeaParametersContent } from '../../utils';

const resolveStatuses = (
  state: AssistantState,
  assistantId: string,
  statuses: Partial<EntityCollectionStatus>,
): AssistantCollectionStatuses => {
  const statusesCopy = copyObject(state.statuses);

  statusesCopy.byAssistantId[assistantId] = { ...statusesCopy.byAssistantId[assistantId], ...statuses };

  return statusesCopy;
};

const setStatuses = (
  state: AssistantState,
  assistantId: string,
  statuses: Partial<EntityCollectionStatus>,
): AssistantState => {
  return {
    ...state,
    statuses: resolveStatuses(state, assistantId, statuses),
  };
};

const setActive = (state: AssistantState, assistantId: string): AssistantState => {
  return {
    ...state,
    activeAssistantId: assistantId,
  };
};

const setHint = (
  state: AssistantState,
  action: AssistantAction,
  payload: AssistantActionPayload,
  assistantId: string,
  statuses?: Partial<EntityCollectionStatus>,
): AssistantState => {
  const hintsCopy = copyObject(state.hints);

  let text = null;
  let content = null;
  let variants = null;
  let completeness = null;
  let toolSuggestions = null;

  if (payload) {
    // text response
    if ('hint' in payload) text = payload.hint;
    if ('explanation' in payload) text = payload.explanation;

    // content response
    if ('factors' in payload) content = resolveIdeaParametersContent(payload.factors, action.meta.ideaParameterType);

    // variants response
    if ('disadvantages' in payload) variants = payload.disadvantages;
    if ('problem_statements' in payload) variants = payload.problem_statements;
    if ('causes' in payload) variants = payload.causes;
    if ('solutions' in payload) variants = payload.solutions;
    if ('ifrs' in payload) variants = payload.ifrs;
    if ('gaps' in payload) variants = payload.gaps;

    // completeness response
    if ('completeness' in payload) completeness = payload.completeness;

    // tools response
    if ('tools' in payload) toolSuggestions = payload.tools;
  }

  hintsCopy.byAssistantId[assistantId] = {
    text,
    content,
    variants,
    completeness,
    toolSuggestions,
  };

  return {
    ...state,
    hints: hintsCopy,
    statuses: resolveStatuses(state, assistantId, { loading: false, error: false, ...statuses }),
  };
};

export const assistantReducers = handleActions(
  {
    // Get

    [combineActions(
      toStarted(AssistantActionType.GetHint),
      toStarted(AssistantActionType.GetProjectFeedback),
      toStarted(AssistantActionType.GetToolSuggestionsHint),
    )]: (state: AssistantState, action: AssistantAction) =>
      setHint(state, action, null, action.meta.assistantId, { loading: true }),

    [combineActions(
      toFailed(AssistantActionType.GetHint),
      toFailed(AssistantActionType.GetProjectFeedback),
      toFailed(AssistantActionType.GetToolSuggestionsHint),
    )]: (state: AssistantState, action: AssistantAction) =>
      setStatuses(state, action.meta.assistantId, { loading: false, error: true }),

    [combineActions(
      toSuccess(AssistantActionType.GetHint),
      toSuccess(AssistantActionType.GetProjectFeedback),
      toSuccess(AssistantActionType.GetToolSuggestionsHint),
    )]: (state: AssistantState, action: AssistantAction) =>
      setHint(state, action, action?.payload, action.meta.assistantId),

    // SetActive

    [toStarted(AssistantActionType.SetActive)]: (state: AssistantState, action: AssistantAction) =>
      setActive(state, action.meta.assistantId),
  },
  DefaultAssistantState,
);
