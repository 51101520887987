import { createStyles, makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 8,
      textDecoration: 'none',
      borderWidth: 4,
      borderStyle: 'solid',
      transition: 'border-color .15s ease, background-color .15s ease, color .15s ease',
      cursor: 'pointer',

      ['&:hover']: {
        color: pgColorScheme.blue,
      },
    },

    link: {
      color: pgColorScheme.darkText,
    },

    done: {
      color: pgColorScheme.white,
    },

    text: {
      color: pgColorScheme.darkText,
    },

    [theme.breakpoints.down('md')]: {
      root: {
        borderRadius: 4,
        borderWidth: 2,
      },
    },
  }),
);
