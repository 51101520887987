import React from 'react';
import { SfmViewerContainer, SfmViewerProps } from '../sfm-viewer-container/component';
import { Box, Tooltip, Typography } from '@mui/material';
import { useStyles } from './styles';
import { defaultSfmRank, OperationMeritType, SfmRank } from '../../models/tools/sfm';
import { Trans } from 'react-i18next';
import { PfmStepType } from '../../models/tools/pfm';
import { PfmStepTypeSelector } from '../pfm-step-type-selector/component';
import { OperationMeritLabel } from '../operation-merit-label/component';
import { resolvePfmTypeIcon } from '../../utils/pfm';

export interface SfmEfficiencyViewerBasicProps {
  sfmRank: SfmRank;
  maxSfmFunctionalRank: number;
  maxSfmProblematicRank: number;
}

interface SfmEfficiencyViewerProps extends SfmEfficiencyViewerBasicProps, SfmViewerProps {
  pfmStepType?: PfmStepType;
  useTooltips?: boolean;
  onPfmStepTypeSelect?: (value: PfmStepType) => void;
}

export const SfmEfficiencyViewer: React.FC<SfmEfficiencyViewerProps> = ({
  sfmRank,
  maxSfmFunctionalRank,
  maxSfmProblematicRank,
  pfmStepType,
  useTooltips,
  onPfmStepTypeSelect,
  ...rest
}) => {
  const styles = useStyles();

  const { functionalRank, problematicRank, operationMerit } = sfmRank || defaultSfmRank;

  const footerRenderer = () => {
    if (!pfmStepType && operationMerit?.type === OperationMeritType.NotDefined) return null;

    return (
      <Box p={0.5} display={'flex'} alignItems={'center'}>
        {pfmStepType && (
          <Box ml={0.5} mr={1}>
            {onPfmStepTypeSelect && <PfmStepTypeSelector value={pfmStepType} onSelect={onPfmStepTypeSelect} />}

            {!onPfmStepTypeSelect && (
              <Tooltip
                title={useTooltips ? <Trans>{pfmStepType}</Trans> : ''}
                placement={'bottom'}
                disableInteractive
                arrow
              >
                <div>{resolvePfmTypeIcon(pfmStepType)}</div>
              </Tooltip>
            )}
          </Box>
        )}

        <OperationMeritLabel operationMerit={operationMerit} useTooltip={useTooltips} />
      </Box>
    );
  };

  return (
    <SfmViewerContainer
      showPlaceholder={!functionalRank && !problematicRank}
      footerRenderer={footerRenderer}
      pfmStepType={pfmStepType}
      {...rest}
    >
      <Tooltip title={useTooltips ? <Trans>Functional rank</Trans> : ''} placement={'right'} arrow={true}>
        <Box
          className={[styles.bar, functionalRank ? styles.functional : styles.empty].join(' ')}
          style={{
            width: `${
              functionalRank && maxSfmFunctionalRank ? Math.round((functionalRank / maxSfmFunctionalRank) * 100) : 0
            }%`,
          }}
          mb={0.5}
        >
          <Typography variant={'caption'} className={styles.label}>
            {functionalRank}
          </Typography>
        </Box>
      </Tooltip>

      <Tooltip title={useTooltips ? <Trans>Problematic rank</Trans> : ''} placement={'right'} arrow={true}>
        <Box
          className={[styles.bar, problematicRank ? styles.problematic : styles.empty].join(' ')}
          style={{
            width: `${
              problematicRank && maxSfmProblematicRank ? Math.round((problematicRank / maxSfmProblematicRank) * 100) : 0
            }%`,
          }}
        >
          <Typography variant={'caption'} className={styles.label}>
            {problematicRank}
          </Typography>
        </Box>
      </Tooltip>
    </SfmViewerContainer>
  );
};
