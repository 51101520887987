export const safeParseJson = (str: string): object => {
  try {
    const parsed = JSON.parse(str);

    if (parsed && typeof parsed === 'object') {
      return parsed;
    }
  } catch (_e) {
    return {};
  }

  return {};
};
