import { handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { AttachmentAction, AttachmentsActionType } from '../actions';
import {
  Attachment,
  AttachmentPreview,
  AttachmentsCollection,
  AttachmentsCollectionStatuses,
  CreatingAttachments,
  DefaultAttachmentsCollection,
  IAttachment,
} from '../model';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { copyObject } from '@priz/shared/src/utils/common';
import { toObjectMap } from '../../../shared/store/reducer-utils';
import { ArrayUtil } from '../../../shared/utils/array-util';

const resolveStatuses = (
  projectId: number,
  state: AttachmentsCollection,
  statuses: Partial<EntityCollectionStatus>,
): AttachmentsCollectionStatuses => {
  const statusesCopy = copyObject(state.statuses);

  statusesCopy.byProjectId[projectId] = { ...(statusesCopy.byProjectId[projectId] || {}), ...statuses };

  return statusesCopy;
};

const addCreatingAttachment = (
  state: AttachmentsCollection,
  projectId: number,
  attachmentPreview: AttachmentPreview,
): CreatingAttachments => {
  const creatingAttachmentsCopy = { ...state.creatingAttachments };

  if (!creatingAttachmentsCopy.byProjectId[projectId]) creatingAttachmentsCopy.byProjectId[projectId] = {};
  creatingAttachmentsCopy.byProjectId[projectId][attachmentPreview.fileName] = attachmentPreview;

  return creatingAttachmentsCopy;
};

const removeCreatingAttachment = (
  state: AttachmentsCollection,
  projectId: number,
  attachmentName: string,
): CreatingAttachments => {
  const creatingAttachmentsCopy = { ...state.creatingAttachments };

  delete creatingAttachmentsCopy.byProjectId[projectId][attachmentName];

  return creatingAttachmentsCopy;
};

const setAttachmentsCollectionStatuses = (
  state: AttachmentsCollection,
  projectId: number,
  statuses: Partial<EntityCollectionStatus>,
): AttachmentsCollection => {
  return {
    ...state,
    statuses: resolveStatuses(projectId, state, statuses),
  };
};

const setCreatingAttachments = (
  state: AttachmentsCollection,
  projectId: number,
  statuses: Partial<EntityCollectionStatus>,
  attachmentPreview: AttachmentPreview,
): AttachmentsCollection => {
  return {
    ...state,
    statuses: resolveStatuses(projectId, state, statuses),
    creatingAttachments: addCreatingAttachment(state, projectId, attachmentPreview),
  };
};

const attachmentInitilizer = (attachmentData: IAttachment): Attachment => new Attachment(attachmentData);

const setAllAttachments = (
  state: AttachmentsCollection,
  projectId: number,
  attachmentsPayload: IAttachment[],
): AttachmentsCollection => {
  const newAttachmentsMap = toObjectMap<Attachment>(attachmentsPayload, attachmentInitilizer);

  const entitiesCopy = {
    ...state.entities,
    ...newAttachmentsMap,
  };

  const lookupsCopy = copyObject(state.lookups);

  Object.values(newAttachmentsMap).forEach((attachment: Attachment) => {
    lookupsCopy.byProjectId[projectId] = ArrayUtil.concatInDistinctArray(
      lookupsCopy.byProjectId[projectId],
      attachment.id,
    );
  });

  return {
    ...state,
    statuses: resolveStatuses(projectId, state, { error: false, loaded: true, loading: false }),
    entities: entitiesCopy,
    lookups: lookupsCopy,
  };
};

const setAttachment = (
  state: AttachmentsCollection,
  projectId: number,
  attachmentPayload: IAttachment,
  isCreated?: boolean,
): AttachmentsCollection => {
  const newAttachment = attachmentInitilizer(attachmentPayload);

  const entitiesCopy = {
    ...state.entities,
    [newAttachment.id]: newAttachment,
  };

  const lookupsCopy = copyObject(state.lookups);

  lookupsCopy.byProjectId[projectId] = ArrayUtil.concatInDistinctArray(
    lookupsCopy.byProjectId[projectId],
    newAttachment.id,
  );

  return {
    ...state,
    statuses: resolveStatuses(projectId, state, { error: false, creating: false, updating: false }),
    entities: entitiesCopy,
    lookups: lookupsCopy,
    creatingAttachments: isCreated
      ? removeCreatingAttachment(state, projectId, newAttachment.fileName)
      : state.creatingAttachments,
  };
};

const removeAttachment = (
  state: AttachmentsCollection,
  projectId: number,
  attachmentId: number,
): AttachmentsCollection => {
  const entitiesCopy = { ...state.entities };
  const lookupsCopy = copyObject(state.lookups);

  delete entitiesCopy[attachmentId];

  lookupsCopy.byProjectId[projectId] = lookupsCopy.byProjectId[projectId].filter(id => id !== attachmentId);

  return {
    ...state,
    statuses: resolveStatuses(projectId, state, { error: false, removing: false }),
    entities: entitiesCopy,
    lookups: lookupsCopy,
  };
};

export const attachmentsReducers = handleActions(
  {
    // Load

    [toStarted(AttachmentsActionType.Load)]: (state: AttachmentsCollection, action: AttachmentAction) =>
      setAttachmentsCollectionStatuses(state, action.meta.projectId, { loading: true }),

    [toFailed(AttachmentsActionType.Load)]: (state: AttachmentsCollection, action: AttachmentAction) =>
      setAttachmentsCollectionStatuses(state, action.meta.projectId, { loading: false, error: true }),

    [toSuccess(AttachmentsActionType.Load)]: (state: AttachmentsCollection, action: AttachmentAction) =>
      setAllAttachments(state, action.meta.projectId, action.payload as IAttachment[]),

    // Create

    [toStarted(AttachmentsActionType.Create)]: (state: AttachmentsCollection, action: AttachmentAction) =>
      setCreatingAttachments(
        state,
        action.meta.projectId,
        { creating: true },
        {
          ...action.meta,
          loading: true,
        },
      ),

    [toFailed(AttachmentsActionType.Create)]: (state: AttachmentsCollection, action: AttachmentAction) =>
      setCreatingAttachments(
        state,
        action.meta.projectId,
        { creating: false, error: true },
        {
          ...action.meta,
          error: true,
          loading: false,
        },
      ),

    [toSuccess(AttachmentsActionType.Create)]: (state: AttachmentsCollection, action: AttachmentAction) =>
      setAttachment(state, action.meta.projectId, action.payload as IAttachment, true),

    // Update

    [toStarted(AttachmentsActionType.Update)]: (state: AttachmentsCollection, action: AttachmentAction) =>
      setAttachmentsCollectionStatuses(state, action.meta.projectId, { updating: true }),

    [toFailed(AttachmentsActionType.Update)]: (state: AttachmentsCollection, action: AttachmentAction) =>
      setAttachmentsCollectionStatuses(state, action.meta.projectId, { updating: false, error: true }),

    [toSuccess(AttachmentsActionType.Update)]: (state: AttachmentsCollection, action: AttachmentAction) =>
      setAttachment(state, action.meta.projectId, action.payload as IAttachment),

    // Delete

    [toStarted(AttachmentsActionType.Delete)]: (state: AttachmentsCollection, action: AttachmentAction) =>
      setAttachmentsCollectionStatuses(state, action.meta.projectId, { removing: true }),

    [toFailed(AttachmentsActionType.Delete)]: (state: AttachmentsCollection, action: AttachmentAction) =>
      setAttachmentsCollectionStatuses(state, action.meta.projectId, { removing: false, error: true }),

    [toSuccess(AttachmentsActionType.Delete)]: (state: AttachmentsCollection, action: AttachmentAction) =>
      removeAttachment(state, action.meta.projectId, action.meta.attachmentId),

    // DeletePreview

    [toSuccess(AttachmentsActionType.DeletePreview)]: (state: AttachmentsCollection, action: AttachmentAction) => ({
      ...state,
      creatingAttachments: removeCreatingAttachment(state, action.meta.projectId, action.meta.fileName),
    }),
  },
  DefaultAttachmentsCollection,
);
