import React, { useState } from 'react';
import { Alert, Box, Button, Typography } from '@mui/material';
import { LoadingButton } from '@priz/shared/src/components/loading-button/component';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { WorkspaceApi } from '../../workspace/services';
import { useQuery, useQueryClient } from 'react-query';
import { formatRelative } from 'date-fns';
import { DialogsActions } from '../../dialogs/store/actions';
import { DialogType } from '../../dialogs/store/model';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { dateFnsLocaleMap, Locales } from '@priz/shared/src/models/locales';
import { SeatsQuantitySelect } from '../seats-quantity-select/component';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { WorkspaceActions } from '../../workspace/store/actions';
import { WorkspaceSelectors } from '../../workspace/store/selectors';

const formatRelativeLocale = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: 'dd.MM.yyyy',
};

interface ManageSeatsProps {
  membersCount: number;
  seatsCount: number;
  onClose?: () => void;
}

export const ManageSeats: React.FC<ManageSeatsProps> = ({ membersCount, seatsCount, onClose }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [newSeatsCount, setNewSeatsCount] = useState(seatsCount);

  const isUpdating = useSelector(WorkspaceSelectors.isUpdating);

  const currentLocale = (LocalStorageService.getItem(LocalStorageKey.Locale) as Locales) || Locales.EN;

  const locale = {
    ...dateFnsLocaleMap[currentLocale],
    formatRelative: token => formatRelativeLocale[token],
  };

  const {
    isLoading: isLoadingPreview,
    data: preview,
    isFetching: isFetchingPreview,
  } = useQuery(
    ['subscription-update-preview', newSeatsCount],
    () => WorkspaceApi.subscriptionUpdatePreview(newSeatsCount),
    {
      refetchOnWindowFocus: false,
      enabled: newSeatsCount !== seatsCount,
    },
  );

  const {
    isLoading: isLoadingPm,
    data: paymentMethod,
    isFetching: isFetchingPm,
  } = useQuery(['default-payment-method'], () => WorkspaceApi.getPaymentMethod(), {
    refetchOnWindowFocus: false,
    enabled: true,
  });

  const submitHandler = () => {
    dispatch(WorkspaceActions.setLicensedSeats(newSeatsCount));
    queryClient.invalidateQueries(['upcoming-invoice']);
    queryClient.invalidateQueries(['current-plan-details']);
  };

  const closeHandler = () => {
    if (onClose) {
      onClose();
    } else {
      dispatch(DialogsActions.close(DialogType.ManageSeats));
    }
  };

  return (
    <Box>
      <Box mb={2}>
        <SeatsQuantitySelect
          value={newSeatsCount}
          minNumber={membersCount}
          onQuantitySelect={n => setNewSeatsCount(n)}
          helperText={`Set the new number of licensed seats. It cannot be less than ${membersCount}`}
          variant={'outlined'}
          fullWidth
        />
      </Box>

      {isFetchingPreview && (
        <Box py={1} position={'relative'}>
          <LoadingOverlay loading={true} backdropStyles={{ position: 'relative', background: 'transparent' }} />
        </Box>
      )}

      {!isFetchingPreview && seatsCount != newSeatsCount && preview && (
        <Alert severity="success" color="info">
          <Typography variant={'body2'} component={'div'}>
            You will be charged{' '}
            <strong>
              <CurrencyFormat
                value={preview.amountDue / 100}
                displayType={'text'}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </strong>{' '}
            <strong>{formatRelative(preview.prorationDate * 1000, new Date(), { locale })}</strong> for{' '}
            <strong>{newSeatsCount - seatsCount}</strong> additional seat bringing you up to a total of{' '}
            <strong>{newSeatsCount}</strong> seats.
          </Typography>
          {paymentMethod && (
            <Typography variant={'body2'} component={'div'}>
              Charge to a card ending with <strong>{paymentMethod.card.last4}</strong>
            </Typography>
          )}
        </Alert>
      )}

      <ButtonsWrap justifyContent={'flex-end'} mt={2}>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={closeHandler}>
          <Trans>Close</Trans>
        </Button>
        <LoadingButton
          loading={isUpdating}
          disabled={
            isLoadingPreview || isFetchingPreview || isLoadingPm || isFetchingPm || seatsCount === newSeatsCount
          }
          onClick={submitHandler}
        />
      </ButtonsWrap>
    </Box>
  );
};
