import React, { useState, useEffect } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useSelector, useDispatch } from 'react-redux';
import { User } from '@priz/shared/src/models/security/user';
import { useStyles } from './styles';
import { Box, Button, Slider, Tooltip, Typography } from '@mui/material';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { Trans, useTranslation } from 'react-i18next';
import { UserActions } from '../../user/store/actions/user.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import mime from 'mime';

import { ReactComponent as EditIcon } from '../../../assets/icons/pg-edit.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { ReactComponent as CrossFramedIcon } from '../../../assets/icons/cross-framed.svg';

export interface UserAvatarEditorProps {
  user: User;
}

interface RangeSliderProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

function ValueLabelComponent(props: RangeSliderProps) {
  const { children, open, value } = props;
  const { t } = useTranslation();
  const title = `${t('zoom')}: ${Math.round(value * 100)}%`;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={title}>
      {children}
    </Tooltip>
  );
}

const acceptableFiles = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
};

export const UserAvatarEditor: React.FC<UserAvatarEditorProps> = ({ user }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { firstName, lastName, avatarUrl } = user?.profile || {};

  const [editor, setEditor] = useState<AvatarEditor>(null);
  const [isEditing, setIsEditing] = useState(null);
  const [avatarImageFile, setAvatarImageFile] = useState<File | null>(null);
  const [avatarPreview, setAvatarPreview] = useState(avatarUrl);
  const [scale, setScale] = useState(1);
  const fullProfileName = [firstName, lastName].filter(item => !!item).join(' ');
  const isAvatarUpdating = useSelector(UserSelectors.isAvatarUpdating);

  useEffect(() => {
    if (!isAvatarUpdating) setIsEditing(false);
  }, [isAvatarUpdating]);

  useEffect(() => {
    if (!avatarPreview && avatarUrl) {
      setAvatarPreview(avatarUrl);
    }
  }, [avatarUrl, avatarPreview]);

  const onFileChangeHandler = e => {
    const file = e.target.files[0];
    const extension = mime.getExtension(file.type);

    if (Object.keys(acceptableFiles).includes(extension)) {
      setAvatarImageFile(file);
      setScale(1);
      setIsEditing(true);
    }
  };

  const cancelAvatarEditing = () => {
    setIsEditing(false);
  };

  const saveAvatar = () => {
    const imageBase64 = editor?.getImage()?.toDataURL('image/jpeg');

    if (imageBase64) {
      fetch(imageBase64)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], `user-${user.id}-avatar-${new Date().getTime()}.jpeg`, {
            type: blob.type,
          });

          setAvatarPreview(imageBase64);
          dispatch(UserActions.updateCurrentUserAvatar(file));
        });
    }
  };

  const mouseWheelHandler = (_e, newValue) => {
    if (scale !== newValue) setScale(newValue);
  };

  const editorRefHandler = (el: AvatarEditor) => {
    if (el) setEditor(el);
  };

  return (
    <Box className={styles.root}>
      {isEditing ? (
        <>
          <AvatarEditor
            ref={editorRefHandler}
            className={`${styles.editor}${isAvatarUpdating ? ' _disabled' : ''}`}
            image={avatarImageFile}
            width={160}
            height={160}
            border={0}
            borderRadius={80}
            color={[60, 60, 60, 1]}
            scale={scale}
            rotate={0}
          />

          <Box mt={1} width={'100%'}>
            <Slider
              disabled={isAvatarUpdating}
              value={scale}
              min={1}
              max={2}
              step={0.1}
              onChange={mouseWheelHandler}
              components={{
                ValueLabel: ValueLabelComponent,
              }}
              color={'primary'}
            />
          </Box>

          <ButtonsWrap spacing={1}>
            <Button
              variant={'pg_button_link'}
              className={`${styles.button} _bright-disabled`}
              startIcon={!isAvatarUpdating ? <CheckIcon /> : null}
              onClick={saveAvatar}
              disabled={isAvatarUpdating}
              endIcon={isAvatarUpdating ? <FontAwesomeIcon icon={faSpinner} size={'xs'} spin={true} /> : null}
            >
              <Trans>Save</Trans>
            </Button>

            <Button
              variant={'pg_button_link'}
              className={`${styles.button} _bright-disabled`}
              startIcon={<CrossFramedIcon />}
              onClick={cancelAvatarEditing}
              disabled={isAvatarUpdating}
            >
              <Trans>Cancel</Trans>
            </Button>
          </ButtonsWrap>
        </>
      ) : (
        <>
          <Box
            className={styles.img}
            style={avatarPreview ? { backgroundImage: `url(${avatarPreview})` } : undefined}
          />
          <ButtonsWrap mt={0}>
            <Button
              variant={'pg_button_link'}
              className={styles.button}
              {...{ component: 'label' }}
              startIcon={<EditIcon />}
              disabled={isAvatarUpdating}
            >
              <Trans>EDIT PHOTO</Trans>
              <input
                name={'file'}
                type={'file'}
                accept={Object.values(acceptableFiles).join(', ')}
                onChange={onFileChangeHandler}
                hidden
              />
            </Button>
          </ButtonsWrap>
        </>
      )}

      {!!fullProfileName.length && (
        <Box mt={1}>
          <Typography variant="h6" component="span" className={styles.text}>
            {fullProfileName}
          </Typography>
        </Box>
      )}

      {user?.username && (
        <Box mt={1}>
          <Typography variant="body2" component="span" className={styles.text}>
            {user.username}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
