import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  formWrapper: {
    paddingLeft: 16,
    borderLeftStyle: 'solid',
    borderLeftWidth: 8,
    transition: 'border-left-color .15s ease',
  },
  borderLeftBlue: {
    borderLeftColor: pgColorScheme.blueLight2,
  },
  borderLeftGreen: {
    borderLeftColor: pgColorScheme.green,
  },
});
