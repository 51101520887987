import React from 'react';
import { useStyles } from './styles';
import { Box, BoxProps } from '@mui/material';
import SimpleBar from 'simplebar-react';
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross-300-fr';

interface AuthFormContainerProps {
  contentBoxProps?: BoxProps;
  overflowScroll?: boolean;
  onClose?: () => void;
}

export const AuthFormContainer: React.FC<AuthFormContainerProps> = ({
  children,
  contentBoxProps,
  overflowScroll,
  onClose,
}) => {
  const styles = useStyles();
  const rootClassNames = [styles.root];

  if (overflowScroll) rootClassNames.push(styles.overflow);

  const closeHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Box className={rootClassNames.join(' ')} pt={onClose ? 6 : 4} pb={4} pl={{ xs: 1, sm: 9 }} pr={1}>
      {onClose && (
        <div className={styles.closeButton} onClick={closeHandler}>
          <CrossIcon className={styles.closeButtonIcon} />
        </div>
      )}

      <div className={styles.sizerContainer}>
        <Box className={styles.scrollContainer}>
          <SimpleBar forceVisible="y" autoHide={false}>
            <Box pb={3} pl={1} pr={{ xs: 1, sm: 9 }}>
              <Box className={styles.contentContainer} {...contentBoxProps}>
                {children}
              </Box>
            </Box>
          </SimpleBar>
        </Box>
      </div>
    </Box>
  );
};
