import React, { ChangeEvent } from 'react';
import { CircularProgress, FormControlLabel, Grid, Switch, Tooltip } from '@mui/material';
import { Trans } from 'react-i18next';

interface ConnectAccountButtonProps {
  checked: boolean;
  text: string;
  onEnable: () => void;
  onDisable: () => void;
  initialTooltip?: string;
  isConnectedAndLast?: boolean;
  isConnectedAndActive?: boolean;
  isActive?: string;
  disabled?: boolean;
  loading?: boolean;
}

export const ConnectAccountButton: React.FC<ConnectAccountButtonProps> = ({
  checked,
  text,
  onEnable,
  onDisable,
  initialTooltip,
  isConnectedAndLast,
  isConnectedAndActive,
  disabled,
  loading,
}) => {
  let tooltip = initialTooltip || '';

  if (isConnectedAndLast) {
    tooltip = 'Last account connection cannot be deleted';
  }

  if (isConnectedAndActive) {
    tooltip = 'Active account connection cannot be deleted';
  }

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onEnable();
    } else {
      onDisable();
    }
  };

  return (
    <Grid container alignItems={'center'}>
      <Grid item>
        <Tooltip
          title={tooltip.length && !loading ? <Trans>{tooltip}</Trans> : ''}
          placement={'right'}
          arrow
          disableInteractive
        >
          <div>
            <FormControlLabel
              checked={checked}
              control={<Switch onChange={changeHandler} />}
              label={<Trans>{text}</Trans>}
              disabled={disabled}
            />
          </div>
        </Tooltip>
      </Grid>

      <Grid item>{loading && <CircularProgress size={20} sx={{ display: 'block' }} />}</Grid>
    </Grid>
  );
};
