import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  highlightSubstrate: {
    position: 'absolute',
    borderRadius: 4,
    zIndex: 1,
  },
  filled: {
    ['&._green']: {
      backgroundColor: alpha(pgColorScheme.green, 0.3),
    },
    ['&._blue']: {
      backgroundColor: alpha(pgColorScheme.blue, 0.3),
    },
    ['&._orange']: {
      backgroundColor: alpha(pgColorScheme.orange, 0.3),
    },
    ['&._red']: {
      backgroundColor: alpha(pgColorScheme.coral, 0.3),
    },
  },
  outlined: {
    borderStyle: 'solid',
    borderWidth: 2,

    ['&._green']: {
      borderColor: alpha(pgColorScheme.green, 0.5),
    },
    ['&._blue']: {
      borderColor: alpha(pgColorScheme.blue, 0.5),
    },
    ['&._orange']: {
      borderColor: alpha(pgColorScheme.orange, 0.5),
    },
    ['&._red']: {
      borderColor: alpha(pgColorScheme.coral, 0.5),
    },
  },
  contentContainer: {
    position: 'relative',
    zIndex: 2,
  },
});
