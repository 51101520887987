import { ApaUtilization, IApaUtilization } from '@priz/shared/src/models/tools/apa';
import axios, { AxiosResponse } from 'axios';

const update = (apaUtilization: ApaUtilization): Promise<IApaUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/apa/${apaUtilization.id}`, {
      title: apaUtilization.title,
      customer: apaUtilization.customer,
      customerDescription: apaUtilization.customerDescription,
    })
    .then((response: AxiosResponse<IApaUtilization>) => response.data);

const doDelete = (utilizationId: number): Promise<any> =>
  axios.delete(`/v1/api/<ws-id>/team/<t-id>/apa/${utilizationId}`);

export const ApaUtilizationApi = {
  delete: doDelete,
  update,
};
