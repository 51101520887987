import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../store/app.state';
import { Chat, ChatState } from '../model';
import { ArrayUtils } from '@priz/shared/src/utils/common';

const chatStateSelector = (state: AppState): ChatState => state.chat;

const chatEntitiesSelector = createSelector(chatStateSelector, state => state.entities);
const chatLookupsSelector = createSelector(chatStateSelector, state => state.lookups);
const chatStatusesSelector = createSelector(chatStateSelector, state => state.statuses);
const activeChatIdSelector = createSelector(chatStateSelector, state => state.activeChatId);

const chatIdsByProjectIdSelector = (projectId: number) =>
  createSelector(chatLookupsSelector, lookup => lookup.byProjectId[projectId] || []);

const getActiveChat = createSelector([chatEntitiesSelector, activeChatIdSelector], (entityMap, id) => entityMap[id]);

const getById = (chartId: number): Selector<AppState, Chat> =>
  createSelector(chatEntitiesSelector, entityMap => entityMap[chartId]);

const getAllByProjectId = (projectId: number): Selector<AppState, Chat[]> =>
  createSelector([chatEntitiesSelector, chatIdsByProjectIdSelector(projectId)], (entityMap, ids) =>
    ids.map(id => entityMap[id]).sort(ArrayUtils.sorterByDateCreated),
  );

const isLoading = (projectId: number): Selector<AppState, boolean> =>
  createSelector(chatStatusesSelector, statuses => statuses.byProjectId[projectId]?.loading || false);
const isLoaded = (projectId: number): Selector<AppState, boolean> =>
  createSelector(chatStatusesSelector, statuses => statuses.byProjectId[projectId]?.loaded || false);

const isAnySaving = (projectId: number): Selector<AppState, boolean> =>
  createSelector(chatStatusesSelector, statuses => statuses.byProjectId[projectId]?.saving || false);
const isAnySaved = (projectId: number): Selector<AppState, boolean> =>
  createSelector(chatStatusesSelector, statuses => statuses.byProjectId[projectId]?.saved || false);

const isAnyUpdating = (projectId: number): Selector<AppState, boolean> =>
  createSelector(chatStatusesSelector, statuses => statuses.byProjectId[projectId]?.updating || false);
const isAnyUpdated = (projectId: number): Selector<AppState, boolean> =>
  createSelector(chatStatusesSelector, statuses => statuses.byProjectId[projectId]?.updated || false);

const isAnyCreating = (projectId: number): Selector<AppState, boolean> =>
  createSelector(chatStatusesSelector, statuses => statuses.byProjectId[projectId]?.creating || false);
const isAnyCreated = (projectId: number): Selector<AppState, boolean> =>
  createSelector(chatStatusesSelector, statuses => statuses.byProjectId[projectId]?.created || false);

const isAnyRemoving = (projectId: number): Selector<AppState, boolean> =>
  createSelector(chatStatusesSelector, statuses => statuses.byProjectId[projectId]?.removing || false);
const isAnyRemoved = (projectId: number): Selector<AppState, boolean> =>
  createSelector(chatStatusesSelector, statuses => statuses.byProjectId[projectId]?.removed || false);

const isSavingById = (projectId: number, chatId: number): Selector<AppState, boolean> =>
  createSelector(chatStatusesSelector, statuses => (statuses.byProjectId[projectId]?.savingIds || []).includes(chatId));
const isSavedById = (projectId: number, chatId: number): Selector<AppState, boolean> =>
  createSelector(chatStatusesSelector, statuses => (statuses.byProjectId[projectId]?.savedIds || []).includes(chatId));

const isUpdatingById = (projectId: number, chatId: number): Selector<AppState, boolean> =>
  createSelector(chatStatusesSelector, statuses =>
    (statuses.byProjectId[projectId]?.updatingIds || []).includes(chatId),
  );
const isUpdatedById = (projectId: number, chatId: number): Selector<AppState, boolean> =>
  createSelector(chatStatusesSelector, statuses =>
    (statuses.byProjectId[projectId]?.updatedIds || []).includes(chatId),
  );

const isRemovingById = (projectId: number, chatId: number): Selector<AppState, boolean> =>
  createSelector(chatStatusesSelector, statuses =>
    (statuses.byProjectId[projectId]?.removingIds || []).includes(chatId),
  );
const isRemovedById = (projectId: number, chatId: number): Selector<AppState, boolean> =>
  createSelector(chatStatusesSelector, statuses =>
    (statuses.byProjectId[projectId]?.removedIds || []).includes(chatId),
  );

const isErrorById = (projectId: number, chatId: number): Selector<AppState, boolean> =>
  createSelector(chatStatusesSelector, statuses => (statuses.byProjectId[projectId]?.errorIds || []).includes(chatId));

export const ChatSelectors = {
  getById,
  getActiveChat,
  getAllByProjectId,
  isLoading,
  isLoaded,
  isAnySaving,
  isAnySaved,
  isAnyUpdating,
  isAnyUpdated,
  isAnyCreating,
  isAnyCreated,
  isAnyRemoving,
  isAnyRemoved,
  isSavingById,
  isSavedById,
  isUpdatingById,
  isUpdatedById,
  isRemovingById,
  isRemovedById,
  isErrorById,
};
