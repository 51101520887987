import React, { useState } from 'react';
import { Idea } from '@priz/shared/src/models/idea';
import { Box, BoxProps, Button, IconButton, IconButtonProps, Tooltip, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { IdeaCommentsDialog } from '../idea-comments-dialog/component';
import {
  ChatBubbleOutlineOutlined,
  MarkChatReadOutlined,
  QuickreplyOutlined,
  SmsOutlined,
  MarkChatUnreadOutlined,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { IdeasCommentsSelectors } from '../store';
import { resolveCommentsSummary } from '../utils';
import { resolveValueByColorType, ValueColorType } from '@priz/shared/src/utils/common';
import { useStyles } from './styles';

interface IdeaCommentsButtonProps extends BoxProps {
  idea: Idea;
  compact?: boolean;
  disabled?: boolean;
}

export const IdeaCommentsButton: React.FC<IdeaCommentsButtonProps> = ({ idea, compact, disabled, ...rest }) => {
  const styles = useStyles();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const comments = useSelector(IdeasCommentsSelectors.getAllByIdeaId(idea.id));

  const { rating, validCount } = resolveCommentsSummary(comments);

  const colorValuesMap: {
    [key in ValueColorType]: {
      className: string;
      color: IconButtonProps['color'];
      icon: JSX.Element;
    };
  } = {
    [ValueColorType.Green]: {
      className: styles.ratingGreen,
      color: 'success',
      icon: <MarkChatReadOutlined fontSize={'small'} />,
    },
    [ValueColorType.Blue]: {
      className: styles.ratingBlue,
      color: 'primary',
      icon: <SmsOutlined fontSize={'small'} />,
    },
    [ValueColorType.Red]: {
      className: styles.ratingRed,
      color: 'error',
      icon: <QuickreplyOutlined fontSize={'small'} />,
    },
  };

  const value = resolveValueByColorType({
    value: rating,
    green: 70,
    blue: 30,
    valuesMap: colorValuesMap,
  });

  const ratingLabelClassNames = [styles.rating, validCount ? value.className : styles.ratingNotDefined];

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      {compact && (
        <Tooltip
          title={
            <>
              <Trans>Comments</Trans>
              {': '}
              {comments.length}

              {!!validCount && (
                <>
                  {', '}
                  <Trans>Rating</Trans>
                  {': '}
                  {rating}%
                </>
              )}
            </>
          }
          placement={'top'}
          disableInteractive
          arrow
        >
          <IconButton color={validCount ? value.color : 'default'} size={'small'} onClick={openDialog}>
            {validCount ? (
              value.icon
            ) : comments.length ? (
              <MarkChatUnreadOutlined fontSize={'small'} />
            ) : (
              <ChatBubbleOutlineOutlined fontSize={'small'} />
            )}
          </IconButton>
        </Tooltip>
      )}

      {!compact && (
        <Box display={'flex'} alignItems={'stretch'} {...rest}>
          {!!validCount && (
            <Tooltip title={<Trans>Rating</Trans>} placement={'top'} disableInteractive arrow>
              <Typography variant={'body1'} component={Box} px={2} className={ratingLabelClassNames.join(' ')}>
                {rating}%
              </Typography>
            </Tooltip>
          )}

          <Button
            variant={'outlined'}
            size={'small'}
            startIcon={<ChatBubbleOutlineOutlined />}
            className={validCount ? styles.button : undefined}
            onClick={openDialog}
            disabled={disabled}
          >
            <Trans>Comments</Trans>
            {comments.length ? ` (${comments.length})` : ''}
          </Button>
        </Box>
      )}

      <IdeaCommentsDialog idea={idea} open={isDialogOpen} onClose={closeDialog} />
    </>
  );
};
