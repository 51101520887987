import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

import assistantImg from '@priz/shared/src/assets/images/assistant-simple.svg';

export const useStyles = makeStyles({
  root: {
    backgroundColor: pgColorScheme.blue,
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundImage: `url(${assistantImg})`,
    backgroundPosition: '20% -12%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '178%',
  },
});
