import React from 'react';
import { ErrorPage, ErrorPageType } from '../../error-page/component';
import { ToolTypeDataMap } from '@priz/shared/src/data/tool-type-data-map';
import { ToolType, ToolUtilization } from '@priz/shared/src/models/tools';
import { FortyPrinciples } from '../../forty-principles/forty-principles-utilization/component';
import { PureRrmUtilizationComponent } from '../../rrm/RrmUtilizationComponent';
import { PureUimToolUtilization } from '../../uim/uim-tool-utilization/component';
import { FiveWhys } from '../../five-whys/five-whys-utilization/component';
import { ApaCustomer } from '../../apa/apa-customer/component';
import { ApaReport } from '../../apa/apa-report/component';
import { ApaActions } from '../../apa/apa-actions/component';
import { ApaProcessAction } from '../../apa/apa-process-action/component';
import { EbsView } from '../../ebs/ebs-view/component';
import { NineWindowsPage } from '../../nine-windows/nine-windows-page/component';
import { SfmView } from '../../sfm/sfm-view/component';
import { PfmView } from '../../pfm/pfm-view/component';
import { PMapView } from '../../pmap/pmap-view/component';
import { CauseAndEffectChainView } from '../../cec/cause-and-effect-chain-view/component';
import { setDocumentTitle } from '../../utils';

interface PlaygroundToolResolver {
  tool: ToolUtilization;
  sub?: string;
  subId?: string;
}

export const PlaygroundToolResolver: React.FC<PlaygroundToolResolver> = ({ tool, sub, subId }) => {
  const toolName = ToolTypeDataMap[tool.type].name;

  if (!sub && !subId) {
    setDocumentTitle(toolName);

    if (tool.type === ToolType.TOOL_40_PRINCIPLES) {
      return <FortyPrinciples utilizationId={tool.id} />;
    }

    if (tool.type === ToolType.RRM) {
      return <PureRrmUtilizationComponent utilizationId={tool.id} />;
    }

    if (tool.type === ToolType.UIM) {
      return <PureUimToolUtilization utilizationId={tool.id} />;
    }

    if (tool.type === ToolType.FIVE_WHYS) {
      return <FiveWhys utilizationId={tool.id} />;
    }

    if (tool.type === ToolType.APA) {
      return <ApaCustomer utilizationId={tool.id} />;
    }

    if (tool.type === ToolType.EBS) {
      return <EbsView utilizationId={tool.id} />;
    }

    if (tool.type === ToolType.NINE_WINDOWS) {
      return <NineWindowsPage utilizationId={tool.id} />;
    }

    if (tool.type === ToolType.CEC) {
      return <CauseAndEffectChainView utilizationId={tool.id} />;
    }

    if (tool.type === ToolType.P_MAP) {
      return <PMapView utilizationId={tool.id} />;
    }

    if (tool.type === ToolType.SFM) {
      return <SfmView utilizationId={tool.id} />;
    }

    if (tool.type === ToolType.PFM) {
      return <PfmView utilizationId={tool.id} />;
    }
  }

  if (sub && !subId) {
    if (tool.type === ToolType.APA && sub === 'report') {
      setDocumentTitle('APA Report');
      return <ApaReport utilizationId={tool.id} />;
    }
    if (tool.type === ToolType.APA && sub === 'actions') {
      setDocumentTitle('APA Actions');
      return <ApaActions utilizationId={tool.id} />;
    }
  }

  if (sub && subId) {
    if (tool.type === ToolType.APA && sub === 'action') {
      setDocumentTitle('APA Action - Process');
      return <ApaProcessAction utilizationId={tool.id} actionId={+subId} />;
    }
  }

  return <ErrorPage type={ErrorPageType.NotFound} renderNavbar={false} />;
};
