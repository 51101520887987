import { UseFormTrigger } from 'react-hook-form/dist/types/form';
import { getTextAndImages } from './quill';

const constructRequiredValidationOption = (
  message = 'Field is required',
): {
  required: string;
} => {
  return {
    required: message,
  };
};

const constructPatternValidationOption = (
  pattern: RegExp,
  message: string,
): {
  pattern: {
    value: RegExp;
    message: string;
  };
} => {
  return {
    pattern: {
      value: pattern,
      message: message,
    },
  };
};

const constructPasswordValidationOption = (): {
  pattern: {
    value: RegExp;
    message: string;
  };
} => {
  return {
    pattern: {
      value: /^(?=.*[a-z])(?=.*\d).{8,}$/,
      message: 'Must be at least 8 characters long, contain letters and numbers',
    },
  };
};

const constructPhoneNumberValidationOption = (): {
  pattern: {
    value: RegExp;
    message: string;
  };
} => {
  return {
    pattern: {
      value: /^\+?[ \-()0-9]{7,}$/,
      message: 'Wrong phone number',
    },
  };
};

const constructTriggerValidationRule = (
  trigger: UseFormTrigger<any>,
  name: string,
): {
  triggerValidation: () => Promise<boolean>;
} => {
  return {
    triggerValidation: async () => {
      await trigger(name);
      return true;
    },
  };
};

const constructMatchValidationRule = (
  getValues: () => string | number,
  message: string,
): {
  matchWithField: (v: string | number) => boolean | string;
} => {
  return {
    matchWithField: v => getValues() === v || message,
  };
};

const constructNotEmptyValidationRule = (
  message: string,
): {
  arrayIsNotEmpty: (v: []) => boolean | string;
} => {
  return {
    arrayIsNotEmpty: v => !!v.length || message,
  };
};

const constructHasTextValidationRule = (
  message: string,
): {
  htmlIsNotEmpty: (v: string) => boolean | string;
} => {
  return {
    htmlIsNotEmpty: v => !!getTextAndImages(v)?.length || message,
  };
};

export const validatorRules = {
  required: constructRequiredValidationOption,
  password: constructPasswordValidationOption,
  phoneNumber: constructPhoneNumberValidationOption,
  pattern: constructPatternValidationOption,
};

export const validateOptions = {
  trigger: constructTriggerValidationRule,
  match: constructMatchValidationRule,
  hasItems: constructNotEmptyValidationRule,
  hasText: constructHasTextValidationRule,
};

export const validationPatterns = {
  email: /\S+@\S+\.\S+/,
};
