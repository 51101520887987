import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from './styles';
import { Perception } from '@priz/shared/src/models/tools/pmap/perception';
import { Network } from '@priz/shared/src/lib/vis/esnext';
import { options, viewOptions } from './options';
import { NetworkSetCenterButton } from '@priz/shared/src/components/network-set-center-button/component';
import { VisScreenshotButton } from '../../react/network/vis-screenshot-button/component';
import { constructPerceptionMapNetworkData } from '@priz/shared/src/utils/pmap';
import { FullscreenContainer } from '@priz/shared/src/components/fullscreen-container/component';
import { FullscreenButton } from '@priz/shared/src/components/fullscreen-button/component';
import { CanvasControlsContainer, CanvasControlsGroup } from '@priz/shared/src/components/canvas-controls';

interface PMapDiagramProps {
  perceptions: Perception[];
  viewMode?: boolean;
}

export const PMapDiagram: React.FC<PMapDiagramProps> = ({ perceptions, viewMode }) => {
  const styles = useStyles();
  const canvasRef = useRef(null);
  const [network, setNetwork] = useState(null);

  useEffect(() => {
    if (network && viewMode) {
      network.setOptions(viewOptions);
    }
  }, [network, viewMode]);

  useEffect(() => {
    if (!network) {
      const networkOptions = {
        ...options,
        ...(viewMode ? viewOptions : {}),
      };

      setNetwork(new Network(canvasRef.current, constructPerceptionMapNetworkData(perceptions), networkOptions));
    } else {
      network.setData(constructPerceptionMapNetworkData(perceptions));
    }
  }, [perceptions]);

  return (
    <FullscreenContainer className={styles.root}>
      <div className={'vis-network-container'} ref={canvasRef} />

      {network && (
        <CanvasControlsContainer>
          <CanvasControlsGroup>
            <NetworkSetCenterButton network={network} />
            <FullscreenButton />
            <VisScreenshotButton network={network} />
          </CanvasControlsGroup>
        </CanvasControlsContainer>
      )}
    </FullscreenContainer>
  );
};
