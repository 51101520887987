import React from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { TemplateCategoryCard } from '../template-category-card/component';
import { TemplateSpiderArrows } from '../template-spider-arrows/component';
import { TemplateCategory, TemplateCompletion, TemplateType } from '@priz/shared/src/models/template';
import { Trans } from 'react-i18next';
import { templateTypeTextMap } from '../data';
import { SettingsRounded } from '@mui/icons-material';
import { TemplateChangeDialogContainer } from '../template-change-dialog-container/component';
import { useStyles } from './styles';

interface TemplateSpiderProps {
  type: TemplateType;
  projectId?: number;
  templateCompletion?: TemplateCompletion;
  interactive?: boolean;
}

export const TemplateSpider: React.FC<TemplateSpiderProps> = ({ type, projectId, interactive, templateCompletion }) => {
  const styles = useStyles();

  return (
    <Grid container spacing={4} justifyContent={'space-evenly'}>
      <Grid item xs={6}>
        <TemplateCategoryCard
          title={'Preparing'}
          templateType={type}
          templateCategory={TemplateCategory.Preparing}
          interactive={interactive}
          templateCompletion={templateCompletion}
          projectId={projectId}
        />
      </Grid>

      <Grid item xs={6}>
        <TemplateCategoryCard
          title={'Activity'}
          templateType={type}
          templateCategory={TemplateCategory.Activity}
          justifyContent={'flex-end'}
          interactive={interactive}
          templateCompletion={templateCompletion}
          projectId={projectId}
        />
      </Grid>

      <Grid item xs={12}>
        <Box display={'flex'} justifyContent={'center'} position={'relative'}>
          <Box
            id={'spider'}
            pl={{ xs: 2, md: 4 }}
            pr={{ xs: 0, md: 2 }}
            py={{ xs: 0, md: 1 }}
            className={styles.templateTypeContainer}
          >
            <Typography variant={'h6'}>
              <Trans>{templateTypeTextMap[type]}</Trans>
            </Typography>

            <Box ml={1}>
              <TemplateChangeDialogContainer projectId={projectId}>
                <IconButton size="medium" color={'inherit'}>
                  <SettingsRounded sx={{ display: 'block' }} fontSize="inherit" />
                </IconButton>
              </TemplateChangeDialogContainer>
            </Box>
          </Box>

          <TemplateSpiderArrows templateCompletion={templateCompletion} templateType={type} />
        </Box>
      </Grid>

      <Grid item xs={6}>
        <TemplateCategoryCard
          title={'Tools'}
          templateType={type}
          templateCategory={TemplateCategory.Tools}
          interactive={interactive}
          templateCompletion={templateCompletion}
          projectId={projectId}
        />
      </Grid>

      <Grid item xs={6}>
        <TemplateCategoryCard
          title={'Result'}
          templateType={type}
          templateCategory={TemplateCategory.Result}
          justifyContent={'flex-end'}
          interactive={interactive}
          templateCompletion={templateCompletion}
          projectId={projectId}
        />
      </Grid>
    </Grid>
  );
};
