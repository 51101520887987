import React from 'react';
import { Agenda } from '../store/model';
import { MeetingStatus } from '@priz/shared/src/models/meetings';
import { AgendaItem, AgendaItemHandlers } from '../agenda-item/component';
import { Box, Button, Paper, Table, TableBody, TableContainer } from '@mui/material';
import { Trans } from 'react-i18next';

interface AgendasListPropsHandlers extends AgendaItemHandlers {
  onAgendaAdd?: () => void;
}

interface AgendasListProps extends AgendasListPropsHandlers {
  agendaItems: Agenda[];
  meetingStatus: MeetingStatus;
  editable?: boolean;
  disabled?: boolean;
  viewMode?: boolean;
}

export const AgendasList: React.FC<AgendasListProps> = ({
  agendaItems,
  meetingStatus,
  onAgendaAdd,
  editable,
  disabled,
  viewMode,
  ...rest
}) => {
  const addHandler = () => {
    if (onAgendaAdd) onAgendaAdd();
  };

  return (
    <>
      {!!agendaItems.length && (
        <TableContainer component={Paper} variant={'outlined'} sx={{ backgroundColor: 'transparent' }}>
          <Table>
            <TableBody>
              {agendaItems.map((agenda, key) => (
                <AgendaItem
                  key={agenda.id}
                  agenda={agenda}
                  meetingStatus={meetingStatus}
                  index={key + 1}
                  editable={editable}
                  disabled={disabled}
                  viewMode={viewMode}
                  {...rest}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {editable && (
        <Box mt={agendaItems.length ? 1 : 0}>
          <Button size={'small'} onClick={addHandler} disabled={disabled}>
            <Trans>{agendaItems.length ? 'Add item' : 'Add agenda'}</Trans>
          </Button>
        </Box>
      )}
    </>
  );
};
