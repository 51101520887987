import React, { useEffect } from 'react';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import { Alert, Box, Button, Chip, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { PartnershipActions } from './store/actions';
import { PartnershipSelectors } from './store/selectors';
import { ReferralsTable } from './referrals-table/components';
import { UserContextSelectors } from '../../security/store/selectors';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { CopyToClipboardButton } from '../../copy-to-clipboard-button/components';
import { ProfitTable } from './profit-table/components';

const resolveRefUrl = (refCode: string): string => {
  return `${window.location.origin}/signup?ref=${refCode}`;
};

export const PartnershipPage: React.FC = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(UserContextSelectors.getCurrentUser);
  const isCurrentUserLoading = useSelector(UserContextSelectors.isLoading);
  const isCurrentUserLoaded = useSelector(UserContextSelectors.isLoaded);
  const partnerProfile = useSelector(PartnershipSelectors.getProfile);
  const isProfileLoading = useSelector(PartnershipSelectors.isProfileLoading);
  const isReferralsLoading = useSelector(PartnershipSelectors.isReferralsLoading);
  const referrals = useSelector(PartnershipSelectors.getReferrals);

  const hasPartnerProfile = typeof currentUser?.partnerProfile?.id !== 'undefined';

  useEffect(() => {
    if (!isCurrentUserLoading && hasPartnerProfile) {
      dispatch(PartnershipActions.loadProfile());
    }
  }, [isCurrentUserLoading, hasPartnerProfile]);

  useEffect(() => {
    if (partnerProfile) {
      dispatch(PartnershipActions.loadReferrals());
    }
  }, [partnerProfile]);

  return (
    <>
      <PageTitleWithDocLink title={<Trans>Partnership</Trans>} />

      <LoadingOverlay
        loading={isCurrentUserLoading || isProfileLoading}
        backdropStyles={{ backgroundColor: 'transparent' }}
      />

      {isCurrentUserLoaded && !hasPartnerProfile && (
        <div>
          <Box mb={2}>
            <Alert severity={'info'}>
              {/* TODO: text */}
              <Trans>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.
              </Trans>
            </Alert>
          </Box>

          {/* TODO: link */}
          <Button variant={'outlined'} component={'a'} href={'https://www.priz.guru/#'} target={'_blank'}>
            {/* TODO: text */}
            <Trans>Request referral code</Trans>
          </Button>
        </div>
      )}

      {partnerProfile && (
        <Box>
          <Box mb={2}>
            <Typography variant={'subtitle2'} mb={1}>
              <Trans>Referral code</Trans>:
            </Typography>

            <Grid container alignItems={'center'} spacing={1}>
              <Grid item>
                <Chip label={partnerProfile.referralCode} />
              </Grid>

              <Grid item>
                <CopyToClipboardButton value={partnerProfile.referralCode} />
              </Grid>
            </Grid>
          </Box>

          <Box mb={2}>
            <Typography variant={'subtitle2'} mb={1}>
              <Trans>Referral link</Trans>:
            </Typography>

            <Grid container alignItems={'center'} spacing={1}>
              <Grid item>
                <Chip label={resolveRefUrl(partnerProfile.referralCode)} />
              </Grid>

              <Grid item>
                <CopyToClipboardButton value={resolveRefUrl(partnerProfile.referralCode)} />
              </Grid>
            </Grid>
          </Box>

          <Box mb={2}>
            <Typography variant={'subtitle2'} mb={1}>
              <Trans>Commission rate</Trans>:
            </Typography>

            <Chip label={`${partnerProfile.commissionRate}%`} />
          </Box>

          <Box mb={2}>
            <Typography variant={'subtitle2'} mb={1}>
              <Trans>Profit</Trans>:
            </Typography>

            <ProfitTable partnerProfile={partnerProfile} />
          </Box>

          <Box>
            <Typography variant={'subtitle2'} mb={1}>
              <Trans>Referrals</Trans>:
            </Typography>

            {isReferralsLoading && (
              <LoadingOverlay
                loading={true}
                backdropStyles={{ backgroundColor: 'transparent', position: 'relative' }}
                progressContainerProps={{ py: 4 }}
              />
            )}

            {!referrals.length && (
              <Alert severity={'info'}>
                {/* TODO: text */}
                <Trans>There are not referrals yet.</Trans>
              </Alert>
            )}

            {!!referrals.length && <ReferralsTable referrals={referrals} />}
          </Box>
        </Box>
      )}
    </>
  );
};
