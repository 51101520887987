import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { dashboardApi } from '../services/dashboard.api';
import { DashboardWidgetCard } from '../dashboard-widget-card/component';
import { ProjectAssigneeActions } from '../../assignment/store/actions';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { Trans } from 'react-i18next';
import { ChevronRight } from '@mui/icons-material';
import { DashboardProject } from './dashboard-project/component';
import { EmptyProjectsList } from '../../project/empty-project-list/component';

interface DashboardProjectsWidgetProps {
  workspaceId: number;
}

export const DashboardProjectsWidget: React.FC<DashboardProjectsWidgetProps> = ({ workspaceId }) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);

  const {
    data = { items: [], totalCount: 0 },
    isLoading,
    isFetching,
  } = useQuery(['dashboard-projects', workspaceId, page], () => dashboardApi.getProjects(page), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    dispatch(ProjectAssigneeActions.fetchAll());
  }, []);

  return (
    <DashboardWidgetCard
      title={'Projects'}
      placeholder={
        <Box py={2}>
          <EmptyProjectsList compact={true} />
        </Box>
      }
      currentPage={page}
      itemsCountPerPage={10}
      totalItemsCount={data.totalCount}
      isLoading={isLoading}
      isFetching={isFetching}
      onChangePage={newPage => {
        setPage(newPage);
      }}
      headerControls={
        <Button component={Link} to={'/projects-and-tools'} size={'small'} endIcon={<ChevronRight />}>
          <Trans>Go to Projects & Tools manager</Trans>
        </Button>
      }
    >
      {data.items.map(item => (
        <DashboardProject key={item.id} project={item} />
      ))}
    </DashboardWidgetCard>
  );
};
