import { handleActions } from 'redux-actions';
import { toSuccess } from '../../../shared/store/action-creator';
import {
  DefaultUtilizationCollection,
  ToolUtilizationType,
  UtilizationCollection,
} from '@priz/shared/src/models/project';
import { RrmUtilizationAction, RrmUtilizationActionType } from '../actions/rrm-utilization.actions';
import cloneDeep from 'lodash/cloneDeep';
import { RrmUtilization } from '@priz/shared/src/models/tools/rrm/rrm-utilization';
import { PMapUtilization } from '@priz/shared/src/models/tools/pmap';
import { ToolType } from '@priz/shared/src/models/tools';
import { numberToLetters } from '@priz/shared/src/utils/convertation';
import { UpdateRankResult } from '../../services';

const updatePMapPerceptionsRankingScore = (tool: ToolUtilizationType, ranks: UpdateRankResult): PMapUtilization => {
  const goalUtilizationCopy = cloneDeep(tool) as PMapUtilization;

  const result = ranks.perceptions.reduce((map: { [letter: string]: number }, item) => {
    map[numberToLetters(item.perceptionSourceId)] = item.rankingScore;
    return map;
  }, {});

  goalUtilizationCopy.data.perceptions.map(perception => {
    if (typeof result[perception.letter] === 'number') {
      perception.latestRankingScore = result[perception.letter];
    }

    return perception;
  });

  return goalUtilizationCopy;
};

export const toolUtilizationRankingScoreReducers = handleActions(
  {
    [toSuccess(RrmUtilizationActionType.RankRecord)]: (
      state: UtilizationCollection,
      action: RrmUtilizationAction,
    ): UtilizationCollection => {
      const goalUtilization = state.entities[action.meta.goalSourceUtilizationId];
      const rrmUtilization = state.entities[action.meta.utilizationId] as RrmUtilization;
      const payload = action.payload as UpdateRankResult;

      const isAllRanksDone = rrmUtilization.ranks.every(r => {
        return typeof r.leftRank === 'number' && typeof r.rightRank === 'number';
      });

      if (isAllRanksDone && goalUtilization?.type === ToolType.P_MAP) {
        const entitiesCopy = {
          ...state.entities,
          [action.meta.goalSourceUtilizationId]: updatePMapPerceptionsRankingScore(goalUtilization, payload),
        };

        return { ...state, entities: entitiesCopy };
      }

      return state;
    },
  },

  DefaultUtilizationCollection,
);
