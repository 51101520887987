import { RrmGoalType } from '@priz/shared/src/models/tools/rrm/rrm-goal-type.enum';
import { UimPriority } from '@priz/shared/src/models/tools/uim';
import { format } from 'date-fns';

const generateRrmGoalTitle = (goalType: RrmGoalType, uimPriority?: UimPriority) => {
  const dateOfUse = format(new Date(), 'MMM d yyyy h:mm:ss aaa');

  let goalTitle = '';
  if (goalType === RrmGoalType.IDEA) {
    goalTitle = `Project Ideas Ranking ${dateOfUse}`;
  } else if (goalType === RrmGoalType.TASK) {
    goalTitle = `Project Tasks Ranking ${dateOfUse}`;
  } else if (goalType === RrmGoalType.UIM_TASK) {
    goalTitle = `${uimPriority} Tasks Ranking ${dateOfUse}`;
  } else if (goalType === RrmGoalType.FIVE_WHYS_SOLUTION) {
    goalTitle = `Root Causes Ranking ${dateOfUse}`;
  } else if (goalType === RrmGoalType.EBS) {
    goalTitle = `EBS Ideas Ranking ${dateOfUse}`;
  } else if (goalType === RrmGoalType.SELF) {
    goalTitle = `Items Ranking ${dateOfUse}`;
  } else if (goalType === RrmGoalType.P_MAP) {
    goalTitle = `PMap Perceptions Ranking ${dateOfUse}`;
  } else {
    throw new Error(`Goal type '${goalType}' is not supported`);
  }

  return goalTitle;
};

const resolveRrmComparisonsCount = (itemsCount: number, smart?: boolean): number => {
  return Math.max((smart ? itemsCount - 1 : (itemsCount * itemsCount - itemsCount) / 2) || 0, 0);
};

export const RrmUtils = {
  generateRrmGoalTitle,
  resolveRrmComparisonsCount,
};
