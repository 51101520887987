import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';

export const useStyles = makeStyles({
  bar: {
    height: 16,
    minWidth: 34,
    borderRadius: 8,
    color: pgColorScheme.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'default',
  },
  functional: {
    background: pgColorScheme.blue,
  },
  problematic: {
    background: pgColorScheme.coral,
  },
  empty: {
    background: pgColorScheme.gray,
  },
  label: {
    lineHeight: 1,
  },
});
