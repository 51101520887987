import axios, { AxiosResponse } from 'axios';
import {
  FortyPrinciplesUtilization,
  IFortyPrinciplesUtilization,
} from '@priz/shared/src/models/tools/forty-principles/forty-principles-utilization';

export interface NewFortyPrinciplesUtilizationCommand {
  title?: string;
  contradictionIf?: string;
  contradictionThen?: string;
  contradictionBut?: string;
  parentId?: number;
  sourceId?: string;
  sourceImprovementId?: number;
  sourceImprovementIterationId?: number;
}

const createForProject = (
  projectId: number,
  command: NewFortyPrinciplesUtilizationCommand,
): Promise<IFortyPrinciplesUtilization> =>
  axios
    .post(`/v1/api/<ws-id>/team/<t-id>/project/${projectId}/40p`, command)
    .then((response: AxiosResponse<IFortyPrinciplesUtilization>) => response.data);

const createStandalone = (command: NewFortyPrinciplesUtilizationCommand): Promise<IFortyPrinciplesUtilization> =>
  axios
    .post(`/v1/api/<ws-id>/team/<t-id>/tool/40p`, command)
    .then((response: AxiosResponse<IFortyPrinciplesUtilization>) => response.data);

const create = (
  command: NewFortyPrinciplesUtilizationCommand,
  projectId?: number,
): Promise<IFortyPrinciplesUtilization> => {
  if (typeof projectId !== 'undefined') {
    return createForProject(projectId, command);
  } else {
    return createStandalone(command);
  }
};

const doDelete = (utilizationId: number): Promise<any> =>
  axios.delete(`/v1/api/<ws-id>/team/<t-id>/40p/${utilizationId}`);

export interface FortyPrinciplesUtilizationCommand {
  title?: string;
  contradictionIf?: string;
  contradictionThen?: string;
  contradictionBut?: string;
  contradictionNotes?: string;
  improvingParameterId?: number;
  improvingParameterDescription?: string;
  worseningParameterId?: number;
  worseningParameterDescription?: string;
}

export const resolve40pInitialUpdateCommandData = (
  utilization: FortyPrinciplesUtilization,
): FortyPrinciplesUtilizationCommand => {
  return {
    title: utilization.title,
    contradictionIf: utilization.contradictionIf,
    contradictionThen: utilization.contradictionThen,
    contradictionBut: utilization.contradictionBut,
    contradictionNotes: utilization.contradictionNotes,
    improvingParameterDescription: utilization.improvingParameterDescription,
    worseningParameterDescription: utilization.worseningParameterDescription,
    improvingParameterId: utilization.improvingParameter?.id,
    worseningParameterId: utilization.worseningParameter?.id,
  };
};

const update = (
  utilizationId: number,
  command: FortyPrinciplesUtilizationCommand,
): Promise<IFortyPrinciplesUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/40p/${utilizationId}`, command)
    .then((response: AxiosResponse<IFortyPrinciplesUtilization>) => response.data);

export const FortyPrinciplesUtilizationApi = {
  create,
  createForProject,
  createStandalone,
  delete: doDelete,
  update,
};
