import React, { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { Box, Typography } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { ProjectWizardInput, ProjectWizardInputValue, ProjectWizardInputValuesMap } from '../model/inputs';
import { checkConditionIsValid, checkIsAllDone, checkValueIsNotEmpty, generateDefaultValues } from '../utils';
import { copyObject } from '@priz/shared/src/utils/common';
import { AnimatedContainer } from '../../../animated-container/component';
import { ProjectWizardSelect } from '../project-wizard-select/component';

interface ProjectWizardFormProps {
  inputs: ProjectWizardInput[];
  onSelect?: (name: string, value: ProjectWizardInputValue) => void;
  onFormChange?: (data: ProjectWizardInputValuesMap, finished: boolean) => void;
  disabled?: boolean;
}

export const ProjectWizardForm: React.FC<ProjectWizardFormProps> = ({ inputs, onSelect, onFormChange, disabled }) => {
  const { control, reset } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: generateDefaultValues(inputs),
  });

  const values = useWatch({ control });

  useEffect(() => {
    const valuesCopy = copyObject(values);
    let hasChanges = false;

    inputs.forEach(({ name, conditions }) => {
      if (checkValueIsNotEmpty(valuesCopy[name]) && !checkConditionIsValid(conditions, valuesCopy)) {
        if (!hasChanges) hasChanges = true;
        valuesCopy[name] = '';
      }
    });

    if (hasChanges) reset(valuesCopy);
    if (onFormChange) formChaneCallback(valuesCopy);
  }, [values]);

  const formChaneCallback = useCallback(
    debounce(values => {
      onFormChange(values, checkIsAllDone(inputs, values));
    }, 100),
    [],
  );

  return (
    <>
      {inputs.map((item, key) => {
        if (!checkConditionIsValid(item.conditions, values)) return null;

        return (
          <AnimatedContainer key={item.name} disableAnimation={!!key}>
            {item.title && (
              <Box mb={item.subtitle ? 1 : 2}>
                <Typography variant={'h6'}>
                  <Trans>{item.title}</Trans>
                </Typography>
              </Box>
            )}

            {item.subtitle && (
              <Box mb={2}>
                <Typography variant={'body2'}>
                  <Trans>{item.subtitle}</Trans>
                </Typography>
              </Box>
            )}

            <ProjectWizardSelect control={control} inputData={item} onSelect={onSelect} disabled={disabled} />
          </AnimatedContainer>
        );
      })}
    </>
  );
};
