import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  addStepButtonContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  addStepButton: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'color .15s ease, opacity .1s ease',
    transitionDelay: '0s, .5s',
    cursor: 'pointer',
    color: pgColorScheme.blue,
    border: `1px dashed ${pgColorScheme.gray}`,
    borderRadius: 5,
    boxSizing: 'border-box',
    opacity: 1,

    ['&:hover']: {
      color: pgColorScheme.orange,

      ['& $plusIconContainer']: {
        backgroundColor: pgColorScheme.orange,
      },
    },
  },

  disabled: {
    opacity: 0.3,
    pointerEvents: 'none',
    transitionDelay: '0s, 0s',
  },

  plusIconContainer: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: pgColorScheme.blue,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color .15s ease',
  },
});
