/**
 * Extracts list of entities by their ids from the entity map
 *
 * @param entityMap
 * @param ids
 * @return object[]
 */
import { EntityMap, Identifiable } from '@priz/shared/src/models/common/entity-collection-state';

export const safeEntityMapToArrayByIds = (entityMap: EntityMap<Identifiable>, ids: number[]) =>
  ids.reduce((entities, id) => {
    const entity = entityMap[id];

    if (entity) {
      entities.push(entity);
    }

    return entities;
  }, []);
