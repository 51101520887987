import { RouteObject } from 'react-router-dom';
import { RouteRenderer } from './route-renderer/component';
import { Role } from '@priz/shared/src/models/security/role.enum';
import { WorkspacesManager } from '../workspaces-manager/component';
import { UsersManager } from '../users-manager/component';
import { TranslationsManager } from '../translation/translations-manager/component';
import { ProjectsManager } from '../projects-manager/component';
import { CommentsManager } from '../comments/comments-manager/component';
import { TopicsManager } from '../topics/topics-manager/component';
import { FortyPrinciplesSettings } from '../forty-principles/forty-principles-settings/component';
import { CommissionsManager } from '../commissions-manager/component';
import { GeneralSettingsPage } from '../admin/general/component';
import { documentTitleResolver } from './utils';

export const adminRoutes: RouteObject[] = [
  {
    path: 'admin/general',
    element: (
      <RouteRenderer Component={GeneralSettingsPage} authGuard={true} mfaFree={true} roles={[Role.ROLE_ADMIN]} />
    ),
    loader: documentTitleResolver('General settings'),
  },
  {
    path: 'admin/workspaces',
    element: <RouteRenderer Component={WorkspacesManager} authGuard={true} mfaFree={true} roles={[Role.ROLE_ADMIN]} />,
    loader: documentTitleResolver('Workspaces manager'),
  },
  {
    path: 'admin/users',
    element: <RouteRenderer Component={UsersManager} authGuard={true} mfaFree={true} roles={[Role.ROLE_ADMIN]} />,
    loader: documentTitleResolver('Users manager'),
  },
  {
    path: 'admin/translations',
    element: (
      <RouteRenderer
        Component={TranslationsManager}
        authGuard={true}
        mfaFree={true}
        roles={[Role.ROLE_ADMIN, Role.ROLE_TRANSLATOR]}
      />
    ),
    loader: documentTitleResolver('Translations'),
  },
  {
    path: 'admin/projects',
    element: <RouteRenderer Component={ProjectsManager} authGuard={true} mfaFree={true} roles={[Role.ROLE_ADMIN]} />,
    loader: documentTitleResolver('Projects manager'),
  },
  {
    path: 'admin/comments',
    element: <RouteRenderer Component={CommentsManager} authGuard={true} mfaFree={true} roles={[Role.ROLE_ADMIN]} />,
    loader: documentTitleResolver('Comments'),
  },
  {
    path: 'admin/topics',
    element: <RouteRenderer Component={TopicsManager} authGuard={true} mfaFree={true} roles={[Role.ROLE_ADMIN]} />,
    loader: documentTitleResolver('Topics'),
  },
  {
    path: 'admin/40p',
    element: (
      <RouteRenderer Component={FortyPrinciplesSettings} authGuard={true} mfaFree={true} roles={[Role.ROLE_ADMIN]} />
    ),
    loader: documentTitleResolver('40P Settings'),
  },
  {
    path: 'admin/commissions',
    element: <RouteRenderer Component={CommissionsManager} authGuard={true} mfaFree={true} roles={[Role.ROLE_ADMIN]} />,
    loader: documentTitleResolver('Commissions'),
  },
];
