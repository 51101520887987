import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { Button, ButtonProps } from '@mui/material';
import { EbsRankIdeasDialog, SharedEbsRankIdeasDialogProps } from '../ebs-rank-ideas-dialog/comonent';
import { ProjectFeatureBlockerActions } from '../../project-feature-blocker/store/actions';
import { ProjectFeatureBlockerType } from '../../project-feature-blocker/store/model';
import { useDispatch } from 'react-redux';
import { splitOnCategoriesAndValidate } from '@priz/shared/src/utils/ebs';

export interface EbsRankIdeasButtonProps extends ButtonProps, SharedEbsRankIdeasDialogProps {
  text?: string;
}

export const EbsRankIdeasButton: React.FC<EbsRankIdeasButtonProps> = ({
  text,
  utilizationId,
  ebsIdeas,
  projectId,
  ...rest
}) => {
  const dispatch = useDispatch();

  const [rankingDialogIsOpen, setRankingDialogIsOpen] = useState(false);

  const handleRankingOpen = () => {
    if (typeof projectId !== 'undefined') {
      setRankingDialogIsOpen(true);
    } else {
      dispatch(
        ProjectFeatureBlockerActions.set({
          utilizationId,
          blocker: ProjectFeatureBlockerType.EBSRanking,
          ebsRankingProps: {
            ebsCategoriesValidationResult: splitOnCategoriesAndValidate(ebsIdeas || []),
          },
        }),
      );
    }
  };

  const handleRankingCancel = () => {
    setRankingDialogIsOpen(false);
  };

  return (
    <>
      <Button variant={'pg_rounded'} color={'pg_orange_solid'} onClick={handleRankingOpen} {...rest}>
        <Trans>{text || 'Rank ideas'}</Trans>
      </Button>

      <EbsRankIdeasDialog
        open={rankingDialogIsOpen}
        utilizationId={utilizationId}
        ebsIdeas={ebsIdeas}
        projectId={projectId}
        onClose={handleRankingCancel}
      />
    </>
  );
};
