import { IdRef } from '../../common/id-ref';
import { AuditableEntity, IAuditableEntity } from '../../common/auditable-entity';
import { Identifiable } from '../../common/entity-collection-state';
import { RrmGoalType } from './rrm-goal-type.enum';
import { Perception } from './perception';

export interface IGoal extends IAuditableEntity, Identifiable {
  title: string;
  definition: string;
  goalType: RrmGoalType;
  project: IdRef;

  perceptions: Perception[];

  sourceUtilizationId: number;
}

export class Goal extends AuditableEntity implements IGoal {
  public id: number;
  public title: string;
  public definition: string;
  public goalType: RrmGoalType;
  public project: IdRef;

  public perceptions: Perception[];

  public sourceUtilizationId: number;

  constructor(json: IGoal) {
    super(json);

    this.id = json.id;
    this.title = json.title;
    this.definition = json.definition;
    this.goalType = json.goalType;
    this.project = json.project;

    this.sourceUtilizationId = json.sourceUtilizationId;

    this.perceptions = json.perceptions.map(perceptionData => new Perception(perceptionData));
  }
}
