import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';

export const useStyles = makeStyles({
  rankingScore: {
    borderRadius: '50%',
    background: pgColorScheme.mediumBackground,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'default',
  },
  medium: {
    width: 30,
    height: 30,
  },
  small: {
    width: 26,
    height: 26,
  },
  shadowBackground: {
    boxShadow: `100px 0 0 ${pgColorScheme.mediumBackground} inset`,
  },
});
