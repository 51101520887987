interface StaticImageData {
  src: string;
  height: number;
  width: number;
  blurDataURL?: string;
}

export const resolveImageUrl = (image: undefined | string | StaticImageData): string => {
  if (typeof image === 'string') return image;
  if (typeof image?.src !== 'undefined') return image.src;
  return '';
};
