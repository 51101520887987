import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { FortyPrinciplesSteps } from '../forty-principles-steps/component';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { ToolUtilizationActions } from '../../project/store/actions/tools.actions';

export interface FortyPrinciplesProps {
  utilizationId: number;
  projectId?: number;
}

export const FortyPrinciples: React.FC<FortyPrinciplesProps> = ({ projectId, utilizationId }) => {
  const dispatch = useDispatch();

  const utilization = useSelector(ToolUtilizationSelector.get40PUtilization(utilizationId));

  useEffect(() => {
    dispatch(ToolUtilizationActions.loadTool(utilizationId, projectId));
  }, [utilizationId, projectId]);

  if (!utilization) {
    return <LoadingOverlay loading={true} backdropStyles={{ backgroundColor: 'transparent' }} />;
  }

  return <FortyPrinciplesSteps utilizationId={utilizationId} projectId={projectId} />;
};
