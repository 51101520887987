import { combineActions, handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { toObjectMap } from '../../../shared/store/reducer-utils';
import { ArrayUtil } from '../../../shared/utils/array-util';
import { TaskAssignee } from '@priz/shared/src/models/assignment';
import { TaskAssigneeAction, TaskAssigneeActionType } from '../actions';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import {
  DefaultTaskAssigneeCollection,
  TaskAssigneeCollection,
} from '@priz/shared/src/models/assignment/task-assignee-collection';
import { copyObject } from '@priz/shared/src/utils/common';

const assigneeInstantiator = (payload: TaskAssignee): TaskAssignee => payload;

const setStatuses = (
  state: TaskAssigneeCollection,
  statuses: Partial<EntityCollectionStatus>,
): TaskAssigneeCollection => ({
  ...state,
  statuses: {
    ...state.statuses,
    ...statuses,
  },
});

const setTaskAssignees = (state: TaskAssigneeCollection, assignees: TaskAssignee[]): TaskAssigneeCollection => {
  const assigneeMap = toObjectMap<TaskAssignee>(assignees, assigneeInstantiator);
  const entitiesCopy = { ...state.entities, ...assigneeMap };

  const lookupsCopy = copyObject(state.lookups);
  Object.values(assigneeMap).forEach((assignee: TaskAssignee) => {
    lookupsCopy.byTaskId[assignee.taskId] = ArrayUtil.concatInDistinctArray(
      lookupsCopy.byTaskId[assignee.taskId],
      assignee.id,
    );
  });

  const statusesCopy = { ...state.statuses, loaded: true, loading: false, error: false };

  return {
    ...state,
    entities: entitiesCopy,
    lookups: lookupsCopy,
    statuses: statusesCopy,
  };
};

const removeTaskAssignee = (
  state: TaskAssigneeCollection,
  taskId: number,
  assignmentId: number,
): TaskAssigneeCollection => {
  const entitiesCopy = { ...state.entities };
  delete entitiesCopy[assignmentId];

  const lookupsCopy = copyObject(state.lookups);
  lookupsCopy.byTaskId[taskId] = lookupsCopy.byTaskId[taskId].filter(id => id != assignmentId);

  const statusesCopy = { ...state.statuses, loaded: true, loading: false, error: false };

  return {
    ...state,
    entities: entitiesCopy,
    lookups: lookupsCopy,
    statuses: statusesCopy,
  };
};

export const taskAssigneeReducers = handleActions(
  {
    [combineActions(
      toStarted(TaskAssigneeActionType.FetchAll),
      toStarted(TaskAssigneeActionType.Create),
      toStarted(TaskAssigneeActionType.Delete),
    )]: (state: TaskAssigneeCollection): TaskAssigneeCollection => setStatuses(state, { loading: true }),

    [combineActions(
      toFailed(TaskAssigneeActionType.FetchAll),
      toFailed(TaskAssigneeActionType.Create),
      toFailed(TaskAssigneeActionType.Delete),
    )]: (state: TaskAssigneeCollection): TaskAssigneeCollection => setStatuses(state, { loading: false, error: true }),

    [toSuccess(TaskAssigneeActionType.FetchAll)]: (
      state: TaskAssigneeCollection,
      action: TaskAssigneeAction,
    ): TaskAssigneeCollection => setTaskAssignees(state, action.payload as TaskAssignee[]),

    [toSuccess(TaskAssigneeActionType.Create)]: (
      state: TaskAssigneeCollection,
      action: TaskAssigneeAction,
    ): TaskAssigneeCollection => setTaskAssignees(state, [action.payload as TaskAssignee]),

    [toSuccess(TaskAssigneeActionType.Delete)]: (
      state: TaskAssigneeCollection,
      action: TaskAssigneeAction,
    ): TaskAssigneeCollection => removeTaskAssignee(state, action.meta.taskId, action.meta.assigneeId),
  },
  DefaultTaskAssigneeCollection,
);
