import axios, { AxiosResponse } from 'axios';
import { AxiosRequestParams } from '../axios';
import { UserSettings, UserSettingsResponse } from '../../models/user/user-settings';

export interface UserSettingsApiGetProps {
  renewTokenErrorCallback?: () => void;
}

const get = (props?: UserSettingsApiGetProps): Promise<UserSettingsResponse> => {
  const { renewTokenErrorCallback } = props || {};

  const axiosParams: AxiosRequestParams = {
    params: {
      renewTokenErrorCallback,
    },
  };

  return axios
    .get(`/v1/api/user/user-settings`, axiosParams)
    .then((response: AxiosResponse<UserSettingsResponse>) => response.data);
};

export interface UserSettingsApiUpdateProps {
  settings: UserSettings;
  renewTokenErrorCallback?: () => void;
}

const update = (props: UserSettingsApiUpdateProps): Promise<UserSettingsResponse> => {
  const { settings, renewTokenErrorCallback } = props;

  const axiosParams: AxiosRequestParams = {
    params: {
      renewTokenErrorCallback,
    },
  };

  return axios
    .put(`/v1/api/user/user-settings`, { settings }, axiosParams)
    .then((response: AxiosResponse<UserSettingsResponse>) => response.data);
};

export const UserSettingsApi = {
  get,
  update,
};
