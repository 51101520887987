import { AuditableEntity, IAuditableEntity } from '@priz/shared/src/models/common/auditable-entity';
import { Identifiable } from '@priz/shared/src/models/common/entity-collection-state';
import { IdRef } from '@priz/shared/src/models/common/id-ref';
import { AgendaUpdateProps } from './agenda';
import moment, { Moment } from 'moment-timezone';
import { MeetingStatus } from '@priz/shared/src/models/meetings';
import { ToolType } from '@priz/shared/models/tools';

export interface IMeeting extends IAuditableEntity, Identifiable {
  title: string;
  date: Moment;
  durationMs: number;
  status: MeetingStatus;
  notes: string;
  project: IdRef;
  workspace: IdRef;
  sourceId?: string;
  sourceToolType?: ToolType;
  sourceToolUtilization?: IdRef;
}

export class Meeting extends AuditableEntity implements IMeeting {
  public id: number;
  public title: string;
  public date: Moment;
  public durationMs: number;
  public status: MeetingStatus;
  public notes: string;
  public project: IdRef;
  public workspace: IdRef;
  public sourceId?: string;
  public sourceToolType?: ToolType;
  public sourceToolUtilization?: IdRef;

  constructor(json: IMeeting) {
    super(json);

    this.id = json.id;
    this.title = json.title;
    this.date = json.date && moment(json.date);
    this.durationMs = json.durationMs;
    this.status = json.status;
    this.notes = json.notes;
    this.project = json.project;
    this.workspace = json.workspace;
    this.sourceId = json.sourceId;
    this.sourceToolType = json.sourceToolType;
    this.sourceToolUtilization = json.sourceToolUtilization;
  }
}

export interface MeetingCreateCommand {
  title: string;
  sourceId?: string;
  sourceToolType?: ToolType;
  sourceToolUtilizationId?: number;
}

export interface MeetingUpdateCommand {
  title?: string;
  date?: Date;
  notes?: string;
  durationMs?: number;
}

export interface MeetingFullUpdateCommand {
  title?: string;
  date?: Date;
  durationMs?: number;
  attendeesEmails?: string[];
  agenda?: AgendaUpdateProps[];
}
