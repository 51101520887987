import React, { useEffect, useState } from 'react';
import { CftNodeData } from '../../../models/tools/cft';
import { useStyles } from './styles';
import { cftSettings } from '../data';

export interface CftBranchSpacerRef {
  id: string;
  refresh: () => void;
}

interface CftBranchLevelSpaceResolverProps {
  parentNode: CftNodeData;
  spacerRef?: (ref: CftBranchSpacerRef) => void;
  onSpacingChange?: () => void;
}

export const CftBranchLevelSpaceResolver: React.FC<CftBranchLevelSpaceResolverProps> = ({
  parentNode,
  spacerRef,
  onSpacingChange,
}) => {
  const styles = useStyles();

  const [parentNodeOffsetTop, setParentNodeOffsetTop] = useState(-1);

  useEffect(() => {
    const parentNodeElement = document.getElementById(parentNode.id);

    if (parentNodeElement) {
      setParentNodeOffsetTop(Math.max(parentNodeElement.offsetTop - cftSettings.spacing.container * 8, 0));
    }
  });

  useEffect(() => {
    if (parentNodeOffsetTop >= 0 && onSpacingChange) {
      onSpacingChange();
    }
  }, [parentNodeOffsetTop]);

  const refresh = () => {
    const parentNodeElement = document.getElementById(parentNode.id);

    if (parentNodeElement) {
      setParentNodeOffsetTop(Math.max(parentNodeElement.offsetTop - cftSettings.spacing.container * 8, 0));
    }
  };

  if (spacerRef && parentNode?.id) {
    spacerRef({
      id: parentNode.id,
      refresh,
    });
  }

  if (parentNodeOffsetTop < 0) {
    return null;
  }

  return <div className={styles.root} style={{ height: parentNodeOffsetTop }} />;
};
