import { EntityCollectionStatus, EntityMap } from '../common/entity-collection-state';
import { WorkspaceMember } from '../workspace/workspace-member';

export interface WorkspaceMembersCollection {
  entities: EntityMap<WorkspaceMember>;

  statuses: EntityCollectionStatus;
}

export const DefaultWorkspaceMembersCollection: WorkspaceMembersCollection = {
  entities: {},

  statuses: {},
};
