import React from 'react';
import { Controller } from 'react-hook-form';
import { FieldTitle, HelperText } from '@priz/shared/src/components/form-elements';
import { ValidatorControllerProps, ReactHookFormElement, DefaultInput } from '@priz/shared/src/models/form';
import { Box } from '@mui/material';
import { TimePickerButton } from '../../../time-picker-button/component';

type ReactHookFormDurationButtonProps = ReactHookFormElement &
  DefaultInput &
  ValidatorControllerProps & {
    onChange?: (durationMs: number) => void;
  };

export const ReactHookFormDurationButton: React.FC<ReactHookFormDurationButtonProps> = ({
  name,
  control,
  placeholder,
  fieldTitle,
  fieldTitleWrapperProps,
  helperText,
  wrapperProps,
  rules,
  disabled,
  onChange,
}) => {
  return (
    <Box mb={3} {...wrapperProps}>
      <FieldTitle text={fieldTitle} {...fieldTitleWrapperProps} />

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange: fieldOnChange, value }, fieldState: { error } }) => (
          <>
            <TimePickerButton
              valueInMs={value}
              onChange={duration => {
                fieldOnChange(duration);
                if (onChange) onChange(duration);
              }}
              placeholder={placeholder}
              disabled={disabled}
            />

            <HelperText error={error?.message} text={helperText} />
          </>
        )}
      />
    </Box>
  );
};
