import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useStyles as useFormStyles } from '../../react/form-elements/styles';
import { useForm } from 'react-hook-form';
import { FormResetButton, FormSubmitButton, ReactHookFormText } from '../../react/form-elements';
import { validateOptions } from '@priz/shared/src/utils/form';
import { Attachment } from '../store/model';
import { AttachmentsActions } from '../store/actions';

interface AttachmentRenameDialogProps {
  projectId: number;
  attachment: Attachment;
  isOpen: boolean;
  onClose?: () => void;
}

export const AttachmentRenameDialog: React.FC<AttachmentRenameDialogProps> = ({
  projectId,
  attachment,
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const formStyles = useFormStyles();
  const [open, setOpen] = useState(false);
  const nameArray = attachment.fileName.split('.');
  const extension = nameArray.pop();
  const name = nameArray.join('.');

  const { handleSubmit, control, reset } = useForm({
    shouldFocusError: false,
    defaultValues: {
      name: name || '',
    },
  });

  useEffect(() => {
    setOpen(isOpen);
    if (isOpen) reset({ name: name || '' });
  }, [isOpen]);

  const save = data => {
    dispatch(AttachmentsActions.updateAttachmentFileName(projectId, attachment.id, [data.name, extension].join('.')));
    handleClose();
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <form onSubmit={handleSubmit(save)} className={formStyles.form}>
        <DialogTitle>{<Trans>Rename attachment</Trans>}</DialogTitle>

        <DialogContent>
          <ReactHookFormText
            name={'name'}
            control={control}
            rules={{
              validate: {
                ...validateOptions.hasText('Field is required'),
              },
            }}
          />
        </DialogContent>

        <DialogActions style={{ textAlign: 'right' }}>
          <FormResetButton onClick={handleClose} />
          <FormSubmitButton text={'Save'} />
        </DialogActions>
      </form>
    </Dialog>
  );
};
