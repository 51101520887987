import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  active: {
    color: pgColorScheme.blue,
    transition: 'color .15s ease',

    ['&:hover']: {
      color: pgColorScheme.orange,
    },
  },
  inactive: {
    color: pgColorScheme.textGray,
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
});
