import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../store/app.state';
import { FiveWhysSolution } from '@priz/shared/src/models/tools/five-whys';

const fiveWhysSolutionCollectionSelector = (state: AppState) => state.fiveWhysSolutions;

const fiveWhysSolutionsStatusesSelector = createSelector(
  fiveWhysSolutionCollectionSelector,
  collection => collection.statuses || {},
);

const fiveWhysSolutionEntitiesSelector = createSelector(
  fiveWhysSolutionCollectionSelector,
  collection => collection.entities,
);

const fiveWhysSolutionLookupsSelector = createSelector(
  fiveWhysSolutionCollectionSelector,
  collection => collection.lookups,
);

const fiveWhySolutionIdsByUtilizationId = (utilizationId: number) =>
  createSelector(fiveWhysSolutionLookupsSelector, lookups => lookups.byUtilizationId[utilizationId] || []);

//////////////////////////////////////////////////////////////////

const getAllByUtilizationId = (utilizationId: number): Selector<AppState, FiveWhysSolution[]> =>
  createSelector(
    [fiveWhySolutionIdsByUtilizationId(utilizationId), fiveWhysSolutionEntitiesSelector],
    (ids: number[], entityMap: { [id: number]: FiveWhysSolution }) => ids.map(id => entityMap[id]),
  );

const isLoadingByUtilizationId = (utilizationId: number) =>
  createSelector(
    fiveWhysSolutionsStatusesSelector,
    statuses => statuses.byUtilizationId[utilizationId] && statuses.byUtilizationId[utilizationId].loading,
  );

const isLoadedByUtilizationId = (utilizationId: number) =>
  createSelector(
    fiveWhysSolutionsStatusesSelector,
    statuses => statuses.byUtilizationId[utilizationId] && statuses.byUtilizationId[utilizationId].loaded,
  );

const getById = (rootCauseId: number) =>
  createSelector(fiveWhysSolutionEntitiesSelector, entityMap => entityMap[rootCauseId]);

export const FiveWhysSolutionSelectors = {
  getAllByUtilizationId,
  getById,
  isLoadingByUtilizationId,
  isLoadedByUtilizationId,
};
