import React from 'react';
import { useSelector } from 'react-redux';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { ToolLink } from '../tool-link/component';
import { ToolIconProps } from '../tool-icon/component';

export interface ToolLinkResolverProps extends Pick<ToolIconProps, 'size'> {
  utilizationId: number;
  sourceId?: string;
  disabled?: boolean;
}

export const ToolLinkResolver: React.FC<ToolLinkResolverProps> = ({ utilizationId, ...rest }) => {
  const tool = useSelector(ToolUtilizationSelector.getByOnlyId(utilizationId));

  if (!tool) {
    return null;
  }

  return <ToolLink tool={tool} {...rest} />;
};
