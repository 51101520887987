import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleWrap: {
      '& > *': {
        marginBottom: 0,
      },
    },
    overflowContainer: {
      overflow: 'hidden',
    },

    [theme.breakpoints.down('sm')]: {
      overflowContainer: {
        overflow: 'visible',
      },
    },
  }),
);
