import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  arrow: {
    'position': 'relative',
    'width': 50,
    'height': 25,

    '&::before': {
      content: '""',
      position: 'absolute',
      height: 0,
      width: '100%',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: 'auto',
    },
  },
  useful: {
    '&::before': {
      borderTop: `2px solid ${pgColorScheme.blue}`,
    },
  },
  harmful: {
    '&::before': {
      borderTop: `2px solid ${pgColorScheme.coral}`,
    },
  },
  excessive: {
    '&::before': {
      borderTop: `6px solid ${pgColorScheme.blue}`,
    },
  },
  insufficient: {
    '&::before': {
      borderTop: `2px dashed ${pgColorScheme.blue}`,
    },
  },
});
