import React, { useState } from 'react';
import { Control } from 'react-hook-form';
import { ReactHookFormSelectSearch } from '../../../react/form-elements';
import { useTranslation } from 'react-i18next';
import { InputValueSuggesterType, ProjectWizardInput, ProjectWizardInputValue } from '../model/inputs';
import { useMutation } from 'react-query';
import { AssistantApi } from '../../../assistant/api/assistant.api';
import { useStyles } from './styles';
import { OptionsSuggesterMessage } from '../options-suggester-message/component';

interface ProjectWizardSelectProps {
  control: Control<any>;
  inputData: ProjectWizardInput;
  onSelect?: (name: string, value: ProjectWizardInputValue) => void;
  disabled?: boolean;
}

export const ProjectWizardSelect: React.FC<ProjectWizardSelectProps> = ({ control, inputData, onSelect, disabled }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const suggestedOptions = suggestions.length ? suggestions.map(item => ({ value: item, label: item })) : [];

  const defaultOptions = inputData.options
    .sort((a, b) => (a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1))
    .map(item => ({ value: item.value, label: t(item.text) }));

  const allOptions = suggestedOptions.length
    ? [
        {
          label: t('Suggested'),
          options: suggestedOptions,
          className: styles.suggestedOptionsGroup,
        },
        {
          label: t('Default'),
          options: defaultOptions,
          className: styles.defaultOptionsGroup,
        },
      ]
    : defaultOptions;

  const suggestionsMutation = useMutation(
    (props: { text: string }) => {
      if (inputData.suggester === InputValueSuggesterType.Industry) {
        return AssistantApi.getIndustriesSuggestion({ industry: props.text });
      }
    },
    {
      onSuccess: data => {
        if (inputData.suggester === InputValueSuggesterType.Industry) {
          setSuggestions(data?.industries || []);
        }

        setInputValue('');
      },
    },
  );

  const getSuggestionHandler = () => {
    setSuggestions([]);
    suggestionsMutation.mutate({ text: inputValue });
  };

  const inputChangeHandler = (value: string) => {
    setInputValue(value);

    if (suggestionsMutation.isError) {
      suggestionsMutation.reset();
    }
  };

  return (
    <ReactHookFormSelectSearch
      name={inputData.name}
      control={control}
      options={allOptions}
      onSelect={value => {
        if (onSelect && value) {
          const option = Array.isArray(value) ? value[0] : value;
          onSelect(inputData.name, option.value as string | number);
        }
      }}
      disabled={disabled}
      inputValue={inputValue}
      onInputChange={inputChangeHandler}
      noOptionsMessage={
        inputData.suggester && (
          <OptionsSuggesterMessage
            type={inputData.suggester}
            isLoading={suggestionsMutation.isLoading}
            isError={suggestionsMutation.isError}
            onSuggest={getSuggestionHandler}
          />
        )
      }
    />
  );
};
