import axios, { AxiosResponse } from 'axios';
import { PublicUser } from '@priz/shared/src/models/user';
import { Profile } from '@priz/shared/src/models/security/profile';
import { Role } from '@priz/shared/src/models/security/role.enum';
import { User } from '@priz/shared/src/models/security/user';
import { UserContextActionPayload } from '../store/actions';
import { Auth0ConnectionType } from '@priz/shared/src/models/security/auth0-connection-type';
import { NewsletterEmailsSubscriptionInfo } from '../store/model';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { ToolType } from '@priz/shared/src/models/tools';
import { ToolsUtils } from '../../tools/utils';

const isUserReviewer = (user: User) => user.roles.includes(Role.ROLE_REVIEWER);

interface AuthCommand {
  publicId?: string;
  projectTitle?: string;
}

const resolveAuthCommand = (): AuthCommand => {
  const publicId = LocalStorageService.getItem(LocalStorageKey.PublicUtilizationIdToMove);
  const projectTitle = LocalStorageService.getItem(LocalStorageKey.ProjectTitleToCreate);

  if (publicId) {
    LocalStorageService.removeItem(LocalStorageKey.PublicUtilizationIdToMove);
  }

  if (projectTitle) {
    LocalStorageService.removeItem(LocalStorageKey.ProjectTitleToCreate);
  }

  return {
    publicId,
    projectTitle,
  };
};

const login = (): Promise<User> => {
  return axios.post(`/v1/login`).then((response: AxiosResponse<User>) => response.data);
};

type SignUpResponse = User & {
  redirect?: {
    projectId?: number;
    toolId?: number;
    toolType?: ToolType;
  };
};

const signUp = (referralCode?: string): Promise<SignUpResponse> => {
  return axios
    .post(`/v1/signup${referralCode ? '?ref=' + referralCode : ''}`, resolveAuthCommand())
    .then((response: AxiosResponse<SignUpResponse>) => {
      const redirect = response.data.redirect;

      if (redirect) {
        const { projectId, toolId, toolType } = redirect;
        const toolPath = ToolsUtils.resolvePath(toolType, toolId, projectId);

        LocalStorageService.setItem(LocalStorageKey.AuthCallbackRedirect, toolPath);
        delete response.data.redirect;
      }

      return response.data;
    });
};

const acceptInvitation = (invitationToken: string): Promise<User> => {
  return axios
    .post(`/v1/accept-invitation?token=${invitationToken}`, null)
    .then((response: AxiosResponse<User>) => response.data);
};

const connectAccounts = (primaryAccessToken: string): Promise<User> => {
  return axios
    .post(`/v1/connect-accounts?primary=${primaryAccessToken}`, null)
    .then((response: AxiosResponse<User>) => response.data);
};

export interface DeleteConnectionCommand {
  connectionType: Auth0ConnectionType;
}

const deleteConnection = (command: DeleteConnectionCommand): Promise<User> => {
  return axios.patch(`/v1/delete-connection`, command).then((response: AxiosResponse<User>) => response.data);
};

const getUserContext = (): Promise<UserContextActionPayload> =>
  axios.get(`/v1/api/context`).then((response: AxiosResponse<UserContextActionPayload>) => response.data);

const updateUserProfile = (profile: Profile): Promise<User> =>
  axios
    .put(`/v1/api/user/profile`, {
      firstName: profile.firstName,
      lastName: profile.lastName,
      title: profile.title,
      organization: profile.organization,
      location: profile.location,
      timeZoneCode: profile.timeZoneCode,
      phoneNumber: profile.phoneNumber,
    })
    .then((response: AxiosResponse<User>) => response.data);

const updateUserPassword = (newPassword: string): Promise<any> =>
  axios
    .put(`/v1/api/user/password`, {
      newPassword: newPassword,
    })
    .then((response: AxiosResponse<Profile>) => response.data);

const updateUserAvatar = (img: File): Promise<any> => {
  const formData = new FormData();

  formData.append('image', img);

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  return axios.post(`/v1/api/user/avatar`, formData, config).then((response: AxiosResponse<Profile>) => response.data);
};

const requestVerificationEmail = (): Promise<any> =>
  axios.post(`/v1/api/user/verify-email`).then((response: AxiosResponse) => response.data);

const verifyEmail = (token: string): Promise<any> =>
  axios.post(`/v1/public-api/user/verify-email/${token}`).then((response: AxiosResponse) => response.data);

export interface PrivacySettingsCommand {
  exposeInHub?: boolean;
  showEmailInHub?: boolean;
}

const updatePrivacySettings = (command: PrivacySettingsCommand): Promise<User> =>
  axios.patch(`/v1/api/user/privacy-settings`, command).then((response: AxiosResponse<User>) => response.data);

export interface SecuritySettingsCommand {
  useMfa?: boolean;
}

const updateSecuritySettings = (command: SecuritySettingsCommand): Promise<User> =>
  axios.patch(`/v1/api/user/security-settings`, command).then((response: AxiosResponse<User>) => response.data);

const isReviewer = (user: User) => user.roles.includes(Role.ROLE_REVIEWER);

const getFullName = (user: User | PublicUser) => `${user.profile.firstName} ${user.profile.lastName}`.trim();

const getNewsletterSubscriptionInfo = (): Promise<NewsletterEmailsSubscriptionInfo> =>
  axios
    .get(`/v1/api/user/newsletter/subscription`)
    .then((response: AxiosResponse<NewsletterEmailsSubscriptionInfo>) => response.data);

const subscribeToNewsletter = (): Promise<NewsletterEmailsSubscriptionInfo> =>
  axios
    .patch(`/v1/api/user/newsletter/subscribe`)
    .then((response: AxiosResponse<NewsletterEmailsSubscriptionInfo>) => response.data);

const unsubscribeOfNewsletter = (): Promise<NewsletterEmailsSubscriptionInfo> =>
  axios
    .patch(`/v1/api/user/newsletter/unsubscribe`)
    .then((response: AxiosResponse<NewsletterEmailsSubscriptionInfo>) => response.data);

const acceptPolicies = (): Promise<User> =>
  axios.patch(`/v1/api/user/accept-policies`).then((response: AxiosResponse<User>) => response.data);

export const UserService = {
  login,
  signUp,
  acceptInvitation,
  connectAccounts,
  deleteConnection,
  isUserReviewer,
  getUserContext,
  updateUserProfile,
  isReviewer,
  getFullName,
  updateUserPassword,
  updateUserAvatar,
  requestVerificationEmail,
  verifyEmail,
  updatePrivacySettings,
  updateSecuritySettings,
  getNewsletterSubscriptionInfo,
  subscribeToNewsletter,
  unsubscribeOfNewsletter,
  acceptPolicies,
};
