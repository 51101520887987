import { handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { Idea, IIdea } from '@priz/shared/src/models/idea/idea';
import { ProjectIdeaAction, ProjectIdeaActionType } from '../actions';
import { IdeaList } from '@priz/shared/src/models/idea';
import { EntityCollectionStatus, EntityMap } from '@priz/shared/src/models/common/entity-collection-state';

const INITIAL_STATE = {};

const setStatuses = (
  state: EntityMap<IdeaList>,
  projectId: number,
  statuses: Partial<EntityCollectionStatus>,
): EntityMap<IdeaList> => {
  return {
    ...state,
    [projectId]: {
      ...(state[projectId] || {}),
      projectId,
      statuses: {
        ...(state[projectId]?.statuses || {}),
        ...statuses,
      },
    },
  };
};

const setIdea = (
  state: EntityMap<IdeaList>,
  projectId: number,
  idea: IIdea,
  statuses: Partial<EntityCollectionStatus>,
): EntityMap<IdeaList> => {
  return {
    ...state,
    [projectId]: {
      ...(state[projectId] || {}),
      ideas: {
        ...(state[projectId]?.ideas || {}),
        [idea.id]: new Idea(idea),
      },
      statuses: {
        ...(state[projectId]?.statuses || {}),
        ...statuses,
      },
    },
  };
};

const setIdeas = (
  state: EntityMap<IdeaList>,
  projectId: number,
  ideas: IIdea[],
  statuses: Partial<EntityCollectionStatus>,
): EntityMap<IdeaList> => {
  const ideasMap = ideas.reduce((map, ideaData) => {
    map[ideaData.id] = new Idea(ideaData);
    return map;
  }, {});

  return {
    ...state,
    [projectId]: {
      ...(state[projectId] || {}),
      ideas: {
        ...(state[projectId]?.ideas || {}),
        ...ideasMap,
      },
      statuses: {
        ...(state[projectId]?.statuses || {}),
        ...statuses,
      },
    },
  };
};

const removeIdea = (
  state: EntityMap<IdeaList>,
  projectId: number,
  ideaId: number,
  statuses: Partial<EntityCollectionStatus>,
): EntityMap<IdeaList> => {
  const ideasMapCopy = { ...(state[projectId]?.ideas || {}) };

  delete ideasMapCopy[ideaId];

  return {
    ...state,
    [projectId]: {
      ...(state[projectId] || {}),
      ideas: ideasMapCopy,
      statuses: {
        ...(state[projectId]?.statuses || {}),
        ...statuses,
      },
    },
  };
};

export const projectIdeaReducers = handleActions(
  {
    // FetchAll

    [toStarted(ProjectIdeaActionType.FetchAll)]: (
      state: EntityMap<IdeaList>,
      action: ProjectIdeaAction,
    ): EntityMap<IdeaList> => setStatuses(state, action.meta.projectId, { loading: true }),

    [toFailed(ProjectIdeaActionType.FetchAll)]: (
      state: EntityMap<IdeaList>,
      action: ProjectIdeaAction,
    ): EntityMap<IdeaList> => setStatuses(state, action.meta.projectId, { loading: false, error: true }),

    [toSuccess(ProjectIdeaActionType.FetchAll)]: (
      state: EntityMap<IdeaList>,
      action: ProjectIdeaAction,
    ): EntityMap<IdeaList> =>
      setIdeas(state, action.meta.projectId, action.payload as IIdea[], { loading: false, loaded: true, error: false }),

    // Create

    [toStarted(ProjectIdeaActionType.Create)]: (
      state: EntityMap<IdeaList>,
      action: ProjectIdeaAction,
    ): EntityMap<IdeaList> => setStatuses(state, action.meta.projectId, { creating: true, created: false }),

    [toFailed(ProjectIdeaActionType.Create)]: (
      state: EntityMap<IdeaList>,
      action: ProjectIdeaAction,
    ): EntityMap<IdeaList> =>
      setStatuses(state, action.meta.projectId, { creating: false, created: false, error: true }),

    [toSuccess(ProjectIdeaActionType.Create)]: (
      state: EntityMap<IdeaList>,
      action: ProjectIdeaAction,
    ): EntityMap<IdeaList> =>
      setIdea(state, action.meta.projectId, action.payload as IIdea, { creating: false, created: true, error: false }),

    // Update

    [toStarted(ProjectIdeaActionType.Update)]: (
      state: EntityMap<IdeaList>,
      action: ProjectIdeaAction,
    ): EntityMap<IdeaList> => setStatuses(state, action.meta.projectId, { updating: true, updated: false }),

    [toFailed(ProjectIdeaActionType.Update)]: (
      state: EntityMap<IdeaList>,
      action: ProjectIdeaAction,
    ): EntityMap<IdeaList> =>
      setStatuses(state, action.meta.projectId, { updating: false, updated: false, error: true }),

    [toSuccess(ProjectIdeaActionType.Update)]: (
      state: EntityMap<IdeaList>,
      action: ProjectIdeaAction,
    ): EntityMap<IdeaList> =>
      setIdea(state, action.meta.projectId, action.payload as IIdea, { updating: false, updated: true, error: false }),

    // Delete

    [toStarted(ProjectIdeaActionType.Delete)]: (
      state: EntityMap<IdeaList>,
      action: ProjectIdeaAction,
    ): EntityMap<IdeaList> => setStatuses(state, action.meta.projectId, { removing: true, removed: false }),

    [toFailed(ProjectIdeaActionType.Delete)]: (
      state: EntityMap<IdeaList>,
      action: ProjectIdeaAction,
    ): EntityMap<IdeaList> =>
      setStatuses(state, action.meta.projectId, { removing: false, removed: false, error: true }),

    [toSuccess(ProjectIdeaActionType.Delete)]: (
      state: EntityMap<IdeaList>,
      action: ProjectIdeaAction,
    ): EntityMap<IdeaList> =>
      removeIdea(state, action.meta.projectId, action.meta.ideaId, { removing: false, removed: true, error: false }),
  },
  INITIAL_STATE,
);
