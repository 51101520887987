import { handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { copyObject } from '@priz/shared/src/utils/common';
import { toObjectMap } from '../../../shared/store/reducer-utils';
import { ArrayUtil } from '../../../shared/utils/array-util';
import {
  Attendee,
  AttendeesCollection,
  AttendeesCollectionStatuses,
  DefaultAttendeesCollection,
  IAttendee,
} from '../model';
import {
  AttendeeAction,
  AttendeeActionType,
  MeetingAction,
  MeetingActionType,
  MeetingFullUpdateAction,
} from '../actions';

const resolveStatuses = (
  projectId: number,
  state: AttendeesCollection,
  statuses: Partial<EntityCollectionStatus>,
): AttendeesCollectionStatuses => {
  const statusesCopy = copyObject(state.statuses);

  statusesCopy.byProjectId[projectId] = { ...(statusesCopy.byProjectId[projectId] || {}), ...statuses };

  return statusesCopy;
};

const setAttendeesCollectionStatuses = (
  state: AttendeesCollection,
  projectId: number,
  statuses: Partial<EntityCollectionStatus>,
): AttendeesCollection => {
  return {
    ...state,
    statuses: resolveStatuses(projectId, state, statuses),
  };
};

const attendeeInitializer = (attendeeData: IAttendee): Attendee => new Attendee(attendeeData);

const setAllAttendees = (
  state: AttendeesCollection,
  projectId: number,
  attendeesPayload: IAttendee[],
  meetingId?: number,
): AttendeesCollection => {
  const newAttendeesMap = toObjectMap<Attendee>(attendeesPayload, attendeeInitializer);
  const lookupsCopy = copyObject(state.lookups);

  const entitiesCopy = {
    ...state.entities,
    ...newAttendeesMap,
  };

  if (meetingId) {
    const newAttendeesIds = Object.keys(newAttendeesMap);
    const deletedIds = [];

    Object.keys(entitiesCopy).forEach(id => {
      if (entitiesCopy[id].meeting.id === meetingId && !newAttendeesIds.includes(id)) {
        deletedIds.push(+id);
        delete entitiesCopy[id];
      }
    });

    if (deletedIds.length) {
      lookupsCopy.byProjectId[projectId] = lookupsCopy.byProjectId[projectId].filter(id => !deletedIds.includes(id));
    }
  }

  Object.values(newAttendeesMap).forEach((attendee: Attendee) => {
    lookupsCopy.byProjectId[projectId] = ArrayUtil.concatInDistinctArray(
      lookupsCopy.byProjectId[projectId],
      attendee.id,
    );
  });

  return {
    ...state,
    statuses: resolveStatuses(projectId, state, { error: false, loaded: true, loading: false, updating: false }),
    entities: entitiesCopy,
    lookups: lookupsCopy,
  };
};

const setAttendee = (
  state: AttendeesCollection,
  projectId: number,
  attendeePayload: IAttendee,
  statuses?: Partial<EntityCollectionStatus>,
): AttendeesCollection => {
  const newAttendee = attendeeInitializer(attendeePayload);

  const entitiesCopy = {
    ...state.entities,
    [newAttendee.id]: newAttendee,
  };

  const lookupsCopy = copyObject(state.lookups);

  lookupsCopy.byProjectId[projectId] = ArrayUtil.concatInDistinctArray(
    lookupsCopy.byProjectId[projectId],
    newAttendee.id,
  );

  return {
    ...state,
    statuses: resolveStatuses(projectId, state, {
      error: false,
      creating: false,
      updating: false,
      ...(statuses || {}),
    }),
    entities: entitiesCopy,
    lookups: lookupsCopy,
  };
};

const removeAttendee = (state: AttendeesCollection, projectId: number, attendeeId: number): AttendeesCollection => {
  const entitiesCopy = { ...state.entities };
  const lookupsCopy = copyObject(state.lookups);

  delete entitiesCopy[attendeeId];

  lookupsCopy.byProjectId[projectId] = lookupsCopy.byProjectId[projectId].filter(id => id !== attendeeId);

  return {
    ...state,
    statuses: resolveStatuses(projectId, state, { error: false, removing: false }),
    entities: entitiesCopy,
    lookups: lookupsCopy,
  };
};

export const attendeesReducers = handleActions(
  {
    // FetchAll

    [toStarted(AttendeeActionType.FetchAll)]: (state: AttendeesCollection, action: AttendeeAction) =>
      setAttendeesCollectionStatuses(state, action.meta.projectId, { loading: true }),

    [toFailed(AttendeeActionType.FetchAll)]: (state: AttendeesCollection, action: AttendeeAction) =>
      setAttendeesCollectionStatuses(state, action.meta.projectId, { loading: false, error: true }),

    [toSuccess(AttendeeActionType.FetchAll)]: (state: AttendeesCollection, action: AttendeeAction) =>
      setAllAttendees(state, action.meta.projectId, action.payload as IAttendee[]),

    // Fetch

    [toStarted(AttendeeActionType.Fetch)]: (state: AttendeesCollection, action: AttendeeAction) =>
      setAttendeesCollectionStatuses(state, action.meta.projectId, { loading: true }),

    [toFailed(AttendeeActionType.Fetch)]: (state: AttendeesCollection, action: AttendeeAction) =>
      setAttendeesCollectionStatuses(state, action.meta.projectId, { loading: false, error: true }),

    [toSuccess(AttendeeActionType.Fetch)]: (state: AttendeesCollection, action: AttendeeAction) =>
      setAttendee(state, action.meta.projectId, action.payload as IAttendee),

    // Create

    [toStarted(AttendeeActionType.Create)]: (state: AttendeesCollection, action: AttendeeAction) =>
      setAttendeesCollectionStatuses(state, action.meta.projectId, { creating: true }),

    [toFailed(AttendeeActionType.Create)]: (state: AttendeesCollection, action: AttendeeAction) =>
      setAttendeesCollectionStatuses(state, action.meta.projectId, { creating: true }),

    [toSuccess(AttendeeActionType.Create)]: (state: AttendeesCollection, action: AttendeeAction) =>
      setAttendee(state, action.meta.projectId, action.payload as IAttendee),

    // Update

    [toStarted(AttendeeActionType.Update)]: (state: AttendeesCollection, action: AttendeeAction) =>
      setAttendeesCollectionStatuses(state, action.meta.projectId, { updating: true, updated: false }),

    [toFailed(AttendeeActionType.Update)]: (state: AttendeesCollection, action: AttendeeAction) =>
      setAttendeesCollectionStatuses(state, action.meta.projectId, { updating: false, updated: false, error: true }),

    [toSuccess(AttendeeActionType.Update)]: (state: AttendeesCollection, action: AttendeeAction) =>
      setAttendee(state, action.meta.projectId, action.payload as IAttendee, { updated: true }),

    // Delete

    [toStarted(AttendeeActionType.Delete)]: (state: AttendeesCollection, action: AttendeeAction) =>
      setAttendeesCollectionStatuses(state, action.meta.projectId, { removing: true }),

    [toFailed(AttendeeActionType.Delete)]: (state: AttendeesCollection, action: AttendeeAction) =>
      setAttendeesCollectionStatuses(state, action.meta.projectId, { removing: false, error: true }),

    [toSuccess(AttendeeActionType.Delete)]: (state: AttendeesCollection, action: AttendeeAction) =>
      removeAttendee(state, action.meta.projectId, action.meta.attendeeId),

    // Meeting FullUpdate

    [toStarted(MeetingActionType.FullUpdate)]: (state: AttendeesCollection, action: MeetingAction) =>
      setAttendeesCollectionStatuses(state, action.meta.projectId, { updating: true }),

    [toFailed(MeetingActionType.FullUpdate)]: (state: AttendeesCollection, action: MeetingAction) =>
      setAttendeesCollectionStatuses(state, action.meta.projectId, { updating: false, error: true }),

    [toSuccess(MeetingActionType.FullUpdate)]: (state: AttendeesCollection, action: MeetingFullUpdateAction) =>
      setAllAttendees(state, action.meta.projectId, action.payload.attendees, action.payload.meeting.id),
  },
  DefaultAttendeesCollection,
);
