import React from 'react';
import { Trans } from 'react-i18next';
import { Plan, PlanLevel } from '@priz/shared/src/models/workspace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';

interface PlanSelectButtonProps {
  planLevel: PlanLevel;
  currentPlan?: Plan;
  selectedPlanLevel?: PlanLevel;
  onPlanSelect?: (level: PlanLevel) => void;
  loading?: boolean;
}

export const PlanSelectButton: React.FC<PlanSelectButtonProps> = ({
  currentPlan,
  selectedPlanLevel,
  planLevel,
  onPlanSelect,
  loading,
}) => {
  const handleSelectPlanLevel = () => {
    if (onPlanSelect) {
      onPlanSelect(planLevel);
    }
  };

  const resolveButtonText = () => {
    if (currentPlan?.level === planLevel) {
      return 'Current';
    } else if (selectedPlanLevel === planLevel) {
      return 'Selected';
    } else {
      return 'Select';
    }
  };

  if (planLevel === PlanLevel.Enterprise) {
    return (
      <Button
        variant={'pg_rounded'}
        color={'pg_orange_outlined'}
        disabled={currentPlan?.level === planLevel}
        href={'https://www.priz.guru/contact-us'}
      >
        <Trans>Contact us</Trans>
      </Button>
    );
  }

  return (
    <>
      {selectedPlanLevel === planLevel ? (
        <Button
          variant={'pg_rounded'}
          color={'pg_orange_solid'}
          onClick={handleSelectPlanLevel}
          disabled={currentPlan?.level === planLevel}
          endIcon={
            loading && currentPlan?.level !== planLevel ? (
              <FontAwesomeIcon icon={faSpinner} size={'xs'} spin={true} />
            ) : null
          }
        >
          <Trans>{resolveButtonText()}</Trans>
        </Button>
      ) : (
        <Button
          variant={'pg_rounded'}
          color={'pg_orange_outlined'}
          onClick={handleSelectPlanLevel}
          disabled={currentPlan?.level === planLevel}
        >
          <Trans>{resolveButtonText()}</Trans>
        </Button>
      )}
    </>
  );
};
