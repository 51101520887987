import React from 'react';
import { Box, IconButton, ListItemIcon, MenuItem, MenuList, Tooltip, Typography } from '@mui/material';
import {
  CheckCircleOutlineRounded,
  CircleOutlined,
  CommentOutlined,
  DeleteOutlineRounded,
  DeviceHubOutlined,
  ErrorOutlineRounded,
  PeopleAltOutlined,
  TipsAndUpdatesOutlined,
  AssignmentLateOutlined,
  HandymanOutlined,
  SaveAltRounded,
  SettingsOutlined,
  SnippetFolderOutlined,
  StartRounded,
  WarningAmberRounded,
  WidthNormalOutlined,
} from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { CftNodeControlsRendererProps, CftNodeStatus } from '@priz/shared/src/models/tools/cft';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { useStyles } from './styles';
import { cftSettings } from '@priz/shared/src/components/cft/data';
import { CftNodeRelations } from '../store/model';
import { CftNodeRelationsRenderer } from '../cft-node-relations-renderer/component';
import { pgColorScheme } from '@priz/shared/src/theme';
import { ProjectFeatureBlockerActions } from '../../project-feature-blocker/store/actions';
import {
  ProjectFeatureBlockerType,
  ProjectFeatureResolverBlockerProps,
} from '../../project-feature-blocker/store/model';
import { useDispatch } from 'react-redux';
import { ToolType } from '@priz/shared/src/models/tools';

export interface CftNodeControlsCallbacks {
  onAddProcess?: (nodeId: string) => void;
  onDelete?: (nodeId: string) => void;
  onAddVariant?: (nodeId: string) => void;
  onAddStep?: (nodeId: string) => void;
  onCreateAbstraction?: (nodeId: string) => void;
  onCreateMeeting?: (nodeId: string) => void;
  onCreateTool?: (nodeId: string) => void;
  onCreateTask?: (nodeId: string) => void;
  onCreateIdea?: (nodeId: string) => void;
  onAddComment?: (nodeId: string) => void;
  onSetStatus?: (nodeId: string, status?: CftNodeStatus) => void;
}

interface CftNodeControlsProps extends CftNodeControlsCallbacks, CftNodeControlsRendererProps {
  utilizationId: number;
  projectId?: number;
  relations?: CftNodeRelations;
  disabled?: boolean;
}

const TooltipContainer: React.FC<{ text: string }> = ({ text, children }) => {
  return (
    <Tooltip title={<Trans>{text}</Trans>} placement={'bottom'} disableInteractive disableTouchListener arrow>
      <div>{children}</div>
    </Tooltip>
  );
};

export const CftNodeControls: React.FC<CftNodeControlsProps> = ({
  utilizationId,
  projectId,
  node,
  onAddProcess,
  onDelete,
  onAddVariant,
  onAddStep,
  onCreateAbstraction,
  onCreateMeeting,
  onCreateTool,
  onCreateTask,
  onCreateIdea,
  onAddComment,
  onSetStatus,
  relations,
  disabled,
  className,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const rootClassNames = [styles.root];
  const isProjectIdDefined = typeof projectId !== 'undefined';

  if (className) {
    rootClassNames.push(className);
  }

  const callIfProjectDefined = (callback: (nodeId: string) => void, blockerType: ProjectFeatureBlockerType) => {
    if (isProjectIdDefined) {
      callback(node.id);
    } else {
      const blockerProps: ProjectFeatureResolverBlockerProps = {
        utilizationId,
        blocker: blockerType,
      };

      if (blockerType === ProjectFeatureBlockerType.Meeting) {
        blockerProps.meetingRecorderProps = {
          sourceId: node.id,
          sourceToolId: utilizationId,
          sourceToolType: ToolType.CFT,
        };
      }

      if (blockerType === ProjectFeatureBlockerType.Tool) {
        blockerProps.toolRecorderProps = {
          sourceId: node.id,
          parentId: utilizationId,
        };
      }

      if (blockerType === ProjectFeatureBlockerType.Task) {
        blockerProps.taskRecorderProps = {
          sourceId: node.id,
          sourceToolId: utilizationId,
          sourceToolType: ToolType.CFT,
        };
      }

      if (blockerType === ProjectFeatureBlockerType.Idea) {
        blockerProps.ideaRecorderProps = {
          sourceId: node.id,
          sourceToolId: utilizationId,
          sourceToolType: ToolType.CFT,
        };
      }

      dispatch(ProjectFeatureBlockerActions.set(blockerProps));
    }
  };

  const renderProjectRequiredLabel = () => {
    return (
      <Typography component={'span'} variant={'caption'} color={'text_color.light'}>
        {' '}
        (<Trans>project is required</Trans>)
      </Typography>
    );
  };

  return (
    <>
      {relations && relations.relationsCount > 0 && <div className={styles.relationsBackdrop} />}

      <Box className={rootClassNames.join(' ')}>
        {relations && relations.relationsCount > 0 && (
          <PopperWrap className={styles.relationsPopper} openHandler={'hover'} zIndex={'back'}>
            <IconButton className={[styles.relationsButton, 'cft-control'].join(' ')} size={'small'}>
              <SnippetFolderOutlined className={styles.relationsIcon} fontSize={'small'} />

              <Typography variant={'subtitle2'} component={Box} ml={0.5}>
                {relations.relationsCount}
              </Typography>
            </IconButton>

            <CftNodeRelationsRenderer
              relations={relations}
              width={500}
              maxWidth={'calc(100vw - 60px)'}
              className={styles.relationsContent}
              tabsContainerProps={{ className: styles.relationsTabs }}
            />
          </PopperWrap>
        )}

        {(onCreateAbstraction ||
          onCreateMeeting ||
          onAddComment ||
          onSetStatus ||
          onCreateTool ||
          onCreateTask ||
          onCreateIdea) && (
          <PopperWrap openHandler={'hover'} dropdownProps={{ p: 0 }} zIndex={'back'}>
            <IconButton className={[styles.button, 'cft-control'].join(' ')} size={'small'} disabled={disabled}>
              <SettingsOutlined fontSize={'small'} />
            </IconButton>

            <MenuList>
              {onCreateAbstraction && (
                <MenuItem onClick={() => onCreateAbstraction(node.id)} disabled={disabled}>
                  <ListItemIcon>
                    <StartRounded fontSize={'small'} color={'success'} />
                  </ListItemIcon>

                  <Typography variant={'inherit'}>
                    <Trans>Alternative Change</Trans>
                  </Typography>
                </MenuItem>
              )}

              {onAddComment && (
                <MenuItem onClick={() => onAddComment(node.id)} disabled={disabled}>
                  <ListItemIcon>
                    <CommentOutlined fontSize={'small'} sx={{ color: pgColorScheme.mediumBlue }} />
                  </ListItemIcon>

                  <Typography variant={'inherit'}>
                    <Trans>Add comment</Trans>
                  </Typography>
                </MenuItem>
              )}

              {onCreateMeeting && (
                <MenuItem
                  onClick={() => callIfProjectDefined(onCreateMeeting, ProjectFeatureBlockerType.Meeting)}
                  disabled={disabled}
                >
                  <ListItemIcon>
                    <PeopleAltOutlined fontSize={'small'} sx={{ color: pgColorScheme.purple }} />
                  </ListItemIcon>

                  <Typography variant={'inherit'}>
                    <Trans>Create meeting</Trans>
                    {!isProjectIdDefined && renderProjectRequiredLabel()}
                  </Typography>
                </MenuItem>
              )}

              {onCreateTool && (
                <MenuItem
                  onClick={() => callIfProjectDefined(onCreateTool, ProjectFeatureBlockerType.Tool)}
                  disabled={disabled}
                >
                  <ListItemIcon>
                    <HandymanOutlined fontSize={'small'} sx={{ color: pgColorScheme.gray600 }} />
                  </ListItemIcon>

                  <Typography variant={'inherit'}>
                    <Trans>Create tool</Trans>
                    {!isProjectIdDefined && renderProjectRequiredLabel()}
                  </Typography>
                </MenuItem>
              )}

              {onCreateTask && (
                <MenuItem
                  onClick={() => callIfProjectDefined(onCreateTask, ProjectFeatureBlockerType.Task)}
                  disabled={disabled}
                >
                  <ListItemIcon>
                    <AssignmentLateOutlined fontSize={'small'} sx={{ color: pgColorScheme.darkOrange }} />
                  </ListItemIcon>

                  <Typography variant={'inherit'}>
                    <Trans>Create task</Trans>
                    {!isProjectIdDefined && renderProjectRequiredLabel()}
                  </Typography>
                </MenuItem>
              )}

              {onCreateIdea && (
                <MenuItem
                  onClick={() => callIfProjectDefined(onCreateIdea, ProjectFeatureBlockerType.Idea)}
                  disabled={disabled}
                >
                  <ListItemIcon>
                    <TipsAndUpdatesOutlined fontSize={'small'} sx={{ color: pgColorScheme.orangeBright }} />
                  </ListItemIcon>

                  <Typography variant={'inherit'}>
                    <Trans>Create idea</Trans>
                    {!isProjectIdDefined && renderProjectRequiredLabel()}
                  </Typography>
                </MenuItem>
              )}

              {onSetStatus && node.status && (
                <MenuItem
                  onClick={() => {
                    onSetStatus(node.id);
                  }}
                  disabled={disabled}
                >
                  <ListItemIcon>
                    <CircleOutlined fontSize={'small'} sx={{ color: cftSettings.colors.status.default }} />
                  </ListItemIcon>

                  <Typography variant={'inherit'}>
                    <Trans>Mark as common</Trans>
                  </Typography>
                </MenuItem>
              )}

              {onSetStatus && node.status !== CftNodeStatus.Verified && (
                <MenuItem
                  onClick={() => {
                    onSetStatus(node.id, CftNodeStatus.Verified);
                  }}
                  disabled={disabled}
                >
                  <ListItemIcon>
                    <CheckCircleOutlineRounded fontSize={'small'} sx={{ color: cftSettings.colors.status.verified }} />
                  </ListItemIcon>

                  <Typography variant={'inherit'}>
                    <Trans>Mark as verified</Trans>
                  </Typography>
                </MenuItem>
              )}

              {onSetStatus && node.status !== CftNodeStatus.Problematic && (
                <MenuItem
                  onClick={() => {
                    onSetStatus(node.id, CftNodeStatus.Problematic);
                  }}
                  disabled={disabled}
                >
                  <ListItemIcon>
                    <WarningAmberRounded fontSize={'small'} sx={{ color: cftSettings.colors.status.problematic }} />
                  </ListItemIcon>

                  <Typography variant={'inherit'}>
                    <Trans>Mark as problematic</Trans>
                  </Typography>
                </MenuItem>
              )}

              {onSetStatus && node.status !== CftNodeStatus.Blocker && (
                <MenuItem
                  onClick={() => {
                    onSetStatus(node.id, CftNodeStatus.Blocker);
                  }}
                  disabled={disabled}
                >
                  <ListItemIcon>
                    <ErrorOutlineRounded fontSize={'small'} sx={{ color: cftSettings.colors.status.blocker }} />
                  </ListItemIcon>

                  <Typography variant={'inherit'}>
                    <Trans>Mark as blocker</Trans>
                  </Typography>
                </MenuItem>
              )}
            </MenuList>
          </PopperWrap>
        )}

        {onDelete && (
          <TooltipContainer text={'Remove'}>
            <IconButton
              className={[styles.button, 'cft-control'].join(' ')}
              size={'small'}
              onClick={() => onDelete(node.id)}
              disabled={disabled}
            >
              <DeleteOutlineRounded fontSize={'small'} />
            </IconButton>
          </TooltipContainer>
        )}

        {onAddProcess && (
          <TooltipContainer text={'Add change sequence'}>
            <IconButton
              className={[styles.button, 'cft-control'].join(' ')}
              size={'small'}
              onClick={() => onAddProcess(node.id)}
              disabled={disabled}
            >
              <WidthNormalOutlined fontSize={'small'} />
            </IconButton>
          </TooltipContainer>
        )}

        {onAddVariant && (
          <TooltipContainer text={'Add option'}>
            <IconButton
              className={[styles.button, 'cft-control'].join(' ')}
              size={'small'}
              onClick={() => onAddVariant(node.id)}
              disabled={disabled}
            >
              <DeviceHubOutlined fontSize={'small'} />
            </IconButton>
          </TooltipContainer>
        )}

        {onAddStep && (
          <TooltipContainer text={'Add step'}>
            <IconButton
              className={[styles.button, 'cft-control'].join(' ')}
              size={'small'}
              onClick={() => onAddStep(node.id)}
              disabled={disabled}
            >
              <SaveAltRounded fontSize={'small'} />
            </IconButton>
          </TooltipContainer>
        )}
      </Box>
    </>
  );
};
