import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EbsIdeaSelectors } from '../../../ebs/store/selectors';
import { EbsUtilization } from '@priz/shared/src/models/tools/ebs';
import { EbsUtilizationActions } from '../../../ebs/store/actions';
import { EbsViewer } from '@priz/shared/src/components/tools-viewers';

export const EbsReportResult: React.FC<{ tool: EbsUtilization }> = ({ tool }) => {
  const dispatch = useDispatch();

  const ebsIdeas = useSelector(EbsIdeaSelectors.getAllByUtilizationId(tool?.id));

  useEffect(() => {
    dispatch(EbsUtilizationActions.fetchIdeas(tool.id));
  }, []);

  if (!tool || !ebsIdeas.length) return null;

  return <EbsViewer ideas={ebsIdeas} grouped={tool?.grouped} disableInteractive />;
};
