import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  backdrop: {
    zIndex: 9999,
    padding: 20,
  },
  closeArea: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
});
