import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { useStyles } from './styles';
import { Trans } from 'react-i18next';

export interface RankingScoreProps {
  rankingScore: number;
  small?: boolean;
  shadowBackground?: boolean;
}

export const RankingScore: React.FC<RankingScoreProps> = ({ rankingScore, small, shadowBackground }) => {
  const styles = useStyles();
  const classNames = [styles.rankingScore, small ? styles.small : styles.medium];

  if (shadowBackground) classNames.push(styles.shadowBackground);

  return (
    <Tooltip title={<Trans>Ranking Score</Trans>} placement={'top'} arrow disableInteractive>
      <div className={classNames.join(' ')}>
        <Typography variant={'subtitle2'}>{rankingScore}</Typography>
      </div>
    </Tooltip>
  );
};
