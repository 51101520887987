import { EntityCollectionStatus, EntityMap, LookupMap } from '@priz/shared/src/models/common/entity-collection-state';
import { Meeting } from './meeting';

export interface MeetingsLookups {
  byProjectId: LookupMap;
}

export interface MeetingsCollectionStatuses {
  byProjectId?: { [projectId: number]: EntityCollectionStatus };
}

export interface MeetingsCollection {
  entities: EntityMap<Meeting>;

  lookups: MeetingsLookups;

  statuses: MeetingsCollectionStatuses;
}

export const DefaultMeetingsCollection: MeetingsCollection = {
  entities: {},

  lookups: {
    byProjectId: {},
  },

  statuses: {
    byProjectId: {},
  },
};
