import React from 'react';
import { useStyles } from './styles';
import { Trans } from 'react-i18next';
import { Box, Tooltip } from '@mui/material';
import { Locales } from '../../../models/locales';
import { LocalesService } from '../services';
import { localesTextMap } from '../data';
import { LocaleIcon } from '../locale-icon/component';

interface LocaleButtonProps {
  locale: Locales;
}

export const LocaleButton: React.FC<LocaleButtonProps> = ({ locale }) => {
  const styles = useStyles();
  const currentLocale = LocalesService.getCurrentLocale();
  const rootClassNames = [styles.root];

  if (currentLocale === locale) rootClassNames.push(styles.bordered);

  return (
    <Tooltip title={<Trans>{localesTextMap[locale]}</Trans>} placement="top">
      <Box
        className={rootClassNames.join(' ')}
        onClick={() => {
          LocalesService.changeLocale(locale);
        }}
        p={0.5}
      >
        <LocaleIcon locale={locale} />
      </Box>
    </Tooltip>
  );
};
