import { handleActions } from 'redux-actions';
import { toSuccess } from '../../../../shared/store/action-creator';
import { PreloaderActionType } from '../actions';
import { DefaultPreloaderState, PreloaderState } from '../model';

export const preloaderReducers = handleActions(
  {
    [toSuccess(PreloaderActionType.Show)]: (): PreloaderState => ({
      loading: true,
    }),
    [toSuccess(PreloaderActionType.Hide)]: (): PreloaderState => ({
      loading: false,
    }),
  },
  DefaultPreloaderState,
);
