import { FluxStandardAction } from 'flux-standard-action';
import { createPgAction } from '../../../shared/store/action-creator';
import { FiveWhysSolutionApi } from '../../service';
import { IFiveWhysSolution } from '@priz/shared/src/models/tools/five-whys';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { ToolType } from '@priz/shared/src/models/tools';

export enum FiveWhysSolutionActionType {
  Fetch = 'five-whys/solution/fetch',
  Create = 'five-whys/solution/create',
  Update = 'five-whys/solution/update',
}

type FiveWhysSolutionPayload = IFiveWhysSolution | IFiveWhysSolution[];

interface CecRootCauseMeta {
  projectId?: number;
  utilizationId?: number;
  rootCauseId?: number;
  causeId?: number;
}

export type FiveWhysSolutionAction = FluxStandardAction<string, FiveWhysSolutionPayload, CecRootCauseMeta>;

const loadSolutions = createPgAction(
  FiveWhysSolutionActionType.Fetch,
  FiveWhysSolutionApi.fetchAll,
  (utilizationId: number) => ({ utilizationId }),
);

const createSolution = createPgAction(
  FiveWhysSolutionActionType.Create,
  FiveWhysSolutionApi.createSolution,
  (utilizationId: number, causeId: number, description: string, projectId?: number) => ({
    causeId,
    description,
    projectId,
    utilizationId,
  }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.FIVE_WHYS,
      utilization_id: utilizationId,
    });
  },
);

const updateSolutionDescription = createPgAction(
  FiveWhysSolutionActionType.Update,
  FiveWhysSolutionApi.updateSolution,
  (utilizationId: number, rootCauseId: number, description: string, projectId?: number) => ({
    description,
    projectId,
    rootCauseId,
    utilizationId,
  }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.FIVE_WHYS,
      utilization_id: utilizationId,
    });
  },
);

export const FiveWhysSolutionActions = {
  updateSolutionDescription,
  createSolution,
  loadSolutions,
};
