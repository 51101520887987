import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Network } from '@priz/shared/src/lib/vis/esnext';
import { DataSet } from 'vis-data';
import { useStyles } from './styles';
import {
  fitTextarea,
  getTrimmedInputValueByEvent,
  moveViewToNodeIfItOutOfCanvas,
} from '../../../cec/cause-and-effect-chain-diagram/utils';
import { NodeProps } from '@priz/shared/src/models/vis-network';
import { getNodeDomPosition, manageNetworkEvents } from '@priz/shared/src/components/network-diagram/utils';
import { pgColorScheme } from '@priz/shared/src/theme';

export interface NetworkNodeWidgetProps {
  network: Network;
  nodesDataSet: DataSet<NodeProps>;
  canvasContainer: HTMLDivElement;
  onNodeSelect?: (node: NodeProps) => void;
  topPanelContent?: ReactElement;
  rightPanelContent?: ReactElement;
  leftPanelContent?: ReactElement;
  bottomPanelContent?: ReactElement;
  topPanelClassName?: string;
  rightPanelClassName?: string;
  leftPanelClassName?: string;
  bottomPanelClassName?: string;
  editorBackground?: string;
  editorBorderRadius?: string | number;
  textareaPadding?: string | number;
  widgetPadding?: string | number;
  textareaFontSize?: number;
}

const networkEvents = (
  drawingCallback: () => void,
  selectCallback: (nodeId: string | null) => void,
  dragStartCallback: (nodeId: string | null) => void,
) => ({
  afterDrawing: () => {
    drawingCallback();
  },
  doubleClick: data => {
    if (data?.nodes?.length) selectCallback(data.nodes[0]);
  },
  selectNode: data => {
    if (data?.nodes?.length) selectCallback(data.nodes[0]);
  },
  dragStart: data => {
    if (data?.nodes?.length) dragStartCallback(data.nodes[0]);
  },
  deselectNode: () => {
    selectCallback(null);
  },
});

export const NetworkNodeWidget: React.FC<NetworkNodeWidgetProps> = ({
  network,
  nodesDataSet,
  canvasContainer,
  onNodeSelect,
  topPanelContent,
  rightPanelContent,
  leftPanelContent,
  bottomPanelContent,
  editorBackground,
  textareaPadding,
  widgetPadding,
  editorBorderRadius,
  topPanelClassName,
  rightPanelClassName,
  leftPanelClassName,
  bottomPanelClassName,
  textareaFontSize,
}) => {
  const styles = useStyles();
  const nodeTextAreaRef = useRef(null);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [nodeTextAreaVal, setNodeTextAreaVal] = useState('');
  const [interfacePositionStyles, setInterfacePositionStyles] = useState({ widget: {} });

  useEffect(() => {
    nodesDataSet.on('remove', dataSetRemoveCallback);

    return () => {
      nodesDataSet.off('remove', dataSetRemoveCallback);
    };
  }, []);

  useEffect(() => {
    const events = networkEvents(onDrawing, onSelect, dragStart);

    if (network) manageNetworkEvents(network, events);

    return () => {
      if (network) {
        manageNetworkEvents(network, events, true, true);
      }
    };
  }, [network, selectedNodeId]);

  const dataSetRemoveCallback = () => {
    setSelectedNodeId(null);
  };

  const moveTextAreaToNode = id => {
    const nodeDomPosition = getNodeDomPosition(network, id);

    if (nodeDomPosition) {
      setInterfacePositionStyles({
        widget: {
          top: nodeDomPosition.top,
          left: nodeDomPosition.left,
          width: nodeDomPosition.width,
          height: nodeDomPosition.height,
          transform: `scale(${nodeDomPosition.scale})`,
        },
      });

      fitTextarea(nodeTextAreaRef.current);
    }
  };

  const onDrawing = () => {
    if (selectedNodeId) {
      moveTextAreaToNode(selectedNodeId);
    }
  };

  const dragStart = (nodeId: string) => {
    if (selectedNodeId !== nodeId) {
      setSelectedNodeId(null);
    }
  };

  const onSelect = (nodeId: string | null) => {
    setSelectedNodeId(nodeId);

    if (nodeId) {
      const node = nodesDataSet.get(nodeId);

      setNodeTextAreaVal(node.label || '');
      if (nodeTextAreaRef.current) nodeTextAreaRef.current.focus();
      if (onNodeSelect) onNodeSelect(node);
    }
  };

  const moveToNodeIfOut = (nodeId, animationDuration?: number, offset?: number) => {
    moveViewToNodeIfItOutOfCanvas(network, canvasContainer, nodeId, animationDuration, offset);
  };

  const textAreaInputHandler = (e, moveToNode = false) => {
    if (!selectedNodeId) return null;

    const formatText = getTrimmedInputValueByEvent(e);

    const update: { id: string; label: string } = {
      id: selectedNodeId,
      label: formatText,
    };

    if (moveToNode) {
      moveToNodeIfOut(selectedNodeId, 0);
    }

    setNodeTextAreaVal(formatText);
    nodesDataSet.updateOnly(update);
  };

  return (
    <div
      className={styles.root}
      style={{
        ...interfacePositionStyles.widget,
        padding: widgetPadding || 2,
      }}
      hidden={!selectedNodeId}
    >
      <label
        className={styles.textAreaLabel}
        style={{
          background: editorBackground || pgColorScheme.white,
          borderRadius: editorBorderRadius || 0,
        }}
      >
        <textarea
          className={styles.nodeTextArea}
          ref={nodeTextAreaRef}
          onInput={e => {
            textAreaInputHandler(e, true);
          }}
          onKeyDown={e => e.stopPropagation()}
          value={nodeTextAreaVal}
          style={{
            padding: textareaPadding || 20,
            fontSize: textareaFontSize || 14,
          }}
        />
      </label>

      <div className={`${styles.panelContainer} ${topPanelClassName || styles.topPanelContainer}`}>
        {topPanelContent}
      </div>

      {rightPanelContent && (
        <div className={`${styles.panelContainer} ${rightPanelClassName || styles.rightPanelContainer}`}>
          {rightPanelContent}
        </div>
      )}

      {bottomPanelContent && (
        <div className={`${styles.panelContainer} ${leftPanelClassName || styles.bottomPanelContainer}`}>
          {bottomPanelContent}
        </div>
      )}

      {leftPanelContent && (
        <div className={`${styles.panelContainer} ${bottomPanelClassName || styles.leftPanelContainer}`}>
          {leftPanelContent}
        </div>
      )}
    </div>
  );
};
