import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  icon: {
    display: 'block',
    width: 30,
    height: 30,
  },
  title: {
    lineHeight: 1.4,
  },
  statusWrap: {
    textAlign: 'right',
  },
  status: {
    boxShadow: `500px 0 0 ${pgColorScheme.backgroundMediumGray} inset`,
  },
});
