import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { RrmUtilization } from '@priz/shared/src/models/tools/rrm/rrm-utilization';
import { RrmPerceptionEditor } from '../RrmPerceptionEditor';
import { Perception } from '@priz/shared/src/models/tools/rrm';
import { RrmGoalActions } from '../store';
import { Trans } from 'react-i18next';
import { RankingScore } from '@priz/shared/src/components/ranking-score/component';
import { DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material';
import { RrmGoalSelector } from '../store/selectors';

interface RrmPerceptionProps {
  utilization: RrmUtilization;
  perception: Perception;
  disabled?: boolean;
}

export const RrmPerception: React.FC<RrmPerceptionProps> = ({ utilization, perception, disabled }) => {
  const dispatch = useDispatch();

  const [isEditorDialogOpen, setIsEditorDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const isRemoving = useSelector(RrmGoalSelector.isPerceptionRemoving(utilization.goal.id, perception.id));

  const deletePerception = () => {
    dispatch(
      RrmGoalActions.deletePerception(utilization.id, perception.id, utilization.goal.id, utilization.project?.id),
    );
  };

  const openEditDialog = () => {
    setIsEditorDialogOpen(true);
  };

  const closeEditDialog = () => {
    setIsEditorDialogOpen(false);
  };

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  return (
    <>
      <Paper variant={'outlined'} component={Box} p={1}>
        <Grid container alignItems={'flex-start'} spacing={1}>
          {perception.rankingScore !== null && typeof perception.rankingScore !== 'undefined' && (
            <Grid item xs={'auto'}>
              <Box py={0.25}>
                <RankingScore rankingScore={perception.rankingScore} />
              </Box>
            </Grid>
          )}

          <Grid item xs={true}>
            <Typography variant={'body1'} component={Box} px={1} py={0.75} onClick={openEditDialog}>
              {perception.definition}
            </Typography>
          </Grid>

          <Grid item xs={'auto'}>
            <Button variant={'text'} onClick={openEditDialog} startIcon={<EditOutlined />} disabled={disabled}>
              <Trans>Edit</Trans>
            </Button>

            <Button
              variant={'text'}
              onClick={openDeleteDialog}
              startIcon={<DeleteOutlineOutlined />}
              disabled={disabled}
            >
              <Trans>Delete</Trans>
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <RrmPerceptionEditor
        utilization={utilization}
        perception={perception}
        isOpen={isEditorDialogOpen}
        onClose={closeEditDialog}
      />

      <PgConfirmationDialog
        isOpen={isDeleteDialogOpen}
        okButtonText={<Trans>Delete</Trans>}
        confirmContent={<Trans>Are you sure you want to delete this perception?</Trans>}
        confirmTitle={<Trans>Confirm delete</Trans>}
        onConfirm={deletePerception}
        onClose={closeDeleteDialog}
        loading={isRemoving}
        disabled={isRemoving}
      />
    </>
  );
};
