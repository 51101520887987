import React, { useState } from 'react';
import { TaskRecorder } from '../../../tasks/task-recorder/component';
import { Trans } from 'react-i18next';
import { ToolUtilization } from '@priz/shared/src/models/tools';
import { Button, ButtonProps, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ProjectFeatureBlockerActions } from '../../../project-feature-blocker/store/actions';
import { ProjectFeatureBlockerType } from '../../../project-feature-blocker/store/model';
import { useDispatch } from 'react-redux';

export interface ButtonCreateTaskProps extends ButtonProps {
  projectId: number;
  sourceTool?: ToolUtilization;
  text?: string;
  tooltip?: string;
}

export const ButtonCreateTask: React.FC<ButtonCreateTaskProps> = ({
  projectId,
  sourceTool,
  text,
  tooltip,
  onClick,
  ...rest
}) => {
  const dispatch = useDispatch();

  const [taskDialogOpen, setTaskDialogOpen] = useState(false);

  const openTaskDialog = () => {
    if (typeof projectId !== 'undefined') {
      setTaskDialogOpen(true);
    } else {
      dispatch(
        ProjectFeatureBlockerActions.set({
          utilizationId: sourceTool?.id,
          blocker: ProjectFeatureBlockerType.Task,
          taskRecorderProps: {
            sourceToolId: sourceTool?.id,
            sourceToolType: sourceTool?.type,
          },
        }),
      );
    }
  };

  const closeTaskDialog = () => {
    setTaskDialogOpen(false);
  };

  return (
    <>
      <Tooltip title={tooltip ? <Trans>{tooltip}</Trans> : ''} placement={'top'} disableInteractive arrow>
        <div>
          <Button
            variant={'pg_rounded'}
            color={'pg_orange_solid'}
            onClick={onClick || openTaskDialog}
            startIcon={<AddIcon />}
            {...rest}
          >
            <Trans>{text || 'Create new task'}</Trans>
          </Button>
        </div>
      </Tooltip>

      <TaskRecorder
        isOpen={taskDialogOpen}
        projectId={projectId}
        sourceToolId={sourceTool?.id}
        sourceToolType={sourceTool?.type}
        onClose={closeTaskDialog}
      />
    </>
  );
};
