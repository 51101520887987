import React from 'react';
import { Crumb } from './crumb/component';
import { Box } from '@mui/material';
import { ChevronRightRounded } from '@mui/icons-material';
import { BreadcrumbsPath } from './model';
import { generateCrumbsPropsMap } from './data';
import { useBreadcrumbsPath } from './utils';

interface BreadcrumbsProps {
  projectId: number;
  basePath?: BreadcrumbsPath;
  minify?: boolean;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ projectId, basePath = [], minify }) => {
  const { path } = useBreadcrumbsPath(projectId, basePath);
  const crumbsPropsMap = generateCrumbsPropsMap(projectId);

  return (
    <Box display={'flex'}>
      {path.map((item, key) => {
        const crumbData = typeof item === 'string' ? crumbsPropsMap[item] : item;
        const { to, ...rest } = crumbData;
        const isLast = key === path.length - 1;
        const linkTo = isLast ? undefined : to;

        return (
          <Box key={key} display={'flex'} alignItems={'center'}>
            <Crumb minify={minify} to={linkTo} {...rest} />

            {!isLast && (
              <Box px={0.5}>
                <ChevronRightRounded sx={{ fontSize: 14, display: 'block' }} />
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
