import { pgColorScheme } from '../../theme';
import { EbsIdeaDifficultyVariant } from '../../models/tools/ebs';
import { Network as NetworkType } from '../../lib/vis/esnext';

export const drawLines = (ctx: CanvasRenderingContext2D, network: NetworkType) => {
  const { clientWidth, clientHeight } = ctx.canvas;
  const { x, y } = network.getViewPosition();
  const scale = network.getScale();
  const color = pgColorScheme.white;
  const lineWeight = 2 / scale;
  const lineWidth = clientWidth / scale / 2;
  const lineHeight = clientHeight / scale / 2;

  ctx.lineWidth = lineWeight;
  ctx.strokeStyle = color;
  ctx.globalCompositeOperation = 'destination-over';

  // vertical top
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(0, -(lineHeight - y));
  ctx.stroke();

  // vertical bottom
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(0, lineHeight + y);
  ctx.stroke();

  // horizontal left
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(-(lineWidth - x), 0);
  ctx.stroke();

  // horizontal right
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(lineWidth + x, 0);
  ctx.stroke();
};

interface AreaLabelsTranslation {
  implementationLabel: string;
  validationLabel: string;
  easy: string;
  notEasy: string;
}

interface DrawAreaLabelProps {
  ctx: CanvasRenderingContext2D;
  translations: AreaLabelsTranslation;
  implementation: EbsIdeaDifficultyVariant;
  validation: EbsIdeaDifficultyVariant;
  xMlt?: 1 | -1;
  yMlt?: 1 | -1;
  offset?: number;
}

export const drawAreasLabels = (ctx: CanvasRenderingContext2D, translations: AreaLabelsTranslation) => {
  drawAreaLabel({
    ctx,
    translations,
    implementation: EbsIdeaDifficultyVariant.Easy,
    validation: EbsIdeaDifficultyVariant.NotEasy,
    xMlt: -1,
    yMlt: -1,
  });

  drawAreaLabel({
    ctx,
    translations,
    implementation: EbsIdeaDifficultyVariant.Easy,
    validation: EbsIdeaDifficultyVariant.Easy,
    yMlt: -1,
  });

  drawAreaLabel({
    ctx,
    translations,
    implementation: EbsIdeaDifficultyVariant.NotEasy,
    validation: EbsIdeaDifficultyVariant.NotEasy,
    xMlt: -1,
  });

  drawAreaLabel({
    ctx,
    translations,
    implementation: EbsIdeaDifficultyVariant.NotEasy,
    validation: EbsIdeaDifficultyVariant.Easy,
  });
};

const difficultyColorMap: { [key in EbsIdeaDifficultyVariant]: string } = {
  [EbsIdeaDifficultyVariant.Easy]: '#64C66E',
  [EbsIdeaDifficultyVariant.NotEasy]: '#FF3014',
};

export const drawRoundRect = (ctx: CanvasRenderingContext2D, x: number, y: number, w: number, h: number, r: number) => {
  const r2d = Math.PI / 180;
  if (w - 2 * r < 0) {
    r = w / 2;
  }
  if (h - 2 * r < 0) {
    r = h / 2;
  }
  ctx.beginPath();
  ctx.moveTo(x + r, y);
  ctx.lineTo(x + w - r, y);
  ctx.arc(x + w - r, y + r, r, r2d * 270, r2d * 360, false);
  ctx.lineTo(x + w, y + h - r);
  ctx.arc(x + w - r, y + h - r, r, 0, r2d * 90, false);
  ctx.lineTo(x + r, y + h);
  ctx.arc(x + r, y + h - r, r, r2d * 90, r2d * 180, false);
  ctx.lineTo(x, y + r);
  ctx.arc(x + r, y + r, r, r2d * 180, r2d * 270, false);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
};

export const drawAreaLabel = ({
  ctx,
  translations,
  implementation,
  validation,
  xMlt = 1,
  yMlt = 1,
  offset = 12,
}: DrawAreaLabelProps) => {
  const { implementationLabel, validationLabel, easy, notEasy } = translations;
  const roundRectPaddingX = 7;
  const roundRectPaddingY = 4;
  const spaceBetweenIV = 16;
  const fontSize = 12;
  const valuesMap = {
    [EbsIdeaDifficultyVariant.Easy]: easy,
    [EbsIdeaDifficultyVariant.NotEasy]: notEasy,
  };

  let strokeWidth = offset;

  ctx.globalCompositeOperation = 'destination-over';
  ctx.font = `normal ${fontSize}px roboto`;
  ctx.textBaseline = 'top';
  ctx.textAlign = 'left';

  const strokeWidthArray = [
    ctx.measureText(implementationLabel + ' ').width + roundRectPaddingX,
    ctx.measureText(valuesMap[implementation]).width + spaceBetweenIV + roundRectPaddingX,
    ctx.measureText(validationLabel + ' ').width + roundRectPaddingX,
    ctx.measureText(valuesMap[validation]).width + roundRectPaddingX,
  ];

  const totalStrokeWidth = strokeWidthArray.reduce((pv, cv) => pv + cv, 0);
  const translateX = xMlt < 1 ? -(totalStrokeWidth + offset * 2) : 0;
  const translateY = yMlt < 1 ? -(fontSize + offset) : offset;

  ctx.fillStyle = pgColorScheme.darkText;
  ctx.fillText(implementationLabel, strokeWidth + translateX, translateY);
  strokeWidth += strokeWidthArray[0];

  ctx.fillStyle = difficultyColorMap[implementation];
  ctx.fillText(valuesMap[implementation], strokeWidth + translateX, translateY);
  strokeWidth += strokeWidthArray[1];

  ctx.fillStyle = pgColorScheme.darkText;
  ctx.fillText(validationLabel, strokeWidth + translateX, translateY);
  strokeWidth += strokeWidthArray[2];

  ctx.fillStyle = difficultyColorMap[validation];
  ctx.fillText(valuesMap[validation], strokeWidth + translateX, translateY);

  ctx.fillStyle = pgColorScheme.white;
  ctx.strokeStyle = difficultyColorMap[implementation];

  drawRoundRect(
    ctx,
    strokeWidthArray[0] + translateX + offset - roundRectPaddingX,
    translateY - roundRectPaddingY,
    strokeWidthArray[1] - spaceBetweenIV + roundRectPaddingX,
    fontSize + roundRectPaddingY * 2,
    (fontSize + roundRectPaddingY * 2) / 2,
  );

  ctx.strokeStyle = difficultyColorMap[validation];

  drawRoundRect(
    ctx,
    totalStrokeWidth - strokeWidthArray[3] + translateX + offset - roundRectPaddingX,
    translateY - roundRectPaddingY,
    strokeWidthArray[3] + roundRectPaddingX,
    fontSize + roundRectPaddingY * 2,
    (fontSize + roundRectPaddingY * 2) / 2,
  );
};

export const drawAreas = (ctx: CanvasRenderingContext2D, network: NetworkType) => {
  const { clientWidth, clientHeight } = ctx.canvas;
  const { x, y } = network.getViewPosition();
  const scale = network.getScale();
  const areaWidth = clientWidth / scale / 2;
  const areaHeight = clientHeight / scale / 2;

  ctx.globalCompositeOperation = 'destination-over';

  // top left
  ctx.fillStyle = '#FCECDD';
  ctx.fillRect(0, 0, -(areaWidth - x), -(areaHeight - y));

  // top right
  ctx.fillStyle = '#ECFFEC';
  ctx.fillRect(0, 0, areaWidth + x, -(areaHeight - y));

  // bottom left
  ctx.fillStyle = '#F7C9C8';
  ctx.fillRect(0, 0, -(areaWidth - x), areaHeight + y);

  // bottom right
  ctx.fillStyle = '#FCECDD';
  ctx.fillRect(0, 0, areaWidth + x, areaHeight + y);
};
