import { FluxStandardAction } from 'flux-standard-action';
import { ParameterService } from '../../services/parameter.service';
import { createPgAction } from '../../../shared/store/action-creator';
import { Parameter } from '@priz/shared/src/models/tools/forty-principles/parameter';
import { ActionMeta } from '../../../shared/store/types';

export enum ParameterActionType {
  Fetch = '40P/parameter/fetch',
  Update = '40P/parameter/update',
}

interface ParameterMeta extends ActionMeta {
  parameterId: number;
}

export type ParametersAction = FluxStandardAction<ParameterActionType, Parameter[]>;
export type ParameterAction = FluxStandardAction<ParameterActionType, Parameter, ParameterMeta>;

const loadParameters = createPgAction(ParameterActionType.Fetch, ParameterService.getParameters);

const updateParameter = createPgAction(
  ParameterActionType.Update,
  ParameterService.updateParameter,
  (parameterId: number) => ({ parameterId }),
);

export const ParameterActions = {
  loadParameters,
  updateParameter,
};
