import { handleActions, combineActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { copyObject } from '@priz/shared/src/utils/common';
import { toObjectMap } from '../../../shared/store/reducer-utils';
import { ArrayUtil } from '../../../shared/utils/array-util';
import { Meeting, MeetingsCollection, MeetingsCollectionStatuses, DefaultMeetingsCollection, IMeeting } from '../model';
import { MeetingAction, MeetingActionType, MeetingFullUpdateAction } from '../actions';

const resolveStatuses = (
  projectId: number,
  state: MeetingsCollection,
  statuses: Partial<EntityCollectionStatus>,
): MeetingsCollectionStatuses => {
  const statusesCopy = copyObject(state.statuses);

  statusesCopy.byProjectId[projectId] = { ...(statusesCopy.byProjectId[projectId] || {}), ...(statuses || {}) };

  return statusesCopy;
};

const setMeetingsCollectionStatuses = (
  state: MeetingsCollection,
  projectId: number,
  statuses: Partial<EntityCollectionStatus>,
): MeetingsCollection => {
  return {
    ...state,
    statuses: resolveStatuses(projectId, state, statuses),
  };
};

const meetingInitializer = (meetingData: IMeeting): Meeting => new Meeting(meetingData);

const setAllMeetings = (
  state: MeetingsCollection,
  projectId: number,
  meetingsPayload: IMeeting[],
): MeetingsCollection => {
  const newMeetingsMap = toObjectMap<Meeting>(meetingsPayload, meetingInitializer);

  const entitiesCopy = {
    ...state.entities,
    ...newMeetingsMap,
  };

  const lookupsCopy = copyObject(state.lookups);

  Object.values(newMeetingsMap).forEach((meeting: Meeting) => {
    lookupsCopy.byProjectId[projectId] = ArrayUtil.concatInDistinctArray(
      lookupsCopy.byProjectId[projectId],
      meeting.id,
    );
  });

  return {
    ...state,
    statuses: resolveStatuses(projectId, state, { error: false, loaded: true, loading: false }),
    entities: entitiesCopy,
    lookups: lookupsCopy,
  };
};

const setMeeting = (
  state: MeetingsCollection,
  projectId: number,
  meetingPayload: IMeeting,
  statuses?: Partial<EntityCollectionStatus>,
): MeetingsCollection => {
  const newMeeting = meetingInitializer(meetingPayload);

  const entitiesCopy = {
    ...state.entities,
    [newMeeting.id]: newMeeting,
  };

  const lookupsCopy = copyObject(state.lookups);

  lookupsCopy.byProjectId[projectId] = ArrayUtil.concatInDistinctArray(
    lookupsCopy.byProjectId[projectId],
    newMeeting.id,
  );

  return {
    ...state,
    statuses: resolveStatuses(projectId, state, statuses),
    entities: entitiesCopy,
    lookups: lookupsCopy,
  };
};

const removeMeeting = (state: MeetingsCollection, projectId: number, meetingId: number): MeetingsCollection => {
  const entitiesCopy = { ...state.entities };
  const lookupsCopy = copyObject(state.lookups);

  delete entitiesCopy[meetingId];

  lookupsCopy.byProjectId[projectId] = lookupsCopy.byProjectId[projectId].filter(id => id !== meetingId);

  return {
    ...state,
    statuses: resolveStatuses(projectId, state, { removing: false, removed: true, error: false }),
    entities: entitiesCopy,
    lookups: lookupsCopy,
  };
};

export const meetingsReducers = handleActions(
  {
    // FetchAll

    [toStarted(MeetingActionType.FetchAll)]: (state: MeetingsCollection, action: MeetingAction) =>
      setMeetingsCollectionStatuses(state, action.meta.projectId, { loading: true }),

    [toFailed(MeetingActionType.FetchAll)]: (state: MeetingsCollection, action: MeetingAction) =>
      setMeetingsCollectionStatuses(state, action.meta.projectId, { loading: false, error: true }),

    [toSuccess(MeetingActionType.FetchAll)]: (state: MeetingsCollection, action: MeetingAction) =>
      setAllMeetings(state, action.meta.projectId, action.payload as IMeeting[]),

    // Fetch

    [toStarted(MeetingActionType.Fetch)]: (state: MeetingsCollection, action: MeetingAction) =>
      setMeetingsCollectionStatuses(state, action.meta.projectId, { loading: true }),

    [toFailed(MeetingActionType.Fetch)]: (state: MeetingsCollection, action: MeetingAction) =>
      setMeetingsCollectionStatuses(state, action.meta.projectId, { loading: false, error: true }),

    [toSuccess(MeetingActionType.Fetch)]: (state: MeetingsCollection, action: MeetingAction) =>
      setMeeting(state, action.meta.projectId, action.payload as IMeeting, { loading: false, error: false }),

    // Create

    [toStarted(MeetingActionType.Create)]: (state: MeetingsCollection, action: MeetingAction) =>
      setMeetingsCollectionStatuses(state, action.meta.projectId, { creating: true, created: false }),

    [toFailed(MeetingActionType.Create)]: (state: MeetingsCollection, action: MeetingAction) =>
      setMeetingsCollectionStatuses(state, action.meta.projectId, { creating: false, created: false, error: true }),

    [toSuccess(MeetingActionType.Create)]: (state: MeetingsCollection, action: MeetingAction) =>
      setMeeting(state, action.meta.projectId, action.payload as IMeeting, {
        creating: false,
        created: true,
        error: false,
      }),

    // Update, Release, Start, Finish

    [combineActions(
      toStarted(MeetingActionType.Update),
      toStarted(MeetingActionType.Release),
      toStarted(MeetingActionType.Start),
      toStarted(MeetingActionType.Finish),
    )]: (state: MeetingsCollection, action: MeetingAction) =>
      setMeetingsCollectionStatuses(state, action.meta.projectId, { updating: true, updated: false }),

    [combineActions(
      toFailed(MeetingActionType.Update),
      toFailed(MeetingActionType.Release),
      toFailed(MeetingActionType.Start),
      toFailed(MeetingActionType.Finish),
    )]: (state: MeetingsCollection, action: MeetingAction) =>
      setMeetingsCollectionStatuses(state, action.meta.projectId, { updating: false, updated: false, error: true }),

    [combineActions(
      toSuccess(MeetingActionType.Update),
      toSuccess(MeetingActionType.Release),
      toSuccess(MeetingActionType.Start),
      toSuccess(MeetingActionType.Finish),
    )]: (state: MeetingsCollection, action: MeetingAction) =>
      setMeeting(state, action.meta.projectId, action.payload as IMeeting, {
        updating: false,
        updated: true,
        error: false,
      }),

    // Delete

    [toStarted(MeetingActionType.Delete)]: (state: MeetingsCollection, action: MeetingAction) =>
      setMeetingsCollectionStatuses(state, action.meta.projectId, { removing: true, removed: false }),

    [toFailed(MeetingActionType.Delete)]: (state: MeetingsCollection, action: MeetingAction) =>
      setMeetingsCollectionStatuses(state, action.meta.projectId, { removing: false, removed: false, error: true }),

    [toSuccess(MeetingActionType.Delete)]: (state: MeetingsCollection, action: MeetingAction) =>
      removeMeeting(state, action.meta.projectId, action.meta.meetingId),

    // FullUpdate

    [toStarted(MeetingActionType.FullUpdate)]: (state: MeetingsCollection, action: MeetingAction) =>
      setMeetingsCollectionStatuses(state, action.meta.projectId, { updating: true }),

    [toFailed(MeetingActionType.FullUpdate)]: (state: MeetingsCollection, action: MeetingAction) =>
      setMeetingsCollectionStatuses(state, action.meta.projectId, { updating: false, error: true }),

    [toSuccess(MeetingActionType.FullUpdate)]: (state: MeetingsCollection, action: MeetingFullUpdateAction) =>
      setMeeting(state, action.meta.projectId, action.payload.meeting, { updating: false, error: false }),
  },
  DefaultMeetingsCollection,
);
