import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { dashboardApi } from '../services/dashboard.api';
import { DashboardWidgetCard } from '../dashboard-widget-card/component';
import { DashboardIdea } from './dashboard-idea/component';

interface DashboardIdeasWidgetProps {
  workspaceId: number;
}

export const DashboardIdeasWidget: React.FC<DashboardIdeasWidgetProps> = ({ workspaceId }) => {
  const [page, setPage] = useState(0);

  const {
    data = { items: [], totalCount: 0 },
    isLoading,
    isFetching,
  } = useQuery(['dashboard-ideas', workspaceId, page], () => dashboardApi.getIdeas(page), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  return (
    <DashboardWidgetCard
      title={'Ideas'}
      placeholder={'Ideas list is empty'}
      currentPage={page}
      itemsCountPerPage={10}
      totalItemsCount={data.totalCount}
      isLoading={isLoading}
      isFetching={isFetching}
      onChangePage={newPage => {
        setPage(newPage);
      }}
    >
      {data.items.map(item => (
        <DashboardIdea key={item.id} idea={item} />
      ))}
    </DashboardWidgetCard>
  );
};
