import React from 'react';
import { UserService } from '../../../security/services/user.service';
import { Alert, Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { UserContextSelectors } from '../../../security/store/selectors';
import { Trans } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { LoadingButtonProps } from '@mui/lab';
import { useStyles } from './styles';
import { resolveNewsletterSubscriptionProps } from './data';

export const NewsletterSubscription: React.FC = () => {
  const styles = useStyles();
  const queryClient = useQueryClient();

  const currentUser = useSelector(UserContextSelectors.getCurrentUser);

  const { data, isLoading, isFetching, isError } = useQuery(
    ['newsletter-subscription', currentUser?.id],
    () => UserService.getNewsletterSubscriptionInfo(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!currentUser,
    },
  );

  const mutationCallbacks = {
    onSuccess: () => {
      void queryClient.invalidateQueries(['newsletter-subscription']);
    },
  };

  const subscribeMutation = useMutation(() => UserService.subscribeToNewsletter(), mutationCallbacks);
  const unsubscribeMutation = useMutation(() => UserService.unsubscribeOfNewsletter(), mutationCallbacks);

  const isAnyLoading = isFetching || subscribeMutation.isLoading || unsubscribeMutation.isLoading;
  const isAnyError = isError || subscribeMutation.isError || unsubscribeMutation.isError;

  const { isSubscribed, isUnsubscribed, isNotExist, alertColor, message } = resolveNewsletterSubscriptionProps(
    data,
    isAnyError,
  );

  const commonButtonProps: Partial<LoadingButtonProps> = {
    variant: 'text',
    size: 'small',
    loading: isAnyLoading,
    disabled: isAnyLoading,
  };

  const subscribe = () => {
    subscribeMutation.mutate();
  };

  const unsubscribe = () => {
    unsubscribeMutation.mutate();
  };

  const reload = () => {
    subscribeMutation.reset();
    unsubscribeMutation.reset();
    void queryClient.resetQueries(['newsletter-subscription']);
    void queryClient.invalidateQueries(['newsletter-subscription']);
  };

  return (
    <Alert
      variant={'outlined'}
      severity={alertColor}
      icon={isLoading ? false : undefined}
      className={isLoading ? styles.alertLoading : undefined}
      classes={{ message: styles.alertMessage }}
    >
      {isLoading && (
        <Box overflow={'hidden'}>
          <Grid container alignItems={'center'} spacing={1}>
            <Grid item xs={true}>
              <Typography variant={'body2'} color={'text_color.light'} component={Box} py={1}>
                <Trans>Loading subscription status...</Trans>
              </Typography>
            </Grid>

            <Grid item xs={'auto'}>
              <Box overflow={'hidden'}>
                <CircularProgress style={{ display: 'block' }} size={18} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      {!isLoading && (
        <Grid container>
          <Grid item xs={true}>
            <Box py={1}>
              <Trans>{message}</Trans>
            </Box>
          </Grid>

          <Grid item xs={'auto'}>
            <Box py={0.5}>
              {!isAnyError && (isNotExist || isUnsubscribed) && (
                <LoadingButton onClick={subscribe} {...commonButtonProps}>
                  <Trans>subscribe</Trans>
                </LoadingButton>
              )}

              {!isAnyError && isSubscribed && (
                <LoadingButton color={'error'} onClick={unsubscribe} {...commonButtonProps}>
                  <Trans>Unsubscribe</Trans>
                </LoadingButton>
              )}

              {isAnyError && (
                <LoadingButton onClick={reload} {...commonButtonProps}>
                  <Trans>Reload</Trans>
                </LoadingButton>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </Alert>
  );
};
