import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  backdropContent: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',

    ['& #pdf-download']: {
      display: 'none',
    },

    ['& #loading-renderer']: {
      height: '100%',
    },

    ['& #txt-renderer']: {
      whiteSpace: 'pre-line',
    },
  },
  fileViewerContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: 4,
    overflow: 'hidden',
    zIndex: 2,
  },
  fileViewer: {
    height: '100%',
  },
  closeButtonContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 'auto',
    bottom: 'auto',
    zIndex: 3,
  },
  closeButton: {
    background: pgColorScheme.white,
    color: pgColorScheme.blue,

    ['&:hover']: {
      background: pgColorScheme.white,
      color: pgColorScheme.orange,
    },
  },
  closeArea: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1,
  },
});
