import { createSelector } from 'reselect';
import { AppState } from '../../../store/app.state';
import { FiveWhysCause } from '@priz/shared/src/models/tools/five-whys';
import { sortByChain } from '@priz/shared/src/utils/five-whys';

const fiveWhysCausesCollectionSelector = (state: AppState) => state.fiveWhysCauses;

const fiveWhysCausesStatusesSelector = createSelector(
  fiveWhysCausesCollectionSelector,
  collection => collection.statuses || {},
);

const fiveWhysCauseEntitiesSelector = createSelector(
  fiveWhysCausesCollectionSelector,
  collection => collection.entities,
);

const fiveWhysCauseLookupsSelector = createSelector(fiveWhysCausesCollectionSelector, collection => collection.lookups);

const fiveWhysCauseIdsByUtilizationId = (utilizationId: number) =>
  createSelector(fiveWhysCauseLookupsSelector, lookups => lookups.byUtilizationId[utilizationId] || []);

//////////////////////////////////////////////////////////////////

const getAllByUtilizationId = (utilizationId: number) =>
  createSelector(
    [fiveWhysCauseIdsByUtilizationId(utilizationId), fiveWhysCauseEntitiesSelector],
    (ids: number[], entityMap: { [id: number]: FiveWhysCause }) => {
      const causes = ids.map(id => entityMap[id]);

      return sortByChain(causes);
    },
  );

const getCauseById = (causeId: number) =>
  createSelector(fiveWhysCauseEntitiesSelector, entityMap => entityMap[causeId]);

const isLoadingByUtilizationId = (utilizationId: number) =>
  createSelector(
    fiveWhysCausesStatusesSelector,
    statuses => statuses.byUtilizationId[utilizationId] && statuses.byUtilizationId[utilizationId].loading,
  );

const isLoadedByUtilizationId = (utilizationId: number) =>
  createSelector(
    fiveWhysCausesStatusesSelector,
    statuses => statuses.byUtilizationId[utilizationId] && statuses.byUtilizationId[utilizationId].loaded,
  );

export const FiveWhysCausesSelector = {
  getAllByUtilizationId,
  getCauseById,
  isLoadingByUtilizationId,
  isLoadedByUtilizationId,
};
