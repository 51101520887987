import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectAssigneeSelectors } from '../store/selectors/project-assignee.selectors';
import { Box, Tooltip, Typography } from '@mui/material';
import { ProjectAssigneeRole, ProjectAssigneeWithMember } from '@priz/shared/src/models/assignment';
import { useStyles } from './styles';
import { ProjectAssigneeActions } from '../store/actions';
import { Trans } from 'react-i18next';
import { WorkspaceMembersActions } from '../../workspace/store/actions';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { Workspace, WorkspaceMember } from '@priz/shared/src/models/workspace';
import { AddAssigneeButton } from '../add-assignee-button/component';
import { Assignee } from '../assignee/component';
import { AssigneeSelectorDialog } from '../assignee-selector-dialog/component';
import { WorkspaceMembersSelectors } from '../../workspace/store/selectors/workspace-members.selectors';
// import {CustomBlocker, PaywallActions} from '../../react/modules/paywall/store';

interface ProjectTeamProps {
  projectId: number;
  disabled?: boolean;
}

export const ProjectTeam: React.FC<ProjectTeamProps> = ({ projectId, disabled }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [assignmentDialogOpen, setAssignmentDialogOpen] = useState(false);
  const [closeAssignmentDialogOnInvite, setCloseAssignmentDialogOnInvite] = useState(false);

  const workspace: Workspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const assignees: ProjectAssigneeWithMember[] = useSelector(ProjectAssigneeSelectors.getProjectAssignees(projectId));

  const isLoaded = useSelector(ProjectAssigneeSelectors.isLoaded);
  const isLoading = useSelector(ProjectAssigneeSelectors.isLoading);
  const isCreating = useSelector(WorkspaceMembersSelectors.isCreating);
  const isCreated = useSelector(WorkspaceMembersSelectors.isCreated);
  const errorCode = useSelector(WorkspaceMembersSelectors.errorCode);

  // const isSeatsLimitHit = useSelector(WorkspaceSelectors.isSeatsLimitHit());

  const lead = assignees.find(a => a.assigneeRole === ProjectAssigneeRole.Lead);
  const contributors = assignees.filter(a => a.assigneeRole === ProjectAssigneeRole.Contributor);

  const existingAssignments: ProjectAssigneeWithMember[] = useSelector(
    ProjectAssigneeSelectors.getProjectAssignees(projectId),
  );
  const assignedMemberIds = existingAssignments.map(m => m.user.id);

  const handleAssignmentDialogOpen = () => {
    // if (isSeatsLimitHit) {
    //   dispatch(PaywallActions.show(CustomBlocker.Seats));
    // } else {
    //   setAssignmentDialogOpen(true);
    // }
    dispatch(WorkspaceMembersActions.clearErrors());
    setAssignmentDialogOpen(true);
  };

  const handleAssignmentDialogClose = () => {
    setAssignmentDialogOpen(false);
  };

  useEffect(() => {
    dispatch(ProjectAssigneeActions.fetchAll());
    dispatch(WorkspaceMembersActions.loadAll());
  }, []);

  useEffect(() => {
    if (isCreated && closeAssignmentDialogOnInvite) {
      setCloseAssignmentDialogOnInvite(false);
      handleAssignmentDialogClose();
    }
  }, [isCreated, closeAssignmentDialogOnInvite]);

  const handleDelete = (assignee: ProjectAssigneeWithMember) => {
    dispatch(ProjectAssigneeActions.delete(projectId, assignee.user.email, assignee.id));
  };

  const handleMemberAssign = (member: WorkspaceMember) => {
    dispatch(ProjectAssigneeActions.create(projectId, member.email, ProjectAssigneeRole.Contributor));
    handleAssignmentDialogClose();
  };

  const handleInvite = (email: string) => {
    dispatch(ProjectAssigneeActions.create(projectId, email, ProjectAssigneeRole.Contributor));
    setCloseAssignmentDialogOnInvite(true);
  };

  if (!lead) {
    return null;
  }

  const renderAddButton = (btnText: string) => {
    return (
      <Box mt={1}>
        <AddAssigneeButton
          text={btnText}
          onClick={handleAssignmentDialogOpen}
          disabled={!workspace || disabled || isLoading}
          loading={isLoaded && isLoading}
        />
      </Box>
    );
  };

  return (
    <>
      <Box className={styles.root} pb={4}>
        <Box mb={1}>
          <Typography variant="h6">
            <Trans>Project Team</Trans>
          </Typography>
        </Box>

        <Box className={styles.flexRow}>
          <Tooltip title={contributors.length ? <Trans>Lead</Trans> : ''} placement="right" arrow={true}>
            <Box mr={1} mt={1}>
              <Assignee assignee={lead.user} variant="filled" />
            </Box>
          </Tooltip>

          {!contributors.length && renderAddButton('Add contributors')}
        </Box>

        {!!contributors.length && (
          <Box mt={2}>
            <Box mb={0.5}>
              <Typography variant="body2" component={'span'} className={styles.label}>
                <Trans>Contributors</Trans>:
              </Typography>
            </Box>

            <Box className={styles.flexRow} mt={-1}>
              {contributors.map(a => (
                <Box key={a.id} mt={1} mr={1}>
                  <Assignee
                    assignee={a.user}
                    onDelete={() => {
                      handleDelete(a);
                    }}
                    disabled={disabled}
                  />
                </Box>
              ))}

              {renderAddButton('Add')}
            </Box>
          </Box>
        )}
      </Box>

      <AssigneeSelectorDialog
        isOpen={assignmentDialogOpen}
        title={'Add a contributor'}
        onMemberAssign={handleMemberAssign}
        onInvite={handleInvite}
        onClose={handleAssignmentDialogClose}
        excludeIds={assignedMemberIds}
        showSeatsLimitMessage={true}
        loading={isCreating}
        errorCode={errorCode}
      />
    </>
  );
};
