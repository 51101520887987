import { CertificateAction, CertificateActionType } from '../actions/certificate.actions';
import { Certificate } from '../model/certificate';
import { CertificateCollection, DefaultCertificateCollection } from '../model/certificate-collection';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { handleActions } from 'redux-actions';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';

const setLoading = (state: CertificateCollection, projectId: number, statuses: Partial<EntityCollectionStatus>) => {
  const loadingIdsState = {
    ...state.loadingIds,
    [projectId]: !!statuses?.loading,
  };

  return {
    ...state,
    loadingIds: loadingIdsState,
    statuses: {
      ...state.statuses,
      ...statuses,
    },
  };
};

const setCertificate = (
  state: CertificateCollection,
  projectId: number,
  certificate: Certificate,
  statuses: Partial<EntityCollectionStatus>,
) => {
  const certificatesState = {
    ...state.certificates,
    [projectId]: certificate,
  };

  return {
    ...state,
    certificates: certificatesState,
    statuses: {
      ...state.statuses,
      ...statuses,
    },
  };
};

const setStatuses = (
  state: CertificateCollection,
  statuses: Partial<EntityCollectionStatus>,
): CertificateCollection => ({
  ...state,
  statuses: {
    ...state.statuses,
    ...statuses,
  },
});

export const certificateReducers = handleActions(
  {
    // CertificateActionType.Set
    [toStarted(CertificateActionType.Set)]: (state: CertificateCollection) => setStatuses(state, { creating: true }),

    [toSuccess(CertificateActionType.Set)]: (state: CertificateCollection, action: CertificateAction) =>
      setCertificate(state, action.meta.projectId, new Certificate(action.payload), { creating: false, error: false }),

    [toFailed(CertificateActionType.Set)]: (state: CertificateCollection) =>
      setStatuses(state, { creating: false, error: true }),

    // CertificateActionType.Load
    [toStarted(CertificateActionType.Load)]: (state: CertificateCollection, action: CertificateAction) =>
      setLoading(state, action.meta.projectId, { loading: true }),

    [toSuccess(CertificateActionType.Load)]: (state: CertificateCollection, action: CertificateAction) => {
      const statuses = { loading: false, loaded: true, error: false };
      const newState = setCertificate(state, action.meta.projectId, new Certificate(action.payload), statuses);
      return setLoading(newState, action.meta.projectId, statuses);
    },

    [toFailed(CertificateActionType.Load)]: (state: CertificateCollection, action: CertificateAction) =>
      setLoading(state, action.meta.projectId, { loading: false, loaded: false, error: true }),

    // CertificateActionType.Update
    [toStarted(CertificateActionType.Update)]: (state: CertificateCollection) => setStatuses(state, { saving: true }),

    [toSuccess(CertificateActionType.Update)]: (state: CertificateCollection, action: CertificateAction) =>
      setCertificate(state, action.meta.projectId, new Certificate(action.payload), { saving: false, error: false }),

    [toFailed(CertificateActionType.Update)]: (state: CertificateCollection) =>
      setStatuses(state, { saving: false, error: true }),
  },
  DefaultCertificateCollection,
);
