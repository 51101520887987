import React, { useRef } from 'react';
import { DragAndZoomContainerRef } from '../../drag-and-zoom-container/component';
import { CftArrowsRef } from '../cft-arrows/component';
import { CftBranchSpacerRef } from '../cft-level-space-resolver/component';

interface CftContextProps {
  fitContent: () => void;
  zoomToElement: (elementId: string, animationTimeMs?: number) => void;
  refreshArrows: (delay?: boolean) => void;
  refreshSpacers: (delay?: boolean) => void;
  setCanvasRef: (ref: DragAndZoomContainerRef) => void;
  setArrowsRef: (ref: CftArrowsRef) => void;
  setSpacersRef: (ref: CftBranchSpacerRef) => void;
}

export const CftContext = React.createContext<CftContextProps>({
  fitContent: () => {
    return;
  },
  zoomToElement: () => {
    return;
  },
  refreshArrows: () => {
    return;
  },
  refreshSpacers: () => {
    return;
  },
  setCanvasRef: () => {
    return;
  },
  setArrowsRef: () => {
    return;
  },
  setSpacersRef: () => {
    return;
  },
});

export const CftContextProvider: React.FC = ({ children }) => {
  const canvasRef = useRef<DragAndZoomContainerRef>();
  const arrowsRef = useRef<CftArrowsRef>();
  const spacersRef = useRef<{ [id: string]: CftBranchSpacerRef }>({});

  const fitContent = () => {
    canvasRef?.current?.fit();
  };

  const zoomToElement = (id: string, animationTimeMs = 0) => {
    canvasRef?.current?.contextRef?.current?.zoomToElement(id, undefined, animationTimeMs);
  };

  const refreshArrows = (delay?: boolean) => {
    arrowsRef?.current?.refresh();

    setTimeout(() => {
      if (delay) refreshArrows();
    }, 0);
  };

  const refreshSpacers = (delay?: boolean) => {
    Object.values(spacersRef.current).forEach(ref => ref.refresh());

    setTimeout(() => {
      if (delay) refreshSpacers();
    }, 0);
  };

  const setCanvasRef = (ref: DragAndZoomContainerRef) => {
    canvasRef.current = ref;
  };

  const setArrowsRef = (ref: CftArrowsRef) => {
    arrowsRef.current = ref;
  };

  const setSpacersRef = (ref: CftBranchSpacerRef) => {
    spacersRef.current = {
      ...spacersRef.current,
      [ref.id]: ref,
    };
  };

  return (
    <CftContext.Provider
      value={{
        fitContent,
        zoomToElement,
        refreshArrows,
        refreshSpacers,
        setCanvasRef,
        setArrowsRef,
        setSpacersRef,
      }}
    >
      {children}
    </CftContext.Provider>
  );
};
