import axios, { AxiosResponse } from 'axios';
import { ICftUtilization } from '@priz/shared/src/models/tools/cft';
import {
  CftCreateVersionCommand,
  CftRemoveVersionCommand,
  CftSwitchVersionCommand,
  CftUpdateUtilizationCommand,
  CftUpdateVersionDataCommand,
  CftUpdateVersionTitleCommand,
} from '../model';

const updateUtilization = (utilizationId: number, command: CftUpdateUtilizationCommand): Promise<ICftUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/cft/${utilizationId}`, command)
    .then((response: AxiosResponse<ICftUtilization>) => response.data);

const updateVersionData = (utilizationId: number, command: CftUpdateVersionDataCommand): Promise<ICftUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/cft/${utilizationId}/version-data`, command)
    .then((response: AxiosResponse<ICftUtilization>) => response.data);

const createNewVersion = (utilizationId: number, command: CftCreateVersionCommand): Promise<ICftUtilization> => {
  return axios
    .put(`/v1/api/<ws-id>/team/<t-id>/cft/${utilizationId}/new-version`, command)
    .then((response: AxiosResponse<ICftUtilization>) => response.data);
};

const updateVersionTitle = (utilizationId: number, command: CftUpdateVersionTitleCommand): Promise<ICftUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/cft/${utilizationId}/version-title`, command)
    .then((response: AxiosResponse<ICftUtilization>) => response.data);

const switchVersion = (utilizationId: number, command: CftSwitchVersionCommand): Promise<ICftUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/cft/${utilizationId}/switch-version`, command)
    .then((response: AxiosResponse<ICftUtilization>) => response.data);

const removeVersion = (utilizationId: number, command: CftRemoveVersionCommand): Promise<ICftUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/cft/${utilizationId}/remove-version`, command)
    .then((response: AxiosResponse<ICftUtilization>) => response.data);

const deleteUtilization = (utilizationId: number): Promise<any> =>
  axios.delete(`/v1/api/<ws-id>/team/<t-id>/cft/${utilizationId}`);

export const CftUtilizationApi = {
  updateUtilization,
  deleteUtilization,
  createNewVersion,
  updateVersionData,
  updateVersionTitle,
  switchVersion,
  removeVersion,
};
