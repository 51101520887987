import { IdRef } from '@priz/shared/src/models/common/id-ref';
import { AuditableEntity, IAuditableEntity } from '@priz/shared/src/models/common/auditable-entity';
import { Identifiable } from '@priz/shared/src/models/common/entity-collection-state';

export enum AttendeeStatus {
  Contributor = 'Contributor',
  Observer = 'Observer',
  Decline = 'Decline',
}

export interface IAttendee extends IAuditableEntity, Identifiable {
  email: string;
  attended: boolean;
  meeting: IdRef;
  project: IdRef;
  workspace: IdRef;
  status?: AttendeeStatus;
  user?: IdRef;
  explanation?: string;
}

export class Attendee extends AuditableEntity implements IAttendee {
  public id: number;
  public email: string;
  public attended: boolean;
  public status?: AttendeeStatus;
  public user?: IdRef;
  public explanation?: string;
  public meeting: IdRef;
  public project: IdRef;
  public workspace: IdRef;

  constructor(json: IAttendee) {
    super(json);

    this.id = json.id;
    this.email = json.email;
    this.status = json.status;
    this.attended = json.attended;
    this.user = json.user;
    this.explanation = json.explanation;
    this.meeting = json.meeting;
    this.project = json.project;
    this.workspace = json.workspace;
  }
}

export interface AttendeeCreateCommand {
  email: string;
  meetingId: number;
}

export interface AttendeeUpdateCommand {
  explanation?: string;
  attended?: boolean;
  status?: AttendeeStatus;
}
