import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from '@mui/material';
import { Trans } from 'react-i18next';
import { pgColorScheme } from '@priz/shared/src/theme';
import { PartnerCommission } from '@priz/shared/src/models/security/partner-commission';
import { PartnerCommissionStatus } from '@priz/shared/src/models/security/partner-commission-status';
import CurrencyFormat from 'react-currency-format';
import { format } from 'date-fns';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';

interface AdminCommissionsTableProps {
  commissions: PartnerCommission[];
  onUpdate?: (ids: number[], status: PartnerCommissionStatus) => void;
  loading?: boolean;
}

export const AdminCommissionsTable: React.FC<AdminCommissionsTableProps> = ({ commissions, onUpdate, loading }) => {
  const [activeCommissionsTabType, setActiveCommissionsTabType] = useState<PartnerCommissionStatus>(
    PartnerCommissionStatus.Pending,
  );
  const [selectedCommissionIds, setSelectedCommissionIds] = useState<number[]>([]);

  const commissionsByStatus = commissions
    .filter(i => i.status === activeCommissionsTabType)
    .sort((l, r) => (l.date > r.date ? -1 : 1));

  const rowsCount = commissionsByStatus.length;
  const selectedCount = selectedCommissionIds.length;

  const selectAll = () => {
    setSelectedCommissionIds(commissionsByStatus.map(i => i.id));
  };

  const deselectAll = () => {
    setSelectedCommissionIds([]);
  };

  const singleChangeHandler = (commissionId: number, checked: boolean) => {
    if (checked && !selectedCommissionIds.includes(commissionId)) {
      setSelectedCommissionIds(currentState => [...currentState, commissionId]);
    }

    if (!checked) {
      setSelectedCommissionIds(currentState => [...currentState].filter(id => id !== commissionId));
    }
  };

  const updateHandler = (status: PartnerCommissionStatus) => {
    if (onUpdate) {
      onUpdate(selectedCommissionIds, status);
      deselectAll();
    }
  };

  return (
    <Box position={'relative'} overflow={'hidden'}>
      <LoadingOverlay loading={loading} backdropStyles={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }} />

      <Box mb={1} position={'relative'}>
        <Grid container spacing={2} alignItems={'flex-end'} justifyContent={'space-between'}>
          <Grid item>
            <Tabs
              value={activeCommissionsTabType}
              onChange={(_e, v) => {
                deselectAll();
                setActiveCommissionsTabType(v);
              }}
            >
              <Tab label={'Pending'} value={PartnerCommissionStatus.Pending} />
              <Tab label={'Paid'} value={PartnerCommissionStatus.Paid} />
            </Tabs>
          </Grid>

          <Grid item>
            <Grid container spacing={1}>
              {activeCommissionsTabType === PartnerCommissionStatus.Pending && (
                <Grid item>
                  <Button
                    variant={'outlined'}
                    color={'primary'}
                    onClick={() => updateHandler(PartnerCommissionStatus.Paid)}
                    disabled={!selectedCount || loading}
                  >
                    <Trans>Mark selected as Paid</Trans>
                  </Button>
                </Grid>
              )}

              {activeCommissionsTabType === PartnerCommissionStatus.Paid && (
                <Grid item>
                  <Button
                    variant={'outlined'}
                    color={'error'}
                    onClick={() => updateHandler(PartnerCommissionStatus.Pending)}
                    disabled={!selectedCount || loading}
                  >
                    <Trans>Mark selected as Pending</Trans>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {!commissionsByStatus.length && (
        <Alert severity={'info'}>
          {activeCommissionsTabType === PartnerCommissionStatus.Pending && (
            <Trans>There are not pending commissions</Trans>
          )}
          {activeCommissionsTabType === PartnerCommissionStatus.Paid && <Trans>There are not paid commissions</Trans>}
        </Alert>
      )}

      {!!commissionsByStatus.length && (
        <TableContainer component={Paper} variant={'outlined'} sx={{ maxHeight: 400 }}>
          <Table stickyHeader size={'small'}>
            <TableHead sx={{ backgroundColor: pgColorScheme.background }}>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    indeterminate={selectedCount > 0 && selectedCount < rowsCount}
                    checked={selectedCount > 0 && selectedCount === rowsCount}
                    onChange={selectedCount === rowsCount ? deselectAll : selectAll}
                    disabled={loading}
                  />
                </TableCell>

                {['Id', 'Date', 'Referral id', 'Referrer id', 'Rate', 'Value'].map((item, key) => (
                  <TableCell key={key}>
                    <Trans>{item}</Trans>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {commissionsByStatus.map(commission => (
                <TableRow key={commission.id}>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={selectedCommissionIds.includes(commission.id)}
                      onChange={e => singleChangeHandler(commission.id, e.target.checked)}
                      disabled={loading}
                    />
                  </TableCell>

                  <TableCell>{commission.id}</TableCell>

                  <TableCell>{format(commission.date, 'MMM d yyyy HH:mm')}</TableCell>

                  <TableCell>{commission.referral.id}</TableCell>

                  <TableCell>{commission.referrer.id}</TableCell>

                  <TableCell>{commission.rate}%</TableCell>

                  <TableCell>
                    <CurrencyFormat
                      value={commission.value}
                      displayType={'text'}
                      prefix={'$'}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};
