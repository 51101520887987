import { createSelector, Selector } from 'reselect';
import { ApaActionSelectors } from '../../../apa/store/selectors/apa-action.selectors';
import { AppState } from '../../../store/app.state';
import { ProjectSelector } from './project.selectors';
import { ToolUtilizationSelector } from './tool-utilizations.selectors';
import { AgendaSelectors, AttendeeSelectors, MeetingSelectors } from '../../../meetings/store/selectors';
import { UimTaskSelector } from '../../../uim/store/selectors/uim-task.selectors';

const isAnythingSaving = (projectId: number): Selector<AppState, boolean> =>
  createSelector(
    [
      ProjectSelector.isAnyProjectSaving,
      ToolUtilizationSelector.isAnyCreating(projectId),
      ToolUtilizationSelector.isAnySaving(projectId),
      ToolUtilizationSelector.isAnyRemoving(projectId),
      ApaActionSelectors.isSavingByProjectId(projectId),

      MeetingSelectors.isCreating(projectId),
      MeetingSelectors.isUpdating(projectId),

      AttendeeSelectors.isCreating(projectId),
      AttendeeSelectors.isUpdating(projectId),
      AttendeeSelectors.isRemoving(projectId),

      AgendaSelectors.isCreating(projectId),
      AgendaSelectors.isUpdating(projectId),
      AgendaSelectors.isRemoving(projectId),

      UimTaskSelector.isAnyTaskCreating(),
      UimTaskSelector.isAnyTaskUpdating(),
      UimTaskSelector.isAnyTaskRemoving(),
    ],
    (
      projectDataSaving,
      toolDataSaving,
      toolDataUpdating,
      apaActionDataSaving,

      meetingCreating,
      meetingUpdating,

      attendeeCreating,
      attendeeUpdating,
      attendeeRemoving,

      agendaCreating,
      agendaUpdating,
      agendaRemoving,

      uimTaskCreating,
      uimTaskUpdating,
      uimTaskRemoving,
    ) =>
      projectDataSaving ||
      toolDataSaving ||
      toolDataUpdating ||
      apaActionDataSaving ||
      meetingCreating ||
      meetingUpdating ||
      attendeeCreating ||
      attendeeUpdating ||
      attendeeRemoving ||
      agendaCreating ||
      agendaUpdating ||
      agendaRemoving ||
      uimTaskCreating ||
      uimTaskUpdating ||
      uimTaskRemoving,
  );

export const ProjectSaveStatusSelectors = {
  isAnythingSaving,
};
