import { AppState } from '../../../store/app.state';
import { Team, TeamCollection } from '@priz/shared/src/models/team';
import { createSelector, Selector } from 'reselect';
import { EntityMap } from '@priz/shared/src/models/common/entity-collection-state';

const teamsCollectionSelector = (state: AppState): TeamCollection => state.teams;

const getTeamEntityMap: Selector<AppState, EntityMap<Team>> = createSelector(
  teamsCollectionSelector,
  collection => collection.entities,
);

const getTeamLookupByWorkspaceId = (workspaceId: number): Selector<AppState, number[]> =>
  createSelector(teamsCollectionSelector, collection => collection?.lookups?.byWorkspaceId[workspaceId]);

const getById = (id: number): Selector<AppState, Team> => createSelector(getTeamEntityMap, entityMap => entityMap[id]);

const getDefault = (workspaceId: number): Selector<AppState, Team> =>
  createSelector(
    [getTeamEntityMap, getTeamLookupByWorkspaceId(workspaceId)],
    (entityMap, ids) => ids && ids[0] && entityMap[ids[0]],
  );

export const TeamSelectors = {
  getById,
  getDefault,
};
