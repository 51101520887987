import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';

export const useStyles = makeStyles({
  root: {
    lineHeight: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'center',
  },
  short: {
    maxWidth: 200,
  },
  component: {
    border: `1px solid ${pgColorScheme.blue}`,
    background: pgColorScheme.white,
    padding: '4px 8px',
    borderRadius: 8,
  },
  superSystem: {
    background: pgColorScheme.blueLight2,
    padding: '4px 8px',
    borderRadius: 4,
  },
  product: {
    background: pgColorScheme.orange,
    padding: '6px 12px',
    borderRadius: '50%',
  },
  empty: {
    ['&$component']: {
      color: pgColorScheme.gray,
    },
    ['&$superSystem']: {
      color: pgColorScheme.white,
    },
    ['&$product']: {
      color: pgColorScheme.white,
    },
  },
});
