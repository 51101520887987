import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { PgConfirmationDialog } from '../../../react/elements/PgConfirmationDialog';
import { useDispatch, useSelector } from 'react-redux';
import { ApiKeysSelectors } from '../store/selectors';
import { ApiKeyTitleEditorDialog } from '../api-key-title-editor-dialog/component';
import { ApiKeysActions } from '../store/actions';
import { ApiKeyCard, SharedApiKeyCardProps } from '../api-key-card/component';

export const EditableApiKeyCard: React.FC<SharedApiKeyCardProps> = ({ apiKey, ...rest }) => {
  const dispatch = useDispatch();

  const [isInvalidateDialogOpen, setIsInvalidateDialogOpen] = useState(false);
  const [isTitleEditorDialogOpen, setIsTitleEditorDialogOpen] = useState(false);

  const isUpdating = useSelector(ApiKeysSelectors.isUpdatingByUserId(apiKey.user.id));
  const isUpdated = useSelector(ApiKeysSelectors.isUpdatedByUserId(apiKey.user.id));

  useEffect(() => {
    if (isUpdated) {
      closeInvalidateConfirmation();
      closeTitleEditorConfirmation();
    }
  }, [isUpdated]);

  const invalidate = () => {
    dispatch(ApiKeysActions.invalidate(apiKey.id, apiKey.user.id));
  };

  const updateTitle = (title: string) => {
    dispatch(ApiKeysActions.updateTitle(apiKey.id, title, apiKey.user.id));
  };

  const openInvalidateConfirmation = () => {
    setIsInvalidateDialogOpen(true);
  };

  const closeInvalidateConfirmation = () => {
    setIsInvalidateDialogOpen(false);
  };

  const openTitleEditorConfirmation = () => {
    setIsTitleEditorDialogOpen(true);
  };

  const closeTitleEditorConfirmation = () => {
    setIsTitleEditorDialogOpen(false);
  };

  return (
    <>
      <ApiKeyCard
        apiKey={apiKey}
        onEditTitle={openTitleEditorConfirmation}
        onInvalidate={openInvalidateConfirmation}
        closeMenu={isInvalidateDialogOpen}
        copy
        view
        {...rest}
      />

      <ApiKeyTitleEditorDialog
        open={isTitleEditorDialogOpen}
        apiKey={apiKey}
        onSave={updateTitle}
        onCancel={closeTitleEditorConfirmation}
        loading={isUpdating}
      />

      <PgConfirmationDialog
        isOpen={isInvalidateDialogOpen}
        confirmTitle={<Trans>Confirm invalidation</Trans>}
        okButtonText={<Trans>Invalidate</Trans>}
        confirmContent={
          <React.Fragment>
            <Trans>
              Are you sure you want to invalidate this API key? Once marked as invalid, it cannot be undone.
            </Trans>
          </React.Fragment>
        }
        onConfirm={invalidate}
        onClose={closeInvalidateConfirmation}
        loading={isUpdating}
      />
    </>
  );
};
