import React from 'react';
import { useStyles } from './styles';
import { Box, BoxProps, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { SfmEdgeType } from '../../models/tools/sfm';

export interface SfmEdgeLabelProps extends BoxProps {
  text?: string;
  type?: SfmEdgeType;
  short?: boolean;
}

export const SfmEdgeLabel: React.FC<SfmEdgeLabelProps> = ({ text, type, short, ...rest }) => {
  const styles = useStyles();

  const { className, ...restProps } = rest;
  const rootClassNames = [styles.root];
  const textClassNames = [styles.text];

  if (className) rootClassNames.push(className);
  if (type === SfmEdgeType.USEFUL) rootClassNames.push(styles.useful);
  if (type === SfmEdgeType.HARMFUL) rootClassNames.push(styles.harmful);
  if (type === SfmEdgeType.EXCESSIVE) rootClassNames.push(styles.excessive);
  if (type === SfmEdgeType.INSUFFICIENT) rootClassNames.push(styles.insufficient);

  if (short) textClassNames.push(styles.short);
  if (!text?.length) textClassNames.push(styles.empty);

  return (
    <Box
      display={'flex'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      className={rootClassNames.join(' ')}
      px={4}
      {...restProps}
    >
      <Typography variant={'body2'} component={'div'} noWrap className={textClassNames.join(' ')}>
        {text || <Trans>empty</Trans>}
      </Typography>
    </Box>
  );
};
