import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from '@mui/material';
import { Trans } from 'react-i18next';
import { Idea } from '@priz/shared/src/models/idea';
import { GrayLabelContainer } from '../../content-containers/gray-label-container/component';
import { QuillContentViewer } from '@priz/shared/src/components/quill-content-viewer/component';
import { IdeaInfo } from '../idea-info/component';

export interface IdeaViewerProps {
  idea: Idea;
  open: boolean;
  onClose?: () => void;
}

export const IdeaViewer: React.FC<IdeaViewerProps> = ({ idea, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle>
        <Trans>Idea</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={4} flexDirection={{ xs: 'column-reverse', lg: 'row' }}>
          <Grid item xs={12} lg={8}>
            {idea.content && <QuillContentViewer content={idea.content} autoHeight />}

            {idea.details && (
              <GrayLabelContainer label={'Details, value & risks'} flexDirection={'column'} mt={idea.content ? 2 : 0}>
                <QuillContentViewer content={idea.details} autoHeight />
              </GrayLabelContainer>
            )}
          </Grid>

          <Grid item xs={12} lg={4}>
            <Paper variant={'outlined'} component={Box} p={2}>
              <IdeaInfo idea={idea} />
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ textAlign: 'right' }}>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={onClose}>
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
