import { ToolType } from '../tools';
import { PlanLevel } from './plan-level.enum';

interface ToolFeatureSet {
  enabled: boolean;
  maxItems?: number | null;
}

export enum FeatureLimit {
  MaxProjects = 'MaxProjects',
  MinWorkspaceMembers = 'MinWorkspaceMembers',
  MaxWorkspaceMembers = 'MaxWorkspaceMembers',
  MaxGuests = 'MaxGuests',
  MaxIdeas = 'MaxIdeas',
  MaxTasks = 'MaxTasks',
  IdeasCertification = 'IdeasCertification',
  DataCrowdSourcing = 'DataCrowdSourcing',
  Tools = 'Tools',
  Security = 'Security',
  ProjectLevelPermissions = 'ProjectLevelPermissions',
  AuditTrail = 'AuditTrail',
  Encryption = 'Encryption',
  SSO = 'SSO',
  TrainingSupport = 'TrainingSupport',
  EmailChat = 'EmailChat',
  GuidedOnboarding = 'GuidedOnboarding',
  PersonalizedOnboarding = 'PersonalizedOnboarding',
  SuccessManager = 'SuccessManager',
  TrainingSessions = 'TrainingSessions',
  TrainingCertification = 'TrainingCertification',
  Facilitation = 'Facilitation',
}

export type PlanFeatureSet = {
  [FeatureLimit.MaxProjects]?: number | null;
  [FeatureLimit.MinWorkspaceMembers]?: number | null;
  [FeatureLimit.MaxWorkspaceMembers]?: number | null;
  [FeatureLimit.MaxGuests]?: number | null;
  [FeatureLimit.MaxIdeas]?: number | null;
  [FeatureLimit.MaxTasks]?: number | null;
  [FeatureLimit.IdeasCertification]?: boolean;
  [FeatureLimit.DataCrowdSourcing]?: boolean;
  [FeatureLimit.Tools]: {
    [toolType in ToolType]: ToolFeatureSet;
  };
  [FeatureLimit.Security]: {
    [FeatureLimit.ProjectLevelPermissions]: boolean;
    [FeatureLimit.AuditTrail]: boolean;
    [FeatureLimit.Encryption]: boolean;
    [FeatureLimit.SSO]: boolean;
  };
  [FeatureLimit.TrainingSupport]: {
    [FeatureLimit.EmailChat]: boolean;
    [FeatureLimit.GuidedOnboarding]: boolean;
    [FeatureLimit.PersonalizedOnboarding]: boolean;
    [FeatureLimit.SuccessManager]: boolean;
    [FeatureLimit.TrainingSessions]: boolean;
    [FeatureLimit.TrainingCertification]: boolean;
    [FeatureLimit.Facilitation]: boolean;
  };
};

export interface FeatureSetsCollection {
  workspaceFeatureSet: PlanFeatureSet;
  publicProjectFeatureSet: PlanFeatureSet;
}

export type PlanFeaturesMap = Record<PlanLevel, PlanFeatureSet>;

export interface FeatureLimitDescription {
  text: string;
  description?: string;
}

export type FeatureDescriptionMap = Record<string, FeatureLimitDescription>;

export const featureLimitDescriptionMap: FeatureDescriptionMap = {
  [FeatureLimit.MaxProjects]: {
    text: 'Active Projects',
    description: 'Maximum number of active projects.',
  },
  [FeatureLimit.MinWorkspaceMembers]: {
    text: 'Minimum Members',
    description: 'Minimum number of members allowed in the workspace',
  },
  [FeatureLimit.MaxWorkspaceMembers]: {
    text: 'Maximum Members',
    description:
      'Maximum number of members allowed in the workspace. Hard limit for personal workspaces. Soft limit for team workspaces.',
  },
  [FeatureLimit.MaxGuests]: {
    text: 'Guests',
    description: 'Maximum number of guests allowed in the workspace',
  },
  [FeatureLimit.MaxIdeas]: {
    text: 'Project Ideas',
    description: 'Maximum number of ideas in every project',
  },
  [FeatureLimit.MaxTasks]: {
    text: 'Project Tasks',
    description: 'Maximum number of tasks in every project',
  },
  [FeatureLimit.DataCrowdSourcing]: {
    text: 'Data Crowd Sourcing',
    description: '',
  },
  [FeatureLimit.IdeasCertification]: {
    text: 'Ideas Certification',
    description: '',
  },
  [ToolType.CEC]: {
    text: 'Cause & Effect Chain',
    description: 'Maximum number of nodes',
  },
  [ToolType.SFM]: {
    text: 'Functional Modeling',
    description: 'Maximum number of nodes',
  },
  [ToolType.PFM]: {
    text: 'Process Functional Modeling',
    description: 'Maximum number of operations',
  },
  [ToolType.CFT]: {
    text: 'Change Flow Thinking',
    description: 'Maximum number of nodes',
  },
  [ToolType.APA]: {
    text: 'Action Preventing Action',
    description: 'Maximum number of customer actions',
  },
  [ToolType.RRM]: {
    text: 'Round Robin Ranking',
    description: 'Maximum number of perceptions',
  },
  [ToolType.EBS]: {
    text: 'Effective Brainstorming',
    description: 'Maximum number of ideas',
  },
  [ToolType.UIM]: {
    text: 'Urgency - Importance Matrix',
    description: 'Maximum number of tasks',
  },
  [ToolType.FIVE_WHYS]: {
    text: '5+ Whys',
    description: 'Maximum number of nodes',
  },
  [ToolType.NINE_WINDOWS]: {
    text: '9 Windows',
    description: '',
  },
  [ToolType.P_MAP]: {
    text: 'Perception Mapping',
    description: 'Maximum number of perceptions',
  },
  [ToolType.TOOL_40_PRINCIPLES]: {
    text: '40 Inventive Principles',
    description: '',
  },
  [FeatureLimit.ProjectLevelPermissions]: {
    text: 'Project Level Permissions',
    description: '',
  },
  [FeatureLimit.AuditTrail]: {
    text: 'Audit Trail',
    description: '',
  },
  [FeatureLimit.Encryption]: {
    text: 'Data Encryption',
    description: '',
  },
  [FeatureLimit.SSO]: {
    text: 'SSO',
    description: '',
  },
  [FeatureLimit.EmailChat]: {
    text: 'Email & Chat',
    description: '',
  },
  [FeatureLimit.GuidedOnboarding]: {
    text: 'Guided Onboarding',
    description: '',
  },
  [FeatureLimit.PersonalizedOnboarding]: {
    text: 'Personalized Onboarding',
    description: '',
  },
  [FeatureLimit.SuccessManager]: {
    text: 'Success Manager',
    description: '',
  },
  [FeatureLimit.TrainingSessions]: {
    text: 'Training Sessions',
    description: '',
  },
  [FeatureLimit.TrainingCertification]: {
    text: 'Training Certification',
    description: '',
  },
  [FeatureLimit.Facilitation]: {
    text: 'Facilitation',
    description: '',
  },
};

export const DefaultPlanFeatureSet: PlanFeatureSet = {
  [FeatureLimit.MaxProjects]: 1,
  [FeatureLimit.MinWorkspaceMembers]: 1,
  [FeatureLimit.MaxWorkspaceMembers]: 1,
  [FeatureLimit.MaxGuests]: 0,
  [FeatureLimit.MaxIdeas]: 1,
  [FeatureLimit.MaxTasks]: null,
  [FeatureLimit.IdeasCertification]: false,
  [FeatureLimit.DataCrowdSourcing]: false,
  [FeatureLimit.Tools]: {
    [ToolType.CEC]: { enabled: true },
    [ToolType.SFM]: { enabled: true },
    [ToolType.APA]: { enabled: true },
    [ToolType.RRM]: { enabled: true },
    [ToolType.EBS]: { enabled: true },
    [ToolType.UIM]: { enabled: true },
    [ToolType.FIVE_WHYS]: { enabled: true },
    [ToolType.NINE_WINDOWS]: { enabled: true },
    [ToolType.P_MAP]: { enabled: true },
    [ToolType.TOOL_40_PRINCIPLES]: { enabled: true },
    [ToolType.SFM]: { enabled: true },
    [ToolType.PFM]: { enabled: true },
    [ToolType.CFT]: { enabled: true },
  },
  [FeatureLimit.Security]: {
    [FeatureLimit.ProjectLevelPermissions]: false,
    [FeatureLimit.AuditTrail]: false,
    [FeatureLimit.Encryption]: false,
    [FeatureLimit.SSO]: false,
  },
  [FeatureLimit.TrainingSupport]: {
    [FeatureLimit.EmailChat]: false,
    [FeatureLimit.GuidedOnboarding]: false,
    [FeatureLimit.PersonalizedOnboarding]: false,
    [FeatureLimit.SuccessManager]: false,
    [FeatureLimit.TrainingSessions]: false,
    [FeatureLimit.TrainingCertification]: false,
    [FeatureLimit.Facilitation]: false,
  },
};
