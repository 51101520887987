import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm/rrm-goal-type.enum';
import { RrmGoalSelector } from '../store/selectors';
import { Trans } from 'react-i18next';
import { ToolTitle } from '../../tools/tool-title/component';
import { RedirectToCallerButton } from '../../tools/redirect-to-caller-button/component';
import { RrmRankingSummaryTable } from '@priz/shared/src/components/rrm-summary-table/component';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';

export interface RrmRankingSummaryProps {
  utilizationId: number;
}

export const RrmRankingSummary: React.FC<RrmRankingSummaryProps> = ({ utilizationId }) => {
  const [isSelfRanking, setIsSelfRanking] = useState(true);

  const utilization = useSelector(ToolUtilizationSelector.getRrmUtilization(utilizationId));
  const goal = useSelector(RrmGoalSelector.getGoalById(utilization && utilization.goal.id));

  useEffect(() => {
    if (goal) {
      setIsSelfRanking(goal.goalType === RrmGoalType.SELF);
    } else {
      setIsSelfRanking(true);
    }
  }, [goal]);

  if (!utilization || !goal) {
    return null;
  }

  return (
    <>
      <ContentContainer>
        <PageTitleWithDocLink
          titleComponent={
            <ToolTitle initialTitle={'Ranking summary'} tool={utilization} preset={'large'} editable={isSelfRanking} />
          }
          oneLiner={<Trans>The easiest known method to compare more than two items</Trans>}
          docsUrl={'https://www.priz.guru/knowledge-base/round-robin-ranking/'}
        />

        <RrmRankingSummaryTable goal={goal} />
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={utilization?.project?.id}
          utilization={utilization}
          rightColContent={goal?.goalType !== RrmGoalType.SELF && <RedirectToCallerButton goal={goal} />}
        />
      </ContentFooter>
    </>
  );
};
