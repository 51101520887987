import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, MenuItem, MenuList, Paper, PaperProps, Tooltip, Typography } from '@mui/material';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { Attachment, AttachmentPreview } from '../store/model';
import { format } from 'date-fns';
import { useStyles } from './styles';
import { Trans } from 'react-i18next';
import { ReplayRounded, MoreHorizRounded, VisibilityRounded, CloudDownloadRounded } from '@mui/icons-material';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { useDispatch, useSelector } from 'react-redux';
import { AttachmentsActions } from '../store/actions';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { AttachmentRenameDialog } from '../attachment-rename-dialog/component';
import fileDownload from 'js-file-download';
import mime from 'mime';
import { AttachmentViewer } from '../attachment-viewer/component';
import { AttachmentsSelectors } from '../store/selectors';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';

const fileViewerSupportedExtensions = [
  // react-doc-viewer
  'bmp',
  'jpg',
  'jpeg',
  'pdf',
  'png',
  'tiff',
  'txt',

  // custom
  'webp',
];

const microsoftViewerSupportedExtensions = ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'];

export interface AttachmentItemProps extends PaperProps {
  workspaceId: number;
  projectId: number;
  attachment?: Attachment;
  attachmentPreview?: AttachmentPreview;
  onCreate?: (file: File) => void;
  compact?: boolean;
  disabled?: boolean;
  editable?: boolean;
}

const checkIsUpdateNeeded = (expiresAt: Date): boolean => {
  return new Date(expiresAt).getTime() - 60 * 1000 <= new Date().getTime();
};

export const AttachmentItem: React.FC<AttachmentItemProps> = ({
  workspaceId,
  projectId,
  attachment,
  attachmentPreview,
  onCreate,
  compact,
  disabled,
  editable = true,
  ...rest
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [renameDialogIsOpen, setRenameDialogIsOpen] = useState(false);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);
  const [fileViewerIsOpen, setFileViewerIsOpen] = useState(false);

  const [openViewerAfterLoading, setOpenViewerAfterLoading] = useState(false);
  const [saveFileAfterLoading, setSaveFileAfterLoading] = useState(false);

  const isLoading = useSelector(AttachmentsSelectors.isLoading(projectId));
  const useExternalViewer = useSelector(WorkspaceSelectors.isExternalFileViewerEnabled(workspaceId));

  const supportedExtensions = [
    ...fileViewerSupportedExtensions,
    ...(useExternalViewer ? microsoftViewerSupportedExtensions : []),
  ];

  const { id, fileName, signedUrl, urlExpiresAt, contentType, loading, error, dateCreated, lastUpdated, file } = {
    ...attachmentPreview,
    ...attachment,
  };

  const rootClassNames = [styles.root];
  const contentContainerClassNames = [styles.contentContainer];
  const extension = mime.getExtension(contentType);
  const viewIsAvailable = supportedExtensions.includes(extension);
  const showLoading = openViewerAfterLoading || saveFileAfterLoading || loading;

  if (menuIsOpen) rootClassNames.push(styles.menuIsOpen);
  if (isDeleting) rootClassNames.push(styles.faded);
  if (compact) rootClassNames.push(styles.compact);

  if (viewIsAvailable) contentContainerClassNames.push(styles.nameHoverContainer);

  useEffect(() => {
    if (openViewerAfterLoading && !isLoading) {
      setOpenViewerAfterLoading(false);
      openFileViewer();
    }
  }, [openViewerAfterLoading, isLoading]);

  useEffect(() => {
    if (saveFileAfterLoading && !isLoading) {
      setSaveFileAfterLoading(false);
      downloadFile();
    }
  }, [saveFileAfterLoading, isLoading]);

  const updateAttachments = () => {
    dispatch(AttachmentsActions.getAllForProject(projectId));
  };

  const popperToggleHandler = (isOpen: boolean) => {
    setMenuIsOpen(isOpen);
  };

  const reCreateHandler = () => {
    if (onCreate && file) {
      onCreate(file);
    }
  };

  const deleteAttachment = () => {
    setIsDeleting(true);
    dispatch(AttachmentsActions.deleteAttachment(projectId, id));
    closeDeleteConfirmation();
  };

  const deletePreview = () => {
    dispatch(AttachmentsActions.deletePreview(projectId, fileName));
  };

  const deleteHandler = () => {
    if (attachment && id) {
      openDeleteConfirmation();
    }

    if (attachmentPreview && fileName) {
      deletePreview();
    }
  };

  const openDeleteConfirmation = () => {
    setMenuIsOpen(false);
    setDeleteConfirmationIsOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmationIsOpen(false);
  };

  const openRenameDialog = () => {
    setMenuIsOpen(false);
    setRenameDialogIsOpen(true);
  };

  const closeRenameDialog = () => {
    setRenameDialogIsOpen(false);
  };

  const closeFileViewer = () => {
    setFileViewerIsOpen(false);
  };

  const openFileViewer = () => {
    if (!checkIsUpdateNeeded(urlExpiresAt)) {
      setFileViewerIsOpen(true);
    } else {
      updateAttachments();
      setOpenViewerAfterLoading(true);
    }
  };

  const downloadFile = () => {
    if (!checkIsUpdateNeeded(urlExpiresAt)) {
      fetch(signedUrl).then(response => {
        return response.blob().then(b => {
          fileDownload(b, fileName);
        });
      });
    } else {
      updateAttachments();
      setSaveFileAfterLoading(true);
    }
  };

  const contentClickHandler = () => {
    if (viewIsAvailable) openFileViewer();
  };

  return (
    <Paper className={rootClassNames.join(' ')} {...rest}>
      <Box className={styles.controlsContainer} p={compact ? 0 : 1}>
        <Grid container justifyContent={'flex-end'}>
          {!error && attachment && (
            <>
              {viewIsAvailable && (
                <Grid item>
                  <IconButton className={styles.controlButton} color="primary" size={'small'} onClick={openFileViewer}>
                    <VisibilityRounded />
                  </IconButton>
                </Grid>
              )}

              {signedUrl && (
                <Grid item>
                  <IconButton className={styles.controlButton} color="primary" size={'small'} onClick={downloadFile}>
                    <CloudDownloadRounded />
                  </IconButton>
                </Grid>
              )}
            </>
          )}

          {editable && !showLoading && (
            <Grid item>
              <PopperWrap dropdownProps={{ p: 0 }} arrow={false} onOpenToggle={popperToggleHandler} open={menuIsOpen}>
                <IconButton className={styles.controlButton} color="primary" size={'small'}>
                  <MoreHorizRounded />
                </IconButton>

                <MenuList>
                  {!error && (
                    <MenuItem onClick={openRenameDialog} disabled={disabled}>
                      <Typography variant={'body2'}>
                        <Trans>Rename</Trans>
                      </Typography>
                    </MenuItem>
                  )}

                  <MenuItem onClick={deleteHandler} disabled={disabled}>
                    <Typography variant={'body2'} color={'error'}>
                      <Trans>Delete</Trans>
                    </Typography>
                  </MenuItem>
                </MenuList>
              </PopperWrap>
            </Grid>
          )}
        </Grid>
      </Box>

      <Box
        className={[...contentContainerClassNames, styles.previewContainer].join(' ')}
        p={compact ? 0 : 1}
        onClick={contentClickHandler}
      >
        {!error && !showLoading && <FileIcon extension={extension} {...defaultStyles[extension]} />}

        {showLoading && (
          <LoadingOverlay
            loading={true}
            backdropStyles={{ backgroundColor: 'transparent' }}
            size={compact ? 30 : 40}
            disableTooLongLoadingMessages
          />
        )}

        {editable && error && (
          <Box
            position={'relative'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            height={'100%'}
            zIndex={3}
            onClick={reCreateHandler}
          >
            <Tooltip title={<Trans>Try again</Trans>}>
              <IconButton color="primary" size={compact ? 'small' : 'large'}>
                <ReplayRounded fontSize={compact ? 'medium' : 'inherit'} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      <Box
        className={[...contentContainerClassNames, styles.descriptionContainer].join(' ')}
        p={0.75}
        onClick={contentClickHandler}
      >
        <Typography className={`${styles.fileName} ${styles.text}`} variant={'caption'} noWrap>
          {fileName}
        </Typography>

        {(lastUpdated || dateCreated) && (
          <Typography className={`${styles.date} ${styles.text}`} variant={'caption'}>
            {format(lastUpdated || dateCreated, 'MMM d yyyy h:mm aaa')}
          </Typography>
        )}

        {error && (
          <Typography className={`${styles.errorText} ${styles.text}`} variant={'caption'}>
            <Trans>Failed to load</Trans>
          </Typography>
        )}
      </Box>

      {attachment && (
        <PgConfirmationDialog
          isOpen={deleteConfirmationIsOpen}
          onConfirm={deleteAttachment}
          onClose={closeDeleteConfirmation}
          confirmTitle={<span>Confirm file deletion</span>}
          confirmContent={
            <span>
              Are you sure you want to delete this file?
              <br />
              This operation cannot be undone!
            </span>
          }
          okButtonText={<span>Delete</span>}
        />
      )}

      {attachment && (
        <AttachmentRenameDialog
          projectId={projectId}
          attachment={attachment}
          isOpen={renameDialogIsOpen}
          onClose={closeRenameDialog}
        />
      )}

      {viewIsAvailable && (
        <AttachmentViewer
          isOpen={fileViewerIsOpen}
          signedUrl={signedUrl}
          extension={extension}
          onClose={closeFileViewer}
        />
      )}
    </Paper>
  );
};
