import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../store/app.state';
import { CalcResult } from '@priz/shared/src/models/tools/pmap';

const pMapResultSelector = (state: AppState) => state.pmapResult;

const pMapResultStatusesSelector = createSelector(pMapResultSelector, collection => collection.statuses);

const getResultByUtilizationId = (utilizationId: number): Selector<AppState, CalcResult> =>
  createSelector(pMapResultSelector, collection => collection.result.byUtilizationId[utilizationId]);

const isLoadingByUtilizationId = (utilizationId: number): Selector<AppState, boolean> =>
  createSelector(pMapResultStatusesSelector, statuses => !!statuses.byUtilizationId[utilizationId]?.loading);

const isLoadedByUtilizationId = (utilizationId: number): Selector<AppState, boolean> =>
  createSelector(pMapResultStatusesSelector, statuses => !!statuses.byUtilizationId[utilizationId]?.loaded);

const isUpdatingByUtilizationId = (utilizationId: number): Selector<AppState, boolean> =>
  createSelector(pMapResultStatusesSelector, statuses => !!statuses.byUtilizationId[utilizationId]?.updating);

export const PMapResultSelectors = {
  getResultByUtilizationId,
  isLoadingByUtilizationId,
  isLoadedByUtilizationId,
  isUpdatingByUtilizationId,
};
