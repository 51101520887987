import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { alpha } from '@mui/material';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: 160,
    height: 'auto',

    ['&:hover $controlsContainer']: {
      opacity: 1,
    },
  },
  compact: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 'auto',
    maxWidth: 160,
    background: 'transparent',
    boxShadow: 'none',

    ['& $previewContainer']: {
      height: 40,
      borderRadius: 0,
      width: 33,
      background: 'transparent',
      flex: '0 0 auto',
      marginRight: 5,
    },

    ['& $descriptionContainer']: {
      overflow: 'hidden',
      minHeight: 0,
    },
  },
  menuIsOpen: {
    ['& $controlsContainer']: {
      opacity: 1,
    },
  },
  faded: {
    opacity: 0.3,
    pointerEvents: 'none',
  },
  contentContainer: {
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
  },
  previewContainer: {
    height: 90,
    background: pgColorScheme.backgroundMediumGray,
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',

    ['& svg']: {
      maxHeight: '100%',
    },
  },
  descriptionContainer: {
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
    minHeight: 38,
  },
  text: {
    lineHeight: 1.1,
    display: 'block',
  },
  nameHoverContainer: {
    cursor: 'pointer',

    ['&:hover, &:hover ~ &']: {
      ['& $fileName']: {
        color: pgColorScheme.blue,
      },
    },
  },
  fileName: {
    fontWeight: 600,
    marginBottom: 2,
    transition: 'color .15s ease',
  },
  date: {
    color: pgColorScheme.textGray,
  },
  errorText: {
    color: pgColorScheme.coral,
  },
  controlsContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 'auto',
    bottom: 'auto',
    zIndex: 4,
    opacity: 0,
    transition: 'opacity .15s ease',
  },
  controlButton: {
    borderRadius: 2,
    padding: '0 4px',
    marginLeft: 4,
    background: alpha(pgColorScheme.white, 0.8),

    ['&:hover']: {
      color: pgColorScheme.white,
      background: pgColorScheme.blue,
    },
  },
});
