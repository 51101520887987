import { Locales } from '@priz/shared/src/models/locales';

type LocalesVideoUrlMap = { [key in Locales]?: string };

export interface VideoGuide {
  title: string;
  localesVideoUrlMap: LocalesVideoUrlMap;
}

export interface LocalizedVideoGuide {
  title: string;
  url: string;
}

interface ConditionalVideoGuide {
  condition: string[];
  videos: VideoGuide[];
}

export const videoGuides: ConditionalVideoGuide[] = [
  {
    condition: ['projects'],
    videos: [
      {
        title: 'Projects List Tutorial',
        localesVideoUrlMap: {
          [Locales.EN]: 'https://vimeo.com/810435379', // https://youtu.be/U-dHRcIww-k
        },
      },
    ],
  },
  {
    condition: ['new-project'],
    videos: [],
  },
  {
    condition: ['start-project'],
    videos: [],
  },
  {
    condition: ['workspace', 'new'],
    videos: [],
  },
  {
    condition: ['workspace', 'settings'],
    videos: [],
  },
  {
    condition: ['workspace', 'billing'],
    videos: [],
  },
  {
    condition: ['project', 'overview'],
    videos: [],
  },
  {
    condition: ['project', 'tasks'],
    videos: [],
  },
  {
    condition: ['project', 'ideas'],
    videos: [],
  },
  {
    condition: ['project', 'problem-statement'],
    videos: [
      {
        title: 'State the right problem to solve',
        localesVideoUrlMap: {
          [Locales.ZH]:
            'https://public-media-priz.sfo2.cdn.digitaloceanspaces.com/ch-tutorials/PRIZ_04_state%20the%20right%20problem%20to%20solve.mp4',
        },
      },
    ],
  },
  {
    condition: ['project', 'tools', 'list'],
    videos: [],
  },
  {
    condition: ['project', 'tools', '40-principles'],
    videos: [
      {
        title: 'Inventive Principles',
        localesVideoUrlMap: {
          [Locales.ZH]:
            'https://public-media-priz.sfo2.cdn.digitaloceanspaces.com/ch-tutorials/PRIZ_07_Inventive%20Principles.mp4',
        },
      },
    ],
  },
  {
    condition: ['project', 'tools', 'rrm'],
    videos: [
      {
        title: 'Prioritization and decision making',
        localesVideoUrlMap: {
          [Locales.ZH]:
            'https://public-media-priz.sfo2.cdn.digitaloceanspaces.com/ch-tutorials/PRIZ_02_Prioritization%20and%20Decision%20Making.mp4',
        },
      },
    ],
  },
  {
    condition: ['project', 'tools', 'uim'],
    videos: [
      {
        title: 'Priority management',
        localesVideoUrlMap: {
          [Locales.ZH]:
            'https://public-media-priz.sfo2.cdn.digitaloceanspaces.com/ch-tutorials/PRIZ_03_Priority%20Management.mp4',
        },
      },
    ],
  },
  {
    condition: ['project', 'tools', '5-whys'],
    videos: [
      {
        title: 'Root cause analysis',
        localesVideoUrlMap: {
          [Locales.ZH]:
            'https://public-media-priz.sfo2.cdn.digitaloceanspaces.com/ch-tutorials/PRIZ_06_Root%20cause%20analysis.mp4',
        },
      },
    ],
  },
  {
    condition: ['project', 'tools', 'apa'],
    videos: [
      {
        title: 'Define customer needs',
        localesVideoUrlMap: {
          [Locales.ZH]:
            'https://public-media-priz.sfo2.cdn.digitaloceanspaces.com/ch-tutorials/PRIZ_12_Define%20a%20customer%20needs.mp4',
        },
      },
    ],
  },
  {
    condition: ['project', 'tools', 'ebs'],
    videos: [
      {
        title: 'Brainstorming concept',
        localesVideoUrlMap: {
          [Locales.ZH]:
            'https://public-media-priz.sfo2.cdn.digitaloceanspaces.com/ch-tutorials/PRIZ_05_brainstorming_concept.mp4',
        },
      },
    ],
  },
  {
    condition: ['project', 'tools', '9-w'],
    videos: [
      {
        title: 'Windows Thinking',
        localesVideoUrlMap: {
          [Locales.ZH]:
            'https://public-media-priz.sfo2.cdn.digitaloceanspaces.com/ch-tutorials/PRIZ_09_9%20Windows%20Thinking.mp4',
        },
      },
    ],
  },
  {
    condition: ['project', 'tools', 'cec'],
    videos: [
      {
        title: 'Root cause analysis',
        localesVideoUrlMap: {
          [Locales.ZH]:
            'https://public-media-priz.sfo2.cdn.digitaloceanspaces.com/ch-tutorials/PRIZ_06_Root%20cause%20analysis.mp4',
        },
      },
    ],
  },
  {
    condition: ['project', 'tools', 'p-map'],
    videos: [
      {
        title: 'Perception Mapping',
        localesVideoUrlMap: {
          [Locales.ZH]:
            'https://public-media-priz.sfo2.cdn.digitaloceanspaces.com/ch-tutorials/PRIZ_01_Perception%20Mapping.mp4',
        },
      },
    ],
  },
  {
    condition: ['project', 'tools', 'sfm'],
    videos: [
      {
        title: 'Functional Modeling',
        localesVideoUrlMap: {
          [Locales.ZH]:
            'https://public-media-priz.sfo2.cdn.digitaloceanspaces.com/ch-tutorials/PRIZ_08_%20Functional%20Modeling.mp4',
        },
      },
    ],
  },
  {
    condition: ['project', 'tools', 'pfm'],
    videos: [
      {
        title: 'Process Functional Modeling',
        localesVideoUrlMap: {
          [Locales.ZH]:
            'https://public-media-priz.sfo2.cdn.digitaloceanspaces.com/ch-tutorials/PRIZ_10_Process%20Functional%20Modeling.mp4',
        },
      },
    ],
  },
];
