import { EntityCollectionStatus, EntityMap, LookupMap } from '../../common/entity-collection-state';
import { Goal } from './goal';

interface RrmGoalLookups {
  byUtilizationId: LookupMap;
}

export interface RrmGoalCollectionStatuses {
  byGoalId?: { [goalId: number]: EntityCollectionStatus };
}

export interface RrmGoalCollection {
  entities: EntityMap<Goal>;

  lookups: RrmGoalLookups;

  statuses: RrmGoalCollectionStatuses;
}

export const DefaultRrmGoalCollection = {
  entities: {},

  lookups: {
    byUtilizationId: {},
  },

  statuses: {
    byGoalId: {},
  },
};
