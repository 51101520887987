const preventPageLeavingOnBeforeunloadEvent = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  e.returnValue = true;
};

export const bindPageLeavingPreventing = () => {
  window.addEventListener('beforeunload', preventPageLeavingOnBeforeunloadEvent);
};

export const unbindPageLeavingPreventing = () => {
  window.removeEventListener('beforeunload', preventPageLeavingOnBeforeunloadEvent);
};
