import { RouteObject } from 'react-router-dom';
import { RouteRenderer } from './route-renderer/component';
import { ToolType, ToolTypeUrlKeyMap } from '@priz/shared/src/models/tools';
import { FortyPrinciples } from '../forty-principles/forty-principles-utilization/component';
import { PureRrmUtilizationComponent } from '../rrm/RrmUtilizationComponent';
import { PureUimToolUtilization } from '../uim/uim-tool-utilization/component';
import { FiveWhys } from '../five-whys/five-whys-utilization/component';
import { ApaCustomer } from '../apa/apa-customer/component';
import { ApaReport } from '../apa/apa-report/component';
import { ApaActions } from '../apa/apa-actions/component';
import { ApaProcessAction } from '../apa/apa-process-action/component';
import { EbsView } from '../ebs/ebs-view/component';
import { CauseAndEffectChainView } from '../cec/cause-and-effect-chain-view/component';
import { SfmView } from '../sfm/sfm-view/component';
import { PfmView } from '../pfm/pfm-view/component';
import { PMapView } from '../pmap/pmap-view/component';
import { documentTitleResolver } from './utils';
import { NineWindowsPage } from '../nine-windows/nine-windows-page/component';
import { CftView } from '../cft/cft-view/component';
import { UnleashFeatureName } from '@priz/shared/src/services/unleash';

export const toolsRoutes: RouteObject[] = [
  {
    path: [ToolTypeUrlKeyMap[ToolType.TOOL_40_PRINCIPLES], ':utilizationId'].join('/'),
    element: <RouteRenderer Component={FortyPrinciples} authGuard={true} />,
    loader: documentTitleResolver('40P'),
  },
  {
    path: [ToolTypeUrlKeyMap[ToolType.RRM], ':utilizationId'].join('/'),
    element: <RouteRenderer Component={PureRrmUtilizationComponent} authGuard={true} />,
    loader: documentTitleResolver('RRM'),
  },
  {
    path: [ToolTypeUrlKeyMap[ToolType.UIM], ':utilizationId'].join('/'),
    element: <RouteRenderer Component={PureUimToolUtilization} authGuard={true} />,
    loader: documentTitleResolver('UIM'),
  },
  {
    path: [ToolTypeUrlKeyMap[ToolType.FIVE_WHYS], ':utilizationId'].join('/'),
    element: <RouteRenderer Component={FiveWhys} authGuard={true} />,
    loader: documentTitleResolver('5 WHYS'),
  },
  {
    path: [ToolTypeUrlKeyMap[ToolType.APA], ':utilizationId'].join('/'),
    element: <RouteRenderer Component={ApaCustomer} authGuard={true} />,
    loader: documentTitleResolver('APA'),
  },
  {
    path: [ToolTypeUrlKeyMap[ToolType.APA], ':utilizationId', 'report'].join('/'),
    element: <RouteRenderer Component={ApaReport} authGuard={true} />,
    loader: documentTitleResolver('APA Report'),
  },
  {
    path: [ToolTypeUrlKeyMap[ToolType.APA], ':utilizationId', 'actions'].join('/'),
    element: <RouteRenderer Component={ApaActions} authGuard={true} />,
    loader: documentTitleResolver('APA Actions'),
  },
  {
    path: [ToolTypeUrlKeyMap[ToolType.APA], ':utilizationId', 'action', ':actionId'].join('/'),
    element: <RouteRenderer Component={ApaProcessAction} authGuard={true} />,
    loader: documentTitleResolver('APA Action - Process'),
  },
  {
    path: [ToolTypeUrlKeyMap[ToolType.EBS], ':utilizationId'].join('/'),
    element: <RouteRenderer Component={EbsView} authGuard={true} />,
    loader: documentTitleResolver('EBS'),
  },
  {
    path: [ToolTypeUrlKeyMap[ToolType.NINE_WINDOWS], ':utilizationId'].join('/'),
    element: <RouteRenderer Component={NineWindowsPage} authGuard={true} />,
    loader: documentTitleResolver('9 Windows'),
  },
  {
    path: [ToolTypeUrlKeyMap[ToolType.CEC], ':utilizationId'].join('/'),
    element: <RouteRenderer Component={CauseAndEffectChainView} authGuard={true} />,
    loader: documentTitleResolver('Cause and Effect Chain'),
  },
  {
    path: [ToolTypeUrlKeyMap[ToolType.SFM], ':utilizationId'].join('/'),
    element: <RouteRenderer Component={SfmView} authGuard={true} />,
    loader: documentTitleResolver('System Functional Modeling'),
  },
  {
    path: [ToolTypeUrlKeyMap[ToolType.PFM], ':utilizationId'].join('/'),
    element: <RouteRenderer Component={PfmView} authGuard={true} />,
    loader: documentTitleResolver('Process Functional Modeling'),
  },
  {
    path: [ToolTypeUrlKeyMap[ToolType.P_MAP], ':utilizationId'].join('/'),
    element: <RouteRenderer Component={PMapView} authGuard={true} />,
    loader: documentTitleResolver('Perception mapping'),
  },
  {
    path: [ToolTypeUrlKeyMap[ToolType.CFT], ':utilizationId'].join('/'),
    element: <RouteRenderer Component={CftView} authGuard={true} requiredFeature={UnleashFeatureName.CFT} />,
    loader: documentTitleResolver('CFT'),
  },
];
