import { FluxStandardAction } from 'flux-standard-action';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { ToolType } from '@priz/shared/src/models/tools';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { createPgAction } from '../../../shared/store/action-creator';
import { Idea, IIdea } from '@priz/shared/src/models/idea/idea';
import { IdeaApi } from '../../services/IdeaApi';

export enum ProjectIdeaActionType {
  FetchAll = 'projectIdeas/fetch',
  Create = 'projectIdea/create',
  Update = 'projectIdea/update',
  Delete = 'projectIdea/delete',
}

type ProjectIdeaActionPayload = IIdea | IIdea[] | number;

interface ProjectIdeaActionMeta {
  projectId: number;
  ideaId?: number;
  sourceToolType?: ToolType;
}

export type ProjectIdeaAction = FluxStandardAction<string, ProjectIdeaActionPayload, ProjectIdeaActionMeta>;

const loadProjectIdeas = createPgAction(
  ProjectIdeaActionType.FetchAll,
  IdeaApi.loadIdeasByProjectId,
  (projectId: number) => ({ projectId }),
  undefined,
);

const create = createPgAction(
  ProjectIdeaActionType.Create,
  IdeaApi.create,
  (projectId: number) => ({ projectId }),
  (_payload: IIdea, meta: ProjectIdeaActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.IDEA_GENERATED, {
      project_id: meta.projectId,
      source_tool_type: meta.sourceToolType,
    });
  },
);

const update = createPgAction(
  ProjectIdeaActionType.Update,
  IdeaApi.update,
  (projectId: number, ideaId: number) => ({ projectId, ideaId }),
  (_payload: IIdea, meta: ProjectIdeaActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.IDEA_UPDATED, {
      idea_id: meta.ideaId,
      project_id: meta.projectId,
      source_tool_type: meta.sourceToolType,
    });
  },
);

const setAsWinning = createPgAction(
  ProjectIdeaActionType.Update,
  IdeaApi.selectAsWinning,
  (idea: Idea, isWinning: boolean) => ({ projectId: idea.project.id, ideaId: idea.id, idea, isWinning }),
  ({ id, sourceToolType, project }) => {
    AnalyticsService.track(AnalyticsEvent.IDEA_WINNING, {
      idea_id: id,
      project_id: project.id,
      source_tool_type: sourceToolType,
    });
  },
);

const doDelete = createPgAction(
  ProjectIdeaActionType.Delete,
  IdeaApi.delete,
  (projectId: number, ideaId: number) => ({ projectId, ideaId }),
  (_, meta: ProjectIdeaActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.IDEA_DELETED, {
      idea_id: meta.ideaId,
      project_id: meta.projectId,
    });
  },
);

export const ProjectIdeaActions = {
  create,
  delete: doDelete,
  loadProjectIdeas,
  setAsWinning,
  update,
};
