import { handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { toObjectMap } from '../../../shared/store/reducer-utils';
import { ArrayUtil } from '../../../shared/utils/array-util';
import { EbsUtilizationAction, EbsUtilizationActionType } from '../actions';
import {
  DefaultEbsIdeaCollection,
  EbsIdea,
  EbsIdeaCollection,
  EbsIdeasStatuses,
  IEbsIdea,
} from '@priz/shared/src/models/tools/ebs';
import { copyObject } from '@priz/shared/src/utils/common';

const ebsIdeaInstantiator = (payload: IEbsIdea): EbsIdea => new EbsIdea(payload);

const resolveStatuses = (
  utilizationId: number,
  state: EbsIdeaCollection,
  statuses: Partial<EntityCollectionStatus>,
): EbsIdeasStatuses => {
  const statusesCopy = copyObject(state.statuses);
  statusesCopy.byUtilizationId[utilizationId] = { ...(statusesCopy.byUtilizationId[utilizationId] || {}), ...statuses };
  return statusesCopy;
};

const setStatus = (
  state: EbsIdeaCollection,
  utilizationId: number,
  statuses: Partial<EntityCollectionStatus>,
): EbsIdeaCollection => {
  return {
    ...state,
    statuses: resolveStatuses(utilizationId, state, statuses),
  };
};

const setEbsIdeas = (state: EbsIdeaCollection, utilizationId: number, ideasPayload: IEbsIdea[]): EbsIdeaCollection => {
  const ideasMap = toObjectMap<EbsIdea>(ideasPayload, ebsIdeaInstantiator);
  const entitiesCopy = { ...state.entities, ...ideasMap };

  const lookupsCopy = copyObject(state.lookups);
  Object.values(ideasMap).forEach((idea: EbsIdea) => {
    lookupsCopy.byUtilizationId[utilizationId] = ArrayUtil.concatInDistinctArray(
      lookupsCopy.byUtilizationId[utilizationId],
      idea.id,
    );
  });

  return {
    ...state,
    entities: entitiesCopy,
    lookups: lookupsCopy,
    statuses: resolveStatuses(utilizationId, state, { loaded: true, loading: false }),
  };
};

const removeEbsIdea = (state: EbsIdeaCollection, utilizationId: number, ideaId: number): EbsIdeaCollection => {
  const entitiesCopy = { ...state.entities };
  delete entitiesCopy[ideaId];

  const lookupsCopy = copyObject(state.lookups);

  lookupsCopy.byUtilizationId[utilizationId] = lookupsCopy.byUtilizationId[utilizationId].filter(id => id !== ideaId);

  return {
    ...state,
    entities: entitiesCopy,
    lookups: lookupsCopy,
  };
};

const updateEbsIdea = (state: EbsIdeaCollection, ideaPayload: IEbsIdea): EbsIdeaCollection => {
  const updatedIdea = ebsIdeaInstantiator(ideaPayload);

  const entitiesCopy = {
    ...state.entities,
    [updatedIdea.id]: updatedIdea,
  };

  return {
    ...state,
    entities: entitiesCopy,
  };
};

const updateEbsIdeas = (state: EbsIdeaCollection, ideasPayload: IEbsIdea[]): EbsIdeaCollection => {
  const entitiesCopy = { ...state.entities };

  ideasPayload.forEach(item => {
    entitiesCopy[item.id] = ebsIdeaInstantiator(item);
  });

  return {
    ...state,
    entities: entitiesCopy,
  };
};

export const ebsIdeaReducers = handleActions(
  {
    [toStarted(EbsUtilizationActionType.FetchIdeas)]: (
      state: EbsIdeaCollection,
      action: EbsUtilizationAction,
    ): EbsIdeaCollection => setStatus(state, action.meta.utilizationId, { loading: true }),
    [toSuccess(EbsUtilizationActionType.FetchIdeas)]: (
      state: EbsIdeaCollection,
      action: EbsUtilizationAction,
    ): EbsIdeaCollection => setEbsIdeas(state, action.meta.utilizationId, action.payload as IEbsIdea[]),
    [toFailed(EbsUtilizationActionType.FetchIdeas)]: (
      state: EbsIdeaCollection,
      action: EbsUtilizationAction,
    ): EbsIdeaCollection => setStatus(state, action.meta.utilizationId, { loading: false, error: true }),

    [toSuccess(EbsUtilizationActionType.IdeaCreate)]: (
      state: EbsIdeaCollection,
      action: EbsUtilizationAction,
    ): EbsIdeaCollection => setEbsIdeas(state, action.meta.utilizationId, [action.payload as IEbsIdea]),

    [toSuccess(EbsUtilizationActionType.IdeaUpdate)]: (
      state: EbsIdeaCollection,
      action: EbsUtilizationAction,
    ): EbsIdeaCollection => updateEbsIdea(state, action.payload as IEbsIdea),

    [toSuccess(EbsUtilizationActionType.IdeasUpdate)]: (
      state: EbsIdeaCollection,
      action: EbsUtilizationAction,
    ): EbsIdeaCollection => updateEbsIdeas(state, action.payload as IEbsIdea[]),

    [toSuccess(EbsUtilizationActionType.IdeaDelete)]: (
      state: EbsIdeaCollection,
      action: EbsUtilizationAction,
    ): EbsIdeaCollection => removeEbsIdea(state, action.meta.utilizationId, action.meta.ideaId),
  },
  DefaultEbsIdeaCollection,
);
