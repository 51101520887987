import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Agenda, Attendee, Meeting } from '../store/model';
import { Trans } from 'react-i18next';
import { MeetingInfo } from '../meeting-info/component';

export interface MeetingViewerProps {
  meeting: Meeting;
  open: boolean;
  onClose?: () => void;
  forcedAgenda?: Agenda[];
  forcedAttendees?: Attendee[];
}

export const MeetingViewer: React.FC<MeetingViewerProps> = ({
  meeting,
  forcedAgenda,
  forcedAttendees,
  open,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const closeHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Dialog open={isOpen} onClose={closeHandler} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>{meeting.title}</DialogTitle>

      <DialogContent>
        <MeetingInfo
          meeting={meeting}
          showTitle={false}
          forcedAttendees={forcedAttendees}
          forcedAgenda={forcedAgenda}
        />
      </DialogContent>

      <DialogActions>
        <Box textAlign={'right'}>
          <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={closeHandler}>
            <Trans>Close</Trans>
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
