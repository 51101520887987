import React from 'react';
import { Idea } from '@priz/shared/src/models/idea';
import { useSelector } from 'react-redux';
import { IdeasCommentsSelectors } from '../store';
import { IdeaCommentCard } from '../idea-comment-card/component';
import { IdeaCommentsSummary } from '../idea-comments-summary/component';
import { Trans } from 'react-i18next';
import { Alert, Box } from '@mui/material';

interface IdeaCommentsListProps {
  idea: Idea;
}

export const IdeaCommentsList: React.FC<IdeaCommentsListProps> = ({ idea }) => {
  const comments = useSelector(IdeasCommentsSelectors.getAllByIdeaId(idea.id));

  return (
    <>
      {!!comments.length && <IdeaCommentsSummary comments={comments} mb={2} />}

      {comments
        .sort((a, b) => {
          return (a.lastUpdated || a.dateCreated) < (b.lastUpdated || b.dateCreated) ? 1 : 0;
        })
        .map(comment => (
          <IdeaCommentCard key={comment.id} comment={comment} mb={1} />
        ))}

      {!comments.length && (
        <Box my={2}>
          <Alert severity={'info'}>
            <Trans>There are no comments yet</Trans>
          </Alert>
        </Box>
      )}
    </>
  );
};
