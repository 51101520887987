import { PartnerCommissionStatus } from './partner-commission-status';
import { IdRef } from '../common/id-ref';
import { AuditableEntity, IAuditableEntity } from '../common/auditable-entity';

export interface IPartnerCommission extends IAuditableEntity {
  id: number;
  referral: IdRef;
  referrer: IdRef;
  rate: number;
  value: number;
  date: Date;
  status: PartnerCommissionStatus;
}

export class PartnerCommission extends AuditableEntity implements IPartnerCommission {
  public id: number;
  public referral: IdRef;
  public referrer: IdRef;
  public rate: number;
  public value: number;
  public date: Date;
  public status: PartnerCommissionStatus;

  constructor(json: IPartnerCommission) {
    super(json);

    this.id = json.id;
    this.referral = json.referral;
    this.referrer = json.referrer;
    this.rate = json.rate;
    this.value = json.value;
    this.date = json.date && new Date(json.date);
    this.status = json.status;
  }
}
