import axios, { AxiosResponse } from 'axios';
import { Translation } from '../model/translation';

const list = (
  locale: string,
  page: number,
  emptyOnly: boolean,
  match?: string,
): Promise<{ translations: Translation[]; count: number }> =>
  axios
    .get(`/v1/api/translations/${locale}/${page}`, { params: { emptyOnly, match } })
    .then((response: AxiosResponse<{ translations: Translation[]; count: number }>) => response.data);

const create = (en: string): Promise<Translation> =>
  axios.post(`/v1/api/translation`, { en }).then((response: AxiosResponse<Translation>) => response.data);

const update = (id: number, locale: string, translation: string): Promise<Translation> =>
  axios
    .put(`/v1/api/translation/${id}/${locale}`, { translation })
    .then((response: AxiosResponse<Translation>) => response.data);

const doDelete = (id: number): Promise<void> => axios.delete(`/v1/api/translation/${id}`);

export const TranslationsApi = {
  list,
  create,
  update,
  delete: doDelete,
};
