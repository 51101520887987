import axios, { AxiosResponse } from 'axios';
import {
  ISfmUtilization,
  OptionalComponentRankMap,
  SfmNetworkData,
  SfmRankMap,
  SfmUtilization,
  SfmVersionsRanksMap,
} from '@priz/shared/src/models/tools/sfm';
import { LocalesService } from '@priz/shared/src/components/locales/services';
import { ComponentRecommendationType, SfmComponentsEliminationEffectMap } from '../model';

const updateUtilization = (utilization: SfmUtilization): Promise<ISfmUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/sfm/${utilization.id}`, {
      title: utilization?.title,
      subject: utilization?.subject,
      conclusion: utilization?.conclusion,
    })
    .then((response: AxiosResponse<ISfmUtilization>) => response.data);

export interface SfmUpdateNetworkDataCommand {
  versionId?: string;
  networkData: SfmNetworkData;
}

const updateNetworkData = (utilizationId: number, command: SfmUpdateNetworkDataCommand): Promise<ISfmUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/sfm/${utilizationId}/network-data`, command)
    .then((response: AxiosResponse<ISfmUtilization>) => response.data);

export interface SfmCreateNewDiagramDataVersionCommand {
  sourceVersionId?: string;
}

const createNewVersion = (
  utilizationId: number,
  command: SfmCreateNewDiagramDataVersionCommand,
): Promise<ISfmUtilization> => {
  const currentLocale = LocalesService.getCurrentLocale();

  return axios
    .put(`/v1/api/<ws-id>/team/<t-id>/sfm/${utilizationId}/new-version`, { ...command, locale: currentLocale })
    .then((response: AxiosResponse<ISfmUtilization>) => response.data);
};

export interface SfmUpdateDiagramDataVersionTitleCommand {
  versionId: string;
  title: string;
}

const updateVersionTitle = (
  utilizationId: number,
  command: SfmUpdateDiagramDataVersionTitleCommand,
): Promise<ISfmUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/sfm/${utilizationId}/update-version-title`, command)
    .then((response: AxiosResponse<ISfmUtilization>) => response.data);

export interface SfmSwitchDiagramDataVersionCommand {
  versionId: string;
}

const switchVersion = (utilizationId: number, command: SfmSwitchDiagramDataVersionCommand): Promise<ISfmUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/sfm/${utilizationId}/switch-version`, command)
    .then((response: AxiosResponse<ISfmUtilization>) => response.data);

export interface SfmRemoveDiagramDataVersionCommand {
  versionId: string;
}

const removeVersion = (utilizationId: number, command: SfmRemoveDiagramDataVersionCommand): Promise<ISfmUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/sfm/${utilizationId}/remove-version`, command)
    .then((response: AxiosResponse<ISfmUtilization>) => response.data);

const doDelete = (utilizationId: number): Promise<any> =>
  axios.delete(`/v1/api/<ws-id>/team/<t-id>/sfm/${utilizationId}`);

const getSfmRank = (utilizationId: number): Promise<SfmRankMap> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/sfm/${utilizationId}/rank`)
    .then((response: AxiosResponse<SfmRankMap>) => response.data);

const getSfmRanks = (pfmId: number): Promise<SfmRankMap> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/pfm/${pfmId}/sfm-ranks`)
    .then((response: AxiosResponse<SfmRankMap>) => response.data);

const getSfmVersionsRanks = (utilizationId: number): Promise<SfmVersionsRanksMap> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/sfm/${utilizationId}/sfm-versions-ranks`)
    .then((response: AxiosResponse<SfmVersionsRanksMap>) => response.data);

const getComponentsRanks = (utilizationId: number): Promise<OptionalComponentRankMap> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/sfm/${utilizationId}/components-ranks`)
    .then((response: AxiosResponse<OptionalComponentRankMap>) => response.data);

const getVersionComponentsRanks = (utilizationId: number, versionId: string): Promise<OptionalComponentRankMap> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/sfm/${utilizationId}/components-ranks/${versionId}`)
    .then((response: AxiosResponse<OptionalComponentRankMap>) => response.data);

const getComponentsEliminationEffect = (
  utilizationId: number,
  versionId: string,
): Promise<SfmComponentsEliminationEffectMap> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/sfm/${utilizationId}/version/${versionId}/components-elimination-effect`)
    .then((response: AxiosResponse<SfmComponentsEliminationEffectMap>) => response.data);

const getProblematicComponents = (utilizationId: number, versionId: string): Promise<string[]> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/sfm/${utilizationId}/version/${versionId}/problematic-components`)
    .then((response: AxiosResponse<string[]>) => response.data);

const getVersionImprovements = (utilizationId: number, versionId: string): Promise<ISfmUtilization> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/sfm/${utilizationId}/version/${versionId}/improvements`)
    .then((response: AxiosResponse<ISfmUtilization>) => response.data);

const getComponentImprovement = (
  utilizationId: number,
  versionId: string,
  componentId: string,
): Promise<ISfmUtilization> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/sfm/${utilizationId}/version/${versionId}/component/${componentId}/improvement`)
    .then((response: AxiosResponse<ISfmUtilization>) => response.data);

export interface SfmComponentRecommendationCommand {
  type: ComponentRecommendationType;
  done: boolean;
}

const updateComponentRecommendation = (
  utilizationId: number,
  versionId: string,
  componentId: string,
  command: string,
): Promise<ISfmUtilization> =>
  axios
    .patch(
      `/v1/api/<ws-id>/team/<t-id>/sfm/${utilizationId}/version/${versionId}/component/${componentId}/recommendation`,
      command,
    )
    .then((response: AxiosResponse<ISfmUtilization>) => response.data);

export const SfmUtilizationApi = {
  delete: doDelete,
  updateUtilization,
  updateNetworkData,
  createNewVersion,
  updateVersionTitle,
  switchVersion,
  removeVersion,
  getSfmRank,
  getSfmRanks,
  getSfmVersionsRanks,
  getComponentsRanks,
  getVersionComponentsRanks,
  getComponentsEliminationEffect,
  getProblematicComponents,
  getVersionImprovements,
  getComponentImprovement,
  updateComponentRecommendation,
};
