import { EntityCollectionStatus, EntityMap, LookupMap } from '../common/entity-collection-state';
import { Task } from './task';

interface TaskLookups {
  byProjectId: LookupMap;
}

export interface TaskCollectionStatuses {
  byProjectId?: { [projectId: number]: EntityCollectionStatus };
}

export interface TaskCollection {
  entities: EntityMap<Task>;

  lookups: TaskLookups;

  statuses: TaskCollectionStatuses;
}

export const DefaultTaskCollection: TaskCollection = {
  entities: {},

  lookups: {
    byProjectId: {},
  },

  statuses: {
    byProjectId: {},
  },
};
