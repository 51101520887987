import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { WorkspaceSelectors } from '../store/selectors';
import { WorkspaceActions } from '../store/actions';
import { useForm } from 'react-hook-form';
import { ReactHookFormText } from '../../react/form-elements';
import { LoadingButton } from '@priz/shared/src/components/loading-button/component';
import { validateOptions, validationPatterns, validatorRules } from '@priz/shared/src/utils/form';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { UserContextSelectors } from '../../security/store/selectors';
import { Alert, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppNavbar } from '../../navigation/app-navbar/component';
import { PageContainer } from '../../content-containers/page-container/component';

import { ReactComponent as BottomBackgroundImage } from '../../../assets/img/apa-bg.svg';

export const CreateWorkspace: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { handleSubmit, getValues, reset, control } = useForm({
    shouldFocusError: false,
    defaultValues: {
      name: '',
      billingEmail: '',
    },
  });

  const currentUser = useSelector(UserContextSelectors.getCurrentUser);
  const selectedWorkspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const workspaceIsCreating = useSelector(WorkspaceSelectors.isCreating);

  useEffect(() => {
    if (currentUser?.email) {
      reset({
        ...getValues(),
        billingEmail: currentUser.email,
      });
    }
  }, [currentUser]);

  const cancelHandler = () => {
    navigate('/');
  };

  const createHandler = () => {
    handleSubmit(formData => {
      dispatch(WorkspaceActions.create(formData.name, formData.billingEmail, 1, navigate));
    })();
  };

  return (
    <PageContainer variant={'narrow'}>
      <AppNavbar />

      <>
        <PageTitleWithDocLink
          title={<Trans>{selectedWorkspace ? 'Create new workspace' : 'Create workspace'}</Trans>}
        />

        {!selectedWorkspace && (
          <Box mb={2}>
            <Alert severity={'info'}>
              <Trans>There is no active workspace available. Please, create a new one.</Trans>
            </Alert>
          </Box>
        )}

        <ReactHookFormText
          fieldTitle={'New workspace name'}
          helperText={'Name is required'}
          name={'name'}
          control={control}
          rules={{
            validate: {
              ...validateOptions.hasText('Name is required'),
            },
          }}
          disabled={workspaceIsCreating}
        />

        <ReactHookFormText
          fieldTitle={'Workspace contact email'}
          helperText={'Email is required'}
          name={'billingEmail'}
          control={control}
          rules={{
            ...validatorRules.required('Email is required'),
            ...validatorRules.pattern(validationPatterns.email, 'A valid email is required'),
          }}
          disabled={workspaceIsCreating}
        />

        <ButtonsWrap justifyContent={'right'} spacing={1}>
          {selectedWorkspace && (
            <Button
              variant={'pg_rounded'}
              color={'pg_orange_outlined'}
              disabled={workspaceIsCreating}
              onClick={cancelHandler}
            >
              <Trans>Cancel</Trans>
            </Button>
          )}

          <LoadingButton text={'Create'} loading={workspaceIsCreating} onClick={createHandler} />
        </ButtonsWrap>

        <Box mt={'auto'} pt={5}>
          <BottomBackgroundImage style={{ display: 'block', height: 'auto', maxWidth: '100%', margin: 'auto' }} />
        </Box>
      </>
    </PageContainer>
  );
};
