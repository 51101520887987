import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfinity } from '@fortawesome/free-solid-svg-icons/faInfinity';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { useStyles } from './styles';
import { VerticalAlignTopRounded } from '@mui/icons-material';
import { Trans } from 'react-i18next';

interface PlanFeatureIndicatorProps {
  value: null | number | boolean;
  maxItems?: number;
  maxItemsTooltip?: string;
}

export const PlanFeatureIndicator: React.FC<PlanFeatureIndicatorProps> = ({ value, maxItems, maxItemsTooltip }) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {!!maxItems && (
        <div className={styles.indicatorsGroup}>
          <Tooltip title={maxItemsTooltip ? <Trans>{maxItemsTooltip}</Trans> : null} arrow placement="top">
            <div className={styles.maxItemsValue}>
              <Typography variant={'body2'} component={'div'}>
                {maxItems}
              </Typography>
              <VerticalAlignTopRounded />
            </div>
          </Tooltip>
        </div>
      )}

      <div className={styles.indicatorsGroup}>
        {value === null && <FontAwesomeIcon icon={faInfinity} size={'1x'} className={styles.activeValue} />}

        {typeof value === 'number' && (
          <Typography variant={'subtitle1'} component={'span'} className={styles.numberValue}>
            {value}
          </Typography>
        )}

        {typeof value === 'boolean' &&
          (value ? (
            <FontAwesomeIcon icon={faCheck} size={'1x'} className={styles.activeValue} />
          ) : (
            <FontAwesomeIcon icon={faTimes} size={'1x'} className={styles.disabledValue} />
          ))}
      </div>
    </div>
  );
};
