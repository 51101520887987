import axios, { AxiosResponse } from 'axios';
import { IToolUtilization, ToolType } from '@priz/shared/src/models/tools';
import { AxiosService } from '@priz/shared/src/services/axios';

export interface ToolUtilizationCommand {
  type: ToolType;
  title?: string;
  locale?: string;
  parentId?: number;
  sourceId?: string;
}

export interface ToolCompleteCommand {
  complete: boolean;
}

const createTeamTool = (command: ToolUtilizationCommand): Promise<IToolUtilization> => {
  return axios
    .post(`/v1/api/<ws-id>/team/<t-id>/utilization`, command)
    .then((response: AxiosResponse<IToolUtilization>) => {
      const accessToken = response.data.accessToken;

      if (accessToken) {
        if (!command.parentId) {
          AxiosService.setUtilizationToken(accessToken);
        }

        delete response.data.accessToken;
      }

      return response.data;
    });
};

const createProjectTool = (projectId: number, command: ToolUtilizationCommand): Promise<IToolUtilization> =>
  axios
    .post(`/v1/api/<ws-id>/team/<t-id>/project/${projectId}/utilization`, command)
    .then((response: AxiosResponse<IToolUtilization>) => response.data);

const createTool = (command: ToolUtilizationCommand, projectId?: number): Promise<IToolUtilization> => {
  if (typeof projectId !== 'undefined') {
    return createProjectTool(projectId, command);
  } else {
    return createTeamTool(command);
  }
};

const getTeamTool = (utilizationId: number): Promise<IToolUtilization> => {
  return axios
    .get(`/v1/api/<ws-id>/team/<t-id>/utilization/${utilizationId}`)
    .then((response: AxiosResponse<IToolUtilization>) => response.data);
};

const getProjectTool = (projectId: number, utilizationId: number): Promise<IToolUtilization> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/project/${projectId}/utilization/${utilizationId}`)
    .then((response: AxiosResponse<IToolUtilization>) => response.data);

const getPublicTool = (publicId: string): Promise<IToolUtilization> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/public-utilization/${publicId}`)
    .then((response: AxiosResponse<IToolUtilization>) => response.data);

const getTool = (utilizationId: number, projectId?: number): Promise<IToolUtilization> => {
  if (typeof projectId !== 'undefined') {
    return getProjectTool(projectId, utilizationId);
  } else {
    return getTeamTool(utilizationId);
  }
};

const getTeamTools = (): Promise<IToolUtilization[]> => {
  return axios
    .get(`/v1/api/<ws-id>/team/<t-id>/utilizations`)
    .then((response: AxiosResponse<IToolUtilization[]>) => response.data);
};

const getProjectTools = (projectId: number): Promise<IToolUtilization[]> =>
  axios
    .get(`/v1/api/<ws-id>/team/<t-id>/project/${projectId}/utilizations`)
    .then((response: AxiosResponse<IToolUtilization[]>) => response.data);

const getTools = (projectId?: number): Promise<IToolUtilization[]> => {
  if (typeof projectId !== 'undefined') {
    return getProjectTools(projectId);
  } else {
    return getTeamTools();
  }
};

const updateCompleteStatus = (utilizationId: number, command: ToolCompleteCommand): Promise<IToolUtilization> =>
  axios
    .patch(`/v1/api/<ws-id>/team/<t-id>/utilization/${utilizationId}/complete`, command)
    .then((response: AxiosResponse<IToolUtilization>) => response.data);

export const ToolsApi = {
  createTool,
  getTool,
  getTools,
  getPublicTool,
  updateCompleteStatus,
};
