import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';

export const useStyles = makeStyles({
  root: {
    position: 'relative',

    ['&::before, &::after']: {
      content: '""',
      borderStyle: 'solid',
      boxSizing: 'border-box',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      margin: 'auto',
      zIndex: 1,
    },

    ['&::before']: {
      width: '100%',
      height: 2,
      borderWidth: 1,
      left: 0,
      borderColor: pgColorScheme.blue,
    },

    ['&::after']: {
      transformOrigin: '50% 50%',
      transform: 'rotate(45deg)',
      width: 10,
      height: 10,
      borderWidth: 2,
      left: 'auto',
      borderLeftColor: 'transparent',
      borderBottomColor: 'transparent',
      borderTopColor: pgColorScheme.blue,
      borderRightColor: pgColorScheme.blue,
    },
  },
  useful: {},
  harmful: {
    ['&::before']: {
      borderColor: pgColorScheme.coral,
    },
    ['&::after']: {
      borderTopColor: pgColorScheme.coral,
      borderRightColor: pgColorScheme.coral,
    },
  },
  excessive: {
    ['&::before']: {
      borderWidth: 2,
      height: 4,
    },

    ['&::after']: {
      borderWidth: 4,
    },
  },
  insufficient: {
    ['&::before']: {
      borderStyle: 'dashed',
    },
  },
  text: {
    position: 'relative',
    textShadow: `-2px 0 #FFF, 2px 0 #FFF, 0 -2px #FFF, 0 2px #FFF`,
    zIndex: 2,
  },
  empty: {
    color: pgColorScheme.gray,
  },
  short: {
    maxWidth: 200,
  },
});
