import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { ColorTypeResolverProps, resolveValueByColorType, ValueColorType } from '../../utils/common';
import { useStyles } from './styles';

interface ValueBarProps extends ColorTypeResolverProps {
  width?: number;
  widthMultiplier?: number;
  containerProps?: BoxProps;
  barProps?: BoxProps;
}

export const ValueBar: React.FC<ValueBarProps> = ({
  width,
  widthMultiplier = 10,
  containerProps,
  barProps,
  ...rest
}) => {
  const styles = useStyles();

  const classesMap = {
    [ValueColorType.Green]: styles.barGreen,
    [ValueColorType.Blue]: styles.barBlue,
    [ValueColorType.Red]: styles.barRed,
  };

  return (
    <Box className={styles.root} {...(containerProps || {})}>
      <Box
        pt={1}
        {...(barProps || {})}
        className={[
          styles.bar,
          resolveValueByColorType({
            valuesMap: classesMap,
            ...rest,
          }),
        ].join(' ')}
        style={{ width: `${width || rest.value * widthMultiplier}%` }}
      />
    </Box>
  );
};
