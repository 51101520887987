import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },
    highlight: {
      outline: `3px solid ${pgColorScheme.blue}`,
    },
    infoIconContainer: {
      position: 'absolute',
      top: -10,
      right: -10,
      bottom: 'auto',
      left: 'auto',
      zIndex: 2,
    },
    infoIcon: {
      position: 'relative',
      display: 'block',
      fontSize: 24,
      color: pgColorScheme.blue,
      backgroundColor: pgColorScheme.white,
      borderRadius: '50%',
      cursor: 'help',
    },
    toolIcon: {
      width: 45,
      height: 45,
      display: 'block',
    },
    title: {
      fontWeight: 'bold',
      lineHeight: 1.2,
    },
    description: {
      lineHeight: 1.2,
    },
    link: {
      textTransform: 'capitalize',
    },
    controlsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
    buttonsWrap: {
      flexDirection: 'column',
    },

    [theme.breakpoints.down('sm')]: {
      controlsContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row-reverse',
        flexWrap: 'wrap',
      },
      buttonsWrap: {
        flexDirection: 'row',
      },
      toolIcon: {
        width: 30,
        height: 33,
      },
    },
  }),
);
