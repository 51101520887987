import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    '&._link': {
      'cursor': 'pointer',

      '&:hover $text': {
        color: pgColorScheme.blue,
      },
    },
  },
  textWrap: {
    wordBreak: 'break-word',
  },
  text: {
    color: pgColorScheme.darkText,
    transition: 'color .15s ease',
  },
  controlsWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
});
