import axios, { AxiosResponse } from 'axios';
import { User } from '@priz/shared/src/models/security/user';
import { Role } from '@priz/shared/src/models/security/role.enum';
import { PartnerProfile, PartnerProfileStatus } from '@priz/shared/src/models/security/partner-profile';

export interface GetUserByIdOrEmailCommand {
  id?: number;
  email?: string;
}

const getByIdOrEmail = (command: GetUserByIdOrEmailCommand): Promise<User> =>
  axios.post(`/v1/api/user/get`, command).then((response: AxiosResponse<User>) => response.data);

export interface UserAddRoleCommand {
  authority: Role;
}

const addUserRole = (userId: number, command: UserAddRoleCommand): Promise<User> =>
  axios.post(`/v1/api/user/${userId}/role/add`, command).then((response: AxiosResponse<User>) => response.data);

export interface UserRemoveRoleCommand {
  authority: Role;
}

const removeUserRole = (userId: number, command: UserRemoveRoleCommand): Promise<User> =>
  axios.post(`/v1/api/user/${userId}/role/remove`, command).then((response: AxiosResponse<User>) => response.data);

export interface CreatePartnerProfileCommand {
  commissionRate: number;
}

const createPartnerProfile = (userId: number, command: CreatePartnerProfileCommand): Promise<User> =>
  axios
    .post(`/v1/api/user/${userId}/partner-profile/create`, command)
    .then((response: AxiosResponse<User>) => response.data);

export interface UserPartnerProfileUpdateCommand {
  commissionRate?: number;
  status?: PartnerProfileStatus;
}

const updatePartnerProfile = (userId: number, command: UserPartnerProfileUpdateCommand): Promise<User> =>
  axios
    .patch(`/v1/api/user/${userId}/partner-profile/update`, command)
    .then((response: AxiosResponse<User>) => response.data);

export interface ActivePartnerProfilesResponse {
  profiles: PartnerProfile[];
  totalCount: number;
}

const getAllActivePartnerProfiles = (page: number): Promise<ActivePartnerProfilesResponse> =>
  axios
    .get(`/v1/api/partner-profiles/${page}`)
    .then((response: AxiosResponse<ActivePartnerProfilesResponse>) => response.data);

export interface UpdateUserRestrictionsCommand {
  nonChargeable?: boolean;
}

const updateUserRestrictions = (userId, command: UpdateUserRestrictionsCommand): Promise<User> =>
  axios.patch(`/v1/api/user/${userId}/restrictions`, command).then((response: AxiosResponse<User>) => response.data);

const createApiKey = (userId: number): Promise<User> =>
  axios.post(`/v1/api/user/${userId}/api-key`).then((response: AxiosResponse<null>) => response.data);

const invalidateApiKey = (userId: number, apiKeyId: number): Promise<User> =>
  axios.delete(`/v1/api/user/${userId}/api-key/${apiKeyId}`).then((response: AxiosResponse<null>) => response.data);

const resetAcceptedPolicies = (): Promise<null> =>
  axios.patch(`/v1/api/users/reset-accepted-policies`).then((response: AxiosResponse<null>) => response.data);

export const UsersAdministrationApi = {
  getByIdOrEmail,
  addUserRole,
  removeUserRole,
  createPartnerProfile,
  updatePartnerProfile,
  getAllActivePartnerProfiles,
  updateUserRestrictions,
  createApiKey,
  invalidateApiKey,
  resetAcceptedPolicies,
};
