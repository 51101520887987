import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { rootReducer } from './app.reducers';
import { registerSagas } from './app.sagas';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { applyMiddleware, createStore } from 'redux';

const loadState = () => {
  try {
    const serializedState = LocalStorageService.getItem(LocalStorageKey.State);

    if (serializedState === null) {
      return {};
    }

    return JSON.parse(serializedState);
  } catch (e) {
    console.error('error loading state ', e);
    return {};
  }
};

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, loadState(), applyMiddleware(thunk, sagaMiddleware));

registerSagas(sagaMiddleware);

export default store;
