export type ComponentRank = {
  functionalRank: number;
  problematicRank: number;
};

export type OptionalComponentRank = {
  functionalRank?: number;
  problematicRank?: number;
};

export type OptionalComponentRankMap = {
  [nodeId: string]: OptionalComponentRank;
};

export enum OperationMeritType {
  Ideal = 'Ideal',
  Harmful = 'Harmful',
  NotDefined = 'NotDefined',
}

export enum OperationPerfectnessType {
  High = 'High',
  Acceptable = 'Acceptable',
  Medium = 'Medium',
  Low = 'Low',
  NotDefined = 'NotDefined',
}

export enum OperationEfficiency {
  High = 'High',
  Low = 'Low',
  Lowest = 'Lowest',
  NotDefined = 'NotDefined',
}

export type OperationMerit = {
  value?: number;
  type?: OperationMeritType;
  efficiency?: OperationEfficiency;
};

export type OperationPerfectness = {
  value: number;
  type: OperationPerfectnessType;
};

export interface SfmRank extends ComponentRank {
  operationMerit: OperationMerit;
  operationPerfectness: OperationPerfectness;
}

export type SfmRankMap = {
  [utilizationId: number]: SfmRank;
};

export type SfmVersionsRanksMap = {
  [versionId: string]: SfmRank;
};

export const defaultSfmRank: SfmRank = {
  operationMerit: { type: OperationMeritType.NotDefined, efficiency: OperationEfficiency.NotDefined },
  operationPerfectness: { value: 0, type: OperationPerfectnessType.NotDefined },
  functionalRank: 0,
  problematicRank: 0,
};
