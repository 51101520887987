import { EntityCollectionStatus, EntityMap, LookupMap } from '@priz/shared/src/models/common/entity-collection-state';
import { Attachment, AttachmentPreview } from './attachment';

export interface AttachmentsLookups {
  byProjectId: LookupMap;
}

export interface CreatingAttachments {
  byProjectId: { [projectId: number]: { [name: string]: AttachmentPreview } };
}

export interface AttachmentsCollectionStatuses {
  byProjectId?: { [projectId: number]: EntityCollectionStatus };
}

export interface AttachmentsCollection {
  entities: EntityMap<Attachment>;

  lookups: AttachmentsLookups;

  creatingAttachments: CreatingAttachments;

  statuses: AttachmentsCollectionStatuses;
}

export const DefaultAttachmentsCollection: AttachmentsCollection = {
  entities: {},

  lookups: {
    byProjectId: {},
  },

  creatingAttachments: {
    byProjectId: {},
  },

  statuses: {
    byProjectId: {},
  },
};
