import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FortyPrinciplesUtilization } from '@priz/shared/src/models/tools/forty-principles/forty-principles-utilization';
import { DomainActions, DomainPrincipleActions, MatrixElementActions, PrincipleActions } from '../store/actions';
import { InventivePrinciplesSelectors } from '../store/selectors/InventivePrinciplesSelectors';
import {
  doMatchingElementsExist,
  resolvePrincipleDescription,
  getPrincipleById,
  resolveSelectedPrincipleId,
} from './utils';
import { Trans, useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { ReactHookFormSelect } from '../../react/form-elements';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { resolveMatchingPrinciples } from '@priz/shared/src/utils/inventive-principles/inventive-principles';
import { resolveTranslation, TranslationResolver } from '../../react/translation-resolver/component';

export interface InventivePrinciplesResultProps {
  utilization: FortyPrinciplesUtilization;
  disabled?: boolean;
}

export const InventivePrinciplesResult: React.FC<InventivePrinciplesResultProps> = ({ utilization }) => {
  const theme = useTheme();
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedDomainId, setSelectedDomainId] = useState(0);
  const [selectedPrincipleId, setSelectedPrincipleId] = useState(null);

  const { getValues, control } = useForm({
    defaultValues: {
      selectedDomainId: null,
    },
  });

  const principles = useSelector(InventivePrinciplesSelectors.getPrinciples);
  const domains = useSelector(InventivePrinciplesSelectors.getDomains);
  const domainPrinciples = useSelector(InventivePrinciplesSelectors.getDomainPrinciples);
  const matrixElements = useSelector(InventivePrinciplesSelectors.getMatrixElements);

  const matchingPrinciples = resolveMatchingPrinciples(utilization, principles, matrixElements);
  const selectedPrinciple = getPrincipleById(selectedPrincipleId, matchingPrinciples);

  const isDownMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  const description =
    selectedPrinciple && domainPrinciples
      ? resolvePrincipleDescription(selectedPrinciple, domainPrinciples, selectedDomainId)
      : null;

  useEffect(() => {
    const newId = resolveSelectedPrincipleId(selectedPrincipleId, matchingPrinciples);

    if (newId !== selectedPrincipleId) {
      setSelectedPrincipleId(newId);
    }
  }, [selectedPrincipleId, matchingPrinciples]);

  useEffect(() => {
    dispatch(PrincipleActions.loadPrinciples());
    dispatch(DomainPrincipleActions.loadDomainPrinciples());
    dispatch(DomainActions.loadDomains());
    dispatch(MatrixElementActions.loadMatrixElements());
  }, [utilization]);

  const handleTabChange = (_e, principleId: number) => {
    setSelectedPrincipleId(principleId);
  };

  const selectDomainHandler = () => {
    setSelectedDomainId(getValues().selectedDomainId);
  };

  return (
    <Box>
      {!doMatchingElementsExist(utilization, matrixElements) && (
        <Box mb={2}>
          <Typography variant={'overline'} component={'span'}>
            <FontAwesomeIcon icon={faInfo} className={styles.infoIcon} />

            <Trans>
              This contradiction does not have any specific matching principles. In such rare case, problem solver
              should start working through all of them from the beginning.
            </Trans>
          </Typography>
        </Box>
      )}

      <Box mb={3}>
        <Tabs
          value={resolveSelectedPrincipleId(selectedPrincipleId, matchingPrinciples)}
          onChange={handleTabChange}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {matchingPrinciples.map((p, key) => {
            const title = resolveTranslation({
              en: p.title,
              zh: p.titleZh,
              es: p.titleEs,
            });

            return <Tab value={p.id} key={key} label={`${p.id}. ${title}`} />;
          })}
        </Tabs>
      </Box>

      {selectedPrinciple && domainPrinciples && (
        <>
          <Grid
            container
            alignItems={isDownMdBreakpoint ? 'stretch' : 'flex-start'}
            justifyContent={isDownMdBreakpoint ? 'flex-start' : 'space-between'}
            direction={isDownMdBreakpoint ? 'column-reverse' : 'row'}
          >
            <Grid item xs={true} className={styles.gridColumn}>
              <Typography variant={'h6'} component={'span'}>
                {selectedPrinciple.id}.{' '}
                {resolveTranslation({
                  en: selectedPrinciple.title,
                  zh: selectedPrinciple.titleZh,
                  es: selectedPrinciple.titleEs,
                })}
              </Typography>
            </Grid>

            <Grid item xs={'auto'} className={styles.gridColumn}>
              <Box mb={{ xs: 3, md: 0 }}>
                <ReactHookFormSelect
                  displayEmpty={true}
                  variant={'standard'}
                  name={'selectedDomainId'}
                  control={control}
                  options={[
                    { value: '', text: t('Generic definitions') },
                    ...domains.map(d => {
                      const title = resolveTranslation({
                        en: d.title,
                        zh: d.titleZh,
                        es: d.titleEs,
                      });

                      return { value: d.id, text: title };
                    }),
                  ]}
                  onChange={selectDomainHandler}
                  wrapperProps={{
                    width: { xs: '100%', sm: 300 },
                    mb: 0,
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {description && (
            <Box mt={3}>
              <TranslationResolver en={description.en} zh={description.zh} es={description.es} parseHtml={true} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
