import { handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { toObjectMap } from '../../../shared/store/reducer-utils';
import { DefaultWorkspaceMembersCollection, WorkspaceMembersCollection } from '@priz/shared/src/models/workspace';
import { IWorkspaceMember, WorkspaceMember } from '@priz/shared/src/models/workspace/workspace-member';
import { WorkspaceMembersAction, WorkspaceMembersActionType } from '../actions';
import {
  NewProjectAssignmentResponse,
  ProjectAssignmentAction,
  ProjectAssignmentActionType,
} from '../../../assignment/store/actions';
import { AxiosError } from 'axios';

const workspaceMemberInitializer = (workspaceMember: IWorkspaceMember) => new WorkspaceMember(workspaceMember);

const setStatuses = (
  state: WorkspaceMembersCollection,
  statuses: Partial<EntityCollectionStatus>,
): WorkspaceMembersCollection => ({
  ...state,
  statuses: {
    ...state.statuses,
    ...statuses,
  },
});

const replaceAllWorkspaces = (
  state: WorkspaceMembersCollection,
  workspaceMembers: IWorkspaceMember[],
  statuses: Partial<EntityCollectionStatus>,
): WorkspaceMembersCollection => {
  const entityMap = toObjectMap<WorkspaceMember>(workspaceMembers, workspaceMemberInitializer);
  const statusesCopy = {
    ...state.statuses,
    ...statuses,
  };

  return {
    ...state,
    entities: entityMap,
    statuses: statusesCopy,
  };
};

const setWorkspaceMember = (
  state: WorkspaceMembersCollection,
  workspaceMember: IWorkspaceMember,
  statuses: Partial<EntityCollectionStatus>,
): WorkspaceMembersCollection => {
  const member = workspaceMemberInitializer(workspaceMember);

  const entityMap = {
    ...state.entities,
    [member.id]: member,
  };

  const statusesCopy = {
    ...state.statuses,
    ...statuses,
  };

  return {
    ...state,
    entities: entityMap,
    statuses: statusesCopy,
  };
};

const removeWorkspaceMember = (
  state: WorkspaceMembersCollection,
  memberEmail: string,
  statuses: Partial<EntityCollectionStatus>,
): WorkspaceMembersCollection => {
  const member = Object.values(state.entities).find(m => m.email === memberEmail);

  if (member) {
    const entitiesCopy = { ...state.entities };
    delete entitiesCopy[member.id];

    const statusesCopy = {
      ...state.statuses,
      ...statuses,
    };

    return {
      ...state,
      entities: entitiesCopy,
      statuses: statusesCopy,
    };
  } else {
    return state;
  }
};

export const workspaceMembersReducers = handleActions(
  {
    // FetchAll

    [toStarted(WorkspaceMembersActionType.FetchAll)]: (state: WorkspaceMembersCollection) =>
      setStatuses(state, { loading: true, error: false }),

    [toFailed(WorkspaceMembersActionType.FetchAll)]: (state: WorkspaceMembersCollection) =>
      setStatuses(state, { loading: false, error: true }),

    [toSuccess(WorkspaceMembersActionType.FetchAll)]: (
      state: WorkspaceMembersCollection,
      action: WorkspaceMembersAction,
    ) =>
      replaceAllWorkspaces(state, action.payload as IWorkspaceMember[], { loading: false, loaded: true, error: false }),

    // Invite

    [toStarted(WorkspaceMembersActionType.Invite)]: (state: WorkspaceMembersCollection) =>
      setStatuses(state, { creating: true, created: false, error: false, errorCode: undefined }),

    [toFailed(WorkspaceMembersActionType.Invite)]: (
      state: WorkspaceMembersCollection,
      action: WorkspaceMembersAction,
    ) => {
      const error = action.payload as AxiosError;

      return setStatuses(state, { creating: false, created: false, error: true, errorCode: error?.response?.status });
    },

    [toSuccess(WorkspaceMembersActionType.Invite)]: (
      state: WorkspaceMembersCollection,
      action: WorkspaceMembersAction,
    ) =>
      setWorkspaceMember(state, action.payload as IWorkspaceMember, {
        creating: false,
        created: true,
        error: false,
        errorCode: undefined,
      }),

    // Create

    [toStarted(ProjectAssignmentActionType.Create)]: (state: WorkspaceMembersCollection) =>
      setStatuses(state, { creating: true, created: false, error: false, errorCode: undefined }),

    [toFailed(ProjectAssignmentActionType.Create)]: (
      state: WorkspaceMembersCollection,
      action: WorkspaceMembersAction,
    ) => {
      const error = action.payload as AxiosError;

      return setStatuses(state, { creating: false, created: false, error: true, errorCode: error?.response?.status });
    },

    [toSuccess(ProjectAssignmentActionType.Create)]: (
      state: WorkspaceMembersCollection,
      action: ProjectAssignmentAction,
    ): WorkspaceMembersCollection =>
      setWorkspaceMember(state, (action.payload as NewProjectAssignmentResponse).member, {
        creating: false,
        created: true,
        error: false,
        errorCode: undefined,
      }),

    // Remove

    [toStarted(WorkspaceMembersActionType.Remove)]: (state: WorkspaceMembersCollection) =>
      setStatuses(state, { removing: true, removed: false, error: false }),

    [toFailed(WorkspaceMembersActionType.Remove)]: (state: WorkspaceMembersCollection) =>
      setStatuses(state, { removing: false, removed: false, error: true }),

    [toSuccess(WorkspaceMembersActionType.Remove)]: (
      state: WorkspaceMembersCollection,
      action: WorkspaceMembersAction,
    ) => removeWorkspaceMember(state, action.meta.email, { removing: false, removed: true, error: false }),

    // UpdateMembershipLevel

    [toStarted(WorkspaceMembersActionType.UpdateMembershipLevel)]: (state: WorkspaceMembersCollection) =>
      setStatuses(state, { updating: true, updated: false, error: false }),

    [toFailed(WorkspaceMembersActionType.UpdateMembershipLevel)]: (state: WorkspaceMembersCollection) =>
      setStatuses(state, { updating: false, updated: false, error: true }),

    [toSuccess(WorkspaceMembersActionType.UpdateMembershipLevel)]: (
      state: WorkspaceMembersCollection,
      action: WorkspaceMembersAction,
    ) =>
      setWorkspaceMember(state, action.payload as IWorkspaceMember, { updating: false, updated: true, error: false }),

    // clearErrors

    [toSuccess(WorkspaceMembersActionType.ClearErrors)]: (state: WorkspaceMembersCollection) =>
      setStatuses(state, { error: false, errorCode: undefined }),
  },
  DefaultWorkspaceMembersCollection,
);
