import { Perception, PMapData, PMapEdge, PMapNode } from '../../models/tools/pmap';

export const constructPerceptionMapNetworkData = (perceptions: Perception[]): PMapData => {
  const nodes: PMapNode[] = [];
  const edges: PMapEdge[] = [];

  for (let i = 0; i < perceptions.length; i++) {
    const { letter, assign, description } = perceptions[i] || {};
    const nodeData = <PMapNode>{
      id: letter,
      label: letter,
    };

    if (description) nodeData.title = description;

    nodes.push(nodeData);

    if (assign) {
      edges.push({ id: `${letter}-${assign}`, from: letter, to: assign });
    }
  }

  return {
    nodes,
    edges,
  };
};
