import { createStyles, makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Theme } from '@mui/material';

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: drawerWidth,
      flexShrink: 0,
      transition: 'width .3s ease',
      zIndex: 990,
      height: '100%',
    },
    drawerRoot: {
      height: '100%',
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: pgColorScheme.darkGray,
      color: 'rgba(255,255,255,0.4)',
      overflowX: 'hidden',
      marginLeft: 0,
      transition: 'width .3s ease, margin-left .3s ease',
      borderRight: 'none',
      boxSizing: 'border-box',
      position: 'relative',
    },
    openMenuButton: {
      'top': 80,
      'left': '100%',
      'right': 'auto',
      'width': 20,
      'bottom': 'auto',
      'height': 50,
      'position': 'absolute',
      'background': pgColorScheme.darkGray,
      'boxShadow': '1px 1px 3px rgba(60,60,60,.5)',
      'borderRadius': '0 4px 4px 0',

      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'center',
      'transition': 'left .3s ease',

      '& svg': {
        display: 'block',
        width: 6,
        fill: pgColorScheme.white,
        opacity: 0.3,
        transition: 'transform .3s ease',
        transformOrigin: '50% 50%',
      },

      '&._open svg': {
        transform: 'scaleX(-1)',
      },
    },
    logoWrapper: {
      boxSizing: 'border-box',
      padding: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...theme.mixins.toolbar,
    },
    logo: {
      height: 40,
      width: 'auto',
      maxWidth: '100%',
      cursor: 'pointer',
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },

    [theme.breakpoints.only('md')]: {
      root: {
        '&:not(._open)': {
          width: 56,
        },
      },
      drawerPaper: {
        '&:not(._open)': {
          width: 56,
        },
      },
    },

    [theme.breakpoints.only('sm')]: {
      root: {
        width: 56,
      },
      drawerPaper: {
        'width': 56,

        '&._open': {
          width: 240,
        },
      },
      openMenuButton: {
        '&._open': {
          left: 240,
        },
      },
    },

    [theme.breakpoints.only('xs')]: {
      root: {
        width: 0,
      },
      drawerPaper: {
        'paddingTop': 64,

        '&:not(._open)': {
          marginLeft: -240,
        },
      },
      openMenuButton: {
        '&._open': {
          left: 240,
        },
      },
    },
  }),
);
