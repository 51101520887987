import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PlatformThemeProvider } from './services/platform-theme-provider';
import { VersionChecker } from './version-checker/component';
import { Notificator } from './react/modules/notification/component';
import { LocalizationService } from '@priz/shared/src/services/localization';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import store from './store/store.module';
import { TimeZoneService } from '@priz/shared/src/services/time';
import { AxiosConfig } from '@priz/shared/src/services/axios';
import { withTranslation } from 'react-i18next';
import { ProfileCompletionDialog } from './user-profile/profile-completion-dialog/component';
import { SubscriptionNotificationLine } from './billing/subscription-notification-line/component';
import { useFlagsStatus, useUnleashClient, useUnleashContext } from '@unleash/proxy-client-react';
import { UserSelectors } from './user/store/selectors/user.selectors';
import { Policies } from './policies/component';
import { PendoService } from '@priz/shared/src/services/pendo/pendo.service';
import { IntercomService } from '@priz/shared/src/services/intercom/intercom.service';
import { WorkspaceSelectors } from './workspace/store/selectors';
import { UserProperties } from '@priz/shared/src/services/analytics/model/user-properties';
import { UserSettingsSelectors } from './user/store/selectors/user-settings.selectors';
import { UserContextSelectors } from './security/store/selectors';
import { WorkspaceActions } from './workspace/store/actions';
import { AuthService } from '@priz/shared/src/services/auth';
import { UserContextActions } from './security/store/actions';
import { AuthFormType } from '@priz/shared/src/models/auth0';
import { AppActions } from './store/app.actions';
import { AuthWatcher } from '@priz/shared/src/components/auth-watcher/component';

AxiosConfig.init();
LocalizationService.init();
AnalyticsService.init();
TimeZoneService.initLocale();

const queryClient = new QueryClient();

export const AppProvider: React.FC = ({ children }) => {
  return (
    <PlatformThemeProvider>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      </Provider>
    </PlatformThemeProvider>
  );
};

const AnalyticsHelper: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    AnalyticsService.trackPageView(pathname);
  }, [pathname]);

  return null;
};

const UnleashHelper: React.FC = () => {
  const client = useUnleashClient();
  const updateContext = useUnleashContext();
  const { flagsReady } = useFlagsStatus();

  const currentUser = useSelector(UserSelectors.currentUserSelector);

  useEffect(() => {
    const contextUserId = client?.getContext()?.userId;

    if (flagsReady && currentUser && contextUserId !== currentUser.email) {
      void updateContext({ userId: currentUser.email });
    }
  }, [flagsReady, currentUser?.id]);

  return null;
};

const UserHelper: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentUser = useSelector(UserSelectors.currentUserSelector);

  useEffect(() => {
    if (currentUser?.profile?.timeZoneCode) {
      TimeZoneService.setTimeZone(currentUser.profile.timeZoneCode);
    }
  }, [currentUser?.profile?.timeZoneCode]);

  const onLoadUser = () => {
    dispatch(AppActions.reset());
    dispatch(UserContextActions.forceLoad(pathname, navigate, '/'));
  };

  const onResetUser = () => {
    AuthService.logout(AuthFormType.Login);
  };

  return <AuthWatcher onLoadUser={onLoadUser} onResetUser={onResetUser} />;
};

const PendoHelper: React.FC = () => {
  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const currentWorkspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const settings = useSelector(UserSettingsSelectors.settings);

  const isProfileCompleted = settings?.global?.userProfileCompleted;
  const arePoliciesAccepted = !!currentUser?.policiesAcceptedAt;

  useEffect(() => {
    if (currentUser && currentWorkspace && isProfileCompleted && arePoliciesAccepted) {
      const properties: UserProperties = {
        id: currentUser.id,
        email: currentUser.email,
        createdDate: currentUser.dateCreated,
        firstName: currentUser.profile.firstName,
        lastName: currentUser.profile.lastName,
        organization: currentUser.profile.organization,
        location: currentUser.profile.location,
        selectedWorkspaceId: currentWorkspace.id,
        planLevel: currentWorkspace.type.toString(),
      };

      PendoService.initialize(properties);
    }
  }, [currentUser?.id, currentWorkspace?.id, isProfileCompleted, arePoliciesAccepted]);

  return null;
};

const IntercomHelper: React.FC = () => {
  const { pathname } = useLocation();

  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const allWorkspaces = useSelector(WorkspaceSelectors.getAll());
  const currentWorkspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const settings = useSelector(UserSettingsSelectors.settings);

  const isProfileCompleted = settings?.global?.userProfileCompleted;
  const arePoliciesAccepted = !!currentUser?.policiesAcceptedAt;

  useEffect(() => {
    if (currentUser && currentWorkspace && isProfileCompleted && arePoliciesAccepted) {
      IntercomService.boot({
        user: currentUser,
        workspaces: allWorkspaces,
      });
    }
  }, [currentUser?.id, currentWorkspace?.id, isProfileCompleted, arePoliciesAccepted]);

  useEffect(() => {
    IntercomService.refresh();
  }, [pathname]);

  return null;
};

const PlanFeaturesHelper: React.FC = () => {
  const dispatch = useDispatch();

  const isMfaValid = useSelector(WorkspaceSelectors.isCurrentUserSatisfyWorkspaceMfa);
  const currentUser = useSelector(UserContextSelectors.getCurrentUser);
  const isUserContextLoaded = useSelector(UserContextSelectors.isLoaded);

  useEffect(() => {
    if (isMfaValid && isUserContextLoaded) {
      dispatch(WorkspaceActions.fetchFeatureSet());
    }
  }, [isMfaValid, isUserContextLoaded, currentUser?.id]);

  return null;
};

function App() {
  useEffect(() => {
    const appLoaderElement = document.getElementById('app-loader');

    if (appLoaderElement) {
      appLoaderElement.classList.add('_hidden');
    }
  }, []);

  return (
    <AppProvider>
      <AnalyticsHelper />
      <UserHelper />
      <UnleashHelper />
      <SubscriptionNotificationLine />
      <Outlet />
      <VersionChecker />
      <Notificator />
      <ProfileCompletionDialog />
      <Policies />
      <PendoHelper />
      <IntercomHelper />
      <PlanFeaturesHelper />
    </AppProvider>
  );
}

export default withTranslation()(App);
