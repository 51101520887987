import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { pgTheme as PlatformPgTheme } from '../react/theme';
import { StyledEngineProvider } from '@mui/material/styles';

export const PlatformThemeProvider: React.FC = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={PlatformPgTheme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};
