import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BoxProps, DialogProps, PopperProps, Portal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AssistanceType, AssistantHintData, AssistantVariant } from './store/model';
import { AssistantActionProps, AssistantActions } from './store/actions';
import { AssistantSelectors } from './store/selectors';
import { DialogAssistant } from './dialog-assistant/component';
import { InlineAssistant, SharedInlineAssistantProps } from './inline-assistant/component';
import { PopperAssistant } from './popper-assistant/component';
import { HintRowType, SharedHintContentProps } from './hint-content/component';
import { AssistantButton, AssistantButtonBaseProps } from './assistant-button/components';
import { AssistantControlsBaseProps } from './assistant-controls/components';
import { ToolUtilizationSelector } from '../project/store/selectors';
import { CustomBlocker, PaywallActions } from '../react/modules/paywall/store';

export interface AssistantProps extends BoxProps {
  type: AssistanceType;
  projectId?: number;
  variant?: AssistantVariant;
  initialHintData?: AssistantHintData;
  actionProps?: AssistantActionProps;
  buttonProps?: AssistantButtonBaseProps;
  controlsProps?: AssistantControlsBaseProps;
  contentProps?: SharedHintContentProps;
  inlineAssistantProps?: SharedInlineAssistantProps;
  dialogAssistantProps?: Partial<DialogProps>;
  popperAssistantProps?: Partial<PopperProps>;
  assistantContainer?: HTMLDivElement;
  onGetHint?: () => void;
  onViewHint?: () => void;
  onHintLoad?: (hintData: AssistantHintData) => void;
  onUseHint?: (text: string[]) => void;
  disabled?: boolean;
}

const throwPropsError = (type: AssistanceType, text?: string) => {
  const errorText = [`Wrong props for "${type}" assistance type.`];

  if (text) errorText.push(text);

  throw new Error(errorText.join(' '));
};

const validateRequiredProps = (type: AssistanceType, props: { [key: string]: string | number }) => {
  Object.keys(props).forEach(key => {
    if (typeof props[key] === 'undefined') {
      throwPropsError(type, `Property "${key}" is required."`);
    }
  });
};

export const Assistant: React.FC<AssistantProps> = ({
  type,
  projectId,
  variant = AssistantVariant.Inline,
  initialHintData,
  actionProps,
  buttonProps,
  controlsProps,
  contentProps,
  inlineAssistantProps,
  dialogAssistantProps,
  popperAssistantProps,
  assistantContainer,
  onGetHint,
  onViewHint,
  onHintLoad,
  onUseHint,
  disabled,
}) => {
  const dispatch = useDispatch();

  const [assistantId] = useState(uuidv4());
  const [selectedHintOptions, setSelectedHintOptions] = useState<string[]>([]);
  const [useCallbackOnHintLoad, setUseCallbackOnHintLoad] = useState(false);
  const [buttonElement, setButtonElement] = useState<HTMLButtonElement>();

  const storageHintData = useSelector(AssistantSelectors.hintByAssistantId(assistantId));
  const isLoading = useSelector(AssistantSelectors.isLoadingByAssistantId(assistantId));
  const activeAssistantId = useSelector(AssistantSelectors.activeId);
  const isToolAssistanceLimitReached = useSelector(
    ToolUtilizationSelector.isAssistanceLimitReached(actionProps?.utilizationId),
  );

  const hintData = isLoading ? storageHintData : storageHintData || initialHintData;
  const isTextHintsType = contentProps?.hintRowsType ? contentProps.hintRowsType === HintRowType.Text : true;
  const isUseHintDisabled = !isTextHintsType && !selectedHintOptions.length;

  useEffect(() => {
    if (useCallbackOnHintLoad && !isLoading && storageHintData?.text && onHintLoad) {
      setUseCallbackOnHintLoad(false);
      onHintLoad(storageHintData);
    }
  }, [useCallbackOnHintLoad, isLoading, storageHintData]);

  const requestHint = () => {
    const { utilizationId, causeId, visNodeId, apaActionId, ideaId, ideaParameterType } = actionProps || {};

    switch (type) {
      case AssistanceType.AskProjectDescriptionFeedback:
        validateRequiredProps(type, { projectId });
        dispatch(AssistantActions.getProjectDescriptionFeedbackHint(projectId, type, assistantId));
        break;

      case AssistanceType.AskCurrentSituationFeedback:
        validateRequiredProps(type, { projectId });
        dispatch(AssistantActions.getCurrentSituationFeedback(projectId, type, assistantId));
        break;

      case AssistanceType.AskDisadvantagesFeedback:
        validateRequiredProps(type, { projectId });
        dispatch(AssistantActions.getDisadvantagesFeedback(projectId, type, assistantId));
        break;

      case AssistanceType.AskIdealFinalResult:
        validateRequiredProps(type, { projectId });
        dispatch(AssistantActions.getIdealFinalResultHint(projectId, type, assistantId));
        break;

      case AssistanceType.AskIdealFinalResultFeedback:
        validateRequiredProps(type, { projectId });
        dispatch(AssistantActions.getIdealFinalResultFeedback(projectId, type, assistantId));
        break;

      case AssistanceType.AskGaps:
        validateRequiredProps(type, { projectId });
        dispatch(AssistantActions.getGapsHint(projectId, type, assistantId));
        break;

      case AssistanceType.AskGapsFeedback:
        validateRequiredProps(type, { projectId });
        dispatch(AssistantActions.getGapsFeedback(projectId, type, assistantId));
        break;

      case AssistanceType.AskProblemStatementFeedback:
        validateRequiredProps(type, { projectId });
        dispatch(AssistantActions.getProblemStatementFeedback(projectId, type, assistantId));
        break;

      case AssistanceType.AskCurrentSituation:
        validateRequiredProps(type, { projectId });
        dispatch(AssistantActions.getCurrentSituationHint(projectId, type, assistantId));
        break;

      case AssistanceType.AskDisadvantages:
        validateRequiredProps(type, { projectId });
        dispatch(AssistantActions.getDisadvantagesHint(projectId, type, assistantId));
        break;

      case AssistanceType.AskProblemStatementHint:
        validateRequiredProps(type, { projectId });
        dispatch(AssistantActions.getProblemStatementHint(projectId, type, assistantId));
        break;

      case AssistanceType.AskIdeaParameterHint:
        validateRequiredProps(type, { projectId, ideaId, ideaParameterType });
        dispatch(
          AssistantActions.getAssistantIdeaParameterHint(projectId, ideaId, ideaParameterType, type, assistantId),
        );
        break;

      // tools

      case AssistanceType.AskFiveWhysCause:
        validateRequiredProps(type, { utilizationId, causeId });
        dispatch(AssistantActions.getFiveWhysCauseHint(utilizationId, causeId, type, assistantId));
        break;

      case AssistanceType.AskFiveWhysCauseSolution:
        validateRequiredProps(type, { utilizationId, causeId });
        dispatch(AssistantActions.getFiveWhysCauseSolutionHint(utilizationId, causeId, type, assistantId));
        break;

      case AssistanceType.AskCecCause:
        validateRequiredProps(type, { utilizationId, visNodeId });
        dispatch(AssistantActions.getCecCauseHint(utilizationId, visNodeId, type, assistantId));
        break;

      case AssistanceType.AskApaCustomerDescription:
        validateRequiredProps(type, { utilizationId });
        dispatch(AssistantActions.getApaCustomerDescriptionHint(utilizationId, type, assistantId));
        break;

      case AssistanceType.AskApaCustomerActions:
        validateRequiredProps(type, { utilizationId });
        dispatch(AssistantActions.getApaCustomerActionsHint(utilizationId, type, assistantId));
        break;

      case AssistanceType.AskApaActionPurpose:
        validateRequiredProps(type, { utilizationId, apaActionId });
        dispatch(AssistantActions.getApaActionPurposeHint(utilizationId, apaActionId, type, assistantId));
        break;

      case AssistanceType.AskApaPreventingAction:
        validateRequiredProps(type, { utilizationId, apaActionId });
        dispatch(AssistantActions.getApaPreventingActionHint(utilizationId, apaActionId, type, assistantId));
        break;

      case AssistanceType.AskApaCustomerNeeds:
        validateRequiredProps(type, { utilizationId, apaActionId });
        dispatch(AssistantActions.getApaCustomerNeedsHint(utilizationId, apaActionId, type, assistantId));
        break;

      case AssistanceType.AskToolSuggestionsHint:
        throw new Error(`Assistant type "${AssistanceType.AskToolSuggestionsHint}" is not supported."`);
    }

    if (onHintLoad) {
      setUseCallbackOnHintLoad(true);
    }
  };

  const openAssistant = () => {
    dispatch(AssistantActions.setActiveAssistant(assistantId));
  };

  const closeAssistant = () => {
    dispatch(AssistantActions.setActiveAssistant(null));
  };

  const openHandler = (anchor: HTMLButtonElement, request = false) => {
    const preventByToolLimit = actionProps?.utilizationId && isToolAssistanceLimitReached;
    const prevent = disabled || (actionProps?.utilizationId && isToolAssistanceLimitReached);

    if (!prevent) {
      setButtonElement(anchor);

      if (!request && onViewHint) {
        onViewHint();
      }

      if (request && onGetHint) {
        onGetHint();
      }

      if (request && !isLoading) {
        requestHint();
      }

      openAssistant();
    }

    if (preventByToolLimit) {
      closeAssistant();
      dispatch(PaywallActions.show(CustomBlocker.AssistanceLimit));
    }
  };

  const useHintHandler = () => {
    if ([HintRowType.Checkbox, HintRowType.Radio].includes(contentProps?.hintRowsType)) {
      onUseHint(selectedHintOptions.map(item => item.trim()));
    } else {
      onUseHint([hintData?.text?.trim() || '']);
    }

    closeAssistant();
  };

  const hitOptionsSelectHandler = (options: string[]) => {
    setSelectedHintOptions(options);
  };

  return (
    <>
      <AssistantButton
        hintData={hintData}
        onGetHintClick={r => openHandler(r, true)}
        onViewHintClick={r => openHandler(r)}
        disabled={disabled}
        {...(buttonProps || {})}
      />

      <Portal container={assistantContainer} disablePortal={!assistantContainer}>
        {variant === AssistantVariant.Dialog && (
          <DialogAssistant
            open={activeAssistantId === assistantId}
            loading={isLoading}
            hintData={hintData}
            onSelectHintOptions={hitOptionsSelectHandler}
            contentProps={contentProps}
            controlsProps={{
              onUseHint: onUseHint ? useHintHandler : undefined,
              onClose: closeAssistant,
              onRetry: requestHint,
              retryDisabled: disabled,
              useHintDisabled: isUseHintDisabled,
              ...controlsProps,
            }}
            {...dialogAssistantProps}
          />
        )}

        {variant === AssistantVariant.Inline && (
          <InlineAssistant
            open={activeAssistantId === assistantId}
            loading={isLoading}
            hintData={hintData}
            onSelectHintOptions={hitOptionsSelectHandler}
            contentProps={contentProps}
            controlsProps={{
              onUseHint: onUseHint ? useHintHandler : undefined,
              onClose: closeAssistant,
              onRetry: requestHint,
              retryDisabled: disabled,
              useHintDisabled: isUseHintDisabled,
              ...controlsProps,
            }}
            {...inlineAssistantProps}
          />
        )}

        {variant === AssistantVariant.Popper && (popperAssistantProps?.anchorEl || buttonElement) && (
          <PopperAssistant
            open={activeAssistantId === assistantId}
            loading={isLoading}
            hintData={hintData}
            onSelectHintOptions={hitOptionsSelectHandler}
            contentProps={contentProps}
            controlsProps={{
              onUseHint: onUseHint ? useHintHandler : undefined,
              onClose: closeAssistant,
              onRetry: requestHint,
              retryDisabled: disabled,
              useHintDisabled: isUseHintDisabled,
              ...controlsProps,
            }}
            {...popperAssistantProps}
            anchorEl={popperAssistantProps?.anchorEl || buttonElement}
          />
        )}
      </Portal>
    </>
  );
};
