import React, { useState } from 'react';
import { Idea } from '@priz/shared/src/models/idea';
import { useSelector } from 'react-redux';
import { ProjectAssigneeSelectors } from '../../../assignment/store/selectors/project-assignee.selectors';
import { DashboardItemCard } from '../../dashboard-item-card/component';
import { useStyles } from '../../styles';
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip } from '@mui/material';
import { Trans } from 'react-i18next';
import { QuillContentViewer } from '@priz/shared/src/components/quill-content-viewer/component';
import { UserUtils } from '@priz/shared/src/utils/user/user-utils';
import { VisibilityOutlined } from '@mui/icons-material';
import { Status } from '@priz/shared/src/components/status/component';

interface DashboardIdeaProps {
  idea: Idea;
}

export const DashboardIdea: React.FC<DashboardIdeaProps> = ({ idea }) => {
  const styles = useStyles();

  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const assigneesMap = useSelector(ProjectAssigneeSelectors.getAllAssigneesMap);

  const user = assigneesMap[idea.createdBy.id]?.user;

  const openViewer = () => {
    setIsViewerOpen(true);
  };

  const closeViewer = () => {
    setIsViewerOpen(false);
  };

  return (
    <DashboardItemCard
      text={idea.content}
      path={['/project', idea.project.id, 'ideas']}
      hash={`#idea-${idea.id}`}
      controls={
        <Grid container alignItems={'center'} spacing={1}>
          {user && (
            <Grid item>
              <Tooltip title={UserUtils.getShowableName(user)} arrow={true} placement={'top'} disableInteractive={true}>
                <Avatar src={user.profile.avatarUrl} sx={{ width: 24, height: 24 }}>
                  {UserUtils.resolveInitials(user)}
                </Avatar>
              </Tooltip>
            </Grid>
          )}

          <Grid item>
            <Status status={idea.status} size={24} showLabel={false} showTooltip={true} />
          </Grid>

          <Grid item>
            <Tooltip title={<Trans>Overview</Trans>} arrow={true} placement={'top'} disableInteractive={true}>
              <VisibilityOutlined
                className={[styles.controlIcon, styles.interactiveControlIcon].join(' ')}
                onClick={openViewer}
              />
            </Tooltip>

            <Dialog open={isViewerOpen} onClose={closeViewer} fullWidth={true} maxWidth={'md'}>
              <DialogTitle>
                <Trans>Idea overview</Trans>
              </DialogTitle>

              <DialogContent>
                <QuillContentViewer content={idea.content} />
              </DialogContent>

              <DialogActions>
                <Box textAlign={'right'}>
                  <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={closeViewer}>
                    <Trans>Close</Trans>
                  </Button>
                </Box>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      }
    />
  );
};
