import { UserProperties } from '../../model/user-properties';

type TrackingCodeProps = { [key: string]: string | number | boolean | Date | undefined };
type CustomBehavioralEventProps = { name: string; properties: TrackingCodeProps };
type TrackingCode = [name: string, props?: string | TrackingCodeProps | CustomBehavioralEventProps];

declare global {
  interface Window {
    _hsq: TrackingCode[];
  }
}

const init = (): void => {
  return;
};

const setUserIdentity = (_identity: string): void => {
  return;
};

const setUserProperties = (properties: UserProperties): void => {
  if (typeof window?._hsq !== 'undefined') {
    const propertyMap = {
      id: properties.id,
      email: properties.email,
      firstname: properties.firstName,
      lastname: properties.lastName,
      organization: properties.organization,
      location: properties.location,
    };

    window._hsq.push(['identify', propertyMap]);
    window._hsq.push(['trackPageView']);
  }
};

const track = (event: string, parameters: TrackingCodeProps): Promise<any> => {
  if (typeof window?._hsq !== 'undefined') {
    window._hsq.push([
      'trackCustomBehavioralEvent',
      {
        name: event,
        properties: parameters,
      },
    ]);
  }

  return new Promise(resolve => resolve({}));
};

const trackPageView = (page: string): void => {
  if (typeof window?._hsq !== 'undefined') {
    window._hsq.push(['setPath', page]);
    window._hsq.push(['trackPageView']);
  }
};

export const HubspotService = {
  init,
  setUserIdentity,
  setUserProperties,
  track,
  trackPageView,
};
