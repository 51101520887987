import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      zIndex: 2,
    },

    dialogContent: {
      zIndex: 2,
    },

    dialogBackground: {
      position: 'absolute',
      bottom: 0,
      left: 10,
      top: 'auto',
      right: 'auto',
      width: '50%',
      maxWidth: 400,
      height: 'auto',
      display: 'block',
      zIndex: 1,
    },

    [theme.breakpoints.up('sm')]: {
      dialogContent: {
        paddingLeft: '40%',
      },
    },

    [theme.breakpoints.down('sm')]: {
      dialogBackground: {
        position: 'relative',
        left: 'auto',
        margin: 'auto',
      },
    },
  }),
);
