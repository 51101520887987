import * as React from "react";

export const ReactComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="#3C3C3C" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M16 5.35449C15.448 5.35449 15 5.80249 15 6.35449V9.45581C12.1294 9.89307 9.87207 12.1504 9.43457 15.021H6.33325C5.78125 15.021 5.33325 15.469 5.33325 16.021C5.33325 16.573 5.78125 17.021 6.33325 17.021H9.43457C9.87207 19.8918 12.1294 22.1492 15 22.5864V25.6877C15 26.2397 15.448 26.6877 16 26.6877C16.552 26.6877 17 26.2397 17 25.6877V22.5864C19.8706 22.1492 22.1279 19.8918 22.5654 17.021H25.6667C26.2188 17.021 26.6667 16.573 26.6667 16.021C26.6667 15.469 26.2188 15.021 25.6667 15.021H22.5654C22.1279 12.1504 19.8706 9.89307 17 9.45581V6.35449C17 5.80249 16.552 5.35449 16 5.35449V5.35449ZM20.6667 16.021C20.6667 18.5945 18.5732 20.6877 16 20.6877C13.4268 20.6877 11.3333 18.5945 11.3333 16.021C11.3333 13.4478 13.4268 11.3545 16 11.3545C18.5732 11.3545 20.6667 13.4478 20.6667 16.021Z" />
    <path d="M1 9.35425C1.552 9.35425 2 8.90625 2 8.35425V6.35425C2 6.0769 2.06396 4.6875 3.33325 4.6875H5.66675C6.21875 4.6875 6.66675 4.2395 6.66675 3.6875C6.66675 3.1355 6.21875 2.6875 5.66675 2.6875H3.33325C1.40259 2.6875 0 4.23022 0 6.35425V8.35425C0 8.90625 0.447998 9.35425 1 9.35425Z" />
    <path d="M28.3333 2.6875H26.3333C25.7812 2.6875 25.3333 3.1355 25.3333 3.6875C25.3333 4.2395 25.7812 4.6875 26.3333 4.6875H28.3333C29.252 4.6875 30 5.43555 30 6.35425V8.35425C30 8.90625 30.448 9.35425 31 9.35425C31.552 9.35425 32 8.90625 32 8.35425V6.35425C32 4.33154 30.356 2.6875 28.3333 2.6875V2.6875Z" />
    <path d="M3.33325 29.3542H5.66675C6.21875 29.3542 6.66675 28.9062 6.66675 28.3542C6.66675 27.8022 6.21875 27.3542 5.66675 27.3542H3.33325C2.06396 27.3542 2 25.9648 2 25.6875V23.6875C2 23.1355 1.552 22.6875 1 22.6875C0.447998 22.6875 0 23.1355 0 23.6875V25.6875C0 27.8115 1.40259 29.3542 3.33325 29.3542V29.3542Z" />
    <path d="M31 22.6875C30.448 22.6875 30 23.1355 30 23.6875V25.6875C30 26.6062 29.252 27.3542 28.3333 27.3542H26.3333C25.7812 27.3542 25.3333 27.8022 25.3333 28.3542C25.3333 28.9062 25.7812 29.3542 26.3333 29.3542H28.3333C30.356 29.3542 32 27.7102 32 25.6875V23.6875C32 23.1355 31.552 22.6875 31 22.6875Z" />
  </svg>
);
