import React from 'react';
import { OptionalComponentRankMap } from '../../models/tools/sfm';
import { Box } from '@mui/material';
import { getMaxRanks } from '../../utils/sfm';
import { SfmRankBars, SfmRankBarsBaseProps } from '../sfm-rank-bars/component';

interface SfmRelativeRankBarsProps extends SfmRankBarsBaseProps {
  componentId: string;
  componentsRanksMap: OptionalComponentRankMap;
  showFunctionalBar?: boolean;
  showProblematicBar?: boolean;
  rankHelperRenderer?: (componentId: string) => React.ReactNode;
  spacing?: number;
  backdrop?: boolean;
}

export const SfmRelativeRankBars: React.FC<SfmRelativeRankBarsProps> = ({
  componentId,
  componentsRanksMap,
  rankHelperRenderer,
  ...rest
}) => {
  const componentsRank = componentsRanksMap[componentId];
  const { functionalRank = 0, problematicRank = 0 } = componentsRank || {};
  const { maxFunctionalRank, maxProblematicRank } = getMaxRanks(componentsRanksMap);
  const functionalBarWidth = functionalRank && maxFunctionalRank ? functionalRank / maxFunctionalRank : 0;
  const problematicBarWidth = problematicRank && maxProblematicRank ? problematicRank / maxProblematicRank : 0;
  const maxBarWidth = Math.max(functionalBarWidth, problematicBarWidth);

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
      <div style={{ width: `${rankHelperRenderer ? maxBarWidth * 50 : 100}%` }}>
        <SfmRankBars
          componentRank={componentsRank}
          functionalBarWidthMultiplier={maxFunctionalRank * (rankHelperRenderer ? maxBarWidth : 1)}
          problematicBarWidthMultiplier={maxProblematicRank * (rankHelperRenderer ? maxBarWidth : 1)}
          {...rest}
        />
      </div>

      {rankHelperRenderer && (
        <div style={{ maxWidth: `${100 - maxBarWidth * 50}%` }}>
          <Box pl={2}>{rankHelperRenderer(componentId)}</Box>
        </div>
      )}
    </Box>
  );
};
