import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans, useTranslation } from 'react-i18next';
import { UserContextSelectors } from '../../security/store/selectors';
import { UserActions } from '../../user/store/actions/user.actions';
import { Box, Link } from '@mui/material';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { ReactHookFormText, FormResetButton, ReactHookFormSelectSearch } from '../../react/form-elements';
import { LoadingButton } from '@priz/shared/src/components/loading-button/component';
import { useForm } from 'react-hook-form';
import { User } from '@priz/shared/src/models/security/user';
import { Alert } from '@mui/material';
import { differenceInMinutes, formatDistance } from 'date-fns';
import { TimeZoneService } from '@priz/shared/src/services/time';
import { ReactHookFormPhoneInput } from '../../react/form-elements/phone/component';

const getUserFormData = (user: User) => {
  return {
    title: user?.profile?.title || '',
    firstName: user?.profile?.firstName || '',
    lastName: user?.profile?.lastName || '',
    organization: user?.profile?.organization || '',
    location: user?.profile?.location || '',
    phoneNumber: user?.profile?.phoneNumber || '',
    email: user?.email || '',
    timeZoneCode: user?.profile?.timeZoneCode || TimeZoneService.getLocalTimeZone().code,
  };
};

export const PersonalInformation: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [emailSentRecently, setEmailSentRecently] = useState<boolean>(false);
  const [, setTick] = useState<number>();

  const user = useSelector(UserContextSelectors.getCurrentUser);
  const isLoaded = useSelector(UserContextSelectors.isLoaded);
  const isLoading = useSelector(UserContextSelectors.isLoading);
  const isProfileUpdating = useSelector(UserSelectors.isProfileUpdating);
  const isVerificationEmailSending = useSelector(UserSelectors.isVerificationEmailSending);
  const disabled = !isLoaded || isProfileUpdating;

  const { handleSubmit, control, reset } = useForm({
    shouldFocusError: false,
    defaultValues: getUserFormData(user),
  });

  const doSetEmailSentRecently = (user: User, resetTick = false) => {
    if (!user.emailVerified && user?.verificationEmailSentAt) {
      if (resetTick) {
        setTick(Math.random());
      }
      const diff = differenceInMinutes(new Date(), user.verificationEmailSentAt);
      setEmailSentRecently(diff < 10);
    }
  };

  useEffect(() => {
    if (isLoaded && !isLoading && user?.profile) {
      reset(getUserFormData(user));
    }

    doSetEmailSentRecently(user);

    const interval = setInterval(() => {
      doSetEmailSentRecently(user, true);
    }, 1000);
    return () => clearInterval(interval);
  }, [user]);

  const submitHandler = () => {
    handleSubmit(formData => {
      dispatch(
        UserActions.updateCurrentUserProfile({
          ...user.profile,
          title: formData.title,
          firstName: formData.firstName,
          lastName: formData.lastName,
          organization: formData.organization,
          location: formData.location,
          timeZoneCode: formData.timeZoneCode,
          phoneNumber: formData.phoneNumber,
        }),
      );
    })();
  };

  const handleRequestEmailVerification = () => dispatch(UserActions.requestVerificationEmail());

  if (!user) {
    return null;
  }

  return (
    <>
      <PageTitleWithDocLink title={<Trans>Personal Information</Trans>} />

      <Box pt={3} maxWidth={655}>
        <ReactHookFormText
          fieldTitle={'Email'}
          name={'email'}
          helperText={'At this point you are not able to update the email.'}
          control={control}
          disabled={true}
          wrapperProps={{ mb: user.emailVerified ? 3 : 0 }}
        />
        {user.emailVerified !== true && (
          <Box mb={3}>
            <Alert severity={'warning'}>
              <Trans>This email is not verified.</Trans>{' '}
              {emailSentRecently ? (
                <Trans>
                  Verification email was sent{' '}
                  {formatDistance(user.verificationEmailSentAt, new Date(), {
                    includeSeconds: true,
                    addSuffix: true,
                  })}
                  .
                </Trans>
              ) : (
                <>
                  {isVerificationEmailSending ? (
                    <Trans>Sending the email ...</Trans>
                  ) : (
                    <Link
                      component={'span'}
                      color={'primary'}
                      onClick={handleRequestEmailVerification}
                      underline={'hover'}
                      style={{ cursor: 'pointer' }}
                    >
                      <Trans>Send verification email.</Trans>
                    </Link>
                  )}
                </>
              )}
            </Alert>
          </Box>
        )}

        <ReactHookFormText
          fieldTitle={'Title'}
          name={'title'}
          placeholder={'e.g. Mr., Mrs., Dr.'}
          helperText={'This is the title that will be used in all official documents generated by the system.'}
          control={control}
          disabled={disabled}
        />

        <ReactHookFormText
          fieldTitle={'First name'}
          name={'firstName'}
          helperText={'The name is used for all official documentation generated by the system.'}
          control={control}
          disabled={disabled}
        />

        <ReactHookFormText
          fieldTitle={'Last name'}
          name={'lastName'}
          helperText={'The name is used for all official documentation generated by the system.'}
          control={control}
          disabled={disabled}
        />

        <ReactHookFormText
          fieldTitle={'Organization'}
          name={'organization'}
          helperText={'The organization will be shown in your profile in PRIZHub'}
          control={control}
          disabled={disabled}
        />

        <ReactHookFormText
          fieldTitle={'Location (City, State, Country)'}
          name={'location'}
          helperText={'The location will be shown in your profile in PRIZHub'}
          control={control}
          disabled={disabled}
        />

        <ReactHookFormSelectSearch
          fieldTitle={`${t('Timezone')} (${t('Your local timezone is')} ${TimeZoneService.getLocalTimeZone().label})`}
          name={'timeZoneCode'}
          helperText={'Adapts dates both on PRIZGuru and PRIZHub'}
          control={control}
          disabled={disabled}
          options={TimeZoneService.getTimeZones().map(item => ({
            value: item.code,
            label: item.label,
          }))}
        />

        <ReactHookFormPhoneInput
          control={control}
          fieldTitle={'Phone number'}
          name={'phoneNumber'}
          disabled={isProfileUpdating}
          formatValidation
        />

        <ButtonsWrap>
          <FormResetButton disabled={disabled} onClick={reset} />
          <LoadingButton disabled={disabled} loading={isProfileUpdating} onClick={submitHandler} />
        </ButtonsWrap>
      </Box>
    </>
  );
};
