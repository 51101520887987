import React from 'react';
import { Box, Grid, Paper, PaperProps, Typography } from '@mui/material';
import { RankingScore } from '../ranking-score/component';
import { useStyles } from './styles';
import { QuillContentViewer } from '../quill-content-viewer/component';
import { BoxProps } from '@mui/material';
import { format } from 'date-fns';
import { FieldTitle } from '../form-elements';

export interface IdeaCardProps extends BoxProps {
  content: string;
  details?: string;
  rank?: number;
  user?: JSX.Element;
  controls?: JSX.Element;
  footerContent?: JSX.Element;
  icons?: JSX.Element;
  date?: Date;
  dateFormat?: string;
  containerBoxProps?: BoxProps;
  contentBoxProps?: BoxProps;
  paperProps?: PaperProps;
}

export const IdeaCard: React.FC<IdeaCardProps> = ({
  content,
  details,
  rank,
  user,
  icons,
  controls,
  footerContent,
  date,
  dateFormat,
  containerBoxProps,
  contentBoxProps,
  paperProps,
  ...rest
}) => {
  const styles = useStyles();

  return (
    <Box mb={2} {...rest}>
      <Paper variant={'outlined'} {...paperProps}>
        <Box p={2} pl={3} {...containerBoxProps}>
          <Grid container flexDirection={{ xs: 'column-reverse', sm: 'row' }} spacing={{ xs: 1, sm: 2 }}>
            <Grid item xs={12} sm={true}>
              <Box {...contentBoxProps}>
                <QuillContentViewer content={content} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={'auto'} display={'flex'} alignItems={'flex-start'} justifyContent={'flex-end'}>
              {icons}
              {typeof rank !== 'undefined' && <RankingScore rankingScore={rank} small={true} />}
            </Grid>
          </Grid>

          {details && (
            <Box mt={2}>
              <FieldTitle text={'Details, value & risks'} mb={0.5} />
              <QuillContentViewer content={details} />
            </Box>
          )}

          <Box mt={2}>
            <Grid container alignItems={'center'} justifyContent={'flex-start'} spacing={2}>
              {user && (
                <Grid item maxWidth={'100%'}>
                  {user}
                </Grid>
              )}

              {date && (
                <Grid item>
                  <Typography className={styles.date} variant="body2" component="div">
                    {format(new Date(date), dateFormat || 'MMM d yyyy h:mm:ss aaa')}
                  </Typography>
                </Grid>
              )}

              {footerContent && <Grid item>{footerContent}</Grid>}

              {controls && (
                <Grid item sx={{ marginLeft: 'auto' }}>
                  {controls}
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
