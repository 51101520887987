import { FluxStandardAction } from 'flux-standard-action';
import { RouterActionMeta } from '../../../../shared/store/types';
import { createPgAction } from '../../../../shared/store/action-creator';
import { PartnershipApi } from '../../service';
import { PartnerProfile } from '@priz/shared/src/models/security/partner-profile';
import { IPublicUser } from '@priz/shared/src/models/user';
import { PlanLevel } from '@priz/shared/src/models/workspace';
import { IPartnerCommission } from '@priz/shared/src/models/security/partner-commission';

export enum PartnershipActionType {
  LoadProfile = 'partnership/profile/load',
  LoadReferrals = 'partnership/referrals/load',
  LoadReferralsPlanLevels = 'partnership/referrals/plan-levels',
  LoadReferralsCommissions = 'partnership/referrals/commissions',
}

type PartnershipActionPayload = PartnerProfile | IPublicUser[] | PlanLevel[] | IPartnerCommission[];

export interface PartnershipActionMeta extends RouterActionMeta {
  referralId: number;
}

export type PartnershipAction = FluxStandardAction<
  PartnershipActionType,
  PartnershipActionPayload,
  PartnershipActionMeta
>;

const loadProfile = createPgAction(PartnershipActionType.LoadProfile, PartnershipApi.getProfile);

const loadReferrals = createPgAction(PartnershipActionType.LoadReferrals, PartnershipApi.getReferrals);

const loadReferralsPlanLevels = createPgAction(
  PartnershipActionType.LoadReferralsPlanLevels,
  PartnershipApi.getReferralPlanLevels,
  (referralId: number) => ({ referralId }),
);

const loadReferralsCommissions = createPgAction(
  PartnershipActionType.LoadReferralsCommissions,
  PartnershipApi.getCommissionByReferralId,
  (referralId: number) => ({ referralId }),
);

export const PartnershipActions = {
  loadProfile,
  loadReferrals,
  loadReferralsPlanLevels,
  loadReferralsCommissions,
};
