import { ToolType } from '../tools';
import { TipProps, DefaultTipProps } from './tips';
import { IdRef } from '../common/id-ref';

interface ToolsTips {
  [ToolType.EBS]: {
    ignoreTips: boolean;
    ideasCreation: TipProps;
    difficulties: TipProps;
    sorting: TipProps;
    ranking: TipProps;
  };
  [ToolType.APA]: {
    ignoreTips: boolean;
    result: TipProps;
  };
}

interface PublishingTips {
  newProjectPublishAdvantages: TipProps;
  hubCreateOwnProject: TipProps;
}

interface UserSettingsTips {
  tools: ToolsTips;
  publishing: PublishingTips;
}

interface UserSettingsGlobal {
  ignoreTips: boolean;
  userProfileCompleted: boolean;
}

export interface UserSettings {
  tips: UserSettingsTips;
  global: UserSettingsGlobal;
}

export interface UserSettingsResponse {
  id: number;
  settings: UserSettings;
  user: IdRef;
}

export const HitsCountLimits = {
  tools: {
    [ToolType.EBS]: 1,
    [ToolType.APA]: 1,
  },
  publishing: {
    newProjectPublishAdvantages: 3,
    hubCreateOwnProject: 1,
  },
};

export const DefaultUserSettings: UserSettings = {
  tips: {
    tools: {
      [ToolType.EBS]: {
        ignoreTips: false,
        ideasCreation: DefaultTipProps,
        difficulties: DefaultTipProps,
        sorting: DefaultTipProps,
        ranking: DefaultTipProps,
      },
      [ToolType.APA]: {
        ignoreTips: false,
        result: DefaultTipProps,
      },
    },

    publishing: {
      newProjectPublishAdvantages: DefaultTipProps,
      hubCreateOwnProject: DefaultTipProps,
    },
  },

  global: {
    ignoreTips: false,
    userProfileCompleted: false,
  },
};
