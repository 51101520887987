import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';

export const useStyles = makeStyles({
  root: {
    'background': pgColorScheme.gray50,

    '&:not(:last-child)': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  textWrap: {
    wordBreak: 'break-word',
  },
  text: {
    color: pgColorScheme.darkText,
    transition: 'color .15s ease',
    whiteSpace: 'pre-line',
  },
  controlsWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
});
