import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { PartnerProfile } from '@priz/shared/src/models/security/partner-profile';
import { PublicUser } from '@priz/shared/src/models/user';
import { PlanLevel } from '@priz/shared/src/models/workspace';
import { PartnerCommission } from '@priz/shared/src/models/security/partner-commission';

interface ReferralsPlanLevelsMap {
  [referralId: number]: PlanLevel[];
}

interface ReferralsCommissionsMap {
  [referralId: number]: PartnerCommission[];
}

interface StatusesByReferralId {
  [referralId: number]: EntityCollectionStatus;
}

interface ReferralsPlanLevelsState {
  map: ReferralsPlanLevelsMap;
  statuses: StatusesByReferralId;
}

interface ReferralsCommissionState {
  map: ReferralsCommissionsMap;
  statuses: StatusesByReferralId;
}

interface ReferralsState {
  list: PublicUser[];
  statuses: EntityCollectionStatus;
}

interface ProfileState {
  data: PartnerProfile;
  statuses: EntityCollectionStatus;
}

export interface PartnershipState {
  profile: ProfileState;
  referrals: ReferralsState;
  referralsPlanLevels: ReferralsPlanLevelsState;
  referralsCommissions: ReferralsCommissionState;
}

export const DefaultPartnershipState: PartnershipState = {
  profile: {
    data: null,
    statuses: {},
  },
  referrals: {
    list: [],
    statuses: {},
  },
  referralsPlanLevels: {
    map: {},
    statuses: {},
  },
  referralsCommissions: {
    map: {},
    statuses: {},
  },
};
