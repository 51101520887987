import React from 'react';
import { useStyles } from './styles';
import { Grid } from '@mui/material';
import { Trans } from 'react-i18next';

interface FiveWhysEdgeProps {
  variant: 'left' | 'down';
  label?: string;
  compact?: boolean;
}

export const FiveWhysEdge: React.FC<FiveWhysEdgeProps> = ({ variant, label, compact }) => {
  const styles = useStyles();

  return (
    <Grid
      item
      xs={variant === 'left' ? (compact ? 1 : 2) : true}
      className={`${styles.root}${compact ? ' _compact' : ''}`}
    >
      <div className={`${styles.arrow} _${variant}`}>
        {label && (
          <span className={styles.label}>
            <Trans>{label}</Trans>
          </span>
        )}
      </div>
    </Grid>
  );
};
