import axios, { AxiosResponse } from 'axios';
import { Attachment } from '../store/model';

// TODO: Move to utils
const generateFormDataAndConfigForFile = (image: File, name = 'file') => {
  const formData = new FormData();

  formData.append(name, image);

  return {
    formData,
    config: {
      headers: {
        'content-type': 'multipart/form-data',
      },
    },
  };
};

const getAllForProject = (projectId: number): Promise<Attachment[]> =>
  axios
    .get(`/v1/api/<ws-id>/project/${projectId}/attachments`)
    .then((response: AxiosResponse<Attachment[]>) => response.data);

const createOverviewAttachment = (projectId: number, file: File): Promise<Attachment> => {
  const { formData, config } = generateFormDataAndConfigForFile(file);

  return axios
    .post(`/v1/api/<ws-id>/project/${projectId}/overview/attachment`, formData, config)
    .then((response: AxiosResponse<Attachment>) => response.data);
};

const createSolutionAttachment = (projectId: number, file: File): Promise<Attachment> => {
  const { formData, config } = generateFormDataAndConfigForFile(file);

  return axios
    .post(`/v1/api/<ws-id>/project/${projectId}/solution/attachment`, formData, config)
    .then((response: AxiosResponse<Attachment>) => response.data);
};

const createTaskAttachment = (projectId: number, taskId: number, file: File): Promise<Attachment> => {
  const { formData, config } = generateFormDataAndConfigForFile(file);

  return axios
    .post(`/v1/api/<ws-id>/project/${projectId}/task/${taskId}/attachment`, formData, config)
    .then((response: AxiosResponse<Attachment>) => response.data);
};

const updateAttachmentFileName = (projectId: number, attachmentId: number, fileName: string): Promise<Attachment> =>
  axios
    .patch(`/v1/api/<ws-id>/project/${projectId}/attachment/${attachmentId}`, { fileName })
    .then((response: AxiosResponse<Attachment>) => response.data);

const deleteAttachment = (projectId: number, attachmentId: number): Promise<void> =>
  axios.delete(`/v1/api/<ws-id>/project/${projectId}/attachment/${attachmentId}`);

export const AttachmentsService = {
  getAllForProject,
  createOverviewAttachment,
  createSolutionAttachment,
  createTaskAttachment,
  updateAttachmentFileName,
  deleteAttachment,
};
