import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { PMapContributedPerception } from '@priz/shared/src/models/tools/pmap/pmap-contributed-perception';
import { format } from 'date-fns';
import { ContributedPerceptionStatus } from '../contributed-perception-status/component';
import { ContributedPerceptionAvatar } from '../../contributed-perception-avatar/component';

interface PublicContributedPerceptionCardProps {
  perception: PMapContributedPerception;
}

export const PublicContributedPerceptionCard: React.FC<PublicContributedPerceptionCardProps> = ({ perception }) => {
  return (
    <Paper variant={'outlined'} component={Box} p={2}>
      <Typography variant={'body1'} component={Box} mb={2}>
        {perception.description}
      </Typography>

      <Grid container spacing={2} justifyContent={'flex-end'} alignItems={'center'}>
        <Grid item marginRight={'auto'}>
          <ContributedPerceptionAvatar perception={perception} />
        </Grid>

        <Grid item>
          <ContributedPerceptionStatus status={perception.status} />
        </Grid>

        <Grid item>{format(new Date(perception.dateCreated), 'MMM d yyyy HH:mm')}</Grid>
      </Grid>
    </Paper>
  );
};
