import React from 'react';
import { SvgIconProps } from '@mui/material';
import { CheckBoxOutlined, CheckBoxOutlineBlankOutlined } from '@mui/icons-material';

interface CheckboxIconProps extends SvgIconProps {
  checked: boolean;
  activeIconProps?: SvgIconProps;
  blankIconProps?: SvgIconProps;
}

export const CheckboxIcon: React.FC<CheckboxIconProps> = ({ checked, activeIconProps, blankIconProps, ...rest }) => {
  const props: SvgIconProps = {
    fontSize: 'small',
    sx: {
      display: 'block',
    },
    ...rest,
  };

  return checked ? (
    <CheckBoxOutlined color={'primary'} {...props} {...activeIconProps} />
  ) : (
    <CheckBoxOutlineBlankOutlined color={'disabled'} {...props} {...blankIconProps} />
  );
};
