import { EntityMap, Identifiable } from '@priz/shared/src/models/common/entity-collection-state';
import { ObjectInstantiator } from './entity-utils';

export function toObjectMap<T extends Identifiable>(arr: any[], instantiator?: ObjectInstantiator<T>): EntityMap<T> {
  return arr.reduce((agg: EntityMap<T>, entityData: T) => {
    const entity = instantiator ? instantiator(entityData) : entityData;
    agg[entity.id] = entity;
    return agg;
  }, {});
}
