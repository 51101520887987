import { createPgAction } from '../../../shared/store/action-creator';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { AgendaApi } from '../../services';
import { IAgenda } from '../model';
import { FluxStandardAction } from 'flux-standard-action';

export enum AgendaActionType {
  FetchAll = 'agendas/fetch',
  Fetch = 'agenda/fetch',
  Create = 'agenda/create',
  Update = 'agenda/update',
  Delete = 'agenda/delete',
}

export interface AgendaActionMeta {
  projectId: number;
  agendaId: number;
}

export type AgendaActionPayload = IAgenda | IAgenda[];

export type AgendaAction = FluxStandardAction<string, AgendaActionPayload, AgendaActionMeta>;

const getAll = createPgAction(AgendaActionType.FetchAll, AgendaApi.getAll, projectId => ({ projectId }));

const get = createPgAction(AgendaActionType.Fetch, AgendaApi.get, (projectId, agendaId) => ({ projectId, agendaId }));

const create = createPgAction(
  AgendaActionType.Create,
  AgendaApi.create,
  projectId => ({ projectId }),
  (_payload, { projectId }) => {
    AnalyticsService.track(AnalyticsEvent.AGENDA_CREATED, {
      project_id: projectId,
    });
  },
);

const update = createPgAction(
  AgendaActionType.Update,
  AgendaApi.update,
  (projectId, agendaId) => ({ projectId, agendaId }),
  (_payload, { projectId, agendaId }) => {
    AnalyticsService.track(AnalyticsEvent.AGENDA_UPDATED, {
      project_id: projectId,
      agenda_id: agendaId,
    });
  },
);

const doDelete = createPgAction(
  AgendaActionType.Delete,
  AgendaApi.delete,
  (projectId, agendaId) => ({ projectId, agendaId }),
  (_payload, { projectId, agendaId }) => {
    AnalyticsService.track(AnalyticsEvent.AGENDA_DELETED, {
      project_id: projectId,
      agenda_id: agendaId,
    });
  },
);

export const AgendaActions = {
  getAll,
  get,
  create,
  update,
  delete: doDelete,
};
