import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { ProjectSelector, ToolUtilizationSelector } from '../../project/store/selectors';
import { ToolType } from '@priz/shared/src/models/tools';
import { generateSfmPreset, SfmNetworkData, SfmUtilization } from '@priz/shared/src/models/tools/sfm';
import { ToolTitle } from '../../tools/tool-title/component';
import { SfmWorkspace } from '../sfm-workspace/component';
import { EdgeProps, NodeProps } from '@priz/shared/src/models/vis-network';
import { SfmUtilizationActions } from '../store/actions';
import { SfmResultViewer } from '../sfm-result-viewer/component';
import { TitleContainer } from '../../react/modules/title-container/component';
import { TabPanel } from '../../react/elements/tab-panel/component';
import { ToolSubjectConclusion } from '../../tools/tool-subject-conclusion/component';
import { PfmUtilization } from '@priz/shared/src/models/tools/pfm';
import { Trans, useTranslation } from 'react-i18next';
import { SfmUpdateNetworkDataCommand } from '../store/services/sfm-utilization.api';
import { SfmVersionsTabs } from '../sfm-versions-tabs/component';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { resolveSfmNetworkData } from '@priz/shared/src/utils/sfm';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { SfmComponentsImprovementHistory } from '../sfm-components-improvement-history/component';
import { ToolUtilizationActions, ToolUtilizationActionType } from '../../project/store/actions/tools.actions';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { useToolTabs } from '../../tools/utils';
import { ToolVersionUtils } from '@priz/shared/src/utils/tools';
import { SfmTab } from '../store/model';

export interface SfmViewProps {
  utilizationId: number;
  projectId?: number;
}

export const SfmView: React.FC<SfmViewProps> = ({ projectId, utilizationId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useToolTabs<SfmTab>(SfmTab.System);
  const [activeVersionId, setActiveVersionId] = useState<string>();

  const sfmUtilization: SfmUtilization = useSelector(ToolUtilizationSelector.getById(utilizationId, ToolType.SFM));
  const pfmUtilization: PfmUtilization = useSelector(
    ToolUtilizationSelector.getById(sfmUtilization?.parentId, ToolType.PFM),
  );
  const isProjectLocked = useSelector(ProjectSelector.isProjectLockedById(projectId));
  const isLoading = useSelector(ToolUtilizationSelector.isLoadingByProjectId(projectId));
  const isCompletenessUpdating = useSelector(
    ToolUtilizationSelector.isPendingByActionType(utilizationId, ToolUtilizationActionType.UpdateCompleteness),
  );

  const isDisabled = !!sfmUtilization?.completed || isCompletenessUpdating || isProjectLocked;

  useEffect(() => {
    if (sfmUtilization?.id && sfmUtilization.publicId) {
      setActiveTab(SfmTab.Model);
    }
  }, [sfmUtilization?.id]);

  useEffect(() => {
    const selectedVersionId = sfmUtilization?.diagramData?.versionId;

    if (!activeVersionId && selectedVersionId) {
      const lastViewedVersion = ToolVersionUtils.resolveLastViewedToolVersion(sfmUtilization.diagramData);

      if (lastViewedVersion) {
        setActiveVersionId(lastViewedVersion);
      } else {
        setActiveVersionId(selectedVersionId);
      }
    }

    if (sfmUtilization && !selectedVersionId) {
      const data = resolveSfmNetworkData(sfmUtilization.diagramData, undefined, generateSfmPreset(t));

      updateNetworkData(data);
    }
  }, [sfmUtilization, activeVersionId]);

  useEffect(() => {
    dispatch(ToolUtilizationActions.loadTool(utilizationId, projectId));
  }, [utilizationId, projectId]);

  const doUpdateUtilization = (updatedUtilization: SfmUtilization) => {
    dispatch(SfmUtilizationActions.updateUtilization(updatedUtilization));
  };

  const updateNetworkData = (data: { nodes: NodeProps[]; edges: EdgeProps[] }) => {
    const command: SfmUpdateNetworkDataCommand = {
      versionId: activeVersionId,
      networkData: data as SfmNetworkData,
    };

    dispatch(SfmUtilizationActions.updateNetworkData(utilizationId, command, projectId));
  };

  const descriptionChangeHandler = (description: string) => {
    doUpdateUtilization({ ...sfmUtilization, subject: description });
  };

  const conclusionChangeHandler = (conclusion: string) => {
    doUpdateUtilization({ ...sfmUtilization, conclusion });
  };

  const handleChange = (_event, newValue: SfmTab) => {
    setActiveTab(newValue);
  };

  const viewVersionHandler = (versionId: string) => {
    setActiveVersionId(versionId);
  };

  if (!sfmUtilization || !activeVersionId) {
    return <LoadingOverlay loading={true} backdropStyles={{ backgroundColor: 'transparent' }} />;
  }

  return (
    <>
      <ContentContainer display={'flex'} alignItems={'stretch'}>
        <Grid container direction={'column'} wrap={'nowrap'}>
          <Grid item xs={'auto'}>
            <TitleContainer>
              <PageTitleWithDocLink
                titleComponent={<ToolTitle tool={sfmUtilization} preset={'large'} editable={!isDisabled} />}
                docsUrl={'https://www.priz.guru/knowledge-base/functional-modeling/'}
              />

              <Box display="flex" justifyContent="flex-end">
                <Tabs
                  orientation={'horizontal'}
                  variant={'scrollable'}
                  scrollButtons={'auto'}
                  allowScrollButtonsMobile={true}
                  value={activeTab}
                  onChange={handleChange}
                >
                  {!sfmUtilization.publicId && <Tab value={SfmTab.System} label={<Trans>System</Trans>} />}

                  <Tab value={SfmTab.Model} label={<Trans>Model</Trans>} />
                  <Tab value={SfmTab.Ranks} label={<Trans>Ranks</Trans>} />

                  {!sfmUtilization.publicId && <Tab value={SfmTab.Conclusion} label={<Trans>Conclusion</Trans>} />}
                </Tabs>
              </Box>
            </TitleContainer>
          </Grid>

          <Grid item xs={true}>
            {!sfmUtilization.publicId && (
              <TabPanel index={SfmTab.System} value={activeTab} stretch>
                <ToolSubjectConclusion
                  onContentChange={descriptionChangeHandler}
                  initialContent={sfmUtilization.subject}
                  placeholder={'Describe the system that you are analyzing with this functional model'}
                  disabled={isDisabled}
                />
              </TabPanel>
            )}

            <TabPanel index={SfmTab.Model} value={activeTab} stretch>
              <Grid container flexDirection={'column'} height={'100%'}>
                <Grid item xs={true} position={'relative'}>
                  <SfmWorkspace
                    key={activeVersionId}
                    utilization={sfmUtilization}
                    activeVersionId={activeVersionId}
                    onDataChange={updateNetworkData}
                    disabled={isDisabled}
                    fitOnInit={activeTab === SfmTab.Model}
                    historyKeyboardControl={activeTab === SfmTab.Model}
                  />

                  <LoadingOverlay loading={isLoading} backdropStyles={{ backgroundColor: 'rgba(255,255,255,.7)' }} />
                </Grid>

                <Grid item maxWidth={'100%'}>
                  <SfmVersionsTabs
                    sfmUtilization={sfmUtilization}
                    activeVersionId={activeVersionId}
                    onVersionSelect={viewVersionHandler}
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel index={SfmTab.Ranks} value={activeTab} stretch>
              <SfmResultViewer
                utilization={sfmUtilization}
                active={activeTab === SfmTab.Ranks}
                activeVersionId={activeVersionId}
                onVersionSelect={viewVersionHandler}
                disabled={isDisabled}
              />
            </TabPanel>

            {!sfmUtilization.publicId && (
              <TabPanel index={SfmTab.Conclusion} value={activeTab} stretch>
                <ToolSubjectConclusion
                  onContentChange={conclusionChangeHandler}
                  initialContent={sfmUtilization?.conclusion}
                  placeholder={'Describe your conclusion that resulted form this functional modeling tool'}
                  disabled={isDisabled}
                />

                <Box mb={5}>
                  <SfmComponentsImprovementHistory
                    utilization={sfmUtilization}
                    forcedVersionId={activeVersionId}
                    load={activeTab === SfmTab.Conclusion}
                  />
                </Box>
              </TabPanel>
            )}
          </Grid>
        </Grid>
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={projectId}
          utilization={sfmUtilization}
          parentUtilization={pfmUtilization}
          disabled={isDisabled}
          workingFlowProps={{ nextVariant: 'ideas' }}
        />
      </ContentFooter>
    </>
  );
};
