import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  scoreButton: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: 'none',

    ['&:hover']: {
      boxShadow: 'none',
    },
  },
  refreshButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: 'none',

    ['&:hover']: {
      borderLeft: 'none',
    },
  },
});
