import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  tabs: {
    minHeight: 0,
  },

  tab: {
    padding: 4,
    height: 'auto',
    width: 'auto',
    minHeight: 0,
    minWidth: 0,
    fontSize: 12,
  },
});
