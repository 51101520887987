import React, { useState } from 'react';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm/rrm-goal-type.enum';
import { RrmUtilizationActions } from '../store/actions/rrm-utilization.actions';
import { RrmUtils } from '../utils/rrm-utils';
import { useDispatch, useSelector } from 'react-redux';
import { UimPriority } from '@priz/shared/src/models/tools/uim';
import { useNavigate } from 'react-router-dom';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { RankingButton } from '../ranking-button/component';
import { Trans } from 'react-i18next';
import { PgLink } from '../../react/elements/pg-link/component';
import { EbsIdeaDifficultyVariant } from '@priz/shared/src/models/tools/ebs';
import { PMapPerceptionBlockingRank } from '@priz/shared/src/models/tools/pmap';
import { RrmUtilizationCommand } from '../services';

export interface RrmToolStarterProps {
  goalType: RrmGoalType;
  itemsCount: number;
  projectId?: number;
  goalId?: number;
  utilizationId?: number;
  uimPriority?: UimPriority;
  implementation?: EbsIdeaDifficultyVariant;
  validation?: EbsIdeaDifficultyVariant;
  blockingRank?: PMapPerceptionBlockingRank;
  disabled?: boolean;
}

export const RrmToolStarter: React.FC<RrmToolStarterProps> = ({
  goalType,
  itemsCount,
  projectId,
  goalId,
  utilizationId,
  uimPriority,
  implementation,
  validation,
  blockingRank,
  disabled,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSmart, setIsSmart] = useState(false);

  const isAnyToolCreating = useSelector(ToolUtilizationSelector.isAnyCreating(projectId));
  const isUtilizationLoading = useSelector(ToolUtilizationSelector.isEntityLoading(utilizationId, projectId));

  const isLoading = isAnyToolCreating || isUtilizationLoading;

  const handleToolAdd = (smart: boolean) => {
    setIsSmart(smart);

    if (goalType === RrmGoalType.SELF) {
      if (typeof goalId !== 'undefined') {
        dispatch(RrmUtilizationActions.startRanking(utilizationId, smart, goalId, projectId));
      }
    } else {
      const command: RrmUtilizationCommand = {
        goalTitle: RrmUtils.generateRrmGoalTitle(goalType),
        goalType,
        utilizationId,
        uimPriority,
        implementation,
        validation,
        blockingRank,
        smart,
      };

      dispatch(RrmUtilizationActions.create(command, projectId, navigate));
    }
  };

  return (
    <Grid container spacing={2} alignItems={'stretch'} textAlign={'left'}>
      <Grid item xs={12}>
        <Paper variant={'outlined'}>
          <Box p={2}>
            <Grid container spacing={2} justifyContent={'center'} height={'100%'}>
              <Grid item xs={12}>
                <Typography variant={'body1'} component={Box} mb={2}>
                  <b>
                    <Trans>Full Ranking</Trans>
                  </b>
                </Typography>

                <Typography variant={'body1'}>
                  <Trans>
                    Full ranking is a comprehensive comparison method in Round Robin Ranking that evaluates all possible
                    pairs of options. This method is most suitable for situations where there are small number of
                    options to compare.
                  </Trans>
                </Typography>
              </Grid>

              <Grid item xs={12} marginTop={'auto'}>
                <Grid container spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                  <Grid item>
                    <PgLink href={'https://www.priz.guru/creative-thinking-tools/round-robin-matrix/'} target="_blank">
                      <Trans>Learn more</Trans>
                    </PgLink>
                  </Grid>

                  <Grid item>
                    <RankingButton
                      buttonText={'Start full ranking'}
                      size={'pg_small'}
                      itemsCount={itemsCount}
                      disabled={disabled || isLoading}
                      loading={!isSmart && isLoading}
                      onClick={() => handleToolAdd(false)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper variant={'outlined'}>
          <Box p={2}>
            <Grid container spacing={2} justifyContent={'center'} height={'100%'}>
              <Grid item xs={12}>
                <Typography variant={'body1'} component={Box} mb={2}>
                  <b>
                    <Trans>Smart Ranking</Trans>
                  </b>
                </Typography>

                <Typography variant={'body1'}>
                  <Trans>
                    Smart ranking is an advanced algorithm in Round-Robin Ranking that minimizes the number of
                    comparisons required while still producing highly accurate results, comparable to those obtained
                    through full ranking. This option is ideal when there are a large number of options to compare,
                    making the process more efficient and less time-consuming.
                  </Trans>
                </Typography>
              </Grid>

              <Grid item xs={12} marginTop={'auto'}>
                <Grid container spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                  <Grid item>
                    <PgLink href={'https://www.priz.guru/creative-thinking-tools/#'} target="_blank">
                      <Trans>Learn more</Trans>
                    </PgLink>
                  </Grid>

                  <Grid item>
                    <RankingButton
                      buttonText={'Start smart ranking'}
                      size={'pg_small'}
                      itemsCount={itemsCount}
                      disabled={disabled || isLoading}
                      loading={isSmart && isLoading}
                      smart={true}
                      onClick={() => handleToolAdd(true)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
