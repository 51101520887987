import React, { useState } from 'react';
import { User } from '@priz/shared/src/models/security/user';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Trans } from 'react-i18next';
import { AuthService } from '@priz/shared/src/services/auth';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { Auth0ConnectionType } from '@priz/shared/src/models/security/auth0-connection-type';
import { ConnectAccountDialog } from '../connect-account-dialog/component';
import { Auth0RedirectKey } from '@priz/shared/src/models/auth0';
import { ConnectAccountButton } from '../connect-account-button/component';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions } from '../../user/store/actions/user.actions';
import { DeleteConnectionCommand } from '../../security/services/user.service';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { useLocation } from 'react-router-dom';

interface ConnectAccountButtonsProps {
  user: User;
}

export const ConnectAccountButtons: React.FC<ConnectAccountButtonsProps> = ({ user }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [isAccountConnectDialogOpen, setIsAccountConnectDialogOpen] = useState(false);
  const [isRemoveConnectionDialogOpen, setIsRemoveConnectionDialogOpen] = useState(false);
  const [connectionToRemove, setConnectionToRemove] = useState<Auth0ConnectionType>();

  const isUserLoaded = useSelector(UserSelectors.isLoaded);
  const isConnectionsUpdating = useSelector(UserSelectors.isConnectionsUpdating);
  const loadingConnection = useSelector(UserSelectors.loadingConnection);

  const isSingleConnection = [user.auth0Id, user.googleId, user.linkedinId].filter(id => !!id).length < 2;

  const isAuth0Connected = !!user.auth0Id;

  const isGoogleConnectedAndLast = !!user.googleId && isSingleConnection;
  const isGoogleConnectedAndActive = !!user.googleId && user.activeConnection === Auth0ConnectionType.GOOGLE;

  const isLinkedInConnectedAndLast = !!user.linkedinId && isSingleConnection;
  const isLinkedInConnectedAndActive = !!user.linkedinId && user.activeConnection === Auth0ConnectionType.LINKEDIN;

  const authWithEmailPassword = (password: string) => {
    setRedirectPath();
    copyCurrentTokenToPrimary();
    AuthService.signupWithEmailPassword(user.email, password, Auth0RedirectKey.ConnectAccounts);
  };

  const googleHandler = () => {
    setRedirectPath();
    copyCurrentTokenToPrimary();
    AuthService.authorizeWithGoogle(Auth0RedirectKey.ConnectAccounts);
  };

  const linkedInHandler = () => {
    setRedirectPath();
    copyCurrentTokenToPrimary();
    AuthService.authorizeWithLinkedIn(Auth0RedirectKey.ConnectAccounts);
  };

  const setRedirectPath = () => {
    LocalStorageService.setItem(LocalStorageKey.AuthCallbackRedirect, pathname);
  };

  const copyCurrentTokenToPrimary = () => {
    const currentAccessToken = LocalStorageService.getItem(LocalStorageKey.AccessToken);

    if (currentAccessToken) {
      LocalStorageService.setItem(LocalStorageKey.PrimaryAccessToken, currentAccessToken);
    }
  };

  const removeConnection = (connectionType: Auth0ConnectionType) => {
    const command: DeleteConnectionCommand = {
      connectionType,
    };

    closeRemoveConnectionDialog();
    dispatch(UserActions.deleteConnection(command));
  };

  const removeConnectionHandler = (connectionType: Auth0ConnectionType) => {
    setConnectionToRemove(connectionType);
    openRemoveConnectionDialog();
  };

  const openRemoveConnectionDialog = () => {
    setIsRemoveConnectionDialogOpen(true);
  };

  const closeRemoveConnectionDialog = () => {
    setIsRemoveConnectionDialogOpen(false);
  };

  const openPasswordDialog = () => {
    setIsAccountConnectDialogOpen(true);
  };

  const closePasswordDialog = () => {
    setIsAccountConnectDialogOpen(false);
  };

  return (
    <>
      <Box mb={1}>
        <ConnectAccountButton
          checked={!!user.auth0Id}
          text={'Email & password'}
          onEnable={openPasswordDialog}
          onDisable={() => removeConnectionHandler(Auth0ConnectionType.AUTH0)}
          disabled={isConnectionsUpdating || isAuth0Connected || !isUserLoaded}
          loading={loadingConnection === Auth0ConnectionType.AUTH0 || !isUserLoaded}
          initialTooltip={isAuth0Connected ? 'Email & Password connection cannot be deleted' : ''}
        />
      </Box>

      <Box mb={1}>
        <ConnectAccountButton
          checked={!!user.googleId}
          text={'Google account'}
          onEnable={googleHandler}
          onDisable={() => removeConnectionHandler(Auth0ConnectionType.GOOGLE)}
          disabled={isConnectionsUpdating || isGoogleConnectedAndLast || isGoogleConnectedAndActive || !isUserLoaded}
          loading={loadingConnection === Auth0ConnectionType.GOOGLE || !isUserLoaded}
          isConnectedAndLast={isGoogleConnectedAndLast}
          isConnectedAndActive={isGoogleConnectedAndActive}
        />
      </Box>

      <Box mb={1}>
        <ConnectAccountButton
          checked={!!user.linkedinId}
          text={'LinkedIn account'}
          onEnable={linkedInHandler}
          onDisable={() => removeConnectionHandler(Auth0ConnectionType.LINKEDIN)}
          disabled={
            isConnectionsUpdating || isLinkedInConnectedAndLast || isLinkedInConnectedAndActive || !isUserLoaded
          }
          loading={loadingConnection === Auth0ConnectionType.LINKEDIN || !isUserLoaded}
          isConnectedAndLast={isLinkedInConnectedAndLast}
          isConnectedAndActive={isLinkedInConnectedAndActive}
        />
      </Box>

      <ConnectAccountDialog
        isOpen={isAccountConnectDialogOpen}
        user={user}
        onClose={closePasswordDialog}
        onSubmit={authWithEmailPassword}
      />

      <Dialog open={isRemoveConnectionDialogOpen} maxWidth={'sm'} fullWidth onClose={closeRemoveConnectionDialog}>
        <DialogTitle>
          <Trans>Confirm delete</Trans>
        </DialogTitle>

        <DialogContent sx={{ textAlign: 'center' }}>
          {connectionToRemove === Auth0ConnectionType.GOOGLE && (
            <Trans>Are you sure you want to delete Google account connection?</Trans>
          )}

          {connectionToRemove === Auth0ConnectionType.LINKEDIN && (
            <Trans>Are you sure you want to delete LinkedIn account connection?</Trans>
          )}
        </DialogContent>

        <DialogActions sx={{ textAlign: 'right' }}>
          <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={closeRemoveConnectionDialog}>
            <Trans>Cancel</Trans>
          </Button>

          <Button variant={'pg_rounded'} color={'pg_orange_solid'} onClick={() => removeConnection(connectionToRemove)}>
            <Trans>Delete</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
