import React from 'react';
import { Box, Collapse, Divider, List } from '@mui/material';
import { SideNavItem } from '../../shared/section-sidenav-item/component';
import { Trans } from 'react-i18next';
import { User } from '@priz/shared/src/models/security/user';
import { UserAvatarEditor } from '../user-avatar-editor/component';
import { useStyles } from './styles';
import { VpnKeyOutlined, GroupAddOutlined, EmailOutlined } from '@mui/icons-material';
import { useFlag } from '@unleash/proxy-client-react';
import { UnleashFeatureName } from '@priz/shared/src/services/unleash';

import { ReactComponent as PersonIcon } from '../../../assets/icons/person.svg';
import { ReactComponent as PadlockIcon } from '../../../assets/icons/padlock.svg';
import { ReactComponent as LogOutIcon } from '../../../assets/icons/log-out.svg';
import { ReactComponent as Notepad } from '../../../assets/icons/notepad.svg';

export interface UserMenuProps {
  user: User;
  showAvatar: boolean;
  navItemClickHandler?: () => void;
}

export const UserMenu: React.FC<UserMenuProps> = ({ user, navItemClickHandler, showAvatar }) => {
  const styles = useStyles();
  const isPartnershipEnabled = useFlag(UnleashFeatureName.Partnership);
  const isApiKeysEnabled = useFlag(UnleashFeatureName.ApiKeys);

  const clickHandler = () => {
    if (navItemClickHandler) navItemClickHandler();
  };

  return (
    <>
      <Box mb={3} pt={{ xs: 2, sm: 7 }} px={2} mx={'auto'} width={220} boxSizing={'border-box'}>
        <Collapse in={showAvatar}>
          <UserAvatarEditor user={user} />
        </Collapse>
      </Box>

      <List className={styles.list}>
        <SideNavItem
          title={<Trans>Personal Information</Trans>}
          icon={<PersonIcon />}
          navigate={['/profile', 'personal-information']}
          onClick={clickHandler}
        />

        <Divider style={{ width: '100%' }} />

        <SideNavItem
          title={<Trans>Security & Privacy</Trans>}
          icon={<PadlockIcon />}
          navigate={['/profile', 'security-setting']}
          onClick={clickHandler}
        />

        <Divider style={{ width: '100%' }} />

        <SideNavItem
          title={<Trans>Notifications</Trans>}
          icon={<EmailOutlined />}
          navigate={['/profile', 'notifications']}
          onClick={clickHandler}
        />

        <Divider style={{ width: '100%' }} />

        {isPartnershipEnabled && (
          <>
            <SideNavItem
              title={<Trans>Partnership</Trans>}
              icon={<GroupAddOutlined />}
              navigate={['/profile', 'partnership']}
              onClick={clickHandler}
            />

            <Divider style={{ width: '100%' }} />
          </>
        )}

        {isApiKeysEnabled && (
          <>
            <SideNavItem
              title={<Trans>API keys</Trans>}
              icon={<VpnKeyOutlined />}
              navigate={['/profile', 'api-keys']}
              onClick={clickHandler}
            />

            <Divider style={{ width: '100%' }} />
          </>
        )}

        <SideNavItem
          title={<Trans>Log out</Trans>}
          icon={<LogOutIcon />}
          navigate={['/logout']}
          onClick={clickHandler}
        />

        <Divider style={{ width: '100%' }} />

        <Box mt={'auto'} width={'100%'} pb={11}>
          <SideNavItem
            title={<Trans>Project List</Trans>}
            icon={<Notepad />}
            navigate={['/projects-and-tools']}
            onClick={clickHandler}
          />
        </Box>
      </List>
    </>
  );
};
