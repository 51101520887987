import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { alpha } from '@mui/material';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
    borderRadius: 4,
    transition: 'background-color .15s ease',

    ['&:hover']: {
      backgroundColor: alpha(pgColorScheme.darkGray, 0.05),
    },
  },
  link: {
    color: pgColorScheme.darkGray,
    transition: 'color .15s ease',
    cursor: 'pointer',
    textDecoration: 'none',

    ['&:hover']: {
      color: pgColorScheme.blue,
    },
  },
});
