import React, { useEffect, useState } from 'react';
import { Chat } from '../store/model';
import {
  Avatar,
  Box,
  Button,
  ButtonProps,
  ClickAwayListener,
  Grid,
  ListItemIcon,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { TimeZoneService } from '@priz/shared/src/services/time';
import { Trans, useTranslation } from 'react-i18next';
import { ChatActions } from '../store/actions';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import {
  ModeEditOutlined,
  DeleteOutlineOutlined,
  SettingsRounded,
  ChevronLeftRounded,
  LockPersonOutlined,
  PersonRounded,
} from '@mui/icons-material';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { useStyles } from './styles';
import { useForm } from 'react-hook-form';
import { ReactHookFormText } from '@priz/shared/src/components/form-elements';
import { validatorRules } from '@priz/shared/src/utils/form';
import { ChatSelectors } from '../store/selectors';
import { ChatUpdateCommand } from '../services/chat.api';
import { safeParseJson } from '@priz/shared/src/utils/common';
import { ReactHookFormSwitch } from '../../react/form-elements/switch/component';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { UserUtils } from '@priz/shared/src/utils/user/user-utils';
import { WorkspaceMembersSelectors } from '../../workspace/store/selectors/workspace-members.selectors';

interface AIChatTabProps {
  activeChatId: number;
  chat: Chat;
}

enum ChatTabMenuType {
  Root = 'Root',
  Edit = 'Edit',
  Remove = 'Remove',
  Privacy = 'Privacy',
}

interface FormButtonsProps {
  onSubmit: () => void;
  onCancel: () => void;
  submitButtonText: string;
  submitButtonProps?: ButtonProps;
  disabled?: boolean;
}

const FormButtons: React.FC<FormButtonsProps> = ({
  onSubmit,
  onCancel,
  disabled,
  submitButtonText,
  submitButtonProps,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Button
          variant={'outlined'}
          color={'primary'}
          size={'small'}
          onClick={onSubmit}
          disabled={disabled}
          {...(submitButtonProps || {})}
        >
          <Trans>{submitButtonText}</Trans>
        </Button>
      </Grid>

      <Grid item>
        <Button variant={'text'} color={'primary'} size={'small'} onClick={onCancel} disabled={disabled}>
          <Trans>Cancel</Trans>
        </Button>
      </Grid>
    </Grid>
  );
};

export const AIChatTab: React.FC<AIChatTabProps> = ({ chat, activeChatId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const styles = useStyles();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState<ChatTabMenuType>(ChatTabMenuType.Root);
  const [closeDropdownOnUpdated, setCloseDropdownOnUpdated] = useState(false);

  const chatOwnerWorkspaceMember = useSelector(WorkspaceMembersSelectors.getById(chat.user.id));
  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const isUpdating = useSelector(ChatSelectors.isUpdatingById(chat.project.id, chat.id));
  const isUpdated = useSelector(ChatSelectors.isUpdatedById(chat.project.id, chat.id));
  const isDeleting = useSelector(ChatSelectors.isRemovingById(chat.project.id, chat.id));

  const isLoading = isUpdating || isDeleting;
  const isChatOwner = currentUser?.id === chat.user.id;

  const name = chat.name ? chat.name : `${t('Chat')} ${TimeZoneService.format(chat.dateCreated, 'M.D.YYYY')}`;
  const tabClassNames = [styles.tab];

  if (chat.id === activeChatId) tabClassNames.push(styles.tabActive);
  if (isLoading) tabClassNames.push(styles.tabLoading);

  const { handleSubmit, reset, control } = useForm({
    shouldFocusError: false,
    defaultValues: {
      name: chat.name || '',
      shared: chat.shared,
    },
  });

  useEffect(() => {
    reset({ name: chat.name || '', shared: chat.shared });
  }, [activeMenu]);

  useEffect(() => {
    if (isDropdownOpen) {
      openRootMenu();
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    if (closeDropdownOnUpdated && isUpdated) {
      setCloseDropdownOnUpdated(false);
      closeDropdown();
    }
  }, [closeDropdownOnUpdated, isUpdated]);

  const changeChatHandler = () => {
    dispatch(ChatActions.setActiveChat(chat.id));
    saveChatIdInLocalStorage();
  };

  const saveChatIdInLocalStorage = () => {
    const lsLastViewedChats = LocalStorageService.getItem(LocalStorageKey.AiLastViewedChats);
    const lastViewedChatsObject = safeParseJson(lsLastViewedChats);

    lastViewedChatsObject[chat.project.id] = chat.id;

    LocalStorageService.setItem(LocalStorageKey.AiLastViewedChats, JSON.stringify(lastViewedChatsObject));
  };

  const submitNameChangeHandler = () => {
    handleSubmit(formData => {
      const command: ChatUpdateCommand = {
        name: formData.name.length ? formData.name : undefined,
        shared: formData.shared,
      };

      dispatch(ChatActions.updateChat(chat.project.id, chat.id, command));
      setCloseDropdownOnUpdated(true);
    })();
  };

  const submitDeletionHandler = () => {
    dispatch(ChatActions.deleteChat(chat.project.id, chat.id));
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(currentState => !currentState);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const openRootMenu = () => {
    setActiveMenu(ChatTabMenuType.Root);
  };

  return (
    <Box className={tabClassNames.join(' ')} pr={0.5}>
      <Grid container alignItems={'center'}>
        <Grid item xs={true} flexWrap={'nowrap'} overflow={'hidden'}>
          <Tooltip title={name} placement={'top'} arrow disableInteractive>
            <Box className={styles.text} pl={2} pr={1} py={1.5} onClick={changeChatHandler}>
              <Typography variant={'body2'} noWrap>
                {name}
              </Typography>
            </Box>
          </Tooltip>
        </Grid>

        {!isChatOwner && (
          <Grid item xs={'auto'}>
            <PopperWrap>
              <Avatar
                sx={{ width: 24, height: 24, cursor: 'pointer' }}
                src={chatOwnerWorkspaceMember?.profile?.avatarUrl}
              >
                {chatOwnerWorkspaceMember && UserUtils.resolveInitials(chatOwnerWorkspaceMember)}
                {!chatOwnerWorkspaceMember && <PersonRounded />}
              </Avatar>

              <div>
                <Typography variant={'subtitle2'} component={Box} mb={1}>
                  <Trans>Chat owner</Trans>
                </Typography>

                {chatOwnerWorkspaceMember && (
                  <>
                    <Typography variant={'caption'} component={Box}>
                      <b>
                        <Trans>Name</Trans>:
                      </b>{' '}
                      {UserUtils.getShowableName(chatOwnerWorkspaceMember)}
                    </Typography>

                    <Typography variant={'caption'} component={Box}>
                      <b>
                        <Trans>Email</Trans>:
                      </b>{' '}
                      {chatOwnerWorkspaceMember.email}
                    </Typography>
                  </>
                )}
              </div>
            </PopperWrap>
          </Grid>
        )}

        {isChatOwner && (
          <ClickAwayListener onClickAway={closeDropdown}>
            <Grid item xs={'auto'}>
              <PopperWrap
                open={isDropdownOpen}
                openHandler={'manually'}
                dropdownProps={{ p: 0 }}
                onClose={closeDropdown}
              >
                <div className={styles.settingsButton} onClick={toggleDropdown}>
                  <SettingsRounded color={'primary'} className={styles.settingsIcon} />
                </div>

                <div>
                  <MenuList>
                    {activeMenu !== ChatTabMenuType.Root && (
                      <MenuItem onClick={openRootMenu} disabled={isLoading}>
                        <ListItemIcon>
                          <ChevronLeftRounded fontSize="small" />
                        </ListItemIcon>

                        <Typography variant="inherit" noWrap>
                          <Trans>Back</Trans>
                        </Typography>
                      </MenuItem>
                    )}

                    {activeMenu === ChatTabMenuType.Root &&
                      [
                        { type: ChatTabMenuType.Edit, text: 'Edit', Icon: ModeEditOutlined },
                        { type: ChatTabMenuType.Privacy, text: 'Privacy', Icon: LockPersonOutlined },
                        { type: ChatTabMenuType.Remove, text: 'Remove', Icon: DeleteOutlineOutlined },
                      ].map(({ type, text, Icon }, key) => (
                        <MenuItem key={key} onClick={() => setActiveMenu(type)}>
                          <ListItemIcon>
                            <Icon fontSize="small" />
                          </ListItemIcon>

                          <Typography variant="inherit" noWrap>
                            <Trans>{text}</Trans>
                          </Typography>
                        </MenuItem>
                      ))}
                  </MenuList>

                  {activeMenu === ChatTabMenuType.Edit && (
                    <Box px={2} pb={2}>
                      <ReactHookFormText
                        placeholder={'Chat name'}
                        name={'name'}
                        variant={'standard'}
                        size={'small'}
                        control={control}
                        rules={{
                          ...validatorRules.required(),
                        }}
                        inputProps={{
                          className: styles.nameInput,
                        }}
                        wrapperProps={{ mb: 2 }}
                        disabled={isLoading}
                      />

                      <FormButtons
                        onSubmit={submitNameChangeHandler}
                        onCancel={openRootMenu}
                        submitButtonText={'Rename'}
                        disabled={isLoading}
                      />
                    </Box>
                  )}

                  {activeMenu === ChatTabMenuType.Privacy && (
                    <Box px={2} pb={2}>
                      <ReactHookFormSwitch
                        helperText={'Allow project members to view and participate in this chat'}
                        label={'Shared'}
                        name={'shared'}
                        control={control}
                        disabled={isUpdating}
                        wrapperProps={{ mb: 2 }}
                      />

                      <FormButtons
                        onSubmit={submitNameChangeHandler}
                        onCancel={openRootMenu}
                        submitButtonText={'Update'}
                        disabled={isLoading}
                      />
                    </Box>
                  )}

                  {activeMenu === ChatTabMenuType.Remove && (
                    <Box px={2} pb={2}>
                      <Box mb={2}>
                        <Typography variant={'subtitle2'} component={'div'}>
                          <Trans>Confirm delete</Trans>
                        </Typography>

                        <Typography variant={'body2'} component={'div'}>
                          <Trans>Are you sure you want to delete this chat?</Trans>
                        </Typography>
                      </Box>

                      <FormButtons
                        onSubmit={submitDeletionHandler}
                        onCancel={openRootMenu}
                        submitButtonText={'Delete'}
                        submitButtonProps={{ color: 'error' }}
                        disabled={isLoading}
                      />
                    </Box>
                  )}
                </div>
              </PopperWrap>
            </Grid>
          </ClickAwayListener>
        )}
      </Grid>
    </Box>
  );
};
