import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Typography } from '@mui/material';
import { useStyles } from './styles';
import { CrumbProps } from '../model';
import { crumbIconsMap } from '../data';

export const Crumb: React.FC<CrumbProps> = ({ text, to, minify, type }) => {
  const styles = useStyles();

  const renderContent = () => {
    return minify && crumbIconsMap[type] ? crumbIconsMap[type] : <Trans>{text}</Trans>;
  };

  return (
    <Typography variant={'body2'} className={to ? styles.active : styles.inactive}>
      {to ? (
        <Link to={to} className={styles.link}>
          {renderContent()}
        </Link>
      ) : (
        renderContent()
      )}
    </Typography>
  );
};
