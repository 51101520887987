import { createSelector, Selector } from 'reselect';
import { ArrayUtils } from '@priz/shared/src/utils/common';
import { AppState } from '../../../store/app.state';
import { Attachment, AttachmentPreview, AttachmentsCollection, AttachmentType } from '../model';

const attachmentsCollectionSelector = (state: AppState): AttachmentsCollection => state.attachments;

const attachmentsEntitiesSelector = createSelector(attachmentsCollectionSelector, collection => collection.entities);

const attachmentsLookupSelector = createSelector(attachmentsCollectionSelector, collection => collection.lookups);

const statusesLookupSelector = createSelector(attachmentsCollectionSelector, collection => collection.statuses);

const creatingAttachmentsSelector = createSelector(
  attachmentsCollectionSelector,
  collection => collection.creatingAttachments,
);

const attachmentsIdsByProjectIdSelector = (projectId: number) =>
  createSelector(attachmentsLookupSelector, lookup => lookup.byProjectId[projectId] || []);

const attachmentsStatusesSelector = createSelector(attachmentsCollectionSelector, collection => collection.statuses);

const areAttachmentsLoading = (projectId: number): Selector<AppState, boolean> =>
  createSelector(
    attachmentsStatusesSelector,
    statuses => !!(statuses.byProjectId[projectId] && statuses.byProjectId[projectId].loading),
  );

const areAttachmentsLoaded = (projectId: number): Selector<AppState, boolean> =>
  createSelector(
    attachmentsStatusesSelector,
    statuses => !!(statuses.byProjectId[projectId] && statuses.byProjectId[projectId].loaded),
  );

const getAllByProjectId = (projectId: number): Selector<AppState, Attachment[]> =>
  createSelector([attachmentsEntitiesSelector, attachmentsIdsByProjectIdSelector(projectId)], (entityMap, ids) =>
    ids.map(id => entityMap[id]).sort(ArrayUtils.sorterByDateCreated),
  );

const getAllByProjectIdAndType = (
  projectId: number,
  type: AttachmentType,
  taskId?: number,
): Selector<AppState, Attachment[]> =>
  createSelector([attachmentsEntitiesSelector, attachmentsIdsByProjectIdSelector(projectId)], (entityMap, ids) =>
    ids
      .map(id => entityMap[id])
      .filter(attachment => attachment.attachmentType === type && (taskId ? attachment.task.id === taskId : true))
      .sort(ArrayUtils.sorterByDateCreated),
  );

const getById = (attachmentId: number): Selector<AppState, Attachment> =>
  createSelector(attachmentsEntitiesSelector, entities => entities[attachmentId]);

const isLoaded = (projectId: number): Selector<AppState, boolean> =>
  createSelector(statusesLookupSelector, statuses => statuses.byProjectId[projectId]?.loaded || false);

const isLoading = (projectId: number): Selector<AppState, boolean> =>
  createSelector(statusesLookupSelector, statuses => statuses.byProjectId[projectId]?.loading || false);

const getCreatingAttachmentsByProjectIdAndType = (projectId: number, type: AttachmentType, taskId?: number) =>
  createSelector(creatingAttachmentsSelector, creatingAttachments => {
    const attachments = creatingAttachments.byProjectId[projectId] || {};
    const previews = Object.values(attachments) as AttachmentPreview[];

    return previews.filter(p => p.attachmentType === type && (taskId ? p.taskId === taskId : true));
  });

export const AttachmentsSelectors = {
  areAttachmentsLoaded,
  areAttachmentsLoading,
  getAllByProjectId,
  getAllByProjectIdAndType,
  getCreatingAttachmentsByProjectIdAndType,
  getById,
  isLoaded,
  isLoading,
};
