import { handleActions } from 'redux-actions';
import { DefaultPaywallState, PaywallState } from '../model';
import { toSuccess } from '../../../../../shared/store/action-creator';
import { PaywallAction, PaywallActionType } from '../actions/paywall.actions';

export const paywallReducers = handleActions(
  {
    [toSuccess(PaywallActionType.Show)]: (state: PaywallState, action: PaywallAction): PaywallState => ({
      ...state,
      ...action.payload,
      show: true,
    }),
    [toSuccess(PaywallActionType.Hide)]: (state: PaywallState): PaywallState => ({
      ...state,
      show: false,
    }),
  },
  DefaultPaywallState,
);
