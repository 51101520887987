import { DocRenderer } from 'react-doc-viewer';
import { useStyles } from './styles';

export const WebPRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
  const styles = useStyles();

  if (!currentDocument) return null;

  return (
    <div className={styles.imageContainer}>
      <img className={styles.image} alt={currentDocument.fileType} src={currentDocument.fileData as string} />
    </div>
  );
};

WebPRenderer.fileTypes = ['webp', 'image/webp'];
WebPRenderer.weight = 1;
