import React, { useEffect } from 'react';
import { ToolUtilization } from '@priz/shared/src/models/tools';
import { Trans } from 'react-i18next';
import { Button, ButtonProps } from '@mui/material';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { useNavigate } from 'react-router-dom';
import { ArrowBackOutlined } from '@mui/icons-material';
import { ToolsUtils } from '../utils';
import { toolTypeLinkBackTextMap, toolTypeWorkspaceTabMap } from '../model';
import { useDispatch, useSelector } from 'react-redux';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { ToolUtilizationActions } from '../../project/store/actions/tools.actions';

export interface BackToParentToolButtonProps extends ButtonProps {
  utilization: ToolUtilization;
}

export const BackToParentToolButton: React.FC<BackToParentToolButtonProps> = ({ utilization, ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const parentTool = useSelector(ToolUtilizationSelector.getByOnlyId(utilization.parentId));

  useEffect(() => {
    if (utilization.parentId && !parentTool?.id) {
      dispatch(ToolUtilizationActions.loadTool(utilization.parentId, utilization.project?.id));
    }
  }, [utilization.parentId, parentTool?.id]);

  const backToParent = () => {
    const tab = toolTypeWorkspaceTabMap[parentTool.type];

    if (tab) {
      LocalStorageService.setItem(LocalStorageKey.LastViewedToolTab, tab);
      LocalStorageService.setItem(LocalStorageKey.SwitchToLastViewedToolTab, 'true');
    }

    navigate(ToolsUtils.resolvePathByUtilization(parentTool));
  };

  if (!parentTool) {
    return null;
  }

  return (
    <Button
      variant={'pg_rounded'}
      color={'pg_blue_solid'}
      onClick={backToParent}
      startIcon={<ArrowBackOutlined />}
      {...rest}
    >
      <Trans>{toolTypeLinkBackTextMap[parentTool.type] || 'Back'}</Trans>
    </Button>
  );
};
