import { FiveWhysUtilization, IFiveWhysUtilization } from '@priz/shared/src/models/tools/five-whys';
import axios, { AxiosResponse } from 'axios';

const update = (utilization: FiveWhysUtilization): Promise<IFiveWhysUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/5-whys/${utilization.id}`, utilization)
    .then((response: AxiosResponse<IFiveWhysUtilization>) => response.data);

const doDelete = (utilizationId: number): Promise<any> =>
  axios.delete(`/v1/api/<ws-id>/team/<t-id>/5-whys/${utilizationId}`);

export const FiveWhysUtilizationApi = {
  update,
  delete: doDelete,
};
