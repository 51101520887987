import React from 'react';
import { UimPriority, UimTask } from '@priz/shared/src/models/tools/uim';
import { UimTaskSummaryTable } from '@priz/shared/src/components/uim-summary-table/component';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm';
import { filterTasksByPriority } from '@priz/shared/src/utils/uim';
import { StartRankingButton } from '../../rrm/start-ranking-button/component';

interface UimTaskSummaryRankingTableProps {
  tasks: UimTask[];
  priority: UimPriority;
  utilizationId: number;
  projectId?: number;
  disabled?: boolean;
}

export const UimTaskSummaryRankingTable: React.FC<UimTaskSummaryRankingTableProps> = ({
  tasks,
  priority,
  utilizationId,
  projectId,
  disabled,
}) => {
  const priorityTasks = filterTasksByPriority(tasks, priority);

  return (
    <UimTaskSummaryTable
      tasks={tasks}
      priority={priority}
      controls={
        priorityTasks.length > 2 &&
        [UimPriority.DoFirst, UimPriority.DoLater].includes(priority) && (
          <StartRankingButton
            projectId={projectId}
            goalType={RrmGoalType.UIM_TASK}
            utilizationId={utilizationId}
            uimPriority={priority}
            itemsCount={priorityTasks.length}
            buttonText={'Rank tasks'}
            buttonProps={{
              size: 'pg_small',
            }}
            disabled={disabled}
          />
        )
      }
    />
  );
};
