import React, { useEffect } from 'react';
import { Dialog, DialogContent, DialogProps, DialogTitle } from '@mui/material';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DialogsSelectors } from '../../dialogs/store/selectors';
import { DialogType } from '../../dialogs/store/model';
import { ManageSeats } from '../manage-seats/component';
import { WorkspaceMembersSelectors } from '../../workspace/store/selectors/workspace-members.selectors';
import { WorkspaceMembersActions } from '../../workspace/store/actions';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { UserContextSelectors } from '../../security/store/selectors';

export const ManageSeatsDialog: React.FC<Partial<DialogProps>> = props => {
  const dispatch = useDispatch();

  const currentUser = useSelector(UserContextSelectors.getCurrentUser);
  const workspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const isCurrentUserSatisfyWorkspaceMfa = useSelector(WorkspaceSelectors.isCurrentUserSatisfyWorkspaceMfa);
  const members = useSelector(WorkspaceMembersSelectors.getAll);
  const isOpen = useSelector(DialogsSelectors.isOpen(DialogType.ManageSeats));

  useEffect(() => {
    if (isCurrentUserSatisfyWorkspaceMfa) {
      dispatch(WorkspaceMembersActions.loadAll());
    }
  }, [workspace?.id, currentUser?.id]);

  if (!members || !workspace) {
    return null;
  }

  return (
    <Dialog {...props} open={isOpen} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>
        <Trans>Manage Seats</Trans>
      </DialogTitle>
      <DialogContent>
        <ManageSeats membersCount={members.length} seatsCount={workspace.licensedSeats} />
      </DialogContent>
    </Dialog>
  );
};
