import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AIChatDialog } from './ai-chat-dialog/component';
import { AIChatIcon } from './ai-chat-icon/component';
import { useStyles } from './styles';

interface ChatProps {
  projectId: number;
  disabled?: boolean;
}

export const Chat: React.FC<ChatProps> = ({ projectId, disabled }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const [isAIChatDialogOpen, setIsAIChatDialogOpen] = useState(false);

  useEffect(() => {
    document.addEventListener('keydown', documentKeyDownHandler);

    return () => {
      document.removeEventListener('keydown', documentKeyDownHandler);
    };
  }, []);

  const documentKeyDownHandler = (e: KeyboardEvent) => {
    if (disabled) return null;

    const aButtonIsPressed = e.key === 'a' || e.keyCode === 65;

    if (e.altKey && aButtonIsPressed) {
      setIsAIChatDialogOpen(currentState => !currentState);
    }
  };

  const openChatDialog = () => {
    setIsAIChatDialogOpen(true);
  };

  const closeChatDialog = () => {
    setIsAIChatDialogOpen(false);
  };

  return (
    <>
      <Tooltip
        title={isAIChatDialogOpen ? '' : `${t('AI Assistant')} (ALT + A)`}
        placement={'bottom'}
        arrow
        disableInteractive
      >
        <div>
          <AIChatIcon className={styles.chatIcon} onClick={openChatDialog} />
        </div>
      </Tooltip>

      <AIChatDialog projectId={projectId} open={isAIChatDialogOpen} onDialogClose={closeChatDialog} />
    </>
  );
};
