import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Task } from '@priz/shared/src/models/task';
import { TaskActions } from '../store/actions/task.actions';
import { TaskSelectors } from '../store/selectors/task.selectors';
import { DateAdapterType, DateTimePickerButton } from '../../date-time-picker-button/component';
import moment from 'moment';

interface DueDateButtonProps {
  task: Task;
  disabled?: boolean;
}

export const DueDateButton: React.FC<DueDateButtonProps> = ({ task, disabled }) => {
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(false);

  const isUpdating = useSelector(TaskSelectors.isUpdating(task.project.id));

  useEffect(() => {
    if (showLoading && !isUpdating) setShowLoading(false);
  }, [isUpdating]);

  const changeHandler = (value: Date) => {
    dispatch(TaskActions.updateDueDate(task.project.id, task.id, value));
    setShowLoading(true);
  };

  return (
    <DateTimePickerButton
      adapter={DateAdapterType.Moment}
      value={moment(task.dueDate)}
      onChange={changeHandler}
      disabled={disabled}
      loading={showLoading}
    />
  );
};
