export enum CrumbType {
  Home = 'Home',
  ProjectList = 'ProjectList',
  Project = 'Project',
  Overview = 'Overview',
  Publish = 'Publish',
  Tasks = 'Tasks',
  Meetings = 'Meetings',
  ProblemStatement = 'ProblemStatement',
  ToolsList = 'ToolsList',
  Tool = 'Tool',
  Ideas = 'Ideas',
  Solution = 'Solution',
  Report = 'Report',
}

export interface RouteCrumbProps {
  minify?: boolean;
  params?: { [key: string]: string | number };
}

export interface CrumbProps extends RouteCrumbProps {
  text: string;
  type: CrumbType;
  to?: string;
}

export type BreadcrumbsPath = (CrumbType | CrumbProps)[];
