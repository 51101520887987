import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    flex: '0 0 auto',
  },
  contentContainer: {
    backgroundColor: pgColorScheme.coral,
    color: pgColorScheme.white,
  },
  link: {
    color: pgColorScheme.darkBlue,
  },
});
