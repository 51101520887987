import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { ParameterActions } from '../store/actions';
import { InventivePrinciplesSelectors } from '../store/selectors/InventivePrinciplesSelectors';
import { EditableTable } from '../../react/editable-table/component';
import { UpdateParameterCommand } from '../services/parameter.service';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';

export const ParametersSettings: React.FC = () => {
  const dispatch = useDispatch();

  const parameters = useSelector(InventivePrinciplesSelectors.getParameters);
  const isLoaded = useSelector(InventivePrinciplesSelectors.isParametersLoaded);

  const updatingIds = useSelector(InventivePrinciplesSelectors.getParametersUpdatingIds);

  useEffect(() => {
    dispatch(ParameterActions.loadParameters());
  }, []);

  const onChangeHandler = (row, col, val) => {
    const parameter = { ...row, [col]: val };

    const command: UpdateParameterCommand = {
      title: parameter.title,
      titleZh: parameter.titleZh,
      titleEs: parameter.titleEs,
      description: parameter.description,
      descriptionZh: parameter.descriptionZh,
      descriptionEs: parameter.descriptionEs,
    };

    dispatch(ParameterActions.updateParameter(parameter.id, command));
  };

  return (
    <Box>
      {!isLoaded && (
        <Box py={10}>
          <LoadingOverlay loading={true} backdropStyles={{ backgroundColor: 'transparent' }} />
        </Box>
      )}

      {isLoaded && (
        <EditableTable
          rowsArray={parameters}
          columnsToDisplayMap={{
            id: [{ propName: 'id' }],
            title: [
              { propName: 'title', label: 'en', editable: true },
              { propName: 'titleZh', label: 'cn', editable: true },
              { propName: 'titleEs', label: 'es', editable: true },
            ],
            description: [
              { propName: 'description', label: 'en', editable: true },
              { propName: 'descriptionZh', label: 'cn', editable: true },
              { propName: 'descriptionEs', label: 'es', editable: true },
            ],
          }}
          titlesArray={[
            { label: 'No.', sortParameter: 'id' },
            { label: 'Title', sortParameter: 'title' },
            { label: 'Description', sortParameter: 'description' },
          ]}
          columnsWidth={{
            id: '5%',
            title: '20%',
          }}
          onChange={onChangeHandler}
          loadingIds={updatingIds}
        />
      )}
    </Box>
  );
};
