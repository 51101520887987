import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    'width': '100%',
    'height': '100%',
    'background': 'transparent',
    'position': 'absolute',
    'left': 0,
    'right': 0,
    'top': 0,
    'bottom': 0,
    'zIndex': 9,

    '&._cross': {
      cursor: 'crosshair',
    },
  },
  dropArea: {
    width: '100%',
    height: '100%',
  },
});
