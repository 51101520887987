import React, { ReactNode, useState } from 'react';
import { ToolType, ToolUtilization } from '@priz/shared/src/models/tools';
import { FiveWhysUtilizationActions } from '../../five-whys/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { ToolTypeDataMap } from '@priz/shared/src/data/tool-type-data-map';
import { RrmUtilizationActions } from '../../rrm/store/actions/rrm-utilization.actions';
import { UimUtilizationActions } from '../../uim/store/actions/uim-utilization.actions';
import { ApaUtilizationActions } from '../../apa/store/actions';
import { EbsUtilizationActions } from '../../ebs/store/actions';
import { NineWindowsUtilizationActions } from '../../nine-windows/store/actions';
import { CecUtilizationActions } from '../../cec/store/actions';
import { PMapUtilizationActions } from '../../pmap/store/actions';
import { SfmUtilizationActions } from '../../sfm/store/actions';
import { FortyPrinciplesUtilizationActions } from '../../forty-principles/store/actions';
import { Button, ButtonProps } from '@mui/material';
import { PfmUtilizationActions } from '../../pfm/store/actions';
import { PfmUtilization } from '@priz/shared/src/models/tools/pfm';
import { deleteStepBySfmId } from '../../pfm/utils';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { useNavigate } from 'react-router-dom';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { CftUtilizationActions } from '../../cft/store/actions';
import { PfmTab } from '../../pfm/store/model';

export interface DeleteToolButtonProps extends ButtonProps {
  utilization: ToolUtilization;
  parentUtilization?: ToolUtilization;
  customButton?: ReactNode;
}

export const DeleteToolButton: React.FC<DeleteToolButtonProps> = ({
  utilization,
  parentUtilization,
  customButton,
  ...rest
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const isRemoving = useSelector(ToolUtilizationSelector.isRemovingByUtilizationId(utilization.id));

  const handleDelete = () => {
    setDeleteConfirmOpen(true);
  };

  const handleOnDeleteConfirmClose = () => {
    setDeleteConfirmOpen(false);
  };

  const doDelete = () => {
    switch (utilization.type) {
      case ToolType.UIM:
        dispatch(UimUtilizationActions.delete(utilization.id, utilization.project?.id, navigate));
        break;
      case ToolType.NINE_WINDOWS:
        dispatch(NineWindowsUtilizationActions.delete(utilization.id, utilization.project?.id, navigate));
        break;
      case ToolType.CEC:
        dispatch(CecUtilizationActions.delete(utilization.id, utilization.project?.id, navigate));
        break;
      case ToolType.EBS:
        dispatch(EbsUtilizationActions.delete(utilization.id, utilization.project?.id, navigate));
        break;
      case ToolType.APA:
        dispatch(ApaUtilizationActions.delete(utilization.id, utilization.project?.id, navigate));
        break;
      case ToolType.RRM:
        dispatch(RrmUtilizationActions.delete(utilization.id, utilization.project?.id, navigate));
        break;
      case ToolType.FIVE_WHYS:
        dispatch(
          FiveWhysUtilizationActions.deleteFiveWhysUtilization(utilization.id, utilization.project?.id, navigate),
        );
        break;
      case ToolType.P_MAP:
        dispatch(PMapUtilizationActions.delete(utilization.id, utilization.project?.id, navigate));
        break;
      case ToolType.SFM:
        if (parentUtilization) {
          const pfm = parentUtilization as PfmUtilization;

          LocalStorageService.setItem(LocalStorageKey.LastViewedToolTab, PfmTab.Model);
          LocalStorageService.setItem(LocalStorageKey.SwitchToLastViewedToolTab, 'true');

          dispatch(
            PfmUtilizationActions.update(
              utilization.parentId,
              {
                diagramData: deleteStepBySfmId(utilization.id, pfm.diagramData.steps),
              },
              utilization.project?.id,
            ),
          );
        }

        dispatch(SfmUtilizationActions.delete(utilization.id, utilization.project?.id, parentUtilization, navigate));
        break;
      case ToolType.PFM:
        dispatch(PfmUtilizationActions.delete(utilization.id, utilization.project?.id, navigate));
        break;
      case ToolType.TOOL_40_PRINCIPLES:
        dispatch(FortyPrinciplesUtilizationActions.delete(utilization.id, utilization.project?.id, navigate));
        break;
      case ToolType.CFT:
        dispatch(CftUtilizationActions.deleteUtilization(utilization.id, utilization.project?.id, navigate));
        break;
      default:
        throw new Error(`Unsupported tool: ${utilization.type}`);
    }
  };

  return (
    <>
      {customButton && <div onClick={handleDelete}>{customButton}</div>}

      {!customButton && (
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleDelete} {...rest}>
          <Trans>Delete</Trans>
        </Button>
      )}

      <PgConfirmationDialog
        isOpen={deleteConfirmOpen}
        okButtonText={<Trans>Delete</Trans>}
        confirmContent={
          <>
            <Trans>
              Are you sure you want to cancel the current usage of {ToolTypeDataMap[utilization.type].name}?
            </Trans>
            <br />
            <Trans>All data related to this tool usage will be permanently deleted!</Trans>
          </>
        }
        confirmTitle={<Trans>Confirm delete</Trans>}
        onConfirm={doDelete}
        onClose={handleOnDeleteConfirmClose}
        loading={isRemoving}
      />
    </>
  );
};
