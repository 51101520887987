import { FluxStandardAction } from 'flux-standard-action';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { ToolType } from '@priz/shared/src/models/tools';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { createPgAction } from '../../../shared/store/action-creator';
import { RouterActionMeta } from '../../../shared/store/types';
import { EbsUtilizationApi } from '../../services/ebs-utilization.api';
import {
  EbsIdea,
  EbsIdeaDifficultyVariant,
  EbsUtilization,
  IEbsIdea,
  IEbsUtilization,
} from '@priz/shared/src/models/tools/ebs';
import { NavigateFunction } from 'react-router-dom';
import { ToolsUtils } from '../../../tools/utils';

export enum EbsUtilizationActionType {
  Update = 'ebs-utilization/update',
  Delete = 'ebs-utilization/delete',
  FetchIdeas = 'ebs-utilization/ideas/fetchAll',
  IdeaCreate = 'ebs-utilization/idea/create',
  IdeaUpdate = 'ebs-utilization/idea/update',
  IdeasUpdate = 'ebs-utilization/ideas/update',
  IdeaDelete = 'ebs-utilization/idea/delete',
}

type ApaUtilizationPayload = IEbsUtilization | IEbsIdea | IEbsIdea[] | number;

interface ApaUtilizationMeta extends RouterActionMeta {
  utilization?: EbsUtilization;
  projectId?: number;
  utilizationId?: number;
  ideaId?: number;
}

export type EbsUtilizationAction = FluxStandardAction<string, ApaUtilizationPayload, ApaUtilizationMeta>;

const doDelete = createPgAction(
  EbsUtilizationActionType.Delete,
  EbsUtilizationApi.delete,
  (utilizationId: number, projectId?: number, navigate?: NavigateFunction) => ({ projectId, utilizationId, navigate }),
  (_, { projectId, utilizationId, navigate }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_DELETED, {
      project_id: projectId,
      tool_type: ToolType.EBS,
      utilization_id: utilizationId,
    });

    if (navigate) {
      navigate(ToolsUtils.resolveListPath(projectId));
    }
  },
);

const update = createPgAction(
  EbsUtilizationActionType.Update,
  EbsUtilizationApi.update,
  (utilization: EbsUtilization) => ({ utilization }),
);

const fetchIdeas = createPgAction(
  EbsUtilizationActionType.FetchIdeas,
  EbsUtilizationApi.getAll,
  (utilizationId: number) => ({ utilizationId }),
);

const createIdea = createPgAction(
  EbsUtilizationActionType.IdeaCreate,
  EbsUtilizationApi.createIdea,
  (
    utilizationId: number,
    description: string,
    nodeId: string,
    x?: number,
    y?: number,
    validation?: EbsIdeaDifficultyVariant,
    implementation?: EbsIdeaDifficultyVariant,
  ) => ({
    utilizationId,
    description,
    nodeId,
    x,
    y,
    validation,
    implementation,
  }),
  (_, { utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.EBS_IDEA_CREATED, {
      utilization_id: utilizationId,
    });
  },
);

const updateIdea = createPgAction(
  EbsUtilizationActionType.IdeaUpdate,
  EbsUtilizationApi.updateIdea,
  (
    utilizationId: number,
    ideaId: number,
    description: string,
    x?: number,
    y?: number,
    validation?: EbsIdeaDifficultyVariant,
    implementation?: EbsIdeaDifficultyVariant,
    resetLatestRankingScore?: boolean,
  ) => ({
    description,
    ideaId,
    utilizationId,
    x,
    y,
    validation,
    implementation,
    resetLatestRankingScore,
  }),
  (_, { utilizationId, ideaId }) => {
    AnalyticsService.track(AnalyticsEvent.EBS_IDEA_UPDATED, {
      idea_id: ideaId,
      utilization_id: utilizationId,
    });
  },
);

const updateIdeas = createPgAction(
  EbsUtilizationActionType.IdeasUpdate,
  EbsUtilizationApi.updateIdeas,
  (utilizationId: number, ideas: EbsIdea[]) => ({
    utilizationId,
    ideas,
  }),
  (_, { utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.EBS_IDEAS_UPDATED, {
      utilization_id: utilizationId,
    });
  },
);

const deleteIdea = createPgAction(
  EbsUtilizationActionType.IdeaDelete,
  EbsUtilizationApi.deleteIdea,
  (utilizationId: number, ideaId: number) => ({ utilizationId, ideaId }),
  (_, { utilizationId, ideaId }) => {
    AnalyticsService.track(AnalyticsEvent.EBS_IDEA_DELETED, {
      idea_id: ideaId,
      utilization_id: utilizationId,
    });
  },
);

export const EbsUtilizationActions = {
  update,
  createIdea,
  delete: doDelete,
  deleteIdea,
  fetchIdeas,
  updateIdea,
  updateIdeas,
};
