import { FluxStandardAction } from 'flux-standard-action';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { ToolType } from '@priz/shared/src/models/tools';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { createPgAction } from '../../../shared/store/action-creator';
import { ApaUtilization, IApaUtilization } from '@priz/shared/src/models/tools/apa';
import { ApaUtilizationApi } from '../../service';
import { NavigateFunction } from 'react-router-dom';
import { ToolsUtils } from '../../../tools/utils';

export enum ApaUtilizationActionType {
  Update = 'apaUtilization/update',
  Delete = 'apaUtilization/delete',
}

type ApaUtilizationActionPayload = IApaUtilization;

interface ApaUtilizationMeta {
  utilization?: Partial<ApaUtilization>;
  projectId?: number;
  utilizationId?: number;
  navigate?: NavigateFunction;
}

export type ApaUtilizationAction = FluxStandardAction<string, ApaUtilizationActionPayload, ApaUtilizationMeta>;

const update = createPgAction(
  ApaUtilizationActionType.Update,
  ApaUtilizationApi.update,
  (utilization: ApaUtilization) => ({
    utilization,
  }),
  (_payload, { utilization }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: utilization.project?.id,
      tool_type: ToolType.APA,
      utilization_id: utilization.id,
    });
  },
);

const doDelete = createPgAction(
  ApaUtilizationActionType.Delete,
  ApaUtilizationApi.delete,
  (utilizationId: number, projectId?: number, navigate?: NavigateFunction) => ({ projectId, utilizationId, navigate }),
  (_, { projectId, utilizationId, navigate }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_DELETED, {
      project_id: projectId,
      tool_type: ToolType.APA,
      utilization_id: utilizationId,
    });

    if (navigate) {
      navigate(ToolsUtils.resolveListPath(projectId));
    }
  },
);

export const ApaUtilizationActions = {
  delete: doDelete,
  update,
};
