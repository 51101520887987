import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCubes } from '@fortawesome/free-solid-svg-icons';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DialogsActions } from '../../dialogs/store/actions';
import { DialogType } from '../../dialogs/store/model';

export const PlanUpgradeButton: React.FC<Partial<ButtonProps>> = ({ onClick, children, ...rest }) => {
  const dispatch = useDispatch();

  const clickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    dispatch(DialogsActions.open(DialogType.PlanUpgrade));

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Button
      variant={'pg_rounded'}
      color={'pg_blue_solid'}
      size={'pg_small'}
      startIcon={<FontAwesomeIcon style={{ fontSize: 18 }} icon={faCubes} />}
      onClick={clickHandler}
      {...rest}
    >
      {children || <Trans>Upgrade</Trans>}
    </Button>
  );
};
