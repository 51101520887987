import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ButtonProps, Tooltip } from '@mui/material';
import { RrmUtils } from '../utils/rrm-utils';
import { LoadingButton } from '@mui/lab';

export interface RankingButtonProps extends ButtonProps {
  itemsCount: number;
  buttonText?: string;
  smart?: boolean;
  loading?: boolean;
}

export const RankingButton: React.FC<RankingButtonProps> = ({
  itemsCount,
  buttonText,
  smart,
  disabled,
  loading,
  ...rest
}) => {
  const { t } = useTranslation();
  const comparisonsCount = RrmUtils.resolveRrmComparisonsCount(itemsCount, smart);

  const renderButton = () => {
    return (
      <LoadingButton
        variant={'pg_rounded'}
        color={'pg_orange_solid'}
        disabled={disabled || loading}
        loading={loading}
        {...rest}
      >
        <Trans>{buttonText ? buttonText : smart ? 'Start smart ranking' : 'Start full ranking'}</Trans>
        {!!comparisonsCount && ` (${comparisonsCount})`}
      </LoadingButton>
    );
  };

  if (disabled) {
    return renderButton();
  }

  return (
    <Tooltip
      title={comparisonsCount ? `${t('Comparisons count')}: ${comparisonsCount}` : ''}
      arrow={true}
      disableInteractive={true}
      placement={'top'}
    >
      <div>{renderButton()}</div>
    </Tooltip>
  );
};
