import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Status } from '@priz/shared/src/components/status/component';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Idea } from '@priz/shared/src/models/idea';
import { resolveIdeaAuthor } from '@priz/shared/src/utils/ideas/ideas-utils';
import { ProjectAssigneeSelectors } from '../../assignment/store/selectors/project-assignee.selectors';
import { GrayLabelContainer } from '../../content-containers/gray-label-container/component';
import { Assignee } from '../../assignment/assignee/component';
import { IdeasCommentsSelectors } from '../store';
import { resolveValueByColorType, ValueColorType } from '@priz/shared/src/utils/common';
import { pgColorScheme } from '@priz/shared/src/theme';
import { resolveCommentsSummary } from '../utils';

interface IdeaInfoProps {
  idea: Idea;
}

export const IdeaInfo: React.FC<IdeaInfoProps> = ({ idea }) => {
  const assignees = useSelector(ProjectAssigneeSelectors.getProjectAssignees(idea.project.id));
  const comments = useSelector(IdeasCommentsSelectors.getAllByIdeaId(idea.id));

  const author = resolveIdeaAuthor(idea, assignees);
  const { rating, validCount } = resolveCommentsSummary(comments);

  const ratingColor = resolveValueByColorType({
    value: rating,
    green: 70,
    blue: 30,
    valuesMap: {
      [ValueColorType.Green]: pgColorScheme.green,
      [ValueColorType.Blue]: pgColorScheme.blue,
      [ValueColorType.Red]: pgColorScheme.coral,
    },
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <GrayLabelContainer label={'Status'} flexDirection={'column'} spacing={0.5}>
          <Status status={idea.status} />
        </GrayLabelContainer>
      </Grid>

      {!!validCount && (
        <Grid item xs={12}>
          <GrayLabelContainer label={'Rating'} flexDirection={'column'} spacing={0}>
            <Typography variant={'h6'} sx={{ color: ratingColor }}>
              {rating}%
            </Typography>
          </GrayLabelContainer>
        </Grid>
      )}

      {author && (
        <Grid item xs={12}>
          <GrayLabelContainer label={'Author'} flexDirection={'column'} spacing={0.5}>
            <Assignee assignee={author.user} size={'small'} />
          </GrayLabelContainer>
        </Grid>
      )}

      <Grid item xs={12}>
        <GrayLabelContainer label={'Date created'} flexDirection={'column'} spacing={0.5}>
          <Typography variant={'subtitle2'}>{format(idea.dateCreated, 'MMM d yyyy h:mm aaa')}</Typography>
        </GrayLabelContainer>
      </Grid>
    </Grid>
  );
};
