import * as React from "react";

export const ReactComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="#08445E" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.85156 7.85156V15.6546H23.3871V7.85156H7.85156ZM7.38281 5.85938C6.54144 5.85938 5.85938 6.54144 5.85938 7.38281V16.1234C5.85938 16.9647 6.54144 17.6468 7.38281 17.6468H23.8559C24.6973 17.6468 25.3793 16.9647 25.3793 16.1234V7.38281C25.3793 6.54144 24.6973 5.85938 23.8559 5.85938H7.38281Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M36.6128 7.85156V15.6546H52.1484V7.85156H36.6128ZM36.144 5.85938C35.3027 5.85938 34.6206 6.54144 34.6206 7.38281V16.1234C34.6206 16.9647 35.3027 17.6468 36.144 17.6468H52.6171C53.4585 17.6468 54.1406 16.9647 54.1406 16.1234V7.38281C54.1406 6.54144 53.4585 5.85938 52.6171 5.85938H36.144Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M36.1767 12.7021H23.7764V10.71H36.1767V12.7021Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.85156 26.1455V33.9485H23.3871V26.1455H7.85156ZM7.38281 24.1533C6.54144 24.1533 5.85938 24.8354 5.85938 25.6768V34.4173C5.85938 35.2587 6.54144 35.9407 7.38281 35.9407H23.8559C24.6973 35.9407 25.3793 35.2587 25.3793 34.4173V25.6768C25.3793 24.8354 24.6973 24.1533 23.8559 24.1533H7.38281Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M36.6128 26.1455V33.9485H52.1484V26.1455H36.6128ZM36.144 24.1533C35.3027 24.1533 34.6206 24.8354 34.6206 25.6768V34.4173C34.6206 35.2587 35.3027 35.9407 36.144 35.9407H52.6171C53.4585 35.9407 54.1406 35.2587 54.1406 34.4173V25.6768C54.1406 24.8354 53.4585 24.1533 52.6171 24.1533H36.144Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M36.1767 30.9961H23.7764V29.0039H36.1767V30.9961Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.85156 44.3455V52.1485H23.3871V44.3455H7.85156ZM7.38281 42.3533C6.54144 42.3533 5.85938 43.0353 5.85938 43.8767V52.6172C5.85938 53.4586 6.54144 54.1407 7.38281 54.1407H23.8559C24.6973 54.1407 25.3793 53.4586 25.3793 52.6172V43.8767C25.3793 43.0353 24.6973 42.3533 23.8559 42.3533H7.38281Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M36.6128 44.3455V52.1485H52.1484V44.3455H36.6128ZM36.144 42.3533C35.3027 42.3533 34.6206 43.0353 34.6206 43.8767V52.6172C34.6206 53.4586 35.3027 54.1407 36.144 54.1407H52.6171C53.4585 54.1407 54.1406 53.4586 54.1406 52.6172V43.8767C54.1406 43.0353 53.4585 42.3533 52.6171 42.3533H36.144Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M36.1767 49.1958H23.7764V47.2036H36.1767V49.1958Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M45.3296 16.1381V25.7566H43.3374V16.1381H45.3296Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.5684 34.3378V43.9563H14.5762L14.5762 34.3378H16.5684Z"/>
  </svg>
);
