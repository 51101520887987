import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    '& .ql-editor': {
      '& p:last-child': {
        marginBottom: 0,
      },
      '& img': {
        display: 'inline-block',
        maxWidth: '100%',
      },
    },
    '& .matching-principles-list': {
      '& > *': {
        pageBreakInside: 'avoid',
      },
    },
  },
  projectTitle: {
    whiteSpace: 'break-spaces',
  },
  description: {
    whiteSpace: 'pre-wrap',
  },
  logo: {
    height: 40,
    width: 'auto',
    display: 'block',
  },
  content: {
    width: '100%',
    margin: '0 auto',
  },
  page: {
    'minHeight': '297mm', // A4 297mm
    'maxWidth': 'calc(210mm)', // A4 210mm
    'padding': '10mm',
    'boxSizing': 'border-box',
    'width': '100%',
    'margin': '0 auto 20px',
    'background': pgColorScheme.white,
    'border': '1px solid rgba(0, 0, 0, 0.12)',

    '& > *:not(.break)': {
      pageBreakInside: 'avoid',
    },

    '@media print': {
      minHeight: 'calc(100vh - 1px)',
      maxWidth: 'none',
      border: 'none',
      padding: 0,
      margin: 0,
    },
  },
  flexRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  fullWidth: {
    width: '100%',
  },
  preventChildrenBreaking: {
    '& > *:not(.break)': {
      pageBreakInside: 'avoid',
    },
  },
});
