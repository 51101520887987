import React, { useEffect, useState } from 'react';
import { Box, BoxProps, Button, Grid } from '@mui/material';
import { Trans } from 'react-i18next';
import { User } from '@priz/shared/src/models/security/user';
import { Profile } from '@priz/shared/src/models/security/profile';
import { useDispatch, useSelector } from 'react-redux';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { UserActions } from '../../user/store/actions/user.actions';
import { ReactHookFormText } from '../../react/form-elements';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { ChevronRightRounded, CheckRounded } from '@mui/icons-material';
import { ValidatorRules } from '@priz/shared/src/models/form';
import { AnimatedContainer } from '../../animated-container/component';
import { UserSettingsSelectors } from '../../user/store/selectors/user-settings.selectors';
import { UserSettingsActions } from '../../user/store/actions/user-settings.actions';
import { copyObject } from '@priz/shared/src/utils/common';
import { ReactHookFormPhoneInput } from '../../react/form-elements/phone/component';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { StepsIndication } from '../../react/steps-indication/component';

interface ProfileFormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  organization: string;
  location: string;
}

const resolveFormData = (user?: User): ProfileFormData => {
  const profile = user?.profile || ({} as Profile);

  return {
    firstName: profile.firstName || '',
    lastName: profile.lastName || '',
    phoneNumber: profile.phoneNumber || '',
    organization: profile.organization || '',
    location: profile.location || '',
  };
};

enum ProfileFieldType {
  Text = 'Text',
  Phone = 'Phone',
}

interface ProfileFieldProps {
  title: string;
  name: keyof ProfileFormData;
  type: ProfileFieldType;
  rules?: ValidatorRules;
}

const formFields: ProfileFieldProps[][] = [
  [
    {
      title: 'First name',
      name: 'firstName',
      type: ProfileFieldType.Text,
    },
    {
      title: 'Last name',
      name: 'lastName',
      type: ProfileFieldType.Text,
    },
  ],
  [
    {
      title: 'Organization',
      name: 'organization',
      type: ProfileFieldType.Text,
    },
  ],
  [
    {
      title: 'Phone number',
      name: 'phoneNumber',
      type: ProfileFieldType.Phone,
    },
  ],
  [
    {
      title: 'Location (City, State, Country)',
      name: 'location',
      type: ProfileFieldType.Text,
    },
  ],
];

interface ProfileCompletionFormProps extends BoxProps {
  onComplete?: () => void;
}

export const ProfileCompletionForm: React.FC<ProfileCompletionFormProps> = ({ onComplete, ...rest }) => {
  const dispatch = useDispatch();

  const [activeSlide, setActiveSlide] = useState(0);
  const [switchToNextSlideAfterUpdating, setSwitchToNextSlideAfterUpdating] = useState(false);
  const [completeProfileAfterUpdating, setCompleteProfileAfterUpdating] = useState(false);
  const [callOnCompleteCallback, setCallOnCompleteCallback] = useState(false);

  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const isProfileUpdating = useSelector(UserSelectors.isProfileUpdating);

  const userSettings = useSelector(UserSettingsSelectors.settings);
  const areUserSettingsLoaded = useSelector(UserSettingsSelectors.isLoaded);
  const areUserSettingsUpdating = useSelector(UserSettingsSelectors.isUpdating);
  const areUserSettingsUpdated = useSelector(UserSettingsSelectors.isUpdated);

  const isLastSlide = activeSlide >= formFields.length - 1;

  const { control, getValues, trigger, reset } = useForm({
    shouldFocusError: false,
    defaultValues: resolveFormData(currentUser),
  });

  useEffect(() => {
    if (!currentUser) dispatch(UserActions.loadCurrentUser());
    if (!areUserSettingsLoaded) dispatch(UserSettingsActions.load());
  }, []);

  useEffect(() => {
    if (currentUser?.profile) reset(resolveFormData(currentUser));
  }, [currentUser]);

  useEffect(() => {
    if (switchToNextSlideAfterUpdating && !isProfileUpdating) {
      setSwitchToNextSlideAfterUpdating(false);
      switchToNextSlide();
    }
  }, [switchToNextSlideAfterUpdating, isProfileUpdating]);

  useEffect(() => {
    if (completeProfileAfterUpdating && !isProfileUpdating) {
      setCompleteProfileAfterUpdating(false);
      completeProfile();
    }
  }, [completeProfileAfterUpdating, isProfileUpdating]);

  useEffect(() => {
    const isCompleted = userSettings?.global?.userProfileCompleted;

    if (callOnCompleteCallback && areUserSettingsUpdated && isCompleted && onComplete) {
      setCallOnCompleteCallback(false);
      onComplete();
    }
  }, [callOnCompleteCallback, areUserSettingsLoaded, areUserSettingsUpdated]);

  const nextHandler = (done?: boolean) => {
    const slideFieldsNames = formFields[activeSlide].map(field => field.name);
    const currentUserData = resolveFormData(currentUser);
    const formData = getValues();
    const hasChanges = slideFieldsNames.some(name => currentUserData[name] !== formData[name]);

    if (hasChanges) {
      trigger(slideFieldsNames).then(isValid => {
        if (!isValid) return null;

        dispatch(
          UserActions.updateCurrentUserProfile(
            {
              ...currentUser.profile,
              ...slideFieldsNames.reduce((map, name) => {
                map[name] = formData[name];
                return map;
              }, {} as ProfileFormData),
            },
            true,
          ),
        );

        done ? setCompleteProfileAfterUpdating(true) : setSwitchToNextSlideAfterUpdating(true);
      });
    } else {
      done ? completeProfile() : switchToNextSlide();
    }
  };

  const completeProfile = () => {
    const userSettingsCopy = copyObject(userSettings);

    userSettingsCopy.global.userProfileCompleted = true;

    setCallOnCompleteCallback(true);
    dispatch(UserSettingsActions.update({ settings: userSettingsCopy }));
  };

  const switchToPrevSlide = () => {
    setActiveSlide(currentState => currentState - 1);
  };

  const switchToNextSlide = () => {
    setActiveSlide(currentState => currentState + 1);
  };

  const resolveField = (props: ProfileFieldProps, key: number) => {
    switch (props.type) {
      case ProfileFieldType.Text:
        return (
          <ReactHookFormText
            key={key}
            control={control}
            fieldTitle={props.title}
            name={props.name}
            rules={props.rules}
            disabled={isProfileUpdating}
          />
        );

      case ProfileFieldType.Phone:
        return (
          <React.Fragment key={key}>
            <ReactHookFormPhoneInput
              control={control}
              fieldTitle={props.title}
              name={props.name}
              rules={props.rules}
              disabled={isProfileUpdating}
              formatValidation
            />
          </React.Fragment>
        );

      default:
        return null;
    }
  };

  if (!currentUser) {
    return (
      <LoadingOverlay
        loading={true}
        backdropStyles={{ backgroundColor: 'transparent', position: 'relative', margin: 'auto' }}
        progressContainerProps={{ py: 4 }}
      />
    );
  }

  return (
    <Box {...rest}>
      <StepsIndication stepsCount={formFields.length} activeStep={activeSlide} />

      {formFields.map((fieldsArray, slideKey) => (
        <AnimatedContainer key={slideKey} show={slideKey === activeSlide} boxSizing={'border-box'} px={1}>
          {fieldsArray.map((field, fieldKey) => resolveField(field, fieldKey))}

          <Grid container justifyContent={'flex-end'} spacing={1}>
            {activeSlide > 0 && (
              <Grid item>
                <Button
                  variant={'pg_rounded'}
                  color={'pg_orange_outlined'}
                  disabled={isProfileUpdating || areUserSettingsUpdating}
                  onClick={switchToPrevSlide}
                >
                  <Trans>Back</Trans>
                </Button>
              </Grid>
            )}

            <Grid item marginLeft={'auto'}>
              <LoadingButton
                variant={'pg_rounded'}
                color={'pg_orange_solid'}
                loading={isProfileUpdating || areUserSettingsUpdating}
                endIcon={isLastSlide ? <CheckRounded /> : <ChevronRightRounded />}
                onClick={() => nextHandler(isLastSlide)}
              >
                <Trans>{isLastSlide ? 'Done' : 'Next'}</Trans>
              </LoadingButton>
            </Grid>
          </Grid>
        </AnimatedContainer>
      ))}
    </Box>
  );
};
