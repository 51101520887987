import React, { useEffect, useState } from 'react';
import { LevelPlansMap, OrderedPlanLevelMap, Plan, PlanInterval, PlanLevel } from '@priz/shared/src/models/workspace';
import { PlanFeaturesMap } from '@priz/shared/src/models/workspace';
import { IntervalSelector } from '../interval-selector/component';
import { PlanCard } from '../plan-card/component';
import Slider, { Settings } from 'react-slick';
import { Box, Grid, Hidden, IconButton, Link, useMediaQuery, useTheme } from '@mui/material';
import { Trans } from 'react-i18next';
import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import { useStyles } from './styles';

interface PlansCardsSliderProps {
  currentPlan: Plan;
  selectedQuantitiesMap: { [key in PlanLevel]?: number };
  onIntervalChange: (newInterval: PlanInterval) => void;
  onSelectPlanLevel: (level: PlanLevel) => void;
  onSelectSeatsQuantity: (level: PlanLevel, value: number) => void;
  applicableLevels: PlanFeaturesMap;
  plansMap: LevelPlansMap;
  interval: PlanInterval;
  loading?: boolean;
  onCompare?: () => void;
}

const resolveNextPlanData = (
  currentPlanLevel: PlanLevel,
  applicableLevels: PlanFeaturesMap,
): { index: number; order: number; key: PlanLevel } => {
  const applicableLevelsKeys = Object.keys(applicableLevels);
  const currentPlanOrder = OrderedPlanLevelMap[currentPlanLevel];
  const nextPlanData = { index: 0, order: currentPlanOrder, key: currentPlanLevel };

  applicableLevelsKeys.find(key => {
    const keyOrder = OrderedPlanLevelMap[key];

    if (keyOrder > currentPlanOrder) {
      nextPlanData.index = applicableLevelsKeys.indexOf(key);
      nextPlanData.order = keyOrder;
      nextPlanData.key = key as PlanLevel;

      return true;
    }
  });

  return nextPlanData;
};

const resolveSlidesToShow = (isDownMdBp: boolean, isDownSmBp: boolean): number => {
  if (isDownSmBp) return 1;
  if (isDownMdBp) return 2;

  return 3;
};

export const PlansCardsSlider: React.FC<PlansCardsSliderProps> = ({
  currentPlan,
  loading,
  onIntervalChange,
  applicableLevels,
  plansMap,
  interval,
  onSelectPlanLevel,
  onSelectSeatsQuantity,
  selectedQuantitiesMap,
  onCompare,
}) => {
  const styles = useStyles();
  const theme = useTheme();

  const [sliderInstance, setSliderInstance] = useState<Slider>();
  const [activeSlide, setActiveSlide] = useState<number>();
  const [isStartEdge, setIsStartEdge] = useState(true);
  const [isEndEdge, setIsEndEdge] = useState(true);

  const isDownMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const isDownSmBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  const totalSlidesCount = Object.keys(applicableLevels).length;
  const slidesCountToShow = resolveSlidesToShow(isDownMdBreakpoint, isDownSmBreakpoint);
  const initialIndex = resolveNextPlanData(currentPlan?.level, applicableLevels).index;
  const showSliderArrows = Object.keys(applicableLevels).length > slidesCountToShow;

  const mediaQueryKey = `${isDownMdBreakpoint ? 'md-t' : 'md-f'}-${isDownSmBreakpoint ? 'sm-t' : 'sm-f'}`;

  const settings: Settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesCountToShow,
    slidesToScroll: 1,
    initialSlide:
      typeof activeSlide !== 'undefined' ? activeSlide : Math.min(initialIndex, totalSlidesCount - slidesCountToShow),
    beforeChange: (_oldIndex, newIndex) => {
      setActiveSlide(newIndex);
    },
  };

  useEffect(() => {
    if (typeof activeSlide === 'undefined') {
      setActiveSlide(initialIndex);
    }
  }, [initialIndex]);

  useEffect(() => {
    if (typeof activeSlide !== 'undefined') {
      setIsStartEdge(!activeSlide);
      setIsEndEdge(activeSlide >= totalSlidesCount - slidesCountToShow);
    }
  }, [activeSlide, slidesCountToShow, totalSlidesCount]);

  const onSliderRef = (slider: Slider) => {
    setSliderInstance(slider);
  };

  const renderCompareButton = () => {
    return (
      <Box p={2} mt={'auto'} boxSizing={'border-box'} width={'100%'} textAlign={'center'}>
        <Link component={'div'} onClick={onCompare}>
          <Trans>Compare all features</Trans>
        </Link>
      </Box>
    );
  };

  const renderButtonPrev = () => {
    return (
      <IconButton
        className={styles.sliderButton}
        onClick={() => {
          sliderInstance.slickPrev();
        }}
        disabled={isStartEdge}
      >
        <ChevronLeftRounded />
      </IconButton>
    );
  };

  const renderButtonNext = () => {
    return (
      <IconButton
        className={styles.sliderButton}
        onClick={() => {
          sliderInstance.slickNext();
        }}
        disabled={isEndEdge}
      >
        <ChevronRightRounded />
      </IconButton>
    );
  };

  return (
    <>
      <Box mb={1}>
        <Grid container alignItems={'flex-end'} justifyContent={'space-between'} spacing={1}>
          {showSliderArrows && (
            <Hidden smDown>
              {sliderInstance && (
                <Grid item xs={'auto'}>
                  {renderButtonPrev()}
                </Grid>
              )}
            </Hidden>
          )}

          <Grid item xs={true}>
            <IntervalSelector initialInterval={interval} onPlanSelect={onIntervalChange} />
          </Grid>

          {showSliderArrows && (
            <Hidden smDown>
              {sliderInstance && (
                <Grid item xs={'auto'}>
                  {renderButtonNext()}
                </Grid>
              )}
            </Hidden>
          )}
        </Grid>
      </Box>

      {showSliderArrows && (
        <Hidden smUp>
          <Box mt={2}>
            {sliderInstance && (
              <Grid container justifyContent={'space-between'}>
                <Grid item xs={'auto'}>
                  {renderButtonPrev()}
                </Grid>

                <Grid item xs={'auto'}>
                  {renderButtonNext()}
                </Grid>
              </Grid>
            )}
          </Box>
        </Hidden>
      )}

      <Slider key={mediaQueryKey} ref={onSliderRef} className={styles.slider} {...settings}>
        {Object.keys(applicableLevels).map((level: PlanLevel) => (
          <Box key={level} height={'100%'}>
            <Box p={1} height={'100%'} boxSizing={'border-box'}>
              <PlanCard
                cardPlanLevel={level}
                currentPlan={currentPlan}
                plansMap={plansMap}
                planInterval={interval}
                onSelectPlanLevel={onSelectPlanLevel}
                onSelectSeatsQuantity={onSelectSeatsQuantity}
                selectedQuantity={selectedQuantitiesMap[level] || 1}
                loading={loading}
                className={styles.planCard}
                footer={onCompare ? renderCompareButton() : undefined}
              />
            </Box>
          </Box>
        ))}
      </Slider>
    </>
  );
};
