import { EntityCollectionStatus, EntityMap, LookupMap } from '../common/entity-collection-state';
import { Team } from './team';

export interface TeamCollection {
  entities: EntityMap<Team>;

  lookups: {
    byWorkspaceId: LookupMap;
  };

  statuses: EntityCollectionStatus;
}

export const DefaultTeamCollection: TeamCollection = {
  entities: {},

  lookups: {
    byWorkspaceId: {},
  },

  statuses: {},
};
