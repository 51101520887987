import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { ToolSuggestion } from './tool-suggestions';
import { ReactNode } from 'react';

export interface AssistantHintData {
  text: string | null;
  content: ReactNode;
  variants: string[] | null;
  completeness: number | null;
  toolSuggestions: ToolSuggestion[] | null;
}

export interface AssistantCollectionHints {
  byAssistantId?: { [assistantId: string]: AssistantHintData };
}

export interface AssistantCollectionStatuses {
  byAssistantId?: { [assistantId: string]: EntityCollectionStatus };
}

export interface AssistantState {
  activeAssistantId?: string;
  hints: AssistantCollectionHints;
  statuses: AssistantCollectionStatuses;
}

export const DefaultAssistantState: AssistantState = {
  hints: {
    byAssistantId: {},
  },
  statuses: {
    byAssistantId: {},
  },
};
