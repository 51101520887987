import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { useStyles } from './styles';
import { TitleContainer } from '../../react/modules/title-container/component';
import { WorkspaceMembersActions } from '../store/actions';
import { WorkspaceMembersSelectors } from '../store/selectors/workspace-members.selectors';
import { WorkspaceSelectors } from '../store/selectors';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { InvitationDialog } from '../invitation-dialog/component';
import { LoadingButton } from '@priz/shared/src/components/loading-button/component';
import { WorkspaceMemberItem } from '../workspace-member-item/component';
import { WorkspaceUtils } from '../services';
import { SeatsBar } from '../../billing/seats-bar/component';
// import {CustomBlocker, PaywallActions} from "../../react/modules/paywall/store";

export const WorkspaceMembers: React.FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [isInvitationDialogOpen, setIsInvitationDialogOpen] = useState(false);

  const workspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const members = useSelector(WorkspaceMembersSelectors.getAll);
  const isCreating = useSelector(WorkspaceMembersSelectors.isCreating);
  // const isSeatsLimitHit = useSelector(WorkspaceSelectors.isSeatsLimitHit());

  useEffect(() => {
    dispatch(WorkspaceMembersActions.loadAll());
  }, []);

  const openInvitationDialog = () => {
    // if (isSeatsLimitHit) {
    //   dispatch(PaywallActions.show(CustomBlocker.Seats));
    // } else {
    //   setIsInvitationDialogOpen(true);
    // }

    setIsInvitationDialogOpen(true);
  };

  const closeInvitationDialog = () => {
    setIsInvitationDialogOpen(false);
  };

  return (
    <>
      <TitleContainer>
        <PageTitleWithDocLink
          title={<Trans>Workspace Members</Trans>}
          oneLiner={<Trans>Here you can manage the members of this workspace</Trans>}
          docsUrl={'https://www.priz.guru/knowledge-base/workspace-members'}
        />

        <LoadingButton
          loading={isCreating}
          disabled={!workspace || WorkspaceUtils.isPersonalOrPro(workspace)}
          onClick={openInvitationDialog}
        >
          + <Trans>Add Member</Trans>
        </LoadingButton>
      </TitleContainer>

      {members && workspace?.licensedSeats && (
        <Box mb={2}>
          <SeatsBar workspace={workspace} members={members} />
        </Box>
      )}

      <TableContainer component={Paper} variant={'outlined'}>
        <Table size="small">
          <TableHead className={styles.tableHead}>
            <TableRow>
              {['First name', 'Last name', 'Email', 'Membership level', 'Status'].map((item, key) => (
                <TableCell key={key}>
                  <Box py={1}>
                    <Trans>{item}</Trans>
                  </Box>
                </TableCell>
              ))}
              <TableCell width={'5%'} />
            </TableRow>
          </TableHead>

          <TableBody>
            {members.map((member, key) => (
              <TableRow key={key}>
                <WorkspaceMemberItem member={member} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <InvitationDialog isOpen={isInvitationDialogOpen} onClose={closeInvitationDialog} disabled={isCreating} />
    </>
  );
};
