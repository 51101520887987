import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PreloaderActions } from './store/actions';
import { PreloaderSelectors } from './store/selectors';
import { LoadingOverlay, LoadingOverlaySettings } from '@priz/shared/src/components/loading-overlay/component';

interface PreloaderProps extends LoadingOverlaySettings {
  onLoad?: () => void;
}

export const Preloader: React.FC<PreloaderProps> = ({ onLoad, ...rest }) => {
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);
  const [documentComplete, setDocumentComplete] = useState(document.readyState === 'complete');

  const isLoading = useSelector(PreloaderSelectors.isLoading);

  const documentReadyStateHandler = () => {
    if (document.readyState === 'complete') {
      setDocumentComplete(true);
    }
  };

  useEffect(() => {
    dispatch(PreloaderActions.show());
    if (!document.onreadystatechange) document.onreadystatechange = documentReadyStateHandler;
  }, []);

  useEffect(() => {
    setLoaded(documentComplete && !isLoading);
  }, [documentComplete, isLoading]);

  useEffect(() => {
    if (onLoad && loaded) {
      setTimeout(() => {
        onLoad();
      }, 100);
    }
  }, [loaded]);

  return <LoadingOverlay loading={!loaded} {...rest} />;
};
