import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';

export const useStyles = makeStyles({
  '@keyframes flickerAnimation': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.1,
    },
    '100%': {
      opacity: 1,
    },
  },

  'root': {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    boxSizing: 'border-box',
    background: pgColorScheme.white,
  },

  'logo': {
    display: 'block',
    width: 250,
    height: 'auto',
    maxWidth: '100%',
    animation: '$flickerAnimation 2s infinite',
  },
});
