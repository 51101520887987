import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    '& .ql-clipboard': {
      display: 'none',
    },

    '& .ql-editor': {
      fontSize: 16,
      padding: 0,
      wordBreak: 'break-word',
    },

    '& img': {
      display: 'block',
      maxWidth: '100%',
    },

    '& p': {
      'marginTop': 0,
      'marginBottom': 12,

      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
});
