import { makeStyles } from '@mui/styles';
import { ToolType } from '@priz/shared/src/models/tools';

export const useStyles = makeStyles({
  '@global': {
    '@media print': {
      'body': {
        'height': 'auto !important',
        'padding': '0 !important',
        'margin': '0 !important',
        'background': '#fff !important',

        '& #hubspot-messages-iframe-container, & #chat-selector-button': {
          display: 'none !important',
        },
      },
      '#root': {
        display: 'block !important',
        height: 'auto !important',
      },
      '.page-container': {
        display: 'block !important',
        height: 'auto !important',
        minWidth: '0 !important',
        minHeight: '0 !important',
        placeContent: 'normal !important',
        width: 'auto !important',
        flex: 'none !important',
      },
      '.content-wrapper, .content-container': {
        flex: 'none !important',
        height: 'auto !important',
        padding: '0 !important',
      },
      '.sidenav-container, .app-project-progress, .header-container, .notification-line': {
        display: 'none !important',
      },
      '.page-content': {
        display: 'block !important',
        height: 'auto !important',
        width: 'auto !important',
        flex: 'none !important',
        overflow: 'hidden !important',
        background: 'transparent !important',
      },
      '.workbench-container': {
        display: 'block !important',
        height: 'auto !important',
        flex: 'none !important',
        padding: '0 !important',
        overflow: 'hidden !important',
      },
      [`.${ToolType.PFM}`]: {
        ['& .MuiPaper-root:not(.pfm-prescription)']: {
          pageBreakInside: 'avoid',
        },
      },
      [`.${ToolType.SFM}`]: {
        ['& .MuiPaper-root']: {
          pageBreakInside: 'avoid',
        },
      },
      [`.${ToolType.NINE_WINDOWS}`]: {
        ['& .MuiGrid-item']: {
          pageBreakInside: 'avoid',
        },
      },
      [`.${ToolType.P_MAP}`]: {
        ['& .MuiCard-root']: {
          pageBreakInside: 'avoid',
        },
      },
      [`.${ToolType.FIVE_WHYS}`]: {
        ['& [class*="contentWrap"]']: {
          pageBreakInside: 'avoid',
        },
      },
    },
  },
});
