import React, { useEffect, useState } from 'react';
import { Alert, Box } from '@mui/material';
import { MeetingCard } from '../meeting-card/component';
import { Meeting } from '../store/model';
import {
  AttendeeRoleFilterType,
  MeetingsPeriodFilterType,
  MeetingStatusFilterType,
} from '@priz/shared/src/models/meetings';
import { MeetingsFilterSelects } from '../meetings-filter-selects/component';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AttendeeSelectors } from '../store/selectors';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { filterAwaitingResponseMeetings, filterMeetings } from '../utils';
import { UserProjectSettingsActions } from '../../user/store/actions/user-project-settings.actions';
import { UserProjectSettingsSelectors } from '../../user/store/selectors/user-project-settings.selectors';
import { copyObject } from '@priz/shared/src/utils/common';
import { HashHighlightContainer } from '../../hash-highlight-container/component';

export interface MeetingsListProps {
  projectId: number;
  meetings: Meeting[];
}

export const MeetingsList: React.FC<MeetingsListProps> = ({ projectId, meetings }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const attendeeRequests = useSelector(AttendeeSelectors.getAllByProjectIdAndUserId(projectId, currentUser?.id));
  const userProjectSettings = useSelector(UserProjectSettingsSelectors.settings);
  const settingsAreLoaded = useSelector(UserProjectSettingsSelectors.isLoaded);

  const [timePeriod, setTimePeriod] = useState<MeetingsPeriodFilterType>(MeetingsPeriodFilterType.All);
  const [meetingStatus, setMeetingStatus] = useState<MeetingStatusFilterType>(MeetingStatusFilterType.All);
  const [attendeeRole, setAttendeeRole] = useState<AttendeeRoleFilterType>(AttendeeRoleFilterType.All);

  const awaitingResponseMeetings = filterAwaitingResponseMeetings(meetings, attendeeRequests, currentUser?.id);
  const isMeetingHighlighting = window.location.hash.indexOf('meeting') !== -1;

  const filteredMeetings = filterMeetings(
    meetings,
    attendeeRequests,
    currentUser?.id,
    timePeriod,
    meetingStatus,
    attendeeRole,
  );

  useEffect(() => {
    dispatch(UserProjectSettingsActions.load({ projectId }));
  }, []);

  useEffect(() => {
    if (!isMeetingHighlighting && settingsAreLoaded && !awaitingResponseMeetings.length) {
      const { period, status, role } = userProjectSettings.meetings.filters;

      if (period) setTimePeriod(period);
      if (status) setMeetingStatus(status);
      if (role) setAttendeeRole(role);
    }
  }, [settingsAreLoaded]);

  useEffect(() => {
    if (userProjectSettings) {
      const settingsCopy = copyObject(userProjectSettings);

      settingsCopy.meetings.filters.period = timePeriod;
      settingsCopy.meetings.filters.status = meetingStatus;
      settingsCopy.meetings.filters.role = attendeeRole;

      dispatch(UserProjectSettingsActions.update({ projectId, settings: settingsCopy }));
    }
  }, [timePeriod, meetingStatus, attendeeRole]);

  const periodChangeHandler = (value: MeetingsPeriodFilterType) => {
    setTimePeriod(value);
  };

  const meetingStatusChangeHandler = (value: MeetingStatusFilterType) => {
    setMeetingStatus(value);
  };

  const attendeeRoleChangeHandler = (value: AttendeeRoleFilterType) => {
    setAttendeeRole(value);
  };

  return (
    <div>
      <Box mb={2}>
        <MeetingsFilterSelects
          onPeriodChange={periodChangeHandler}
          onMeetingStatusChange={meetingStatusChangeHandler}
          onAttendeeRoleChange={attendeeRoleChangeHandler}
          periodDefaultValue={timePeriod}
          meetingStatusDefaultValue={meetingStatus}
          attendeeRoleDefaultValue={
            !isMeetingHighlighting && awaitingResponseMeetings.length
              ? AttendeeRoleFilterType.AwaitingResponse
              : attendeeRole
          }
        />
      </Box>

      {filteredMeetings
        .sort((a, b) => (a.date < b.date ? -1 : 1))
        .map(meeting => (
          <Box key={meeting.id} mb={2}>
            <HashHighlightContainer id={`meeting-${meeting.id}`} scrollContainerId={'meetings-view'} scrollOffset={15}>
              <MeetingCard meeting={meeting} />
            </HashHighlightContainer>
          </Box>
        ))}

      {!filteredMeetings.length && (
        <Box p={2}>
          <Alert severity={'info'}>
            <Trans>No matches found with selected filter parameters</Trans>
          </Alert>
        </Box>
      )}
    </div>
  );
};
