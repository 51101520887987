import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  assignees: {
    ['& .MuiAvatar-root']: {
      width: 24,
      height: 24,
      fontSize: 14,
      borderWidth: 1,
    },
  },
});
