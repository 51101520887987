import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { Box, Button, Grid, Typography } from '@mui/material';
// import { useStyles } from './styles';

// import {ReactComponent as CrossIcon} from '../../assets/icons/cross-framed.svg';

export interface TipPopupContentProps {
  title?: string;
  text?: string;
  okButtonText?: string;
  onClose?: () => void;
  extraButtons?: ReactElement;
  maxWidth?: number;
  showCrossButton?: boolean;
}

export const TipPopupContent: React.FC<TipPopupContentProps> = ({
  title,
  text,
  okButtonText,
  onClose,
  extraButtons,
  maxWidth,
  showCrossButton = false,
}) => {
  // const styles = useStyles();

  const closeHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Box maxWidth={maxWidth || 275}>
      {showCrossButton && (
        <Box display={'flex'} justifyContent={'flex-end'}>
          {/*<Box p={1} mt={-1} mr={-1} mb={-1} onClick={closeHandler} className={styles.popperCloseButton}>*/}
          {/*  <CrossIcon display={'block'} width={12} height={12} />*/}
          {/*</Box>*/}
        </Box>
      )}

      {title && (
        <Box mb={1}>
          <Typography component={'span'} variant={'subtitle1'}>
            <strong>
              <Trans>{title}</Trans>
            </strong>
          </Typography>
        </Box>
      )}

      {text && (
        <Box mb={1.5}>
          <Typography component={'span'} variant={'body2'}>
            <Trans>{text}</Trans>
          </Typography>
        </Box>
      )}

      <Grid container spacing={1}>
        {extraButtons && <Grid item>{extraButtons}</Grid>}

        <Grid item>
          <Button variant={'pg_rounded'} color={'pg_white_outlined'} size={'pg_small'} onClick={closeHandler}>
            <Trans>{okButtonText || 'ok, got it'}</Trans>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
