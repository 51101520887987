import axios, { AxiosResponse } from 'axios';
import { ProjectComment } from '@priz/shared/src/models/project';

const list = (page: number): Promise<{ comments: ProjectComment[]; count: number }> =>
  axios
    .get(`/v1/api/comments/not-approved/${page}`)
    .then((response: AxiosResponse<{ comments: ProjectComment[]; count: number }>) => response.data);

const approve = (commentId: number, version: number): Promise<ProjectComment> =>
  axios
    .put(`/v1/api/comment/${commentId}/approve/${version}`)
    .then((response: AxiosResponse<ProjectComment>) => response.data);

const discard = (commentId: number, version: number): Promise<ProjectComment> =>
  axios
    .put(`/v1/api/comment/${commentId}/discard/${version}`)
    .then((response: AxiosResponse<ProjectComment>) => response.data);

const doDelete = (commentId: number): Promise<ProjectComment> =>
  axios.delete(`/v1/api/comment/${commentId}`).then((response: AxiosResponse<ProjectComment>) => response.data);

export interface ProjectCommentsDeleteCommand {
  ids: number[];
}

const deleteByIds = (command: ProjectCommentsDeleteCommand): Promise<ProjectComment[]> =>
  axios.patch('/v1/api/comments/delete', command).then((response: AxiosResponse<ProjectComment[]>) => response.data);

export const AdminCommentsApi = {
  list,
  approve,
  discard,
  delete: doDelete,
  deleteByIds,
};
