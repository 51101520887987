import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    '& .MuiChip-avatar': {
      boxShadow: `100px 0 0 ${pgColorScheme.placeholder} inset`,
    },
    '&._filled': {
      boxShadow: `500px 0 0 ${pgColorScheme.backgroundMediumGray} inset`,
    },
  },
});
