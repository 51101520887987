import { FluxStandardAction } from 'flux-standard-action';
import { createPgAction } from '../../../shared/store/action-creator';
import { UserSettings, UserSettingsResponse } from '@priz/shared/src/models/user/user-settings';
import { UserSettingsApi } from '@priz/shared/src/services/user';

export enum UserSettingsActionType {
  Fetch = 'userSettings/fetch',
  Update = 'userSettings/update',
}

export type UserSettingsAction = FluxStandardAction<UserSettingsActionType, UserSettingsResponse>;

const load = createPgAction(UserSettingsActionType.Fetch, UserSettingsApi.get);

const update = createPgAction(UserSettingsActionType.Update, UserSettingsApi.update, (settings: UserSettings) => ({
  settings,
}));

export const UserSettingsActions = {
  load,
  update,
};
