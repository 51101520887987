import { FluxStandardAction } from 'flux-standard-action';
import { createPgAction } from '../../../shared/store/action-creator';
import { CompleteWorkspaceData, FeatureSetsCollection, IWorkspace, Workspace } from '@priz/shared/src/models/workspace';
import { WorkspaceSelectors } from '../selectors';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { UpdateWorkspaceCommand, WorkspaceApi } from '../../services';
import { RouterActionMeta } from '../../../shared/store/types';
import { NavigateFunction } from 'react-router-dom';

export enum WorkspaceActionType {
  FetchAll = 'workspace/fetchAll',
  Create = 'workspace/create',
  Update = 'workspace/update',
  Delete = 'workspace/delete',
  Complete = 'workspace/complete',
  UpdatePlan = 'workspace/plan/update',
  CancelSubscription = 'workspace/cancelSubscription',
  Switch = 'workspace/switch',
  Features = 'workspace/features',
  SetLicensedSeats = 'workspace/setLicensedSeats',
}

interface WorkspaceActionMeta extends RouterActionMeta {
  name?: string;
  email?: string;
  workspaceId?: number;
  quantity?: number;
}

type WorkspaceActionPayload = Workspace | Workspace[] | FeatureSetsCollection;

export type WorkspaceAction = FluxStandardAction<string, WorkspaceActionPayload, WorkspaceActionMeta>;

const loadAll = createPgAction(
  WorkspaceActionType.FetchAll,
  WorkspaceApi.getAllMy,
  undefined,
  undefined,
  undefined,
  WorkspaceSelectors.isLoaded,
);

const update = createPgAction(
  WorkspaceActionType.Update,
  WorkspaceApi.update,
  (command: UpdateWorkspaceCommand) => ({
    name: command.name,
  }),
  (payload: IWorkspace) => {
    AnalyticsService.track(AnalyticsEvent.WORKSPACE_UPDATED, {
      workspace_id: payload.id,
      workspace_type: payload.type,
    });
  },
);

const doDelete = createPgAction(WorkspaceActionType.Delete, WorkspaceApi.delete, (id: number) => ({
  id,
}));

const create = createPgAction(
  WorkspaceActionType.Create,
  WorkspaceApi.create,
  (name: string, email: string, numberOfSeats: number, navigate?: NavigateFunction) => ({
    email,
    name,
    numberOfSeats,
    navigate,
  }),
  (payload: IWorkspace, meta: WorkspaceActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.WORKSPACE_CREATED, {
      workspace_type: payload.type,
    });

    if (meta.navigate) meta.navigate(['/workspace', payload.id, 'switch'].join('/'));
  },
);

const complete = createPgAction(
  WorkspaceActionType.Complete,
  WorkspaceApi.complete,
  (organizationCompleteData: CompleteWorkspaceData) => ({
    organizationCompleteData,
  }),
  (payload: IWorkspace) => {
    AnalyticsService.track(AnalyticsEvent.WORKSPACE_COMPLETED, {
      workspace_id: payload.id,
      workspace_type: payload.type,
    });
  },
);

const updatePlan = createPgAction(
  WorkspaceActionType.UpdatePlan,
  WorkspaceApi.updatePlan,
  (organizationCompleteData: CompleteWorkspaceData) => ({
    organizationCompleteData,
  }),
  (payload: IWorkspace) => {
    AnalyticsService.track(AnalyticsEvent.WORKSPACE_PLAN_UPDATED, {
      workspace_id: payload.id,
      workspace_type: payload.type,
      quantity: payload.licensedSeats,
    });
  },
);

const cancelSubscription = createPgAction(
  WorkspaceActionType.CancelSubscription,
  WorkspaceApi.cancelSubscription,
  (navigate: NavigateFunction) => ({ navigate }),
  (payload: IWorkspace, meta: WorkspaceActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.WORKSPACE_CANCEL_SUBSCRIPTION, {
      workspace_id: payload.id,
      workspace_type: payload.type,
    });

    if (meta.navigate) meta.navigate('');
  },
);

const doSwitch = createPgAction(
  WorkspaceActionType.Switch,
  (workspaceId: number, teamId: number) =>
    new Promise(resolve =>
      resolve({
        workspaceId,
        teamId,
      }),
    ),
);

const setLicensedSeats = createPgAction(
  WorkspaceActionType.SetLicensedSeats,
  WorkspaceApi.setLicensedSeats,
  (quantity: number) => ({ quantity }),
  (payload: IWorkspace) => {
    AnalyticsService.track(AnalyticsEvent.WORKSPACE_SEATS_UPDATED, {
      workspace_id: payload.id,
      workspace_type: payload.type,
      seats: payload.licensedSeats,
    });
  },
);

const fetchFeatureSet = createPgAction(WorkspaceActionType.Features, WorkspaceApi.getFeatureSet);

export const WorkspaceActions = {
  cancelSubscription,
  switch: doSwitch,
  complete,
  create,
  updatePlan,
  delete: doDelete,
  update,
  loadAll,
  fetchFeatureSet,
  setLicensedSeats,
};
