import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PfmStep, PfmUtilization } from '@priz/shared/src/models/tools/pfm';
import { PfmViewer } from '@priz/shared/src/components/tools-viewers/pfm/component';
import { SfmUtilization } from '@priz/shared/src/models/tools/sfm';
import { ToolUtilizationSelector } from '../../store/selectors';
import { Box, Typography } from '@mui/material';
import {
  SfmEfficiencyViewer,
  SfmEfficiencyViewerBasicProps,
} from '@priz/shared/src/components/sfm-efficiency-viewer/component';
import { SfmResultSelectors } from '../../../sfm/store/selectors';
import { SfmUtilizationActions } from '../../../sfm/store/actions';
import { getMaxSfmRanks } from '@priz/shared/src/utils/pfm';

interface ReportPfmItemProps extends SfmEfficiencyViewerBasicProps {
  step: PfmStep;
  title: string;
}

const ReportPfmItem: React.FC<ReportPfmItemProps> = ({ step, title, ...rest }) => {
  const headerRenderer = () => {
    return (
      <Box px={1} py={0.5}>
        <Typography variant={'body2'} noWrap>
          {title || `Step ${step.index}`}
        </Typography>
      </Box>
    );
  };

  return (
    <SfmEfficiencyViewer
      pfmStepType={step.type}
      headerRenderer={headerRenderer}
      placeholderRenderer={() => <Box height={36} />}
      contentBoxProps={{ p: 1 }}
      {...rest}
    />
  );
};

export const PfmResult: React.FC<{ utilization: PfmUtilization }> = ({ utilization }) => {
  const dispatch = useDispatch();

  const allSfmUtilizationByParentId: SfmUtilization[] = useSelector(
    ToolUtilizationSelector.getAllByParentId(utilization.id),
  );

  const sfmRanks = useSelector(SfmResultSelectors.getSfmRanksByParentUtilizationId(utilization?.id));
  const isLoaded = useSelector(SfmResultSelectors.isLoadedByUtilizationId(utilization?.id));

  const { maxSfmFunctionalRank, maxSfmProblematicRank } = getMaxSfmRanks(sfmRanks);

  const sfmTitlesMap = allSfmUtilizationByParentId.reduce((map: { [key: number]: string }, sfm) => {
    map[sfm.id] = sfm.title;
    return map;
  }, {});

  useEffect(() => {
    if (!isLoaded && utilization) {
      dispatch(SfmUtilizationActions.calcSfmRanks(utilization.id, utilization.project?.id));
    }
  }, [isLoaded, utilization]);

  const renderSfmItem = (step: PfmStep) => {
    return (
      <ReportPfmItem
        step={step}
        title={sfmTitlesMap[step.sfmId]}
        sfmRank={sfmRanks[step.sfmId]}
        maxSfmFunctionalRank={maxSfmFunctionalRank}
        maxSfmProblematicRank={maxSfmProblematicRank}
      />
    );
  };

  if (!utilization) return null;

  return (
    <PfmViewer
      pfmUtilization={utilization}
      sfmUtilizationArray={allSfmUtilizationByParentId}
      sfmRankMap={sfmRanks}
      sfmRenderer={renderSfmItem}
      itemsPerRow={3}
      spacing={4}
    />
  );
};
