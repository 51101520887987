import React from 'react';
import { useStyles } from './styles';
import { Box, Grid, Paper, PaperProps, BoxProps } from '@mui/material';
import SimpleBar from 'simplebar-react';
import { PfmStepType } from '../../models/tools/pfm';

export interface SfmViewerProps extends PaperProps {
  headerRenderer?: () => JSX.Element | null;
  footerRenderer?: () => JSX.Element | null;
  controlsRenderer?: () => JSX.Element | null;
  placeholderRenderer?: () => JSX.Element | null;
  contentHeight?: number;
  contentBoxProps?: BoxProps;
  pfmStepType?: PfmStepType;
}

interface SfmViewerContainerProps extends SfmViewerProps {
  showPlaceholder?: boolean;
}

export const SfmViewerContainer: React.FC<SfmViewerContainerProps> = ({
  headerRenderer,
  footerRenderer,
  controlsRenderer,
  placeholderRenderer,
  showPlaceholder,
  contentHeight,
  contentBoxProps,
  pfmStepType,
  children,
  ...rest
}) => {
  const styles = useStyles();
  const rootClassNames = [styles.root];
  const headerClassNames = [styles.header];

  if (pfmStepType === PfmStepType.Productive) {
    rootClassNames.push(styles.productiveContainer);
    headerClassNames.push(styles.productiveHeader);
  }
  if (pfmStepType === PfmStepType.Providing) {
    rootClassNames.push(styles.providingContainer);
    headerClassNames.push(styles.providingHeader);
  }
  if (pfmStepType === PfmStepType.Corrective) {
    rootClassNames.push(styles.correctiveContainer);
    headerClassNames.push(styles.correctiveHeader);
  }
  if (pfmStepType === PfmStepType.Metrology) {
    rootClassNames.push(styles.metrologyContainer);
    headerClassNames.push(styles.metrologyHeader);
  }

  const renderContent = () => {
    return (
      <Box p={2} className={showPlaceholder ? styles.centerWrap : undefined} {...contentBoxProps}>
        {showPlaceholder && placeholderRenderer && placeholderRenderer()}
        {!showPlaceholder && children}
      </Box>
    );
  };

  return (
    <Paper className={rootClassNames.join(' ')} variant={'outlined'} {...rest}>
      {headerRenderer && <Box className={headerClassNames.join(' ')}>{headerRenderer()}</Box>}

      <Box
        className={styles.contentContainer}
        sx={{ height: contentHeight || 'auto', flex: contentHeight ? 'none' : '1 0 0' }}
      >
        {contentHeight && (
          <SimpleBar className={'wide'} forceVisible="y" autoHide={false}>
            {renderContent()}
          </SimpleBar>
        )}

        {!contentHeight && renderContent()}
      </Box>

      {(footerRenderer || controlsRenderer) && (
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          {footerRenderer && (
            <Grid item xs={true} overflow={'hidden'}>
              {footerRenderer()}
            </Grid>
          )}

          {controlsRenderer && (
            <Grid item xs={'auto'} sx={{ marginLeft: 'auto' }}>
              {controlsRenderer()}
            </Grid>
          )}
        </Grid>
      )}
    </Paper>
  );
};
