import React, { useContext } from 'react';
import { FullscreenContainerContext } from '../fullscreen-container/component';
import { CanvasControlButton } from '../canvas-controls/button/component';

import { ReactComponent as FullscreenIcon } from '../../assets/icons/fullscreen';
import { ReactComponent as FullscreenAlternativeIcon } from '../../assets/icons/fullscreen-alternative';

interface FullscreenButtonProps {
  id?: string;
}

export const FullscreenButton: React.FC<FullscreenButtonProps> = ({ id }) => {
  const fullscreenContext = useContext(FullscreenContainerContext);

  return (
    <CanvasControlButton
      id={id}
      key={fullscreenContext.enabled ? 'FullscreenButton-e' : 'FullscreenButton-d'}
      tooltip={fullscreenContext.enabled ? 'Disable fullscreen' : 'Enable fullscreen'}
      onClick={fullscreenContext.toggle}
    >
      {fullscreenContext.enabled ? <FullscreenAlternativeIcon /> : <FullscreenIcon />}
    </CanvasControlButton>
  );
};
