import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Typography } from '@mui/material';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import { TaskActions } from '../store/actions/task.actions';
import { TaskSelectors } from '../store/selectors/task.selectors';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { TitleContainer } from '../../react/modules/title-container/component';
import { ButtonCreateTask } from '../../react/elements/button-create-task/component';
import { TaskStatus } from '@priz/shared/src/models/task';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm/rrm-goal-type.enum';
import { ArrayUtils, SortDirection } from '@priz/shared/src/utils/common';
import { ProjectSelector, ToolUtilizationSelector } from '../../project/store/selectors';
import { EmptyTasksList } from '../empty-task-list/component';
import { ProjectAssigneeActions, TaskAssigneeActions } from '../../assignment/store/actions';
import { WorkspaceMembersActions } from '../../workspace/store/actions';
import { HashHighlightContainer } from '../../hash-highlight-container/component';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { StartRankingButton } from '../../rrm/start-ranking-button/component';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { TaskCard } from '../task-card/component';
import { UimPriority } from '@priz/shared/src/models/tools/uim';
import { categorizeTasks, TaskListCategory } from '../utils/categorization';
import { PrioritizeProjectTasksButton } from '../../uim/prioritize-project-tasks-button/component';
import { ToolUtilizationActions } from '../../project/store/actions/tools.actions';

interface EbsUtilizationProps {
  projectId: number;
}

export const TaskList: React.FC<EbsUtilizationProps> = ({ projectId }) => {
  const dispatch = useDispatch();

  const tasks = useSelector(TaskSelectors.getAllByProjectId(projectId));
  const isLoaded = useSelector(TaskSelectors.isLoaded(projectId));
  const isProjectLocked = useSelector(ProjectSelector.isProjectLockedById(projectId));
  const isAnyToolCreatingByProjectId = useSelector(ToolUtilizationSelector.isAnyCreating(projectId));

  const categorizedTasks = categorizeTasks(tasks);
  const notCompletedTasksCount = tasks.filter(task => task.status !== TaskStatus.Done).length;

  useEffect(() => {
    dispatch(TaskActions.fetchAllByProjectId(projectId));
    dispatch(TaskAssigneeActions.fetchAll());
    dispatch(ProjectAssigneeActions.fetchAll());
    dispatch(WorkspaceMembersActions.loadAll());
    dispatch(ToolUtilizationActions.loadTools(projectId));
  }, [projectId]);

  if (!isLoaded) {
    return <LoadingOverlay loading={true} backdropStyles={{ backgroundColor: 'transparent' }} />;
  }

  return (
    <>
      <ContentContainer id={'task-list'}>
        <TitleContainer>
          <PageTitleWithDocLink
            title={<Trans>Tasks manager</Trans>}
            oneLiner={<Trans>Work on the tasks to progress with the project</Trans>}
            docsUrl={'https://www.priz.guru/knowledge-base/task-manager'}
          />
          <ButtonsWrap>
            <ButtonCreateTask
              projectId={projectId}
              text={'Add new task'}
              startIcon={<div>+</div>}
              disabled={isProjectLocked || isAnyToolCreatingByProjectId}
            />

            {notCompletedTasksCount > 2 && (
              <PrioritizeProjectTasksButton
                projectId={projectId}
                disabled={isProjectLocked || isAnyToolCreatingByProjectId}
                loading={isAnyToolCreatingByProjectId}
              />
            )}

            {notCompletedTasksCount > 2 && (
              <StartRankingButton
                projectId={projectId}
                goalType={RrmGoalType.TASK}
                buttonText={'Rank tasks'}
                itemsCount={tasks.length}
                buttonProps={{
                  disabled: isProjectLocked || isAnyToolCreatingByProjectId,
                }}
              />
            )}
          </ButtonsWrap>
        </TitleContainer>

        {[TaskListCategory.Active, TaskListCategory.Completed].map(category => {
          if (!categorizedTasks[category]) return null;

          return (
            <React.Fragment key={category}>
              {category === TaskListCategory.Completed && (
                <>
                  <Box mb={2}>
                    <Divider style={{ width: '100%' }} />
                  </Box>

                  <Box mb={2}>
                    <Typography variant={'h6'} component={'span'}>
                      <Trans>Completed Tasks</Trans>
                    </Typography>
                  </Box>
                </>
              )}

              {[
                UimPriority.DoFirst,
                UimPriority.Delegate,
                UimPriority.DoLater,
                UimPriority.Drop,
                UimPriority.Undefined,
              ].map(priority => {
                if (!categorizedTasks[category][priority]) return null;

                return categorizedTasks[category][priority]
                  .sort(ArrayUtils.sorterByParam('latestRankingScore', SortDirection.DESC))
                  .map(task => (
                    <Box mb={2} key={task.id}>
                      <HashHighlightContainer id={`task-${task.id}`} scrollContainerId={'task-list'} scrollOffset={15}>
                        <TaskCard task={task} disabled={isProjectLocked} />
                      </HashHighlightContainer>
                    </Box>
                  ));
              })}
            </React.Fragment>
          );
        })}

        {!tasks.length && <EmptyTasksList projectId={projectId} />}
      </ContentContainer>
    </>
  );
};
