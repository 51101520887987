import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MoveToolToProjectDialog } from '../project/move-tool-to-project-dialog/component';
import { ProjectFeatureBlockerSelectors } from './store/selectors';
import { ProjectFeatureBlockerActions } from './store/actions';
import { ProjectFeatureBlockerType } from './store/model';
import { TaskRecorder } from '../tasks/task-recorder/component';
import { MeetingRecorder } from '../meetings/meeting-recorder/component';
import { StartRankingDialog } from '../rrm/start-ranking-dialog/component';
import { EbsRankIdeasDialog } from '../ebs/ebs-rank-ideas-dialog/comonent';
import { FortyPrinciplesUtilizationActions } from '../forty-principles/store/actions';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { IdeaRecorder } from '../idea/idea-recorder/component';
import { ToolSelectorDialog } from '../tools/tool-selector-dialog/component';

interface ProjectFeatureBlockerProps {
  projectId?: number;
  anonymous?: boolean;
}

const loadingTextMap: { [key in ProjectFeatureBlockerType]?: string } = {
  [ProjectFeatureBlockerType.FortyPrinciples]: 'Creating Forty Principles tool...',
};

export const ProjectFeatureBlocker: React.FC<ProjectFeatureBlockerProps> = ({ projectId, anonymous }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const blockerType = useSelector(ProjectFeatureBlockerSelectors.getBlockerType);
  const blockerData = useSelector(ProjectFeatureBlockerSelectors.getBlockerData);
  const isAnyCreating = useSelector(ProjectFeatureBlockerSelectors.isAnyCreating);

  const isProjectIdDefined = typeof projectId !== 'undefined';

  useEffect(() => {
    const lsStateMock = LocalStorageService.getItem(LocalStorageKey.ProjectFeatureBlockerMock);

    if (lsStateMock) {
      LocalStorageService.removeItem(LocalStorageKey.ProjectFeatureBlockerMock);

      try {
        dispatch(ProjectFeatureBlockerActions.set(JSON.parse(lsStateMock), true));
      } catch (_e) {
        return;
      }
    }
  }, []);

  useEffect(() => {
    if (
      isProjectIdDefined &&
      blockerType === ProjectFeatureBlockerType.FortyPrinciples &&
      blockerData.fortyPrinciplesCommand
    ) {
      dispatch(FortyPrinciplesUtilizationActions.create(blockerData.fortyPrinciplesCommand, projectId, navigate));
    }
  }, [blockerType, projectId, blockerData.fortyPrinciplesCommand]);

  const clearBlockerState = () => {
    dispatch(ProjectFeatureBlockerActions.clear());
  };

  return (
    <>
      <LoadingOverlay
        loading={isAnyCreating}
        backdropStyles={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
        text={loadingTextMap[blockerType]}
      />

      <MoveToolToProjectDialog
        utilizationId={blockerData.utilizationId}
        open={
          !!blockerData.utilizationId && !isProjectIdDefined && blockerType !== ProjectFeatureBlockerType.NotDefined
        }
        onClose={clearBlockerState}
        anonymous={anonymous}
      />

      {isProjectIdDefined && blockerData.meetingRecorderProps && (
        <MeetingRecorder
          open={blockerType === ProjectFeatureBlockerType.Meeting}
          projectId={projectId}
          onClose={clearBlockerState}
          {...blockerData.meetingRecorderProps}
        />
      )}

      {isProjectIdDefined && blockerData.toolRecorderProps && (
        <ToolSelectorDialog
          isOpen={blockerType === ProjectFeatureBlockerType.Tool}
          projectId={projectId}
          onClose={clearBlockerState}
          {...blockerData.toolRecorderProps}
        />
      )}

      {isProjectIdDefined && blockerData.ideaRecorderProps && (
        <IdeaRecorder
          isOpen={blockerType === ProjectFeatureBlockerType.Idea}
          projectId={projectId}
          onClose={clearBlockerState}
          {...blockerData.ideaRecorderProps}
        />
      )}

      {isProjectIdDefined && blockerData.taskRecorderProps && (
        <TaskRecorder
          isOpen={blockerType === ProjectFeatureBlockerType.Task}
          projectId={projectId}
          onClose={clearBlockerState}
          {...blockerData.taskRecorderProps}
        />
      )}

      {isProjectIdDefined && blockerData.rrmStarterProps && (
        <StartRankingDialog
          isOpen={blockerType === ProjectFeatureBlockerType.RRM}
          projectId={projectId}
          onClose={clearBlockerState}
          {...blockerData.rrmStarterProps}
        />
      )}

      {isProjectIdDefined && blockerData.ebsRankingProps && (
        <EbsRankIdeasDialog
          open={blockerType === ProjectFeatureBlockerType.EBSRanking}
          utilizationId={blockerData.utilizationId}
          projectId={projectId}
          onClose={clearBlockerState}
          {...blockerData.ebsRankingProps}
        />
      )}
    </>
  );
};
