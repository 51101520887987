import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { INineWindowsUtilization, NineWindowsUtilization } from '../../../models/tools/nine-windows';
import { windowTypeMap } from '../../../data/nine-windows';
import { Trans } from 'react-i18next';
import { pgColorScheme } from '../../../theme';
import { useStyles } from './styles';

export interface NineWindowsViewerProps {
  utilization: NineWindowsUtilization;
}

export const NineWindowsViewer: React.FC<NineWindowsViewerProps> = ({ utilization }) => {
  const styles = useStyles();
  const windowsTypes = Object.keys(windowTypeMap) as (keyof INineWindowsUtilization)[];

  return (
    <Grid container spacing={1}>
      {windowsTypes.map((type, key) => (
        <Grid item xs={4} key={key} className={styles.cell}>
          <Box className={styles.labelContainer}>
            <Typography variant={'caption'} component={'div'} style={{ color: pgColorScheme.textGray }}>
              <Trans>{`${windowTypeMap[type]?.order || ''}. ${windowTypeMap[type]?.label || ''}`}</Trans>
            </Typography>
          </Box>

          <Paper variant={'outlined'} className={styles.textContainer}>
            <Box p={1}>
              <Typography variant={'body1'} component={'span'}>
                {utilization[type] || '-'}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};
