import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import { PMapUtilizationActions } from '../store/actions';
import { ProjectSelector, ToolUtilizationSelector } from '../../project/store/selectors';
import { PMapWorkspace, PMapWorkspaceTabType } from '../pmap-workspace/component';
import { IPMapUtilizationData, PMapUtilization } from '@priz/shared/src/models/tools/pmap';
import { TitleContainer } from '../../react/modules/title-container/component';
import { ToolTitle } from '../../tools/tool-title/component';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { AccessLevel } from '@priz/shared/src/models/security/access-level';
import { stringToHash } from '@priz/shared/src/utils/common';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { ToolUtilizationActions, ToolUtilizationActionType } from '../../project/store/actions/tools.actions';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { useToolTabs } from '../../tools/utils';

interface PMapViewProps {
  utilizationId: number;
  projectId?: number;
}

export const PMapView: React.FC<PMapViewProps> = ({ projectId, utilizationId }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useToolTabs<PMapWorkspaceTabType>(PMapWorkspaceTabType.Subject);

  const [utilizationState, setUtilizationState] = useState<PMapUtilization>(null);
  const [publicAccessLevel, setPublicAccessLevel] = useState<AccessLevel>();
  const [contributedPerceptionsHash, setContributedPerceptionsHash] = useState<number>();

  const pMapUtilization = useSelector(ToolUtilizationSelector.getPMapUtilization(utilizationId));
  const isProjectLocked = useSelector(ProjectSelector.isProjectLockedById(projectId));
  const isCompletenessUpdating = useSelector(
    ToolUtilizationSelector.isPendingByActionType(utilizationId, ToolUtilizationActionType.UpdateCompleteness),
  );

  const isDisabled = !!pMapUtilization?.completed || isCompletenessUpdating || isProjectLocked;

  const contributedPerceptions = pMapUtilization?.contributedData?.perceptions || [];
  const latestContributedPerceptionsHash = stringToHash(JSON.stringify(contributedPerceptions));

  useEffect(() => {
    if (pMapUtilization?.id && pMapUtilization.publicId) {
      setActiveTab(PMapWorkspaceTabType.Perceptions);
    }
  }, [pMapUtilization?.id]);

  useEffect(() => {
    const showConclusion = LocalStorageService.getItem(LocalStorageKey.PMapShowConclusion);

    if (showConclusion) {
      LocalStorageService.removeItem(LocalStorageKey.PMapShowConclusion);
      setActiveTab(PMapWorkspaceTabType.Conclusion);
    }
  }, []);

  useEffect(() => {
    if (pMapUtilization) {
      const isAccessLevelMatch = pMapUtilization.publicAccessLevel === publicAccessLevel;
      const isContributedPerceptionsMatch = contributedPerceptionsHash === latestContributedPerceptionsHash;

      if (!utilizationState || !isAccessLevelMatch || !isContributedPerceptionsMatch) {
        setUtilizationState(pMapUtilization);
        setPublicAccessLevel(pMapUtilization.publicAccessLevel);
        setContributedPerceptionsHash(latestContributedPerceptionsHash);
      }
    }
  }, [
    pMapUtilization,
    utilizationState,
    publicAccessLevel,
    contributedPerceptionsHash,
    latestContributedPerceptionsHash,
  ]);

  useEffect(() => {
    dispatch(ToolUtilizationActions.loadTool(utilizationId, projectId));
  }, [utilizationId, projectId]);

  const doUpdateUtilization = (utilization: PMapUtilization) => {
    setUtilizationState(utilization);
    dispatch(PMapUtilizationActions.update(utilization));
  };

  const updateData = (data: IPMapUtilizationData) => {
    doUpdateUtilization({ ...utilizationState, data });
  };

  const conclusionChangeHandler = (conclusion: string) => {
    doUpdateUtilization({ ...utilizationState, conclusion });
  };

  const subjectChangeHandler = (subject: string) => {
    doUpdateUtilization({ ...utilizationState, subject });
  };

  const changeTabHandler = (_event, newValue: PMapWorkspaceTabType) => {
    setActiveTab(newValue);
  };

  if (!utilizationState) {
    return <LoadingOverlay loading={true} backdropStyles={{ backgroundColor: 'transparent' }} />;
  }

  return (
    <>
      <ContentContainer display={'flex'} alignItems={'stretch'}>
        <Grid container direction={'column'} wrap={'nowrap'}>
          <Grid item xs={'auto'}>
            <TitleContainer>
              <PageTitleWithDocLink
                titleComponent={<ToolTitle tool={pMapUtilization} preset={'large'} editable={!isDisabled} />}
                oneLiner={<Trans>Find the blocker</Trans>}
                docsUrl={'https://www.priz.guru/knowledge-base/perception-mapping'}
              />

              <Box display="flex" justifyContent="flex-end">
                <Tabs
                  orientation={'horizontal'}
                  variant={'scrollable'}
                  scrollButtons={'auto'}
                  allowScrollButtonsMobile={true}
                  value={activeTab}
                  onChange={changeTabHandler}
                  className={styles.tabs}
                >
                  {!utilizationState.publicId && (
                    <Tab value={PMapWorkspaceTabType.Subject} label={<Trans>Subject</Trans>} />
                  )}

                  <Tab value={PMapWorkspaceTabType.Perceptions} label={<Trans>Perceptions</Trans>} />
                  <Tab value={PMapWorkspaceTabType.Result} label={<Trans>Result</Trans>} />

                  {!utilizationState.publicId && (
                    <Tab value={PMapWorkspaceTabType.Conclusion} label={<Trans>Conclusion</Trans>} />
                  )}
                </Tabs>
              </Box>
            </TitleContainer>
          </Grid>

          <Grid item xs={true} className={styles.toolContainer}>
            {utilizationState && (
              <PMapWorkspace
                pMapUtilization={utilizationState}
                disabled={isDisabled}
                activeTab={activeTab}
                conclusion={utilizationState.conclusion}
                subject={utilizationState.subject}
                onConclusionChange={conclusionChangeHandler}
                onSubjectChange={subjectChangeHandler}
                onDataChange={updateData}
              />
            )}
          </Grid>
        </Grid>
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={projectId}
          utilization={pMapUtilization}
          disabled={isDisabled}
          workingFlowProps={{ nextVariant: 'ideas' }}
        />
      </ContentFooter>
    </>
  );
};
