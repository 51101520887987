import React, { CSSProperties } from 'react';
import { pgColorScheme } from '@priz/shared/src/theme';

interface ComponentIconProps {
  styleProps?: CSSProperties;
  onClick?: () => void;
}

export const ComponentIcon: React.FC<ComponentIconProps> = ({ styleProps, onClick, ...rest }) => {
  return (
    <div
      onClick={() => {
        if (onClick) onClick();
      }}
      style={{
        width: 50,
        height: 25,
        background: pgColorScheme.white,
        borderRadius: 10,
        border: `1px solid ${pgColorScheme.blue}`,
        boxSizing: 'border-box',
        ...styleProps,
      }}
      {...rest}
    />
  );
};
