import { v1 as uuidv1 } from 'uuid';
import { CecNodeProps, CecEdgeProps } from './cec-utilization';

export const CecNodeDefaultPlaceholder = 'Type text...';

export const CecPreset: { nodes: CecNodeProps[]; edges: CecEdgeProps[] } = {
  nodes: [
    {
      id: uuidv1(),
      type: 'first',
    },
  ],
  edges: [],
};
