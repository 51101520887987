import React, { useState } from 'react';
import { Alert, Box, Grid, Snackbar } from '@mui/material';
import { Network } from '@priz/shared/src/lib/vis/esnext';
import { Trans } from 'react-i18next';
import { pgColorScheme } from '@priz/shared/src/theme';
import { useStyles } from './styles';
import { CanvasControlButton } from '@priz/shared/src/components/canvas-controls/button/component';

import { ReactComponent as CameraIcon } from '../../../../assets/icons/camera.svg';

export interface VisScreenshotButtonProps {
  network: Network;
  id?: string;
}

export const VisScreenshotButton: React.FC<VisScreenshotButtonProps> = ({ network, id }) => {
  const styles = useStyles();

  const [snackbarIsOpen, setSnackbarIsOpen] = useState(false);
  const [preview, setPreview] = useState<string | null>(null);

  const copyToClipboard = () => {
    const canvasElement = (network as any)?.canvas?.frame?.canvas as HTMLCanvasElement;

    if (canvasElement) {
      const canvasCopy = document.createElement('canvas');
      const canvasCopyContext = canvasCopy.getContext('2d');

      canvasCopy.width = canvasElement.width;
      canvasCopy.height = canvasElement.height;

      canvasCopyContext.drawImage(canvasElement, 0, 0);
      canvasCopyContext.globalCompositeOperation = 'destination-over';
      canvasCopyContext.fillStyle = pgColorScheme.backgroundMediumGray;
      canvasCopyContext.fillRect(0, 0, canvasCopy.width, canvasCopy.height);

      const dataUrl = canvasCopy.toDataURL('image/jpeg', 1.0);
      const img = document.createElement('img');

      img.style.position = 'absolute';
      img.style.left = 'auto';
      img.style.bottom = 'auto';
      img.style.top = '-300vw';
      img.style.right = '300vw';

      img.onload = () => {
        window.getSelection().removeAllRanges();
        const range = document.createRange();
        range.selectNode(img);
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        setPreview(dataUrl);
        setSnackbarIsOpen(true);
        img.remove();
      };

      document.body.appendChild(img);
      img.src = dataUrl;
    }
  };

  const closeHandler = () => {
    setSnackbarIsOpen(false);
  };

  return (
    <>
      <CanvasControlButton id={id} tooltip={'Copy to clipboard'} onClick={copyToClipboard}>
        <CameraIcon />
      </CanvasControlButton>

      <Snackbar
        open={snackbarIsOpen}
        autoHideDuration={3000}
        onClose={closeHandler}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={closeHandler} severity="success" sx={{ width: '100%', alignItems: 'center' }}>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item>
              <Box className={styles.preview} sx={{ backgroundImage: preview ? `url(${preview})` : 'none' }} />
            </Grid>

            <Grid item>
              <Trans>Copied to clipboard!</Trans>
            </Grid>
          </Grid>
        </Alert>
      </Snackbar>
    </>
  );
};
