import { createSelector, Selector } from 'reselect';
import { EntityMap } from '@priz/shared/src/models/common/entity-collection-state';
import { safeEntityMapToArrayByIds } from '../../../shared/store/selector-utils';
import { AppState } from '../../../store/app.state';
import { UimTask } from '@priz/shared/src/models/tools/uim/uim-task';
import { UimPriority } from '@priz/shared/src/models/tools/uim';
import { ArrayUtils, SortDirection } from '@priz/shared/src/utils/common';

const uimTasksCollectionSelector = (state: AppState) => state.uimTasks;

const uimTasksEntitiesSelector = createSelector(uimTasksCollectionSelector, collection => collection.entities);

const uimTasksLookupsSelector = createSelector(uimTasksCollectionSelector, collection => collection.lookups);

const uimTaskIdsByUtilizationId = (utilizationId: number) =>
  createSelector(uimTasksLookupsSelector, lookups => lookups.byUtilizationId[utilizationId] || []);

const uimTasksStatusesSelector = createSelector(uimTasksCollectionSelector, collection => collection.statuses || {});

const isLoadingByUtilizationId = (utilizationId: number) =>
  createSelector(uimTasksStatusesSelector, statuses => !!statuses.byUtilizationId[utilizationId]?.loading);

const isLoadedByUtilizationId = (utilizationId: number) =>
  createSelector(uimTasksStatusesSelector, statuses => !!statuses.byUtilizationId[utilizationId]?.loaded);

const isCreatingByUtilizationId = (utilizationId: number) =>
  createSelector(uimTasksStatusesSelector, statuses => !!statuses.byUtilizationId[utilizationId]?.creating);

const isAnyTaskCreating = () =>
  createSelector(
    uimTasksStatusesSelector,
    statuses => !!Object.values(statuses.byUtilizationId).find(task => task.creating),
  );

const isAnyTaskUpdating = () =>
  createSelector(
    uimTasksStatusesSelector,
    statuses => !!Object.values(statuses.byUtilizationId).find(task => task.updating),
  );

const isAnyTaskRemoving = () =>
  createSelector(
    uimTasksStatusesSelector,
    statuses => !!Object.values(statuses.byUtilizationId).find(task => task.removing),
  );

const isUpdatingByUtilizationId = (utilizationId: number) =>
  createSelector(uimTasksStatusesSelector, statuses => !!statuses.byUtilizationId[utilizationId]?.updating);

const getAllByUtilizationId = (utilizationId: number): Selector<AppState, UimTask[]> =>
  createSelector(
    [uimTasksEntitiesSelector, uimTaskIdsByUtilizationId(utilizationId)],
    (entityMap: EntityMap<UimTask>, taskIds: number[]) => safeEntityMapToArrayByIds(entityMap, taskIds),
  );

const getAllByUtilizationIdAndPriority = (
  utilizationId: number,
  priority: UimPriority,
): Selector<AppState, UimTask[]> =>
  createSelector(getAllByUtilizationId(utilizationId), allTasks =>
    allTasks
      .filter(t => t.priority === priority || (priority === UimPriority.Undefined && !t.priority))
      .sort(ArrayUtils.sorterByParam('latestRankingScore', SortDirection.DESC)),
  );

const getById = (taskId: number) => createSelector(uimTasksCollectionSelector, entityMap => entityMap[taskId]);

const areAllTasksPrioritized = (utilizationId: number): Selector<AppState, boolean> =>
  createSelector(getAllByUtilizationId(utilizationId), tasks => tasks.every(t => !!t.flawCost && !!t.flawExpectancy));

export const UimTaskSelector = {
  areAllTasksPrioritized,
  getAllByUtilizationId,
  getAllByUtilizationIdAndPriority,
  getById,
  isLoadingByUtilizationId,
  isLoadedByUtilizationId,
  isCreatingByUtilizationId,
  isUpdatingByUtilizationId,
  isAnyTaskCreating,
  isAnyTaskUpdating,
  isAnyTaskRemoving,
};
