import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles as useApaStyles } from '../styles';
import { Box, Button, Grid, Hidden, Typography } from '@mui/material';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import { ProjectSelector, ToolUtilizationSelector } from '../../project/store/selectors';
import { ToolTitle } from '../../tools/tool-title/component';
import { ApaUtilizationSelectors } from '../store/selectors/apa-utilization.selectors';
import { useForm } from 'react-hook-form';
import { FieldTitle, ReactHookFormText } from '../../react/form-elements';
import debounce from 'lodash/debounce';
import { ApaUtilizationActions } from '../store/actions';
import { validatorRules } from '@priz/shared/src/utils/form';
import { ApaUtilization } from '@priz/shared/src/models/tools/apa';
import { DeleteToolButton } from '../../tools/delete-tool-button/component';
import { useNavigate } from 'react-router-dom';
import { Assistant } from '../../assistant/component';
import { AssistanceType, AssistantVariant } from '../../assistant/store/model';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { ToolUtilizationActions, ToolUtilizationActionType } from '../../project/store/actions/tools.actions';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { ToolsUtils } from '../../tools/utils';

interface ApaCustomerProps {
  utilizationId: number;
  projectId?: number;
}

export const ApaCustomer: React.FC<ApaCustomerProps> = ({ projectId, utilizationId }) => {
  const apaStyles = useApaStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [utilization, setUtilization] = useState<ApaUtilization>(null);

  const selectedWorkspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const apaUtilization = useSelector(ToolUtilizationSelector.getApaUtilization(utilizationId));

  const isCustomerDetailsComplete = useSelector(ApaUtilizationSelectors.isCustomerDetailsComplete(utilizationId));
  const isAiAssistantEnabled = useSelector(WorkspaceSelectors.isAiAssistantEnabled(selectedWorkspace?.id));
  const isProjectLocked = useSelector(ProjectSelector.isProjectLockedById(projectId));
  const isCompletenessUpdating = useSelector(
    ToolUtilizationSelector.isPendingByActionType(utilizationId, ToolUtilizationActionType.UpdateCompleteness),
  );

  const isDisabled = !!apaUtilization?.completed || isCompletenessUpdating || isProjectLocked;
  const isCustomerDefined = !!apaUtilization?.customer?.length;

  useEffect(() => {
    dispatch(ToolUtilizationActions.loadTool(utilizationId, projectId));
  }, [utilizationId, projectId]);

  const { control, reset, getValues, setValue } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      customer: '',
      customerDescription: '',
    },
  });

  useEffect(() => {
    if (apaUtilization && !utilization) {
      setUtilization(apaUtilization);
    }
  }, [apaUtilization, utilization]);

  useEffect(() => {
    reset({
      customer: utilization?.customer || '',
      customerDescription: utilization?.customerDescription || '',
    });
  }, [utilization]);

  const onTextUpdate = useCallback(
    debounce((utilization: ApaUtilization) => {
      dispatch(ApaUtilizationActions.update({ ...utilization, ...getValues() }));
    }, 500),
    [],
  );

  const formChangeHandler = () => {
    onTextUpdate(apaUtilization);
  };

  const nextClickHandler = () => {
    navigate([ToolsUtils.resolvePathByUtilization(apaUtilization), 'actions'].join('/'));
  };

  const useDescriptionHintHandler = (text: string[]) => {
    setValue('customerDescription', text[0]);
    formChangeHandler();
  };

  if (!apaUtilization) {
    return null;
  }

  return (
    <>
      <ContentContainer>
        <PageTitleWithDocLink
          titleComponent={
            <ToolTitle
              initialTitle={'Action Preventing Action (APA)'}
              tool={apaUtilization}
              preset={'large'}
              editable={!isDisabled}
            />
          }
          oneLiner={<Trans>Use APA to search for what your customer wants</Trans>}
          // docsUrl={'https://www.priz.guru/knowledge-base/#/'}
        />

        <Box mb={2}>
          <Typography variant={'h6'}>
            <Trans>Use APA to search for what your customer wants</Trans>
          </Typography>
        </Box>

        <Grid container>
          <Grid item xs={true}>
            <form onSubmit={e => e.preventDefault()} onChange={formChangeHandler}>
              <ReactHookFormText
                fieldTitle={'Customer'}
                name={'customer'}
                control={control}
                rules={{
                  ...validatorRules.required(),
                }}
                disabled={isDisabled}
              />

              <Grid container alignItems={'flex-end'} mb={1} spacing={1}>
                <Grid item>
                  <FieldTitle text={'Customer description'} mb={0} />
                </Grid>

                {isAiAssistantEnabled && (
                  <Grid item>
                    <Assistant
                      type={AssistanceType.AskApaCustomerDescription}
                      variant={AssistantVariant.Popper}
                      projectId={projectId}
                      actionProps={{ utilizationId }}
                      onUseHint={useDescriptionHintHandler}
                      disabled={isDisabled || !isCustomerDefined}
                      buttonProps={{
                        tooltip: !isCustomerDefined ? 'Customer is required' : '',
                      }}
                      popperAssistantProps={{
                        placement: 'right',
                        modifiers: [
                          {
                            name: 'preventOverflow',
                            options: {
                              altAxis: true,
                            },
                          },
                        ],
                      }}
                    />
                  </Grid>
                )}
              </Grid>

              <ReactHookFormText
                multiline
                name={'customerDescription'}
                control={control}
                rules={{
                  ...validatorRules.required(),
                }}
                disabled={isDisabled}
              />
            </form>
          </Grid>

          <Hidden smDown>
            <Grid item>
              <Box ml={3} className={apaStyles.rightPane} />
            </Grid>
          </Hidden>
        </Grid>
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={projectId}
          utilization={apaUtilization}
          leftColContent={
            utilization && !utilization.publicId && <DeleteToolButton utilization={utilization} disabled={isDisabled} />
          }
          rightColContent={
            <Button
              variant={'pg_rounded'}
              color={'pg_orange_solid'}
              disabled={!isCustomerDetailsComplete}
              onClick={nextClickHandler}
            >
              <Trans>Start</Trans>
            </Button>
          }
          workingFlowProps={{ nextVariant: 'ideas' }}
          disabled={isDisabled}
        />
      </ContentFooter>
    </>
  );
};
