import { handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { toObjectMap } from '../../../shared/store/reducer-utils';
import { ArrayUtil } from '../../../shared/utils/array-util';
import { FiveWhysSolutionAction, FiveWhysSolutionActionType } from '../actions';
import {
  FiveWhysCauseCollection,
  FiveWhysSolution,
  IFiveWhysSolution,
  FiveWhysSolutionCollection,
  DefaultFiveWhysSolutionCollection,
} from '@priz/shared/src/models/tools/five-whys';
import { copyObject } from '@priz/shared/src/utils/common';

const fiveWhysSolutionInstantiator = (payload: IFiveWhysSolution): FiveWhysSolution => new FiveWhysSolution(payload);

const setStatus = (
  state: FiveWhysSolutionCollection,
  utilizationId: number,
  loading: boolean,
  loaded: boolean,
): FiveWhysSolutionCollection => {
  const statusesCopy = copyObject(state.statuses);
  statusesCopy.byUtilizationId[utilizationId] = {
    loaded,
    loading,
  };

  return {
    ...state,
    statuses: statusesCopy,
  };
};

const setFiveWhysSolutions = (
  state: FiveWhysSolutionCollection,
  utilizationId: number,
  solutionsPayload: IFiveWhysSolution[],
): FiveWhysSolutionCollection => {
  const solutionsMap = toObjectMap<FiveWhysSolution>(solutionsPayload, fiveWhysSolutionInstantiator);
  const entitiesCopy = { ...state.entities, ...solutionsMap };

  const lookupsCopy = copyObject(state.lookups);
  Object.values(solutionsMap).forEach((cause: FiveWhysSolution) => {
    lookupsCopy.byUtilizationId[utilizationId] = ArrayUtil.concatInDistinctArray(
      lookupsCopy.byUtilizationId[utilizationId],
      cause.id,
    );
  });

  const statusesCopy = copyObject(state.statuses);
  statusesCopy.byUtilizationId[utilizationId] = {
    loaded: true,
    loading: false,
  };

  return {
    ...state,
    entities: entitiesCopy,
    lookups: lookupsCopy,
    statuses: statusesCopy,
  };
};

const updateFiveWhysSolution = (
  state: FiveWhysCauseCollection,
  solutionPayload: IFiveWhysSolution,
): FiveWhysCauseCollection => {
  const updatedCause = fiveWhysSolutionInstantiator(solutionPayload);

  const entitiesCopy = {
    ...state.entities,
    [updatedCause.id]: updatedCause,
  };

  return {
    ...state,
    entities: entitiesCopy,
  };
};

export const fiveWhysSolutionReducers = handleActions(
  {
    [toStarted(FiveWhysSolutionActionType.Fetch)]: (
      state: FiveWhysSolutionCollection,
      action: FiveWhysSolutionAction,
    ): FiveWhysSolutionCollection => setStatus(state, action.meta.utilizationId, true, false),
    [toSuccess(FiveWhysSolutionActionType.Fetch)]: (
      state: FiveWhysSolutionCollection,
      action: FiveWhysSolutionAction,
    ): FiveWhysSolutionCollection =>
      setFiveWhysSolutions(state, action.meta.utilizationId, action.payload as IFiveWhysSolution[]),
    [toFailed(FiveWhysSolutionActionType.Fetch)]: (
      state: FiveWhysSolutionCollection,
      action: FiveWhysSolutionAction,
    ): FiveWhysCauseCollection => setStatus(state, action.meta.utilizationId, false, false),
    [toSuccess(FiveWhysSolutionActionType.Create)]: (
      state: FiveWhysSolutionCollection,
      action: FiveWhysSolutionAction,
    ): FiveWhysSolutionCollection =>
      setFiveWhysSolutions(state, action.meta.utilizationId, [action.payload as IFiveWhysSolution]),
    [toSuccess(FiveWhysSolutionActionType.Update)]: (
      state: FiveWhysSolutionCollection,
      action: FiveWhysSolutionAction,
    ): FiveWhysCauseCollection => updateFiveWhysSolution(state, action.payload as IFiveWhysSolution),
  },
  DefaultFiveWhysSolutionCollection,
);
