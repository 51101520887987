import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ProjectSaveStatusSelectors, ProjectSelector } from '../store/selectors';
import { Trans } from 'react-i18next';
import { Project } from '@priz/shared/src/models/project';
import { Badge, Box, Button, Grid } from '@mui/material';
import { intervalToDuration } from 'date-fns';
import { ProjectActions } from '../store/actions/project.actions';
import { useStyles } from './styles';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';

import { ReactComponent as TimerIcon } from '../../../assets/icons/timer.svg';

export interface ProjectPublishCountdownButtonProps {
  project: Project;
}

const formatCountdownNumber = (num: number): string => {
  const str = Math.abs(num).toString();
  return str.length > 1 ? str : '0' + str;
};

export const ProjectPublishCountdownButton: React.FC<ProjectPublishCountdownButtonProps> = ({ project }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const [countdown, setCountdown] = useState({ h: 0, m: 0, s: 0, show: false });
  const isAnythingSaving = useSelector(ProjectSaveStatusSelectors.isAnythingSaving(project.id));
  const publishCountdown = useSelector(ProjectSelector.getPublishCountdownByProjectId(project.id));

  const publishedAtMs = project?.publishedAt?.getTime() || 0;
  const lastChangedAtMs = Math.max(project?.lastChangedAt?.getTime() || 0, publishCountdown?.getTime() || 0);
  const hasUnpublishedChanges = lastChangedAtMs > publishedAtMs;

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      const startTime = publishedAtMs + 4 * 60 * 60 * 1000;
      const endTime = new Date().getTime();
      const showCountdown = startTime - endTime > 0;

      const {
        hours = 0,
        minutes = 0,
        seconds = 0,
      } = showCountdown
        ? intervalToDuration({
            start: endTime,
            end: startTime,
          })
        : {};

      setCountdown({
        h: hours,
        m: minutes,
        s: seconds,
        show: showCountdown,
      });

      if (!showCountdown || !hasUnpublishedChanges) {
        clearInterval(countdownInterval);
      }
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [project, hasUnpublishedChanges]);

  const update = () => {
    dispatch(ProjectActions.publishChanges(project.id));
  };

  if (!hasUnpublishedChanges) return null;

  return (
    <PopperWrap openHandler={'hover'} variant={'blue'}>
      <Badge variant="dot" color="secondary">
        <TimerIcon className={styles.svg} />
      </Badge>

      <Box maxWidth={280}>
        <Trans>
          This project has unpublished changes. You can publish the changes then you ready. Or you can let us
          automatically publish them after some time of inactivity.
        </Trans>

        <Box mt={2}>
          <Grid container spacing={2} alignItems={'center'}>
            {countdown.show && (
              <Grid item>
                {formatCountdownNumber(countdown.h)}:{formatCountdownNumber(countdown.m)}:
                {formatCountdownNumber(countdown.s)}
              </Grid>
            )}

            <Grid item>
              <Button
                variant={'pg_rounded'}
                color={'pg_orange_solid'}
                size={'pg_small'}
                onClick={update}
                disabled={isAnythingSaving}
              >
                <Trans>Publish Changes</Trans>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </PopperWrap>
  );
};
