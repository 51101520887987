import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { useSelector, useDispatch } from 'react-redux';
import { ProjectSelector } from '../store/selectors';
import { ProjectActions } from '../store/actions/project.actions';
import { MenuItem } from '@mui/material';
import { ProjectStatus } from '@priz/shared/src/models/project';

export interface CompleteProjectProps {
  projectId: number;
}

export const MarkProjectAsInProgress: React.FC<CompleteProjectProps> = ({ projectId }) => {
  const dispatch = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const isAnyProjectSaving = useSelector(ProjectSelector.isAnyProjectSaving);
  const isProjectCompleted = useSelector(ProjectSelector.isProjectCompleted(projectId));

  const handleOpen = () => {
    setConfirmOpen(true);
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const handleComplete = () => {
    dispatch(ProjectActions.updateStatus(projectId, ProjectStatus.InProgress));
  };

  useEffect(() => {
    if (!isAnyProjectSaving && !isProjectCompleted) handleClose();
  }, [isAnyProjectSaving, isProjectCompleted]);

  return (
    <>
      <MenuItem onClick={handleOpen}>
        <Trans>Reactivate the project</Trans>
      </MenuItem>

      <PgConfirmationDialog
        isOpen={confirmOpen}
        disabled={isAnyProjectSaving}
        loading={isAnyProjectSaving}
        confirmTitle={<Trans>Reactivate the project</Trans>}
        onConfirm={handleComplete}
        onClose={handleClose}
      />
    </>
  );
};
