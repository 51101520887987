import { IWorkspace, PlanLevel, Workspace, WorkspaceMember } from '@priz/shared/src/models/workspace';

const isPersonalOrPro = (workspace: IWorkspace) => [PlanLevel.Personal, PlanLevel.PersonalPro].includes(workspace.type);

const isPersonal = (workspace: Workspace) => workspace.type === PlanLevel.Personal;

const isPersonalPro = (workspace: Workspace) => workspace.type === PlanLevel.PersonalPro;

const isMaxPlan = (workspace: IWorkspace) => workspace?.type === PlanLevel.Enterprise;

const countChargeableMembers = (workspace: Workspace, members: WorkspaceMember[]): number => {
  return members.filter(member => !member.nonChargeable || member.id === workspace.createdBy.id).length;
};

export const WorkspaceUtils = {
  isPersonalOrPro,
  isPersonal,
  isPersonalPro,
  isMaxPlan,
  countChargeableMembers,
};
