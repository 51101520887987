import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles as useApaStyles } from '../styles';
import { Box, Button, Chip, Divider, Grid, Hidden, Typography } from '@mui/material';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { ToolTitle } from '../../tools/tool-title/component';
import { Trans } from 'react-i18next';
import { ApaUtilization } from '@priz/shared/src/models/tools/apa';
import { ProjectSelector, ToolUtilizationSelector } from '../../project/store/selectors';
import { ToolType } from '@priz/shared/src/models/tools';
import { ApaActionActions } from '../store/actions';
import { ApaActionSelectors } from '../store/selectors/apa-action.selectors';
import { FeatureSetSelectors } from '../../workspace/store/selectors/feature-set.selectors';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Add as AddIcon } from '@mui/icons-material';
import { ApaActionRecorder } from '../apa-action-recorder/component';
import { PaywallActions } from '../../react/modules/paywall/store';
import { ApaActionItem } from '../apa-action-item/component';
import { useNavigate } from 'react-router-dom';
import { Assistant } from '../../assistant/component';
import { AssistanceType, AssistantVariant } from '../../assistant/store/model';
import { HintLinesSeparatorType, HintRowType } from '../../assistant/hint-content/component';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { ToolUtilizationActions, ToolUtilizationActionType } from '../../project/store/actions/tools.actions';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { ToolsUtils } from '../../tools/utils';

interface ApaActionsProps {
  utilizationId: number;
  projectId?: number;
}

export const ApaActions: React.FC<ApaActionsProps> = ({ projectId, utilizationId }) => {
  const apaStyles = useApaStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openActionRecorder, setOpenActionRecorder] = useState(false);
  const [utilization, setUtilization] = useState<ApaUtilization>(null);
  const [actionsCreationQueue, setActionsCreationQueue] = useState<string[]>([]);

  const assistantContainerRef = useRef<HTMLDivElement>(null);

  const selectedWorkspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const apaUtilization = useSelector(ToolUtilizationSelector.getApaUtilization(utilizationId));
  const apaActions = useSelector(ApaActionSelectors.getAllByUtilizatonId(utilizationId));

  const isCreating = useSelector(ApaActionSelectors.isCreatingByProjectIdOrCurrentTeam(projectId));
  const isApaLimitHit = useSelector(FeatureSetSelectors.isApaLimitHit(utilizationId, projectId));
  const isProjectLocked = useSelector(ProjectSelector.isProjectLockedById(projectId));
  const isAiAssistantEnabled = useSelector(WorkspaceSelectors.isAiAssistantEnabled(selectedWorkspace?.id));
  const isCompletenessUpdating = useSelector(
    ToolUtilizationSelector.isPendingByActionType(utilizationId, ToolUtilizationActionType.UpdateCompleteness),
  );

  const isDisabled = !!apaUtilization?.completed || isCompletenessUpdating || isProjectLocked;

  useEffect(() => {
    dispatch(ToolUtilizationActions.loadTool(utilizationId, projectId));
    dispatch(ApaActionActions.getAll(utilizationId, projectId));
  }, [utilizationId, projectId]);

  useEffect(() => {
    if (apaUtilization && !utilization) {
      setUtilization(apaUtilization);
    }
  }, [apaUtilization, utilization]);

  useEffect(() => {
    if (actionsCreationQueue.length) {
      if (isApaLimitHit) {
        dispatch(PaywallActions.show(ToolType.APA));
        setActionsCreationQueue([]);
      } else {
        dispatch(ApaActionActions.create(utilizationId, actionsCreationQueue[0], projectId));
        setActionsCreationQueue(currentState => [...currentState.slice(1)]);
      }
    }
  }, [actionsCreationQueue]);

  const customerDefinitionHandler = () => {
    navigate(ToolsUtils.resolvePathByUtilization(apaUtilization));
  };

  const openActionRecorderHandler = () => {
    if (isApaLimitHit) {
      dispatch(PaywallActions.show(ToolType.APA));
    } else {
      setOpenActionRecorder(true);
    }
  };

  const closeActionRecorderHandler = () => {
    setOpenActionRecorder(false);
  };

  const useHintHandler = (text: string[]) => {
    const apaActionsTextArray = apaActions.map(item => item.customerAction);
    setActionsCreationQueue(text.filter(item => !apaActionsTextArray.includes(item)));
  };

  if (!apaUtilization) {
    return null;
  }

  return (
    <>
      <ContentContainer>
        <div ref={assistantContainerRef} />

        <PageTitleWithDocLink
          titleComponent={
            <ToolTitle
              initialTitle={'Action Preventing Action (APA)'}
              tool={apaUtilization}
              preset={'large'}
              editable={!isDisabled}
            />
          }
          oneLiner={<Trans>Use APA to search for what your customer wants</Trans>}
          // docsUrl={'https://www.priz.guru/knowledge-base/#/'}
        />

        <Grid container>
          <Grid item xs={true}>
            <Box mb={2}>
              <Typography variant={'h6'}>
                <Trans>Customer definition</Trans>
              </Typography>
            </Box>

            <Box mb={1.5}>
              <Typography variant={'caption'} style={{ color: pgColorScheme.gray }}>
                <Trans>Customer</Trans>
              </Typography>

              <Typography variant={'body1'}>{apaUtilization.customer}</Typography>
            </Box>

            <Box mb={1.5}>
              <Typography variant={'caption'} style={{ color: pgColorScheme.gray }}>
                <Trans>Customer description</Trans>
              </Typography>

              <Typography variant={'body1'} whiteSpace={'pre-line'}>
                {apaUtilization.customerDescription}
              </Typography>
            </Box>

            <Box mt={2} mb={3}>
              <Divider />
            </Box>

            <Box mb={2}>
              <Typography variant={'h6'}>
                <Trans>Customer actions</Trans>
              </Typography>
            </Box>

            <Box mb={2}>
              <Box mb={1}>
                <Chip label={<Trans>Answer the question</Trans>} size={'small'} />
              </Box>

              <Grid container alignItems={'center'} spacing={1}>
                <Grid item>
                  <Typography variant={'subtitle2'}>
                    <Trans>What keeps him busy?</Trans>
                  </Typography>
                </Grid>

                {isAiAssistantEnabled && (
                  <Grid item>
                    <Assistant
                      type={AssistanceType.AskApaCustomerActions}
                      projectId={projectId}
                      variant={AssistantVariant.Popper}
                      assistantContainer={assistantContainerRef.current}
                      disabled={isDisabled}
                      onUseHint={useHintHandler}
                      actionProps={{ utilizationId }}
                      contentProps={{
                        linesSeparator: HintLinesSeparatorType.OrderNumber,
                        hintRowsType: HintRowType.Checkbox,
                      }}
                      popperAssistantProps={{
                        placement: 'right',
                        modifiers: [
                          {
                            name: 'preventOverflow',
                            options: {
                              altAxis: true,
                            },
                          },
                        ],
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>

            {!!apaActions?.length && (
              <Box mb={2}>
                {apaActions.map((item, key) => (
                  <ApaActionItem key={key} apaUtilization={apaUtilization} apaAction={item} disabled={isDisabled} />
                ))}
              </Box>
            )}

            {isCreating && (
              <Box py={1} position={'relative'}>
                <LoadingOverlay loading={true} backdropStyles={{ position: 'relative', background: 'transparent' }} />
              </Box>
            )}

            {!isCreating && (
              <Button
                variant={'pg_button_link'}
                startIcon={<AddIcon />}
                onClick={openActionRecorderHandler}
                disabled={isDisabled}
              >
                <Trans>ADD ACTION</Trans>
              </Button>
            )}
          </Grid>

          <Hidden mdDown>
            <Grid item>
              <Box ml={3} className={apaStyles.rightPane} />
            </Grid>
          </Hidden>
        </Grid>
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={projectId}
          utilization={apaUtilization}
          leftColContent={
            <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={customerDefinitionHandler}>
              <Trans>Edit customer</Trans>
            </Button>
          }
          workingFlowProps={{ nextVariant: 'ideas' }}
          disabled={isDisabled}
        />
      </ContentFooter>

      <ApaActionRecorder
        open={openActionRecorder}
        projectId={projectId}
        utilizationId={utilizationId}
        onClose={closeActionRecorderHandler}
      />
    </>
  );
};
