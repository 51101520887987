import { EntityCollectionStatus, EntityMap, LookupMap } from '../common/entity-collection-state';
import { TaskAssignee } from './task-assignee';

export interface TaskAssigneeCollection {
  entities: EntityMap<TaskAssignee>;

  lookups: {
    byTaskId: LookupMap;
  };

  statuses: EntityCollectionStatus;
}

export const DefaultTaskAssigneeCollection: TaskAssigneeCollection = {
  entities: {},

  lookups: {
    byTaskId: {},
  },

  statuses: {},
};
