import { FluxStandardAction } from 'flux-standard-action';
import { createPgAction } from '../../../shared/store/action-creator';
import { IInvitation, IWorkspace, WorkspaceMembershipLevel } from '@priz/shared/src/models/workspace';
import { IWorkspaceMember } from '@priz/shared/src/models/workspace/workspace-member';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { WorkspaceApi } from '../../services';
import { RouterActionMeta } from '../../../shared/store/types';
import { NavigateFunction } from 'react-router-dom';
import { AxiosError } from 'axios';

export enum WorkspaceMembersActionType {
  FetchAll = 'workspace/members/fetchAll',
  Invite = 'workspace/members/invite',
  Remove = 'workspace/member/remove',
  UpdateMembershipLevel = 'workspace/member/level/update',
  AcceptInvitation = 'workspace/invitation/accept',
  ClearErrors = 'workspace/members/clear-errors',
}

interface WorkspaceMembersActionMeta extends RouterActionMeta {
  email?: string;
  token?: string;
}

type WorkspaceMembersActionPayload = IWorkspaceMember | IWorkspaceMember[] | AxiosError;

export type WorkspaceMembersAction = FluxStandardAction<
  string,
  WorkspaceMembersActionPayload,
  WorkspaceMembersActionMeta
>;

const loadAll = createPgAction(
  WorkspaceMembersActionType.FetchAll,
  WorkspaceApi.getWorkspaceMembers,
  undefined,
  undefined,
  undefined,
);

const invite = createPgAction(
  WorkspaceMembersActionType.Invite,
  WorkspaceApi.invite,
  (email: string) => ({
    email,
  }),
  (payload: IWorkspace) => {
    AnalyticsService.track(AnalyticsEvent.WORKSPACE_MEMBER_INVITED, {
      workspace_id: payload.id,
      workspace_type: payload.type,
    });
  },
);

const remove = createPgAction(
  WorkspaceMembersActionType.Remove,
  WorkspaceApi.remove,
  (email: string) => ({
    email,
  }),
  (payload: IWorkspace) => {
    AnalyticsService.track(AnalyticsEvent.WORKSPACE_MEMBER_REMOVED, {
      workspace_id: payload.id,
      workspace_type: payload.type,
    });
  },
);

const updateMembershipLevel = createPgAction(
  WorkspaceMembersActionType.UpdateMembershipLevel,
  WorkspaceApi.updateMembershipLevel,
  (email: string, level: WorkspaceMembershipLevel) => ({
    email,
    level,
  }),
  (payload: IWorkspaceMember) => {
    AnalyticsService.track(AnalyticsEvent.WORKSPACE_MEMBER_LEVEL_UPDATED, {
      workspace_id: payload.id,
      workspace_membership_level: payload.membershipLevel,
    });
  },
);

const acceptInvitation = createPgAction(
  WorkspaceMembersActionType.AcceptInvitation,
  WorkspaceApi.acceptInvitation,
  (token: string, navigate: NavigateFunction) => ({ token, navigate }),
  (invitation: IInvitation, meta: WorkspaceMembersActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.WORKSPACE_INVITATION_ACCEPTED, {
      invitation_id: invitation.id,
      invitee_id: invitation.invitee.id,
      workspace_id: invitation.workspace.id,
    });

    if (meta.navigate) meta.navigate(['/workspace', invitation.workspace.id, 'switch'].join('/'));
  },
);

const clearErrors = createPgAction(WorkspaceMembersActionType.ClearErrors, () => new Promise(resolve => resolve({})));

export const WorkspaceMembersActions = {
  acceptInvitation,
  remove,
  invite,
  loadAll,
  updateMembershipLevel,
  clearErrors,
};
