import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {},
  label: {
    color: pgColorScheme.textGray,
  },
  popover: {
    pointerEvents: 'none',
  },
  contributorsInfo: {
    marginTop: 8,
    marginLeft: 16,
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
});
