import React, { useState } from 'react';
import { PartnerProfile } from '@priz/shared/src/models/security/partner-profile';
import { Alert, alpha, Box, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useStyles } from './styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  CommissionsAdministrationApi,
  PartnerCommissionStatusesUpdateCommand,
} from '../api/commissions-administration.api';
import { PartnerCommissionStatus } from '@priz/shared/src/models/security/partner-commission-status';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import CurrencyFormat from 'react-currency-format';
import { AdminCommissionsTable } from '../admin-commissions-table/component';

interface AdminPartnerProfileTableRowProps {
  profile: PartnerProfile;
}

export const AdminPartnerProfileTableRow: React.FC<AdminPartnerProfileTableRowProps> = ({ profile }) => {
  const styles = useStyles();
  const queryClient = useQueryClient();

  const [isCommissionsListOpen, setIsCommissionsListOpen] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [areStatusesUpdating, setAreStatusesUpdating] = useState(false);

  const { data = [], isFetched } = useQuery(
    ['commissions-administration-list', profile.id],
    () => CommissionsAdministrationApi.getAllByReferrerId(profile.user.id),
    {
      enabled: isCommissionsListOpen,
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const mutationUpdate = useMutation(
    (data: { referrerId: number; ids: number[]; status: PartnerCommissionStatus }) => {
      const command: PartnerCommissionStatusesUpdateCommand = {
        commissionsIds: data.ids,
        status: data.status,
      };

      return CommissionsAdministrationApi.updatePartnerCommissionsStatuses(data.referrerId, command);
    },
    {
      onSuccess: () => {
        setAreStatusesUpdating(false);
        void queryClient.invalidateQueries(['commissions-administration-list', profile.id]);
        void queryClient.invalidateQueries(['partner-profiles-administration-list']);
      },
      onError: (error: { response: { data: { message } } }) => {
        setAreStatusesUpdating(false);
        setErrorText(error?.response?.data?.message || 'Error');
      },
    },
  );

  const updateProfiles = (ids: number[], status: PartnerCommissionStatus) => {
    setErrorText(null);
    setAreStatusesUpdating(true);

    mutationUpdate.mutate({
      referrerId: profile.user.id,
      ids: ids,
      status: status,
    });
  };

  return (
    <>
      <TableRow sx={{ backgroundColor: isCommissionsListOpen ? alpha(pgColorScheme.blue, 0.2) : pgColorScheme.white }}>
        <TableCell className={styles.userInfoCell}>{profile.user.id}</TableCell>

        <TableCell className={styles.userInfoCell}>{profile.name}</TableCell>

        <TableCell className={styles.userInfoCell}>{profile.email}</TableCell>

        <TableCell className={styles.userInfoCell}>{profile.referralCode}</TableCell>

        <TableCell className={styles.userInfoCell}>
          <CurrencyFormat
            value={profile.totalProfit}
            displayType={'text'}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </TableCell>

        <TableCell
          className={styles.userInfoCell}
          sx={{ color: profile.pendingPayout > 0 ? pgColorScheme.coral : pgColorScheme.green }}
        >
          <CurrencyFormat
            value={profile.pendingPayout}
            displayType={'text'}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </TableCell>

        <TableCell align="right" className={styles.userInfoCell}>
          <IconButton size="small" onClick={() => setIsCommissionsListOpen(currentState => !currentState)}>
            {isCommissionsListOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow className={styles.detailsRow}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={isCommissionsListOpen} timeout="auto" unmountOnExit>
            <Box pb={1}>
              {!isFetched && (
                <LoadingOverlay
                  loading={true}
                  backdropStyles={{ backgroundColor: 'transparent', position: 'relative' }}
                  progressContainerProps={{ py: 2 }}
                />
              )}

              {errorText && (
                <Box py={2}>
                  <Alert severity={'error'}>{errorText}</Alert>
                </Box>
              )}

              {isFetched && (
                <AdminCommissionsTable commissions={data} onUpdate={updateProfiles} loading={areStatusesUpdating} />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
