import { createStyles, makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    rootPerceptions: {},
    rootResult: {},
    tabPanelPerceptions: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    tabPanelResult: {
      height: '100%',
    },
    plusButtonLabel: {
      width: '100%',
      color: pgColorScheme.placeholder,
    },
    pMpWrapper: {
      position: 'relative',
      width: '100%',
    },
    diagramAndResultContainer: {
      height: '100%',
    },
    relativeWrap: {
      position: 'relative',
    },
    resultWrap: {
      position: 'relative',
      width: '100%',
    },
    scrollbarContainer: {
      position: 'relative',
      overflow: 'hidden',
      width: 'calc(100% + 48px)',
      left: -24,
    },
    scrollbar: {
      padding: '0 25px',
      boxSizing: 'border-box',
    },
    plusButton: {
      width: '100%',
      flex: '0 0 auto',
      marginTop: 20,
    },
    addNewPerceptionButton: {
      backgroundColor: pgColorScheme.blue,

      ['& svg']: {
        fill: pgColorScheme.white,
      },

      ['&.MuiIconButton-root:hover']: {
        backgroundColor: pgColorScheme.orange,
      },
    },

    [theme.breakpoints.up('md')]: {
      rootResult: {
        height: '100%',
        position: 'absolute',
      },
    },

    [theme.breakpoints.up('sm')]: {
      rootPerceptions: {
        height: '100%',
        position: 'absolute',
      },
    },

    [theme.breakpoints.down('sm')]: {
      scrollbarContainer: {
        width: 'calc(100% + 32px)',
        left: -16,
      },
    },
  }),
);
