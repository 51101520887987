import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  cell: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelContainer: {
    flex: '0 0 auto',
  },
  textContainer: {
    flex: '1 0 0',
  },
});
