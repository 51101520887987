import reduceReducers from 'reduce-reducers';
import { Action, combineReducers } from 'redux';
import { apaActionReducers } from '../apa/store/reducers/apa-action.reducers';
import { fiveWhysCauseReducers } from '../five-whys/store/reducers/five-whys-cause.reducers';
import { fiveWhysSolutionReducers } from '../five-whys/store/reducers/five-whys-solution.reducers';
import { certificateReducers } from '../certificate/store';
import { ebsIdeaReducers } from '../ebs/store/reducers';
import { domainReducers, parameterReducers, principleReducers } from '../forty-principles/store/';
import { domainPrincipleReducers } from '../forty-principles/store/reducers/domain-principle.reducers';
import { fortyPrinciplesConfigReducers } from '../forty-principles/store/reducers/forty-principles-config.reducers';
import { matrixElementReducers } from '../forty-principles/store/reducers/matrix-element.reducers';
import { projectIdeaReducers } from '../idea/store';
import { approvalActivityReducers } from '../project-approval/store/reducers/approval-activity.reducers';
import { approvalActivityProjectReducers, projectReducers } from '../project/store/reducers/project.reducers';
import { toolUtilizationsReducers } from '../project/store/reducers/tool-utilizations.reducers';
import { rrmGoalReducers, rrmUtilizationReducers, toolUtilizationRankingScoreReducers } from '../rrm/store/reducers';
import { userContextReducers } from '../security/store/reducers';
import { toStarted, toSuccess } from '../shared/store/action-creator';
import { taskReducers } from '../tasks/store/reducers/task.reducers';
import { uimTaskReducers } from '../uim/store/reducers/uim-task.redusers';
import { publicUserReducers } from '../user/store/reducers/public-user.reducers';
import { currentUserReducers } from '../user/store/reducers/user.reducers';
import { WorkspaceActionType } from '../workspace/store/actions';
import { planReducers, workspaceMembersReducers, workspaceReducers } from '../workspace/store/reducers';
import { AppState, generateCleanAppState } from './app.state';
import { projectAssigneeReducers } from '../assignment/store/reducers/project-assignee.reducers';
import { notificationReducers } from '../react/modules/notification/store';
import { paywallReducers } from '../react/modules/paywall/store';
import { preloaderReducers } from '../react/preloader/store/reducers';
import { userSettingsReducers } from '../user/store/reducers/user-settings.reducers';
import { UserProjectSettingsReducers } from '../user/store/reducers/user-project-settings.reducers';
import { taskAssigneeReducers } from '../assignment/store/reducers/task-assignee.reducers';
import { attachmentsReducers } from '../attachments/store/reducers';
import { agendasReducers, attendeesReducers, meetingsReducers } from '../meetings/store/reducers';
import { assistantReducers } from '../assistant/store/reducers';
import { dialogsReducers } from '../dialogs/store/reducers';
import { sfmResultReducers } from '../sfm/store/reducers';
import { pMapResultReducers } from '../pmap/store/reducers';
import { apiKeysReducers } from '../user-profile/api-keys/store/reducers';
import { partnershipReducers } from '../user-profile/partnership/store/reducers';
import { teamReducers } from '../team/store/reducers';
import { chatReducers } from '../chat/store/reducers';
import { projectIdeasCommentsReducers } from '../idea/store/reducers/ideas-comments.reducers';
import { projectFeatureBlockerReducers } from '../project-feature-blocker/store/reducers';
import { playgroundReducers } from '../playground/store/reducers';
import { AppActionType } from './app.actions';

const appReducer = combineReducers({
  apaActions: apaActionReducers,
  approvalActivities: approvalActivityReducers,
  certificates: certificateReducers,

  users: publicUserReducers,
  currentUser: currentUserReducers,
  userContext: userContextReducers,
  userSettings: userSettingsReducers,
  userProjectSettings: UserProjectSettingsReducers,

  fiveWhysCauses: fiveWhysCauseReducers,
  fiveWhysSolutions: fiveWhysSolutionReducers,
  domainList: domainReducers,
  domainPrincipleList: domainPrincipleReducers,
  ebsIdeas: ebsIdeaReducers,
  fortyPrinciplesConfig: fortyPrinciplesConfigReducers,

  matrixElementList: matrixElementReducers,

  parameterList: parameterReducers,
  plans: planReducers,
  tasks: taskReducers,

  principleList: principleReducers,
  projectIdeasMap: projectIdeaReducers,
  projectIdeasComments: projectIdeasCommentsReducers,

  projects: reduceReducers(projectReducers, approvalActivityProjectReducers),
  rrmGoals: rrmGoalReducers,
  toolUtilizations: reduceReducers(
    toolUtilizationsReducers,
    rrmUtilizationReducers,
    toolUtilizationRankingScoreReducers,
  ),

  sfmResult: sfmResultReducers,
  pmapResult: pMapResultReducers,

  uimTasks: uimTaskReducers,
  apiKeys: apiKeysReducers,
  partnership: partnershipReducers,
  workspaceMembers: workspaceMembersReducers,
  workspaces: workspaceReducers,
  teams: teamReducers,

  notifications: notificationReducers,
  paywall: paywallReducers,
  projectFeatureBlocker: projectFeatureBlockerReducers,
  preloader: preloaderReducers,

  projectAssignees: projectAssigneeReducers,
  taskAssignees: taskAssigneeReducers,

  attachments: attachmentsReducers,

  agendas: agendasReducers,
  attendees: attendeesReducers,
  meetings: meetingsReducers,

  assistant: assistantReducers,
  chat: chatReducers,

  dialogs: dialogsReducers,

  playground: playgroundReducers,
});

export const rootReducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case toSuccess(AppActionType.Reset):
      return appReducer(generateCleanAppState({}), action);

    case toStarted(WorkspaceActionType.Switch):
      return appReducer(generateCleanAppState({}), action);
  }

  return appReducer(state, action);
};
