import { IdRef } from '../common/id-ref';
import { AuditableEntity, IAuditableEntity } from '../common/auditable-entity';
import { Identifiable } from '../common/entity-collection-state';
import { ProjectCertificationStatus } from './project-certification-status.enum';
import { ProjectStatus } from './project-status.enum';
import { Topic } from './topic';
import { ScopesMap } from './scope';
import { TemplateType } from '../template';

export interface ProjectMetaData {
  skipStartWizard?: boolean;

  projectBackgroundCompleteness?: number;
  projectBackgroundExplanation?: string;

  currentSituationCompleteness?: number;
  currentSituationExplanation?: string;

  disadvantagesCompleteness?: number;
  disadvantagesExplanation?: string;

  idealFinalResultCompleteness?: number;
  idealFinalResultExplanation?: string;

  gapsCompleteness?: number;
  gapsExplanation?: string;

  problemStatementCompleteness?: number;
  problemStatementExplanation?: string;
}

export interface IProject extends IAuditableEntity, Identifiable {
  id: number;
  title: string;
  description?: string;
  industry?: string;
  area?: string;
  goal?: string;
  currentSituation?: string;
  disadvantages?: string;
  idealFinalResult?: string;
  gaps?: string;
  problemStatement?: string;
  owner: IdRef;
  reviewer?: IdRef;
  certificationStatus: ProjectCertificationStatus;
  workspace: IdRef;
  team: IdRef;
  status: ProjectStatus;
  metaData?: ProjectMetaData;
  solution?: string;
  open: boolean;

  topic?: Topic;
  publicTitle?: string;
  publicDescription?: string;
  keywords?: string[];
  posterKey?: string;
  posterUrl?: string;
  originalPosterKey?: string;
  originalPosterUrl?: string;
  publicScopes?: ScopesMap;
  template?: TemplateType;

  lastWorkedOnAt?: Date;
  lastChangedAt?: Date;
  publishedAt?: Date;
}

export class Project extends AuditableEntity implements IProject {
  public id: number;
  public title: string;
  public description?: string;
  public industry?: string;
  public area?: string;
  public goal?: string;
  public currentSituation?: string;
  public disadvantages?: string;
  public idealFinalResult?: string;
  public gaps?: string;
  public problemStatement?: string;
  public owner: IdRef;
  public reviewer?: IdRef;
  public certificationStatus: ProjectCertificationStatus;
  public workspace: IdRef;
  public team: IdRef;
  public status: ProjectStatus = ProjectStatus.Todo;
  public metaData?: ProjectMetaData;
  public solution?: string;
  public open: boolean;
  public topic?: Topic;
  public publicTitle?: string;
  public publicDescription?: string;
  public keywords?: string[];
  public posterKey?: string;
  public posterUrl?: string;
  public originalPosterKey?: string;
  public originalPosterUrl?: string;
  public publicScopes?: ScopesMap;
  public lastChangedAt?: Date;
  public lastWorkedOnAt?: Date;
  public publishedAt?: Date;
  public template?: TemplateType;

  constructor(json: IProject) {
    super(json);

    this.id = json.id;
    this.title = json.title;
    this.description = json.description;
    this.industry = json.industry;
    this.area = json.area;
    this.goal = json.goal;
    this.currentSituation = json.currentSituation;
    this.disadvantages = json.disadvantages;
    this.idealFinalResult = json.idealFinalResult;
    this.gaps = json.gaps;
    this.problemStatement = json.problemStatement;
    this.owner = json.owner;
    this.reviewer = json.reviewer;
    this.certificationStatus = json.certificationStatus;
    this.createdBy = json.createdBy;
    this.dateCreated = json.dateCreated && new Date(json.dateCreated);
    this.updatedBy = json.updatedBy;
    this.lastUpdated = json.lastUpdated && new Date(json.lastUpdated);
    this.workspace = json.workspace;
    this.team = json.team;
    this.status = json.status;
    this.metaData = json.metaData;
    this.solution = json.solution;
    this.open = json.open;
    this.topic = json.topic;
    this.publicTitle = json.publicTitle;
    this.publicDescription = json.publicDescription;
    this.keywords = json.keywords;
    this.posterKey = json.posterKey;
    this.posterUrl = json.posterUrl;
    this.originalPosterKey = json.originalPosterKey;
    this.originalPosterUrl = json.originalPosterUrl;
    this.publicScopes = json.publicScopes;
    this.template = json.template;

    this.lastChangedAt = json.lastChangedAt && new Date(json.lastChangedAt);
    this.lastWorkedOnAt = json.lastWorkedOnAt && new Date(json.lastWorkedOnAt);
    this.publishedAt = json.publishedAt && new Date(json.publishedAt);
  }
}
