import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../../theme';

export const useStyles = makeStyles({
  root: {
    position: 'relative',

    ['&:hover $causeControlsWrap']: {
      opacity: 1,
    },
  },
  causeControlsWrap: {
    position: 'absolute',
    top: '100%',
    right: 0,
    bottom: 'auto',
    left: 'auto',
    padding: 5,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    opacity: 0.5,

    ['&:hover']: {
      opacity: 1,
    },
  },
  description: {
    textAlign: 'center',
    lineHeight: 23 / 16,
    color: 'rgba(0, 0, 0, 0.87)',
    whiteSpace: 'pre-line',
  },
  textareaWrap: {
    cursor: 'text',

    ['&:hover $nodeBorder']: {
      borderColor: pgColorScheme.orange,
    },

    ['& .Mui-focused ~ $nodeBorder']: {
      borderStyle: 'dashed',
      borderColor: pgColorScheme.orange,
    },

    ['& .error ~ $nodeBorder']: {
      borderColor: pgColorScheme.red,
      borderWidth: 2,
    },
  },
  contentWrap: {
    background: pgColorScheme.white,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 'auto',
    borderRadius: 10,
    minHeight: 60,
    wordBreak: 'break-word',
  },
  nodeBorder: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    pointerEvents: 'none',
    boxSizing: 'border-box',
    border: `1px solid ${pgColorScheme.blue}`,
    borderRadius: 'inherit',
  },
  rankContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 'auto',
    left: 'auto',
  },
});
