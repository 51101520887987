import { handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { toObjectMap } from '../../../shared/store/reducer-utils';
import { PlanAction, PlanActionType } from '../actions';
import { DefaultPlanCollection, IPlan, Plan, PlanCollection } from '@priz/shared/src/models/workspace';

const planInitializer = (plan: IPlan) => new Plan(plan);

const setStatuses = (state: PlanCollection, statuses: Partial<EntityCollectionStatus>): PlanCollection => ({
  ...state,
  statuses: {
    ...state.statuses,
    ...statuses,
  },
});

const replaceAllPlans = (state: PlanCollection, plans: IPlan[]): PlanCollection => {
  const entityMap = toObjectMap<Plan>(plans, planInitializer);

  const statusesCopy = {
    ...state.statuses,
    error: false,
    loaded: true,
    loading: false,
  };

  return {
    ...state,
    entities: entityMap,
    statuses: statusesCopy,
  };
};

export const planReducers = handleActions(
  {
    [toStarted(PlanActionType.FetchAll)]: (state: PlanCollection) => setStatuses(state, { loading: true }),
    [toSuccess(PlanActionType.FetchAll)]: (state: PlanCollection, action: PlanAction) =>
      replaceAllPlans(state, action.payload as IPlan[]),
    [toFailed(PlanActionType.FetchAll)]: (state: PlanCollection) =>
      setStatuses(state, { loading: false, loaded: false, error: true }),
  },
  DefaultPlanCollection,
);
