import { FluxStandardAction } from 'flux-standard-action';
import { createPgAction } from '../../../shared/store/action-creator';
import { FiveWhysCauseApi } from '../../service';
import { IFiveWhysCause } from '@priz/shared/src/models/tools/five-whys';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { ToolType } from '@priz/shared/src/models/tools';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';

export enum FiveWhysCauseActionType {
  Fetch = 'five-whys/cause/fetch',
  Create = 'five-whys/cause/create',
  Update = 'five-whys/cause/update',
  Delete = 'five-whys/cause/delete',
}

type FiveWhysCausePayload = IFiveWhysCause | IFiveWhysCause[];

interface FiveWhysCauseMeta {
  projectId?: number;
  utilizationId?: number;
  causeId?: number;
  causedCauseId?: number;
  description?: string;
}

export type FiveWhysCauseAction = FluxStandardAction<string, FiveWhysCausePayload, FiveWhysCauseMeta>;

const loadCauses = createPgAction(
  FiveWhysCauseActionType.Fetch,
  FiveWhysCauseApi.fetchAll,
  (utilizationId: number) => ({ utilizationId }),
);

const createCause = createPgAction(
  FiveWhysCauseActionType.Create,
  FiveWhysCauseApi.createCause,
  (utilizationId: number, description: string, causedCauseId: number, projectId?: number) => ({
    causedCauseId,
    description,
    projectId,
    utilizationId,
  }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.FIVE_WHYS,
      utilization_id: utilizationId,
    });
  },
);

const updateCauseDescription = createPgAction(
  FiveWhysCauseActionType.Update,
  FiveWhysCauseApi.updateCause,
  (utilizationId: number, causeId: number, _command, projectId?: number) => ({
    causeId,
    projectId,
    utilizationId,
  }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.FIVE_WHYS,
      utilization_id: utilizationId,
    });
  },
);

const updateCauseDeadEnd = createPgAction(
  FiveWhysCauseActionType.Update,
  FiveWhysCauseApi.updateCause,
  (utilizationId: number, causeId: number, _command, projectId?: number) => ({
    causeId,
    projectId,
    utilizationId,
  }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.FIVE_WHYS,
      utilization_id: utilizationId,
    });
  },
);

const deleteCause = createPgAction(
  FiveWhysCauseActionType.Delete,
  FiveWhysCauseApi.deleteCause,
  (utilizationId: number, causeId: number, projectId?: number) => ({
    causeId,
    projectId,
    utilizationId,
  }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.FIVE_WHYS,
      utilization_id: utilizationId,
    });
  },
);

export const FiveWhysCauseActions = {
  updateCauseDeadEnd,
  updateCauseDescription,
  createCause,
  loadCauses,
  deleteCause,
};
