import { combineActions, handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { IUser, User } from '@priz/shared/src/models/security/user';
import { UserContextAction, UserContextActionType } from '../actions';
import { EmptyUserContext, UserContext } from '../model';
import { UserAction, UserActionType } from '../../../user/store/actions/user.actions';

export const userContextReducers = handleActions(
  {
    [toStarted(UserContextActionType.UserContext_Load)]: (state: UserContext): UserContext => ({
      ...state,
      loaded: false,
      loading: true,
    }),
    [toFailed(UserContextActionType.UserContext_Load)]: (state: UserContext): UserContext => ({
      ...state,
      loaded: false,
      loading: false,
    }),
    [toSuccess(UserContextActionType.UserContext_Load)]: (
      state: UserContext,
      action: UserContextAction,
    ): UserContext => ({
      ...state,
      loaded: true,
      loading: false,
      currentUser: new User(action.payload.currentUser),
      userWorkspaces: action.payload.workspaceMemberships.map(wm => ({
        id: wm.workspace.id,
        membershipLevel: wm.membershipLevel,
      })),
      workspaceProjectsCountMap: action.payload.workspaceProjectsCountMap,
    }),

    [combineActions(
      toSuccess(UserActionType.UpdatePrivacySettings),
      toSuccess(UserActionType.UpdateSecuritySettings),
      toSuccess(UserActionType.UpdatePrivacySettings),
      toSuccess(UserActionType.DeleteConnection),
      toSuccess(UserActionType.AcceptPolicies),
    )]: (state: UserContext, action: UserAction) => ({
      ...state,
      currentUser: new User(action.payload as IUser),
    }),
  },
  EmptyUserContext,
);
