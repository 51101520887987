import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { handleActions, combineActions } from 'redux-actions';
import { UserProjectSettingsAction, UserProjectSettingsActionType } from '../actions/user-project-settings.actions';
import { DefaultUserProjectSettingsState, UserProjectSettingsState } from '../model';

export const UserProjectSettingsReducers = handleActions(
  {
    [combineActions(toStarted(UserProjectSettingsActionType.Fetch), toStarted(UserProjectSettingsActionType.Update))]: (
      state: UserProjectSettingsState,
    ): UserProjectSettingsState => ({
      ...state,
      loading: true,
    }),

    [combineActions(toFailed(UserProjectSettingsActionType.Fetch), toFailed(UserProjectSettingsActionType.Update))]: (
      state: UserProjectSettingsState,
    ): UserProjectSettingsState => ({
      ...state,
      loading: false,
      loaded: false,
      error: true,
    }),

    [combineActions(toSuccess(UserProjectSettingsActionType.Fetch), toSuccess(UserProjectSettingsActionType.Update))]: (
      state: UserProjectSettingsState,
      action: UserProjectSettingsAction,
    ): UserProjectSettingsState => ({
      ...state,
      loading: false,
      loaded: true,
      error: false,
      settings: { ...state.settings, ...action.payload.settings },
    }),
  },
  DefaultUserProjectSettingsState,
);
