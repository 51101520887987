import { INineWindowsUtilization } from '../models/tools/nine-windows';

export const windowTypeMap: { [key in keyof Partial<INineWindowsUtilization>]: { order: number; label: string } } = {
  pastSupersystem: { order: 6, label: 'Past Super System' },
  presentSupersystem: { order: 3, label: 'Present Super System' },
  futureSupersystem: { order: 8, label: 'Future Super System' },
  pastSystem: { order: 4, label: 'Past System' },
  presentSystem: { order: 1, label: 'Present System' },
  futureSystem: { order: 9, label: 'Future System' },
  pastSubsystem: { order: 5, label: 'Past Subsystem' },
  presentSubsystem: { order: 2, label: 'Present Subsystem' },
  futureSubsystem: { order: 7, label: 'Future Subsystem' },
};
