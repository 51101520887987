import { useSelector } from 'react-redux';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { ProjectSelector } from '../../project/store/selectors';
import { projectDataCompletenessValuesMap } from '../../project/store/model';

interface ProjectDataCompletenessHookData {
  isAiAssistantEnabled: boolean;
  isProjectBackgroundDefined: boolean;
  isProjectBackgroundCompleted: boolean;
  isCurrentSituationDefined: boolean;
  isCurrentSituationCompleted: boolean;
  isDisadvantagesDefined: boolean;
  isDisadvantagesCompleted: boolean;
  isProblemStatementDefined: boolean;
  isProblemStatementCompleted: boolean;
  isIdealFinalResultDefined: boolean;
  isIdealFinalResultCompleted: boolean;
  isGapsDefined: boolean;
  isGapsCompleted: boolean;
  isAllProjectDataDefined: boolean;
  isAllProjectDataCompleted: boolean;
  isAllProjectDataDone: boolean;
  definitionMessage: string;
  completenessMessage: string;
  message: string;
}

export const useProjectDataCompleteness = (projectId: number): ProjectDataCompletenessHookData => {
  const { blue } = projectDataCompletenessValuesMap;

  const selectedWorkspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const isAiAssistantEnabled = useSelector(WorkspaceSelectors.isAiAssistantEnabled(selectedWorkspace?.id));

  const isProjectBackgroundDefined = useSelector(ProjectSelector.isProjectDescriptionDefinedById(projectId));
  const isProjectBackgroundCompleted = useSelector(ProjectSelector.isProjectBackgroundCompleted(projectId));

  const isCurrentSituationDefined = useSelector(ProjectSelector.isCurrentSituationDefinedById(projectId));
  const isCurrentSituationCompleted = useSelector(ProjectSelector.isCurrentSituationCompleted(projectId));

  const isDisadvantagesDefined = useSelector(ProjectSelector.isDisadvantagesDefinedById(projectId));
  const isDisadvantagesCompleted = useSelector(ProjectSelector.isDisadvantagesCompleted(projectId));

  const isProblemStatementDefined = useSelector(ProjectSelector.isProblemStatementDefinedById(projectId));
  const isProblemStatementCompleted = useSelector(ProjectSelector.isProblemStatementCompleted(projectId));

  const isIdealFinalResultDefined = useSelector(ProjectSelector.isIdealFinalResultDefinedById(projectId));
  const isIdealFinalResultCompleted = useSelector(ProjectSelector.isIdealFinalResultCompleted(projectId));

  const isGapsDefined = useSelector(ProjectSelector.isGapsDefinedById(projectId));
  const isGapsCompleted = useSelector(ProjectSelector.isGapsCompleted(projectId));

  const definitionCheck = [
    { condition: isProjectBackgroundDefined, message: 'Project background is required' },
    { condition: isCurrentSituationDefined, message: 'Current situation is required' },
    { condition: isDisadvantagesDefined, message: 'Disadvantages of current situation are required' },
    { condition: isIdealFinalResultDefined, message: 'Ideal final result description is required' },
    { condition: isGapsDefined, message: 'Gaps description is required' },
    { condition: isProblemStatementDefined, message: 'Problem to solve is required' },
  ];

  const completenessCheck = [
    {
      condition: isProjectBackgroundCompleted,
      message: `Project background must have at least ${blue}% of completeness`,
    },
    // {
    //   condition: isCurrentSituationCompleted,
    //   message: `Current situation must have at least ${blue}% of completeness`,
    // },
    // {
    //   condition: isDisadvantagesCompleted,
    //   message: `Disadvantages of current situation must have at least ${blue}% of completeness`,
    // },
    // {
    //   condition: isIdealFinalResultCompleted,
    //   message: `Ideal final result description must have at least ${blue}% of completeness`,
    // },
    // {
    //   condition: isGapsCompleted,
    //   message: `Gaps description must have at least ${blue}% of completeness`,
    // },
    // {
    //   condition: isProblemStatementCompleted,
    //   message: `Problem to solve must have at least ${blue}% of completeness`,
    // },
  ];

  let definitionMessage = '';
  let completenessMessage = '';

  for (let i = 0; i < definitionCheck.length; i++) {
    if (!definitionCheck[i].condition) {
      definitionMessage = definitionCheck[i].message;
      break;
    }
  }

  for (let i = 0; i < completenessCheck.length; i++) {
    if (!completenessCheck[i].condition) {
      completenessMessage = completenessCheck[i].message;
      break;
    }
  }

  const isAllProjectDataDefined = Object.values(definitionCheck).every(i => !!i.condition);
  const isAllProjectDataCompleted = Object.values(completenessCheck).every(i => !!i.condition);

  const isAllProjectDataDone = isAllProjectDataDefined && isAllProjectDataCompleted;
  const message = definitionMessage.length ? definitionMessage : completenessMessage.length ? completenessMessage : '';

  if (typeof projectId !== 'undefined') {
    return {
      isAiAssistantEnabled,
      isProjectBackgroundDefined,
      isProjectBackgroundCompleted,
      isCurrentSituationDefined,
      isCurrentSituationCompleted,
      isDisadvantagesDefined,
      isDisadvantagesCompleted,
      isProblemStatementDefined,
      isProblemStatementCompleted,
      isIdealFinalResultDefined,
      isIdealFinalResultCompleted,
      isGapsDefined,
      isGapsCompleted,
      isAllProjectDataDefined,
      isAllProjectDataCompleted,
      isAllProjectDataDone,
      definitionMessage,
      completenessMessage,
      message,
    };
  } else {
    return {
      isAiAssistantEnabled,
      isProjectBackgroundDefined: true,
      isProjectBackgroundCompleted: true,
      isCurrentSituationDefined: true,
      isCurrentSituationCompleted: true,
      isDisadvantagesDefined: true,
      isDisadvantagesCompleted: true,
      isProblemStatementDefined: true,
      isProblemStatementCompleted: true,
      isIdealFinalResultDefined: true,
      isIdealFinalResultCompleted: true,
      isGapsDefined: true,
      isGapsCompleted: true,
      isAllProjectDataDefined: true,
      isAllProjectDataCompleted: true,
      isAllProjectDataDone: true,
      definitionMessage: '',
      completenessMessage: '',
      message: '',
    };
  }
};
