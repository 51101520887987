import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { AppNavbar } from '../navigation/app-navbar/component';
import { PageTitleWithDocLink } from '../shared/PageTitleWithDocLink';
import { Alert, Box, Pagination } from '@mui/material';
import { Trans } from 'react-i18next';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { LinearLoader } from '../react/elements/linear-loader/component';
import { PageContainer } from '../content-containers/page-container/component';
import { UsersAdministrationApi } from '../users-manager/api/users-administration.api';
import { AdminPartnerProfileTable } from './admin-partner-profiles-table/component';

const itemsCountPerPage = 20;

export const CommissionsManager: React.FC = () => {
  const [page, setPage] = useState(0);

  const { isFetching, data, isFetched } = useQuery(
    ['partner-profiles-administration-list', page],
    () => UsersAdministrationApi.getAllActivePartnerProfiles(page),
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const pageChangeHandler = (_event: React.ChangeEvent, page: number) => {
    setPage(page - 1);
  };

  return (
    <PageContainer>
      <AppNavbar />

      <>
        <PageTitleWithDocLink title={'Commissions manager'} />

        {isFetched && <LinearLoader mt={2} loading={isFetching} />}

        {isFetched && !(data && data?.profiles?.length) && (
          <Box>
            <Alert severity={'info'}>
              <Trans>Partner profiles not found</Trans>
            </Alert>
          </Box>
        )}

        {isFetched && data && !!data?.profiles?.length && <AdminPartnerProfileTable profiles={data.profiles} />}

        {!isFetched && !data && (
          <Box position={'relative'}>
            <LoadingOverlay loading={isFetching} backdropStyles={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }} />
          </Box>
        )}

        {isFetched && data?.totalCount > itemsCountPerPage && (
          <Box mt={4} display={'flex'} justifyContent={'center'}>
            <Pagination
              page={page + 1}
              count={Math.ceil(data.totalCount / itemsCountPerPage)}
              onChange={pageChangeHandler}
            />
          </Box>
        )}
      </>
    </PageContainer>
  );
};
