import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { PlaygroundToolResolver } from '../playground-tool-resolver/component';
import { ToolUtilizationActions } from '../../project/store/actions/tools.actions';
import { AxiosService } from '@priz/shared/src/services/axios';
import { PrizLoadingOverlay } from '@priz/shared/src/components/priz-loading-overlay/component';
import { ErrorPage, ErrorPageType } from '../../error-page/component';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { PlaygroundActions } from '../store/actions';
import { bindPageLeavingPreventing, unbindPageLeavingPreventing } from '@priz/shared/src/utils/common';
import { SignupDialog } from '../../security/signup-dialog/component';
import { PlaygroundSelectors } from '../store/selectors';

export const PlaygroundToolLoader: React.FC = () => {
  const dispatch = useDispatch();
  const urlParams = useParams();

  const publicId = urlParams['publicId'];
  const sub = urlParams['sub'];
  const subId = urlParams['subId'];

  const [isToolAccessAllowed, setIsToolAccessAllowed] = useState(false);
  const [isToolNotFound, setIsToolNotFound] = useState(false);

  const tool = useSelector(ToolUtilizationSelector.getByPublicId(publicId));
  const errorCode = useSelector(ToolUtilizationSelector.errorCodeByPublicUtilizationId(publicId));
  const isAuthenticationRequired = useSelector(PlaygroundSelectors.isAuthenticationRequired);

  useEffect(() => {
    if (tool) {
      resolveAccess();
    } else {
      resetStateAndLoadTool();
    }
  }, [tool?.id]);

  useEffect(() => {
    if (errorCode === 404) {
      setIsToolNotFound(true);
    }
  }, [errorCode]);

  const resolveAccess = () => {
    bindPageLeavingPreventing();

    setIsToolAccessAllowed(true);
    setIsToolNotFound(false);

    if (AxiosService.getUtilizationToken()) {
      dispatch(PlaygroundActions.disableAuthenticationRequirement());
    } else {
      dispatch(PlaygroundActions.enableAuthenticationRequirement());
    }

    LocalStorageService.setItem(LocalStorageKey.PublicUtilizationIdToMove, tool.publicId);
  };

  const resetStateAndLoadTool = () => {
    unbindPageLeavingPreventing();

    setIsToolAccessAllowed(false);
    setIsToolNotFound(false);

    dispatch(PlaygroundActions.disableAuthenticationRequirement());
    dispatch(ToolUtilizationActions.loadPublicTool(publicId));

    LocalStorageService.removeItem(LocalStorageKey.PublicUtilizationIdToMove);
  };

  if (isToolNotFound) {
    return <ErrorPage type={ErrorPageType.NotFound} renderNavbar={false} />;
  }

  if (isToolAccessAllowed) {
    return (
      <>
        <PlaygroundToolResolver tool={tool} sub={sub} subId={subId} />

        <SignupDialog forceOpen={isAuthenticationRequired} beforeSend={unbindPageLeavingPreventing} />
      </>
    );
  }

  return <PrizLoadingOverlay />;
};
