import React, { CSSProperties } from 'react';
import { pgColorScheme } from '@priz/shared/src/theme';

interface SuperSystemIconProps {
  styleProps?: CSSProperties;
  onClick?: () => void;
}

export const SuperSystemIcon: React.FC<SuperSystemIconProps> = ({ styleProps, onClick, ...rest }) => {
  return (
    <div
      onClick={() => {
        if (onClick) onClick();
      }}
      style={{
        width: 50,
        height: 25,
        background: pgColorScheme.mediumBlue,
        ...styleProps,
      }}
      {...rest}
    />
  );
};
