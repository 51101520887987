import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  root: {
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: pgColorScheme.gray2,
  },
  button: {
    height: 'auto',
    minHeight: 0,
    padding: 2,
    borderRadius: 4,
  },
});
