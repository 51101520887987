import { IdRef } from '../../common/id-ref';
import { AuditableEntity, IAuditableEntity } from '../../common/auditable-entity';
import { Identifiable } from '../../common/entity-collection-state';

export interface IFiveWhysCause extends IAuditableEntity, Identifiable {
  description?: string;
  utilization: IdRef;
  causing?: IdRef;
  deadEnd?: boolean;
}

export class FiveWhysCause extends AuditableEntity implements IFiveWhysCause {
  public id: number;

  public description?: string;
  public utilization: IdRef;
  public causing?: IdRef;
  public deadEnd?: boolean;

  constructor(json: IFiveWhysCause) {
    super(json);

    this.id = json.id;
    this.description = json.description;
    this.utilization = json.utilization;
    this.causing = json.causing;
    this.deadEnd = json.deadEnd;
  }
}
