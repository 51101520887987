import { User } from '../../models/security/user';
import { PublicUser } from '../../models/user';
import { WorkspaceMember } from '../../models/workspace';
import { HubPublicUser } from '../../models/user/hub-public-user';

const getFirstAndLastNames = (
  user: User | PublicUser | WorkspaceMember | HubPublicUser,
): {
  firstName: string | undefined;
  lastName: string | undefined;
} => {
  let firstName = undefined;
  let lastName = undefined;

  if (!user) {
    return { firstName, lastName };
  }

  if ('profile' in user) {
    firstName = user?.profile?.firstName;
    lastName = user?.profile?.lastName;
  }

  if ('firstName' in user) {
    firstName = user?.firstName;
  }

  if ('lastName' in user) {
    lastName = user?.lastName;
  }

  return { firstName, lastName };
};

const extractInitials = (value: string): string => {
  let initials;
  const words = (value || '?').split(/[\s._-]+/);
  if (words.length > 1) {
    initials = words
      .map(word => (word.length ? word.charAt(0).toUpperCase() : '?'))
      .slice(0, 2)
      .join('');
  } else {
    initials = words[0].slice(0, 2);
  }
  return initials;
};

const getFullName = (user: User | PublicUser | WorkspaceMember | HubPublicUser): string | undefined => {
  const { firstName, lastName } = getFirstAndLastNames(user);
  const name = [firstName, lastName].join(' ').trim();

  return name.length ? name : undefined;
};

const getShowableName = (user: User | PublicUser | WorkspaceMember | HubPublicUser, withEmail = false): string => {
  const name = getFullName(user);
  const showableName = [];

  if (name) showableName.push(name);
  if (withEmail && user.email) showableName.push(`(${user.email})`);
  if (!withEmail && !name && user.email) showableName.push(user.email);
  if (!showableName.length && 'username' in user) showableName.push(user.username);

  return showableName.join(' ');
};

const resolveInitials = (user: User | PublicUser | WorkspaceMember | HubPublicUser): string =>
  UserUtils.extractInitials(getShowableName(user));

const getAvatarUrl = (user: User | PublicUser | WorkspaceMember | HubPublicUser): string => {
  if ('avatarUrl' in user) return user?.avatarUrl || '';
  if ('profile' in user) return user?.profile?.avatarUrl || '';
  return '';
};

export const UserUtils = {
  extractInitials,
  getFullName,
  resolveInitials,
  getShowableName,
  getAvatarUrl,
};
