import cloneDeep from 'lodash/cloneDeep';
import { combineActions, handleActions } from 'redux-actions';
import { ProjectCertificationWorkflowService } from '../../../project-approval/services/project-certification-workflow.service';
import {
  ApprovalActivityAction,
  ApprovalActivityActionType,
} from '../../../project-approval/store/actions/approval-activity.actions';
import {
  ApprovalActivity,
  ApprovalActivityActionEnum,
  ApprovalActivityStatus,
} from '../../../project-approval/store/model';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { IProject, Project, ProjectMetaData } from '@priz/shared/src/models/project';
import { ProjectAction, ProjectActionType } from '../actions/project.actions';
import { DefaultProjectCollection, ProjectCollection, ProjectCollectionStatus } from '@priz/shared/src/models/project';
import { AxiosError } from 'axios';
import { AssistantAction, AssistantActionType } from '../../../assistant/store/actions';
import { resolveProjectMetaDataUpdate } from '../../problem-statement/utils';
import { CompletenessFeedback } from '../../../assistant/store/model';
import { CollectionStatusesService } from '@priz/shared/src/services/statuses/collection-statuses.service';

const setCollectionStatus = (state: ProjectCollection, status: Partial<ProjectCollectionStatus>): ProjectCollection => {
  const statusesCopy = {
    ...state.status,
    ...status,
  };

  return {
    ...state,
    status: statusesCopy,
  };
};

const setLoadedProjects = (state: ProjectCollection, projectsPayload: IProject[]): ProjectCollection => {
  const newProjectMap = projectsPayload.reduce((map, projectPayload) => {
    map[projectPayload.id] = new Project(projectPayload);
    return map;
  }, {});

  return {
    ...state,
    entities: newProjectMap,
    status: {
      error: false,
      loaded: true,
      loading: false,
    },
  };
};

const setProject = (
  state: ProjectCollection,
  projectPayload: IProject,
  status?: Partial<EntityCollectionStatus>,
): ProjectCollection => {
  const project = new Project(projectPayload);
  const entitiesCopy = cloneDeep(state.entities);
  entitiesCopy[project.id] = project;

  let statusCopy = { ...state.status };
  if (status) {
    statusCopy = {
      ...statusCopy,
      ...status,
    };
  }

  return {
    ...state,
    entities: entitiesCopy,
    status: statusCopy,
  };
};

const setProjectMetaData = (
  state: ProjectCollection,
  projectId: number,
  metaData: Partial<ProjectMetaData>,
): ProjectCollection => {
  const entitiesCopy = cloneDeep(state.entities);
  const project = entitiesCopy[projectId];

  if (project && Object.keys(metaData).length) {
    const updatedProject = new Project(project);

    updatedProject.metaData = {
      ...(project.metaData || {}),
      ...(metaData || {}),
    };

    entitiesCopy[project.id] = updatedProject;
  }

  return {
    ...state,
    entities: entitiesCopy,
  };
};

const setPublishCountdown = (
  state: ProjectCollection,
  projectId: number,
  publishCountdown?: Date,
): ProjectCollection => {
  let countdownsCopy = { ...state.publishCountdowns };

  if (publishCountdown) {
    countdownsCopy = {
      ...countdownsCopy,
      [projectId]: publishCountdown,
    };
  }

  return {
    ...state,
    publishCountdowns: countdownsCopy,
  };
};

const removeProject = (
  state: ProjectCollection,
  projectId: number,
  statuses?: Partial<EntityCollectionStatus>,
): ProjectCollection => {
  const entitiesCopy = cloneDeep(state.entities);
  delete entitiesCopy[projectId];

  return {
    ...state,
    entities: entitiesCopy,
    status: {
      ...state.status,
      ...(statuses || {}),
    },
  };
};

export const projectReducers = handleActions(
  {
    // FetchAll

    [toStarted(ProjectActionType.FetchAll)]: (state: ProjectCollection): ProjectCollection =>
      setCollectionStatus(state, { loading: true, error: false, errorCode: undefined }),

    [toSuccess(ProjectActionType.FetchAll)]: (state: ProjectCollection, action: ProjectAction) =>
      setLoadedProjects(state, action.payload as IProject[]),

    [toFailed(ProjectActionType.FetchAll)]: (state: ProjectCollection, action: ProjectAction) =>
      setCollectionStatus(state, {
        loading: false,
        loaded: false,
        error: true,
        errorCode: (action?.payload as AxiosError)?.response?.status,
      }),

    // Fetch

    [toSuccess(ProjectActionType.Fetch)]: (state: ProjectCollection, action: ProjectAction) =>
      setProject(state, action.payload as IProject),

    // Create

    [toStarted(ProjectActionType.Create)]: (state: ProjectCollection) => setCollectionStatus(state, { creating: true }),

    [toSuccess(ProjectActionType.Create)]: (state: ProjectCollection, action: ProjectAction) =>
      setProject(state, action.payload as IProject, { creating: false, error: false }),

    [toFailed(ProjectActionType.Create)]: (state: ProjectCollection) =>
      setCollectionStatus(state, { creating: false, error: true }),

    // Update, ProblemStatementUpdate, SolutionUpdate, PublishChanges

    [combineActions(
      toStarted(ProjectActionType.Update),
      toStarted(ProjectActionType.ProblemStatementUpdate),
      toStarted(ProjectActionType.SolutionUpdate),
      toStarted(ProjectActionType.PublishChanges),
      toStarted(ProjectActionType.UpdateStatus),
      toStarted(ProjectActionType.UpdatePublicInfo),
    )]: (state: ProjectCollection) => setCollectionStatus(state, { saving: true, updated: false }),

    [combineActions(
      toFailed(ProjectActionType.Update),
      toFailed(ProjectActionType.ProblemStatementUpdate),
      toFailed(ProjectActionType.SolutionUpdate),
      toFailed(ProjectActionType.PublishChanges),
      toFailed(ProjectActionType.UpdateStatus),
      toFailed(ProjectActionType.UpdatePublicInfo),
    )]: (state: ProjectCollection) => setCollectionStatus(state, { saving: false, updated: false, error: true }),

    [combineActions(
      toSuccess(ProjectActionType.Update),
      toSuccess(ProjectActionType.ProblemStatementUpdate),
      toSuccess(ProjectActionType.SolutionUpdate),
      toSuccess(ProjectActionType.PublishChanges),
      toSuccess(ProjectActionType.UpdateStatus),
      toSuccess(ProjectActionType.UpdatePublicInfo),
    )]: (state: ProjectCollection, action: ProjectAction) =>
      setProject(state, action.payload as IProject, { saving: false, updated: true, error: false }),

    // Delete

    [toStarted(ProjectActionType.Delete)]: (state: ProjectCollection, action: ProjectAction) =>
      setCollectionStatus(
        state,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.status,
          removing: true,
          removed: false,
          error: false,
          idsUpdate: [{ target: 'removingIds', method: 'add', ids: [action.meta.project.id] }],
        }),
      ),

    [toFailed(ProjectActionType.Delete)]: (state: ProjectCollection, action: ProjectAction) =>
      setCollectionStatus(
        state,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.status,
          removing: false,
          removed: false,
          error: true,
          idsUpdate: [{ target: 'removingIds', method: 'remove', ids: [action.meta.project.id] }],
        }),
      ),

    [toSuccess(ProjectActionType.Delete)]: (state: ProjectCollection, action: ProjectAction) =>
      removeProject(
        state,
        action.meta.project.id,
        CollectionStatusesService.updateStatusesCollection({
          collection: state.status,
          removing: false,
          removed: true,
          error: false,
          idsUpdate: [{ target: 'removingIds', method: 'remove', ids: [action.meta.project.id] }],
        }),
      ),

    // SetPublishCountdown

    [combineActions(toSuccess(ProjectActionType.SetPublishCountdown), toSuccess(ProjectActionType.UpdatePublicInfo))]: (
      state: ProjectCollection,
      action: ProjectAction,
    ) => {
      return setPublishCountdown(state, action.meta.projectId, new Date());
    },

    // AI Feedback

    [toSuccess(AssistantActionType.GetProjectFeedback)]: (state: ProjectCollection, action: AssistantAction) =>
      setProjectMetaData(
        state,
        action.meta.projectId,
        resolveProjectMetaDataUpdate(action.payload as CompletenessFeedback, action.meta.assistance),
      ),
  },
  DefaultProjectCollection,
);

const setProjectCertificationStatusByFinalActivity = (
  state: ProjectCollection,
  projectId: number,
  actingUserId: number,
  approvalActivity: ApprovalActivity,
  status: Partial<ProjectCollectionStatus>,
) => {
  if (approvalActivity.status !== ApprovalActivityStatus.Final) {
    return state;
  }

  const entitiesCopy = cloneDeep(state.entities);
  const projectCopy: Project = {
    ...entitiesCopy[projectId],
    certificationStatus: ProjectCertificationWorkflowService.resolveProjectCertificationStatusByApprovalActivityAction(
      approvalActivity.action,
    ),
  };

  if (approvalActivity.action === ApprovalActivityActionEnum.AcceptedForReview) {
    projectCopy.reviewer = { id: actingUserId };
  }

  entitiesCopy[projectId] = projectCopy;

  return {
    ...state,
    entities: entitiesCopy,
    status: {
      ...state.status,
      ...status,
    },
  };
};

export const approvalActivityProjectReducers = handleActions(
  {
    [toSuccess(ApprovalActivityActionType.Create)]: (state: ProjectCollection, action: ApprovalActivityAction) =>
      setProjectCertificationStatusByFinalActivity(
        state,
        action.meta.projectId,
        action.meta.actingUserId,
        new ApprovalActivity(action.payload),
        { saving: false, error: false },
      ),

    [toSuccess(ApprovalActivityActionType.Update)]: (state: ProjectCollection, action: ApprovalActivityAction) =>
      setProjectCertificationStatusByFinalActivity(
        state,
        action.meta.projectId,
        action.meta.actingUserId,
        new ApprovalActivity(action.payload),
        { saving: false, error: false },
      ),

    [combineActions(toStarted(ApprovalActivityActionType.Create), toStarted(ApprovalActivityActionType.Update))]: (
      state: ProjectCollection,
    ) => setCollectionStatus(state, { saving: true }),

    [combineActions(toFailed(ApprovalActivityActionType.Create), toFailed(ApprovalActivityActionType.Update))]: (
      state: ProjectCollection,
    ) => setCollectionStatus(state, { saving: false, error: true }),
  },

  DefaultProjectCollection,
);
