import { PfmStep, PfmStepType } from '../../models/tools/pfm';

type PfmStepsCounterResult = { [key in PfmStepType]: number };

export const countPfmStepsTypes = (steps: PfmStep[]): PfmStepsCounterResult => {
  const typesCounters: PfmStepsCounterResult = {
    [PfmStepType.Productive]: 0,
    [PfmStepType.Providing]: 0,
    [PfmStepType.Corrective]: 0,
    [PfmStepType.Metrology]: 0,
  };

  steps.forEach(step => {
    if (step.type) typesCounters[step.type] += 1;
  });

  return typesCounters;
};
