import React, { useEffect, useState } from 'react';
import { Backdrop, Box, BoxProps, CircularProgress, Paper, Typography } from '@mui/material';
import { useStyles } from './styles';
import { Trans } from 'react-i18next';

export interface LoadingOverlaySettings {
  fullScreen?: boolean;
  text?: string;
  content?: JSX.Element;
  backdropStyles?: React.CSSProperties;
  disableShrink?: boolean;
  size?: number;
  disableTooLongLoadingMessages?: boolean;
  progressContainerProps?: BoxProps;
}

interface LoadingOverlayProps extends LoadingOverlaySettings {
  loading?: boolean;
}

const tooLongLoadingMessages = ['Almost done', 'And a bit more', 'Give me a second'];

let messageInterval: NodeJS.Timer;

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  loading,
  fullScreen,
  text,
  content,
  backdropStyles,
  disableShrink = true,
  size = 40,
  progressContainerProps,
  disableTooLongLoadingMessages,
  children,
}) => {
  const styles = useStyles();
  const [tooLongLoadingMessage, setTooLongLoadingMessage] = useState<string>();

  useEffect(() => {
    if (loading) {
      let messagesCopy = [...tooLongLoadingMessages];

      messageInterval = setInterval(() => {
        if (!messagesCopy.length) {
          messagesCopy = [...tooLongLoadingMessages];
        }

        setTooLongLoadingMessage(messagesCopy.shift());
      }, 3 * 1000);
    } else {
      clearInterval(messageInterval);
      setTooLongLoadingMessage(undefined);
    }

    return () => {
      clearInterval(messageInterval);
    };
  }, [loading]);

  return (
    <Backdrop
      open={loading || false}
      className={styles.backdrop}
      transitionDuration={{ exit: 0 }}
      style={{ position: fullScreen ? 'fixed' : 'absolute', ...backdropStyles }}
    >
      {text || content || children ? (
        <Paper>
          <Box px={8} py={3}>
            <Box mb={3}>
              {text && (
                <Typography variant={'body1'} component={'div'}>
                  <Trans>{text}</Trans>
                </Typography>
              )}

              {content}
              {children}
            </Box>
            <CircularProgress className={styles.progress} disableShrink={disableShrink} size={size} />
          </Box>
        </Paper>
      ) : (
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
          {...progressContainerProps}
        >
          <CircularProgress className={styles.progress} disableShrink={disableShrink} size={size} />

          {!disableTooLongLoadingMessages && tooLongLoadingMessage && (
            <Typography className={styles.messageContainer} variant={'body1'} component={Box} mt={1}>
              <span className={styles.message}>
                <Trans>{tooLongLoadingMessage}</Trans>...
              </span>
            </Typography>
          )}
        </Box>
      )}
    </Backdrop>
  );
};
