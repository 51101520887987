import React from 'react';
import { useDispatch } from 'react-redux';
import { Certificate, CertificateActions } from '../store';
import { Project } from '@priz/shared/src/models/project';
import { Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ProjectFlowService } from '../../project/services';
import { fixQuillEmptyValue } from '@priz/shared/src/utils/form';
import { ReactHookFormQuill } from '../../react/form-elements';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { Button } from '@mui/material';

export interface EditCertificateFullDocumentProps {
  project: Project;
  certificate: Certificate;
}

export const EditCertificateFullDocument: React.FC<EditCertificateFullDocumentProps> = ({ project, certificate }) => {
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    shouldFocusError: false,
    defaultValues: {
      fullDocument: certificate.fullDocument || '',
    },
  });

  const isProjectLocked = ProjectFlowService.isProjectLocked(project);

  const save = () => {
    handleSubmit(formData => {
      dispatch(
        CertificateActions.updateCertificate({
          ...certificate,
          fullDocument: fixQuillEmptyValue(formData.fullDocument),
        }),
      );
    })();
  };

  return (
    <>
      <ButtonsWrap spacing={2} justifyContent={'flex-end'} mb={1}>
        <Button
          variant={'pg_rounded'}
          color={'pg_orange_solid'}
          size={'pg_small'}
          onClick={save}
          disabled={isProjectLocked || !isDirty}
        >
          <Trans>Save</Trans>
        </Button>
      </ButtonsWrap>

      <ReactHookFormQuill
        placeholder={'Detailed documentation of your invention here...'}
        name={'fullDocument'}
        control={control}
        wrapperProps={{
          mb: 0,
        }}
      />

      <ButtonsWrap spacing={2} justifyContent={'flex-end'} mt={2}>
        <Button
          variant={'pg_rounded'}
          color={'pg_orange_solid'}
          size={'pg_small'}
          onClick={save}
          disabled={isProjectLocked || !isDirty}
        >
          <Trans>Save</Trans>
        </Button>
      </ButtonsWrap>
    </>
  );
};
