import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip } from '@mui/material';
import { AvailableToolsList } from '../available-tools-list/component';
import { makeStyles } from '@mui/styles';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm/rrm-goal-type.enum';
import { Trans } from 'react-i18next';
import { AutoFixHighOutlined } from '@mui/icons-material';
import { AssistantActions } from '../../assistant/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AssistanceType } from '../../assistant/store/model';
import { v4 as uuidv4 } from 'uuid';
import { AssistantSelectors } from '../../assistant/store/selectors';
import { LoadingButton } from '@mui/lab';
import { useProjectDataCompleteness } from '../../assistant/utils';
import { ToolUtilizationSelector } from '../../project/store/selectors';

const useStyles = makeStyles({
  actions: {
    textAlign: 'right',
  },
});

export interface ToolSelectorDialogSharedProps {
  parentId?: number;
  sourceId?: string;
}

interface ToolSelectorDialogProps extends ToolSelectorDialogSharedProps {
  isOpen: boolean;
  projectId?: number;
  onClose?: () => void;
  rrmGoalType?: RrmGoalType;
  disableAssistant?: boolean;
  navigateOnCreate?: boolean;
}

export const ToolSelectorDialog: React.FC<ToolSelectorDialogProps> = ({
  projectId,
  isOpen,
  onClose,
  rrmGoalType,
  parentId,
  sourceId,
  disableAssistant,
  navigateOnCreate,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [assistantId] = useState(uuidv4());
  const [open, setOpen] = useState(false);

  const { isAiAssistantEnabled, isAllProjectDataDone, message } = useProjectDataCompleteness(projectId);

  const hintData = useSelector(AssistantSelectors.hintByAssistantId(assistantId));
  const isLoading = useSelector(AssistantSelectors.isLoadingByAssistantId(assistantId));
  const isCreated = useSelector(ToolUtilizationSelector.isAnyCreated(projectId));

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isCreated) handleClose();
  }, [isCreated]);

  const handleClose = () => {
    setOpen(false);

    if (onClose) {
      onClose();
    }
  };

  const suggestionHandler = () => {
    dispatch(AssistantActions.getToolSuggestionsHint(projectId, AssistanceType.AskToolSuggestionsHint, assistantId));
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
      <DialogTitle id="alert-dialog-title">
        <Trans>Choose your next creative tool</Trans>
      </DialogTitle>

      <DialogContent>
        <AvailableToolsList
          projectId={projectId}
          rrmGoalType={rrmGoalType}
          suggestions={hintData?.toolSuggestions}
          parentId={parentId}
          sourceId={sourceId}
          navigateOnCreate={navigateOnCreate}
        />
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Grid container spacing={1} alignItems={'center'} justifyContent={'flex-end'}>
          {!disableAssistant && projectId && isAiAssistantEnabled && (
            <Grid item>
              <Tooltip title={message} placement={'top'} disableInteractive arrow>
                <div>
                  <LoadingButton
                    variant={'pg_rounded'}
                    color={'pg_blue_solid'}
                    startIcon={<AutoFixHighOutlined />}
                    onClick={suggestionHandler}
                    disabled={isLoading || !isAllProjectDataDone}
                    loading={isLoading}
                  >
                    <Trans>Get a suggestion</Trans>
                  </LoadingButton>
                </div>
              </Tooltip>
            </Grid>
          )}

          <Grid item>
            <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose}>
              <Trans>Cancel</Trans>
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
