import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../../theme';

export const useStyles = makeStyles({
  modelContainer: {
    position: 'relative',
    height: '100%',
  },

  arrow: {
    'position': 'absolute',
    'borderRadius': 2,
    'background': pgColorScheme.darkGray,

    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      borderRadius: 'inherit',
      background: 'inherit',
    },
  },

  arrowRight: {
    'left': '100%',
    'right': 'auto',
    'width': 0,
    'height': 6,
    'top': 'calc(50% - 3px)',

    '&::before, &::after': {
      top: 0,
      bottom: 'auto',
      left: 'auto',
      right: 3,
      width: 15,
      height: '100%',
    },

    '&::before': {
      transform: 'rotate(30deg)',
      transformOrigin: '100% 100%',
    },

    '&::after': {
      transform: 'rotate(-30deg)',
      transformOrigin: '100% 0',
    },
  },

  arrowLeft: {
    'left': 'auto',
    'right': '100%',
    'width': 0,
    'height': 6,
    'top': 'calc(50% - 3px)',

    '&::before, &::after': {
      top: 0,
      bottom: 'auto',
      left: 3,
      right: 'auto',
      width: 15,
      height: '100%',
    },

    '&::before': {
      transform: 'rotate(-30deg)',
      transformOrigin: '0 100%',
    },

    '&::after': {
      transform: 'rotate(30deg)',
      transformOrigin: '0 0',
    },
  },

  arrowDown: {
    'top': '100%',
    'bottom': 'auto',
    'width': 6,
    'height': 0,
    'left': 'calc(50% - 3px)',

    '&::before, &::after': {
      top: 'auto',
      bottom: 3,
      left: 0,
      right: 'auto',
      width: '100%',
      height: 15,
    },

    '&::before': {
      transform: 'rotate(-30deg)',
      transformOrigin: '100% 100%',
    },

    '&::after': {
      transform: 'rotate(30deg)',
      transformOrigin: '0 100%',
    },
  },
});
