import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { ProjectSelector } from '../../project/store/selectors';
import { useSelector } from 'react-redux';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm';
import { ToolSelectorDialog } from '../tool-selector-dialog/component';
import { Button, ButtonProps } from '@mui/material';

export interface ButtonCreateTaskProps extends ButtonProps {
  projectId?: number;
  text?: string | JSX.Element;
  disabled?: boolean;
  startIcon?: JSX.Element;
}

export const ButtonCreateTool: React.FC<ButtonCreateTaskProps> = ({
  text,
  projectId,
  disabled,
  startIcon,
  ...rest
}) => {
  const isProjectLockedById = useSelector(ProjectSelector.isProjectLockedById(projectId));
  const [toolsSelectorOpen, setToolsSelectorOpen] = useState(false);

  const openToolsSelectorDialog = () => {
    setToolsSelectorOpen(true);
  };

  const handleToolSelectorClose = () => {
    setToolsSelectorOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant={'pg_rounded'}
        color={'pg_orange_solid'}
        disabled={disabled || (projectId && isProjectLockedById)}
        onClick={openToolsSelectorDialog}
        startIcon={startIcon ? startIcon : false}
        {...rest}
      >
        <Trans>{text || 'New'}</Trans>
      </Button>

      <ToolSelectorDialog
        projectId={projectId}
        isOpen={toolsSelectorOpen}
        onClose={handleToolSelectorClose}
        rrmGoalType={RrmGoalType.SELF}
      />
    </React.Fragment>
  );
};
