import { User } from '@priz/shared/src/models/security/user';
import { Role } from '@priz/shared/src/models/security/role.enum';

export interface UserRoleSelectOption {
  label: string;
  value: Role;
  isFixed?: boolean;
}

export const resolveUserRoleSelectDefaultOptions = (user: User): UserRoleSelectOption[] => {
  const fixedRoles = [Role.ROLE_USER];

  return user.roles.map(role => ({ value: role, label: role, isFixed: fixedRoles.includes(role) }));
};

export const resolveUserRoleSelectOptions = (user: User): UserRoleSelectOption[] => {
  const optionsForSelect = [Role.ROLE_PARTNER, Role.ROLE_TRANSLATOR, Role.ROLE_ADMIN];

  return optionsForSelect.reduce((map, role) => {
    if (!user.roles.includes(role)) map.push({ value: role, label: role });
    return map;
  }, [] as UserRoleSelectOption[]);
};
