import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  cardHeader: {
    background: pgColorScheme.white,
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: 'inherit',
  },

  cardContent: {
    background: pgColorScheme.background,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'center',
    width: '100%',
    boxSizing: 'border-box',
  },

  checkIcon: {
    color: pgColorScheme.green,
  },
});
