import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectActions } from '../store/actions/project.actions';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { Project, ProjectStatus } from '@priz/shared/src/models/project';
import { ReactHookFormSelect, ReactHookFormText } from '../../react/form-elements';
import { validateOptions } from '@priz/shared/src/utils/form';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { TemplateType } from '@priz/shared/src/models/template';
import { templateTypeTextMap } from '../template/data';
import { TemplateDescription } from '../template/template-description/component';
import { ProjectSelector } from '../store/selectors';
import { LoadingButton } from '@mui/lab';
import { useFlag } from '@unleash/proxy-client-react';
import { UnleashFeatureName } from '@priz/shared/src/services/unleash';

interface ProjectFormProps {
  project?: Project;
  onCancel?: () => void;
}

export const ProjectForm: React.FC<ProjectFormProps> = ({ project, onCancel }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isTemplatesEnabled = useFlag(UnleashFeatureName.Templates);

  const [selectedStatus, setSelectedStatus] = useState<ProjectStatus | undefined>();

  const isProjectGettingCreated = useSelector(ProjectSelector.isProjectGettingCreated);

  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      title: project?.title || '',
      template: project?.template || '',
    },
  });

  const title = useWatch({
    control,
    name: 'title',
  });

  const template = useWatch({
    control,
    name: 'template',
  });

  useEffect(() => {
    if (project) {
      reset({
        title: project.title || '',
        template: project.template || '',
      });
    }
  }, [project]);

  const handleCreate = (status: ProjectStatus = ProjectStatus.InProgress) => {
    setSelectedStatus(status);

    handleSubmit(formData => {
      doCreate(formData.title, formData.template, status);
    })();
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  const doCreate = (title: string, template: string, status: ProjectStatus) => {
    const templateProp = template.length ? template : undefined;

    if (project) {
      dispatch(
        ProjectActions.updateProject(
          {
            ...project,
            status,
            title,
            template: templateProp,
          },
          false,
          navigate,
        ),
      );
    } else {
      dispatch(
        ProjectActions.createProject(
          {
            title,
            status,
            template: templateProp,
          },
          navigate,
        ),
      );
    }
  };

  return (
    <>
      <ReactHookFormText
        multiline
        name={'title'}
        fieldTitle={'Project title'}
        control={control}
        rules={{
          validate: {
            ...validateOptions.hasText('Title is required'),
          },
        }}
      />

      {isTemplatesEnabled && (
        <ReactHookFormSelect
          fieldTitle="Template"
          name={'template'}
          control={control}
          options={[
            { value: '', text: 'Without template' },
            ...Object.values(TemplateType).map(type => ({ value: type, text: templateTypeTextMap[type] })),
          ]}
          displayEmpty={true}
        />
      )}

      {!!template.length && <TemplateDescription type={template as TemplateType} mb={3} />}

      <ButtonsWrap justifyContent={'flex-end'}>
        <Button
          variant={'pg_rounded'}
          color={'pg_orange_outlined'}
          onClick={handleCancel}
          disabled={isProjectGettingCreated}
        >
          <Trans>Cancel</Trans>
        </Button>

        <LoadingButton
          variant={'pg_rounded'}
          color={'pg_orange_solid'}
          type={'button'}
          onClick={() => handleCreate(ProjectStatus.Todo)}
          disabled={!title.trim().length || isProjectGettingCreated}
          loading={isProjectGettingCreated && selectedStatus === ProjectStatus.Todo}
        >
          <Trans>Save for later</Trans>
        </LoadingButton>

        <LoadingButton
          variant={'pg_rounded'}
          color={'pg_orange_solid'}
          type={'button'}
          onClick={() => handleCreate(ProjectStatus.InProgress)}
          disabled={!title.trim().length || isProjectGettingCreated}
          loading={isProjectGettingCreated && selectedStatus === ProjectStatus.InProgress}
        >
          <Trans>Start now</Trans>
        </LoadingButton>
      </ButtonsWrap>
    </>
  );
};
