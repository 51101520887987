import React, { useState } from 'react';
import { useStyles } from './styles';
import { Perception } from '@priz/shared/src/models/tools/pmap/perception';
import { Box, Card, Grid, IconButton, Typography, Tooltip, BoxProps, Hidden } from '@mui/material';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { Trans } from 'react-i18next';
import { PMapPerceptionsSelect } from '../pmap-perceptions-select/component';
import { filterAvailableContradictPerceptions, filterAvailableAssignPerceptions } from '../utils';
import { CalcResult } from '@priz/shared/src/models/tools/pmap';

import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { PerceptionTextField } from '../perception-text-field/component';

interface PMapItemProps extends BoxProps {
  calcResult: CalcResult;
  perception: Perception;
  perceptionsArray: Perception[];
  deleteHandler?: (letter: string) => void;
  assignHandler?: (from: string, to: string) => void;
  contradictHandler?: (from: string, to: string, unset?: boolean) => void;
  updateDescriptionHandler?: (letter: string, text: string) => void;
  sum: number;
  blockingRank: string;
  allWereConnected?: boolean;
  disableConnections?: boolean;
  disableInput?: boolean;
  disableControls?: boolean;
  disableDeleting?: boolean;
  selectMenuPortalContainer?: HTMLDivElement;
}

export const PMapItem: React.FC<PMapItemProps> = ({
  calcResult,
  perception,
  perceptionsArray,
  deleteHandler,
  assignHandler,
  contradictHandler,
  updateDescriptionHandler,
  sum,
  blockingRank,
  allWereConnected,
  disableConnections,
  disableInput,
  disableControls,
  disableDeleting,
  selectMenuPortalContainer,
  ...rest
}) => {
  const styles = useStyles();
  const { connections = {}, conflicts = {} } = calcResult || {};
  const { letter, description, assign, conflict } = perception;
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);

  const availableAssignPerceptions = filterAvailableAssignPerceptions({
    letter,
    assign,
    perceptionsArray,
  });

  const availableContradictPerceptions = filterAvailableContradictPerceptions({
    letter,
    conflict,
    connections,
    conflicts,
    perceptionsArray,
  });

  const assignPerception = availableAssignPerceptions.find(item => item.value === assign);
  const conflictPerception = availableContradictPerceptions.find(item => item.value === conflict);

  const openDeleteConfirmation = () => {
    setDeleteConfirmationIsOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmationIsOpen(false);
  };

  const deletePerception = () => {
    closeDeleteConfirmation();
    if (deleteHandler) deleteHandler(letter);
  };

  const selectAssignPerception = option => {
    if (assignHandler) assignHandler(letter, option?.value ? option.value : '');
  };

  const setContradictingPerceptions = option => {
    if (contradictHandler) {
      if (option) {
        contradictHandler(letter, option.value);
      } else {
        contradictHandler(letter, '', true);
      }
    }
  };

  const saveDescriptionTextHandler = (text: string) => {
    if (updateDescriptionHandler) updateDescriptionHandler(letter, text);
  };

  const renderDeleteButton = () => {
    return (
      <IconButton
        className={styles.iconButton}
        onClick={openDeleteConfirmation}
        disabled={disableControls || disableDeleting}
        size="large"
      >
        <TrashIcon />
      </IconButton>
    );
  };

  return (
    <>
      <Box className={styles.root} mb={2} {...rest}>
        <Card className={styles.card} elevation={1} variant={'elevation'}>
          <Box p={{ xs: 1, lg: 2 }}>
            <Grid container spacing={{ xs: 1, md: 2 }}>
              <Grid item container xs={12} md={6}>
                <Grid item xs={12} sm={'auto'}>
                  <Grid container alignItems={'center'} justifyContent={'space-between'}>
                    <Grid item>
                      <Box width={{ xs: 'auto', sm: 50, lg: 70 }}>
                        <div className={styles.letterWrap}>
                          <Hidden lgDown>
                            <Typography className={styles.letter} variant={'h6'} component={'span'}>
                              {letter}
                            </Typography>
                          </Hidden>
                          <Hidden lgUp>
                            <Typography className={styles.letter} variant={'subtitle2'} component={'span'}>
                              {letter}
                            </Typography>
                          </Hidden>
                        </div>
                      </Box>
                    </Grid>

                    <Hidden smUp>
                      <Grid item>{renderDeleteButton()}</Grid>
                    </Hidden>
                  </Grid>
                </Grid>

                <Grid item container alignItems={'flex-start'} xs={true}>
                  <PerceptionTextField
                    value={description}
                    onSave={saveDescriptionTextHandler}
                    disabled={disableInput}
                  />
                </Grid>
              </Grid>

              <Grid item container xs={12} md={6} alignItems={{ xs: 'flex-end', md: 'flex-start' }}>
                <Grid item xs={true}>
                  <Grid container spacing={{ xs: 1, sm: 2 }}>
                    <Grid item xs={12} sm={6}>
                      {perceptionsArray.length > 1 && (
                        <Box>
                          <Hidden mdUp>
                            <Typography variant={'subtitle2'}>
                              <Trans>Find connections</Trans>
                            </Typography>
                          </Hidden>

                          <PMapPerceptionsSelect
                            defaultValue={assignPerception}
                            placeholder={'Leads to'}
                            options={availableAssignPerceptions}
                            variant={'left'}
                            selectCallback={selectAssignPerception}
                            mod={' _assign'}
                            disabled={disableControls || disableConnections}
                            selectMenuPortalContainer={selectMenuPortalContainer}
                          />
                        </Box>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      {perceptionsArray.length > 1 && (
                        <Tooltip
                          title={
                            !allWereConnected ? (
                              <Trans>You must complete all "Leads to" connection to define contradictions</Trans>
                            ) : (
                              ''
                            )
                          }
                          placement="top"
                        >
                          <Box>
                            <Hidden mdUp>
                              <Typography variant={'subtitle2'}>
                                <Trans>Find contradictions</Trans>
                              </Typography>
                            </Hidden>

                            <PMapPerceptionsSelect
                              disabled={!allWereConnected || disableControls}
                              defaultValue={conflictPerception}
                              placeholder={'Contradict with'}
                              options={availableContradictPerceptions}
                              variant={'right'}
                              selectCallback={setContradictingPerceptions}
                              mod={' _conflict'}
                              selectMenuPortalContainer={selectMenuPortalContainer}
                            />
                          </Box>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Hidden smDown>
                  <Grid item xs={'auto'}>
                    <Box display={'flex'} justifyContent={'flex-end'} pl={1}>
                      {renderDeleteButton()}
                    </Box>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>

      <PgConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        confirmTitle={<Trans>Confirm delete</Trans>}
        okButtonText={<Trans>Delete</Trans>}
        confirmContent={
          <React.Fragment>
            <Trans>Are you sure you want to delete current perception?</Trans>
          </React.Fragment>
        }
        onConfirm={deletePerception}
        onClose={closeDeleteConfirmation}
      />
    </>
  );
};
