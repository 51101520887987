import React, { useEffect, useRef } from 'react';
import { Alert, Box, Link } from '@mui/material';
import { Trans } from 'react-i18next';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { useStyles } from './styles';
import { InvoiceList } from '../invoices/component';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { useQueries } from 'react-query';
import { WorkspaceApi } from '../../workspace/services';
import { BillingSummary } from '../billing-summary/component';
import { useDispatch, useSelector } from 'react-redux';
import { PlanSelectors } from '../../workspace/store/selectors/plan.selectors';
import { PlanActions, WorkspaceMembersActions } from '../../workspace/store/actions';
import { WorkspaceMembersSelectors } from '../../workspace/store/selectors/workspace-members.selectors';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { DialogsActions } from '../../dialogs/store/actions';
import { DialogType } from '../../dialogs/store/model';

export const BillingPage: React.FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const billingPageContainerRef = useRef(null);
  const currentPlan = useSelector(PlanSelectors.getCurrentPlan);
  const workspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const members = useSelector(WorkspaceMembersSelectors.getAll);
  const isCurrentPlanLoading = useSelector(PlanSelectors.isLoading);
  const isCurrentPlanLoaded = useSelector(PlanSelectors.isLoaded);

  const [{ isLoading: isLoadingInv, data: invoice }, { isLoading: isLoadingSub, data: subDetails }] = useQueries([
    {
      queryKey: ['upcoming-invoice'],
      queryFn: () => WorkspaceApi.getUpcomingInvoice(),
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!currentPlan,
    },
    {
      queryKey: ['current-plan-details'],
      queryFn: () => WorkspaceApi.getSubscriptionDetails(),
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!currentPlan,
    },
  ]);

  const isLoading = isCurrentPlanLoading || isLoadingInv || isLoadingSub;

  useEffect(() => {
    dispatch(PlanActions.loadAll);
    dispatch(WorkspaceMembersActions.loadAll());
  }, []);

  const choosePlanHandler = () => {
    dispatch(DialogsActions.open(DialogType.PlanUpgrade));
  };

  return (
    <div className={styles.root} ref={billingPageContainerRef}>
      <Box>
        <PageTitleWithDocLink title={<Trans>Billing</Trans>} />
      </Box>

      {!isLoading && isCurrentPlanLoaded && !currentPlan && (
        <Box mb={2}>
          <Alert severity={'info'}>
            <Trans>This workspace does not have a plan selected</Trans>.{' '}
            <Link component={'span'} onClick={choosePlanHandler}>
              <Trans>Please, choose a plan</Trans>
            </Link>
            .
          </Alert>
        </Box>
      )}

      <Box mb={2}>
        <BillingSummary
          invoice={invoice}
          subDetails={subDetails}
          currentPlan={currentPlan}
          members={members}
          workspace={workspace}
        />
        {/*<CurrentPlan onLoadingStateChange={currentPlanLoadingHandler} />*/}
      </Box>

      <InvoiceList />

      <LoadingOverlay
        loading={isLoading}
        backdropStyles={{
          backgroundColor: 'transparent',
        }}
      />
    </div>
  );
};
