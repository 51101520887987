import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  header: {
    width: '100%',
    boxSizing: 'border-box',
    background: pgColorScheme.white,
    flex: '0 0 auto',
  },
  logoContainer: {
    display: 'block',
    cursor: 'pointer',
  },
  logo: {
    display: 'block',
  },
});
