import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserSelectors } from '../user/store/selectors/user.selectors';
import { UserActions } from '../user/store/actions/user.actions';
import { LoadingButton } from '@mui/lab';
import { Trans } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { PoliciesAgreement } from '@priz/shared/src/components/security/policies-agreement/component';

export const Policies: React.FC = () => {
  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const isPoliciesUpdating = useSelector(UserSelectors.isPoliciesUpdating);

  useEffect(() => {
    if (currentUser) {
      setIsDialogOpen(!currentUser.policiesAcceptedAt);
    }
  }, [currentUser?.lastUpdated]);

  const acceptHandler = () => {
    dispatch(UserActions.acceptPolicies());
  };

  if (!currentUser) return null;

  return (
    <Dialog open={isDialogOpen} maxWidth={'sm'} fullWidth>
      <DialogTitle>
        <Trans>Policies updated</Trans>
      </DialogTitle>

      <DialogContent>
        <PoliciesAgreement variant={'update'} />
      </DialogContent>

      <DialogActions sx={{ textAlign: 'right' }}>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} component={RouterLink} to={'/logout'}>
          <Trans>Log out</Trans>
        </Button>

        <LoadingButton
          variant={'pg_rounded'}
          color={'pg_orange_solid'}
          onClick={acceptHandler}
          loading={isPoliciesUpdating}
        >
          <Trans>Accept</Trans>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
