import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { Box, BoxProps } from '@mui/material';

export interface HamburgerStylesProps {
  barColor?: string;
  barColorHover?: string;
  barColorActive?: string;
  barColorActiveHover?: string;
  barHeight?: number;
  barSpacing?: number;
  barWidth?: number;
}

export interface HamburgerProps extends BoxProps {
  active?: boolean;
  callback?: (open: boolean) => void;
  barProps?: HamburgerStylesProps;
}

export const Hamburger: React.FC<HamburgerProps> = ({ active, callback, barProps = {}, ...rest }) => {
  const styles = useStyles(barProps);

  const [isActive, setIsActive] = useState<boolean>(active || false);

  const rootClassNames = [styles.root];

  if (isActive) rootClassNames.push('_active');

  useEffect(() => {
    if (typeof active !== 'undefined') {
      setIsActive(active);
    }
  }, [active]);

  const clickHandle = () => {
    if (callback) {
      callback(!active);
    } else {
      setIsActive(currentState => !currentState);
    }
  };

  return (
    <Box className={rootClassNames.join(' ')} onClick={clickHandle} {...rest}>
      <div className={styles.barsContainer}>
        <div className={styles.bar} />
        <div className={styles.bar} />
        <div className={styles.bar} />
      </div>
    </Box>
  );
};
