import React, { useState } from 'react';
import { Network } from '../../../lib/vis/esnext';
import { Box } from '@mui/material';
import { CecUtilization } from '../../../models/tools/cec';
import { edgePropsDecorator, options } from '../../../data/cec-options';
import { NetworkDiagram } from '../../../components/network-diagram/component';
import { getMaximumChainLength, useNetworkDataset } from '../../../components/network-diagram/utils';
import { pgColorScheme } from '../../../theme';
import { FullscreenContainer } from '../../../components/fullscreen-container/component';
import { DiagramControls } from '../diagram-controls/component';

export interface CecViewerProps {
  utilization: CecUtilization;
  disableInteractive?: boolean;
}

export const CecViewer: React.FC<CecViewerProps> = ({ utilization, disableInteractive }) => {
  const diagramData = utilization.diagramData || { nodes: [], edges: [] };
  const maximumChainLength = getMaximumChainLength(diagramData.edges, diagramData.nodes) || 1;
  const { nodesDataSet, edgesDataSet } = useNetworkDataset(diagramData, undefined, edgePropsDecorator);
  const [visNetwork, setVisNetwork] = useState<Network | null>(null);

  const networkInitHandler = (network: Network) => {
    setVisNetwork(network);
  };

  return (
    <Box
      style={{
        width: '100%',
        height: `${Math.min(maximumChainLength * 130, 870)}px`,
        position: 'relative',
        background: pgColorScheme.workbenchBackground,
        boxShadow: `1000px 0 0 ${pgColorScheme.workbenchBackground} inset`,
        borderRadius: 10,
      }}
    >
      <FullscreenContainer
        style={{
          background: pgColorScheme.workbenchBackground,
          width: '100%',
          height: '100%',
        }}
        fullscreenDisabledStyle={{
          borderRadius: 'inherit',
        }}
        usePortal
      >
        {!disableInteractive && visNetwork && (
          <DiagramControls
            onSetCenter={() => {
              visNetwork.fit();
            }}
          />
        )}

        <NetworkDiagram
          options={options}
          nodesDataSet={nodesDataSet}
          edgesDataSet={edgesDataSet}
          onNetworkInit={networkInitHandler}
          viewMode={true}
          disabled={disableInteractive}
        />
      </FullscreenContainer>
    </Box>
  );
};
