import { createStyles, makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentCol: {
      display: 'flex',
      alignItems: 'center',
    },
    leftCol: {
      justifyContent: 'flex-start',
      flex: '1 0 0',

      ['& > *']: {
        marginLeft: 0,
        marginRight: 8,
      },
    },
    rightCol: {
      justifyContent: 'flex-end',

      ['& > button']: {
        marginLeft: 8,
      },
    },
    menuButtonsContainer: {
      display: 'flex',
      flexWrap: 'wrap',

      ['& > *']: {
        marginTop: 8,
        marginRight: 8,
      },
    },
    projectContentCtrlBar: {
      backgroundColor: pgColorScheme.white,
      boxShadow: '0 0 5px rgba(0,0,0,.05)',
      height: 'auto',
      margin: 0,
      padding: 0,
      width: '100%',
      paddingRight: 60,
      boxSizing: 'border-box',
    },

    hamburgerButton: {
      'background': pgColorScheme.orange,
      'padding': 7,

      '&:hover': {
        background: pgColorScheme.darkOrange,
      },
    },

    hamburgerIcon: {
      fill: pgColorScheme.white,
    },

    [theme.breakpoints.down('sm')]: {
      hamburgerButton: {
        padding: 5,
      },
    },
  }),
);
