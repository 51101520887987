import React, { useEffect, useState } from 'react';
import { TitleContainer } from '../../react/modules/title-container/component';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { TopicDialog } from '../topic-dialog/component';
import { TopicCard } from '../topic-card/component';
import { Box, Button } from '@mui/material';
import { useQuery, useQueryClient } from 'react-query';
import { Alert, Pagination } from '@mui/material';
import { PublicTopicsApi } from '../services';
import { AppNavbar } from '../../navigation/app-navbar/component';
import { PageContainer } from '../../content-containers/page-container/component';

const topicsPerPage = 7;

const getPagesCount = (itemsCount?: number) => {
  return itemsCount ? Math.ceil(itemsCount / topicsPerPage) : 0;
};

export const TopicsManager: React.FC = () => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [page, setPage] = useState(0);

  const queryClient = useQueryClient();

  const { isLoading, data, isFetching } = useQuery(['topics', page], () => PublicTopicsApi.list(page, topicsPerPage), {
    keepPreviousData: true,
  });

  const pagesCount = getPagesCount(data?.count);

  const refetchTopics = () => {
    queryClient.invalidateQueries(['topics', page]);
  };

  const openDialog = () => {
    setDialogIsOpen(true);
  };

  const closeDialog = () => {
    setDialogIsOpen(false);
  };

  const pageChangeHandler = (_event: React.ChangeEvent, page: number) => {
    setPage(page - 1);
  };

  const createHandler = () => {
    const newPagesCount = getPagesCount(data?.count + 1);

    refetchTopics();

    if (page < newPagesCount - 1) {
      setPage(newPagesCount - 1);
    }
  };

  useEffect(() => {
    if (!isFetching && page > pagesCount - 1) {
      setPage(Math.max(0, pagesCount - 1));
    }
  }, [pagesCount, page, isFetching]);

  if (isLoading) return null;

  return (
    <PageContainer>
      <AppNavbar />

      <>
        <TitleContainer>
          <PageTitleWithDocLink title={'Topics'} />

          <ButtonsWrap>
            <Button variant={'pg_rounded'} color={'pg_orange_solid'} onClick={openDialog}>
              + Add New Topic
            </Button>
          </ButtonsWrap>
        </TitleContainer>

        {data?.topics?.length ? (
          data.topics.map((item, key) => (
            <Box key={key} mb={2}>
              <TopicCard topic={item} onDelete={refetchTopics} onUpdate={refetchTopics} />
            </Box>
          ))
        ) : (
          <Alert severity={'info'}>There are no topics yet</Alert>
        )}

        {pagesCount > 1 && (
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Pagination page={page + 1} count={pagesCount} onChange={pageChangeHandler} />
          </Box>
        )}

        <TopicDialog isOpen={dialogIsOpen} onClose={closeDialog} onCreate={createHandler} />
      </>
    </PageContainer>
  );
};
