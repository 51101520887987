import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    padding: 16,

    ['&.compact']: {
      padding: 8,
    },
  },
  textarea: {
    borderRadius: 'inherit',
    textAlign: 'center',
  },
});
