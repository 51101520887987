import React, { useState } from 'react';
import { FiveWhysNode, FiveWhysNodeProps } from '@priz/shared/src/components/five-whys/five-whys-node/component';
import { checkHasCausing } from '@priz/shared/src/utils/five-whys';
import { FiveWhysCause } from '@priz/shared/src/models/tools/five-whys';
import {
  FiveWhysNodeControls,
  FiveWhysNodeControlsEvents,
  FiveWhysNodeControlsProps,
} from '../five-whys-node-controls/component';
import { FiveWhysNodeTextarea } from '../five-whys-node-textarea/component';
import { AssistanceType } from '../../assistant/store/model';
import { HintRowType } from '../../assistant/hint-content/component';

export enum EditableFiveWhysNodeType {
  Cause = 'Cause',
  Solution = 'Solution',
}

export interface EditableFiveWhysNodeProps extends FiveWhysNodeProps, FiveWhysNodeControlsEvents {
  cause: FiveWhysCause;
  causes: FiveWhysCause[];
  projectId?: number;
  isFirst?: boolean;
  isLast?: boolean;
  changeTextCallback?: (id: number, text: string) => void;
  placeholder?: string;
  type: EditableFiveWhysNodeType;
}

export const EditableFiveWhysNode: React.FC<EditableFiveWhysNodeProps> = ({
  cause,
  causes,
  projectId,
  description,
  isFirst,
  isLast,
  changeTextCallback,
  placeholder,
  onAddCause,
  onRemove,
  onFrp,
  onUseHint,
  type,
  ...rest
}) => {
  const [isValid, setIsValid] = useState(true);
  const [nodeDescription, setNodeDescription] = useState(description);
  const [nodeElement, setNodeElement] = useState<HTMLDivElement>(null);

  const hasCause = checkHasCausing(causes, cause.id);
  const hasDescription = !!description?.trim()?.length;

  const textareaChangeHandler = (value: string) => {
    if (changeTextCallback) changeTextCallback(cause.id, value);
    setIsValid(true);
  };

  const textareaErrorHandler = () => {
    setIsValid(false);
  };

  const useHintHandler = (causeId: number, text: string) => {
    setNodeDescription(text);
    if (onUseHint) onUseHint(causeId, text);
  };

  const nodeRefHandler = (el: HTMLDivElement) => {
    setNodeElement(el);
  };

  const textEditorRenderer = () => {
    return (
      <FiveWhysNodeTextarea
        defaultValue={nodeDescription}
        placeholder={placeholder}
        onChange={textareaChangeHandler}
        onError={textareaErrorHandler}
      />
    );
  };

  const nodeControlsRenderer = () => {
    const baseProps: FiveWhysNodeControlsProps = {
      utilizationId: cause.utilization.id,
      projectId: projectId,
      causeId: cause.id,
      causes: causes,
      onUseHint: useHintHandler,
      assistantContentProps: {
        hintRowsType: HintRowType.Radio,
      },
      assistantPopperProps: {
        anchorEl: nodeElement,
        placement: 'left',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['right', 'bottom', 'top'],
            },
          },
        ],
      },
    };

    if (type === EditableFiveWhysNodeType.Cause) {
      return (
        <FiveWhysNodeControls
          showFrpButton={isLast && !isFirst && isValid && hasDescription}
          showRemoveButton={isLast && !isFirst}
          showAddCauseButton={isValid && hasDescription && !hasCause}
          showAssistantButton={isLast && !isFirst}
          onAddCause={onAddCause}
          onRemove={onRemove}
          onFrp={onFrp}
          assistanceType={AssistanceType.AskFiveWhysCause}
          assistanceTargetCauseId={cause?.causing?.id}
          {...baseProps}
        />
      );
    }

    if (type === EditableFiveWhysNodeType.Solution) {
      return (
        <FiveWhysNodeControls
          showAssistantButton={true}
          assistanceType={AssistanceType.AskFiveWhysCauseSolution}
          assistanceTargetCauseId={cause?.id}
          {...baseProps}
        />
      );
    }
  };

  return (
    <FiveWhysNode
      onRef={nodeRefHandler}
      description={description}
      textEditorRenderer={textEditorRenderer}
      nodeControlsRenderer={nodeControlsRenderer}
      {...rest}
    />
  );
};
