import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../store/app.state';
import { Meeting, MeetingsCollection } from '../model';

const meetingsCollectionSelector = (state: AppState): MeetingsCollection => state.meetings;

const meetingsEntitiesSelector = createSelector(meetingsCollectionSelector, collection => collection.entities);

const meetingsLookupSelector = createSelector(meetingsCollectionSelector, collection => collection.lookups);

const meetingsStatusesSelector = createSelector(meetingsCollectionSelector, collection => collection.statuses);

const meetingsIdsByProjectIdSelector = (projectId: number) =>
  createSelector(meetingsLookupSelector, lookup => lookup.byProjectId[projectId] || []);

const getAllByProjectId = (projectId: number): Selector<AppState, Meeting[]> =>
  createSelector([meetingsEntitiesSelector, meetingsIdsByProjectIdSelector(projectId)], (entityMap, ids) =>
    ids.map(id => entityMap[id]),
  );
const getAllByProjectIdAndUserId = (projectId: number, userId: number): Selector<AppState, Meeting[]> =>
  createSelector([meetingsEntitiesSelector, meetingsIdsByProjectIdSelector(projectId)], (entityMap, ids) =>
    ids.map(id => entityMap[id]).filter(meeting => meeting.createdBy.id === userId),
  );

const getById = (meetingId: number): Selector<AppState, Meeting> =>
  createSelector(meetingsEntitiesSelector, entities => entities[meetingId]);

const getByIds = (meetingIds: number[]): Selector<AppState, Meeting[]> =>
  createSelector(meetingsEntitiesSelector, entities => {
    const meetings = [];

    meetingIds.forEach(id => {
      if (entities[id]) meetings.push(entities[id]);
    });

    return meetings;
  });

const isLoaded = (projectId: number): Selector<AppState, boolean> =>
  createSelector(meetingsStatusesSelector, statuses => statuses.byProjectId[projectId]?.loaded || false);

const isLoading = (projectId: number): Selector<AppState, boolean> =>
  createSelector(meetingsStatusesSelector, statuses => statuses.byProjectId[projectId]?.loading || false);

const isCreating = (projectId: number): Selector<AppState, boolean> =>
  createSelector(meetingsStatusesSelector, statuses => statuses.byProjectId[projectId]?.creating || false);

const isCreated = (projectId: number): Selector<AppState, boolean> =>
  createSelector(meetingsStatusesSelector, statuses => statuses.byProjectId[projectId]?.created || false);

const isUpdating = (projectId: number): Selector<AppState, boolean> =>
  createSelector(meetingsStatusesSelector, statuses => statuses.byProjectId[projectId]?.updating || false);

const isUpdated = (projectId: number): Selector<AppState, boolean> =>
  createSelector(meetingsStatusesSelector, statuses => statuses.byProjectId[projectId]?.updated || false);

const isRemoving = (projectId: number): Selector<AppState, boolean> =>
  createSelector(meetingsStatusesSelector, statuses => statuses.byProjectId[projectId]?.removing || false);

const isRemoved = (projectId: number): Selector<AppState, boolean> =>
  createSelector(meetingsStatusesSelector, statuses => statuses.byProjectId[projectId]?.removed || false);

const isFailed = (projectId: number): Selector<AppState, boolean> =>
  createSelector(meetingsStatusesSelector, statuses => statuses.byProjectId[projectId]?.error || false);

export const MeetingSelectors = {
  getAllByProjectId,
  getAllByProjectIdAndUserId,
  getById,
  getByIds,
  isLoaded,
  isLoading,
  isCreating,
  isCreated,
  isUpdating,
  isUpdated,
  isRemoving,
  isRemoved,
  isFailed,
};
