import { AssistantHintData } from '../store/model';
import { Project } from '@priz/shared/src/models/project';

const resolveCompletenessHintData = (text?: string, completeness?: number) => {
  return [text, completeness].some(value => value !== null && typeof value !== 'undefined')
    ? {
        text,
        completeness,
        content: null,
        variants: null,
        toolSuggestions: null,
      }
    : undefined;
};

export const resolveProjectBackgroundHintData = (project?: Project): AssistantHintData | undefined => {
  const explanation = project?.metaData?.projectBackgroundExplanation;
  const completeness = project?.metaData?.projectBackgroundCompleteness;

  return resolveCompletenessHintData(explanation, completeness);
};

export const resolveCurrentSituationHintData = (project?: Project): AssistantHintData | undefined => {
  const explanation = project?.metaData?.currentSituationExplanation;
  const completeness = project?.metaData?.currentSituationCompleteness;

  return resolveCompletenessHintData(explanation, completeness);
};

export const resolveIdealFinalResultHintData = (project?: Project): AssistantHintData | undefined => {
  const explanation = project?.metaData?.idealFinalResultExplanation;
  const completeness = project?.metaData?.idealFinalResultCompleteness;

  return resolveCompletenessHintData(explanation, completeness);
};

export const resolveGapsHintData = (project?: Project): AssistantHintData | undefined => {
  const explanation = project?.metaData?.gapsExplanation;
  const completeness = project?.metaData?.gapsCompleteness;

  return resolveCompletenessHintData(explanation, completeness);
};

export const resolveDisadvantagesHintData = (project?: Project): AssistantHintData | undefined => {
  const explanation = project?.metaData?.disadvantagesExplanation;
  const completeness = project?.metaData?.disadvantagesCompleteness;

  return resolveCompletenessHintData(explanation, completeness);
};

export const resolveProblemStatementHintData = (project?: Project): AssistantHintData | undefined => {
  const explanation = project?.metaData?.problemStatementExplanation;
  const completeness = project?.metaData?.problemStatementCompleteness;

  return resolveCompletenessHintData(explanation, completeness);
};
