import React, { ReactNode } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AuthGuard, AuthGuardProps } from '../auth-guard/component';
import { UnleashFeatureName } from '@priz/shared/src/services/unleash';
import { FeatureGuard, FeatureGuardProps } from '../feature-guard/component';
import { PrizLoadingOverlay } from '@priz/shared/src/components/priz-loading-overlay/component';

interface RouteRenderProps extends AuthGuardProps {
  Component: React.FC;
  loadingElement?: ReactNode;
  authGuard?: boolean;
  requiredFeature?: UnleashFeatureName;
}

const AuthGuardContainer: React.FC<AuthGuardProps & { enabled: boolean }> = ({ enabled, children, ...rest }) => {
  if (!enabled) return <>{children}</>;

  return <AuthGuard {...rest}>{children}</AuthGuard>;
};

const FeatureGuardContainer: React.FC<FeatureGuardProps & { enabled: boolean }> = ({ enabled, children, ...rest }) => {
  if (!enabled) return <>{children}</>;

  return <FeatureGuard {...rest}>{children}</FeatureGuard>;
};

export const RouteRenderer: React.FC<RouteRenderProps> = ({
  Component,
  authGuard,
  roles,
  forceBilling,
  loadingElement,
  requiredFeature,
  mfaFree,
}) => {
  const { pathname } = useLocation();
  const urlParams = useParams();

  const preloader = typeof loadingElement !== 'undefined' ? loadingElement : <PrizLoadingOverlay />;

  const parsedParams = Object.keys(urlParams).reduce((map: { [key: string]: string | number }, key) => {
    map[key] = isNaN(+urlParams[key]) ? urlParams[key] : +urlParams[key];
    return map;
  }, {});

  return (
    <FeatureGuardContainer enabled={!!requiredFeature} feature={requiredFeature} loadingElement={preloader}>
      <AuthGuardContainer
        key={pathname}
        enabled={authGuard}
        roles={roles}
        forceBilling={forceBilling}
        loadingElement={preloader}
        mfaFree={mfaFree}
      >
        <Component {...parsedParams} />
      </AuthGuardContainer>
    </FeatureGuardContainer>
  );
};
