import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { Alert, Link, Box, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { validationPatterns, validatorRules } from '../../../utils/form';
import { AuthErrorMessage, ErrorResponse } from '../auth-error-message/component';
import { LoadingButton } from '../../loading-button/component';
import { ReactHookFormText } from '../../form-elements';
import { NavigationProps } from '../auth-tabs/component';
import { Auth0Error } from 'auth0-js';
import { AuthErrorCode } from '../auth-error-message/AuthErrorCode';
import { AuthService } from '../../../services/auth';
import { AuthFormType } from '../../../models/auth0';

interface RequestPasswordResetFormProps extends Partial<NavigationProps> {
  initialEmail?: string;
}

export const RequestPasswordResetForm: React.FC<RequestPasswordResetFormProps> = ({
  initialEmail,
  onLoginNavigate,
}) => {
  const [requestFailureResponse, setRequestFailureResponse] = useState<ErrorResponse | null>(null);

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const { handleSubmit, control } = useForm({
    shouldFocusError: true,
    defaultValues: {
      email: initialEmail || '',
    },
  });

  const requestPasswordReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    handleSubmit(formData => {
      setLoading(true);
      setSuccessMessage(null);
      setRequestFailureResponse(null);

      AuthService.requestPasswordReset(formData.email, onRequestSuccess, onRequestFailure);
    })();
  };

  const onRequestSuccess = (response: string) => {
    setLoading(false);
    setSuccessMessage(response);
  };

  const onRequestFailure = (error: Auth0Error) => {
    setLoading(false);

    setRequestFailureResponse({
      errorCode: AuthErrorCode[error?.code as keyof typeof AuthErrorCode] || error?.code,
      errorMessage: error?.description || 'Error',
    });
  };

  const loginHandler = () => {
    if (onLoginNavigate) onLoginNavigate();
  };

  return (
    <>
      {!successMessage && (
        <>
          <Box mb={2} textAlign={'center'}>
            <Typography variant={'h5'} component={'span'}>
              <Trans>Reset your password</Trans>
            </Typography>
          </Box>

          {!initialEmail && (
            <Box mb={2} textAlign={'center'}>
              <Typography variant={'body2'} component={'span'}>
                <Trans>Please enter your email address. We will send you an email to reset your password.</Trans>
              </Typography>
            </Box>
          )}

          <AuthErrorMessage
            formType={AuthFormType.RequestPasswordReset}
            errorResponse={requestFailureResponse}
            onLoginNavigate={onLoginNavigate}
            mb={2}
          />

          <form onSubmit={requestPasswordReset}>
            <ReactHookFormText
              fieldTitle={'Email *'}
              name={'email'}
              control={control}
              disabled={loading || !!initialEmail}
              rules={{
                ...validatorRules.required('Email is required'),
                ...validatorRules.pattern(validationPatterns.email, 'A valid email is required'),
              }}
            />

            <LoadingButton type={'submit'} style={{ width: '100%', margin: 0 }} loading={loading}>
              <Trans>Send email</Trans>
            </LoadingButton>
          </form>

          <Box textAlign={'center'} mt={2.25}>
            <Typography variant={'caption'} component={'span'}>
              <Trans>I remember now!</Trans>
              &nbsp;
              <Link component={'span'} underline={'hover'} onClick={loginHandler}>
                <Trans>Login</Trans>
              </Link>
            </Typography>
          </Box>
        </>
      )}

      {successMessage && (
        <>
          <Box mb={2} textAlign={'center'}>
            <Typography variant={'h5'} component={'span'}>
              <Trans>Password reset instructions sent</Trans>
            </Typography>
          </Box>

          <Alert severity={'success'}>
            <Typography variant={'body2'} component={'span'}>
              <Trans>{successMessage}</Trans>
            </Typography>
          </Alert>

          <Box textAlign={'center'} mt={1.25}>
            <Link component={'span'} variant={'body2'} underline={'hover'} onClick={loginHandler}>
              <Trans>Back to Login</Trans>
            </Link>
          </Box>
        </>
      )}
    </>
  );
};
