import { combineActions, handleActions } from 'redux-actions';
import { ApaUtilization } from '@priz/shared/src/models/tools/apa';
import { ApaUtilizationActionType } from '../../../apa/store/actions';
import { EbsUtilizationActionType } from '../../../ebs/store/actions';
import { EbsUtilization } from '@priz/shared/src/models/tools/ebs';
import { FortyPrinciplesUtilization } from '@priz/shared/src/models/tools/forty-principles/forty-principles-utilization';
import { FortyPrinciplesUtilizationActionType } from '../../../forty-principles/store/actions';
import { IToolUtilization, ToolType, ToolUtilization } from '@priz/shared/src/models/tools';
import { IRrmUtilization, RrmUtilization } from '@priz/shared/src/models/tools/rrm/rrm-utilization';
import { RrmUtilizationActionType } from '../../../rrm/store/actions/rrm-utilization.actions';
import { removeActionSuffix, toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { ArrayUtil } from '../../../shared/utils/array-util';
import { UimUtilizationActionType } from '../../../uim/store/actions/uim-utilization.actions';
import { UimUtilization } from '@priz/shared/src/models/tools/uim';
import { ToolUtilizationActionType, ToolUtilizationMeta, ToolUtilizationPayload } from '../actions/tools.actions';
import {
  DefaultUtilizationCollection,
  UtilizationCollection,
  UtilizationCollectionStatuses,
} from '@priz/shared/src/models/project';
import { INineWindowsUtilization, NineWindowsUtilization } from '@priz/shared/src/models/tools/nine-windows';
import { NineWindowsUtilizationActionType } from '../../../nine-windows/store/actions';
import { FiveWhysUtilization } from '@priz/shared/src/models/tools/five-whys';
import {
  FiveWhysCauseActionType,
  FiveWhysSolutionActionType,
  FiveWhysUtilizationActionType,
} from '../../../five-whys/store/actions';
import { CecUtilization } from '@priz/shared/src/models/tools/cec';
import { CecUtilizationActionType } from '../../../cec/store/actions';
import { PMapUtilization } from '@priz/shared/src/models/tools/pmap';
import { PMapUtilizationActionType } from '../../../pmap/store/actions';
import { SfmUtilizationActionType } from '../../../sfm/store/actions';
import { SfmUtilization } from '@priz/shared/src/models/tools/sfm';
import { PfmUtilization } from '@priz/shared/src/models/tools/pfm';
import { copyObject } from '@priz/shared/src/utils/common';
import { PfmUtilizationActionType } from '../../../pfm/store/actions';
import { FluxStandardAction } from 'flux-standard-action';
import {
  CollectionStatusesService,
  SharedUpdateStatusesProps,
} from '@priz/shared/src/services/statuses/collection-statuses.service';
import { UserContextService } from '@priz/shared/src/services/user';
import { AxiosError } from 'axios';
import { AssistantAction, AssistantActionType } from '../../../assistant/store/actions';
import { CftUtilizationActionType } from '../../../cft/store/actions';
import { CftUtilization } from '@priz/shared/src/models/tools/cft';

interface CombinedToolUtilizationMeta extends ToolUtilizationMeta {
  sourceUtilizationId?: number;
  utilization?: IToolUtilization;
}

type CombinedToolUtilizationAction = FluxStandardAction<string, ToolUtilizationPayload, CombinedToolUtilizationMeta>;

const utilizationPayloadToObject = (toolPayload: IToolUtilization): ToolUtilization => {
  if (toolPayload.type === ToolType.TOOL_40_PRINCIPLES) {
    return new FortyPrinciplesUtilization(toolPayload);
  } else if (toolPayload.type === ToolType.RRM) {
    return new RrmUtilization(toolPayload as IRrmUtilization);
  } else if (toolPayload.type === ToolType.UIM) {
    return new UimUtilization(toolPayload);
  } else if (toolPayload.type === ToolType.FIVE_WHYS) {
    return new FiveWhysUtilization(toolPayload);
  } else if (toolPayload.type === ToolType.APA) {
    return new ApaUtilization(toolPayload);
  } else if (toolPayload.type === ToolType.EBS) {
    return new EbsUtilization(toolPayload);
  } else if (toolPayload.type === ToolType.NINE_WINDOWS) {
    return new NineWindowsUtilization(toolPayload as INineWindowsUtilization);
  } else if (toolPayload.type === ToolType.CEC) {
    return new CecUtilization(toolPayload);
  } else if (toolPayload.type === ToolType.P_MAP) {
    return new PMapUtilization(toolPayload);
  } else if (toolPayload.type === ToolType.SFM) {
    return new SfmUtilization(toolPayload);
  } else if (toolPayload.type === ToolType.PFM) {
    return new PfmUtilization(toolPayload);
  } else if (toolPayload.type === ToolType.CFT) {
    return new CftUtilization(toolPayload);
  }
};

const getToolIds = (
  payload: IToolUtilization,
): {
  utilizationId: number;
  projectId?: number;
  teamId?: number;
  publicId?: string;
} => {
  const { id: utilizationId, project, team, publicId } = payload;
  const { id: projectId } = project || {};
  const { id: teamId } = team || {};

  return {
    utilizationId,
    projectId,
    teamId,
    publicId,
  };
};

const getMetaIds = (
  meta: CombinedToolUtilizationMeta,
): {
  utilizationId?: number;
  projectId?: number;
  publicId?: string;
} => {
  const { projectId: pId, utilizationId: uId, publicId, utilization } = meta || {};
  const projectId = pId || utilization?.project?.id;
  const utilizationId = uId || utilization?.id;

  return {
    projectId,
    utilizationId,
    publicId,
  };
};

const getMetaUtilizationIdsArray = (meta: CombinedToolUtilizationMeta): (string | number)[] => {
  const { utilizationId, publicId } = getMetaIds(meta);
  const ids = [];

  if (typeof utilizationId !== 'undefined') {
    ids.push(utilizationId);
  }

  if (typeof publicId !== 'undefined') {
    ids.push(publicId);
  }

  return ids;
};

const utilizationsPayloadToMap = (toolsPayload: any): { [id: number]: ToolUtilization } =>
  toolsPayload.reduce((map, toolData) => {
    const tool = utilizationPayloadToObject(toolData);
    if (tool) {
      map[tool.id] = tool;
    }
    return map;
  }, {});

interface StatusesResolveProps {
  state: UtilizationCollection;
  meta: CombinedToolUtilizationMeta;
  globalStatuses?: SharedUpdateStatusesProps;
  teamStatuses?: SharedUpdateStatusesProps;
  projectStatuses?: SharedUpdateStatusesProps;
  utilizationStatuses?: SharedUpdateStatusesProps;
}

export const resolveUtilizationCollectionStatuses = (props: StatusesResolveProps): UtilizationCollectionStatuses => {
  const { state, meta, globalStatuses, teamStatuses, projectStatuses, utilizationStatuses } = props;

  const contextTeamId = UserContextService.getSelectedTeamId();
  const { projectId, utilizationId, publicId } = getMetaIds(meta);

  const statusesCopy = copyObject(state.statuses);

  if (contextTeamId && (teamStatuses || globalStatuses)) {
    statusesCopy.byTeamId[contextTeamId] = CollectionStatusesService.updateStatusesCollection({
      collection: statusesCopy.byTeamId[contextTeamId] || {},
      ...(globalStatuses || {}),
      ...(teamStatuses || {}),
    });
  }

  if (projectId && (projectStatuses || globalStatuses)) {
    statusesCopy.byProjectId[projectId] = CollectionStatusesService.updateStatusesCollection({
      collection: statusesCopy.byProjectId[projectId] || {},
      ...(globalStatuses || {}),
      ...(projectStatuses || {}),
    });
  }

  if (utilizationId && utilizationStatuses) {
    statusesCopy.byUtilizationId[utilizationId] = CollectionStatusesService.updateStatusesCollection({
      collection: statusesCopy.byUtilizationId[utilizationId] || {},
      ...(utilizationStatuses || {}),
    });
  }

  if (publicId && utilizationStatuses) {
    statusesCopy.byPublicId[publicId] = CollectionStatusesService.updateStatusesCollection({
      collection: statusesCopy.byPublicId[publicId] || {},
      ...(utilizationStatuses || {}),
    });
  }

  return statusesCopy;
};

const setStatuses = (props: StatusesResolveProps): UtilizationCollection => {
  return {
    ...props.state,
    statuses: resolveUtilizationCollectionStatuses(props),
  };
};

const setToolUtilizationList = (props: {
  state: UtilizationCollection;
  action: CombinedToolUtilizationAction;
  statuses?: UtilizationCollectionStatuses;
}) => {
  const { state, action, statuses } = props;
  const { payload } = action;

  const loadedTools = payload as ToolUtilization[];
  const toolsMap = utilizationsPayloadToMap(loadedTools);

  const lookupsCopy = copyObject(state.lookups);
  const entitiesCopy = { ...state.entities, ...toolsMap };

  loadedTools.reduce((map, item) => {
    const { utilizationId, projectId, teamId, publicId } = getToolIds(item);

    if (teamId) {
      lookupsCopy.byTeamId[teamId] = ArrayUtil.concatInDistinctArray(lookupsCopy.byTeamId[teamId], utilizationId);
    }

    if (projectId) {
      lookupsCopy.byProjectId[projectId] = ArrayUtil.concatInDistinctArray(
        lookupsCopy.byProjectId[projectId],
        utilizationId,
      );
    }

    if (publicId) {
      lookupsCopy.byPublicId = {
        ...(lookupsCopy.byPublicId || {}),
        [publicId]: utilizationId,
      };
    }

    return map;
  }, {});

  return {
    ...state,
    entities: entitiesCopy,
    lookups: lookupsCopy,
    statuses: {
      ...state.statuses,
      ...statuses,
    },
  };
};

const setToolUtilization = (props: {
  state: UtilizationCollection;
  action: CombinedToolUtilizationAction;
  statuses?: UtilizationCollectionStatuses;
}): UtilizationCollection => {
  const { state, action, statuses } = props;
  const payload = action.payload as IToolUtilization;
  const { utilizationId, projectId, teamId, publicId } = getToolIds(payload);

  const lookupsCopy = copyObject(state.lookups);

  const entitiesCopy = {
    ...state.entities,
    [utilizationId]: utilizationPayloadToObject(payload),
  };

  if (teamId) {
    lookupsCopy.byTeamId[teamId] = ArrayUtil.concatInDistinctArray(lookupsCopy.byTeamId[teamId], utilizationId);
  }

  if (projectId) {
    lookupsCopy.byProjectId[projectId] = ArrayUtil.concatInDistinctArray(
      lookupsCopy.byProjectId[projectId],
      utilizationId,
    );
  }

  if (publicId) {
    lookupsCopy.byPublicId = {
      ...(lookupsCopy.byPublicId || {}),
      [publicId]: utilizationId,
    };
  }

  return {
    ...state,
    entities: entitiesCopy,
    lookups: lookupsCopy,
    statuses: {
      ...state.statuses,
      ...statuses,
    },
  };
};

const removeToolUtilization = (props: {
  state: UtilizationCollection;
  action: CombinedToolUtilizationAction;
  statuses?: UtilizationCollectionStatuses;
}): UtilizationCollection => {
  const teamId = UserContextService.getSelectedTeamId();

  const { state, action, statuses } = props;
  const { projectId, utilizationId } = getMetaIds(action.meta);

  const entitiesCopy = { ...state.entities };
  const lookupsCopy = copyObject(state.lookups);

  const lookupsForDeleting = [utilizationId];

  Object.values(entitiesCopy).forEach(item => {
    if (item.parentId === utilizationId) {
      lookupsForDeleting.push(item.id);
      delete entitiesCopy[item.id];
    }
  });

  delete entitiesCopy[utilizationId];

  if (teamId) {
    lookupsCopy.byTeamId[teamId] = lookupsCopy.byTeamId[teamId].filter(lookup => !lookupsForDeleting.includes(lookup));
  }

  if (projectId) {
    lookupsCopy.byProjectId[projectId] = lookupsCopy.byProjectId[projectId].filter(
      lookup => !lookupsForDeleting.includes(lookup),
    );
  }

  return {
    ...state,
    entities: entitiesCopy,
    lookups: lookupsCopy,
    statuses: {
      ...state.statuses,
      ...statuses,
    },
  };
};

export const toolUtilizationsReducers = handleActions(
  {
    ///////////////////////////////////////////////////
    ///////////////// TOOLS FETCHING //////////////////
    ///////////////////////////////////////////////////

    [toStarted(ToolUtilizationActionType.FetchTools)]: (
      state: UtilizationCollection,
      action: CombinedToolUtilizationAction,
    ): UtilizationCollection =>
      setStatuses({
        state,
        meta: action.meta,
        teamStatuses: action.meta.projectId ? undefined : { loading: true, loaded: false, error: false },
        projectStatuses: !action.meta.projectId ? undefined : { loading: true, loaded: false, error: false },
      }),

    [toFailed(ToolUtilizationActionType.FetchTools)]: (
      state: UtilizationCollection,
      action: CombinedToolUtilizationAction,
    ): UtilizationCollection =>
      setStatuses({
        state,
        meta: action.meta,
        teamStatuses: action.meta.projectId ? undefined : { loading: false, loaded: false, error: true },
        projectStatuses: !action.meta.projectId ? undefined : { loading: false, loaded: false, error: true },
      }),

    [toSuccess(ToolUtilizationActionType.FetchTools)]: (
      state: UtilizationCollection,
      action: CombinedToolUtilizationAction,
    ): UtilizationCollection =>
      setToolUtilizationList({
        state,
        action,
        statuses: resolveUtilizationCollectionStatuses({
          state,
          meta: action.meta,
          teamStatuses: action.meta.projectId ? undefined : { loading: false, loaded: true, error: false },
          projectStatuses: !action.meta.projectId ? undefined : { loading: false, loaded: true, error: false },
        }),
      }),

    ///////////////////////////////////////////////////
    ////////////// SINGLE TOOL FETCHING ///////////////
    ///////////////////////////////////////////////////

    [toStarted(ToolUtilizationActionType.FetchTool)]: (
      state: UtilizationCollection,
      action: CombinedToolUtilizationAction,
    ): UtilizationCollection =>
      setStatuses({
        state,
        meta: action.meta,
        globalStatuses: {
          idsUpdate: [
            { target: 'errorIds', method: 'set', ids: [] },
            { target: 'loadedIds', method: 'set', ids: [] },
          ],
        },
        utilizationStatuses: {
          loading: true,
          errorCode: undefined,
        },
      }),

    [toFailed(ToolUtilizationActionType.FetchTool)]: (
      state: UtilizationCollection,
      action: CombinedToolUtilizationAction,
    ): UtilizationCollection => {
      return setStatuses({
        state,
        meta: action.meta,
        globalStatuses: {
          idsUpdate: [{ target: 'errorIds', method: 'add', ids: getMetaUtilizationIdsArray(action.meta) }],
        },
        utilizationStatuses: {
          loading: false,
          loaded: false,
          error: true,
          errorCode: (action?.payload as AxiosError)?.response?.status,
        },
      });
    },

    [toSuccess(ToolUtilizationActionType.FetchTool)]: (
      state: UtilizationCollection,
      action: CombinedToolUtilizationAction,
    ): UtilizationCollection => {
      return setToolUtilization({
        state,
        action,
        statuses: resolveUtilizationCollectionStatuses({
          state,
          meta: action.meta,
          globalStatuses: {
            idsUpdate: [{ target: 'loadedIds', method: 'add', ids: getMetaUtilizationIdsArray(action.meta) }],
          },
          utilizationStatuses: {
            loading: false,
            loaded: true,
            error: false,
            errorCode: undefined,
          },
        }),
      });
    },

    ///////////////////////////////////////////////////
    ////////////// UTILIZATION CREATING ///////////////
    ///////////////////////////////////////////////////

    [combineActions(
      toStarted(ToolUtilizationActionType.CreateTool),
      toStarted(UimUtilizationActionType.CreateForProjectTasks),
      toStarted(RrmUtilizationActionType.Create),
      toStarted(FortyPrinciplesUtilizationActionType.Create),
    )]: (state: UtilizationCollection, action: CombinedToolUtilizationAction): UtilizationCollection =>
      setStatuses({
        state,
        meta: action.meta,
        globalStatuses: {
          creating: true,
          created: false,
          idsUpdate: [{ target: 'createdIds', method: 'set', ids: [] }],
        },
      }),

    [combineActions(
      toFailed(ToolUtilizationActionType.CreateTool),
      toFailed(RrmUtilizationActionType.Create),
      toFailed(UimUtilizationActionType.CreateForProjectTasks),
      toFailed(FortyPrinciplesUtilizationActionType.Create),
    )]: (state: UtilizationCollection, action: CombinedToolUtilizationAction): UtilizationCollection =>
      setStatuses({
        state,
        meta: action.meta,
        globalStatuses: { creating: false, created: false, error: true },
      }),

    [combineActions(
      toSuccess(ToolUtilizationActionType.CreateTool),
      toSuccess(UimUtilizationActionType.CreateForProjectTasks),
      toSuccess(RrmUtilizationActionType.Create),
      toSuccess(FortyPrinciplesUtilizationActionType.Create),
    )]: (state: UtilizationCollection, action: CombinedToolUtilizationAction): UtilizationCollection => {
      return setToolUtilization({
        state,
        action,
        statuses: resolveUtilizationCollectionStatuses({
          state,
          meta: action.meta,
          globalStatuses: {
            creating: false,
            created: true,
            error: false,
            idsUpdate: [{ target: 'createdIds', method: 'add', ids: [(action.payload as ToolUtilization).id] }],
          },
        }),
      });
    },

    ///////////////////////////////////////////////////
    ////////////// UTILIZATION UPDATING ///////////////
    ///////////////////////////////////////////////////

    [combineActions(
      toStarted(ToolUtilizationActionType.UpdateCompleteness),
      toStarted(RrmUtilizationActionType.Update),
      toStarted(EbsUtilizationActionType.Update),
      toStarted(ApaUtilizationActionType.Update),
      toStarted(FortyPrinciplesUtilizationActionType.Update),
      toStarted(NineWindowsUtilizationActionType.Update),
      toStarted(CecUtilizationActionType.Update),
      toStarted(SfmUtilizationActionType.Update),
      toStarted(SfmUtilizationActionType.UpdateNetworkData),
      toStarted(SfmUtilizationActionType.CreateNewVersion),
      toStarted(SfmUtilizationActionType.UpdateVersionTitle),
      toStarted(SfmUtilizationActionType.SwitchVersion),
      toStarted(SfmUtilizationActionType.RemoveVersion),
      toStarted(CftUtilizationActionType.Update),
      toStarted(CftUtilizationActionType.UpdateVersionData),
      toStarted(CftUtilizationActionType.CreateNewVersion),
      toStarted(CftUtilizationActionType.UpdateVersionTitle),
      toStarted(CftUtilizationActionType.SwitchVersion),
      toStarted(CftUtilizationActionType.RemoveVersion),
      toStarted(PfmUtilizationActionType.Update),
      toStarted(PMapUtilizationActionType.Update),
      toStarted(PMapUtilizationActionType.UpdateAccessLevel),
      toStarted(PMapUtilizationActionType.ResolveContributedPerception),
      toStarted(UimUtilizationActionType.Update),
      toStarted(FiveWhysUtilizationActionType.Update),
    )]: (state: UtilizationCollection, action: CombinedToolUtilizationAction): UtilizationCollection => {
      const { utilizationId } = getMetaIds(action.meta);
      const actionType = removeActionSuffix(action.type);

      return setStatuses({
        state,
        meta: action.meta,
        globalStatuses: {
          saving: true,
          saved: false,
        },
        utilizationStatuses: CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses.byUtilizationId[utilizationId],
          updating: true,
          updated: false,
          actionUpdate: {
            type: actionType,
            statuses: {
              pending: true,
              done: false,
            },
          },
        }),
      });
    },

    [combineActions(
      toFailed(ToolUtilizationActionType.UpdateCompleteness),
      toFailed(RrmUtilizationActionType.Update),
      toFailed(EbsUtilizationActionType.Update),
      toFailed(ApaUtilizationActionType.Update),
      toFailed(FortyPrinciplesUtilizationActionType.Update),
      toFailed(NineWindowsUtilizationActionType.Update),
      toFailed(CecUtilizationActionType.Update),
      toFailed(SfmUtilizationActionType.Update),
      toFailed(SfmUtilizationActionType.UpdateNetworkData),
      toFailed(SfmUtilizationActionType.CreateNewVersion),
      toFailed(SfmUtilizationActionType.UpdateVersionTitle),
      toFailed(SfmUtilizationActionType.SwitchVersion),
      toFailed(SfmUtilizationActionType.RemoveVersion),
      toFailed(CftUtilizationActionType.Update),
      toFailed(CftUtilizationActionType.UpdateVersionData),
      toFailed(CftUtilizationActionType.CreateNewVersion),
      toFailed(CftUtilizationActionType.UpdateVersionTitle),
      toFailed(CftUtilizationActionType.SwitchVersion),
      toFailed(CftUtilizationActionType.RemoveVersion),
      toFailed(PfmUtilizationActionType.Update),
      toFailed(PMapUtilizationActionType.Update),
      toFailed(PMapUtilizationActionType.UpdateAccessLevel),
      toFailed(PMapUtilizationActionType.ResolveContributedPerception),
      toFailed(UimUtilizationActionType.Update),
      toFailed(FiveWhysUtilizationActionType.Update),
    )]: (state: UtilizationCollection, action: CombinedToolUtilizationAction): UtilizationCollection => {
      const { utilizationId } = getMetaIds(action.meta);
      const actionType = removeActionSuffix(action.type);

      return setStatuses({
        state,
        meta: action.meta,
        globalStatuses: {
          saving: false,
          saved: false,
          error: true,
        },
        utilizationStatuses: CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses.byUtilizationId[utilizationId],
          updating: false,
          updated: false,
          error: true,
          actionUpdate: {
            type: actionType,
            statuses: {
              pending: false,
              failed: true,
              done: false,
            },
          },
        }),
      });
    },

    [combineActions(
      toSuccess(ToolUtilizationActionType.UpdateCompleteness),
      toSuccess(RrmUtilizationActionType.Update),
      toSuccess(EbsUtilizationActionType.Update),
      toSuccess(ApaUtilizationActionType.Update),
      toSuccess(FortyPrinciplesUtilizationActionType.Update),
      toSuccess(NineWindowsUtilizationActionType.Update),
      toSuccess(CecUtilizationActionType.Update),
      toSuccess(SfmUtilizationActionType.Update),
      toSuccess(SfmUtilizationActionType.UpdateNetworkData),
      toSuccess(SfmUtilizationActionType.CreateNewVersion),
      toSuccess(SfmUtilizationActionType.UpdateVersionTitle),
      toSuccess(SfmUtilizationActionType.SwitchVersion),
      toSuccess(SfmUtilizationActionType.RemoveVersion),
      toSuccess(CftUtilizationActionType.Update),
      toSuccess(CftUtilizationActionType.UpdateVersionData),
      toSuccess(CftUtilizationActionType.CreateNewVersion),
      toSuccess(CftUtilizationActionType.UpdateVersionTitle),
      toSuccess(CftUtilizationActionType.SwitchVersion),
      toSuccess(CftUtilizationActionType.RemoveVersion),
      toSuccess(PfmUtilizationActionType.Update),
      toSuccess(PMapUtilizationActionType.Update),
      toSuccess(PMapUtilizationActionType.UpdateAccessLevel),
      toSuccess(PMapUtilizationActionType.ResolveContributedPerception),
      toSuccess(UimUtilizationActionType.Update),
      toSuccess(FiveWhysUtilizationActionType.Update),
    )]: (state: UtilizationCollection, action: CombinedToolUtilizationAction): UtilizationCollection => {
      const { utilizationId } = getMetaIds(action.meta);
      const actionType = removeActionSuffix(action.type);

      return setToolUtilization({
        state,
        action,
        statuses: resolveUtilizationCollectionStatuses({
          state,
          meta: action.meta,
          globalStatuses: {
            saving: false,
            saved: true,
            error: false,
          },
          utilizationStatuses: CollectionStatusesService.updateStatusesCollection({
            collection: state.statuses.byUtilizationId[utilizationId],
            updating: false,
            updated: true,
            error: false,
            actionUpdate: {
              type: actionType,
              statuses: {
                pending: false,
                failed: false,
                done: true,
              },
            },
          }),
        }),
      });
    },

    /////////////////////////////////////////////////////////////////////
    //////// TOOLS RELATED DATA, ONLY FOR PROJECT SAVING STATUS /////////
    /////////////////////////////////////////////////////////////////////

    [combineActions(
      toStarted(FiveWhysCauseActionType.Create),
      toStarted(FiveWhysSolutionActionType.Create),
      toStarted(FiveWhysCauseActionType.Update),
      toStarted(FiveWhysSolutionActionType.Update),
      toStarted(EbsUtilizationActionType.IdeaCreate),
      toStarted(EbsUtilizationActionType.IdeaUpdate),
      toStarted(EbsUtilizationActionType.IdeasUpdate),
      toStarted(EbsUtilizationActionType.IdeaDelete),
    )]: (state: UtilizationCollection, action: CombinedToolUtilizationAction): UtilizationCollection =>
      setStatuses({
        state,
        meta: action.meta,
        globalStatuses: {
          saving: true,
          saved: false,
        },
      }),

    [combineActions(
      toFailed(FiveWhysCauseActionType.Create),
      toFailed(FiveWhysSolutionActionType.Create),
      toFailed(FiveWhysCauseActionType.Update),
      toFailed(FiveWhysSolutionActionType.Update),
      toFailed(EbsUtilizationActionType.IdeaCreate),
      toFailed(EbsUtilizationActionType.IdeaUpdate),
      toFailed(EbsUtilizationActionType.IdeasUpdate),
      toFailed(EbsUtilizationActionType.IdeaDelete),
    )]: (state: UtilizationCollection, action: CombinedToolUtilizationAction): UtilizationCollection =>
      setStatuses({
        state,
        meta: action.meta,
        globalStatuses: {
          saving: false,
          saved: false,
          error: true,
        },
      }),

    [combineActions(
      toSuccess(FiveWhysCauseActionType.Create),
      toSuccess(FiveWhysSolutionActionType.Create),
      toSuccess(FiveWhysCauseActionType.Update),
      toSuccess(FiveWhysSolutionActionType.Update),
      toSuccess(EbsUtilizationActionType.IdeaCreate),
      toSuccess(EbsUtilizationActionType.IdeaUpdate),
      toSuccess(EbsUtilizationActionType.IdeasUpdate),
      toSuccess(EbsUtilizationActionType.IdeaDelete),
    )]: (state: UtilizationCollection, action: CombinedToolUtilizationAction): UtilizationCollection =>
      setStatuses({
        state,
        meta: action.meta,
        globalStatuses: {
          saving: false,
          saved: true,
          error: false,
        },
      }),

    ///////////////////////////////////////////////////
    ////////////// UTILIZATION REMOVING ///////////////
    ///////////////////////////////////////////////////

    [combineActions(
      toStarted(SfmUtilizationActionType.Delete),
      toStarted(PfmUtilizationActionType.Delete),
      toStarted(EbsUtilizationActionType.Delete),
      toStarted(UimUtilizationActionType.Delete),
      toStarted(RrmUtilizationActionType.Delete),
      toStarted(ApaUtilizationActionType.Delete),
      toStarted(FortyPrinciplesUtilizationActionType.Delete),
      toStarted(NineWindowsUtilizationActionType.Delete),
      toStarted(CecUtilizationActionType.Delete),
      toStarted(FiveWhysUtilizationActionType.Delete),
      toStarted(PMapUtilizationActionType.Delete),
      toStarted(CftUtilizationActionType.Delete),
    )]: (state: UtilizationCollection, action: CombinedToolUtilizationAction): UtilizationCollection =>
      setStatuses({
        state,
        meta: action.meta,
        globalStatuses: {
          removing: true,
        },
        utilizationStatuses: {
          removing: true,
          removed: false,
        },
      }),

    [combineActions(
      toFailed(SfmUtilizationActionType.Delete),
      toFailed(PfmUtilizationActionType.Delete),
      toFailed(EbsUtilizationActionType.Delete),
      toFailed(UimUtilizationActionType.Delete),
      toFailed(RrmUtilizationActionType.Delete),
      toFailed(ApaUtilizationActionType.Delete),
      toFailed(FortyPrinciplesUtilizationActionType.Delete),
      toFailed(NineWindowsUtilizationActionType.Delete),
      toFailed(CecUtilizationActionType.Delete),
      toFailed(FiveWhysUtilizationActionType.Delete),
      toFailed(PMapUtilizationActionType.Delete),
      toFailed(CftUtilizationActionType.Delete),
    )]: (state: UtilizationCollection, action: CombinedToolUtilizationAction): UtilizationCollection =>
      setStatuses({
        state,
        meta: action.meta,
        globalStatuses: {
          removing: false,
          error: true,
        },
        utilizationStatuses: {
          removing: false,
          error: true,
          removed: false,
        },
      }),

    [combineActions(
      toSuccess(EbsUtilizationActionType.Delete),
      toSuccess(UimUtilizationActionType.Delete),
      toSuccess(RrmUtilizationActionType.Delete),
      toSuccess(ApaUtilizationActionType.Delete),
      toSuccess(FortyPrinciplesUtilizationActionType.Delete),
      toSuccess(NineWindowsUtilizationActionType.Delete),
      toSuccess(CecUtilizationActionType.Delete),
      toSuccess(FiveWhysUtilizationActionType.Delete),
      toSuccess(PMapUtilizationActionType.Delete),
      toSuccess(SfmUtilizationActionType.Delete),
      toSuccess(PfmUtilizationActionType.Delete),
      toSuccess(CftUtilizationActionType.Delete),
    )]: (state: UtilizationCollection, action: CombinedToolUtilizationAction): UtilizationCollection =>
      removeToolUtilization({
        state,
        action,
        statuses: resolveUtilizationCollectionStatuses({
          state,
          meta: action.meta,
          globalStatuses: {
            removing: false,
            error: false,
          },
          utilizationStatuses: {
            removing: false,
            error: false,
            removed: true,
          },
        }),
      }),

    ///////////////////////////////////////////////////
    //////////////////// ASSISTANT ////////////////////
    ///////////////////////////////////////////////////

    [toSuccess(AssistantActionType.GetHint)]: (
      state: UtilizationCollection,
      action: AssistantAction,
    ): UtilizationCollection => {
      const tool = state.entities[action.meta.utilizationId];

      if (tool && tool.publicId) {
        return {
          ...state,
          entities: {
            ...state.entities,
            [action.meta.utilizationId]: {
              ...tool,
              metaData: {
                ...tool.metaData,
                aiAssistanceCount: tool.metaData.aiAssistanceCount + 1,
              },
            },
          },
        };
      } else {
        return state;
      }
    },
  },
  DefaultUtilizationCollection,
);
