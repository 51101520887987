import { Principle } from '@priz/shared/src/models/tools/forty-principles/principle';
import { PrincipleAction, PrincipleActionType, PrinciplesAction } from '../actions';
import { DefaultPrincipleList, PrincipleList } from '../model';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { handleActions } from 'redux-actions';
import { CollectionStatusesService } from '@priz/shared/src/services/statuses/collection-statuses.service';

export const principleReducers = handleActions(
  {
    // Fetch

    [toStarted(PrincipleActionType.Fetch)]: (state: PrincipleList): PrincipleList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        loading: true,
        loaded: false,
      }),
    }),
    [toFailed(PrincipleActionType.Fetch)]: (state: PrincipleList): PrincipleList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        loading: false,
        loaded: false,
        error: true,
      }),
    }),
    [toSuccess(PrincipleActionType.Fetch)]: (state: PrincipleList, action: PrinciplesAction): PrincipleList => {
      const principles = action.payload.map(
        principleData =>
          new Principle({
            title: '',
            ...principleData,
          }),
      );

      return {
        ...state,
        principles,
        statuses: CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses,
          loading: false,
          loaded: true,
          error: false,
        }),
      };
    },

    // Update

    [toStarted(PrincipleActionType.Update)]: (state: PrincipleList, action: PrincipleAction): PrincipleList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        updating: true,
        updated: false,
        idsUpdate: [{ target: 'updatingIds', method: 'add', ids: [action.meta.principleId] }],
      }),
    }),
    [toFailed(PrincipleActionType.Update)]: (state: PrincipleList, action: PrincipleAction): PrincipleList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        updating: false,
        updated: false,
        error: true,
        idsUpdate: [{ target: 'updatingIds', method: 'remove', ids: [action.meta.principleId] }],
      }),
    }),
    [toSuccess(PrincipleActionType.Update)]: (state: PrincipleList, action: PrincipleAction): PrincipleList => {
      const updatedPrinciples = state.principles.map(item => (item.id === action.payload.id ? action.payload : item));

      return {
        ...state,
        principles: updatedPrinciples,
        statuses: CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses,
          updating: false,
          updated: true,
          error: false,
          idsUpdate: [{ target: 'updatingIds', method: 'remove', ids: [action.meta.principleId] }],
        }),
      };
    },
  },
  DefaultPrincipleList,
);
