import { Network } from '@priz/shared/src/lib/vis/esnext';
import {
  calcNodeSizeByCanvasPosition,
  checkIsTheSameChain,
  resolveChains,
} from '@priz/shared/src/components/network-diagram/utils';
import { CecEdgeProps, CecNodeProps } from '@priz/shared/src/models/tools/cec';
import { EdgeProps } from '@priz/shared/src/models/vis-network';

export const fitTextarea = (textAreaNode: HTMLElement): void => {
  textAreaNode.style.height = '0px';
  textAreaNode.style.height = `${textAreaNode.scrollHeight}px`;
};

export const getTrimmedInputValueByEvent = (e): string => {
  const text = e.target.value;
  let formatText = text.replace(/  +/g, ' ');
  if (formatText[0] === ' ') formatText = formatText.substring(1);

  return formatText;
};

export const moveViewToNodeIfItOutOfCanvas = (
  network: Network,
  canvasContainer: HTMLElement,
  nodeId: string | number,
  animationDuration = 200,
  offset = 20,
): void => {
  const nodeBoundingBox = network.getBoundingBox(nodeId);

  if (!nodeBoundingBox) return null;

  const canvasScale = network.getScale();
  const viewDomPosition = network.canvasToDOM(network.getViewPosition());
  const nodeDomBoundingBox = network.canvasToDOM({ x: nodeBoundingBox.left, y: nodeBoundingBox.top });
  const nodeSize = calcNodeSizeByCanvasPosition(nodeBoundingBox);

  const right = nodeDomBoundingBox.x + nodeSize.width * canvasScale;
  const bottom = nodeDomBoundingBox.y + nodeSize.height * canvasScale;
  const outOfLeft = nodeDomBoundingBox.x < 0;
  const outOfTop = nodeDomBoundingBox.y < 0;
  const outOfRight = right > canvasContainer.offsetWidth;
  const outOfBottom = bottom > canvasContainer.offsetHeight;

  const outOfCanvas = outOfLeft || outOfTop || outOfRight || outOfBottom;

  if (outOfCanvas) {
    const diffX = outOfLeft ? nodeDomBoundingBox.x : outOfRight ? right - canvasContainer.offsetWidth : 0;
    const diffY = outOfTop ? nodeDomBoundingBox.y : outOfBottom ? bottom - canvasContainer.offsetHeight : 0;
    const moveToCoordinates = network.DOMtoCanvas({ x: diffX + viewDomPosition.x, y: diffY + viewDomPosition.y });

    if (
      nodeSize.height * canvasScale >= canvasContainer.offsetHeight ||
      nodeSize.width * canvasScale >= canvasContainer.offsetHeight
    ) {
      network.fit({ nodes: [nodeId] });
    }

    network.moveTo({
      position: moveToCoordinates,
      offset: {
        x: outOfLeft ? offset : outOfRight ? -offset : 0,
        y: outOfTop ? offset : outOfBottom ? -offset : 0,
      },
      animation: {
        duration: animationDuration,
        easingFunction: 'linear',
      },
    });
  }
};

export const getHierarchicalNodesOrder = (nodes: CecNodeProps[], edges: CecEdgeProps[]): { [id: string]: number } => {
  const rootNode = nodes.find(item => item?.type === 'first') || nodes[0];
  const nodsOrder: { [id: string]: number } = { [rootNode.id]: 0 };
  const totalNodesCount = nodes.length;

  if (rootNode) {
    let connectedEdges = edges.filter(e => e.from === rootNode.id);
    let rowsCounter = 0;

    while (connectedEdges?.length) {
      const childrenConnections = [];
      let columnsCounter = 0;
      // let columnsCounter: {[id: string]: number} = {};

      connectedEdges.forEach(connectedEdge => {
        const nextEdges = edges.filter(e => e.from === connectedEdge.to);

        nodsOrder[connectedEdge.to] = totalNodesCount * rowsCounter + ++columnsCounter;

        // if (!nodsOrder[connectedEdge.to]) {
        //   nodsOrder[connectedEdge.to] = totalNodesCount * rowsCounter + ++columnsCounter;
        // }

        // if (!columnsCounter[connectedEdge.from]) columnsCounter[connectedEdge.from] = 0;
        // nodsOrder[connectedEdge.to] = totalNodesCount * rowsCounter + columnsCounter[connectedEdge.from]++;

        if (nextEdges?.length) {
          childrenConnections.push(...nextEdges);
        }
      });

      connectedEdges = childrenConnections;
      rowsCounter++;
    }
  }

  return nodsOrder;
};

export const sortHierarchicalNodes = (nodes: CecNodeProps[], edges: CecEdgeProps[]): CecNodeProps[] => {
  const nodesCopy = JSON.parse(JSON.stringify(nodes)) as CecNodeProps[];
  const nodesOrder = getHierarchicalNodesOrder(nodes, edges);

  return nodesCopy.sort((a, b) => {
    const aOrder = nodesOrder[a.id];
    const bOrder = nodesOrder[b.id];

    if (aOrder > bOrder) return 1;
    if (aOrder < bOrder) return -1;
    return 0;
  });
};

export const minifyCecNodesProps = (nodes: CecNodeProps[]): CecNodeProps[] => {
  return nodes.map(props => {
    const { id, label, type, potentialSolution, color, borderWidth } = props;
    const minifiedProps = { id } as CecNodeProps;

    if (type) minifiedProps.type = type;
    if (label) minifiedProps.label = label;
    if (color) minifiedProps.color = color;
    if (borderWidth) minifiedProps.borderWidth = borderWidth;
    if (potentialSolution) minifiedProps.potentialSolution = potentialSolution;

    return minifiedProps;
  });
};

export const minifyCecEdgesProps = (edges: CecEdgeProps[]): CecEdgeProps[] => {
  return edges.map(({ id, from, to }) => ({ id, from, to }));
};

interface EdgeConnectionConflictsResolverResult {
  isAlreadyExist: boolean;
  isTheSameLevel: boolean;
  isCauseUpperEffect: boolean;
  isTheSameChain: boolean;
  isNoConflicts: boolean;
  errorMessage: string;
}

export const resolveEdgeConnectionConflicts = (
  newEdge: EdgeProps,
  nodes: CecNodeProps[],
  edges: CecEdgeProps[],
): EdgeConnectionConflictsResolverResult => {
  const fromChainsData = resolveChains(newEdge.from, nodes, edges);
  const toChainsData = resolveChains(newEdge.to, nodes, edges);
  const isTheSameChain = checkIsTheSameChain(fromChainsData.maxChains[0], toChainsData.maxChains[0]);
  const isAlreadyExist = !!edges.find(edge => edge.from === newEdge.from && edge.to === newEdge.to);
  const isCauseUpperEffect = toChainsData.maxChainLength < fromChainsData.maxChainLength;
  const isTheSameLevel = toChainsData.maxChainLength === fromChainsData.maxChainLength;
  const isNoConflicts = !isAlreadyExist && !isTheSameLevel && !isCauseUpperEffect && !isTheSameChain;

  let errorMessage = '';

  if (isTheSameChain) errorMessage = 'Cause cannot be effect';
  if (isCauseUpperEffect || isTheSameLevel) errorMessage = 'Cause must be under effect';
  if (isAlreadyExist) errorMessage = 'Cause already exist';

  return {
    isTheSameChain,
    isAlreadyExist,
    isCauseUpperEffect,
    isTheSameLevel,
    isNoConflicts,
    errorMessage,
  };
};
