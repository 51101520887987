import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';
import { Box, BoxProps } from '@mui/material';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { Trans } from 'react-i18next';
import { ApaAction, ApaUtilization } from '@priz/shared/src/models/tools/apa';
import { ApaActionActions } from '../store/actions';
import { ApaActionCard } from '@priz/shared/src/components/apa-action-card/component';
import { useNavigate } from 'react-router-dom';
import { ToolsUtils } from '../../tools/utils';

interface ApaActionItemProps extends BoxProps {
  apaUtilization: ApaUtilization;
  apaAction: ApaAction;
  finished?: boolean;
  disabled?: boolean;
  compact?: boolean;
}

export const ApaActionItem: React.FC<ApaActionItemProps> = ({
  apaUtilization,
  apaAction,
  finished = false,
  disabled,
  compact,
  ...rest
}) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const deleteClickHandler = e => {
    e.stopPropagation();
    e.preventDefault();
    openDeleteConfirmation();
  };

  const openDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  const deleteCustomerAction = () => {
    dispatch(ApaActionActions.delete(apaUtilization.id, apaAction.id, apaUtilization.project?.id));
    closeDeleteConfirmation();
  };

  const clickHandler = () => {
    navigate([ToolsUtils.resolvePathByUtilization(apaUtilization), 'action', apaAction.id].join('/'));
  };

  const renderContent = () => {
    return (
      <ApaActionCard
        apaAction={apaAction}
        compact={compact}
        finished={finished}
        disabled={disabled}
        onDelete={deleteClickHandler}
      />
    );
  };

  return (
    <>
      {finished ? (
        <Box mb={2} className={styles.root} {...rest}>
          {renderContent()}
        </Box>
      ) : (
        <Box mb={2} className={`${styles.root} _link`} onClick={clickHandler} {...rest}>
          {renderContent()}
        </Box>
      )}

      <PgConfirmationDialog
        isOpen={deleteConfirmationOpen}
        confirmTitle={<Trans>Confirm delete</Trans>}
        okButtonText={<Trans>Delete</Trans>}
        confirmContent={
          <React.Fragment>
            <Trans>Are you sure you want to delete this action?</Trans>
          </React.Fragment>
        }
        onConfirm={deleteCustomerAction}
        onClose={closeDeleteConfirmation}
      />
    </>
  );
};
