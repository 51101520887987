import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Trans } from 'react-i18next';
import { AssigneeSelector, AssigneeSelectorProps } from '../assignee-selector/component';
import { useStyles } from './styles';
import { TeamBlockerMessage } from '../../react/modules/paywall/team-blocker-message/component';
import { useSelector } from 'react-redux';
import { WorkspaceSelectors } from '../../workspace/store/selectors';

interface AssigneeSelectorDialogProps extends AssigneeSelectorProps {
  title: string;
  isOpen: boolean;
  showSeatsLimitMessage?: boolean;
  onClose?: () => void;
  loading?: boolean;
  errorCode?: number;
}

export const AssigneeSelectorDialog: React.FC<AssigneeSelectorDialogProps> = ({
  title,
  isOpen,
  showSeatsLimitMessage,
  onClose,
  loading,
  errorCode,
  ...rest
}) => {
  const styles = useStyles();

  const [open, setOpen] = useState(false);

  const isSeatsLimitHit = useSelector(WorkspaceSelectors.isSeatsLimitHit());

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle className={styles.title}>
        <Trans>{title}</Trans>
      </DialogTitle>

      <DialogContent>
        {showSeatsLimitMessage && isSeatsLimitHit && <TeamBlockerMessage />}

        {errorCode === 402 && (
          <Box mt={2}>
            <Alert severity={'error'}>
              <Trans>Members limit reached</Trans>
            </Alert>
          </Box>
        )}

        <AssigneeSelector key={errorCode} loading={loading} {...rest} />
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose}>
          <Trans>Cancel</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
