import React, { useEffect } from 'react';
import { Attendee, AttendeeStatus } from '../store/model';
import { useForm } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { Trans } from 'react-i18next';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { ReactHookFormText } from '@priz/shared/src/components/form-elements';
import { validateOptions, validatorRules } from '@priz/shared/src/utils/form';
import { ReactHookFormRadio } from '../../react/form-elements';
import { LoadingButton } from '@mui/lab';

interface AttendeeRoleEditorProps {
  attendee: Attendee;
  onAccept?: (status: AttendeeStatus, explanation: string) => void;
  onDecline?: () => void;
  onCancel?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

const resolveInitialValues = (attendee: Attendee): { status: AttendeeStatus | null; explanation: string } => {
  const status = attendee?.status !== AttendeeStatus.Decline ? attendee?.status : null;

  return {
    status: status || null,
    explanation: attendee?.explanation || '',
  };
};

export const AttendeeRoleEditor: React.FC<AttendeeRoleEditorProps> = ({
  attendee,
  onAccept,
  onDecline,
  onCancel,
  disabled,
  loading,
}) => {
  const { handleSubmit, control, reset } = useForm({
    shouldFocusError: false,
    defaultValues: resolveInitialValues(attendee),
  });

  useEffect(() => {
    reset(resolveInitialValues(attendee));
  }, [attendee]);

  const acceptHandler = () => {
    if (onAccept) {
      handleSubmit(formData => {
        onAccept(formData.status, formData.explanation);
      })();
    }
  };

  const declineHandler = () => {
    if (onDecline) onDecline();
  };

  return (
    <>
      <ReactHookFormRadio
        fieldTitle={'Role'}
        name={'status'}
        control={control}
        row={true}
        options={[
          {
            label: 'Contributor',
            value: AttendeeStatus.Contributor,
          },
          {
            label: 'Observer',
            value: AttendeeStatus.Observer,
          },
        ]}
        rules={{
          ...validatorRules.required('Role is required'),
        }}
        color={'primary'}
        fieldTitleWrapperProps={{ mb: 0 }}
        helperTextProps={{ mt: -1 }}
        wrapperProps={{ mb: 1 }}
        disabled={disabled}
      />

      <ReactHookFormText
        fieldTitle={'Explanation'}
        name={'explanation'}
        control={control}
        rules={{
          validate: {
            ...validateOptions.hasText('Explanation is required'),
          },
        }}
        wrapperProps={{ mb: 2 }}
        disabled={disabled}
        multiline
      />

      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <ButtonsWrap spacing={1}>
            <LoadingButton
              size={'pg_small'}
              variant={'pg_rounded'}
              color={'pg_orange_solid'}
              onClick={acceptHandler}
              disabled={disabled}
              loading={loading}
            >
              <Trans>Submit</Trans>
            </LoadingButton>

            {onCancel && (
              <Button size={'pg_small'} variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={onCancel}>
                <Trans>Cancel</Trans>
              </Button>
            )}
          </ButtonsWrap>
        </Grid>

        {onDecline && (
          <Grid item>
            <Button variant="text" size={'small'} onClick={declineHandler} disabled={disabled}>
              <Trans>Decline</Trans>
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};
