import { AttendeeCreateCommand, AttendeeUpdateCommand, IAttendee } from '../store/model';
import axios, { AxiosResponse } from 'axios';

const getAll = (projectId: number): Promise<IAttendee> =>
  axios
    .get(`/v1/api/<ws-id>/project/${projectId}/attendees`)
    .then((response: AxiosResponse<IAttendee>) => response.data);

const get = (projectId: number, attendeeId: number): Promise<IAttendee> =>
  axios
    .get(`/v1/api/<ws-id>/project/${projectId}/attendee/${attendeeId}`)
    .then((response: AxiosResponse<IAttendee>) => response.data);

const create = (projectId: number, command: AttendeeCreateCommand): Promise<IAttendee> =>
  axios
    .post(`/v1/api/<ws-id>/project/${projectId}/attendee`, command)
    .then((response: AxiosResponse<IAttendee>) => response.data);

const update = (projectId: number, attendeeId: number, command: AttendeeUpdateCommand): Promise<IAttendee> =>
  axios
    .patch(`/v1/api/<ws-id>/project/${projectId}/attendee/${attendeeId}`, command)
    .then((response: AxiosResponse<IAttendee>) => response.data);

const doDelete = (projectId: number, attendeeId: number): Promise<IAttendee> =>
  axios
    .delete(`/v1/api/<ws-id>/project/${projectId}/attendee/${attendeeId}`)
    .then((response: AxiosResponse<IAttendee>) => response.data);

export const AttendeeApi = {
  getAll,
  get,
  create,
  update,
  delete: doDelete,
};
