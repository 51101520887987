import React from 'react';
import { SfmUtilization } from '@priz/shared/src/models/tools/sfm';
import { useDispatch, useSelector } from 'react-redux';
import { SfmUtilizationActions, SfmUtilizationActionType } from '../store/actions';
import {
  SfmCreateNewDiagramDataVersionCommand,
  SfmRemoveDiagramDataVersionCommand,
  SfmSwitchDiagramDataVersionCommand,
  SfmUpdateDiagramDataVersionTitleCommand,
} from '../store/services/sfm-utilization.api';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { ToolVersionsTabs } from '../../tools/tool-version-tabs/component';

interface SfmVersionTabsProps {
  sfmUtilization: SfmUtilization;
  activeVersionId?: string;
  onVersionSelect?: (versionId: string) => void;
  disabled?: boolean;
}

export const SfmVersionsTabs: React.FC<SfmVersionTabsProps> = ({
  sfmUtilization,
  activeVersionId,
  onVersionSelect,
  disabled,
}) => {
  const dispatch = useDispatch();

  const isVersionUpdatePending = useSelector(
    ToolUtilizationSelector.isAnyActionPending(sfmUtilization.id, [
      SfmUtilizationActionType.CreateNewVersion,
      SfmUtilizationActionType.UpdateVersionTitle,
      SfmUtilizationActionType.SwitchVersion,
      SfmUtilizationActionType.RemoveVersion,
    ]),
  );

  const switchVersion = (versionId: string) => {
    const command: SfmSwitchDiagramDataVersionCommand = {
      versionId,
    };

    dispatch(SfmUtilizationActions.switchVersion(sfmUtilization.id, command, sfmUtilization.project?.id));
  };

  const renameVersion = (versionId: string, title: string) => {
    const command: SfmUpdateDiagramDataVersionTitleCommand = {
      versionId,
      title,
    };

    dispatch(SfmUtilizationActions.updateVersionTitle(sfmUtilization.id, command, sfmUtilization.project?.id));
  };

  const deleteVersion = (versionId: string) => {
    const command: SfmRemoveDiagramDataVersionCommand = {
      versionId,
    };

    dispatch(SfmUtilizationActions.removeVersion(sfmUtilization.id, command, sfmUtilization.project?.id));
  };

  const createNewVersion = (sourceVersionId?: string) => {
    const command: SfmCreateNewDiagramDataVersionCommand = {
      sourceVersionId,
    };

    dispatch(SfmUtilizationActions.createNewVersion(sfmUtilization.id, command, sfmUtilization.project?.id));
  };

  return (
    <ToolVersionsTabs
      utilizationId={sfmUtilization.id}
      diagramData={sfmUtilization.diagramData}
      activeVersionId={activeVersionId}
      loading={isVersionUpdatePending}
      disabled={disabled}
      onVersionCreate={createNewVersion}
      onVersionSwitch={switchVersion}
      onVersionDelete={deleteVersion}
      onVersionRename={renameVersion}
      onVersionSelect={onVersionSelect}
    />
  );
};
