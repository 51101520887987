export interface Identifiable {
  id: number;
}

/**
 * represents state.entities.`given entity`
 */
export interface EntityMap<T> {
  [id: string]: Readonly<T>;
}

export interface LookupMap {
  [id: string]: number[];
}

export type EntityStatusId = string | number;

export interface ActionTypeStatus {
  pending?: boolean;
  failed?: boolean;
  done?: boolean;
}

export interface ActionTypeStatusMap {
  [actionType: string]: ActionTypeStatus;
}

export interface EntityCollectionStatus {
  creating?: boolean;
  created?: boolean;
  createdIds?: EntityStatusId[];

  removing?: boolean;
  removingIds?: EntityStatusId[];
  removed?: boolean;
  removedIds?: EntityStatusId[];

  updating?: boolean;
  updatingIds?: EntityStatusId[];
  updated?: boolean;
  updatedIds?: EntityStatusId[];

  loading?: boolean;
  loadingIds?: EntityStatusId[];
  loaded?: boolean;
  loadedIds?: EntityStatusId[];

  error?: boolean;
  errorText?: string;
  errorCode?: number;
  errorIds?: EntityStatusId[];

  // Uses for combining creating/updating/removing etc. statuses
  saving?: boolean;
  savingIds?: EntityStatusId[];
  saved?: boolean;
  savedIds?: EntityStatusId[];

  // Used to track statuses of specific action types.
  // Useful for separating the same status types of different sources.
  // For example:
  //    updateData1 and updateData2 actions leads to changing "updating" status on entity,
  //    but its may call different action types, like "entity/update/first" and "entity/update/second"
  actionTypes?: ActionTypeStatusMap;
}

export const DefaultEntityCollectionStatus: EntityCollectionStatus = {
  creating: false,
  created: false,
  createdIds: [],

  removing: false,
  removingIds: [],
  removed: false,
  removedIds: [],

  updating: false,
  updatingIds: [],
  updated: false,
  updatedIds: [],

  loading: false,
  loadingIds: [],
  loaded: false,
  loadedIds: [],

  error: false,
  errorIds: [],

  saving: false,
  savingIds: [],
  saved: false,
  savedIds: [],

  actionTypes: {},
};
