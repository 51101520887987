import { makeStyles } from '@mui/styles';
import React, { ReactElement, ReactNode } from 'react';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Trans } from 'react-i18next';
import { Box, BoxProps } from '@mui/material';

interface PageTitleWithDocLinkProps {
  title?: ReactNode;
  titleComponent?: ReactElement;
  oneLiner?: ReactNode;
  docsUrl?: string;
  wrapperProps?: BoxProps;
}

const useStyles = makeStyles({
  docLink: {
    'backgroundColor': 'transparent',
    'border': 0,
    'color': pgColorScheme.blue,
    'cursor': 'pointer',
    'fontSize': '14px',
    'fontWeight': 'normal',
    'lineHeight': '120%',
    'marginLeft': '4px',
    'textAlign': 'center',
    'textDecoration': 'none',

    '&:focus': {
      outline: 'none',
    },

    '&:active': {
      color: pgColorScheme.darkBlue,
      textDecoration: 'none',
    },
    '&:hover': {
      color: pgColorScheme.darkBlue,
      textDecoration: 'none',
    },
  },
  title: {
    margin: 0,
  },
});

export const PageTitleWithDocLink: React.FC<PageTitleWithDocLinkProps> = ({
  title,
  oneLiner,
  docsUrl,
  titleComponent,
  wrapperProps,
}) => {
  const styles = useStyles();

  return (
    <Box mb={3} {...wrapperProps}>
      {title && <h1 className={`pg-page-title ${styles.title}`}>{title}</h1>}
      {titleComponent}
      {(oneLiner || docsUrl) && (
        <Box mt={1}>
          <span className={'pg-page-help-block'}>
            {oneLiner}
            {docsUrl && (
              <a target="_blank" href={docsUrl} className={styles.docLink} rel="noreferrer">
                <Trans>Learn more</Trans> &raquo;
              </a>
            )}
          </span>
        </Box>
      )}
    </Box>
  );
};
