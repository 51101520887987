import React, { useState } from 'react';
import { ProjectComment } from '@priz/shared/src/models/project';
import { Box, Checkbox, Grid, IconButton, Link, Paper, Tooltip } from '@mui/material';
import { Trans } from 'react-i18next';
import {
  LinkOutlined,
  DeleteForeverOutlined,
  History,
  TaskAlt,
  VisibilityOutlined,
  VisibilityOffOutlined,
} from '@mui/icons-material';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { FieldTitle } from '@priz/shared/src/components/form-elements';

interface CommentProps {
  comment: ProjectComment;
  onApprove: (commentId: number, version: number) => void;
  onDiscard: (commentId: number, version: number) => void;
  onDelete: (commentId: number) => void;
  onSelectToggle: (commentId: number, selected: boolean) => void;
  selected: boolean;
}

export const Comment: React.FC<CommentProps> = ({
  comment,
  onApprove,
  onDiscard,
  onDelete,
  onSelectToggle,
  selected,
}) => {
  const [showPreviousText, setShowPreviousText] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [isApproveConfirmationOpen, setIsApproveConfirmationOpen] = useState(false);
  const [isDiscardConfirmationOpen, setIsDiscardConfirmationOpen] = useState(false);

  const approveHandler = () => {
    closeApproveConfirmation();
    onApprove(comment.id, comment.version);
  };

  const discardHandler = () => {
    closeDiscardConfirmation();
    onDiscard(comment.id, comment.version);
  };

  const deleteHandler = () => {
    closeDeleteConfirmation();
    onDelete(comment.id);
  };

  const openDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
  };

  const openApproveConfirmation = () => {
    setIsApproveConfirmationOpen(true);
  };

  const closeApproveConfirmation = () => {
    setIsApproveConfirmationOpen(false);
  };

  const openDiscardConfirmation = () => {
    setIsDiscardConfirmationOpen(true);
  };

  const closeDiscardConfirmation = () => {
    setIsDiscardConfirmationOpen(false);
  };

  const toggleTextType = () => {
    setShowPreviousText(currentState => !currentState);
  };

  const selectHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelectToggle(comment.id, e.target.checked);
  };

  return (
    <Paper variant={'outlined'} component={Box} p={1}>
      <Grid container spacing={2}>
        <Grid item xs={'auto'}>
          <Tooltip title={<Trans>Select</Trans>} placement={'top'} disableInteractive arrow>
            <Checkbox onChange={selectHandler} size={'small'} checked={selected} />
          </Tooltip>
        </Grid>

        <Grid item xs={true}>
          <Box py={1}>
            {showPreviousText && <FieldTitle text={'Previous text:'} />}
            {showPreviousText ? comment.text : comment.draft}
          </Box>
        </Grid>

        <Grid item xs={'auto'}>
          <Grid container justifyContent={'flex-end'}>
            <Grid item>
              <Tooltip
                title={<Trans>{comment.answersCount ? 'Cannot be deleted due to replies' : 'Delete'}</Trans>}
                placement={'top'}
                disableInteractive
                arrow
              >
                <div>
                  <IconButton
                    color={'error'}
                    size={'small'}
                    onClick={openDeleteConfirmation}
                    disabled={!!comment.answersCount}
                  >
                    <DeleteForeverOutlined />
                  </IconButton>
                </div>
              </Tooltip>
            </Grid>

            {comment.text && (
              <Grid item>
                <Tooltip
                  title={<Trans>{showPreviousText ? 'Show NEW text' : 'Show PREVIOUS text'}</Trans>}
                  placement={'top'}
                  disableInteractive
                  arrow
                >
                  <IconButton color={'secondary'} size={'small'} onClick={toggleTextType}>
                    {showPreviousText ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                  </IconButton>
                </Tooltip>
              </Grid>
            )}

            {comment.version > 1 && (
              <Grid item>
                <Tooltip title={<Trans>Discard</Trans>} placement={'top'} disableInteractive arrow>
                  <IconButton color={'primary'} size={'small'} onClick={openDiscardConfirmation}>
                    <History />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}

            <Grid item>
              <Tooltip title={<Trans>Approve</Trans>} placement={'top'} disableInteractive arrow>
                <IconButton color={'success'} size={'small'} onClick={openApproveConfirmation}>
                  <TaskAlt />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item>
              <Tooltip title={<Trans>Go to related Hub project</Trans>} placement={'top'} disableInteractive arrow>
                <IconButton
                  size={'small'}
                  component={Link}
                  href={`https://hub.priz.guru/project/${comment.project.id}/overview`}
                  target={'_blank'}
                >
                  <LinkOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <PgConfirmationDialog
        isOpen={isApproveConfirmationOpen}
        confirmTitle={<Trans>Confirm approve</Trans>}
        okButtonText={<Trans>Approve</Trans>}
        confirmContent={
          <React.Fragment>
            <Trans>Are you sure you want to approve this comment?</Trans>
          </React.Fragment>
        }
        onConfirm={approveHandler}
        onClose={closeApproveConfirmation}
      />

      <PgConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        confirmTitle={<Trans>Confirm delete</Trans>}
        okButtonText={<Trans>Delete</Trans>}
        confirmContent={
          <React.Fragment>
            <Trans>Are you sure you want to delete this comment?</Trans>
          </React.Fragment>
        }
        onConfirm={deleteHandler}
        onClose={closeDeleteConfirmation}
      />

      <PgConfirmationDialog
        isOpen={isDiscardConfirmationOpen}
        confirmTitle={<Trans>Confirm discard</Trans>}
        okButtonText={<Trans>Discard</Trans>}
        confirmContent={
          <React.Fragment>
            <Trans>Are you sure you want to discard changes of this comment?</Trans>
          </React.Fragment>
        }
        onConfirm={discardHandler}
        onClose={closeDiscardConfirmation}
      />
    </Paper>
  );
};
