import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions } from '../../../user/store/actions/user.actions';
import { UserSelectors } from '../../../user/store/selectors/user.selectors';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { useForm } from 'react-hook-form';
import { FormResetButton, ReactHookFormCheckbox } from '../../../react/form-elements';
import { LoadingButton } from '@priz/shared/src/components/loading-button/component';
import { PrivacySettingsCommand } from '../../../security/services/user.service';
import { User } from '@priz/shared/src/models/security/user';

interface PrivacyFormProps {
  user: User;
}

export const PrivacyForm: React.FC<PrivacyFormProps> = ({ user }) => {
  const dispatch = useDispatch();

  const isPrivacySettingsUpdating = useSelector(UserSelectors.isPrivacySettingsUpdating);

  const { handleSubmit, control, reset, formState } = useForm({
    shouldFocusError: false,
    defaultValues: {
      exposeInHub: false,
      showEmailInHub: false,
    },
  });

  useEffect(() => {
    if (user) {
      resetFormData();
    }
  }, [user?.lastUpdated]);

  const resetFormData = () => {
    reset({
      exposeInHub: user?.exposeInHub || false,
      showEmailInHub: user?.showEmailInHub || false,
    });
  };

  const save = data => {
    const command: PrivacySettingsCommand = {
      exposeInHub: data.exposeInHub,
      showEmailInHub: data.showEmailInHub,
    };

    dispatch(UserActions.updatePrivacySettings(command));
  };

  return (
    <form onSubmit={handleSubmit(save)}>
      <ReactHookFormCheckbox
        label={'Show my profile in PRIZ Hub'}
        helperText={'Publicly show my user in PRIZ Hub even if no projects published'}
        name={'exposeInHub'}
        control={control}
        disabled={isPrivacySettingsUpdating}
      />

      <ReactHookFormCheckbox
        label={'Show email in PRIZ Hub'}
        helperText={'Show my email publicly in PRIZ Hub'}
        name={'showEmailInHub'}
        control={control}
        disabled={isPrivacySettingsUpdating}
      />

      <ButtonsWrap>
        <FormResetButton disabled={!formState.isDirty || isPrivacySettingsUpdating} onClick={resetFormData} />
        <LoadingButton
          disabled={!formState.isDirty || isPrivacySettingsUpdating}
          loading={isPrivacySettingsUpdating}
          type={'submit'}
        />
      </ButtonsWrap>
    </form>
  );
};
