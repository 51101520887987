import { Principle } from '../../models/tools/forty-principles/principle';
import { MatrixElement } from '../../models/tools/forty-principles/matrix-element';
import { Parameter } from '../../models/tools/forty-principles/parameter';
import { IdRef } from '../../models/common/id-ref';
import { FortyPrinciplesUtilization } from '../../models/tools/forty-principles/forty-principles-utilization';

export const resolveParameterTitleById = (parameters: Parameter[], id: number) => {
  const parameter = parameters.find(p => p.id === id);

  return parameter && parameter.title;
};

export const getMatchingPrincipleIds = (utilization: FortyPrinciplesUtilization, matrixElements: MatrixElement[]) => {
  const improvingParameterId = utilization?.improvingParameter?.id;
  const worseningParameterId = utilization?.worseningParameter?.id;

  if (improvingParameterId && worseningParameterId) {
    const matchingElement = matrixElements.find(
      e => e.improvingParameter.id === improvingParameterId && e.worseningParameter.id === worseningParameterId,
    );

    // TODO: MatrixElement.principles is IdRef[], but got string
    const principlesCopy = matchingElement?.principles && JSON.parse(JSON.stringify(matchingElement?.principles));
    const idsArray =
      typeof principlesCopy === 'string'
        ? principlesCopy.split(',').map(id => +id)
        : matchingElement?.principles && (matchingElement?.principles as IdRef[]).map(p => +p.id);

    return idsArray || [];
  } else {
    return [];
  }
};

export const resolveMatchingPrinciples = (
  utilization: FortyPrinciplesUtilization,
  principles: Principle[],
  matrixElements: MatrixElement[],
): Principle[] => {
  const matchingPrincipleIds = getMatchingPrincipleIds(utilization, matrixElements);

  if (Array.isArray(matchingPrincipleIds) && matchingPrincipleIds.length > 0) {
    return matchingPrincipleIds.map(id => principles.find(p => p.id === id)).filter(p => !!p);
  } else {
    // return the first 5 principles
    return principles.slice(0, 10);
  }
};
