import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import { UserWorkspaceContextSelectors } from '../../security/store/selectors/user-workspace-context.selectors';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { PlanLevel, Workspace, WorkspaceMembershipLevel } from '@priz/shared/src/models/workspace';
import { Trans } from 'react-i18next';
import {
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Hidden,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
} from '@mui/material';
import { faBuilding, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pgColorScheme } from '@priz/shared/src/theme';
import { UserContextActions } from '../../security/store/actions';
import { PlanActions } from '../../workspace/store/actions';
import { WorkspaceUtils } from '../../workspace/services';
import { LicenseType } from '@priz/shared/src/models/workspace/license-type.enum';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { LockOutlined } from '@mui/icons-material';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { PlanUpgradeButton } from '../../billing/plan-upgrade-button/component';
import { DialogsSelectors } from '../../dialogs/store/selectors';
import { UserContextSelectors } from '../../security/store/selectors';
import { MfaDialog } from '../../user-profile/security-setting/mfa-dialog/component';

import { ReactComponent as AngleArrowDownIcon } from '../../../assets/icons/pg-angle-arrow-down.svg';

const BadgeContentMap = {
  [LicenseType.Standard]: null,
  [LicenseType.Demo]: 'demo',
  [LicenseType.Education]: 'edu',
  [PlanLevel.PersonalPro]: 'pro',
  [PlanLevel.Team]: 'team',
  [PlanLevel.Organization]: 'org',
};

export const WorkspaceSelector: React.FC = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [isMfaDialogOpen, setIsMfaDialogOpen] = useState(false);

  const currentUser = useSelector(UserContextSelectors.getCurrentUser);
  const workspaces = useSelector(UserWorkspaceContextSelectors.getUserWorkspaces);
  const isWorkspaceAdmin = useSelector(UserWorkspaceContextSelectors.isWorkspaceAdmin);
  const selectedWorkspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const isAnyDialogOpen = useSelector(DialogsSelectors.isAnyOpen);
  const workspacesMembershipLevelMap = useSelector(UserWorkspaceContextSelectors.getUserWorkspacesMembershipLevelMap);

  const badgeContent =
    BadgeContentMap[selectedWorkspace?.licenseType] || BadgeContentMap[selectedWorkspace?.type] || '';

  useEffect(() => {
    dispatch(UserContextActions.load(pathname, navigate));
    dispatch(PlanActions.loadAll());
  }, []);

  const resolveWorkspaceIcon = (workspace: Workspace) => {
    switch (workspace.type) {
      case PlanLevel.Team:
        return <FontAwesomeIcon style={{ fontSize: 14 }} icon={faUsers} />;
      case PlanLevel.Organization:
        return <FontAwesomeIcon style={{ fontSize: 14 }} icon={faBuilding} />;
      default:
        return <FontAwesomeIcon style={{ fontSize: 14 }} icon={faUser} />;
    }
  };

  // const isAdmin = () => {
  //   return AuthService.access([Role.ROLE_ADMIN]);
  // }

  const openMfaDialog = () => {
    setIsMfaDialogOpen(true);
  };

  const closeMfaDialog = () => {
    setIsMfaDialogOpen(false);
  };

  return (
    <div className={styles.root}>
      {isWorkspaceAdmin && selectedWorkspace && !WorkspaceUtils.isMaxPlan(selectedWorkspace) && (
        <Hidden mdDown>
          <Box mr={1}>
            <PlanUpgradeButton />
          </Box>
        </Hidden>
      )}

      {workspaces && selectedWorkspace && workspaces.length > 0 && (
        <PopperWrap
          dropdownProps={{ p: 0 }}
          arrow={false}
          open={isAnyDialogOpen || isMfaDialogOpen ? false : undefined}
        >
          <Button
            variant={'pg_transparent'}
            startIcon={resolveWorkspaceIcon(selectedWorkspace)}
            endIcon={<AngleArrowDownIcon />}
          >
            <Badge
              badgeContent={badgeContent}
              invisible={!badgeContent.length}
              color="secondary"
              overlap={'rectangular'}
            >
              <span className={styles.workspaceButtonText}>{selectedWorkspace.name}</span>
            </Badge>
          </Button>

          <MenuList className={styles.menuList}>
            {isWorkspaceAdmin && (
              <Box>
                <MenuItem component={Link} to={'/workspace/settings'} className={styles.menuItem}>
                  <Typography variant="body2" component="span">
                    <Box color={pgColorScheme.blue}>
                      <Trans>Workspace Settings</Trans>
                    </Box>
                  </Typography>
                </MenuItem>

                <Box py={1}>
                  <Divider />
                </Box>
              </Box>
            )}

            {selectedWorkspace && !WorkspaceUtils.isMaxPlan(selectedWorkspace) && (
              <Box>
                <Hidden mdUp>
                  <Box px={1}>
                    <PlanUpgradeButton />
                  </Box>
                  <Box py={1}>
                    <Divider />
                  </Box>
                </Hidden>
              </Box>
            )}

            <Box px={2} py={1}>
              <Typography variant="caption" component="span">
                <Box fontWeight="fontWeightBold">
                  <Trans>Your workspaces</Trans>
                </Box>
              </Typography>
            </Box>

            {workspaces.map((workspace, key) => {
              const path = ['/workspace', workspace.id, 'switch'].join('/');
              const isWsAdmin = workspacesMembershipLevelMap[workspace.id] === WorkspaceMembershipLevel.Admin;
              const mfaLock = !isWsAdmin && workspace.mfa ? !currentUser.mfa : false;

              return (
                <Box key={key} maxWidth={300}>
                  {!mfaLock && (
                    <MenuItem component={Link} to={path} selected={workspace.id === selectedWorkspace.id}>
                      <Typography variant="body2" component="span" noWrap>
                        {workspace.name}
                      </Typography>
                    </MenuItem>
                  )}

                  {mfaLock && (
                    <MenuItem onClick={openMfaDialog} selected={workspace.id === selectedWorkspace.id}>
                      <Grid container spacing={1}>
                        <Grid item xs={true} overflow={'hidden'} display={'flex'} alignItems={'center'}>
                          <Typography variant="body2" component="span" noWrap>
                            {workspace.name}
                          </Typography>
                        </Grid>

                        <Grid item xs={'auto'}>
                          <Tooltip
                            title={<Trans>Multi-Factor Authentication required</Trans>}
                            placement={'top'}
                            arrow
                            disableInteractive
                          >
                            <Chip
                              variant={'filled'}
                              size={'extra_small'}
                              icon={<LockOutlined fontSize={'inherit'} />}
                              label={<Trans>mfa</Trans>}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  )}
                </Box>
              );
            })}

            <Box py={1}>
              <Divider />
            </Box>

            <MenuItem
              component={Link}
              to={['/workspace', 'new'].join('/')}
              className={styles.menuItem}
              disabled={!!matchPath(['/workspace', 'new'].join('/'), pathname)}
            >
              <Typography variant="body2" component="span">
                <Box color={pgColorScheme.blue}>
                  <Trans>Create a workspace</Trans>
                </Box>
              </Typography>
            </MenuItem>
          </MenuList>
        </PopperWrap>
      )}

      <MfaDialog open={isMfaDialogOpen} onClose={closeMfaDialog} />
    </div>
  );
};
