import { ToolType } from '../tools';
import { IdRef } from '../common/id-ref';
import { AuditableEntity, IAuditableEntity } from '../common/auditable-entity';
import { Identifiable } from '../common/entity-collection-state';
import { IdeaStatus } from './status';

export interface IIdea extends IAuditableEntity, Identifiable {
  content: string;
  details: string;
  status: IdeaStatus;
  sourceToolType: ToolType;
  sourceToolUtilization: IdRef;
  sourceId?: string;
  latestRankingScore?: number;
  createdById?: number;
  selectedAsWinning: boolean;
  project: IdRef;
  user: IdRef;
}

export class Idea extends AuditableEntity implements IIdea {
  public id: number;
  public content: string;
  public details: string;
  public status: IdeaStatus;
  public sourceToolType: ToolType;
  public sourceToolUtilization: IdRef;
  public sourceId?: string;
  public latestRankingScore?: number;
  public createdById?: number;
  public selectedAsWinning: boolean;
  public project: IdRef;
  public user: IdRef;

  constructor(json: IIdea) {
    super(json);
    this.id = json.id;
    this.sourceToolType = json.sourceToolType;
    this.content = json.content;
    this.details = json.details;
    this.status = json.status;
    this.sourceToolUtilization = json.sourceToolUtilization;
    this.sourceId = json.sourceId;
    this.latestRankingScore = json.latestRankingScore;
    this.createdById = json.createdById;
    this.selectedAsWinning = json.selectedAsWinning || false;
    this.project = json.project;
    this.user = json.user;
  }
}

export interface IdeaUpdateCommand {
  content?: string;
  details?: string;
  status?: IdeaStatus;
}
