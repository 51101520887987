import React from 'react';
import { useStyles } from './styles';
import { PerceptionCalcResult } from '../../models/tools/pmap';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  PaperProps,
  TableProps,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

interface PMapSelectorProps {
  result: PerceptionCalcResult[];
  paperProps?: PaperProps;
  tableProps?: TableProps;
}

export const PMapResultTable: React.FC<PMapSelectorProps> = ({ result = [], paperProps, tableProps }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <TableContainer className={styles.container} component={Paper} variant={'outlined'} {...paperProps}>
      <Table size={'small'} {...tableProps}>
        <TableHead className={styles.header}>
          <TableRow>
            {['Assignation', 'Collector', 'Loop', 'Conflicts', 'Sum', 'Blocking Rank'].map((item, key) => (
              <TableCell key={key} align={'left'}>
                <Trans>{item}</Trans>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {result.map((row, rowKey) => {
            const { letter, collector, loop, conflicts, sum, blockingRank } = row;
            const translatedBlockingRank = t(blockingRank);

            return (
              <TableRow role="checkbox" tabIndex={-1} key={rowKey}>
                {[letter, collector, loop, conflicts, sum, translatedBlockingRank].map((col, colKey) => {
                  return (
                    <TableCell key={colKey} align={'left'}>
                      {col}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
