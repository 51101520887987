import { handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { toObjectMap } from '../../../shared/store/reducer-utils';
import { ArrayUtil } from '../../../shared/utils/array-util';
import {
  DefaultProjectAssigneeCollection,
  ProjectAssigneeCollection,
  ProjectAssignee,
} from '@priz/shared/src/models/assignment';
import { NewProjectAssignmentResponse, ProjectAssignmentAction, ProjectAssignmentActionType } from '../actions';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { copyObject } from '@priz/shared/src/utils/common';

const assigneeInstantiator = (payload: ProjectAssignee): ProjectAssignee => payload;

const setStatuses = (
  state: ProjectAssigneeCollection,
  statuses: Partial<EntityCollectionStatus>,
): ProjectAssigneeCollection => ({
  ...state,
  statuses: {
    ...state.statuses,
    ...statuses,
  },
});

const setProjectAssignees = (
  state: ProjectAssigneeCollection,
  assignees: ProjectAssignee[],
): ProjectAssigneeCollection => {
  const assigneeMap = toObjectMap<ProjectAssignee>(assignees, assigneeInstantiator);
  const entitiesCopy = { ...state.entities, ...assigneeMap };

  const lookupsCopy = copyObject(state.lookups);
  Object.values(assigneeMap).forEach((assignee: ProjectAssignee) => {
    lookupsCopy.byProjectId[assignee.projectId] = ArrayUtil.concatInDistinctArray(
      lookupsCopy.byProjectId[assignee.projectId],
      assignee.id,
    );
  });

  const statusesCopy = { ...state.statuses, loaded: true, loading: false, error: false };

  return {
    ...state,
    entities: entitiesCopy,
    lookups: lookupsCopy,
    statuses: statusesCopy,
  };
};

//const updateFiveWhysCause = (state: FiveWhysCauseCollection, causePayload: any): FiveWhysCauseCollection => {
//  const updatedCause = assigneeInstantiator(causePayload);
//
//  const entitiesCopy = {
//    ...state.entities,
//    [updatedCause.id]: updatedCause,
//  };
//
//  return {
//    ...state,
//    entities: entitiesCopy,
//  };
//};

const removeProjectAssignee = (
  state: ProjectAssigneeCollection,
  projectId: number,
  assignmentId: number,
): ProjectAssigneeCollection => {
  const entitiesCopy = { ...state.entities };
  delete entitiesCopy[assignmentId];

  const lookupsCopy = copyObject(state.lookups);
  lookupsCopy.byProjectId[projectId] = lookupsCopy.byProjectId[projectId].filter(id => id != assignmentId);

  return {
    ...state,
    entities: entitiesCopy,
    lookups: lookupsCopy,
  };
};

export const projectAssigneeReducers = handleActions(
  {
    [toStarted(ProjectAssignmentActionType.FetchAll)]: (
      state: ProjectAssigneeCollection,
      _action: ProjectAssignmentAction,
    ): ProjectAssigneeCollection => setStatuses(state, { loading: true }),
    [toSuccess(ProjectAssignmentActionType.FetchAll)]: (
      state: ProjectAssigneeCollection,
      action: ProjectAssignmentAction,
    ): ProjectAssigneeCollection => setProjectAssignees(state, action.payload as ProjectAssignee[]),
    [toFailed(ProjectAssignmentActionType.FetchAll)]: (
      state: ProjectAssigneeCollection,
      _action: ProjectAssignmentAction,
    ): ProjectAssigneeCollection => setStatuses(state, { loading: false, error: true }),
    [toStarted(ProjectAssignmentActionType.Create)]: (state: ProjectAssigneeCollection): ProjectAssigneeCollection =>
      setStatuses(state, { loading: true }),
    [toSuccess(ProjectAssignmentActionType.Create)]: (
      state: ProjectAssigneeCollection,
      action: ProjectAssignmentAction,
    ): ProjectAssigneeCollection =>
      setProjectAssignees(state, [(action.payload as NewProjectAssignmentResponse).assignee]),
    [toFailed(ProjectAssignmentActionType.Create)]: (state: ProjectAssigneeCollection): ProjectAssigneeCollection =>
      setStatuses(state, { loading: false, error: true }),
    [toSuccess(ProjectAssignmentActionType.Delete)]: (
      state: ProjectAssigneeCollection,
      action: ProjectAssignmentAction,
    ): ProjectAssigneeCollection => removeProjectAssignee(state, action.meta.projectId, action.meta.assignmentId),
  },
  DefaultProjectAssigneeCollection,
);
