import { DefaultUserProjectSettings, UserProjectSettings } from '@priz/shared/src/models/user/user-project-settings';

export interface UserProjectSettingsState {
  settings: UserProjectSettings;
  loading: boolean;
  error?: any;
  loaded: boolean;
}

export const DefaultUserProjectSettingsState: UserProjectSettingsState = {
  loading: false,
  loaded: false,
  settings: DefaultUserProjectSettings,
};
