import React, { useEffect } from 'react';
import { ToolType } from '@priz/shared/src/models/tools';
import { PrizLoadingOverlay } from '@priz/shared/src/components/priz-loading-overlay/component';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ToolUtilizationCommand } from '../../project/services';
import { ToolUtilizationActions } from '../../project/store/actions/tools.actions';
import { RrmUtils } from '../../rrm/utils/rrm-utils';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm';

interface ToolCreatingPage {
  type: ToolType;
}

export const ToolCreatingPage: React.FC<ToolCreatingPage> = ({ type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.values(ToolType).includes(type)) {
      const command: ToolUtilizationCommand = {
        type: type,
        title: type === ToolType.RRM ? RrmUtils.generateRrmGoalTitle(RrmGoalType.SELF) : '',
      };

      dispatch(ToolUtilizationActions.createTool(command, undefined, navigate));
    }
  }, []);

  return <PrizLoadingOverlay />;
};
