import { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { v1 as uuidv1 } from 'uuid';
import { EdgeProps, NodeProps } from '../../models/vis-network';
import { Network, NetworkEvents, Options } from '../../lib/vis/esnext';
import { DataSet } from 'vis-data';
import { ArrayUtils } from '../../utils/common';
import { useIsMounted } from '../../utils/hooks';
import { CecEdgeProps, CecNodeProps } from '../../models/tools/cec';

type VisNetworkElementId = number | string;

interface VisNetworkInteractedNode {
  node: VisNetworkElementId;
}

interface VisNetworkInteractedEdge {
  edge: VisNetworkElementId;
}

interface VisNetworkPointerData {
  DOM: { x: number; y: number };
  canvas: { x: number; y: number };
}

interface VisNetworkClickEventData {
  nodes: VisNetworkElementId[];
  edges: VisNetworkElementId[];
  event: MouseEvent;
  pointer: VisNetworkPointerData;
}

interface VisNetworkDeselectEventData extends VisNetworkClickEventData {
  previousSelection: {
    nodes: NodeProps[];
    edges: EdgeProps[];
  };
}

interface VisNetworkControlNodeDragEventData extends VisNetworkClickEventData {
  controlEdge: { from: VisNetworkElementId; to: VisNetworkElementId };
}

interface VisNetworkZoomEventData {
  direction: '+' | '-';
  scale: number;
  pointer: VisNetworkPointerData;
}

interface VisNetworkStabilizationData {
  iterations: number;
}

interface VisNetworkStabilizationProgressData extends VisNetworkStabilizationData {
  total: number;
}

interface VisNetworkResizeData {
  width: number;
  height: number;
  oldWidth: number;
  oldHeight: number;
}

export interface VisNetworkEvents {
  click: (data: VisNetworkClickEventData) => void;
  doubleClick: (data: VisNetworkClickEventData) => void;
  oncontext: (data: VisNetworkClickEventData) => void;
  hold: (data: VisNetworkClickEventData) => void;
  release: (data: VisNetworkClickEventData) => void;
  select: (data: VisNetworkClickEventData) => void;
  selectNode: (data: VisNetworkClickEventData) => void;
  selectEdge: (data: VisNetworkClickEventData) => void;

  deselectNode: (data: VisNetworkDeselectEventData) => void;
  deselectEdge: (data: VisNetworkDeselectEventData) => void;

  dragStart: (data: VisNetworkClickEventData) => void;
  dragging: (data: VisNetworkClickEventData) => void;
  dragEnd: (data: VisNetworkClickEventData) => void;

  controlNodeDragging: (data: VisNetworkControlNodeDragEventData) => void;
  controlNodeDragEnd: (data: VisNetworkControlNodeDragEventData) => void;

  hoverNode: (data: VisNetworkInteractedNode) => void;
  blurNode: (data: VisNetworkInteractedNode) => void;
  hoverEdge: (data: VisNetworkInteractedEdge) => void;
  blurEdge: (data: VisNetworkInteractedEdge) => void;

  zoom: (data: VisNetworkZoomEventData) => void;
  showPopup: (data: VisNetworkElementId) => void;

  hidePopup: () => void;
  startStabilizing: () => void;

  stabilizationProgress: (data: VisNetworkStabilizationProgressData) => void;
  stabilizationIterationsDone: () => void;

  stabilized: (data: VisNetworkStabilizationData) => void;
  resize: (data: VisNetworkResizeData) => void;
  initRedraw: () => void;

  beforeDrawing: (data: CanvasRenderingContext2D) => void;
  afterDrawing: (data: CanvasRenderingContext2D) => void;
  animationFinished: () => void;
  configChange: (data: Options) => void;
}

export const resolveNodeProps = <N = NodeProps>(nodes: N[], resolver: (nodeProps: Partial<N>) => Partial<N>): N[] => {
  return nodes.map(item => ({ ...item, ...resolver(item) }));
};

export const resolveEdgeProps = <E = NodeProps>(edges: E[], resolver: (edgeProps: Partial<E>) => Partial<E>): E[] => {
  return edges.map(item => ({ ...item, ...resolver(item) }));
};

export const useNetworkDataset = <N extends NodeProps = NodeProps, E extends EdgeProps = EdgeProps>(
  data: {
    nodes: N[];
    edges: E[];
  },
  nodePropsDecorator?: (nodeProps: Partial<N>) => Partial<N>,
  edgePropsDecorator?: (edgeProps: Partial<E>) => Partial<E>,
): {
  nodesDataSet: DataSet<N>;
  edgesDataSet: DataSet<E>;
} => {
  const { nodes = [], edges = [] } = data || {};

  const nodesDataSet = useMemo(
    () => new DataSet<N>(nodePropsDecorator ? resolveNodeProps<N>(nodes, nodePropsDecorator) : nodes),
    [],
  );

  const edgesDataSet = useMemo(
    () => new DataSet<E>(edgePropsDecorator ? resolveEdgeProps<E>(edges, edgePropsDecorator) : edges),
    [],
  );

  return { nodesDataSet, edgesDataSet };
};

export const manageNetworkEvents = (
  network: Network,
  events: Partial<VisNetworkEvents>,
  unbind?: boolean,
  preventResetInteraction?: boolean,
): void => {
  if (network) {
    const eventsArray = Object.keys(events) as NetworkEvents[];

    eventsArray.forEach((e: NetworkEvents) => {
      const event = events[e];

      if (event) {
        if (unbind) {
          network.off(e, event);
        } else {
          network.on(e, event);
        }
      }
    });

    if (unbind && !preventResetInteraction) {
      network.disableEditMode();
      network.unselectAll();
    }
  }
};

export const bindNetworkEvents = (network: Network, events: Partial<VisNetworkEvents>): void => {
  if (network) {
    const eventsArray = Object.keys(events) as NetworkEvents[];

    eventsArray.forEach((e: NetworkEvents) => {
      const event = events[e];

      if (event) {
        network.on(e, event);
      }
    });
  }
};

export const unbindNetworkEvents = (network: Network, events: Partial<VisNetworkEvents>): void => {
  if (network) {
    const eventsArray = Object.keys(events) as NetworkEvents[];

    eventsArray.forEach((e: NetworkEvents) => {
      const event = events[e];

      if (event) {
        network.off(e, event);
      }
    });
  }
};

export const getPositionInHtmlElement = (
  element: HTMLElement,
  domX: number,
  domY: number,
): {
  x: number;
  y: number;
} => {
  const rect = element.getBoundingClientRect();

  return {
    x: domX - rect.left + window.scrollX,
    y: domY - rect.top + window.scrollY,
  };
};

export const addNode = (props: {
  nodesDataSet: DataSet<NodeProps>;
  nodeProps: Partial<NodeProps>;
  edgesDataSet?: DataSet<EdgeProps>;
  edgeProps?: Partial<EdgeProps>;
  nodePropsDecorator?: (nodeProps: Partial<NodeProps>) => Partial<NodeProps>;
  edgePropsDecorator?: (edgeProps: Partial<EdgeProps>) => Partial<EdgeProps>;
  edgeDirection?: 'to' | 'from';
  senderId?: string;
}): void => {
  const {
    nodesDataSet,
    edgesDataSet,
    nodeProps,
    edgeProps,
    nodePropsDecorator,
    edgePropsDecorator,
    edgeDirection = 'to',
    senderId,
  } = props;
  const id = nodeProps?.id || uuidv1();
  const nodeData = { id, ...nodeProps } as NodeProps;

  nodesDataSet.add(nodePropsDecorator ? { ...nodeData, ...nodePropsDecorator(nodeData) } : nodeData, senderId);

  if (edgeProps?.from && edgesDataSet) {
    const edgeData = {
      ...edgeProps,
      to: edgeDirection === 'to' ? id : edgeProps.from,
      from: edgeDirection === 'to' ? edgeProps.from : id,
    } as EdgeProps;

    edgesDataSet.add(edgePropsDecorator ? { ...edgeData, ...edgePropsDecorator(edgeData) } : edgeData, senderId);
  }
};

export const addEdge = (props: {
  edgesDataSet: DataSet<EdgeProps>;
  edgeProps: Partial<EdgeProps>;
  edgePropsDecorator?: (edgeProps: Partial<EdgeProps>) => Partial<EdgeProps>;
  edgeDirection?: 'to' | 'from';
  senderId?: string;
}): void => {
  const { edgesDataSet, edgeProps, edgePropsDecorator, edgeDirection = 'to', senderId } = props;
  const edgeData = {
    ...edgeProps,
    from: edgeDirection === 'to' ? edgeProps?.from : edgeProps?.to,
    to: edgeDirection === 'to' ? edgeProps?.to : edgeProps?.from,
  } as EdgeProps;

  edgesDataSet.add(edgePropsDecorator ? { ...edgeData, ...edgePropsDecorator(edgeData) } : edgeData, senderId);
};

export const updateNode = <N = NodeProps>(props: {
  id: string | number;
  nodesDataSet: DataSet<NodeProps>;
  data: Partial<N>;
  nodePropsDecorator?: (nodeProps: Partial<N>) => Partial<N>;
}): void => {
  const { nodesDataSet, id, data, nodePropsDecorator } = props;
  nodesDataSet.updateOnly({ id, ...(nodePropsDecorator ? nodePropsDecorator(data) : data) });
};

export const updateEdge = (props: {
  id: string | number;
  edgesDataSet: DataSet<EdgeProps>;
  data: Partial<EdgeProps>;
  edgePropsDecorator?: (edgeProps: Partial<EdgeProps>) => Partial<EdgeProps>;
}): void => {
  const { edgesDataSet, id, data, edgePropsDecorator } = props;
  edgesDataSet.updateOnly({ id, ...(edgePropsDecorator ? edgePropsDecorator(data) : data) });
};

export const revertEdgeDirection = (props: { id: string; edgesDataSet: DataSet<EdgeProps> }): void => {
  const { edgesDataSet, id } = props;
  const { from, to } = edgesDataSet.get(id) as EdgeProps;
  edgesDataSet.updateOnly({ id, from: to, to: from });
};

export const removeEdge = (props: { id: string; edgesDataSet: DataSet<EdgeProps> }): void => {
  const { edgesDataSet, id } = props;
  edgesDataSet.remove(id);
};

export const removeNode = (props: {
  id: string;
  nodesDataSet: DataSet<NodeProps>;
  removeConnectedEdges?: {
    network: Network;
    edgesDataSet: DataSet<EdgeProps>;
  };
}): void => {
  const { nodesDataSet, id: nodeId, removeConnectedEdges } = props;

  if (removeConnectedEdges) {
    const { network, edgesDataSet } = removeConnectedEdges;
    const edges = network.getConnectedEdges(nodeId);
    edgesDataSet.remove(edges);
  }

  nodesDataSet.remove(nodeId);
};

export enum HistorySenderId {
  Ignore = 'history_ignore',
  Save = 'save_in_history',
}

export enum HistoryListener {
  All = 'All',
  Custom = 'Custom',
}

export const useNetworkHistory = (
  nodesDataSet: DataSet<NodeProps>,
  edgesDataSet: DataSet<EdgeProps>,
  keyboardControl = false,
  maxChangesCache = 1000,
  listen: HistoryListener = HistoryListener.All,
  undoCallback?: () => void,
  redoCallback?: () => void,
  disabled?: boolean,
): [boolean, boolean, () => void, () => void] => {
  const [pointerIsAtStart, setPointerIsAtStart] = useState(true);
  const [pointerIsAtEnd, setPointerIsAtEnd] = useState(true);
  const [changesArrayPointer, setChangesArrayPointer] = useState(0);

  const [changesCache, setChangesCache] = useState<{ nodes: NodeProps[]; edges: EdgeProps[] }[]>([
    { nodes: nodesDataSet.get(), edges: edgesDataSet.get() },
  ]);

  const { checkIsMounted } = useIsMounted();

  useEffect(() => {
    nodesDataSet.on('*', dataSetChangeCallback);
    edgesDataSet.on('*', dataSetChangeCallback);

    return () => {
      nodesDataSet.off('*', dataSetChangeCallback);
      edgesDataSet.off('*', dataSetChangeCallback);
    };
  }, []);

  useEffect(() => {
    if (keyboardControl) document.addEventListener('keydown', documentKeyDownHandler);

    return () => {
      if (keyboardControl) document.removeEventListener('keydown', documentKeyDownHandler);
    };
  }, [keyboardControl, changesCache, changesArrayPointer, disabled]);

  useEffect(() => {
    setPointerIsAtStart(changesArrayPointer <= 0);
    setPointerIsAtEnd(changesArrayPointer >= changesCache.length - 1);
  }, [changesArrayPointer]);

  useEffect(() => {
    setChangesArrayPointer(changesCache.length - 1);
  }, [changesCache]);

  const dataSetChangeCallback = (_event: any, _properties: any, senderId: string) => {
    const allListenerIsValid = listen === HistoryListener.All && senderId !== HistorySenderId.Ignore;
    const customListenerIsValid = listen === HistoryListener.Custom && senderId === HistorySenderId.Save;

    if (allListenerIsValid || customListenerIsValid) {
      return debounceSetChangesCache(nodesDataSet.get(), edgesDataSet.get());
    }
  };

  const debounceSetChangesCache = useCallback(
    debounce((nodes: NodeProps[], edges: EdgeProps[]) => {
      if (checkIsMounted()) {
        setChangesCache(currentState => {
          const state = [...currentState];
          if (state.length >= maxChangesCache) state.shift();
          return [...state, { nodes, edges }];
        });
      }
    }, 500),
    [],
  );

  const moveHistoryIndex = (direction: 1 | -1) => {
    const newPointer = changesArrayPointer + direction;

    if (newPointer >= 0 && newPointer <= changesCache.length - 1) {
      const changes = changesCache[newPointer];

      nodesDataSet.clear(HistorySenderId.Ignore);
      edgesDataSet.clear(HistorySenderId.Ignore);

      nodesDataSet.add(changes.nodes, HistorySenderId.Ignore);
      edgesDataSet.add(changes.edges, HistorySenderId.Ignore);

      setChangesArrayPointer(newPointer);
    }
  };

  const documentKeyDownHandler = (e: KeyboardEvent) => {
    if (disabled) return null;

    const zButtonIsPressed = e.key === 'z' || e.keyCode === 90;

    if (e.ctrlKey && !e.shiftKey && zButtonIsPressed) {
      undoChanges();
    }

    if (e.ctrlKey && e.shiftKey && zButtonIsPressed) {
      redoChanges();
    }
  };

  const undoChanges = () => {
    moveHistoryIndex(-1);
    if (undoCallback) undoCallback();
  };

  const redoChanges = () => {
    moveHistoryIndex(1);
    if (redoCallback) redoCallback();
  };

  return [pointerIsAtStart, pointerIsAtEnd, undoChanges, redoChanges];
};

export const replaceUniqueElementTypeIfExists = (props: {
  nodesDataSet: DataSet<NodeProps>;
  uniqueTypes: { type: string; replaceByType: string }[];
  replaceType: string;
  nodePropsDecorator?: (nodeProps: Partial<NodeProps>) => Partial<NodeProps>;
}): void => {
  const { nodesDataSet, uniqueTypes, replaceType, nodePropsDecorator } = props;
  const matchingUniqueNodeType = uniqueTypes.find(item => item.type === replaceType);

  if (matchingUniqueNodeType) {
    const existUniqueNode = nodesDataSet.get().find(item => item.type === replaceType);

    if (existUniqueNode) {
      updateNode({
        nodesDataSet,
        nodePropsDecorator,
        id: existUniqueNode.id,
        data: { type: matchingUniqueNodeType.replaceByType },
      });
    }
  }
};

export const calcNodeSizeByCanvasPosition = (position: {
  top: number;
  left: number;
  right: number;
  bottom: number;
}): { width: number; height: number } => {
  const { top, left, right, bottom } = position;

  return {
    width:
      left < 0 && right < 0
        ? Math.abs(left - right)
        : left > 0 && right > 0
        ? right - left
        : Math.abs(left) + Math.abs(right),
    height:
      top < 0 && bottom < 0
        ? Math.abs(top - bottom)
        : top > 0 && bottom > 0
        ? bottom - top
        : Math.abs(top) + Math.abs(bottom),
  };
};

export const useRemoveNetworkElementsByKeyboard = (props: {
  network: Network;
  nodesDataSet: DataSet<NodeProps>;
  edgesDataSet: DataSet<EdgeProps>;
  removeExceptionNodesTypes?: string[];
  removeExceptionEdgesTypes?: string[];
  listenBackspace?: boolean;
  listenDelete?: boolean;
  disabled?: boolean;
}): [removedNodeId: string | null, removedEdgeId: string | null] => {
  const [removedNodeId, setRemovedNodeId] = useState<string | null>(null);
  const [removedEdgeId, setRemovedEdgeId] = useState<string | null>(null);

  const {
    network,
    nodesDataSet,
    edgesDataSet,
    removeExceptionNodesTypes = [],
    removeExceptionEdgesTypes = [],
    listenBackspace = true,
    listenDelete = true,
    disabled,
  } = props;

  useEffect(() => {
    if (network) document.addEventListener('keydown', documentKeyDownHandler);

    return () => {
      document.removeEventListener('keydown', documentKeyDownHandler);
    };
  }, [network, disabled]);

  const documentKeyDownHandler = (e: KeyboardEvent) => {
    if (disabled) return;

    const isBackspace = listenBackspace && (e.key === 'Backspace' || e.keyCode === 8);
    const isDelete = listenDelete && (e.key === 'Delete' || e.keyCode === 46);

    if (isBackspace || isDelete) {
      const selectedNodeId = network.getSelectedNodes()[0] as string;
      const selectedNode = selectedNodeId && nodesDataSet.get(selectedNodeId);

      const selectedEdgeId = network.getSelectedEdges()[0] as string;
      const selectedEdge = selectedEdgeId && edgesDataSet.get(selectedEdgeId);

      if (!selectedNode && selectedEdge) {
        const allowToRemove = selectedEdge.type ? !removeExceptionEdgesTypes.includes(selectedEdge.type) : true;

        if (allowToRemove) {
          removeEdge({
            id: selectedEdgeId,
            edgesDataSet,
          });

          setRemovedEdgeId(selectedEdgeId);
        }
      }

      if (selectedNode) {
        const allowToRemove = selectedNode.type ? !removeExceptionNodesTypes.includes(selectedNode.type) : true;

        if (allowToRemove) {
          removeNode({
            id: selectedNodeId,
            nodesDataSet,
            removeConnectedEdges: {
              network,
              edgesDataSet,
            },
          });

          setRemovedNodeId(selectedNodeId);
        }
      }
    }
  };

  return [removedNodeId, removedEdgeId];
};

export const minifyNodesProps = (nodes: NodeProps[]): NodeProps[] => {
  return nodes.map(({ id, label, type, x, y }) => ({ id, label, type, x, y }));
};

export const minifyEdgesProps = (edges: EdgeProps[]): EdgeProps[] => {
  return edges.map(({ id, label, type, from, to }) => ({ id, label, type, from, to }));
};

export const checkLoop = (edges: EdgeProps[]): boolean => {
  const fromToMap = edges.reduce((map: { [key: string]: string }, edge) => {
    map[edge.from] = edge.to;
    return map;
  }, {});

  return !!edges.find(edge => fromToMap[edge.to] === edge.from);
};

interface ChainMapItem {
  from: string[];
  to: string[];
  node: NodeProps;
}

type ChainMap = { [nodeId: string]: ChainMapItem };

interface ChainsResult {
  allChains: NodeProps[][];
  minChains: NodeProps[][];
  maxChains: NodeProps[][];
  minChainLength: number;
  maxChainLength: number;
  loop?: boolean;
}

export const resolveChains = (nodeId: string | number, nodes: CecNodeProps[], edges: CecEdgeProps[]): ChainsResult => {
  if (checkLoop(edges)) {
    return { minChains: [], maxChains: [], allChains: [], minChainLength: 0, maxChainLength: 0, loop: true };
  }

  const nodesFromToMap: ChainMap = nodes.reduce((map: ChainMap, node) => {
    map[node.id] = {
      node: node,
      from: edges.filter(edge => edge.to === node.id).map(edge => edge.from),
      to: edges.filter(edge => edge.from === node.id).map(edge => edge.to),
    };
    return map;
  }, {});

  const rootNode = Object.values(nodesFromToMap).find(node => !node.from.length)?.node;

  let connections: string[] = nodesFromToMap[nodeId]?.from || [];
  let chains: NodeProps[][] = connections.map(from => [nodesFromToMap[nodeId].node, nodesFromToMap[from].node]);

  while (connections.length) {
    const newPath: NodeProps[][] = [];
    const nextConnections: string[] = [];

    connections.forEach(from => {
      nextConnections.push(...nodesFromToMap[from].from);
    });

    nextConnections.forEach(from => {
      chains.forEach(chain => {
        if (nodesFromToMap[from].to.includes(chain[chain.length - 1]?.id as string)) {
          newPath.push([...chain, nodesFromToMap[from].node]);
        }
      });
    });

    chains = ArrayUtils.resolveUniqueItemsArray([...chains, ...newPath]);
    connections = nextConnections;
  }

  const completeChains = chains.filter(chain => chain.map(edge => edge.id).includes(rootNode?.id || 0));
  const chainsLength = completeChains.map(c => c.length);
  const maxChainLength = Math.max(...chainsLength, 0);
  const minChainLength = Math.min(...chainsLength, maxChainLength);

  return {
    minChains: completeChains.filter(c => c.length === minChainLength),
    maxChains: completeChains.filter(c => c.length === maxChainLength),
    allChains: completeChains,
    maxChainLength,
    minChainLength,
  };
};

export const checkIsTheSameChain = (from: NodeProps[], to: NodeProps[]): boolean => {
  if (from && to) {
    const fromKey = from.map(node => node.id).join('.');
    const toKey = to.map(node => node.id).join('.');

    return fromKey.includes(toKey) || toKey.includes(fromKey);
  }

  return false;
};

export const getEndEdges = (edgesArray: EdgeProps[]): EdgeProps[] => {
  const connections = edgesArray.map(item => item.from);

  return edgesArray.reduce((arr: EdgeProps[], edge) => {
    if (connections.indexOf(edge.to) === -1) arr.push(edge);
    return arr;
  }, []);
};

export const getMaximumChainLength = (edgesArray: CecEdgeProps[], nodesArray: CecNodeProps[]): number => {
  const maxChainValues: number[] = [];

  if (edgesArray) {
    getEndEdges(edgesArray).forEach((item: EdgeProps) => {
      maxChainValues.push(resolveChains(item.to, nodesArray, edgesArray).maxChainLength);
    });
  }

  return Math.max(...maxChainValues, 0);
};

export const getNodeDomPosition = (
  network: Network,
  id: number | string,
): {
  top: number;
  left: number;
  width: number;
  height: number;
  scale: number;
} | null => {
  const nodePosition = network.getBoundingBox(id);

  if (nodePosition) {
    const { top, left, right, bottom } = nodePosition;
    const nodeDomPosition = network.canvasToDOM({ x: left, y: top });
    const canvasScale = network.getScale();
    const nodeSize = calcNodeSizeByCanvasPosition({ top, left, right, bottom });

    return {
      top: nodeDomPosition.y || 0,
      left: nodeDomPosition.x || 0,
      width: nodeSize.width || 0,
      height: nodeSize.height || 0,
      scale: canvasScale || 0,
    };
  } else {
    return null;
  }
};
