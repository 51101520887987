import React from 'react';
import { useSelector } from 'react-redux';
import { PrizLoadingOverlay } from '@priz/shared/src/components/priz-loading-overlay/component';
import { UserContextSelectors } from '../../security/store/selectors';
import { AppSideNav } from '../../navigation/app-sidenav/component';
import { AppNavbar } from '../../navigation/app-navbar/component';
import { Outlet } from 'react-router-dom';
import { PageContainerWithAsideNav } from '../../content-containers/page-container-with-aside-nav/component';

export const UserProfile: React.FC = () => {
  const user = useSelector(UserContextSelectors.getCurrentUser);

  if (!user) return <PrizLoadingOverlay />;

  return (
    <PageContainerWithAsideNav useContentContainer>
      <AppSideNav currentUser={user} hideLogo />
      <AppNavbar padding={false} />
      <Outlet />
    </PageContainerWithAsideNav>
  );
};
