import React, { useCallback, useEffect, useState } from 'react';
import { Box, BoxProps, Divider, TextField } from '@mui/material';
import debounce from 'lodash/debounce';
import Cookies from 'js-cookie';
import { CookiesKey } from '../../services/cookies';

interface ReferralCodeInputProps {
  textFiledContainerProps?: BoxProps;
}

export const ReferralCodeInput: React.FC<ReferralCodeInputProps> = ({ textFiledContainerProps }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    initReferralCode();
  }, []);

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('visibilitychange', initReferralCode);
      window.addEventListener('focus', initReferralCode);
    }

    return () => {
      window.removeEventListener('visibilitychange', initReferralCode);
      window.removeEventListener('focus', initReferralCode);
    };
  }, [isDirty]);

  useEffect(() => {
    if (inputValue.length) debouncedSaveReferralCodeInCookies(inputValue);
  }, [inputValue]);

  const initReferralCode = () => {
    const urlReferralCode = new URLSearchParams(window.location.search).get('ref');
    const cookiesReferralCode = Cookies.get(CookiesKey.ReferralCode);

    if (!urlReferralCode && !cookiesReferralCode) {
      setIsVisible(true);
    }

    if (!isDirty && (urlReferralCode || cookiesReferralCode)) {
      setIsVisible(false);
    }

    if (urlReferralCode) {
      saveReferralCodeInCookies(urlReferralCode);
    }
  };

  const saveReferralCodeInCookies = (code: string) => {
    const hostname = window?.location?.hostname;
    const domain = hostname === 'localhost' ? hostname : '.priz.guru';

    Cookies.set(CookiesKey.ReferralCode, code, { expires: 30, domain });
  };

  const debouncedSaveReferralCodeInCookies = useCallback(debounce(saveReferralCodeInCookies, 500), []);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isDirty) setIsDirty(true);
    setInputValue(event.target.value.replace(/[^a-zA-Z0-9]+/g, ''));
  };

  if (!isVisible) {
    return <Box py={1} />;
  }

  return (
    <>
      <Box {...textFiledContainerProps}>
        <TextField value={inputValue} placeholder={'Referral code'} size={'small'} onChange={changeHandler} fullWidth />
      </Box>

      <Box mt={3} mb={2}>
        <Divider />
      </Box>
    </>
  );
};
