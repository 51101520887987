import { EntityCollectionStatus, EntityMap } from '../common/entity-collection-state';
import { Workspace } from '../workspace/workspace';
import { DefaultPlanFeatureSet, PlanFeatureSet } from '../workspace/feature-set';

export interface WorkspaceCollection {
  entities: EntityMap<Workspace>;

  currentFeatureSet: PlanFeatureSet;
  publicProjectFeatureSet: PlanFeatureSet;

  statuses: EntityCollectionStatus;
}

export const DefaultWorkspaceCollection: WorkspaceCollection = {
  entities: {},

  currentFeatureSet: DefaultPlanFeatureSet,
  publicProjectFeatureSet: DefaultPlanFeatureSet,

  statuses: {},
};
