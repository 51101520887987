import React from 'react';
import { OptionalComponentRankMap, SfmDiagramData, SfmRank } from '../../models/tools/sfm';
import { countSfmFunctionsConnections, countSfmRanksEfficiencies, resolveSfmNetworkData } from '../../utils/sfm';
import { Box, Grid, Typography } from '@mui/material';
import { PieChardCard } from '../pie-chart-card/component';
import { pgColorScheme } from '../../theme';
import { Circle } from '@mui/icons-material';
import { OperationMeritLabel } from '../operation-merit-label/component';
import { OperationPerfectnessLabel } from '../operation-perfectness-label/component';
import { Trans } from 'react-i18next';

export interface SfmSummaryProps {
  sfmDiagramData: SfmDiagramData;
  componentsRanksMap: OptionalComponentRankMap;
  sfmRank: SfmRank;
  activeVersionId?: string;
  compact?: boolean;
  useTooltips?: boolean;
  showAnnotations?: boolean;
}

export const SfmSummary: React.FC<SfmSummaryProps> = ({
  sfmDiagramData,
  componentsRanksMap,
  sfmRank,
  activeVersionId,
  useTooltips,
  showAnnotations,
}) => {
  const networkData = resolveSfmNetworkData(sfmDiagramData, activeVersionId);
  const ranksSum = countSfmRanksEfficiencies(componentsRanksMap);
  const connectionsSum = countSfmFunctionsConnections(networkData);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <PieChardCard
            cardTitle={'Operational Effectiveness – OE '}
            chartData={[
              { title: 'Effective', value: ranksSum.effective, color: pgColorScheme.blue },
              { title: 'Ineffective', value: ranksSum.ineffective, color: pgColorScheme.coral },
            ]}
            legendData={[
              { label: 'Effective', icon: <Circle fontSize={'small'} sx={{ fill: pgColorScheme.blue }} /> },
              { label: 'Ineffective', icon: <Circle fontSize={'small'} sx={{ fill: pgColorScheme.coral }} /> },
              {
                label: sfmRank?.operationMerit && (
                  <OperationMeritLabel operationMerit={sfmRank.operationMerit} useTooltip={useTooltips} />
                ),
              },
            ]}
            maxWidth={160}
            annotation={
              showAnnotations && (
                <Box maxWidth={500}>
                  <Typography variant={'body1'}>
                    <Trans>
                      Operational Effectiveness (OE) measures how well a system performs its intended functions relative
                      to the problems it encounters. It is calculated by dividing the sum of functional ranks by the sum
                      of problematic ranks.
                    </Trans>
                  </Typography>
                </Box>
              )
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <PieChardCard
            cardTitle={'Operational Perfectness - OP'}
            chartData={[
              { title: 'Basic functions', value: connectionsSum.basic, color: pgColorScheme.green },
              { title: 'Components', value: connectionsSum.components, color: pgColorScheme.gray500 },
              { title: 'Supersystems', value: connectionsSum.superSystems, color: pgColorScheme.gray700 },
            ]}
            legendData={[
              { label: 'Basic functions', icon: <Circle fontSize={'small'} sx={{ fill: pgColorScheme.green }} /> },
              { label: 'Components', icon: <Circle fontSize={'small'} sx={{ fill: pgColorScheme.gray500 }} /> },
              { label: 'Supersystems', icon: <Circle fontSize={'small'} sx={{ fill: pgColorScheme.gray700 }} /> },
              {
                label: sfmRank?.operationPerfectness && (
                  <OperationPerfectnessLabel
                    operationPerfectness={sfmRank.operationPerfectness}
                    useTooltip={useTooltips}
                  />
                ),
              },
            ]}
            maxWidth={160}
            annotation={
              showAnnotations && (
                <Box maxWidth={500}>
                  <Typography variant={'body1'}>
                    <Trans>
                      Operational Perfectness (OP) assesses the ideality of your system, revealing the ease with which
                      your system achieves desired functions relative to its components. It is calculated by dividing
                      the sum of basic functions by the sum of components with their weighting coefficients.
                    </Trans>
                  </Typography>
                </Box>
              )
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
