import { ToolType } from '../../models/tools';
import { PfmStep, PfmUtilization } from '../../models/tools/pfm';
import { ArrayUtils } from '../../utils/common';
import { AuditableEntity } from '../../models/common/auditable-entity';

interface RequiredToolProps extends AuditableEntity {
  id: number;
  type?: ToolType;
  parentId?: number;
}

const sortSfmToolsByPfmSteps = <T extends RequiredToolProps>(tools: T[], pfmSteps: PfmStep[]): T[] => {
  const orderedTools: T[] = [];

  const toolsMap = tools.reduce((map, tool) => {
    map[tool.id] = tool;
    return map;
  }, {} as { [toolId: number]: T });

  const sortedPfmSteps = pfmSteps.sort((a, b) => a.index - b.index);

  sortedPfmSteps.forEach(step => {
    orderedTools.push(toolsMap[step.sfmId]);
  });

  return orderedTools;
};

const sortToolsByParent = <T extends RequiredToolProps>(tools: T[], parentTool: T): T[] => {
  switch (parentTool.type) {
    case ToolType.PFM:
      const pfmSteps = (parentTool as Partial<PfmUtilization>)?.diagramData?.steps;
      if (pfmSteps) return sortSfmToolsByPfmSteps(tools, pfmSteps);
      break;
  }

  return tools.sort(ArrayUtils.sorterByDateCreated);
};

export const groupAndSortTools = <T extends RequiredToolProps>(tools: T[]): T[] => {
  const topLevelToolsMap: { [toolId: number]: T } = {};
  const childTools: { [parentId: number]: T[] } = {};
  const groupedTools: T[] = [];

  for (let i = 0; i < tools.length; i++) {
    const tool = tools[i];

    if (typeof tool.parentId !== 'undefined') {
      childTools[tool.parentId] = [...(childTools[tool.parentId] || []), tool];
    } else {
      topLevelToolsMap[tool.id] = tool;
    }
  }

  Object.values(topLevelToolsMap)
    .sort(ArrayUtils.sorterByDateCreated)
    .forEach(tool => {
      groupedTools.push(tool);

      if (childTools[tool.id]) {
        groupedTools.push(...sortToolsByParent(childTools[tool.id], tool));
      }
    });

  return groupedTools;
};
