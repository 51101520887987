import { handleActions } from 'redux-actions';
import { toStarted, toFailed, toSuccess } from '../../../../shared/store/action-creator';
import { PartnershipAction, PartnershipActionType } from '../actions';
import { DefaultPartnershipState, PartnershipState } from '../model';
import { PartnerProfile } from '@priz/shared/src/models/security/partner-profile';
import { IPublicUser, PublicUser } from '@priz/shared/src/models/user';
import { PlanLevel } from '@priz/shared/src/models/workspace';
import { IPartnerCommission, PartnerCommission } from '@priz/shared/src/models/security/partner-commission';

export const partnershipReducers = handleActions(
  {
    // LoadProfile

    [toStarted(PartnershipActionType.LoadProfile)]: (state: PartnershipState): PartnershipState => ({
      ...state,
      profile: {
        ...state.profile,
        statuses: { loading: true, loaded: false },
      },
    }),
    [toFailed(PartnershipActionType.LoadProfile)]: (state: PartnershipState): PartnershipState => ({
      ...state,
      profile: {
        ...state.profile,
        statuses: { loading: false, loaded: false, error: true },
      },
    }),
    [toSuccess(PartnershipActionType.LoadProfile)]: (
      state: PartnershipState,
      action: PartnershipAction,
    ): PartnershipState => ({
      ...state,
      profile: {
        data: action.payload as PartnerProfile,
        statuses: { loading: false, loaded: true, error: false },
      },
    }),

    // LoadReferrals

    [toStarted(PartnershipActionType.LoadReferrals)]: (state: PartnershipState): PartnershipState => ({
      ...state,
      referrals: {
        ...state.referrals,
        statuses: { loading: true, loaded: false },
      },
    }),
    [toFailed(PartnershipActionType.LoadReferrals)]: (state: PartnershipState): PartnershipState => ({
      ...state,
      referrals: {
        ...state.referrals,
        statuses: { loading: false, loaded: false, error: true },
      },
    }),
    [toSuccess(PartnershipActionType.LoadReferrals)]: (
      state: PartnershipState,
      action: PartnershipAction,
    ): PartnershipState => {
      const referrals = action.payload as IPublicUser[];

      return {
        ...state,
        referrals: {
          list: referrals.map(referral => new PublicUser(referral)),
          statuses: { loading: false, loaded: true, error: false },
        },
      };
    },

    // LoadReferralsPlanLevels

    [toStarted(PartnershipActionType.LoadReferralsPlanLevels)]: (
      state: PartnershipState,
      action: PartnershipAction,
    ): PartnershipState => ({
      ...state,
      referralsPlanLevels: {
        ...state.referralsPlanLevels,
        statuses: {
          ...state.referralsPlanLevels.statuses,
          [action.meta.referralId]: { loading: true, loaded: false },
        },
      },
    }),
    [toFailed(PartnershipActionType.LoadReferralsPlanLevels)]: (
      state: PartnershipState,
      action: PartnershipAction,
    ): PartnershipState => ({
      ...state,
      referralsPlanLevels: {
        ...state.referralsPlanLevels,
        statuses: {
          ...state.referralsPlanLevels.statuses,
          [action.meta.referralId]: { loading: false, loaded: false, error: true },
        },
      },
    }),
    [toSuccess(PartnershipActionType.LoadReferralsPlanLevels)]: (
      state: PartnershipState,
      action: PartnershipAction,
    ): PartnershipState => ({
      ...state,
      referralsPlanLevels: {
        map: {
          ...state.referralsPlanLevels.map,
          [action.meta.referralId]: action.payload as PlanLevel[],
        },
        statuses: {
          ...state.referralsPlanLevels.statuses,
          [action.meta.referralId]: { loading: false, loaded: true, error: false },
        },
      },
    }),

    // LoadReferralsCommissions

    [toStarted(PartnershipActionType.LoadReferralsCommissions)]: (
      state: PartnershipState,
      action: PartnershipAction,
    ): PartnershipState => ({
      ...state,
      referralsCommissions: {
        ...state.referralsCommissions,
        statuses: {
          ...state.referralsCommissions.statuses,
          [action.meta.referralId]: { loading: true, loaded: false },
        },
      },
    }),
    [toFailed(PartnershipActionType.LoadReferralsCommissions)]: (
      state: PartnershipState,
      action: PartnershipAction,
    ): PartnershipState => ({
      ...state,
      referralsCommissions: {
        ...state.referralsCommissions,
        statuses: {
          ...state.referralsCommissions.statuses,
          [action.meta.referralId]: { loading: false, loaded: false, error: true },
        },
      },
    }),
    [toSuccess(PartnershipActionType.LoadReferralsCommissions)]: (
      state: PartnershipState,
      action: PartnershipAction,
    ): PartnershipState => ({
      ...state,
      referralsCommissions: {
        map: {
          ...state.referralsCommissions.map,
          [action.meta.referralId]: ((action.payload as IPartnerCommission[]) || []).map(i => new PartnerCommission(i)),
        },
        statuses: {
          ...state.referralsCommissions.statuses,
          [action.meta.referralId]: { loading: false, loaded: true, error: false },
        },
      },
    }),
  },

  DefaultPartnershipState,
);
