import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { CecNodeProps } from '@priz/shared/src/models/tools/cec';
import { ElementsViewerContext } from '@priz/shared/src/components/elements-viewer/elements-viewer-context/component';
import {
  VisNodeViewer,
  VisNodeViewerBaseProps,
} from '@priz/shared/src/components/elements-viewer/vis-node-viewer/component';

export const CecNodeViewer: React.FC<VisNodeViewerBaseProps> = ({ nodesDataSet, ...rest }) => {
  const viewerContext = useContext(ElementsViewerContext);

  const contentResolver = (nodeId: string) => {
    const node = nodesDataSet.get(nodeId) as CecNodeProps;

    if (node) {
      return (
        <>
          <Typography variant={'subtitle2'}>
            <Trans>{node.type === 'first' ? 'Description' : 'Cause'}</Trans>
          </Typography>

          <Typography variant={'body2'} whiteSpace={'pre-line'}>
            {node.label || '-'}
          </Typography>

          {!!node.potentialSolution?.length && (
            <>
              <Typography variant={'subtitle2'} mt={2}>
                <Trans>Notes / Potential solution</Trans>
              </Typography>

              <Typography variant={'body2'} whiteSpace={'pre-line'}>
                {node.potentialSolution}
              </Typography>
            </>
          )}
        </>
      );
    }

    return null;
  };

  if (!viewerContext.enabled) {
    return null;
  }

  return <VisNodeViewer contentResolver={contentResolver} nodesDataSet={nodesDataSet} {...rest} />;
};
