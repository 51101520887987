import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Box, Grid, Tab, Tabs, TextField } from '@mui/material';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { ToolTypeDataMap } from '@priz/shared/src/data/tool-type-data-map';
import { ToolType } from '@priz/shared/src/models/tools';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectSelector, ToolUtilizationSelector } from '../../project/store/selectors';
import { NineWindowsUtilization } from '@priz/shared/src/models/tools/nine-windows';
import { NineWindowsUtilizationActions } from '../store/actions';
import debounce from 'lodash/debounce';
import { useTranslation, Trans } from 'react-i18next';
import { ToolTitle } from '../../tools/tool-title/component';
import { windowTypeMap } from '@priz/shared/src/data/nine-windows';
import { TitleContainer } from '../../react/modules/title-container/component';
import { TabPanel } from '../../react/elements/tab-panel/component';
import { ToolSubjectConclusion } from '../../tools/tool-subject-conclusion/component';
import { useStyles } from './styles';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { ToolUtilizationActions, ToolUtilizationActionType } from '../../project/store/actions/tools.actions';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { useToolTabs } from '../../tools/utils';

interface NineWindowsPageProps {
  utilizationId: number;
  projectId?: number;
}

export const NineWindowsPage: React.FC<NineWindowsPageProps> = ({ utilizationId, projectId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const styles = useStyles();

  const [activeTab, setActiveTab] = useToolTabs(0);

  const [id, setId] = useState<number>();
  const [utilization, setUtilization] = useState<NineWindowsUtilization>();
  const [manualUpdate, setManualUpdate] = useState(false);

  const nineWindowsUtilization = useSelector(ToolUtilizationSelector.getNineWindowsUtilization(utilizationId));
  const isProjectLocked = useSelector(ProjectSelector.isProjectLockedById(projectId));
  const isCompletenessUpdating = useSelector(
    ToolUtilizationSelector.isPendingByActionType(utilizationId, ToolUtilizationActionType.UpdateCompleteness),
  );

  const isDisabled = !!nineWindowsUtilization?.completed || isCompletenessUpdating || isProjectLocked;

  useEffect(() => {
    dispatch(ToolUtilizationActions.loadTool(utilizationId, projectId));
  }, [utilizationId, projectId]);

  useEffect(() => {
    if (nineWindowsUtilization && (!id || nineWindowsUtilization.id !== id)) {
      setId(nineWindowsUtilization.id);
      setUtilization(nineWindowsUtilization);
    }
  }, [nineWindowsUtilization]);

  const handleValueChange = (type: string) => (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setUtilization({ ...utilization, [type]: value });
    setManualUpdate(true);
  };

  const updateUtilization = useCallback(
    debounce((changedUtilization: NineWindowsUtilization) => {
      dispatch(NineWindowsUtilizationActions.update(changedUtilization));
    }, 500),
    [],
  );

  const conclusionChangeHandler = (conclusion: string) => {
    dispatch(
      NineWindowsUtilizationActions.update({
        ...utilization,
        conclusion,
      }),
    );
  };

  useEffect(() => {
    if (manualUpdate) {
      updateUtilization(utilization);
      setManualUpdate(false);
    }
  }, [utilization]);

  const handleChange = (_event, newValue: number) => {
    setActiveTab(newValue);
  };

  if (!utilization) {
    return null;
  }

  return (
    <>
      <ContentContainer display={'flex'} alignItems={'stretch'}>
        <Grid container direction={'column'} wrap={'nowrap'}>
          <Grid item xs={'auto'}>
            <TitleContainer>
              <PageTitleWithDocLink
                titleComponent={
                  <ToolTitle
                    initialTitle={'Think in space and time.'}
                    tool={nineWindowsUtilization}
                    preset={'large'}
                    editable={!isDisabled}
                  />
                }
                oneLiner={<Trans>Analyze a system and predict future development.</Trans>}
                docsUrl={ToolTypeDataMap[ToolType.NINE_WINDOWS].readMoreLink}
              />

              {!utilization.publicId && (
                <Box display="flex" justifyContent="flex-end">
                  <Tabs
                    orientation={'horizontal'}
                    variant={'scrollable'}
                    scrollButtons={'auto'}
                    allowScrollButtonsMobile={true}
                    value={activeTab}
                    onChange={handleChange}
                  >
                    <Tab label={<Trans>Diagram</Trans>} />
                    <Tab label={<Trans>Conclusion</Trans>} />
                  </Tabs>
                </Box>
              )}
            </TitleContainer>
          </Grid>

          <Grid item xs={true}>
            <TabPanel index={0} value={activeTab} stretch>
              <Box py={1} className={styles.scrollContainer}>
                <Box className={styles.toolContainer}>
                  <Grid
                    container
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'stretch'}
                    spacing={2}
                    className={styles.matrixRoot}
                  >
                    {Object.keys(windowTypeMap).map(type => (
                      <Grid item xs={4} key={windowTypeMap[type].order} className={styles.cell}>
                        <TextField
                          label={t(windowTypeMap[type].label)}
                          multiline
                          fullWidth
                          placeholder={windowTypeMap[type].order.toString()}
                          variant="outlined"
                          className={styles.textField}
                          InputLabelProps={{ shrink: true }}
                          onChange={handleValueChange(type)}
                          value={utilization[type]}
                          disabled={isDisabled}
                          InputProps={{
                            classes: {
                              root: styles.textFieldRoot,
                              input: styles.textFieldInput,
                            },
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </TabPanel>

            {!utilization.publicId && (
              <TabPanel index={1} value={activeTab} stretch>
                <ToolSubjectConclusion
                  onContentChange={conclusionChangeHandler}
                  initialContent={utilization.conclusion}
                  disabled={isDisabled}
                  placeholder={'Describe your conclusion that resulted form this 9 Windows tool'}
                />
              </TabPanel>
            )}
          </Grid>
        </Grid>
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={projectId}
          utilization={nineWindowsUtilization}
          disabled={isDisabled}
          workingFlowProps={{ nextVariant: 'ideas' }}
        />
      </ContentFooter>
    </>
  );
};
