import { ToolOverview, ToolType } from '../models/tools';
import { UnleashFeatureName } from '@priz/shared/src/services/unleash';

import { ReactComponent as FortyPrinciplesIcon } from '../assets/tools/40p-icon';
import { ReactComponent as RrrIcon } from '../assets/tools/rrr-icon';
import { ReactComponent as UimIcon } from '../assets/tools/uim-icon';
import { ReactComponent as CecIcon } from '../assets/tools/cec-icon';
import { ReactComponent as ApaIcon } from '../assets/tools/apa-icon';
import { ReactComponent as EbsIcon } from '../assets/tools/ebs-icon';
import { ReactComponent as NineWindowsIcon } from '../assets/tools/nine-windows-icon';
import { ReactComponent as FiveWhysIcon } from '../assets/tools/5-whys';
import { ReactComponent as PMapIcon } from '../assets/tools/p-map';
import { ReactComponent as SfmIcon } from '../assets/tools/sfm-icon';
import { ReactComponent as PfmIcon } from '../assets/tools/pfm';
import { ReactComponent as CftIcon } from '../assets/tools/cft-icon';

export const ToolTypeDataMap: { [type: string]: ToolOverview } = {
  [ToolType.TOOL_40_PRINCIPLES]: {
    name: 'Inventive principles',
    description: 'Find inventive solution by breaking a contradiction',
    type: ToolType.TOOL_40_PRINCIPLES,
    icon: FortyPrinciplesIcon,
    readMoreLink: 'https://www.priz.guru/knowledge-base/40-inventive-principles/',
  },
  [ToolType.RRM]: {
    name: 'Round - Robin Ranking',
    description: 'Make the best choice by ranking the available options',
    type: ToolType.RRM,
    icon: RrrIcon,
    readMoreLink: 'https://www.priz.guru/knowledge-base/round-robin-ranking/',
  },
  [ToolType.UIM]: {
    name: 'Urgency - Importance Matrix',
    description: 'Prioritize tasks',
    type: ToolType.UIM,
    icon: UimIcon,
    readMoreLink: 'https://www.priz.guru/knowledge-base/urgency-importance-matrix/',
  },
  [ToolType.FIVE_WHYS]: {
    name: '5 Whys',
    description: 'Find the root cause of a failure',
    type: ToolType.FIVE_WHYS,
    icon: FiveWhysIcon,
    readMoreLink: 'https://www.priz.guru/knowledge-base/five-whys/',
  },
  [ToolType.APA]: {
    name: 'Action Preventing Action',
    description: 'Find what a customer needs',
    type: ToolType.APA,
    icon: ApaIcon,
    readMoreLink: 'https://www.priz.guru/knowledge-base/action-preventing-action/',
  },
  [ToolType.EBS]: {
    name: 'Effective Brainstorming',
    description: 'Brainstorm and rank ideas',
    type: ToolType.EBS,
    icon: EbsIcon,
    readMoreLink: 'https://www.priz.guru/knowledge-base/effective-brainstorming/',
  },
  [ToolType.NINE_WINDOWS]: {
    name: '9 Windows',
    description: 'System Operator. Think in space and time. Predict future.',
    type: ToolType.NINE_WINDOWS,
    icon: NineWindowsIcon,
    readMoreLink: 'https://www.priz.guru/knowledge-base/nine-windows/',
  },
  [ToolType.CEC]: {
    name: 'Cause and Effect Chain',
    description: 'Search for a Root Cause using ramified tree',
    type: ToolType.CEC,
    icon: CecIcon,
    readMoreLink: 'https://www.priz.guru/knowledge-base/cause-and-effect-chain/',
  },
  [ToolType.P_MAP]: {
    name: 'Perception mapping',
    description: 'Map and analyze all perceptions on how to achieve the goal and find blockers',
    type: ToolType.P_MAP,
    icon: PMapIcon,
    readMoreLink: 'https://www.priz.guru/knowledge-base/perception-mapping',
  },
  [ToolType.SFM]: {
    name: 'Functional Modeling',
    description: 'Learn a system and identify the most Functional and Problematic components',
    type: ToolType.SFM,
    icon: SfmIcon,
    readMoreLink: 'https://www.priz.guru/knowledge-base/functional-modeling',
  },
  [ToolType.PFM]: {
    name: 'Process Functional Modeling',
    description: 'Analyze functionality of different operations of a process',
    type: ToolType.PFM,
    icon: PfmIcon,
    readMoreLink: 'https://www.priz.guru/knowledge-base/process-functional-modeling',
  },
  [ToolType.CFT]: {
    name: 'Change Flow Thinking',
    // description: 'Change Flow Thinking is a software tool that helps users manage and visualize change implementation processes. Creating interconnected steps in a flowchart enables systematic planning, discussion, and tracking. The tool prompts questions about the necessity and risks of each step, ensuring thorough analysis and smooth, error-free transitions. Ideal for effective change management and collaborative workflow optimization.',
    description: 'Manage and visualize change implementation processes.',
    type: ToolType.CFT,
    icon: CftIcon,
    readMoreLink: 'https://www.priz.guru/knowledge-base/#',
    features: [UnleashFeatureName.CFT],
  },
};
