import { IdeaComment } from '@priz/shared/src/models/idea';

interface CommentsSummaryResult {
  gainSum: number;
  costSum: number;
  riskSum: number;
  rating: number;
  totalCount: number;
  validCount: number;
  invalidCount: number;
}

export const checkIsCommentValid = (comment: IdeaComment): boolean => {
  return (
    comment.gainDescription?.length &&
    comment.costDescription?.length &&
    comment.riskDescription?.length &&
    typeof comment.gainValue === 'number' &&
    typeof comment.costValue === 'number' &&
    typeof comment.riskValue === 'number'
  );
};

export const resolveCommentsSummary = (comments: IdeaComment[]): CommentsSummaryResult => {
  const validComments = comments.filter(comment => checkIsCommentValid(comment));

  let gainSum = 0;
  let costSum = 0;
  let riskSum = 0;

  validComments.forEach(comment => {
    gainSum += comment.gainValue || 0;
    costSum += comment.costValue || 0;
    riskSum += comment.riskValue || 0;
  });

  const rating = gainSum ? Math.round((gainSum / (gainSum + costSum + riskSum)) * 100) : 0;

  return {
    gainSum,
    costSum,
    riskSum,
    rating,
    totalCount: comments.length,
    validCount: validComments.length,
    invalidCount: comments.length - validComments.length,
  };
};
