import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  rating: {
    color: pgColorScheme.white,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    display: 'flex',
    alignItems: 'center',
  },
  ratingNotDefined: {
    backgroundColor: pgColorScheme.gray400,
  },
  ratingGreen: {
    backgroundColor: pgColorScheme.green,
  },
  ratingBlue: {
    backgroundColor: pgColorScheme.blue,
  },
  ratingRed: {
    backgroundColor: pgColorScheme.coral,
  },
  button: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});
