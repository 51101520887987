export enum PublicScope {
  Overview = 'Overview',
  Team = 'Team',
  ProblemStatement = 'ProblemStatement',
  Tools = 'Tools',
  Ideas = 'Ideas',
  Solution = 'Solution',
}

export type ScopesMap = {
  [key in PublicScope]: boolean;
};

export const PublicScopeLabelMap: { [key in PublicScope]: string } = {
  [PublicScope.Overview]: 'Overview',
  [PublicScope.Team]: 'Team',
  [PublicScope.ProblemStatement]: 'Problem statement',
  [PublicScope.Tools]: 'Tools',
  [PublicScope.Ideas]: 'Ideas',
  [PublicScope.Solution]: 'Solution',
};
