import React from 'react';
import { ToolUtilization } from '@priz/shared/src/models/tools';
import { Grid } from '@mui/material';
import { RowToolCard } from '../row-tool-card/component';
import { ToolsUtils } from '../utils';

interface ToolsListProps {
  tools: ToolUtilization[];
  disabled?: boolean;
}

export const ToolsList: React.FC<ToolsListProps> = ({ tools, disabled }) => {
  const { parentTools, childrenToolsMap } = ToolsUtils.groupByLevel(tools);

  return (
    <Grid container direction="column" justifyContent="space-between" alignItems="stretch" spacing={2}>
      {parentTools.map(tool => {
        return (
          <Grid item key={tool.id} style={{ maxWidth: '100%' }}>
            <RowToolCard tool={tool} childrenToolsMap={childrenToolsMap} disabled={disabled} root />
          </Grid>
        );
      })}
    </Grid>
  );
};
