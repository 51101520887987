import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    flex: '1 0 0',
  },
  pageContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flex: '1 0 0',
    overflow: 'hidden',
    position: 'relative',
  },
  headerContainer: {
    width: '100%',
    flex: '0 0 auto',
  },
  workbenchContainer: {
    width: '100%',
    flex: '1 0 0',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
  },
});
