import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { SubscriptionStatus } from '@priz/shared/src/models/workspace';
import { Trans } from 'react-i18next';

const chipPropsMap: { [key in SubscriptionStatus]: Partial<ChipProps> } = {
  [SubscriptionStatus.Active]: {
    label: <Trans>active</Trans>,
    color: 'success',
  },
  [SubscriptionStatus.Unpaid]: {
    label: <Trans>unpaid</Trans>,
    color: 'error',
  },
  [SubscriptionStatus.Canceled]: {
    label: <Trans>canceled</Trans>,
    color: 'default',
  },

  [SubscriptionStatus.Incomplete]: {
    label: <Trans>incomplete</Trans>,
    color: 'default',
  },
  [SubscriptionStatus.IncompleteExpired]: {
    label: <Trans>incomplete expired</Trans>,
    color: 'default',
  },
  [SubscriptionStatus.Trialing]: {
    label: <Trans>trialing</Trans>,
    color: 'default',
  },
  [SubscriptionStatus.PastDue]: {
    label: <Trans>past due</Trans>,
    color: 'default',
  },
};

interface SubscriptionStatusIndicatorProps {
  status: SubscriptionStatus;
}

export const SubscriptionStatusIndicator: React.FC<SubscriptionStatusIndicatorProps> = ({ status }) => {
  const { label, ...rest } = chipPropsMap[status];

  return <Chip label={label || status} size={'small'} {...rest} />;
};
