import { IdRef } from '@priz/shared/src/models/common/id-ref';
import { AuditableEntity } from '@priz/shared/src/models/common/auditable-entity';
import { ApprovalActivityActionEnum } from './approval-activity-action.enum';
import { ApprovalActivityStatus } from './approval-activity-status.enum';

export class ApprovalActivity extends AuditableEntity {
  public id?: number;

  public project: IdRef;
  public status: ApprovalActivityStatus;
  public action: ApprovalActivityActionEnum;
  public comment?: string;

  constructor(json: any) {
    super(json);

    this.id = json.id;

    this.project = json.project;
    this.status = json.status;
    this.action = json.action;
    this.comment = json.comment;
  }
}
