import { ApprovalActivity } from './approval-activity';
import {
  DefaultEntityCollectionStatus,
  EntityCollectionStatus,
} from '@priz/shared/src/models/common/entity-collection-state';

export class ApprovalActivityCollection {
  public entities: { [id: number]: ApprovalActivity };
  public loadingByProjectId: { [id: number]: boolean };
  public statuses: EntityCollectionStatus;
}

export const DefaultApprovalActivityCollection: ApprovalActivityCollection = {
  entities: {},
  loadingByProjectId: {},
  statuses: DefaultEntityCollectionStatus,
};
