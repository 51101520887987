export const isStepCompleted = (
  index: number,
  isContradictionCompleted: boolean,
  isImprovingParameterCompleted: boolean,
  isWorseningParameterCompleted: boolean,
): boolean => {
  return (
    (index === 0 && isContradictionCompleted) ||
    (index === 1 && isImprovingParameterCompleted) ||
    (index === 2 && isWorseningParameterCompleted)
  );
};

export const isPrevStepCompleted = (
  index: number,
  isContradictionCompleted: boolean,
  isImprovingParameterCompleted: boolean,
  isWorseningParameterCompleted: boolean,
): boolean => {
  return (
    index === 0 ||
    (index === 1 && isContradictionCompleted) ||
    (index === 2 && isImprovingParameterCompleted) ||
    (index === 3 && isWorseningParameterCompleted)
  );
};
