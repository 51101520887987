import { environment } from '@priz/shared/src/environments/environment';
import axios, { AxiosResponse } from 'axios';
import { MatrixElement } from '@priz/shared/src/models/tools/forty-principles/matrix-element';

const loadMatrixElements = (): Promise<MatrixElement[]> =>
  axios
    .get(`${environment.apiDomain}/v1/api/40p/matrix-elements`)
    .then((response: AxiosResponse<MatrixElement[]>) => response.data);

export const MatrixElementService = {
  loadMatrixElements,
};
