import * as React from "react";

export const ReactComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="320" height="320" viewBox="0 0 320 320" fill="#3C3C3C" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M228.453 228.453C231.968 224.939 237.667 224.939 241.181 228.453L317.368 304.639C320.882 308.154 320.882 313.853 317.368 317.367V317.367C313.853 320.882 308.154 320.882 304.64 317.367L228.453 241.181C224.939 237.667 224.939 231.968 228.453 228.453V228.453Z"/>
    <path d="M95.636 95.364C99.1508 91.8492 104.849 91.8492 108.364 95.364L184.55 171.55C188.065 175.065 188.065 180.763 184.55 184.278V184.278C181.035 187.793 175.337 187.793 171.822 184.278L95.636 108.092C92.1213 104.577 92.1213 98.8787 95.636 95.364V95.364Z"/>
    <path d="M95.364 184.364C91.8492 180.849 91.8492 175.151 95.364 171.636L171.55 95.4499C175.065 91.9352 180.763 91.9352 184.278 95.4499V95.4499C187.793 98.9646 187.793 104.663 184.278 108.178L108.092 184.364C104.577 187.879 98.8787 187.879 95.364 184.364V184.364Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M140 262C207.379 262 262 207.379 262 140C262 72.6213 207.379 18 140 18C72.6213 18 18 72.6213 18 140C18 207.379 72.6213 262 140 262ZM140 280C217.32 280 280 217.32 280 140C280 62.6801 217.32 0 140 0C62.6801 0 0 62.6801 0 140C0 217.32 62.6801 280 140 280Z"/>
  </svg>
);
