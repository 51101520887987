import { createStyles, makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    templateTypeContainer: {
      color: pgColorScheme.gray600,
      borderWidth: 4,
      borderStyle: 'solid',
      borderColor: pgColorScheme.gray600,
      borderRadius: 20,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
    },

    [theme.breakpoints.down('md')]: {
      templateTypeContainer: {
        borderWidth: 3,
        borderRadius: 10,
      },
    },
  }),
);
