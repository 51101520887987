import axios, { AxiosResponse } from 'axios';
import { AxiosRequestParams } from '../axios';
import { UserProjectSettings, UserProjectSettingsResponse } from '../../models/user/user-project-settings';

export interface UserProjectSettingsApiGetProps {
  projectId: number;
  renewTokenErrorCallback?: () => void;
}

const get = (props?: UserProjectSettingsApiGetProps): Promise<UserProjectSettingsResponse> => {
  const { projectId, renewTokenErrorCallback } = props || {};

  const axiosParams: AxiosRequestParams = {
    params: {
      renewTokenErrorCallback,
    },
  };

  return axios
    .get(`/v1/api/project/${projectId}/user-settings`, axiosParams)
    .then((response: AxiosResponse<UserProjectSettingsResponse>) => response.data);
};

export interface UserProjectSettingsApiUpdateProps {
  projectId: number;
  settings: UserProjectSettings;
  renewTokenErrorCallback?: () => void;
}

const update = (props: UserProjectSettingsApiUpdateProps): Promise<UserProjectSettingsResponse> => {
  const { projectId, settings, renewTokenErrorCallback } = props;

  const axiosParams: AxiosRequestParams = {
    params: {
      renewTokenErrorCallback,
    },
  };

  return axios
    .put(`/v1/api/project/${projectId}/user-settings`, { settings }, axiosParams)
    .then((response: AxiosResponse<UserProjectSettingsResponse>) => response.data);
};

export const UserProjectSettingsApi = {
  get,
  update,
};
