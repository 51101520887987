import { createPgAction } from '../../../shared/store/action-creator';
import { MeetingApi } from '../../services';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { IAgenda, IAttendee, IMeeting, Meeting } from '../model';
import { FluxStandardAction } from 'flux-standard-action';
import { NavigateFunction } from 'react-router-dom';

export enum MeetingActionType {
  FetchAll = 'meetings/fetch',
  Fetch = 'meeting/fetch',
  Create = 'meeting/create',
  Update = 'meeting/update',
  FullUpdate = 'meeting/full-update',
  Release = 'meeting/release',
  Start = 'meeting/start',
  Finish = 'meeting/finish',
  Delete = 'meeting/delete',
}

export interface MeetingActionMeta {
  projectId: number;
  meetingId: number;
}

export interface MeetingFullUpdatePayload {
  meeting: IMeeting;
  attendees: IAttendee[];
  agenda: IAgenda[];
}

export type MeetingActionPayload = IMeeting | IMeeting[];

export type MeetingAction = FluxStandardAction<string, MeetingActionPayload, MeetingActionMeta>;
export type MeetingFullUpdateAction = FluxStandardAction<string, MeetingFullUpdatePayload, MeetingActionMeta>;

const getAll = createPgAction(MeetingActionType.FetchAll, MeetingApi.getAll, projectId => ({ projectId }));

const get = createPgAction(MeetingActionType.Fetch, MeetingApi.get, (projectId, meetingId) => ({
  projectId,
  meetingId,
}));

const create = createPgAction(
  MeetingActionType.Create,
  MeetingApi.create,
  (projectId, _command, navigate?: NavigateFunction) => ({ projectId, navigate }),
  (payload: Meeting, { projectId, navigate }) => {
    AnalyticsService.track(AnalyticsEvent.MEETING_CREATED, {
      project_id: projectId,
    });

    if (navigate) {
      navigate(['/project', projectId, 'meeting', payload.id].join('/'));
    }
  },
);

const update = createPgAction(
  MeetingActionType.Update,
  MeetingApi.update,
  (projectId, meetingId) => ({ projectId, meetingId }),
  (_payload, { projectId, meetingId }) => {
    AnalyticsService.track(AnalyticsEvent.MEETING_UPDATED, {
      project_id: projectId,
      meeting_id: meetingId,
    });
  },
);

const fullUpdate = createPgAction(
  MeetingActionType.FullUpdate,
  MeetingApi.fullUpdate,
  (projectId, meetingId) => ({ projectId, meetingId }),
  (_payload, { projectId, meetingId }) => {
    AnalyticsService.track(AnalyticsEvent.MEETING_UPDATED, {
      project_id: projectId,
      meeting_id: meetingId,
    });
  },
);

const release = createPgAction(
  MeetingActionType.Release,
  MeetingApi.release,
  (projectId, meetingId) => ({ projectId, meetingId }),
  (_payload, { projectId, meetingId }) => {
    AnalyticsService.track(AnalyticsEvent.MEETING_RELEASED, {
      project_id: projectId,
      meeting_id: meetingId,
    });
  },
);

const start = createPgAction(
  MeetingActionType.Start,
  MeetingApi.start,
  (projectId, meetingId) => ({ projectId, meetingId }),
  (_payload, { projectId, meetingId }) => {
    AnalyticsService.track(AnalyticsEvent.MEETING_STARTED, {
      project_id: projectId,
      meeting_id: meetingId,
    });
  },
);

const finish = createPgAction(
  MeetingActionType.Finish,
  MeetingApi.finish,
  (projectId, meetingId) => ({ projectId, meetingId }),
  (_payload, { projectId, meetingId }) => {
    AnalyticsService.track(AnalyticsEvent.MEETING_FINISHED, {
      project_id: projectId,
      meeting_id: meetingId,
    });
  },
);

const doDelete = createPgAction(
  MeetingActionType.Delete,
  MeetingApi.delete,
  (projectId, meetingId, navigate?: NavigateFunction) => ({ projectId, meetingId, navigate }),
  (_payload, { projectId, meetingId, navigate }) => {
    AnalyticsService.track(AnalyticsEvent.MEETING_DELETED, {
      project_id: projectId,
      meeting_id: meetingId,
    });

    if (navigate) {
      navigate(['/project', projectId, 'meetings'].join('/'));
    }
  },
);

export const MeetingActions = {
  getAll,
  get,
  create,
  update,
  fullUpdate,
  release,
  start,
  finish,
  delete: doDelete,
};
