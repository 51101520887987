import React from 'react';
import { AIChat } from '../ai-chat/component';
import { Dialog, DialogProps } from '@mui/material';
import { useStyles } from './styles';

interface AIChatDialogProps extends DialogProps {
  projectId: number;
  open: boolean;
  onDialogClose?: () => void;
}

export const AIChatDialog: React.FC<AIChatDialogProps> = ({ projectId, onDialogClose, ...rest }) => {
  const styles = useStyles();

  return (
    <Dialog PaperProps={{ className: styles.dialogPaper }} maxWidth={'lg'} fullWidth onClose={onDialogClose} {...rest}>
      <AIChat projectId={projectId} onClose={onDialogClose} />
    </Dialog>
  );
};
