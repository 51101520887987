import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { pgColorScheme } from '@priz/shared/src/theme';

import loginImageUrl from '../../../assets/img/login-bg.svg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      padding: 20,
      boxSizing: 'border-box',
      overflow: 'auto',
      background: 'linear-gradient(225deg, #68DBF2 0.01%, #1198D2 100%)',
    },
    container: {
      width: '100%',
      maxWidth: 1000,
      margin: '0 auto',
    },
    backgroundWrap: {
      backgroundPosition: 'left bottom',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '46.8%',
      borderRadius: 'inherit',
    },
    contentWrapper: {
      width: 'calc(50% - 10px)',
      marginLeft: 'auto',
      minHeight: 560,
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      color: pgColorScheme.white,
      textAlign: 'center',
    },
    backToWebsiteLink: {
      'color': pgColorScheme.white,
      'display': 'flex',
      'alignItems': 'center',
      'textDecoration': 'none',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
    backToWebsiteIcon: {
      color: pgColorScheme.white,
      width: 16,
      height: 16,
      marginRight: '.5em',
    },
    backToWebsiteText: {
      lineHeight: 1,
    },
    flexCenterWrap: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },

    [theme.breakpoints.up('md')]: {
      backgroundWrap: {
        backgroundImage: `url(${loginImageUrl})`,
      },
    },

    [theme.breakpoints.down('md')]: {
      contentWrapper: {
        width: '100%',
      },
    },

    [theme.breakpoints.down('sm')]: {
      contentWrapper: {
        minHeight: 0,
      },
    },
  }),
);
