import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, LinearProgress, Tooltip, Typography } from '@mui/material';
import { SideNavItem } from '../../shared/section-sidenav-item/component';
import { Trans, useTranslation } from 'react-i18next';
import { TaskActions } from '../store/actions/task.actions';
import { TaskSelectors } from '../store/selectors/task.selectors';
import { TaskStatus } from '@priz/shared/src/models/task';

import { ReactComponent as ClipboardIcon } from '../../../assets/icons/clipboard.svg';

export interface TasksMenuItemProps {
  projectId: number;
  onClick?: () => void;
  id?: string;
}

export const TasksMenuItem: React.FC<TasksMenuItemProps> = ({ projectId, onClick, id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tasks = useSelector(TaskSelectors.getAllByProjectId(projectId));
  const isProgressAvailable = useSelector(TaskSelectors.isProgressAvailableByProjectId(projectId));
  const percentageComplete = useSelector(TaskSelectors.getPercentageCompleteByProjectId(projectId));

  const tasksWithDueDate = tasks.filter(t => !!t.dueDate);
  const doneTasks = tasksWithDueDate.filter(t => t.status === TaskStatus.Done);

  useEffect(() => {
    dispatch(TaskActions.fetchAllByProjectId(projectId));
  }, []);

  const clickHandler = () => {
    if (onClick) onClick();
  };

  const renderTitle = () => {
    return <Trans>Tasks</Trans>;
  };

  const renderTitleWithProgress = () => {
    return (
      <Tooltip title={`${t('The project is')} ${Math.round(percentageComplete)}% ${t('complete...')}`} arrow={true}>
        <Grid container flexDirection={'column'}>
          <Grid item xs={12}>
            <Grid container wrap={'nowrap'} alignItems={'baseline'} spacing={1}>
              <Grid item>{renderTitle()}</Grid>
              <Grid item>
                <Typography component={'span'} variant={'body2'}>
                  {doneTasks.length}/{tasksWithDueDate.length}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box mt={0.5}>
              <LinearProgress
                variant={'determinate'}
                color={percentageComplete === 100 ? 'success' : 'secondary'}
                value={percentageComplete}
              />
            </Box>
          </Grid>
        </Grid>
      </Tooltip>
    );
  };

  return (
    <SideNavItem
      id={id}
      title={isProgressAvailable ? renderTitleWithProgress() : renderTitle()}
      icon={<ClipboardIcon />}
      navigate={['/project', projectId, 'tasks']}
      variant={'orange'}
      onClick={clickHandler}
    />
  );
};
