import { IdRef } from '../../common/id-ref';

export class MatrixElement {
  public id?: number;
  public improvingParameter: IdRef;
  public worseningParameter: IdRef;
  public principles?: IdRef[] | string;
  public createdBy: IdRef;
  public dateCreated: Date;
  public updatedBy?: IdRef;
  public lastUpdated?: Date;

  constructor(json: MatrixElement) {
    // TODO: MatrixElement.principles is IdRef[], but got string
    const principlesCopy =
      typeof json?.principles !== 'undefined' ? JSON.parse(JSON.stringify(json.principles)) : undefined;
    const principles =
      typeof principlesCopy === 'string' ? principlesCopy.split(',').map(id => ({ id: +id })) : principlesCopy;

    this.id = json.id;
    this.improvingParameter = json.improvingParameter;
    this.worseningParameter = json.worseningParameter;
    this.principles = principles;
    this.createdBy = json.createdBy;
    this.dateCreated = json.dateCreated && new Date(json.dateCreated);
    this.updatedBy = json.updatedBy;
    this.lastUpdated = json.lastUpdated && new Date(json.lastUpdated);
  }
}
