import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  ['@keyframes fadeToggle']: {
    '0%, 65%, 100%': {
      opacity: 0,
    },
    '15%, 50%': {
      opacity: 1,
    },
  },
  root: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  svg: {
    display: 'block',
    maxHeight: '100%',
    maxWidth: '100%',
    height: 'auto',
    width: 'auto',

    ['& .assistant-lightbulb, & .assistant-circles']: {
      opacity: 0,
    },
  },
  loading: {
    ['& .assistant-circles']: {
      opacity: 1,

      ['& .assistant-circle']: {
        animationName: '$fadeToggle',
        animationDuration: '3s',
        animationTimingFunction: 'linear',
        animationFillMode: 'both',
        animationIterationCount: 'infinite',

        ['&:nth-child(1)']: {
          animationDelay: '0s',
        },
        ['&:nth-child(2)']: {
          animationDelay: '0.5s',
        },
        ['&:nth-child(3)']: {
          animationDelay: '1s',
        },
      },
    },
  },
  hint: {
    ['& .assistant-lightbulb']: {
      opacity: 1,
    },
  },
});
