import React from 'react';
import { useStyles } from './styles';
import { Box, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { useSelector } from 'react-redux';

import { ButtonCreateTask } from '../../react/elements/button-create-task/component';
import { ProjectSelector } from '../../project/store/selectors';

interface EmptyTasksListProps {
  projectId: number;
}

export const EmptyTasksList: React.FC<EmptyTasksListProps> = ({ projectId }) => {
  const styles = useStyles();

  const isProjectLocked = useSelector(ProjectSelector.isProjectLockedById(projectId));

  return (
    <Box className={styles.root}>
      <Box mt={8} className={styles.title}>
        <Typography variant={'subtitle1'} component={'span'}>
          <Trans>You still do not have any tasks in your project.</Trans>
          <br />
          <Trans>Click "ADD NEW TASK" button to create a task.</Trans>
        </Typography>
      </Box>

      <Box mt={4}>
        <ButtonsWrap justifyContent={'center'}>
          <ButtonCreateTask
            projectId={projectId}
            text={'Add new task'}
            startIcon={<div>+</div>}
            disabled={isProjectLocked}
          />
        </ButtonsWrap>
      </Box>
    </Box>
  );
};
