import { EliminationVariants } from './elimination';
import { AuditableEntity, IAuditableEntity } from '@priz/shared/src/models/common/auditable-entity';
import { Identifiable } from '@priz/shared/src/models/common/entity-collection-state';
import { IdRef } from '@priz/shared/src/models/common/id-ref';
import { OperationMerit, OperationPerfectness, OptionalComponentRank } from '@priz/shared/src/models/tools/sfm';

export enum ComponentProblematicType {
  Harmful = 'Harmful',
  MostHarmful = 'MostHarmful',
  Ineffective = 'Ineffective',
  MostIneffective = 'MostIneffective',
  PureProblematic = 'PureProblematic',
  Insufficient = 'Insufficient',
  Excessive = 'Excessive',
  Skew = 'Skew',
  Conflict = 'Conflict',
  Functionality = 'Functionality',
  NoImpact = 'NoImpact',
  NotDefined = 'NotDefined',
}

export interface ProblematicComponentPreview {
  id: string;
  hasRecommendations: boolean;
}

export interface ProblematicComponentPreviewsMap {
  [componentId: string]: ProblematicComponentPreview;
}

export interface SfmComponentEliminationEffect {
  problematicTypes: ComponentProblematicType[];
  eliminationVariants: EliminationVariants;
}

export interface SfmComponentsEliminationEffectMap {
  [nodeId: string | number]: SfmComponentEliminationEffect;
}

export enum ComponentRecommendationType {
  Verify = 'Verify',
  Eliminate = 'Eliminate',
  Mitigate = 'Mitigate',
  Resolve = 'Resolve',
}

export interface ComponentImprovementRecommendation {
  type: ComponentRecommendationType;
  done: boolean;
}

export interface ComponentImprovementIteration {
  componentId?: string;

  problematicType: ComponentProblematicType;
  recommendations: ComponentImprovementRecommendation[];
  completed: boolean;

  mostUsefulFunctionId: string;
  mostHarmfulFunctionId: string;

  initialComponentRank: OptionalComponentRank;
  resultComponentRank: OptionalComponentRank;

  initialMerit: OperationMerit;
  resultMerit: OperationMerit;

  initialPerfectness: OperationPerfectness;
  resultPerfectness: OperationPerfectness;

  dateCreatedMs: number;
  dateCompletedMs: number;
}

export interface ComponentImprovementIterationsMap {
  [iterationId: string]: ComponentImprovementIteration;
}

export interface ISfmComponentImprovement extends IAuditableEntity, Identifiable {
  workspace: IdRef;
  project: IdRef;
  utilization: IdRef;
  versionId: string;
  componentId: string;
  iterations: ComponentImprovementIterationsMap;
}

export class SfmComponentImprovement extends AuditableEntity implements ISfmComponentImprovement {
  public id: number;
  public workspace: IdRef;
  public project: IdRef;
  public utilization: IdRef;
  public versionId: string;
  public componentId: string;
  public iterations: ComponentImprovementIterationsMap;

  constructor(json: ISfmComponentImprovement) {
    super(json);

    this.id = json.id;
    this.workspace = json.workspace;
    this.project = json.project;
    this.utilization = json.utilization;
    this.versionId = json.versionId;
    this.componentId = json.componentId;
    this.iterations = json.iterations;
  }
}

export interface SfmComponentImprovementMap {
  [componentId: string]: SfmComponentImprovement;
}
