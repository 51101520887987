import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IdeasCommentsActions, ProjectIdeaActions } from '../store';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import { ProjectSelector } from '../../project/store/selectors';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm/rrm-goal-type.enum';
import { TitleContainer } from '../../react/modules/title-container/component';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { IdeasSelectors } from '../store';
import { ArrayUtils, SortDirection } from '@priz/shared/src/utils/common';
import { Idea } from '@priz/shared/src/models/idea/idea';
import { EntityMap } from '@priz/shared/src/models/common/entity-collection-state';
import { ProjectAssigneeSelectors } from '../../assignment/store/selectors/project-assignee.selectors';
import { WorkspaceMembersActions } from '../../workspace/store/actions';
import { ProjectAssigneeActions } from '../../assignment/store/actions';
import { resolveIdeaAuthor } from '@priz/shared/src/utils/ideas/ideas-utils';
import { EditableIdeaCard } from '../editable-idea-card/comonent';
import { HashHighlightContainer } from '../../hash-highlight-container/component';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { StartRankingButton } from '../../rrm/start-ranking-button/component';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { NewIdeaButton } from '../new-idea-button';
import { EmptyIdeasList } from '../empty-ideas-list/component';
import { ToolUtilizationActions } from '../../project/store/actions/tools.actions';

export interface IdeaManagerProps {
  projectId: number;
}

export const IdeaManager: React.FC<IdeaManagerProps> = ({ projectId }) => {
  const dispatch = useDispatch();
  const ideasMap: EntityMap<Idea> = useSelector(IdeasSelectors.getAllByProjectId(projectId));
  const isProjectLocked = useSelector(ProjectSelector.isProjectLockedById(projectId));

  const ideas = ideasMap && Object.values(ideasMap);
  const assignees = useSelector(ProjectAssigneeSelectors.getProjectAssignees(projectId));

  useEffect(() => {
    dispatch(ProjectIdeaActions.loadProjectIdeas(projectId));
    dispatch(IdeasCommentsActions.getAllIdeasCommentsForProject(projectId));
    dispatch(ProjectAssigneeActions.fetchAll());
    dispatch(WorkspaceMembersActions.loadAll());
    dispatch(ToolUtilizationActions.loadTools(projectId));
  }, [projectId]);

  if (!ideas) {
    return <LoadingOverlay loading={true} backdropStyles={{ backgroundColor: 'transparent' }} />;
  }

  return (
    <>
      <ContentContainer id={'idea-manager'}>
        <TitleContainer>
          <PageTitleWithDocLink
            title={<Trans>Collection of your generated ideas</Trans>}
            oneLiner={
              <Trans>
                This list contains all the ideas you have generated during your inventive work on the project.
              </Trans>
            }
            docsUrl={'https://www.priz.guru/knowledge-base/ideas-manager/'}
          />

          <ButtonsWrap>
            {ideas.length > 0 && (
              <NewIdeaButton
                projectId={projectId}
                text={'Add idea'}
                startIcon={<div>+</div>}
                disabled={isProjectLocked}
              />
            )}

            {ideas.length > 2 && (
              <StartRankingButton
                projectId={projectId}
                goalType={RrmGoalType.IDEA}
                itemsCount={ideas.length}
                buttonText={'Rank all ideas'}
                buttonProps={{
                  disabled: isProjectLocked,
                }}
              />
            )}
          </ButtonsWrap>
        </TitleContainer>

        {ideas.length > 0 ? (
          <>
            {ideas.sort(ArrayUtils.sorterByParam('latestRankingScore', SortDirection.DESC)).map((idea, key) => (
              <HashHighlightContainer
                key={key}
                id={`idea-${idea.id}`}
                scrollContainerId={'idea-manager'}
                scrollOffset={15}
              >
                <EditableIdeaCard
                  idea={idea}
                  projectId={projectId}
                  disabled={isProjectLocked}
                  assignee={resolveIdeaAuthor(idea, assignees)}
                />
              </HashHighlightContainer>
            ))}
          </>
        ) : (
          <EmptyIdeasList projectId={projectId} />
        )}
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={projectId}
          workingFlowProps={{
            backVariant: 'tools',
            nextVariant: 'propose-solution',
            hideIdeaButton: true,
          }}
        />
      </ContentFooter>
    </>
  );
};
