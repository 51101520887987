import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material';

export const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    transition: 'background-color .15s ease',
    borderRadius: 4,
    height: 24,
    padding: '4px 10px',
    cursor: 'pointer',
    boxSizing: 'border-box',
    maxWidth: '100%',
  },
  text: {
    lineHeight: 1,
    fontWeight: 700,
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rootColorProps: (props: { color: string }) => ({
    'backgroundColor': alpha(props.color, 0.1),
    'color': alpha(props.color, 0.7),

    '&:hover': {
      backgroundColor: alpha(props.color, 0.175),
    },
  }),
});
