import React from 'react';
import { Task } from '@priz/shared/src/models/task';
import { Box, Chip, Grid, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { StatusButton } from '../status-button/component';
import { TaskAssigneeButton } from '../../assignment/task-assignee-button/component';
import { pgColorScheme } from '@priz/shared/src/theme';
import { DueDateButton } from '../due-date-button/component';

interface TaskQuickMenuProps {
  task: Task;
  disabled?: boolean;
}

export const TaskQuickMenu: React.FC<TaskQuickMenuProps> = ({ task, disabled }) => {
  return (
    <Grid container columnSpacing={2} alignItems={'center'}>
      <Grid item>
        <Box display={'flex'} alignItems={'baseline'}>
          <StatusButton task={task} disabled={disabled} />
        </Box>
      </Grid>

      <Grid item maxWidth={'100%'}>
        <TaskAssigneeButton task={task} size={'small'} disabled={disabled} />
      </Grid>

      <Grid item>
        <Grid container alignItems={'baseline'}>
          <Grid item>
            <Box mr={1} style={{ color: pgColorScheme.placeholder }}>
              <Typography variant={'body2'} component={'span'}>
                <Trans>Due date</Trans>:
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <DueDateButton task={task} disabled={disabled} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Box display={'flex'} alignItems={'baseline'}>
          <Box mr={1} style={{ color: pgColorScheme.placeholder }}>
            <Typography variant={'body2'} component={'span'}>
              <Trans>Solving the problem?</Trans>
            </Typography>
          </Box>

          <Chip
            size={'extra_small'}
            color={task.solvingProblem ? 'success' : 'default'}
            label={<Trans>{task.solvingProblem ? 'Yes' : 'No'}</Trans>}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
