import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { ReactHookFormText } from '../../react/form-elements';
import { validationPatterns, validatorRules } from '@priz/shared/src/utils/form';
import { useForm } from 'react-hook-form';
import { WorkspaceMembersActions } from '../store/actions';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { WorkspaceSelectors } from '../store/selectors';
import { TeamBlockerMessage } from '../../react/modules/paywall/team-blocker-message/component';
import { WorkspaceMembersSelectors } from '../store/selectors/workspace-members.selectors';
import { LoadingButton } from '@mui/lab';

interface InvitationDialogProps {
  isOpen: boolean;
  onClose?: () => void;
  disabled?: boolean;
}

export const InvitationDialog: React.FC<InvitationDialogProps> = ({ isOpen, onClose, disabled }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [closeOnInvite, setCloseOnInvite] = useState(false);

  const isSeatsLimitHit = useSelector(WorkspaceSelectors.isSeatsLimitHit());
  const isCreating = useSelector(WorkspaceMembersSelectors.isCreating);
  const isCreated = useSelector(WorkspaceMembersSelectors.isCreated);
  const isFailed = useSelector(WorkspaceMembersSelectors.isFailed);
  const errorCode = useSelector(WorkspaceMembersSelectors.errorCode);

  const { handleSubmit, reset, control } = useForm({
    shouldFocusError: false,
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    setOpen(isOpen);

    if (isOpen) {
      dispatch(WorkspaceMembersActions.clearErrors());
      reset();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isCreated && closeOnInvite) {
      setCloseOnInvite(false);
      handleClose();
    }
  }, [isCreated, closeOnInvite]);

  const handleInvite = () => {
    handleSubmit(formData => {
      dispatch(WorkspaceMembersActions.invite(formData.email));
      setCloseOnInvite(true);
    })();
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle>
        <Trans>Invite new member to the workspace</Trans>
      </DialogTitle>

      <DialogContent>
        {isSeatsLimitHit && <TeamBlockerMessage mb={2} />}

        {isFailed && errorCode && errorCode === 402 && (
          <Box mb={2}>
            <Alert severity={'error'}>
              <Trans>Members limit reached</Trans>
            </Alert>
          </Box>
        )}

        <ReactHookFormText
          placeholder={'Email'}
          name={'email'}
          control={control}
          rules={{
            ...validatorRules.required(),
            ...validatorRules.pattern(validationPatterns.email, 'A valid email is required'),
          }}
          disabled={disabled}
        />
      </DialogContent>

      <DialogActions>
        <ButtonsWrap justifyContent={'flex-end'}>
          <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose}>
            <Trans>Cancel</Trans>
          </Button>

          <LoadingButton
            variant={'pg_rounded'}
            color={'pg_orange_solid'}
            onClick={handleInvite}
            disabled={isCreating || disabled}
            loading={isCreating}
          >
            <Trans>Invite</Trans>
          </LoadingButton>
        </ButtonsWrap>
      </DialogActions>
    </Dialog>
  );
};
