import { FluxStandardAction } from 'flux-standard-action';
import { PublicUserApi } from '../../services/public-user.api';
import { IPublicUser } from '@priz/shared/src/models/user';
import { createPgAction } from '../../../shared/store/action-creator';

export enum PublicUserActionType {
  LoadAllByIds = 'publicUser/loadAllByIds',
}

interface PublicUserMeta {
  ids?: number[];
}

type PublicUserPayload = IPublicUser[];

export type PublicUserAction = FluxStandardAction<PublicUserActionType, PublicUserPayload, PublicUserMeta>;

const loadAllByIds = createPgAction(PublicUserActionType.LoadAllByIds, PublicUserApi.getAllByIds, (ids: number[]) => ({
  ids,
}));

export const PublicUserActions = {
  loadAllByIds,
};
