export enum SessionStorageKey {
  NewProjectPublishAdvantagesTipShown = 'NewProjectPublishAdvantagesTipShown',
}

const reportError = (error: Error) => {
  let message = 'Unknown Error';
  if (error instanceof Error) message = error.message;
  console.log(message);
};

const isAvailable = () => {
  try {
    sessionStorage.setItem('test', 'test');
    sessionStorage.removeItem('test');
    return true;
  } catch (e) {
    reportError(e as Error);
    return false;
  }
};

const setItem = (key: SessionStorageKey, value: string): void => {
  try {
    sessionStorage.setItem(key, value);
  } catch (e) {
    reportError(e as Error);
    return;
  }
};

const getItem = (key: SessionStorageKey): string | null => {
  try {
    return sessionStorage.getItem(key);
  } catch (e) {
    reportError(e as Error);
    return null;
  }
};

const removeItem = (key: SessionStorageKey): void => {
  try {
    sessionStorage.removeItem(key);
  } catch (e) {
    reportError(e as Error);
    return;
  }
};

export const SessionStorageService = {
  getItem,
  removeItem,
  setItem,
  isAvailable,
};
