export enum LocalStorageKey {
  InvitationToken = 'InvitationToken',
  RefUrl = 'RefUrl',
  SelectedWorkspaceId = 'SelectedWorkspaceId',
  SelectedTeamId = 'SelectedTeamId',
  PrimaryAccessToken = 'primary_access_token',
  AccessToken = 'access_token',
  Locale = 'locale',
  User = 'user',
  ExpiresAt = 'expires_at',
  State = 'state',
  Version = 'version',
  LastAuthErrorCode = 'LastAuthErrorCode',
  EbsEnableGrouping = 'ebs_enable_grouping',
  PMapShowConclusion = 'p_map_show_conclusion',
  Auth0RouterRedirectPath = 'auth0_router_redirect_path',
  ActiveAuthFormType = 'active_auth_form_type',
  LastSubmittedAuthFormType = 'last_submitted_auth_form_type',
  CreateProjectProps = 'create_project_props',
  AuthCallbackRedirect = 'auth_callback_redirect',
  LastViewedToolVersion = 'last_viewed_tool_version',
  ShowBackToTemplateNavigation = 'show_back_to_template_navigation',
  AiLastViewedChats = 'pg_last_viewed_ai_chats',
  AiChatsPositions = 'pg_ai_chats_positions',
  LastViewedToolPath = 'last_viewed_tool_path',
  LastViewedToolTab = 'last_viewed_tool_tab',
  SwitchToLastViewedToolTab = 'switch_to_last_viewed_tool_tab',
  ProjectTitleToCreate = 'project_title_to_create',
  PublicUtilizationIdToMove = 'utilization_public_id_to_move',
  ProjectFeatureBlockerMock = 'project_feature_blocker_mock',
  OpenPlanUpgradeDialog = 'open_plan_upgrade_dialog',
  LinkSourceId = 'link_source_id',
}

const reportError = (error: Error) => {
  let message = 'Unknown Error';
  if (error instanceof Error) message = error.message;
  console.log(message);
};

const isWindowExist = () => {
  return typeof window !== 'undefined';
};

const isAvailable = () => {
  if (!isWindowExist()) return false;

  try {
    localStorage.setItem('test', 'test');
    localStorage.removeItem('test');
    return true;
  } catch (e) {
    reportError(e as Error);
    return false;
  }
};

const setItem = (key: LocalStorageKey, value: string): void => {
  if (!isWindowExist()) return;

  try {
    localStorage.setItem(key, value);
  } catch (e) {
    reportError(e as Error);
    return;
  }
};

const getItem = (key: LocalStorageKey): string | null => {
  if (!isWindowExist()) return null;

  try {
    return localStorage.getItem(key);
  } catch (e) {
    reportError(e as Error);
    return null;
  }
};

const removeItem = (key: LocalStorageKey): void => {
  if (!isWindowExist()) return;

  try {
    localStorage.removeItem(key);
  } catch (e) {
    reportError(e as Error);
    return;
  }
};

export const LocalStorageService = {
  getItem,
  removeItem,
  setItem,
  isAvailable,
};
