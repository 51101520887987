import { AppState } from '../../../store/app.state';
import { createSelector, Selector } from 'reselect';
import { EntityCollectionStatus, EntityMap } from '@priz/shared/src/models/common/entity-collection-state';
import { TaskAssignee, TaskAssigneeWithMember } from '@priz/shared/src/models/assignment';
import { WorkspaceMembersSelectors } from '../../../workspace/store/selectors/workspace-members.selectors';

const taskAssigneeCollectionSelector = (state: AppState) => state.taskAssignees;

const taskAssigneeEntitiesSelector: Selector<AppState, EntityMap<TaskAssignee>> = createSelector(
  taskAssigneeCollectionSelector,
  collection => collection.entities,
);

const taskAssigneeLookupByTaskIdSelector = (taskId: number): Selector<AppState, number[]> =>
  createSelector(taskAssigneeCollectionSelector, collection => collection.lookups.byTaskId[taskId] || []);

const taskAssigneeStatusSelector: Selector<AppState, EntityCollectionStatus> = createSelector(
  taskAssigneeCollectionSelector,
  collection => collection.statuses,
);

const isLoaded: Selector<AppState, boolean> = createSelector(taskAssigneeStatusSelector, statuses => statuses.loaded);

const isLoading: Selector<AppState, boolean> = createSelector(taskAssigneeStatusSelector, statuses => statuses.loading);

const getTaskAssignees = (taskId: number): Selector<AppState, TaskAssigneeWithMember[]> =>
  createSelector(
    [
      taskAssigneeLookupByTaskIdSelector(taskId),
      taskAssigneeEntitiesSelector,
      WorkspaceMembersSelectors.getWorkspaceMembersEntityMap,
    ],
    (ids, assignees, members) =>
      ids.reduce((arr, id) => {
        const assignee = assignees[id];
        const member = assignee && members[assignee.userId];

        if (assignee && member) {
          arr.push({ ...assignee, user: member } as TaskAssigneeWithMember);
        }
        return arr;
      }, []),
  );

export const TaskAssigneeSelectors = {
  isLoaded,
  isLoading,
  getTaskAssignees,
};
