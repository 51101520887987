import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectSelector } from '../store/selectors';
import { Box, Button, Grid, Typography } from '@mui/material';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import debounce from 'lodash/debounce';
import { ProjectActions } from '../store/actions/project.actions';
import { ProjectTeam } from '../../assignment/project-team/component';
import { TitleContainer } from '../../react/modules/title-container/component';
import { ProjectFlowService } from '../services';
import { FieldTitle, ReactHookFormQuill, ReactHookFormText } from '../../react/form-elements';
import { useForm } from 'react-hook-form';
import { fixQuillEmptyValue, validateOptions } from '@priz/shared/src/utils/form';
import { Project } from '@priz/shared/src/models/project';
import { AttachmentType } from '../../attachments/store/model';
import { AttachmentsLoader } from '../../attachments/attachments-loader/component';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { Assistant } from '../../assistant/component';
import { AssistanceType, AssistantVariant } from '../../assistant/store/model';
import { resolveProjectBackgroundHintData } from '../../assistant/utils';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { TemplateChangeDialogContainer } from '../template/template-change-dialog-container/component';
import { useFlag } from '@unleash/proxy-client-react';
import { UnleashFeatureName } from '@priz/shared/src/services/unleash';
import { MediationRounded } from '@mui/icons-material';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { inputsData } from '../project-wizard/inputs-data';
import { generateDefaultValues } from '../project-wizard/utils';
import { ProjectWizardInput } from '../project-wizard/model/inputs';
import { ProjectWizardSelect } from '../project-wizard/project-wizard-select/component';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';

export interface ProjectOverviewProps {
  projectId: number;
}

const resolveWizardInputsForProject = (project: Project): ProjectWizardInput[] => {
  return project
    ? [...inputsData].map(inputData => ({
        ...inputData,
        options:
          project[inputData.name] && !inputData.options.map(o => o.value).includes(project[inputData.name])
            ? [{ value: project[inputData.name], text: project[inputData.name] }, ...inputData.options]
            : inputData.options,
      }))
    : [];
};

export const ProjectOverview: React.FC<ProjectOverviewProps> = ({ projectId }) => {
  const dispatch = useDispatch();
  const isTemplatesEnabled = useFlag(UnleashFeatureName.Templates);

  const project = useSelector(ProjectSelector.getById(projectId));
  const isProjectDescriptionDefined = useSelector(ProjectSelector.isProjectDescriptionDefinedById(projectId));
  const selectedWorkspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const isAiAssistantEnabled = useSelector(WorkspaceSelectors.isAiAssistantEnabled(selectedWorkspace?.id));

  const isProjectLocked = ProjectFlowService.isProjectLocked(project);

  const backgroundHintData = resolveProjectBackgroundHintData(project);
  const wizardInputsData = resolveWizardInputsForProject(project);

  const {
    watch,
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { isDirty },
  } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      title: project?.title || '',
      description: project?.description || '',
      ...generateDefaultValues(wizardInputsData, project),
    },
  });

  useEffect(() => {
    const subscription = watch((_value, { type }) => {
      if (type === 'change') handleSubmit(updateProject)();
    });

    return () => subscription.unsubscribe();
  }, [watch, project]);

  useEffect(() => {
    if (!isDirty && project) {
      reset({
        title: project.title || '',
        description: project.description || '',
        ...generateDefaultValues(wizardInputsData, project),
      });
    }
  }, [project]);

  const updateProject = useCallback(
    debounce(() => {
      const { description, ...rest } = getValues();

      dispatch(
        ProjectActions.updateProject({
          ...project,
          ...rest,
          description: fixQuillEmptyValue(description),
        }),
      );
    }, 500),
    [project],
  );

  if (!project) {
    return <LoadingOverlay loading={true} backdropStyles={{ backgroundColor: 'transparent' }} />;
  }

  return (
    <>
      <ContentContainer>
        <TitleContainer>
          <PageTitleWithDocLink
            title={<Trans>Project overview</Trans>}
            oneLiner={<Trans>Some high-level project details.</Trans>}
            docsUrl="https://www.priz.guru/knowledge-base/project-overview"
          />

          {isTemplatesEnabled && !project.template && (
            <TemplateChangeDialogContainer projectId={project.id} disabled={isProjectLocked}>
              <Button
                variant={'pg_rounded'}
                color={'pg_orange_solid'}
                startIcon={<MediationRounded />}
                disabled={isProjectLocked}
              >
                <Trans>Choose template</Trans>
              </Button>
            </TemplateChangeDialogContainer>
          )}
        </TitleContainer>

        <ReactHookFormText
          multiline
          name={'title'}
          fieldTitle={'Project title'}
          control={control}
          rules={{
            validate: {
              ...validateOptions.hasText('Title is required'),
            },
          }}
          disabled={isProjectLocked}
        />

        <Grid container columnSpacing={2}>
          {wizardInputsData.map(input => (
            <Grid item xs={12} md={4} key={input.name}>
              <FieldTitle text={input.title} />

              <ProjectWizardSelect control={control} inputData={input} disabled={isProjectLocked} />
            </Grid>
          ))}
        </Grid>

        <Box mb={1}>
          <Grid container spacing={1} justifyContent={'space-between'} alignItems={'flex-end'}>
            <Grid item>
              <FieldTitle text={'Project background'} mb={0} />
            </Grid>

            {isAiAssistantEnabled && (
              <Grid item>
                <Assistant
                  type={AssistanceType.AskProjectDescriptionFeedback}
                  variant={AssistantVariant.Dialog}
                  projectId={projectId}
                  initialHintData={backgroundHintData}
                  buttonProps={{
                    text: 'Get feedback',
                    variant: backgroundHintData ? 'completeness' : 'button',
                    tooltip: backgroundHintData ? 'Project background feedback' : '',
                  }}
                  controlsProps={{ retry: !!backgroundHintData, retryText: 'Reevaluate' }}
                  dialogAssistantProps={{ maxWidth: 'lg' }}
                  disabled={isProjectLocked || !isProjectDescriptionDefined}
                />
              </Grid>
            )}
          </Grid>
        </Box>

        <ReactHookFormQuill name={'description'} placeholder={''} control={control} disabled={isProjectLocked} />

        <Box mb={2}>
          <Typography variant={'h6'}>
            <Trans>Attachments</Trans>
          </Typography>
        </Box>

        <Box mb={2}>
          <AttachmentsLoader projectId={projectId} type={AttachmentType.Overview} disabled={isProjectLocked} />
        </Box>

        <ProjectTeam projectId={projectId} disabled={isProjectLocked} />
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={projectId}
          disabled={isProjectLocked}
          workingFlowProps={{ nextVariant: 'problem-statement' }}
        />
      </ContentFooter>
    </>
  );
};
