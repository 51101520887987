import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Certificate, CertificateActions } from '../store';
import { Project, ProjectCertificationStatus } from '@priz/shared/src/models/project';
import { User } from '@priz/shared/src/models/security/user';
import { Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ProjectFlowService, ProjectPermissionsService } from '../../project/services';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import {
  ApprovalActivity,
  ApprovalActivityActionEnum,
  ApprovalActivityStatus,
} from '../../project-approval/store/model';
import { ApprovalActivityActions } from '../../project-approval/store/actions/approval-activity.actions';
import { ReactHookFormText } from '../../react/form-elements';
import { validateOptions } from '@priz/shared/src/utils/form';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { Button } from '@mui/material';

export interface EditCertificateDetailsProps {
  user: User;
  project: Project;
  certificate: Certificate;
}

const getFullName = (user: User) => {
  if (user && user.profile) {
    const userProfile = user.profile;

    const title = userProfile.title && userProfile.title.trim();

    const fullNameParts = [userProfile.firstName, userProfile.lastName];

    if (title && title.length > 0) {
      fullNameParts.unshift(title);
    }

    return fullNameParts.join(' ');
  } else {
    return '';
  }
};

const resolveSubmitForReviewButtonText = (project: Project) => {
  const isWaiting = project.certificationStatus === ProjectCertificationStatus.WaitingForRequiredUpdates;
  return isWaiting ? 'Resubmit for approval' : 'Submit for approval';
};

const resolveSubmitForReviewAction = (project: Project): ApprovalActivityActionEnum => {
  let action;

  switch (project.certificationStatus) {
    case ProjectCertificationStatus.None:
      action = ApprovalActivityActionEnum.SubmittedForApproval;
      break;
    case ProjectCertificationStatus.WaitingForRequiredUpdates:
      action = ApprovalActivityActionEnum.SubmittedChangesForApproval;
      break;
    default:
  }

  return action;
};

export const EditCertificateDetails: React.FC<EditCertificateDetailsProps> = ({ project, user, certificate }) => {
  const dispatch = useDispatch();

  const [confirmSubmissionIsOpen, setConfirmSubmissionIsOpen] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    shouldFocusError: false,
    defaultValues: {
      name: getFullName(user),
      title: certificate.title || '',
      abstractContent: certificate.abstractContent || '',
    },
  });

  const isCurrentUserProjectOwner = ProjectPermissionsService.isCurrentUserProjectOwner(project, user);
  const isProjectLocked = ProjectFlowService.isProjectLocked(project);
  const shouldShowSubmitForApproval = project.certificationStatus !== ProjectCertificationStatus.Rejected;

  const save = () => {
    handleSubmit(formData => {
      dispatch(
        CertificateActions.updateCertificate({
          ...certificate,
          title: formData.title,
          abstractContent: formData.abstractContent,
        }),
      );
    })();
  };

  const createFullDocument = () => {
    dispatch(CertificateActions.createFullDocument(project.id));
  };

  const submitForApprovalHandler = () => {
    const activity = new ApprovalActivity({
      action: resolveSubmitForReviewAction(project),
      comment: '',
      status: ApprovalActivityStatus.Final,
    });

    dispatch(ApprovalActivityActions.create(project.id, activity));
    closeConfirmSubmissionDialog();
  };

  const openConfirmSubmissionDialog = () => {
    setConfirmSubmissionIsOpen(true);
  };

  const closeConfirmSubmissionDialog = () => {
    setConfirmSubmissionIsOpen(false);
  };

  return (
    <>
      <ButtonsWrap spacing={2} justifyContent={'flex-end'} mb={1}>
        {!certificate.hasFullDocument && (
          <Button
            variant={'pg_rounded'}
            color={'pg_orange_outlined'}
            size={'pg_small'}
            onClick={createFullDocument}
            disabled={isProjectLocked}
          >
            <Trans>Create Detailed Document</Trans>
          </Button>
        )}

        <Button
          variant={'pg_rounded'}
          color={'pg_orange_solid'}
          size={'pg_small'}
          onClick={save}
          disabled={isProjectLocked || !isDirty}
        >
          <Trans>Save</Trans>
        </Button>
      </ButtonsWrap>

      <ReactHookFormText
        name={'name'}
        fieldTitle={'Your full name'}
        placeholder={''}
        control={control}
        rules={{
          validate: {
            ...validateOptions.hasText('Field is required'),
          },
        }}
        helperText={
          isCurrentUserProjectOwner
            ? 'Your name can be changed only in your <a [routerLink]="[\'/profile\']">user profile</a>'
            : ''
        }
        disabled={true}
      />

      <ReactHookFormText
        name={'title'}
        fieldTitle={'Project Title (to be used on certificate)'}
        control={control}
        rules={{
          validate: {
            ...validateOptions.hasText('Field is required'),
          },
        }}
        disabled={isProjectLocked}
        helperText={
          'By default, this is the title of the project itself; however, this can be changed for certificate to make it look better'
        }
      />

      <ReactHookFormText
        multiline
        fieldTitle={'Abstract'}
        placeholder={'Short description of the project'}
        name={'abstract'}
        control={control}
        rules={{
          validate: {
            ...validateOptions.hasText('Field is required'),
          },
        }}
        disabled={isProjectLocked}
        helperText={'Some details about the abstract content'}
      />

      <ButtonsWrap spacing={2} justifyContent={'flex-end'}>
        {shouldShowSubmitForApproval && (
          <Button
            variant={'pg_rounded'}
            color={'pg_orange_outlined'}
            size={'pg_small'}
            onClick={openConfirmSubmissionDialog}
            disabled={isProjectLocked}
          >
            <Trans>{resolveSubmitForReviewButtonText(project)}</Trans>
          </Button>
        )}

        <Button
          variant={'pg_rounded'}
          color={'pg_orange_solid'}
          size={'pg_small'}
          onClick={save}
          disabled={isProjectLocked || !isDirty}
        >
          <Trans>Save</Trans>
        </Button>
      </ButtonsWrap>

      <PgConfirmationDialog
        isOpen={confirmSubmissionIsOpen}
        confirmTitle={<Trans>Please, confirm submission</Trans>}
        okButtonText={<Trans>Yes, submit and lock the project</Trans>}
        cancelButtonText={<Trans>No, I still need to work on it</Trans>}
        confirmContent={
          <React.Fragment>
            <p>
              <Trans>You are about to submit the project for certification.</Trans>
            </p>
            <p>
              <Trans>Please, note - once certification process started</Trans>:
            </p>
            <ul>
              <li>
                <Trans>the entire project will be locked in read only mode</Trans>
              </li>
              <li>
                <Trans>designated reviewer will have an access to project's data</Trans>
              </li>
            </ul>
            <p>
              <Trans>Don't worry, we are not sharing the data with anyone! Ever!</Trans>
            </p>
            <p>
              <Trans>Do you want to continue?</Trans>
            </p>
          </React.Fragment>
        }
        onConfirm={submitForApprovalHandler}
        onClose={closeConfirmSubmissionDialog}
      />
    </>
  );
};
