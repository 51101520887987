import { Project } from '@priz/shared/src/models/project';
import { User } from '@priz/shared/src/models/security/user';

const isCurrentUserProjectOwner = (project: Project, currentUser: User) => {
  return project && currentUser && project.owner.id === +currentUser.id;
};

export const ProjectPermissionsService = {
  isCurrentUserProjectOwner,
};
