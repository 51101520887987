import { createPgAction } from '../shared/store/action-creator';

export enum AppActionType {
  Reset = 'app/reset',
}

const reset = createPgAction(AppActionType.Reset, () => new Promise(resolve => resolve(null)));

export const AppActions = {
  reset,
};
