import { createTheme } from '@mui/material/styles';
import { alpha, PaletteColorOptions } from '@mui/material';
import { pgColorScheme } from './colors';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    pg_rounded: true;
    pg_link: true;
    pg_button_link: true;
    pg_transparent: true;
  }

  interface ButtonPropsSizeOverrides {
    pg_extra_small: true;
    pg_small: true;
    pg_medium: true;
    pg_medium_narrow: true;
  }

  interface ButtonPropsColorOverrides {
    pg_orange_solid: true;
    pg_orange_outlined: true;
    pg_blue_solid: true;
    pg_white_blue: true;
    pg_blue_white: true;
    pg_white_outlined: true;
    pg_red_text: true;
  }
}

declare module '@mui/material/Slider' {
  interface SliderPropsColorOverrides {
    error: true;
    success: true;
    gray: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsSizeOverrides {
    extra_small: true;
  }
  interface ChipPropsColorOverrides {
    text_bg_gray: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body0: true;
    body1_space: true;
    h1_hub: true;
    h2_hub: true;
    h3_hub: true;
    h4_hub: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    text_color: PaletteColorOptions;
    text_bg_gray: PaletteColorOptions;
    gray: PaletteColorOptions;
  }
  interface PaletteOptions {
    text_color: PaletteColorOptions;
    text_bg_gray: PaletteColorOptions;
    gray: PaletteColorOptions;
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1334, // default 1200
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: pgColorScheme.blue,
      contrastText: pgColorScheme.white,
    },
    secondary: {
      main: pgColorScheme.orange,
      contrastText: pgColorScheme.white,
    },
    success: {
      main: pgColorScheme.green,
      contrastText: pgColorScheme.white,
    },
    error: {
      main: pgColorScheme.coral,
      contrastText: pgColorScheme.white,
    },
    text_color: {
      light: pgColorScheme.gray600,
      main: pgColorScheme.gray700,
      dark: pgColorScheme.gray800,
      contrastText: pgColorScheme.white,
    },
    text_bg_gray: {
      light: pgColorScheme.gray300,
      main: pgColorScheme.gray400,
      dark: pgColorScheme.gray500,
      contrastText: pgColorScheme.white,
    },
    gray: {
      light: pgColorScheme.gray500,
      main: pgColorScheme.gray600,
      dark: pgColorScheme.gray700,
      contrastText: pgColorScheme.white,
    },
  },
});

export const prizFont = {
  fontFamily: '"Roboto", sans-serif',
};

export const pgTheme = createTheme(theme, {
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        rounded: {
          'background': pgColorScheme.white,
          'minWidth': 36,
          'height': 36,
          'borderRadius': 8,
          'margin': '0 5px',
          'color': pgColorScheme.gray600,
          'display': 'flex',
          'alignItems': 'center',
          'justifyContent': 'center',
          'fontSize': 16,

          '&:not(.MuiPaginationItem-ellipsis).Mui-selected, &:not(.MuiPaginationItem-ellipsis):hover': {
            background: alpha(pgColorScheme.blue, 0.2),
          },

          [theme.breakpoints.down('sm')]: {
            minWidth: 30,
            height: 30,
            margin: '0 3px',
          },
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        root: {
          wordBreak: 'break-word',
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          transition: 'none',
          background: pgColorScheme.white,
          color: pgColorScheme.darkText,
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('md')]: {
            minHeight: 70,
          },
          [theme.breakpoints.down('md')]: {
            minHeight: 60,
          },
          [theme.breakpoints.down('sm')]: {
            minHeight: 50,
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          margin: '4px !important',
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        h1: {
          [theme.breakpoints.down('md')]: {
            fontSize: '4rem',
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: '3rem',
          },
        },
        h4: {
          [theme.breakpoints.down('sm')]: {
            fontSize: '1.6rem',
          },
        },
        h6: {
          [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            lineHeight: 1.4,
          },
        },
      },

      variants: [
        {
          props: { variant: 'body0' },
          style: {
            fontSize: 18,
          },
        },
        {
          props: { variant: 'body1_space' },
          style: {
            fontSize: 16,
            lineHeight: 26 / 16,
          },
        },
        {
          props: { variant: 'h1_hub' },
          style: {
            fontWeight: 'bold',
            fontSize: 44,
            lineHeight: 1.2,

            [theme.breakpoints.down('md')]: {
              fontSize: 32,
            },

            [theme.breakpoints.down('sm')]: {
              fontSize: 22,
            },
          },
        },
        {
          props: { variant: 'h2_hub' },
          style: {
            fontWeight: 'bold',
            fontSize: 32,
            lineHeight: 34 / 32,

            [theme.breakpoints.down('sm')]: {
              fontSize: 24,
            },
          },
        },
        {
          props: { variant: 'h3_hub' },
          style: {
            fontWeight: 'bold',
            fontSize: 18,
            lineHeight: 27 / 18,

            [theme.breakpoints.down('sm')]: {
              fontSize: 16,
            },
          },
        },
        {
          props: { variant: 'h4_hub' },
          style: {
            fontWeight: 'bold',
            fontSize: 16,
            lineHeight: 1,
          },
        },
      ],
    },

    MuiBadge: {
      styleOverrides: {
        dot: {
          width: 10,
          height: 10,
          borderRadius: 5,
          border: `2px solid ${pgColorScheme.white}`,
          transform: 'none',
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          'maxWidth': '100%',

          '&.Mui-disabled': {
            opacity: 0.5,
          },
        },
        label: {
          lineHeight: 'inherit',
        },
      },

      variants: [
        {
          props: { size: 'extra_small' },
          style: {
            'height': 20,

            '& .MuiChip-label': {
              paddingLeft: 7,
              paddingRight: 7,
              fontWeight: 'bold',
              fontSize: 12,
              lineHeight: 1,
            },
          },
        },
      ],
    },

    MuiDivider: {
      styleOverrides: {
        light: {
          borderColor: alpha(pgColorScheme.white, 0.12),
        },
      },
    },

    MuiPickersCalendar: {
      styleOverrides: {
        week: {
          '& .MuiTypography-root': {
            marginBottom: 0,
          },
        },
      },
    },

    MuiPickersDay: {
      styleOverrides: {
        current: {
          backgroundColor: pgColorScheme.background,
        },
        daySelected: {
          backgroundColor: pgColorScheme.blue,
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        outlined: {
          boxSizing: 'border-box',
        },
      },
    },

    MuiTableBody: {
      styleOverrides: {
        root: {
          '& > .MuiTableRow-root:last-child > .MuiTableCell-root': {
            borderBottom: 0,
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        stickyHeader: {
          backgroundColor: pgColorScheme.gray50,
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        selectRoot: {
          [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 8,
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        scrollButtons: {
          '&.Mui-disabled': {
            opacity: 0.3,
          },
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: pgColorScheme.blue,
          },
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '@font-face': [prizFont],
        },
        'body': {
          color: pgColorScheme.gray700,
          letterSpacing: 0,
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        outlined: {
          'padding': 16,

          '&:focus': {
            background: pgColorScheme.white,
          },
        },
      },
    },

    MuiStepper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },

    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            fill: pgColorScheme.orange,
          },
        },
      },
    },

    MuiStepButton: {
      styleOverrides: {
        root: {
          '&:not(.Mui-disabled):hover': {
            '& .MuiStepIcon-root': {
              fill: pgColorScheme.orange,
            },
          },
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        underline: {
          '&::after': {
            borderBottomColor: pgColorScheme.blue,
          },
          '&.Mui-focused': {
            background: 'transparent',
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: 24,

          [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 10,
          },
        },

        paperFullWidth: {
          [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)',
          },
        },

        paperWidthFalse: {
          [theme.breakpoints.down('sm')]: {
            maxWidth: 'calc(100% - 20px)',
          },
        },

        paperScrollPaper: {
          [theme.breakpoints.down('sm')]: {
            maxHeight: 'calc(100% - 20px)',
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        inputMultiline: {
          maxWidth: '100%',
          boxSizing: 'border-box',
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            background: 'rgba(0,0,0,.12)',
          },
        },
        outlined: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
        text: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
        containedError: {
          '&:hover': {
            backgroundColor: pgColorScheme.coralDark,
          },
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: pgColorScheme.darkBlue,
          },
        },
        containedSuccess: {
          '&:hover': {
            backgroundColor: pgColorScheme.greenMedium,
          },
        },
      },

      variants: [
        {
          props: { variant: 'pg_rounded' },
          style: {
            borderRadius: 30,
            fontSize: 14,
            fontWeight: 400,
            height: 44,
            lineHeight: 1,
            margin: '0 5px',
            padding: '0 16px',
            textTransform: 'uppercase',
            minWidth: 90,

            [theme.breakpoints.down('md')]: {
              height: 38,
            },

            [theme.breakpoints.down('sm')]: {
              fontSize: 12,
              height: 34,
              padding: '0 12px',
              margin: '0 3px',
            },
          },
        },

        {
          props: { variant: 'pg_link' },
          style: {
            border: 0,
            color: pgColorScheme.blue,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 1,
            textAlign: 'center',
            textDecoration: 'none',
            textTransform: 'uppercase',
            padding: 0,

            ['&:not(.Mui-disabled):hover, &:not(.Mui-disabled):active']: {
              color: pgColorScheme.darkBlue,
              textDecoration: 'none',
              background: 'transparent',
            },
          },
        },

        {
          props: { variant: 'pg_button_link' },
          style: {
            backgroundColor: 'transparent',
            border: 0,
            color: pgColorScheme.blue,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 1.2,
            textTransform: 'uppercase',
            padding: '6px 8px',

            ['&:not(.Mui-disabled):hover, &:not(.Mui-disabled):active']: {
              color: pgColorScheme.darkBlue,
              textDecoration: 'none',
            },

            ['&._bright-disabled.Mui-disabled']: {
              color: 'rgba(255, 255, 255, 0.26)',

              ['& svg']: {
                fill: 'rgba(255, 255, 255, 0.26)',
              },
            },

            [theme.breakpoints.down('md')]: {
              fontSize: 13,

              ['& .MuiSvgIcon-root']: {
                fontSize: 18,
              },
            },
            [theme.breakpoints.down('sm')]: {
              fontSize: 12,

              ['& .MuiSvgIcon-root']: {
                fontSize: 16,
              },
            },
          },
        },

        {
          props: { variant: 'pg_transparent' },
          style: {
            textTransform: 'none',
            padding: '6px 16px',
          },
        },

        {
          props: { color: 'pg_orange_solid' },
          style: {
            backgroundColor: pgColorScheme.orange,
            borderColor: pgColorScheme.orange,
            color: pgColorScheme.white,

            ['&:not(.Mui-disabled):hover']: {
              backgroundColor: pgColorScheme.darkOrange,
              borderColor: pgColorScheme.darkOrange,
            },
          },
        },

        {
          props: { color: 'pg_orange_outlined' },
          style: {
            backgroundColor: pgColorScheme.white,
            color: pgColorScheme.orange,
            border: `1px solid ${pgColorScheme.orange}`,

            ['&:not(.Mui-disabled):hover']: {
              backgroundColor: pgColorScheme.white,
              borderColor: pgColorScheme.darkOrange,
              color: pgColorScheme.darkOrange,
            },
          },
        },

        {
          props: { color: 'pg_white_blue' },
          style: {
            backgroundColor: pgColorScheme.white,
            border: `1px solid ${pgColorScheme.white}`,
            color: pgColorScheme.blue,

            ['&:not(.Mui-disabled):hover']: {
              backgroundColor: pgColorScheme.blue,
              borderColor: pgColorScheme.white,
              color: pgColorScheme.white,
            },
          },
        },

        {
          props: { color: 'pg_blue_white' },
          style: {
            backgroundColor: pgColorScheme.blue,
            border: `1px solid ${pgColorScheme.white}`,
            color: pgColorScheme.white,

            ['&:not(.Mui-disabled):hover']: {
              backgroundColor: pgColorScheme.white,
              borderColor: pgColorScheme.white,
              color: pgColorScheme.blue,
            },
          },
        },

        {
          props: { color: 'pg_blue_solid' },
          style: {
            backgroundColor: pgColorScheme.blue,
            color: pgColorScheme.white,

            ['&:not(.Mui-disabled):hover']: {
              backgroundColor: pgColorScheme.darkBlue,
              color: pgColorScheme.white,
            },
          },
        },

        {
          props: { color: 'pg_white_outlined' },
          style: {
            backgroundColor: pgColorScheme.transparent,
            border: `1px solid ${pgColorScheme.white}`,
            color: pgColorScheme.white,

            ['&:not(.Mui-disabled):hover']: {
              backgroundColor: pgColorScheme.orange,
              borderColor: pgColorScheme.orange,
              color: pgColorScheme.white,
            },
          },
        },

        {
          props: { color: 'pg_red_text' },
          style: {
            color: pgColorScheme.coral,

            ['&:not(.Mui-disabled):hover']: {
              color: pgColorScheme.coralDark,
            },
          },
        },

        {
          props: { size: 'pg_medium' },
          style: {
            fontWeight: 600,
            height: 40,
            margin: 0,
            letterSpacing: 0,
            textTransform: 'none',

            [theme.breakpoints.down('sm')]: {
              height: 30,

              ['& .MuiButton-startIcon svg']: {
                width: 20,
                height: 20,
              },
            },
          },
        },

        {
          props: { size: 'pg_medium_narrow' },
          style: {
            fontWeight: 600,
            height: 'auto',
            padding: '4px 8px',
            margin: 0,
            letterSpacing: 0,
            textTransform: 'none',

            [theme.breakpoints.down('sm')]: {
              padding: '2px 8px',

              ['& .MuiButton-startIcon svg']: {
                width: 20,
                height: 20,
              },
            },
          },
        },

        {
          props: { size: 'pg_small' },
          style: {
            fontSize: 12,
            fontWeight: 400,
            height: 30,
            margin: 0,
            letterSpacing: '0',
            textTransform: 'none',

            ['& .MuiButton-startIcon svg']: {
              width: 16,
              height: 16,
            },

            [theme.breakpoints.down('md')]: {
              height: 30,
              margin: 0,
            },

            [theme.breakpoints.down('sm')]: {
              height: 30,
              margin: 0,
            },
          },
        },

        {
          props: { size: 'pg_extra_small' },
          style: {
            fontSize: 12,
            fontWeight: 400,
            height: 'auto',
            margin: 0,
            padding: '0 8px',
            letterSpacing: '0',
            textTransform: 'none',

            ['& .MuiButton-startIcon svg']: {
              width: 16,
              height: 16,
            },
          },
        },
      ],
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            'background': 'rgba(0,0,0,.12)',

            '& svg': {
              fill: 'rgba(0, 0, 0, 0.26)',
            },
          },
        },

        sizeMedium: {
          [theme.breakpoints.down('sm')]: {
            padding: 6,
          },
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          display: 'unset',
          textAlign: 'center',
          padding: '8px 24px',

          [theme.breakpoints.down('sm')]: {
            padding: '20px 24px',
          },
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '8px 24px',
        },
      },
    },

    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: pgColorScheme.black,
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: '140%',
          paddingBottom: '20px',
          paddingTop: '10px',
          textAlign: 'center',
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          'color': pgColorScheme.black,
          'textAlign': 'center',

          '& .MuiTypography-root': {
            marginBottom: 0,
          },

          '& + .MuiDialogContent-root': {
            paddingTop: '8px !important',
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          // borderColor: pgColorScheme.lightGray, // TODO: ?
          borderColor: pgColorScheme.gray300,
          transition: 'border-color .1s ease',
        },
        root: {
          'background': pgColorScheme.white,
          'outline': 'none',

          '&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
            borderColor: pgColorScheme.placeholder,
            outline: 'none',
          },

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: pgColorScheme.blue,
            outline: 'none',
          },

          '& legend': {
            maxHeight: '100%',
            overflow: 'hidden',
          },
        },

        input: {
          'height': 19,
          'padding': 18,

          '&.MuiInputBase-inputSizeSmall': {
            height: 16,
            padding: 13,
          },

          '&.MuiInputBase-inputMultiline': {
            padding: 16,
          },

          '&.MuiInputBase-inputMultiline.MuiInputBase-inputSizeSmall': {
            padding: 10,
          },

          [theme.breakpoints.down('lg')]: {
            padding: 16,
          },
          [theme.breakpoints.down('md')]: {
            padding: 14,
          },
          [theme.breakpoints.down('sm')]: {
            padding: 12,
          },
        },

        multiline: {
          padding: 0,
        },

        inputMultiline: {
          padding: 18,

          [theme.breakpoints.down('lg')]: {
            padding: 16,
          },
          [theme.breakpoints.down('md')]: {
            padding: 14,
          },
          [theme.breakpoints.down('sm')]: {
            padding: 12,
          },
        },
      },
    },
  },
});
