import React from 'react';
import { ButtonCreateTask } from '../../react/elements/button-create-task/component';
import { ToolUtilization } from '@priz/shared/src/models/tools';
import { Button, ButtonProps, Grid, Tooltip } from '@mui/material';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  ArrowCircleLeftOutlined,
  FollowTheSignsRounded,
  PeopleAltOutlined,
  TaskOutlined,
  TipsAndUpdatesOutlined,
} from '@mui/icons-material';
import { CreateMeetingButton } from '../../meetings/create-meeting-button/component';
import { NewIdeaButton } from '../../idea/new-idea-button';

type NextButtonVariant = 'ideas' | 'tasks' | 'tools' | 'problem-statement' | 'propose-solution' | 'report';

export interface SharedWorkingFlowButtonsProps {
  nextPath?: string;
  nextVariant?: NextButtonVariant;
  backPath?: string;
  backVariant?: NextButtonVariant;
  hideIdeaButton?: boolean;
  hideTaskButton?: boolean;
  hideMeetingButton?: boolean;
}

interface WorkingFlowButtonsProps extends SharedWorkingFlowButtonsProps {
  projectId?: number;
  utilizationId?: number;
  sourceTool?: ToolUtilization;
  disabled?: boolean;
}

const resolveLinkPath = (projectId: number, variant: NextButtonVariant): string => {
  switch (variant) {
    case 'ideas':
      return `/project/${projectId}/ideas`;

    case 'tasks':
      return `/project/${projectId}/tasks`;

    case 'tools':
      return `/project/${projectId}/tools/list#create`;

    case 'problem-statement':
      return `/project/${projectId}/problem-statement`;

    case 'propose-solution':
      return `/project/${projectId}/solution`;

    case 'report':
      return `/project/${projectId}/report`;

    default:
      return '/';
  }
};

const buttonStyleProps: Pick<ButtonProps, 'variant' | 'size' | 'color'> = {
  variant: 'text',
  size: 'large',
  color: 'primary',
};

export const WorkingFlowButtons: React.FC<WorkingFlowButtonsProps> = ({
  projectId,
  nextPath,
  nextVariant,
  backPath,
  backVariant,
  sourceTool,
  hideIdeaButton,
  hideTaskButton,
  hideMeetingButton,
  disabled,
}) => {
  const isProjectIdDefined = typeof projectId !== 'undefined';

  return (
    <>
      <Grid container spacing={{ xs: 0, sm: 1, md: 2 }}>
        {!hideIdeaButton && (
          <Grid item>
            <NewIdeaButton
              projectId={projectId}
              disabled={disabled}
              text={'Idea'}
              tooltip={'Record an Idea'}
              startIcon={<TipsAndUpdatesOutlined />}
              sourceTool={sourceTool}
              {...buttonStyleProps}
            />
          </Grid>
        )}

        {!hideTaskButton && (
          <Grid item>
            <ButtonCreateTask
              projectId={projectId}
              disabled={disabled}
              text={'Task'}
              tooltip={'Create New Task'}
              startIcon={<TaskOutlined />}
              sourceTool={sourceTool}
              {...buttonStyleProps}
            />
          </Grid>
        )}

        {!hideMeetingButton && (
          <Grid item>
            <CreateMeetingButton
              projectId={projectId}
              disabled={disabled}
              text={'Meet'}
              tooltip={'Schedule a new meeting'}
              startIcon={<PeopleAltOutlined />}
              sourceTool={sourceTool}
              {...buttonStyleProps}
            />
          </Grid>
        )}

        {isProjectIdDefined && (backPath || backVariant) && (
          <Grid item>
            <Tooltip title={<Trans>Move to previous step</Trans>} placement={'top'} disableInteractive arrow>
              <div>
                <Button
                  component={Link}
                  to={backPath || resolveLinkPath(projectId, backVariant)}
                  startIcon={<ArrowCircleLeftOutlined />}
                  disabled={disabled}
                  {...buttonStyleProps}
                >
                  <Trans>Back</Trans>
                </Button>
              </div>
            </Tooltip>
          </Grid>
        )}

        {isProjectIdDefined && (nextPath || nextVariant) && (
          <Grid item>
            <Tooltip title={<Trans>Move to next step</Trans>} placement={'top'} disableInteractive arrow>
              <div>
                <Button
                  component={Link}
                  to={nextPath || resolveLinkPath(projectId, nextVariant)}
                  startIcon={<FollowTheSignsRounded />}
                  disabled={disabled}
                  {...buttonStyleProps}
                >
                  <Trans>Next</Trans>
                </Button>
              </div>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </>
  );
};
