import { AuditableEntity, IAuditableEntity } from '@priz/shared/src/models/common/auditable-entity';
import { IdRef } from '@priz/shared/src/models/common/id-ref';
import { Identifiable } from '@priz/shared/src/models/common/entity-collection-state';

export enum AttachmentType {
  Task = 'task',
  Overview = 'overview',
  Solution = 'solution',
}

export interface AttachmentPreview {
  file: File;
  fileName: string;
  contentType: string;
  attachmentType: AttachmentType;
  error?: boolean;
  loading?: boolean;
  taskId?: number;
}

export interface IAttachment extends IAuditableEntity, Identifiable {
  attachmentType: AttachmentType;
  workspace: IdRef;
  project: IdRef;
  fileName: string;
  fileKey: string;
  signedUrl: string;
  urlExpiresAt: Date;
  contentType: string;
  task?: IdRef;
}

export class Attachment extends AuditableEntity implements IAttachment {
  public id: number;
  public attachmentType: AttachmentType;
  public workspace: IdRef;
  public project: IdRef;
  public fileName: string;
  public fileKey: string;
  public signedUrl: string;
  public urlExpiresAt: Date;
  public contentType: string;
  public task?: IdRef;

  constructor(json: IAttachment) {
    super(json);
    this.id = json.id;
    this.attachmentType = json.attachmentType;
    this.workspace = json.workspace;
    this.project = json.project;
    this.fileName = json.fileName;
    this.fileKey = json.fileKey;
    this.signedUrl = json.signedUrl;
    this.urlExpiresAt = json.urlExpiresAt;
    this.contentType = json.contentType;
    this.task = json.task;
  }
}
