import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  controlIcon: {
    display: 'block',
    color: pgColorScheme.textGray,
  },
  interactiveControlIcon: {
    cursor: 'pointer',
    transition: 'color .15s ease',

    ['&:hover']: {
      color: pgColorScheme.blue,
    },
  },
});
