import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { dashboardApi } from '../services/dashboard.api';
import { DashboardWidgetCard } from '../dashboard-widget-card/component';
import { Agenda, Attendee, Meeting } from '../../meetings/store/model';
import { WorkspaceMembersActions } from '../../workspace/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardMeeting } from './dashboard-meeting/component';
import { AttendeeSelectors } from '../../meetings/store/selectors';

interface DashboardMeetingsWidgetProps {
  workspaceId: number;
}

export const DashboardMeetingsWidget: React.FC<DashboardMeetingsWidgetProps> = ({ workspaceId }) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);

  const isAnyAttendeeUpdated = useSelector(AttendeeSelectors.isAnyUpdated());

  const {
    data = { items: [], attendees: [], agenda: [], totalCount: 0 },
    isLoading,
    isFetching,
    refetch,
  } = useQuery(['dashboard-meetings', workspaceId, page], () => dashboardApi.getMeetings(page), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    dispatch(WorkspaceMembersActions.loadAll());
  }, []);

  useEffect(() => {
    if (isAnyAttendeeUpdated) void refetch();
  }, [isAnyAttendeeUpdated]);

  const meetingsAttendeesMap = data.attendees.reduce((map: { [meetingId: number]: Attendee[] }, attendee) => {
    if (!map[attendee.meeting.id]) map[attendee.meeting.id] = [];
    map[attendee.meeting.id].push(attendee);
    return map;
  }, {});

  const meetingsAgendaMap = data.agenda.reduce((map: { [meetingId: number]: Agenda[] }, agenda) => {
    if (!map[agenda.meeting.id]) map[agenda.meeting.id] = [];
    map[agenda.meeting.id].push(agenda);
    return map;
  }, {});

  return (
    <DashboardWidgetCard
      title={'Meetings'}
      placeholder={'Meetings list is empty'}
      currentPage={page}
      itemsCountPerPage={10}
      totalItemsCount={data.totalCount}
      isLoading={isLoading}
      isFetching={isFetching}
      onChangePage={newPage => {
        setPage(newPage);
      }}
    >
      {data.items.map(item => (
        <DashboardMeeting
          key={item.id}
          meeting={new Meeting(item)}
          attendees={meetingsAttendeesMap[item.id]}
          agenda={meetingsAgendaMap[item.id]}
        />
      ))}
    </DashboardWidgetCard>
  );
};
