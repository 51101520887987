import { AuditableEntity, IAuditableEntity } from '@priz/shared/src/models/common/auditable-entity';
import { Identifiable } from '@priz/shared/src/models/common/entity-collection-state';
import { IdRef } from '@priz/shared/src/models/common/id-ref';

export interface IAgenda extends IAuditableEntity, Identifiable {
  text: string;
  done: boolean;
  meeting: IdRef;
  project: IdRef;
  workspace: IdRef;
}

export class Agenda extends AuditableEntity implements IAgenda {
  public id: number;
  public text: string;
  public done: boolean;
  public meeting: IdRef;
  public project: IdRef;
  public workspace: IdRef;

  constructor(json: IAgenda) {
    super(json);

    this.id = json.id;
    this.text = json.text;
    this.done = json.done;
    this.meeting = json.meeting;
    this.project = json.project;
    this.workspace = json.workspace;
  }
}

export interface AgendaCreateCommand {
  meetingId: number;
}

export interface AgendaUpdateCommand {
  text?: string;
  done?: boolean;
}

export interface AgendaUpdateProps {
  id?: number;
  text?: string;
  done?: boolean;
}
