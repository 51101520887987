import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Paper, Typography, Button } from '@mui/material';
import { ToolOverview, ToolType } from '@priz/shared/src/models/tools';
import { PgLink } from '../../react/elements/pg-link/component';
import { ToolTypeDataMap } from '@priz/shared/src/data/tool-type-data-map';
import { Trans } from 'react-i18next';
import { FeatureSetSelectors } from '../../workspace/store/selectors/feature-set.selectors';
import { PaywallActions } from '../../react/modules/paywall/store';
import { FeatureLimit } from '@priz/shared/src/models/workspace';
import { useStyles } from './styles';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { RenderForRoles } from '../../react/security/render-for-roles/component';
import { Help } from '@mui/icons-material';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { useFlags, useFlagsStatus } from '@unleash/proxy-client-react';

export interface ToolOverviewCardProps {
  toolType: ToolType;
  onToolAdd: () => void;
  onCancel?: () => void;
  projectId?: number;
  highlight?: boolean;
  info?: string;
}

export const ToolOverviewCard: React.FC<ToolOverviewCardProps> = ({
  toolType,
  onToolAdd,
  onCancel,
  projectId,
  highlight,
  info,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const flags = useFlags();
  const { flagsReady, flagsError } = useFlagsStatus();

  const rootClassNames = [styles.root];

  const [toolOverview, setToolOverview] = useState<ToolOverview>();
  const [isFeaturesCheckPassed, setIsFeaturesCheckPassed] = useState(false);

  const isEnabled = useSelector(FeatureSetSelectors.isToolEnabled(toolType, projectId));

  useEffect(() => {
    setToolOverview(ToolTypeDataMap[toolType]);
  }, [toolType]);

  useEffect(() => {
    if (!toolOverview) {
      return null;
    }

    if (!toolOverview.features) {
      return setIsFeaturesCheckPassed(true);
    }

    if (flagsError) {
      return setIsFeaturesCheckPassed(false);
    }

    if (flagsReady && flags) {
      const flagsMap = flags.reduce((map: { [name: string]: boolean }, toggle) => {
        map[toggle.name] = toggle.enabled;
        return map;
      }, {});

      setIsFeaturesCheckPassed(toolOverview.features.every(f => flagsMap[f]));
    }
  }, [toolOverview, flags, flagsReady, flagsError]);

  const handleToolAddClick = () => {
    if (isEnabled) {
      onToolAdd();
    } else {
      dispatch(PaywallActions.show(FeatureLimit.Tools));
    }
  };

  const renderButtons = () => {
    return (
      <ButtonsWrap className={styles.buttonsWrap} spacing={1}>
        <Button variant={'pg_rounded'} color={'pg_orange_solid'} size={'pg_small'} onClick={handleToolAddClick}>
          <Trans>Use</Trans>
        </Button>

        {!!onCancel && (
          <Button variant={'pg_rounded'} color={'pg_orange_outlined'} size={'pg_small'} onClick={() => onCancel()}>
            <Trans>Cancel</Trans>
          </Button>
        )}
      </ButtonsWrap>
    );
  };

  if (highlight) {
    rootClassNames.push(styles.highlight);
  }

  if (!toolOverview || !isFeaturesCheckPassed) {
    return null;
  }

  return (
    <>
      <Paper className={rootClassNames.join(' ')} elevation={3}>
        {info && (
          <PopperWrap
            openHandler={'hover'}
            containerProps={{ className: styles.infoIconContainer }}
            placement={'top'}
            shadow
          >
            <Help className={styles.infoIcon} />

            <Box width={'100%'} maxWidth={400}>
              <Typography variant={'body1'}>{info}</Typography>
            </Box>
          </PopperWrap>
        )}

        <Box p={2} width={'100%'}>
          <Grid container alignItems={'center'}>
            {toolOverview.icon && (
              <Grid item xs={'auto'}>
                <Box pr={2}>
                  <toolOverview.icon className={styles.toolIcon} />
                </Box>
              </Grid>
            )}

            <Grid item xs>
              <Box pr={{ xs: 0, sm: 1 }}>
                <Typography className={styles.title} variant={'subtitle1'} component={'span'}>
                  <Trans>{toolOverview.name}</Trans>
                </Typography>
              </Box>

              {toolOverview.description && (
                <Box pr={{ xs: 0, sm: 1 }} mt={0.5}>
                  <Typography variant={'body2'} component={'div'} className={styles.description}>
                    <Trans>{toolOverview.description}</Trans>
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid item xs={12} sm={'auto'}>
              <Box mt={{ xs: 1, sm: 0 }} className={styles.controlsContainer}>
                {toolOverview.roles ? (
                  <>
                    <RenderForRoles roles={toolOverview.roles}>{renderButtons()}</RenderForRoles>

                    <RenderForRoles roles={toolOverview.roles} reverse>
                      <Button variant={'pg_rounded'} color={'pg_orange_solid'} size={'pg_small'} disabled>
                        <Trans>Coming soon</Trans>...
                      </Button>
                    </RenderForRoles>
                  </>
                ) : (
                  renderButtons()
                )}

                <Box mt={0.5}>
                  <PgLink className={styles.link} href={toolOverview.readMoreLink} target="_blank" rel="noopener">
                    <Trans>Learn more</Trans>
                  </PgLink>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  );
};
