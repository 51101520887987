import { handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { copyObject } from '@priz/shared/src/utils/common';
import { toObjectMap } from '../../../shared/store/reducer-utils';
import { ArrayUtil } from '../../../shared/utils/array-util';
import { Agenda, AgendasCollection, AgendasCollectionStatuses, DefaultAgendasCollection, IAgenda } from '../model';
import { AgendaAction, AgendaActionType, MeetingAction, MeetingActionType, MeetingFullUpdateAction } from '../actions';

const resolveStatuses = (
  projectId: number,
  state: AgendasCollection,
  statuses: Partial<EntityCollectionStatus>,
): AgendasCollectionStatuses => {
  const statusesCopy = copyObject(state.statuses);

  statusesCopy.byProjectId[projectId] = { ...(statusesCopy.byProjectId[projectId] || {}), ...statuses };

  return statusesCopy;
};

const setAgendasCollectionStatuses = (
  state: AgendasCollection,
  projectId: number,
  statuses: Partial<EntityCollectionStatus>,
): AgendasCollection => {
  return {
    ...state,
    statuses: resolveStatuses(projectId, state, statuses),
  };
};

const agendaInitializer = (agendaData: IAgenda): Agenda => new Agenda(agendaData);

const setAllAgendas = (
  state: AgendasCollection,
  projectId: number,
  agendasPayload: IAgenda[],
  meetingId?: number,
): AgendasCollection => {
  const newAgendaItemsMap = toObjectMap<Agenda>(agendasPayload, agendaInitializer);
  const lookupsCopy = copyObject(state.lookups);

  const entitiesCopy = {
    ...state.entities,
    ...newAgendaItemsMap,
  };

  if (meetingId) {
    const newAgendaItemsIds = Object.keys(newAgendaItemsMap);
    const deletedIds = [];

    Object.keys(entitiesCopy).forEach(id => {
      if (entitiesCopy[id].meeting.id === meetingId && !newAgendaItemsIds.includes(id)) {
        deletedIds.push(+id);
        delete entitiesCopy[id];
      }
    });

    if (deletedIds.length) {
      lookupsCopy.byProjectId[projectId] = lookupsCopy.byProjectId[projectId].filter(id => !deletedIds.includes(id));
    }
  }

  Object.values(newAgendaItemsMap).forEach((agenda: Agenda) => {
    lookupsCopy.byProjectId[projectId] = ArrayUtil.concatInDistinctArray(lookupsCopy.byProjectId[projectId], agenda.id);
  });

  return {
    ...state,
    statuses: resolveStatuses(projectId, state, { error: false, loaded: true, loading: false, updating: false }),
    entities: entitiesCopy,
    lookups: lookupsCopy,
  };
};

const setAgenda = (state: AgendasCollection, projectId: number, agendaPayload: IAgenda): AgendasCollection => {
  const newAgenda = agendaInitializer(agendaPayload);

  const entitiesCopy = {
    ...state.entities,
    [newAgenda.id]: newAgenda,
  };

  const lookupsCopy = copyObject(state.lookups);

  lookupsCopy.byProjectId[projectId] = ArrayUtil.concatInDistinctArray(
    lookupsCopy.byProjectId[projectId],
    newAgenda.id,
  );

  return {
    ...state,
    statuses: resolveStatuses(projectId, state, { error: false, creating: false, updating: false }),
    entities: entitiesCopy,
    lookups: lookupsCopy,
  };
};

const removeAgenda = (state: AgendasCollection, projectId: number, agendaId: number): AgendasCollection => {
  const entitiesCopy = { ...state.entities };
  const lookupsCopy = copyObject(state.lookups);

  delete entitiesCopy[agendaId];

  lookupsCopy.byProjectId[projectId] = lookupsCopy.byProjectId[projectId].filter(id => id !== agendaId);

  return {
    ...state,
    statuses: resolveStatuses(projectId, state, { error: false, removing: false }),
    entities: entitiesCopy,
    lookups: lookupsCopy,
  };
};

export const agendasReducers = handleActions(
  {
    // FetchAll

    [toStarted(AgendaActionType.FetchAll)]: (state: AgendasCollection, action: AgendaAction) =>
      setAgendasCollectionStatuses(state, action.meta.projectId, { loading: true }),

    [toFailed(AgendaActionType.FetchAll)]: (state: AgendasCollection, action: AgendaAction) =>
      setAgendasCollectionStatuses(state, action.meta.projectId, { loading: false, error: true }),

    [toSuccess(AgendaActionType.FetchAll)]: (state: AgendasCollection, action: AgendaAction) =>
      setAllAgendas(state, action.meta.projectId, action.payload as IAgenda[]),

    // Fetch

    [toStarted(AgendaActionType.Fetch)]: (state: AgendasCollection, action: AgendaAction) =>
      setAgendasCollectionStatuses(state, action.meta.projectId, { loading: true }),

    [toFailed(AgendaActionType.Fetch)]: (state: AgendasCollection, action: AgendaAction) =>
      setAgendasCollectionStatuses(state, action.meta.projectId, { loading: false, error: true }),

    [toSuccess(AgendaActionType.Fetch)]: (state: AgendasCollection, action: AgendaAction) =>
      setAgenda(state, action.meta.projectId, action.payload as IAgenda),

    // Create

    [toStarted(AgendaActionType.Create)]: (state: AgendasCollection, action: AgendaAction) =>
      setAgendasCollectionStatuses(state, action.meta.projectId, { creating: true }),

    [toFailed(AgendaActionType.Create)]: (state: AgendasCollection, action: AgendaAction) =>
      setAgendasCollectionStatuses(state, action.meta.projectId, { creating: true }),

    [toSuccess(AgendaActionType.Create)]: (state: AgendasCollection, action: AgendaAction) =>
      setAgenda(state, action.meta.projectId, action.payload as IAgenda),

    // Update

    [toStarted(AgendaActionType.Update)]: (state: AgendasCollection, action: AgendaAction) =>
      setAgendasCollectionStatuses(state, action.meta.projectId, { updating: true }),

    [toFailed(AgendaActionType.Update)]: (state: AgendasCollection, action: AgendaAction) =>
      setAgendasCollectionStatuses(state, action.meta.projectId, { updating: false, error: true }),

    [toSuccess(AgendaActionType.Update)]: (state: AgendasCollection, action: AgendaAction) =>
      setAgenda(state, action.meta.projectId, action.payload as IAgenda),

    // Delete

    [toStarted(AgendaActionType.Delete)]: (state: AgendasCollection, action: AgendaAction) =>
      setAgendasCollectionStatuses(state, action.meta.projectId, { removing: true }),

    [toFailed(AgendaActionType.Delete)]: (state: AgendasCollection, action: AgendaAction) =>
      setAgendasCollectionStatuses(state, action.meta.projectId, { removing: false, error: true }),

    [toSuccess(AgendaActionType.Delete)]: (state: AgendasCollection, action: AgendaAction) =>
      removeAgenda(state, action.meta.projectId, action.meta.agendaId),

    // Meeting FullUpdate

    [toStarted(MeetingActionType.FullUpdate)]: (state: AgendasCollection, action: MeetingAction) =>
      setAgendasCollectionStatuses(state, action.meta.projectId, { updating: true }),

    [toFailed(MeetingActionType.FullUpdate)]: (state: AgendasCollection, action: MeetingAction) =>
      setAgendasCollectionStatuses(state, action.meta.projectId, { updating: false, error: true }),

    [toSuccess(MeetingActionType.FullUpdate)]: (state: AgendasCollection, action: MeetingFullUpdateAction) =>
      setAllAgendas(state, action.meta.projectId, action.payload.agenda, action.payload.meeting.id),
  },
  DefaultAgendasCollection,
);
