import React, { useState } from 'react';
import { ToolUtilization } from '@priz/shared/src/models/tools';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import { Trans } from 'react-i18next';
import { ToolTitle } from '../tool-title/component';
import { format } from 'date-fns';
import { LinkCard } from '../../link-card/component';
import { RowToolCard } from '../row-tool-card/component';
import { WorkspaceMember } from '@priz/shared/src/models/workspace';
import { User } from '@priz/shared/src/models/security/user';
import { UserAvatar } from '@priz/shared/src/components/user-avatar/component';
import { ToolsUtils } from '../utils';
import { DeleteOutlined, HandymanOutlined } from '@mui/icons-material';
import { ToolTypeDataMap } from '@priz/shared/src/data/tool-type-data-map';
import { DeleteToolButton } from '../delete-tool-button/component';

interface ToolCardProps {
  tool: ToolUtilization;
  childrenTools?: ToolUtilization[];
  author?: User | WorkspaceMember;
}

export const ToolCard: React.FC<ToolCardProps> = ({ tool, childrenTools, author }) => {
  const [isChildToolsDialogOpen, setIsChildToolsDialogOpen] = useState(false);

  const preventClickEvents = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const openChildToolsDialog = (e: React.MouseEvent) => {
    preventClickEvents(e);
    setIsChildToolsDialogOpen(true);
  };

  const closeChildToolsDialog = () => {
    setIsChildToolsDialogOpen(false);
  };

  return (
    <>
      <LinkCard to={ToolsUtils.resolvePathByUtilization(tool)}>
        <Box p={2}>
          <Grid container spacing={1} justifyContent={'flex-start'}>
            <Grid item>
              <Chip
                label={<Trans>Tool</Trans>}
                icon={<HandymanOutlined />}
                color={'primary'}
                variant={'filled'}
                size={'small'}
                sx={{
                  borderRadius: 1,
                }}
              />
            </Grid>

            <Grid item>
              <Chip
                label={<Trans>{ToolTypeDataMap[tool.type]?.name}</Trans>}
                color={'default'}
                variant={'outlined'}
                size={'small'}
              />
            </Grid>
          </Grid>
        </Box>

        <Box px={2} mb={1}>
          <ToolTitle tool={tool} editable={!tool.completed} disableIcon typographyProps={{ variant: 'h6' }} />

          {childrenTools?.length > 0 && (
            <Box mt={1}>
              <Link component={'span'} onClick={openChildToolsDialog}>
                <Trans>Show child tools</Trans> ({childrenTools.length})
              </Link>
            </Box>
          )}
        </Box>

        <Box px={2} mb={1}>
          <Typography component={Box} variant={'caption'} color={'text_color.light'}>
            <Trans>Used at</Trans>: {format(tool.dateCreated, 'MMM d yyyy h:mm:ss aaa')}
          </Typography>
        </Box>

        <Box pl={2} pr={1} pb={1}>
          <Grid container justifyContent={'space-between'} spacing={1}>
            <Grid item>{author && <UserAvatar user={author} size={28} showName />}</Grid>

            <Grid item>
              <div onClick={preventClickEvents}>
                <DeleteToolButton
                  utilization={tool}
                  customButton={
                    <IconButton color={'error'} size={'small'}>
                      <DeleteOutlined />
                    </IconButton>
                  }
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </LinkCard>

      {childrenTools?.length > 0 && (
        <Dialog open={isChildToolsDialogOpen} fullWidth maxWidth={'md'}>
          <DialogTitle>{tool.title || ToolTypeDataMap[tool.type]?.name || ''}</DialogTitle>

          <DialogContent>
            {childrenTools.map((child, key) => {
              return (
                <Box key={child.id} mb={key === childrenTools.length - 1 ? 0 : 1}>
                  <RowToolCard tool={child} />
                </Box>
              );
            })}
          </DialogContent>

          <DialogActions>
            <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={closeChildToolsDialog}>
              <Trans>Close</Trans>
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
