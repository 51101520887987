import { EntityCollectionStatus, EntityMap, LookupMap } from '@priz/shared/src/models/common/entity-collection-state';
import { Agenda } from './agenda';

export interface AgendasLookups {
  byProjectId: LookupMap;
}

export interface AgendasCollectionStatuses {
  byProjectId?: { [projectId: number]: EntityCollectionStatus };
}

export interface AgendasCollection {
  entities: EntityMap<Agenda>;

  lookups: AgendasLookups;

  statuses: AgendasCollectionStatuses;
}

export const DefaultAgendasCollection: AgendasCollection = {
  entities: {},

  lookups: {
    byProjectId: {},
  },

  statuses: {
    byProjectId: {},
  },
};
