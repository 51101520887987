import { EntityMap } from '../../models/common/entity-collection-state';
import { Idea } from '../../models/idea/idea';
import { ProjectAssigneeWithMember } from '../../models/assignment';

export const filterRelatedIdeas = (ideas: EntityMap<Idea>, utilizationId: number) => {
  return Object.values(ideas).filter((idea: Idea) => idea?.sourceToolUtilization?.id === utilizationId);
};

export const resolveIdeaAuthor = (
  idea: Idea,
  assignees: ProjectAssigneeWithMember[] = [],
): ProjectAssigneeWithMember | undefined => {
  return assignees.find(item => item.userId === idea.createdBy.id);
};
