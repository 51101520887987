import React from 'react';
import { Goal } from '../../../models/tools/rrm';
import { RrmRankingSummaryTable } from '../../../components/rrm-summary-table/component';

export interface RrmViewerProps {
  goal: Goal;
}

export const RrmViewer: React.FC<RrmViewerProps> = ({ goal }) => {
  if (!goal) return null;

  return <RrmRankingSummaryTable goal={goal} />;
};
