import { WorkspaceMembershipLevel } from '@priz/shared/src/models/workspace';
import { IUser } from '@priz/shared/src/models/security/user';

export interface WorkspaceMembershipData {
  id: number;
  membershipLevel: WorkspaceMembershipLevel;
}

export interface WorkspaceProjectsCountMap {
  [workspaceId: number]: number;
}

export interface UserContext {
  currentUser?: IUser;
  userWorkspaces: WorkspaceMembershipData[];
  workspaceProjectsCountMap: WorkspaceProjectsCountMap;
  loading: boolean;
  loaded: boolean;
}

export const EmptyUserContext: UserContext = {
  userWorkspaces: [],
  workspaceProjectsCountMap: {},
  loaded: false,
  loading: false,
};
