import React, { useEffect, useState } from 'react';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  Pagination,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { AppNavbar } from '../../navigation/app-navbar/component';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AdminCommentsApi, ProjectCommentsDeleteCommand } from '../services/comments.api';
import { Trans } from 'react-i18next';
import { Comment } from '../comment/component';
import { useStyles } from './styles';
import { LinearLoader } from '../../react/elements/linear-loader/component';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { LoadingButton } from '@mui/lab';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { ProjectComment } from '@priz/shared/src/models/project';

const commentPerPage = 30;

enum SelectionType {
  Empty = 'Empty',
  Full = 'Full',
  Partial = 'Partial',
}

const resolveSelectionType = (loaded: number, selected: number): SelectionType => {
  if (!selected) return SelectionType.Empty;
  if (selected && selected !== loaded) return SelectionType.Partial;
  if (selected && selected === loaded) return SelectionType.Full;

  return SelectionType.Empty;
};

export const CommentsManager: React.FC = () => {
  const styles = useStyles();
  const queryClient = useQueryClient();

  const [page, setPage] = useState(0);
  const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [loadingCommentIds, setLoadingCommentIds] = useState<number[]>([]);
  const [selectedCommentsIds, setSelectedCommentsIds] = useState<number[]>([]);

  const { isLoading, isFetching, data, refetch } = useQuery(['comments', page], () => AdminCommentsApi.list(page), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const pagesCount = data?.count ? Math.ceil(data.count / commentPerPage) : 1;
  const loadedCommentsCount = data?.comments?.length || 0;
  const selectedCommentsCount = selectedCommentsIds.length;
  const selectionType = resolveSelectionType(loadedCommentsCount, selectedCommentsCount);

  const errorCallback = () => {
    setLoadingCommentIds([]);
    openErrorSnackbar();
    void refetch();
  };

  const singleMutationCallback = {
    onSuccess: (comment: ProjectComment) => {
      removeLoadingIds([comment.id]);
      void queryClient.invalidateQueries(['comments', page]);
    },
    onError: errorCallback,
  };

  const bulkMutationCallback = {
    onSuccess: (comments: ProjectComment[]) => {
      const updatedIds = (comments || []).map(c => c.id);

      removeLoadingIds(updatedIds);
      setSelectedCommentsIds(currentState => [...currentState].filter(id => !updatedIds.includes(id)));
      void queryClient.invalidateQueries(['comments', page]);
    },
    onError: errorCallback,
  };

  const approveMutation = useMutation(
    (props: { id: number; version: number }) => AdminCommentsApi.approve(props.id, props.version),
    singleMutationCallback,
  );

  const discardMutation = useMutation(
    (props: { id: number; version: number }) => AdminCommentsApi.discard(props.id, props.version),
    singleMutationCallback,
  );

  const deleteMutation = useMutation(
    (props: { id: number }) => AdminCommentsApi.delete(props.id),
    singleMutationCallback,
  );

  const selectedDeleteMutation = useMutation(
    (props: { command: ProjectCommentsDeleteCommand }) => AdminCommentsApi.deleteByIds(props.command),
    bulkMutationCallback,
  );

  useEffect(() => {
    if (typeof page !== 'number' || typeof pagesCount !== 'number') return null;
    if (page + 1 > pagesCount) setPage(pagesCount - 1);
  }, [page, pagesCount]);

  const changePageHandler = (_event: React.ChangeEvent<unknown>, value: number) => {
    setSelectedCommentsIds([]);
    setPage(value - 1);
  };

  const deleteHandler = (id: number) => {
    addLoadingIds([id]);
    deleteMutation.mutate({ id });
  };

  const approveHandler = (id: number, version: number) => {
    addLoadingIds([id]);
    approveMutation.mutate({ id, version });
  };

  const discardHandler = (id: number, version: number) => {
    addLoadingIds([id]);
    discardMutation.mutate({ id, version });
  };

  const deleteSelectedHandler = () => {
    const command: ProjectCommentsDeleteCommand = {
      ids: selectedCommentsIds,
    };

    closeDeleteConfirmation();
    addLoadingIds(selectedCommentsIds);
    selectedDeleteMutation.mutate({ command });
  };

  const addLoadingIds = (ids: number[]) => {
    setLoadingCommentIds(currentState => [...currentState, ...ids]);
  };

  const removeLoadingIds = (ids: number[]) => {
    setLoadingCommentIds(currentState => [...currentState].filter(id => !ids.includes(id)));
  };

  const selectHandler = (id: number, selected: boolean) => {
    setSelectedCommentsIds(currentState =>
      selected ? [...currentState, id] : [...currentState].filter(i => i !== id),
    );
  };

  const selectionManagerHandler = () => {
    if (selectionType === SelectionType.Empty) {
      setSelectedCommentsIds((data?.comments || []).filter(c => !loadingCommentIds.includes(c.id)).map(c => c.id));
    }

    if ([SelectionType.Partial, SelectionType.Full].includes(selectionType)) {
      setSelectedCommentsIds([]);
    }
  };

  const openErrorSnackbar = () => {
    setIsErrorSnackbarOpen(true);
  };

  const closeErrorSnackbar = () => {
    setIsErrorSnackbarOpen(false);
  };

  const openDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
  };

  return (
    <>
      <AppNavbar />

      <ContentContainer>
        <PageTitleWithDocLink title={'Comments manager'} />

        {data && !data.count && (
          <Box my={2}>
            <Alert severity={'success'}>
              <Trans>All comments are approved</Trans>
            </Alert>
          </Box>
        )}

        {isLoading && (
          <Box py={20} textAlign={'center'}>
            <CircularProgress />
          </Box>
        )}

        <LinearLoader loading={!isLoading && isFetching} />

        {data && !!loadedCommentsCount && (
          <Box>
            {data.comments.map((comment, key) => {
              const loading = loadingCommentIds.includes(comment.id);
              const contentClassNames = [styles.commentContent];

              if (loading) contentClassNames.push(styles.disabled);

              return (
                <Box key={comment.id} mt={key ? 1 : 0} className={styles.comment}>
                  <Box className={contentClassNames.join(' ')}>
                    <Comment
                      comment={comment}
                      onApprove={approveHandler}
                      onDiscard={discardHandler}
                      onDelete={deleteHandler}
                      onSelectToggle={selectHandler}
                      selected={selectedCommentsIds.includes(comment.id)}
                    />
                  </Box>

                  {loading && (
                    <Box className={styles.loadingOverlay}>
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        )}
      </ContentContainer>

      <ContentFooter>
        <Paper component={Box} elevation={2} square py={1} px={4} sx={{ position: 'relative', zIndex: 10 }}>
          <Grid container justifyContent={'space-between'} alignItems={'center'} spacing={2}>
            <Grid item>
              <Grid container alignItems={'center'} spacing={1}>
                <Grid item>
                  <Tooltip
                    title={
                      <Trans>
                        {[SelectionType.Partial, SelectionType.Full].includes(selectionType)
                          ? 'Deselect all'
                          : 'Select all'}
                      </Trans>
                    }
                    placement={'top'}
                    disableInteractive
                    arrow
                  >
                    <Checkbox
                      checked={[SelectionType.Partial, SelectionType.Full].includes(selectionType)}
                      indeterminate={selectionType === SelectionType.Partial}
                      onClick={selectionManagerHandler}
                      disabled={!loadedCommentsCount}
                    />
                  </Tooltip>
                </Grid>

                <Grid item>
                  <Typography variant={'subtitle2'} color={'text_color.light'} component={Box} pr={1}>
                    <Trans>selected</Trans>: {selectedCommentsCount}/{loadedCommentsCount}
                  </Typography>
                </Grid>

                <Grid item>
                  <LoadingButton
                    variant={'outlined'}
                    color={'error'}
                    size={'small'}
                    disabled={!selectedCommentsCount || !!loadingCommentIds.length}
                    loading={loadingCommentIds.some(id => selectedCommentsIds.includes(id))}
                    onClick={openDeleteConfirmation}
                  >
                    <Trans>Remove selected</Trans>
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              {data && pagesCount > 1 && <Pagination page={page + 1} count={pagesCount} onChange={changePageHandler} />}
            </Grid>
          </Grid>
        </Paper>
      </ContentFooter>

      <PgConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        confirmTitle={<Trans>Confirm deletion</Trans>}
        okButtonText={<Trans>Delete</Trans>}
        confirmContent={
          <React.Fragment>
            <Trans>Are you sure you want to delete selected comments?</Trans>
          </React.Fragment>
        }
        onConfirm={deleteSelectedHandler}
        onClose={closeDeleteConfirmation}
      />

      <Snackbar
        open={isErrorSnackbarOpen}
        onClose={closeErrorSnackbar}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Alert onClose={closeErrorSnackbar} severity={'error'} sx={{ width: '100%' }}>
          <Trans>Unable to update or delete comment. Comments list reloaded.</Trans>
        </Alert>
      </Snackbar>
    </>
  );
};
