import React, { useState } from 'react';
import { Certificate } from '../store';
import { Project } from '@priz/shared/src/models/project';
import { User } from '@priz/shared/src/models/security/user';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { EditCertificateDetails } from '../edit-certificate-details/component';
import { TabPanel } from '../../react/elements/tab-panel/component';
import { EditCertificateFullDocument } from '../edit-certificate-full-document/component';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';

export interface EditCertificateProps {
  user: User;
  project: Project;
  certificate: Certificate;
}

export const EditCertificate: React.FC<EditCertificateProps> = ({ project, user, certificate }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (_event, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Box mb={2}>
        <PageTitleWithDocLink
          title={<Trans>Certificate Draft</Trans>}
          oneLiner={<Trans>Update your certificate details.</Trans>}
        />

        <Typography variant={'body2'}>
          <Trans>
            On this phase of the certification process, is where you are going to edit the copy for your final
            certificate for your ideas.
          </Trans>
        </Typography>
      </Box>

      {!certificate.hasFullDocument && (
        <EditCertificateDetails user={user} project={project} certificate={certificate} />
      )}

      {certificate.hasFullDocument && (
        <>
          <Tabs orientation="horizontal" variant="standard" value={activeTab} onChange={handleChangeTab}>
            <Tab label={<Trans>parameters</Trans>} />
            <Tab label={<Trans>domains</Trans>} />
          </Tabs>

          <TabPanel value={activeTab} index={0}>
            <EditCertificateDetails user={user} project={project} certificate={certificate} />
          </TabPanel>

          <TabPanel value={activeTab} index={1}>
            <EditCertificateFullDocument project={project} certificate={certificate} />
          </TabPanel>
        </>
      )}
    </>
  );
};
