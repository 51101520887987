import { IToolUtilization, ToolUtilization } from '../tool-utilization';
import { UimGoal } from './uim-goal';

export interface IUimUtilization extends IToolUtilization {
  uimGoal?: UimGoal;
}

export class UimUtilization extends ToolUtilization implements IUimUtilization {
  public uimGoal?: UimGoal;

  constructor(json: IUimUtilization) {
    super(json);

    this.uimGoal = json.uimGoal || UimGoal.Self;
  }
}
