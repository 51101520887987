import React from 'react';
import { Box, Button, Grid, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { OperationMeritLabel } from '../operation-merit-label/component';
import { OperationMeritType, SfmDiagramDataVersions, SfmVersionsRanksMap } from '../../models/tools/sfm';
import { useStyles } from './styles';
import { CheckCircleRounded } from '@mui/icons-material';
import { OperationPerfectnessLabel } from '../operation-perfectness-label/component';
import { ToolVersionUtils } from '../../utils/tools';

interface SfmVersionsComparisonProps {
  versions: SfmDiagramDataVersions;
  ranksMap: SfmVersionsRanksMap;
  mainVersionId?: string;
  activeVersionId?: string;
  onVersionSelect?: (versionId: string) => void;
}

export const SfmVersionsComparison: React.FC<SfmVersionsComparisonProps> = ({
  versions,
  mainVersionId,
  activeVersionId,
  onVersionSelect,
  ranksMap,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const { t } = useTranslation();

  const isDownMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const isDownSmBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  const orderedVersionsKeys = versions ? ToolVersionUtils.getOrderedVersionsKeys(versions) : [];
  const columnsCount = versions ? Object.keys(versions).length : 1;
  const columnWidth = isDownSmBreakpoint ? 100 : Math.max(100 / columnsCount, isDownMdBreakpoint ? 50 : 20);

  return (
    <Box className={styles.root} mt={-1}>
      {orderedVersionsKeys.map((versionId, key) => {
        const sfmRank = ranksMap[versionId];
        const title = versions[versionId]?.title || `${t('Version')} ${key + 1}`;
        const isActive = versionId === activeVersionId;
        const isMain = versionId === mainVersionId;
        const colClassNames = [styles.col, isActive ? styles.colActive : styles.colDefault];

        if (isMain) colClassNames.push(styles.colMain);

        return (
          <Box key={versionId} pt={1} pr={1} width={`${columnWidth}%`} boxSizing={'border-box'}>
            <Box className={colClassNames.join(' ')}>
              <Box p={1} className={styles.headerCell}>
                <Box display={'flex'} width={'100%'}>
                  {isMain && (
                    <Box pr={0.5}>
                      <Tooltip title={<Trans>Main</Trans>} arrow={true} disableInteractive={true} placement={'top'}>
                        <CheckCircleRounded fontSize="small" color={'success'} sx={{ display: 'block' }} />
                      </Tooltip>
                    </Box>
                  )}

                  <Tooltip title={title} arrow={true} disableInteractive={true} placement={'top'}>
                    <Typography variant={'subtitle2'} noWrap>
                      {title}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>

              <Box p={1}>
                <Grid container justifyContent={'space-between'} alignItems={'center'} spacing={1}>
                  <Grid item>
                    {sfmRank && (
                      <Grid container spacing={1}>
                        <Grid item>
                          <OperationMeritLabel operationMerit={sfmRank.operationMerit} useTooltip={true} />
                        </Grid>

                        <Grid item>
                          <OperationPerfectnessLabel
                            operationPerfectness={sfmRank.operationPerfectness}
                            useTooltip={true}
                          />
                        </Grid>
                      </Grid>
                    )}

                    {sfmRank && sfmRank.operationMerit.type === OperationMeritType.NotDefined && (
                      <Typography variant={'body2'}>
                        <Trans>No data</Trans>
                      </Typography>
                    )}
                  </Grid>

                  <Grid item>
                    <Button
                      size={'small'}
                      variant={'outlined'}
                      onClick={() => onVersionSelect(versionId)}
                      disabled={isActive}
                      sx={{ lineHeight: 1.2 }}
                    >
                      <Trans>{isActive ? 'Selected' : 'Select'}</Trans>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
