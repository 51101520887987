import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';
import { alpha } from '@mui/material';

export const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    lineHeight: 1,
    borderRadius: 4,
    maxWidth: '100%',
    boxSizing: 'border-box',
    cursor: 'default',
  },
  numberOperationMerit: {
    color: pgColorScheme.darkText,
    background: pgColorScheme.background,
    padding: '1px 2px 1px 8px',
  },
  idealOperationMerit: {
    color: pgColorScheme.blue,
    background: pgColorScheme.background,
    padding: '4px 8px',
  },
  harmfulOperationMerit: {
    color: pgColorScheme.coral,
    background: pgColorScheme.background,
    padding: '4px 8px',
  },
  notDefinedOperationMerit: {
    color: pgColorScheme.textGray,
    background: pgColorScheme.background,
    padding: '4px 8px',
  },
  highEfficiency: {
    background: alpha(pgColorScheme.green, 0.2),
  },
  lowEfficiency: {
    background: alpha(pgColorScheme.orange, 0.2),
  },
  lowestEfficiency: {
    background: alpha(pgColorScheme.coral, 0.2),
  },
});
