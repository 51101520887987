import React, { useState } from 'react';
import { SfmUtilization } from '@priz/shared/src/models/tools/sfm';
import { componentRecommendationsDescriptionMap } from './data';
import {
  Alert,
  AlertProps,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { ExpandMoreRounded, ExpandLessRounded, CheckRounded, CloseRounded } from '@mui/icons-material';
import { TimeZoneService } from '@priz/shared/src/services/time';
import { useDispatch, useSelector } from 'react-redux';
import { SfmResultSelectors } from '../store/selectors';
import { getSfmEdgesMap, getSfmNodesMap, resolveSfmNetworkData } from '@priz/shared/src/utils/sfm';
import { ComponentImprovementIteration, ComponentProblematicType, ComponentRecommendationType } from '../store/model';
import { RankChangeResult } from '../sfm-component-fixes/rank-change-result/component';
import { MeritChangeResult } from '../sfm-component-fixes/merit-change-result/component';
import { PerfectnessChangeResult } from '../sfm-component-fixes/perfectness-change-result/component';
import { useStyles } from './styles';
import { SfmUtilizationActions } from '../store/actions';
import { SfmComponentRecommendationCommand } from '../store/services/sfm-utilization.api';
import { FieldTitle } from '@priz/shared/src/components/form-elements';
import { SfmComponentLabel } from '@priz/shared/src/components/sfm-component-label/component';
import { SfmEdgeLabel } from '@priz/shared/src/components/sfm-edge-label/component';
import { NewFortyPrinciplesUtilizationCommand } from '../../forty-principles/services/forty-principles-utilization.service';
import { FortyPrinciplesUtilizationActions } from '../../forty-principles/store/actions';
import { useNavigate } from 'react-router-dom';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { LoadingButton } from '@mui/lab';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { Link as RouterLink } from 'react-router-dom';
import { ToolsUtils } from '../../tools/utils';
import { ProjectFeatureBlockerActions } from '../../project-feature-blocker/store/actions';
import { ProjectFeatureBlockerType } from '../../project-feature-blocker/store/model';
import { ToolVersionUtils } from '@priz/shared/src/utils/tools';

export interface SfmComponentImprovementBaseProps {
  utilization: SfmUtilization;
  versionId: string;
  componentId?: string;
  editable?: boolean;
}

interface SfmComponentImprovementIterationProps extends SfmComponentImprovementBaseProps, AlertProps {
  improvementIteration: ComponentImprovementIteration;
  iterationId?: number;
  improvementId?: number;
  defaultExpanded?: boolean;
  showComponent?: boolean;
}

export const SfmComponentImprovementIteration: React.FC<SfmComponentImprovementIterationProps> = ({
  utilization,
  versionId,
  componentId,
  iterationId,
  improvementId,
  improvementIteration,
  defaultExpanded,
  editable,
  showComponent,
  ...rest
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    problematicType,
    recommendations,
    completed,
    dateCreatedMs,
    initialComponentRank,
    resultComponentRank,
    initialMerit,
    resultMerit,
    initialPerfectness,
    resultPerfectness,
    mostUsefulFunctionId,
    mostHarmfulFunctionId,
  } = improvementIteration || {};

  const { title, descriptions = {} } = componentRecommendationsDescriptionMap[problematicType] || {};

  const [isExpanded, setIsExpanded] = useState(defaultExpanded || false);

  const isLoading = useSelector(SfmResultSelectors.isLoadingByEntityStatusId(utilization?.id, componentId));
  const isUpdating = useSelector(ToolUtilizationSelector.isUpdatingByUtilizationId(utilization?.id));
  const related40p = useSelector(ToolUtilizationSelector.get40PForImprovementIteration(improvementId, iterationId));

  const networkData = resolveSfmNetworkData(utilization?.diagramData, versionId);
  const nodesMap = getSfmNodesMap(networkData);
  const edgesMap = getSfmEdgesMap(networkData);

  const mostUsefulFunction = mostUsefulFunctionId && edgesMap[mostUsefulFunctionId];
  const mostUsefulSource = mostUsefulFunction && nodesMap[mostUsefulFunction.from];
  const mostUsefulTarget = mostUsefulFunction && nodesMap[mostUsefulFunction.to];

  const mostHarmfulFunction = mostHarmfulFunctionId && edgesMap[mostHarmfulFunctionId];
  const mostHarmfulSource = mostHarmfulFunction && nodesMap[mostHarmfulFunction.from];
  const mostHarmfulTarget = mostHarmfulFunction && nodesMap[mostHarmfulFunction.to];

  const fortyPrinciplesAvailabilityMap = {
    [ComponentProblematicType.Conflict]: mostUsefulSource && mostUsefulTarget && mostHarmfulSource && mostHarmfulTarget,
    [ComponentProblematicType.Ineffective]: mostUsefulSource && mostUsefulTarget,
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (componentId && !isLoading) {
      const command: SfmComponentRecommendationCommand = {
        type: e.target.name as ComponentRecommendationType,
        done: e.target.checked,
      };

      dispatch(
        SfmUtilizationActions.updateComponentRecommendation(
          utilization.id,
          versionId,
          componentId,
          command,
          utilization.project?.id,
        ),
      );
    }
  };

  const setLastViewedVersion = () => {
    LocalStorageService.setItem(LocalStorageKey.LastViewedToolVersion, versionId);
  };

  const use40PHandler = () => {
    const versionTitle = ToolVersionUtils.getVersionTitle(utilization?.diagramData, versionId);
    const { label } = nodesMap[componentId] || {};
    const title = [];

    if (versionTitle) {
      title.push(`${t('Version')}: ${versionTitle}`);
    }

    if (label) {
      title.push(`${t('Component')}: ${label}`);
    }

    const command: NewFortyPrinciplesUtilizationCommand = {
      title: title.join(', '),
      parentId: utilization.id,
      sourceImprovementId: improvementId,
      sourceImprovementIterationId: iterationId,
    };

    if (problematicType === ComponentProblematicType.Conflict) {
      command.contradictionIf = [mostUsefulSource?.label, t('remains unchanged')].join(' ');

      command.contradictionThen = [mostUsefulSource?.label, mostUsefulFunction?.label, mostUsefulTarget?.label].join(
        ' ',
      );

      command.contradictionBut = [mostHarmfulSource?.label, mostHarmfulFunction?.label, mostHarmfulTarget?.label].join(
        ' ',
      );
    }

    if (problematicType === ComponentProblematicType.Ineffective) {
      command.contradictionIf = [mostUsefulSource?.label, t('remains unchanged')].join(' ');

      command.contradictionThen = [mostUsefulSource?.label, mostUsefulFunction?.label, mostUsefulTarget?.label].join(
        ' ',
      );

      command.contradictionBut = t('the cost of the component is high');
    }

    setLastViewedVersion();

    if (typeof utilization?.project?.id !== 'undefined') {
      dispatch(FortyPrinciplesUtilizationActions.create(command, utilization.project.id, navigate));
    } else {
      dispatch(
        ProjectFeatureBlockerActions.set({
          utilizationId: utilization.id,
          blocker: ProjectFeatureBlockerType.FortyPrinciples,
          fortyPrinciplesCommand: command,
        }),
      );
    }
  };

  return (
    <Box mb={1}>
      <Alert
        severity={completed ? 'success' : 'error'}
        action={
          <IconButton onClick={() => setIsExpanded(v => !v)} size={'small'} sx={{ padding: '3px' }}>
            {isExpanded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
          </IconButton>
        }
        classes={{
          message: styles.message,
        }}
        {...rest}
      >
        <Grid container spacing={2} alignItems={'center'}>
          {showComponent && (
            <Grid item xs={'auto'}>
              <SfmComponentLabel text={nodesMap[componentId]?.label} short />
            </Grid>
          )}

          <Grid item xs={true}>
            <Trans>{title}</Trans>
          </Grid>

          <Grid item xs={'auto'}>
            <Typography variant={'subtitle2'}>{TimeZoneService.format(dateCreatedMs)}</Typography>
          </Grid>
        </Grid>

        <Collapse in={isExpanded}>
          <Box pt={2} pb={1} width={'100%'}>
            <Paper variant={'outlined'} component={Box} p={2}>
              {recommendations.map(({ type, done }) => {
                const description = descriptions[type] || type;
                const useCheckboxes = !completed && editable;

                if ((completed && !done) || !description) return null;

                return (
                  <div key={type}>
                    {useCheckboxes && (
                      <FormControlLabel
                        onChange={changeHandler}
                        control={<Checkbox name={type} checked={done} />}
                        label={<Trans>{description}</Trans>}
                        disabled={isLoading || isUpdating}
                      />
                    )}

                    {!useCheckboxes && (
                      <Grid container spacing={1} flexWrap={'nowrap'}>
                        <Grid item xs={'auto'}>
                          {done ? (
                            <CheckRounded fontSize={'small'} color={'success'} />
                          ) : (
                            <CloseRounded fontSize={'small'} color={'error'} />
                          )}
                        </Grid>

                        <Grid item xs={true}>
                          <Typography variant={'body1'}>
                            <Trans>{description}</Trans>
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                );
              })}

              {!completed && editable && fortyPrinciplesAvailabilityMap[problematicType] && (
                <Box mt={2}>
                  <Grid container spacing={4}>
                    {mostUsefulSource && mostUsefulTarget && mostUsefulFunction && (
                      <Grid item xs={6}>
                        <FieldTitle
                          text={
                            <>
                              <Trans>Most useful function</Trans>:
                            </>
                          }
                        />

                        <Grid container alignItems={'center'}>
                          <Grid item>
                            <SfmComponentLabel text={mostUsefulSource.label} type={mostUsefulSource.type} short />
                          </Grid>

                          <Grid item xs={true}>
                            <SfmEdgeLabel
                              text={mostUsefulFunction.label}
                              type={mostUsefulFunction.type}
                              justifyContent={'center'}
                            />
                          </Grid>

                          <Grid item>
                            <SfmComponentLabel text={mostUsefulTarget.label} type={mostUsefulTarget.type} short />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {mostHarmfulSource && mostHarmfulTarget && mostHarmfulFunction && (
                      <Grid item xs={6}>
                        <FieldTitle
                          text={
                            <>
                              <Trans>Most harmful function</Trans>:
                            </>
                          }
                        />

                        <Grid container alignItems={'center'}>
                          <Grid item>
                            <SfmComponentLabel text={mostHarmfulSource.label} type={mostHarmfulSource.type} short />
                          </Grid>

                          <Grid item xs={true}>
                            <SfmEdgeLabel
                              text={mostHarmfulFunction.label}
                              type={mostHarmfulFunction.type}
                              justifyContent={'center'}
                            />
                          </Grid>

                          <Grid item>
                            <SfmComponentLabel text={mostHarmfulTarget.label} type={mostHarmfulTarget.type} short />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Box mt={2}>
                    {related40p && (
                      <Button
                        variant={'outlined'}
                        size={'small'}
                        component={RouterLink}
                        to={ToolsUtils.resolvePathByUtilization(related40p)}
                        onClick={setLastViewedVersion}
                      >
                        <Trans>Open "40 Inventive principles" tool</Trans>
                      </Button>
                    )}

                    {!related40p && (
                      <LoadingButton
                        variant={'outlined'}
                        size={'small'}
                        loading={isUpdating}
                        disabled={isUpdating}
                        onClick={use40PHandler}
                      >
                        <Trans>Use "40 Inventive principles" tool</Trans>
                      </LoadingButton>
                    )}
                  </Box>
                </Box>
              )}

              {completed && (
                <Box mt={recommendations.some(r => r.done) ? 2 : 0}>
                  <FieldTitle
                    text={
                      <>
                        <Trans>Component Rank</Trans>:
                      </>
                    }
                  />

                  <RankChangeResult oldRank={initialComponentRank} newRank={resultComponentRank} />

                  <FieldTitle
                    text={
                      <>
                        <Trans>Operation Merit</Trans>:
                      </>
                    }
                    mt={2}
                  />

                  <MeritChangeResult oldMerit={initialMerit} newMerit={resultMerit} />

                  <FieldTitle
                    text={
                      <>
                        <Trans>Operation Perfectness</Trans>:
                      </>
                    }
                    mt={2}
                  />

                  <PerfectnessChangeResult oldPerfectness={initialPerfectness} newPerfectness={resultPerfectness} />
                </Box>
              )}
            </Paper>
          </Box>
        </Collapse>
      </Alert>
    </Box>
  );
};
