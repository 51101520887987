import React, { ChangeEvent, useCallback, useState } from 'react';
import { IconButton, InputAdornment, TextField, TextFieldProps, Tooltip } from '@mui/material';
import { CloseRounded, SearchRounded } from '@mui/icons-material';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

type SearchInputProps = TextFieldProps & {
  initialValue?: string;
  onChangeDebounced?: (text: string) => void;
  onClear?: () => void;
};

export const SearchInput: React.FC<SearchInputProps> = ({
  initialValue,
  onChange,
  onChangeDebounced,
  onClear,
  placeholder,
  ...rest
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue || '');

  const searchInputValueChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e?.target?.value || '';

    setValue(text);
    handleSearchTextChange(text);

    if (onChange) onChange(e);
  };

  const handleSearchTextChange = useCallback(
    debounce((text: string) => {
      if (onChangeDebounced) onChangeDebounced(text);
    }, 500),
    [],
  );

  const clearHandler = () => {
    setValue('');
    if (onClear) onClear();
  };

  return (
    <TextField
      value={value}
      placeholder={t(placeholder || 'Search')}
      variant={'outlined'}
      size={'small'}
      fullWidth={true}
      onChange={searchInputValueChangeHandler}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {!!value?.length && (
              <Tooltip title={'Clear'} placement={'top'}>
                <IconButton edge="end" size={'small'} onClick={clearHandler}>
                  <CloseRounded />
                </IconButton>
              </Tooltip>
            )}

            {!value?.length && <SearchRounded />}
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};
