import React, { useState } from 'react';
import { PfmStepType } from '../../models/tools/pfm';
import { Box, Button, ListItemIcon, ListItemText, MenuItem, MenuList, Tooltip } from '@mui/material';
import { PopperWrap } from '../popper-wrap/component';
import { Trans } from 'react-i18next';
import { resolvePfmTypeIcon } from '../../utils/pfm';

const pfmStepTypeSelectOptions = [
  {
    type: PfmStepType.Productive,
    text: 'Productive',
    tooltip: 'Irreversible change',
  },
  {
    type: PfmStepType.Providing,
    text: 'Providing',
    tooltip: 'Temporary change',
  },
  {
    type: PfmStepType.Corrective,
    text: 'Corrective',
    tooltip: 'Improving change',
  },
  {
    type: PfmStepType.Metrology,
    text: 'Metrology',
    tooltip: 'Parameters measurement',
  },
];

interface PfmStepTypeSelectorProps {
  value: PfmStepType;
  onSelect: (value: PfmStepType) => void;
  label?: string;
  disabled?: boolean;
}

export const PfmStepTypeSelector: React.FC<PfmStepTypeSelectorProps> = ({ value, onSelect, label, disabled }) => {
  const [selectIsOpen, setSelectIsOpen] = useState(false);
  const [stepType, setStepType] = useState<PfmStepType>(value);

  const selectHandler = (selectedValue: PfmStepType) => {
    setSelectIsOpen(false);
    setStepType(selectedValue);
    onSelect(selectedValue);
  };

  const openToggleHandle = (open: boolean) => {
    setSelectIsOpen(open);
  };

  return (
    <PopperWrap
      open={selectIsOpen}
      onOpenToggle={openToggleHandle}
      openHandler={label ? 'click' : 'hover'}
      dropdownProps={{ p: 0 }}
    >
      {label && (
        <Button variant={'outlined'} color={'primary'} size={'small'} disabled={disabled}>
          <Trans>{label}</Trans>
        </Button>
      )}

      {!label && <div>{resolvePfmTypeIcon(stepType)}</div>}

      <MenuList>
        {pfmStepTypeSelectOptions.map(option => (
          <Tooltip key={option.type} title={<Trans>{option.tooltip}</Trans>} placement={'left'} arrow={true}>
            <MenuItem
              onClick={() => {
                selectHandler(option.type);
              }}
              disabled={disabled}
            >
              <ListItemIcon>{resolvePfmTypeIcon(option.type)}</ListItemIcon>

              <ListItemText>
                <Box pr={1}>
                  <Trans>{option.text}</Trans>
                </Box>
              </ListItemText>
            </MenuItem>
          </Tooltip>
        ))}
      </MenuList>
    </PopperWrap>
  );
};
