import axios, { AxiosResponse } from 'axios';
import { PublicPMapUtilization } from '../model';
import { AxiosRequestParams } from '@priz/shared/src/services/axios';

const axiosParams: Partial<AxiosRequestParams> = {
  params: {
    skipAuthCheck: true,
  },
};

const load = (utilizationId: number, skipAuthCheck?: boolean): Promise<PublicPMapUtilization> =>
  axios
    .get(`/v1/public-api/p-map/${utilizationId}`, skipAuthCheck ? axiosParams : undefined)
    .then((response: AxiosResponse<PublicPMapUtilization>) => response.data);

export interface PerceptionMapAddContributedPerceptionCommand {
  text: string;
}

const addPerception = (
  utilizationId: number,
  command: PerceptionMapAddContributedPerceptionCommand,
  skipAuthCheck?: boolean,
): Promise<PublicPMapUtilization> =>
  axios
    .post(`/v1/public-api/p-map/${utilizationId}/perception`, command, skipAuthCheck ? axiosParams : undefined)
    .then((response: AxiosResponse<PublicPMapUtilization>) => response.data);

export const PublicPMapApi = {
  load,
  addPerception,
};
