import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import { PMapDiagram } from '../pmap-diagram/component';
import { PMapItem } from '../pmap-item/component';
import { Box, Grid, Hidden, IconButton, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { IPMapUtilizationData, Perception, PMapUtilization } from '@priz/shared/src/models/tools/pmap';
import { PaywallActions } from '../../react/modules/paywall/store';
import { ToolType } from '@priz/shared/src/models/tools';
import { TabPanel } from '../../react/elements/tab-panel/component';
import { ToolSubjectConclusion } from '../../tools/tool-subject-conclusion/component';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { PMapResultTable } from '@priz/shared/src/components/pmap-result-table/component';
import { PMapProgressBar } from '../pmap-progress-bar/component';
import { CsvDataSelector, CSVImportType } from '../../csv-data-selector/component';
import { numberToLetters } from '@priz/shared/src/utils/convertation';
import SimpleBar from 'simplebar-react';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { PMapResultSelectors } from '../store/selectors/pmap-result.selectors';
import { PMapUtilizationActions, PMapUtilizationActionType } from '../store/actions';
import {
  addNextPerceptionToArray,
  changePerceptionConnections,
  changePerceptionDescription,
  checkIsAllPerceptionsWereConnected,
  getMaxPerceptionId,
  removePerceptionFromArray,
  setPerceptionsConflicts,
} from '../utils';
import { stringToHash } from '@priz/shared/src/utils/common';
import { PMapResultingTable } from '../pmap-resulting-table/component';
import { PMapContributedPerceptionStatus } from '@priz/shared/src/models/tools/pmap/pmap-contributed-perception';
import { ContributedPerceptionCard } from '../contributed-perception-card/component';
import { PerceptionMapResolveContributedPerceptionCommand, ResolveContributedPerceptionStatus } from '../service';
import { PMapShareButton } from '../pmap-share-button/component';

import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';

export enum PMapWorkspaceTabType {
  Subject = 'Subject',
  Perceptions = 'Perceptions',
  Result = 'Result',
  Conclusion = 'Conclusion',
}

interface PMapWorkspaceProps {
  pMapUtilization: PMapUtilization;
  activeTab?: PMapWorkspaceTabType;
  finished?: boolean;
  disabled?: boolean;
  conclusion?: string;
  subject?: string;
  onConclusionChange?: (conclusion: string) => void;
  onSubjectChange?: (subject: string) => void;
  onDataChange?: (data: IPMapUtilizationData) => void;
}

const pMapDataConnectionsToKey = (data: IPMapUtilizationData): string | null => {
  if (!data?.perceptions) return null;
  return data.perceptions.length
    ? data.perceptions.map(p => `${p.letter}-${p.assign}-${p.conflict}`).join(',')
    : 'empty';
};

const pMapDataToHash = (data?: IPMapUtilizationData): number | null => {
  return data ? stringToHash(JSON.stringify(data)) : null;
};

export const PMapWorkspace: React.FC<PMapWorkspaceProps> = ({
  pMapUtilization,
  activeTab = PMapWorkspaceTabType.Subject,
  disabled,
  conclusion,
  subject,
  onConclusionChange,
  onSubjectChange,
  onDataChange,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [isImporting, setIsImporting] = useState(false);
  const [importIsOpen, setImportIsOpen] = useState(false);

  const [data, setData] = useState<IPMapUtilizationData>();
  const [savedDataHash, setSavedDataHash] = useState<number>();
  const [connectionsKey, setConnectionsKey] = useState<string>();
  const [preventConnectingAndDeleting, setPreventConnectingAndDeleting] = useState(true);
  const [selectMenuPortalContainer, setSelectMenuPortalContainer] = useState<HTMLDivElement>();
  const [scrollToBottom, setScrollToBottom] = useState(false);

  const featureSet = useSelector(WorkspaceSelectors.getApplicableFeatureSet(pMapUtilization.project?.id));
  const result = useSelector(PMapResultSelectors.getResultByUtilizationId(pMapUtilization.id));
  const isResultLoaded = useSelector(PMapResultSelectors.isLoadedByUtilizationId(pMapUtilization.id));
  const isUpdated = useSelector(ToolUtilizationSelector.isUpdatedByUtilizationId(pMapUtilization.id));

  const isBlockingUpdatePending = useSelector(
    ToolUtilizationSelector.isAnyActionPending(pMapUtilization.id, [
      PMapUtilizationActionType.UpdateAccessLevel,
      PMapUtilizationActionType.ResolveContributedPerception,
    ]),
  );

  const isEditingDisabled = disabled || isImporting || isBlockingUpdatePending;

  const { perceptions = [], allPerceptionsWereConnected = false } = data || {};
  const contributedPerceptions = pMapUtilization?.contributedData?.perceptions || [];
  const pendingContributedPerceptions = contributedPerceptions.filter(
    perception => perception.status === PMapContributedPerceptionStatus.Pending,
  );

  const perceptionsListRef = useRef(null);
  const currentDataHash = pMapDataToHash(data);

  useEffect(() => {
    if (!data && pMapUtilization?.data) {
      setData(pMapUtilization.data);
    }

    if (!savedDataHash && data) {
      setSavedDataHash(pMapDataToHash(data));
    }

    if (!connectionsKey && data) {
      setConnectionsKey(pMapDataConnectionsToKey(data));
    }
  }, [data, pMapUtilization, savedDataHash, connectionsKey]);

  useEffect(() => {
    if (connectionsKey && data?.perceptions) {
      dispatch(PMapUtilizationActions.getResult(pMapUtilization.id));
    }
  }, [connectionsKey]);

  useEffect(() => {
    if (isUpdated) {
      setSavedDataHash(currentDataHash);
      setConnectionsKey(pMapDataConnectionsToKey(data));
    }
  }, [isUpdated]);

  useEffect(() => {
    if (preventConnectingAndDeleting !== !isResultLoaded) {
      setPreventConnectingAndDeleting(!isResultLoaded);
    }
  }, [isResultLoaded, preventConnectingAndDeleting]);

  useEffect(() => {
    if (
      data &&
      result &&
      !data.allPerceptionsWereConnected &&
      data.perceptions.length &&
      checkIsAllPerceptionsWereConnected(data, result)
    ) {
      setData(currentState => ({
        ...currentState,
        allPerceptionsWereConnected: true,
      }));
    }
  }, [data, result]);

  useEffect(() => {
    if (disabled || !currentDataHash || !savedDataHash || !onDataChange) return;

    if (currentDataHash !== savedDataHash) {
      onDataChange(data);
    }
  }, [currentDataHash, savedDataHash]);

  useEffect(() => {
    if (isImporting && isUpdated) {
      setIsImporting(false);
      closeImport();
    }
  }, [isUpdated, isImporting]);

  useEffect(() => {
    if (scrollToBottom) {
      setScrollToBottom(false);
      scrollOptionsListToBottom();
    }
  }, [scrollToBottom]);

  const scrollOptionsListToBottom = () => {
    if (perceptionsListRef?.current) {
      perceptionsListRef.current.scrollTop = perceptionsListRef.current.scrollHeight;
    }
  };

  const addNewPerception = (description?: string, acceptContributedPerceptionId?: string, preventScroll?: boolean) => {
    if (featureSet?.Tools?.P_MAP?.maxItems && perceptions.length >= featureSet.Tools.P_MAP.maxItems) {
      dispatch(PaywallActions.show(ToolType.P_MAP));
    } else {
      setPreventConnectingAndDeleting(true);

      if (acceptContributedPerceptionId) {
        resolveContributedPerception(acceptContributedPerceptionId, PMapContributedPerceptionStatus.Accepted);
      }

      setData(currentState => ({
        ...currentState,
        perceptions: addNextPerceptionToArray(currentState.perceptions, description, !!acceptContributedPerceptionId),
      }));

      if (!preventScroll) {
        setScrollToBottom(true);
      }
    }
  };

  const resolveContributedPerception = (
    contributedPerceptionId: string,
    status: ResolveContributedPerceptionStatus,
  ) => {
    const command: PerceptionMapResolveContributedPerceptionCommand = {
      perceptionId: contributedPerceptionId,
      status: status,
    };

    dispatch(
      PMapUtilizationActions.resolveContributedPerception(pMapUtilization.id, command, pMapUtilization.project?.id),
    );
  };

  const deletePerception = (letterToRemove: string) => {
    if (!result) return null;

    setPreventConnectingAndDeleting(true);

    setData(currentState => ({
      ...currentState,
      perceptions: removePerceptionFromArray(currentState.perceptions, letterToRemove, result.conflicts),
    }));
  };

  const assignPerception = (letterFrom: string, letterTo: string) => {
    if (!result) return null;

    setPreventConnectingAndDeleting(true);

    setData(currentState => ({
      ...currentState,
      perceptions: changePerceptionConnections(currentState.perceptions, letterFrom, letterTo, result.connections),
    }));
  };

  const contradictPerceptions = (from: string, to: string, unset?: boolean) => {
    setPreventConnectingAndDeleting(true);

    setData(currentState => ({
      ...currentState,
      perceptions: setPerceptionsConflicts(currentState.perceptions, from, to, unset),
    }));
  };

  const updatePerceptionDescription = (letterToUpdate: string, text: string) => {
    setData(currentState => ({
      ...currentState,
      perceptions: changePerceptionDescription(currentState.perceptions, letterToUpdate, text),
    }));
  };

  const importHandler = (items: string[], importType: CSVImportType, limitHit: boolean) => {
    if (items.length) {
      setIsImporting(true);
      setPreventConnectingAndDeleting(true);

      setData(currentState => {
        const isAdding = importType === CSVImportType.Add;
        const maxId = getMaxPerceptionId(currentState.perceptions);

        const importedPerceptions = items.map(
          (item, key) =>
            new Perception({
              letter: numberToLetters(key + 1 + (isAdding ? maxId : 0)),
              description: item,
              assign: '',
              conflict: '',
            }),
        );

        return {
          ...currentState,
          perceptions: isAdding ? [...perceptions, ...importedPerceptions] : importedPerceptions,
        };
      });

      setScrollToBottom(true);
    }

    if (limitHit) {
      dispatch(PaywallActions.show(ToolType.P_MAP));
    }
  };

  const openImport = () => {
    setImportIsOpen(true);
  };

  const closeImport = () => {
    setImportIsOpen(false);
  };

  const selectMenuPortalContainerRefHandler = (element?: HTMLDivElement) => {
    if (element) {
      setSelectMenuPortalContainer(element);
    }
  };

  const renderItems = () => {
    return (
      <>
        {contributedPerceptions.map(item => (
          <ContributedPerceptionCard
            key={item.id}
            projectId={pMapUtilization.project?.id}
            perception={item}
            onAccept={(text, perceptionId) => addNewPerception(text, perceptionId, true)}
            onReject={id => resolveContributedPerception(id, PMapContributedPerceptionStatus.Rejected)}
            mb={2}
          />
        ))}

        {perceptions.map(item => (
          <PMapItem
            key={item.id || item.letter}
            calcResult={result}
            perception={item}
            perceptionsArray={perceptions}
            deleteHandler={deletePerception}
            assignHandler={assignPerception}
            contradictHandler={contradictPerceptions}
            updateDescriptionHandler={updatePerceptionDescription}
            sum={result?.map?.[item.letter]?.sum}
            blockingRank={result?.map?.[item.letter]?.blockingRank}
            allWereConnected={allPerceptionsWereConnected}
            disableConnections={preventConnectingAndDeleting}
            selectMenuPortalContainer={selectMenuPortalContainer}
            disableInput={isEditingDisabled}
            disableControls={isEditingDisabled}
            disableDeleting={preventConnectingAndDeleting}
          />
        ))}
      </>
    );
  };

  const resolveRootClassName = () => {
    const rootClassNames = [styles.root];

    if (activeTab === PMapWorkspaceTabType.Perceptions) rootClassNames.push(styles.rootPerceptions);
    if (activeTab === PMapWorkspaceTabType.Result) rootClassNames.push(styles.rootResult);

    return rootClassNames.join(' ');
  };

  return (
    <Box className={resolveRootClassName()}>
      {!pMapUtilization.publicId && (
        <TabPanel value={activeTab} index={PMapWorkspaceTabType.Subject} renderOnlyIfActive>
          <Box zIndex={1} position={'relative'} width={'100%'}>
            <ToolSubjectConclusion
              onContentChange={onSubjectChange}
              initialContent={subject}
              disabled={disabled}
              placeholder={'Describe the system that you are analyzing with perception mapping tool'}
            />
          </Box>
        </TabPanel>
      )}

      <TabPanel
        value={activeTab}
        index={PMapWorkspaceTabType.Perceptions}
        className={styles.tabPanelPerceptions}
        renderOnlyIfActive
      >
        <PMapProgressBar pMapUtilization={pMapUtilization} />

        <Box mb={1} width={'100%'}>
          <Grid container spacing={2} alignItems={'flex-end'}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={1} alignItems={'center'}>
                <Grid item>
                  <CsvDataSelector
                    open={importIsOpen}
                    onOpen={openImport}
                    onClose={closeImport}
                    onImport={importHandler}
                    planLimit={featureSet?.Tools?.P_MAP?.maxItems}
                    currentItemsCount={perceptions.length}
                    loading={isImporting}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item>
                  <PMapShareButton utilization={pMapUtilization} disabled={disabled} />
                </Grid>
              </Grid>
            </Grid>

            {!pendingContributedPerceptions.length && perceptions?.length > 1 && (
              <Hidden mdDown>
                <Grid item xs={6}>
                  <Box pr={2}>
                    <Grid item container alignItems={'flex-end'}>
                      <Grid item xs={11}>
                        <Grid item container spacing={2} alignItems={'flex-end'}>
                          <Grid item xs={6}>
                            <Typography variant={'subtitle2'}>
                              <Trans>Find connections</Trans>
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <Typography variant={'subtitle2'}>
                              <Trans>Find contradictions</Trans>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={1} />
                    </Grid>
                  </Box>
                </Grid>
              </Hidden>
            )}
          </Grid>
        </Box>

        <div ref={r => selectMenuPortalContainerRefHandler(r)} />

        <Hidden smUp>
          <Box width={'100%'}>{renderItems()}</Box>
        </Hidden>

        <Hidden smDown>
          <Box className={styles.scrollbarContainer}>
            <SimpleBar
              scrollableNodeProps={{ ref: perceptionsListRef }}
              className={`${styles.scrollbar} wide`}
              forceVisible="y"
              autoHide={false}
            >
              <div>{renderItems()}</div>
            </SimpleBar>
          </Box>
        </Hidden>

        {!perceptions.length && (
          <Box textAlign={'center'} mt={6} className={styles.plusButtonLabel}>
            <Typography variant="h6" component="span">
              <Trans>Add new perceptions</Trans>
            </Typography>
          </Box>
        )}

        <Box display={'flex'} justifyContent={'center'} className={styles.plusButton}>
          <IconButton
            className={styles.addNewPerceptionButton}
            onClick={() => addNewPerception()}
            disabled={isEditingDisabled}
          >
            <PlusIcon />
          </IconButton>
        </Box>
      </TabPanel>

      <TabPanel
        value={activeTab}
        index={PMapWorkspaceTabType.Result}
        className={styles.tabPanelResult}
        renderOnlyIfActive
      >
        <Box position={'relative'} height={{ xs: 'auto', md: '100%' }} width={'100%'}>
          <Grid
            container
            spacing={3}
            alignItems={{ xs: 'flex-start', md: 'stretch' }}
            alignContent={'flex-start'}
            className={styles.diagramAndResultContainer}
          >
            <Grid item xs={12} md={6}>
              <Box className={styles.pMpWrapper} height={{ xs: 200, sm: 300, md: '100%' }}>
                <PMapDiagram viewMode={disabled} perceptions={perceptions} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} className={styles.relativeWrap} height={{ xs: 'auto', md: '100%' }}>
              <Box className={styles.resultWrap} height={{ xs: 'auto', md: '100%' }}>
                <PMapResultTable result={result?.perceptions} tableProps={{ stickyHeader: true }} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>

      {!pMapUtilization.publicId && (
        <TabPanel value={activeTab} index={PMapWorkspaceTabType.Conclusion} renderOnlyIfActive>
          <Box zIndex={1} position={'relative'} width={'100%'}>
            {pMapUtilization && result && data && (
              <Box mb={4}>
                <PMapResultingTable
                  projectId={pMapUtilization.project?.id}
                  utilizationId={pMapUtilization.id}
                  result={result.perceptions}
                  perceptions={pMapUtilization?.data?.perceptions}
                  disabled={disabled}
                />
              </Box>
            )}

            <ToolSubjectConclusion
              onContentChange={onConclusionChange}
              initialContent={conclusion}
              disabled={disabled}
            />
          </Box>
        </TabPanel>
      )}
    </Box>
  );
};
