import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { NavigateFunction } from 'react-router-dom';
import { WorkspaceProjectsCountMap } from '../store/model';

interface RedirectResolverProps {
  navigate: NavigateFunction;
  workspaceProjectsCountMap?: WorkspaceProjectsCountMap;
}

export const resolveRedirect = (props: RedirectResolverProps) => {
  const { navigate, workspaceProjectsCountMap } = props;

  const createProject = LocalStorageService.getItem(LocalStorageKey.CreateProjectProps);
  const redirectUrl = LocalStorageService.getItem(LocalStorageKey.AuthCallbackRedirect);
  const selectedWorkspaceId = LocalStorageService.getItem(LocalStorageKey.SelectedWorkspaceId);

  if (createProject) {
    void navigate('/project-wizard-callback');
    return;
  }

  if (redirectUrl) {
    LocalStorageService.removeItem(LocalStorageKey.AuthCallbackRedirect);
    void navigate(redirectUrl);
    return;
  }

  if (selectedWorkspaceId !== null && workspaceProjectsCountMap[+selectedWorkspaceId] < 1) {
    void navigate('/projects-and-tools');
    return;
  }

  void navigate('/');
};
