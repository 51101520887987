import React, { useEffect, useState } from 'react';
import { Box, Collapse, Grid, IconButton, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { SubscriptionStatus } from '@priz/shared/src/models/workspace';
import { Link as RouterLink } from 'react-router-dom';

export const SubscriptionNotificationLine: React.FC = () => {
  const styles = useStyles();
  const theme = useTheme();
  const isDownMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  const workspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (workspace) {
      setIsVisible(workspace.subscriptionStatus === SubscriptionStatus.Unpaid);
    }
  }, [workspace]);

  const closeHandler = () => {
    setIsVisible(false);
  };

  return (
    <Collapse in={isVisible} className={['notification-line', styles.root].join(' ')}>
      <Grid container className={styles.contentContainer} alignItems={'center'}>
        <Grid item xs={true}>
          <Box px={1} py={0.25}>
            <Typography variant={isDownMdBreakpoint ? 'body2' : 'body1'} textAlign={'center'}>
              <Trans>It seems like there is a problem with charging the subscription fees.</Trans>{' '}
              <Trans>Please, visit</Trans>{' '}
              <Link component={RouterLink} to={'/workspace/billing'} className={styles.link}>
                <b>
                  <Trans>billing page</Trans>
                </b>
              </Link>{' '}
              <Trans>to update billing details.</Trans>.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={'auto'} marginBottom={'auto'}>
          <IconButton size={'medium'} color={'inherit'} onClick={closeHandler}>
            <CloseRounded fontSize={'inherit'} />
          </IconButton>
        </Grid>
      </Grid>
    </Collapse>
  );
};
