import { SvgIconProps } from '@mui/material';
import { CurrencyExchange, HandymanOutlined, ManageHistory, SquareFootOutlined } from '@mui/icons-material';
import { PfmStepType } from '../../models/tools/pfm';
import { pgColorScheme } from '../../theme';

export const resolvePfmTypeIcon = (pfmStepType: PfmStepType, svgProps?: SvgIconProps): JSX.Element | null => {
  const props: SvgIconProps = {
    fontSize: 'small',
    ...svgProps,
  };

  switch (pfmStepType) {
    case PfmStepType.Productive:
      return <CurrencyExchange {...props} sx={{ display: 'block', color: pgColorScheme.green }} />;

    case PfmStepType.Providing:
      return <ManageHistory {...props} sx={{ display: 'block', color: pgColorScheme.blue }} />;

    case PfmStepType.Corrective:
      return <HandymanOutlined {...props} sx={{ display: 'block', color: pgColorScheme.orange }} />;

    case PfmStepType.Metrology:
      return <SquareFootOutlined {...props} sx={{ display: 'block', color: pgColorScheme.purple }} />;

    default:
      return null;
  }
};
