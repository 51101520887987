import React from 'react';
import { useStyles } from './styles';
import { Box, BoxProps, Grid } from '@mui/material';
import { PfmStep, PfmUtilization } from '../../../models/tools/pfm';
import { SfmRankMap, SfmUtilization } from '../../../models/tools/sfm';
import { PfmSummary } from '../../pfm-summary/component';

export interface PfmViewerGridProps extends BoxProps {
  itemsPerRow: number;
  spacing: number;
}

export interface PfmViewerProps extends PfmViewerGridProps {
  pfmUtilization: PfmUtilization;
  sfmRankMap: SfmRankMap;
  sfmRenderer: (step: PfmStep, reversed: boolean, isFirstColumn: boolean, isLastColumn: boolean) => JSX.Element;
  addStepButtonRenderer?: () => JSX.Element;
  sfmUtilizationArray?: SfmUtilization[];
}

const splitStepsOnRows = (steps: PfmStep[], itemsPerRow: number): PfmStep[][] => {
  const sortedSteps = steps.sort((a, b) => (a.index > b.index ? 1 : -1));
  const stepsRows: PfmStep[][] = [];

  for (let i = 0; i < sortedSteps.length; i += itemsPerRow) {
    const chunk = sortedSteps.slice(i, i + itemsPerRow);

    stepsRows.push(chunk);
  }

  return stepsRows;
};

export const PfmViewer: React.FC<PfmViewerProps> = ({
  pfmUtilization,
  sfmUtilizationArray,
  sfmRankMap,
  addStepButtonRenderer,
  sfmRenderer,
  itemsPerRow,
  spacing,
}) => {
  const styles = useStyles();

  const steps = pfmUtilization?.diagramData?.steps || [];
  const columnWidth = 12 / itemsPerRow;
  const stepsRows = splitStepsOnRows(steps, itemsPerRow);

  return (
    <Box overflow={'hidden'}>
      <Grid container spacing={stepsRows.length ? spacing : 0}>
        {stepsRows.map((row, rowKey) => {
          const isLastRow = stepsRows.length === rowKey + 1;
          const reverseRow = rowKey % 2;

          return (
            <React.Fragment key={rowKey}>
              <Grid item xs={12}>
                <Grid container spacing={spacing} flexDirection={reverseRow ? 'row-reverse' : 'row'}>
                  {row.map((step, columnKey) => {
                    const isLastColumn = itemsPerRow === columnKey + 1;
                    const arrowClassNames = [styles.arrow];

                    if (!pfmUtilization.flowFinished && addStepButtonRenderer) {
                      arrowClassNames.push(
                        isLastColumn ? styles.arrowDown : reverseRow ? styles.arrowLeft : styles.arrowRight,
                      );
                    } else {
                      if (isLastColumn && stepsRows[rowKey + 1]) arrowClassNames.push(styles.arrowDown);
                      if (columnKey + 1 < row.length)
                        arrowClassNames.push(reverseRow ? styles.arrowLeft : styles.arrowRight);
                    }

                    return (
                      <Grid key={step.sfmId} item xs={columnWidth}>
                        <div className={styles.modelContainer}>
                          {sfmRenderer(step, !!reverseRow, !columnKey, isLastColumn)}

                          <Box
                            className={arrowClassNames.join(' ')}
                            pr={isLastColumn ? 0 : spacing}
                            pt={isLastColumn ? spacing : 0}
                          />
                        </div>
                      </Grid>
                    );
                  })}

                  {!pfmUtilization.flowFinished && addStepButtonRenderer && isLastRow && row.length < itemsPerRow && (
                    <Grid item xs={columnWidth}>
                      {addStepButtonRenderer()}
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {!pfmUtilization.flowFinished && addStepButtonRenderer && isLastRow && row.length === itemsPerRow && (
                <Grid item xs={12}>
                  <Grid container spacing={spacing} flexDirection={(rowKey + 1) % 2 ? 'row-reverse' : 'row'}>
                    <Grid item xs={columnWidth}>
                      {addStepButtonRenderer()}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </React.Fragment>
          );
        })}

        {addStepButtonRenderer && !stepsRows.length && (
          <Grid item xs={12}>
            {addStepButtonRenderer()}
          </Grid>
        )}
      </Grid>

      {sfmUtilizationArray && (
        <Box mt={stepsRows.length ? 4 : 0}>
          <PfmSummary
            pfmUtilization={pfmUtilization}
            sfmUtilizationArray={sfmUtilizationArray}
            sfmRankMap={sfmRankMap}
            pieContainerProps={{ maxWidth: 200 }}
          />
        </Box>
      )}
    </Box>
  );
};
