import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { ApprovalActivityActionEnum } from '../store/model';
import { ApprovalActivityActions } from '../store/actions/approval-activity.actions';
import { ApprovalActivitiesSelectors } from '../store/selectors/approval-activity.selectors';
import { ProjectSelector } from '../../project/store/selectors';
import { PublicUser } from '@priz/shared/src/models/user';
import { PublicUserSelectors } from '../../user/store/selectors/public-user.selectors';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { PublicUserActions } from '../../user/store/actions/public-users.actions';
import uniq from 'lodash/uniq';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { format } from 'date-fns';
import { Divider, Grid } from '@mui/material';
import { ApprovalActionRecorder } from '../approval-action-recorder/component';

export interface ApprovalProcessActivityProps {
  projectId: number;
}

const actionToDescriptionMap = {
  [ApprovalActivityActionEnum.SubmittedForApproval]: 'submitted the project for review',
  [ApprovalActivityActionEnum.AcceptedForReview]: 'started reviewing the project',
  [ApprovalActivityActionEnum.ChangesRequested]: 'requested for changes',
  [ApprovalActivityActionEnum.SubmittedChangesForApproval]: 'resubmitted the project for review',
  [ApprovalActivityActionEnum.Rejected]: 'rejected the certification of this project',
  [ApprovalActivityActionEnum.Approved]: 'approved the certification of this project',
};

const resolvePublicUserName = (users: PublicUser[], id: number) => {
  const { title, firstName, lastName } = (Array.isArray(users) && users.find(u => u.id === id)?.profile) || {};
  return [title, firstName, lastName].filter(i => !!i).join(' ');
};

export const ApprovalProcessActivity: React.FC<ApprovalProcessActivityProps> = ({ projectId }) => {
  const dispatch = useDispatch();
  const [uniqUserIds, setUniqUserIds] = useState([]);

  const activities = useSelector(ApprovalActivitiesSelectors.finalProjectApprovalActivitiesSelector(projectId));
  const project = useSelector(ProjectSelector.getById(projectId));
  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const users = useSelector(PublicUserSelectors.allPublicUsersByIdsSelector(uniqUserIds));

  useEffect(() => {
    dispatch(ApprovalActivityActions.loadAllByProjectId(projectId));
  }, []);

  useEffect(() => {
    if (activities && activities?.length) {
      const userIds = activities.map(a => a.createdBy.id);
      if (userIds?.length) setUniqUserIds(uniq(userIds));
    }
  }, [activities]);

  useEffect(() => {
    if (uniqUserIds.length) dispatch(PublicUserActions.loadAllByIds(uniqUserIds));
  }, [uniqUserIds]);

  return (
    <>
      <PageTitleWithDocLink
        title={<Trans>Review activities</Trans>}
        oneLiner={<Trans>The list below includes all the activities recorded during the approval process.</Trans>}
      />
      <p>
        <Trans>Explanation...</Trans>
      </p>

      {activities && !!activities?.length && (
        <div className={'activity-list'}>
          {activities.map((activity, key) => {
            return (
              <div key={key}>
                <Grid container spacing={2}>
                  <Grid item xs={'auto'}>
                    <span>{key + 1}</span>
                  </Grid>

                  <Grid item xs={true}>
                    <span className="activity-description">
                      {users && <span className="name">{resolvePublicUserName(users, activity.createdBy.id)}</span>}

                      <span className="activity">
                        <Trans>{actionToDescriptionMap[activity.action]}</Trans>
                      </span>
                    </span>
                  </Grid>
                </Grid>

                <span className="activity-date">
                  <strong>{format(activity.dateCreated, 'MMM d yyyy')}</strong>
                </span>

                {!!activity?.comment?.length && (
                  <div className="comments">
                    <div>
                      <Trans>Comments</Trans>
                    </div>
                    <p>{activity.comment}</p>
                  </div>
                )}

                <Divider />
              </div>
            );
          })}
        </div>
      )}

      <ApprovalActionRecorder project={project} currentUser={currentUser} />
    </>
  );
};
