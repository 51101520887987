import { InvitationInfo, IWorkspaceMember } from '@priz/shared/src/models/workspace';
import axios, { AxiosResponse } from 'axios';
import { AxiosRequestParams } from '@priz/shared/src/services/axios';

const axiosParams: Partial<AxiosRequestParams> = {
  params: {
    skipAuthCheck: true,
  },
};

const getAllWorkspaceMembers = (token: string): Promise<IWorkspaceMember[]> =>
  axios
    .get(`/v1/public-api/workspace/members/${token}`, axiosParams)
    .then((response: AxiosResponse<IWorkspaceMember[]>) => response.data);

const getInvitationInfo = (token: string): Promise<InvitationInfo> =>
  axios
    .get(`/v1/public-api/workspace/invitation/${token}`, axiosParams)
    .then((response: AxiosResponse<InvitationInfo>) => response.data);

export const PublicWorkspaceApi = {
  getAllWorkspaceMembers,
  getInvitationInfo,
};
