import axios, { AxiosResponse } from 'axios';
import { PartnerProfile } from '@priz/shared/src/models/security/partner-profile';
import { IPublicUser } from '@priz/shared/src/models/user';
import { PlanLevel } from '@priz/shared/src/models/workspace';
import { IPartnerCommission } from '@priz/shared/src/models/security/partner-commission';

const getProfile = (): Promise<PartnerProfile> =>
  axios.get(`/v1/api/user/partner-profile`).then((response: AxiosResponse<PartnerProfile>) => response.data);

const getReferrals = (): Promise<IPublicUser[]> =>
  axios.get(`/v1/api/user/referrals`).then((response: AxiosResponse<IPublicUser[]>) => response.data);

const getReferralPlanLevels = (referralId: number): Promise<PlanLevel[]> =>
  axios
    .get(`/v1/api/user/referral/${referralId}/plan-levels`)
    .then((response: AxiosResponse<PlanLevel[]>) => response.data);

const getCommissionByReferralId = (referralId: number): Promise<IPartnerCommission[]> =>
  axios
    .get(`/v1/api/referral/${referralId}/commissions`)
    .then((response: AxiosResponse<IPartnerCommission[]>) => response.data);

export const PartnershipApi = {
  getProfile,
  getReferrals,
  getReferralPlanLevels,
  getCommissionByReferralId,
};
