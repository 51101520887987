import React from 'react';
import { Box, BoxProps, Grid } from '@mui/material';
import { useStyles } from './styles';

interface TitleContainerProps extends BoxProps {
  fitButtons?: boolean;
}

export const TitleContainer: React.FC<TitleContainerProps> = ({ children, fitButtons, ...rest }) => {
  const styles = useStyles();

  return (
    <Box mb={3} mt={fitButtons ? -1 : 0} {...rest}>
      <Grid container>
        <Grid item xs={fitButtons ? true : 12} md={true} className={styles.overflowContainer}>
          <Box pr={{ xs: fitButtons ? 2 : 0, md: 2 }} mt={fitButtons ? 1 : 0} className={styles.titleWrap}>
            {children[0]}
          </Box>
        </Grid>

        {children[1] && (
          <Grid item xs={fitButtons ? 'auto' : 12} md={'auto'} maxWidth={'100%'}>
            <Box mt={{ xs: 1, md: fitButtons ? 1 : 0 }}>{children[1]}</Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
