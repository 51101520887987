import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { ApprovalActivity, ApprovalActivityActionEnum, ApprovalActivityStatus } from '../store/model';
import { ApprovalActivityActions } from '../store/actions/approval-activity.actions';
import { ApprovalActivitiesSelectors } from '../store/selectors/approval-activity.selectors';
import { Project } from '@priz/shared/src/models/project';
import { User } from '@priz/shared/src/models/security/user';
import { useForm } from 'react-hook-form';
import { ProjectCertificationWorkflowService } from '../services/project-certification-workflow.service';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { ReactHookFormText } from '../../react/form-elements';
import { validateOptions } from '@priz/shared/src/utils/form';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export interface ApprovalActionRecorderProps {
  project: Project;
  currentUser: User;
}

interface ApprovalDialogText {
  confirmTitle: string | JSX.Element;
  confirmContent: string | JSX.Element;
  okButtonText: string | JSX.Element;
  cancelButtonText: string | JSX.Element;
}

enum ApprovalDialogTypes {
  DeleteDraft = 'DeleteDraft',
  ResubmitForApproval = 'ResubmitForApproval',
  Reject = 'Reject',
  Approve = 'Approve',
}

const ApprovalDialogTextMap: { [key in ApprovalDialogTypes]: ApprovalDialogText } = {
  [ApprovalDialogTypes.DeleteDraft]: {
    confirmTitle: <Trans>Please, confirm</Trans>,
    okButtonText: <Trans>Yes</Trans>,
    cancelButtonText: <Trans>No</Trans>,
    confirmContent: (
      <>
        <Trans>You are about to delete the feedback.</Trans>
        <br />
        <Trans>This cannot be undone.</Trans>
        <br />
        <Trans>Do you want to continue?</Trans>
      </>
    ),
  },

  [ApprovalDialogTypes.ResubmitForApproval]: {
    confirmTitle: <Trans>Please, confirm submission</Trans>,
    okButtonText: <Trans>Yes, submit and lock the project</Trans>,
    cancelButtonText: <Trans>No, I still need to work on it</Trans>,
    confirmContent: (
      <>
        <Trans>You are about to submit the project for certification.</Trans>
        <br />
        <Trans>Please, note - once certification process started</Trans>:
        <br />
        <ul>
          <li>
            <Trans>the entire project will be locked in read only mode</Trans>
          </li>
          <li>
            <Trans>designated reviewer will have an access to project's data</Trans>
          </li>
        </ul>
        <br />
        <Trans>Don't worry, we are not sharing the data with anyone! Ever!</Trans>
        <br />
        <Trans>Do you want to continue?</Trans>
      </>
    ),
  },

  [ApprovalDialogTypes.Reject]: {
    confirmTitle: <Trans>Please, confirm rejection</Trans>,
    okButtonText: <Trans>Yes, reject the certification</Trans>,
    cancelButtonText: <Trans>No</Trans>,
    confirmContent: (
      <>
        <Trans>You are about to reject the request for certification of this project.</Trans> <br />
        <Trans>This operation cannot be undone!</Trans> <br />
        <Trans>Please, note - once certification certification is rejected</Trans>:
        <br />
        <ul>
          <li>
            <Trans>certification of this project will not be an available option</Trans>
          </li>
          <li>
            <Trans>the project will stay active for the owner use</Trans>
          </li>
        </ul>
        <br />
        <Trans>Do you want to continue?</Trans>
      </>
    ),
  },

  [ApprovalDialogTypes.Approve]: {
    confirmTitle: <Trans>Please, confirm approval</Trans>,
    okButtonText: <Trans>Yes, certify the project!</Trans>,
    cancelButtonText: <Trans>No</Trans>,
    confirmContent: (
      <>
        <Trans>You are about to confirm that this project is certified.</Trans> <br />
        <Trans>
          By this you are confirming that the project is meeting the minimal requirements for the certification.
        </Trans>
        <Trans>This operation cannot be undone!</Trans> <br />
        <Trans>Do you want to continue?</Trans>
      </>
    ),
  },
};

export const ApprovalActionRecorder: React.FC<ApprovalActionRecorderProps> = ({ project, currentUser }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeDialog, setActiveDialog] = useState<keyof typeof ApprovalDialogTypes | null>(null);

  const draftActivity = useSelector(
    ApprovalActivitiesSelectors.draftProjectApprovalActivity(project.id, currentUser.id),
  );
  const isApproveAvailable = ProjectCertificationWorkflowService.isApproveAvailable(project, currentUser);
  const isActionRequiredAvailable = ProjectCertificationWorkflowService.isActionRequiredAvailable(project, currentUser);
  const isRejectAvailable = ProjectCertificationWorkflowService.isRejectAvailable(project, currentUser);
  const isResubmitForReviewAvailable = ProjectCertificationWorkflowService.isResubmitForReviewAvailable(
    project,
    currentUser,
  );

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm({
    shouldFocusError: false,
    defaultValues: {
      comment: draftActivity?.comment || '',
    },
  });

  useEffect(() => {
    if (draftActivity?.comment) reset({ comment: draftActivity.comment });
  }, [draftActivity]);

  const openDialog = (type: ApprovalDialogTypes) => {
    setActiveDialog(type);
  };

  const closeDialog = () => {
    setActiveDialog(null);
  };

  const deleteDraft = () => {
    dispatch(ApprovalActivityActions.deleteApproval(project.id, draftActivity.id));
  };

  const saveDraft = () => {
    handleSubmit(formData => {
      const nextActivityAction = ApprovalActivityActionEnum.ChangesRequested; // should calculate based on the current status

      if (draftActivity) {
        const activityCopy = { ...draftActivity, comment: formData.comment };

        dispatch(ApprovalActivityActions.update(project.id, activityCopy));
      } else {
        const newDraftActivity = new ApprovalActivity({
          action: nextActivityAction,
          comment: formData.comment,
          status: ApprovalActivityStatus.Draft,
        });

        dispatch(ApprovalActivityActions.create(project.id, newDraftActivity, navigate));
      }
    })();
  };

  const createApprovalActivityAction = (action: ApprovalActivityActionEnum) => {
    const activity = new ApprovalActivity({
      action,
      comment: '',
      status: ApprovalActivityStatus.Final,
    });

    dispatch(ApprovalActivityActions.create(project.id, activity, navigate));
  };

  const submitActionRequired = () => {
    const isNotDraft = draftActivity.status !== ApprovalActivityStatus.Draft;

    if (isNotDraft) {
      handleSubmit(formData => {
        const activityCopy = {
          ...draftActivity,
          comment: formData.comment,
          status: ApprovalActivityStatus.Final,
        };

        dispatch(ApprovalActivityActions.update(project.id, activityCopy));
      })();
    } else {
      alert('Is a draft');
    }
  };

  return (
    <>
      {!draftActivity && (
        <>
          {isApproveAvailable && (
            <button
              onClick={() => {
                openDialog(ApprovalDialogTypes.Approve);
              }}
            >
              <Trans>Approve</Trans>
            </button>
          )}

          {isActionRequiredAvailable && (
            <button
              onClick={() => {
                createApprovalActivityAction(ApprovalActivityActionEnum.ChangesRequested);
              }}
            >
              <Trans>Action required</Trans>
            </button>
          )}

          {isRejectAvailable && (
            <button
              onClick={() => {
                openDialog(ApprovalDialogTypes.Reject);
              }}
            >
              <Trans>Reject</Trans>
            </button>
          )}

          {isResubmitForReviewAvailable && (
            <button
              onClick={() => {
                openDialog(ApprovalDialogTypes.ResubmitForApproval);
              }}
            >
              <Trans>Resubmit for approval</Trans>
            </button>
          )}
        </>
      )}

      {draftActivity && (
        <>
          <Box mb={2}>
            <Typography variant={'h6'}>
              <Trans>Please, add the details of required actions and changes</Trans>
            </Typography>
          </Box>

          <ReactHookFormText
            multiline
            name={'comment'}
            control={control}
            rules={{
              validate: {
                ...validateOptions.hasText('A feedback must be provided'),
              },
            }}
          />

          <button onClick={deleteDraft}>
            <Trans>Cancel</Trans>
          </button>

          <button onClick={saveDraft} disabled={!isDirty}>
            <Trans>Save Draft</Trans>
          </button>

          <button onClick={submitActionRequired} disabled={isDirty}>
            <Trans>Submit feedback</Trans>
          </button>
        </>
      )}

      <PgConfirmationDialog
        isOpen={activeDialog === ApprovalDialogTypes.DeleteDraft}
        {...ApprovalDialogTextMap[ApprovalDialogTypes.DeleteDraft]}
        onConfirm={deleteDraft}
        onClose={closeDialog}
      />

      <PgConfirmationDialog
        isOpen={activeDialog === ApprovalDialogTypes.ResubmitForApproval}
        {...ApprovalDialogTextMap[ApprovalDialogTypes.ResubmitForApproval]}
        onConfirm={() => {
          createApprovalActivityAction(ApprovalActivityActionEnum.SubmittedChangesForApproval);
        }}
        onClose={closeDialog}
      />

      <PgConfirmationDialog
        isOpen={activeDialog === ApprovalDialogTypes.Reject}
        {...ApprovalDialogTextMap[ApprovalDialogTypes.Reject]}
        onConfirm={() => {
          createApprovalActivityAction(ApprovalActivityActionEnum.Rejected);
        }}
        onClose={closeDialog}
      />

      <PgConfirmationDialog
        isOpen={activeDialog === ApprovalDialogTypes.Approve}
        {...ApprovalDialogTextMap[ApprovalDialogTypes.Approve]}
        onConfirm={() => {
          createApprovalActivityAction(ApprovalActivityActionEnum.Approved);
        }}
        onClose={closeDialog}
      />
    </>
  );
};
