import { createStyles, makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: 42,
      height: 42,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: pgColorScheme.background,
      cursor: 'pointer',

      ['&:hover $icon']: {
        fill: pgColorScheme.blue,
      },
    },
    icon: {
      width: 24,
      height: 24,
      display: 'block',
      fill: pgColorScheme.darkText,
      transition: 'fill .15s ease',
    },

    [theme.breakpoints.down('sm')]: {
      button: {
        position: 'fixed',
        top: 80,
        right: 0,
        bottom: 'auto',
        left: 'auto',
        width: 20,
        height: 50,
        background: pgColorScheme.blue,
        opacity: 0.4,
        borderRadius: '4px 0 0 4px',

        ['&:hover $icon']: {
          fill: pgColorScheme.white,
        },
      },
      icon: {
        width: 16,
        height: 16,
        fill: pgColorScheme.white,
      },
    },
  }),
);
