import { IdRef } from '@priz/shared/src/models/common/id-ref';
import { AuditableEntity } from '@priz/shared/src/models/common/auditable-entity';
import { CertificateStatus } from './certificate-status.enum';

export class Certificate extends AuditableEntity {
  public id?: number;

  public project: IdRef;
  public owner: IdRef;

  public title?: string;
  public abstractContent?: string;
  public hasFullDocument: boolean;
  public fullDocument?: string;

  public status: CertificateStatus;

  constructor(json: any) {
    super(json);

    this.id = json.id;

    this.project = json.project;

    this.title = json.title;
    this.abstractContent = json.abstractContent;
    this.hasFullDocument = json.hasFullDocument || false;
    this.fullDocument = json.fullDocument;
    this.status = json.status;
  }
}
