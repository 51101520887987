import axios from 'axios';

const setAuthorizationToken = (accessToken: string) => {
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

const getAuthorizationToken = () => {
  return axios.defaults.headers.common.Authorization;
};

const clearAuthorizationToken = () => {
  axios.defaults.headers.common.Authorization = undefined;
};

const setUtilizationToken = (utilizationToken: string) => {
  axios.defaults.headers.common.UTILIZATION_TOKEN = utilizationToken;
};

const getUtilizationToken = () => {
  return axios.defaults.headers.common.UTILIZATION_TOKEN;
};

const clearUtilizationToken = () => {
  axios.defaults.headers.common.UTILIZATION_TOKEN = undefined;
};

const enableAnonymousAccess = () => {
  axios.defaults.params = {
    ...(axios.defaults.params || {}),
    anonymous: true,
  };
};

const disableAnonymousAccess = () => {
  axios.defaults.params = {
    ...(axios.defaults.params || {}),
    anonymous: false,
  };
};

const isAnonymousAccessEnabled = () => {
  return !!axios.defaults.params?.anonymous;
};

export const AxiosService = {
  setAuthorizationToken,
  getAuthorizationToken,
  clearAuthorizationToken,
  setUtilizationToken,
  getUtilizationToken,
  clearUtilizationToken,
  enableAnonymousAccess,
  disableAnonymousAccess,
  isAnonymousAccessEnabled,
};
