import React, { useEffect, useState } from 'react';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans } from 'react-i18next';
import { PageContainer } from '../../content-containers/page-container/component';
import { AppPublicNavbar } from '../../navigation/app-public-navbar/component';
import { useQuery, UseQueryOptions } from 'react-query';
import { PublicMeetingsApi } from '../services/public.api';
import { Alert, Box, Chip, Grid, Link, Paper, Table, TableBody, TableContainer, Typography } from '@mui/material';
import { Agenda, Attendee, AttendeeStatus, IAgenda, IAttendee, IMeeting, Meeting } from '../store/model';
import { FieldTitle } from '@priz/shared/src/components/form-elements';
import { TimeZoneService } from '@priz/shared/src/services/time';
import { InsertInvitationRounded, SettingsOutlined, TimerOutlined } from '@mui/icons-material';
import { resolveDurationLabel } from '../../time-picker-button/component';
import { PureAttendeeItem } from '../attendee-item/component';
import { PureAgendaItem } from '../agenda-item/component';
import { AttendeeRoleEditor } from '../attendee-role-editor/component';
import { PublicWorkspaceApi } from '../../workspace/services/public.api';
import { IWorkspaceMember, WorkspaceMember } from '@priz/shared/src/models/workspace';
import { Assignee } from '../../assignment/assignee/component';
import { pgColorScheme } from '@priz/shared/src/theme';
import { PrizLoadingOverlay } from '@priz/shared/src/components/priz-loading-overlay/component';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const AcceptMeetingInvitation: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [loading, setLoading] = useState(false);
  const [isRoleFormVisible, setIsRoleFormVisible] = useState(false);
  const [errorText, setErrorText] = useState<string | null>(null);

  const queryOptions: UseQueryOptions = {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!token,
  };

  const meetingQuery = useQuery(['meeting'], () => PublicMeetingsApi.getMeeting(token), queryOptions);
  const attendeesQuery = useQuery(['attendees'], () => PublicMeetingsApi.getMeetingAttendees(token), queryOptions);
  const agendaQuery = useQuery(['agenda'], () => PublicMeetingsApi.getMeetingAgenda(token), queryOptions);
  const attendeeQuery = useQuery(
    ['attendee'],
    () => PublicMeetingsApi.getMeetingRequestedAttendee(token),
    queryOptions,
  );
  const workspaceMembers = useQuery(
    ['workspace-members'],
    () => PublicWorkspaceApi.getAllWorkspaceMembers(token),
    queryOptions,
  );

  const meeting = meetingQuery?.data ? new Meeting(meetingQuery.data as IMeeting) : null;
  const attendees = attendeesQuery?.data ? (attendeesQuery?.data as IAttendee[]).map(a => new Attendee(a)) : null;
  const agenda = agendaQuery?.data ? (agendaQuery?.data as IAgenda[]).map(a => new Agenda(a)) : null;
  const attendee = attendeeQuery?.data ? new Attendee(attendeeQuery?.data as IAttendee) : null;
  const workspaceMembersMap = workspaceMembers?.data
    ? (workspaceMembers.data as IWorkspaceMember[]).reduce((map: { [key: number]: WorkspaceMember }, item) => {
        map[item.id] = new WorkspaceMember(item);
        return map;
      }, {})
    : {};

  const allFetched =
    meetingQuery.isFetched &&
    agendaQuery.isFetched &&
    attendeesQuery.isFetched &&
    attendeeQuery.isFetched &&
    workspaceMembers.isFetched;

  useEffect(() => {
    if (allFetched && !attendee?.status) showLoginForm();
  }, [allFetched]);

  const requestHandler = (status = AttendeeStatus.Decline, explanation?: string) => {
    setLoading(true);
    setErrorText(null);

    void PublicMeetingsApi.updateMeetingRequestedAttendee(token, { status, explanation })
      .then(
        () => {
          void attendeeQuery.refetch();
          void attendeesQuery.refetch();
          hideLoginForm();
        },
        () => {
          setErrorText('Failed to accept meeting invitation');
        },
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const showLoginForm = () => {
    setIsRoleFormVisible(true);
  };

  const hideLoginForm = () => {
    setIsRoleFormVisible(false);
  };

  if (!token) {
    navigate('/');
  }

  if (!allFetched) {
    return <PrizLoadingOverlay />;
  }

  if (attendeeQuery.isError) {
    return (
      <PageContainer>
        <AppPublicNavbar />

        <Alert severity={'error'}>
          <Trans>Failed to load meeting invitation</Trans>
        </Alert>
      </PageContainer>
    );
  }

  const renderChangeAttendeeStatusButton = () => {
    return (
      <Link component={'span'} onClick={showLoginForm}>
        <Trans>Change</Trans> <SettingsOutlined fontSize={'inherit'} sx={{ verticalAlign: 'middle' }} />
      </Link>
    );
  };

  return (
    <PageContainer variant={'narrow'}>
      <AppPublicNavbar />

      <div>
        {meeting && <PageTitleWithDocLink title={meeting.title} />}

        <div>
          {errorText && (
            <Box mb={2}>
              <Alert severity={'error'}>
                <Trans>{errorText}</Trans>
              </Alert>
            </Box>
          )}

          {attendee?.status && (
            <Box mb={2}>
              {attendee.status === AttendeeStatus.Decline && (
                <Alert>
                  <Trans>You have declined to this meeting</Trans> {renderChangeAttendeeStatusButton()}
                </Alert>
              )}

              {[AttendeeStatus.Contributor, AttendeeStatus.Observer].includes(attendee.status) && (
                <Alert severity={'success'}>
                  <Trans>You are attending to this meeting as </Trans> <Trans>{attendee.status}</Trans>.{' '}
                  {renderChangeAttendeeStatusButton()}
                </Alert>
              )}
            </Box>
          )}

          {attendee && isRoleFormVisible && (
            <Box mb={4}>
              {!attendee?.status && (
                <Box mb={2}>
                  <Typography variant={'h6'}>
                    <Trans>Accept meeting invitation</Trans>:
                  </Typography>
                </Box>
              )}

              <Paper variant={'outlined'} sx={{ backgroundColor: 'transparent' }}>
                <Box p={2}>
                  <AttendeeRoleEditor
                    attendee={attendee}
                    onAccept={requestHandler}
                    onDecline={attendee?.status !== AttendeeStatus.Decline && requestHandler}
                    onCancel={attendee?.status && hideLoginForm}
                    disabled={loading}
                    loading={loading}
                  />
                </Box>
              </Paper>
            </Box>
          )}

          {meeting && (
            <>
              <Box mb={2}>
                <Typography variant={'h6'}>
                  <Trans>Meeting details</Trans>:
                </Typography>
              </Box>

              <Box mb={2}>
                <Grid container spacing={2}>
                  {workspaceMembersMap[meeting.createdBy.id] && (
                    <Grid item maxWidth={'100%'}>
                      <FieldTitle text={'Meeting lead'} />
                      <Assignee assignee={workspaceMembersMap[meeting.createdBy.id]} variant="filled" />
                    </Grid>
                  )}

                  {meeting.date && (
                    <Grid item maxWidth={'100%'}>
                      <FieldTitle text={'Date and time'} />
                      <Chip
                        label={`${TimeZoneService.format(meeting.date)} (${
                          TimeZoneService.getCurrentTimeZone().offsetLabel
                        })`}
                        icon={<InsertInvitationRounded />}
                        variant="outlined"
                      />
                    </Grid>
                  )}

                  {meeting.durationMs && (
                    <Grid item>
                      <FieldTitle text={'Duration'} />
                      <Chip
                        label={resolveDurationLabel(meeting.durationMs)}
                        icon={<TimerOutlined />}
                        variant="outlined"
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </>
          )}

          {!!attendees?.length && (
            <Box mb={2}>
              <FieldTitle text={'Attendees'} />

              <Box display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
                {attendees.map(attendee => (
                  <Box key={attendee.id} mb={1} mr={1} maxWidth={'100%'}>
                    <PureAttendeeItem attendee={attendee} workspaceMember={workspaceMembersMap[attendee?.user?.id]} />
                  </Box>
                ))}
              </Box>
            </Box>
          )}

          {!!agenda?.length && (
            <Box mb={2}>
              <FieldTitle text={'Agenda'} />

              <TableContainer component={Paper} variant={'outlined'} sx={{ backgroundColor: 'transparent' }}>
                <Table>
                  <TableBody>
                    {agenda.map((agenda, key) => (
                      <PureAgendaItem key={agenda.id} agenda={agenda} index={key + 1} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </div>
      </div>

      <Box mt={6} p={2} sx={{ backgroundColor: pgColorScheme.white, textAlign: 'center' }}>
        <div>
          <Typography>
            <Trans>This meeting is managed by</Trans>{' '}
            <Link href={'https://www.priz.guru/'} target={'_blank'}>
              PRIZ Guru
            </Link>
          </Typography>
        </div>

        <div>
          <Typography>
            <Link href={'/signup'} target={'_blank'}>
              <Trans>Create free account</Trans>
            </Link>
          </Typography>
        </div>
      </Box>
    </PageContainer>
  );
};
