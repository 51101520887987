import React, { useEffect, useState } from 'react';
import { RrmToolStarter, RrmToolStarterProps } from '../RrmToolStarter';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Trans } from 'react-i18next';

export interface StartRankingDialogProps extends RrmToolStarterProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const StartRankingDialog: React.FC<StartRankingDialogProps> = ({ isOpen, onClose, ...rest }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
      <DialogTitle>
        <Trans>Choose Ranking Method</Trans>
      </DialogTitle>

      <DialogContent>
        <Box py={{ xs: 0, sm: 2 }}>
          <RrmToolStarter {...rest} />
        </Box>
      </DialogContent>

      <DialogActions sx={{ textAlign: 'right' }}>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose}>
          <Trans>Cancel</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
