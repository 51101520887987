import React from 'react';
import { Role } from '@priz/shared/src/models/security/role.enum';
import { UserSelectors } from '../../../user/store/selectors/user.selectors';
import { useSelector } from 'react-redux';

interface RenderForRolesProps {
  roles: Role[];
  reverse?: boolean;
}

export const RenderForRoles: React.FC<RenderForRolesProps> = ({ roles, reverse, children }) => {
  const user = useSelector(UserSelectors.currentUserSelector);

  if (!user) {
    return null;
  }

  if (!reverse && !user.roles.some(r => roles.includes(r))) {
    return null;
  }

  if (reverse && user.roles.some(r => roles.includes(r))) {
    return null;
  }

  return <>{children}</>;
};
