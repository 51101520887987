import { pgColorScheme } from '../theme';
import { Options } from '../lib/vis/esnext';
import { CecNodeProps, CecEdgeProps } from '../models/tools/cec';

const colors = {
  default: pgColorScheme.blue,
  hover: pgColorScheme.darkText,
  highlight: pgColorScheme.orange,
  background: pgColorScheme.white,
};

const arrows = {
  blue: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMS44ODAxIDYuMjc2NzRMMTEuMjc4OSA2Ljg3Nzg1QzExLjE5ODggNi45NTgxMyAxMS4xMDY2IDYuOTk4MTQgMTEuMDAyMiA2Ljk5ODE0QzEwLjg5ODIgNi45OTgxNCAxMC44MDU5IDYuOTU4MTMgMTAuNzI1OCA2Ljg3Nzg1TDYuMDAwMDYgMi4xNTIzNUwxLjI3NDUxIDYuODc3NzNDMS4xOTQzNiA2Ljk1OCAxLjEwMjEzIDYuOTk4MDEgMC45OTc5MzMgNi45OTgwMUMwLjg5MzY5NiA2Ljk5ODAxIDAuODAxNDYzIDYuOTU4IDAuNzIxMzU5IDYuODc3NzNMMC4xMjAyODMgNi4yNzY1N0MwLjA0MDAxIDYuMTk2NDYgMCA2LjEwNDIzIDAgNS45OTk5OUMwIDUuODk1ODQgMC4wNDAxMzYzIDUuODAzNjEgMC4xMjAyODMgNS43MjM1MUw1LjcyMzQ5IDAuMTIwMTg4QzUuODAzNTkgMC4wNDAwMzc4IDUuODk1ODcgLTEuMTQ0NDFlLTA1IDYuMDAwMDYgLTEuMTQ0NDFlLTA1QzYuMTA0MjYgLTEuMTQ0NDFlLTA1IDYuMTk2MzcgMC4wNDAwMzc4IDYuMjc2NDMgMC4xMjAxODhMMTEuODgwMSA1LjcyMzUxQzExLjk2MDIgNS44MDM2NSAxMiA1Ljg5NTg4IDEyIDUuOTk5OTlDMTIgNi4xMDQyMyAxMS45NjAyIDYuMTk2NDYgMTEuODgwMSA2LjI3Njc0WiIgZmlsbD0iIzExOThEMiIvPgo8L3N2Zz4K',
  dark: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMS44ODAxIDYuMjc2NzRMMTEuMjc4OSA2Ljg3Nzg1QzExLjE5ODggNi45NTgxMyAxMS4xMDY2IDYuOTk4MTQgMTEuMDAyMiA2Ljk5ODE0QzEwLjg5ODIgNi45OTgxNCAxMC44MDU5IDYuOTU4MTMgMTAuNzI1OCA2Ljg3Nzg1TDYuMDAwMDYgMi4xNTIzNUwxLjI3NDUxIDYuODc3NzNDMS4xOTQzNiA2Ljk1OCAxLjEwMjEzIDYuOTk4MDEgMC45OTc5MzMgNi45OTgwMUMwLjg5MzY5NiA2Ljk5ODAxIDAuODAxNDYzIDYuOTU4IDAuNzIxMzU5IDYuODc3NzNMMC4xMjAyODMgNi4yNzY1N0MwLjA0MDAxIDYuMTk2NDYgMCA2LjEwNDIzIDAgNS45OTk5OUMwIDUuODk1ODQgMC4wNDAxMzYzIDUuODAzNjEgMC4xMjAyODMgNS43MjM1MUw1LjcyMzQ5IDAuMTIwMTg4QzUuODAzNTkgMC4wNDAwMzc4IDUuODk1ODcgLTEuMTQ0NDFlLTA1IDYuMDAwMDYgLTEuMTQ0NDFlLTA1QzYuMTA0MjYgLTEuMTQ0NDFlLTA1IDYuMTk2MzcgMC4wNDAwMzc4IDYuMjc2NDMgMC4xMjAxODhMMTEuODgwMSA1LjcyMzUxQzExLjk2MDIgNS44MDM2NSAxMiA1Ljg5NTg4IDEyIDUuOTk5OTlDMTIgNi4xMDQyMyAxMS45NjAyIDYuMTk2NDYgMTEuODgwMSA2LjI3Njc0WiIgZmlsbD0iIzFBMTkyQiIvPgo8L3N2Zz4K',
  orange:
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMS44ODAxIDYuMjc2NzRMMTEuMjc4OSA2Ljg3Nzg1QzExLjE5ODggNi45NTgxMyAxMS4xMDY2IDYuOTk4MTQgMTEuMDAyMiA2Ljk5ODE0QzEwLjg5ODIgNi45OTgxNCAxMC44MDU5IDYuOTU4MTMgMTAuNzI1OCA2Ljg3Nzg1TDYuMDAwMDYgMi4xNTIzNUwxLjI3NDUxIDYuODc3NzNDMS4xOTQzNiA2Ljk1OCAxLjEwMjEzIDYuOTk4MDEgMC45OTc5MzMgNi45OTgwMUMwLjg5MzY5NiA2Ljk5ODAxIDAuODAxNDYzIDYuOTU4IDAuNzIxMzU5IDYuODc3NzNMMC4xMjAyODMgNi4yNzY1N0MwLjA0MDAxIDYuMTk2NDYgMCA2LjEwNDIzIDAgNS45OTk5OUMwIDUuODk1ODQgMC4wNDAxMzYzIDUuODAzNjEgMC4xMjAyODMgNS43MjM1MUw1LjcyMzQ5IDAuMTIwMTg4QzUuODAzNTkgMC4wNDAwMzc4IDUuODk1ODcgLTEuMTQ0NDFlLTA1IDYuMDAwMDYgLTEuMTQ0NDFlLTA1QzYuMTA0MjYgLTEuMTQ0NDFlLTA1IDYuMTk2MzcgMC4wNDAwMzc4IDYuMjc2NDMgMC4xMjAxODhMMTEuODgwMSA1LjcyMzUxQzExLjk2MDIgNS44MDM2NSAxMiA1Ljg5NTg4IDEyIDUuOTk5OTlDMTIgNi4xMDQyMyAxMS45NjAyIDYuMTk2NDYgMTEuODgwMSA2LjI3Njc0WiIgZmlsbD0iI0Y4ODAxRSIvPgo8L3N2Zz4K',
};

export const viewOptions: Options = {
  interaction: {
    dragNodes: false,
    hideEdgesOnDrag: false,
    hideEdgesOnZoom: false,
    hideNodesOnDrag: false,
    hover: true,
    hoverConnectedEdges: true,
    keyboard: false,
    navigationButtons: false,
    selectable: true,
    selectConnectedEdges: true,
  },
};

const chosenEdge = (reverse?: boolean) => {
  //TODO: types
  //@ts-ignore
  return (values, _id, selected, hovering) => {
    if (hovering) {
      values.color = colors.hover;

      if (reverse) {
        values.fromArrowSrc = arrows.dark;
      } else {
        values.toArrowSrc = arrows.dark;
      }
    }
    if (selected) {
      values.color = colors.highlight;

      if (reverse) {
        values.fromArrowSrc = arrows.orange;
      } else {
        values.toArrowSrc = arrows.orange;
      }
    }
  };
};

export const constructNodePropsDecorator = (defaultPlaceholder: string) => {
  return (node: Partial<CecNodeProps>): Partial<CecNodeProps> => ({
    ...node,
    placeholder: defaultPlaceholder,
  });
};

export const edgePropsDecorator = (edge: Partial<CecEdgeProps>, label?: string): Partial<CecEdgeProps> => {
  const arrowProps = {
    enabled: true,
    type: 'image',
    imageWidth: 12,
    imageHeight: 7,
    src: arrows.blue,
  };

  return {
    ...edge,

    label: label || 'Causes',
    arrows: {
      to: edge.reverse ? arrowProps : false,
      from: edge.reverse ? false : arrowProps,
    },
    chosen: {
      // @ts-ignore
      edge: chosenEdge(!edge.reverse),
    },
  };
};

export const options: Options = {
  edges: {
    physics: false,
    label: 'Why?',
    smooth: {
      enabled: true,
      type: 'cubicBezier',
      roundness: 0.7,
      forceDirection: 'vertical',
    },
    arrows: {
      to: {
        enabled: true,
        type: 'image',
        imageWidth: 12,
        imageHeight: 7,
        src: arrows.blue,
      },
      from: false,
    },
    endPointOffset: {
      from: 0,
      to: 0,
    },
    arrowStrikethrough: true,
    chosen: {
      // @ts-ignore
      edge: chosenEdge(),
    },
    color: {
      color: colors.default,
      highlight: colors.highlight,
      hover: colors.hover,
      inherit: 'from',
      opacity: 1.0,
    },
    dashes: false,
    hidden: false,
    hoverWidth: 0,
    labelHighlightBold: false,
    selectionWidth: 0,
    width: 1,

    scaling: {
      label: {
        drawThreshold: 0,
      },
    },
  },
  interaction: {
    dragNodes: false,
    hover: true,
  },
  layout: {
    improvedLayout: true,
    hierarchical: {
      enabled: true,
      levelSeparation: 250,
      nodeSpacing: 400,
      sortMethod: 'directed',
      shakeTowards: 'roots',
      edgeMinimization: true,
      blockShifting: true,
    },
  },
  physics: {
    enabled: false,
    hierarchicalRepulsion: {
      avoidOverlap: 1,
    },
  },
  nodes: {
    physics: true,
    shadow: {
      enabled: false,
      size: 0,
    },
    borderWidth: 1,
    borderWidthSelected: 1,
    labelHighlightBold: false,
    // @ts-ignore
    chosen: {
      node: (values, _id, selected, hovering) => {
        values.color = colors.background;
        values.borderColor = colors.default;

        if (hovering && !selected) {
          values.borderColor = colors.hover;
        }
        if (selected) {
          if (values.borderColor.toLowerCase() === colors.default.toLowerCase()) {
            values.borderColor = colors.highlight;
          }
          values.borderDashes = [5, 5];
        }
      },
    },
    margin: {
      top: 20,
      right: 40,
      bottom: 20,
      left: 40,
    },
    color: {
      border: colors.default,
      background: colors.background,
      highlight: {
        border: colors.highlight,
        background: colors.background,
      },
      hover: {
        border: colors.hover,
        background: colors.background,
      },
    },
    opacity: 1,
    font: {
      color: '#222',
      size: 18,
      background: 'none',
      align: 'center',
      face: '"Roboto", sans-serif',
      multi: false,
    },

    shape: 'box',
    shapeProperties: {
      borderRadius: 10,
    },
    heightConstraint: {
      minimum: 50,
    },
    widthConstraint: {
      minimum: 100,
      maximum: 400,
    },

    scaling: {
      label: {
        drawThreshold: 0,
      },
    },
  },
};

interface CecOptionsTexts {
  edgeLabel?: string;
}

export const getCecTranslatedOptions = (props: CecOptionsTexts): Options => {
  const { edgeLabel } = props;
  const optionsCopy = JSON.parse(JSON.stringify(options)) as Options;

  if (edgeLabel && optionsCopy?.edges?.label) optionsCopy.edges.label = edgeLabel;

  return optionsCopy;
};
