import React from 'react';
import { useStyles } from './styles';

interface CanvasControlsGroupProps {
  unWrap?: boolean;
}

export const CanvasControlsGroup: React.FC<CanvasControlsGroupProps> = ({ unWrap, children }) => {
  const styles = useStyles();

  if (unWrap) {
    return <>{children}</>;
  }

  return <div className={styles.root}>{children}</div>;
};
