import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationActions, NotificationSelectors } from './store';
import { Trans } from 'react-i18next';

export const Notificator: React.FC = () => {
  const dispatch = useDispatch();

  const notification = useSelector(NotificationSelectors.getLast);

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    dispatch(NotificationActions.remove());
  };

  return (
    <Snackbar
      open={notification.show}
      autoHideDuration={notification?.duration || 2000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={notification?.severity}>
        <Trans>{notification?.message || ''}</Trans>
      </Alert>
    </Snackbar>
  );
};
