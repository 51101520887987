import { ToolType } from '@priz/shared/src/models/tools';
import {
  TemplateActivityOptionType,
  TemplateCategory,
  TemplatePreparingOptionType,
  TemplateResultOptionType,
  TemplateType,
} from '@priz/shared/src/models/template';

import testImgUrl1 from '../../../../assets/img/templates/test-img-1920x1080.png';
import testImgUrl2 from '../../../../assets/img/templates/test-img-400x200.png';

export const templateTypeTextMap: { [key in TemplateType]: string } = {
  [TemplateType.General]: 'General',
  [TemplateType.ExcursionPrevention]: 'Excursion Prevention',
  [TemplateType.LeanSixSigma]: 'Lean/Six Sigma',
  [TemplateType.RootCauseAnalysis]: 'Root Cause Analysis',
};

type TemplateTypeDescription = {
  [key in TemplateType]: {
    text: string;
    img: string;
  };
};

export const templateTypeDescription: TemplateTypeDescription = {
  [TemplateType.General]: {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    img: testImgUrl1,
  },
  [TemplateType.ExcursionPrevention]: {
    text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
    img: testImgUrl2,
  },
  [TemplateType.LeanSixSigma]: {
    text: 'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
    img: testImgUrl1,
  },
  [TemplateType.RootCauseAnalysis]: {
    text: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.',
    img: testImgUrl2,
  },
};

export interface OptionsDescription {
  preview: string;
  full: string;
}

interface TemplateOptionsDescription {
  [TemplateCategory.Preparing]: {
    [key in TemplatePreparingOptionType]: OptionsDescription;
  };
  [TemplateCategory.Tools]: {
    [key in ToolType]: OptionsDescription;
  };
  [TemplateCategory.Activity]: {
    [key in TemplateActivityOptionType]: OptionsDescription;
  };
  [TemplateCategory.Result]: {
    [key in TemplateResultOptionType]: OptionsDescription;
  };
}

type TemplatesOptionsDescription = {
  [key in TemplateType]: TemplateOptionsDescription;
};

export const defaultTemplateOptionsDescription: TemplateOptionsDescription = {
  [TemplateCategory.Preparing]: {
    [TemplatePreparingOptionType.ProjectBackground]: {
      preview: 'Describe the background of the issue',
      full: 'full_text',
    },
    [TemplatePreparingOptionType.ProblemStatement]: {
      preview: 'Define the problem  statement',
      full: 'full_text',
    },
  },
  [TemplateCategory.Tools]: {
    [ToolType.TOOL_40_PRINCIPLES]: {
      preview: 'Generate ideas with 40 Inventive Principles',
      full: 'full_text',
    },
    [ToolType.RRM]: {
      preview: '',
      full: 'full_text',
    },
    [ToolType.UIM]: {
      preview: '',
      full: 'full_text',
    },
    [ToolType.CEC]: {
      preview: 'Root Cause Analysis with Cause Effect Chain',
      full: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    [ToolType.APA]: {
      preview: 'Define customer needs',
      full: 'full_text',
    },
    [ToolType.EBS]: {
      preview: 'Effective Brainstorming',
      full: 'full_text',
    },
    [ToolType.NINE_WINDOWS]: {
      preview: '',
      full: 'full_text',
    },
    [ToolType.FIVE_WHYS]: {
      preview: 'Search for Root Cause with 5+Whys',
      full: 'full_text',
    },
    [ToolType.P_MAP]: {
      preview: 'Define blockers with Perception Mapping',
      full: 'full_text',
    },
    [ToolType.SFM]: {
      preview: 'Create System Functional Model',
      full: 'full_text',
    },
    [ToolType.PFM]: {
      preview: 'Create Process Functional Model',
      full: 'full_text',
    },
    [ToolType.CFT]: {
      preview: 'Change Flow Thinking',
      full: 'full_text',
    },
  },
  [TemplateCategory.Activity]: {
    [TemplateActivityOptionType.Tasks]: {
      preview: 'Create Action Items - TASKS',
      full: 'full_text',
    },
    [TemplateActivityOptionType.TasksPriority]: {
      preview: 'Manage TASKS priority with Urgency-Importance',
      full: 'full_text',
    },
    [TemplateActivityOptionType.Meetings]: {
      preview: 'Schedule meetings',
      full: 'full_text',
    },
    [TemplateActivityOptionType.Publishing]: {
      preview: 'Publish project',
      full: 'full_text',
    },
  },
  [TemplateCategory.Result]: {
    [TemplateResultOptionType.Ideas]: {
      preview: 'Document and manage ideas',
      full: 'full_text',
    },
    [TemplateResultOptionType.Solution]: {
      preview: 'Document Proposed Solution',
      full: 'full_text',
    },
    [TemplateResultOptionType.Report]: {
      preview: 'Run the report',
      full: 'full_text',
    },
  },
};

export const templatesOptionsDescription: TemplatesOptionsDescription = {
  [TemplateType.General]: {
    ...defaultTemplateOptionsDescription,
  },

  [TemplateType.ExcursionPrevention]: {
    [TemplateCategory.Preparing]: {
      ...defaultTemplateOptionsDescription[TemplateCategory.Preparing],
      [TemplatePreparingOptionType.ProjectBackground]: {
        preview: 'Describe the background of the excursion',
        full: 'full_text',
      },
    },
    [TemplateCategory.Tools]: {
      ...defaultTemplateOptionsDescription[TemplateCategory.Tools],
      [ToolType.CEC]: {
        preview: 'Perform Root Cause Analysis with Cause Effect Chain',
        full: 'full_text',
      },
      [ToolType.EBS]: {
        preview: 'Perform Brainstorming',
        full: 'full_text',
      },
    },
    [TemplateCategory.Activity]: {
      ...defaultTemplateOptionsDescription[TemplateCategory.Activity],
    },
    [TemplateCategory.Result]: {
      ...defaultTemplateOptionsDescription[TemplateCategory.Result],
    },
  },

  [TemplateType.LeanSixSigma]: {
    [TemplateCategory.Preparing]: {
      ...defaultTemplateOptionsDescription[TemplateCategory.Preparing],
      [TemplatePreparingOptionType.ProjectBackground]: {
        preview: 'Describe the background',
        full: 'full_text',
      },
    },
    [TemplateCategory.Tools]: {
      ...defaultTemplateOptionsDescription[TemplateCategory.Tools],
      [ToolType.SFM]: {
        preview: 'Define Functional & Problem Rank of the System with System Functional Model',
        full: 'full_text',
      },
      [ToolType.PFM]: {
        preview: 'Define valuable and wasting operations with Create Process Functional Model',
        full: 'full_text',
      },
      [ToolType.EBS]: {
        preview: 'Generate and rank ideas with Brainstorming',
        full: 'full_text',
      },
    },
    [TemplateCategory.Activity]: {
      ...defaultTemplateOptionsDescription[TemplateCategory.Activity],
    },
    [TemplateCategory.Result]: {
      ...defaultTemplateOptionsDescription[TemplateCategory.Result],
    },
  },

  [TemplateType.RootCauseAnalysis]: {
    [TemplateCategory.Preparing]: {
      ...defaultTemplateOptionsDescription[TemplateCategory.Preparing],
    },
    [TemplateCategory.Tools]: {
      ...defaultTemplateOptionsDescription[TemplateCategory.Tools],
    },
    [TemplateCategory.Activity]: {
      ...defaultTemplateOptionsDescription[TemplateCategory.Activity],
    },
    [TemplateCategory.Result]: {
      ...defaultTemplateOptionsDescription[TemplateCategory.Result],
    },
  },
};
