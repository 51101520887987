import React from 'react';
import { Box, BoxProps, Grid } from '@mui/material';
import { countSfmRanksEfficiencies } from '../../utils/sfm';
import { countPfmStepsTypes, resolvePfmTypeIcon } from '../../utils/pfm';
import { SfmRankMap, SfmUtilization } from '../../models/tools/sfm';
import { PfmStepType, PfmUtilization } from '../../models/tools/pfm';
import { pgColorScheme } from '../../theme';
import { Circle } from '@mui/icons-material';
import { PfmDiagnosis } from './diagnosis/component';
import { PfmPrescription } from './prescription/component';
import { PieChardCard } from '../pie-chart-card/component';

interface PfmSummaryProps {
  pfmUtilization: PfmUtilization;
  sfmUtilizationArray: SfmUtilization[];
  sfmRankMap: SfmRankMap;
  pieContainerProps?: BoxProps;
}

export const PfmSummary: React.FC<PfmSummaryProps> = ({
  pfmUtilization,
  sfmUtilizationArray,
  sfmRankMap,
  pieContainerProps,
}) => {
  const pfmSteps = pfmUtilization?.diagramData?.steps || [];
  const productiveSfmRanks = pfmSteps.reduce((map: SfmRankMap, step) => {
    if (step.type === PfmStepType.Productive && sfmRankMap[step.sfmId]) map[step.sfmId] = sfmRankMap[step.sfmId];
    return map;
  }, {});
  const sfmRanksEfficienciesCounts = countSfmRanksEfficiencies(productiveSfmRanks);
  const pfmStepsTypesCounts = countPfmStepsTypes(pfmUtilization?.diagramData?.steps || []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <PieChardCard
            cardTitle={'Productive operations effectiveness'}
            chartData={[
              { title: 'Effective', value: sfmRanksEfficienciesCounts.effective, color: pgColorScheme.blue },
              {
                title: 'Ineffective',
                value: sfmRanksEfficienciesCounts.ineffective,
                color: pgColorScheme.coral,
              },
            ]}
            legendData={[
              { label: 'Effective', icon: <Circle fontSize={'small'} sx={{ fill: pgColorScheme.blue }} /> },
              { label: 'Ineffective', icon: <Circle fontSize={'small'} sx={{ fill: pgColorScheme.coral }} /> },
            ]}
            pieContainerProps={pieContainerProps}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <PieChardCard
            cardTitle={'Operation types breakdown'}
            chartData={[
              { title: 'Productive', value: pfmStepsTypesCounts.Productive, color: pgColorScheme.green },
              { title: 'Providing', value: pfmStepsTypesCounts.Providing, color: pgColorScheme.blue },
              { title: 'Corrective', value: pfmStepsTypesCounts.Corrective, color: pgColorScheme.orange },
              { title: 'Metrology', value: pfmStepsTypesCounts.Metrology, color: pgColorScheme.purple },
            ]}
            legendData={[
              PfmStepType.Productive,
              PfmStepType.Providing,
              PfmStepType.Corrective,
              PfmStepType.Metrology,
            ].map(type => ({ label: type, icon: resolvePfmTypeIcon(type) }))}
            pieContainerProps={pieContainerProps}
          />
        </Grid>
      </Grid>

      <Box mt={4}>
        <PfmDiagnosis />
      </Box>

      {sfmRankMap && !!Object.keys(sfmRankMap).length && (
        <Box mt={4}>
          <PfmPrescription
            pfmUtilization={pfmUtilization}
            sfmUtilizationArray={sfmUtilizationArray}
            sfmRanks={sfmRankMap}
          />
        </Box>
      )}
    </div>
  );
};
