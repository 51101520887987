import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { Box, Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Skeleton, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { copyObject } from '@priz/shared/src/utils/common';
import { AssistantHintData } from '../store/model';
import { ProgressBarWithLegend } from '../../react/elements/progress-bar-with-legend/components';
import { resolveHintText } from '../utils';

export enum HintLinesSeparatorType {
  LineBreak = 'LineBreak',
  OrderNumber = 'OrderNumber',
}

export enum HintRowType {
  Text = 'Text',
  Content = 'Content',
  Radio = 'Radio',
  Checkbox = 'Checkbox',
}

export interface SharedHintContentProps {
  linesSeparator?: HintLinesSeparatorType;
  hintRowsType?: HintRowType;
}

export interface HintContentProps extends SharedHintContentProps {
  hintData?: AssistantHintData;
  loading?: boolean;
  onSelect?: (selectedRows: string[]) => void;
}

const removeOrderNumber = (text: string): string => {
  return text.replace(/\d+\. /, '');
};

export const HintContent: React.FC<HintContentProps> = ({
  hintData,
  loading,
  linesSeparator = HintLinesSeparatorType.LineBreak,
  hintRowsType = HintRowType.Text,
  onSelect,
}) => {
  const styles = useStyles();
  const [isDirty, setIsDirty] = useState(false);
  const [selectedValues, setSelectedValues] = useState<{ [key: number]: string }>({});

  const { completeness } = hintData || {};
  const { textRows, rawText, content } = resolveHintText(hintData, linesSeparator);

  useEffect(() => {
    if (rawText) {
      setIsDirty(false);
      setSelectedValues({});
      if (onSelect) onSelect([]);
    }
  }, [rawText]);

  useEffect(() => {
    if (isDirty && onSelect) {
      onSelect(Object.values(selectedValues));
    }
  }, [selectedValues, isDirty]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValues(currentState => {
      const stateCopy = copyObject(currentState);

      if (e.target.checked) {
        stateCopy[e.target.name] = e.target.value;
      } else {
        delete stateCopy[e.target.name];
      }

      return stateCopy;
    });

    setIsDirty(true);
  };

  if (!rawText && !content && loading) {
    return (
      <>
        <Skeleton animation="wave" width={'100%'} />
        <Skeleton animation="wave" width={'100%'} />
        <Skeleton animation="wave" width={'50%'} />
      </>
    );
  }

  return (
    <div className={styles.hintContainer}>
      {completeness !== null && <ProgressBarWithLegend value={completeness} label={'Completeness'} mb={2} />}

      {!textRows && !content && (
        <Typography variant={'body1'}>
          <Trans>My apologies, I couldn't come up with a good answer.</Trans>
        </Typography>
      )}

      {textRows && hintRowsType === HintRowType.Text && (
        <Typography variant={'body1'}>
          {textRows.map((textRow, key) => (
            <Box key={key} component={'span'} display={'block'}>
              {textRow}
            </Box>
          ))}
        </Typography>
      )}

      {content && hintRowsType === HintRowType.Content && content}

      {textRows && hintRowsType === HintRowType.Checkbox && (
        <FormGroup onChange={handleChange}>
          {textRows.map((item, key) => (
            <Box key={key} mb={1}>
              <FormControlLabel
                label={item}
                control={<Checkbox name={key.toString()} value={removeOrderNumber(item)} size={'small'} />}
              />
            </Box>
          ))}
        </FormGroup>
      )}

      {textRows && hintRowsType === HintRowType.Radio && (
        <RadioGroup onChange={handleChange} name={'hint-rows'}>
          {textRows.map((item, key) => (
            <Box key={key} mb={1}>
              <FormControlLabel label={item} control={<Radio value={removeOrderNumber(item)} size={'small'} />} />
            </Box>
          ))}
        </RadioGroup>
      )}
    </div>
  );
};
