export const pgColorScheme = {
  gray50: '#fafafa',
  gray100: '#F7FAFC',
  gray200: '#EDF2F7',
  gray300: '#E2E8F0',
  gray400: '#CBD5E0',
  gray500: '#A0AEC0',
  gray600: '#718096',
  gray700: '#4A5568',
  gray800: '#2D3748',
  gray900: '#1A202C',
  purple: '#A436D2B2',
  purple2: '#7B6BDF',
  blue2: '#1198D2B2',
  green2: '#19C974B2',
  background: '#F4F4F4',
  mediumBackground: '#efefef',
  workbenchBackground: '#f8f8f8',
  backgroundMediumGray: '#e8e8e8',
  backgroundDarkGray: '#bdbdbd',
  black: '#000',
  blue: '#1198D2',
  darkBlue: '#1085B7',
  mediumBlue: '#61d0ff',
  blueLight: '#62D6F0',
  blueLight2: '#7FCEFA',
  blueSuperLight: '#C0E9FF',
  darkGray: '#3C3C3C',
  darkText: '#1a192b',
  gray: '#ADADAD',
  gray2: '#e0e0e0',
  gray3: '#d5d5d5',
  green: '#64C66E',
  greenBright: '#8fd14f',
  greenMedium: '#53a75b',
  lightBlue: '#E8F7FF',
  lightGray: '#D8D8D8',
  orange: '#F8801E',
  orangeBright: '#fac710',
  mediumOrange: '#ffa45a',
  darkOrange: '#EC6B22',
  placeholder: '#c4c4c4',
  textGray: '#858585',
  transparent: 'transparent',
  white: '#FFF',
  red: '#ff0000',
  redBright: '#FF3014',
  mediumRed: '#E80000',
  coral: '#FA6464',
  coralDark: '#fd4949',
  yellowLight: '#FFFFD1',
};

export const pgGradients = {
  orange: 'linear-gradient(225deg, #FFC656 0%, #F16063 100%)',
  blue: 'linear-gradient(225deg, #68DBF2 0.01%, #1198D2 100%)',
};
