import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';

export const useStyles = makeStyles({
  barWrap: {
    borderRadius: 10,
  },
  backdrop: {
    backgroundColor: 'rgba(0,0,0,.05)',
  },
  bar: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: pgColorScheme.white,
    borderRadius: 'inherit',
    minWidth: 28,
    zIndex: 1,
  },
  functionalBar: {
    background: pgColorScheme.blue,
  },
  problematicBar: {
    background: pgColorScheme.coral,
  },
  barValue: {
    lineHeight: 1,
  },
  noImpact: {
    width: 'auto',
  },
  noImpactStretch: {
    width: '100%',
    height: 'auto',
    lineHeight: 1,
  },
});
