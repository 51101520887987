import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material';
import { pgColorScheme } from '../../../theme';

export const useStyles = makeStyles({
  nodeOverlay: {
    position: 'fixed',
    right: 'auto',
    bottom: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
  backdrop: {
    position: 'absolute',
    width: 'calc(100% + 10px)',
    height: 'calc(100% + 10px)',
    top: -5,
    right: 'auto',
    bottom: 'auto',
    left: -5,
    background: alpha(pgColorScheme.white, 0.8),
    borderRadius: 4,
  },
  popupCloseButton: {
    position: 'absolute',
    top: 2,
    right: 2,
    bottom: 'auto',
    left: 'auto',
  },
  icon: {
    position: 'relative',
    zIndex: 9,
  },
});
