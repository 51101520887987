import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PfmStep, PfmUtilization } from '@priz/shared/src/models/tools/pfm';
import { PfmItem } from '../pfm-item/component';
import { PfmUtilizationActions } from '../store/actions';
import { SfmRankMap, SfmUtilization } from '@priz/shared/src/models/tools/sfm';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { PfmViewer } from '@priz/shared/src/components/tools-viewers/pfm/component';
import { Trans } from 'react-i18next';
import { pgColorScheme } from '@priz/shared/src/theme';
import { ToolUtilizationCommand } from '../../project/services';
import { deleteStepBySfmId } from '../utils';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { PaywallActions } from '../../react/modules/paywall/store';
import { ToolType } from '@priz/shared/src/models/tools';
import { SfmResultSelectors } from '../../sfm/store/selectors';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { getMaxSfmRanks } from '@priz/shared/src/utils/pfm';
import { ToolUtilizationActions } from '../../project/store/actions/tools.actions';
import { LocalesService } from '@priz/shared/src/components/locales/services';

import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';

interface PfmWorkspaceProps {
  utilization: PfmUtilization;
  sfmRankMap: SfmRankMap;
  disabled?: boolean;
}

const resolvePfmViewerGridProps = (isDownXl, isDownLg, isDownMd): { itemsPerRow: number; spacing: number } => {
  if (isDownMd) return { itemsPerRow: 1, spacing: 5 };
  if (isDownLg) return { itemsPerRow: 2, spacing: 5 };
  if (isDownXl) return { itemsPerRow: 3, spacing: 8 };

  return { itemsPerRow: 4, spacing: 10 };
};

export const PfmWorkspace: React.FC<PfmWorkspaceProps> = ({ utilization, sfmRankMap, disabled }) => {
  const theme = useTheme();
  const styles = useStyles();
  const dispatch = useDispatch();

  const [sfmIds, setSfmIds] = useState({ children: [], steps: [] });

  const allSfmUtilizationByParentId: SfmUtilization[] = useSelector(
    ToolUtilizationSelector.getAllByParentId(utilization.id),
  );
  const featureSet = useSelector(WorkspaceSelectors.getApplicableFeatureSet(utilization.project?.id));

  const isAnyToolSaving = useSelector(ToolUtilizationSelector.isAnySaving(utilization.project?.id));
  const isAnyToolCreating = useSelector(ToolUtilizationSelector.isAnyCreating(utilization.project?.id));
  const isAnyToolRemoving = useSelector(ToolUtilizationSelector.isAnyRemoving(utilization.project?.id));
  const isSfmResultLoaded = useSelector(SfmResultSelectors.isLoadedByUtilizationId(utilization.id));

  const isDownXlBreakpoint = useMediaQuery(theme.breakpoints.down('xl'));
  const isDownLgBreakpoint = useMediaQuery(theme.breakpoints.down('lg'));
  const isDownMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const pfmViewerGridProps = resolvePfmViewerGridProps(isDownXlBreakpoint, isDownLgBreakpoint, isDownMdBreakpoint);

  const { maxSfmProblematicRank, maxSfmFunctionalRank } = getMaxSfmRanks(sfmRankMap);

  const steps = utilization?.diagramData?.steps || [];
  const isLoading = isAnyToolSaving || isAnyToolCreating || isAnyToolRemoving;

  useEffect(() => {
    const childrenSfmIds = allSfmUtilizationByParentId.map(sfm => sfm.id);
    const stepsSfmIds = steps.map(step => step.sfmId);
    const isChildrenSfmIdsChanged = sfmIds.children.toString() !== childrenSfmIds.toString();
    const isStepsSfmIdsChanged = sfmIds.steps.toString() !== stepsSfmIds.toString();

    if (isChildrenSfmIdsChanged || isStepsSfmIdsChanged) {
      setSfmIds({ children: childrenSfmIds, steps: stepsSfmIds });
    }
  }, [allSfmUtilizationByParentId, steps]);

  useEffect(() => {
    let maxIndex = Math.max(...steps.map(step => step.index), 0);

    sfmIds.children.forEach(sfmId => {
      if (!sfmIds.steps.includes(sfmId)) {
        dispatch(
          PfmUtilizationActions.update(
            utilization.id,
            {
              diagramData: {
                steps: [...steps, { index: ++maxIndex, sfmId: sfmId }],
              },
            },
            utilization.project?.id,
          ),
        );
      }
    });

    sfmIds.steps.forEach(sfmId => {
      if (!sfmIds.children.includes(sfmId)) {
        dispatch(
          PfmUtilizationActions.update(
            utilization.id,
            {
              diagramData: deleteStepBySfmId(sfmId, steps),
            },
            utilization.project?.id,
          ),
        );
      }
    });
  }, [sfmIds]);

  const checkIsLimitReached = (): boolean => {
    return featureSet?.Tools?.PFM?.maxItems && steps.length >= featureSet?.Tools?.PFM?.maxItems;
  };

  const addStepHandler = () => {
    if (disabled || isLoading) return null;

    if (checkIsLimitReached()) {
      dispatch(PaywallActions.show(ToolType.PFM));
    } else {
      const command: ToolUtilizationCommand = {
        type: ToolType.SFM,
        parentId: utilization.id,
        locale: LocalesService.getCurrentLocale(),
      };

      dispatch(ToolUtilizationActions.createTool(command, utilization.project?.id));
    }
  };

  const renderAddButton = () => {
    const addButtonClassNames = [styles.addStepButton];

    if (disabled || isLoading) addButtonClassNames.push(styles.disabled);

    return (
      <Box className={styles.addStepButtonContainer}>
        <Box className={addButtonClassNames.join(' ')} onClick={addStepHandler} p={4}>
          <Grid container alignItems={'center'} justifyContent={'center'} spacing={2}>
            <Grid item>
              <div className={styles.plusIconContainer}>
                <PlusIcon fill={pgColorScheme.white} />
              </div>
            </Grid>

            <Grid item>
              <Typography variant={'body1'}>
                <Trans>Add step</Trans>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const renderSfmItem = (step: PfmStep, reversed: boolean, isFirstColumn: boolean, isLastColumn: boolean) => {
    return (
      <PfmItem
        sfmRank={sfmRankMap[step.sfmId]}
        maxSfmProblematicRank={maxSfmProblematicRank}
        maxSfmFunctionalRank={maxSfmFunctionalRank}
        projectId={utilization.project?.id}
        pfmUtilizationId={utilization.id}
        step={step}
        allSteps={steps}
        reversed={reversed}
        disabled={disabled}
        loading={isLoading}
        isFirst={isFirstColumn}
        isLast={isLastColumn}
        navigationDisabled={!!utilization.publicId}
      />
    );
  };

  if (!isSfmResultLoaded) {
    return <LoadingOverlay loading={true} backdropStyles={{ background: 'transparent' }} />;
  }

  return (
    <PfmViewer
      pfmUtilization={utilization}
      sfmRankMap={sfmRankMap}
      sfmRenderer={renderSfmItem}
      addStepButtonRenderer={renderAddButton}
      itemsPerRow={pfmViewerGridProps.itemsPerRow}
      spacing={pfmViewerGridProps.spacing}
    />
  );
};
