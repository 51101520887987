export enum ValueColorType {
  Green = 'Green',
  Blue = 'Blue',
  Red = 'Red',
}

export interface ColorTypeResolverProps {
  value: number;
  green: number;
  blue: number;
  reverse?: boolean;
}

export const resolveColorType = (props: ColorTypeResolverProps): ValueColorType => {
  const { value, green, blue, reverse } = props;

  if (value >= green) {
    return reverse ? ValueColorType.Red : ValueColorType.Green;
  }

  if (value >= blue) {
    return ValueColorType.Blue;
  }

  return reverse ? ValueColorType.Green : ValueColorType.Red;
};

export interface ColorTypeValueResolverProps<T> extends ColorTypeResolverProps {
  valuesMap: { [key in ValueColorType]: T };
}

export const resolveValueByColorType = <T>(props: ColorTypeValueResolverProps<T>): T => {
  const { valuesMap, ...rest } = props;

  return valuesMap[resolveColorType(rest)];
};
