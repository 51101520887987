import { FluxStandardAction } from 'flux-standard-action';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { ToolType } from '@priz/shared/src/models/tools';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { createPgAction } from '../../../shared/store/action-creator';
import { IFortyPrinciplesUtilization } from '@priz/shared/src/models/tools/forty-principles/forty-principles-utilization';
import {
  FortyPrinciplesUtilizationApi,
  FortyPrinciplesUtilizationCommand,
  NewFortyPrinciplesUtilizationCommand,
} from '../../services/forty-principles-utilization.service';
import { RouterActionMeta } from '../../../shared/store/types';
import { NavigateFunction } from 'react-router-dom';
import { ToolsUtils } from '../../../tools/utils';

export enum FortyPrinciplesUtilizationActionType {
  Create = '40PUtilization/create',
  Update = '40PUtilization/update',
  Delete = '40PUtilization/delete',
}

type FortyPrinciplesUtilizationPayload = IFortyPrinciplesUtilization;

interface FortyPrinciplesUtilizationMeta extends RouterActionMeta {
  projectId?: number;
  utilizationId?: number;
  navigate?: NavigateFunction;
}

export type FortyPrinciplesUtilizationAction = FluxStandardAction<
  string,
  FortyPrinciplesUtilizationPayload,
  FortyPrinciplesUtilizationMeta
>;

const create = createPgAction(
  FortyPrinciplesUtilizationActionType.Create,
  FortyPrinciplesUtilizationApi.create,
  (command: NewFortyPrinciplesUtilizationCommand, projectId?: number, navigate?: NavigateFunction) => ({
    projectId,
    command,
    navigate,
  }),
  (payload: FortyPrinciplesUtilizationPayload, { projectId, navigate }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_USED, {
      project_id: projectId,
      tool_type: ToolType.TOOL_40_PRINCIPLES,
    });

    if (navigate) {
      navigate(ToolsUtils.resolvePathByUtilization(payload));
    }
  },
);

const update = createPgAction(
  FortyPrinciplesUtilizationActionType.Update,
  FortyPrinciplesUtilizationApi.update,
  (utilizationId: number, command: FortyPrinciplesUtilizationCommand, projectId?: number) => ({
    projectId,
    utilizationId,
    command,
  }),
  (_payload, { projectId, utilizationId }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_UPDATED, {
      project_id: projectId,
      tool_type: ToolType.TOOL_40_PRINCIPLES,
      utilization_id: utilizationId,
    });
  },
);

const doDelete = createPgAction(
  FortyPrinciplesUtilizationActionType.Delete,
  FortyPrinciplesUtilizationApi.delete,
  (utilizationId: number, projectId?: number, navigate?: NavigateFunction) => ({ projectId, utilizationId, navigate }),
  (_payload: any, { projectId, utilizationId, navigate }) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_DELETED, {
      project_id: projectId,
      utilization_id: utilizationId,
      tool_type: ToolType.TOOL_40_PRINCIPLES,
    });

    if (navigate) {
      navigate(ToolsUtils.resolveListPath(projectId));
    }
  },
);

export const FortyPrinciplesUtilizationActions = {
  create,
  update,
  delete: doDelete,
};
