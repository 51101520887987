import { IConfig as UnleashConfig } from 'unleash-proxy-client';
import { AuthOptions } from 'auth0-js';
import { Auth0RedirectKey, AuthFormType } from '../models/auth0';

enum EnvironmentKey {
  Platform = 'Platform',
  Hub = 'Hub',
  Unknown = 'Unknown',
}

const unleashConfigDev: UnleashConfig = {
  appName: 'priz-guru-dev',
  url: 'https://unleash.priz.guru/api/frontend',
  clientKey: '*:development.78ffde613cd72c718c08c96873206a81597c68cd88c35db0496bcf70',
  refreshInterval: 15,
};

const unleashConfigProd: UnleashConfig = {
  appName: 'priz-guru-prod',
  url: 'https://unleash.priz.guru/api/frontend',
  clientKey: '*:production.6eb002f670ca36e52f07b9f75af04335310a3a8eaf86a8d8baf4e09e',
  refreshInterval: 15,
};

const origins = {
  hub: {
    dev: 'http://localhost:3000',
    prod: 'https://hub.priz.guru',
  },
  platform: {
    dev: 'https://localhost:4200',
    prod: 'https://app.priz.guru',
  },
};

type AuthRedirects = {
  [key in Auth0RedirectKey]: string;
};

const resolveAuthRedirects = (origin: string): AuthRedirects => {
  return {
    [Auth0RedirectKey.Login]: [origin, 'login-callback'].join('/'),
    [Auth0RedirectKey.Signup]: [origin, 'signup-callback'].join('/'),
    [Auth0RedirectKey.AcceptInvitation]: [origin, 'invitation-callback'].join('/'),
    [Auth0RedirectKey.ConnectAccounts]: [origin, 'connect-callback'].join('/'),
  };
};

type LogoutRedirects = { [key in AuthFormType]?: string } & { default: string };

const resolvePlatformLogoutRedirects = (origin: string): LogoutRedirects => {
  return {
    [AuthFormType.Login]: [origin, 'login'].join('/'),
    [AuthFormType.SignUp]: [origin, 'signup'].join('/'),
    default: [origin, 'login'].join('/'),
  };
};

const resolveHubLogoutRedirects = (origin: string): LogoutRedirects => {
  return {
    default: [origin, 'logout'].join('/'),
  };
};

interface EnvironmentConfig {
  environmentKey: EnvironmentKey;
  apiDomain: string;
  auth0: AuthOptions;
  authRedirects: AuthRedirects;
  logoutRedirects: LogoutRedirects;
  baseUrl: string;
  mixpanelToken: string;
  production: boolean;
  stripeApiKey: string;
  unleash?: UnleashConfig;
}

const environmentPlatformDev: EnvironmentConfig = {
  environmentKey: EnvironmentKey.Platform,
  apiDomain: 'http://localhost:8080',
  auth0: {
    audience: 'https://priz-dev.auth0.com/api/v2/',
    clientID: 'wCNz5hplNel4uCmRdI72k353hr4uZgGX',
    domain: 'priz-dev.auth0.com',
    responseType: 'token id_token',
    scope: 'openid email profile',
  },
  authRedirects: resolveAuthRedirects(origins.platform.dev),
  logoutRedirects: resolvePlatformLogoutRedirects(origins.platform.dev),
  baseUrl: 'https://localhost:4200',
  mixpanelToken: '4d062ee177f992ffff58ecf795d67836',
  production: false,
  stripeApiKey:
    'pk_test_51Mg1ufD80BbICR8LTGGEPNCS4HbfTfSmpmAC8gzIu3vYzpZ9GoYzGrVH6zF09cRyl4wv9igHPAYDUyHoQ7koYz8S00A0ju9R2P',
  unleash: unleashConfigDev,
};

const environmentPlatformProd: EnvironmentConfig = {
  environmentKey: EnvironmentKey.Platform,
  apiDomain: 'https://api.priz.guru',
  auth0: {
    audience: 'https://priz-guru.auth0.com/api/v2/',
    clientID: '0oha1AsM96kG8QC12YM6NsOr7enU1RB0',
    domain: 'auth.priz.guru',
    responseType: 'token id_token',
    scope: 'openid email profile',
  },
  authRedirects: resolveAuthRedirects(origins.platform.prod),
  logoutRedirects: resolvePlatformLogoutRedirects(origins.platform.prod),
  baseUrl: 'https://app.priz.guru',
  mixpanelToken: 'bc8ca7d286ece66deb11f69f77bffca5',
  production: true,
  stripeApiKey:
    'pk_live_51Mg1ufD80BbICR8LdHCW2487rSeipFP8C5ar9o4lTdCw5GNvC4oIzZ9zEwcxEKwcabNCwa7n1h04y0dG1DzUKc6l00WCuSVYVo',
  unleash: unleashConfigProd,
};

const environmentHubDev: EnvironmentConfig = {
  environmentKey: EnvironmentKey.Hub,
  apiDomain: 'http://localhost:8080',
  auth0: {
    audience: 'https://priz-dev.auth0.com/api/v2/',
    clientID: 'wCNz5hplNel4uCmRdI72k353hr4uZgGX',
    domain: 'priz-dev.auth0.com',
    responseType: 'token id_token',
    scope: 'openid email profile',
  },
  authRedirects: resolveAuthRedirects(origins.hub.dev),
  logoutRedirects: resolveHubLogoutRedirects(origins.hub.dev),
  baseUrl: 'http://localhost:3000',
  mixpanelToken: '4d062ee177f992ffff58ecf795d67836',
  production: false,
  stripeApiKey:
    'pk_test_51Mg1ufD80BbICR8LTGGEPNCS4HbfTfSmpmAC8gzIu3vYzpZ9GoYzGrVH6zF09cRyl4wv9igHPAYDUyHoQ7koYz8S00A0ju9R2P',
  unleash: unleashConfigDev,
};

const environmentHubProd: EnvironmentConfig = {
  environmentKey: EnvironmentKey.Hub,
  apiDomain: 'https://api.priz.guru',
  auth0: {
    audience: 'https://priz-guru.auth0.com/api/v2/',
    clientID: '0oha1AsM96kG8QC12YM6NsOr7enU1RB0',
    domain: 'auth.priz.guru',
    responseType: 'token id_token',
    scope: 'openid email profile',
  },
  authRedirects: resolveAuthRedirects(origins.hub.prod),
  logoutRedirects: resolveHubLogoutRedirects(origins.hub.prod),
  baseUrl: 'https://hub.priz.guru',
  mixpanelToken: 'bc8ca7d286ece66deb11f69f77bffca5',
  production: true,
  stripeApiKey:
    'pk_live_51Mg1ufD80BbICR8LdHCW2487rSeipFP8C5ar9o4lTdCw5GNvC4oIzZ9zEwcxEKwcabNCwa7n1h04y0dG1DzUKc6l00WCuSVYVo',
  unleash: unleashConfigProd,
};

const emptyEnvironmentConfig: EnvironmentConfig = {
  environmentKey: EnvironmentKey.Unknown,
  apiDomain: '',
  auth0: {
    audience: '',
    clientID: '',
    domain: '',
    redirectUri: '',
    responseType: '',
    scope: '',
  },
  authRedirects: resolveAuthRedirects(''),
  logoutRedirects: { default: '' },
  baseUrl: '',
  mixpanelToken: '',
  production: false,
  stripeApiKey: '',
};

const resolveConfig = (): EnvironmentConfig => {
  const configKey = process.env.REACT_APP_ENVIRONMENT_CONFIG || process.env.NEXT_PUBLIC_ENVIRONMENT_CONFIG;

  switch (configKey) {
    case 'platform_dev':
      return environmentPlatformDev;

    case 'platform_prod':
      return environmentPlatformProd;

    case 'hub_dev':
      return environmentHubDev;

    case 'hub_prod':
      return environmentHubProd;

    default:
      return emptyEnvironmentConfig;
  }
};

export const environment = resolveConfig();
