import { put, select, takeLatest } from 'redux-saga/effects';
import { toSuccess } from '../../../shared/store/action-creator';
import { WorkspaceActions, WorkspaceActionType } from '../actions';
import { WorkspaceSelectors } from '../selectors';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { DialogsActions } from '../../../dialogs/store/actions';
import { DialogType } from '../../../dialogs/store/model';

function* onWorkspaceDelete(action) {
  const initialWorkspace = yield select(WorkspaceSelectors.getInitialWorkspace([action.meta.id]));

  AnalyticsService.track(AnalyticsEvent.WORKSPACE_DELETED, {
    workspace_id: action.meta.id,
  }).then(() => {
    if (initialWorkspace) {
      location.replace(`/workspace/${initialWorkspace.id}/switch`);
    } else {
      location.replace(`/workspace/new`);
    }
  });
}

function* onPlanChange() {
  // @ts-ignore
  yield put(WorkspaceActions.fetchFeatureSet());
}

function* onSeatsUpdated() {
  // @ts-ignore
  yield put(DialogsActions.close(DialogType.ManageSeats));
}

export function* workspaceSagas() {
  yield takeLatest(toSuccess(WorkspaceActionType.Delete), onWorkspaceDelete);
  yield takeLatest(toSuccess(WorkspaceActionType.UpdatePlan), onPlanChange);
  yield takeLatest(toSuccess(WorkspaceActionType.SetLicensedSeats), onSeatsUpdated);
}
