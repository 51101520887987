import React from 'react';
import { Meeting } from '../store/model';
import { MeetingStatus } from '@priz/shared/src/models/meetings';
import { Chip, ChipProps } from '@mui/material';
import { Trans } from 'react-i18next';

export interface MeetingStatusIndicatorProps {
  meeting: Meeting;
}

const statusPropsMap: { [key in MeetingStatus]: { label: string; chipProps: Partial<ChipProps> } } = {
  [MeetingStatus.Draft]: {
    label: 'Draft',
    chipProps: {
      color: 'default',
      variant: 'outlined',
    },
  },
  [MeetingStatus.Released]: {
    label: 'Published',
    chipProps: {
      color: 'primary',
    },
  },
  [MeetingStatus.Active]: {
    label: 'Active',
    chipProps: {
      color: 'success',
    },
  },
  [MeetingStatus.Finished]: {
    label: 'Finished',
    chipProps: {
      color: 'default',
    },
  },
};

export const MeetingStatusIndicator: React.FC<MeetingStatusIndicatorProps> = ({ meeting }) => {
  const { label = '', chipProps = {} } = statusPropsMap[meeting.status] || {};

  return <Chip label={<Trans>{label}</Trans>} size={'small'} {...chipProps} />;
};
