import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PrizLoadingOverlay } from '@priz/shared/src/components/priz-loading-overlay/component';
import { UserActions } from '../../user/store/actions/user.actions';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const VerifyEmail: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (token) {
      dispatch(UserActions.verifyEmail(token, navigate));
    }
  }, [token]);

  return <PrizLoadingOverlay />;
};
