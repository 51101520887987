import React from 'react';
import { PartnerProfile } from '@priz/shared/src/models/security/partner-profile';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Trans } from 'react-i18next';
import { pgColorScheme } from '@priz/shared/src/theme';
import { AdminPartnerProfileTableRow } from '../admin-partner-profile-table-row/component';

interface AdminPartnerProfileTableProps {
  profiles: PartnerProfile[];
}

export const AdminPartnerProfileTable: React.FC<AdminPartnerProfileTableProps> = ({ profiles }) => {
  return (
    <TableContainer component={Paper} variant={'outlined'}>
      <Table>
        <TableHead sx={{ backgroundColor: pgColorScheme.background }}>
          <TableRow>
            {['User id', 'Name', 'Email', 'Referral code', 'Total profit', 'Pending payout'].map((item, key) => (
              <TableCell key={key}>
                <Trans>{item}</Trans>
              </TableCell>
            ))}

            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {profiles.map(profile => (
            <AdminPartnerProfileTableRow key={profile.id} profile={profile} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
