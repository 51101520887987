import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { PMapContributedPerceptionStatus } from '@priz/shared/src/models/tools/pmap/pmap-contributed-perception';
import { Trans } from 'react-i18next';

interface ContributedPerceptionStatusProps {
  status: PMapContributedPerceptionStatus;
}

const statusPropsMap: { [key in PMapContributedPerceptionStatus]: Partial<ChipProps> } = {
  [PMapContributedPerceptionStatus.Pending]: {
    color: 'default',
    label: <Trans>Pending</Trans>,
  },
  [PMapContributedPerceptionStatus.Accepted]: {
    color: 'success',
    label: <Trans>Accepted</Trans>,
  },
  [PMapContributedPerceptionStatus.Rejected]: {
    color: 'error',
    label: <Trans>Rejected</Trans>,
  },
};

export const ContributedPerceptionStatus: React.FC<ContributedPerceptionStatusProps> = ({ status }) => {
  return <Chip size={'small'} {...(statusPropsMap[status] || {})} />;
};
