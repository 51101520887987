import axios, { AxiosResponse } from 'axios';
import {
  AssistantHint,
  CecCausesResponse,
  CompletenessFeedback,
  DisadvantagesResponse,
  FiveWhysCausesResponse,
  FiveWhysSolutionsResponse,
  GapsResponse,
  IfrsResponse,
  IndustriesSuggestionResponse,
  ProblemStatementsResponse,
  ToolSuggestionsResponse,
} from '../store/model';
import { IdeaParameterResponse } from '../store/model/idea-parameter-response';
import { IdeaParameterType } from '@priz/shared/src/models/idea';

let abortController: AbortController;

const abortRequestAndResetController = () => {
  abortController?.abort();
  abortController = new AbortController();
};

// project

const getCurrentSituationHint = (projectId: number): Promise<AssistantHint> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/project/${projectId}/assist/cirrent-situation`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<AssistantHint>) => response.data);
};

const getDescriptionFeedback = (projectId: number): Promise<CompletenessFeedback> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/project/${projectId}/description/feedback`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<CompletenessFeedback>) => response.data);
};

const getCurrentSituationFeedback = (projectId: number): Promise<CompletenessFeedback> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/project/${projectId}/current-situation/feedback`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<CompletenessFeedback>) => response.data);
};

const getDisadvantagesFeedback = (projectId: number): Promise<CompletenessFeedback> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/project/${projectId}/disadvantages/feedback`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<CompletenessFeedback>) => response.data);
};

const getProblemStatementFeedback = (projectId: number): Promise<CompletenessFeedback> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/project/${projectId}/problem-statement/feedback`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<CompletenessFeedback>) => response.data);
};

const getIdealFinalResultHint = (projectId: number): Promise<IfrsResponse> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/project/${projectId}/assist/ifrs`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<IfrsResponse>) => response.data);
};

const getIdealFinalResultFeedback = (projectId: number): Promise<CompletenessFeedback> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/project/${projectId}/ideal-final-result/feedback`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<CompletenessFeedback>) => response.data);
};

const getGapsHint = (projectId: number): Promise<GapsResponse> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/project/${projectId}/assist/gaps`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<GapsResponse>) => response.data);
};

const getGapsFeedback = (projectId: number): Promise<CompletenessFeedback> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/project/${projectId}/gaps/feedback`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<CompletenessFeedback>) => response.data);
};

const getToolSuggestionsHint = (projectId: number): Promise<ToolSuggestionsResponse> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/project/${projectId}/assist/tool-suggestions`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<ToolSuggestionsResponse>) => response.data);
};

const getDisadvantagesHint = (projectId: number): Promise<DisadvantagesResponse> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/project/${projectId}/assist/disadvantages`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<DisadvantagesResponse>) => response.data);
};

const getProblemStatementHint = (projectId: number): Promise<ProblemStatementsResponse> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/project/${projectId}/assist/problem-statement`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<ProblemStatementsResponse>) => response.data);
};

const getAssistantIdeaParameterHint = (
  projectId: number,
  ideaId: number,
  type: IdeaParameterType,
): Promise<IdeaParameterResponse> => {
  abortRequestAndResetController();

  return axios
    .post(
      `/v1/api/<ws-id>/project/${projectId}/idea/${ideaId}/assist/parameter`,
      { type },
      {
        signal: abortController.signal,
      },
    )
    .then((response: AxiosResponse<IdeaParameterResponse>) => response.data);
};

// Project wizard

export interface ProjectWizardCommand {
  industry: string;
}

const getIndustriesSuggestion = (command: ProjectWizardCommand): Promise<IndustriesSuggestionResponse> => {
  abortRequestAndResetController();

  return axios
    .post(`/v1/api/<ws-id>/industries-suggestion`, command, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<IndustriesSuggestionResponse>) => response.data);
};

// tools

const getFiveWhysCauseHint = (utilizationId: number, causeId: number): Promise<FiveWhysCausesResponse> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/team/<t-id>/5-whys/${utilizationId}/cause/${causeId}/assist/cause`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<FiveWhysCausesResponse>) => response.data);
};

const getFiveWhysCauseSolutionHint = (utilizationId: number, causeId: number): Promise<FiveWhysSolutionsResponse> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/team/<t-id>/5-whys/${utilizationId}/cause/${causeId}/assist/solution`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<FiveWhysSolutionsResponse>) => response.data);
};

const getCecCauseHint = (utilizationId: number, nodeId: string): Promise<CecCausesResponse> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/team/<t-id>/cec/${utilizationId}/node/${nodeId}/assist/cause`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<CecCausesResponse>) => response.data);
};

const getApaCustomerDescriptionHint = (utilizationId: number): Promise<AssistantHint> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/team/<t-id>/apa/${utilizationId}/assist/customer-description`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<AssistantHint>) => response.data);
};

const getApaCustomerActionsHint = (utilizationId: number): Promise<AssistantHint> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/team/<t-id>/apa/${utilizationId}/assist/customer-actions`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<AssistantHint>) => response.data);
};

const getApaActionPurposeHint = (utilizationId: number, apaActionId: number): Promise<AssistantHint> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/team/<t-id>/apa/${utilizationId}/action/${apaActionId}/assist/action-purpose`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<AssistantHint>) => response.data);
};

const getApaPreventingActionHint = (utilizationId: number, apaActionId: number): Promise<AssistantHint> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/team/<t-id>/apa/${utilizationId}/action/${apaActionId}/assist/preventing-action`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<AssistantHint>) => response.data);
};

const getApaCustomerNeedsHint = (utilizationId: number, apaActionId: number): Promise<AssistantHint> => {
  abortRequestAndResetController();

  return axios
    .get(`/v1/api/<ws-id>/team/<t-id>/apa/${utilizationId}/action/${apaActionId}/assist/customer-needs`, {
      signal: abortController.signal,
    })
    .then((response: AxiosResponse<AssistantHint>) => response.data);
};

export const AssistantApi = {
  // project
  getDisadvantagesHint,
  getCurrentSituationHint,
  getProblemStatementHint,
  getDescriptionFeedback,
  getCurrentSituationFeedback,
  getDisadvantagesFeedback,
  getIdealFinalResultHint,
  getIdealFinalResultFeedback,
  getGapsHint,
  getGapsFeedback,
  getProblemStatementFeedback,
  getToolSuggestionsHint,
  getAssistantIdeaParameterHint,

  // project wizard
  getIndustriesSuggestion,

  // tools
  getFiveWhysCauseHint,
  getFiveWhysCauseSolutionHint,
  getCecCauseHint,
  getApaCustomerDescriptionHint,
  getApaCustomerActionsHint,
  getApaActionPurposeHint,
  getApaPreventingActionHint,
  getApaCustomerNeedsHint,
};
