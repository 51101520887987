import React, { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import { Grid } from '@mui/material';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { Trans, useTranslation } from 'react-i18next';
import { ToolType } from '@priz/shared/src/models/tools';
import { FiveWhysCauseActions, FiveWhysSolutionActions } from '../store/actions';
import { ProjectSelector, ToolUtilizationSelector } from '../../project/store/selectors';
import { FiveWhysCausesSelector } from '../store/selectors/five-whys-causes.selectors';
import { FiveWhysSolutionSelectors } from '../store/selectors/five-whys-solution.selectors';
import { ToolTitle } from '../../tools/tool-title/component';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm/rrm-goal-type.enum';
import { FeatureSetSelectors } from '../../workspace/store/selectors/feature-set.selectors';
import { PaywallActions } from '../../react/modules/paywall/store';
import { FiveWhysDiagram, FiveWhysDiagramNodeRendererProps } from '@priz/shared/src/components/five-whys';
import { EditableFiveWhysNode, EditableFiveWhysNodeType } from '../editable-five-whys-node/component';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { StartRankingButton } from '../../rrm/start-ranking-button/component';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { ToolUtilizationActions, ToolUtilizationActionType } from '../../project/store/actions/tools.actions';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { UpdateFiveWhysCauseCommand } from '../service';

interface FiveWhysProps {
  utilizationId: number;
  projectId?: number;
}

export const FiveWhys: React.FC<FiveWhysProps> = ({ projectId, utilizationId }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const utilization = useSelector(ToolUtilizationSelector.getFiveWhysUtilization(utilizationId));
  const causes = useSelector(FiveWhysCausesSelector.getAllByUtilizationId(utilizationId));
  const causesAreLoaded = useSelector(FiveWhysCausesSelector.isLoadedByUtilizationId(utilizationId));
  const solutions = useSelector(FiveWhysSolutionSelectors.getAllByUtilizationId(utilizationId));

  const solutionsAreLoaded = useSelector(FiveWhysSolutionSelectors.isLoadedByUtilizationId(utilizationId));
  const isFiveWhysLimitHit = useSelector(FeatureSetSelectors.isFiveWhysLimitHit(utilizationId, projectId));
  const isProjectLocked = useSelector(ProjectSelector.isProjectLockedById(projectId));
  const isCompletenessUpdating = useSelector(
    ToolUtilizationSelector.isPendingByActionType(utilizationId, ToolUtilizationActionType.UpdateCompleteness),
  );

  const isDisabled = !!utilization?.completed || isCompletenessUpdating || isProjectLocked;

  useEffect(() => {
    dispatch(ToolUtilizationActions.loadTool(utilizationId, projectId));
    dispatch(FiveWhysCauseActions.loadCauses(utilizationId));
    dispatch(FiveWhysSolutionActions.loadSolutions(utilizationId));
  }, [utilizationId, projectId]);

  const setDeadEnd = useCallback(
    (causeId: number) => {
      if (causes[causes.length - 1].id !== causeId) {
        removeCause(causes[causes.length - 1].id);
      }

      const command: UpdateFiveWhysCauseCommand = {
        deadEnd: true,
      };

      dispatch(FiveWhysCauseActions.updateCauseDeadEnd(utilizationId, causeId, command, projectId));
    },
    [projectId, utilizationId, causes],
  );

  const createNewCause = (causeId: number, description?: string) => {
    if (isFiveWhysLimitHit) {
      dispatch(PaywallActions.show(ToolType.FIVE_WHYS));
    } else {
      dispatch(FiveWhysCauseActions.createCause(utilizationId, description, causeId, projectId));
    }
  };

  const removeCause = useCallback(
    (causeId: number) => {
      dispatch(FiveWhysCauseActions.deleteCause(utilizationId, causeId, projectId));
    },
    [utilizationId],
  );

  const updateCauseDescription = (causeId: number, description: string) => {
    const command: UpdateFiveWhysCauseCommand = {
      description,
    };

    dispatch(FiveWhysCauseActions.updateCauseDescription(utilizationId, causeId, command, projectId));
  };

  const updateDescription = useCallback(
    debounce((id, text) => {
      updateCauseDescription(id, text);
    }, 500),
    [causes, utilizationId],
  );

  const updateCauseSolution = (causeId: number, description: string) => {
    const solution = solutions.find(c => c.cause.id === causeId && c.id);

    if (solution) {
      dispatch(FiveWhysSolutionActions.updateSolutionDescription(utilizationId, solution.id, description, projectId));
    } else {
      dispatch(FiveWhysSolutionActions.createSolution(utilizationId, causeId, description, projectId));
    }
  };

  const updateSolution = useCallback(
    debounce((id, text) => {
      updateCauseSolution(id, text);
    }, 500),
    [solutions, utilizationId],
  );

  const causeNodeRenderer = (props: FiveWhysDiagramNodeRendererProps) => {
    return (
      <EditableFiveWhysNode
        projectId={projectId}
        causes={causes}
        changeTextCallback={updateDescription}
        onAddCause={createNewCause}
        onRemove={removeCause}
        onFrp={setDeadEnd}
        onUseHint={updateCauseDescription}
        placeholder={t('Describe what is happening')}
        type={EditableFiveWhysNodeType.Cause}
        {...props}
      />
    );
  };

  const solutionNodeRenderer = (props: FiveWhysDiagramNodeRendererProps) => {
    return (
      <EditableFiveWhysNode
        projectId={projectId}
        causes={causes}
        changeTextCallback={updateSolution}
        placeholder={t('If root cause is here')}
        onUseHint={updateCauseSolution}
        type={EditableFiveWhysNodeType.Solution}
        {...props}
      />
    );
  };

  if (!utilization || !causesAreLoaded || !solutionsAreLoaded) {
    return <LoadingOverlay loading={true} backdropStyles={{ backgroundColor: 'transparent' }} />;
  }

  return (
    <>
      <ContentContainer display={'flex'} alignItems={'stretch'}>
        <Grid container direction={'column'} wrap={'nowrap'}>
          <Grid item xs={'auto'}>
            <PageTitleWithDocLink
              titleComponent={
                <ToolTitle
                  initialTitle={'5 WHYS Analysis'}
                  tool={utilization}
                  preset={'large'}
                  editable={!isDisabled}
                />
              }
              oneLiner={<Trans>Find the root cause of a failure</Trans>}
              docsUrl={'https://www.priz.guru/knowledge-base/five-whys/'}
            />
          </Grid>
          <Grid item xs={true} className={styles.flexSizerContainer}>
            {causesAreLoaded && solutionsAreLoaded && (
              <FiveWhysDiagram
                causes={causes}
                solutions={solutions}
                causeNodeRenderer={causeNodeRenderer}
                solutionNodeRenderer={solutionNodeRenderer}
                viewMode={isDisabled}
              />
            )}
          </Grid>
        </Grid>
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar
          projectId={projectId}
          utilization={utilization}
          disabled={isDisabled}
          rightColContent={
            solutions?.length > 2 && (
              <StartRankingButton
                projectId={projectId}
                utilizationId={utilizationId}
                goalType={RrmGoalType.FIVE_WHYS_SOLUTION}
                itemsCount={solutions.length}
                buttonText={'Rank solutions'}
                buttonProps={{
                  disabled: isDisabled,
                }}
              />
            )
          }
          workingFlowProps={{ nextVariant: 'ideas' }}
        />
      </ContentFooter>
    </>
  );
};
