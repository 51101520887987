import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import {
  bindNetworkEvents,
  getNodeDomPosition,
  unbindNetworkEvents,
  VisNetworkEvents,
} from '@priz/shared/src/components/network-diagram/utils';
import { CecEdgeProps, CecNodeDefaultPlaceholder, CecNodeProps } from '@priz/shared/src/models/tools/cec';
import { useTranslation } from 'react-i18next';
import { Assistant } from '../../assistant/component';
import { AssistanceType, AssistantVariant } from '../../assistant/store/model';
import { HintRowType } from '../../assistant/hint-content/component';
import { IdType, Network as NetworkType } from '@priz/shared/src/lib/vis/esnext';
import { fitTextarea, moveViewToNodeIfItOutOfCanvas } from '../cause-and-effect-chain-diagram/utils';
import { DataSet } from 'vis-data';
import { DataSetEventType } from '@priz/shared/src/models/vis-network';

interface CecNodeWidgetProps {
  nodeId: IdType;
  projectId: number;
  utilizationId: number;
  network: NetworkType;
  nodesDataSet: DataSet<CecNodeProps, 'id'>;
  edgesDataSet: DataSet<CecEdgeProps, 'id'>;
  canvasContainer: HTMLDivElement;
  onLabelChange: (nodeId: IdType, text: string) => void;
  onEditStateChange?: (isEditing: boolean) => void;
  placeholder?: string;
}

interface PositionProps {
  top?: number;
  left?: number;
  width?: number;
  height?: number;
  transform?: string;
}

const resolveEffect = (nodes: CecNodeProps[], edges: CecEdgeProps[], nodeId: IdType): IdType | undefined => {
  const effectsIds: IdType[] = edges.filter(edge => edge?.to === nodeId).map(edge => edge?.from);
  const firstEffectWithText = nodes.find(node => effectsIds.includes(node?.id) && node?.label);

  return firstEffectWithText?.id;
};

export const CecNodeWidget: React.FC<CecNodeWidgetProps> = ({
  nodeId,
  projectId,
  utilizationId,
  network,
  nodesDataSet,
  edgesDataSet,
  canvasContainer,
  onLabelChange,
  onEditStateChange,
  placeholder = CecNodeDefaultPlaceholder,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const [text, setText] = useState<string>('');
  const [position, setPosition] = useState<PositionProps>({});
  const [effectNodeId, setEffectNodeId] = useState<IdType>();

  const containerRef = useRef<HTMLDivElement>();
  const textareaRef = useRef<HTMLTextAreaElement>();

  const selectedWorkspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);
  const isAiAssistantEnabled = useSelector(WorkspaceSelectors.isAiAssistantEnabled(selectedWorkspace?.id));

  useEffect(() => {
    const networkEvents: Partial<VisNetworkEvents> = {
      afterDrawing: () => {
        if (nodeId) {
          moveWidget();
        }
      },
    };

    if (network) {
      bindNetworkEvents(network, networkEvents);
    }

    return () => {
      if (network) {
        unbindNetworkEvents(network, networkEvents);
      }
    };
  }, [network, nodeId]);

  useEffect(() => {
    if (nodeId) {
      const node = nodesDataSet.get(nodeId);

      if (node) {
        const nodes = nodesDataSet.get();
        const edges = edgesDataSet.get();
        const editingNodeEffectId = resolveEffect(nodes, edges, nodeId);

        setEffectNodeId(editingNodeEffectId);
      } else {
        setEffectNodeId(null);
      }
    } else {
      setEffectNodeId(null);
    }
  }, [nodeId]);

  useEffect(() => {
    if (nodeId) {
      const selectedNode = nodesDataSet.get(nodeId);
      const { label } = selectedNode || {};

      setText(label || '');
    } else {
      setText('');
    }
  }, [nodeId]);

  useEffect(() => {
    const dataSetChangeCallback = (e: DataSetEventType) => {
      if (e === 'update' && nodeId) {
        const node = nodesDataSet.get(nodeId);

        if (node) {
          setText(node.label);
        }
      }
    };

    if (network) {
      nodesDataSet.on('*', dataSetChangeCallback);
    }

    return () => {
      if (network) {
        nodesDataSet.off('*', dataSetChangeCallback);
      }
    };
  }, [nodeId]);

  useEffect(() => {
    if (nodeId && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [nodeId]);

  const moveWidget = () => {
    const nodeDomPosition = getNodeDomPosition(network, nodeId);

    if (nodeDomPosition) {
      setPosition({
        top: nodeDomPosition.top,
        left: nodeDomPosition.left,
        width: nodeDomPosition.width,
        height: nodeDomPosition.height,
        transform: `scale(${nodeDomPosition.scale})`,
      });

      if (textareaRef.current) {
        fitTextarea(textareaRef.current);
      }
    }
  };

  const textAreaInputHandler = (e: React.FormEvent<HTMLTextAreaElement>) => {
    moveViewToNodeIfItOutOfCanvas(network, canvasContainer, nodeId, 0);
    setText(e.currentTarget.value);
    onLabelChange(nodeId, e.currentTarget.value);
  };

  const useHintHandler = (text: string) => {
    setText(text);
    onLabelChange(nodeId, text);
  };

  const textareaFocusHandler = () => {
    moveViewToNodeIfItOutOfCanvas(network, canvasContainer, nodeId, 0);

    if (onEditStateChange) {
      onEditStateChange(true);
    }
  };

  const textareaBlurHandler = () => {
    if (onEditStateChange) {
      onEditStateChange(false);
    }
  };

  if (!nodeId) return null;

  return (
    <div className={styles.root} ref={containerRef} style={position}>
      {effectNodeId && (
        <div className={styles.controls}>
          {isAiAssistantEnabled && (
            <Assistant
              type={AssistanceType.AskCecCause}
              variant={AssistantVariant.Popper}
              projectId={projectId}
              actionProps={{ utilizationId, visNodeId: effectNodeId }}
              buttonProps={{
                variant: 'icon',
              }}
              onUseHint={text => {
                useHintHandler(text[0]);
              }}
              contentProps={{
                hintRowsType: HintRowType.Radio,
              }}
            />
          )}
        </div>
      )}

      <label className={styles.textAreaContainer}>
        <textarea
          placeholder={t(placeholder)}
          className={styles.textArea}
          ref={textareaRef}
          onInput={textAreaInputHandler}
          onFocus={textareaFocusHandler}
          onBlur={textareaBlurHandler}
          value={text}
        />
      </label>
    </div>
  );
};
