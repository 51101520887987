import { CftNodeData, CftNodeType } from '@priz/shared/src/models/tools/cft';
import { v1 as uuidv1 } from 'uuid';
import { copyObject } from '@priz/shared/src/utils/common';
import { CftDataUtils } from '@priz/shared/src/utils/cft';

const createNode = (props?: Partial<CftNodeData>): CftNodeData => {
  return {
    id: uuidv1(),
    type: CftNodeType.Step,
    to: [],
    ...(props || {}),
  };
};

const addNode = (nodes: CftNodeData[], fromId: string, type: CftNodeType): CftNodeData[] => {
  const nodesCopy = copyObject(nodes);
  const nodesMap = CftDataUtils.resolveNodesMap(nodesCopy);
  const parentNode = nodesMap[fromId];

  if (!parentNode) {
    return nodesCopy;
  }

  const newNodes: CftNodeData[] = [];

  if ([CftNodeType.Abstraction, CftNodeType.Statement].includes(type)) {
    const step = createNode({ type });
    parentNode.to.push(step.id);
    newNodes.push(step);
  }

  if (type === CftNodeType.Step) {
    const step = createNode({ type });

    const parentToIdsMove = [];
    const parentToIdsKeep = [];

    parentNode.to.forEach(id => {
      if (nodesMap[id].type === CftNodeType.Abstraction) {
        parentToIdsKeep.push(id);
      } else {
        parentToIdsMove.push(id);
      }
    });

    step.to = parentToIdsMove;
    parentNode.to = [...parentToIdsKeep, step.id];
    newNodes.push(step);
  }

  if (type === CftNodeType.Variant) {
    const currentVariantsCount = parentNode.to.reduce((count: number, nodeId: string) => {
      return nodesMap[nodeId]?.type === CftNodeType.Variant ? ++count : count;
    }, 0);

    const variantsCountToCreate = currentVariantsCount ? 1 : 2;

    for (let i = 0; i < variantsCountToCreate; i++) {
      const step = createNode({ type: CftNodeType.Step });
      const variant = createNode({ to: [step.id], type: CftNodeType.Variant });

      parentNode.to.push(variant.id);
      newNodes.push(variant);
      newNodes.push(step);
    }
  }

  if (type === CftNodeType.Process) {
    const innerNodes = CftDataUtils.resolveTree(nodesMap, fromId);
    const firstInnerNode = innerNodes?.[0]?.nodes?.[0];

    if (firstInnerNode?.type === CftNodeType.Step) {
      const process = createNode({ to: [firstInnerNode.id], type: CftNodeType.Process });

      parentNode.to = parentNode.to.filter(id => id !== firstInnerNode.id);
      parentNode.to.push(process.id);
      newNodes.push(process);
    }

    const step = createNode();
    const process = createNode({ to: [step.id], type: CftNodeType.Process });

    parentNode.to.push(process.id);
    newNodes.push(process);
    newNodes.push(step);
  }

  return [...nodesCopy, ...newNodes];
};

const updateNode = (nodes: CftNodeData[], nodeId: string, data: Partial<CftNodeData>): CftNodeData[] => {
  const nodesCopy = copyObject(nodes);
  const nodesMap = CftDataUtils.resolveNodesMap(nodesCopy);

  if (nodesMap[nodeId]) {
    nodesMap[nodeId] = {
      ...nodesMap[nodeId],
      ...data,
    };
  }

  return Object.values(nodesMap);
};

const removeNode = (nodes: CftNodeData[], nodeId: string): CftNodeData[] => {
  const nodesCopy = copyObject(nodes);
  const nodesMap = CftDataUtils.resolveNodesMap(nodesCopy);
  const relations = CftDataUtils.resolveNodesRelations(nodesCopy);
  const nodeToDelete = nodesMap[nodeId];
  const fromId = relations.parentNode[nodeToDelete.id];
  const fromNode = nodesMap[fromId];

  if (nodeToDelete.type === CftNodeType.Step) {
    fromNode.to = [
      ...fromNode.to.filter(id => id !== nodeToDelete.id),
      ...nodeToDelete.to.filter(id => nodesMap[id]?.type !== CftNodeType.Abstraction),
    ];
  }

  if (nodeToDelete.type === CftNodeType.Process && fromNode.to.length <= 2) {
    const lastProcessId = fromNode.to.find(toId => toId !== nodeToDelete.id);
    const lastProcessTo = lastProcessId && nodesMap[lastProcessId]?.to;

    if (lastProcessTo) {
      fromNode.to = lastProcessTo;
    }
  }

  fromNode.to = fromNode.to.filter(id => id !== nodeToDelete.id);

  delete nodesMap[nodeId];

  let deleteQueue: string[] = CftDataUtils.getUnchainedNodesIds(Object.values(nodesMap));

  while (deleteQueue.length) {
    deleteQueue.forEach(idToDelete => {
      const fromId = relations.parentNode[idToDelete];

      if (nodesMap[fromId]) {
        nodesMap[fromId].to = nodesMap[fromId].to.filter(id => id !== idToDelete);
      }

      delete nodesMap[idToDelete];
    });

    deleteQueue = CftDataUtils.getUnchainedNodesIds(Object.values(nodesMap));
  }

  return [...Object.values(nodesMap)];
};

export const CftManagementUtils = {
  addNode,
  updateNode,
  removeNode,
};
