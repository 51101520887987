import { DomainPrinciple } from '@priz/shared/src/models/tools/forty-principles/domain-principle';
import { DomainPrincipleAction, DomainPrincipleActionType, DomainPrinciplesAction } from '../actions';
import { DefaultDomainPrincipleList, DomainPrincipleList } from '../model';

import { handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { CollectionStatusesService } from '@priz/shared/src/services/statuses/collection-statuses.service';

export const domainPrincipleReducers = handleActions(
  {
    // Fetch

    [toStarted(DomainPrincipleActionType.Fetch)]: (state: DomainPrincipleList): DomainPrincipleList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        loading: true,
        loaded: false,
      }),
    }),
    [toFailed(DomainPrincipleActionType.Fetch)]: (state: DomainPrincipleList): DomainPrincipleList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        loading: false,
        loaded: false,
        error: true,
      }),
    }),
    [toSuccess(DomainPrincipleActionType.Fetch)]: (
      state: DomainPrincipleList,
      action: DomainPrinciplesAction,
    ): DomainPrincipleList => {
      const domainPrinciples = action.payload.map(domainPrincipleData => new DomainPrinciple(domainPrincipleData));

      return {
        ...state,
        domainPrinciples,
        statuses: CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses,
          loading: false,
          loaded: true,
          error: false,
        }),
      };
    },

    // Update

    [toStarted(DomainPrincipleActionType.Update)]: (
      state: DomainPrincipleList,
      action: DomainPrincipleAction,
    ): DomainPrincipleList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        updating: true,
        updated: false,
        idsUpdate: [{ target: 'updatingIds', method: 'add', ids: [action.meta.domainPrincipleId] }],
      }),
    }),
    [toFailed(DomainPrincipleActionType.Update)]: (
      state: DomainPrincipleList,
      action: DomainPrincipleAction,
    ): DomainPrincipleList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        updating: false,
        updated: false,
        error: true,
        idsUpdate: [{ target: 'updatingIds', method: 'remove', ids: [action.meta.domainPrincipleId] }],
      }),
    }),
    [toSuccess(DomainPrincipleActionType.Update)]: (
      state: DomainPrincipleList,
      action: DomainPrincipleAction,
    ): DomainPrincipleList => {
      const updatedDomainPrinciples = state.domainPrinciples.map(item =>
        item.id === action.payload.id ? action.payload : item,
      );

      return {
        ...state,
        domainPrinciples: updatedDomainPrinciples,
        statuses: CollectionStatusesService.updateStatusesCollection({
          collection: state.statuses,
          updating: false,
          updated: true,
          error: false,
          idsUpdate: [{ target: 'updatingIds', method: 'remove', ids: [action.meta.domainPrincipleId] }],
        }),
      };
    },

    // SetState

    [toStarted(DomainPrincipleActionType.SetState)]: (state: DomainPrincipleList): DomainPrincipleList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        loading: true,
        loaded: false,
      }),
    }),
    [toFailed(DomainPrincipleActionType.SetState)]: (state: DomainPrincipleList): DomainPrincipleList => ({
      ...state,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        loading: false,
        loaded: false,
        error: true,
      }),
    }),
    [toSuccess(DomainPrincipleActionType.SetState)]: (
      state: DomainPrincipleList,
      action: DomainPrinciplesAction,
    ): DomainPrincipleList => ({
      ...state,
      domainPrinciples: action.payload,
      statuses: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses,
        loading: false,
        loaded: true,
        error: false,
      }),
    }),
  },
  DefaultDomainPrincipleList,
);
