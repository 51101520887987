import React from 'react';
import { ReportDocument } from './document/component';
import { Preloader } from '../../react/preloader/component';
import { ReportHeader } from './header/component';
import { useStyles } from './styles';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';

export interface ProjectReportProps {
  projectId: number;
}

export const ProjectReport: React.FC<ProjectReportProps> = ({ projectId }) => {
  useStyles();

  return (
    <ContentContainer>
      <Preloader text={'Preparing document'} />
      <ReportHeader />
      <ReportDocument projectId={projectId} />
    </ContentContainer>
  );
};
