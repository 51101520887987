import { AlertColor } from '@mui/material/Alert/Alert';

export interface NotificationState {
  show: boolean;
  message?: string;
  severity?: AlertColor;
  duration?: number;
}

export const DefaultNotificationState: NotificationState = {
  show: false,
};
