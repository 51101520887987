import React from 'react';
import { Grid } from '@mui/material';
import { ComponentRank, OptionalComponentRank } from '@priz/shared/src/models/tools/sfm';
import { ArrowForwardRounded } from '@mui/icons-material';
import { pgColorScheme } from '@priz/shared/src/theme';
import { SfmRankBars } from '@priz/shared/src/components/sfm-rank-bars/component';

interface RankChangeResultProps {
  oldRank: ComponentRank | OptionalComponentRank;
  newRank: ComponentRank | OptionalComponentRank;
}

export const RankChangeResult: React.FC<RankChangeResultProps> = ({ oldRank, newRank }) => {
  return (
    <Grid container spacing={1} alignItems={'center'}>
      <Grid item xs={true}>
        <SfmRankBars componentRank={oldRank} spacing={1} backdrop stretchNoImpact />
      </Grid>

      <Grid item xs={'auto'}>
        <ArrowForwardRounded fontSize={'small'} sx={{ display: 'block', color: pgColorScheme.gray500 }} />
      </Grid>

      <Grid item xs={true}>
        <SfmRankBars componentRank={newRank} spacing={1} backdrop stretchNoImpact />
      </Grid>
    </Grid>
  );
};
