import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { Meeting } from '../store/model';
import { MeetingStatus } from '@priz/shared/src/models/meetings';
import { Trans } from 'react-i18next';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { Link } from 'react-router-dom';
import { MeetingSelectors } from '../store/selectors';

export interface MeetingEditorControlsProps {
  meeting: Meeting;
  onRelease?: () => void;
  onStart?: () => void;
  onFinish?: () => void;
  onUpdate?: () => void;
  onDelete?: () => void;
  disabled?: boolean;
  disableStart?: boolean;
}

export const MeetingEditorControls: React.FC<MeetingEditorControlsProps> = ({
  meeting,
  onRelease,
  onStart,
  onFinish,
  onUpdate,
  onDelete,
  disabled,
  disableStart,
}) => {
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [isReleaseConfirmationOpen, setIsReleaseConfirmationOpen] = useState(false);
  const [isStartConfirmationOpen, setIsStartConfirmationOpen] = useState(false);
  const [isFinishConfirmationOpen, setIsFinishConfirmationOpen] = useState(false);

  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const isUpdating = useSelector(MeetingSelectors.isUpdating(meeting.project.id));
  const isUpdated = useSelector(MeetingSelectors.isUpdated(meeting.project.id));
  const isRemoving = useSelector(MeetingSelectors.isRemoving(meeting.project.id));
  const isRemoved = useSelector(MeetingSelectors.isRemoved(meeting.project.id));
  const isLead = currentUser?.id === meeting.createdBy.id;

  useEffect(() => {
    if (isUpdated) {
      closeReleaseConfirmation();
      closeStartConfirmation();
      closeFinishConfirmation();
    }
  }, [isUpdated]);

  useEffect(() => {
    if (isRemoved) {
      closeDeleteConfirmation();
    }
  }, [isRemoved]);

  const releaseHandler = () => {
    if (onRelease) onRelease();
  };

  const startHandler = () => {
    if (onStart) onStart();
  };

  const finishHandler = () => {
    if (onFinish) onFinish();
  };

  const updateHandler = () => {
    if (onUpdate) onUpdate();
  };

  const deleteHandler = () => {
    if (onDelete) onDelete();
  };

  const openReleaseConfirmation = () => {
    setIsReleaseConfirmationOpen(true);
  };

  const closeReleaseConfirmation = () => {
    setIsReleaseConfirmationOpen(false);
  };

  const openStartConfirmation = () => {
    setIsStartConfirmationOpen(true);
  };

  const closeStartConfirmation = () => {
    setIsStartConfirmationOpen(false);
  };

  const openFinishConfirmation = () => {
    setIsFinishConfirmationOpen(true);
  };

  const closeFinishConfirmation = () => {
    setIsFinishConfirmationOpen(false);
  };

  const openDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
  };

  return (
    <>
      <ProjectContentControlBar
        projectId={meeting.project.id}
        leftColContent={
          <Button
            component={Link}
            to={['/project', meeting.project.id, 'meetings'].join('/')}
            variant={'pg_rounded'}
            color={'pg_orange_outlined'}
          >
            <Trans>Back to meetings list</Trans>
          </Button>
        }
        rightColCollapsingContent={
          <>
            <Button
              variant={'pg_rounded'}
              color={'pg_orange_outlined'}
              onClick={openDeleteConfirmation}
              disabled={disabled || !isLead || meeting.status === MeetingStatus.Active}
            >
              <Trans>Delete</Trans>
            </Button>

            {meeting.status === MeetingStatus.Draft && (
              <Button
                variant={'pg_rounded'}
                color={'pg_orange_solid'}
                onClick={openReleaseConfirmation}
                disabled={disabled || !isLead}
              >
                <Trans>Publish</Trans>
              </Button>
            )}

            {meeting.status === MeetingStatus.Released && (
              <Button
                variant={'pg_rounded'}
                color={'pg_orange_solid'}
                onClick={updateHandler}
                disabled={disabled || !isLead}
              >
                <Trans>Update</Trans>
              </Button>
            )}

            {meeting.status === MeetingStatus.Released && (
              <Button
                variant={'pg_rounded'}
                color={'pg_orange_solid'}
                onClick={openStartConfirmation}
                disabled={disabled || disableStart || !isLead}
              >
                <Trans>Start</Trans>
              </Button>
            )}

            {meeting.status === MeetingStatus.Active && (
              <Button
                variant={'pg_rounded'}
                color={'pg_orange_solid'}
                onClick={openFinishConfirmation}
                disabled={disabled || !isLead}
              >
                <Trans>Finish</Trans>
              </Button>
            )}
          </>
        }
        hideWorkingFlowButtons
      />

      {!disabled && isLead && (
        <>
          <PgConfirmationDialog
            isOpen={isReleaseConfirmationOpen}
            confirmTitle={<Trans>Confirm publish</Trans>}
            okButtonText={<Trans>Publish</Trans>}
            confirmContent={
              <>
                <Trans>Are you sure you want to publish this meeting?</Trans>
              </>
            }
            onConfirm={releaseHandler}
            onClose={closeReleaseConfirmation}
            disabled={isUpdating}
            loading={isUpdating}
          />

          <PgConfirmationDialog
            isOpen={isStartConfirmationOpen}
            confirmTitle={<Trans>Confirm start</Trans>}
            okButtonText={<Trans>Start</Trans>}
            confirmContent={
              <>
                <Trans>Are you sure you want to start this meeting?</Trans>
              </>
            }
            onConfirm={startHandler}
            onClose={closeStartConfirmation}
            disabled={isUpdating}
            loading={isUpdating}
          />

          <PgConfirmationDialog
            isOpen={isFinishConfirmationOpen}
            confirmTitle={<Trans>Confirm finish</Trans>}
            okButtonText={<Trans>Finish</Trans>}
            confirmContent={
              <>
                <Trans>Are you sure you want to finish this meeting?</Trans>
              </>
            }
            onConfirm={finishHandler}
            onClose={closeFinishConfirmation}
            disabled={isUpdating}
            loading={isUpdating}
          />

          <PgConfirmationDialog
            isOpen={isDeleteConfirmationOpen}
            confirmTitle={<Trans>Confirm delete</Trans>}
            okButtonText={<Trans>Delete</Trans>}
            confirmContent={
              <>
                <Trans>Are you sure you want to delete this meeting?</Trans>
              </>
            }
            onConfirm={deleteHandler}
            onClose={closeDeleteConfirmation}
            disabled={isRemoving}
            loading={isRemoving}
          />
        </>
      )}
    </>
  );
};
