import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useMutation } from 'react-query';
import { TranslationsApi } from '../services/translations.api';
import { Translation } from '../model/translation';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  actions: {
    textAlign: 'right',
  },
});

interface TranslationUpdateObject {
  id: number;
  loc: string;
  tr: string;
}

interface EditTranslationDialogProps {
  id: number;
  locale: string;
  value: string;
  isOpen: boolean;
  onClose?: () => void;
  onEdit?: (data: Translation) => void;
}

export const EditTranslationDialog: React.FC<EditTranslationDialogProps> = ({
  id,
  locale,
  value,
  isOpen,
  onClose,
  onEdit,
}) => {
  const styles = useStyles();

  const [open, setOpen] = useState(false);
  const [translation, setTranslation] = useState('');

  useEffect(() => {
    setTranslation(value);
  }, [value]);

  const mutation = useMutation(
    (object: TranslationUpdateObject) => TranslationsApi.update(object.id, object.loc, object.tr),
    {
      onSuccess: data => {
        if (onEdit) {
          onEdit(data);
        }
      },
    },
  );

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleValueChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTranslation(event.target.value);
  };

  const handleUpdate = () => {
    mutation.mutate({ id, loc: locale, tr: translation.trim() });
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setTranslation('');
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'lg'}>
      <DialogTitle id="edit-translation-dialog-title">Update translation record</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          onChange={handleValueChange}
          variant={'outlined'}
          id={`translation-field-${locale}`}
          type="text"
          value={translation}
          fullWidth
          multiline
        />
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose}>
          Cancel
        </Button>
        <Button variant={'pg_rounded'} color={'pg_orange_solid'} onClick={handleUpdate}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
