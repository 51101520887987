import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { Idea } from '@priz/shared/src/models/idea/idea';
import { StarIcon } from '../../../react/icons';
import { RankingScore } from '@priz/shared/src/components/ranking-score/component';
import { useStyles } from './styles';
import { ProjectAssigneeWithMember } from '@priz/shared/src/models/assignment';
import { ReportUser } from '../user/component';
import { format } from 'date-fns';
import { QuillContentViewer } from '@priz/shared/src/components/quill-content-viewer/component';
import { Status } from '@priz/shared/src/components/status/component';
import { FieldTitle } from '@priz/shared/src/components/form-elements';
import { ToolLinkResolver } from '../../../tools/tool-link-resolver/component';

export interface ReportIdeaProps {
  idea: Idea;
  assignee?: ProjectAssigneeWithMember;
  showRankingScore?: boolean;
  compact?: boolean;
}

export const ReportIdea: React.FC<ReportIdeaProps> = ({ idea, assignee, showRankingScore, compact }) => {
  const styles = useStyles();
  const { content, selectedAsWinning, latestRankingScore, details } = idea;
  const hasRankingScore = typeof latestRankingScore !== 'undefined';

  return (
    <Box mb={1} className={`${styles.root}${compact ? ' _compact' : ''}`}>
      <Paper variant={'outlined'}>
        <Box p={1}>
          <Grid container alignItems={'flex-start'}>
            <Grid item xs={true}>
              <QuillContentViewer content={content} />
            </Grid>
            <Grid item xs={'auto'} className={styles.iconsWrap}>
              {selectedAsWinning && (
                <Box pr={1}>
                  <StarIcon className={styles.icon} />
                </Box>
              )}

              {showRankingScore && hasRankingScore && (
                <RankingScore rankingScore={latestRankingScore} small={true} shadowBackground={true} />
              )}
            </Grid>
          </Grid>

          {details && (
            <Box my={2}>
              <FieldTitle text={'Details, value & risks'} mb={0.5} />
              <QuillContentViewer content={details} />
            </Box>
          )}

          {assignee && (
            <Box mt={1}>
              <Grid container alignItems={'center'} justifyContent={'flex-start'} spacing={2}>
                <Grid item>
                  <ReportUser assignee={assignee} size="small" />
                </Grid>

                <Grid item>
                  <Typography variant={'caption'} component={'div'}>
                    {format(idea?.lastUpdated || idea.dateCreated, 'MMM d yyyy')}
                  </Typography>
                </Grid>

                {idea.status && (
                  <Grid item>
                    <Status status={idea.status} />
                  </Grid>
                )}

                {idea.sourceToolUtilization && (
                  <Grid item overflow={'hidden'}>
                    <ToolLinkResolver
                      utilizationId={idea.sourceToolUtilization.id}
                      size={'extra-small'}
                      disabled={true}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};
