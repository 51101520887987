import { PublicUserAction, PublicUserActionType } from '../actions/public-users.actions';
import { PublicUser, PublicUserCollection } from '@priz/shared/src/models/user';
import { toSuccess } from '../../../shared/store/action-creator';
import { handleActions } from 'redux-actions';

export const publicUserReducers = handleActions(
  {
    [toSuccess(PublicUserActionType.LoadAllByIds)]: (
      state: PublicUserCollection,
      action: PublicUserAction,
    ): PublicUserCollection => {
      const userMapCopy = { ...state.entities };
      const newUserMap = action.payload.reduce((map, userPayload) => {
        map[userPayload.id] = new PublicUser(userPayload);
        return map;
      }, userMapCopy);

      return {
        ...state,
        entities: newUserMap,
      };
    },
  },
  {},
);
