import cloneDeep from 'lodash/cloneDeep';
import { combineActions, handleActions } from 'redux-actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { RrmGoalAction, RrmGoalActionType } from '../actions/rrm-goal.actions';
import { Goal, IGoal, RrmGoalCollectionStatuses } from '@priz/shared/src/models/tools/rrm';
import { IPerception, Perception } from '@priz/shared/src/models/tools/rrm';
import { DefaultRrmGoalCollection, RrmGoalCollection } from '@priz/shared/src/models/tools/rrm';
import { copyObject } from '@priz/shared/src/utils/common';
import { RrmUtilizationAction, RrmUtilizationActionType } from '../actions/rrm-utilization.actions';
import { StartRankingResult, UpdateRankResult } from '../../services';
import {
  CollectionStatusesService,
  SharedUpdateStatusesProps,
} from '@priz/shared/src/services/statuses/collection-statuses.service';

const resolveStatuses = (
  state: RrmGoalCollection,
  goalId: number,
  statuses: SharedUpdateStatusesProps,
): RrmGoalCollectionStatuses => {
  const statusesCopy = copyObject(state.statuses);

  statusesCopy.byGoalId[goalId] = CollectionStatusesService.updateStatusesCollection({
    collection: statusesCopy.byGoalId[goalId],
    ...statuses,
  });

  return statusesCopy;
};

const setStatuses = (
  state: RrmGoalCollection,
  goalId: number,
  statuses: SharedUpdateStatusesProps,
): RrmGoalCollection => {
  return {
    ...state,
    statuses: resolveStatuses(state, goalId, statuses),
  };
};

const setGoal = (state: RrmGoalCollection, goal: Goal): RrmGoalCollection => {
  const entitiesCopy = {
    ...state.entities,
    [goal.id]: goal,
  };

  return {
    ...state,
    entities: entitiesCopy,
    statuses: resolveStatuses(state, goal.id, { loaded: true, loading: false, error: false }),
  };
};

const addPerceptionToGoal = (goalId: number, perception: Perception) => state => {
  const goalCopy = cloneDeep(state.entities[goalId]) as Goal;
  goalCopy.perceptions.push(perception);

  const entitiesCopy = {
    ...state.entities,
    [goalCopy.id]: goalCopy,
  };

  return {
    ...state,
    entities: entitiesCopy,
    statuses: resolveStatuses(state, goalId, { creating: false, error: false }),
  };
};

const replacePerceptionInGoal = (goalId: number, perception: Perception) => state => {
  const goalCopy = cloneDeep(state.entities[goalId]) as Goal;
  const index = goalCopy.perceptions.findIndex(p => p.id === perception.id);

  if (index !== -1) {
    goalCopy.perceptions[index] = perception;
  }

  const entitiesCopy = {
    ...state.entities,
    [goalCopy.id]: goalCopy,
  };

  return {
    ...state,
    entities: entitiesCopy,
    statuses: resolveStatuses(state, goalId, { updating: false, error: false }),
  };
};

const replacePerceptionsInGoal = (goalId: number, perceptions: IPerception[], deletedIds?: number[]) => state => {
  const goalCopy = cloneDeep(state.entities[goalId]) as Goal;

  goalCopy.perceptions = [
    ...goalCopy.perceptions.filter(perception => (deletedIds ? !deletedIds.includes(perception.id) : true)),
    ...perceptions.map(perception => new Perception(perception)),
  ];

  const entitiesCopy = {
    ...state.entities,
    [goalCopy.id]: goalCopy,
  };

  return {
    ...state,
    entities: entitiesCopy,
    statuses: resolveStatuses(state, goalId, { updating: false, error: false }),
  };
};

const updatePerceptionsInGoal = (goalId: number, perceptions: IPerception[]) => state => {
  const goalCopy = cloneDeep(state.entities[goalId]) as Goal;

  const updatedPerceptionsMap = perceptions.reduce((map: { [key: number]: IPerception }, item) => {
    map[item.id] = item;
    return map;
  }, {});

  goalCopy.perceptions = goalCopy.perceptions.map(p => {
    return updatedPerceptionsMap[p.id] ? new Perception(updatedPerceptionsMap[p.id]) : p;
  });

  const entitiesCopy = {
    ...state.entities,
    [goalCopy.id]: goalCopy,
  };

  return {
    ...state,
    entities: entitiesCopy,
    statuses: resolveStatuses(state, goalId, { updating: false, error: false }),
  };
};

const removePerceptionFromGoal = (goalId: number, perceptionId: number) => state => {
  const goalCopy = cloneDeep(state.entities[goalId]) as Goal;
  goalCopy.perceptions = goalCopy.perceptions.slice().filter(p => p.id !== perceptionId);

  const entitiesCopy = {
    ...state.entities,
    [goalCopy.id]: goalCopy,
  };

  return {
    ...state,
    entities: entitiesCopy,
    statuses: resolveStatuses(state, goalId, {
      removing: false,
      error: false,
      idsUpdate: [
        { target: 'removingIds', method: 'remove', ids: [perceptionId] },
        { target: 'removedIds', method: 'add', ids: [perceptionId] },
      ],
    }),
  };
};

export const rrmGoalReducers = handleActions(
  {
    // load

    [toStarted(RrmGoalActionType.Fetch)]: (state: RrmGoalCollection, action: RrmGoalAction) =>
      setStatuses(state, action.meta.goalId, { loaded: false, loading: true, error: false }),

    [toFailed(RrmGoalActionType.Fetch)]: (state: RrmGoalCollection, action: RrmGoalAction) =>
      setStatuses(state, action.meta.goalId, { loaded: false, loading: false, error: true }),

    [toSuccess(RrmGoalActionType.Fetch)]: (state: RrmGoalCollection, action: RrmGoalAction) =>
      setGoal(state, new Goal(action.payload as IGoal)),

    // create

    [toStarted(RrmGoalActionType.PerceptionCreate)]: (state: RrmGoalCollection, action: RrmGoalAction) =>
      setStatuses(state, action.meta.goalId, { creating: true, error: false }),

    [toFailed(RrmGoalActionType.PerceptionCreate)]: (state: RrmGoalCollection, action: RrmGoalAction) =>
      setStatuses(state, action.meta.goalId, { creating: false, error: true }),

    [toSuccess(RrmGoalActionType.PerceptionCreate)]: (state: RrmGoalCollection, action: RrmGoalAction) =>
      addPerceptionToGoal(action.meta.goalId, new Perception(action.payload as IPerception))(state),

    // update

    [combineActions(
      toStarted(RrmGoalActionType.PerceptionUpdate),
      toStarted(RrmGoalActionType.PerceptionsReplace),
      toStarted(RrmUtilizationActionType.RankRecord),
      toStarted(RrmUtilizationActionType.StartRanking),
    )]: (state: RrmGoalCollection, action: RrmGoalAction) =>
      setStatuses(state, action.meta.goalId, { updating: true, error: false }),

    [combineActions(
      toFailed(RrmGoalActionType.PerceptionUpdate),
      toFailed(RrmGoalActionType.PerceptionsReplace),
      toFailed(RrmUtilizationActionType.RankRecord),
      toFailed(RrmUtilizationActionType.StartRanking),
    )]: (state: RrmGoalCollection, action: RrmGoalAction) =>
      setStatuses(state, action.meta.goalId, { updating: false, error: true }),

    [toSuccess(RrmGoalActionType.PerceptionUpdate)]: (state: RrmGoalCollection, action: RrmGoalAction) =>
      replacePerceptionInGoal(action.meta.goalId, new Perception(action.payload as IPerception))(state),

    [toSuccess(RrmGoalActionType.PerceptionsReplace)]: (state: RrmGoalCollection, action: RrmGoalAction) =>
      replacePerceptionsInGoal(action.meta.goalId, action.payload as IPerception[], action.meta.idsToDelete)(state),

    [toSuccess(RrmUtilizationActionType.RankRecord)]: (state: RrmGoalCollection, action: RrmUtilizationAction) => {
      const payload = action.payload as UpdateRankResult;

      return updatePerceptionsInGoal(action.meta.goalId, payload.perceptions)(state);
    },

    [toSuccess(RrmUtilizationActionType.StartRanking)]: (state: RrmGoalCollection, action: RrmUtilizationAction) => {
      const payload = action.payload as StartRankingResult;

      return updatePerceptionsInGoal(action.meta.goalId, payload.perceptions)(state);
    },

    // delete

    [toStarted(RrmGoalActionType.PerceptionDelete)]: (state: RrmGoalCollection, action: RrmGoalAction) =>
      setStatuses(state, action.meta.goalId, {
        removing: true,
        error: false,
        idsUpdate: [{ target: 'removingIds', method: 'add', ids: [action.meta.perceptionId] }],
      }),

    [toFailed(RrmGoalActionType.PerceptionDelete)]: (state: RrmGoalCollection, action: RrmGoalAction) =>
      setStatuses(state, action.meta.goalId, {
        removing: false,
        error: true,
        idsUpdate: [{ target: 'removingIds', method: 'remove', ids: [action.meta.perceptionId] }],
      }),

    [toSuccess(RrmGoalActionType.PerceptionDelete)]: (state: RrmGoalCollection, action: RrmGoalAction) =>
      removePerceptionFromGoal(action.meta.goalId, action.meta.perceptionId)(state),
  },
  DefaultRrmGoalCollection,
);
