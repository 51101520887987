import { FluxStandardAction } from 'flux-standard-action';
import { createPgAction } from '../../../shared/store/action-creator';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { AttachmentsService } from '../../services/attachments.service';
import { AttachmentType, IAttachment } from '../model';

export enum AttachmentsActionType {
  Load = 'attachments/load',
  Create = 'attachments/create',
  Update = 'attachments/update',
  Delete = 'attachments/delete',
  DeletePreview = 'attachments/deletePreview',
}

export interface AttachmentActionMeta {
  projectId: number;
  taskId: number;
  attachmentId: number;
  fileName: string;
  contentType: string;
  attachmentType: AttachmentType;
  file: File;
}

export type AttachmentActionPayload = IAttachment | IAttachment[];

export type AttachmentAction = FluxStandardAction<string, AttachmentActionPayload, AttachmentActionMeta>;

const getAllForProject = createPgAction(
  AttachmentsActionType.Load,
  AttachmentsService.getAllForProject,
  (projectId: number) => ({ projectId }),
  undefined,
);

const createOverviewAttachment = createPgAction(
  AttachmentsActionType.Create,
  AttachmentsService.createOverviewAttachment,
  (projectId: number, file: File) => ({
    projectId,
    file: file,
    fileName: file.name,
    contentType: file.type,
    attachmentType: AttachmentType.Overview,
  }),
  (_payload, meta) => {
    AnalyticsService.track(AnalyticsEvent.OVERVIEW_ATTACHMENT_CREATED, {
      project_id: meta.projectId,
    });
  },
);

const createSolutionAttachment = createPgAction(
  AttachmentsActionType.Create,
  AttachmentsService.createSolutionAttachment,
  (projectId: number, file: File) => ({
    projectId,
    file: file,
    fileName: file.name,
    contentType: file.type,
    attachmentType: AttachmentType.Solution,
  }),
  (_payload, meta) => {
    AnalyticsService.track(AnalyticsEvent.SOLUTION_ATTACHMENT_CREATED, {
      project_id: meta.projectId,
    });
  },
);

const createTaskAttachment = createPgAction(
  AttachmentsActionType.Create,
  AttachmentsService.createTaskAttachment,
  (projectId: number, taskId: number, file: File) => ({
    projectId,
    taskId,
    file: file,
    fileName: file.name,
    contentType: file.type,
    attachmentType: AttachmentType.Task,
  }),
  (_payload, meta) => {
    AnalyticsService.track(AnalyticsEvent.TASK_ATTACHMENT_CREATED, {
      project_id: meta.projectId,
    });
  },
);

const updateAttachmentFileName = createPgAction(
  AttachmentsActionType.Update,
  AttachmentsService.updateAttachmentFileName,
  (projectId: number, attachmentId: number) => ({ projectId, attachmentId }),
  (_payload, meta) => {
    AnalyticsService.track(AnalyticsEvent.ATTACHMENT_UPDATED, {
      project_id: meta.projectId,
    });
  },
);

const deleteAttachment = createPgAction(
  AttachmentsActionType.Delete,
  AttachmentsService.deleteAttachment,
  (projectId: number, attachmentId: number) => ({ projectId, attachmentId }),
  (meta: AttachmentActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.ATTACHMENT_DELETED, {
      project_id: meta.projectId,
    });
  },
);

const deletePreview = createPgAction(
  AttachmentsActionType.DeletePreview,
  () => new Promise(resolve => resolve({})),
  (projectId: number, fileName: string) => ({ projectId, fileName }),
);

export const AttachmentsActions = {
  getAllForProject,
  createOverviewAttachment,
  createSolutionAttachment,
  createTaskAttachment,
  updateAttachmentFileName,
  deleteAttachment,
  deletePreview,
};
