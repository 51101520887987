import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectSelector } from '../store/selectors';
import { ProjectActions } from '../store/actions/project.actions';
import { AppSideNav } from '../../navigation/app-sidenav/component';
import { AppNavbar } from '../../navigation/app-navbar/component';
import { Outlet } from 'react-router-dom';
import { PrizLoadingOverlay } from '@priz/shared/src/components/priz-loading-overlay/component';
import { PayWall } from '../../react/modules/paywall/component';
import { PageContainerWithAsideNav } from '../../content-containers/page-container-with-aside-nav/component';
import { ProjectFeatureBlocker } from '../../project-feature-blocker/component';

interface ProjectProps {
  projectId: number;
}

export const Project: React.FC<ProjectProps> = ({ projectId }) => {
  const dispatch = useDispatch();
  const project = useSelector(ProjectSelector.getById(projectId));

  useEffect(() => {
    dispatch(ProjectActions.loadProject(projectId));
  }, []);

  if (!project) return <PrizLoadingOverlay />;

  return (
    <>
      <PageContainerWithAsideNav>
        <AppSideNav project={project} />
        <AppNavbar project={project} padding={false} />
        <Outlet />
      </PageContainerWithAsideNav>

      <PayWall />
      <ProjectFeatureBlocker projectId={projectId} />
    </>
  );
};
