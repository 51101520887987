import { FluxStandardAction } from 'flux-standard-action';
import { DomainPrinciple } from '@priz/shared/src/models/tools/forty-principles/domain-principle';
import { PrincipleService } from '../../services/principle.service';
import { createPgAction } from '../../../shared/store/action-creator';
import { ActionMeta } from '../../../shared/store/types';

export enum DomainPrincipleActionType {
  Fetch = 'domainPrinciple/fetch',
  Update = 'domainPrinciple/update',
  SetState = 'domainPrinciple/setState',
}

interface DomainPrincipleMeta extends ActionMeta {
  domainPrincipleId: number;
}

export type DomainPrinciplesAction = FluxStandardAction<DomainPrincipleActionType, DomainPrinciple[]>;
export type DomainPrincipleAction = FluxStandardAction<DomainPrincipleActionType, DomainPrinciple, DomainPrincipleMeta>;

const loadDomainPrinciples = createPgAction(DomainPrincipleActionType.Fetch, PrincipleService.getAllDomainPrinciples);

const updateDomainPrinciple = createPgAction(
  DomainPrincipleActionType.Update,
  PrincipleService.updateDomainPrinciple,
  (domainPrincipleId: number) => ({ domainPrincipleId }),
);

const setState = createPgAction(
  DomainPrincipleActionType.SetState,
  (domainPrinciples: DomainPrinciple[]) => new Promise(resolve => resolve(domainPrinciples)),
);

export const DomainPrincipleActions = {
  loadDomainPrinciples,
  updateDomainPrinciple,
  setState,
};
