import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../store/app.state';

const playgroundStateSelector = (state: AppState) => state.playground;

const isAuthenticationRequired: Selector<AppState, boolean> = createSelector(
  playgroundStateSelector,
  state => state.isAuthenticationRequired,
);

export const PlaygroundSelectors = {
  isAuthenticationRequired,
};
