import React from 'react';
import { ReportDocument } from '../project-report/document/component';
import { useStyles } from './styles';
import { Preloader } from '../../react/preloader/component';
import { ReportHeader } from '../project-report/header/component';

export interface ProjectReportPageProps {
  projectId: number;
}

export const ProjectReportPage: React.FC<ProjectReportPageProps> = ({ projectId }) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Preloader text={'Preparing document'} fullScreen />
      <ReportHeader />
      <ReportDocument projectId={projectId} />
    </div>
  );
};
