import React from 'react';
import { useStyles } from './styles';
import { Box, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { SfmNodeType } from '../../models/tools/sfm';

export enum SfmRanksFilterType {
  All = 'All',
  Functional = 'Functional',
  Problematic = 'Problematic',
  NoImpact = 'NoImpact',
}

export interface SfmComponentLabelProps {
  text?: string;
  short?: boolean;
  type?: SfmNodeType;
}

export const SfmComponentLabel: React.FC<SfmComponentLabelProps> = ({ text, short, type = SfmNodeType.COMPONENT }) => {
  const styles = useStyles();
  const classNames = [styles.root];

  if (type === SfmNodeType.COMPONENT) classNames.push(styles.component);
  if (type === SfmNodeType.SUPER_SYSTEM) classNames.push(styles.superSystem);
  if (type === SfmNodeType.PRODUCT) classNames.push(styles.product);
  if (!text?.length) classNames.push(styles.empty);
  if (short) classNames.push(styles.short);

  return (
    <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
      <Typography variant={'caption'} component={'div'} className={classNames.join(' ')}>
        {text || <Trans>empty</Trans>}
      </Typography>
    </Box>
  );
};
