import * as React from "react";

export const ReactComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="#08445E" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M6.7002 24.2996C6.9002 24.5996 7.2002 24.7996 7.6002 24.7996H20.7002C21.1002 24.7996 21.4002 24.5996 21.6002 24.2996L24.4002 19.4996L31.6002 23.4996L28.0002 24.3996C27.5002 24.4996 27.1002 25.0996 27.3002 25.5996C27.4002 26.0996 28.0002 26.4996 28.5002 26.2996L34.3002 24.7996C34.6002 24.6996 34.8002 24.5996 34.9002 24.2996C35.1002 23.9996 35.1002 23.7996 35.0002 23.4996L33.5002 17.6996C33.4002 17.1996 32.8002 16.7996 32.3002 16.9996C31.8002 17.0996 31.4002 17.6996 31.6002 18.1996L32.5002 21.5996L25.3002 17.5996L28.0002 12.9996C28.2002 12.6996 28.2002 12.2996 28.0002 11.9996L21.5002 0.599609C21.3002 0.299609 21.0002 0.0996094 20.6002 0.0996094H7.5002C7.1002 0.0996094 6.8002 0.299609 6.6002 0.599609L0.100195 11.9996C-0.0998047 12.2996 -0.0998047 12.6996 0.100195 12.9996L6.7002 24.2996ZM8.1002 2.19961H20.0002L26.0002 12.4996L20.0002 22.7996H8.1002L2.2002 12.4996L8.1002 2.19961Z"/>
      <path d="M59.9001 29.4992L53.4001 18.1992C53.2001 17.8992 52.9001 17.6992 52.5001 17.6992H39.4001C39.0001 17.6992 38.7001 17.8992 38.5001 18.1992L32.0001 29.4992C31.8001 29.7992 31.8001 30.1992 32.0001 30.4992L34.5001 34.7992L27.3001 38.7992L28.2001 35.3992C28.3001 34.8992 28.0001 34.2992 27.5001 34.1992C27.0001 34.0992 26.4001 34.3992 26.3001 34.8992L24.8001 40.6992C24.7001 40.9992 24.7001 41.1992 24.9001 41.4992C25.1001 41.7992 25.2001 41.8992 25.5001 41.9992L31.3001 43.4992C31.8001 43.5992 32.4001 43.2992 32.5001 42.7992C32.6001 42.2992 32.3001 41.6992 31.8001 41.5992L28.4001 40.6992L35.6001 36.6992L38.6001 41.8992C38.8001 42.1992 39.1001 42.3992 39.5001 42.3992H52.6001C53.0001 42.3992 53.3001 42.1992 53.5001 41.8992L60.0001 30.5992C60.0001 30.1992 60.0001 29.7992 59.9001 29.4992ZM51.9001 40.2992H40.0001L34.0001 29.9992L40.0001 19.6992H51.9001L57.8001 29.9992L51.9001 40.2992Z"/>
      <path d="M21.5002 35.6992C21.3002 35.3992 21.0002 35.1992 20.6002 35.1992H7.5002C7.1002 35.1992 6.8002 35.3992 6.6002 35.6992L0.100195 46.9992C-0.0998047 47.2992 -0.0998047 47.6992 0.100195 47.9992L6.6002 59.2992C6.8002 59.5992 7.1002 59.7992 7.5002 59.7992H20.6002C21.0002 59.7992 21.3002 59.5992 21.5002 59.2992L28.0002 47.9992C28.2002 47.6992 28.2002 47.2992 28.0002 46.9992L21.5002 35.6992ZM20.0002 57.7992H8.1002L2.2002 47.4992L8.1002 37.1992H20.0002L26.0002 47.4992L20.0002 57.7992Z"/>
      <path d="M9.70005 19.0994L10.7 16.4994H17.4L18.4 19.0994C18.6 19.5994 19.2 19.8994 19.7 19.6994C20.2 19.4994 20.5 18.8994 20.3 18.3994L15.3 5.19941C15.1 4.69941 14.6 4.39941 14.1 4.39941C13.6 4.39941 13.1 4.69941 12.9 5.19941L7.90005 18.3994C7.70005 18.8994 8.00005 19.4994 8.50005 19.6994C8.90005 19.7994 9.50005 19.5994 9.70005 19.0994ZM14.1 7.59941L16.7 14.4994H11.5L14.1 7.59941Z"/>
      <path d="M50.1001 29.7L49.6001 29.3L49.9001 28.8C50.3001 28.1 50.6001 27.4 50.6001 26.6C50.6001 24.4 48.8001 22.5 46.5001 22.5H42.6001C42.1001 22.5 41.6001 22.9 41.6001 23.5V36.9C41.6001 37.2 41.7001 37.4 41.9001 37.6C42.1001 37.8 42.3001 37.9 42.6001 37.9H42.9001C43.7001 37.9 46.4001 37.9 47.2001 37.9C49.7001 37.9 51.8001 35.8 51.8001 33.3C51.8001 31.9 51.2001 30.6 50.1001 29.7ZM43.6001 24.5H46.5001C47.7001 24.5 48.6001 25.4 48.6001 26.6C48.6001 27.8 47.7001 28.7 46.5001 28.7H43.6001V24.5ZM47.2001 35.9H43.6001V30.7H47.2001C48.6001 30.7 49.8001 31.9 49.8001 33.3C49.8001 34.7 48.7001 35.9 47.2001 35.9Z"/>
      <path d="M18.9 51.5996C18.7 51.3996 18.4 51.2996 18.2 51.3996C17.9 51.3996 17.7 51.4996 17.5 51.7996C17.4 51.9996 17.2 52.0996 17.1 52.2996C16.3 52.9996 15.3 53.3996 14.1 53.3996C11 53.3996 8.4 50.7996 8.4 47.6996C8.4 44.5996 11 41.9996 14.1 41.9996C15.2 41.9996 16.3 42.2996 17.3 42.9996C17.8 43.2996 18.4 43.1996 18.7 42.6996C18.8 42.4996 18.9 42.1996 18.9 41.9996C18.9 41.6996 18.7 41.4996 18.5 41.3996C17.2 40.4996 15.7 40.0996 14.2 40.0996C10 40.0996 6.5 43.4996 6.5 47.7996C6.5 51.9996 9.9 55.4996 14.2 55.4996C15.9 55.4996 17.4 54.9996 18.5 53.8996C18.7 53.6996 18.9 53.4996 19.1 53.1996C19.4 52.5996 19.4 51.8996 18.9 51.5996Z"/>
    </g>
  </svg>
);
