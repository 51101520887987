import React, { useState } from 'react';
import { getCountries, getCountryCallingCode, Country } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';
import es from 'react-phone-number-input/locale/es.json';
import cn from '../locale/cn.json';
import { Box, IconButton, ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from '@mui/material';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { FlagIcon } from '../flag-icon/component';
import { SearchInput } from '../../../elements/search-input/component';
import { useStyles } from './styles';
import { LocalesService } from '@priz/shared/src/components/locales/services';
import { Locales } from '@priz/shared/src/models/locales';

const localesMap = {
  [Locales.EN]: en,
  [Locales.ES]: es,
  [Locales.ZH]: cn,
};

interface CountrySelectProps {
  country: Country;
  onChange: (countryCode: Country) => void;
  disabled?: boolean;
}

export const CountrySelect: React.FC<CountrySelectProps> = ({ country, onChange, disabled }) => {
  const styles = useStyles();

  const currentLocale = LocalesService.getCurrentLocale();
  const locale = localesMap[currentLocale];

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const changeSearchHandler = (text: string) => {
    setSearchValue(text.length ? text : null);
  };

  const clearSearchHandler = () => {
    setSearchValue(null);
  };

  const openToggleHandler = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const countrySelectHandler = (country: Country) => {
    setOpen(false);
    onChange(country);
  };

  return (
    <Box display={'flex'}>
      <PopperWrap
        open={open}
        disabled={disabled}
        onOpenToggle={openToggleHandler}
        placement={'bottom'}
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              altAxis: true,
            },
          },
        ]}
        dropdownProps={{
          p: 0,
          pt: 7.5,
        }}
      >
        <IconButton className={styles.selectButton} disabled={disabled}>
          <Box width={{ xs: 26, sm: 30 }} height={{ xs: 26, sm: 30 }} display={'flex'} alignItems={'center'}>
            <FlagIcon country={country} />
          </Box>
        </IconButton>

        <Box className={styles.popperContent}>
          <Box pt={2} px={2} pb={1} className={styles.searchContainer}>
            <SearchInput
              onChangeDebounced={changeSearchHandler}
              onClear={clearSearchHandler}
              onKeyDown={e => {
                e.stopPropagation();
              }}
              placeholder={'Search'}
              inputProps={{ min: 0 }}
            />
          </Box>

          <MenuList>
            {getCountries()
              .filter(item => {
                if (!searchValue) return true;
                return locale[item]?.toLowerCase()?.includes(searchValue.toLowerCase());
              })
              .map((item, key) => (
                <MenuItem key={key} onClick={() => countrySelectHandler(item)}>
                  <ListItemIcon>
                    <Box width={30}>
                      <FlagIcon country={item} />
                    </Box>
                  </ListItemIcon>

                  <ListItemText>
                    <Typography noWrap>{locale[item]}</Typography>
                  </ListItemText>

                  <Typography variant="body2" color="text.secondary">
                    +{getCountryCallingCode(item)}
                  </Typography>
                </MenuItem>
              ))}
          </MenuList>
        </Box>
      </PopperWrap>
    </Box>
  );
};
