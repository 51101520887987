import React, { useEffect } from 'react';
import { Box, BoxProps, Grid, Typography } from '@mui/material';
import { AttachmentsActions } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AttachmentsSelectors } from '../store/selectors';
import { AttachmentType } from '../store/model';
import { AttachmentItem } from '../attachment-item/component';
import { Trans } from 'react-i18next';
import { ProjectSelector } from '../../project/store/selectors';

export interface AttachmentsListProps extends BoxProps {
  projectId: number;
  type: AttachmentType;
  taskId?: number;
  onCreate?: (file: File) => void;
  compact?: boolean;
  loadingText?: string;
  disabled?: boolean;
}

export const AttachmentsList: React.FC<AttachmentsListProps> = ({
  projectId,
  type,
  taskId,
  onCreate,
  compact,
  loadingText,
  disabled,
  ...rest
}) => {
  const dispatch = useDispatch();

  const attachments = useSelector(AttachmentsSelectors.getAllByProjectIdAndType(projectId, type, taskId));
  const creatingAttachments = useSelector(
    AttachmentsSelectors.getCreatingAttachmentsByProjectIdAndType(projectId, type, taskId),
  );

  const isLoaded = useSelector(AttachmentsSelectors.isLoaded(projectId));
  const isLoading = useSelector(AttachmentsSelectors.isLoading(projectId));
  const project = useSelector(ProjectSelector.getById(projectId));

  useEffect(() => {
    dispatch(AttachmentsActions.getAllForProject(projectId));
  }, []);

  if (!project) return null;

  return (
    <Box {...rest} sx={{ pointerEvents: isLoading ? 'none' : 'auto', opacity: isLoading ? 0.7 : 1 }}>
      {!isLoaded && (
        <Typography variant={'body1'}>
          <Trans>{loadingText || 'Loading...'}</Trans>
        </Typography>
      )}

      {isLoaded && (
        <Grid container spacing={2}>
          {attachments.map((attachment, key) => (
            <Grid key={`${attachment.id}-${key}`} item>
              <AttachmentItem
                workspaceId={project.workspace.id}
                projectId={projectId}
                attachment={attachment}
                compact={compact}
                disabled={disabled}
              />
            </Grid>
          ))}

          {creatingAttachments.map((preview, key) => (
            <Grid key={`${preview.fileName}-${key}`} item>
              <AttachmentItem
                workspaceId={project.workspace.id}
                projectId={projectId}
                attachmentPreview={preview}
                onCreate={onCreate}
                compact={compact}
                disabled={disabled}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};
