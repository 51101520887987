import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { PfmStep, PfmStepType } from '@priz/shared/src/models/tools/pfm';
import { Box, Button, CircularProgress, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import {
  ChevronLeftRounded,
  ChevronRightRounded,
  DeleteOutlineOutlined,
  ExpandLessRounded,
  ExpandMoreRounded,
  OpenInNewRounded,
} from '@mui/icons-material';
import { PfmUtilizationActions } from '../store/actions';
import { Trans, useTranslation } from 'react-i18next';
import { ToolTitle } from '../../tools/tool-title/component';
import { useStyles } from './styles';
import { changeStepIndex, ChangeStepIndexDirection, changeStepType } from '../utils';
import { Link } from 'react-router-dom';
import {
  SfmEfficiencyViewer,
  SfmEfficiencyViewerBasicProps,
} from '@priz/shared/src/components/sfm-efficiency-viewer/component';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { SfmUtilizationActions } from '../../sfm/store/actions';
import { PfmStepTypeSelector } from '@priz/shared/src/components/pfm-step-type-selector/component';
import { ToolsUtils } from '../../tools/utils';

interface PfmItemProps extends SfmEfficiencyViewerBasicProps {
  step: PfmStep;
  allSteps: PfmStep[];
  projectId: number;
  pfmUtilizationId: number;
  isFirst: boolean;
  isLast: boolean;
  reversed: boolean;
  disabled?: boolean;
  loading?: boolean;
  navigationDisabled?: boolean;
}

enum ChangeIndexArrowType {
  Up = 'Up',
  Down = 'Down',
  Left = 'Left',
  Right = 'Right',
}

const resolveArrowType = (
  basicType: ChangeIndexArrowType,
  isFirst: boolean,
  isLast: boolean,
  reversed: boolean,
): ChangeIndexArrowType => {
  if (basicType === ChangeIndexArrowType.Left) {
    if (reversed && isLast) return ChangeIndexArrowType.Down;
    if (!reversed && isFirst) return ChangeIndexArrowType.Up;
  }

  if (basicType === ChangeIndexArrowType.Right) {
    if (!reversed && isLast) return ChangeIndexArrowType.Down;
    if (reversed && isFirst) return ChangeIndexArrowType.Up;
  }

  return basicType;
};

const arrowsDataMap = {
  [ChangeIndexArrowType.Up]: {
    tooltip: 'Move up',
    icon: <ExpandLessRounded fontSize="small" />,
  },
  [ChangeIndexArrowType.Down]: {
    tooltip: 'Move down',
    icon: <ExpandMoreRounded fontSize="small" />,
  },
  [ChangeIndexArrowType.Left]: {
    tooltip: 'Move left',
    icon: <ChevronLeftRounded fontSize="small" />,
  },
  [ChangeIndexArrowType.Right]: {
    tooltip: 'Move right',
    icon: <ChevronRightRounded fontSize="small" />,
  },
};

export const PfmItem: React.FC<PfmItemProps> = ({
  step,
  allSteps,
  sfmRank,
  maxSfmFunctionalRank,
  maxSfmProblematicRank,
  projectId,
  pfmUtilizationId,
  reversed,
  isFirst,
  isLast,
  disabled,
  loading,
  navigationDisabled,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);

  const sfmUtilization = useSelector(ToolUtilizationSelector.getSfmUtilization(step.sfmId));
  const maxIndex = Math.max(...allSteps.map(step => step.index));
  const minIndex = Math.min(...allSteps.map(step => step.index));

  const changeIndex = (direction: ChangeStepIndexDirection) => {
    if (disabled || loading) return null;

    dispatch(
      PfmUtilizationActions.update(
        pfmUtilizationId,
        {
          diagramData: changeStepIndex(step, allSteps, direction),
        },
        projectId,
      ),
    );
  };

  const deleteHandler = () => {
    dispatch(SfmUtilizationActions.delete(sfmUtilization.id, sfmUtilization.project?.id));
    closeDeleteConfirmation();
  };

  const pfmStepTypeChangeHandler = (value: PfmStepType) => {
    dispatch(
      PfmUtilizationActions.update(
        pfmUtilizationId,
        {
          diagramData: changeStepType(step.sfmId, allSteps, value),
        },
        projectId,
      ),
    );
  };

  const openDeleteConfirmation = () => {
    setDeleteConfirmationIsOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmationIsOpen(false);
  };

  const renderHeader = () => {
    const tooltipText = [t('Operation Title')];

    if (step.type) tooltipText.unshift(t(step.type));

    return (
      <Box px={1} py={0.25} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
        <Box maxWidth={'100%'}>
          <ToolTitle
            tool={sfmUtilization}
            editable={!disabled}
            initialTitle={`${t('Step')} ${step.index}`}
            disableIcon
            titleTooltip={tooltipText.join(' ')}
          />
        </Box>
      </Box>
    );
  };

  const renderControls = () => {
    const leftArrowData = arrowsDataMap[resolveArrowType(ChangeIndexArrowType.Left, isFirst, isLast, reversed)];
    const rightArrowData = arrowsDataMap[resolveArrowType(ChangeIndexArrowType.Right, isFirst, isLast, reversed)];

    return (
      <Box display={'flex'}>
        <Tooltip title={<Trans>Delete</Trans>} placement={'bottom'} disableInteractive arrow>
          <div>
            <IconButton size="small" onClick={openDeleteConfirmation} disabled={disabled}>
              <DeleteOutlineOutlined fontSize="small" />
            </IconButton>
          </div>
        </Tooltip>

        {(reversed ? maxIndex : minIndex) !== step.index && (
          <Tooltip title={<Trans>{leftArrowData.tooltip}</Trans>} placement={'bottom'} disableInteractive arrow>
            <div>
              <IconButton
                size="small"
                onClick={() => {
                  changeIndex(reversed ? 1 : -1);
                }}
                disabled={disabled}
              >
                {leftArrowData.icon}
              </IconButton>
            </div>
          </Tooltip>
        )}

        {(reversed ? minIndex : maxIndex) !== step.index && (
          <Tooltip title={<Trans>{rightArrowData.tooltip}</Trans>} placement={'bottom'} disableInteractive arrow>
            <div>
              <IconButton
                size="small"
                onClick={() => {
                  changeIndex(reversed ? -1 : 1);
                }}
                disabled={disabled}
              >
                {rightArrowData.icon}
              </IconButton>
            </div>
          </Tooltip>
        )}

        {!!step.type && (
          <Tooltip
            title={
              <Trans>{navigationDisabled ? 'Must be logged in to continue' : 'Go to Operation Functional Model'}</Trans>
            }
            placement={'bottom'}
            disableInteractive
            arrow
          >
            <Box sx={{ display: 'inline-block' }}>
              <IconButton
                component={Link}
                to={ToolsUtils.resolvePathByUtilization(sfmUtilization)}
                size="small"
                disabled={navigationDisabled}
              >
                <OpenInNewRounded fontSize="small" />
              </IconButton>
            </Box>
          </Tooltip>
        )}
      </Box>
    );
  };

  const renderDefineModelPlaceholder = () => {
    return (
      <Grid container flexDirection={'column'} alignItems={'center'} justifyContent={'center'} spacing={1}>
        <Grid item>
          <Typography variant={'body1'} color={'text_color.light'}>
            <Trans>Operation Functional Model is empty</Trans>
          </Typography>
        </Grid>

        <Grid item>
          <Tooltip
            title={navigationDisabled ? <Trans>Must be logged in to continue</Trans> : ''}
            placement={'bottom'}
            disableInteractive
            arrow
          >
            <div>
              <Button
                component={Link}
                to={ToolsUtils.resolvePathByUtilization(sfmUtilization)}
                variant={'pg_rounded'}
                color={'pg_orange_solid'}
                size={'pg_small'}
                disabled={disabled || navigationDisabled}
              >
                <Trans>Go to Model</Trans>
              </Button>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const renderSelectTypePlaceholder = () => {
    return (
      <PfmStepTypeSelector
        value={step.type}
        label={'Select operation type'}
        onSelect={pfmStepTypeChangeHandler}
        disabled={disabled}
      />
    );
  };

  if (!sfmUtilization)
    return (
      <div className={styles.preloader}>
        <CircularProgress />
      </div>
    );

  return (
    <>
      <SfmEfficiencyViewer
        sfmRank={sfmRank}
        maxSfmFunctionalRank={maxSfmFunctionalRank}
        maxSfmProblematicRank={maxSfmProblematicRank}
        pfmStepType={step.type}
        headerRenderer={renderHeader}
        controlsRenderer={renderControls}
        placeholderRenderer={step.type ? renderDefineModelPlaceholder : renderSelectTypePlaceholder}
        useTooltips={true}
        onPfmStepTypeSelect={!disabled && pfmStepTypeChangeHandler}
      />

      <PgConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        confirmTitle={<Trans>Confirm delete</Trans>}
        okButtonText={<Trans>Delete</Trans>}
        confirmContent={
          <React.Fragment>
            <Trans>Are you sure you want to delete this step and related model?</Trans>
          </React.Fragment>
        }
        onConfirm={deleteHandler}
        onClose={closeDeleteConfirmation}
      />
    </>
  );
};
