import React from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { Link } from 'react-router-dom';
import { ButtonCreateTool } from '../../tools/button-create-tool/component';
import { AddRounded } from '@mui/icons-material';

import emptyProjectsImg from '../../../assets/img/empty-projects.svg';

interface EmptyProjectsListProps {
  compact?: boolean;
}

export const EmptyProjectsList: React.FC<EmptyProjectsListProps> = ({ compact }) => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <img className={compact ? styles.compactImg : styles.img} src={emptyProjectsImg} alt={'empty projects'} />

      <Box mt={compact ? 2 : 8} className={styles.title}>
        <Typography variant={compact ? 'h6' : 'h4'} component={'span'}>
          <Trans>You have no project or tool. Add your first one.</Trans>
        </Typography>
      </Box>

      <Box mt={compact ? 2 : 4}>
        <ButtonsWrap justifyContent={'center'} spacing={compact ? 1 : 0}>
          <ButtonCreateTool startIcon={<AddRounded />} text={'Add New Tool'} size={compact ? 'pg_small' : undefined} />

          <Button
            component={Link}
            to={'/project-wizard'}
            variant={'pg_rounded'}
            color={'pg_orange_solid'}
            size={compact ? 'pg_small' : undefined}
            startIcon={<AddRounded />}
          >
            <Trans>Add New Project</Trans>
          </Button>
        </ButtonsWrap>
      </Box>
    </Box>
  );
};
