import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  tab: {
    position: 'relative',
  },
  tabLoading: {
    opacity: 0.6,
    pointerEvents: 'none',
  },
  tabActive: {
    backgroundColor: 'rgba(0,0,0,.1)',
  },
  nameInput: {
    fontSize: '0.875rem',
    lineHeight: '1.43',
    letterSpacing: '0.01071em',
  },
  text: {
    cursor: 'pointer',
    transition: 'color .15s ease',
    color: pgColorScheme.darkText,

    ['&:hover']: {
      color: pgColorScheme.blue,
    },
  },
  settingsButton: {
    cursor: 'pointer',
    padding: 2,

    ['&:not(:hover)']: {
      ['& $settingsIcon']: {
        color: pgColorScheme.placeholder,
      },
    },
  },
  settingsIcon: {
    transition: 'color .15s ease',
    display: 'block',
    fontSize: 20,
  },
});
