import axios, { AxiosResponse } from 'axios';
import { AgendaCreateCommand, AgendaUpdateCommand, IAgenda } from '../store/model';

const getAll = (projectId: number): Promise<IAgenda> =>
  axios.get(`/v1/api/<ws-id>/project/${projectId}/agendas`).then((response: AxiosResponse<IAgenda>) => response.data);

const get = (projectId: number, agendaId: number): Promise<IAgenda> =>
  axios
    .get(`/v1/api/<ws-id>/project/${projectId}/agenda/${agendaId}`)
    .then((response: AxiosResponse<IAgenda>) => response.data);

const create = (projectId: number, command: AgendaCreateCommand): Promise<IAgenda> =>
  axios
    .post(`/v1/api/<ws-id>/project/${projectId}/agenda`, command)
    .then((response: AxiosResponse<IAgenda>) => response.data);

const update = (projectId: number, agendaId: number, command: AgendaUpdateCommand): Promise<IAgenda> =>
  axios
    .patch(`/v1/api/<ws-id>/project/${projectId}/agenda/${agendaId}`, command)
    .then((response: AxiosResponse<IAgenda>) => response.data);

const doDelete = (projectId: number, agendaId: number): Promise<IAgenda> =>
  axios
    .delete(`/v1/api/<ws-id>/project/${projectId}/agenda/${agendaId}`)
    .then((response: AxiosResponse<IAgenda>) => response.data);

export const AgendaApi = {
  getAll,
  get,
  create,
  update,
  delete: doDelete,
};
