import { copyObject } from '../../utils/common';
import {
  CftDiagramData,
  CftNodeData,
  CftNodesCollection,
  CftNodesMap,
  CftNodeType,
  CftVersion,
} from '../../models/tools/cft';

const resolveVersionNodes = (
  diagramData?: CftDiagramData,
  versionId?: string,
  defaultNodes?: CftNodeData[],
): CftNodeData[] => {
  let data: CftNodeData[] = defaultNodes ? copyObject(defaultNodes) : [];

  if (diagramData) {
    const diagramDataCopy = copyObject(diagramData);
    const id = versionId || diagramDataCopy.versionId;
    const version: CftVersion | null = id && diagramDataCopy.versions ? diagramDataCopy.versions[id] : null;

    if (version && version.nodes) {
      data = copyObject(version.nodes);
    }
  }

  return data;
};

const resolveNodesMap = (cftNodes: CftNodeData[] = []): CftNodesMap => {
  return cftNodes.reduce((map: CftNodesMap, node) => {
    map[node.id] = node;
    return map;
  }, {});
};

const resolveNodesCollection = (cftNodes: CftNodeData[] = []): CftNodesCollection => {
  return {
    nodesArray: cftNodes,
    nodesMap: resolveNodesMap(cftNodes),
  };
};

interface NodesRelations {
  parentNode: { [toId: string]: string };
  childNodes: { [nodeId: string]: string[] };
}

const resolveNodesRelations = (cftNodes: CftNodeData[]): NodesRelations => {
  const relations: NodesRelations = { parentNode: {}, childNodes: {} };

  cftNodes.forEach(node => {
    if (node.to.length) {
      node.to.forEach(toId => {
        relations.parentNode[toId] = node.id;
      });

      relations.childNodes[node.id] = node.to;
    }
  });

  return relations;
};

const getUnchainedNodesIds = (cftNodes: CftNodeData[]): string[] => {
  const relations = CftDataUtils.resolveNodesRelations(cftNodes);
  const unchainedNodes: string[] = [];

  cftNodes.forEach(node => {
    if (node.root) {
      return;
    }

    if (!node.to.length && [CftNodeType.Variant, CftNodeType.Process].includes(node.type)) {
      unchainedNodes.push(node.id);
    }

    if (!relations.parentNode[node.id]) {
      unchainedNodes.push(node.id);
    }
  });

  return unchainedNodes;
};

type Level = {
  index: number;
  nodes: CftNodeData[];
};

type Tree = Level[];

const resolveTree = (nodesMap: CftNodesMap, fromId: string, includeRoot?: boolean): Tree => {
  const tree: Tree = [];

  if (includeRoot && nodesMap[fromId]) {
    tree.push({
      index: 0,
      nodes: [nodesMap[fromId]],
    });
  }

  let queue: string[] = [fromId];

  while (queue.length) {
    const nextLevel: CftNodeData[] = [];

    queue.forEach(id => {
      const currentNode = nodesMap[id];
      const toIds = currentNode.to || [];

      toIds.forEach(toId => {
        const toNode = nodesMap[toId];

        if (toNode && toNode.type !== CftNodeType.Abstraction) {
          nextLevel.push(toNode);
        }
      });
    });

    if (nextLevel.length) {
      tree.push({
        index: tree.length,
        nodes: nextLevel,
      });
    }

    queue = nextLevel.reduce((ids: string[], node) => {
      if (![CftNodeType.Variant, CftNodeType.Process].includes(node.type)) {
        ids.push(node.id);
      }

      return ids;
    }, []);
  }

  return tree;
};

type Branch = { index: number; tree: Tree; root: CftNodeData; fromId?: string };

const resolveBranches = (nodesMap: CftNodesMap): Branch[] => {
  const nodes = Object.values(nodesMap);
  const rootStatements: CftNodeData[] = [];
  const abstractions: CftNodeData[] = [];
  const levelRelationsMap: { [toId: string]: { from: string; index: number } } = {};
  const branches: Branch[] = [];

  const toArray = nodes.reduce((array: string[], node) => {
    if (node.to.length) {
      array.push(...node.to);
    }

    return array;
  }, []);

  nodes.forEach(node => {
    if (node.type === CftNodeType.Abstraction) {
      abstractions.push(node);
    }

    if (node.type === CftNodeType.Statement && !toArray.includes(node.id)) {
      rootStatements.push(node);
    }
  });

  rootStatements.forEach((node, key) => {
    const tree = resolveTree(nodesMap, node.id, true);

    tree.forEach(level => {
      level.nodes.forEach(node => {
        node.to.forEach(toId => {
          levelRelationsMap[toId] = { from: node.id, index: level.index };
        });
      });
    });

    branches.push({ index: key, root: node, tree });
  });

  abstractions.forEach(node => {
    const relatedLevel = levelRelationsMap[node.id];

    branches.push({
      index: nodes.length + (nodes.length - (relatedLevel?.index || 0)),
      tree: resolveTree(nodesMap, node.id, true),
      root: node,
      fromId: relatedLevel?.from,
    });
  });

  return branches;
};

interface NextNodeIterations {
  leadsToStep: boolean;
  leadsToStatement: boolean;
  leadsToAbstraction: boolean;
  leadsToVariant: boolean;
  leadsToProcess: boolean;
}

const resolveNextNodeIterations = (nodesMap: CftNodesMap, node: CftNodeData): NextNodeIterations => {
  const nextIterations: NextNodeIterations = {
    leadsToStep: false,
    leadsToStatement: false,
    leadsToAbstraction: false,
    leadsToVariant: false,
    leadsToProcess: false,
  };

  node.to.forEach(id => {
    const nodeType = nodesMap[id]?.type;

    if (nodeType === CftNodeType.Step) {
      nextIterations.leadsToStep = true;
    }

    if (nodeType === CftNodeType.Statement) {
      nextIterations.leadsToStatement = true;
    }

    if (nodeType === CftNodeType.Abstraction) {
      nextIterations.leadsToAbstraction = true;
    }

    if (nodeType === CftNodeType.Variant) {
      nextIterations.leadsToVariant = true;
    }

    if (nodeType === CftNodeType.Process) {
      nextIterations.leadsToProcess = true;
    }
  });

  return nextIterations;
};

export const CftDataUtils = {
  resolveVersionNodes,
  resolveNodesMap,
  resolveNodesCollection,
  resolveNodesRelations,
  resolveNextNodeIterations,
  getUnchainedNodesIds,
  resolveTree,
  resolveBranches,
};
