import { IdRef } from '../../common/id-ref';
import { AuditableEntity, IAuditableEntity } from '../../common/auditable-entity';
import { Identifiable } from '../../common/entity-collection-state';

export enum EbsIdeaDifficultyVariant {
  Easy = 'Easy',
  NotEasy = 'NotEasy',
}

export enum EbsIdeaCategory {
  IE_VE = 'IE_VE',
  IE_VN = 'IE_VN',
  IN_VE = 'IN_VE',
  IN_VN = 'IN_VN',
  NOT_DEFINED = 'NOT_DEFINED',
}

export const EbsIdeaDifficultyVariantTextMap: {
  [key in EbsIdeaCategory]: { implementation: string; validation: string };
} = {
  [EbsIdeaCategory.IE_VE]: {
    implementation: 'Easy',
    validation: 'Easy',
  },
  [EbsIdeaCategory.IE_VN]: {
    implementation: 'Easy',
    validation: 'Not Easy',
  },
  [EbsIdeaCategory.IN_VE]: {
    implementation: 'Not Easy',
    validation: 'Easy',
  },
  [EbsIdeaCategory.IN_VN]: {
    implementation: 'Not Easy',
    validation: 'Not Easy',
  },
  [EbsIdeaCategory.NOT_DEFINED]: {
    implementation: 'Not selected',
    validation: 'Not selected',
  },
};

export interface IEbsIdea extends Identifiable, IAuditableEntity {
  description: string;
  workspace: IdRef;
  utilization: IdRef;
  latestRankingScore?: number;
  implementation?: EbsIdeaDifficultyVariant;
  validation?: EbsIdeaDifficultyVariant;
  x?: number;
  y?: number;
  nodeId?: string;
}

export interface EbsIdeaNodeProps extends IEbsIdea {
  label: string;
  type?: EbsIdeaCategory;
  extraRenderFunction?: (
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    width: number,
    height: number,
  ) => void;
}

export class EbsIdea extends AuditableEntity implements IEbsIdea {
  public id: number;
  public description: string;
  public workspace: IdRef;
  public utilization: IdRef;

  public implementation?: EbsIdeaDifficultyVariant;
  public validation?: EbsIdeaDifficultyVariant;
  public latestRankingScore?: number;
  public x?: number;
  public y?: number;
  public nodeId?: string;

  constructor(json: IEbsIdea) {
    super(json);

    this.id = json.id;
    this.description = json.description;
    this.workspace = json.workspace;
    this.utilization = json.utilization;
    this.latestRankingScore = json.latestRankingScore;
    this.implementation = json.implementation;
    this.validation = json.validation;
    this.x = json.x;
    this.y = json.y;
    this.nodeId = json.nodeId;
  }
}
