import { numberToLetters } from '@priz/shared/src/utils/convertation';
import { DelimiterType, delimitersOptions } from './data';

export type CSVDataArray = { [key: string]: string }[];

export const CSVToArray = (csvData: string, delimiter: string, header: boolean): CSVDataArray => {
  const lines = csvData.split('\n');
  const result = [];
  const headers = lines[0]
    .split(delimiter)
    .filter(item => !!item?.trim()?.length)
    .map((item, key) => (header ? item.trim() : numberToLetters(key + 1)));

  for (let i = header ? 1 : 0; i < lines.length; i++) {
    if (!lines[i]) continue;

    const obj = {};
    const line = lines[i].split(delimiter);
    let lineValuesCount = 0;

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = line[j];
      if (line[j]?.length) lineValuesCount += 1;
    }

    if (lineValuesCount) result.push(obj);
  }

  return result;
};

export const detectDelimiter = (csvData: string): DelimiterType => {
  const firstLine = csvData.split('\n', 1)[0];
  const delimitersCountMap = delimitersOptions.reduce((map: { [key: string]: number }, item) => {
    map[item.value] = (firstLine.match(new RegExp('\\' + item.value, 'g')) || []).length;
    return map;
  }, {});
  const maxCount = Math.max(...Object.values(delimitersCountMap), 0);

  return Object.keys(delimitersCountMap).find(key => delimitersCountMap[key] === maxCount) as DelimiterType;
};
