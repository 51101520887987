import axios, { AxiosResponse } from 'axios';
import { ITopic, Topic } from '@priz/shared/src/models/project';

const create = (topic: ITopic): Promise<Topic> =>
  axios.post(`/v1/api/topic`, { ...topic }).then((response: AxiosResponse<Topic>) => response.data);

const update = ({ id, ...rest }: Partial<Topic>): Promise<Topic> =>
  axios.put(`/v1/api/topic/${id}`, { ...rest }).then((response: AxiosResponse<Topic>) => response.data);

const doDelete = (id: number): Promise<void> => axios.delete(`/v1/api/topic/${id}`);

export const TopicsApi = {
  create,
  update,
  delete: doDelete,
};
