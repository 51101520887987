import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../store/app.state';
import { Workspace, WorkspaceMembershipLevel, PlanLevel } from '@priz/shared/src/models/workspace';
import { WorkspaceSelectors } from '../../../workspace/store/selectors';
import { UserContext } from '../model';

const userContextSelector = (state: AppState): UserContext => state.userContext;

const getCurrentUserWorkspaceIds: Selector<AppState, number[]> = createSelector(userContextSelector, context =>
  context.userWorkspaces.map(wmd => wmd.id),
);

const getUserWorkspacesMembershipLevelMap: Selector<AppState, { [key: number]: WorkspaceMembershipLevel }> =
  createSelector(userContextSelector, context =>
    context.userWorkspaces.reduce((map, item) => {
      map[item.id] = item.membershipLevel;
      return map;
    }, {}),
  );

const getUserWorkspaces: Selector<AppState, Workspace[]> = createSelector(
  [getCurrentUserWorkspaceIds, WorkspaceSelectors.getWorkspaceEntityMap],
  (ids, map) => {
    const workspaces = ids.map(id => map[id]).filter(workspace => !!workspace);
    const personalIndex = workspaces.findIndex(w => w.type === PlanLevel.Personal);

    if (personalIndex !== -1) {
      const personalWorkspace = workspaces.splice(personalIndex, 1);

      if (personalWorkspace) {
        workspaces.unshift(personalWorkspace[0]);
      }
    }

    return workspaces;
  },
);

const isWorkspaceAdmin: Selector<AppState, boolean> = createSelector(
  [userContextSelector, WorkspaceSelectors.getSelectedWorkspace],
  (context, selectedWorkspace) =>
    context.userWorkspaces.find(wmd => selectedWorkspace && wmd.id === selectedWorkspace.id)?.membershipLevel ===
    WorkspaceMembershipLevel.Admin,
);

export const UserWorkspaceContextSelectors = {
  getUserWorkspacesMembershipLevelMap,
  getUserWorkspaces,
  isWorkspaceAdmin,
};
