const sorterById = (left: any, right: any) => (left.id < right.id ? -1 : left.id > right.id ? 1 : 0);

const fromMap = value => value && typeof value === 'object' && Object.keys(value).map(key => value[key]);

const concatInDistinctArray = (arr = [], el) => {
  const nonNullArr = arr === null ? [] : arr;
  return nonNullArr.concat(el).filter((v, i, a) => a.indexOf(v) === i);
};

const removeFromArray = (arr = [], el) => {
  const nonNullArr = arr === null ? [] : arr;
  return nonNullArr.filter(item => item !== el);
};

export const ArrayUtil = {
  concatInDistinctArray,
  fromMap,
  removeFromArray,
  sorterById,
};
