import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { pgColorScheme } from '../../theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      textAlign: 'center',
    },
    progress: {
      color: pgColorScheme.blue,
      animationDuration: '.5s',
      zIndex: 3,
    },
    messageContainer: {
      position: 'relative',
    },
    message: {
      position: 'relative',
      zIndex: 2,
    },
  }),
);
