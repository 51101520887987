import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { MeetingActions } from '../store/actions';
import { useForm } from 'react-hook-form';
import { ReactHookFormText } from '../../react/form-elements';
import { validateOptions } from '@priz/shared/src/utils/form';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { MeetingSelectors } from '../store/selectors';
import { MeetingCreateCommand } from '../store/model';
import { useStyles } from './styles';
import { ToolType } from '@priz/shared/src/models/tools';

export interface SharedMeetingRecorderProps {
  projectId: number;
  sourceId?: string;
  sourceToolId?: number;
  sourceToolType?: ToolType;
  navigateToMeetingPage?: boolean;
}

interface MeetingRecorderProps extends SharedMeetingRecorderProps {
  open?: boolean;
  onClose?: () => void;
}

export const MeetingRecorder: React.FC<MeetingRecorderProps> = ({
  projectId,
  open,
  onClose,
  sourceId,
  sourceToolId,
  sourceToolType,
  navigateToMeetingPage,
}) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const isCreating = useSelector(MeetingSelectors.isCreating(projectId));
  const isCreated = useSelector(MeetingSelectors.isCreated(projectId));

  const { handleSubmit, reset, control } = useForm({
    shouldFocusError: false,
    defaultValues: {
      title: '',
    },
  });

  useEffect(() => {
    if (typeof open !== 'undefined') {
      setIsConfirmationOpen(open);
    }
  }, [open]);

  useEffect(() => {
    if (isCreated) closeConfirmation();
  }, [isCreated]);

  useEffect(() => {
    if (isConfirmationOpen) {
      reset({ title: '' });
    }
  }, [isConfirmationOpen]);

  const createMeetingHandler = () => {
    handleSubmit(formData => {
      const command: MeetingCreateCommand = {
        ...formData,
        sourceId,
        sourceToolType,
        sourceToolUtilizationId: sourceToolId,
      };

      dispatch(MeetingActions.create(projectId, command, navigateToMeetingPage ? navigate : undefined));
    })();
  };

  const closeConfirmation = () => {
    if (onClose) {
      onClose();
    } else {
      setIsConfirmationOpen(false);
    }
  };

  return (
    <Dialog
      className={styles.root}
      open={isConfirmationOpen}
      onClose={closeConfirmation}
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle>{<Trans>Create new meeting</Trans>}</DialogTitle>

      <DialogContent>
        <ReactHookFormText
          name={'title'}
          fieldTitle={'Meeting title'}
          control={control}
          rules={{
            validate: {
              ...validateOptions.hasText('Field is required'),
            },
          }}
          disabled={isCreating}
        />
      </DialogContent>

      <DialogActions>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={closeConfirmation}>
          <Trans>Cancel</Trans>
        </Button>

        <LoadingButton
          variant={'pg_rounded'}
          color={'pg_orange_solid'}
          onClick={createMeetingHandler}
          disabled={isCreating}
          loading={isCreating}
        >
          <Trans>Create</Trans>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
