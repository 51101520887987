import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserContextActions } from '../../security/store/actions';
import { WorkspaceSelectors } from '../store/selectors';
import { UserContextService } from '@priz/shared/src/services/user';
import { WorkspaceActions } from '../store/actions';
import { PrizLoadingOverlay } from '@priz/shared/src/components/priz-loading-overlay/component';
import { useLocation, useNavigate } from 'react-router-dom';
import { TeamSelectors } from '../../team/store/selectors/team.selectors';
import { UserContextSelectors } from '../../security/store/selectors';
import { resolveRedirect } from '../../security/utils';

export interface SwitchToWorkspaceProps {
  workspaceId: number;
}

export const SwitchToWorkspace: React.FC<SwitchToWorkspaceProps> = ({ workspaceId }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [allowToSwitch, setAllowToSwitch] = useState(false);

  const workspace = useSelector(WorkspaceSelectors.getById(workspaceId));
  const workspaceProjectsCountMap = useSelector(UserContextSelectors.workspaceProjectsCountMap);
  const isUserContextLoaded = useSelector(UserContextSelectors.isLoaded);
  const team = useSelector(TeamSelectors.getDefault(workspaceId));

  useEffect(() => {
    dispatch(UserContextActions.forceLoad(pathname, navigate));
    setAllowToSwitch(true);
  }, []);

  useEffect(() => {
    if (allowToSwitch && isUserContextLoaded && workspace && team) {
      setAllowToSwitch(false);

      UserContextService.setSelectedWorkspaceId(workspace.id);
      UserContextService.setSelectedTeamId(team.id);

      dispatch(WorkspaceActions.switch(workspace.id, team.id));

      // the timeout is to add a feeling that something happens
      setTimeout(() => {
        resolveRedirect({
          navigate,
          workspaceProjectsCountMap,
        });
      }, 1000);
    }
  }, [isUserContextLoaded, allowToSwitch, workspace?.id, team?.id]);

  return <PrizLoadingOverlay />;
};
