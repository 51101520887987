import React, { useCallback, useEffect } from 'react';
import Xarrow, { anchorType, useXarrow } from 'react-xarrows';
import { TemplateCategory, TemplateCompletion, TemplateType } from '@priz/shared/src/models/template';
import { templateCategoryColorMap, templates } from '../data';
import { useMediaQuery, useTheme } from '@mui/material';
import debounce from 'lodash/debounce';

interface TemplateSpiderProps {
  templateType: TemplateType;
  templateCompletion?: TemplateCompletion;
}

const startAnchorsProps: { [key in TemplateCategory]: anchorType } = {
  [TemplateCategory.Preparing]: 'top',
  [TemplateCategory.Tools]: 'bottom',
  [TemplateCategory.Activity]: 'top',
  [TemplateCategory.Result]: 'bottom',
};

const endAnchorsProps: { [key in TemplateCategory]: anchorType } = {
  [TemplateCategory.Preparing]: 'right',
  [TemplateCategory.Tools]: 'right',
  [TemplateCategory.Activity]: 'left',
  [TemplateCategory.Result]: 'left',
};

export const TemplateSpiderArrows: React.FC<TemplateSpiderProps> = ({ templateType, templateCompletion }) => {
  const theme = useTheme();
  const isDownMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const isDownLgBreakpoint = useMediaQuery(theme.breakpoints.down('lg'));
  const updateXArrow = useXarrow();

  useEffect(() => {
    updateXArrowDebounced();
  }, [templateCompletion]);

  useEffect(() => {
    updateXArrow();
    updateXArrowDebounced();
  }, [isDownMdBreakpoint, isDownLgBreakpoint]);

  useEffect(() => {
    window.addEventListener('resize', updateXArrowDebounced);

    return () => {
      window.removeEventListener('resize', updateXArrowDebounced);
    };
  }, []);

  const updateXArrowDebounced = useCallback(
    debounce(() => {
      updateXArrow();
    }, 100),
    [],
  );

  return (
    <>
      {Object.keys(templates[templateType]).map(category => {
        return (
          <React.Fragment key={category}>
            {templates[templateType][category].map(option => {
              return (
                <Xarrow
                  key={option}
                  start={'spider'}
                  end={`${templateType}-${option}`}
                  color={templateCategoryColorMap[category]}
                  strokeWidth={isDownMdBreakpoint ? 2 : 4}
                  showHead={false}
                  startAnchor={isDownLgBreakpoint ? startAnchorsProps[category] : 'auto'}
                  endAnchor={endAnchorsProps[category]}
                />
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
};
