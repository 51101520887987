import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  table: {
    minWidth: 800,
  },
  letter: {
    padding: '0 0.4em',
    minWidth: '0.8em',
    textAlign: 'center',
    borderRadius: '1em',
    backgroundColor: pgColorScheme.gray2,
  },
  blockingRankLow: {
    color: pgColorScheme.gray,
  },
  blockingRankMed: {
    color: pgColorScheme.darkText,
  },
  blockingRankHigh: {
    color: pgColorScheme.coral,
  },
});
