import { AuditableEntity, IAuditableEntity } from '../common/auditable-entity';
import { Identifiable } from '../common/entity-collection-state';
import { PlanInterval } from './plan-interval.enum';
import { PlanLevel } from '../workspace/plan-level.enum';

export interface IPlan extends IAuditableEntity, Identifiable {
  stripePlanId: string;
  name: string;
  cost: number;
  maxUsers?: number;
  active: boolean;
  interval: PlanInterval;
  level: PlanLevel;
}

export class Plan extends AuditableEntity implements IPlan {
  public id: number;
  public stripePlanId: string;
  public name: string;
  public cost: number;
  public maxUsers?: number;
  public active: boolean;
  public interval: PlanInterval;
  public level: PlanLevel;

  constructor(json: IPlan) {
    super(json);
    this.id = json.id;
    this.stripePlanId = json.stripePlanId;
    this.name = json.name;
    this.cost = json.cost;
    this.maxUsers = json.maxUsers;
    this.active = json.active;
    this.interval = json.interval;
    this.level = json.level;
  }
}

export type IntervalPlanMap = {
  [interval in PlanInterval]: Readonly<Plan>;
};

export type LevelPlansMap = { [level in PlanLevel]: IntervalPlanMap };
