import { makeStyles } from '@mui/styles';
import { cftSettings } from '../data';
import { pgColorScheme } from '../../../theme';
import { alpha } from '@mui/material';

export const useStyles = makeStyles({
  root: {
    display: 'block',
    position: 'relative',
  },
  statement: {
    backgroundColor: cftSettings.colors.statement.background,
    minWidth: cftSettings.node.statement.width.min,
    maxWidth: cftSettings.node.statement.width.max,

    ['& $content']: {
      padding: `
        ${cftSettings.node.statement.paddings.top * 8}px
        ${cftSettings.node.statement.paddings.right * 8}px
        ${cftSettings.node.statement.paddings.bottom * 8}px
        ${cftSettings.node.statement.paddings.left * 8}px
      `,
      minHeight: cftSettings.node.statement.height.min,
      ...cftSettings.font.default,
      ...(cftSettings.font.statement || {}),
    },
  },
  abstraction: {
    backgroundColor: cftSettings.colors.abstraction.background,
    minWidth: cftSettings.node.abstraction.width.min,
    maxWidth: cftSettings.node.abstraction.width.max,

    ['& $content']: {
      padding: `
        ${cftSettings.node.abstraction.paddings.top * 8}px
        ${cftSettings.node.abstraction.paddings.right * 8}px
        ${cftSettings.node.abstraction.paddings.bottom * 8}px
        ${cftSettings.node.abstraction.paddings.left * 8}px
      `,
      minHeight: cftSettings.node.abstraction.height.min,
      ...cftSettings.font.default,
      ...(cftSettings.font.abstraction || {}),
    },
  },
  step: {
    backgroundColor: cftSettings.colors.step.background,
    minWidth: cftSettings.node.step.width.min,
    maxWidth: cftSettings.node.step.width.max,
    borderRadius: 20,
    borderStyle: 'solid',
    borderWidth: 2,

    ['& $content']: {
      padding: `
        ${cftSettings.node.step.paddings.top * 8}px
        ${cftSettings.node.step.paddings.right * 8}px
        ${cftSettings.node.step.paddings.bottom * 8}px
        ${cftSettings.node.step.paddings.left * 8}px
      `,
      minHeight: cftSettings.node.step.height.min,
      ...cftSettings.font.default,
      ...(cftSettings.font.step || {}),
    },
  },
  blockerStep: {
    borderColor: cftSettings.colors.status.blocker,
  },
  problematicStep: {
    borderColor: cftSettings.colors.status.problematic,
  },
  verifiedStep: {
    borderColor: cftSettings.colors.status.verified,
  },
  defaultStep: {
    borderColor: cftSettings.colors.status.default,
  },
  content: {
    boxSizing: 'border-box',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  controlsContainer: {
    position: 'absolute',
    width: 'auto',
    maxWidth: '100%',
    top: '100%',
    right: 0,
    left: 'auto',
    bottom: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 8,
    background: alpha(pgColorScheme.backgroundMediumGray, 0.9),
  },
  indicatorsContainer: {
    position: 'absolute',
    width: 'auto',
    height: 'auto',
    minHeight: '100%',
    top: 0,
    right: '100%',
    left: 'auto',
    bottom: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
});
