import React, { useState } from 'react';
import { Box } from '@mui/material';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { IntervalSelector, IntervalSelectorProps } from '../interval-selector/component';
import { PlanInterval } from '@priz/shared/src/models/workspace';

export const IntervalSelectorPopper: React.FC<IntervalSelectorProps> = ({ children, onPlanSelect, ...rest }) => {
  const [open, setOpen] = useState(false);

  const planSelectHandler = (planInterval: PlanInterval) => {
    if (onPlanSelect) onPlanSelect(planInterval);
    openStateChangeHandler(false);
  };

  const openStateChangeHandler = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  return (
    <PopperWrap open={open} containerProps={{ component: 'span' }} onOpenToggle={openStateChangeHandler}>
      {children}

      <Box>
        <IntervalSelector
          onPlanSelect={planSelectHandler}
          buttonsProps={{
            py: 0.5,
            minHeight: 0,
            width: 'auto',
          }}
          {...rest}
        />
      </Box>
    </PopperWrap>
  );
};
