import { FluxStandardAction } from 'flux-standard-action';
import { createPgAction } from '../../../shared/store/action-creator';

export enum PlaygroundActionType {
  EnableAuthenticationRequirement = 'playground/authentication/requirement/enable',
  DisableAuthenticationRequirement = 'playground/authentication/requirement/disable',
}

export type PlaygroundAction = FluxStandardAction<PlaygroundActionType, null>;

const enableAuthenticationRequirement = createPgAction(
  PlaygroundActionType.EnableAuthenticationRequirement,
  () => new Promise(resolve => resolve(null)),
);

const disableAuthenticationRequirement = createPgAction(
  PlaygroundActionType.DisableAuthenticationRequirement,
  () => new Promise(resolve => resolve(null)),
);

export const PlaygroundActions = {
  enableAuthenticationRequirement,
  disableAuthenticationRequirement,
};
