import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      padding: 0,
      overflow: 'hidden',
      height: '100%',
      background: 'transparent',
      borderRadius: 4,
    },

    [theme.breakpoints.down('sm')]: {
      dialogPaper: {
        maxHeight: 'none',
        width: '100%',
        margin: 0,
      },
    },
  }),
);
