import React from 'react';
import { UimPriority, UimTask } from '@priz/shared/src/models/tools/uim';
import { UimTaskSummaryRankingTable } from '../uim-task-summary-ranking-table/component';

interface UimToolUtilizationSummaryProps {
  tasks: UimTask[];
  utilizationId: number;
  projectId?: number;
  disabled?: boolean;
}

export const UimToolUtilizationSummary: React.FC<UimToolUtilizationSummaryProps> = ({
  tasks,
  utilizationId,
  projectId,
  disabled,
}) => {
  if (!tasks.length) return null;

  return (
    <>
      <UimTaskSummaryRankingTable
        tasks={tasks}
        utilizationId={utilizationId}
        priority={UimPriority.DoFirst}
        projectId={projectId}
        disabled={disabled}
      />
      <UimTaskSummaryRankingTable
        tasks={tasks}
        utilizationId={utilizationId}
        priority={UimPriority.DoLater}
        projectId={projectId}
        disabled={disabled}
      />
      <UimTaskSummaryRankingTable
        tasks={tasks}
        utilizationId={utilizationId}
        priority={UimPriority.Delegate}
        projectId={projectId}
        disabled={disabled}
      />
      <UimTaskSummaryRankingTable
        tasks={tasks}
        utilizationId={utilizationId}
        priority={UimPriority.Drop}
        projectId={projectId}
        disabled={disabled}
      />
      <UimTaskSummaryRankingTable
        tasks={tasks}
        utilizationId={utilizationId}
        priority={UimPriority.Undefined}
        projectId={projectId}
        disabled={disabled}
      />
    </>
  );
};
