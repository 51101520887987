import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';
import { alpha } from '@mui/material';

export const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    width: '100%',
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
  },
  productiveContainer: {
    borderColor: pgColorScheme.green,
  },
  providingContainer: {
    borderColor: pgColorScheme.blue,
  },
  correctiveContainer: {
    borderColor: pgColorScheme.orange,
  },
  metrologyContainer: {
    borderColor: pgColorScheme.purple,
  },
  productiveHeader: {
    backgroundColor: alpha(pgColorScheme.green, 0.2),
  },
  providingHeader: {
    backgroundColor: alpha(pgColorScheme.blue, 0.2),
  },
  correctiveHeader: {
    backgroundColor: alpha(pgColorScheme.orange, 0.2),
  },
  metrologyHeader: {
    backgroundColor: alpha(pgColorScheme.purple, 0.2),
  },
  contentContainer: {
    background: pgColorScheme.workbenchBackground,
  },
  centerWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
    boxSizing: 'border-box',
  },
});
