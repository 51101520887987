import { FluxStandardAction } from 'flux-standard-action';
import { createPgAction } from '../../../../shared/store/action-creator';

export enum PreloaderActionType {
  Show = 'preloader/show',
  Hide = 'preloader/hide',
}

export interface PreloaderActionPayload {
  loading: boolean;
}

export type PreloaderAction = FluxStandardAction<PreloaderActionType, PreloaderActionPayload>;

const show = createPgAction(PreloaderActionType.Show, (): Promise<void> => new Promise(resolve => resolve()));

const hide = createPgAction(PreloaderActionType.Hide, (): Promise<void> => new Promise(resolve => resolve()));

export const PreloaderActions = {
  show,
  hide,
};
