import React from 'react';
import { Network } from '../../lib/vis/esnext';
import { BoxProps } from '@mui/material';
import { CanvasControlButton } from '../canvas-controls/button/component';

import { ReactComponent as CameraIcon } from '../../assets/icons/center';

interface NetworkSetCenterButtonProps extends BoxProps {
  network?: Network;
  className?: string;
  id?: string;
}

export const NetworkSetCenterButton: React.FC<NetworkSetCenterButtonProps> = ({ network, id }) => {
  const setCenter = () => {
    if (network) network.fit();
  };

  return (
    <CanvasControlButton id={id} tooltip={'Recenter'} onClick={setCenter}>
      <CameraIcon />
    </CanvasControlButton>
  );
};
