import React, { useEffect, useState } from 'react';
import { Button, Snackbar } from '@mui/material';
import { Trans } from 'react-i18next';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';

export const VersionChecker: React.FC = () => {
  const [snackbarIsOpen, setSnackbarIsOpen] = useState(false);

  useEffect(() => {
    checkVersion();

    const interval = setInterval(() => {
      checkVersion();
    }, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const checkVersion = () => {
    const versionRequest = fetch(`/version.txt?${Math.random()}`);

    versionRequest
      .then(response => response.text())
      .then(version => {
        if (version) {
          const latestVersion = version.toString().trim();
          const currentVersion = LocalStorageService.getItem(LocalStorageKey.Version);

          if (!currentVersion) {
            LocalStorageService.setItem(LocalStorageKey.Version, latestVersion);
          } else if (latestVersion !== currentVersion) {
            setSnackbarIsOpen(true);
          }
        }
      })
      .catch(() => {
        console.error('Failed to check version');
      });
  };

  const handleRefresh = () => {
    LocalStorageService.removeItem(LocalStorageKey.Version);
    window.location.reload();
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarIsOpen}
        message={<Trans>A new version is available</Trans>}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleRefresh}>
              <Trans>REFRESH</Trans>
            </Button>
          </React.Fragment>
        }
      />
    </div>
  );
};
