import axios, { AxiosResponse } from 'axios';
import { ToolType } from '@priz/shared/src/models/tools';
import { Idea, IdeaUpdateCommand, IIdea } from '@priz/shared/src/models/idea/idea';

const loadIdeasByProjectId = (projectId: number): Promise<IIdea[]> =>
  axios.get(`/v1/api/<ws-id>/project/${projectId}/ideas`).then((response: AxiosResponse<IIdea[]>) => response.data);

export interface IdeaCommand {
  content: string;
  sourceId?: string;
  sourceToolType?: ToolType;
  sourceToolUtilizationId?: number;
}

const create = (projectId: number, command: IdeaCommand): Promise<IIdea> =>
  axios
    .post(`/v1/api/<ws-id>/project/${projectId}/idea`, command)
    .then((response: AxiosResponse<IIdea>) => response.data);

const update = (projectId: number, ideaId: number, command: IdeaUpdateCommand): Promise<IIdea> =>
  axios
    .put(`/v1/api/<ws-id>/project/${projectId}/idea/${ideaId}`, command)
    .then((response: AxiosResponse<IIdea>) => response.data);

const selectAsWinning = (idea: Idea, isWinning: boolean): Promise<IIdea> =>
  axios
    .put(`/v1/api/<ws-id>/project/${idea.project.id}/idea/${idea.id}/set-winning`, {
      winning: isWinning,
    })
    .then((response: AxiosResponse<IIdea>) => response.data);

const doDelete = (projectId: number, ideaId: number): Promise<any> =>
  axios.delete(`/v1/api/<ws-id>/project/${projectId}/idea/${ideaId}`);

export const IdeaApi = {
  create,
  delete: doDelete,
  loadIdeasByProjectId,
  selectAsWinning,
  update,
};
