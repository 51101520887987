import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UimTask, UimUtilization } from '@priz/shared/src/models/tools/uim';
import { UimUtilizationActions } from '../../../uim/store/actions/uim-utilization.actions';
import { UimViewer } from '@priz/shared/src/components/tools-viewers';
import { UimTaskSelector } from '../../../uim/store/selectors/uim-task.selectors';

export const UimReportResult: React.FC<{ tool: UimUtilization }> = ({ tool }) => {
  const dispatch = useDispatch();

  const tasks: UimTask[] = useSelector(UimTaskSelector.getAllByUtilizationId(tool.id));

  useEffect(() => {
    if (tool?.project?.id && tool?.id) {
      dispatch(UimUtilizationActions.loadTasks(tool.id));
    }
  }, []);

  return <UimViewer tasks={tasks} />;
};
