export enum PlanLevel {
  Personal = 'Personal',
  PersonalPro = 'PersonalPro',
  Team = 'Team',
  Organization = 'Organization',
  Enterprise = 'Enterprise',
}

export const AllPlanLevels: PlanLevel[] = [
  PlanLevel.Personal,
  PlanLevel.PersonalPro,
  PlanLevel.Team,
  //PlanLevel.Organization,
  PlanLevel.Enterprise,
];

export const GroupPlanLevels: PlanLevel[] = [
  PlanLevel.Team,
  //PlanLevel.Organization,
  PlanLevel.Enterprise,
];

export const OrderedPlanLevelMap = {
  [PlanLevel.Personal]: 0,
  [PlanLevel.PersonalPro]: 1,
  [PlanLevel.Team]: 2,
  [PlanLevel.Organization]: 3,
  [PlanLevel.Enterprise]: 4,
};
