import React from 'react';
import { Grid } from '@mui/material';
import { OperationMerit } from '@priz/shared/src/models/tools/sfm';
import { ArrowForwardRounded } from '@mui/icons-material';
import { pgColorScheme } from '@priz/shared/src/theme';
import { OperationMeritLabel } from '@priz/shared/src/components/operation-merit-label/component';

interface MeritChangeResultProps {
  oldMerit: OperationMerit;
  newMerit: OperationMerit;
}

export const MeritChangeResult: React.FC<MeritChangeResultProps> = ({ oldMerit, newMerit }) => {
  return (
    <Grid container spacing={1} alignItems={'center'}>
      <Grid item>
        <OperationMeritLabel operationMerit={oldMerit} showNotDefined />
      </Grid>

      <Grid item>
        <ArrowForwardRounded fontSize={'small'} sx={{ display: 'block', color: pgColorScheme.gray500 }} />
      </Grid>

      <Grid item>
        <OperationMeritLabel operationMerit={newMerit} showNotDefined />
      </Grid>
    </Grid>
  );
};
