import { InputValueSuggesterType, ProjectWizardInput, ProjectWizardResult } from './model/inputs';

export const inputsData: ProjectWizardInput[] = [
  {
    name: 'industry',
    conditions: [],
    options: [
      { text: 'Advertising', value: 'Advertising' },
      { text: 'Aerospace', value: 'Aerospace' },
      { text: 'Agriculture and Farming', value: 'Agriculture and Farming' },
      { text: 'Antiques and Collectibles', value: 'Antiques and Collectibles' },
      { text: 'Art', value: 'Art' },
      { text: 'Automotive', value: 'Automotive' },
      { text: 'Banking & Financial', value: 'Banking & Financial' },
      { text: 'Biotechnology', value: 'Biotechnology' },
      { text: 'Building Materials', value: 'Building Materials' },
      { text: 'Chemical', value: 'Chemical' },
      { text: 'Cleaning', value: 'Cleaning' },
      { text: 'Hardware', value: 'Hardware' },
      { text: 'Construction', value: 'Construction' },
      { text: 'Consulting', value: 'Consulting' },
      { text: 'Consumer Electronics', value: 'Consumer Electronics' },
      { text: 'Consumer Goods', value: 'Consumer Goods' },
      { text: 'Healthcare', value: 'Healthcare' },
      { text: 'Defense', value: 'Defense' },
      { text: 'Education and Training', value: 'Education and Training' },
      { text: 'Energy', value: 'Energy' },
      { text: 'Entertainment', value: 'Entertainment' },
      { text: 'Environmental', value: 'Environmental' },
      { text: 'Events', value: 'Events' },
      { text: 'Fashion', value: 'Fashion' },
      { text: 'Food & Beverage', value: 'Food & Beverage' },
      { text: 'Healthcare', value: 'Healthcare' },
      { text: 'Hospitality', value: 'Hospitality' },
      { text: 'Information Technology', value: 'Information Technology' },
      { text: 'Logistics and Supply Chain', value: 'Logistics and Supply Chain' },
      { text: 'Machinery and Heavy Equipment', value: 'Machinery and Heavy Equipment' },
      { text: 'Manufacturing', value: 'Manufacturing' },
      { text: 'Medical Devices and Supplies', value: 'Medical Devices and Supplies' },
      { text: 'Mining', value: 'Mining' },
      { text: 'Pharmaceutical', value: 'Pharmaceutical' },
      { text: 'Publishing', value: 'Publishing' },
      { text: 'Real Estate', value: 'Real Estate' },
      { text: 'Restaurants and Food Services', value: 'Restaurants and Food Services' },
      { text: 'Retail', value: 'Retail' },
      { text: 'Security', value: 'Security' },
      { text: 'Software', value: 'Software' },
      { text: 'Space', value: 'Space' },
      { text: 'Sports and Fitness', value: 'Sports and Fitness' },
      { text: 'Telecom', value: 'Telecom' },
      { text: 'Transportation', value: 'Transportation' },
      { text: 'Travel & Tourism', value: 'Travel & Tourism' },
      { text: 'Utilities', value: 'Utilities' },
      { text: 'Video Games', value: 'Video Games' },
      { text: 'Waste Management', value: 'Waste Management' },
      { text: 'Other', value: 'Other' },
    ],
    title: 'Industry',
    subtitle: 'Please, choose the industry you are working in.',
    suggester: InputValueSuggesterType.Industry,
  },
  {
    name: 'area',
    conditions: [{ inputName: 'industry' }],
    options: [
      { text: 'Process', value: 'Process' },
      { text: 'Maintenance', value: 'Maintenance' },
      { text: 'Reliability', value: 'Reliability' },
      { text: 'Production', value: 'Production' },
      { text: 'Human Resource', value: 'Human Resource' },
      { text: 'Finance', value: 'Finance' },
      { text: 'Supply chain', value: 'Supply chain' },
      { text: 'Customer support', value: 'Customer support' },
      { text: 'Legal', value: 'Legal' },
      { text: 'R&D', value: 'R&D' },
      { text: 'Quality', value: 'Quality' },
      { text: 'Regulatory affairs', value: 'Regulatory affairs' },
      { text: 'Sales', value: 'Sales' },
      { text: 'Engineering', value: 'Engineering' },
      { text: 'Safety', value: 'Safety' },
      { text: 'Other', value: 'Other' },
    ],
    title: 'Area of Responsibility/Department',
    subtitle: 'Select your area of responsibility or department you are part of.',
  },
  {
    name: 'goal',
    conditions: [{ inputName: 'area' }],
    options: [
      { text: 'Improve yield', value: 'Improve yield' },
      { text: 'Reduce cost', value: 'Reduce cost' },
      { text: 'Reduce the number of defects', value: 'Reduce the number of defects' },
      { text: 'Improve reliability', value: 'Improve reliability' },
      { text: 'Reduce maintenance time', value: 'Reduce maintenance time' },
      { text: 'Improve tools availability', value: 'Improve tools availability' },
      { text: 'Find new customers', value: 'Find new customers' },
      { text: 'Reduce turnover', value: 'Reduce turnover' },
      { text: 'Reduce the number of bugs', value: 'Reduce the number of bugs' },
      { text: 'New product implementation', value: 'New product implementation' },
      { text: 'Process improvement', value: 'Process improvement' },
      { text: 'Other', value: 'Other' },
    ],
    title: 'Current goal',
    subtitle: 'What is the overall goal of this project?',
  },
];

export const ProjectWizardResults: ProjectWizardResult[] = [];
