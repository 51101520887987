import React, { useEffect, useMemo, useState } from 'react';
import { Backdrop, Box, IconButton } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { useStyles } from './styles';
import DocViewer, {
  BMPRenderer,
  HTMLRenderer,
  ImageProxyRenderer,
  JPGRenderer,
  MSDocRenderer,
  MSGRenderer,
  PDFRenderer,
  PNGRenderer,
  TIFFRenderer,
  TXTRenderer,
} from 'react-doc-viewer';
import { WebPRenderer } from '../file-renderers';

interface AttachmentViewerProps {
  isOpen: boolean;
  extension: string;
  signedUrl: string;
  onClose?: () => void;
}

export const AttachmentViewer: React.FC<AttachmentViewerProps> = ({ isOpen, onClose, extension, signedUrl }) => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.addEventListener('keydown', handleClose);

    return () => {
      document.removeEventListener('keydown', handleClose);
    };
  }, []);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setOpen(false);
    }
  };

  const memorizedViewer = useMemo(() => {
    return (
      <DocViewer
        className={styles.fileViewer}
        pluginRenderers={[
          BMPRenderer,
          HTMLRenderer,
          ImageProxyRenderer,
          JPGRenderer,
          MSDocRenderer,
          MSGRenderer,
          PDFRenderer,
          PNGRenderer,
          TIFFRenderer,
          TXTRenderer,
          WebPRenderer,
        ]}
        documents={[{ uri: signedUrl, fileType: extension }]}
        config={{
          header: {
            disableHeader: true,
          },
        }}
      />
    );
  }, []);

  return (
    <Backdrop open={open} sx={{ zIndex: theme => theme.zIndex.drawer + 1, pointerEvents: open ? 'auto' : 'none' }}>
      <Box className={styles.backdropContent} p={5} pt={6}>
        <div className={styles.closeArea} onClick={handleClose} />

        <Box className={styles.closeButtonContainer} pt={1} pr={5} sx={{ zIndex: theme => theme.zIndex.drawer }}>
          <IconButton size={'small'} className={styles.closeButton} onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </Box>

        <div className={styles.fileViewerContainer}>{open && memorizedViewer}</div>
      </Box>
    </Backdrop>
  );
};
