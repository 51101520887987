import { ProjectMetaData } from '@priz/shared/src/models/project';
import { AssistanceType, CompletenessFeedback } from '../../assistant/store/model';
import { projectDataCompletenessValuesMap } from '../store/model';

interface ProblemStatementStepResolverProps {
  step: number;
  isProjectBackgroundDefined: boolean;
  isCurrentSituationDefined: boolean;
  isDisadvantagesDefined: boolean;
  isIdealFinalResultDefined: boolean;
  isGapsDefined: boolean;
  isProblemStatementDefined: boolean;
  isProjectBackgroundCompleted: boolean;
}

interface ProblemStatementStepResolverResult {
  isInputDisabled: boolean;
  isHintDisabled: boolean;
  isFeedbackDisabled: boolean;
  hintButtonTooltip: string;
  feedbackButtonTooltip: string;
  progressButtonTooltip: string;
}

const validatorMessagesMap = {
  required: {
    projectBackground: 'Project background is required',
    currentSituation: 'Current situation description is required',
    disadvantages: 'Disadvantages description is required',
    idealFinalResult: 'Ideal final result description is required',
    gaps: 'Gaps description is required',
    problemStatement: 'Problem statement description is required',
  },
  completeness: {
    projectBackground: `Project background must have at least ${projectDataCompletenessValuesMap.blue}% completeness`,
  },
};

export const resolveProblemStatementStepProps = (
  props: ProblemStatementStepResolverProps,
): ProblemStatementStepResolverResult => {
  const {
    step,
    isProjectBackgroundDefined,
    isCurrentSituationDefined,
    isDisadvantagesDefined,
    isIdealFinalResultDefined,
    isGapsDefined,
    isProblemStatementDefined,
    isProjectBackgroundCompleted,
  } = props || {};

  const result: ProblemStatementStepResolverResult = {
    isInputDisabled: false,
    isHintDisabled: false,
    isFeedbackDisabled: false,
    hintButtonTooltip: '',
    feedbackButtonTooltip: '',
    progressButtonTooltip: '',
  };

  if (step < 1 || step > 5) {
    result.isInputDisabled = true;
    result.isHintDisabled = true;
    result.isFeedbackDisabled = true;
    return result;
  }

  const hintsValidatorArray = [
    {
      rules: [isProjectBackgroundDefined, isProjectBackgroundCompleted],
      messages: [validatorMessagesMap.required.projectBackground, validatorMessagesMap.completeness.projectBackground],
    },
    {
      rules: [isCurrentSituationDefined],
      messages: [validatorMessagesMap.required.currentSituation],
    },
    {
      rules: [isDisadvantagesDefined],
      messages: [validatorMessagesMap.required.disadvantages],
    },
    {
      rules: [isIdealFinalResultDefined],
      messages: [validatorMessagesMap.required.idealFinalResult],
    },
    {
      rules: [isGapsDefined],
      messages: [validatorMessagesMap.required.gaps],
    },
  ];

  const feedbackValidatorArray = [
    {
      rules: [isCurrentSituationDefined],
      messages: [validatorMessagesMap.required.currentSituation],
    },
    {
      rules: [isDisadvantagesDefined],
      messages: [validatorMessagesMap.required.disadvantages],
    },
    {
      rules: [isIdealFinalResultDefined],
      messages: [validatorMessagesMap.required.idealFinalResult],
    },
    {
      rules: [isGapsDefined],
      messages: [validatorMessagesMap.required.gaps],
    },
    {
      rules: [isProblemStatementDefined],
      messages: [validatorMessagesMap.required.problemStatement],
    },
  ];

  const inputsValidatorArray = [
    [],
    [isCurrentSituationDefined],
    [isDisadvantagesDefined],
    [isIdealFinalResultDefined],
    [isGapsDefined],
  ];

  result.feedbackButtonTooltip = [
    'Current situation feedback',
    'Disadvantages feedback',
    'Ideal final result feedback',
    'Gaps feedback',
    'Problem statement feedback',
  ][step - 1];

  let hintStepsCounter = 0;

  while (hintStepsCounter < step) {
    const errorIndex = hintsValidatorArray[hintStepsCounter].rules.indexOf(false);

    if (errorIndex !== -1) {
      result.isHintDisabled = true;
      result.hintButtonTooltip = hintsValidatorArray[hintStepsCounter].messages[errorIndex];
      break;
    }

    hintStepsCounter++;
  }

  const feedbackErrorIndex = feedbackValidatorArray[step - 1].rules.indexOf(false);

  if (feedbackErrorIndex !== -1) {
    result.isFeedbackDisabled = true;
    result.feedbackButtonTooltip = feedbackValidatorArray[step - 1].messages[feedbackErrorIndex];
  }

  if (inputsValidatorArray[step - 1].indexOf(false) !== -1) {
    result.isInputDisabled = true;
  }

  return result;
};

export const resolveProjectMetaDataUpdate = (
  completenessFeedback: CompletenessFeedback,
  assistanceType: AssistanceType,
): ProjectMetaData => {
  const { completeness, explanation } = completenessFeedback || {};
  const metaData: ProjectMetaData = {};

  if (assistanceType === AssistanceType.AskProjectDescriptionFeedback) {
    metaData.projectBackgroundExplanation = explanation;
    metaData.projectBackgroundCompleteness = completeness;
  }

  if (assistanceType === AssistanceType.AskCurrentSituationFeedback) {
    metaData.currentSituationExplanation = explanation;
    metaData.currentSituationCompleteness = completeness;
  }

  if (assistanceType === AssistanceType.AskDisadvantagesFeedback) {
    metaData.disadvantagesExplanation = explanation;
    metaData.disadvantagesCompleteness = completeness;
  }

  if (assistanceType === AssistanceType.AskProblemStatementFeedback) {
    metaData.problemStatementExplanation = explanation;
    metaData.problemStatementCompleteness = completeness;
  }

  if (assistanceType === AssistanceType.AskIdealFinalResultFeedback) {
    metaData.idealFinalResultExplanation = explanation;
    metaData.idealFinalResultCompleteness = completeness;
  }

  if (assistanceType === AssistanceType.AskGapsFeedback) {
    metaData.gapsExplanation = explanation;
    metaData.gapsCompleteness = completeness;
  }

  return metaData;
};
