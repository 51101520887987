import { SfmRankMap } from '../../models/tools/sfm';

export const getMaxSfmRanks = (
  ranksMap: SfmRankMap,
): {
  maxSfmFunctionalRank: number;
  maxSfmProblematicRank: number;
} => {
  let maxSfmFunctionalRank = 0;
  let maxSfmProblematicRank = 0;

  Object.values(ranksMap || {}).forEach(rank => {
    if (rank.functionalRank > maxSfmFunctionalRank) {
      maxSfmFunctionalRank = rank.functionalRank;
    }

    if (rank.problematicRank > maxSfmProblematicRank) {
      maxSfmProblematicRank = rank.problematicRank;
    }
  });

  return {
    maxSfmFunctionalRank,
    maxSfmProblematicRank,
  };
};
