import React, { useEffect, useState } from 'react';
import { Box, BoxProps, Collapse, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import {
  PMapContributedPerception,
  PMapContributedPerceptionStatus,
} from '@priz/shared/src/models/tools/pmap/pmap-contributed-perception';
import { format } from 'date-fns';
import { ContributedPerceptionAvatar } from '../contributed-perception-avatar/component';
import { Trans } from 'react-i18next';
import { BlockRounded, CheckCircleOutlineRounded, DoneRounded, CloseRounded, InfoOutlined } from '@mui/icons-material';
import { useStyles } from './styles';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { FieldTitle } from '@priz/shared/src/components/form-elements';

interface ContributedPerceptionCardProps extends BoxProps {
  projectId: number;
  perception: PMapContributedPerception;
  onAccept?: (text: string, perceptionId: string) => void;
  onReject?: (perceptionId: string) => void;
}

const checkIsResolved = (status: PMapContributedPerceptionStatus): boolean => {
  return [PMapContributedPerceptionStatus.Accepted, PMapContributedPerceptionStatus.Rejected].includes(status);
};

export const ContributedPerceptionCard: React.FC<ContributedPerceptionCardProps> = ({
  projectId,
  perception,
  onAccept,
  onReject,
  ...rest
}) => {
  const styles = useStyles();

  const [status, setStatus] = useState(perception.status);

  useEffect(() => {
    let timeout = null;

    if (perception.status !== status) {
      timeout = setTimeout(() => {
        setStatus(perception.status);
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
      timeout = null;
    };
  }, [perception.status]);

  const acceptHandler = () => {
    if (onAccept) {
      onAccept(perception.description, perception.id);
    }
  };

  const rejectHandler = () => {
    if (onReject) {
      onReject(perception.id);
    }
  };

  return (
    <Collapse in={!checkIsResolved(status)} unmountOnExit>
      <Paper variant={'elevation'} elevation={1} component={Box} p={2} className={styles.root} {...rest}>
        {checkIsResolved(perception.status) && (
          <Box className={styles.overlay}>
            <Grid container alignItems={'center'} justifyContent={'center'} spacing={2}>
              <Grid item>
                {perception.status === PMapContributedPerceptionStatus.Accepted && (
                  <CheckCircleOutlineRounded sx={{ display: 'block' }} fontSize={'large'} color={'success'} />
                )}

                {perception.status === PMapContributedPerceptionStatus.Rejected && (
                  <BlockRounded sx={{ display: 'block' }} fontSize={'large'} color={'error'} />
                )}
              </Grid>

              <Grid item>
                <Typography variant={'h6'}>
                  <Trans>{perception.status}</Trans>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}

        <Grid container spacing={2} className={styles.contentContainer}>
          <Grid item xs={12} md={6}>
            <Typography variant={'body1'}>{perception.description}</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container columnSpacing={2} justifyContent={'flex-end'} alignItems={'center'}>
              <Grid item>
                <ContributedPerceptionAvatar perception={perception} showEmail={false} />
              </Grid>

              <Grid item>
                <Grid container>
                  <Grid item>
                    <PopperWrap>
                      <IconButton>
                        <InfoOutlined />
                      </IconButton>

                      <Box>
                        <FieldTitle text={'Email'} mb={0} />

                        {perception.userEmail && (
                          <Typography variant={'subtitle2'} component={Box} mb={2}>
                            {perception.userEmail}
                          </Typography>
                        )}

                        <FieldTitle text={'Date'} mb={0} />

                        <Typography variant={'subtitle2'} component={Box}>
                          {format(new Date(perception.dateCreated), 'MMM d yyyy HH:mm')}
                        </Typography>
                      </Box>
                    </PopperWrap>
                  </Grid>

                  <Grid item>
                    <Tooltip title={<Trans>Accept</Trans>} placement={'top'} arrow disableInteractive>
                      <div>
                        <IconButton onClick={acceptHandler} color={'primary'}>
                          <DoneRounded />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </Grid>

                  <Grid item>
                    <Tooltip title={<Trans>Reject</Trans>} placement={'top'} arrow disableInteractive>
                      <div>
                        <IconButton onClick={rejectHandler} color={'error'}>
                          <CloseRounded />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Collapse>
  );
};
