import axios, { AxiosResponse } from 'axios';
import { PfmUtilization } from '@priz/shared/src/models/tools/pfm';

const update = (utilizationId: number, data: Partial<PfmUtilization>): Promise<PfmUtilization> =>
  axios
    .put(`/v1/api/<ws-id>/team/<t-id>/pfm/${utilizationId}`, data)
    .then((response: AxiosResponse<PfmUtilization>) => response.data);

const doDelete = (utilizationId: number): Promise<PfmUtilization> =>
  axios
    .delete(`/v1/api/<ws-id>/team/<t-id>/pfm/${utilizationId}`)
    .then((response: AxiosResponse<PfmUtilization>) => response.data);

export const PfmUtilizationApi = {
  update,
  delete: doDelete,
};
