import { FluxStandardAction } from 'flux-standard-action';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { ToolType } from '@priz/shared/src/models/tools';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { createPgAction } from '../../../shared/store/action-creator';
import { FiveWhysUtilizationApi } from '../../service';
import { FiveWhysUtilization, IFiveWhysUtilization } from '@priz/shared/src/models/tools/five-whys';
import { NavigateFunction } from 'react-router-dom';
import { ToolsUtils } from '../../../tools/utils';

export enum FiveWhysUtilizationActionType {
  Update = 'five-whys/update',
  Delete = 'five-whys/delete',
}

type FiveWhysUtilizationPayload = IFiveWhysUtilization;

interface FiveWhysUtilizationMeta {
  projectId?: number;
  utilizationId?: number;
  navigate?: NavigateFunction;
}

export type FiveWhysUtilizationAction = FluxStandardAction<string, FiveWhysUtilizationPayload, FiveWhysUtilizationMeta>;

const update = createPgAction(
  FiveWhysUtilizationActionType.Update,
  FiveWhysUtilizationApi.update,
  (utilization: FiveWhysUtilization, projectId: number) => ({ projectId, utilization }),
);

const deleteFiveWhysUtilization = createPgAction(
  FiveWhysUtilizationActionType.Delete,
  FiveWhysUtilizationApi.delete,
  (utilizationId: number, projectId?: number, navigate?: NavigateFunction) => ({ projectId, utilizationId, navigate }),
  (_, meta: FiveWhysUtilizationMeta) => {
    AnalyticsService.track(AnalyticsEvent.TOOL_DELETED, {
      project_id: meta.projectId,
      tool_type: ToolType.FIVE_WHYS,
      utilization_id: meta.utilizationId,
    });

    if (meta.navigate) {
      meta.navigate(ToolsUtils.resolveListPath(meta.projectId));
    }
  },
);

export const FiveWhysUtilizationActions = {
  deleteFiveWhysUtilization,
  update,
};
