import React from 'react';
import { Trans } from 'react-i18next';
import { Link, Typography } from '@mui/material';

interface PoliciesAgreementProps {
  variant: 'sign-up' | 'update';
}

export const PoliciesAgreement: React.FC<PoliciesAgreementProps> = ({ variant }) => {
  const termsHref = 'https://www.priz.guru/terms/';
  const privacyPolicyHref = 'https://www.priz.guru/privacy-policy/';

  if (variant === 'update') {
    return (
      <>
        <Trans>Our</Trans>{' '}
        <Link href={privacyPolicyHref} target={'_blank'} display={'inline-block'}>
          <Trans>Privacy Policy</Trans>
        </Link>{' '}
        <Trans>and/or</Trans>{' '}
        <Link href={termsHref} target={'_blank'} display={'inline-block'}>
          <Trans>Terms of Service</Trans>
        </Link>{' '}
        <Trans>have been recently updated. Please, review and accept the changes to continue.</Trans>
      </>
    );
  }

  if (variant === 'sign-up') {
    return (
      <Typography variant={'body2'} component={'div'} color={'text_color.light'} textAlign={'center'}>
        <Trans>By signing up, you agree to our</Trans>{' '}
        <Link href={privacyPolicyHref} target={'_blank'} display={'inline-block'}>
          <Trans>Privacy Policy</Trans>
        </Link>{' '}
        <Trans>and</Trans>{' '}
        <Link href={termsHref} target={'_blank'} display={'inline-block'}>
          <Trans>Terms of Service</Trans>
        </Link>
        .
      </Typography>
    );
  }

  return null;
};
