import { handleActions, combineActions } from 'redux-actions';
import { toStarted, toFailed, toSuccess } from '../../../shared/store/action-creator';
import { SfmUtilizationAction, SfmUtilizationActionType } from '../actions';
import {
  DefaultSfmResultCollection,
  ProblematicComponentPreview,
  SfmComponentImprovement,
  SfmComponentImprovementMap,
  SfmComponentsEliminationEffectMap,
  SfmResultCollection,
  SfmResultStatuses,
} from '../model';
import {
  CollectionStatusesService,
  SharedUpdateStatusesProps,
} from '@priz/shared/src/services/statuses/collection-statuses.service';
import { OptionalComponentRankMap, SfmRank, SfmRankMap, SfmVersionsRanksMap } from '@priz/shared/src/models/tools/sfm';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';

const resolveVersionStatuses = (
  state: SfmResultCollection,
  action: SfmUtilizationAction,
  statuses: Partial<EntityCollectionStatus>,
): SfmResultStatuses => {
  const versionId = action.meta.versionId;

  return {
    ...state.statuses,
    byVersionId: {
      ...state.statuses.byVersionId,
      [versionId]: {
        ...(state.statuses.byVersionId[versionId] || {}),
        ...statuses,
      },
    },
  };
};

const resolveNodeStatuses = (
  state: SfmResultCollection,
  action: SfmUtilizationAction,
  statuses: Partial<EntityCollectionStatus>,
): SfmResultStatuses => {
  const nodeId = action.meta.nodeId;

  return {
    ...state.statuses,
    byNodeId: {
      ...state.statuses.byNodeId,
      [nodeId]: {
        ...(state.statuses.byNodeId[nodeId] || {}),
        ...statuses,
      },
    },
  };
};

const resolveUtilizationStatuses = (
  state: SfmResultCollection,
  action: SfmUtilizationAction,
  statuses: SharedUpdateStatusesProps,
): SfmResultStatuses => {
  const utilizationId = action.meta.utilizationId;

  return {
    ...state.statuses,
    byUtilizationId: {
      ...state.statuses.byUtilizationId,
      [utilizationId]: CollectionStatusesService.updateStatusesCollection({
        collection: state.statuses.byUtilizationId[utilizationId] || {},
        ...statuses,
      }),
    },
  };
};

const setComponentsImprovements = (
  state: SfmResultCollection,
  action: SfmUtilizationAction,
  improvements: SfmComponentImprovement[],
  statuses: SfmResultStatuses,
): SfmResultCollection => {
  const utilizationId = action.meta.utilizationId;
  const versionId = action.meta.versionId;
  const improvementsMap = improvements.reduce((map: SfmComponentImprovementMap, item) => {
    map[item.componentId] = item;
    return map;
  }, {});

  return {
    ...state,
    componentsImprovements: {
      byUtilizationId: {
        ...(state.componentsImprovements.byUtilizationId || {}),
        [utilizationId]: {
          ...(state.componentsImprovements?.byUtilizationId?.[utilizationId] || {}),
          byVersionId: {
            ...(state.componentsImprovements?.byUtilizationId?.[utilizationId]?.byVersionId || {}),
            [versionId]: {
              ...(state.componentsImprovements?.byUtilizationId?.[utilizationId]?.byVersionId?.[versionId] || {}),
              ...improvementsMap,
            },
          },
        },
      },
    },
    statuses,
  };
};

export const sfmResultReducers = handleActions(
  {
    [combineActions(
      toStarted(SfmUtilizationActionType.CalcRank),
      toStarted(SfmUtilizationActionType.CalcRanks),
      toStarted(SfmUtilizationActionType.CalcVersionRanks),
      toStarted(SfmUtilizationActionType.CalcComponentsRanks),
    )]: (state: SfmResultCollection, action: SfmUtilizationAction): SfmResultCollection =>
      CollectionStatusesService.setStatusesByUtilizationId(state, action.meta.utilizationId, {
        loading: true,
        loaded: false,
      }),

    [combineActions(
      toFailed(SfmUtilizationActionType.CalcRank),
      toFailed(SfmUtilizationActionType.CalcRanks),
      toFailed(SfmUtilizationActionType.CalcVersionRanks),
      toFailed(SfmUtilizationActionType.CalcComponentsRanks),
    )]: (state: SfmResultCollection, action: SfmUtilizationAction): SfmResultCollection =>
      CollectionStatusesService.setStatusesByUtilizationId(state, action.meta.utilizationId, {
        loading: false,
        loaded: false,
        error: true,
      }),

    [toSuccess(SfmUtilizationActionType.CalcRank)]: (
      state: SfmResultCollection,
      action: SfmUtilizationAction,
    ): SfmResultCollection => ({
      ...state,
      sfmRanks: {
        byPfmId: state.sfmRanks.byPfmId,
        byUtilizationId: {
          ...state.sfmRanks.byUtilizationId,
          [action.meta.utilizationId]: action.payload as SfmRank,
        },
      },
      statuses: {
        ...state.statuses,
        ...CollectionStatusesService.resolveStatusesByUtilizationId(state.statuses, action.meta.pfmId, {
          loading: false,
          loaded: true,
          error: false,
        }),
      },
    }),

    [toSuccess(SfmUtilizationActionType.CalcRanks)]: (
      state: SfmResultCollection,
      action: SfmUtilizationAction,
    ): SfmResultCollection => ({
      ...state,
      sfmRanks: {
        byPfmId: {
          ...state.sfmRanks.byPfmId,
          [action.meta.pfmId]: action.payload as SfmRankMap,
        },
        byUtilizationId: state.sfmRanks.byUtilizationId,
      },
      statuses: {
        ...state.statuses,
        ...CollectionStatusesService.resolveStatusesByUtilizationId(state.statuses, action.meta.pfmId, {
          loading: false,
          loaded: true,
          error: false,
        }),
      },
    }),

    [toSuccess(SfmUtilizationActionType.CalcVersionRanks)]: (
      state: SfmResultCollection,
      action: SfmUtilizationAction,
    ): SfmResultCollection => ({
      ...state,
      sfmVersionsRanks: {
        byUtilizationId: {
          ...state.sfmVersionsRanks.byUtilizationId,
          [action.meta.utilizationId]: action.payload as SfmVersionsRanksMap,
        },
      },
      statuses: {
        ...state.statuses,
        ...CollectionStatusesService.resolveStatusesByUtilizationId(state.statuses, action.meta.utilizationId, {
          loading: false,
          loaded: true,
          error: false,
        }),
      },
    }),

    [toSuccess(SfmUtilizationActionType.CalcComponentsRanks)]: (
      state: SfmResultCollection,
      action: SfmUtilizationAction,
    ): SfmResultCollection => ({
      ...state,
      componentsRanks: {
        byUtilizationId: {
          ...state.componentsRanks.byUtilizationId,
          [action.meta.utilizationId]: action.payload as OptionalComponentRankMap,
        },
      },
      statuses: {
        ...state.statuses,
        ...CollectionStatusesService.resolveStatusesByUtilizationId(state.statuses, action.meta.utilizationId, {
          loading: false,
          loaded: true,
          error: false,
        }),
      },
    }),

    // CalcVersionComponentsRanks, GetComponentsEliminationEffect, GetProblematicComponents, GetComponentsImprovements

    [combineActions(
      toStarted(SfmUtilizationActionType.CalcVersionComponentsRanks),
      toStarted(SfmUtilizationActionType.GetComponentsEliminationEffect),
      toStarted(SfmUtilizationActionType.GetProblematicComponents),
      toStarted(SfmUtilizationActionType.GetComponentsImprovements),
    )]: (state: SfmResultCollection, action: SfmUtilizationAction): SfmResultCollection => ({
      ...state,
      statuses: resolveVersionStatuses(state, action, {
        loaded: false,
        loading: true,
      }),
    }),

    [combineActions(
      toFailed(SfmUtilizationActionType.CalcVersionComponentsRanks),
      toFailed(SfmUtilizationActionType.GetComponentsEliminationEffect),
      toFailed(SfmUtilizationActionType.GetProblematicComponents),
      toFailed(SfmUtilizationActionType.GetComponentsImprovements),
    )]: (state: SfmResultCollection, action: SfmUtilizationAction): SfmResultCollection => ({
      ...state,
      statuses: resolveVersionStatuses(state, action, {
        loading: false,
        loaded: false,
        error: true,
      }),
    }),

    [toSuccess(SfmUtilizationActionType.CalcVersionComponentsRanks)]: (
      state: SfmResultCollection,
      action: SfmUtilizationAction,
    ): SfmResultCollection => ({
      ...state,
      versionComponentsRanks: {
        byVersionId: {
          ...state.versionComponentsRanks.byVersionId,
          [action.meta.versionId]: action.payload as OptionalComponentRankMap,
        },
      },
      statuses: resolveVersionStatuses(state, action, {
        loading: false,
        loaded: true,
        error: false,
      }),
    }),

    [toSuccess(SfmUtilizationActionType.GetComponentsEliminationEffect)]: (
      state: SfmResultCollection,
      action: SfmUtilizationAction,
    ): SfmResultCollection => ({
      ...state,
      componentsEliminationEffect: {
        byUtilizationId: {
          ...(state.componentsEliminationEffect.byUtilizationId || {}),
          [action.meta.utilizationId]: {
            ...(state.componentsEliminationEffect?.byUtilizationId?.[action.meta.utilizationId] || {}),
            byVersionId: {
              ...(state.componentsEliminationEffect?.byUtilizationId?.[action.meta.utilizationId]?.byVersionId || {}),
              [action.meta.versionId]: action.payload as SfmComponentsEliminationEffectMap,
            },
          },
        },
      },
      statuses: resolveVersionStatuses(state, action, {
        loading: false,
        loaded: true,
        error: false,
      }),
    }),

    [toSuccess(SfmUtilizationActionType.GetProblematicComponents)]: (
      state: SfmResultCollection,
      action: SfmUtilizationAction,
    ): SfmResultCollection => ({
      ...state,
      problematicComponents: {
        byUtilizationId: {
          ...(state.problematicComponents.byUtilizationId || {}),
          [action.meta.utilizationId]: {
            ...(state.problematicComponents?.byUtilizationId?.[action.meta.utilizationId] || {}),
            byVersionId: {
              ...(state.problematicComponents?.byUtilizationId?.[action.meta.utilizationId]?.byVersionId || {}),
              [action.meta.versionId]: action.payload as ProblematicComponentPreview[],
            },
          },
        },
      },
      statuses: resolveVersionStatuses(state, action, {
        loading: false,
        loaded: true,
        error: false,
      }),
    }),

    [toSuccess(SfmUtilizationActionType.GetComponentsImprovements)]: (
      state: SfmResultCollection,
      action: SfmUtilizationAction,
    ): SfmResultCollection =>
      setComponentsImprovements(
        state,
        action,
        action.payload as SfmComponentImprovement[],
        resolveVersionStatuses(state, action, {
          loading: false,
          loaded: true,
          error: false,
        }),
      ),

    // GetComponentImprovement

    [toStarted(SfmUtilizationActionType.GetComponentImprovement)]: (
      state: SfmResultCollection,
      action: SfmUtilizationAction,
    ): SfmResultCollection => ({
      ...state,
      statuses: resolveNodeStatuses(state, action, {
        loading: true,
      }),
    }),

    [toFailed(SfmUtilizationActionType.GetComponentImprovement)]: (
      state: SfmResultCollection,
      action: SfmUtilizationAction,
    ): SfmResultCollection => ({
      ...state,
      statuses: resolveNodeStatuses(state, action, {
        loading: false,
        loaded: false,
        error: true,
      }),
    }),

    [toSuccess(SfmUtilizationActionType.GetComponentImprovement)]: (
      state: SfmResultCollection,
      action: SfmUtilizationAction,
    ): SfmResultCollection =>
      setComponentsImprovements(
        state,
        action,
        [action.payload] as SfmComponentImprovement[],
        resolveNodeStatuses(state, action, {
          loading: false,
          loaded: true,
          error: false,
        }),
      ),

    //  UpdateComponentRecommendation

    [toStarted(SfmUtilizationActionType.UpdateComponentRecommendation)]: (
      state: SfmResultCollection,
      action: SfmUtilizationAction,
    ): SfmResultCollection => ({
      ...state,
      statuses: resolveUtilizationStatuses(state, action, {
        idsUpdate: [{ target: 'loadingIds', method: 'add', ids: [action.meta.nodeId] }],
      }),
    }),

    [toFailed(SfmUtilizationActionType.UpdateComponentRecommendation)]: (
      state: SfmResultCollection,
      action: SfmUtilizationAction,
    ): SfmResultCollection => ({
      ...state,
      statuses: resolveUtilizationStatuses(state, action, {
        idsUpdate: [
          { target: 'loadingIds', method: 'remove', ids: [action.meta.nodeId] },
          { target: 'errorIds', method: 'add', ids: [action.meta.nodeId] },
        ],
      }),
    }),

    [toSuccess(SfmUtilizationActionType.UpdateComponentRecommendation)]: (
      state: SfmResultCollection,
      action: SfmUtilizationAction,
    ): SfmResultCollection =>
      setComponentsImprovements(
        state,
        action,
        [action.payload] as SfmComponentImprovement[],
        resolveUtilizationStatuses(state, action, {
          idsUpdate: [
            { target: 'loadingIds', method: 'remove', ids: [action.meta.nodeId] },
            { target: 'errorIds', method: 'remove', ids: [action.meta.nodeId] },
          ],
        }),
      ),
  },

  DefaultSfmResultCollection,
);
