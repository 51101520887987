import { IToolUtilization, ToolUtilization } from '../index';

export enum PfmStepType {
  Productive = 'Productive',
  Providing = 'Providing',
  Corrective = 'Corrective',
  Metrology = 'Metrology',
}

export interface PfmStep {
  index: number;
  sfmId: number;
  type?: PfmStepType;
}

export interface PfmDiagramData {
  steps: PfmStep[];
}

export interface IPfmUtilization extends IToolUtilization {
  diagramData?: PfmDiagramData;
  flowFinished?: boolean;
}

export class PfmUtilization extends ToolUtilization implements IPfmUtilization {
  public diagramData?: PfmDiagramData;
  public flowFinished?: boolean;

  constructor(json: IPfmUtilization) {
    super(json);

    this.diagramData = json.diagramData;
    this.flowFinished = json.flowFinished;
  }
}
