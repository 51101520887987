import { FluxStandardAction } from 'flux-standard-action';
import { TaskAssignee, TaskAssignment } from '@priz/shared/src/models/assignment';
import { createPgAction } from '../../../shared/store/action-creator';
import { TaskAssignmentApi } from '../../service';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';

export enum TaskAssigneeActionType {
  FetchAll = 'task-assignee/fetch-all',
  Create = 'task-assignee/create',
  Delete = 'task-assignee/delete',
}

type TaskAssigneeActionPayload = TaskAssignee | TaskAssignee[] | TaskAssignment | undefined;

interface TaskAssigneeActionMeta {
  projectId?: number;
  taskId?: number;
  assigneeId?: number;
  assigneeEmail?: string;
}

export type TaskAssigneeAction = FluxStandardAction<string, TaskAssigneeActionPayload, TaskAssigneeActionMeta>;

const fetchAll = createPgAction(TaskAssigneeActionType.FetchAll, TaskAssignmentApi.fetchAll);

const create = createPgAction(
  TaskAssigneeActionType.Create,
  TaskAssignmentApi.create,
  (projectId: number) => ({
    projectId,
  }),
  (_payload: TaskAssignee, { projectId }) => {
    AnalyticsService.track(AnalyticsEvent.TASK_ASSIGNEE_CREATED, {
      project_id: projectId,
    });
  },
);

const doDelete = createPgAction(
  TaskAssigneeActionType.Delete,
  TaskAssignmentApi.delete,
  (projectId: number, taskId: number, assigneeEmail: string, assigneeId: number) => ({
    projectId,
    taskId,
    assigneeEmail,
    assigneeId,
  }),
  (_payload: TaskAssignment, { projectId }) => {
    AnalyticsService.track(AnalyticsEvent.TASK_ASSIGNEE_DELETED, {
      project_id: projectId,
    });
  },
);

export const TaskAssigneeActions = {
  fetchAll,
  create,
  delete: doDelete,
};
