import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { pgColorScheme } from '../../../theme';
import { resolveImageUrl } from '../../../utils/images';

import loginImageUrl from '../../../assets/images/login-bg.svg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      overflow: 'hidden',
      width: '100%',
      maxWidth: 1000,
      backgroundPosition: 'left bottom',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '46.8%',
      borderRadius: 8,
      backgroundColor: pgColorScheme.white,
      boxSizing: 'border-box',
    },
    overflow: {
      maxHeight: '100%',
    },
    sizerContainer: {
      display: 'flex',
      width: '100%',
    },
    scrollContainer: {
      boxSizing: 'border-box',
      width: '100%',
    },
    contentContainer: {
      minHeight: 570,
      width: 'calc(50% - 10px)',
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    closeButton: {
      'position': 'absolute',
      'top': 8,
      'right': 8,
      'left': 'auto',
      'bottom': 'auto',
      'backgroundColor': pgColorScheme.background,
      'borderRadius': '50%',
      'width': 33,
      'height': 33,
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'center',
      'transition': 'background-color .15s ease',
      'cursor': 'pointer',

      '&:hover': {
        'backgroundColor': pgColorScheme.orange,

        '& $closeButtonIcon': {
          fill: pgColorScheme.white,
        },
      },
    },
    closeButtonIcon: {
      fill: pgColorScheme.darkText,
      transition: 'fill .15s ease',
    },

    [theme.breakpoints.up('md')]: {
      root: {
        backgroundImage: `url(${resolveImageUrl(loginImageUrl)})`,
      },
    },

    [theme.breakpoints.down('md')]: {
      contentContainer: {
        width: '100%',
      },
    },

    [theme.breakpoints.down('sm')]: {
      contentContainer: {
        minHeight: 0,
      },
    },
  }),
);
