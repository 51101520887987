import { handleActions, combineActions } from 'redux-actions';
import { UserContextAction, UserContextActionType } from '../../../security/store/actions';
import { toFailed, toStarted, toSuccess } from '../../../shared/store/action-creator';
import { EntityCollectionStatus } from '@priz/shared/src/models/common/entity-collection-state';
import { toObjectMap } from '../../../shared/store/reducer-utils';
import { WorkspaceAction, WorkspaceActionType } from '../actions';
import {
  DefaultWorkspaceCollection,
  FeatureSetsCollection,
  IWorkspace,
  Workspace,
  WorkspaceCollection,
} from '@priz/shared/src/models/workspace';

const workspaceInitializer = (workspace: IWorkspace) => new Workspace(workspace);

const setStatuses = (state: WorkspaceCollection, statuses: Partial<EntityCollectionStatus>): WorkspaceCollection => ({
  ...state,
  statuses: {
    ...state.statuses,
    ...statuses,
  },
});

const replaceAllWorkspaces = (
  state: WorkspaceCollection,
  workspaces: IWorkspace[],
  statuses: EntityCollectionStatus,
): WorkspaceCollection => {
  const entityMap = toObjectMap<Workspace>(workspaces, workspaceInitializer);

  return {
    ...state,
    entities: entityMap,
    statuses: {
      ...state.statuses,
      ...statuses,
    },
  };
};

const setWorkspace = (
  state: WorkspaceCollection,
  workspace: IWorkspace,
  statuses: EntityCollectionStatus,
): WorkspaceCollection => {
  const createdWorkspace = workspaceInitializer(workspace);

  return {
    ...state,
    entities: {
      ...state.entities,
      [createdWorkspace.id]: createdWorkspace,
    },
    statuses: {
      ...state.statuses,
      ...statuses,
    },
  };
};

export const workspaceReducers = handleActions(
  {
    // Fetching

    [toStarted(WorkspaceActionType.FetchAll)]: (state: WorkspaceCollection) =>
      setStatuses(state, {
        loading: true,
        loaded: false,
        error: false,
      }),

    [toFailed(WorkspaceActionType.FetchAll)]: (state: WorkspaceCollection) =>
      setStatuses(state, {
        loading: false,
        loaded: false,
        error: true,
      }),

    [toSuccess(WorkspaceActionType.FetchAll)]: (state: WorkspaceCollection, action: WorkspaceAction) =>
      replaceAllWorkspaces(state, action.payload as IWorkspace[], {
        loading: false,
        loaded: true,
        error: false,
      }),

    // Loading

    [toStarted(UserContextActionType.UserContext_Load)]: (state: WorkspaceCollection) =>
      setStatuses(state, { loading: true }),

    [toFailed(UserContextActionType.UserContext_Load)]: (state: WorkspaceCollection) =>
      setStatuses(state, { loading: false }),

    [toSuccess(UserContextActionType.UserContext_Load)]: (state: WorkspaceCollection, action: UserContextAction) =>
      replaceAllWorkspaces(
        state,
        action.payload.workspaceMemberships.map(wm => wm.workspace),
        { loading: false },
      ),

    // Creating

    [combineActions(toStarted(WorkspaceActionType.Create), toStarted(WorkspaceActionType.Complete))]: (
      state: WorkspaceCollection,
    ) => setStatuses(state, { creating: true, created: false, error: false }),

    [combineActions(toFailed(WorkspaceActionType.Create), toFailed(WorkspaceActionType.Complete))]: (
      state: WorkspaceCollection,
    ) => setStatuses(state, { creating: false, created: false, error: true }),

    [combineActions(toSuccess(WorkspaceActionType.Create), toSuccess(WorkspaceActionType.Complete))]: (
      state: WorkspaceCollection,
      action: WorkspaceAction,
    ): WorkspaceCollection =>
      setWorkspace(state, action.payload as IWorkspace, { creating: false, created: true, error: false }),

    // Updating

    [combineActions(
      toStarted(WorkspaceActionType.SetLicensedSeats),
      toStarted(WorkspaceActionType.UpdatePlan),
      toStarted(WorkspaceActionType.Update),
      toStarted(WorkspaceActionType.CancelSubscription),
    )]: (state: WorkspaceCollection) =>
      setStatuses(state, {
        updated: false,
        updating: true,
        error: false,
      }),

    [combineActions(
      toFailed(WorkspaceActionType.SetLicensedSeats),
      toFailed(WorkspaceActionType.UpdatePlan),
      toFailed(WorkspaceActionType.Update),
      toFailed(WorkspaceActionType.CancelSubscription),
    )]: (state: WorkspaceCollection) =>
      setStatuses(state, {
        updated: false,
        updating: false,
        error: true,
      }),

    [combineActions(
      toSuccess(WorkspaceActionType.SetLicensedSeats),
      toSuccess(WorkspaceActionType.UpdatePlan),
      toSuccess(WorkspaceActionType.Update),
      toSuccess(WorkspaceActionType.CancelSubscription),
    )]: (state: WorkspaceCollection, action: WorkspaceAction): WorkspaceCollection =>
      setWorkspace(state, action.payload as IWorkspace, {
        updated: true,
        updating: false,
        error: false,
      }),

    // Removing

    [toStarted(WorkspaceActionType.Delete)]: (state: WorkspaceCollection) =>
      setStatuses(state, { removing: true, removed: false, error: false }),

    [toFailed(WorkspaceActionType.Delete)]: (state: WorkspaceCollection) =>
      setStatuses(state, { removing: false, removed: false, error: true }),

    [toSuccess(WorkspaceActionType.Delete)]: (state: WorkspaceCollection) =>
      setStatuses(state, { removing: false, removed: true, error: false }),

    // Extracting related data

    [toSuccess(WorkspaceActionType.Features)]: (state: WorkspaceCollection, action: WorkspaceAction) => ({
      ...state,
      currentFeatureSet: (action.payload as FeatureSetsCollection).workspaceFeatureSet,
      publicProjectFeatureSet: (action.payload as FeatureSetsCollection).publicProjectFeatureSet,
    }),
  },
  DefaultWorkspaceCollection,
);
