import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    flex: '1 0 0',
    overflow: 'auto',
    boxSizing: 'border-box',
  },
});
