import { FluxStandardAction } from 'flux-standard-action';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { createPgAction } from '../../../shared/store/action-creator';
import { AppState } from '../../../store/app.state';
import { IProject, Project, ProjectStatus } from '@priz/shared/src/models/project';
import { CreateProjectCommand, ProjectApi } from '../../services';
import { ProjectSelector } from '../selectors';
import { NavigateFunction } from 'react-router-dom';
import { AxiosError } from 'axios';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';

export enum ProjectActionType {
  FetchAll = 'projects/fetch',
  Fetch = 'project/fetch',
  Create = 'project/create',
  Update = 'project/update',
  Delete = 'project/delete',
  PublishChanges = 'project/publishChanges',
  ProblemStatementUpdate = 'project/projectStatement/update',
  SolutionUpdate = 'project/solution/update',
  UpdateStatus = 'project/status/update',
  UpdatePublicInfo = 'project/publicInfo/update',
  SetPublishCountdown = 'project/publishCountdown/set',
}

type ProjectActionPayload = IProject | IProject[] | AxiosError;

interface ProjectMeta {
  userId?: number;
  project?: Project;
  projectId?: number;
  skipNavigate?: boolean;
  navigate?: NavigateFunction;
}

export type ProjectAction = FluxStandardAction<string, ProjectActionPayload, ProjectMeta>;

const loadProjects = createPgAction(
  ProjectActionType.FetchAll,
  ProjectApi.loadProjects,
  undefined,
  undefined,
  undefined,
  ProjectSelector.areProjectsLoading,
);

const loadProject = createPgAction(
  ProjectActionType.Fetch,
  ProjectApi.loadProject,
  (projectId: number) => ({
    projectId,
  }),
  undefined,
  undefined,
  ProjectSelector.areProjectsLoading,
);

const createProject = createPgAction(
  ProjectActionType.Create,
  ProjectApi.createProject,
  (command: CreateProjectCommand, navigate?: NavigateFunction) => ({
    navigate,
    ...command,
  }),
  (payload: IProject, meta: ProjectMeta) => {
    AnalyticsService.track(AnalyticsEvent.PROJECT_CREATED, {
      project_id: payload.id,
      project_status: payload.status,
      project_title: payload.title,
      project_industry: payload.industry,
      project_area: payload.area,
      project_goal: payload.goal,
    });

    if (payload.status === ProjectStatus.InProgress) {
      AnalyticsService.track(AnalyticsEvent.PROJECT_STARTED, {
        project_id: payload.id,
        project_status: payload.status,
        project_title: payload.title,
        project_industry: payload.industry,
        project_area: payload.area,
        project_goal: payload.goal,
      });
    }

    if (meta.navigate) {
      const lsToolPath = LocalStorageService.getItem(LocalStorageKey.LastViewedToolPath);

      if (lsToolPath) {
        LocalStorageService.removeItem(LocalStorageKey.LastViewedToolPath);
        LocalStorageService.setItem(LocalStorageKey.SwitchToLastViewedToolTab, 'true');

        return meta.navigate(['/project', payload.id].join('/') + lsToolPath);
      }

      if (payload.status !== ProjectStatus.InProgress) {
        return meta.navigate('/projects-and-tools');
      }

      meta.navigate(['/project', payload.id, payload.template ? 'template' : 'overview'].join('/'));
    }
  },
);

const updateProject = createPgAction(
  ProjectActionType.Update,
  ProjectApi.updateProject,
  (project: Project, skipNavigate?: boolean, navigate?: NavigateFunction) => ({ project, skipNavigate, navigate }),
  (payload: IProject, meta) => {
    AnalyticsService.track(AnalyticsEvent.PROJECT_UPDATED, {
      project_id: payload.id,
      project_title: payload.title,
    });

    if (!meta.skipNavigate && meta.navigate) {
      const projectPage = payload.template ? 'template' : 'overview';
      const path =
        payload.status === ProjectStatus.InProgress ? ['/project', payload.id, projectPage] : ['/projects-and-tools'];

      meta.navigate(path.join('/'));
    }
  },
);

const updateProblemStatement = createPgAction(
  ProjectActionType.ProblemStatementUpdate,
  ProjectApi.updateProblemStatement,
  (project: Partial<Project>) => ({ project }),
  (payload: IProject) => {
    AnalyticsService.track(AnalyticsEvent.PROBLEM_STATEMENT_UPDATED, {
      project_id: payload.id,
      project_title: payload.title,
    });
  },
);

const updateSolution = createPgAction(
  ProjectActionType.SolutionUpdate,
  ProjectApi.updateSolution,
  (project: Partial<Project>) => ({ project }),
  (payload: IProject) => {
    AnalyticsService.track(AnalyticsEvent.SOLUTION_UPDATED, {
      project_id: payload.id,
      project_title: payload.title,
    });
  },
);

const updateStatus = createPgAction(
  ProjectActionType.UpdateStatus,
  ProjectApi.updateStatus,
  (project: Partial<Project>) => ({ project }),
  (payload: IProject) => {
    AnalyticsService.track(AnalyticsEvent.PROJECT_STATUS_UPDATED, {
      project_id: payload.id,
      project_title: payload.title,
      project_status: payload?.status,
    });
  },
);

const updatePublicInfo = createPgAction(
  ProjectActionType.UpdatePublicInfo,
  ProjectApi.updatePublicInfo,
  (project: Partial<Project>) => ({ project }),
  (payload: IProject) => {
    AnalyticsService.track(AnalyticsEvent.PROJECT_OPEN_UPDATED, {
      project_id: payload.id,
      project_title: payload.title,
      project_open: payload.open,
    });
  },
);

const publishChanges = createPgAction(
  ProjectActionType.PublishChanges,
  ProjectApi.publishChanges,
  (project: Partial<Project>) => ({ project }),
  (payload: IProject) => {
    AnalyticsService.track(AnalyticsEvent.PROJECT_CHANGES_PUBLISHED, {
      project_id: payload.id,
      project_title: payload.title,
      project_open: payload.open,
    });
  },
);

const deleteProject = createPgAction(
  ProjectActionType.Delete,
  (project: Project) => ProjectApi.deleteProjectById(project.id),
  (project: Project) => ({ project }),
  (_, { project }) => {
    AnalyticsService.track(AnalyticsEvent.PROJECT_DELETED, {
      project_id: project.id,
      project_title: project.title,
    });
  },
);

const saveProjectTitle = (projectId: number, newTitle: string) => (_, getState) => {
  const project = resolveProjectById(projectId, getState());

  const modifiedProject = {
    ...project,
    title: newTitle,
  };

  updateProject(modifiedProject, true /*skipNavigate*/);
};

const resolveProjectById = (projectId: number, state: AppState) => {
  const project = ProjectSelector.getById(projectId)(state);
  if (!project) {
    throw new Error(`Could not find project by id ${projectId} or it is not yet loaded`);
  }

  return project;
};

const setPublishCountdown = createPgAction(
  ProjectActionType.SetPublishCountdown,
  (): Promise<void> => new Promise(resolve => resolve()),
  (projectId: number) => ({ projectId }),
);

export const ProjectActions = {
  saveProjectTitle,
  deleteProject,
  updateProblemStatement,
  updateSolution,
  updateProject,
  createProject,
  loadProject,
  loadProjects,
  updateStatus,
  updatePublicInfo,
  setPublishCountdown,
  publishChanges,
};
