import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../theme';

export const useStyles = makeStyles({
  root: {
    marginBottom: 20,
  },
  cell: {
    borderCollapse: 'collapse',
    whiteSpace: 'pre-wrap',
    boxSizing: 'border-box',
  },
  header: {
    backgroundColor: pgColorScheme.gray50,
  },
  table: {
    width: '100%',
  },
  body: {
    '& $cell:nth-child(1)': {
      borderRight: `1px solid rgba(224, 224, 224, 1)`,
    },
  },
});
