import React from 'react';
import { UimPriority } from '../../models/tools/uim';
import { PriorityLabelMap } from '../../data/priority-map';
import { useStyles } from './styles';
import { Chip, ChipProps } from '@mui/material';
import { Trans } from 'react-i18next';

export interface PriorityIndicatorProps extends ChipProps {
  priority: UimPriority;
  rounded?: boolean;
}

export const PriorityIndicator: React.FC<PriorityIndicatorProps> = ({ priority, rounded, ...rest }) => {
  const styles = useStyles();
  const rootClassNames = [styles.root, styles[priority]];

  if (rounded) rootClassNames.push('_rounded');

  if (!priority) return null;

  return <Chip className={rootClassNames.join(' ')} label={<Trans>{PriorityLabelMap[priority]}</Trans>} {...rest} />;
};
