import { LetterChain, Perception } from '@priz/shared/src/models/tools/pmap/perception';
import { lettersToNumber, numberToLetters } from '@priz/shared/src/utils/convertation';
import { copyObject } from '@priz/shared/src/utils/common';
import { CalcResult, IPMapUtilizationData } from '@priz/shared/src/models/tools/pmap';

export const checkIsAllPerceptionsWereConnected = (data: IPMapUtilizationData, calcResult: CalcResult): boolean => {
  return data.allPerceptionsWereConnected || data.perceptions.length === Object.keys(calcResult.connections).length;
};

export const getMaxPerceptionId = (perceptionsArray: Perception[]): number => {
  return perceptionsArray.length ? Math.max(...perceptionsArray.map(p => lettersToNumber(p.letter)), 0) : 0;
};

const isPerceptionClear = (perception: Perception): boolean => {
  return !perception.description.length && !perception.assign.length && !perception.conflict.length;
};

export const addNextPerceptionToArray = (
  perceptionsArray: Perception[],
  perceptionDescription?: string,
  replaceIfInitial?: boolean,
): Perception[] => {
  const replace = replaceIfInitial && perceptionsArray.length === 1 && isPerceptionClear(perceptionsArray[0]);

  const newPerception = new Perception({
    letter: numberToLetters(replace ? 1 : getMaxPerceptionId(perceptionsArray) + 1),
    description: perceptionDescription || '',
    assign: '',
    conflict: '',
  });

  return replace ? [newPerception] : [...copyObject(perceptionsArray), newPerception];
};

export const removePerceptionFromArray = (
  perceptionsArray: Perception[],
  letterToRemove: string,
  conflicts: LetterChain,
): Perception[] => {
  const unchainedConflicts: string[] = [];

  Object.keys(conflicts).forEach(key => {
    if (conflicts[key].includes(letterToRemove)) {
      unchainedConflicts.push(...key.split('-'));
    }
  });

  return copyObject(perceptionsArray).filter(item => {
    const { letter, assign, conflict } = item;

    if (assign === letterToRemove) item.assign = '';
    if (conflict === letterToRemove || unchainedConflicts.includes(letter)) item.conflict = '';

    return letter !== letterToRemove;
  });
};

export const changePerceptionConnections = (
  perceptionsArray: Perception[],
  letterFrom: string,
  letterTo: string,
  connections: LetterChain,
): Perception[] => {
  const currentConflictLetter = perceptionsArray.find(p => p.letter === letterFrom)?.conflict;
  const newChainContainsConflict = currentConflictLetter && !connections[letterTo]?.includes(currentConflictLetter);
  const conflictToReset = newChainContainsConflict ? currentConflictLetter : null;

  return copyObject(perceptionsArray).map(item => {
    if (item.letter === letterFrom) item.assign = letterTo;
    if (item.letter === letterFrom && conflictToReset) item.conflict = '';
    if (item.letter === conflictToReset) item.conflict = '';

    return item;
  });
};

export const setPerceptionsConflicts = (
  perceptionsArray: Perception[],
  from: string,
  to: string,
  unset?: boolean,
): Perception[] => {
  const currentConflictLetter = perceptionsArray.find(p => p.letter === from)?.conflict;

  return copyObject(perceptionsArray).map(item => {
    if (item.letter === from) item.conflict = unset ? '' : to;
    if (item.letter === to) item.conflict = unset ? '' : from;
    if (item.letter === currentConflictLetter) item.conflict = '';
    return item;
  });
};

export const changePerceptionDescription = (
  perceptionsArray: Perception[],
  letterToUpdate: string,
  text: string,
): Perception[] => {
  return copyObject(perceptionsArray).map(item => {
    if (item.letter === letterToUpdate) item.description = text;
    return item;
  });
};
