import { Project, ProjectStatus } from '@priz/shared/src/models/project';
import { ProjectsAssigneesMap } from '@priz/shared/src/models/assignment';
import { UserUtils } from '@priz/shared/src/utils/user/user-utils';

const filterByText = (projects: Project[], assigneesMap: ProjectsAssigneesMap, text: string): Project[] => {
  if (!text?.length || !assigneesMap) return projects;

  const textLowerCase = text.toLowerCase();

  return projects.filter(project => {
    const projectUsers = Object.values(assigneesMap[project.id])
      .map(a => a?.user)
      .filter(user => !!user);

    const matchProjectTitle = project.title.toLowerCase().includes(textLowerCase);

    const matchAssigneeEmail = projectUsers.some(user => {
      const matchEmail = user?.email && user.email.toLowerCase().includes(textLowerCase);
      const matchName = UserUtils.getShowableName(user)?.toLowerCase().includes(textLowerCase);
      return matchEmail || matchName;
    });

    return matchAssigneeEmail || matchProjectTitle;
  });
};

const groupByStatus = (projects: Project[]): { [key in ProjectStatus]: Project[] } => {
  const groups = {
    [ProjectStatus.Todo]: [],
    [ProjectStatus.InProgress]: [],
    [ProjectStatus.Completed]: [],
    [ProjectStatus.Archived]: [],
  };

  (projects || []).forEach(project => {
    groups[project.status].push(project);
  });

  return groups;
};

export const ProjectUtils = {
  filterByText,
  groupByStatus,
};
