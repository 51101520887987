import { IdRef } from '../../common/id-ref';

export class DomainPrinciple {
  public id?: number;
  public description?: string;
  public descriptionZh?: string;
  public descriptionEs?: string;
  public domain?: IdRef;
  public principle?: IdRef;
  public createdBy: IdRef;
  public dateCreated: Date;
  public updatedBy?: IdRef;
  public lastUpdated?: Date;

  constructor(json: DomainPrinciple) {
    this.id = json.id;
    this.description = json.description;
    this.descriptionZh = json.descriptionZh;
    this.descriptionEs = json.descriptionEs;
    this.domain = json.domain;
    this.principle = json.principle;
    this.createdBy = json.createdBy;
    this.dateCreated = json.dateCreated && new Date(json.dateCreated);
    this.updatedBy = json.updatedBy;
    this.lastUpdated = json.lastUpdated && new Date(json.lastUpdated);
  }
}
