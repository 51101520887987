import React from 'react';
import { LocalesService } from '@priz/shared/src/components/locales/services';
import { Locales } from '@priz/shared/src/models/locales';
import parse from 'html-react-parser';

interface Translations {
  en: string;
  zh?: string;
  es?: string;
}

interface TranslationResolverProps extends Translations {
  parseHtml?: boolean;
}

export const resolveTranslation = (props: Translations) => {
  const { en, zh, es } = props;
  const currentLocale = LocalesService.getCurrentLocale();

  let text = en;

  if (currentLocale === Locales.ZH && zh) text = zh;
  if (currentLocale === Locales.ES && es) text = es;

  return text;
};

interface TranslationResolverProps extends Translations {
  parseHtml?: boolean;
}

export const TranslationResolver: React.FC<TranslationResolverProps> = ({ en, zh, es, parseHtml }) => {
  const text = resolveTranslation({ en, zh, es });

  return <>{parseHtml ? parse(text) : text}</>;
};
