import React, { useEffect, useState } from 'react';
import { Alert, Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SfmResultSelectors } from '../store/selectors';
import { SfmUtilizationActions } from '../store/actions';
import { LoadingOverlay } from '@priz/shared/src/components/loading-overlay/component';
import { SfmNodesMap, SfmUtilization } from '@priz/shared/src/models/tools/sfm';
import { ComponentImprovementIteration, SfmComponentImprovementMap } from '../store/model';
import { SfmComponentImprovementIteration } from '../sfm-component-iteration/component';
import { getSfmNodesMap, resolveSfmNetworkData } from '@priz/shared/src/utils/sfm';
import { ToolVersionUtils } from '@priz/shared/src/utils/tools';

interface SfmComponentsImprovementHistoryProps {
  utilization: SfmUtilization;
  load: boolean;
  forcedVersionId?: string;
}

const resolveImprovementIterationsArray = (
  improvementsMap: SfmComponentImprovementMap,
  nodeMap: SfmNodesMap,
): ComponentImprovementIteration[] => {
  const array: ComponentImprovementIteration[] = [];
  const nodesIds = Object.keys(nodeMap || {});

  Object.keys(improvementsMap || {}).forEach(key => {
    if (nodesIds.includes(key)) {
      array.push(
        ...Object.values(improvementsMap[key].iterations || {}).map(i => {
          i.componentId = key;
          return i;
        }),
      );
    }
  });

  return array.sort((a, b) => b.dateCreatedMs - a.dateCreatedMs);
};

export const SfmComponentsImprovementHistory: React.FC<SfmComponentsImprovementHistoryProps> = ({
  utilization,
  forcedVersionId,
  load,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const versionId = forcedVersionId || utilization?.diagramData?.versionId;

  const [requestImprovementsAfterLoad, setRequestImprovementsAfterLoad] = useState(false);

  const componentsImprovementsMap = useSelector(
    SfmResultSelectors.getComponentsImprovementsMap(utilization?.id, versionId),
  );
  const isLoading = useSelector(SfmResultSelectors.isLoadingByVersionId(versionId));
  const isLoaded = useSelector(SfmResultSelectors.isLoadedByVersionId(versionId));

  const networkData = resolveSfmNetworkData(utilization?.diagramData, versionId);
  const iterations = resolveImprovementIterationsArray(componentsImprovementsMap, getSfmNodesMap(networkData));

  const titlesMap = ToolVersionUtils.getVersionsTitlesMap(utilization?.diagramData, t);

  useEffect(() => {
    if (load) {
      dispatch(SfmUtilizationActions.getProblematicComponents(utilization.id, versionId, utilization.project?.id));
      setRequestImprovementsAfterLoad(true);
    }
  }, [utilization?.id, load]);

  useEffect(() => {
    if (requestImprovementsAfterLoad && isLoaded) {
      setRequestImprovementsAfterLoad(false);

      dispatch(SfmUtilizationActions.getVersionImprovements(utilization.id, versionId, utilization.project?.id));
    }
  }, [requestImprovementsAfterLoad, isLoaded]);

  if (!load) return null;

  if (isLoading) {
    return <LoadingOverlay loading={true} backdropStyles={{ position: 'relative', background: 'transparent' }} />;
  }

  return (
    <>
      <Typography variant={'h6'} component={Box} mb={2}>
        {!!titlesMap[versionId]?.length && `${titlesMap[versionId]}: `}
        <Trans>Components improvement history</Trans>
      </Typography>

      {isLoaded && !iterations.length && (
        <Alert severity={'info'}>
          <Trans>No changes yet</Trans>
        </Alert>
      )}

      {isLoaded &&
        iterations.map((iteration, key) => (
          <Box key={key} mb={1}>
            <SfmComponentImprovementIteration
              improvementIteration={iteration}
              utilization={utilization}
              versionId={versionId}
              componentId={iteration.componentId}
              variant={'outlined'}
              showComponent
            />
          </Box>
        ))}
    </>
  );
};
