import { Parameter } from '@priz/shared/src/models/tools/forty-principles/parameter';
import {
  DefaultEntityCollectionStatus,
  EntityCollectionStatus,
} from '@priz/shared/src/models/common/entity-collection-state';

export interface ParameterList {
  parameters: Parameter[];
  statuses: EntityCollectionStatus;
}

export const DefaultParameterList: ParameterList = {
  parameters: [],
  statuses: DefaultEntityCollectionStatus,
};
