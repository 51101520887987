import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { Box } from '@mui/material';

interface HashHighlightContainerProps {
  id: string;
  scrollContainerId?: string;
  scrollContainerElement?: HTMLDivElement;
  scrollOffset?: number;
  highlightPadding?: number;
  variant?: 'filled' | 'outlined' | 'transparent';
  color?: 'green' | 'blue' | 'orange' | 'red';
}

export const HashHighlightContainer: React.FC<HashHighlightContainerProps> = ({
  id,
  scrollContainerId,
  scrollContainerElement,
  scrollOffset = 0,
  highlightPadding = 10,
  children,
  variant = 'filled',
  color = 'blue',
}) => {
  const styles = useStyles();

  const [containerElement, setContainerElement] = useState<HTMLDivElement | undefined>();
  const [isHighlightingEnabled, setIsHighlightingEnabled] = useState(false);

  const substrateClasses = [styles.highlightSubstrate];

  if (variant === 'filled') substrateClasses.push(styles.filled);
  if (variant === 'outlined') substrateClasses.push(styles.outlined);
  if (variant !== 'transparent') substrateClasses.push('_' + color);

  useEffect(() => {
    const hash = window.location.hash;

    if (hash.replace('#', '') === id) {
      if (containerElement) {
        setIsHighlightingEnabled(true);

        setTimeout(() => {
          scrollToContainer();
        }, 1000);
      }
    } else {
      if (isHighlightingEnabled) setIsHighlightingEnabled(false);
    }
  }, [containerElement]);

  const scrollToContainer = () => {
    const scrollContainer = scrollContainerId ? document.getElementById(scrollContainerId) : scrollContainerElement;
    const scrollContainerTop = scrollContainer ? scrollContainer.getBoundingClientRect().top : 0;
    const containerElementTop = containerElement.getBoundingClientRect().top;
    const scrollContainerScrollTop = scrollContainer ? scrollContainer.scrollTop : 0;

    (scrollContainer || window).scrollTo({
      top: containerElementTop - scrollContainerTop - scrollOffset + scrollContainerScrollTop,
      behavior: 'smooth',
    });
  };

  const refHandler = (element: HTMLDivElement | null) => {
    if (element) setContainerElement(element);
  };

  return (
    <div className={styles.root} ref={r => refHandler(r)} id={id}>
      {isHighlightingEnabled && (
        <Box
          className={substrateClasses.join(' ')}
          style={{
            top: -highlightPadding,
            bottom: -highlightPadding,
            left: -highlightPadding,
            right: -highlightPadding,
          }}
        />
      )}

      <div className={styles.contentContainer}>{children}</div>
    </div>
  );
};
