import React from 'react';
import { FiveWhysCause, FiveWhysSolution } from '../../../models/tools/five-whys';
import { FiveWhysDiagram } from '../../../components/five-whys';
import { pgColorScheme } from '../../../theme';
import { Box } from '@mui/material';

interface FiveWhysViewerProps {
  causes: FiveWhysCause[];
  solutions: FiveWhysSolution[];
}

export const FiveWhysViewer: React.FC<FiveWhysViewerProps> = ({ causes, solutions }) => {
  return (
    <Box
      style={{
        width: '100%',
        position: 'relative',
      }}
    >
      <FiveWhysDiagram
        causes={causes}
        solutions={solutions}
        compact={true}
        viewMode={true}
        boxProps={{
          style: {
            position: 'relative',
            height: 'auto',
            background: pgColorScheme.workbenchBackground,
            boxShadow: `1000px 0 0 ${pgColorScheme.workbenchBackground} inset`,
          },
          pb: 3,
          px: 2,
        }}
      />
    </Box>
  );
};
