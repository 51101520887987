import React from 'react';
import { Attendee, AttendeeStatus, Meeting } from '../store/model';
import { useDispatch, useSelector } from 'react-redux';
import { AttendeeActions } from '../store/actions';
import { AttendeeSelectors } from '../store/selectors';
import { UserSelectors } from '../../user/store/selectors/user.selectors';
import { Button, Grid, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { ManageAccountsOutlined, SettingsOutlined } from '@mui/icons-material';
import { AttendeeRolePopper } from '../attendee-role-popper/component';
import { useStyles } from './styles';
import { AppState } from '../../store/app.state';

interface AttendeeRoleProps {
  meeting: Meeting;
  forcedAttendee?: Attendee;
}

const rolesMap = {
  [AttendeeStatus.Contributor]: 'contributor',
  [AttendeeStatus.Observer]: 'observer',
  [AttendeeStatus.Decline]: 'declined',
};

export const AttendeeRole: React.FC<AttendeeRoleProps> = ({ meeting, forcedAttendee }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const currentUser = useSelector(UserSelectors.currentUserSelector);
  const attendee = useSelector((state: AppState) =>
    forcedAttendee
      ? forcedAttendee
      : AttendeeSelectors.getByProjectIdAndMeetingIdAndUserId(meeting.project.id, meeting.id, currentUser?.id)(state),
  );
  const isLead = currentUser?.id === meeting.createdBy.id;
  const roleTextClassNames = [styles.roleText];

  if (!isLead) roleTextClassNames.push(styles.link);

  const acceptHandler = (status: AttendeeStatus, explanation: string) => {
    dispatch(AttendeeActions.update(attendee.project.id, attendee.id, { status, explanation }));
  };

  const declineHandler = () => {
    dispatch(AttendeeActions.update(attendee.project.id, attendee.id, { status: AttendeeStatus.Decline }));
  };

  const renderStatusLink = () => {
    return (
      <span className={roleTextClassNames.join(' ')}>
        <Trans>{attendee?.status ? rolesMap[attendee.status] : 'meeting lead'}</Trans>{' '}
        {!isLead && <SettingsOutlined fontSize={'inherit'} sx={{ verticalAlign: 'middle' }} />}
      </span>
    );
  };

  if (!currentUser) return null;

  return (
    <>
      {(attendee?.status || isLead) && (
        <Typography variant={'body2'}>
          <Trans>{attendee?.status === AttendeeStatus.Decline ? 'You' : 'You are attending as'}</Trans>{' '}
          {isLead && renderStatusLink()}
          {!isLead && (
            <AttendeeRolePopper attendee={attendee} onAccept={acceptHandler} onDecline={declineHandler}>
              {renderStatusLink()}
            </AttendeeRolePopper>
          )}
          {attendee?.status === AttendeeStatus.Decline && (
            <>
              {' '}
              <Trans>this meeting</Trans>
            </>
          )}
        </Typography>
      )}

      {attendee && !attendee?.status && !isLead && (
        <Grid container spacing={1}>
          <Grid item>
            <AttendeeRolePopper attendee={attendee} onAccept={acceptHandler}>
              <Button endIcon={<ManageAccountsOutlined />} variant="outlined" size={'small'}>
                <Trans>Accept</Trans>
              </Button>
            </AttendeeRolePopper>
          </Grid>

          <Grid item>
            <Button variant="text" size={'small'} onClick={declineHandler}>
              <Trans>Decline</Trans>
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
