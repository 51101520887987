import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '../../../theme';
import { cftSettings } from '../data';

export const useStyles = makeStyles({
  root: {
    display: 'block',
  },
  titleContainer: {
    boxSizing: 'border-box',
    paddingRight: 8,
    maxWidth: cftSettings.node.step.width.min,
  },
  title: {
    backgroundColor: cftSettings.colors.process.background,
    color: pgColorScheme.white,
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    padding: '4px 8px',
    display: 'block',
    ...cftSettings.font.default,
    ...(cftSettings.font.process || {}),
  },
  stepsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  controls: {
    display: 'flex',
  },
});
