import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  alertLoading: {
    borderColor: pgColorScheme.gray,
  },
  alertMessage: {
    width: '100%',
    padding: 0,
  },
});
