import {
  ProjectWizardInput,
  ProjectWizardInputCondition,
  ProjectWizardInputValue,
  ProjectWizardInputValuesMap,
} from '../model/inputs';
import { Project } from '@priz/shared/src/models/project';

export const generateDefaultValues = (inputs: ProjectWizardInput[], project?: Project): ProjectWizardInputValuesMap => {
  return inputs.reduce((obj, input) => {
    obj[input.name] = (project && project[input.name]) || '';
    return obj;
  }, {});
};

export const checkConditionIsValid = (
  conditions: ProjectWizardInputCondition[],
  valuesMap: ProjectWizardInputValuesMap,
): boolean => {
  return !conditions.some(condition => {
    const inputValue = valuesMap[condition.inputName];
    return condition.inputValues ? !condition.inputValues.includes(inputValue) : !inputValue;
  });
};

export const checkValueIsNotEmpty = (value?: ProjectWizardInputValue): boolean => {
  return typeof value === 'string' ? !!value.length : typeof value === 'number';
};

export const checkIsAllDone = (inputs: ProjectWizardInput[], valuesMap: ProjectWizardInputValuesMap): boolean => {
  let lastVisibleInput = null;

  inputs.forEach(input => {
    if (checkConditionIsValid(input.conditions, valuesMap)) {
      lastVisibleInput = input;
    }
  });

  return lastVisibleInput ? checkValueIsNotEmpty(valuesMap[lastVisibleInput.name]) : false;
};
