import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Trans } from 'react-i18next';
import { ReactHookFormText } from '../../../react/form-elements';
import { validatorRules } from '@priz/shared/src/utils/form';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { DiagramDataVersions } from '@priz/shared/src/models/tools';
import { ToolVersionUtils } from '@priz/shared/src/utils/tools';

interface EditVersionDialogProps {
  diagramData?: DiagramDataVersions;
  editingVersionId: string;
  open?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onSubmit?: (title: string) => void;
  onCancel?: () => void;
}

export const EditVersionDialog: React.FC<EditVersionDialogProps> = ({
  diagramData,
  editingVersionId,
  open,
  onSubmit,
  onCancel,
  disabled,
  loading,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { handleSubmit, reset, control } = useForm({
    shouldFocusError: false,
    defaultValues: {
      title: ToolVersionUtils.getVersionTitle(diagramData, editingVersionId),
    },
  });

  useEffect(() => {
    if (typeof open !== 'undefined') {
      setIsOpen(open);
    }

    if (open) {
      resetValues();
    }
  }, [open]);

  useEffect(() => {
    resetValues();
  }, [diagramData]);

  const resetValues = () => {
    reset({
      title: ToolVersionUtils.getVersionTitle(diagramData, editingVersionId),
    });
  };

  const handleClose = () => {
    if (onCancel) {
      onCancel();
    } else {
      setIsOpen(false);
    }
  };

  const handleSave = () => {
    handleSubmit(formData => {
      if (onSubmit) onSubmit(formData.title);
    })();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>
        <Trans>Edit SFM version title</Trans>
      </DialogTitle>

      <DialogContent>
        <ReactHookFormText
          fieldTitle={'Version title'}
          name={'title'}
          control={control}
          rules={{
            ...validatorRules.required(),
          }}
          wrapperProps={{ mb: 2 }}
          disabled={disabled || loading}
        />
      </DialogContent>

      <DialogActions>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose}>
          <Trans>Cancel</Trans>
        </Button>

        <LoadingButton
          variant={'pg_rounded'}
          color={'pg_orange_solid'}
          onClick={handleSave}
          disabled={disabled || loading}
          loading={loading}
        >
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
