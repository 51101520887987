import React, { useContext } from 'react';
import { CanvasControlButton } from '../../canvas-controls';
import { ElementsViewerContext } from '../elements-viewer-context/component';

import { ReactComponent as ZoomIcon } from '../../../assets/icons/zoom';
import { ReactComponent as ZoomAltIcon } from '../../../assets/icons/zoom-alt';

interface ElementsViewerButtonProps {
  className?: string;
  id?: string;
}

export const ElementsViewerButton: React.FC<ElementsViewerButtonProps> = ({ id }) => {
  const viewerContext = useContext(ElementsViewerContext);

  return (
    <CanvasControlButton
      id={id}
      key={viewerContext.enabled ? 'ViewerButton-e' : 'ViewerButton-d'}
      tooltip={viewerContext.enabled ? 'Disable view mode' : 'Enable view mode'}
      active={viewerContext.enabled}
      onClick={viewerContext.toggle}
    >
      {viewerContext.enabled ? <ZoomAltIcon /> : <ZoomIcon />}
    </CanvasControlButton>
  );
};
