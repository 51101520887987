import React, { useEffect, useState } from 'react';
import { Task } from '@priz/shared/src/models/task';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
} from '@mui/material';
import { AttachmentType } from '../../attachments/store/model';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AttachmentsSelectors } from '../../attachments/store/selectors';
import { AttachmentsActions } from '../../attachments/store/actions';
import { AttachmentItem } from '../../attachments/attachment-item/component';
import { QuillContentViewer } from '@priz/shared/src/components/quill-content-viewer/component';
import { GrayLabelContainer } from '../../content-containers/gray-label-container/component';
import { TaskInfo } from '../task-info/component';

interface TaskViewerProps {
  task: Task;
  isOpen: boolean;
  onClose?: () => void;
}

export const TaskViewer: React.FC<TaskViewerProps> = ({ task, isOpen, onClose }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const attachments = useSelector(
    AttachmentsSelectors.getAllByProjectIdAndType(task.project.id, AttachmentType.Task, task.id),
  );

  useEffect(() => {
    dispatch(AttachmentsActions.getAllForProject(task.project.id));
  }, []);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle>{task.title}</DialogTitle>

      <DialogContent>
        <Grid container spacing={4} flexDirection={{ xs: 'column-reverse', lg: 'row' }}>
          <Grid item xs={12} lg={8}>
            {!task.details && !task.resolution && (
              <Alert severity={'info'}>
                <Trans>No details</Trans>
              </Alert>
            )}

            {(task.details || task.resolution) && (
              <Box pt={2}>
                {task.details && (
                  <GrayLabelContainer label={'Details'} flexDirection={'column'}>
                    <QuillContentViewer content={task.details} />
                  </GrayLabelContainer>
                )}

                {task.resolution && (
                  <>
                    {task.details && (
                      <Box py={2}>
                        <Divider />
                      </Box>
                    )}

                    <GrayLabelContainer label={'Resolution'} flexDirection={'column'}>
                      <QuillContentViewer content={task.resolution} />
                    </GrayLabelContainer>
                  </>
                )}
              </Box>
            )}
          </Grid>

          <Grid item xs={12} lg={4}>
            <Paper variant={'outlined'} component={Box} p={2}>
              <TaskInfo task={task} columnDirection />

              {!!attachments?.length && (
                <Box mt={1}>
                  <GrayLabelContainer label={'Attachments'} flexDirection={'column'}>
                    <Grid container spacing={1}>
                      {attachments.map(attachment => (
                        <Grid key={attachment.id} item>
                          <AttachmentItem
                            workspaceId={task.workspace.id}
                            projectId={task.project.id}
                            attachment={attachment}
                            editable={false}
                            compact
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </GrayLabelContainer>
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ textAlign: 'right' }}>
        <Button variant={'pg_rounded'} color={'pg_orange_outlined'} onClick={handleClose}>
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
