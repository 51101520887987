import React, { useState } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { Topic } from '@priz/shared/src/models/project';
import { TopicLabelPreview } from '../topic-label-preview/component';
import { DeleteIcon, EditIcon } from '../../react/icons';
import { ButtonsWrap } from '@priz/shared/src/components/buttons-wrap/component';
import { TopicDialog } from '../topic-dialog/component';
import { pgColorScheme } from '@priz/shared/src/theme';
import { PgConfirmationDialog } from '../../react/elements/PgConfirmationDialog';
import { Trans } from 'react-i18next';
import { useMutation } from 'react-query';
import { TopicsApi } from '../services';
import { Alert } from '@mui/material';

interface TopicCardProps {
  topic: Topic;
  onDelete?: () => void;
  onUpdate?: (topic: Topic) => void;
}

export const TopicCard: React.FC<TopicCardProps> = ({ topic, onDelete, onUpdate }) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const mutationDelete = useMutation((id: number) => TopicsApi.delete(id), {
    onSuccess: () => {
      if (onDelete) onDelete();
      closeDeleteConfirmationIsOpen();
    },
    onError: (error: { response: { data: { message } } }) => {
      setErrorText(error?.response?.data?.message || 'Error');
    },
  });

  const openDialog = () => {
    setDialogIsOpen(true);
  };

  const closeDialog = () => {
    setDialogIsOpen(false);
  };

  const openDeleteConfirmationIsOpen = () => {
    setDeleteConfirmationIsOpen(true);
  };

  const closeDeleteConfirmationIsOpen = () => {
    setDeleteConfirmationIsOpen(false);
  };

  const deleteTopic = () => {
    mutationDelete.mutate(topic.id);
  };

  const updateHandler = (topic: Topic) => {
    if (onUpdate) onUpdate(topic);
  };

  return (
    <Paper>
      <Box p={2}>
        <Grid container spacing={4}>
          <Grid item xs={true}>
            <Box mb={2}>
              <Grid container spacing={2}>
                <Grid item>
                  <TopicLabelPreview color={topic.color} text={topic.label} />
                </Grid>

                <Grid item>
                  <Typography variant={'body1'} component={'span'} style={{ color: pgColorScheme.textGray }}>
                    Slug:&nbsp;<b>{topic.slug}</b>
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Typography variant={'body1'} component={'span'}>
                {topic.description}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={'auto'}>
            <ButtonsWrap spacing={1} mt={0}>
              <Button variant={'pg_button_link'} startIcon={<EditIcon />} onClick={openDialog}>
                Edit
              </Button>

              <Button variant={'pg_button_link'} startIcon={<DeleteIcon />} onClick={openDeleteConfirmationIsOpen}>
                Delete
              </Button>
            </ButtonsWrap>
          </Grid>

          <TopicDialog topic={topic} edit={true} isOpen={dialogIsOpen} onClose={closeDialog} onUpdate={updateHandler} />

          <PgConfirmationDialog
            isOpen={deleteConfirmationIsOpen}
            confirmTitle={<Trans>Confirm delete</Trans>}
            okButtonText={<Trans>Delete</Trans>}
            confirmContent={
              <>
                {errorText && (
                  <Box mb={2}>
                    <Alert severity={'error'}>{errorText}</Alert>
                  </Box>
                )}
                Are you sure you want to delete this topic?
              </>
            }
            onConfirm={deleteTopic}
            onClose={closeDeleteConfirmationIsOpen}
            loading={mutationDelete.isLoading}
          />
        </Grid>
      </Box>
    </Paper>
  );
};
