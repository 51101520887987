import { FortyPrinciplesUtilization } from '../tools/forty-principles/forty-principles-utilization';
import { RrmUtilization } from '../tools/rrm/rrm-utilization';
import { EntityCollectionStatus } from '../common/entity-collection-state';
import { UimUtilization } from '../tools/uim';
import { SfmUtilization } from '../tools/sfm';
import { CecUtilization } from '../tools/cec';
import { ApaUtilization } from '../tools/apa';
import { EbsUtilization } from '../tools/ebs';
import { NineWindowsUtilization } from '../tools/nine-windows';
import { FiveWhysUtilization } from '../tools/five-whys';
import { PMapUtilization } from '../tools/pmap';
import { ToolType } from '../tools';
import { PfmUtilization } from '../tools/pfm';
import { CftUtilization } from '../tools/cft';

export type ToolUtilizationType =
  | FortyPrinciplesUtilization
  | RrmUtilization
  | UimUtilization
  | CecUtilization
  | ApaUtilization
  | EbsUtilization
  | NineWindowsUtilization
  | FiveWhysUtilization
  | PMapUtilization
  | SfmUtilization
  | PfmUtilization
  | CftUtilization;

export type UtilizationTypesMap = {
  [T in ToolType]: {
    [ToolType.TOOL_40_PRINCIPLES]: FortyPrinciplesUtilization;
    [ToolType.RRM]: RrmUtilization;
    [ToolType.UIM]: UimUtilization;
    [ToolType.FIVE_WHYS]: FiveWhysUtilization;
    [ToolType.APA]: ApaUtilization;
    [ToolType.EBS]: EbsUtilization;
    [ToolType.NINE_WINDOWS]: NineWindowsUtilization;
    [ToolType.CEC]: CecUtilization;
    [ToolType.P_MAP]: PMapUtilization;
    [ToolType.SFM]: SfmUtilization;
    [ToolType.PFM]: PfmUtilization;
    [ToolType.CFT]: CftUtilization;
  }[T];
};

export interface UtilizationCollectionStatuses {
  byTeamId: { [projectId: number]: EntityCollectionStatus };
  byProjectId: { [projectId: number]: EntityCollectionStatus };
  byUtilizationId: { [utilizationId: number]: EntityCollectionStatus };
  byPublicId: { [publicId: string]: EntityCollectionStatus };
}

export interface UtilizationCollectionLookups {
  byTeamId: { [teamId: number]: number[] };
  byProjectId: { [projectId: number]: number[] };
  byPublicId: { [publicId: string]: number };
}

export interface UtilizationCollection {
  entities: { [id: number]: ToolUtilizationType };
  lookups: UtilizationCollectionLookups;
  statuses: UtilizationCollectionStatuses;
}

export const DefaultUtilizationCollection: UtilizationCollection = {
  entities: {},

  lookups: {
    byTeamId: {},
    byProjectId: {},
    byPublicId: {},
  },

  statuses: {
    byTeamId: {},
    byProjectId: {},
    byUtilizationId: {},
    byPublicId: {},
  },
};
