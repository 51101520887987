import { IdRef } from '../../common/id-ref';

export class Parameter {
  public id?: number;
  public title: string;
  public titleZh?: string;
  public titleEs?: string;
  public description?: string;
  public descriptionZh?: string;
  public descriptionEs?: string;
  public createdBy: IdRef;
  public dateCreated: Date;
  public updatedBy?: IdRef;
  public lastUpdated?: Date;

  constructor(json: Parameter) {
    this.id = json.id;
    this.title = json.title;
    this.titleZh = json.titleZh;
    this.titleEs = json.titleEs;
    this.description = json.description;
    this.descriptionZh = json.descriptionZh;
    this.descriptionEs = json.descriptionEs;
    this.createdBy = json.createdBy;
    this.dateCreated = json.dateCreated && new Date(json.dateCreated);
    this.updatedBy = json.updatedBy;
    this.lastUpdated = json.lastUpdated && new Date(json.lastUpdated);
  }
}
