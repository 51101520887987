import React from 'react';
import { Slider, SliderProps } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FieldTitle, HelperText } from '@priz/shared/src/components/form-elements';
import { Box } from '@mui/material';
import {
  ReactHookFormSetValue,
  ValidatorControllerProps,
  ReactHookFormElement,
  DefaultInput,
} from '@priz/shared/src/models/form';

export type ReactHookFormSliderProps = ReactHookFormElement &
  DefaultInput &
  ValidatorControllerProps &
  SliderProps & {
    setValue: ReactHookFormSetValue;
  };

export const ReactHookFormSlider: React.FC<ReactHookFormSliderProps> = ({
  name,
  control,
  setValue,
  fieldTitle,
  fieldTitleWrapperProps,
  helperText,
  wrapperProps,
  rules,
  ...rest
}) => {
  return (
    <Box mb={3} {...wrapperProps}>
      <FieldTitle text={fieldTitle} {...fieldTitleWrapperProps} />

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <>
            <Slider
              value={field.value}
              onChange={(_e, val) => {
                setValue(name, val);
                field.onChange(val);
              }}
              valueLabelDisplay="auto"
              {...rest}
            />

            <HelperText error={error?.message} text={helperText} />
          </>
        )}
      />
    </Box>
  );
};
