import React from 'react';
import { Trans } from 'react-i18next';
import { Badge, Box } from '@mui/material';
import { useStyles } from './styles';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';

import { ReactComponent as GlobeIcon } from '../../../assets/icons/globe.svg';

export const ProjectGlobeButton: React.FC = () => {
  const styles = useStyles();

  return (
    <PopperWrap openHandler={'hover'} variant={'blue'}>
      <Badge variant="dot" color="success">
        <GlobeIcon className={styles.svg} />
      </Badge>

      <Box maxWidth={280}>
        <Trans>Your project is published on PrizHub</Trans>
      </Box>
    </PopperWrap>
  );
};
