import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToolUtilizationSelector } from '../../project/store/selectors';
import { PageTitleWithDocLink } from '../../shared/PageTitleWithDocLink';
import { RrmGoalType } from '@priz/shared/src/models/tools/rrm/rrm-goal-type.enum';
import { Goal } from '@priz/shared/src/models/tools/rrm';
import { RrmGoalSelector } from '../store/selectors';
import { RrmOptionsSelector } from './RrmOptionsSelector';
import { RrmRankingProgress } from './RrmRankingProgress';
import { Trans } from 'react-i18next';
import { ToolTitle } from '../../tools/tool-title/component';
import { ContentContainer } from '../../content-containers/page-container-with-aside-nav/content-container/component';
import { ContentFooter } from '../../content-containers/page-container-with-aside-nav/content-footer/component';
import { ProjectContentControlBar } from '../../react/project-content-control-bar/component';

export interface RrmRankingManagerProps {
  utilizationId: number;
}

const resolvePageTitle = (goal: Goal): string => {
  switch (goal.goalType) {
    case RrmGoalType.SELF:
      return 'Ranking the options';
    case RrmGoalType.IDEA:
      return 'Ranking your ideas';
    case RrmGoalType.TASK:
      return 'Ranking your tasks';
    case RrmGoalType.UIM_TASK:
      return 'Ranking of tasks';
    case RrmGoalType.EBS:
      return 'Ranking of brainstorming tasks';
    case RrmGoalType.FIVE_WHYS_SOLUTION:
      return 'Ranking of Root Causes';
    default:
      return 'Ranking the options';
  }
};

export const RrmRankingManager: React.FC<RrmRankingManagerProps> = ({ utilizationId }) => {
  const [title, setTitle] = useState('');

  const utilization = useSelector(ToolUtilizationSelector.getRrmUtilization(utilizationId));
  const goal = useSelector(RrmGoalSelector.getGoalById(utilization && utilization.goal.id));

  useEffect(() => {
    if (goal) {
      setTitle(resolvePageTitle(goal));
    }
  }, [goal]);

  return (
    <>
      <ContentContainer>
        {title && (
          <PageTitleWithDocLink
            titleComponent={<ToolTitle initialTitle={title} tool={utilization} preset={'large'} editable={false} />}
            oneLiner={<Trans>The easiest known method to compare more than two items</Trans>}
            docsUrl={'https://www.priz.guru/knowledge-base/round-robin-ranking/'}
          />
        )}
        {utilization && (
          <>
            <RrmRankingProgress utilization={utilization} />
            <RrmOptionsSelector utilization={utilization} />
          </>
        )}
      </ContentContainer>

      <ContentFooter>
        <ProjectContentControlBar projectId={utilization?.project?.id} utilization={utilization} />
      </ContentFooter>
    </>
  );
};
