import React, { ReactNode } from 'react';
import { Box, BoxProps, Button, Grid, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { useStyles } from './styles';

interface GuideCardProps extends BoxProps {
  title: string;
  buttonText?: string;
  icon?: ReactNode;
  onButtonClick?: () => void;
}

export const GuideCard: React.FC<GuideCardProps> = ({ title, buttonText, icon, onButtonClick, ...rest }) => {
  const styles = useStyles();

  return (
    <Box className={styles.root} {...rest}>
      <Grid container alignItems={'center'} flexWrap={'nowrap'}>
        {icon && (
          <Grid item xs={'auto'}>
            <Box p={0.25}>{icon}</Box>
          </Grid>
        )}

        <Grid item xs={true} overflow={'hidden'}>
          <Box px={1}>
            <Typography variant={'body2'} noWrap>
              <Trans>{title}</Trans>
            </Typography>
          </Box>
        </Grid>

        {onButtonClick && (
          <Grid item xs={'auto'}>
            <Button className={styles.button} size={'pg_medium'} onClick={onButtonClick}>
              <Trans>{buttonText || 'show'}</Trans>
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
