import { Locales } from '../../../models/locales';
import { LocalStorageKey, LocalStorageService } from '../../../services/local-storage';

const getCurrentLocale = (): Locales => (LocalStorageService.getItem(LocalStorageKey.Locale) as Locales) || Locales.EN;

const changeLocale = (locale: Locales) => {
  LocalStorageService.setItem(LocalStorageKey.Locale, locale);
  location.reload();
};

export const LocalesService = {
  getCurrentLocale,
  changeLocale,
};
