import { EntityCollectionStatus, EntityMap, LookupMap } from '../../common/entity-collection-state';
import { FiveWhysSolution } from './five-whys-solution';

interface FiveWhysSolutionLookups {
  byUtilizationId: LookupMap;
  byCauseId: EntityMap<number>;
}

export interface FiveWhysSolutionCollection {
  entities: EntityMap<FiveWhysSolution>;

  lookups: FiveWhysSolutionLookups;

  statuses: {
    byUtilizationId?: { [utilizationId: number]: EntityCollectionStatus };
  };
}

export const DefaultFiveWhysSolutionCollection = {
  entities: {},

  lookups: {
    byCauseId: {},
    byUtilizationId: {},
  },

  statuses: {
    byUtilizationId: {},
  },
};
