import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  list: {
    flex: '1 0 0',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
});
