import { EbsIdea, EbsIdeaCategory, EbsIdeaDifficultyVariant, EbsIdeaNodeProps } from '../../models/tools/ebs';
import { ArrayUtils, SortDirection } from '../../utils/common';
import { Network } from '../../lib/vis/esnext';
import { DataSet } from 'vis-data';
import { NodeProps } from '../../models/vis-network';
import { calcNodeSizeByCanvasPosition } from '../../components/network-diagram/utils';

export const resolveNodeType = (implementation?: string, validation?: string): EbsIdeaCategory => {
  const key = [implementation, validation].join();

  switch (key) {
    case [EbsIdeaDifficultyVariant.Easy, EbsIdeaDifficultyVariant.Easy].join():
      return EbsIdeaCategory.IE_VE;
    case [EbsIdeaDifficultyVariant.Easy, EbsIdeaDifficultyVariant.NotEasy].join():
      return EbsIdeaCategory.IE_VN;
    case [EbsIdeaDifficultyVariant.NotEasy, EbsIdeaDifficultyVariant.Easy].join():
      return EbsIdeaCategory.IN_VE;
    case [EbsIdeaDifficultyVariant.NotEasy, EbsIdeaDifficultyVariant.NotEasy].join():
      return EbsIdeaCategory.IN_VN;
    default:
      return EbsIdeaCategory.NOT_DEFINED;
  }
};

export const splitIdeasOnCategories = <I extends EbsIdea>(
  ideas: I[],
): {
  [key in EbsIdeaCategory]: I[];
} => {
  const categories: { [key in EbsIdeaCategory]: I[] } = {
    [EbsIdeaCategory.IE_VE]: [],
    [EbsIdeaCategory.IE_VN]: [],
    [EbsIdeaCategory.IN_VE]: [],
    [EbsIdeaCategory.IN_VN]: [],
    [EbsIdeaCategory.NOT_DEFINED]: [],
  };

  ideas.forEach(item => {
    const { implementation, validation } = item;
    const type = resolveNodeType(implementation, validation);

    categories[type].push(item);
  });

  Object.keys(categories).forEach(c => {
    const key = c as EbsIdeaCategory;
    categories[key] = categories[key].sort(ArrayUtils.sorterByParam('latestRankingScore', SortDirection.DESC));
  });

  return categories;
};

type EbsCategoryValidationResult = {
  itemsCount: number;
  isCountValid: boolean;
  isContentValid: boolean;
};

export type EbsCategoriesValidationResult = {
  [key in EbsIdeaCategory]: EbsCategoryValidationResult;
};

export const validateEbsIdeasCategories = <I extends EbsIdea>(map: {
  [key in EbsIdeaCategory]: I[];
}): EbsCategoriesValidationResult => {
  const initialValues: EbsCategoryValidationResult = { itemsCount: 0, isCountValid: false, isContentValid: false };

  const result: EbsCategoriesValidationResult = {
    [EbsIdeaCategory.IE_VE]: { ...initialValues },
    [EbsIdeaCategory.IE_VN]: { ...initialValues },
    [EbsIdeaCategory.IN_VE]: { ...initialValues },
    [EbsIdeaCategory.IN_VN]: { ...initialValues },
    [EbsIdeaCategory.NOT_DEFINED]: { ...initialValues },
  };

  (Object.keys(map || {}) as EbsIdeaCategory[]).map(key => {
    if (result[key]) {
      const category = map[key as EbsIdeaCategory];

      if (category) {
        result[key].itemsCount = category.length;
        result[key].isCountValid = category.length >= 3;
        result[key].isContentValid = !category.some(idea => !idea.description?.length);
      }
    }
  });

  return result;
};

export const splitOnCategoriesAndValidate = <I extends EbsIdea>(ideas: I[]): EbsCategoriesValidationResult => {
  return validateEbsIdeasCategories(splitIdeasOnCategories(ideas));
};

export const convertEbsIdeasToNodes = (ideas: EbsIdea[]): EbsIdeaNodeProps[] => {
  return ideas.map(({ description, implementation, validation, ...rest }) => ({
    ...rest,
    description,
    implementation,
    validation,
    label: description,
    type: resolveNodeType(implementation, validation),
  }));
};

export const convertEbsNodesToIdeas = (nodes: EbsIdeaNodeProps[]): EbsIdea[] => {
  return nodes.map(({ label, ...rest }) => {
    const restProps = JSON.parse(JSON.stringify(rest));

    if (restProps.extraRenderFunction) delete restProps.extraRenderFunction;

    return { ...rest, description: label };
  });
};

interface MosaicSortingProps {
  network: Network;
  nodes: NodeProps[];
  xMlt?: 1 | -1;
  yMlt?: 1 | -1;
  spacing?: number;
  startOffsetX?: number;
  startOffsetY?: number;
  extraColumns?: number;
}

type MosaicSortResult = {
  id: number;
  x: number;
  y: number;
}[];

export const getMosaicSortedNodesParameters = ({
  network,
  nodes,
  xMlt = 1,
  yMlt = 1,
  spacing = 12,
  startOffsetX = 50,
  startOffsetY = 80,
  extraColumns = 0,
}: MosaicSortingProps): MosaicSortResult => {
  const newNodesPositions = [];
  const matrixSize = Math.max(Math.ceil(Math.sqrt(nodes.length)) + extraColumns, 2);
  const rowsWidth = Array(matrixSize).fill(startOffsetX * xMlt);
  let previousRowHeight = startOffsetY;

  for (let n = 0, row = 0; n <= nodes.length; row++, n += matrixSize) {
    let maxNodeHeightInCurrentRow = 0;

    for (let col = 0; col < matrixSize; col++) {
      const node = nodes[n + col];

      if (node) {
        const { width, height } = calcNodeSizeByCanvasPosition(network.getBoundingBox(node.id));

        rowsWidth[row] += (width / 2) * xMlt;
        newNodesPositions.push({ id: node.id, x: rowsWidth[row], y: (previousRowHeight + height / 2) * yMlt });
        rowsWidth[row] += (width / 2 + spacing) * xMlt;

        maxNodeHeightInCurrentRow = Math.max(maxNodeHeightInCurrentRow, height);
      }
    }

    previousRowHeight += maxNodeHeightInCurrentRow + spacing;
  }

  // TODO: node.id: number | string; MosaicSortResult -> id: number
  return newNodesPositions as MosaicSortResult;
};

export const hasAnyCoords = (nodes: EbsIdeaNodeProps[]) => {
  return !!nodes.find(({ x, y }) => {
    if (typeof x !== 'undefined' && typeof y !== 'undefined') {
      return true;
    }
  });
};

export const updateNodesPositionIfUnset = (network: Network, nodesDataSet: DataSet<EbsIdeaNodeProps>) => {
  const nodes = nodesDataSet.get();

  if (!hasAnyCoords(nodes)) {
    nodesDataSet.updateOnly(getMosaicSortedNodesParameters({ network, nodes }));
    network.fit();
  }
};
