import React from 'react';
import { UimPriority, UimTask } from '../../models/tools/uim';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Trans } from 'react-i18next';
import { useStyles } from './styles';
import { PriorityIndicator } from '../../components/priority-indicator/component';
import { ArrayUtils, SortDirection } from '../../utils/common';
import { filterTasksByPriority } from '../../utils/uim';

interface UimTaskSummaryTableProps {
  tasks: UimTask[];
  priority: UimPriority;
  controls?: JSX.Element;
}

export const UimTaskSummaryTable: React.FC<UimTaskSummaryTableProps> = ({ tasks, priority, controls }) => {
  const styles = useStyles();
  const priorityTasks = filterTasksByPriority(tasks, priority);

  if (priorityTasks.length === 0) {
    return null;
  }

  return (
    <>
      <Box mb={0.5}>
        <Grid container spacing={2} alignItems={'flex-end'} justifyContent={'space-between'}>
          <Grid item>
            <PriorityIndicator priority={priority} size={'small'} rounded={true} />
          </Grid>

          {controls && <Grid item>{controls}</Grid>}
        </Grid>
      </Box>

      <TableContainer component={Paper} variant={'outlined'} className={styles.root}>
        <Table className={styles.table} size={'small'}>
          <TableHead className={styles.header}>
            <TableRow>
              <TableCell className={styles.cell}>
                <Trans>Task</Trans>
              </TableCell>
              <TableCell align="center" className={styles.cell}>
                <Trans>Rank</Trans>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody className={styles.body}>
            {priorityTasks.sort(ArrayUtils.sorterByParam('latestRankingScore', SortDirection.DESC)).map(task => (
              <TableRow key={task.id}>
                <TableCell component="th" scope="row" className={styles.cell}>
                  <Box p={1}>
                    <Typography variant={'body1'}>{task.description}</Typography>
                  </Box>
                </TableCell>

                <TableCell align="center" className={styles.cell} width={'80px'}>
                  <Typography variant={'subtitle2'}>{task.latestRankingScore}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
