export interface CftNodePosition {
  inVariant?: boolean;
  inProcess?: boolean;
  inRootBranch?: boolean;
}

export enum CftNodeType {
  Statement = 'Statement',
  Abstraction = 'Abstraction',
  Step = 'Step',
  Variant = 'Variant',
  Process = 'Process',
}

export enum CftNodeStatus {
  Verified = 'Verified',
  Problematic = 'Problematic',
  Blocker = 'Blocker',
}

export interface CftNodeData {
  id: string;
  type: CftNodeType;
  to: string[];
  status?: CftNodeStatus;
  description?: string;
  notes?: string;
  root?: boolean;
}

export type CftNodesMap = {
  [nodeId: string]: CftNodeData;
};

export type CftNodesCollection = {
  nodesArray: CftNodeData[];
  nodesMap: CftNodesMap;
};
