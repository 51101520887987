import { FluxStandardAction } from 'flux-standard-action';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { createPgAction } from '../../../shared/store/action-creator';
import { ActionMeta, RouterActionMeta } from '../../../shared/store/types';
import { CreateTaskCommand, TaskApi } from '../../services/task.api';
import { ITask, Task, TaskStatus } from '@priz/shared/src/models/task';
import { ToolType } from '@priz/shared/src/models/tools';

export enum TaskActionType {
  Tasks_Fetch = 'tasks/fetch',
  Task_Fetch = 'task/fetch',
  Task_Create = 'task/create',
  Task_Update = 'task/update',
  Task_Update_Status = 'task/update/status',
  Task_Update_DueDate = 'task/update/dueDate',
  Task_Delete = 'task/delete',
}

interface TaskActionMeta extends ActionMeta, RouterActionMeta {
  projectId?: number;
  taskId?: number;
  sourceToolType?: ToolType;
  sourceToolUtilizationId?: number;
  task?: Task;
  rankingScore?: number;
}

type TaskActionPayload = ITask[] | ITask;

export type TaskAction = FluxStandardAction<string, TaskActionPayload, TaskActionMeta>;

const fetchAllByProjectId = createPgAction(
  TaskActionType.Tasks_Fetch,
  TaskApi.getAllByProjectId,
  (projectId: number) => ({ projectId }),
  undefined,
  undefined,
);

const fetchByProjectIdAndTaskId = createPgAction(
  TaskActionType.Task_Fetch,
  TaskApi.getByProjectIdAndTaskId,
  (projectId: number, taskId: number) => ({ projectId, taskId }),
  undefined,
  undefined,
);

const create = createPgAction(
  TaskActionType.Task_Create,
  TaskApi.create,
  (projectId: number, command: CreateTaskCommand) => ({
    projectId,
    sourceToolType: command.sourceToolType,
    sourceToolUtilizationId: command.sourceToolUtilizationId,
  }),
  (taskPayload: ITask, meta: TaskActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.TASK_CREATED, {
      project_id: taskPayload.project.id,
      source_tool_Id: meta.sourceToolUtilizationId,
      source_tool_type: meta.sourceToolType,
    });
  },
);

const update = createPgAction(
  TaskActionType.Task_Update,
  TaskApi.update,
  (task: Task) => ({ task, taskId: task.id, projectId: task.project.id }),
  (taskPayload: ITask, meta: TaskActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.TASK_UPDATED, {
      project_id: taskPayload.project.id,
      source_tool_Id: meta.sourceToolUtilizationId,
      source_tool_type: meta.sourceToolType,
      task_id: taskPayload.id,
    });
  },
);

const updateStatus = createPgAction(
  TaskActionType.Task_Update_Status,
  TaskApi.updateStatus,
  (projectId: number, taskId: number, status: TaskStatus) => ({ projectId, taskId, status }),
  (taskPayload: ITask, meta: TaskActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.TASK_STATUS_UPDATED, {
      project_id: taskPayload.project.id,
      source_tool_Id: meta.sourceToolUtilizationId,
      source_tool_type: meta.sourceToolType,
      task_id: taskPayload.id,
      status: taskPayload.status,
    });
  },
);

const updateDueDate = createPgAction(
  TaskActionType.Task_Update_DueDate,
  TaskApi.updateDueDate,
  (projectId: number, taskId: number, dueDate: Date) => ({ projectId, taskId, dueDate }),
  (taskPayload: ITask, meta: TaskActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.TASK_DUE_DATE_UPDATED, {
      project_id: taskPayload.project.id,
      source_tool_Id: meta.sourceToolUtilizationId,
      source_tool_type: meta.sourceToolType,
      task_id: taskPayload.id,
      due_date: taskPayload.dueDate,
    });
  },
);

const doDelete = createPgAction(
  TaskActionType.Task_Delete,
  TaskApi.delete,
  (task: Task) => ({ task, taskId: task.id, projectId: task.project.id }),
  (_, meta: TaskActionMeta) => {
    AnalyticsService.track(AnalyticsEvent.TASK_DELETED, {
      project_id: meta.task.project.id,
      source_tool_Id: meta.task.sourceToolUtilization && meta.task.sourceToolUtilization.id,
      source_tool_type: meta.task.sourceToolType,
      task_id: meta.task.id,
    });
  },
);

export const TaskActions = {
  create,
  delete: doDelete,
  fetchAllByProjectId,
  fetchByProjectIdAndTaskId,
  update,
  updateStatus,
  updateDueDate,
};
