import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { useStyles } from './styles';
import { BoxProps } from '@mui/material';
import { SfmEdgeType, SfmNodeType, SfmUtilization } from '@priz/shared/src/models/tools/sfm';
import { NetworkDiagram } from '@priz/shared/src/components/network-diagram/component';
import {
  addEdge,
  addNode,
  bindNetworkEvents,
  minifyEdgesProps,
  minifyNodesProps,
  unbindNetworkEvents,
  updateNode,
  useNetworkDataset,
} from '@priz/shared/src/components/network-diagram/utils';
import { edgePropsDecorator, nodePropsDecorator, options } from '@priz/shared/src/data/sfm-options';
import { NetworkElementsEditorMenu } from '../../react/network/network-elements-editor-menu/component';
import { Network } from '@priz/shared/src/lib/vis/esnext';
import { DataSet } from 'vis-data';
import { EdgeProps, NodeProps } from '@priz/shared/src/models/vis-network';
import { ComponentIcon, SuperSystemIcon } from '../sfm-nodes-icons';
import { ExcessiveIcon, HarmfulIcon, InsufficientIcon, UsefulIcon } from '../sfm-edges-icons';
import { ProductIcon } from '../sfm-nodes-icons/product-icon';
import { WorkspaceSelectors } from '../../workspace/store/selectors';
import { PaywallActions } from '../../react/modules/paywall/store';
import { ToolType } from '@priz/shared/src/models/tools';
import { NetworkToolsMenu } from '../../react/network/network-tools-menu/component';
import { resolveSfmNetworkData } from '@priz/shared/src/utils/sfm';
import { FullscreenContainer } from '@priz/shared/src/components/fullscreen-container/component';

import { ReactComponent as AddRectangleIcon } from '../../../assets/icons/add-rectangle.svg';
import { ReactComponent as AddRoundIcon } from '../../../assets/icons/add-round.svg';

interface SfmWorkspaceProps extends BoxProps {
  utilization: SfmUtilization;
  activeVersionId?: string;
  onDataChange?: (data: { nodes: NodeProps[]; edges: EdgeProps[] }) => void;
  viewMode?: boolean;
  disabled?: boolean;
  fitOnInit?: boolean;
  historyKeyboardControl?: boolean;
}

const networkEvents = (
  network: Network,
  nodesDataSet: DataSet<NodeProps>,
  controlNodeDragEndCallback: (fromId: string, coords: { x: number; y: number }) => void,
) => ({
  dragStart: data => {
    if (data?.nodes?.length) {
      network.unselectAll();
      network.selectNodes(data.nodes, false);
    }
  },
  dragEnd: data => {
    if (data?.nodes?.length) {
      updateNode({ nodesDataSet, id: data.nodes[0], data: { ...network.getPosition(data.nodes[0]) } });
    }
  },
  controlNodeDragEnd: data => {
    const {
      controlEdge: { from, to },
      pointer: { canvas },
    } = data;

    if (from && !to) {
      controlNodeDragEndCallback(from, canvas);
    }
  },
});

export const SfmWorkspace: React.FC<SfmWorkspaceProps> = ({
  utilization,
  activeVersionId,
  onDataChange,
  viewMode = false,
  disabled,
  fitOnInit,
  historyKeyboardControl,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [network, setNetwork] = useState(null);
  const [edgeMode, setEdgeMod] = useState(true);
  const [networkIsFit, setNetworkIsFit] = useState(false);
  const { nodesDataSet, edgesDataSet } = useNetworkDataset(
    resolveSfmNetworkData(utilization.diagramData, activeVersionId),
    nodePropsDecorator,
    edgePropsDecorator,
  );

  const featureSet = useSelector(WorkspaceSelectors.getApplicableFeatureSet(utilization.project?.id));

  useEffect(() => {
    nodesDataSet.on('*', dataSetChangeCallback);
    edgesDataSet.on('*', dataSetChangeCallback);

    return () => {
      nodesDataSet.off('*', dataSetChangeCallback);
      edgesDataSet.off('*', dataSetChangeCallback);
    };
  }, []);

  const dataSetChangeCallback = useCallback(
    debounce(() => {
      saveData();
    }, 1000),
    [],
  );

  useEffect(() => {
    const events = networkEvents(network, nodesDataSet, controlNodeDragEndHandler);

    if (network) {
      bindNetworkEvents(network, events);
    }

    return () => {
      if (network) {
        unbindNetworkEvents(network, events);
      }
    };
  }, [network, featureSet]);

  useEffect(() => {
    if (network && fitOnInit && !networkIsFit) {
      network.fit();
      setNetworkIsFit(true);
    }
  }, [network, fitOnInit, networkIsFit]);

  const saveData = () => {
    if (onDataChange) {
      onDataChange({
        nodes: minifyNodesProps(nodesDataSet.get()),
        edges: minifyEdgesProps(edgesDataSet.get()),
      });
    }
  };

  const checkIsLimitReached = (): boolean => {
    return featureSet?.Tools?.SFM?.maxItems && nodesDataSet.get().length >= featureSet?.Tools?.SFM?.maxItems;
  };

  const controlNodeDragEndHandler = (fromId: string, coords: { x: number; y: number }) => {
    if (checkIsLimitReached()) {
      dispatch(PaywallActions.show(ToolType.SFM));
    } else {
      addNode({
        nodeProps: {
          type: SfmNodeType.COMPONENT,
          ...coords,
        },
        edgeProps: {
          from: fromId,
          type: SfmEdgeType.USEFUL,
        },
        nodesDataSet,
        edgesDataSet,
        nodePropsDecorator,
        edgePropsDecorator,
      });
    }
  };

  const networkInitHandler = network => {
    setNetwork(network);
  };

  const toggleEdgeModHandler = isEnabled => {
    setEdgeMod(isEnabled);
  };

  const addEdgeBetweenNodeHandler = (from: string, to: string) => {
    addEdge({
      edgesDataSet,
      edgePropsDecorator,
      edgeProps: { from, to, type: SfmEdgeType.USEFUL },
    });
  };

  return (
    <div className={styles.root}>
      <FullscreenContainer className={styles.container}>
        <NetworkDiagram
          options={options}
          nodesDataSet={nodesDataSet}
          edgesDataSet={edgesDataSet}
          onNetworkInit={networkInitHandler}
          edgeMode={edgeMode}
          onAddEdge={addEdgeBetweenNodeHandler}
          viewMode={disabled}
          styledCursor={true}
        />

        {network && (
          <>
            <NetworkToolsMenu
              network={network}
              nodesDataSet={nodesDataSet}
              edgesDataSet={edgesDataSet}
              onToggleEdgeMod={toggleEdgeModHandler}
              nodePropsDecorator={nodePropsDecorator}
              viewMode={viewMode}
              disabled={disabled}
              controls={['edge-mode', 'history']}
              checkIsLimitReached={checkIsLimitReached}
              toolType={ToolType.SFM}
              historyKeyboardControl={historyKeyboardControl}
              addNodeButtons={[
                {
                  type: SfmNodeType.COMPONENT,
                  icon: AddRoundIcon,
                  tooltip: 'Add Component node',
                },
                {
                  type: SfmNodeType.SUPER_SYSTEM,
                  icon: AddRectangleIcon,
                  tooltip: 'Add Supersystem node',
                },
              ]}
            />

            {!viewMode && (
              <NetworkElementsEditorMenu
                disabled={disabled}
                menuInitiallyOpen={true}
                focusInputOnElementSelect={true}
                network={network}
                nodesDataSet={nodesDataSet}
                edgesDataSet={edgesDataSet}
                nodePropsDecorator={nodePropsDecorator}
                edgePropsDecorator={edgePropsDecorator}
                disableChangeNodeTypeFor={[SfmNodeType.PRODUCT]}
                disableRemoveNodeFor={[SfmNodeType.PRODUCT]}
                uniqueNodesTypes={[{ type: SfmNodeType.PRODUCT, replaceByType: SfmNodeType.COMPONENT }]}
                changeNodeTypes={[
                  {
                    type: SfmNodeType.COMPONENT,
                    icon: ComponentIcon,
                    activeTooltip: 'Switch to Component',
                  },
                  {
                    type: SfmNodeType.SUPER_SYSTEM,
                    icon: SuperSystemIcon,
                    activeTooltip: 'Switch to Supersystem',
                  },
                  {
                    type: SfmNodeType.PRODUCT,
                    icon: ProductIcon,
                    activeTooltip: 'Switch to Product',
                  },
                ]}
                changeEdgeTypes={[
                  {
                    type: SfmEdgeType.USEFUL,
                    icon: UsefulIcon,
                    activeTooltip: 'Switch to Useful',
                  },
                  {
                    type: SfmEdgeType.HARMFUL,
                    icon: HarmfulIcon,
                    activeTooltip: 'Switch to Harmful',
                  },
                  {
                    type: SfmEdgeType.EXCESSIVE,
                    icon: ExcessiveIcon,
                    activeTooltip: 'Switch to Excessive',
                  },
                  {
                    type: SfmEdgeType.INSUFFICIENT,
                    icon: InsufficientIcon,
                    activeTooltip: 'Switch to Insufficient',
                  },
                ]}
              />
            )}
          </>
        )}
      </FullscreenContainer>
    </div>
  );
};
