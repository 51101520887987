import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  iconWrap: {
    width: 40,
    height: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    'color': pgColorScheme.green,
    'opacity': 0.4,

    '&._loading': {
      color: pgColorScheme.blue,
      opacity: 1,
    },
  },
  chip: {
    background: pgColorScheme.gray2,
  },
});
