import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';

export const useStyles = makeStyles({
  userInfoCell: {
    borderBottom: 'unset',
  },
  detailsRow: {
    backgroundColor: pgColorScheme.gray100,
  },
});
