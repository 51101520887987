export enum NewsletterSubscriptionStatus {
  subscribed = 'subscribed',
  unsubscribed = 'unsubscribed',
  cleaned = 'cleaned',
  pending = 'pending',
  archived = 'archived',
}

export interface NewsletterEmailsSubscriptionInfo {
  exist: boolean;
  status?: NewsletterSubscriptionStatus;
}
