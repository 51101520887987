import React, { useEffect, useState } from 'react';
import { Attendee, AttendeeStatus } from '../store/model';
import { Box } from '@mui/material';
import { PopperWrap } from '@priz/shared/src/components/popper-wrap/component';
import { AttendeeRoleEditor } from '../attendee-role-editor/component';
import { useSelector } from 'react-redux';
import { AttendeeSelectors } from '../store/selectors';

interface AttendeeRolePopperProps {
  attendee: Attendee;
  onAccept?: (status: AttendeeStatus, explanation: string) => void;
  onDecline?: () => void;
}

export const AttendeeRolePopper: React.FC<AttendeeRolePopperProps> = ({ attendee, onAccept, onDecline, children }) => {
  const [isExplanationOpen, setIsExplanationOpen] = useState(false);

  const isUpdating = useSelector(AttendeeSelectors.isUpdating(attendee.project.id));
  const isUpdated = useSelector(AttendeeSelectors.isUpdated(attendee.project.id));

  const openExplanation = () => {
    setIsExplanationOpen(true);
  };

  const closeExplanation = () => {
    setIsExplanationOpen(false);
  };

  useEffect(() => {
    if (isUpdated) closeExplanation();
  }, [isUpdated]);

  return (
    <PopperWrap
      open={isExplanationOpen}
      openHandler={'manually'}
      onClose={closeExplanation}
      placement={'bottom-start'}
      containerProps={{
        display: 'inline-block',
        component: 'span',
      }}
    >
      <span onClick={openExplanation}>{children}</span>

      <Box width={500} maxWidth={'calc(100vw - 50px)'}>
        <AttendeeRoleEditor
          attendee={attendee}
          onAccept={onAccept}
          onDecline={onDecline}
          onCancel={closeExplanation}
          loading={isUpdating}
        />
      </Box>
    </PopperWrap>
  );
};
