import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { WorkspaceMembersSelectors } from '../../workspace/store/selectors/workspace-members.selectors';
import { WorkspaceMember } from '@priz/shared/src/models/workspace/workspace-member';
import CreatableSelect, { components, createFilter } from 'react-select';
import { useStyles } from './styles';
import { UserUtils } from '@priz/shared/src/utils/user/user-utils';
import { Avatar, Box, Button, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { Validation } from '../../shared/services/validation';
import { WorkspaceMembershipLevel } from '@priz/shared/src/models/workspace';
import { pgColorScheme } from '@priz/shared/src/theme';
import { FilterConfig } from '../../react/form-elements';

export interface AssigneeSelectorProps {
  defaultValue?: WorkspaceMember;
  onMemberAssign?: (member: WorkspaceMember) => void;
  onInvite?: (email: string) => void;
  excludeIds?: number[];
  disabled?: boolean;
  loading?: boolean;
  isLimitReached?: boolean;
}

const filterConfig: FilterConfig<WorkspaceMember> = {
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  matchFrom: 'any',
  stringify: option => UserUtils.getShowableName(option.data, true),
};

export const AssigneeSelector: React.FC<AssigneeSelectorProps> = ({
  defaultValue,
  onMemberAssign,
  onInvite,
  excludeIds,
  isLimitReached,
  disabled,
  loading,
}) => {
  const styles = useStyles();

  const [inputValue, setInputValue] = useState('');
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);

  const isWorkspaceAdmin = useSelector(
    WorkspaceMembersSelectors.isCurrentMemberOfLevel(WorkspaceMembershipLevel.Admin),
  );

  const memberships: WorkspaceMember[] = useSelector(WorkspaceMembersSelectors.getAll);
  const selectableMembers = excludeIds ? memberships.filter(m => !excludeIds.includes(m.id)) : memberships;

  const handleSelect = (member: WorkspaceMember) => {
    if (onMemberAssign) {
      onMemberAssign(member);
    }
  };

  const handleInputChange = (input: string, { action }) => {
    // : 'set-value' | 'input-change' | 'input-blur' | 'menu-close') => {
    if (action === 'input-change') {
      setInputValue(input);
      if (emailIsInvalid) setEmailIsInvalid(false);
    }
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter' && onInvite && isWorkspaceAdmin) {
      validateAndInvite();
    }
  };

  const addButtonClickHandler = () => {
    validateAndInvite();
  };

  const validateAndInvite = () => {
    if (Validation.isValidEmail(inputValue)) {
      onInvite(inputValue);
    } else if (!emailIsInvalid) {
      setEmailIsInvalid(true);
    }
  };

  const handleSelectClose = () => {
    setEmailIsInvalid(false);
  };

  const Option = props => (
    <components.Option {...props}>
      <Box display={'flex'} alignItems="center" className={styles.option}>
        <Box p={1}>
          <Avatar className={styles.avatar} src={props.data.profile.avatarUrl}>
            {UserUtils.resolveInitials(props.data)}
          </Avatar>
        </Box>
        <Box p={1} flexGrow={1}>
          {UserUtils.getShowableName(props.data, true)}
        </Box>
      </Box>
    </components.Option>
  );

  const sortOptions = (members: WorkspaceMember[]): WorkspaceMember[] => {
    if (defaultValue) {
      return members.sort(m => (m.id === defaultValue.id ? -1 : 0));
    } else {
      return members;
    }
  };

  return (
    <>
      <Typography
        className={`${styles.errorMessage}${emailIsInvalid ? ' _show' : ''}`}
        variant={'caption'}
        component={'div'}
      >
        <Trans>Invalid email</Trans>
      </Typography>

      <CreatableSelect
        options={sortOptions(selectableMembers)}
        defaultValue={defaultValue || null}
        getOptionValue={(option: WorkspaceMember) => option.id.toString()}
        isSearchable={true}
        components={{ Option }}
        filterOption={createFilter(filterConfig)}
        menuPlacement={'auto'}
        menuPosition={'fixed'}
        onChange={handleSelect}
        onInputChange={handleInputChange}
        onMenuClose={handleSelectClose}
        onKeyDown={handleInputKeyDown}
        placeholder={
          isWorkspaceAdmin && onInvite ? (
            <Trans>Select member or type an email to invite...</Trans>
          ) : (
            <Trans>Select member...</Trans>
          )
        }
        noOptionsMessage={() => (
          <div>
            <Trans>No matching members</Trans>

            {onInvite && isWorkspaceAdmin && (
              <Box ml={1} display={'inline-block'}>
                <Button variant={'pg_button_link'} onClick={addButtonClickHandler} disabled={isLimitReached}>
                  <Trans>Invite by email</Trans>
                </Button>
              </Box>
            )}
          </div>
        )}
        styles={{
          option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isSelected
              ? pgColorScheme.backgroundMediumGray
              : isFocused
              ? pgColorScheme.background
              : pgColorScheme.white,
            color: pgColorScheme.darkText,
          }),
        }}
        isDisabled={disabled || loading}
        isLoading={loading}
      />
    </>
  );
};
