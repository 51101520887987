import React, { ReactNode, useState } from 'react';
import { Box, BoxProps, Hidden, IconButton } from '@mui/material';
import { PgPopper } from '../../pg-popper/component';
import { SearchRounded, CloseRounded } from '@mui/icons-material';
import { useStyles } from './styles';

export interface ElementViewerPopperBaseProps {
  backdropProps?: Partial<BoxProps>;
}

interface ElementViewerPopperProps extends ElementViewerPopperBaseProps {
  content: ReactNode;
  position: Partial<DOMRect>;
  onClose?: () => void;
  disable?: boolean;
}

export const ElementViewerPopper: React.FC<ElementViewerPopperProps> = ({
  content,
  position,
  onClose,
  disable,
  backdropProps,
}) => {
  const styles = useStyles();

  const [overlayElement, setOverlayElement] = useState<HTMLDivElement>();

  const overlayRefHandler = (element: HTMLDivElement) => {
    if (!overlayElement && element) {
      setOverlayElement(element);
    }
  };

  if (disable) return null;

  return (
    <>
      <div
        ref={r => overlayRefHandler(r)}
        className={styles.nodeOverlay}
        style={{
          left: position.left,
          top: position.top,
          width: position.width,
          height: position.height,
        }}
      >
        <Box className={styles.backdrop} {...(backdropProps || {})} />
        <SearchRounded className={styles.icon} color={'primary'} display={'block'} />
      </div>

      {overlayElement && (
        <PgPopper
          open={true}
          anchorEl={overlayElement}
          placement={'top'}
          modifiers={[
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: false,
                altBoundary: true,
                tether: true,
                rootBoundary: 'document',
                padding: 8,
              },
            },
            {
              name: 'flip',
              enabled: false,
            },
          ]}
        >
          {onClose && (
            <Hidden mdUp>
              <IconButton className={styles.popupCloseButton} size={'small'} onClick={onClose}>
                <CloseRounded display={'block'} />
              </IconButton>
            </Hidden>
          )}

          <Box minWidth={100} maxWidth={700} maxHeight={'calc(100vh - 40px)'} overflow={'auto'}>
            {content}
          </Box>
        </PgPopper>
      )}
    </>
  );
};
