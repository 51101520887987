import React from 'react';
import { Box, Grid, PopperProps } from '@mui/material';
import { useStyles } from './styles';
import { AssistantSvg } from '../assistant-svg/component';
import { HintContent, SharedHintContentProps } from '../hint-content/component';
import { PgPopper } from '@priz/shared/src/components/pg-popper/component';
import { AssistantHintData } from '../store/model';
import { AssistantControls, SharedAssistantControlsProps } from '../assistant-controls/components';

export interface PopperAssistantProps extends PopperProps {
  hintData?: AssistantHintData;
  loading?: boolean;
  contentProps?: SharedHintContentProps;
  onSelectHintOptions?: (options: string[]) => void;
  controlsProps?: SharedAssistantControlsProps;
}

export const PopperAssistant: React.FC<PopperAssistantProps> = ({
  anchorEl,
  open,
  hintData,
  loading,
  contentProps,
  onSelectHintOptions,
  controlsProps,
  ...rest
}) => {
  const styles = useStyles();

  if (!open) return null;

  return (
    <PgPopper open={open} anchorEl={anchorEl} dropdownProps={{ p: 0 }} {...rest}>
      <Box p={2} maxWidth={500}>
        <HintContent hintData={hintData} loading={loading} onSelect={onSelectHintOptions} {...contentProps} />
      </Box>

      <Grid container justifyContent={'space-between'} alignItems={'flex-end'} spacing={1}>
        <Grid item xs={true}>
          <Box p={2}>
            <AssistantControls
              hintData={hintData}
              hintRowsType={contentProps?.hintRowsType}
              loading={loading}
              containerProps={{ justifyContent: 'flex-start' }}
              {...controlsProps}
            />
          </Box>
        </Grid>

        <Grid item xs={'auto'}>
          <AssistantSvg px={2} pt={2} className={styles.assistantSvg} loading={loading} hint={!!hintData?.text} />
        </Grid>
      </Grid>
    </PgPopper>
  );
};
