import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PrizLoadingOverlay } from '@priz/shared/src/components/priz-loading-overlay/component';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { ProjectActions } from '../../store/actions/project.actions';
import { ProjectStatus } from '@priz/shared/src/models/project';
import { useNavigate } from 'react-router-dom';

export const ProjectWizardCallback: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const createProject = LocalStorageService.getItem(LocalStorageKey.CreateProjectProps);

    if (createProject) {
      LocalStorageService.removeItem(LocalStorageKey.CreateProjectProps);

      dispatch(
        ProjectActions.createProject(
          {
            ...JSON.parse(createProject),
            status: ProjectStatus.InProgress,
          },
          navigate,
        ),
      );
    } else {
      navigate('/');
    }
  }, []);

  return <PrizLoadingOverlay />;
};
