import { EntityCollectionStatus, EntityMap, LookupMap } from '../../common/entity-collection-state';
import { UimTask } from './uim-task';

interface UimTaskLookups {
  byUtilizationId: LookupMap;
}

export interface UimTaskCollectionStatuses {
  byUtilizationId?: { [utilizationId: number]: EntityCollectionStatus };
}

export interface UimTaskCollection {
  entities: EntityMap<UimTask>;

  lookups: UimTaskLookups;

  statuses: UimTaskCollectionStatuses;
}

export const DefaultUimTaskCollection = {
  entities: {},

  lookups: {
    byUtilizationId: {},
  },

  statuses: {
    byUtilizationId: {},
  },
};
