import React from 'react';
import { Alert, Box, Grid, LinearProgress, Pagination, Paper, Skeleton, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

interface DashboardWidgetCardProps {
  title: string;
  currentPage: number;
  itemsCountPerPage: number;
  totalItemsCount: number;
  isLoading: boolean;
  isFetching: boolean;
  placeholder?: React.ReactNode;
  headerControls?: JSX.Element;
  onChangePage?: (page: number) => void;
}

export const DashboardWidgetCard: React.FC<DashboardWidgetCardProps> = ({
  title,
  currentPage,
  itemsCountPerPage,
  totalItemsCount,
  isLoading,
  isFetching,
  placeholder,
  headerControls,
  onChangePage,
  children,
}) => {
  const pageChangeHandler = (_event: React.ChangeEvent, page: number) => {
    if (onChangePage) onChangePage(page - 1);
  };

  return (
    <Paper component={Box} variant={'outlined'} p={2} position={'relative'}>
      {!isLoading && isFetching && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: 'inherit',
            overflow: 'hidden',
          }}
        >
          <LinearProgress />
        </Box>
      )}

      <Box mb={2}>
        <Grid container justifyContent={'space-between'} alignItems={'flex-end'} spacing={2}>
          <Grid item>
            <Typography variant={'h6'}>
              <Trans>{title}</Trans>
            </Typography>
          </Grid>

          {headerControls && <Grid item>{headerControls}</Grid>}
        </Grid>
      </Box>

      {isLoading &&
        [...new Array(4)].map((_item, key) => <Skeleton key={key} animation="wave" height={30} width={'100%'} />)}

      {!isLoading && <Box overflow={'auto'}>{children}</Box>}

      {!isLoading && !totalItemsCount && (
        <>
          {typeof placeholder === 'string' ? (
            <Alert severity={'info'}>
              <Trans>{placeholder || 'Empty'}</Trans>
            </Alert>
          ) : (
            placeholder
          )}
        </>
      )}

      {!isLoading && !!totalItemsCount && (
        <Box mt={2}>
          <Pagination
            page={currentPage + 1}
            count={Math.ceil(totalItemsCount / itemsCountPerPage)}
            onChange={pageChangeHandler}
            size="small"
          />
        </Box>
      )}
    </Paper>
  );
};
