import { UserStatus } from '../security/user-status.enum';
import { Identifiable } from '../common/entity-collection-state';
import { WorkspaceMembershipLevel } from '../workspace/workspace-membership-level.enum';
import { IProfile, Profile } from '../security/profile';

export interface IWorkspaceMember extends Identifiable {
  email: string;
  membershipLevel: WorkspaceMembershipLevel;
  status: UserStatus;
  nonChargeable: boolean;
  profile?: IProfile;
}

export class WorkspaceMember implements IWorkspaceMember {
  public id: number; // this is user id
  public email: string;
  public membershipLevel: WorkspaceMembershipLevel;
  public status: UserStatus;
  public nonChargeable: boolean;
  public profile?: Profile;

  constructor(member: IWorkspaceMember) {
    this.id = member.id;
    this.email = member.email;
    this.membershipLevel = member.membershipLevel;
    this.status = member.status;
    this.nonChargeable = member.nonChargeable;
    this.profile = member.profile && new Profile(member.profile);
  }
}
