import { createSelector, Selector } from 'reselect';
import { ToolUtilizationSelector } from '../../../project/store/selectors';
import { AppState } from '../../../store/app.state';
import { Parameter } from '@priz/shared/src/models/tools/forty-principles/parameter';
import { Principle } from '@priz/shared/src/models/tools/forty-principles/principle';
import { MatrixElement } from '@priz/shared/src/models/tools/forty-principles/matrix-element';
import { Domain } from '@priz/shared/src/models/tools/forty-principles/domain';
import { DomainPrinciple } from '@priz/shared/src/models/tools/forty-principles/domain-principle';

const parameterListSelector = (state: AppState) => state.parameterList;
const principleListSelector = (state: AppState) => state.principleList;
const matrixElementListSelector = (state: AppState) => state.matrixElementList;
const domainListSelector = (state: AppState) => state.domainList;
const domainPrincipleListSelector = (state: AppState) => state.domainPrincipleList;

const getParameters = createSelector(parameterListSelector, list => list.parameters || ([] as Parameter[]));

const getPrinciples = createSelector(principleListSelector, list => list.principles || ([] as Principle[]));

const getMatrixElements = createSelector(
  matrixElementListSelector,
  matrix => matrix.elements || ([] as MatrixElement[]),
);
const isMatrixElementsLoading = createSelector(matrixElementListSelector, list => list?.loading);

const getDomains = createSelector(domainListSelector, list => list.domains || ([] as Domain[]));

const getDomainPrinciples = createSelector(
  domainPrincipleListSelector,
  list => list.domainPrinciples || ([] as DomainPrinciple[]),
);

const isContradictionCompleted = (utilizationId: number): Selector<AppState, boolean> =>
  createSelector(
    ToolUtilizationSelector.get40PUtilization(utilizationId),
    utilization =>
      utilization &&
      utilization.contradictionIf &&
      utilization.contradictionIf.length > 0 &&
      utilization.contradictionThen &&
      utilization.contradictionThen.length > 0 &&
      utilization.contradictionBut &&
      utilization.contradictionBut.length > 0,
  );

const isImprovingParameterCompleted = (utilizationId: number): Selector<AppState, boolean> =>
  createSelector(
    [ToolUtilizationSelector.get40PUtilization(utilizationId), isContradictionCompleted(utilizationId)],
    (utilization, contradictionCompleted) =>
      contradictionCompleted &&
      utilization.improvingParameter &&
      utilization.improvingParameter.id &&
      utilization.improvingParameterDescription &&
      utilization.improvingParameterDescription.length > 0,
  );

const isWorseningParameterCompleted = (utilizationId: number): Selector<AppState, boolean> =>
  createSelector(
    [ToolUtilizationSelector.get40PUtilization(utilizationId), isImprovingParameterCompleted(utilizationId)],
    (utilization, improvingParameterCompleted) =>
      improvingParameterCompleted &&
      utilization.worseningParameter &&
      utilization.worseningParameter.id &&
      utilization.worseningParameterDescription &&
      utilization.worseningParameterDescription.length > 0,
  );

const isParametersLoading = createSelector(parameterListSelector, list => !!list?.statuses?.loading);
const isParametersLoaded = createSelector(parameterListSelector, list => !!list?.statuses?.loaded);
const getParametersUpdatingIds = createSelector(parameterListSelector, list => list?.statuses?.updatingIds || []);

const isParameterUpdatingById = (parameterId: number) =>
  createSelector(parameterListSelector, list => !!list?.statuses?.updatingIds?.includes(parameterId));

const isPrinciplesLoading = createSelector(principleListSelector, list => !!list?.statuses?.loading);
const isPrinciplesLoaded = createSelector(principleListSelector, list => !!list?.statuses?.loaded);
const getPrinciplesUpdatingIds = createSelector(principleListSelector, list => list?.statuses?.updatingIds || []);

const isPrinciplesUpdatingById = (principleId: number) =>
  createSelector(principleListSelector, list => !!list?.statuses?.updatingIds?.includes(principleId));

const isDomainsLoading = createSelector(domainListSelector, list => !!list?.statuses?.loading);
const isDomainsLoaded = createSelector(domainListSelector, list => !!list?.statuses?.loaded);
const getDomainsUpdatingIds = createSelector(domainListSelector, list => list?.statuses?.updatingIds || []);
const getDomainsRemovingIds = createSelector(domainListSelector, list => list?.statuses?.removingIds || []);

const isDomainUpdatingById = (domainId: number) =>
  createSelector(domainListSelector, list => !!list?.statuses?.updatingIds?.includes(domainId));

const isDomainPrinciplesLoading = createSelector(domainPrincipleListSelector, list => !!list?.statuses?.loading);
const isDomainPrinciplesLoaded = createSelector(domainPrincipleListSelector, list => !!list?.statuses?.loaded);
const getDomainPrinciplesUpdatingIds = createSelector(
  domainPrincipleListSelector,
  list => list?.statuses?.updatingIds || [],
);

const isDomainPrincipleUpdatingById = (domainPrincipleId: number) =>
  createSelector(domainPrincipleListSelector, list => !!list?.statuses?.updatingIds?.includes(domainPrincipleId));

export const InventivePrinciplesSelectors = {
  isContradictionCompleted,
  isImprovingParameterCompleted,
  isWorseningParameterCompleted,
  getParameters,
  getPrinciples,
  getMatrixElements,
  getDomains,
  getDomainPrinciples,
  isParametersLoading,
  isParametersLoaded,
  getParametersUpdatingIds,
  isParameterUpdatingById,
  isPrinciplesLoading,
  isPrinciplesLoaded,
  getPrinciplesUpdatingIds,
  isPrinciplesUpdatingById,
  isDomainsLoading,
  isDomainsLoaded,
  getDomainsUpdatingIds,
  getDomainsRemovingIds,
  isDomainUpdatingById,
  isDomainPrinciplesLoading,
  isDomainPrinciplesLoaded,
  getDomainPrinciplesUpdatingIds,
  isDomainPrincipleUpdatingById,
  isMatrixElementsLoading,
};
