import { createSelector, Selector } from 'reselect';
import { EntityCollectionStatus, EntityMap } from '@priz/shared/src/models/common/entity-collection-state';
import { AppState } from '../../../store/app.state';
import { WorkspaceMembersCollection, WorkspaceMembershipLevel } from '@priz/shared/src/models/workspace';
import { WorkspaceMember } from '@priz/shared/src/models/workspace/workspace-member';
import { UserSelectors } from '../../../user/store/selectors/user.selectors';

const workspaceMembersCollectionSelector = (state: AppState): WorkspaceMembersCollection => state.workspaceMembers;

const getWorkspaceMembersEntityMap: Selector<AppState, EntityMap<WorkspaceMember>> = createSelector(
  workspaceMembersCollectionSelector,
  collection => collection.entities,
);

const statusesSelector: Selector<AppState, EntityCollectionStatus> = createSelector(
  workspaceMembersCollectionSelector,
  collection => collection.statuses,
);

const getAll: Selector<AppState, WorkspaceMember[]> = createSelector(getWorkspaceMembersEntityMap, entityMap =>
  Object.values(entityMap),
);

const getById = (id: number): Selector<AppState, WorkspaceMember> =>
  createSelector(getWorkspaceMembersEntityMap, entityMap => entityMap[id]);

const getByEmail = (email: string): Selector<AppState, WorkspaceMember | undefined> =>
  createSelector(getWorkspaceMembersEntityMap, entityMap =>
    Object.values(entityMap).find(item => item.email === email),
  );

const isLoading: Selector<AppState, boolean> = createSelector(statusesSelector, statuses => statuses.loading);
const isLoaded: Selector<AppState, boolean> = createSelector(statusesSelector, statuses => statuses.loaded);
const isCreating: Selector<AppState, boolean> = createSelector(statusesSelector, statuses => statuses.creating);
const isCreated: Selector<AppState, boolean> = createSelector(statusesSelector, statuses => statuses.created);
const isRemoving: Selector<AppState, boolean> = createSelector(statusesSelector, statuses => statuses.removing);
const isFailed: Selector<AppState, boolean> = createSelector(statusesSelector, statuses => statuses.error);
const errorCode: Selector<AppState, number> = createSelector(statusesSelector, statuses => statuses.errorCode);

const isCurrentMemberOfLevel = (membershipLevel: WorkspaceMembershipLevel): Selector<AppState, boolean> =>
  createSelector(
    [UserSelectors.currentUserSelector, getWorkspaceMembersEntityMap],
    (currentUser, membersMap) => currentUser && membersMap[currentUser.id]?.membershipLevel === membershipLevel,
  );

export const WorkspaceMembersSelectors = {
  getWorkspaceMembersEntityMap,
  getAll,
  getById,
  getByEmail,
  isLoaded,
  isLoading,
  isCreating,
  isCreated,
  isRemoving,
  isFailed,
  errorCode,
  isCurrentMemberOfLevel,
};
