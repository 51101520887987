import React from 'react';
import { Divider, List } from '@mui/material';
import { SideNavItem } from '../../shared/section-sidenav-item/component';
import { Trans } from 'react-i18next';

import { ReactComponent as GearIcon } from '../../../assets/icons/gear.svg';
import { ReactComponent as InvoiceIcon } from '../../../assets/icons/invoice.svg';
import { ReactComponent as TeamIcon } from '../../../assets/icons/team.svg';

export interface WorkspaceMenuProps {
  navItemClickHandler?: () => void;
}

export const WorkspaceMenu: React.FC<WorkspaceMenuProps> = ({ navItemClickHandler }) => {
  const clickHandler = () => {
    if (navItemClickHandler) navItemClickHandler();
  };

  return (
    <>
      <List>
        <SideNavItem
          title={<Trans>Settings</Trans>}
          icon={<GearIcon />}
          navigate={['/workspace', 'settings']}
          onClick={clickHandler}
        />

        <Divider style={{ width: '100%' }} />

        <SideNavItem
          title={<Trans>Billing</Trans>}
          icon={<InvoiceIcon />}
          navigate={['/workspace', 'billing']}
          onClick={clickHandler}
        />

        <Divider style={{ width: '100%' }} />

        <SideNavItem
          title={<Trans>Team</Trans>}
          icon={<TeamIcon />}
          navigate={['/workspace', 'members']}
          onClick={clickHandler}
        />
      </List>
    </>
  );
};
