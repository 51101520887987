import React, { useState } from 'react';
import { TemplateChangeDialog } from '../template-change-dialog/component';

interface TemplateChangeDialogContainerProps {
  projectId: number;
  disabled?: boolean;
}

export const TemplateChangeDialogContainer: React.FC<TemplateChangeDialogContainerProps> = ({
  projectId,
  disabled,
  children,
}) => {
  const [isTemplateChangeDialogOpen, setIsTemplateChangeDialogOpen] = useState(false);

  const openTemplateChangeDialog = () => {
    if (disabled) return null;
    setIsTemplateChangeDialogOpen(true);
  };

  const closeTemplateChangeDialog = () => {
    setIsTemplateChangeDialogOpen(false);
  };

  return (
    <>
      <div onClick={openTemplateChangeDialog}>{children}</div>

      <TemplateChangeDialog
        open={isTemplateChangeDialogOpen}
        projectId={projectId}
        onClose={closeTemplateChangeDialog}
      />
    </>
  );
};
