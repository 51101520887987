import { makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { alpha } from '@mui/material';

export const useStyles = makeStyles({
  menu: {
    width: 'calc(100% - 20px)',
    maxWidth: 400,
    height: 'auto',
    maxHeight: 'calc(100% - 20px)',
    padding: '50px 20px 20px',
    boxSizing: 'border-box',
    color: pgColorScheme.darkText,
    background: alpha(pgColorScheme.background, 0.95),
    position: 'absolute',
    top: 10,
    left: 'auto',
    right: 10,
    bottom: 'auto',
    transition: 'opacity .1s ease, transform .5s ease',
    overflow: 'auto',
    zIndex: 89,

    ['&:not(._open)']: {
      opacity: 0,
      pointerEvents: 'none',
    },
  },
  hamburgerContainer: {
    position: 'absolute',
    right: 0,
    left: 'auto',
    bottom: 'auto',
    top: 0,
    zIndex: 99,
  },
  circlePicker: {
    ['&._disabled']: {
      pointerEvents: 'none',
      opacity: 0.3,
    },
  },
});
