import { EntityCollectionStatus, EntityMap, LookupMap } from '../../common/entity-collection-state';
import { FiveWhysCause } from './five-whys-cause';

interface FiveWhysCauseLookups {
  byUtilizationId: LookupMap;
}

export interface FiveWhysCauseCollection {
  entities: EntityMap<FiveWhysCause>;

  lookups: FiveWhysCauseLookups;

  statuses: {
    byUtilizationId?: { [utilizationId: number]: EntityCollectionStatus };
  };
}

export const DefaultFiveWhysCauseCollection = {
  entities: {},

  lookups: {
    byUtilizationId: {},
  },

  statuses: {
    byUtilizationId: {},
  },
};
