import React from 'react';
import { useStyles } from './styles';
import { Locales } from '../../../models/locales';

import chinaIcon from '../../../assets/icons/flags/china.png';
import unitedKingdom from '../../../assets/icons/flags/united-kingdom.png';
import spain from '../../../assets/icons/flags/spain.png';

const localesIconsMap = {
  [Locales.EN]: unitedKingdom,
  [Locales.ES]: spain,
  [Locales.ZH]: chinaIcon,
};

interface LocaleIconProps {
  locale: Locales;
}

export const LocaleIcon: React.FC<LocaleIconProps> = ({ locale }) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <img className={styles.img} src={localesIconsMap[locale]} alt={locale} />
    </div>
  );
};
