import { FluxStandardAction } from 'flux-standard-action';
import { ApprovalActivityService } from '../../services/approval-activity.service';
import { ApprovalActivity, ApprovalActivityActionEnum, ApprovalActivityStatus } from '../model';
import { createPgAction } from '../../../shared/store/action-creator';
import { NavigateFunction } from 'react-router-dom';

export enum ApprovalActivityActionType {
  Load = 'approvalActivities/load',
  Create = 'approvalActivities/create',
  Update = 'approvalActivities/update',
  Delete = 'approvalActivities/delete',
}

interface ApprovalActivityMeta {
  projectId?: number;
  activityId?: number;
  actingUserId?: number;
}

export type ApprovalActivityAction = FluxStandardAction<
  ApprovalActivityActionType,
  ApprovalActivity,
  ApprovalActivityMeta
>;

export type ApprovalActivitiesAction = FluxStandardAction<
  ApprovalActivityActionType,
  ApprovalActivity[],
  ApprovalActivityMeta
>;

// ### if !areApprovalActivitiesLoadingByProjectIdSelector(projectId)(getState());
const loadAllByProjectId = createPgAction(
  ApprovalActivityActionType.Load,
  ApprovalActivityService.getAllByProjectId,
  (projectId: number) => ({ projectId }),
);

const create = createPgAction(
  ApprovalActivityActionType.Create,
  ApprovalActivityService.create,
  (projectId: number, activity: ApprovalActivity, navigate?: NavigateFunction) => ({ projectId, activity, navigate }),
  (payload, { projectId, navigate }) => {
    const createdActivity = new ApprovalActivity(payload);
    const isFinal = createdActivity.status === ApprovalActivityStatus.Final;
    const isAcceptedForReview = createdActivity.action === ApprovalActivityActionEnum.AcceptedForReview;

    if (isFinal && isAcceptedForReview && navigate) {
      navigate(['/project', projectId].join('/'));
    }
  },
);

const update = createPgAction(
  ApprovalActivityActionType.Update,
  ApprovalActivityService.update,
  (projectId: number, activity: ApprovalActivity) => ({ projectId, activity }),
);

const deleteApproval = createPgAction(
  ApprovalActivityActionType.Update,
  ApprovalActivityService.deleteApproval,
  (projectId: number, activityId: number) => ({ projectId, activityId }),
);

export const ApprovalActivityActions = {
  loadAllByProjectId,
  create,
  update,
  deleteApproval,
};
