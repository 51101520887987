import { ToolType } from '../tools';

export enum TemplatePreparingOptionType {
  ProjectBackground = 'ProjectBackground',
  ProblemStatement = 'ProblemStatement',
}

export enum TemplateActivityOptionType {
  Tasks = 'Tasks',
  TasksPriority = 'TasksPriority',
  Meetings = 'Meetings',
  Publishing = 'Publishing',
}

export enum TemplateResultOptionType {
  Ideas = 'Ideas',
  Solution = 'Solution',
  Report = 'Report',
}

export type TemplateOptionType =
  | TemplatePreparingOptionType
  | TemplateActivityOptionType
  | TemplateResultOptionType
  | ToolType;
