import { environment } from '@priz/shared/src/environments/environment';
import axios, { AxiosResponse } from 'axios';
import { Principle } from '@priz/shared/src/models/tools/forty-principles/principle';
import { DomainPrinciple } from '@priz/shared/src/models/tools/forty-principles/domain-principle';

const getPrinciples = (): Promise<Principle[]> =>
  axios
    .get(`${environment.apiDomain}/v1/api/40p/principles`)
    .then((response: AxiosResponse<Principle[]>) => response.data);

const getAllDomainPrinciples = (): Promise<DomainPrinciple[]> =>
  axios
    .get(`${environment.apiDomain}/v1/api/40p/domain-principles`)
    .then((response: AxiosResponse<DomainPrinciple[]>) => response.data);

export interface UpdatePrincipleCommand {
  title: string;
  titleZh?: string;
  titleEs?: string;
  description?: string;
  descriptionZh?: string;
  descriptionEs?: string;
}

const updatePrinciple = (principleId: number, command: UpdatePrincipleCommand): Promise<Principle> =>
  axios
    .put(`${environment.apiDomain}/v1/api/40p/principle/${principleId}`, command)
    .then((response: AxiosResponse<Principle>) => response.data);

export interface UpdateDomainPrincipleCommand {
  description?: string;
  descriptionZh?: string;
  descriptionEs?: string;
}

const updateDomainPrinciple = (domainPrincipleId: number, command: UpdateDomainPrincipleCommand): Promise<Principle> =>
  axios
    .put(`${environment.apiDomain}/v1/api/40p/domain-principle/${domainPrincipleId}`, command)
    .then((response: AxiosResponse<Principle>) => response.data);

export const PrincipleService = {
  getPrinciples,
  updatePrinciple,
  getAllDomainPrinciples,
  updateDomainPrinciple,
};
