import React from 'react';
import { useSelector } from 'react-redux';
import { PrizLoadingOverlay } from '@priz/shared/src/components/priz-loading-overlay/component';
import { AppSideNav } from '../../navigation/app-sidenav/component';
import { AppNavbar } from '../../navigation/app-navbar/component';
import { Outlet } from 'react-router-dom';
import { UserContextSelectors } from '../../security/store/selectors';
import { WorkspaceSelectors } from '../store/selectors';
import { PayWall } from '../../react/modules/paywall/component';
import { PageContainerWithAsideNav } from '../../content-containers/page-container-with-aside-nav/component';

export const Workspace: React.FC = () => {
  const user = useSelector(UserContextSelectors.getCurrentUser);
  const workspace = useSelector(WorkspaceSelectors.getSelectedWorkspace);

  if (!user || !workspace) return <PrizLoadingOverlay />;

  return (
    <PageContainerWithAsideNav useContentContainer>
      <AppSideNav workspace={workspace} currentUser={user} />
      <AppNavbar workspace={workspace} padding={false} />
      <Outlet />
      <PayWall />
    </PageContainerWithAsideNav>
  );
};
