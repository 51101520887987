import { FluxStandardAction } from 'flux-standard-action';
import { AnalyticsService } from '@priz/shared/src/services/analytics/services/analytics.service';
import { AnalyticsEvent } from '../../../shared/analytics-event.enum';
import { createPgAction } from '../../../shared/store/action-creator';
import { LocalStorageKey, LocalStorageService } from '@priz/shared/src/services/local-storage';
import { DialogType } from '../model';

export enum DialogsActionType {
  Open = 'dialog/open',
  Close = 'dialog/close',
}

export interface DialogsActionPayload {
  dialogType: DialogType;
}

export type DialogsAction = FluxStandardAction<DialogsActionType, DialogsActionPayload>;

const open = createPgAction(
  DialogsActionType.Open,
  dialogType =>
    new Promise(resolve =>
      resolve({
        dialogType,
      }),
    ),
  undefined,
  (payload: DialogsActionPayload) => {
    if (payload.dialogType === DialogType.PlanUpgrade) {
      const workspaceId = LocalStorageService.getItem(LocalStorageKey.SelectedWorkspaceId);

      AnalyticsService.track(AnalyticsEvent.PLAN_UPGRADE_OPEN, {
        workspace_id: workspaceId,
      });
    }
  },
);

const close = createPgAction(
  DialogsActionType.Close,
  dialogType =>
    new Promise(resolve =>
      resolve({
        dialogType,
      }),
    ),
);

export const DialogsActions = {
  open,
  close,
};
