import { createStyles, makeStyles } from '@mui/styles';
import { pgColorScheme } from '@priz/shared/src/theme';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: pgColorScheme.white,
      color: pgColorScheme.darkText,
      zIndex: 980,
    },
    leftCol: {
      flex: '1 0 0',
      width: '100%',
      overflow: 'hidden',
    },
    logoWrap: {
      display: 'block',
      height: 50,
      width: 'fit-content',
      marginRight: 20,
      cursor: 'pointer',
    },
    logo: {
      display: 'block',
      height: '100%',
      width: 'auto',
    },
    descriptionContainer: {
      flex: '1 0 0',
      overflow: 'hidden',
    },
    titleWrap: {
      display: 'flex',
    },
    title: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      flex: '1 0 0',
    },
    toolbar: {},

    [theme.breakpoints.down('md')]: {
      logoWrap: {
        height: 45,
      },
    },

    [theme.breakpoints.down('sm')]: {
      logoWrap: {
        height: 40,
        marginRight: 10,
      },
      toolbar: {
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
  }),
);
